import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { checkIsActive } from '../../../../_helpers';
import { formatNameWithSpaceTohipen } from '../../../../../app/components/commonfunction';

const AdminMenu = () => {
    const location = useLocation();
    const menuItems = [
        { name: 'Manage Tags', category: 'Manage Tags' },
        { name: 'Import Physicians', category: 'Physician List' },
    ];

    const categoryList = [...new Set(menuItems.map(item => item.category))];
    const currentPath = location.pathname;
    const pathArr = currentPath.split('/');
    const currentMenu = pathArr[1];

    const getMenuItemActive = (url, hasSubmenu = false) => (
        checkIsActive(location, url) ? `${!hasSubmenu && 'menu-item-active'} menu-item-open` : ''
    );

    const getMenuItemDisplay = (itemcat) => (
        menuItems.some(item => formatNameWithSpaceTohipen(item.category) === formatNameWithSpaceTohipen(itemcat)) ? 'block' : 'none'
    );

    return (
        <div id='kt_aside' className='aside aside-left rounded-lg aside-fixed d-flex flex-column flex-row-auto'>
            <div id='kt_aside_menu_wrapper' className='aside-menu-wrapper flex-column-fluid'>
                <Scrollbars id='kt_aside_menu' data-menu-vertical='1' className='aside-menu'>
                    {menuItems.length > 0 ? (
                        <ul className='menu-nav p-0' key='aside-main-admin'>
                            {categoryList.map(itemcat => (
                                <li
                                    key={`aside-frag-${itemcat}`}
                                    style={{ display: getMenuItemDisplay(itemcat) }}
                                    className={`menu-item menu-item-submenu ${getMenuItemActive(`/${currentMenu}/${formatNameWithSpaceTohipen(itemcat)}`, true)}`}
                                    aria-haspopup='true'
                                    data-menu-toggle='hover'
                                >
                                    <NavLink
                                        className='menu-link menu-toggle'
                                        title={itemcat}
                                        to={`/${currentMenu}/${formatNameWithSpaceTohipen(itemcat)}`}
                                    >
                                        <span className='menu-text'>{itemcat}</span>
                                        <i className='menu-arrow' />
                                    </NavLink>
                                    <div className='menu-submenu'>
                                        <i className='menu-arrow' />
                                        <ul className='menu-subnav'>
                                            <li className='menu-item menu-item-parent' aria-haspopup='true'>
                                                <span className='menu-link'>
                                                    <span className='menu-text'>{itemcat}</span>
                                                </span>
                                            </li>
                                            {menuItems.map((item, index) => {
                                                const linkBuild = formatNameWithSpaceTohipen(item.originalname || item.name);
                                                return item.category === itemcat && (
                                                    <li
                                                        key={index}
                                                        className={`menu-item ${pathArr[4] === String(item.formId) ? 'active' : ''}`}
                                                        aria-haspopup='true'
                                                    >
                                                        <NavLink
                                                            className='menu-link'
                                                            to={`/${currentMenu}/${formatNameWithSpaceTohipen(item.category)}/${linkBuild}`}
                                                        >
                                                            <div className='menu-text' dangerouslySetInnerHTML={{ __html: item.name }} />
                                                        </NavLink>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className='no-record-menu'>No Results Found</div>
                    )}
                </Scrollbars>
            </div>
        </div>
    );
};

export default AdminMenu;
