/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';

import {
    GetFieldData,
    PatientWithUnderScore,
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    normalErrorToast,
    normalSuccessToast,
    showErrorToast,
    getDropDownDataFromApi,
    stringEmptyOrUndefined,
    encodeParameter,
} from '../commonfunction';
import {
    updateRecordSet,
    recordSetSearch,
    createNewPatient,
    deleteRecordSet,
} from '../../pages/api/api';

import DeleteModal from './DeleteModal';
import { getDDLlist } from '../../pages/_redux/actions/configActions';

// const _ = require('lodash');

const ByPhysician = (props) => {
    const textInput = useRef(null);
    const [physician_filter, setPhysician_filter] = useState(
        props.physician_filter !== undefined
            ? props.physician_filter
            : props.state.physician_filter !== undefined
            ? props.state.physician_filter
            : ''
    );
    const [physicianFieldIdArray, setPhysicianFieldIdArray] = useState(
        props.state.physicianFieldIdArray
    );
    const [physicianRecordsetId, setPhysicianRecordsetId] = useState(
        props.state.physicianRecordsetId
    );
    const [physicianModifiableRecordsetId, setPhysicianModifiableRecordsetId] =
        useState(props.state.physicianModifiableRecordsetId);
    /* const [currentSelectedPhysician, setCurrentSelectedPhysician] =
        useState(''); */
    const [autoCompleteSpinner, setAutoCompleteSpinner] = useState(true);
    const [open_physician_dropdown, setOpen_physician_dropdown] = useState();
    // const [open_order_dropdown, setOpen_order_dropdown] = useState();
    // const [order_filter, setOrder_filter] = useState();
    const [physician_list, setPhysician_list] = useState([]);
    const [selectedPhysician, setSelectedPhysician] = useState(
        props.selectedPhysician
    );
    // eslint-disable-next-line no-unused-vars
    const [view_physician, setView_physician] = useState(
        props.state.view_physician
    );
    const [, setSelected_physician_id] = useState(
        props.state.selected_physician_id
    );
    // const[create_physician, setCreate_physician   ]=useState(props.state.create_physician)
    const [create_physician, setCreate_physician] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [edit_physician, setEdit_physician] = useState(
        props.state.edit_physician
    );
    const [, setEdit_or_create] = useState(props.state.edit_or_create);
    const [edit_button, setEdit_button] = useState(false);
    const [create_button, setCreate_button] = useState(false);
    const [view, setView] = useState(props.view);
    const [runFunction, setRunFunction] = useState(true);
    const [newFunction, setNewFunction] = useState(true);
    const [openPhysicianDelete, setOpenPhysicianDelete] = useState(false);
    const [oldSelectedPhysician, setOldSelectedPhysician] = useState(
        props.selectedPhysician
    );
    const [text, setText] = useState(null);
    const [ourTimeout, setOurTimeout] = useState(null);
    const redux_store = useSelector((state) => state);

    const configData = redux_store.configReducerPersist.configData;

    const dispatch = useDispatch();

    useEffect(() => {
        if (text != null) {
            if (ourTimeout != null) {
                clearInterval(ourTimeout);
            }
            setOurTimeout(
                setTimeout(
                    () => {
                        // props.callBack(text);
                        setPhysician_filter(text);
                        physicianApiCall(text);
                    },
                    text.length == 0 ? 400 : 800
                )
            );
        }
    }, [text]);

    const configuredRecordsets = () => {
        let configuredRecordsets = [];
        let physician_FieldIdArray;
        let physician_RecordsetId;
        let physician_ModifiableRecordsetId;

        configuredRecordsets =
            redux_store.configReducerPersist.configData.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectory'
                ) {
                    physician_FieldIdArray = data.fields;
                    physician_RecordsetId = data.recordsetId;
                    physician_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                return data;
            });
        }

        setPhysicianFieldIdArray(physician_FieldIdArray);
        setPhysicianRecordsetId(physician_RecordsetId);
        setPhysicianModifiableRecordsetId(physician_ModifiableRecordsetId);
    };

    const getBranchDropDownData = () => {
        const fieldData = GetFieldData({ configData: configData });

        if (stringEmptyOrUndefined(fieldData)) {
            if (!redux_store.persistDDL.DDL.hasOwnProperty('deliverymethod')) {
                getDropDownDataFromApi(fieldData.delivery_method).then(
                    (data) => {
                        if (data.values) {
                            dispatch(
                                getDDLlist({
                                    [formatHeaderNameWithOutSpace(data.name)]:
                                        data.values,
                                })
                            );
                        }
                    }
                );
            }
        }
    };

    const searchSinglePhysicain = (npiId) => {
        props.updateState({
            view: 'not_initial_view',
        });
        setView('not_initial_view');

        let searchText = '';
        if (selectedPhysician !== '') {
            physicianFieldIdArray.map((fieldData) => {
                if (fieldData.name === 'NPI') {
                    searchText += `fieldSearch=${fieldData.fieldId}%3D${npiId}%2A`;
                    // encodeURI(fieldData.fieldId + '=' + e.target.value);
                }
                //  return fieldData;
            });
        }

        let status;
        recordSetSearch(physicianRecordsetId, searchText)
            .then((response) => {
                if (response !== undefined && response.status !== undefined) {
                    status = response.status;
                    return response.json();
                }
            })
            .then((data) => {
                if (status === 200) {
                    // const searchResult = PatientWithUnderScore(data);
                    const singlePhysicain = PatientWithUnderScore(data);

                    /* if (view !== 'initialView') {
                        this.setState(
                            { currentSelectedPatient: singlePatient[0] },
                            () => {
                                this.props.updateState({
                                    currentSelectedPatient: singlePatient[0],
                                });
                            }
                        );
                        if (searchResult.length > 0) {
                            this.open_patient_search(searchResult[0]);
                        }
                    } else { */
                    let search = {};
                    if (singlePhysicain.length > 0) {
                        search = singlePhysicain[0];
                    }

                    if (search.hasOwnProperty('npi')) {
                        const data_physician =
                            props.selectedPhysician !== undefined
                                ? props.selectedPhysician
                                : selectedPhysician;

                        const temp = {
                            ...data_physician,
                            id: search.id,
                            isModifiable: search.isModifiable,
                        };

                        setSelectedPhysician(temp);
                        setOldSelectedPhysician(temp);
                        props.updateState({ selectedPhysician: temp });
                        props.updateState({ tab_name: 'tab_inbox' });
                    }
                    /* if (search.id > 0 || search.id === 0) {
                            this.setState(
                                {
                                    patientIsNegative: false,
                                },
                                () => {
                                    this.props.updateState({
                                        patientIsNegative: false,
                                    });
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    patientIsNegative: true,
                                },
                                () => {
                                    this.props.updateState({
                                        patientIsNegative: true,
                                    });
                                }
                            );
                        } */
                    // }
                } else {
                    showErrorToast(
                        'An error has occured while calling the API.'
                    );
                }
            });
    };

    const closePhysicianDropDown = (event) => {
        if (event.target.id !== 'search_physician') {
            const popElement =
                document.getElementsByClassName('patient_dropdown');
            let popEl;
            for (let i = 0; i < popElement.length; i++) {
                popEl = popElement[i];
                const isClickInside = popEl.contains(event.target);
                if (!isClickInside) {
                    setOpen_physician_dropdown(false);
                    setPhysician_filter('');
                    // setOpen_order_dropdown(false);
                    // setOrder_filter('');
                    return;
                }
            }
        }
    };

    useEffect(() => {
        configuredRecordsets();
        if (
            selectedPhysician !== undefined &&
            selectedPhysician.npi !== undefined &&
            selectedPhysician.npi !== '' &&
            view === 'initial_view'
        ) {
            searchSinglePhysicain(selectedPhysician.npi);
        }

        if (
            props.toggleRecentDoc === false &&
            physician_filter === '' &&
            props.physician_filter !== '' &&
            props.physician_filter !== undefined
        ) {
            setPhysician_filter(props.physician_filter);
        }

        document.addEventListener('mousedown', closePhysicianDropDown);

        if (
            props.physician_filter !== undefined &&
            JSON.stringify(physician_filter) !==
                JSON.stringify(props.physician_filter)
        ) {
            setPhysician_filter(props.physician_filter);
        }

        //}, [view, props.physician_filter, props.selectedPhysician]);
        // if use above line then during edit Physician auto focues move to auto complete place
    }, [props.physician_filter, props.selectedPhysician]);

    const clearInputBox = () => {
        const fieldValue = '';
        setPhysician_filter(fieldValue);
        textInput.current.focus();
        props.updateState({
            physician_filter: '',
        });
        //document.getElementById("search_physician").focus();
        //textInput.current.select();
        // document.getElementById('search_physician').value='';
    };

    const byPhysicianOnChangeHandler = (e) => {
        setPhysician_filter(e.target.value);
    };
    const physicianApiCall = (text) => {
        const currentValue = text.split('');

        if (currentValue.length <= 0) {
            //setPhysician_filter(text);
            setOpen_physician_dropdown(false);
            return true;
        }
        setEdit_or_create('edit');
        setCreate_button(false);
        props.updateState({
            edit_or_create: 'edit',
            create_button: false,
        });
        setOpen_physician_dropdown(false);
        //setPhysician_filter(text);
        setAutoCompleteSpinner(true);

        let searchText = '';

        if (
            physicianFieldIdArray !== '' &&
            physicianFieldIdArray !== undefined &&
            physicianFieldIdArray !== null
        ) {
            physicianFieldIdArray.map((fieldData, index) => {
                if (
                    (props.state.currentLookup === undefined ||
                        props.state.currentLookup === 'byPhysician') &&
                    fieldData.name === 'Ordering Physician'
                ) {
                    searchText += `fieldSearch=${
                        fieldData.fieldId
                    }%3D${encodeParameter(text)}%2A`;
                    // encodeURI(fieldData.fieldId + '=' + e.target.value);
                }
                if (
                    props.state.currentLookup === 'byNpi' &&
                    fieldData.name === 'NPI'
                ) {
                    searchText += `fieldSearch=${fieldData.fieldId}%3D${text}%2A`;
                    // encodeURI(fieldData.fieldId + '=' + e.target.value);
                }
                return fieldData;
            });
        }

        setOpen_physician_dropdown(true);
        setPhysician_list([]);

        let status;
        recordSetSearch(physicianRecordsetId, searchText)
            .then((response) => {
                if (response !== undefined && response.status !== undefined) {
                    status = response.status;
                    return response.json();
                } else {
                    setAutoCompleteSpinner(undefined);
                }
            })
            .then((data) => {
                if (status === 200) {
                    // var searchResult = PatientSearch(data).slice(0,11)
                    const searchResult = PatientWithUnderScore(data);
                    // var singlePatient = PatientWithUnderScore(data);
                    searchResult.reduce(function (r, a, c) {
                        r[a.physician_id] = r[a.physician_id] || [];
                        r[a.physician_id].push(a);
                        return r;
                    }, Object.create(null));

                    // this.state.patient_list.push(searchResult)
                    setPhysician_list(searchResult);
                    setAutoCompleteSpinner(false);

                    //  ('Header5 searchResult==>', searchResult);
                }
            });
    };

    const open_physician_search = async (search) => {
        if (props.calingFrom === 'dashboard') {
            let path = 'search/physicians/?NPI=' + search.npi;
            history.push(path);
        } else {
            setPhysician_filter(
                `${search.physician_last_name}, ${search.physician_first_name}`
            );
            setSelectedPhysician(search);
            setOldSelectedPhysician(search);
            setOpen_physician_dropdown(false);
            setView_physician(false);
            setSelected_physician_id(search.id);
            setCreate_physician(false);
            setEdit_physician(false);
            setEdit_button(false);
            props.updateState({
                physician_filter: `${search.physician_last_name}, ${search.physician_first_name}`,
                selectedPhysician: search,
                open_physician_dropdown: false,
                view_physician: false,
                // selected_physician_id: search.id,
                create_physician: false,
                edit_physician: false,
                validateEdit: false,
            });
            if (props.calingFrom === 'upload') {
                props.PhsicianData_Function(search);
                props.setView_check(1);
            }
            await setView_physician(true);
            await props.updateState({
                view_physician: true,
            });
            if (props.calingFrom === 'search') {
                props.toggleRecentDocFun();
            }
        }
    };

    const physician_lists = () => {
        if (physician_list.length > 0) {
            return physician_list.map((search, index) => {
                //let res = search.physician_last_name;
                let res = search.ordering_physician;
                const req = [physician_filter];

                if (req) {
                    /* const normReq = req
                        .toLowerCase()
                        .replace(/\s+/g, ' ')
                        .trim()
                        .split(' ')
                        .sort((a, b) => b.length - a.length); */

                    //here g means matching all occurance, bargvai mam asked first occurancce alone -11/05/2023

                    /*  res = res.replace(
                        new RegExp(`(${normReq.join('|')})`, 'gi'),
                        (match) => `<b class='highlight'>${match}</b>`
                    ); */
                    res = res.replace(
                        new RegExp(`(${req.join('|')})`, 'i'),
                        (match) => `<b class='highlight'>${match}</b>`
                    );
                }

                //const custom_name = `${res}, ${search.physician_first_name}`;
                const custom_name = `${res}`;
                return (
                    <React.Fragment key={index}>
                        <li
                            key={index}
                            onClick={() => {
                                open_physician_search(search);
                            }}
                        >
                            <div className='d-flex align-items-stretch justify-content-between'>
                                <div className=''>
                                    <div className='patient_no_name'>
                                        <span
                                            className='first_name'
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: custom_name,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='filter-date'>
                                    <span>NPI: </span>
                                    <span>{search.npi}</span>
                                </div>
                            </div>
                        </li>
                    </React.Fragment>
                );
            });
        } else {
            return <div style={{ textAlign: 'center' }}>No Records Found</div>;
        }
    };

    const close_edit = () => {
        setCreate_physician(false);
        setCreate_button(false);
        props.updateState({
            edit_physician: undefined,
            view_physician: false,
            // open_edit_physician: false,
            // edit_button: false,
            create_button: false,
        });
    };
    const close_edit2 = () => {
        setEdit_button(false);
        setSelectedPhysician(oldSelectedPhysician);
        props.updateState({
            edit_physician: false,
            selectedPhysician: oldSelectedPhysician,
            edit_or_create: 'edit',
            // view_physician:false,
            // open_edit_physician: false,
            // edit_button: false,
            create_button: false,
        });
        //open_physician_search(oldSelectedPhysician);
    };
    const editPhysician = () => {
        props.updateState({
            edit_physician: true,
            open_edit_physician: true,
            edit_button: true,
            create_physician: false,
        });

        setEdit_physician(true);
        setEdit_button(true);
        if (props.calingFrom === 'upload') {
            props.setView_check(2);
        }
    };
    const newPhysicianSubmit = async () => {
        setNewFunction(false);
        await props.resetSubmit();

        let error = false;
        if (props.state.createPhysicianData === undefined) {
            error = true;
        }

        for (const property in props.state.createPhysicianData) {
            if (
                Object.prototype.hasOwnProperty.call(
                    props.state.createPhysicianData,
                    property
                )
            ) {
                if (
                    props.state.createPhysicianData[property] === '' &&
                    (property === 'npi' ||
                        property === 'fax_number' ||
                        //property === 'phone_number' ||
                        //property === 'email_address' ||
                        property === 'physician_first_name' ||
                        property === 'physician_last_name')
                ) {
                    if (error === false) {
                        error = true;
                    }
                    break;
                }
                if (props.calingFrom !== 'upload') {
                    if (
                        props.state.createPhysicianData.delivery_method ===
                            '' ||
                        props.state.createPhysicianData.delivery_method ===
                            undefined
                    ) {
                        if (error === false) {
                            error = true;
                        }
                    }
                }
            }
        }

        if (error === true) {
            if (props.validateEdit === false) {
                props.updateState({
                    validateEdit: true,
                });
            }
            normalErrorToast('Please fill mandatory fields');
            setNewFunction(true);
            return true;
        }
        let validationError = false;
        if (props.state.createPhysicianData.ordering_physician) {
            props.updateState({
                ...props.state.createPhysicianData,
                ordering_physician: `${props.state.createPhysicianData.physician_last_name}, ${props.state.createPhysicianData.physician_first_name}`,
            });
        }
        // as per client requirement we remove validation check for email (bug no 9074)
        /*  const regex_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (props.state.createPhysicianData.email_address) {
            if (
                !regex_email.test(props.state.createPhysicianData.email_address)
            ) {
                props.updateState({
                    emailValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        } */

        // eslint-disable-next-line no-useless-escape
        const faxPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if (props.state.createPhysicianData.fax_number) {
            if (!props.state.createPhysicianData.fax_number.match(faxPattern)) {
                props.updateState({
                    faxValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        }

        if (props.state.createPhysicianData.phone_number) {
            if (
                !props.state.createPhysicianData.phone_number.match(
                    faxPattern
                ) &&
                props.state.createPhysicianData.phone_number !== '__________' &&
                props.state.createPhysicianData.phone_number !== '___-___-____'
            ) {
                props.updateState({
                    phoneValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        }
        const pattern = /^\d{10}$/;
        if (props.state.createPhysicianData.npi) {
            if (!props.state.createPhysicianData.npi.match(pattern)) {
                props.updateState({
                    npiValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        }
        if (validationError === true) {
            setNewFunction(true);
            return false;
        }

        // let recordSetId;
        //    physicianFieldIdArray = physicianFieldIdArray;
        const recordSetId = physicianModifiableRecordsetId;
        const params = [];
        const formObj = props.state.createPhysicianData;
        const formData = [];
        formData.push(formObj);
        /*  let phoneNumber = formData.map((item, idx) => {
           
            if (
                item.phone_number.includes('_') ||
                item.phone_number.includes('-')
            ) {
                return item.phone_number.replace(/[_-]/g, '');
            } else if (
                !item.phone_number.includes('_') ||
                !item.phone_number.includes('-')
            ) {
                return item.phone_number;
            }
        });
        let UpdateFormData = [];

        let newformData = {
            ...formData[0],
            phone_number: phoneNumber[0],
        };
        UpdateFormData.push(newformData); */

        if (physicianFieldIdArray !== '') {
            physicianFieldIdArray.map((data) => {
                const value =
                    formData[0][formatNameWithSpaceToUnderscore(data.name)];
                if (
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        undefined &&
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        '' &&
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        null &&
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        '___-___-____' &&
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        '__________' // this is for empty phone number
                ) {
                    params.push({
                        fieldId: data.fieldId,
                        value: value,
                    });
                }
                return '';
            });
        }

        if (props.state.edit_or_create === 'edit') {
            let status;
            updateRecordSet(
                params,
                recordSetId,
                props.state.selectedPhysician.id
            )
                .then((response) => {
                    // this.setState({ blur_spinner: false });
                    status = response.status;
                    if (status === 204) {
                        setEdit_physician(false);

                        props.updateState({ edit_physician: false });
                        if (props.calingFrom === 'upload') {
                            props.setView_check(1);
                        }
                        normalSuccessToast('Updated Successfully');
                        setNewFunction(true);
                    } else if (response !== '') {
                        return response.json();
                    }
                })
                .then((data) => {
                    if (status === 400 || status === 404) {
                        showErrorToast(data.message);
                        setNewFunction(true);
                    }
                });
        } else {
            let create_status;
            createNewPatient(params, recordSetId)
                .then((response) => {
                    create_status = response.status;
                    return response.json();
                })
                .then((data) => {
                    // this.setState({loader:false});

                    if (create_status === 200) {
                        formData.id = data.uniqueId;
                        // formData.patientid = data.uniqueId;
                        // this.setState({ loader: false });
                        setEdit_physician(false);
                        setCreate_button(false);
                        props.updateState({
                            edit_physician: false,
                            create_button: false,
                        });
                        const physician_data = props.state.createPhysicianData;
                        const data_spread = props.state.createPhysicianData;
                        if (props.calingFrom === 'upload') {
                            props.PhsicianData_Function({
                                ...data_spread,
                                id: data.uniqueId,
                                isModifiable: true,
                            });
                            props.setView_check(1);
                        }
                        if (props.calingFrom === 'search') {
                            props.updateState({
                                toggleRecentDoc: false,
                            });
                        }
                        setSelectedPhysician({
                            ...props.state.createPhysicianData,
                            id: data.uniqueId,
                            isModifiable: true,
                        });
                        setPhysician_filter(
                            `${props.state.createPhysicianData.physician_last_name}, ${props.state.createPhysicianData.physician_first_name}`
                        );
                        setOldSelectedPhysician({
                            ...props.state.createPhysicianData,
                            id: data.uniqueId,
                            isModifiable: true,
                        });
                        props.updateState({
                            selectedPhysician: {
                                ...physician_data,
                                id: data.uniqueId,
                                isModifiable: true,
                            },

                            physician_filter: `${props.state.createPhysicianData.physician_last_name}, ${props.state.createPhysicianData.physician_first_name}`,
                            edit_or_create: 'edit',
                        }); // added by kalpana
                        setEdit_or_create('edit');
                        normalSuccessToast('Physician Created Successfully');

                        // searchSinglePhysicain(props.state.createPhysicianData.npi)

                        // this.props.closeCreatePatient(formData);
                    }
                    if (create_status === 400) {
                        // this.setState({ loader: false });
                        showErrorToast(data.message);
                        setNewFunction(true);
                    }
                });
        }
    };

    const handleCloseOperation = async () => {
        setOpenPhysicianDelete(false);
    };

    const handlePhysicianDeleteOperation = async () => {
        if (props.state.selectedPhysician !== undefined) {
            let status;
            const multipleIds = [];
            const recordSetId = physicianModifiableRecordsetId;

            /* normalSuccessToast(
                'Deleted Successfully - ' + props.state.selectedPhysician.id
            );
            setEdit_physician(false);
            setEdit_button(false);
            setView_physician(false);
            setPhysician_filter('');
            props.updateState({
                edit_physician: false,
                edit_button: false,
                view_physician: false,
                selectedPhysician: false,
            });
            setOpenPhysicianDelete(false); */
            let physicianId = Math.abs(props.state.selectedPhysician.id);
            deleteRecordSet(multipleIds, recordSetId, physicianId)
                .then((response) => {
                    // this.setState({ blur_spinner: false });
                    status = response.status;
                    if (status === 204) {
                        setEdit_physician(false);
                        setEdit_button(false);
                        setView_physician(false);
                        setCreate_physician(false);
                        setPhysician_filter('');
                        props.updateState({
                            edit_physician: false,
                            edit_button: false,
                            view_physician: false,
                            selectedPhysician: false,
                        });
                        normalSuccessToast('Physician Deleted Successfully');
                        setOpenPhysicianDelete(false);
                        setRunFunction(true);
                    } else if (response !== '') {
                        setRunFunction(true);
                        return response.json();
                    }
                })
                .then((data) => {
                    if (status === 400 || status === 404) {
                        showErrorToast('Error in deleting.');
                        setOpenPhysicianDelete(false);
                        setRunFunction(true);
                    }
                });
        }
    };

    const deletePhysician = async () => {
        if (props.state.selectedPhysician !== undefined) {
            setOpenPhysicianDelete(true);
        }
    };

    const submitEditPhysician = async () => {
        setRunFunction(false);

        await props.resetSubmit();
        let error = false;

        if (
            props.state.selectedPhysician !== undefined &&
            props.state.selectedPhysician.ordering_physician !== undefined
        ) {
            for (const property in props.state.selectedPhysician) {
                if (
                    props.state.selectedPhysician[property] === '' &&
                    (property === 'npi' ||
                        property === 'fax_number' ||
                        //property === 'phone_number' ||
                        //property === 'email_address' ||
                        property === 'physician_first_name' ||
                        property === 'physician_last_name')
                ) {
                    if (error === false) {
                        error = true;
                    }

                    break;
                }
            }
        }
        if (props.calingFrom !== 'upload') {
            if (
                props.state.selectedPhysician.delivery_method === '' ||
                props.state.selectedPhysician.delivery_method === undefined
            ) {
                if (error === false) {
                    error = true;
                }
            }
        }
        if (
            props.state.selectedPhysician.npi === undefined ||
            props.state.selectedPhysician.fax_number === undefined ||
            //props.state.selectedPhysician.phone_number === undefined ||
            props.state.selectedPhysician.ordering_physician === undefined // ||
            //props.state.selectedPhysician.email_address === undefined
        ) {
            if (error === false) {
                error = true;
            }
        }
        let validationError = false;
        // eslint-disable-next-line no-useless-escape
        const regex_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // as per client requirement we remove validation check for Email address (bug no 9074)
        /* if (props.state.selectedPhysician.email_address) {
            if (
                !regex_email.test(props.state.selectedPhysician.email_address)
            ) {
                props.updateState({
                    emailValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        } */

        const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        //const pattern = /^[0-9\b\.\,\+\-\*\s\(\)\[\]\#]+$/;
        if (props.state.selectedPhysician.fax_number) {
            if (!props.state.selectedPhysician.fax_number.match(pattern)) {
                props.updateState({
                    faxValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        }
        if (props.state.selectedPhysician.phone_number) {
            if (
                !props.state.selectedPhysician.phone_number.match(pattern) &&
                props.state.selectedPhysician.phone_number !== '___-___-____' &&
                props.state.selectedPhysician.phone_number !== '__________'
            ) {
                props.updateState({
                    phoneValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        }

        const npiPattern = /^\d{10}$/;
        if (props.state.selectedPhysician.npi) {
            if (!props.state.selectedPhysician.npi.match(npiPattern)) {
                props.updateState({
                    npiValidation: true,
                });
                if (validationError === false) {
                    validationError = true;
                }
            }
        }
        if (error === true) {
            if (props.validateEdit === false) {
                props.updateState({
                    validateEdit: true,
                });
            }
            normalErrorToast('Please fill mandatory fields');
            // this.setState({ blur_spinner: false });
            setRunFunction(true);

            return true;
        }
        if (validationError === true) {
            setRunFunction(true);
            return false;
        }

        /* var dob;
        if (this.state.dob !== '') {
            dob = formatDate(this.state.dob, 'MM/DD/YYYY');
        } */

        // var physicianFieldIdArray;
        // physicianFieldIdArray = physicianFieldIdArray;

        //delete props.state.selectedPhysician.npi

        const recordSetId = physicianModifiableRecordsetId;
        const params = [];
        const formObj = props.state.selectedPhysician;
        let formData = [];
        formData.push(formObj);

        /*  let phoneNumber = formData.map((item, idx) => {
          
            if (
                item.phone_number.includes('_') ||
                item.phone_number.includes('-')
            ) {
                return item.phone_number.replace(/[_-]/g, '');
            } else if (
                !item.phone_number.includes('_') ||
                !item.phone_number.includes('-')
            ) {
                return item.phone_number;
            }
        });
        let UpdateFormData = []

        let newformData = {
            ...formData[0],
            phone_number: phoneNumber[0],
        };
        UpdateFormData.push(newformData); */

        if (physicianFieldIdArray !== '') {
            physicianFieldIdArray.map((data) => {
                const value =
                    formData[0][formatNameWithSpaceToUnderscore(data.name)];
                if (
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        undefined &&
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        '' &&
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        null &&
                    formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                        '___-___-____'
                ) {
                    params.push({
                        fieldId: data.fieldId,
                        value: value,
                    });
                }
                return '';
            });
        }

        let status;
        let physicianId = Math.abs(props.state.selectedPhysician.id);
        if (props.state.selectedPhysician.isModifiable === true) {
            updateRecordSet(params, recordSetId, physicianId)
                .then((response) => {
                    // this.setState({ blur_spinner: false });
                    status = response.status;
                    if (status === 204) {
                        setEdit_physician(false);
                        setEdit_button(false);
                        props.updateState({
                            edit_physician: false,
                            edit_button: false,
                            physician_filter:
                                props.state.selectedPhysician
                                    .ordering_physician,
                            edit_or_create: 'edit',
                        });

                        setPhysician_filter(
                            props.state.selectedPhysician.ordering_physician
                        );
                        if (props.calingFrom === 'upload') {
                            props.setView_check(1);
                        }
                        normalSuccessToast('Updated Successfully');

                        setEdit_or_create('edit');
                        setRunFunction(true);
                    } else if (response !== '') {
                        setRunFunction(true);
                        return response.json();
                    }
                })
                .then((data) => {
                    if (status === 400 || status === 404) {
                        showErrorToast(data.message);
                        setRunFunction(true);
                    }
                });
        } else {
            let create_status;
            createNewPatient(params, recordSetId)
                .then((response) => {
                    create_status = response.status;
                    return response.json();
                })
                .then((data) => {
                    // this.setState({loader:false});

                    if (create_status === 200) {
                        formData.id = data.uniqueId;
                        // formData.patientid = data.uniqueId;
                        // this.setState({ loader: false });
                        setEdit_physician(false);
                        setCreate_button(false);
                        props.updateState({
                            edit_physician: false,
                            create_button: false,
                        });
                        const physician_data = props.state.selectedPhysician;
                        const data_spread = props.state.selectedPhysician;
                        if (props.calingFrom === 'upload') {
                            props.PhsicianData_Function({
                                ...data_spread,
                                id: data.uniqueId,
                                isModifiable: true,
                            });
                            props.setView_check(1);
                        }
                        if (props.calingFrom === 'search') {
                            props.updateState({
                                toggleRecentDoc: false,
                            });
                        }
                        setSelectedPhysician({
                            ...props.state.selectedPhysician,
                            id: data.uniqueId,
                            isModifiable: true,
                        });
                        setPhysician_filter(
                            `${props.state.selectedPhysician.physician_last_name}, ${props.state.selectedPhysician.physician_first_name}`
                        );
                        setOldSelectedPhysician({
                            ...props.state.selectedPhysician,
                            id: data.uniqueId,
                            isModifiable: true,
                        });
                        props.updateState({
                            selectedPhysician: {
                                ...physician_data,
                                id: data.uniqueId,
                                isModifiable: true,
                            },

                            physician_filter: `${props.state.selectedPhysician.physician_last_name}, ${props.state.selectedPhysician.physician_first_name}`,
                            edit_or_create: 'edit',
                        });
                        setEdit_or_create('edit');
                        normalSuccessToast('Updated Successfully');
                        setEdit_button(false);
                        props.updateState({
                            edit_button: false,
                        });
                        // searchSinglePhysicain(props.state.createPhysicianData.npi)

                        // this.props.closeCreatePatient(formData);
                    }
                    if (create_status === 400) {
                        // this.setState({ loader: false });
                        showErrorToast(data.message);
                        setNewFunction(true);
                    }
                });
        }
    };
    const history = useHistory();
    const openCreateNewPhysician = async () => {
        if (props.calingFrom === 'dashboard') {
            let path = '/search/physicians/?operation=add';

            history.push(path);
        } else {
            // setOpen_create_physician(true);
            setEdit_physician(true);
            setView_physician(true);
            setOpen_physician_dropdown(false);
            setCreate_physician(true);
            setEdit_or_create('create');
            setCreate_button(true);
            setSelectedPhysician('');
            setEdit_button(false);
            setPhysician_filter('');
            await props.updateState({
                open_create_physician: true,
                edit_physician: true,
                view_physician: true,
                open_physician_dropdown: false,
                create_physician: true,
                edit_or_create: 'create',
                create_button: true,
                selectedPhysician: undefined,
                validateEdit: false,
                physician_filter: '',
                npiValidation: false,
                phoneValidation: false,
                faxValidation: false,
                emailValidation: false,
            });
            if (props.calingFrom === 'upload') {
                props.PhsicianData_Function({
                    npi: '',
                    physician_first_name: '',
                    physician_last_name: '',
                    fax_number: '',
                    phone_number: '',
                    email_address: '',
                    ordering_physician: '',
                });

                props.setView_check(2);
            }
        }
    };

    // props.state.view_physician &&
    // props.state.edit_physician === false &&
    // selectedPhysician !== undefined &&
    // selectedPhysician.id !== undefined &&
    // selectedPhysician.isModifiable &&
    // props.state.modifyPhysicianModifiableRecordsetId !==
    //     null ? (
    //     <>
    //         {props.calingFrom === 'search' &&
    //         props.toggleRecentDoc === false
    return (
        <>
            <div
                className={
                    props.calingFrom !== 'search' &&
                    props.calingFrom !== 'dashboard'
                        ? 'row grid-row-group'
                        : 'grid-row-group'
                }
            >
                <div className='col-lg-12 col-xl-8 detail_search'>
                    <label
                        className={
                            props.calingFrom === 'search' &&
                            props.toggleRecentDoc === true
                                ? ' hide-data pl-5'
                                : 'pl-5'
                        }
                    >
                        {props.calingFrom === 'search' &&
                        props.toggleRecentDoc === false
                            ? 'LOOKUP'
                            : props.calingFrom === 'bigView'
                            ? 'PHYSICIAN'
                            : null}
                    </label>
                    <div className='d-flex'>
                        {props.toggleRecentDoc === true ? (
                            <div
                                className='row'
                                onClick={() => {
                                    props.toggleRecentDocFun();
                                }}
                            >
                                <div className='mr-4'>
                                    <label>LOOKUP</label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <h3 className='title m-0'>
                                        {physician_filter
                                            .charAt(0)
                                            .toUpperCase() +
                                            physician_filter.slice(1)}
                                    </h3>
                                </div>
                            </div>
                        ) : null}
                        {(props.toggleRecentDoc === false ||
                            props.toggleRecentDoc === undefined) && (
                            <div className='filter'>
                                <input
                                    id='search_physician'
                                    className={
                                        props.physicianLink ||
                                        props.updatephysicianLink
                                            ? 'patient-autoComplete form-control error-field '
                                            : 'patient-autoComplete form-control'
                                    }
                                    value={
                                        physician_filter !== ''
                                            ? physician_filter
                                            : ''
                                    }
                                    placeholder={
                                        (props.calingFrom === 'search' ||
                                            props.calingFrom === 'dashboard') &&
                                        props.state.currentLookup ===
                                            'byPhysician'
                                            ? 'Lookup by Physician Name'
                                            : (props.calingFrom === 'search' ||
                                                  props.calingFrom ===
                                                      'dashboard') &&
                                              props.state.currentLookup ===
                                                  'byNpi'
                                            ? 'Lookup by NPI Number'
                                            : 'Type to search by Physician'
                                    }
                                    type='text'
                                    onChange={(e) => {
                                        setText(e.target.value);
                                        byPhysicianOnChangeHandler(e);
                                        if (props.physicianLink === true) {
                                            props.updateState({
                                                physicianLink: false,
                                            });
                                        }
                                        if (
                                            props.calingFrom === 'upload' &&
                                            props.updatephysicianLink
                                        ) {
                                            props.PhsicianLink_Function(false);
                                        }
                                    }}
                                    autoFocus
                                    ref={textInput}
                                />
                                {physician_filter !== '' &&
                                physician_filter !== null &&
                                physician_filter !== undefined &&
                                physician_filter.length > 0 ? (
                                    <i
                                        className='fa-regular fa-xmark'
                                        onClick={clearInputBox}
                                    />
                                ) : (
                                    <i className='fa-regular fa-magnifying-glass' />
                                )}
                                {open_physician_dropdown ? (
                                    <div
                                        className='patient_dropdown'
                                        style={{
                                            height:
                                                physician_list.length > 0
                                                    ? '248px'
                                                    : '80px',
                                        }}
                                    >
                                        <ul
                                            style={{
                                                listStyleType: 'none',
                                                overflowY: 'scroll',
                                                height:
                                                    physician_list.length > 0
                                                        ? '200px'
                                                        : '',
                                            }}
                                        >
                                            {autoCompleteSpinner ? (
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Spinner
                                                        animation='grow'
                                                        variant='primary'
                                                    />
                                                </div>
                                            ) : (
                                                physician_lists() // check finally
                                            )}
                                        </ul>
                                        <br />
                                        <div
                                            className='adding_patient'
                                            onClick={openCreateNewPhysician}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className='plus'>
                                                <span>
                                                    <i
                                                        className='fa-regular fa-plus'
                                                        aria-hidden='true'
                                                    />
                                                </span>
                                            </div>
                                            Create New Physician
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        )}

                        {props.state.view_physician !== true &&
                        create_physician === false &&
                        edit_button !== true ? (
                            <div className='create'>
                                {props.calingFrom === 'search' ? (
                                    <Button
                                        variant='primary'
                                        className='success'
                                        onClick={openCreateNewPhysician}
                                        title='Add New Physician'
                                    >
                                        <i className='fa-regular fa-plus' /> Add
                                        New Physician
                                    </Button>
                                ) : null}

                                {props.calingFrom !== 'search' &&
                                props.calingFrom !== 'dashboard' ? (
                                    <div
                                        className='plus'
                                        onClick={openCreateNewPhysician}
                                    >
                                        <span>
                                            <i
                                                className='fa-regular fa-plus'
                                                aria-hidden='true'
                                            />
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {/*
							selectedPhysician.isModifiable ? (
								<div>I am here</div>
							) : null*/}

                        {
                            //props added with npi since we dont get id from document details api
                            props.state.view_physician &&
                            props.state.edit_physician === false &&
                            ((selectedPhysician !== undefined &&
                                selectedPhysician.id !== undefined) ||
                                (props.selectedPhysician !== undefined &&
                                    props.selectedPhysician.npi !==
                                        undefined)) ? (
                                <>
                                    {props.calingFrom === 'search' &&
                                    props.toggleRecentDoc === false ? (
                                        <div className='save_cancel'>
                                            <Button
                                                variant='primary'
                                                className='success'
                                                onClick={editPhysician}
                                                title='Edit Physician'
                                            >
                                                Edit Physician
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            {props.calingFrom !== 'search' && (
                                                <div className='plus'>
                                                    <span
                                                        className='edit_btn'
                                                        onClick={editPhysician}
                                                    >
                                                        <i className='fas fa-pencil-alt' />
                                                    </span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : null
                        }
                        {edit_button === true &&
                        props.calingFrom !== 'search' ? (
                            <div
                                className='plus'
                                id='submitEditPhysician'
                                onClick={
                                    runFunction === true
                                        ? submitEditPhysician
                                        : null
                                }
                            >
                                {/* <OverlayTrigger
									placement={'top'}
									overlay={
										<Tooltip
											id={`tooltip`}
											open={true}
											style={{
												backgroundColor: 'rgb(0, 35, 58)',
												color: 'white',
												marginBottom: '20px',
												display:'block !important'
											}}
										>
											<div
												style={{
													backgroundColor: 'rgb(0, 35, 58)',
													color: 'white',
												}}
											>
												Click here to save Details
											</div>
										</Tooltip>
									}
								>
									<span><i className='fas fa-check-square'></i> </span>
								</OverlayTrigger>
							*/}
                                <span>
                                    <i className='fas fa-check-square' />{' '}
                                </span>
                            </div>
                        ) : edit_button === true &&
                          props.calingFrom === 'search' &&
                          props.toggleRecentDoc === false ? (
                            <>
                                <div className='save_cancel'>
                                    {props.state.selectedPhysician
                                        .isModifiable && (
                                        <Button
                                            className='clear cancel-bg-btn'
                                            title='Delete'
                                            onClick={deletePhysician}
                                        >
                                            <i
                                                title='Delete'
                                                className='fas fa-trash-alt'
                                            />
                                        </Button>
                                    )}
                                    <Button
                                        variant='secondary'
                                        className='btn-sav'
                                        onClick={close_edit2}
                                        title='Cancel'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='success'
                                        className='btn-sav'
                                        onClick={submitEditPhysician}
                                        title='Save'
                                    >
                                        Save
                                    </Button>
                                </div>
                            </>
                        ) : create_button === true &&
                          props.calingFrom !== 'search' ? (
                            <div
                                className='plus'
                                id='submitEditPhysician'
                                onClick={
                                    newFunction === true
                                        ? newPhysicianSubmit
                                        : null
                                }
                            >
                                <span>
                                    <i className='fas fa-check-square' />
                                    {/* <span>Save Physician</span> */}
                                    {/* need to check */}
                                </span>
                            </div>
                        ) : (create_button === true ||
                              props.create_button === true) &&
                          props.calingFrom === 'search' ? (
                            <>
                                <div className='save_cancel'>
                                    <Button
                                        variant='secondary'
                                        className='btn-sav'
                                        onClick={close_edit}
                                        title='Cancel'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='success'
                                        className='btn-sav'
                                        onClick={newPhysicianSubmit}
                                        title='Save'
                                    >
                                        Save
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {openPhysicianDelete ? (
                <DeleteModal
                    handlePhysicianDeleteOperation={
                        handlePhysicianDeleteOperation
                    }
                    handleCloseOperation={handleCloseOperation}
                    openDelete
                />
            ) : null}
        </>
    );
};
export default ByPhysician;
