/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { addDocumentNotes, addGlobalDocumentNotes } from '../../api/api';
import {
    showSuccessToast,
    showErrorToast,
    stringEmptyOrUndefined,
    getNotes,
} from '../../../components/commonfunction';
import { inboxPage } from '../../_redux/actions/inboxActions';
import { deletedPage } from '../../_redux/actions/searchActions';
import { useLocation } from 'react-router-dom';

export function Notes(props) {
    const dispatch = useDispatch();

    const location = useLocation();

    const [openNotes, setOpenNotes] = useState(false);
    const [notesValue, setNotesValue] = useState('');
    const [notesSubmitted, setNotesSubmitted] = useState(0);
    const [notesTypeId, setNotesTypeId] = useState('');
    const [notesFieldId, setNotesFieldId] = useState('');
    const [notesName, setNotesName] = useState('');
    const [viewNoteValue, setViewNoteValue] = useState([]);
    const [viewAllOpen, setViewAllOpen] = useState(false);
    const reduxStore = useSelector((state) => state);
    let noteTypes = [];
    var processId = '';
    var statusId = '';
    const { configData } = reduxStore.configReducerPersist;

    if (reduxStore.inbox !== undefined && reduxStore.inbox.processId !== '') {
        processId = reduxStore.inbox.processId;
        statusId = reduxStore.inbox.statusId;
        noteTypes = getNotes(configData, processId, statusId);
    } else if (
        reduxStore.search.deletedPage !== undefined &&
        reduxStore.search.deletedPage.processId !== ''
    ) {
        processId = reduxStore.search.deletedPage.processId;
        statusId = reduxStore.search.deletedPage.statusId;
        if (processId !== '' && statusId !== '') {
            noteTypes = getNotes(configData, processId, statusId);
        } else if (
            stringEmptyOrUndefined(reduxStore.configReducerPersist) &&
            stringEmptyOrUndefined(
                reduxStore.configReducerPersist.configData.noteTypes
            )
        ) {
            noteTypes = reduxStore.configReducerPersist.configData.noteTypes;
        }
    } else {
        if (
            stringEmptyOrUndefined(reduxStore.configReducerPersist) &&
            stringEmptyOrUndefined(
                reduxStore.configReducerPersist.configData.noteTypes
            )
        ) {
            noteTypes = reduxStore.configReducerPersist.configData.noteTypes;
        }
    }

    const redux_store = reduxStore.inbox;
    const reduxDeleteStore = reduxStore.search.deletedPage;

    useEffect(() => {
        setOpenNotes(props.openNote);
        setNotesValue('');
        setNotesSubmitted(0);
        setNotesTypeId('');
        setNotesFieldId('');
    }, [viewAllOpen, viewNoteValue, props.openNote]);

    let userdetails = localStorage.getItem('user_details');
    userdetails = JSON.parse(userdetails);
    let userName = '';
    let userDisplayName = '';
    if (userdetails) {
        userName = userdetails.userDisplayName;
        const uname = userName.split(' ');
        if (uname.length > 1) {
            userDisplayName = `${uname[0].charAt(0).toUpperCase()}${uname[1]
                .charAt(0)
                .toUpperCase()}`;
        } else {
            userDisplayName = `${uname[0].charAt(0).toUpperCase()}${uname[0]
                .charAt(1)
                .toUpperCase()}`;
        }
    }

    const handleNotesText = (event) => {
        setNotesValue(event.target.value);
    };

    if (processId === undefined && statusId === undefined) {
        if (
            stringEmptyOrUndefined(reduxStore.configReducerPersist) &&
            stringEmptyOrUndefined(
                reduxStore.configReducerPersist.configData.noteTypes
            )
        ) {
            noteTypes = reduxStore.configReducerPersist.configData.noteTypes;
        }
    }

    const handleNotesDDL = (event, fieldId, noteId, name) => {
        setNotesTypeId(noteId);
        setNotesFieldId(fieldId);
        setNotesName(name);
    };

    const handleNotesSubmit = (docIds) => {
        setViewAllOpen(false);
        if (noteTypes.length === 0) {
            showErrorToast("Can't able to add Note");
            setNotesSubmitted(3);
            return;
        }
        const notesField = {};
        if (notesFieldId === '') {
            // default
            notesField.noteFieldId = noteTypes[0].fieldId;
            notesField.noteTypeId = noteTypes[0].noteId;
            notesField.noteValue = notesValue;
        } else {
            notesField.noteFieldId = notesFieldId;
            notesField.noteTypeId = notesTypeId;
            notesField.noteValue = notesValue;
        }
        let docIdData = [];
        if (Array.isArray(docIds)) {
            docIdData = docIds;
        } else {
            docIdData.push(docIds);
        }
        setNotesSubmitted(2);

        if (processId === undefined && statusId === undefined) {
            addGlobalDocumentNotes(docIdData, notesField)
                .then((data) => {
                    if (data.status === 400) {
                        return data.json();
                    }
                })
                .then((data) => {
                    if (data !== undefined) {
                        setNotesSubmitted(0);
                        setNotesValue('');
                        if (data.message !== undefined) {
                            const { message } = data;
                            let error = message.replace(/[0-9]/g, '');
                            error = error.replace(/:/g, '');
                            showErrorToast(error);
                        }
                    } else {
                        if (props.handleMultiSelectClose !== undefined) {
                            props.handleMultiSelectClose();
                        }
                        //  let tagIndexPosition = '';
                        if (
                            props.viewAllOpen !== undefined &&
                            props.viewAllOpen === true
                        ) {
                            const userName = JSON.parse(
                                localStorage.getItem('user_details')
                            ).userDisplayName;
                            const userNameCaps = JSON.parse(
                                localStorage.getItem('user_details')
                            ).userName;
                            const viewNoteValueData = viewNoteValue;
                            viewNoteValueData.push({
                                value: notesValue,
                                name: notesField.noteTypeId,
                                username: userName,
                                usernamecaps: userNameCaps,
                            });
                            setViewAllOpen(true);
                            setViewNoteValue(viewNoteValueData);
                        }
                        setNotesSubmitted(3);

                        if (props.refreshViewAll !== undefined) {
                            props.refreshViewAll();
                        }
                        if (props.updateState !== undefined) {
                            props.updateState();
                        }
                        if (props.refreshTable !== undefined) {
                            props.refreshTable();
                        }

                        setNotesValue('');
                        setNotesSubmitted(0);

                        if (!location.pathname.includes('search')) {
                            dispatch(
                                inboxPage({
                                    newNotes: 1,
                                })
                            );
                        } else {
                            dispatch(
                                deletedPage({
                                    newNotes: 1,
                                })
                            );
                        }

                        showSuccessToast('Note added successfully.');
                    }
                    if (props.openNote !== undefined) {
                        setOpenNotes(false);
                        props.handleReset();
                    }
                });
        } else {
            addDocumentNotes(docIdData, notesField, processId, statusId)
                .then((response) => {
                    if (response.status === 400) {
                        return response.json();
                    }
                })
                .then((data) => {
                    if (data !== undefined && data !== '') {
                        if (Object.keys(docIdData).length === 1) {
                            showErrorToast('Document is Locked');
                        } else if (Object.keys(docIdData).length > 1) {
                            showErrorToast('Documents are Locked');
                        }
                        setNotesSubmitted(0);
                        setNotesValue('');

                        // const { message } = data;
                        // let error = message.replace(/[0-9]/g, '');
                        // error = error.replace(/:/g, '');

                        //}
                    } else {
                        let tagIndexPosition = '';
                        if (!location.pathname.includes('search')) {
                            if (
                                redux_store.inbox !== undefined &&
                                redux_store.inbox.dataTable !== undefined
                            ) {
                                redux_store.inbox.dataTable.headerColumns.map(
                                    (data, index) => {
                                        if (
                                            data.name.toLowerCase() ===
                                            'last note'
                                        ) {
                                            tagIndexPosition = index;
                                        }
                                    }
                                );
                                redux_store.inbox.dataTable.dataRows.map(
                                    (data, index) => {
                                        docIdData.map((id) => {
                                            if (data.id === id) {
                                                redux_store.inbox.dataTable.dataRows[
                                                    index
                                                ].values[tagIndexPosition] =
                                                    notesValue;
                                            }
                                        });
                                    }
                                );
                            }
                        } else {
                            let tagIndexPosition = '';
                            reduxDeleteStore.deleteDataGrid.headerColumns.map(
                                (data, index) => {
                                    if (
                                        data.name.toLowerCase() === 'last note'
                                    ) {
                                        tagIndexPosition = index;
                                    }
                                }
                            );
                            reduxDeleteStore.deleteDataGrid.dataRows.map(
                                (data, index) => {
                                    docIdData.map((id) => {
                                        if (data.id === id) {
                                            reduxDeleteStore.deleteDataGrid.dataRows[
                                                index
                                            ].values[tagIndexPosition] =
                                                notesValue;
                                        }
                                    });
                                }
                            );
                        }
                        if (
                            props.viewAllOpen !== undefined &&
                            props.viewAllOpen === true
                        ) {
                            const userName = JSON.parse(
                                localStorage.getItem('user_details')
                            ).userDisplayName;
                            const userNameCaps = JSON.parse(
                                localStorage.getItem('user_details')
                            ).userName;
                            const viewNoteValueData = viewNoteValue;
                            viewNoteValueData.push({
                                value: notesValue,
                                name: notesField.noteTypeId,
                                username: userName,
                                usernamecaps: userNameCaps,
                            });
                            setViewAllOpen(true);
                            setViewNoteValue(viewNoteValueData);
                        }

                        if (props.handleMultiSelectClose !== undefined) {
                            props.handleMultiSelectClose();
                        }
                        setNotesSubmitted(3);

                        if (props.refreshViewAll !== undefined) {
                            props.refreshViewAll();
                        }
                        if (props.updateState !== undefined) {
                            props.updateState();
                        }
                        if (props.refreshTable !== undefined) {
                            props.refreshTable();
                        }

                        setNotesValue('');
                        setNotesSubmitted(0);

                        if (!location.pathname.includes('search')) {
                            dispatch(
                                inboxPage({
                                    newNotes: 1,
                                })
                            );
                        } else {
                            dispatch(
                                deletedPage({
                                    newNotes: 1,
                                })
                            );
                        }

                        showSuccessToast('Note added successfully.');
                    }
                    if (props.openNote !== undefined) {
                        setOpenNotes(false);
                        props.handleReset();
                    }
                });
        }
    };

    const handleCloseAction = () => {
        setOpenNotes(false);
        if (props.handleReset !== undefined) {
            props.handleReset();
        }
    };
    const btnGroupDrop = `btnGroupDrop1_${Math.random()}`;
    const postLoading = `post_loading_${Math.random()}`;
    const posted = `posted_${Math.random()}`;
    const checkNameUndefine = (data) => {
        if (data[0] !== undefined) {
            return data[0].name;
        }
        return '';
    };

    if (noteTypes.length === 0) {
        return <div />;
    }

    if (props.type !== undefined && props.type === 'normal') {
        return (
            <>
                {/* viewAllOpen ? (
                    <div
                        style={
                            viewNoteValue.length > 1
                                ? { height: '150px', overflowY: 'scroll' }
                                : null
                        }
                    >
                        {viewNoteValue.map((noteData, index) => {
                            let userName = '';
                            let userDisplayName = '';
                            if (noteData.username) {
                                userName = noteData.username;
                                const uname = userName.split(' ');
                                if (uname.length > 1) {
                                    userDisplayName = `${uname[0]
                                        .charAt(0)
                                        .toUpperCase()}${uname[1]
                                        .charAt(0)
                                        .toUpperCase()}`;
                                } else {
                                    userDisplayName = `${uname[0]
                                        .charAt(0)
                                        .toUpperCase()}${uname[0]
                                        .charAt(1)
                                        .toUpperCase()}`;
                                }
                            }
                            return (
                                <div
                                    className={
                                        viewNoteValue.length > 1
                                            ? 'post_data expect_more_items mb-5 pt-8 pb-1'
                                            : 'post_data mb-5 pt-8 pb-1'
                                    }
                                    key={index}
                                >
                                    <div className='d-flex'>
                                        <button
                                            type='button'
                                            className='username mr-3'
                                            title={noteData.username}
                                        >
                                            {userDisplayName}
                                        </button>
                                        <div className=''>
                                            <h5 className='mr-5'>
                                                {noteData.usernamecaps}
                                            </h5>
                                            <span>a few seconds ago</span>
                                            {noteData.name === 1 ? (
                                                <span className='note-type-tag'>
                                                    Follow-up Note
                                                </span>
                                            ) : null}
                                            <p>{noteData.value}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null */}

                <div className='notes pt-5 d-flex pb-2'>
                    <div className='d-flex w-100'>
                        <button
                            type='button'
                            className='username mr-3'
                            title={userName}
                        >
                            {userDisplayName}
                        </button>
                        <input
                            className='mr-3'
                            type='text'
                            placeholder='Type your note here'
                            disabled={
                                props.callingFrom === 'deleted_document' ||
                                props.isLocked === true
                            }
                            onChange={(event) => handleNotesText(event)}
                            value={notesValue}
                            maxLength={250}
                        />
                    </div>
                    <div className='notes-btn d-flex'>
                        {notesSubmitted === 2 ? (
                            <div
                                className='btn-group posted_btn load_img'
                                role='group'
                            >
                                <button
                                    type='button'
                                    className='post_loading'
                                    id={postLoading}
                                >
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                </button>
                            </div>
                        ) : notesSubmitted === 3 ? (
                            <div className='btn-group posted_btn ' role='group'>
                                <button
                                    type='button'
                                    className='posted d-flex'
                                    id={posted}
                                >
                                    <i className='fa fa-check' />
                                    Posted!
                                </button>
                            </div>
                        ) : (
                            <div className='d-flex'>
                                <div className='btn-group' role='group'>
                                    {/* <select id="btnGroupDrop1" type="button" className="btn success font-weight-bold dropdown-toggle" disabled={(notesValue === '')} onChange={(event) => handleNotesDDL(event)}>
												{(localStorage.getItem('noteTypes') && localStorage.getItem('noteTypes').length > 0) ?
													JSON.parse(localStorage.getItem('noteTypes')).map((data, i) => <option key={i} className="dropdown-item" value={'{"fieldId":' + data.fieldId + ',"noteId":' + data.noteId + '}'}>{data.name}</option>) : null}
										</select> */}

                                    <button
                                        title='Post Note'
                                        id='btnGroupDrop1'
                                        type='button'
                                        className={
                                            noteTypes.length > 1
                                                ? 'btn btn-ddl success font-weight-bold dropdown-toggle'
                                                : 'btn no-btn-ddl success font-weight-bold dropdown-toggle'
                                        }
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                        disabled={notesValue === ''}
                                    >
                                        Post{' '}
                                        {notesName !== ''
                                            ? notesName
                                            : checkNameUndefine(noteTypes)}
                                        {noteTypes.length > 1 ? (
                                            <i className='fa-light fa-angle-down' />
                                        ) : null}
                                    </button>
                                    {noteTypes.length > 1 ? (
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                        >
                                            {noteTypes.length > 0
                                                ? noteTypes.map((data, i) => (
                                                      <a
                                                          key={i}
                                                          className='dropdown-item'
                                                          /* data-fieldId={
                                                              data.fieldId
                                                          }
                                                          data-noteId={
                                                              data.noteId
                                                          }
                                                          data-noteName={
                                                              data.name
                                                          } */
                                                          onClick={(event) =>
                                                              handleNotesDDL(
                                                                  event,
                                                                  data.fieldId,
                                                                  data.noteId,
                                                                  data.name
                                                              )
                                                          }
                                                      >
                                                          Post {data.name}
                                                      </a>
                                                  ))
                                                : null}
                                        </div>
                                    ) : null}
                                </div>
                                <div className='checkbox-note' title='Save'>
                                    <label className='checkbox checkbox-success'>
                                        <input
                                            type='checkbox'
                                            disabled={notesValue === ''}
                                            name='Checkboxes5'
                                            checked='checked'
                                            onClick={() =>
                                                handleNotesSubmit(props.docId)
                                            }
                                            readOnly
                                        />
                                        <span
                                            className={
                                                notesValue === ''
                                                    ? 'disable'
                                                    : ''
                                            }
                                        >
                                            <i className='fa-light fa-check' />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <Modal
                // size="sm"
                show={openNotes}
                onHide={() => handleCloseAction()}
                animation={false}
                size='lg'
                className='notes-popup'
                aria-labelledby='contained-modal-title-vcenter'
                // dialogClassName="modal-90w"
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                    onClick={() => handleCloseAction()}
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>
                            <i className='fa-solid fa-sticky-note mr-3 inbox' />
                            Notes
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={() => handleCloseAction()}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='notes d-flex'>
                    <div className='d-flex w-100'>
                        <button
                            type='button'
                            className='username mr-3'
                            title={userName}
                        >
                            {userDisplayName}
                        </button>
                        <input
                            className='mr-3'
                            type='text'
                            placeholder='Type your note here'
                            onChange={(event) => handleNotesText(event)}
                            value={notesValue}
                            maxLength={250}
                        />
                        {/* <input className="mr-3" type="text" placeholder="Type your note here" onChange={(event) => handleNotesText(event)} value={notesValue} /> */}
                    </div>
                    <div
                        className='notes-btn d-flex'
                        key={`${Math.random()}_sub3`}
                    >
                        {notesSubmitted === 2 ? (
                            <div
                                className='btn-group posted_btn load_img'
                                role='group'
                            >
                                <button
                                    type='button'
                                    className='post_loading'
                                    id={postLoading}
                                >
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                </button>
                            </div>
                        ) : notesSubmitted === 3 ? (
                            <div className='btn-group posted_btn ' role='group'>
                                <button
                                    type='button'
                                    className='posted d-flex'
                                    id={posted}
                                >
                                    <i className='fa fa-check' />
                                    Posted!
                                </button>
                            </div>
                        ) : (
                            <div className='d-flex'>
                                <div className='btn-group' role='group'>
                                    {/* <button title="Post Note" id={btnGroupDrop} type="button" className="btn success font-weight-bold dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={(notesValue === '')}> */}
                                    <div className='btn-group' role='group'>
                                        <button
                                            title='Post Note'
                                            id='btnGroupDrop1'
                                            type='button'
                                            className={
                                                noteTypes.length > 1
                                                    ? 'btn btn-ddl success font-weight-bold dropdown-toggle'
                                                    : 'btn no-btn-ddl success font-weight-bold dropdown-toggle'
                                            }
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                            disabled={notesValue === ''}
                                        >
                                            Post{' '}
                                            {notesName !== ''
                                                ? notesName
                                                : checkNameUndefine(noteTypes)}
                                            {noteTypes.length > 1 ? (
                                                <i className='fa-light fa-angle-down' />
                                            ) : null}
                                        </button>
                                        {noteTypes.length > 1 ? (
                                            <div
                                                className='dropdown-menu'
                                                aria-labelledby='btnGroupDrop1'
                                            >
                                                {noteTypes.length > 0
                                                    ? noteTypes.map(
                                                          (data, i) => (
                                                              <a
                                                                  key={i}
                                                                  className='dropdown-item'
                                                                  /* data-fieldId={
                                                                      data.fieldId
                                                                  }
                                                                  data-noteId={
                                                                      data.noteId
                                                                  }
                                                                  data-noteName={
                                                                      data.name
                                                                  } */
                                                                  onClick={(
                                                                      event
                                                                  ) =>
                                                                      handleNotesDDL(
                                                                          event,
                                                                          data.fieldId,
                                                                          data.noteId,
                                                                          data.name
                                                                      )
                                                                  }
                                                              >
                                                                  Post{' '}
                                                                  {data.name}
                                                              </a>
                                                          )
                                                      )
                                                    : null}
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* </button> */}
                                    <div
                                        className='dropdown-menu'
                                        aria-labelledby={btnGroupDrop}
                                    >
                                        {noteTypes.length > 0
                                            ? noteTypes.map((data, i) => (
                                                  <a
                                                      className='dropdown-item'
                                                      key={i}
                                                      title={data.name}
                                                      onClick={(event) =>
                                                          handleNotesDDL(
                                                              event,
                                                              data.fieldId,
                                                              data.noteId,
                                                              data.name
                                                          )
                                                      }
                                                  >
                                                      {data.name}
                                                  </a>
                                              ))
                                            : null}
                                    </div>
                                </div>
                                <div className='checkbox-note' title='Save'>
                                    <label className='checkbox checkbox-success'>
                                        <input
                                            type='checkbox'
                                            disabled={notesValue === ''}
                                            name='Checkboxes5'
                                            checked='checked'
                                            onClick={() =>
                                                handleNotesSubmit(props.docId)
                                            }
                                            readOnly
                                        />
                                        <span
                                            className={
                                                notesValue === ''
                                                    ? 'disable'
                                                    : ''
                                            }
                                        >
                                            <i className='fa-light fa-check' />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-block border-0' />
            </Modal>
        </>
    );
}
