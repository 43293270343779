import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { updateUserDetails } from '../api/api';
import {
    normalSuccessToast,
    showErrorToast,
} from '../../components/commonfunction';

const ActivateVsDeactiveModal = (props) => {
    const [btnLoader, setBtnLoader] = useState(false);
    const postLoading = `post_loading_${Math.random()}`;
    const handleActivateUser = () => {
        setBtnLoader(true);
        const userId = props.selectedUserDetails.userid;
        const fields = {};
        fields.userId = props.selectedUserDetails.userid;
        // fields.username = this.state.userName;
        // fields.email = this.state.userEmail;
        // fields.name = this.state.userDisplayName;
        fields.disabled = false;
        let status;
        updateUserDetails(userId, fields)
            .then((response) => {
                status = response.status;
                if (status === 204) {
                    normalSuccessToast(
                        `The User ${props.selectedUserDetails.username}  is successfully Re-activated.`
                    );
                    setBtnLoader(false);

                    props.updateState({
                        activateVsDeactiveModal: false,
                    });
                    setTimeout(() => props.refreshTable(), 30);
                }
            })
            .then((data) => {
                if (status === 400 || status === 404) {
                    showErrorToast('Unable to Re-activate the user');
                    setBtnLoader(false);
                }
            });
    };
    const handleDeactivateUser = () => {
        setBtnLoader(true);
        const userId = props.selectedUserDetails.userid;
        const fields = {};
        fields.userId = props.selectedUserDetails.userid;
        // fields.username = this.state.userName;
        // fields.email = this.state.userEmail;
        // fields.name = this.state.userDisplayName;
        fields.disabled = true;
        let status;
        updateUserDetails(userId, fields)
            .then((response) => {
                status = response.status;
                if (status === 204) {
                    props.updateState({
                        loader: true,
                    });
                    setBtnLoader(false);
                    normalSuccessToast(
                        `The User ${props.selectedUserDetails.username}  is successfully deactivated`
                    );
                    props.updateState({
                        activateVsDeactiveModal: false,
                    });
                    setTimeout(() => props.refreshTable(), 30);
                }
            })
            .then((data) => {
                if (status === 400 || status === 404) {
                    showErrorToast('Unable to deactivate the user');
                    setBtnLoader(false);
                }
            });
        // props.refreshTable();
    };
    const handleCloseOperation = () => {
        props.updateState({
            activateVsDeactiveModal: false,
        });
        setBtnLoader(false);
    };

    return (
        <>
            <Modal
                show={props.activateVsDeactiveModal}
                animation={false}
                onHide={handleCloseOperation}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        {props.deactiveUser === true && (
                            <div className=''>Deactivate User</div>
                        )}
                        {props.activeUser === true && (
                            <div className=''>Re-activate User</div>
                        )}
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleCloseOperation}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='deletes'>
                    {props.deactiveUser === true && (
                        <h6 className='info_msg'>
                            You are deactivating the user{' '}
                            <b>{props.selectedUserDetails.username} </b>
                            {props.selectedUserDetails.email !== ''
                                ? '(' + props.selectedUserDetails.email + ')'
                                : ''}
                            .<br />
                            They won't be able to access the platform anymore.
                            Are you sure you want to proceed?
                        </h6>
                    )}
                    {props.activeUser === true && (
                        <h6 className='info_msg'>
                            You are re-activating the user{' '}
                            <b>{props.selectedUserDetails.username} </b>
                            {props.selectedUserDetails.email !== ''
                                ? '(' + props.selectedUserDetails.email + ')'
                                : ''}
                            .<br />
                            They will be able to access the platform again. Are
                            you sure you want to proceed?
                        </h6>
                    )}
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                variant='secondary'
                                onClick={handleCloseOperation}
                                title='Cancel'
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            {props.activeUser === true && (
                                <>
                                    {btnLoader === true ? (
                                        <Button
                                            type='button'
                                            variant='success'
                                            className='post_loading'
                                            id={postLoading}
                                        >
                                            <span
                                                className='spinner-border spinner-border-sm'
                                                role='status'
                                                aria-hidden='true'
                                            />
                                        </Button>
                                    ) : (
                                        <Button
                                            variant='success'
                                            onClick={() => {
                                                handleActivateUser();
                                            }}
                                            title='Save Changes'
                                        >
                                            Save Changes
                                        </Button>
                                    )}
                                </>
                            )}
                            {props.deactiveUser === true && (
                                <>
                                    {btnLoader === true ? (
                                        <Button
                                            type='button'
                                            variant='danger'
                                            className='post_loading'
                                            id={postLoading}
                                        >
                                            <span
                                                className='spinner-border spinner-border-sm'
                                                role='status'
                                                aria-hidden='true'
                                            />
                                        </Button>
                                    ) : (
                                        <Button
                                            variant='danger'
                                            onClick={() => {
                                                handleDeactivateUser();
                                            }}
                                            title='Save Changes'
                                        >
                                            Save Changes
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ActivateVsDeactiveModal;
