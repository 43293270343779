/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { addGlobalDocumentNotes } from '../../pages/api/api';
import {
    showSuccessToast,
    showErrorToast,
    stringEmptyOrUndefined,
} from '../commonfunction';
import { deletedPage } from '../../pages/_redux/actions/searchActions';

export function Notes(props) {
    const dispatch = useDispatch();
    const [openNotes, setOpenNotes] = useState(false);
    const [notesValue, setNotesValue] = useState('');
    const [notesSubmitted, setNotesSubmitted] = useState(0);
    const [notesTypeId, setNotesTypeId] = useState('');
    const [notesFieldId, setNotesFieldId] = useState('');
    const [notesName, setNotesName] = useState('');
    const [viewNoteValue, setViewNoteValue] = useState([]);
    const [viewAllOpen, setViewAllOpen] = useState(false);
    const reduxStore = useSelector((state) => state);

    let noteTypes = [];

    const reduxDeleteStore = reduxStore.search.deletedPage;

    if (
        stringEmptyOrUndefined(reduxStore.configReducerPersist) &&
        stringEmptyOrUndefined(
            reduxStore.configReducerPersist.configData.globalNoteTypes
        )
    ) {
        noteTypes = reduxStore.configReducerPersist.configData.globalNoteTypes;
    }

    useEffect(() => {
        setOpenNotes(props.openNote);
        setNotesValue('');
        setNotesSubmitted(0);
        setNotesTypeId('');
        setNotesFieldId('');
    }, [viewAllOpen, viewNoteValue, props.openNote]);

    let userdetails = localStorage.getItem('user_details');
    userdetails = JSON.parse(userdetails);
    let userName = '';
    let userDisplayName = '';
    if (userdetails) {
        userName = userdetails.userDisplayName;
        const uname = userName.split(' ');
        if (uname.length > 1) {
            userDisplayName = `${uname[0].charAt(0).toUpperCase()}${uname[1]
                .charAt(0)
                .toUpperCase()}`;
        } else {
            userDisplayName = `${uname[0].charAt(0).toUpperCase()}${uname[0]
                .charAt(1)
                .toUpperCase()}`;
        }
    }

    const handleNotesText = (event) => {
        setNotesValue(event.target.value);
    };

    const handleNotesDDL = (event, fieldId, noteId, name) => {
        setNotesTypeId(noteId);
        setNotesFieldId(fieldId);
        setNotesName(name);
    };

    const handleNotesSubmit = (docIds) => {
        console.log('handleNotesSubmit:', docIds);
        setViewAllOpen(false);
        if (noteTypes.length === 0) {
            showErrorToast("Can't able to add Note");
            setNotesSubmitted(0);
            return;
        }
        const notesField = {};
        if (notesFieldId === '') {
            notesField.noteFieldId = noteTypes[0].fieldId;
            notesField.noteTypeId = noteTypes[0].noteId;
            notesField.noteValue = notesValue;
        } else {
            notesField.noteFieldId = notesFieldId;
            notesField.noteTypeId = notesTypeId;
            notesField.noteValue = notesValue;
        }
        let docIdData = [];
        if (Array.isArray(docIds)) {
            docIdData = docIds;
        } else {
            docIdData.push(docIds);
        }
        setNotesSubmitted(2);

        addGlobalDocumentNotes(docIdData, notesField)
            .then((data) => {
                if (data.status === 400 || data.status === 403) {
                    return data.json();
                }
                if (data.status === 404) {
                    showErrorToast('404 not Found');
                    return true;
                }
            })
            .then((data) => {
                if (data !== undefined) {
                    setNotesSubmitted(0);
                    setNotesValue('');
                    if (data.message !== undefined) {
                        const { message } = data;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');
                        showErrorToast(error);
                    }
                } else {
                    let tagIndexPosition = '';
                    if (
                        reduxDeleteStore.deleteDataGrid &&
                        reduxDeleteStore.deleteDataGrid.headerColumns
                    ) {
                        reduxDeleteStore.deleteDataGrid.headerColumns.map(
                            (data, index) => {
                                if (data.name.toLowerCase() === 'last note') {
                                    tagIndexPosition = index;
                                }
                            }
                        );
                    }
                    if (
                        reduxDeleteStore.deleteDataGrid &&
                        reduxDeleteStore.deleteDataGrid.dataRows
                    ) {
                        reduxDeleteStore.deleteDataGrid.dataRows.map(
                            (data, index) => {
                                docIdData.map((id) => {
                                    if (data.id === id) {
                                        reduxDeleteStore.deleteDataGrid.dataRows[
                                            index
                                        ].values[tagIndexPosition] = notesValue;
                                    }
                                });
                            }
                        );
                    }

                    if (
                        props.viewAllOpen !== undefined &&
                        props.viewAllOpen === true
                    ) {
                        const userName = JSON.parse(
                            localStorage.getItem('user_details')
                        ).userDisplayName;
                        const userNameCaps = JSON.parse(
                            localStorage.getItem('user_details')
                        ).userName;
                        const viewNoteValueData = viewNoteValue;
                        viewNoteValueData.push({
                            value: notesValue,
                            name: notesField.noteTypeId,
                            username: userName,
                            usernamecaps: userNameCaps,
                        });
                        setViewAllOpen(true);
                        setViewNoteValue(viewNoteValueData);
                    }
                    setNotesSubmitted(3);

                    if (props.refreshViewAll !== undefined) {
                        props.refreshViewAll();
                    }
                    if (props.updateState !== undefined) {
                        props.updateState();
                    }
                    if (props.refreshTable !== undefined) {
                        props.refreshTable();
                    }
                    if (props.updateStateNotes) {
                        props.updateStateNotes({
                            selectedRows: new Set(),
                        });
                    }

                    setNotesValue('');
                    setNotesSubmitted(0);

                    dispatch(
                        deletedPage({
                            newNotes: 1,
                        })
                    );
                    showSuccessToast('Note added successfully.');
                }
                if (props.openNote !== undefined) {
                    setOpenNotes(false);
                    props.handleReset();
                }
            });
    };

    const handleCloseAction = () => {
        setOpenNotes(false);
        if (props.handleReset !== undefined) {
            props.handleReset();
        }
    };
    const btnGroupDrop = `btnGroupDrop1_${Math.random()}`;
    const postLoading = `post_loading_${Math.random()}`;
    const posted = `posted_${Math.random()}`;
    const checkNameUndefine = (data) => {
        if (data[0] !== undefined) {
            return data[0].name;
        }
        return '';
    };

    if (props.type !== undefined && props.type === 'normal') {
        return (
            <>
                <div className='notes pt-5 d-flex pb-2'>
                    <div className='d-flex w-100'>
                        <button
                            type='button'
                            className='username mr-3'
                            title={userName}
                        >
                            {userDisplayName}
                        </button>
                        <input
                            className='mr-3'
                            type='text'
                            placeholder='Type your note here'
                            onChange={(event) => handleNotesText(event)}
                            value={notesValue}
                            maxLength={250}
                        />
                    </div>
                    <div className='notes-btn d-flex'>
                        {notesSubmitted === 2 ? (
                            <div
                                className='btn-group posted_btn load_img'
                                role='group'
                            >
                                <button
                                    type='button'
                                    className='post_loading'
                                    id={postLoading}
                                >
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                </button>
                            </div>
                        ) : notesSubmitted === 3 ? (
                            <div className='btn-group posted_btn ' role='group'>
                                <button
                                    type='button'
                                    className='posted d-flex'
                                    id={posted}
                                >
                                    <i className='fa fa-check' />
                                    Posted!
                                </button>
                            </div>
                        ) : (
                            <div className='d-flex'>
                                <div className='btn-group' role='group'>
                                    <button
                                        title='Post Note'
                                        id='btnGroupDrop1'
                                        type='button'
                                        className={
                                            noteTypes.length > 1
                                                ? 'btn btn-ddl success font-weight-bold dropdown-toggle'
                                                : 'btn no-btn-ddl success font-weight-bold dropdown-toggle'
                                        }
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                        disabled={notesValue === ''}
                                    >
                                        Post{' '}
                                        {notesName !== ''
                                            ? notesName
                                            : checkNameUndefine(noteTypes)}
                                        {noteTypes.length > 1 ? (
                                            <i className='fa-light fa-angle-down' />
                                        ) : null}
                                    </button>
                                    {noteTypes.length > 1 ? (
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                        >
                                            {noteTypes.length > 0
                                                ? noteTypes.map((data, i) => (
                                                      <a
                                                          key={i}
                                                          className='dropdown-item'
                                                          onClick={(event) =>
                                                              handleNotesDDL(
                                                                  event,
                                                                  data.fieldId,
                                                                  data.noteId,
                                                                  data.name
                                                              )
                                                          }
                                                      >
                                                          Post {data.name}
                                                      </a>
                                                  ))
                                                : null}
                                        </div>
                                    ) : null}
                                </div>
                                <div className='checkbox-note' title='Save'>
                                    <label className='checkbox checkbox-success'>
                                        <input
                                            type='checkbox'
                                            disabled={notesValue === ''}
                                            name='Checkboxes5'
                                            checked='checked'
                                            onClick={() =>
                                                handleNotesSubmit(props.docId)
                                            }
                                            readOnly
                                        />
                                        <span
                                            className={
                                                notesValue === ''
                                                    ? 'disable'
                                                    : ''
                                            }
                                        >
                                            <i className='fa-light fa-check' />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <Modal
                show={openNotes}
                onHide={() => handleCloseAction()}
                animation={false}
                size='lg'
                className='notes-popup'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                    onClick={() => handleCloseAction()}
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>
                            <i className='fa-solid fa-sticky-note mr-3 common' />
                            Notes
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={() => handleCloseAction()}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='notes d-flex'>
                    <div className='d-flex w-100'>
                        <button
                            type='button'
                            className='username mr-3'
                            title={userName}
                        >
                            {userDisplayName}
                        </button>
                        <input
                            className='mr-3'
                            type='text'
                            placeholder='Type your note here'
                            onChange={(event) => handleNotesText(event)}
                            value={notesValue}
                            maxLength={250}
                        />
                    </div>
                    <div
                        className='notes-btn d-flex'
                        key={`${Math.random()}_sub3`}
                    >
                        {notesSubmitted === 2 ? (
                            <div
                                className='btn-group posted_btn load_img'
                                role='group'
                            >
                                <button
                                    type='button'
                                    className='post_loading'
                                    id={postLoading}
                                >
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                </button>
                            </div>
                        ) : notesSubmitted === 3 ? (
                            <div className='btn-group posted_btn ' role='group'>
                                <button
                                    type='button'
                                    className='posted d-flex'
                                    id={posted}
                                >
                                    <i className='fa fa-check' />
                                    Posted!
                                </button>
                            </div>
                        ) : (
                            <div className='d-flex'>
                                <div className='btn-group' role='group'>
                                    <div className='btn-group' role='group'>
                                        <button
                                            title='Post Note'
                                            id='btnGroupDrop1'
                                            type='button'
                                            className={
                                                noteTypes.length > 1
                                                    ? 'btn btn-ddl success font-weight-bold dropdown-toggle'
                                                    : 'btn no-btn-ddl success font-weight-bold dropdown-toggle'
                                            }
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                            disabled={notesValue === ''}
                                        >
                                            Post{' '}
                                            {notesName !== ''
                                                ? notesName
                                                : checkNameUndefine(noteTypes)}
                                            {noteTypes.length > 1 ? (
                                                <i className='fa-light fa-angle-down' />
                                            ) : null}
                                        </button>
                                        {noteTypes.length > 1 ? (
                                            <div
                                                className='dropdown-menu'
                                                aria-labelledby='btnGroupDrop1'
                                            >
                                                {noteTypes.length > 0
                                                    ? noteTypes.map(
                                                          (data, i) => (
                                                              <a
                                                                  key={i}
                                                                  className='dropdown-item'
                                                                  onClick={(
                                                                      event
                                                                  ) =>
                                                                      handleNotesDDL(
                                                                          event,
                                                                          data.fieldId,
                                                                          data.noteId,
                                                                          data.name
                                                                      )
                                                                  }
                                                              >
                                                                  Post{' '}
                                                                  {data.name}
                                                              </a>
                                                          )
                                                      )
                                                    : null}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div
                                        className='dropdown-menu'
                                        aria-labelledby={btnGroupDrop}
                                    >
                                        {noteTypes.length > 0
                                            ? noteTypes.map((data, i) => (
                                                  <a
                                                      className='dropdown-item'
                                                      key={i}
                                                      title={data.name}
                                                      onClick={(event) =>
                                                          handleNotesDDL(
                                                              event,
                                                              data.fieldId,
                                                              data.noteId,
                                                              data.name
                                                          )
                                                      }
                                                  >
                                                      {data.name}
                                                  </a>
                                              ))
                                            : null}
                                    </div>
                                </div>
                                <div className='checkbox-note' title='Save'>
                                    <label className='checkbox checkbox-success'>
                                        <input
                                            type='checkbox'
                                            disabled={notesValue === ''}
                                            name='Checkboxes5'
                                            checked='checked'
                                            onClick={() =>
                                                handleNotesSubmit(props.docId)
                                            }
                                            readOnly
                                        />
                                        <span
                                            className={
                                                notesValue === ''
                                                    ? 'disable'
                                                    : ''
                                            }
                                        >
                                            <i className='fa-light fa-check' />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-block border-0' />
            </Modal>
        </>
    );
}
