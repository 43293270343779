import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import SearchFilter from '../../components/searchFilter/SearchFilter';
import {
    NoRecordFound,
    normalSuccessToast,
    showErrorToast,
} from '../../components/commonfunction';
import DataGrid from 'react-data-grid';
import { useEffect, useState } from 'react';
import Spinner from './components/Spinner';
import AddEntityPage from './AddEntityPage';
import { columnKeysToSearch, columnsToShow } from './models/entityListData';
import EditEntityPage from './EditEntityPage';
import { actionColumn } from './components/dataGrid/HeaderFormatOptions';
import { mapDataRowToEntity } from './utils/mapDataRowToEntity';
import { fetchRecordsetData } from './utils/signatureApi';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { deletePhysician } from '../api/api';
import { mapApiDataToObjectModel } from './utils/mapApiDataToSignature';
import generateColumnsArray from './utils/generateColumnsArray';
import generateRows from './utils/generateRows';
import { Entity } from './models/interfaces';
import DeleteModal from '../../components/DeleteModal';

export function EntityListPage() {
    const [showAddNewEntity, setShowAddNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [originalRows, setOriginalRows] = useState<Entity[]>([]);
    const [dataRows, setDataRows] = useState<Entity[]>([]);
    const [headerColumnsToShow, setHeaderColumnsToShow] = useState<any[]>([]);
    const [headerColumns, setHeaderColumns] = useState<any[]>([]);
    const [selectedEntityId, setSelectedEntityId] = useState('');
    const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>();
    const [physicianRecordSetId, setPhysicianRecordSetId] = useState('');
    const [filterText, setFilterText] = useState('');

    const defaultHeight = window.innerHeight - 280;

    useEffect(() => {
        fetchRecordsetData('All Entities')
            .then((entities) => {
                if (entities) {
                    setPhysicianRecordSetId(entities.data.recordsetId);
                    const { data, headerColumns } = entities;
                    const mappedEntities = mapApiDataToObjectModel(data);
                    const allColumns = generateColumnsArray(headerColumns);
                    const columnsWithMenu = allColumns.concat(
                        actionColumn(handleEditEntity, handleDeleteEntity)
                    );
                    const filteredColumns = columnsToShow(columnsWithMenu);
                    setHeaderColumns(allColumns);
                    setHeaderColumnsToShow(filteredColumns);
                    const rows = generateRows(mappedEntities, allColumns);
                    setDataRows(rows);
                    setOriginalRows(rows);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [physicianRecordSetId, showAddNewEntity, showEditEntity]);

    useEffect(() => {
        const selectedRow = dataRows.find(
            (row: Entity) => row.id.toString() === selectedEntityId.toString()
        );
        setSelectedEntity(selectedRow);
    }, [selectedEntityId, dataRows]);

    useEffect(() => {
        if (!filterText) {
            setDataRows(originalRows);
            return;
        }
        const lowerFilterText = filterText.toLowerCase();
        const filteredRows = originalRows.filter((row) =>
            columnKeysToSearch.some((key) => {
                const value = row[key];
                if (typeof value === 'string' || typeof value === 'number') {
                    return value
                        .toString()
                        .toLowerCase()
                        .includes(lowerFilterText);
                }
                return false;
            })
        );

        setDataRows(filteredRows);
    }, [filterText, originalRows]);
    
    useEffect(() => {
        if (showDeleteModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showDeleteModal]);

    const deleteEntity = async () => {
        try {
            const response = await deletePhysician(
                physicianRecordSetId,
                selectedEntityId
            );
            if (
                !response ||
                response.status === 400 ||
                response.status === 404
            ) {
                showErrorToast('Failed to delete physician');
            } else {
                setDataRows(
                    dataRows.filter((row) => row.id !== selectedEntityId)
                );
                normalSuccessToast('Physician deleted successfully');
            }
        } catch (error) {
            console.log(error);
            showErrorToast('Failed to delete physician');
        }
    };

    const handleAddNewEntity = () => {
        setShowAddNewEntity(true);
        setFilterText('');
    };

    const handleEditEntity = (id: any) => {
        setSelectedEntityId(id);
        setShowEditEntity(true);
        setFilterText('');
    };

    const handleDeleteEntity = (id: any) => {
        setSelectedEntityId(id);
        setShowDeleteModal(true);
    };

    return (
        <>
            {showDeleteModal && (
                <DeleteModal
                    deleteModel={showDeleteModal}
                    setDeleteModel={setShowDeleteModal}
                    onDelete={deleteEntity}
                    title='Delete an Entity'
                    warningText={`You are deleting the Entity ${selectedEntity?.entityfirstname} ${selectedEntity?.entitylastname}. Are you sure you want to proceed?`}
                    actionButtonText='Delete'
                />
            )}
            {showAddNewEntity ? (
                <AddEntityPage
                    setModel={() => setShowAddNewEntity(false)}
                    headerColumns={headerColumns}
                    physicianRecordSetId={physicianRecordSetId}
                />
            ) : showEditEntity ? (
                <EditEntityPage
                    entityData={mapDataRowToEntity(dataRows, selectedEntityId)}
                    setModel={() => setShowEditEntity(false)}
                    headerColumns={headerColumns}
                    physicianRecordSetId={physicianRecordSetId}
                    entityId={selectedEntityId}
                    dataRows={dataRows}
                />
            ) : (
                <div
                    id='right-aside'
                    className='right-aside py-5 d-flex flex-column justify-content-between'
                    style={{
                        minHeight: isMobile
                            ? window.innerHeight - 112
                            : window.innerHeight - 158,
                    }}
                >
                    {loading ? (
                        <div
                            className='d-flex align-items-center justify-content-center'
                            style={{ height: '300px' }}
                        >
                            <Spinner show={true} />
                            <span
                                className='text-muted'
                                style={{ fontSize: '16px', marginLeft: '24px' }}
                            >
                                Loading...
                            </span>
                        </div>
                    ) : (
                        <div className=''>
                            <div className='d-flex justify-content-between mb-5 pl-7 pr-7'>
                                <div
                                    className='d-flex align-items-center'
                                    style={{ float: 'left' }}
                                >
                                    <h3 className='m-0 title-color'>
                                        All Entities ({dataRows.length})
                                    </h3>
                                </div>
                                <div
                                    className='d-flex filter_part'
                                    style={{ maxWidth: 500 }}
                                >
                                    <SearchFilter
                                        inputProps={{
                                            maxLength: 50,
                                            disabled: false,
                                            style: { paddingRight: '34px' },
                                        }}
                                        disabled={false}
                                        value={filterText}
                                        callBack={setFilterText}
                                    />
                                    <Button
                                        variant='success'
                                        onClick={handleAddNewEntity}
                                        title='Add Entity'
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <i className='fa-regular fa-plus'></i>
                                        Add New Entity
                                    </Button>
                                </div>
                            </div>
                            <div className='no-first-col'>
                                <div
                                    className='grid_table'
                                    style={{
                                        borderRight: '50px',
                                        minHeight: '100px',
                                        height: defaultHeight,
                                    }}
                                >
                                    <DndProvider backend={HTML5Backend}>
                                        <DataGrid
                                            columns={headerColumnsToShow}
                                            rows={dataRows}
                                            style={{
                                                borderRight: '50px',
                                                height: '100%',
                                            }}
                                            defaultColumnOptions={{
                                                sortable: true,
                                                resizable: true,
                                            }}
                                            rowHeight={50}
                                            emptyRowsRenderer={NoRecordFound}
                                        />
                                    </DndProvider>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default EntityListPage;

