import React from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import DataGrid from 'react-data-grid';
import TablePagination from '@material-ui/core/TablePagination';
import { getDocumentHistroy, getColorCodes } from '../../api/api';
import Spinner from './Spinner';
import { DocumentHistroyHeader } from './DocumentHistroyHeader';
import {
    formatDate,
    formatHeaderNameWithOutSpace,
    showErrorToast,
} from '../../../components/commonfunction';

const _ = require('lodash');

function NoRecordFound(params) {
    return (
        <>
            {params.showLoader || this.state.displayAnimation ? (
                <div className='react-grid-Empty'>
                    <Spinner show />
                </div>
            ) : (
                <div className='no_result norecordfound'>
                    <span>No Records Found</span>
                </div>
            )}
        </>
    );
}

function formatDateWithTime(date, format = '') {
    //utc time coverstion is not there
    if (date.trim() === '') {
        return null;
    }
    if (format.trim() !== 'html') {
        return formatDate(date, 'MM/DD/YYYY h:mm:ss A');
    }
    return (
        <div
            className='date-display'
            title={`${formatDate(date, 'MM/DD/YYYY')} ${formatDate(
                date,
                'HH:mm:ss'
            )}`}
        >
            <span className='dateonly'>
                <strong>{formatDate(date, 'MM/DD/YYYY')}</strong>
            </span>
            <span className='timeonly'>{formatDate(date, ' HH:mm:ss')}</span>{' '}
        </div>
    );
}

function formatDateOnly(date, format = '') {
    if (date.trim() === '') {
        return null;
    }
    if (format.trim() !== 'html') {
        return formatDate(date, 'MM/DD/YYYY');
    }
    return (
        <div
            className='date-display'
            title={`${formatDate(date, 'MM/DD/YYYY')}`}
        >
            <span className='dateonly'>{formatDate(date, 'MM/DD/YYYY')}</span>
        </div>
    );
}

const noteCellFormatter = (value) => {
    if (value !== '' && value !== undefined) {
        return (
            <div className='last-note-wrap'>
                <div className='apply-wrap' title={value}>
                    <div className='last-note'> {value}</div>
                </div>
            </div>
        );
    }
    return <div />;
};
export class DocumentHistroy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: 'View Histroy',
            documentHistory: {
                header: [],
                rowData: [],
                filteredRowData: [],
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                showLoader: true,
            },
            workflowQueues: {
                header: [],
                rowData: [],
                filteredRowData: [],
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                showLoader: true,
            },
            workflowTransactions: {
                header: [],
                rowData: [],
                filteredRowData: [],
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                showLoader: true,
            },
            currentTab: 'document-histroy',
            refresh: false,
            apiErrorMsg: '',
            displayAnimation: false,
        };
        this.dataGridComponent = this.dataGridComponent.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.filterDataGrid = this.filterDataGrid.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.sortRows = this.sortRows.bind(this);
        this.handleColumn = this.handleColumn.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
        const { docId } = this.props;
        const userColorCode = {};
        const handelHeader = (headerData) => {
            const header = [];
            if (headerData !== undefined) {
                headerData.forEach((data) => {
                    header.push({
                        key: formatHeaderNameWithOutSpace(data.name),
                        name: data.name,
                        colname: data.name,
                        sortable: true,
                        draggable: false,
                        resizable: false,
                        editable: false,
                        // width:200,
                        datatype: data.datatype.toLowerCase().trim(),
                    });
                });
            }
            return header;
        };
        const handleRowData = (header, rowData) => {
            const rowCollection = [];
            if (rowData !== undefined) {
                rowData.forEach((data, index) => {
                    const color_code = getColorCodes();
                    const obj = {};
                    data.values.forEach((row, i) => {
                        if (header[i] !== undefined) {
                            obj.id = index;
                            if (header[i].datatype === 'datetime') {
                                obj[header[i].key] = formatDateWithTime(
                                    row.trim(),
                                    'html'
                                );
                                obj[`${header[i].key}_raw`] = row.trim();
                                obj.logdate_formatted = formatDateWithTime(
                                    row.trim(),
                                    'html'
                                );
                                return false;
                            }
                            if (header[i].datatype === 'date') {
                                obj[header[i].key] = formatDateOnly(
                                    row.trim(),
                                    'html'
                                );
                                obj[`${header[i].key}_raw`] = row.trim();
                                obj.logdate_formatted = formatDateOnly(
                                    row.trim(),
                                    'html'
                                );
                                return false;
                            }
                            if (header[i].key === 'message') {
                                obj[header[i].key] = noteCellFormatter(
                                    row.trim()
                                );
                                obj.message_raw = row.trim();
                            } else if (header[i].key === 'action') {
                                obj[header[i].key] = row.trim();
                                obj.action_raw = row.trim();
                            } else if (header[i].key === 'username') {
                                obj[header[i].key] = row.trim();
                                obj.username_raw = row.trim();
                                if (userColorCode[row.trim()] === undefined) {
                                    userColorCode[row.trim()] =
                                        color_code[
                                            Math.floor(Math.random() * 11)
                                        ];
                                }
                            } else if (header[i].key === 'entryusername') {
                                obj[header[i].key] = row.trim();
                                obj.entryusername_raw = row.trim();
                                if (userColorCode[row.trim()] === undefined) {
                                    userColorCode[row.trim()] =
                                        color_code[
                                            Math.floor(Math.random() * 22)
                                        ];
                                }
                            } else if (header[i].key === 'exitusername') {
                                obj[header[i].key] = row.trim();
                                obj.exitusername_raw = row.trim();
                                if (userColorCode[row.trim()] === undefined) {
                                    userColorCode[row.trim()] =
                                        color_code[
                                            Math.floor(Math.random() * 44)
                                        ];
                                }
                            } else {
                                obj[header[i].key] = row.trim();
                            }
                        }
                    });
                    rowCollection.push(obj);
                });
            }
            return rowCollection;
        };

        const processHistroy = (histroy) => {
            if (histroy.message !== undefined && histroy.message !== '') {
                // Display Api Error
                this.setState({
                    apiErrorMsg: histroy.message,
                    documentHistory: {
                        ...this.state.documentHistory,
                        showLoader: false,
                    },
                    workflowQueues: {
                        ...this.state.workflowQueues,
                        showLoader: false,
                    },
                    workflowTransactions: {
                        ...this.state.workflowTransactions,
                        showLoader: false,
                    },
                });
                showErrorToast(histroy.message);
                return;
            }
            let documentHistory = {};
            let workflowQueues = {};
            let workflowTransactions = {};
            const isSortDesc = (name) => {
                name = formatHeaderNameWithOutSpace(name);
                return name === 'logdate' || name === 'entrydate';
            };
            // eslint-disable-next-line guard-for-in
            for (const property in histroy) {
                let header = handelHeader(histroy[property].headerColumns);
                let rowData = handleRowData(header, histroy[property].dataRows);
                header.forEach((data) => {
                    if (isSortDesc(data.name)) {
                        // Sort DESC for Date Columns
                        const params = this.sortFields(data.key, 'desc');
                        rowData = _.orderBy(
                            rowData,
                            params.sortfn,
                            params.orderBy
                        );
                    }
                });
                let bgColor = '';
                let filteredRowData = [];
                const totalCount = rowData.length;
                rowData = rowData.map((data, index) => {
                    data.id = index;
                    if (data.action !== undefined) {
                        data.action = (
                            <div
                                className='action'
                                title={`${data.action} ${data.message_raw}`}
                            >
                                <span>{data.action}</span>{' '}
                                <span>{data.message_raw}</span>
                            </div>
                        );
                    }
                    if (data.username !== undefined && data.username !== '') {
                        bgColor = userColorCode[data.username];
                        const displayName = data.username.slice(0, 2); // Getting first Two Letter
                        data.username = (
                            <div className='' title={data.username}>
                                <BrowserView>
                                    <span
                                        className='owner-name-list owner-logo username-logo'
                                        style={{ background: bgColor }}
                                    >
                                        {' '}
                                        {displayName}
                                    </span>
                                    {data.username}
                                </BrowserView>
                                <MobileView>
                                    <span
                                        className='owner-name-list owner-logo username-logo'
                                        style={{ background: bgColor }}
                                    >
                                        {' '}
                                        {displayName}
                                    </span>
                                </MobileView>
                            </div>
                        );
                    }
                    if (
                        data.entryusername !== undefined &&
                        data.entryusername !== ''
                    ) {
                        bgColor = userColorCode[data.entryusername];
                        const displayName = data.entryusername.slice(0, 2); // Getting first Two Letter
                        data.entryusername = (
                            <div className='' title={data.entryusername}>
                                <span
                                    className='owner-name-list owner-logo username-logo'
                                    style={{ background: bgColor }}
                                >
                                    {' '}
                                    {displayName}
                                </span>
                                <BrowserView>{data.entryusername}</BrowserView>
                            </div>
                        );
                    }
                    if (
                        data.exitusername !== undefined &&
                        data.exitusername !== ''
                    ) {
                        bgColor = userColorCode[data.exitusername];
                        const displayName = data.exitusername.slice(0, 2); // Getting first Two Letter
                        data.exitusername = (
                            <div className='' title={data.exitusername}>
                                <span
                                    className='owner-name-list owner-logo username-logo'
                                    style={{ background: bgColor }}
                                >
                                    {' '}
                                    {displayName}
                                </span>
                                <BrowserView>{data.exitusername}</BrowserView>
                            </div>
                        );
                    }
                    return data;
                });
                switch (property) {
                    case 'documentHistory':
                        header = header.filter((data, i) => {
                            return data.key !== 'message';
                        });
                        header = header.map((data) => {
                            if (data.key === 'action') {
                                data.name = `${data.name} & Message`;
                                if (isMobile) {
                                    data.width = 200;
                                } else {
                                    data.width = 370;
                                }
                            }
                            if (data.key === 'username') {
                                data.width = 200;
                            }

                            if (isMobile) {
                                //	data.width = 200;
                            }

                            return data;
                        });
                        const { currentPage, perPage } =
                            this.state.documentHistory;
                        filteredRowData = this.getRowData(
                            currentPage,
                            perPage,
                            rowData
                        );
                        documentHistory = Object.assign(
                            this.state.documentHistory,
                            { header, rowData, filteredRowData, totalCount }
                        );
                        break;
                    case 'workflowQueues':
                        const currentPageWorkFlowQueues =
                            this.state.workflowQueues.currentPage;
                        const perPageWorkFlowQueues =
                            this.state.workflowQueues.perPage;
                        filteredRowData = this.getRowData(
                            currentPageWorkFlowQueues,
                            perPageWorkFlowQueues,
                            rowData
                        );
                        workflowQueues = Object.assign(
                            this.state.workflowQueues,
                            { header, rowData, filteredRowData, totalCount }
                        );
                        break;
                    case 'workflowTransactions':
                        const currentPageworkflowTransactions =
                            this.state.workflowTransactions.currentPage;
                        const perPageflowTransactions =
                            this.state.workflowTransactions.perPage;
                        filteredRowData = this.getRowData(
                            currentPageworkflowTransactions,
                            perPageflowTransactions,
                            rowData
                        );
                        workflowTransactions = Object.assign(
                            this.state.workflowTransactions,
                            { header, rowData, filteredRowData, totalCount }
                        );
                        break;
                    default:
                        break;
                }
            }
            this.setState({
                documentHistory: { ...documentHistory, showLoader: false },
                workflowQueues: { ...workflowQueues, showLoader: false },
                workflowTransactions: {
                    ...workflowTransactions,
                    showLoader: false,
                },
                displayAnimation: false,
            });
        };
        // docId
        getDocumentHistroy({ docId })
            .then((response) => {
                response = response.json();
                return response;
            })
            .then(processHistroy);
    }

    refreshTable() {
        const resetIt = () => {
            this.componentDidMount();
            this.setState({
                displayAnimation: true,
                refresh: false,
                documentHistory: {
                    ...this.state.documentHistory,
                    filterTxt: '',
                    perPage: 10,
                    currentPage: 0,
                    totalCount: 0,
                    sortDirection: 'NONE',
                    sortColumn: '',
                    filteredRowData: [],
                },
                workflowQueues: {
                    ...this.state.workflowQueues,
                    filterTxt: '',
                    perPage: 10,
                    currentPage: 0,
                    totalCount: 0,
                    sortDirection: 'NONE',
                    sortColumn: '',
                    filteredRowData: [],
                },
                workflowTransactions: {
                    ...this.state.workflowTransactions,
                    filterTxt: '',
                    perPage: 10,
                    currentPage: 0,
                    totalCount: 0,
                    sortDirection: 'NONE',
                    sortColumn: '',
                    filteredRowData: [],
                },
            });
        };
        this.setState(
            {
                refresh: true,
            },
            () => {
                setTimeout(() => {
                    resetIt();
                }, 100);
            }
        );
    }

    sortRows(sortColumn, sortDirection) {
        let rowData = [];
        let filterTxt = '';
        let filteredRowData = [];
        let perPage = 10;
        if (sortDirection === 'NONE') {
            sortDirection = 'ASC';
        } else if (sortDirection === 'ASC') {
            sortDirection = 'DESC';
        } else if (sortDirection === 'DESC') {
            sortDirection = 'ASC';
        }
        const sortOperation = (
            filterTxt,
            sortColumn,
            sortDirection,
            rowData
        ) => {
            let filteredRowData = rowData;
            if (sortDirection !== 'NONE') {
                if (filterTxt.trim() !== '') {
                    filteredRowData = this.filterDataGrid(filterTxt);
                }
                const fields = this.sortFields(sortColumn, sortDirection);
                filteredRowData = _.orderBy(
                    filteredRowData,
                    fields.sortfn,
                    fields.orderBy
                );
                filteredRowData = this.getRowData(0, perPage, filteredRowData);
            }
            return filteredRowData;
        };
        switch (this.state.currentTab) {
            case 'document-histroy':
                rowData = this.state.documentHistory.rowData;
                filterTxt = this.state.documentHistory.filterTxt;
                perPage = this.state.documentHistory.perPage;
                filteredRowData = sortOperation(
                    filterTxt,
                    sortColumn,
                    sortDirection,
                    rowData
                );
                this.setState({
                    documentHistory: {
                        ...this.state.documentHistory,
                        sortColumn,
                        sortDirection,
                        filteredRowData,
                    },
                });
                return;
            case 'workflow-queues':
                rowData = this.state.workflowQueues.rowData;
                filterTxt = this.state.workflowQueues.filterTxt;
                perPage = this.state.workflowQueues.perPage;
                filteredRowData = sortOperation(
                    filterTxt,
                    sortColumn,
                    sortDirection,
                    rowData
                );
                this.setState({
                    workflowQueues: {
                        ...this.state.workflowQueues,
                        sortColumn,
                        sortDirection,
                        filteredRowData,
                    },
                });
                return;
            case 'workflow-transactions':
                rowData = this.state.workflowTransactions.rowData;
                filterTxt = this.state.workflowTransactions.filterTxt;
                perPage = this.state.workflowTransactions.perPage;
                filteredRowData = sortOperation(
                    filterTxt,
                    sortColumn,
                    sortDirection,
                    rowData
                );
                this.setState({
                    workflowTransactions: {
                        ...this.state.workflowTransactions,
                        sortColumn,
                        sortDirection,
                        filteredRowData,
                    },
                });
                break;
            default:
                break;
        }
    }

    handleChangeRowsPerPage(event) {
        const startIndex = 0;
        const endIndex = parseInt(event.target.value, 10);
        let filteredRowData = [];
        let filterTxt = '';
        switch (this.state.currentTab) {
            case 'document-histroy':
                filteredRowData = this.state.documentHistory.rowData;
                filterTxt = this.state.documentHistory.filterTxt;
                if (filterTxt.trim() !== '') {
                    filteredRowData = this.filterDataGrid(filterTxt);
                }
                filteredRowData = _.slice(
                    filteredRowData,
                    startIndex,
                    endIndex
                );
                this.setState({
                    documentHistory: {
                        ...this.state.documentHistory,
                        filteredRowData,
                        perPage: endIndex,
                        currentPage: 0,
                    },
                });
                return;
            case 'workflow-queues':
                filteredRowData = this.state.workflowQueues.rowData;
                filterTxt = this.state.workflowQueues.filterTxt;
                if (filterTxt.trim() !== '') {
                    filteredRowData = this.filterDataGrid(filterTxt);
                }
                filteredRowData = _.slice(
                    filteredRowData,
                    startIndex,
                    endIndex
                );
                this.setState({
                    workflowQueues: {
                        ...this.state.workflowQueues,
                        filteredRowData,
                        perPage: endIndex,
                        currentPage: 0,
                    },
                });
                return;
            case 'workflow-transactions':
                filteredRowData = this.state.workflowTransactions.rowData;
                filterTxt = this.state.workflowTransactions.filterTxt;
                if (filterTxt.trim() !== '') {
                    filteredRowData = this.filterDataGrid(filterTxt);
                }
                filteredRowData = _.slice(
                    filteredRowData,
                    startIndex,
                    endIndex
                );
                this.setState({
                    workflowTransactions: {
                        ...this.state.workflowTransactions,
                        filteredRowData,
                        perPage: endIndex,
                        currentPage: 0,
                    },
                });
                break;
            default:
                break;
        }
    }

    sortFields(sortColumn, sortDirection) {
        const sortOrder = sortColumn.split('&');

        const orderBy = [];
        const sortfn = (data) => {
            const result = [];
            sortOrder.forEach((val) => {
                switch (val.trim()) {
                    case 'logdate':
                        const date = new Date(data.logdate_raw);
                        result.push(date.getTime());
                        break;
                    case 'entrydate':
                        const entrydate_raw = new Date(data.entrydate_raw);
                        result.push(entrydate_raw.getTime());
                        break;
                    case 'exitdate':
                        const exitdate_raw = new Date(data.exitdate_raw);
                        result.push(exitdate_raw.getTime());
                        break;
                    case 'message':
                        result.push(data.message_raw);
                        break;
                    case 'action':
                        result.push(data.action_raw);
                        break;
                    case 'username':
                        result.push(data.username_raw);
                        break;
                    case 'entryusername':
                        result.push(data.entryusername_raw);
                        break;
                    case 'exitusername':
                        result.push(data.exitusername_raw);
                        break;
                    default:
                        if (val.trim() !== '') {
                            result.push(data[val].toLowerCase());
                        }
                        break;
                }
            });
            return result;
        };
        sortOrder.forEach(() => orderBy.push(sortDirection.toLowerCase()));
        return { orderBy, sortfn };
    }

    handleColumn(header) {
        const headerRenderer = (props) => {
            return (
                <DocumentHistroyHeader
                    {...props}
                    {...this.state}
                    sortRows={this.sortRows}
                />
            );
        };
        return header.map((c) => {
            if (c.key === 'id' || c.key === 'select-row') return c;
            return { ...c, headerRenderer };
        });
    }

    dataGridComponent(params, keyVal) {
        if (
            params === undefined ||
            Object.keys(params).length === 0 ||
            params.rowData === undefined
        ) {
            return null;
        }
        let defaultHeight = 560;
        // if (params.rowData.length < 10) {
        //     if (params.rowData.length === 0) {
        //         defaultHeight = 120;
        //     } else {
        //         defaultHeight = params.rowData.length * 50 + 60;
        //     }
        // }
        if (params.rowData.length >= 10) {
            defaultHeight = 10 * 50 + 60;
        }

        defaultHeight = window.innerHeight - 280;
        return (
            <>
                <DataGrid
                    height={10}
                    key={Math.random() + params.rowData.length + keyVal}
                    columns={this.handleColumn(params.header)}
                    rows={params.filteredRowData}
                    rowKeyGetter={(row) => {
                        return row.id;
                    }}
                    rowsCount={params.filteredRowData.length}
                    rowHeight={(args) => {
                        return 50;
                    }}
                    style={{ height: defaultHeight }}
                    className='fill-grid'
                    emptyRowsRenderer={NoRecordFound.bind(this, params)}
                />
            </>
        );
    }

    handlePagination(params) {
        if (
            params === undefined ||
            Object.keys(params).length === 0 ||
            params.totalCount === 0
        ) {
            return null;
        }
        return (
            <>
                <TablePagination
                    component='div'
                    count={params.totalCount}
                    page={parseInt(params.currentPage, 10)}
                    rowsPerPage={parseInt(params.perPage, 10)}
                    onRowsPerPageChange={(event) =>
                        this.handleChangeRowsPerPage(event)
                    }
                    onPageChange={this.nextPage.bind(this)}
                />
            </>
        );
    }

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    filterDataGrid(searchText = '') {
        // Fixes for ReEx issue
        const isValid = (str) => {
            // eslint-disable-next-line no-useless-escape
            //return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText.trim()) === false) {
            return [];
        }
        // End
        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        const filterOperation = (rowData) => {
            const filteredData = _.filter(rowData, function (data) {
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        continue;
                    }
                    passed =
                        String(data[property]).toLowerCase().match(inputData) !=
                            null &&
                        String(data[property]).toLowerCase().match(inputData)
                            .length > 0;
                    if (passed) break;
                }
                return passed;
            });
            return filteredData;
        };
        let filteredRowData = [];
        let totalCount = 0;
        switch (this.state.currentTab) {
            case 'document-histroy':
                const fullRowData = filterOperation(
                    this.state.documentHistory.rowData
                );
                filteredRowData = this.getRowData(
                    0,
                    this.state.documentHistory.perPage,
                    fullRowData
                );
                totalCount = fullRowData.length;
                this.setState({
                    documentHistory: {
                        ...this.state.documentHistory,
                        filterTxt: searchText,
                        filteredRowData,
                        totalCount,
                        currentPage: 0,
                    },
                });
                return fullRowData;
            case 'workflow-queues':
                const fullworkflowQueuesRowData = filterOperation(
                    this.state.workflowQueues.rowData
                );
                filteredRowData = this.getRowData(
                    0,
                    this.state.workflowQueues.perPage,
                    fullworkflowQueuesRowData
                );
                totalCount = fullworkflowQueuesRowData.length;
                this.setState({
                    workflowQueues: {
                        ...this.state.workflowQueues,
                        filterTxt: searchText,
                        filteredRowData,
                        totalCount,
                        currentPage: 0,
                    },
                });
                return filteredRowData;
            case 'workflow-transactions':
                const fullworkflowTransactionsRowData = filterOperation(
                    this.state.workflowTransactions.rowData
                );
                filteredRowData = this.getRowData(
                    0,
                    this.state.workflowTransactions.perPage,
                    fullworkflowTransactionsRowData
                );
                totalCount = fullworkflowTransactionsRowData.length;
                this.setState({
                    workflowTransactions: {
                        ...this.state.workflowTransactions,
                        filterTxt: searchText,
                        filteredRowData,
                        totalCount,
                        currentPage: 0,
                    },
                });
                return fullworkflowTransactionsRowData;
            default:
                return [];
        }
    }

    nextPage(event, newPage) {
        let perPage = 10;
        switch (this.state.currentTab) {
            case 'document-histroy':
                perPage = this.state.documentHistory.perPage;
                break;
            case 'workflow-queues':
                perPage = this.state.workflowQueues.perPage;
                break;
            case 'workflow-transactions':
                perPage = this.state.workflowTransactions.perPage;
                break;
            default:
                break;
        }
        const startIndex = parseInt(newPage, 10) * parseInt(perPage, 10);
        const endIndex = startIndex + parseInt(perPage, 10);
        let filteredRowData = [];
        const handlePageOperation = (rowData, filterTxt) => {
            let filteredRowData = rowData;
            if (filterTxt.trim() !== '') {
                filteredRowData = this.filterDataGrid(filterTxt);
            }
            return filteredRowData;
        };
        switch (this.state.currentTab) {
            case 'document-histroy':
                const { rowData, filterTxt } = this.state.documentHistory;
                filteredRowData = handlePageOperation(rowData, filterTxt);
                const fields = this.sortFields(
                    this.state.documentHistory.sortColumn,
                    this.state.documentHistory.sortDirection
                );
                filteredRowData = _.orderBy(
                    filteredRowData,
                    fields.sortfn,
                    fields.orderBy
                );
                filteredRowData = _.slice(
                    filteredRowData,
                    startIndex,
                    endIndex
                );
                this.setState({
                    documentHistory: {
                        ...this.state.documentHistory,
                        filteredRowData,
                        currentPage: newPage,
                    },
                });
                return true;
            case 'workflow-queues':
                const rowDataworkflowQueues = this.state.workflowQueues.rowData;
                const filterTxtworkflowQueues =
                    this.state.workflowQueues.filterTxt;
                filteredRowData = handlePageOperation(
                    rowDataworkflowQueues,
                    filterTxtworkflowQueues
                );
                const fieldsworkflowQueues = this.sortFields(
                    this.state.workflowQueues.sortColumn,
                    this.state.workflowQueues.sortDirection
                );
                filteredRowData = _.orderBy(
                    filteredRowData,
                    fieldsworkflowQueues.sortfn,
                    fieldsworkflowQueues.orderBy
                );
                filteredRowData = _.slice(
                    filteredRowData,
                    startIndex,
                    endIndex
                );
                this.setState({
                    workflowQueues: {
                        ...this.state.workflowQueues,
                        filteredRowData,
                        currentPage: newPage,
                    },
                });
                return true;
            case 'workflow-transactions':
                const rowDataworkflowTransactions =
                    this.state.workflowTransactions.rowData;
                const filterTxtworkflowTransactions =
                    this.state.workflowTransactions.filterTxt;
                filteredRowData = handlePageOperation(
                    rowDataworkflowTransactions,
                    filterTxtworkflowTransactions
                );
                const fieldsworkflowTransactions = this.sortFields(
                    this.state.workflowTransactions.sortColumn,
                    this.state.workflowTransactions.sortDirection
                );
                filteredRowData = _.orderBy(
                    filteredRowData,
                    fieldsworkflowTransactions.sortfn,
                    fieldsworkflowTransactions.orderBy
                );
                filteredRowData = _.slice(
                    filteredRowData,
                    startIndex,
                    endIndex
                );
                this.setState({
                    workflowTransactions: {
                        ...this.state.workflowTransactions,
                        filteredRowData,
                        currentPage: newPage,
                    },
                });
                return true;
            default:
                return false;
        }
    }

    render() {
        const handleTabSelect = (eventKey, event) => {
            this.setState({
                currentTab: eventKey,
            });
        };
        const getFilterText = () => {
            switch (this.state.currentTab) {
                case 'document-histroy':
                    const filterTxtHistroy =
                        this.state.documentHistory.filterTxt;
                    return filterTxtHistroy;
                case 'workflow-queues':
                    const filterTxtWorkFlowQueues =
                        this.state.workflowQueues.filterTxt;
                    return filterTxtWorkFlowQueues;
                case 'workflow-transactions':
                    const filterTxtworkflowTransactions =
                        this.state.workflowTransactions.filterTxt;
                    return filterTxtworkflowTransactions;
                default:
                    return '';
            }
        };
        return (
            <>
                <div className='tab_part'>
                    <div className='tabs'>
                        <Tab.Container
                            id='noanim-tab'
                            defaultActiveKey={this.state.currentTab}
                            transition={false}
                            className='mb-3 nav-item'
                            onSelect={handleTabSelect}
                        >
                            <Nav
                                style={{ float: 'left' }}
                                variant='pills'
                                className=''
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey='document-histroy'
                                        title='Documents'
                                        key={0}
                                    >
                                        Documents
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey='workflow-queues'
                                        title='Workflow Queues'
                                        key={1}
                                    >
                                        Workflow Queues
                                    </Nav.Link>
                                </Nav.Item>
                                {this.state.workflowTransactions.rowData
                                    .length > 1 && (
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey='workflow-transactions'
                                            title='Workflow Transactions'
                                            key={2}
                                        >
                                            Workflow Transactions
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                            <div
                                className='d-flex filter_section'
                                style={{ float: 'right' }}
                            >
                                <div className='filter mr-3'>
                                    <input
                                        type='text'
                                        placeholder='Filter'
                                        value={getFilterText()}
                                        onChange={(event) =>
                                            this.filterDataGrid(
                                                event.target.value
                                            )
                                        }
                                        style={{ paddingRight: '34px' }}
                                    />
                                    <i className='fa-regular fa-magnifying-glass' />
                                </div>
                                <span
                                    className='align-self-center reload'
                                    onClick={() => this.refreshTable()}
                                >
                                    {' '}
                                    <i
                                        title='Refresh'
                                        className={
                                            this.state.displayAnimation
                                                ? 'fas fa-sync-alt refreshing'
                                                : 'fas fa-sync-alt'
                                        }
                                    />{' '}
                                </span>
                            </div>
                            <Tab.Content style={{ clear: 'both' }}>
                                <Tab.Pane
                                    eventKey='document-histroy'
                                    key={`${Math.random()}key1`}
                                >
                                    {this.dataGridComponent(
                                        this.state.documentHistory,
                                        'key-1'
                                    )}
                                    {this.handlePagination(
                                        this.state.documentHistory
                                    )}
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey='workflow-queues'
                                    key={`${Math.random()}key2`}
                                >
                                    {this.dataGridComponent(
                                        this.state.workflowQueues,
                                        'key-2'
                                    )}
                                    {this.handlePagination(
                                        this.state.workflowQueues
                                    )}
                                </Tab.Pane>
                                {this.state.workflowTransactions.rowData
                                    .length > 1 && (
                                    <Tab.Pane
                                        eventKey='workflow-transactions'
                                        key={`${Math.random()}key3`}
                                    >
                                        {this.dataGridComponent(
                                            this.state.workflowTransactions,
                                            'key-3'
                                        )}
                                        {this.handlePagination(
                                            this.state.workflowTransactions
                                        )}
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </>
        );
    }
}
