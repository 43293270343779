import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    CONFIG_DATA,
    UPDATE_CONFIGURED_RECORDSETS,
    UPDATE_CUSTOM_SEARCHES,
    ACTION_ARRAY,
    GET_DDL_LIST,
} from '../actionTypes/configTypes';

const initialState = {
    configData: {
        configuredRecordsets: [],
        recordsetNamedIdsNeedingConfiguration: [],
        customSearchQueries: [],
        customSearchNamedIdsNeedingConfiguration: [],
    },
    finalDDL: '',
    actionArray: [],
};

export const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIG_DATA: {
            const { configData } = action;
            return { ...state, configData };
        }

        case UPDATE_CONFIGURED_RECORDSETS: {
            const {
                configuredRecordsets,
                recordsetNamedIdsNeedingConfiguration,
            } = action;
            return {
                ...state,
                configData: {
                    ...state.configData,
                    configuredRecordsets,
                    recordsetNamedIdsNeedingConfiguration,
                },
            };
        }

        case UPDATE_CUSTOM_SEARCHES: {
            const {
                customSearchQueries,
                customSearchNamedIdsNeedingConfiguration,
            } = action;
            const existingQueries = state.configData.customSearchQueries || [];
            const newQueries = customSearchQueries || [];

            const mergedQueriesMap = {};
            existingQueries.forEach((item) => {
                mergedQueriesMap[item.searchId] = item;
            });
            newQueries.forEach((item) => {
                mergedQueriesMap[item.searchId] = item; // Overwrite if duplicate
            });

            const mergedQueries = Object.values(mergedQueriesMap);

            const existingNamedIds =
                state.configData.customSearchNamedIdsNeedingConfiguration || [];
            const newNamedIds = customSearchNamedIdsNeedingConfiguration || [];

            const mergedNamedIdsMap = {};
            existingNamedIds.forEach((item) => {
                mergedNamedIdsMap[item.id] = item;
            });
            newNamedIds.forEach((item) => {
                mergedNamedIdsMap[item.id] = item; // Overwrite if duplicate
            });

            const mergedNamedIds = Object.values(mergedNamedIdsMap);

            return {
                ...state,
                configData: {
                    ...state.configData,
                    customSearchQueries: mergedQueries,
                    customSearchNamedIdsNeedingConfiguration: mergedNamedIds,
                },
            };
        }

        case ACTION_ARRAY: {
            const { actionArray } = action;
            return { ...state, actionArray };
        }

        default:
            return state;
    }
};

const initialStateDDL = {
    finalDDL: '',
    DDL: {},
};
var DDL = {};
export const persistDDL = (state = initialStateDDL, action) => {
    switch (action.type) {
        case GET_DDL_LIST: {
            const drop = action.DDL;
            DDL = { ...state.DDL, ...drop };
            return { DDL };
        }

        default:
            return state;
    }
};
