/* eslint-disable array-callback-return */
import React from 'react';
import ReactDOM from 'react-dom';
import Spinner from '../inbox/Spinner';
import { Redirect } from 'react-router-dom';
import { outboxDocumentAction } from '../../pages/api/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    stringEmptyOrUndefined,
    showErrorToast,
    GetFieldData,
} from '../commonfunction';
import { toast } from 'react-toastify';
toast.configure();
class TagAction extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            color: props.value,
            selected_rows: '',
            open_list: true,
            open_pdf: false,
            open_detail: false,
            selected_length: '',
            selected_tag: [],
            selected_tag_temp: [],
            selected_tagonly: [],
            refresh_delete: true,
            listingtags: [],
            tags: [],
            hide_tag: false,
            delete_tag: false,
            old_propid: '',
            spinner: this.props,
            addSuccess: false,
            filterTxt: '',
        };
    }
    getValue() {
        var join = this.state.selected_tagonly.join('|');
        return { tags_data: join };
    }

    getInputNode() {
        return ReactDOM.findDOMNode(this).getElementsByTagName('input')[0];
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ spinner: true });

        if (
            this.props.selected_tag !== undefined &&
            this.props.selected_tag !== null
        ) {
            let sel_tag = [];
            if (String(this.props.selected_tag).includes('|')) {
                sel_tag = this.props.selected_tag.split('|');
            } else {
                sel_tag[0] = this.props.selected_tag;
            }

            this.selected_tag(sel_tag);
            this.setState({ addSuccess: true });
        }

        let result = JSON.parse(localStorage.getItem('tagList'));
        var newTagArray = [];
        var ddlTag = this.props.ddl.tags;

        var ttempTagArray = [];

        if (result !== undefined && result.length > 0) {
            ddlTag.map((tttag, ttindex) => {
                const item = this.findTagPresent(tttag, result);
                if (item) {
                    item.tagId = ttindex;
                    ttempTagArray.push(item);
                    newTagArray[item.name] = item.color;
                } else {
                    let newTags = {};
                    newTags.name = tttag;
                    newTags.tagId = ttindex;
                    newTags.color = '#12cbc4';
                    ttempTagArray.push(newTags);
                    newTagArray[newTags.name] = '#12cbc4';
                }
            });
        } else {
            ddlTag.map((tttag, ttindex) => {
                var tTag = {};
                tTag.tagId = ttindex;
                tTag.name = tttag;
                tTag.color = '#12cbc4';
                ttempTagArray.push(tTag);
                newTagArray[tttag] = '#12cbc4';
            });
        }

        this.props.falseLoader();
        this.setState({
            tags: ttempTagArray,
            newTagColorArray: newTagArray,
            spinner: false,
            listingtags: ttempTagArray,
        });
    }

    findTagPresent = (tag, result) => {
        return result.find((item) => {
            return item.name === tag ? item : null;
        });
    };

    componentWillUnmount() {
        this.setState({
            selected_tag: [],
            selected_tagonly: [],
        });
        this.redirect();
        this._isMounted = false;
    }

    redirect = () => {
        return <Redirect to='/inbox' />;
    };

    add_tag = async (tag) => {
        if (!tag || !tag.name) {
            return;
        }

        const presentTags = this.state.selected_tag;
        let tagsWithDividers = '';
        let total = 0;

        presentTags.forEach((tag) => {
            total += tag.name.length;
            tagsWithDividers += tag.name + '|';
        });

        if (tag && tag.name) {
            total += tag.name.length;
            tagsWithDividers += tag.name + '|';
        }

        // console.log('total:', total);
        // console.log('tagsWithDividers:', tagsWithDividers.length);

        if (total >= 250 || tagsWithDividers.length >= 250) {
            showErrorToast('Document has no room for more tags.');
            return;
        }
        let fieldDataObj = GetFieldData({ configData: this.props.configData });
        this.props.trueLoader();
        this.setState({ spinner: true });
        if (this.state.selected_tagonly.includes(tag.name)) {
            return true;
        } else {
            if (this.state.selected_tag_temp.indexOf(tag) === -1) {
                this.setState({
                    selected_tag_temp: [...this.state.selected_tag_temp, tag],
                });
                this.state.selected_tagonly.push(tag.name);
            }
        }
        await this.setState({ spinner: false });
        var join = this.state.selected_tagonly.join('|');
        let fieldData = {};
        fieldData.fieldId = fieldDataObj.tagFieldId;
        fieldData.value = join;
        fieldData.value = [...new Set(fieldData.value.split('|'))].join('|');
        if (String(fieldData.value.charAt(0)) === '|') {
            fieldData.value = fieldData.value.substring(1);
        }
        if (
            String(fieldData.value.charAt(fieldData.value.length - 1)) === '|'
        ) {
            fieldData.value = fieldData.value.substring(
                0,
                fieldData.value.length - 1
            );
        }
        fieldData.value = fieldData.value + '|';
        let successDocId = [];
        let failedDocId = [];
        const params = {};
        const fields = {};
        fields.docIds = [this.props.docId];
        params.processId = this.props.processId;
        params.statusId = this.props.statusId;
        params.actionId =
            this.props.allActions !== undefined &&
            this.props.allActions.update_tag !== undefined &&
            this.props.allActions.update_tag.actionId !== undefined
                ? this.props.allActions.update_tag.actionId
                : undefined;

        params.fields = fields;
        fields.fields = [
            {
                fieldId: fieldDataObj.tags,
                value: fieldData.value,
            },
        ];
        await outboxDocumentAction(params)
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                }
            });

        let message;
        if (failedDocId.length !== 0) {
            this.setState({
                selected_tagonly: this.state.selected_tag.map(
                    (tag) => tag.name
                ),
            });
            this.props.falseLoader();
            message = `Doc Id  ${failedDocId[0]} is  Locked`;
            await showErrorToast(message);
        }
        if (successDocId.length !== 0) {
            this.props.tagActionUpdate(fieldData.value);

            this.setState({
                selected_tag: this.state.selected_tag_temp,
                addSuccess: true,
            });
        }
    };

    remove_tag = async (tag_index, value) => {
        let fieldDataObj = GetFieldData({ configData: this.props.configData });
        this.props.trueLoader();
        this.setState({ spinner: true });
        if (tag_index !== '') {
            this.setState({ selected_tagonly: [] });
            this.state.selected_tag_temp.splice(tag_index, 1);
            await this.setState(
                { selected_tag_temp: this.state.selected_tag_temp },
                () => {
                    this.state.selected_tag_temp.map((data) => {
                        this.state.selected_tagonly.push(data.name);
                        return data;
                    });
                }
            );
            this.setState({ spinner: false });
            this.setState({ refresh_delete: true, delete_tag: true });
        } else if (value !== '') {
            var index_tagonly = this.state.selected_tagonly.indexOf(value);
            this.state.selected_tagonly.splice(index_tagonly, 1);
            this.state.selected_tag_temp.map((data, index_main) => {
                if (data.name === value) {
                    this.state.selected_tag_temp.splice(index_main, 1);
                    this.setState(
                        { selected_tagonly: this.state.selected_tagonly },
                        () => {
                            this.setState({ spinner: false });
                            this.setState({
                                refresh_delete: true,
                                delete_tag: true,
                            });
                        }
                    );
                }
                return data;
            });
        }
        var join = this.state.selected_tagonly.join('|');
        let fieldData = {};
        fieldData.fieldId = fieldDataObj.tagFieldId;
        fieldData.value = join;
        fieldData.value = [...new Set(fieldData.value.split('|'))].join('|');
        if (fieldData.value.charAt(0) === '|') {
            fieldData.value = fieldData.value.substring(1);
        }
        if (fieldData.value.charAt(fieldData.value.length - 1) === '|') {
            fieldData.value = fieldData.value.substring(
                0,
                fieldData.value.length - 1
            );
        }

        fieldData.value = fieldData.value + '|';
        let successDocId = [];
        let failedDocId = [];
        const params = {};
        const fields = {};
        fields.docIds = [this.props.docId];
        params.processId = this.props.processId;
        params.statusId = this.props.statusId;
        params.actionId =
            this.props.allActions !== undefined &&
            this.props.allActions.update_tag !== undefined &&
            this.props.allActions.update_tag.actionId !== undefined
                ? this.props.allActions.update_tag.actionId
                : undefined;

        params.fields = fields;
        fields.fields = [
            {
                fieldId: fieldDataObj.tags,
                value: fieldData.value,
            },
        ];
        await outboxDocumentAction(params)
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                }
            });
        let message;
        if (failedDocId.length !== 0) {
            this.setState({
                selected_tagonly: this.state.selected_tag.map(
                    (tag) => tag.name
                ),
            });
            message = `Doc Id  ${failedDocId[0]} is  Locked`;
            await showErrorToast(message);
            this.props.falseLoader();
        }
        if (successDocId.length !== 0) {
            this.props.tagActionUpdate(fieldData.value);
            this.setState({
                selected_tag: this.state.selected_tag_temp,
                addSuccess: true,
            });
        }
        this.setState({ spinner: false });
    };

    selected_tag = (sel_tag) => {
        if (
            (this.state.selected_tag === '' ||
                this.state.selected_tag.length === 0) &&
            this.state.delete_tag === false
        ) {
            var tempselTag = [];
            var tempselTagOnly = [];
            sel_tag.map((data) => {
                if (String(data) !== '') {
                    this.state.selected_tag_temp.push({
                        name: data,
                        color: 'red',
                    });
                    tempselTag.push({ name: data, color: 'red' });
                    tempselTagOnly.push(data);
                }
                return data;
            });

            this.setState({
                selected_tag: tempselTag,
                selected_tagonly: tempselTagOnly,
            });
        }
    };

    render() {
        return (
            <>
                <Spinner show={this.state.spinner} />
                <div
                    onClick={() => this.divClick}
                    key={this.props}
                    id='tag_options'
                    className='tag_options'
                    style={{
                        border: this.state.refresh_delete
                            ? '1px solid black'
                            : '',
                        backgroundColor: 'white',
                        position: 'absolute',
                        margin: '70px',
                    }}
                >
                    {this.state.refresh_delete ? (
                        <div
                            className='tag_options_up'
                            style={{ borderBottom: '1px solid black' }}
                        >
                            {this.props.loader ? (
                                <div
                                    className='spinner-border text-primary'
                                    role='status'
                                >
                                    <span className='sr-only'>Loading...</span>
                                </div>
                            ) : null}

                            {this.state.selected_tag.map((data, index) => {
                                return (
                                    <div
                                        key={index + 2}
                                        style={{
                                            textAlign: 'center',
                                            color: 'white',
                                            borderRadius: '25px',
                                        }}
                                    >
                                        {data.name !== '' &&
                                        data.name !== undefined &&
                                        data.name !== null ? (
                                            <button
                                                style={{
                                                    backgroundColor: this.state
                                                        .newTagColorArray[
                                                        data.name
                                                    ]
                                                        ? this.state
                                                              .newTagColorArray[
                                                              data.name
                                                          ]
                                                        : '#7f96a5',
                                                    borderRadius: '25px',
                                                    textAlign: 'left',
                                                }}
                                                key={index + 1}
                                                title={data.name}
                                            >
                                                {data.name}{' '}
                                                <i
                                                    onClick={() => {
                                                        this.remove_tag(
                                                            index,
                                                            ''
                                                        );
                                                    }}
                                                    className='fa-light fa-xmark'
                                                ></i>
                                            </button>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}

                    {this.state.listingtags === '' ? (
                        <div style={{ textAlign: 'center' }} className='noTags'>
                            <span>No Tags Found</span>
                        </div>
                    ) : null}
                    {this.state.refresh_delete &&
                    this.state.listingtags !== '' ? (
                        <div className='tag_options_bottom'>
                            {this.state.listingtags.map((data, index) => (
                                <React.Fragment key={index}>
                                    <button
                                        key={index + 1}
                                        className={
                                            this.state.selected_tagonly.includes(
                                                data.name
                                            ) && this.state.addSuccess
                                                ? 'delete_true'
                                                : ''
                                        }
                                        onClick={() => {
                                            this.state.selected_tagonly.includes(
                                                data.name
                                            )
                                                ? this.remove_tag('', data.name)
                                                : this.add_tag(data);
                                            this.setState({ filterTxt: '' });
                                            this.setState({
                                                listingtags: this.state.tags,
                                            });
                                        }}
                                        style={{
                                            borderRadius: '25px',
                                            backgroundColor: data.color,
                                            color: 'white',
                                            textAlign: 'left',
                                        }}
                                        title={data.name}
                                    >
                                        {data.name}
                                        {this.state.selected_tagonly.includes(
                                            data.name
                                        ) && this.state.addSuccess ? (
                                            <i
                                                className='fa-light fa-xmark'
                                                style={{
                                                    position: 'relative',
                                                    marginLeft: '4px',
                                                }}
                                            ></i>
                                        ) : null}
                                    </button>
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}
const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TagAction));
