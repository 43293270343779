export const signatureColumns = [
    {
        name: "First Name",
        datatype: "Alphanumeric"
    },
    {
        name: "Last Name",
        datatype: "Alphanumeric"
    },
    {
        name: "Communication Email",
        datatype: "Alphanumeric"
    },
    {
        name: "Lookup Key",
        datatype: "Alphanumeric"
    },
];
