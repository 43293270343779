import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import ImportPhysicianModal from './components/ImportPhysicianModal';
import SuccessUploadModal from './components/SuccessUploadModal';

const PhysicianUpload: React.FC = () => {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [uploadResults, setUploadResults] = useState<any>(null);

  const openModal = () => {
    setOpenImportModal(true);
  };

  const handleUploadResults = (results: any) => {
    setUploadResults(results);
  };

  return (
    <div
      className='right-aside admin_upload-page'
      style={
        isMobile
          ? { height: window.innerHeight - 112 }
          : { height: window.innerHeight - 160 }
      }
    >
      <div
        className='header-top pl-7 pr-3 mb-5'
        style={{ maxWidth: '620px' }}
      >
        <h3 className='m-0 title-color'>Import Physicians</h3>
        <Button
          variant='success'
          onClick={openModal}
          title='Physician Bulk Import'
        >
          <i className="fa-solid fa-file-import"></i>Bulk Import
        </Button>
      </div>

      <div
        className='header-top pl-7 pr-3 pt-5 text-dark'
      >
        On this page you can bulk upload physicians. Click the button above to get started.
      </div>

      <ImportPhysicianModal
        showModal={openImportModal}
        setShowModal={setOpenImportModal}
        handleUploadResults={handleUploadResults}
      />

      <SuccessUploadModal
        showModal={!!uploadResults}
        setShowModal={() => setUploadResults(null)}
        uploadResults={uploadResults}
      />
    </div>
  );
};

export default PhysicianUpload;
