import { FieldConfiguration } from '../models/configuration';
import { GetDropdownData } from '../pages/api/api';

export async function getDropDownDataFromApi(
    fieldId: number
): Promise<FieldConfiguration> {
    return await GetDropdownData(fieldId)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let filteredValues = data.values.filter(
                (item: string) =>
                    item !== '' && item !== null && item !== undefined
            );
            return {
                ...data,
                values: filteredValues,
            };
        })
        .catch((error) => {
            console.log(error);
        });
}

