import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { faxCellFormatter } from '../commonfunction';
// import { Button, Modal } from 'react-bootstrap';

const ViewEditPhysician = (props) => {
    const [delivery_method, setDelivery_method] = useState('');
    const [email_address, setEmail_address] = useState('');
    const [fax_number, setFax_number] = useState('');
    const [phone_number, setPhone_number] = useState('');
    const [, setPhysician_id] = useState('');
    const [npi, setNpi] = useState('');
    const [, setOrdering_physician] = useState('');
    const [physician_first_name, setPhysician_first_name] = useState('');
    const [physician_last_name, setPhysician_last_name] = useState('');
    const [physicianData, setPhysicianData] = useState({
        npi: '',
        physician_first_name: '',
        physician_last_name: '',
        fax_number: '',
        phone_number: '',
        email_address: '',
        ordering_physician: '',
        id: '',
    });
    const [, setCreatePhysicianData] = useState({
        npi: '',
        physician_first_name: '',
        physician_last_name: '',
        fax_number: '',
        phone_number: '',
        email_address: '',
        ordering_physician: '',
    });
    const redux_store = useSelector((state) => state);
    const deliverymethod = redux_store.persistDDL.DDL.deliverymethod;

    //const[updateSelectedPhysician , setUpdateSlectedPhysician] = useState(props.selectedPhysician)
    useEffect(() => {
        if (
            props.selectedPhysician !== undefined &&
            props.edit_or_create === 'edit'
        ) {
            setDelivery_method(props.selectedPhysician.delivery_method);
            setEmail_address(props.selectedPhysician.email_address);

            setPhysician_id(props.selectedPhysician.physician_id);
            setNpi(props.selectedPhysician.npi);
            setOrdering_physician(props.selectedPhysician.ordering_physician);

            setFax_number(props.selectedPhysician.fax_number);
            setPhone_number(props.selectedPhysician.phone_number);
            if (
                props.selectedPhysician.physician_first_name !== undefined &&
                props.selectedPhysician.physician_first_name !== ''
            ) {
                setPhysician_first_name(
                    props.selectedPhysician.physician_first_name
                );
            } else {
                setPhysician_first_name(props.physician_first_name);
            }
            if (
                props.selectedPhysician.physician_last_name !== undefined &&
                props.selectedPhysician.physician_last_name !== ''
            ) {
                setPhysician_last_name(
                    props.selectedPhysician.physician_last_name
                );
            } else {
                setPhysician_last_name(props.physician_last_name);
            }
        }
        if (props.edit_or_create === 'create') {
            setDelivery_method('');
            setEmail_address('');
            setFax_number('');
            setPhone_number('');
            setPhysician_id('');
            setNpi('');
            setOrdering_physician('');
            setPhysician_first_name('');
            setPhysician_last_name('');
        }
        if (
            props.edit_or_create === 'DeletePhysician' &&
            (npi === '' || npi === undefined)
        ) {
            const queryParams = new URLSearchParams(window.location.search);
            const npi = queryParams.get('NPI');
            const orderingphysician = queryParams.get('orderingphysician');
            const deliverymethod = queryParams.get('deliverymethod');
            let nameArr = orderingphysician.split(',');
            let first_name;
            let last_name;
            if (orderingphysician !== '' && orderingphysician !== undefined) {
                first_name = nameArr[1].toString();
                last_name = nameArr[0].toString();
                first_name = first_name.trim();
            }
            const faxnumber = queryParams.get('faxnumber');
            const emailaddress = queryParams.get('emailaddress');
            const phonenumber = queryParams.get('phonenumber');
            setDelivery_method(deliverymethod);
            setEmail_address(emailaddress);
            setFax_number(faxnumber);
            setPhone_number(phonenumber);
            setPhysician_id('');
            setNpi(npi);
            setOrdering_physician('');
            setPhysician_first_name(first_name);
            setPhysician_last_name(last_name);
            setPhysicianData({
                npi: npi,
                physician_first_name: first_name,
                physician_last_name: last_name,
                fax_number: faxnumber,
                ordering_physician: orderingphysician,
                delivery_method: deliverymethod,
                phone_number: phonenumber,
                email_address: emailaddress,
            });
            props.updateState({
                createPhysicianData: {
                    npi: npi,
                    physician_first_name: first_name,
                    physician_last_name: last_name,
                    fax_number: faxnumber,
                    ordering_physician: orderingphysician,
                    delivery_method: deliverymethod,
                    phone_number: phonenumber,
                    email_address: emailaddress,
                },
                selectedPhysician: undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.edit_or_create, props.selectedPhysician]); // kalpana to check

    const handleInputOnChange = (state, event) => {
        props.updateState({
            edit_or_create: 'some',
        });

        const value = event.target.value;
        if (state === 'first_name') {
            setPhysician_first_name(value);
            setPhysicianData({
                ...physicianData,
                physician_first_name: value,
                ordering_physician: `${physician_last_name}, ${value}`,
            });

            if (props.selectedPhysician !== undefined) {
                props.updateState({
                    selectedPhysician: {
                        ...props.selectedPhysician,
                        physician_first_name: value,
                        ordering_physician: `${physician_last_name}, ${value}`,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...props.selectedPhysician,
                        physician_first_name: value,
                        ordering_physician: `${physician_last_name}, ${value}`,
                    });
                }
            }
            if (props.selectedPhysician === undefined) {
                setCreatePhysicianData({
                    ...physicianData,
                    physician_first_name: value,
                    ordering_physician: `${physician_last_name}, ${value}`,
                });
                props.updateState({
                    createPhysicianData: {
                        ...physicianData,
                        physician_first_name: value,
                        ordering_physician: `${physician_last_name}, ${value}`,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...physicianData,
                        physician_first_name: value,
                        ordering_physician: `${physician_last_name}, ${value}`,
                    });
                }
                if (props.calingFrom === 'bigView') {
                    props.functionPhsicianData({
                        ...physicianData,
                        physician_first_name: value,
                        ordering_physician: `${physician_last_name}, ${value}`,
                    });
                }
            }
        }
        if (state === 'last_name') {
            setPhysician_last_name(value);
            setPhysicianData({
                ...physicianData,
                physician_last_name: value,
                ordering_physician: `${value}, ${physician_first_name}`,
            });

            if (props.selectedPhysician !== undefined) {
                props.updateState({
                    selectedPhysician: {
                        ...props.selectedPhysician,
                        physician_last_name: value,
                        ordering_physician: `${value}, ${physician_first_name}`,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...props.selectedPhysician,
                        physician_last_name: event.target.value,
                        ordering_physician: `${value}, ${physician_first_name}`,
                    });
                }
            }
            if (props.selectedPhysician === undefined) {
                setCreatePhysicianData({
                    ...physicianData,
                    physician_last_name: value,
                    ordering_physician: `${value}, ${physician_first_name}`,
                });
                props.updateState({
                    createPhysicianData: {
                        ...physicianData,
                        physician_last_name: value,
                        ordering_physician: `${value}, ${physician_first_name}`,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...physicianData,
                        physician_last_name: value,
                        ordering_physician: `${value}, ${physician_first_name}`,
                    });
                }
                if (props.calingFrom === 'bigView') {
                    props.functionPhsicianData({
                        ...physicianData,
                        physician_last_name: value,
                        ordering_physician: `${value}, ${physician_first_name}`,
                    });
                }
            }
        }
        if (state === 'npi') {
            setNpi(value);
            setPhysicianData({
                ...physicianData,
                npi: value,
            });

            if (props.selectedPhysician !== undefined) {
                props.updateState({
                    npiValidation: false,
                    selectedPhysician: {
                        ...props.selectedPhysician,
                        npi: value,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...props.selectedPhysician,
                        npi: value,
                    });
                }
            }
            if (props.selectedPhysician === undefined) {
                setCreatePhysicianData({
                    ...physicianData,
                    npi: value,
                });
                props.updateState({
                    npiValidation: false,
                    createPhysicianData: {
                        ...physicianData,
                        npi: value,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...physicianData,
                        npi: value,
                    });
                }
                if (props.calingFrom === 'bigView') {
                    props.functionPhsicianData({
                        ...physicianData,
                        npi: value,
                    });
                }
            }
        }
        if (state === 'fax_number') {
            let regExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
            const format = value.match(regExp);
            setFax_number(value);
            // if (format !== null || value === '') {

            let number1;
            number1 = value.replace(/-/g, '');
            setPhysicianData({
                ...physicianData,
                fax_number: number1,
            });
            if (props.selectedPhysician !== undefined) {
                props.updateState({
                    faxValidation: false,
                    selectedPhysician: {
                        ...props.selectedPhysician,
                        fax_number: number1,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...props.selectedPhysician,
                        fax_number: number1,
                    });
                }
            }
            if (props.selectedPhysician === undefined) {
                setCreatePhysicianData({
                    ...physicianData,
                    fax_number: number1,
                });
                props.updateState({
                    faxValidation: false,
                    createPhysicianData: {
                        ...physicianData,
                        fax_number: number1,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...physicianData,
                        fax_number: number1,
                    });
                }
                if (props.calingFrom === 'bigView') {
                    props.functionPhsicianData({
                        ...physicianData,
                        fax_number: number1,
                    });
                }
            }
            //}
        }
        if (state === 'phone_number') {
            let regExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
            const format = value.match(regExp);
            setPhone_number(value);
            //if (format !== null || value === '') {
            let number = value.replace(/-/g, '');
            setPhysicianData({
                ...physicianData,
                phone_number: number,
            });
            if (props.selectedPhysician !== undefined) {
                props.updateState({
                    phoneValidation: false,
                    selectedPhysician: {
                        ...props.selectedPhysician,
                        phone_number: number,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...props.selectedPhysician,
                        phone_number: number,
                    });
                }
            }
            if (props.selectedPhysician === undefined) {
                setCreatePhysicianData({
                    ...physicianData,
                    phone_number: number,
                });
                props.updateState({
                    phoneValidation: false,
                    createPhysicianData: {
                        ...physicianData,
                        phone_number: number,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...physicianData,
                        phone_number: number,
                    });
                }
                if (props.calingFrom === 'bigView') {
                    props.functionPhsicianData({
                        ...physicianData,
                        phone_number: number,
                    });
                }
            }
            //}
        }
        if (state === 'email_address') {
            setEmail_address(value);
            setPhysicianData({
                ...physicianData,
                email_address: value,
            });
            if (props.selectedPhysician !== undefined) {
                props.updateState({
                    emailValidation: false,
                    validateEdit: false,
                    selectedPhysician: {
                        ...props.selectedPhysician,
                        email_address: value,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...props.selectedPhysician,
                        email_address: value,
                    });
                }
            }
            if (props.selectedPhysician === undefined) {
                setCreatePhysicianData({
                    ...physicianData,
                    email_address: value,
                });
                props.updateState({
                    emailValidation: false,
                    createPhysicianData: {
                        ...physicianData,
                        email_address: value,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...physicianData,
                        email_address: value,
                    });
                }
                if (props.calingFrom === 'bigView') {
                    props.functionPhsicianData({
                        ...physicianData,
                        email_address: value,
                    });
                }
            }
        }
        if (state === 'delivery_method') {
            setDelivery_method(value);
            setPhysicianData({
                ...physicianData,
                delivery_method: value,
            });
            if (props.selectedPhysician !== undefined) {
                props.updateState({
                    // emailValidation: false,
                    validateEdit: false,
                    selectedPhysician: {
                        ...props.selectedPhysician,
                        delivery_method: value,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...props.selectedPhysician,
                        delivery_method: value,
                    });
                }
            }
            if (props.selectedPhysician === undefined) {
                setCreatePhysicianData({
                    ...physicianData,
                    delivery_method: value,
                });
                props.updateState({
                    // emailValidation: false,
                    createPhysicianData: {
                        ...physicianData,
                        delivery_method: value,
                    },
                });
                if (props.calingFrom === 'upload') {
                    props.PhsicianData_Function({
                        ...physicianData,
                        delivery_method: value,
                    });
                }
                if (props.calingFrom === 'bigView') {
                    props.functionPhsicianData({
                        ...physicianData,
                        delivery_method: value,
                    });
                }
            }
        }
    };
    let classrow = 'col-lg-6';
    if (props.calingFrom === 'search') classrow = 'col-lg-3';

    return (
        <div
            className={
                props.calingFrom === 'search' && props.edit_physician
                    ? 'search_page edit_patient'
                    : props.calingFrom === 'search' &&
                      props.edit_physician === false
                    ? 'search_page view_patient'
                    : 'patient create-physician'
            }
        >
            {/* <SpinnerBlur show={this.state.blur_spinner} /> */}

            {props.edit_physician === true ? (
                <div className='row grid-row-group'>
                    <>
                        <div className={`transition-select ${classrow}`}>
                            <label className='pl-5'>PHYSICIAN FIRST NAME</label>
                            <div className='position-relative'>
                                <input
                                    type='text'
                                    className={
                                        (props.validateEdit &&
                                            (physician_first_name === '' ||
                                                physician_first_name ===
                                                    undefined)) ||
                                        (props.physician_validation &&
                                            props.physicianData_fax
                                                .physician_first_name === '')
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        physician_first_name !== ''
                                            ? physician_first_name
                                            : ''
                                    }
                                    onChange={(event) => {
                                        handleInputOnChange(
                                            'first_name',
                                            event
                                        );
                                    }}
                                    maxLength='50'
                                />

                                {(props.validateEdit &&
                                    (physician_first_name === '' ||
                                        physician_first_name === undefined)) ||
                                (props.physician_validation &&
                                    props.physicianData_fax
                                        .physician_first_name === '') ? (
                                    <p
                                        className='error m-0'
                                        style={{
                                            color: 'red',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Physician First Name is required.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className={`transition-select ${classrow}`}>
                            <label className='pl-5'>PHYSICIAN LAST NAME</label>
                            <input
                                type='text'
                                className={
                                    (props.validateEdit &&
                                        (physician_last_name === '' ||
                                            physician_last_name ===
                                                undefined)) ||
                                    (props.physician_validation &&
                                        props.physicianData_fax
                                            .physician_last_name === '')
                                        ? 'form-control error-field'
                                        : 'form-control'
                                }
                                value={
                                    physician_last_name !== ''
                                        ? physician_last_name
                                        : ''
                                }
                                onChange={(event) => {
                                    handleInputOnChange('last_name', event);
                                }}
                                maxLength='50'
                            />
                            {(props.validateEdit &&
                                (physician_last_name === '' ||
                                    physician_last_name === undefined)) ||
                            (props.physician_validation &&
                                props.physicianData_fax.physician_last_name ===
                                    '') ? (
                                <p
                                    className='error m-0'
                                    style={{ color: 'red' }}
                                >
                                    Physician Last Name is required.
                                </p>
                            ) : null}
                        </div>
                    </>

                    <>
                        <div className={`transition-select ${classrow}`}>
                            <label className='pl-5'>NPI NUMBER</label>
                            <div className='position-relative'>
                                <NumberFormat
                                    // format='##########'
                                    maxLength='10'
                                    className={
                                        (props.validateEdit &&
                                            (npi === '' ||
                                                npi === undefined)) ||
                                        (props.physician_validation &&
                                            props.physicianData_fax.npi ===
                                                '') ||
                                        props.npiValidation === true ||
                                        props.npiVerify === true
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={npi !== '' ? npi : ''}
                                    onChange={(event) => {
                                        handleInputOnChange('npi', event);
                                    }}
                                />
                                {(props.validateEdit &&
                                    (npi === '' || npi === undefined)) ||
                                (props.physician_validation &&
                                    props.physicianData_fax.npi === '') ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        NPI Number is required.
                                    </p>
                                ) : null}
                                {props.npiValidation === true ||
                                props.npiVerify === true ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        NPI Number requires 10 digits.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className={`transition-select ${classrow}`}>
                            <label className='pl-5'>FAX NUMBER</label>
                            <NumberFormat
                                className={
                                    (props.validateEdit &&
                                        (fax_number === '' ||
                                            fax_number === undefined)) ||
                                    (props.physician_validation &&
                                        props.physicianData_fax.fax_number ===
                                            '') ||
                                    props.faxValidation === true ||
                                    props.faxNumberVerify === true
                                        ? 'form-control error-field'
                                        : 'form-control'
                                }
                                value={fax_number !== '' ? fax_number : ''}
                                format='###-###-####'
                                //maxLength='14'
                                allowEmptyFormatting
                                mask='_'
                                onChange={(event) => {
                                    handleInputOnChange('fax_number', event);
                                }}
                            />
                            {(props.validateEdit &&
                                (fax_number === '' ||
                                    fax_number === undefined)) ||
                            (props.physician_validation &&
                                props.physicianData_fax.fax_number === '') ? (
                                <p
                                    className='error m-0'
                                    style={{ color: 'red' }}
                                >
                                    Fax Number is required.
                                </p>
                            ) : null}
                            {props.faxValidation === true ||
                            props.faxNumberVerify === true ? (
                                <p
                                    className='error m-0'
                                    style={{ color: 'red' }}
                                >
                                    Invalid Fax Number.
                                </p>
                            ) : null}
                        </div>
                    </>

                    <>
                        <div className={`transition-select ${classrow}`}>
                            <label className='pl-5'>PHONE NUMBER</label>
                            <div className='position-relative'>
                                <NumberFormat
                                    className={
                                        props.phoneValidation === true ||
                                        props.phoneNumberVerify === true
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        phone_number !== '' ? phone_number : ''
                                    }
                                    format='###-###-####'
                                    //maxLength='14'
                                    allowEmptyFormatting
                                    mask='_'
                                    onChange={(event) => {
                                        handleInputOnChange(
                                            'phone_number',
                                            event
                                        );
                                    }}
                                />
                                {/* (props.validateEdit &&
                                    (phone_number === '' ||
                                        phone_number === undefined)) ||
                                (props.physician_validation &&
                                    props.physicianData_fax.phone_number ===
                                        '') ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Phone Number is required.
                                    </p>
                                ) : null */}
                                {props.phoneValidation === true ||
                                props.phoneNumberVerify === true ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Invalid phone number.
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        <div className={`transition-select ${classrow}`}>
                            <label className='pl-5'>EMAIL ADDRESS</label>
                            <input
                                type='text'
                                className={
                                    props.emailValidation === true ||
                                    props.emailVerify === true
                                        ? 'form-control error-field'
                                        : 'form-control'
                                }
                                value={
                                    email_address !== '' ? email_address : ''
                                }
                                onChange={(event) => {
                                    handleInputOnChange('email_address', event);
                                }}
                            />
                            {/* (props.validateEdit &&
                                (email_address === '' ||
                                    email_address === undefined)) ||
                            (props.physician_validation &&
                                props.physicianData_fax.email_address ===
                                    '') ? (
                                <p
                                    className='error m-0'
                                    style={{ color: 'red' }}
                                >
                                    Email Address is required.
                                </p>
                            ) : null */}
                            {props.emailValidation === true ||
                            props.emailVerify === true ? (
                                <p
                                    className='error m-0'
                                    style={{ color: 'red' }}
                                >
                                    Invalid email address.
                                </p>
                            ) : null}
                        </div>
                    </>
                    {props.calingFrom !== 'upload' && (
                        <div className={`transition-select ${classrow}`}>
                            <label className='pl-5'>DELIVERY METHOD</label>
                            <div className=''>
                                <select
                                    id='delete_selectbrand'
                                    className={
                                        (props.validateEdit &&
                                            (delivery_method === '' ||
                                                delivery_method ===
                                                    undefined)) ||
                                        (props.physician_validation &&
                                            props.physicianData_fax
                                                .delivery_method === '')
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        delivery_method !== ''
                                            ? delivery_method
                                            : ''
                                    }
                                    onChange={(event) => {
                                        handleInputOnChange(
                                            'delivery_method',
                                            event
                                        );
                                    }}
                                >
                                    <option value=''>Select</option>
                                    {deliverymethod &&
                                    Array.isArray(deliverymethod)
                                        ? deliverymethod.map((data, index) => (
                                              <option key={index} value={data}>
                                                  {data}
                                              </option>
                                          ))
                                        : null}
                                </select>
                            </div>
                            <i
                                className='fa-light fa-chevron-down'
                                style={{ pointerEvents: 'none' }}
                            />
                            {(props.validateEdit &&
                                (delivery_method === '' ||
                                    delivery_method === undefined)) ||
                            (props.physician_validation &&
                                props.physicianData_fax.delivery_method ===
                                    '') ? (
                                <p
                                    className='error m-0'
                                    style={{
                                        color: 'red',
                                        fontWeight: 600,
                                    }}
                                >
                                    Delivery Method is required.
                                </p>
                            ) : null}
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {props.selectedPhysician !== undefined &&
                        props.selectedPhysician.npi !== undefined && (
                            <div
                                className='patient_detail edit-detail-bg'
                                style={{ backgroundColor: '#F0F7FB' }}
                            >
                                <div
                                    style={{ display: 'flex' }}
                                    className='form-group row'
                                >
                                    <span className='col-lg-4'>
                                        <span className='patient_label'>
                                            NPI
                                        </span>
                                        <br />
                                        <span className='patient_info'>
                                            {npi !== '' ? `#${npi}` : '-'}
                                        </span>
                                    </span>

                                    <span className='col-lg-4'>
                                        <span className='patient_label'>
                                            PHYSICIAN FIRST NAME
                                        </span>
                                        <br />
                                        <span className='patient_info'>
                                            {physician_first_name !== ''
                                                ? physician_first_name
                                                : '-'}
                                        </span>
                                    </span>

                                    <span className='col-lg-4'>
                                        <span className='patient_label'>
                                            PHYSICIAN LAST NAME
                                        </span>
                                        <br />
                                        <span className='patient_info'>
                                            {physician_last_name !== ''
                                                ? physician_last_name
                                                : '-'}
                                        </span>
                                    </span>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '20px',
                                    }}
                                    className='form-group row mb-0'
                                >
                                    <span className='col-lg-4'>
                                        <span className='patient_label'>
                                            FAX NUMBER
                                        </span>
                                        <br />
                                        <span className='patient_info'>
                                            {fax_number !== '' &&
                                            fax_number !== undefined
                                                ? faxCellFormatter(fax_number)
                                                : '-'}
                                        </span>
                                    </span>
                                    <span className='col-lg-4'>
                                        <span className='patient_label'>
                                            PHONE NUMBER
                                        </span>
                                        <br />
                                        <span className='patient_info'>
                                            {phone_number !== '' &&
                                            phone_number !== undefined
                                                ? faxCellFormatter(phone_number)
                                                : '-'}
                                        </span>
                                    </span>
                                    <span className='col-lg-4'>
                                        <span className='patient_label'>
                                            EMAIL ADDRESS
                                        </span>
                                        <br />
                                        <span className='patient_info p-email'>
                                            {email_address !== ''
                                                ? email_address
                                                : '-'}
                                        </span>
                                    </span>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '20px',
                                    }}
                                    className='form-group row mb-0'
                                >
                                    <span className='col-lg-4'>
                                        <span className='patient_label'>
                                            DELIVERY METHOD
                                        </span>
                                        <br />
                                        <span className='patient_info'>
                                            {delivery_method !== ''
                                                ? delivery_method
                                                : '-'}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        )}
                </>
            )}
        </div>
    );
};
export default ViewEditPhysician;
