/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
//import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import Logout from './Logout';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import SuccessPage from './SuccessPage';
import { ProcessPassword } from './ProcessPassword';
import ChangePassword from './ChangePassword';
//import web from '../../../../'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';

//const { PUBLIC_URL } = process.env;
function AuthPage(props) {
    React.useEffect(() => {}, [props.location]);
    return (
        <>
            <div className='d-flex flex-column flex-root'>
                {/* begin::Login */}
                <div
                    className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid /*bg-white*/'
                    id='kt_login'
                    style={
                        {
                            // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
                        }
                    }
                >
                    {/* begin::Content */}
                    <div className='flex-row-fluid d-flex flex-column position-relative px-7 py-0 overflow-hidden'>
                        {/* begin::Content header */}
                        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
              </div> */}
                        {/* end::Content header */}

                        {/* begin::Content body */}
                        <div className='login-bg align-items-center  flex-column-fluid flex-center py-10 mt-10 mb-10 mt-lg-0 mb-lg-0 mt-sm-0 mb-sm-0 px-5 d-flex'>
                            {/* d-flex */}
                            <Switch>
                                <ContentRoute
                                    path='/auth/login'
                                    component={Login}
                                />
                                <ContentRoute
                                    path='/auth/logout'
                                    component={Logout}
                                />
                                <ContentRoute
                                    path='/auth/registration'
                                    component={Registration}
                                />
                                {/* <ContentRoute path="/change-password" component={ChangePassword}/> */}
                                <ContentRoute
                                    path='/auth/confirmation'
                                    component={SuccessPage}
                                />
                                <ContentRoute
                                    path='/process-forgot-password'
                                    component={ProcessPassword}
                                />
                                <ContentRoute
                                    path='/auth/forgot-password'
                                    component={ForgotPassword}
                                />
                                <ContentRoute
                                    path='/auth/change-password'
                                    component={ChangePassword}
                                />

                                <Redirect from='/auth' exact to='/' />
                                <Redirect to='/auth/login' />
                            </Switch>
                        </div>
                        {/* end::Content body */}

                        {/* begin::Mobile footer */}
                        {/* <div
                  className="d-flex flex-column-auto flex-column text-center flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-white font-weight-bold order-2 order-sm-1 my-2">
                  &copy; 2021 Esignature
                </div>
               <div className="d-flex order-1 order-sm-2 my-2">
                  <Link to="/terms" className="text-dark-75 text-hover-primary">
                    Privacy
                  </Link>
                  <Link
                      to="/terms"
                      className="text-dark-75 text-hover-primary ml-4"
                  >
                    Legal
                  </Link>
                  <Link
                      to="/terms"
                      className="text-dark-75 text-hover-primary ml-4"
                  >
                    Contact
                  </Link>
                </div>
              </div> */}
                        {/* end::Mobile footer */}
                    </div>
                    {/* end::Content */}
                </div>
                {/* end::Login */}
            </div>
        </>
    );
}

export default withRouter(AuthPage);
