/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
//import SVG from "react-inlinesvg";
import {
    getConfig,
    GetCounter,
    checkIsActive,
    constructFormMenu,
    getdefaultLinkFromFormMenu,
} from '../../../../_helpers';
import { formatNameWithSpaceTohipen } from '../../../../../app/components/commonfunction';
import { useSelector, useDispatch } from 'react-redux';
import { updateSearchState } from '../../../../../app/pages/_redux/actions/searchActions';
// import { getListaction, updateOutboxState } from '../../../../../app/pages/_redux/actions/outboxActions';
export function FormMenu({ layoutProps }) {
    const location = useLocation();
    const CounterData = GetCounter();

    // START: Worked by vijay for adding sample data for menu section on 01-07-2021
    const configData = getConfig();

    const menuItems = configData.length > 0 ? configData[0].menuItems : [];

    var formsArray = configData.length > 0 ? configData[0].forms : [];
    const formsCategory = constructFormMenu(formsArray, menuItems);
    const getdefaultLink = getdefaultLinkFromFormMenu(formsArray, menuItems);
    const [filterText, setFilterText] = useState('');
    const [filter, setFilter] = useState('');

    const [forms, setForms] = useState(formsArray);
    const [menuRefresh, setMenuRefresh] = useState(true);

    React.useEffect(() => {
        // if (document.body.classList.contains('doc-small-view')) {
        //     setMenuRefresh(false);
        // }
    }, []);

    const current_path = location.pathname;
    const path_arr = current_path.split('/');
    var current_menu = path_arr[1];
    var current_menu_cat = '';
    if (path_arr.length >= 2) {
        current_menu_cat = path_arr[2];
    }

    var filter_tex = '';
    const handleInputOnChangeForm = async (filter, event) => {
        var forms = configData.length > 0 ? configData[0].forms : [];
        filter_tex = event.target.value;
        if (filter_tex !== '' && filter_tex !== undefined) {
            setFilter(filter_tex);
            filter_tex = filter_tex.toLowerCase();
            let filterResult = [];
            for (let i = 0; i < forms.length; i++) {
                if (forms[i].name.toLowerCase().includes(filter_tex)) {
                    forms[i].originalname = forms[i].name;
                    forms[i].name = forms[i].name.replace(
                        new RegExp(filter_tex, 'gi'),
                        '<span class="highlight">$&</span>'
                    );
                    filterResult.push(forms[i]);
                }
            }

            var forms = filterResult;
            setForms(filterResult);

            const boxes = document.querySelectorAll('.menu-item-submenu');

            for (const box of boxes) {
                box.classList.add(
                    'menu-item-submenu',
                    'menu-item',
                    'menu-item-open'
                );
            }
        } else {
            setFilter(filter_tex);
            setForms(forms);
        }
    };

    const reduxStore = useSelector((state) => state);
    let dispatch = useDispatch();

    // END: Worked by vijay for adding sample data for menu section on 01-07-2021
    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const { pathname } = location;
        return pathname === '/';
    };

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
            : '';
    };

    const getMenuItemActiveCustom = (cat) => {
        if (formatNameWithSpaceTohipen(cat) === current_menu_cat) {
            return 'menu-item-active menu-item-open';
        }
    };

    const getMenuItemActiveDefault = (cateeCount) => {
        if (current_menu === 'forms' && cateeCount === 1) {
            return 'menu-item-active menu-item-open';
        }
    };

    const getMenuItemDisplay = (itemcat) => {
        var display = 'block';
        var exists = 0;
        {
            forms.map((item, index) => {
                if (
                    String(formatNameWithSpaceTohipen(item.category)) ===
                    String(formatNameWithSpaceTohipen(itemcat))
                ) {
                    exists++;
                }
            });
            if (exists === 0) {
                display = 'none';
            } else {
                display = 'block';
            }
            return display;
        }
    };

    /*const getDefaultUrl = (itemcat) => {
        var linking = '';
        {
            forms.map((item, index) => {
                if (
                    index === 0 &&
                    String(formatNameWithSpaceTohipen(item.category)) ===
                        String(formatNameWithSpaceTohipen(itemcat))
                ) {
                    linking =
                        '/forms/' +
                        formatNameWithSpaceTohipen(item.category) +
                        '/' +
                        formatNameWithSpaceTohipen(item.name) +
                        '/' +
                        item.formId;
                    return linking;
                }
            });
        }

        return linking;
    };*/

    return (
        <div
            id='kt_aside'
            className='aside aside-left rounded-lg   aside-fixed d-flex flex-column flex-row-auto'
        /* style={{ height: window.innerHeight - 160 }}*/
        >
            <div
                id='kt_aside_menu_wrapper'
                className='aside-menu-wrapper flex-column-fluid'
            >
                <Scrollbars
                    id='kt_aside_menu'
                    data-menu-vertical='1'
                    className='aside-menu '
                >
                    <div className='report-left-top'>

                        {menuRefresh === true ? (
                            <div className='filter'>
                                <input
                                    type='text'
                                    name='filter_tex'
                                    placeholder='Filter'
                                    className='form-control'
                                    onChange={(event) => {
                                        handleInputOnChangeForm(filter, event);
                                    }}
                                    value={filter}
                                />
                                <i className='icon-md fab fa-sistrix' />
                            </div>
                        ) : null}
                    </div>
                    {menuItems && menuItems.length > 0
                        ? menuItems.map((data, key) => {
                            if (
                                data.menuName.toLowerCase() === current_menu
                            ) {
                                let catee = 0;
                                return (
                                    <div key={key}>
                                        {/* {!document.body.classList.contains(
                                              'doc-small-view'
                                          ) && menuRefresh === true ? ( */}
                                        {forms.length > 0 ? (
                                            <ul
                                                className='menu-nav p-0'
                                                key={'aside-main-' + key}
                                            >
                                                {formsCategory.map(
                                                    (itemcat) => {
                                                        catee++;
                                                        return (
                                                            <React.Fragment
                                                                key={
                                                                    'aside-frag-' +
                                                                    itemcat
                                                                }
                                                            >
                                                                <li
                                                                    style={{
                                                                        display: `${getMenuItemDisplay(
                                                                            itemcat
                                                                        )}`,
                                                                    }}
                                                                    className={`menu-item menu-item-submenu 
																																			
																			${getMenuItemActive(
                                                                        '/' +
                                                                        current_menu +
                                                                        '/' +
                                                                        formatNameWithSpaceTohipen(
                                                                            itemcat
                                                                        ),
                                                                        true
                                                                    )}`}
                                                                    //${getMenuItemActiveCustom(itemcat)}

                                                                    aria-haspopup='true'
                                                                    data-menu-toggle='hover'
                                                                >
                                                                    <NavLink
                                                                        className={
                                                                            'menu-link menu-toggle'
                                                                        }
                                                                        title={
                                                                            itemcat
                                                                        }
                                                                        id='menu_search_1'
                                                                        /*to={'#ngo'
                                                                            `${getDefaultUrl(
                                                                            itemcat
                                                                        )}`}*/
                                                                        to={
                                                                            '/' +
                                                                            current_menu +
                                                                            '/' +
                                                                            formatNameWithSpaceTohipen(
                                                                                itemcat
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className='menu-text'>
                                                                            {
                                                                                itemcat
                                                                            }
                                                                        </span>
                                                                        <i className='menu-arrow' />
                                                                    </NavLink>
                                                                    <div className='menu-submenu '>
                                                                        <i className='menu-arrow' />
                                                                        <ul className='menu-subnav'>
                                                                            <li
                                                                                className='menu-item  menu-item-parent'
                                                                                aria-haspopup='true'
                                                                            >
                                                                                <span className='menu-link'>
                                                                                    <span className='menu-text'>
                                                                                        {
                                                                                            itemcat
                                                                                        }
                                                                                    </span>
                                                                                </span>
                                                                            </li>

                                                                            {forms.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => {

                                                                                    var linkBuild = formatNameWithSpaceTohipen(
                                                                                        item.name
                                                                                    );
                                                                                    if (item.originalname !== undefined && item.name !== '') {
                                                                                        linkBuild = formatNameWithSpaceTohipen(
                                                                                            item.originalname
                                                                                        );
                                                                                    }

                                                                                    if (
                                                                                        data.forms.includes(
                                                                                            item.formId
                                                                                        ) &&
                                                                                        String(
                                                                                            item.category
                                                                                        ) ===
                                                                                        String(
                                                                                            itemcat
                                                                                        )
                                                                                    ) {
                                                                                        return (
                                                                                            <React.Fragment
                                                                                                key={
                                                                                                    'aside-frag-' +
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                <li
                                                                                                    className = {path_arr.length >= 3 &&  String(path_arr[4]) === String(item.formId) ?
                                                                                                        'menu-item active' :
                                                                                                        'menu-item'
                                                                                                    } 
                                                                                                    aria-haspopup='true'
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    <NavLink
                                                                                                        /* className={`menu-link ${getMenuItemActive(
                                                                                                            '/forms/' +
                                                                                                            item.formId
                                                                                                        )}`} */
                                                                                                        className = {`menu-link`}
                                                                                                        to={
                                                                                                            '/' +
                                                                                                            current_menu +
                                                                                                            '/' +
                                                                                                            formatNameWithSpaceTohipen(
                                                                                                                item.category
                                                                                                            ) +
                                                                                                            '/' +
                                                                                                            linkBuild +
                                                                                                            '/' +
                                                                                                            item.formId
                                                                                                        }
                                                                                                    >
                                                                                                        {/*<span className='menu-text'>
                                                                                                              {
                                                                                                                  item.name
                                                                                                              }
																									  </span>*/}
                                                                                                        <div
                                                                                                            className='menu-text'
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: item.name,
                                                                                                            }}
                                                                                                        />
                                                                                                    </NavLink>
                                                                                                </li>
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        ) : (
                                            <div className='no-record-menu'>
                                                No Results Found
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        })
                        : null}
                </Scrollbars>
            </div>
        </div>
    );
}

/*const mapDispatchToProps = {
    //showLoader: (canShow) => showLoader(canShow),
    getListaction: getListaction,
};
const mapStateToProps = (state, ownProps) => {
    return {
        outbox: state.outbox,            
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
        
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportMenu))
);*/
