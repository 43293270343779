/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { outboxDocumentAction } from '../../api/api';
import {
    formatNameWithSpaceToUnderscore,
    showSuccessToast,
    showErrorToast,
} from '../../../components/commonfunction';
import {
    getSelectedRowDetails,
    inboxPage,
    updateInboxState,
    updateList,
} from '../../_redux/actions/inboxActions';

const UpdateFaxDetails = (props) => {
    //const [openUpdateNotes, setOpenUpdateNotes]=useState('');
    const [updateValue, setUpdateValue] = useState('');
    const [initialValue, setInitialValue] = useState('');
    const [notesSubmitted, setNotesSubmitted] = useState(0);
    const [checked] = useState('checked');
    const redux_store = useSelector((state) => state);
    // const btnGroupDrop = `btnGroupDrop1_${Math.random()}`;
    const postLoading = `post_loading_${Math.random()}`;
    // const posted = `posted_${Math.random()}`;
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.updateValue !== undefined) {
            setUpdateValue(props.updateValue);
            setInitialValue(props.updateValue);
        }
    }, [props.updateValue]);

    const handleUpdateFaxText = (event) => {
        setUpdateValue(event.target.value);
    };
    const handleCloseAction = (event) => {
        setUpdateValue(initialValue ? initialValue : '');
        //setOpenUpdateNotes("false")
        setNotesSubmitted(0);
        props.updateState({
            openUpdateNotes: false,
        });
    };
    const handleUpdateFaxSubmit = async () => {
        setNotesSubmitted(1);
        let processId = 1;
        let statusId = 1;
        var Fields = [];
        if (props.calingFrom === 'deleted_document') {
            processId = props.processId;
            statusId = props.statusId;
        } else {
            if (String(redux_store.inbox.processId) !== '') {
                processId = redux_store.inbox.processId;
            }
            if (String(redux_store.inbox.statusId) !== '') {
                statusId = redux_store.inbox.statusId;
            }
        }
        Fields = redux_store.configReducerPersist.configData.fields;
        var allFields = [];

        Fields.map((data) => {
            allFields.push({
                fieldId: data.fieldId,
                name: formatNameWithSpaceToUnderscore(data.name),
            });
        });
        let fax_id;
        var params = {};
        var fields = {};
        fields.docIds = props.selectedDocId;
        allFields.map((dataValue) => {
            if (dataValue.name === 'fax_detail') {
                fax_id = dataValue.fieldId;
                fields.fields = [
                    {
                        fieldId: dataValue.fieldId,
                        value: updateValue,
                    },
                ];
            }
        });

        let actionId;
        if (props.calingFrom === 'deleted_document') {
            actionId =
                redux_store.search.deletedPage.allActions.update_fax_details
                    .actionId;
        } else if (
            Object.keys(redux_store.inbox.stateData).length !== 0 &&
            Object.keys(redux_store.inbox.stateData.allActions).length !== 0
        ) {
            if (
                redux_store.inbox.stateData.allActions.update_fax_details !==
                undefined
            )
                actionId =
                    redux_store.inbox.stateData.allActions.update_fax_details
                        .actionId;
        } else if (
            props.user_permission.allActions !== undefined &&
            props.user_permission.allActions.update_fax_details !== undefined
        ) {
            actionId =
                props.user_permission.allActions.update_fax_details.actionId;
            statusId = props.user_permission.statusId;
        }

        params.processId = processId;
        params.statusId = statusId;
        params.actionId = actionId;
        params.fields = fields;
        let successDocId = [];
        let failedDocId = [];
        await outboxDocumentAction(params)
            .then(async (response) => {
                let result = await response.json();

                return result;
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                }
            });
        let message;
        let messageSucc;
        if (failedDocId.length !== 0) {
            props.updateState({
                penUpdateNotes: false,
            });
            setNotesSubmitted(0);
            if (failedDocId.length === 1) {
                message = `Doc Id  ${failedDocId[0]} is  Locked`;
            } else {
                message = `Doc Ids `;
                failedDocId.map(async (item, idx) => {
                    if (
                        idx !== failedDocId.length - 1 &&
                        idx !== failedDocId.length - 2
                    ) {
                        message += `${item}, `;
                    } else if (idx === failedDocId.length - 2) {
                        message += `${item}`;
                    } else {
                        message += ` and ${item} `;
                    }
                });
                message += `are Locked`;
            }
            props.updateState({
                openUpdateNotes: false,
                more_information: false,
            });
            setTimeout(() => {
                props.updateState({
                    more_information: true,
                });
            }, 10);
            setNotesSubmitted(0);
            showErrorToast(message);
        }

        if (successDocId.length !== 0) {
            props.updateState({
                penUpdateNotes: false,
            });
            setNotesSubmitted(0);
            if (successDocId.length === 1) {
                messageSucc = `Fax Details ( ${successDocId[0]} ) updated successfully.`;
            } else {
                messageSucc = `Fax Details (`;
                successDocId.map(async (item, idx) => {
                    if (
                        idx !== successDocId.length - 1 &&
                        idx !== successDocId.length - 2
                    ) {
                        messageSucc += `${item}, `;
                    } else if (idx === successDocId.length - 2) {
                        messageSucc += `${item}`;
                    } else {
                        messageSucc += ` and ${item} `;
                    }
                });
                messageSucc += `) updated successfully.`;
            }

            showSuccessToast('Fax Details updated successfully.');

            let r = 0;
            let commentsUpdated = 0;
            let faxDetailIndex;
            if (
                redux_store.inbox.inbox &&
                redux_store.inbox.inbox.dataTable &&
                redux_store.inbox.inbox.dataTable.headerColumns
            ) {
                await redux_store.inbox.inbox.dataTable.headerColumns.map(
                    (data, index) => {
                        if (data.name.toLowerCase() === 'faxdetail') {
                            faxDetailIndex = index;
                        }
                        return data;
                    }
                );
            }

            dispatch(
                inboxPage({
                    faxDetailIndex: faxDetailIndex,
                })
            );
            if (props.calingFrom !== 'row-selection') {
                let selectedRowDetailsArray = [];
                if (props.calingFrom === 'deleted_document') {
                    selectedRowDetailsArray =
                        redux_store.search.selectedRowDetails.fields;
                } else {
                    selectedRowDetailsArray =
                        redux_store.inbox.selectedRowDetails.fields;
                }

                const newstate = [...selectedRowDetailsArray];
                selectedRowDetailsArray.map((data) => {
                    if (data.fieldId === fax_id) {
                        newstate[r] = {
                            ...newstate[r],
                            fieldId: data.fieldId,
                            value: updateValue,
                        };
                        commentsUpdated = 1;
                    }
                    r++;
                });

                let len;
                if (props.calingFrom === 'deleted_document') {
                    len = redux_store.search.selectedRowDetails.fields;
                } else {
                    len = redux_store.inbox.selectedRowDetails.fields.length;
                }

                let j = len;
                if (commentsUpdated === 0) {
                    newstate[j + 1] = {
                        ...newstate[r + len],
                        fieldId: fax_id,
                        value: updateValue,
                    };
                    j += 1;
                }

                if (props.calingFrom === 'deleted_document') {
                    redux_store.search.selectedRowDetails.fields = newstate;
                } else {
                    redux_store.inbox.selectedRowDetails.fields = newstate;
                }

                if (props.calingFrom !== 'deleted_document') {
                    dispatch(
                        getSelectedRowDetails(
                            redux_store.inbox.selectedRowDetails
                        )
                    );

                    let faxDetailIndexPosition = '';
                    redux_store.inbox.inbox.dataTable.headerColumns.map(
                        (data, index) => {
                            if (data.name.toLowerCase() === 'fax detail') {
                                faxDetailIndexPosition = index; //5
                            }
                            return data;
                        }
                    );
                    redux_store.inbox.inbox.dataTable.dataRows.map(
                        (data, index) => {
                            if (
                                String(data.id) === String(props.selectedDocId)
                            ) {
                                redux_store.inbox.inbox.dataTable.dataRows[
                                    index
                                ].values[faxDetailIndexPosition] = updateValue;
                            }
                            //return data;
                        }
                    );

                    //dispatch(updateList(redux_store.inbox));
                    dispatch(
                        inboxPage({
                            dataTable: redux_store.inbox.inbox.dataTable,
                            faxUpdate: true,
                            selectedRowDetails:
                                redux_store.inbox.selectedRowDetails,
                        })
                    );
                } else if (props.calingFrom === 'deleted_document') {
                    // props.refreshForNextDocument();
                }
            }
            if (props.calingFrom === 'row-selection') {
                let selectedRowIndex = [];

                let selectedRows = Array.from(props.selectedRows);
                redux_store.inbox.stateData.rowData.map((data, index) => {
                    if (selectedRows.includes(index)) {
                        selectedRowIndex.push(data.id);
                    }
                });

                selectedRowIndex.map((data) => {
                    let id = redux_store.inbox.stateData.rowData[data].doc_id;

                    if (successDocId.includes(id) === true) {
                        redux_store.inbox.stateData.rowData[data].faxdetail =
                            updateValue;
                    }
                });

                dispatch(
                    updateInboxState({
                        rowData: redux_store.inbox.stateData.rowData,
                    })
                );
                let faxDetailIndexPosition = '';
                redux_store.inbox.inbox.dataTable.headerColumns.map(
                    (data, index) => {
                        if (data.name.toLowerCase() === 'fax detail') {
                            faxDetailIndexPosition = index; //5
                        }
                        return data;
                    }
                );
                selectedRowIndex.map((data) => {
                    let id = redux_store.inbox.stateData.rowData[data].doc_id;
                    redux_store.inbox.inbox.dataTable.dataRows.map(
                        (data, index) => {
                            if (String(data.id) === String(id)) {
                                redux_store.inbox.inbox.dataTable.dataRows[
                                    index
                                ].values[faxDetailIndexPosition] = updateValue;
                            }
                            //return data;
                        }
                    );
                });
                dispatch(
                    inboxPage({
                        dataTable: redux_store.inbox.inbox.dataTable,
                        faxUpdate: true,
                    })
                );
            }
            props.updateState({
                openUpdateNotes: false,
                more_information: false,
            });
            setTimeout(() => {
                props.updateState({
                    more_information: true,
                });
            }, 10);
            setNotesSubmitted(0);
        }
    };

    return (
        <>
            <Modal
                show={props.openUpdateNotes}
                onHide={() => handleCloseAction()}
                animation={false}
                size='lg'
                className='notes-popup'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                    onClick={() => handleCloseAction()}
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>
                            <span className='delivery-details'>
                                <svg
                                    className='icon-fill'
                                    width='39'
                                    height='34'
                                    viewBox='0 0 39 34'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                </svg>{' '}
                                Fax Details
                            </span>
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={() => handleCloseAction()}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='notes d-flex'>
                    <div className='d-flex w-100'>
                        <input
                            className='mr-3'
                            type='text'
                            placeholder='Update Fax Details '
                            onChange={(event) => handleUpdateFaxText(event)}
                            value={updateValue}
                            maxLength={250}
                            //disabled={props.isLocked === true ? true : false}
                        />

                        {/* <input className="mr-3" type="text" placeholder="Type your note here" onChange={(event) => handleNotesText(event)} value={notesValue} /> */}
                    </div>
                    <div className='notes-btn d-flex'>
                        <div className='d-flex'>
                            <div className='btn-group' role='group'>
                                {/* <button title="Post Note" id={btnGroupDrop} type="button" className="btn success font-weight-bold dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={(notesValue === '')}> */}
                                <div className='btn-group' role='group'>
                                    <button
                                        title='Post Note'
                                        id='btnGroupDrop1'
                                        type='button'
                                        className='btn success font-weight-bold dropdown-toggle'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                        disabled={updateValue === ''}
                                    >
                                        Update
                                    </button>
                                </div>
                                {/* </button> */}
                            </div>
                            {notesSubmitted === 1 ? (
                                <div className='checkbox-note' role='group'>
                                    <button
                                        className='post_loading post_loading icon_fun fix-now-btn'
                                        id={postLoading}
                                    >
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    </button>
                                </div>
                            ) : (
                                <div className='checkbox-note' title='Save'>
                                    <label className='checkbox checkbox-success'>
                                        <input
                                            type='checkbox'
                                            disabled={updateValue === ''}
                                            name='Checkboxes5'
                                            defaultChecked={checked}
                                            onClick={() =>
                                                handleUpdateFaxSubmit()
                                            }
                                        />
                                        <span
                                            className={
                                                updateValue === ''
                                                    ? 'disable'
                                                    : ''
                                            }
                                        >
                                            <i className='fa-light fa-check' />
                                        </span>
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-block border-0' />
            </Modal>
        </>
    );
};

export default UpdateFaxDetails;
