/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
const postLoading = `post_loading_${Math.random()}`;

export class CreateCoordinatorModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnLoader: false,
        };
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.popUp}
                    onHide={this.props.handleCloseOperation}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i className='fas fa-user-plus' />
                                Create New Co-ordinator
                            </div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.props.handleCloseOperation}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes'>
                        <h6 className='mb-5'>
                            Are you sure you want to create this Co-ordinator?
                        </h6>
                        <div className='form-group row '>
                            <div className='col-sm-6 col-6'>
                                <input
                                    // placeholder={props.ownerName}
                                    autoFocus={true}
                                    type='text'
                                    className={
                                        this.props.emptyOwner
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={this.props.ownerName}
                                    onChange={(event) => {
                                        this.props.ownerNameConfirm(
                                            event.target.value
                                        );
                                    }}
                                />
                                {this.props.emptyOwner ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Co-ordinator name is required.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    variant='secondary'
                                    onClick={this.props.handleCloseOperation}
                                    title='Cancel'
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                {this.state.btnLoader === true ? (
                                    <Button
                                        type='button'
                                        variant='success'
                                        className='post_loading'
                                        id={postLoading}
                                    >
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        variant='success'
                                        onClick={() => {
                                            this.props.createCoordinator();
                                        }}
                                        title='Add New User'
                                    >
                                        Yes
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateCoordinatorModule));
