/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useCombinedRefs } from './useCombinedRefs';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

let initialUniqueArray = [];

export function DraggableHeaderRenderer(
    {
        onColumnsReorder,
        column,
        sortDirection,
        // onSort,
        // priority,
        uniqueData,
        setGrouping,
        // groupBy,
        // defaultOpen,
        groupByData,
        sortFields,
        sortColumn,
        // menu_tags,
        defaultPopOpenState,
        // header,
        uniqueTagNameValues,
        // responsive,
        handleClickOutside,
        filteredRowData,
        row_transition,
        dateFormatFields,
        groupBy,
        totalColumn,
        childUniqueData,
        childTagNameValues,
    },
    props
) {
    if (uniqueData.hasOwnProperty('action')) {
        uniqueData.action = '';
        //  uniqueData.dateofbirth = '';
    }

    if (
        typeof childUniqueData === 'object' &&
        childUniqueData.hasOwnProperty('action')
    ) {
        childUniqueData.action = '';
        // childUniqueData.dateofbirth = '';
    }

    //const IndexName = String(column.name).replace(/\s/g, '').toLowerCase();
    //going to remove - from the header name since we use only lower case , if any changes in header name need to restrict Index name
    // I am changing for lastfollow-up to lastfollowup
    const IndexName = String(column.name).replace(/\s|-/g, '').toLowerCase();

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState(null);
    const [position, setPosition] = React.useState({ left: 0, top: 0 });
    const inputRef = React.useRef(null);
    const tagName = new Set();
    let uniqueTagName =
        uniqueTagNameValues !== undefined
            ? Array.from(uniqueTagNameValues)
            : [];
    uniqueTagName.forEach((data) => {
        if (data.trim() !== '') {
            data.split('|').forEach((val) => {
                if (data !== undefined) {
                    val =
                        val.charAt(0).toUpperCase() + val.slice(1, data.length); // First Letter UpperCase
                    if (val !== undefined) {
                        tagName.add(val);
                    }
                }
            });
        }
    });
    uniqueTagName = Array.from(tagName);
    // For Dragging and Droping
    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN_DRAG',
        item: { key: column.key },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'COLUMN_DRAG',
        drop({ key }) {
            onColumnsReorder(key, column.key);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    // End
    // Open PopOver Functionality
    const handleClick = (event, name) => {
        handleClickOutside(event, true);
        const elementPosition = event.currentTarget.getBoundingClientRect();
        const positionTemp = {
            top: parseInt(elementPosition.top, 10),
            left: parseInt(elementPosition.x, 10),
        };
        const key = `${name}_position`;
        setAnchorEl(event.currentTarget);
        setPosition(positionTemp);
        setTimeout(() => {
            if (
                document.getElementById('search-input') !== null &&
                document.getElementById('search-input') !== undefined
            ) {
                document.getElementById('search-input').focus();
            }
        }, 100);
        localStorage.setItem(key, JSON.stringify(positionTemp));
    };

    /* const handleClose = () => {
        setAnchorEl(null);
    }; */
    // For Single data selection
    const handleGrouping = (data, event) => {
        setGrouping(data, '', event.target.checked, '', true);
    };
    /* const isValid = (str) => {
        return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
    } */
    const handleSearchAction = (txt, index, event) => {
        txt = String(txt).replaceAll(/\\/g, ''); // Remove backward slash from string
        setSearchValue({ index, txt });
    };
    // Select / unSelect Function
    const selectAllHandler = (title, event, selectData) => {
        let uniqueDataArray =
            IndexName !== 'tags' && uniqueData !== undefined
                ? Array.from(uniqueData[IndexName])
                : uniqueTagName;
        uniqueDataArray = uniqueDataArray.filter((data) => {
            return data.trim() !== '';
        });
        const mode = !(
            groupByData[title] !== undefined &&
            groupByData[title].length === uniqueDataArray.length
        ); // Checking All Data Selected Or Not
        setGrouping(
            {
                title: title,
                value: selectData,
            },
            'ALL',
            mode
        );
    };
    // Clear all Function
    const handleClearAll = (event, title) => {
        setGrouping(
            {
                title: title,
                value: [],
            },
            'ALL',
            false
        );
        setSearchValue({ index: title, txt: '' });
    };
    let open = Boolean(anchorEl);

    React.useEffect(() => {
        let IndexClass = IndexName;
        if (IndexName.indexOf('&') !== -1) {
            // Remove (&) symbol, className won`t accept it
            IndexClass = IndexClass.replace('&', '-');
        }
        if (
            !open &&
            defaultPopOpenState !== undefined &&
            defaultPopOpenState[IndexName] !== undefined
        ) {
            // Condition to open popOver
            open = defaultPopOpenState[IndexName];
            setAnchorEl(open);
            const columnheader = document.querySelectorAll(
                `.get-position-${IndexClass}`
            ); // Get first Popover Position
            if (columnheader !== null && columnheader[0] !== undefined) {
                // Get first Popover Position
                const elementPosition = columnheader[0].getBoundingClientRect(); // Get PopOver Position
                setPosition({
                    top: parseInt(elementPosition.top, 10),
                    left: parseInt(elementPosition.x, 10),
                }); // Set PopOver Position
            }
        }
    }, [anchorEl, inputRef, props]);

    // if (!open) {
    //  open = defaultOpen;
    // }

    let uniqueDataArray = [];
    if (
        IndexName.trim() !== '' &&
        uniqueData !== undefined &&
        uniqueData[IndexName] !== undefined
    ) {
        if (groupBy.length > 0 && childUniqueData !== undefined) {
            const child = {};
            const parent = {};
            for (const property in childUniqueData) {
                if (!groupBy.includes(property)) {
                    child[property] = childUniqueData[property];
                }
            }
            for (const property in uniqueData) {
                if (groupBy.includes(property)) {
                    parent[property] = uniqueData[property];
                }
            }
            if (Object.keys(child).includes(IndexName)) {
                uniqueDataArray =
                    IndexName !== 'tags'
                        ? Array.from(child[IndexName])
                        : uniqueTagName;
            }
            if (Object.keys(parent).includes(IndexName)) {
                uniqueDataArray =
                    IndexName !== 'tags'
                        ? Array.from(parent[IndexName])
                        : uniqueTagName;
            }
        } else {
            uniqueDataArray =
                IndexName !== 'tags'
                    ? Array.from(uniqueData[IndexName])
                    : uniqueTagName;
        }

        if (searchValue !== null) {
            // perform Search Action
            if (IndexName === 'userid') {
                uniqueDataArray =
                    searchValue.index !== 'tags'
                        ? Array.from(uniqueData[searchValue.index])
                        : uniqueTagName;
                uniqueDataArray = uniqueDataArray.filter((data) => {
                    if (typeof data === 'string')
                        data = data.toLowerCase().trim();
                    return data.toString().match(String(searchValue.txt));
                });
            } else {
                uniqueDataArray =
                    searchValue.index !== 'tags'
                        ? Array.from(uniqueData[searchValue.index])
                        : uniqueTagName;
                uniqueDataArray = uniqueDataArray.filter((data) => {
                    data = data.toLowerCase().trim();
                    return data.match(searchValue.txt);
                });
            }
        }
    }

    const id = open ? `simple-popover-${column.name}` : undefined;
    if (uniqueDataArray.length > 0 && uniqueDataArray !== undefined) {
        uniqueDataArray = uniqueDataArray.map((data) => {
            if (
                typeof data !== 'object' &&
                data !== undefined &&
                data !== '' &&
                data !== null
            ) {
                data = String(data);
                data = data.trim();
                return (
                    data.charAt(0).toUpperCase() + data.slice(1, data.length)
                ); // First Letter UpperCase
            }
            if (typeof data === 'object') {
                data = '';
            }
        });
    }
    uniqueDataArray = uniqueDataArray.filter((data) => {
        if (data !== undefined && data !== '') {
            return data.trim() !== '';
        }
    }); // Filter empty data
    uniqueDataArray = uniqueDataArray.sort();
    const DisplayData = uniqueDataArray;
    const displayDataInHtml = (index) => {
        const data = DisplayData[index];
        return data.length > 25 ? `${data.slice(0, 25)}...` : data;
    };
    let groupByValue =
        groupByData[IndexName] !== undefined ? groupByData[IndexName] : [];
    groupByValue = groupByValue.map((data) => {
        data = data.trim();
        return data.charAt(0).toUpperCase() + data.slice(1, data.length); // First Letter UpperCase
    });
    uniqueDataArray = uniqueDataArray.map((data) => {
        data = data.trim();
        return (
            data.charAt(0).toUpperCase() +
            data.slice(1, data.length).toLowerCase()
        ); // First Letter UpperCase
    });

    function handleClosePopUp() {
        for (const name in defaultPopOpenState) {
            if (defaultPopOpenState) {
                defaultPopOpenState[name] = false;
            }
        }
        open = false;
        setAnchorEl(null);
    }
    let IndexClass = IndexName;
    if (IndexName.indexOf('&') !== -1) {
        IndexClass = IndexClass.replace('&', '-');
    } // Set class to highlight asc or desc
    let activeSortClassASC = '';
    let activeSortClassDESC = '';
    if (IndexName === sortColumn && sortDirection === 'ASC') {
        activeSortClassASC = 'active-asc';
    }
    if (IndexName === sortColumn && sortDirection === 'DESC') {
        activeSortClassDESC = 'active-desc';
    }
    // End
    const isSelectAll =
        groupByValue.length === uniqueDataArray.length &&
        groupByValue.length !== 0; // Check SelectALL should be checked or not
    if (initialUniqueArray.length === 0) {
        initialUniqueArray = uniqueDataArray;
    }

    let dataFromTable = [];
    if (
        IndexName !== 'tags' &&
        uniqueData !== undefined &&
        uniqueData !== null &&
        uniqueData[IndexName] !== undefined
    ) {
        const temparr = Array.from(uniqueData[IndexName]);
        dataFromTable = temparr.filter((n) => n);
    }

    return (
        <div
            ref={useCombinedRefs(drag, drop)}
            style={{
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: isOver ? '#ececec' : 'inherit',
                cursor: 'move',
            }}
        >
            {IndexName.trim() !== '' ? (
                <>
                    {/* casing header in usermanagement and group managemnet added */}
                    <span className='header-name' title={column.name}>
                        {column.name.charAt(0).toUpperCase() +
                            column.name.slice(1)}
                    </span>

                    {groupBy !== undefined &&
                    groupBy.length > 0 &&
                    column.idx ===
                        totalColumn.length ? null : filteredRowData.length >
                          0 && row_transition === false ? (
                        <Button
                            aria-describedby={id}
                            key={String(Math.random()) + column.name}
                            variant='contained'
                            className='filter-popup'
                            // disabled={uniqueDataArray.length===0?true:false}
                            style={
                                dataFromTable.length === 0 ||
                                column.name == 'usergroups'
                                    ? { cursor: 'not-allowed' }
                                    : {}
                            }
                            onClick={(event) => {
                                if (
                                    dataFromTable.length !== 0 &&
                                    column.name != 'usergroups'
                                ) {
                                    handleClick(event, column.name);
                                }
                            }}
                        >
                            <i
                                title='Filter'
                                className={`fa fa-chevron-down filter-position get-position-${IndexClass}`}
                                aria-hidden='true'
                            />
                        </Button>
                    ) : null}

                    <Popover
                        id={id}
                        open={open}
                        anchorReference='anchorPosition'
                        anchorPosition={position}
                        //  onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleClosePopUp}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        style={{ maxHeight: '400px' }}
                        className='popover-menu-items'
                    >
                        {IndexName !== 'tags' &&
                        initialUniqueArray.length > 0 ? (
                            <div className='filter-sorting'>
                                <ul>
                                    <li
                                        key={0}
                                        onClick={() => {
                                            sortFields(
                                                IndexName,
                                                'ASC',
                                                dateFormatFields
                                            );
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        className={`custom-sort ${activeSortClassASC}`}
                                    >
                                        {' '}
                                        <i className='fa-regular fa-sort-alpha-down' />
                                        Sort A to Z
                                    </li>
                                    <li
                                        key={1}
                                        onClick={() =>
                                            sortFields(
                                                IndexName,
                                                'DESC',
                                                dateFormatFields
                                            )
                                        }
                                        style={{ cursor: 'pointer' }}
                                        className={`custom-sort ${activeSortClassDESC}`}
                                    >
                                        {' '}
                                        <i className='fa-regular fa-sort-alpha-up' />
                                        Sort Z to A{' '}
                                    </li>
                                </ul>
                            </div>
                        ) : null}

                        <span className='title'>
                            <i className='icon-xd fas fa-layer-group' /> Group
                            by this
                        </span>

                        {initialUniqueArray.length > 0 ? (
                            <>
                                <div
                                    className={
                                        IndexName === 'tags'
                                            ? 'filter-search tag-filter'
                                            : 'filter-search'
                                    }
                                >
                                    <div className='filter_wrap'>
                                        <i className='fa-regular fa-magnifying-glass' />
                                        <input
                                            value={
                                                searchValue !== null
                                                    ? searchValue.txt
                                                    : ''
                                            }
                                            maxLength={20}
                                            ref={inputRef}
                                            id='search-input'
                                            placeholder='Search values'
                                            className='search-textbox'
                                            onChange={(event) => {
                                                handleSearchAction(
                                                    event.target.value,
                                                    IndexName,
                                                    event
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className='inner-filter'
                                    style={{ float: 'left', width: '50%' }}
                                >
                                    <label>
                                        {' '}
                                        <span
                                            style={
                                                uniqueDataArray.length === 0
                                                    ? {
                                                          pointerEvents: 'none',
                                                          color: 'gray',
                                                      }
                                                    : {}
                                            }
                                            onClick={(event) => {
                                                selectAllHandler(
                                                    IndexName,
                                                    event,
                                                    uniqueDataArray
                                                );
                                            }}
                                        >
                                            {isSelectAll
                                                ? 'UnSelect All'
                                                : 'Select All'}{' '}
                                        </span>
                                    </label>
                                </div>
                                <div className='inner-filter'>
                                    <span
                                        title='Clear'
                                        style={
                                            uniqueDataArray.length === 0
                                                ? {
                                                      pointerEvents: 'none',
                                                      color: 'gray',
                                                  }
                                                : {}
                                        }
                                        onClick={(event) =>
                                            handleClearAll(event, IndexName)
                                        }
                                    >
                                        {' '}
                                        Clear
                                    </span>
                                </div>
                            </>
                        ) : null}

                        {uniqueDataArray.length > 0 ? (
                            uniqueDataArray.map((data, i) => {
                                const keyy = `grouping${i}`;
                                return (
                                    <Typography
                                        className={classes.typography}
                                        key={i}
                                    >
                                        {' '}
                                        <label key={i} htmlFor={keyy}>
                                            <span className='rdg-checkbox-label'>
                                                {' '}
                                                <input
                                                    type='checkbox'
                                                    checked={
                                                        groupByValue.indexOf(
                                                            data
                                                        ) !== -1
                                                    }
                                                    onChange={(event) => {
                                                        handleGrouping(
                                                            {
                                                                title: IndexName,
                                                                value: data,
                                                            },
                                                            event
                                                        );
                                                    }}
                                                    key={i}
                                                    id={keyy}
                                                />
                                                <span className='rdg-checkbox'></span>
                                            </span>{' '}
                                            <span title={data}>
                                                {displayDataInHtml(i)}
                                            </span>{' '}
                                        </label>
                                    </Typography>
                                );
                            })
                        ) : (
                            <Typography
                                className={`${classes.typography} norecordfound`}
                                key={0}
                            >
                                {' '}
                                No Data found{' '}
                            </Typography>
                        )}
                    </Popover>
                </>
            ) : null}
        </div>
    );
}
