import { formatHeaderNameWithOutSpace } from '../../../components/commonfunction';

export const mapApiDataToObjectModel = (apiData: any): any[] => {
    const objects: any[] = [];

    apiData?.dataTable?.dataRows?.forEach((row: any) => {
        const values = row.values;
        const object: any = {};

        apiData.dataTable.headerColumns.forEach((h: any) => {
            const propertyName = formatHeaderNameWithOutSpace(h.name);
            if (propertyName) {
                object.id = row.id;
                object[propertyName] =
                    values[
                    apiData.dataTable.headerColumns.findIndex(
                        (header: any) => header.name === h.name
                    )
                    ];
            }
        });

        objects.push({ ...object, id: row.id });
    });

    return objects;
};
