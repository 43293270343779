import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { stringEmptyOrUndefined } from '../../commonfunction';
import {
    setSelectedRow,
    setOwner,
    updateList,
    getListaction,
    updateOutboxState,
    showLoader,
} from '../../../pages/_redux/actions/outboxActions';
import GlobalActions from '../../../pages/deletedDocument/components/GlobalActions';
// import { updateSearchState } from '../../../../../app/pages/_redux/actions/searchActions';
import { updateSearchState } from '../../../pages/_redux/actions/searchActions';

class DeleteRowSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: new Set(),
            ...this.props.state,
            // flag: true,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState) {
        if (
            Array.from(this.props.state.selectedRows).length > 0 &&
            document.body.classList.contains('doc-small-view') === false &&
            this.props.search.deletedPage.row_transition === true
        ) {
            // added to handle pdf view on back side while select the document
            this.props.updateSearchState({
                deletedPage: {
                    ...this.props.search.deletedPage,
                    row_transition: false,
                },
            });
        }
    }

    handleMultiSelectClose = () => {
        this.props.updateState({
            selectedRows: new Set(),
        });
    };

    updateState = (state) => {
        this.setState({ ...state });
    };

    render() {
        return (
            <>
                {Array.from(this.props.state.selectedRows).length > 0 ? (
                    <div
                        className='bottom-function d-flex align-items-center sticky'
                        id='inbox_bottom_menu'
                    >
                        <span className='bottombar-txt'>
                            {Array.from(this.props.state.selectedRows).length}{' '}
                            {Array.from(this.props.state.selectedRows).length >
                            1
                                ? 'files'
                                : 'file'}{' '}
                            selected
                        </span>

                        <div className='outbox-outer-action'>
                            <GlobalActions
                                selectedDocId={this.props.selectedDocId}
                                callingFrom='rowSelection'
                                selectedRows={this.props.selectedRows}
                                allRows={this.props.state.allRows}
                                openNotesModal={this.props.openNotesModal}
                                docId={this.props.selectedRows}
                                updateState={this.updateState}
                                isLocked={false}
                                calingFrom='action_bar_delete'
                            />
                            <div className='bottom-icons d-flex'>
                                <OverlayTrigger
                                    placement='top'
                                    onEntering={this.entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Close
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <div className='icon_fun outer-col no-bg'>
                                        <i
                                            className='fa-light fa-xmark'
                                            onClick={() =>
                                                this.handleMultiSelectClose()
                                            }
                                        />
                                    </div>
                                </OverlayTrigger>
                            </div>
                            {/* this.state.printSelectedDocId.length > 0 ? (
                            <PrintPdfDocument
                                docId={this.state.printSelectedDocId}
                                loader={setPrinterloader}
                            />
                         ) : null */}
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    setOwner,
    updateList,
    setSelectedRow: setSelectedRow,
    getListaction: getListaction,
    updateOutboxState,
    showLoader: (data) => showLoader(data),
    updateSearchState,
};

const mapStateToProps = (state, ownProps) => {
    //   ('======ROW SELECTION=============state=>', state);
    return {
        outbox: state.outbox,
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        owners: state.outbox.owners,
        stateData: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.stateData
            : {},
        stateToken: state.outbox.stateToken,
        search: state.search,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteRowSelection))
);
