import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

export function ESignMenu() {
       return (
           <div
               id='kt_aside'
               className='aside aside-left rounded-lg aside-fixed d-flex flex-column flex-row-auto'
           >
               <div
                   id='kt_aside_menu_wrapper'
                   className='aside-menu-wrapper flex-column-fluid'
               >
                   <PerfectScrollbar
                       id='kt_aside_menu'
                       data-menu-vertical='1'
                       className='aside-menu'
                   >
                       <ul className='menu-nav'>
                           <li className='menu-item'>
                               <NavLink
                                   className='menu-link'
                                   to='/e-sign/manage-entities'
                               >
                                   <span className='menu-text'>
                                       Manage Entities
                                   </span>
                               </NavLink>
                           </li>
                       </ul>
                   </PerfectScrollbar>
               </div>
           </div>
       );
}

export default ESignMenu;