/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { isMobile } from 'react-device-detect';
import DataGrid from 'react-data-grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as XLSX from 'xlsx';
import { saveAs } from 'save-as';
import { Button } from 'react-bootstrap';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import { Tile } from '../../pages/deletedDocument/components/Tiles';

import {
    getCustomSearchList,
    getDocumentMetaWithDocId,
    lockDocument,
    configurationCustomeSearchId,
    configurationRecordSetId,
} from '../../pages/api/api';
import {
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    GetFieldData,
    noteCellFormatter,
    NoRecordFound,
    getPageFromPath,
    formatPathName,
    handleClickOutside,
    handleColumnsReorder,
    sortFields,
    sortMethod,
    customizedWidth,
    ObjectPropertyToLowerCase,
    ObjectPropertyToUpperCase,
    headerDataFormating,
    formatDate,
    dateCellFormatter,
    dateTimeDisplayFormatter,
    createTagElement,
    ownerCellFormatter,
    userPermission,
    documentTypeMappings,
    typeCellFormatter,
    faxCellFormatter,
    encodeParameter,
} from '../commonfunction';
import {
    inboxPage,
    getSelectedRowDetails,
} from '../../pages/_redux/actions/inboxActions';
import {
    getConfigData,
    getRecordSetConfig,
} from '../../pages/_redux/actions/configActions';
import ToggleGroupFormatter from './ToggleGroupFormatter';
import DropDownEditor from '../owner/DropDownEditor';
import { RowRenderer } from './RowRenderer';
import { SpinnerBlur } from '../Spinner';
import { SelectColumn } from './Column';
import DeleteRowSelection from './deleteDocument/DeleteRowSelection';
import { Notes } from '../notes/Notes';
import {
    deletedPage,
    selectedRowDetail,
} from '../../pages/_redux/actions/searchActions';

const _ = require('lodash');

class DeletedDocumentsDataGrid extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            toggleRecentDoc: false,
            selectedDoc: [],
            docIds: [],
            header: [],
            rowData: [],
            selectedRows: new Set(),
            selectedDocIds: [],
            currentPage: 0,
            perPage: 10,
            filterTxt: '',
            refreshDataGrid: '',
            filteredData: [],
            physicianFieldIdArray: [],
            physicianRecordsetId: '',
            modifyPhysicianRecordsetId: '',
            patientId: this.props.patientId,
            groupBy: [],
            groupByData: {},
            row_transition: false,
            defaultPopOpenState: {},
            expandedGroupIds: new Set(),
            dateFormatFields: [],
            modifiedColumnsNames: new Set(),
            rowClickClass: [],
            customGroupedData: [],
            defaultOpen: true,
            groupLoader: true,
            openedRowId: [],
            open_notes_modal: false,
            graphicalType: 'Tiles',
            graphicalField: '',
            statisticsBar: false,
            propertyTiles: ' All Data',
            tileFilter: false,
            viewDetail: '',
        };
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.gridRef = React.createRef();
        this.createGroupedData = this.createGroupedData.bind(this);
        this.removeAllGrouping = this.removeAllGrouping.bind(this);
        this.resetPopOverData = this.resetPopOverData.bind(this);
        this.createGroupByRows = this.createGroupByRows.bind(this);
        this.sortRows = this.sortRows.bind(this);
    }

    async componentDidMount() {
        // eslint-disable-next-line no-underscore-dangle

        this._isMounted = true;

        GetFieldData({
            configData: this.props.configData,
        });

        if (this.props.patient_documents_query !== undefined) {
            this.getDeletedDocumentsData();
        }

        this.setState({ row_transition: false });
    }

    async componentWillUnmount() {
        await this.setState({ row_transition: false });
        // eslint-disable-next-line no-underscore-dangle
        this._isMounted = false;
    }
    configurationCustomeSearchId = async (id) => {
        configurationRecordSetId(id)
            .then(async (data) => {
                const configuredRecordsets = data.configuredRecordsets;
                const recordsetNamedIdsNeedingConfiguration =
                    data.recordsetNamedIdsNeedingConfiguration;

                const existingConfigData =
                    JSON.parse(localStorage.getItem('configData')) || {};
                existingConfigData.configuredRecordsets = configuredRecordsets;
                existingConfigData.recordsetNamedIdsNeedingConfiguration =
                    data.recordsetNamedIdsNeedingConfiguration;

                localStorage.setItem(
                    'configData',
                    JSON.stringify(existingConfigData)
                );

                if (this.props.getRecordSetConfig) {
                    await this.props.getRecordSetConfig(
                        configuredRecordsets,
                        recordsetNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    };
    configurationRecordSet(id) {
        configurationRecordSetId(id)
            .then(async (data) => {
                const configuredRecordsets = data.configuredRecordsets;
                const recordsetNamedIdsNeedingConfiguration =
                    data.recordsetNamedIdsNeedingConfiguration;

                const existingConfigData =
                    JSON.parse(localStorage.getItem('configData')) || {};
                existingConfigData.configuredRecordsets = configuredRecordsets;
                existingConfigData.recordsetNamedIdsNeedingConfiguration =
                    data.recordsetNamedIdsNeedingConfiguration;

                localStorage.setItem(
                    'configData',
                    JSON.stringify(existingConfigData)
                );

                if (this.props.getRecordSetConfig) {
                    await this.props.getRecordSetConfig(
                        configuredRecordsets,
                        recordsetNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    }
    async componentDidUpdate(prevState, prevProps) {
        if (this.props.deleted_page.newNotes == 1) {
            this.getDeletedDocData(this.props.deleted_page.deleteDataGrid);
            this.props.deletedPage({
                newNotes: 0,
            });
        }

        // for scroll issue
        if (
            this.props.deleted_page !== undefined &&
            this.props.deleted_page.collapseBigView === true
        ) {
            this.props.deletedPage({
                collapseBigView: false,
            });
            if (
                this.props.deleted_page !== undefined &&
                this.props.deleted_page.rowID !== undefined &&
                this.state.totalCount > 1
            ) {
                let positionId = this.props.deleted_page.rowID;
                if (this.props.deleted_page.currentPage !== 0) {
                    positionId = this.props.deleted_page.rowID;
                }
                // let positionId = this.props.deleted_page.rowID;

                setTimeout(() => {
                    this.gridRef.current?.scrollToRow(positionId);
                }, 1000);
            }
        }
        // search refresh
        //need to handle in another place , not handled need know the case
        // if (
        //     this.props.deleted_page !== undefined &&
        //     this.props.deleted_page.refreshTab === true
        // ) {
        //
        //     this.props.deletedPage({
        //         refreshTab: false,
        //     });
        //     if (this.props.operate_search === false) {
        //         await this.removeAllGrouping();
        //         await this.setState({
        //             perPage: 10,
        //             currentPage: 0,
        //             filterTxt: '',
        //         });
        //     }
        // }
        if (String(prevState.filterTxt) !== String(this.props.filterTxt)) {
            this.setState(
                {
                    filterTxt: this.props.filterTxt,
                },
                () => {
                    this.initialConfigExecAfterState();
                }
            );
        }

        if (String(prevState.query) !== String(this.props.query)) {
            this.setState(
                {
                    query: this.props.query,
                    currentPage: 0,
                    perPage: this.state.perPage,
                    totalCount: this.state.rowData.length,
                    row_transition: false,
                },
                () => {
                    this.resetPopOverData();
                    this.setState({ row_transition: false });
                    this.getDeletedDocumentsData();
                }
            );
        }

        if (
            JSON.stringify(prevState.filter_options) !==
            JSON.stringify(this.props.filter_options)
        ) {
            this.setState(
                {
                    // filteredRowData: paginatedRowData,
                    currentPage: 0,
                    perPage: this.state.perPage,
                    totalCount: this.state.rowData.length,
                },
                () => {
                    this.resetPopOverData();
                }
            );
        }
        if (prevState.filter_options !== this.props.filter_options) {
            this.setState({ row_transition: false });
            this.getDeletedDocumentsData();
        }

        if (prevState.selectedDocId !== this.props.selectedDocId) {
            this.setState({
                toggleRecentDoc: false,
                selectedDoc: [],
                openPhysician: false,
                docIds: [],
            });
        }

        if (this.props.deleted_page.refreshTable === true) {
            this.refreshTable();
            this.doCollapse();
        }
    }

    initialConfigExecAfterState(
        params = {
            searchMode: false,
        }
    ) {
        let paginatedRowData = this.state.rowData;
        if (
            this.state.rowData !== undefined &&
            this.state.rowData.length !== 0
        ) {
            if (
                this.state.filterTxt !== '' &&
                this.state.filterTxt !== undefined
            ) {
                paginatedRowData = this.filterDataGrid(
                    this.state.filterTxt,
                    params.searchMode
                );

                this.setState({
                    allFilteredRowData: paginatedRowData,
                    totalCount: paginatedRowData.length,
                    selectedRows: new Set(),
                });
                this.props.inboxPage({
                    allFilteredRowData: paginatedRowData,
                });
            } else {
                if (this.state.groupBy.length === 0) {
                    const startIndex = 0;
                    const endIndex =
                        startIndex + parseInt(this.state.perPage, 10);
                    const fields = sortFields(
                        this.state.sortColumn,
                        this.state.sortDirection,
                        this.state.dateFormatFields
                    );
                    paginatedRowData = _.orderBy(
                        paginatedRowData,
                        fields.sortfn,
                        fields.orderBy
                    ); // Sorting
                    if (this.state.sortColumn) {
                        let columnDataType;
                        this.state.header.map((item, index) => {
                            if (item.key === this.state.sortColumn) {
                                columnDataType = item.datatype;
                            }
                        });
                        paginatedRowData = sortMethod(
                            paginatedRowData,
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields,
                            columnDataType
                        );
                    }
                    paginatedRowData = _.slice(
                        paginatedRowData,
                        startIndex,
                        endIndex
                    ); // Pagination
                }
                this.setState(
                    {
                        filteredRowData: paginatedRowData,
                        currentPage: 0,
                        perPage: this.state.perPage,
                        totalCount: this.state.rowData.length,
                    },
                    () => {
                        this.resetPopOverData();
                    }
                );
            }
        }
    }

    getDeletedDocumentsData = () => {
        this.setState({
            loader: true,
            filteredRowData: [],
        });
        this.resetDataGrid();
        const fieldId = GetFieldData({
            configData: this.props.configData,
        });
        let searchText;
        let pathname = this.props.location.pathname;
        pathname = getPageFromPath(pathname);

        // filter block data will come here
        searchText = '';

        if (
            this.props.filter_options !== undefined &&
            Object.keys(this.props.filter_options).length > 0
        ) {
            // eslint-disable-next-line vars-on-top, no-var
            var i = 0;
            // eslint-disable-next-line vars-on-top, no-var
            var concat;
            let temp_dataType = '';
            let concat_star = '';
            for (const property in this.props.filter_options) {
                concat = '&';
                concat_star = '';
                if (i === 0) concat = '';
                i++;
                if (
                    fieldId[property] !== undefined &&
                    this.props.filter_options[property] !== ''
                ) {
                    const temp_fieldId = fieldId[property];
                    temp_dataType = fieldId.allFieldID[temp_fieldId].datatype;
                    if (String(temp_dataType) === 'AlphaNumeric') {
                        concat_star = '*';
                    }
                    if (
                        String(fieldId.allFieldID[temp_fieldId].name) ===
                        'Fax Number'
                    ) {
                        concat_star = '';
                    }

                    searchText += `${concat}fieldSearch=${
                        fieldId[property]
                    }%3D${encodeParameter(
                        this.props.filter_options[property]
                    )}${concat_star}`;
                } else if (String(property) === String('from_date')) {
                    // beginOrderDate endOrderDate
                    searchText += `${concat}beginDate=${this.props.filter_options[property]}`;
                } else if (String(property) === String('to_date')) {
                    searchText += `${concat}endDate=${this.props.filter_options[property]}`;
                }
            }
        }

        let customSearchQueries = [];
        customSearchQueries = this.props.customSearchQueries;

        customSearchQueries.map((data) => {
            if (String(data.searchId) === String(pathname)) {
                // eslint-disable-next-line block-scoped-var
                if (i === 0) concat = '';
                // eslint-disable-next-line block-scoped-var
                else concat = '&';
                if (data.inputSearchFieldIdsAndValues.length > 0) {
                    if (
                        data.inputSearchFieldIdsAndValues[0] !== undefined &&
                        data.inputSearchFieldIdsAndValues[0] !== ''
                    ) {
                        if (
                            data.inputSearchFieldIdsAndValues[0].fieldValue !==
                                null &&
                            data.inputSearchFieldIdsAndValues[0]
                                .frontEndSupplies
                        ) {
                            // eslint-disable-next-line block-scoped-var

                            searchText += `${concat}fieldSearch=${
                                data.inputSearchFieldIdsAndValues[0].fieldId
                            }%3D${encodeParameter(
                                data.inputSearchFieldIdsAndValues[0].fieldValue
                            )}`;
                        }
                    }
                }
            }
        });
        if (
            (pathname === 'patients' ||
                pathname === 'physicians' ||
                this.props.location.pathname.includes('physicians') ||
                this.props.location.pathname.includes('patients')) &&
            searchText === ''
        ) {
            pathname = '';
            searchText = this.props.query;
        }
        // searchText = 'fieldSearch=295=Hospice&fieldSearch=296=DAL';

        let status;

        if (searchText !== undefined) {
            getCustomSearchList(pathname, searchText)
                .then((response) => {
                    status = response.status;

                    if (status === 400 || status === 404) {
                        // showErrorToast(response.message);
                        this.setState({
                            refreshTab: false,
                            loader: false,
                            refreshDataGrid: '',
                        });
                    }
                    if (status === 200 || status === 204) {
                        if (response.json !== undefined) return response.json();
                    }
                })
                .then((data) => {
                    if (
                        data != undefined &&
                        data.hasOwnProperty('dataTable') &&
                        data.dataTable !== ''
                    ) {
                        if (data.dataTable.hasOwnProperty('dataRows')) {
                        }

                        this.setState({
                            // totalCount: length,
                            refreshTab: false,
                            dataTable: data.dataTable.dataRows,
                        });
                        setTimeout(() => {
                            this.setState({ refreshTab: true });
                        }, 1000);
                        this.getDeletedDocData(data.dataTable);
                        this.props.deletedPage({
                            deleteDataGrid: data.dataTable,
                        });
                    }
                });
        }
        // need to check this loader 21-12-2022
        this.setState({
            //loader: false,
        });
    };

    resetDataGrid = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    handleClick = async () => {
        await this.setState({ openPopover: true });
    };

    actionMenu = (id) => {
        return (
            <div className='d-flex action_column align-items-center rel_action_menu'>
                <Button
                    variant='primary'
                    className='success'
                    onClick={() => {
                        this.props.history.push({
                            pathname: `/search/patients/?ID=${id}`,
                        });
                    }}
                    title='View Patient'
                >
                    View Patient
                </Button>
            </div>
        );
    };

    getDeletedDocData = async (data) => {
        this.setState({ relDocLoading: true });
        let rows = [];
        const lowerCase = [];
        const uniqueData = {};
        let header = [];
        let dateFormatFields = [];

        if (data !== undefined) {
            let { dataRows, headerColumns } = data;

            let currentWidth = 0;
            if (document.getElementById('custom_listing')) {
                currentWidth = document
                    .getElementById('custom_listing')
                    .getBoundingClientRect().width;
            }

            const otherColumnWidth = (headerColumns.length - 1) * 150;

            let actionMenuWidth = 150;
            if (currentWidth !== 0) {
                actionMenuWidth = currentWidth - otherColumnWidth;
            }

            const headerNameOriginal = [];
            const headerNameFormatted = [];

            const headerColumnsData = [];
            const headerColumnsData2 = [];

            let nameunique = '';
            let nameunique2 = '';

            let headerColumnsName = [];
            headerColumns.forEach((data, index) => {
                headerColumnsName.push(data.name);
            });

            const counts = {};

            headerColumnsName.forEach(function (x) {
                counts[x] = (counts[x] || 0) + 1;
            });

            for (const property in counts) {
                if (counts[property] > 1) {
                    let totalCount = counts[property];
                    let i = 1;
                    let initiateValue = ' I';
                    if (i <= totalCount) {
                        // eslint-disable-next-line no-loop-func
                        headerColumns.forEach((data, index) => {
                            if (data.name === property) {
                                if (i === 1) {
                                    nameunique2 = data.name;
                                } else {
                                    nameunique2 = data.name + initiateValue;
                                }
                                const headerArray = {
                                    name: nameunique2,
                                    fieldId: data.fieldId,
                                    datatype: data.datatype,
                                };
                                i++;
                                initiateValue = initiateValue + 'I';
                                headerColumnsData2.push(headerArray);
                            }
                        });
                    }
                }
            }

            headerColumns.forEach((data, index) => {
                if (counts[data.name] === 1) {
                    nameunique = data.name;
                } else if (counts[data.name] > 1) {
                    headerColumnsData2.forEach((property, index) => {
                        if (property.fieldId === data.fieldId) {
                            nameunique = property.name;
                        }
                    });
                }
                // if (!uniqueHeader.includes(data.name)) {
                //     uniqueHeader.push(data.name);
                //     nameunique = data.name;
                // } else {
                //     // if we contain two duplicate header then this works(not for more than two)
                //     let tempName = data.name + ' II';
                //     nameunique = tempName;
                // }
                const headerArray = {
                    name: nameunique,
                    fieldId: data.fieldId,
                    datatype: data.datatype,
                };

                headerColumnsData.push(headerArray);
            });
            headerColumns = headerColumnsData;

            await headerColumns.forEach((data, index) => {
                const headerName = formatHeaderNameWithOutSpace(data.name);

                headerNameOriginal.push(data.name);
                headerNameFormatted.push(headerName);

                uniqueData[headerName] = new Set();
                if (data.datatype === 'Date' || data.datatype === 'DateTime') {
                    dateFormatFields = [...dateFormatFields, headerName];
                }

                let headerObj = {
                    key: formatHeaderNameWithOutSpace(data.name),
                    name: data.name,
                    datatype: data.datatype,
                    colname: data.datatype,
                    fieldId: data.fieldId,
                    sortable: true,
                    draggable: true,
                    resizable: true,
                    className: data.name,
                    dummyname: data.name.trim().toUpperCase(),
                };
                switch (data.name.trim().toUpperCase()) {
                    case 'TAGS':
                        headerObj = {
                            ...headerObj,
                            ...{
                                // editor: TagAction, //Comment Due to issue
                                width: 60,
                                editorOptions: {
                                    createPortal: true,
                                },
                            },
                        };
                        break;
                    case 'TYPE':
                        headerObj = {
                            ...headerObj,
                            ...{
                                sortable: false,
                            },
                        };
                        break;
                    case 'ACTION':
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: actionMenuWidth - 20,
                            },
                        };
                        break;
                    default:
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: 150,
                                editable: false,
                            },
                        };
                        break;
                }
                header.push(headerObj);
            });
            //worked for new display name by kalpana - start
            let displayNameObj = {
                key: 'displayname',
                name: 'Display Name',
                colname: 'AlphaNumeric',
                sortable: true,
                draggable: true,
                resizable: true,
                width: 150,
                //  frozen: false,
                className: 'Display Name',
            };
            header.push(displayNameObj);
            uniqueData['displayname'] = new Set();
            //worked for new display name by kalpana - end

            this.setState({
                headerNameOriginal: headerNameOriginal,
                headerNameFormatted: headerNameFormatted,
            });

            this.setState({ totalColumn: header });

            const uniqueRow = [];
            await dataRows.forEach((data, index) => {
                const lowercaseObj = {};

                const obj = {};
                obj['id'] = data.id;
                obj.doc_id = data.id;
                lowercaseObj['id'] = data.id;
                const array = data.values;

                array.push(''); // addinf extra column for any actions
                array.forEach((row, i) => {
                    if (header[i] !== undefined) {
                        obj[header[i].key] = row.trim();
                        lowercaseObj[header[i].key] =
                            row.trim().toLowerCase() + header[i].key;

                        switch (header[i].key) {
                            case 'tags':
                                if (row !== '') {
                                    const tagData = createTagElement(
                                        row,
                                        this.props.configData,
                                        true,
                                        '',
                                        this.props.ddl.tags
                                    );
                                    this.setState({
                                        newTagColorArray:
                                            tagData.newTagColorArray,
                                    });
                                    obj[header[i].key] = tagData.tagElement;
                                    obj.tags_data = row;
                                    obj.editable = false;
                                } else {
                                    obj[header[i].key] = row;
                                    obj.tags_data = row;
                                    obj.editable = false;
                                }
                                break;

                            case 'owner':
                                // eslint-disable-next-line no-case-declarations
                                const tempOwnerData = ownerCellFormatter(
                                    row,
                                    obj.doc_id
                                );
                                obj.ownerName = row;
                                obj[header[i].key] = tempOwnerData;
                                obj.owner_rawdata = row;
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            case 'documenttype':
                                obj[header[i].key] = typeCellFormatter(row);
                                obj.documenttype_rawdata = row;
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            case 'lastnote':
                                obj[header[i].key] = row;
                                obj[header[i].key] = noteCellFormatter(row);
                                obj.lastnote_rawdata = row.trim();
                                obj.datalast = row.trim();
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            case 'comments':
                                obj[header[i].key] = row;
                                obj[header[i].key] = noteCellFormatter(
                                    row,
                                    'normal'
                                );
                                obj.comments_rawdata = row.trim();
                                obj.datacomment = row.trim();
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            case 'faxnumber':
                                obj[header[i].key] = row;
                                obj[header[i].key] = faxCellFormatter(row);
                                obj.faxnumber_rawdata = row.trim();
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            case 'phonenumber':
                                obj[header[i].key] = row;
                                obj[header[i].key] = faxCellFormatter(row);
                                obj.faxnumber_rawdata = row.trim();
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            default:
                                break;
                        }
                        switch (header[i].colname) {
                            case 'Date':
                                obj[`${header[i].key}_rawdata`] = formatDate(
                                    row,
                                    'MM/DD/YYYY hh:mm:ss A'
                                );
                                obj[`${header[i].key}_onlyDate`] = formatDate(
                                    row,
                                    'MM/DD/YYYY'
                                );

                                // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                obj[header[i].key] = formatDate(
                                    row,
                                    'MM/DD/YYYY'
                                );
                                if (header[i].key === 'date&batch') {
                                    obj[header[i].key] = dateCellFormatter(row);
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(
                                        formatDate(row, 'MM/DD/YYYY')
                                    );
                                }
                                break;
                            case 'DateTime':
                                // pls note we r reciving UTC date time('2023-06-29T09:58:42Z') so convert to local date time
                                let dateVal = '';
                                const date = new Date(row);
                                const offset = date.getTimezoneOffset();
                                const localDate = new Date(
                                    date.getTime() - offset * 60 * 1000
                                );
                                dateVal = formatDate(
                                    localDate,
                                    'MM/DD/YYYY HH:mm:ss'
                                );

                                obj[`${header[i].key}_rawdata`] = formatDate(
                                    row,
                                    'MM/DD/YYYY HH:mm:ss'
                                );
                                obj[`${header[i].key}_onlyDate`] = formatDate(
                                    row,
                                    'MM/DD/YYYY HH:mm:ss'
                                );

                                // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                obj[header[i].key] = formatDate(
                                    row,
                                    'MM/DD/YYYY HH:mm:ss'
                                );

                                if (header[i].key === 'date&batch') {
                                    obj[header[i].key] = dateCellFormatter(row);
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(
                                        //dateTimeDisplayFormatter(row)
                                        formatDate(row, 'MM/DD/YYYY HH:mm:ss')
                                    );
                                }
                                break;

                            default:
                                if (header[i].key === 'owner') {
                                    row =
                                        row.split('#')[0] !== undefined
                                            ? row.split('#')[0]
                                            : row; // issue fixes
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(row);
                                }
                                break;
                        }
                    }
                });
                lowerCase.push(lowercaseObj);
                if (!uniqueRow.includes(data.id)) {
                    uniqueRow.push(data.id);
                    rows.push(obj);
                }
            });

            //added recently to avoid duplicates
            if (this.props.inbox.inboxPage.createNewPatientCount === true) {
                if (document.getElementById('documents_count')) {
                    document.getElementById('documents_count').style.display =
                        'none';
                }
                if (document.getElementById('tracking_count')) {
                    document.getElementById('tracking_count').style.display =
                        'none';
                }
                this.props.inboxPage({
                    createNewPatientCount: false,
                });
            } else {
                if (rows.length !== 0) {
                    if (
                        this.props.tab_name === 'tab_all_documents' ||
                        this.props.tab_name === 'tab_inbox'
                    ) {
                        // eslint-disable-next-line vars-on-top
                        var myEle = document.getElementById('documents_count');
                        if (myEle && rows.length !== 0) {
                            document.getElementById(
                                'documents_count'
                            ).innerHTML = rows.length;
                            document.getElementById(
                                'documents_count'
                            ).style.display = 'inherit';
                        }
                        this.props.inboxPage({
                            documents_count: rows.length,
                        });
                    }
                    if (
                        this.props.tab_name === 'tab_tracking' ||
                        this.props.tab_name === 'tab_outbox'
                    ) {
                        // eslint-disable-next-line vars-on-top, no-redeclare
                        var myEle = document.getElementById('tracking_count');
                        if (myEle && rows.length !== 0) {
                            document.getElementById(
                                'tracking_count'
                            ).innerHTML = rows.length;
                            document.getElementById(
                                'tracking_count'
                            ).style.display = 'inherit';
                        }
                        this.props.inboxPage({
                            tracking_count: rows.length,
                        });
                    }
                }
            }

            this.setState({
                totalCount: rows.length,
            });
        }

        rows = rows.filter((value) => {
            return value.id !== this.props.selectedDocId;
        });

        if (isMobile && window.screen.width <= 960) {
            header = header.filter(
                (data) => data.key !== 'select-row' && data.name.trim() !== ''
            );

            const documentTitle = header.filter(
                (data) =>
                    data.key === 'documentname' ||
                    data.key === 'name' ||
                    data.key === 'ordernumber' ||
                    data.key === 'displayname'
            );
            const docIndex = header.findIndex(
                (data) =>
                    data.key === 'documentname' ||
                    data.key === 'name' ||
                    data.key === 'ordernumber' ||
                    data.key === 'displayname'
            );

            header.splice(docIndex, 1);
            const ArrowFormatter = {
                key: 'toggle',
                name: '',
                colname: '',
                width: 10,
                className: 'arrow-mobile',
                formatter: ({ row }) => {
                    const docid = row.id;
                    const openedRowId = this.state.openedRowId;
                    const toggle = () => {
                        if (openedRowId[docid] === undefined) {
                            openedRowId[docid] = true;
                            this.setState({ openedRowId: openedRowId });
                        } else {
                            openedRowId[docid] = !openedRowId[docid];
                            this.setState({ openedRowId: openedRowId });
                        }
                    };
                    const icon =
                        openedRowId[docid] === true
                            ? 'fa fa-chevron-up'
                            : 'fa fa-chevron-right';
                    return (
                        <div
                            onClick={() => toggle()}
                            className='toggle-it d-flex align-items-center'
                        >
                            {' '}
                            <i className={`${icon}`} aria-hidden='true' />{' '}
                        </div>
                    );
                },
            };
            documentTitle.push(ArrowFormatter);
            header = documentTitle.concat(header); // Adding Document Name at the beginning
        } else {
            header = header.filter(
                (data) =>
                    data.key !== 'select-row' &&
                    data.name.trim() !== '' &&
                    data.name.trim() !== 'Document Name' &&
                    data.name.trim() !== 'Display Name'
            );
        }
        header = [SelectColumn].concat(header);

        let newUniquesData = {};
        for (const property in uniqueData) {
            let newProp = property.replace(/[0-9-]/g, '');

            newUniquesData = {
                ...newUniquesData,
                [newProp]: uniqueData[property],
            };
        }

        await this.setState(
            {
                uniqueData: uniqueData,
                relDocLoading: false,
                header: header,
                rowData: rows,
                filteredRowData: rows,
                allRows: rows,
                orginAllRows: rows,
                copyAllRows: rows,
                allRowsLoweCase: lowerCase,
                dateFormatFields,
                refreshDataGrid: true,
            },
            () => {
                let paginatedRowData = this.state.rowData;
                //let paginatedRowData = rows;
                paginatedRowData = _.slice(paginatedRowData, 0, 10);

                this.setState({
                    refreshDataGrid: true,
                    rowData: paginatedRowData,
                    filteredRowData: paginatedRowData,
                    loader: false,
                    // eslint-disable-next-line no-dupe-keys
                    refreshDataGrid: true,
                });
            }
        );
        if (newUniquesData !== undefined) {
            await this.setState({
                uniqueData: newUniquesData,
            });
        }
        // need to check this loader 21-12-2022
        this.setState({
            loader: false,
        });
    };

    openPhysician = (docId) => {
        this.setState({
            openPhysician: true,
            docIds: [docId],
        });
    };

    updateState = (state) => {
        this.props.updateState(state);
    };

    onRowClick = async (rowDetails, rowHeader, stateData) => {
        var customSearchQueries = [];
        customSearchQueries =
            this.props.configData.customSearchNamedIdsNeedingConfiguration;

        if (customSearchQueries !== undefined) {
            await customSearchQueries.map((data) => {
                if (data.name === 'By Batch' || data.name === 'By Order') {
                    this.configurationCustomeSearchId(data.id);
                }
            });
        }
        if (
            this.props.configData.recordsetNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                (data) => {
                    if (
                        data.name === 'Order Data' ||
                        data.name === 'Patient Data Modify' ||
                        data.name === 'Order Data Modify' ||
                        data.name === 'Branch Data' ||
                        data.name === 'Episode Visit Data' ||
                        data.name === 'Physicians By Location' ||
                        data.name === 'Locations By Physician Standard' ||
                        data.name === 'Attachment Data'
                    ) {
                        this.configurationRecordSet(data.id);
                    }
                }
            );
        }
        // added for edit details open
        this.props.updateState({
            editDetailsOutbox: false,
            edit_details: false,
        });

        const docid = rowDetails.id;
        document.body.classList.add('doc-small-view');
        if (String(rowHeader.key) === 'select-row') {
            this.setState({
                showData: false,
                selectedColumn: rowHeader.key,
                reInitiateGrid: false,
            });
            this.props.deletedPage({
                // ...this.state, // hidden by krish for performance 23 May 2023
                showData: false,
                selectedColumn: rowHeader.key,
                reInitiateGrid: false,
            });
            return true;
        } else {
            this.setState(
                {
                    onRowClickId: rowDetails.doc_id,
                    rowClickClass: [rowDetails.id],
                    menu_branch: '',
                    menu_group: '',
                    menu_inbox: '',
                    row_transition: '',
                    rowDetails: rowDetails,
                    reInitiateGrid: false,
                    showData: true,
                    selectedColumn: rowHeader.key,
                },
                async () => {
                    let viewDetails;
                    this.props.deletedPage({
                        // ...this.state, // hidden by krish for performance 22 May 2023
                        onRowClickId: rowDetails.doc_id,
                        rowClickClass: [rowDetails.id],
                        menu_branch: '',
                        menu_group: '',
                        menu_inbox: '',
                        row_transition: '',
                        rowDetails: rowDetails,
                        reInitiateGrid: false,
                        showData: true,
                        selectedColumn: rowHeader.key,
                    });
                    document.body.classList.add('aside-minimize');
                    const response = getDocumentMetaWithDocId({ docid });
                    await response.then((data) => {
                        const documentTypeArray = documentTypeMappings(
                            this.props.documentTypeDetailViewMappings
                        );
                        const selectedDocumentType =
                            data.miscPproperties.documentType;
                        for (const property in documentTypeArray) {
                            if (property === selectedDocumentType) {
                                viewDetails = formatHeaderNameWithOutSpace(
                                    documentTypeArray[property]
                                );

                                this.setState({
                                    viewDetail: formatHeaderNameWithOutSpace(
                                        documentTypeArray[property]
                                    ),
                                    currentBigView:
                                        formatHeaderNameWithOutSpace(
                                            documentTypeArray[property]
                                        ),
                                });
                            }
                        }
                        if (data.hasOwnProperty('statuses')) {
                            if (data.statuses.length > 0) {
                                if (viewDetails === '') {
                                    if (data.statuses[0].processId === 1) {
                                        this.props.deletedPage({
                                            currentBigViewSearch: 'inbox',
                                        });
                                    }
                                    if (data.statuses[0].processId === 3) {
                                        this.props.deletedPage({
                                            currentBigViewSearch: 'outbox',
                                        });
                                    } else {
                                        this.props.deletedPage({
                                            currentBigViewSearch: 'inbox',
                                        });
                                    }
                                } else {
                                    this.props.deletedPage({
                                        currentBigViewSearch: viewDetails,
                                    });
                                }

                                let permission = userPermission({
                                    processId: data.statuses[0].processId,
                                    statusId: data.statuses[0].statusId,
                                    menu_name: 'Search',
                                    configData: this.props.configData,
                                });

                                permission = {
                                    ...permission,
                                    show_pdf: true,
                                    show_notes: true,
                                };
                                this.props.deletedPage({
                                    allActions: permission.allActions,
                                    permission: permission,
                                    processId: data.statuses[0].processId,
                                    statusId: data.statuses[0].statusId,
                                });
                            } else {
                                if (viewDetails) {
                                    this.props.deletedPage({
                                        allActions: undefined,
                                        permission: undefined,
                                        processId: undefined,
                                        statusId: undefined,
                                        currentBigViewSearch: viewDetails,
                                    });
                                } else {
                                    this.props.deletedPage({
                                        allActions: undefined,
                                        permission: undefined,
                                        processId: undefined,
                                        statusId: undefined,
                                        currentBigViewSearch: 'inbox',
                                    });
                                }
                            }
                        }

                        if (data.hasOwnProperty('miscPproperties')) {
                            this.setState(
                                {
                                    selectedDocId: data.miscPproperties.docId,
                                    row_transition: true,
                                },
                                () => {
                                    this.props.deletedPage({
                                        // ...this.state, // hidden by krish for performance 22 May 2023
                                        row_transition: true,
                                        selectedDocId:
                                            data.miscPproperties.docId,
                                        selectedRowDetails:
                                            data.miscPproperties,
                                        rowData: this.state.rowData,
                                        rowDetails: rowDetails,
                                    });
                                }
                            );
                        }
                        if (
                            data.miscPproperties !== undefined &&
                            data.miscPproperties.isLocked !== undefined
                        ) {
                            this.setState({
                                is_locked: data.miscPproperties.isLocked,
                            });

                            setTimeout(() => {
                                if (
                                    this.state.is_locked === true &&
                                    document.querySelector(
                                        '.rdg-row.row-selected-once'
                                    ) !== null
                                ) {
                                    document
                                        .querySelector(
                                            '.rdg-row.row-selected-once'
                                        )
                                        .classList.add('locked');
                                    if (
                                        document.getElementById(
                                            'left-aside'
                                        ) !== null
                                    ) {
                                        document
                                            .getElementById('left-aside')
                                            .classList.remove(
                                                'no-lock-section'
                                            );
                                    }
                                } else if (
                                    document.getElementById('left-aside') !==
                                    null
                                ) {
                                    document
                                        .getElementById('left-aside')
                                        .classList.add('no-lock-section');
                                }
                            }, 10);
                        }
                        if (data.miscPproperties.lockedBy !== undefined) {
                            this.setState({
                                is_locked_by: data.miscPproperties.lockedBy,
                            });
                        }

                        this.props.getSelectedRowDetails(data);
                        this.props.selectedRowDetail(data);

                        let formatted_rowDetail = '';
                        if (data.hasOwnProperty('fields')) {
                            formatted_rowDetail = data.fields;
                        }
                        const allFields = this.props.configData.fields;
                        const finalRowDetail = {};
                        if (formatted_rowDetail.length > 0) {
                            formatted_rowDetail.map((rowdetail) => {
                                allFields.map((allfields) => {
                                    const temp = { ...rowdetail };
                                    if (
                                        rowdetail.fieldId === allfields.fieldId
                                    ) {
                                        temp.fieldName = allfields.name;
                                        const removeSpace =
                                            formatNameWithSpaceToUnderscore(
                                                allfields.name
                                            );
                                        finalRowDetail[removeSpace] = temp;
                                    }
                                });
                            });
                        }
                    });
                    if (this.state.is_locked !== true) {
                        lockDocument(docid)
                            .then((data) => {})
                            .catch((err) => {});
                    }
                }
            );
        }
    };

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    async filterDataGrid(searchText = '', preventState = false) {
        // Fixes for ReEx issue
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
        const isValid = (str) => {
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText) === false) {
            // Blocking Special Character
            return [];
        }
        // End
        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        if (
            this.state.tileFilter !== true &&
            this.state.statisticsBar !== true
        ) {
            const filteredData = _.filter(this.state.allRows, function (data) {
                // filter Operation
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        // eslint-disable-next-line no-continue
                        continue;
                    } // Excluding few Property from filter
                    passed =
                        String(data[property]).toLowerCase().match(inputData) !=
                            null &&
                        String(data[property]).toLowerCase().match(inputData)
                            .length > 0;
                    if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                }
                return passed;
            });
            let state = {};
            if (searchText.trim() === '') {
                state = {
                    sortColumn: '',
                    sortDirection: 'NONE',
                };
            }
            // Condition Specifically handled for Grouping + filter
            let paginatedRowData =
                (this.state.groupBy.length > 0 && inputData.trim() !== '') ||
                preventState
                    ? filteredData
                    : this.getRowData(0, this.state.perPage, filteredData);
            paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() === ''
                    ? this.state.allRows
                    : paginatedRowData;
            // End
            if (preventState === false) {
                state = { ...state, currentPage: 0 };
            }
            await this.setState(
                Object.assign(
                    {
                        filteredData: filteredData,
                        filterTxt: searchText,
                        filteredRowData: paginatedRowData,
                        totalCount: filteredData.length,
                        isFilterRowEmpty: filteredData.length === 0,
                    },
                    state
                ),
                () => {
                    this.resetPopOverData(filteredData);
                }
            );
            return filteredData;
        } else {
            const filteredData = _.filter(
                this.state.copyAllRows,
                function (data) {
                    // filter Operation
                    let passed = false;
                    for (const property in data) {
                        if (
                            property === 'doc_id' ||
                            property === 'tag_id' ||
                            property === 'id'
                        ) {
                            // eslint-disable-next-line no-continue
                            continue;
                        } // Excluding few Property from filter
                        passed =
                            String(data[property])
                                .toLowerCase()
                                .match(inputData) != null &&
                            String(data[property])
                                .toLowerCase()
                                .match(inputData).length > 0;
                        if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                    }
                    return passed;
                }
            );
            let state = {};
            if (searchText.trim() === '') {
                state = {
                    sortColumn: '',
                    sortDirection: 'NONE',
                };
            }

            // Condition Specifically handled for Grouping + filter
            let paginatedRowData =
                (this.state.groupBy.length > 0 && inputData.trim() !== '') ||
                preventState
                    ? filteredData
                    : this.getRowData(0, this.state.perPage, filteredData);
            paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() === ''
                    ? this.state.orginAllRows
                    : paginatedRowData;
            // End

            if (preventState === false) {
                state = { ...state, currentPage: 0 };
            }
            await this.setState(
                Object.assign(
                    {
                        filteredData: filteredData,
                        filterTxt: searchText,
                        orginAllRows: filteredData,
                    },
                    state
                ),
                () => {}
            );

            const filteredDataTile = _.filter(
                this.state.allRows,
                function (data) {
                    // filter Operation
                    let passed = false;

                    for (const property in data) {
                        if (
                            property === 'doc_id' ||
                            property === 'tag_id' ||
                            property === 'id'
                        ) {
                            // eslint-disable-next-line no-continue
                            continue;
                        } // Excluding few Property from filter
                        passed =
                            String(data[property])
                                .toLowerCase()
                                .match(inputData) != null &&
                            String(data[property])
                                .toLowerCase()
                                .match(inputData).length > 0;
                        if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                    }
                    return passed;
                }
            );
            state = {};
            if (searchText.trim() === '') {
                state = {
                    sortColumn: '',
                    sortDirection: 'NONE',
                };
            }

            // Condition Specifically handled for Grouping + filter
            paginatedRowData =
                (this.state.groupBy.length > 0 && inputData.trim() !== '') ||
                preventState
                    ? filteredDataTile
                    : this.getRowData(0, this.state.perPage, filteredDataTile);
            paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() === ''
                    ? this.state.allRows
                    : paginatedRowData;
            // End
            if (
                inputData.trim() === '' &&
                this.state.propertyTiles === 'All Data'
            ) {
                paginatedRowData = filteredData;
            }

            if (preventState === false) {
                state = { ...state, currentPage: 0 };
            }
            await this.setState(
                Object.assign(
                    {
                        filteredRowData: paginatedRowData,
                        totalCount: filteredDataTile.length,
                        isFilterRowEmpty: filteredDataTile.length === 0,
                    },
                    state
                ),
                () => {
                    this.resetPopOverData(filteredDataTile);
                }
            );
            return filteredDataTile;
        }
    }

    refreshTable() {
        document.body.classList.remove('doc-detail-view');
        document.body.classList.remove('aside-minimize');
        this.removeAllGrouping();
        this.getDeletedDocumentsData(); //added here in order to get document after perforing recover action
        this.props.deletedPage({ refreshTable: false });
        const resetIt = () => {
            this.componentDidMount();
            this.props.deletedPage({
                row_transition: false,
            });
            this.setState({
                displayAnimation: true,
                refresh: false,
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                filteredRowData: [],
            });
            setTimeout(() => {
                this.setState({
                    displayAnimation: false,
                });
            }, 1000);
        };
        this.setState(
            {
                refresh: true,
            },
            () => {
                setTimeout(() => {
                    resetIt();
                }, 100);
            }
        );
    }

    nextPage(event, newPage) {
        const startIndex =
            parseInt(newPage, 10) * parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        let paginatedRowData;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }
        // paginatedRowData = this.state.allRows;
        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            currentPage: newPage,
            filteredRowData: paginatedRowData,
        });
    }

    handleChangeRowsPerPage(event) {
        const startIndex = 0;
        const endIndex = parseInt(event.target.value, 10);
        let paginatedRowData;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }
        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            filteredRowData: paginatedRowData,
            perPage: endIndex,
            currentPage: 0,
        });
    }

    handleSearch = (searchText) => {
        this.setState({
            refreshDataGrid: false,
            relDocLoading: true,
        });
        searchText = String(searchText).replaceAll(/\\/g, ''); //Remove backward slash from string

        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        const filteredData = _.filter(this.state.allRows, function (data) {
            // filter Operation
            let passed = false;
            for (const property in data) {
                if (property === 'id') {
                    // eslint-disable-next-line no-continue
                    continue;
                } //Excluding few Property from filter
                passed =
                    String(data[property]).toLowerCase().match(inputData) !=
                        null &&
                    String(data[property]).toLowerCase().match(inputData)
                        .length > 0;
                if (passed) break; //if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
            }
            return passed;
        });
        const length = filteredData.length;

        const paginatedRowData = _.slice(filteredData, 0, 10);
        this.setState({
            filteredData: filteredData,
            relDocLoading: false,
            rowData: paginatedRowData,
            //allRows: filteredData,
            totalCount: length,
            currentPage: 0,
            perPage: this.state.perPage,
            refreshDataGrid: true,
        });
    };

    draggableColumns() {
        const headerRenderer = (props) => {
            return (
                <DraggableHeaderRenderer
                    {...props}
                    {...this.state}
                    row_transition={this.props.deleted_page.row_transition}
                    setGrouping={this.createGroupedData}
                    onColumnsReorder={handleColumnsReorder.bind(this)}
                    handleClickOutside={handleClickOutside}
                    sortFields={this.sortRows}
                />
            );
        };
        let header = this.state.header;
        let pathname = this.props.location.pathname;
        pathname = formatPathName(pathname);
        if (header.length > 0 && !isMobile) {
            // HEADER REORDER AND WIDTH SETTING  FROM LOCALSTORAGE
            let headerReorder = localStorage.getItem(
                `header-reorder-${pathname}`
            );
            let headerWidth = localStorage.getItem(`header-width-${pathname}`);
            if (headerReorder !== null && headerReorder !== '') {
                headerReorder = JSON.parse(headerReorder);
                header = headerDataFormating({ headerReorder, header });
            }
            if (headerWidth !== null && headerWidth !== '') {
                headerWidth = JSON.parse(headerWidth);
                header = headerDataFormating({ headerWidth, header });
            }
        } //END
        if (header.length === 0) {
            header = this.state.header;
        }
        header = header.filter((data) => {
            data.rowGroup = false;
            if (data.groupFormatter !== undefined) {
                delete data.groupFormatter; // issue fixes for grouping
            }
            return data;
        });
        return header.map((c) => {
            if (this.state.groupBy.length > 0) {
                c.rowGroup = this.state.groupBy.indexOf(c.key) !== -1;
                if (c.rowGroup) {
                    c.width = c.width > 200 ? c.width : 200;
                    c.groupFormatter = ToggleGroupFormatter.bind(this);
                }
            }
            if (c.key === 'owner') {
                //c.editor = DropDownEditor.bind(this);
                c.editorOptions = { createPortal: true, editOnClick: true };
                c.editable = true;
            }
            if (c.key === 'select-row') {
                c.selectedCount = Array.from(this.state.selectedRows).length;
            }
            c.row_transition = this.state.row_transition;
            if (
                c.key === 'id' ||
                c.key === 'select-row' ||
                this.state.tableDesign === 'simple'
            )
                return c;
            return { ...c, headerRenderer: headerRenderer };
        });
    }

    createGroupedData(data, type = '', mode = false) {
        this.setState({ customGroupedData: [] });

        const groupByData = this.state.groupByData;
        const { expandedGroupIds } = this.state; //Grouping Expansion Id
        for (const property in groupByData) {
            groupByData[property] = groupByData[property].map((data) =>
                data.trim().toLowerCase()
            );
        }
        const str_pad = (num) => {
            const str1 = '';
            return str1.padStart(num, '_');
        };
        if (String(type) === 'ALL') {
            //SELECT ALL option in POPOVER
            groupByData[data.title] = [];
            let collection = data.value;
            collection = collection.map((v) => v.trim().toLowerCase());
            groupByData[data.title] = mode ? collection : [];
            const groupBy = [];
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                openState[names] = false;
            }
            for (const props in groupByData) {
                if (groupByData[props].length > 0) {
                    groupBy.push(props);
                }
            }
            let groupingValueData = Object.values(groupByData);
            groupingValueData = groupingValueData.filter((v) => v.length > 0);
            const expandedArray = Array.from(expandedGroupIds);
            const lastIndexData =
                groupingValueData[groupingValueData.length - 1];
            const str = str_pad(2);
            if (lastIndexData !== undefined) {
                if (expandedArray.length > 0) {
                    if (
                        lastIndexData !== undefined &&
                        lastIndexData.length > 0
                    ) {
                        expandedArray.forEach((v1) => {
                            lastIndexData.forEach((v2) => {
                                v2 =
                                    v2.charAt(0).toUpperCase() +
                                    v2.slice(1, v2.length);
                                expandedGroupIds.add(v2);
                                const parentIndexCount = v1.split('__').length;
                                if (parentIndexCount <= groupBy.length) {
                                    expandedGroupIds.add(`${v1}${str}${v2}`);
                                }
                            });
                        });
                    }
                } else {
                    lastIndexData.forEach((v2) => {
                        v2 =
                            v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                        if (!expandedGroupIds.has(v2)) {
                            expandedGroupIds.add(v2);
                        }
                    });
                }
            }
            //let rowData = this.state.rowData;
            let rowData = this.state.allRows;
            if (!mode && !(groupBy.length > 0)) {
                rowData = _.slice(rowData, 0, this.state.perPage);
            }
            if (this.state.filterTxt !== '') {
                rowData = this.state.filteredRowData;
            }
            this.setState({
                groupBy: groupBy,
                groupByData: groupByData,
                defaultOpen: groupBy.length > 0,
                groupLoader: groupBy.length > 0,
                filteredRowData: rowData,
                // filteredRowData: [],
                selectedRows: new Set(),
                tempExpandedGroupIds:
                    groupBy.length > 0 ? expandedGroupIds : new Set(),
                defaultPopOpenState: openState,
                sortColumn: '',
                sortDirection: 'NONE',
                reInitiateGrid: true,
            });
            if (mode === false) {
                this.resetPopOverData(this.state.rowData);
            }
            return;
        } //End SELECT ALL
        if (
            data.title !== undefined &&
            this.state.groupByData[data.title] === undefined
        ) {
            //Check , value already exist, if not,  assign it to Array
            groupByData[data.title] = [];
        }
        const val = data.value.trim().toLowerCase();
        if (
            String(groupByData[data.title].indexOf(val)) === String(-1) &&
            mode
        ) {
            // mode = true, add value to array
            groupByData[data.title].push(val);
        } else if (!mode) {
            // mode = false , Remove value
            const index = groupByData[data.title].indexOf(val);
            groupByData[data.title].splice(index, 1);
        }
        const groupBy = [];
        const openState = this.state.defaultPopOpenState; // FOR POPOVER OPEN or CLOSE
        for (const names in openState) {
            openState[names] = false; //CLOSING ALL POPOVER
        }
        openState[data.title] = true; //OPENING CURRENT POPOVER
        const groupedValueData = [];
        for (const props in groupByData) {
            if (groupByData[props].length > 0) {
                groupBy.push(props);
                groupByData[props].forEach((d) => {
                    groupedValueData.push(d);
                });
            }
        }
        let groupingValueData = Object.values(groupByData);
        groupingValueData = groupingValueData.filter((v) => v.length > 0);
        const expandedArray = Array.from(expandedGroupIds);
        const lastIndexData = groupingValueData[groupingValueData.length - 1];
        const str = str_pad(2);
        if (lastIndexData !== undefined) {
            // Generate Grouping Expansion Id
            if (expandedArray.length > 0) {
                if (lastIndexData !== undefined && lastIndexData.length > 0) {
                    expandedArray.forEach((v1) => {
                        lastIndexData.forEach((v2) => {
                            v2 =
                                v2.charAt(0).toUpperCase() +
                                v2.slice(1, v2.length);
                            expandedGroupIds.add(v2);
                            const parentIndexCount = v1.split('__').length;
                            if (parentIndexCount <= groupBy.length) {
                                expandedGroupIds.add(`${v1}${str}${v2}`);
                            }
                        });
                    });
                }
            } else {
                lastIndexData.forEach((v2) => {
                    v2 = v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                    if (!expandedGroupIds.has(v2)) {
                        expandedGroupIds.add(v2);
                    }
                });
            }
        } //End

        let rowData = this.state.allRows;
        if (String(groupBy.length) === String(0)) {
            rowData = _.slice(rowData, 0, this.state.perPage); //Paginating the data
        }

        if (String(this.state.filterTxt) !== '') {
            //rowData = this.state.allFilteredRowData;
            rowData = this.state.filteredRowData;
        }

        this.setState({
            groupBy: groupBy, //REQUIRED STATE FOR GROUPING
            groupByData: groupByData,
            defaultOpen: groupBy.length > 0,
            groupLoader: groupBy.length > 0,
            filteredRowData: rowData,
            // filteredRowData: [],
            selectedRows: new Set(),
            //expandedGroupIds:  (groupBy.length > 0) ? expandedGroupIds : new Set(),
            tempExpandedGroupIds:
                groupBy.length > 0 ? expandedGroupIds : new Set(),
            defaultPopOpenState: openState,
            sortColumn: '',
            sortDirection: 'NONE',
            reInitiateGrid: true,
            // uniqueData:""
            // refreshDataGrid :true
        });

        //Abu added @19-01-2022 {
        if (mode === false) {
            this.resetPopOverData(this.state.rowData);
        }
        //}
    }

    sortRows(sortColumn, sortDirection) {
        let columnDataType;
        this.state.header.map((item, index) => {
            if (item.key === sortColumn) {
                columnDataType = item.datatype;
            }
        });

        if (sortDirection !== 'NONE') {
            //let paginatedRowData = this.state.rowData;
            let paginatedRowData = this.state.allRows;
            if (this.state.filterTxt.trim() !== '') {
                paginatedRowData = this.state.filteredData;
            }

            let entireRow = sortMethod(
                paginatedRowData,
                sortColumn,
                sortDirection,
                this.state.dateFormatFields,
                columnDataType
            );

            /*  const fields = sortFields(
                sortColumn,
                sortDirection,
                this.state.dateFormatFields
            );
            paginatedRowData = _.orderBy(
                paginatedRowData,
                fields.sortfn,
                fields.orderBy
            );
            let allRows= paginatedRowData = _.orderBy(
                paginatedRowData,
                fields.sortfn,
                fields.orderBy
            ) */
            paginatedRowData = this.getRowData(
                0,
                this.state.perPage,
                entireRow
            );
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                openState[names] = false;
            }
            this.setState({
                filteredRowData:
                    this.state.groupBy.length > 0
                        ? entireRow
                        : paginatedRowData,
                allRows: entireRow,
                filteredData: entireRow,

                currentPage: 0,
                sortDirection: sortDirection,
                sortColumn: sortColumn,
                defaultPopOpenState: openState,
            });
        }
    }

    //Common Function used to regenerate Popover Data while filtering
    resetPopOverData(filteredData = []) {
        const uniqueData = {};
        const uniqueTagNameValues = new Set();
        let makeData = this.state.rowData;
        if (this.state.filterTxt.trim() !== '') {
            makeData = filteredData;
        }

        //  if (this.state.filterTxt.trim() !== '') {
        //     makeData = this.state.allFilteredRowData;
        // }
        // makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        //  makeData=Object.values(this.state.customGroupedData)
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (uniqueData[property] === undefined) {
                    uniqueData[property] = new Set();
                }
                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        uniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        this.props.deleted_page?.deleteDataGrid?.headerColumns?.map(
                            (item, idx) => {
                                if (item.datatype === 'DateTime') {
                                    if (
                                        formatHeaderNameWithOutSpace(
                                            item.name
                                        ) === property
                                    ) {
                                        uniqueData[property].add(
                                            formatDate(
                                                data[`${property}_rawdata`],
                                                'MM/DD/YYYY HH:mm:ss'
                                            )
                                        );
                                    }
                                } else {
                                    uniqueData[property].add(
                                        formatDate(
                                            data[`${property}_rawdata`],
                                            'MM/DD/YYYY'
                                        )
                                    );
                                }
                            }
                        );
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    uniqueTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    uniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    uniqueData[property].add(data[property]);
                }
            }
        });

        this.setState(
            {
                uniqueData,
                uniqueTagNameValues,
            },
            () => {}
        );
    }

    createGroupByRows(groupedData, indexProp) {
        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            if (String(property) === String(this.state.sortColumn)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.groupByData[property] =
                    this.state.sortDirection === 'ASC'
                        ? rowValue.sort()
                        : rowValue.sort().reverse(); //Performing Sorting
            }
        }
        //End
        let customGroupedData = [];
        const groupResult = {};
        let dateFieldColumn = '';
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );

        //Check If it is Date Field And Group It
        const emptyDateBatch = [];
        if (this.state.dateFormatFields.indexOf(indexProp) !== -1) {
            const index = this.state.dateFormatFields.indexOf(indexProp);
            dateFieldColumn = this.state.dateFormatFields[index];
            if (dateFieldColumn === 'date&batch') {
                customGroupedData = _.groupBy(groupedData, 'date_batch');
            } else {
                // customGroupedData = _.groupBy(
                //     groupedData,
                //     `${dateFieldColumn}_rawdata`
                // );
                customGroupedData = _.groupBy(
                    groupedData,
                    `${dateFieldColumn}_onlyDate`
                );
            }
        } else if (modifiedColumnsNames.indexOf(indexProp) !== -1) {
            customGroupedData = _.groupBy(groupedData, `${indexProp}_rawdata`);
        } else {
            customGroupedData = _.groupBy(groupedData, `${indexProp}`);
        }

        customGroupedData = ObjectPropertyToLowerCase(customGroupedData);
        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            const index = this.state.groupBy.indexOf(property);
            if (index !== -1 && rowValue.length === 0) {
                this.state.groupBy.splice(index, 1);
            }
            if (String(property) === String(indexProp)) {
                rowValue.forEach((data, i) => {
                    const dateData = data.trim();
                    if (customGroupedData[dateData] !== undefined) {
                        const fields = sortFields(
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields
                        );

                        customGroupedData[dateData] = _.orderBy(
                            customGroupedData[dateData],
                            fields.sortfn,
                            fields.orderBy
                        );

                        groupResult[data] = customGroupedData[dateData];

                        /*  if(this.state.sortColumn){
                            let columnDataType
                            this.state.header.map((item, index)=>{
                                if(item.key === this.state.sortColumn){
                                    columnDataType = item.datatype
                                }
                            })
                            groupResult[data] = sortMethod(
                                groupResult[data] ,
                                this.state.sortColumn,
                                this.state.sortDirection,
                                this.state.dateFormatFields,
                                columnDataType,  
                            )
                        } */
                    } else {
                        emptyDateBatch.push(dateData);
                    }
                });
            }
        }
        //  this.state.defaultOpen = false;
        //this.state.isFilterRowEmpty = Object.keys(groupResult).length === 0;
        const empty = {};
        if (emptyDateBatch.length > 0) {
            emptyDateBatch.forEach((name) => {
                empty[name] = [];
            });
        }
        this.render();
        let result = { ...empty, ...groupResult };

        result = ObjectPropertyToUpperCase(
            result,
            this.state.sortDirection,
            this.state.groupByData
        );
        //this.setState({filteredRowData:result})
        // added by abu {

        if (this.state.customGroupedData.length === 0) {
            this.setState({ customGroupedData: result }, () => {
                const temp = Object.values(this.state.customGroupedData);
                const tempArray = [];
                temp.map((data) => {
                    data.map((array) => {
                        tempArray.push(array);
                    });
                });
                //this.setState({childUniqueData:tempArray})
                this.makeChildUniqueData(tempArray);
                //this.resetPopOverData(tempArray);
            });
        }
        // }
        return result;
    }

    makeChildUniqueData = (filteredData = []) => {
        const childUniqueData = {};
        const childTagNameValues = new Set();
        let makeData = this.state.rowData;
        makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (childUniqueData[property] === undefined) {
                    childUniqueData[property] = new Set();
                }

                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        childUniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        childUniqueData[property].add(
                            formatDate(
                                data[`${property}_rawdata`],
                                'MM/DD/YYYY'
                            )
                        );
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    childTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    childUniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    childUniqueData[property].add(data[property]);
                }
            }
        });

        this.setState({
            childUniqueData,
            childTagNameValues,
        });
    };

    removeAllGrouping() {
        this.setState({ refreshDataGrid: false });
        let paginatedRowData = this.state.allRows;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredData;
        }
        const startIndex =
            parseInt(this.state.currentPage, 10) *
            parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        paginatedRowData = this.getRowData(
            startIndex,
            endIndex,
            paginatedRowData
        );
        this.setState({
            groupByData: {},
            groupBy: [],
            filteredRowData: paginatedRowData,
            groupingData: {},
            tempExpandedGroupIds: new Set(),
            expandedGroupIds: new Set(),
            rowClickClass: this.state.row_transition
                ? this.state.rowClickClass
                : [],
            reInitiateGrid: true,
            refreshDataGrid: true,
        });
    }

    rowTransition = async () => {
        this.setState({
            row_transition: false,
            detailBigView: false,
        });
    };

    open_notes_section = () => {
        this.setState({
            open_notes_section: !this.state.open_notes_section,
        });
    };

    open_notes_modal = () => {
        this.setState({
            open_notes_modal: !this.state.open_notes_modal,
        });
    };

    statisticsOpen = () => {
        this.setState({
            statisticsBar: !this.state.statisticsBar,
        });
    };

    doCollapse = () => {
        //used for resume tracking and recover
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        // const elements = document.querySelectorAll('.rdg-row');
        this.setState({
            row_transition: false,
            detailBigView: false,
        });
        this.rowTransition();
        //this.props.updateState({ row_transition: false,  });
        if (isMobile && window.screen.width <= 960) {
            document.body.classList.remove('small-view-display');
        }
        setTimeout(() => {
            const checkBox =
                document.getElementsByClassName('rdg-checkbox-input');
            if (checkBox !== null) {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].disabled = false;
                    checkBox[i].removeAttribute('disabled');
                }
            }
        }, 20);
    };

    removeAttribute = (
        data,
        propName,
        propName2,
        propName3,
        propName4,
        propName5,
        propName6,
        propName7,
        propName8,
        propName9,
        propName10
    ) => {
        data.map((value) => {
            let key;
            for (key in value) {
                if (
                    key.includes(propName) ||
                    key.includes(propName2) ||
                    key.includes(propName3) ||
                    key.includes(propName4) ||
                    key.includes(propName5) ||
                    key.includes(propName6) ||
                    key.includes(propName7) ||
                    key.includes(propName8) ||
                    key.includes(propName9) ||
                    key.includes(propName10)
                ) {
                    delete value[key];
                }
            }
        });
        return data;
    };

    exportToXlsx = () => {
        let allData = [];

        if (this.state.filteredData.length !== 0) {
            allData = this.state.filteredData;
        } else {
            allData = this.state.allRows;
        }

        this.removeAttribute(
            allData,
            '_rawdata',
            '_onlyDate',
            '_value',
            'lastnote',
            'comments',
            'documentname',
            'displayname',
            'Document Name',
            'Tags',
            'Owner'
            //'id', // dont include this since it will remove id from original data which cause issue in document id undefined
        );
        let entireData = [];
        allData.map((item) => {
            let newObject = {};
            for (const property in item) {
                if (property === 'doc_id' || property === 'id') {
                    newObject = {
                        ...newObject,
                        [property]: Math.abs(item[property]),
                    };
                } else if (property === 'documenttype') {
                    newObject = {
                        ...newObject,
                        [property]: item[property].props?.title
                            ? item[property].props?.title
                            : '',
                    };
                } else if (property === 'tags_data') {
                    newObject = {
                        ...newObject,
                        [property]:
                            item[property] == '|'
                                ? ''
                                : item[property]
                                ? item[property].replace(/\|/g, ',')
                                : item[property],
                    };
                } else {
                    newObject = {
                        ...newObject,
                        [property]: item[property],
                    };
                }
            }
            entireData.push(newObject);
        });
        const textarea = JSON.stringify(entireData);

        const find = this.state.headerNameFormatted;
        const replace = this.state.headerNameOriginal;

        find.push('doc_id');
        replace.push('Doc Id');

        find.push('datalast');
        replace.push('LastNotes');

        find.push('datacomment');
        replace.push('Comments');

        find.push('Tags_data');
        replace.push('Tags');

        find.push('OwnerName');
        replace.push('Owner');

        find.push('id');
        replace.push('ID');

        find.push('editable');
        replace.push('Editable');

        const text = find.reduce((acc, item, i) => {
            const regex = new RegExp(item, 'g');
            return acc.replace(regex, replace[i]);
        }, textarea);
        const allData2 = JSON.parse(text);

        const ws = XLSX.utils.json_to_sheet(allData2);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        saveAs(data, `${this.props.pageTitle}.xlsx`);
    };

    dataGrid = (reBuildGrid, stateRowData, defaultHeight, responsiveGrid) => {
        return (
            <DataGrid
                // {...reBuildGrid}
                ref={this.gridRef}
                columns={this.draggableColumns()}
                // columns={this.state.header}
                rows={stateRowData}
                rowsCount={stateRowData.length}
                selectedRows={this.state.selectedRows}
                onSelectedRowsChange={(rows) => {
                    const selectedRows = rows;
                    this.setState(
                        {
                            selectedRows: selectedRows,
                            // selectedDocIds: selectedRows
                        },
                        () => {
                            this.props.deletedPage({
                                // ...this.state, // hidden by krish for performance 22 May 2023
                                selectedRows: selectedRows,
                            });
                        }
                    );
                }}
                expandedGroupIds={this.state.tempExpandedGroupIds}
                onExpandedGroupIdsChange={(groupId) => {
                    this.setState({
                        expandedGroupIds: groupId,
                        tempExpandedGroupIds: groupId,
                    });
                }}
                //headerRowHeight={0}
                rowHeight={52}
                //summaryRowHeight={20}
                rowKeyGetter={(row) => {
                    return row.id;
                }}
                onRowClick={(rowDetails, rowHeader) => {
                    this.onRowClick(rowDetails, rowHeader, this.state);
                    this.setState({
                        selectedRows: new Set(),
                    });
                }}
                emptyRowsRenderer={NoRecordFound.bind(
                    this,
                    this.state.relDocLoading
                )}
                style={{
                    height: defaultHeight,
                    borderRight: '50px',
                }}
                className='fill-grid mt-5'
                rowClass={(row) => {
                    if (this.state.rowClickClass.indexOf(row.id) !== -1) {
                        if (
                            Number(this.props.deleted_page.rowID) !==
                            Number(row.rowId)
                        ) {
                            this.props.deletedPage({
                                rowID: row.rowId,
                            });
                        }

                        return 'row-selected-once';
                    }
                }}
                defaultColumnOptions={{
                    sortable: true,
                    resizable: true,
                    frozen: false,
                }}
                rowGrouper={(groupedData, indexProp) =>
                    this.createGroupByRows(groupedData, indexProp)
                }
                onColumnResize={(idx, width) => {
                    customizedWidth(
                        idx,
                        width,
                        this.state.header,
                        this.props.location.pathname
                    );
                }}
                groupBy={this.state.groupBy}
                headerRowHeight={40}
                {...responsiveGrid}
            />
        );
    };

    render() {
        const temp = Array.from(this.state.selectedRows);
        temp.push(this.state.onRowClickId);

        /*var rows = this.state.rowData;allRows
        if(this.state.filteredRowData !=''){
            rows = this.state.filteredRowData;
        }*/
        let stateRowData = [];

        if (this.state.filteredRowData !== undefined) {
            stateRowData =
                this.state.expandedGroupIds.size != 0 &&
                this.state.filterTxt != ''
                    ? this.state.filteredData
                    : //: this.state.expandedGroupIds.size != 0
                      //? this.state.rowData
                      this.state.filteredRowData;
        }
        stateRowData.forEach((data, index) => {
            data.rowId = index;
            return data;
        });
        // eslint-disable-next-line vars-on-top
        var defaultHeight = 560;

        let reBuildGrid = {};
        if (this.state.reInitiateGrid) {
            // Need to Set this value to TRUE , if we face ROW COLLAPSE or DATAGRID Design issue
            reBuildGrid = { key: Math.random() };
        }
        let responsiveGrid = {};
        const toggleRowElement = (params) => {
            const { row } = params;
            const docid = row.id;
            const openedRowId = this.state.openedRowId;
            if (openedRowId[docid] === undefined) {
                openedRowId[docid] = true;
                this.setState({ openedRowId: openedRowId });
            } else {
                openedRowId[docid] = !openedRowId[docid];
                this.setState({ openedRowId: openedRowId });
            }
        };
        //isMobile
        if (isMobile && window.screen.width <= 960) {
            responsiveGrid = {
                rowRenderer: (props) => {
                    return (
                        <RowRenderer
                            {...props}
                            fullHeaderData={this.state.header}
                            openedRowId={this.state.openedRowId}
                            modifiedColumnsNames={Array.from(
                                this.state.modifiedColumnsNames
                            )}
                            dateFormatFields={Array.from(
                                this.state.dateFormatFields
                            )}
                            toggleRowElement={toggleRowElement}
                            fromPage='custom_listing'
                        />
                    );
                },
                rowHeight: (args) => {
                    const { type, row } = args;
                    let heightVal = this.state.header.length * 30;
                    if (
                        this.state.header.length >= 15 &&
                        this.state.header.length <= 19
                    ) {
                        heightVal = this.state.header.length * 35;
                    } else if (
                        this.state.header.length < 15 &&
                        this.state.header.length >= 12
                    )
                        heightVal = this.state.header.length * 35;
                    else if (
                        this.state.header.length < 12 &&
                        this.state.header.length >= 10
                    )
                        heightVal = this.state.header.length * 40;
                    else if (
                        this.state.header.length < 10 &&
                        this.state.header.length > 4
                    )
                        heightVal = (this.state.header.length - 1) * 45;
                    //else this.state.header.length * 42;
                    if (type === 'GROUP') {
                        return 70;
                    }

                    if (
                        this.state.openedRowId[row.id] !== undefined &&
                        this.state.openedRowId[row.id] === true
                    ) {
                        return heightVal;
                    }
                    return 60;
                },
                headerRowHeight: 0,
            };
        }

        // eslint-disable-next-line vars-on-top, no-redeclare
        var defaultHeight = window.innerHeight - 280;

        return (
            <>
                {this.state.loader ? (
                    <SpinnerBlur show={this.state.loader} />
                ) : null}
                {this.props.deleteBigView === false && (
                    <div className='right-aside py-5' id='right-aside'>
                        {this.state.groupBy.length > 0 ? (
                            <div
                                className='remove-grouping-all'
                                style={{ cursor: 'pointer' }}
                            >
                                <div className='count_no d-none'>
                                    <span>12</span>
                                </div>
                                <i
                                    className='fa fa-list-alt'
                                    aria-hidden='true'
                                />
                                <span
                                    title='Remove Grouping'
                                    onClick={() => this.removeAllGrouping()}
                                >
                                    Remove Grouping{' '}
                                </span>
                            </div>
                        ) : null}
                        <div className='listing-grid grid-physicians'>
                            <div className='d-flex justify-content-between pl-7 pr-7 report_header'>
                                <div
                                    className={
                                        this.props.query !== undefined
                                            ? 'd-flex align-items-center'
                                            : 'no-heading-doc'
                                    }
                                >
                                    {this.props.query !== undefined ? (
                                        <h3 className='m-0 title-color'>
                                            {this.props.pageTitle}
                                        </h3>
                                    ) : null}
                                </div>
                                <div className='d-flex filter_part rpt-filter'>
                                    <div className='export'>
                                        <Button
                                            title='Export Xls'
                                            variant='primary'
                                            className='mr-3'
                                            onClick={() => {
                                                this.exportToXlsx();
                                            }}
                                            disabled={
                                                (this.state.allRows !==
                                                    undefined &&
                                                    this.state.allRows
                                                        .length === 0) ||
                                                (this.state.filterTxt !== '' &&
                                                    this.state.filteredData
                                                        .length === 0) ||
                                                this.state.refreshDataGrid ===
                                                    ''
                                            }
                                        >
                                            <i className='fa-solid fa-file-export' />
                                            Export
                                        </Button>
                                    </div>
                                    <div className='filter'>
                                        <input
                                            type='text'
                                            placeholder='Filter'
                                            disabled={
                                                this.props.deleted_page
                                                    .row_transition
                                            }
                                            value={this.state.filterTxt}
                                            onChange={(event) =>
                                                this.filterDataGrid(
                                                    event.target.value
                                                )
                                            }
                                            style={{
                                                paddingRight: this.props
                                                    .deleted_page.row_transition
                                                    ? '0px'
                                                    : '34px',
                                                cursor: this.props.deleted_page
                                                    .row_transition
                                                    ? 'not-allowed'
                                                    : 'text',
                                            }}
                                        />
                                        <i className='fa-regular fa-magnifying-glass' />
                                    </div>

                                    <div
                                        className={
                                            this.state.statisticsBar === true
                                                ? 'align-self-strich reload-outer active'
                                                : 'align-self-strich reload-outer'
                                        }
                                    >
                                        <span
                                            className='align-self-center reload'
                                            onClick={() => this.refreshTable()}
                                        >
                                            <i
                                                title='Refresh'
                                                className={
                                                    this.state.displayAnimation
                                                        ? 'fas fa-sync-alt refreshing'
                                                        : 'fas fa-sync-alt'
                                                }
                                            />
                                        </span>
                                        {this.props.deleted_page
                                            .showStatistic !== undefined &&
                                        this.props.deleted_page
                                            .showStatistic === true &&
                                        (this.props.location.pathname.includes(
                                            'patients'
                                        ) === true ||
                                            this.props.location.pathname.includes(
                                                'physicians'
                                            ) === true) ? (
                                            <span
                                                className={
                                                    this.state.statisticsBar
                                                        ? 'reload active'
                                                        : 'reload'
                                                }
                                                onClick={() =>
                                                    this.statisticsOpen()
                                                }
                                            >
                                                <i className='fas fa-chart-line' />
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className='' id='custom_listing'>
                                {this.state.refreshDataGrid === '' &&
                                this.state.loader === false ? (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '30px',
                                        }}
                                    >
                                        No Documents Found
                                    </div>
                                ) : null}

                                {this.state.refreshDataGrid &&
                                this.state.loader === false ? (
                                    <>
                                        {this.state.statisticsBar === true ? (
                                            <Tile
                                                orginAllRows={
                                                    this.state.orginAllRows
                                                }
                                                rows={stateRowData}
                                                filteredRowData={
                                                    this.state.filteredRowData
                                                }
                                                allFilteredRowData={
                                                    this.state
                                                        .allFilteredRowData
                                                }
                                                allRows={this.state.allRows}
                                                rowData={this.state.rowData}
                                                updateState={(state) => {
                                                    this.setState({
                                                        ...state,
                                                    });
                                                }}
                                                filteredData={
                                                    this.state.filteredData
                                                }
                                                graphicalType={
                                                    this.state.graphicalType
                                                }
                                                graphicalField={
                                                    this.state.graphicalField
                                                }
                                                resetPopOverData={() =>
                                                    this.resetPopOverData()
                                                }
                                                headerColumns={
                                                    this.state.headerColumns
                                                }
                                                perPage={this.state.perPage}
                                                propertyTiles={
                                                    this.state.propertyTiles
                                                }
                                                totalCount={
                                                    this.state.totalCount
                                                }
                                                filterTxt={this.state.filterTxt}
                                            />
                                        ) : null}

                                        <DndProvider backend={HTML5Backend}>
                                            {this.dataGrid(
                                                reBuildGrid,
                                                stateRowData,
                                                defaultHeight,
                                                responsiveGrid
                                            )}
                                        </DndProvider>
                                    </>
                                ) : null}
                            </div>
                            {this.state.refreshDataGrid &&
                            this.state.groupBy.length === 0 &&
                            this.state.rowData.length > 0 &&
                            this.props.paginationRefresh ? (
                                <div>
                                    <TablePagination
                                        component='div'
                                        count={this.state.totalCount}
                                        page={parseInt(
                                            this.state.currentPage,
                                            10
                                        )}
                                        rowsPerPage={parseInt(
                                            this.state.perPage,
                                            10
                                        )}
                                        onRowsPerPageChange={(event) =>
                                            this.handleChangeRowsPerPage(event)
                                        }
                                        onPageChange={this.nextPage.bind(this)}
                                    />
                                </div>
                            ) : null}
                        </div>
                        {Array.from(this.state.selectedRows).length > 0 ? (
                            <DeleteRowSelection
                                state={{ ...this.state }}
                                refreshTable={this.getTableData}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                }}
                                selectedRows={this.state.selectedRows}
                                openNotesModal={() => {
                                    this.open_notes_modal();
                                }}
                            />
                        ) : null}
                    </div>
                )}

                {this.state.open_notes_modal ? (
                    <Notes
                        openNote={this.state.open_notes_modal}
                        docId={Array.from(this.state.selectedRows)}
                        updateState={() => {
                            let count = this.state.noteCount;
                            this.setState({
                                noteCount: count++,
                            });
                        }}
                        updateStateNotes={(state) => {
                            this.setState({ ...state });
                        }}
                        handleReset={this.open_notes_modal}
                        viewAllOpen
                    />
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    inboxPage,
    getSelectedRowDetails: getSelectedRowDetails,
    deletedPage,
    selectedRowDetail,
    getConfigData: getConfigData,
    getRecordSetConfig: getRecordSetConfig,
};
const mapStateToProps = (state, ownProps) => {
    return {
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        deleted_page: state.search.deletedPage,
        inbox: state.inbox,
        ddl: state.persistDDL.DDL,
        documentTypeDetailViewMappings:
            state.configReducerPersist.configData
                .documentTypeDetailViewMappings,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeletedDocumentsDataGrid));
