const parseCSVLine = (line: string): string[] => {
    const result = [];
    let currentField = '';
    let inQuotes = false;

    for (let i = 0; i < line.length; i++) {
        const char = line[i];

        if (char === '"' && inQuotes) {
            if (line[i + 1] === '"') {
                currentField += '"'; // handle escaped quotes
                i++;
            } else {
                inQuotes = false; // closing quote
            }
        } else if (char === '"' && !inQuotes) {
            inQuotes = true; // opening quote
        } else if (char === ',' && !inQuotes) {
            result.push(currentField.trim());
            currentField = '';
        } else {
            currentField += char;
        }
    }

    result.push(currentField.trim());
    return result;
};

export default parseCSVLine;