import {
    DELETED_PAGE,
    ROW_DETAIL,
    UPDATE_SEARCH_STATE,
} from '../actionTypes/searchTypes';

const deletedPage = {
    selectedDocId: '',
    row_transition: false,
    open_notes_section: false,
    openHistory: false,
    selectedRows: [],
    hideSmallView: false,
    single_edit: false,
    blurSpinner: false,
    refreshTable: false,
    currentBigView: 'inbox',
    deleteBigView: false,
    backBtn: false,
};

const initialState = {
    deletedPage: {
        ...deletedPage,
    },
    selectedRowDetails: {},
};

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETED_PAGE:
            var old_state = {
                ...state.deletedPage,
                ...action.data,
            };
            var new_state = { deletedPage: old_state };
            return {
                ...state,
                ...new_state,
            };

        case ROW_DETAIL:
            return {
                ...state,
                selectedRowDetails: action.data,
            };
        case UPDATE_SEARCH_STATE:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
