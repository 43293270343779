/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
//import { useFormik } from "formik";
//import * as Yup from "yup";
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
//import * as auth from '../_redux/authRedux';
import {
    login,
    loginWithSession,
    getConfiguration,
    updateConfiguration,
    getProcessCount,
} from '../_redux/authCrud';
import './../../../../_metronic/_assets/sass/default-style.scss';
//import { Redirect, Switch, Route } from 'react-router-dom';
import { Toast } from 'react-bootstrap';
import Spinner from './Spinner';
//import { ToastContainer, toast } from 'react-toastify';
//import { connect } from "react-redux";
//import { getListaction, setSelectedRow,getRulesJson } from "./_redux/actions/inboxActions"
import {
    //getListaction,
    //setSelectedRow,
    //getRulesJson,
    getCountData,
} from '../../../pages/_redux/actions/inboxActions';
import {
    getConfigData,
    getActionArray,
} from '../../../pages/_redux/actions/configActions';
import {
    configurationWithStatus,
    PostMessageLogout,
} from '../../../pages/api/api';
import { toast } from 'react-toastify';
import ApplicationVersion from './ApplicationVersion';
toast.configure();
//let config = require('./configuration.json');

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            empty_username: false,
            empty_pwd: false,
            username_error: '',
            show_pwd: false,
            show_toast: false,
            toast_message: 'test',
            toast_type: 'error',
            length_password: false,
            remember_me: 0,
            spinner: false,
            submit_login: false,
            waiting: false,
            countCall: 1,
        };
        this.handleusername = this.handleusername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleRemember = this.handleRemember.bind(this);
    }

    componentDidMount() {
        let username = '';
        let password = '';
        let remember_me =
            localStorage.getItem('remember_me') !== null
                ? Number(localStorage.getItem('remember_me'))
                : 0;
        if (parseInt(localStorage.getItem('remember_me')) === 1) {
            username = localStorage.getItem('username');
            password = localStorage.getItem('password');
        }
        remember_me = isNaN(remember_me) ? 0 : remember_me;
        this.setState({
            remember_me,
            username,
            password,
        });
        if (
            this.props.location.state &&
            String(this.props.location.state.msg) !== ''
        ) {
            let suc_msg = this.props.location.state.msg;
            //let msg_type = this.props.location.state.msgtype;
            toast.success(suc_msg, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        document.addEventListener('keydown', this.onEnterKey);
        let sessionID = window.location.search.substring(1);
        if (sessionID) this.submit_sessionid_login(sessionID);
    }

    onEnterKey = (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            if (this.state.submit_login === false) {
                this.submit_login();
            }
        }
    };
    async processStatusIds(item) {
        let entireStatusIds = [];
        let statusIdsCount = item.processes?.reduce(
            (pre, cur) => pre + cur.statusIds.length,
            0
        );

        item.processes.forEach((process) => {
            process.statusIds.forEach((statusId) => {
                entireStatusIds.push(statusId);
            });
        });

        try {
            // hanlded by krish for cuttoff loop and access in promise all apis
            const results = await Promise.allSettled(entireStatusIds);
            console.log('SETTLED');

            if (
                results.some(
                    (result) =>
                        result.status === 'fulfilled' &&
                        result.value?.code === 300
                )
            ) {
                PostMessageLogout();
            }

            let length = statusIdsCount - 1;
            console.log(
                `Total Status IDs: ${statusIdsCount}, Processed Length: ${length}`
            );

            this.getProcessCount(JSON.stringify(this.state.processData));
        } catch (error) {
            console.error(error);
        }
    }

    async inboxFunctionStatus(data) {
        if (data.menuItems && data.menuItems.length > 0) {
            for (const item of data.menuItems) {
                if (item.menuName === 'Inbox') {
                    await this.processStatusIds(item);
                }
            }
        }
    }
    async outboxFunctionStatus(data) {
        if (data.menuItems && data.menuItems.length > 0) {
            for (const item of data.menuItems) {
                if (item.menuName === 'Outbox') {
                    await this.processStatusIds(item);
                }
            }
        }
    }
    async configurationFunctionStatus(data) {
        if (data.menuItems && data.menuItems.length > 0) {
            for (const item of data.menuItems) {
                if (item.menuName === 'Inbox') {
                    await this.inboxFunctionStatus(data);
                }
                if (item.menuName === 'Outbox') {
                    await this.outboxFunctionStatus(data);
                }
            }
        }
    }
    get_configuration = async () => {
        this.setState({ spinner: true });
        var status = '';
        getConfiguration()
            .then((response) => {
                status = response.status;
                if (response.status !== 200) {
                    toast.error(
                        'Login Failed. User Configuration Error. Please contact your administrator.',
                        {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    this.setState({ spinner: false, waiting: false });
                } else return response.json();
            })
            .then(async (data) => {
                if (data && data?.code === 300) {
                    PostMessageLogout();
                }
                if (data !== undefined) {
                    if (data.isCompleteConfiguration === false) {
                        this.get_configuration();
                    } else if (data.isCompleteConfiguration === true) {
                        localStorage.setItem(
                            'configData',
                            JSON.stringify(data)
                        );
                        this.setState({
                            statusCount:
                                data.statusNamedIdsNeedingConfiguration.length,
                            processData: data,
                        });
                        localStorage.setItem(
                            'initialCustomSearchNamedIdsNeedingConfiguration',
                            JSON.stringify(
                                data.customSearchNamedIdsNeedingConfiguration
                            )
                        );

                        await this.configurationFunctionStatus(data);

                        var IndexingSettings = {};
                        if (data.indexingSettings !== undefined) {
                            for (const property in data.indexingSettings) {
                                var key = property.toLowerCase();
                                IndexingSettings[key] =
                                    data.indexingSettings[property];
                            }
                        }

                        data.IndexingSettings = IndexingSettings;

                        if (this.props.getConfigData !== undefined) {
                            this.props.getConfigData(data);
                        }

                        // Process menu items
                        if (data.menuItems && data.menuItems.length > 0) {
                            var menu_list = [];

                            await Promise.all(
                                data.menuItems.map(async (menu) => {
                                    var temp = menu.menuName.toLowerCase();
                                    menu_list.push(temp);
                                    var current_menu = '';
                                    if (menu.menuName.toLowerCase() === 'inbox')
                                        current_menu = 'inbox';
                                    if (
                                        menu.menuName.toLowerCase() === 'outbox'
                                    )
                                        current_menu = 'outbox';
                                    if (
                                        menu.menuName.toLowerCase() ===
                                            current_menu &&
                                        menu.processes &&
                                        menu.processes.length > 0
                                    ) {
                                        await Promise.all(
                                            menu.processes.map(
                                                async (value) => {
                                                    var menuLink = '';
                                                    var cc = 0;
                                                    if (
                                                        value.statuses &&
                                                        value.statuses.length >
                                                            0
                                                    ) {
                                                        value.statuses.forEach(
                                                            (val) => {
                                                                if (cc === 0) {
                                                                    menuLink =
                                                                        '/' +
                                                                        current_menu +
                                                                        '/' +
                                                                        String(
                                                                            val.name
                                                                        )
                                                                            .replace(
                                                                                /\s/g,
                                                                                '-'
                                                                            )
                                                                            .toLowerCase() +
                                                                        '/' +
                                                                        value.processId +
                                                                        '/' +
                                                                        val.statusId;
                                                                    if (
                                                                        menu.menuName.toLowerCase() ===
                                                                        'inbox'
                                                                    )
                                                                        localStorage.setItem(
                                                                            'inboxLink',
                                                                            menuLink
                                                                        );
                                                                    if (
                                                                        menu.menuName.toLowerCase() ===
                                                                        'outbox'
                                                                    )
                                                                        localStorage.setItem(
                                                                            'outboxLink',
                                                                            menuLink
                                                                        );
                                                                }
                                                                cc++;
                                                            }
                                                        );
                                                    }
                                                }
                                            )
                                        );
                                    }
                                })
                            );
                            if (menu_list.includes('inbox')) {
                                localStorage.setItem('inboxMenu', true);
                            } else {
                                localStorage.setItem('inboxMenu', false);
                            }

                            toast.success('Success', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            this.setState({ submit_login: false });
                            if (status === 400) {
                                toast.error(
                                    'Login Failed. User Configuration Error. Please contact your administrator.',
                                    {
                                        position: 'top-right',
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    }
                                );
                            }
                            setTimeout(() => {
                                this.setState({ spinner: false });
                            }, 5000);
                        }

                        updateConfiguration()
                            .then((updateResponse) => {
                                if (updateResponse.status === 200) {
                                    return updateResponse.json();
                                } else {
                                    throw new Error(
                                        'Failed to update configuration'
                                    );
                                }
                            })
                            .then((newConfigData) => {
                                localStorage.setItem(
                                    'configData',
                                    JSON.stringify(newConfigData)
                                );
                                this.setState({ processData: newConfigData });

                                if (this.props.getConfigData !== undefined) {
                                    this.props.getConfigData(newConfigData);
                                }

                                console.log(
                                    'Configuration updated successfully.'
                                );
                            })
                            .catch((error) => {
                                console.error(
                                    'Error updating configuration:',
                                    error
                                );
                            });
                    }
                }
            })
            .catch((error) => {
                this.setState({ spinner: false, waiting: false });
                console.error('Error fetching configuration:', error);
            });
    };
    countApiFunction(idx) {
        let count = [];
        let i = 0;
        getProcessCount(idx)
            .then((response) => {
                if (response.status !== 200) {
                    let countCall = this.state.countCall + 1;
                    this.setState({
                        countCall: countCall,
                    });
                    if (this.state.countCall < 5) {
                        this.countApiFunction(idx);
                    }
                }
                return response.json();
            })
            .then(async (data) => {
                if (data && data?.code === 300) {
                    PostMessageLogout();
                }
                this.setState({ spinner: true });
                this.setState({ waiting: true });

                count.push(data);
                i++;
                if (i === process.length) {
                    localStorage.setItem('count_redux', JSON.stringify(count));

                    localStorage.setItem('valid_user', true);

                    if (this.props.getCountData !== undefined) {
                        await this.props.getCountData(count);
                    }

                    setTimeout(() => {
                        this.props.history.push('/dashboard');
                    }, 10);
                    this.setState({ spinner: true });
                    this.setState({ waiting: true });
                }

                let counts = [];
                counts[0] = data[0].statuses.sort(
                    (a, b) => a.statusId - b.statusId
                );
                counts[1] = data[1].statuses.sort(
                    (a, b) => a.statusId - b.statusId
                );

                localStorage.setItem('count_redux', JSON.stringify(data));

                localStorage.setItem('valid_user', true);
            });
    }

    //START: Api to get count config api
    getProcessCount = async (data) => {
        let initialData = data;
        let configData = [];
        configData.push(JSON.parse(data));
        const menuItems =
            configData[0].menuItems !== undefined
                ? configData[0].menuItems
                : [];
        let i = 0;
        let process = [];

        let count = [];
        await menuItems.forEach(async (data, key) => {
            if (data.displayCounts === true) {
                await data.processes.forEach(async (value, index) => {
                    process.push(value.processId);
                    await getProcessCount(value.processId)
                        .then((response) => {
                            // status = response.status;
                            if (response.status === 400) {
                                this.getProcessCount(initialData);
                            }
                            return response.json();
                        })
                        .then(async (data) => {
                            if (data && data?.code === 300) {
                                PostMessageLogout();
                            }
                            this.setState({ spinner: true });
                            this.setState({ waiting: true });
                            count.push(data);
                            i++;
                            if (i === process.length) {
                                localStorage.setItem(
                                    'count_redux',
                                    JSON.stringify(count)
                                );

                                localStorage.setItem('valid_user', true);

                                if (this.props.getCountData !== undefined) {
                                    await this.props.getCountData(count);
                                }

                                setTimeout(() => {
                                    this.props.history.push('/dashboard');
                                }, 10);
                                this.setState({ spinner: true });
                                this.setState({ waiting: true });
                            }
                        });
                });
            }
        });
    };
    //END: Api to get count config api

    submit_login = () => {
        if (this.state.username === '') {
            this.setState({
                empty_username: true,
                username_error: 'Username is required',
            });
        } else {
            this.setState({ empty_username: false });
        }

        if (this.state.password === '') {
            this.setState({ empty_pwd: true });
        } else {
            this.setState({ empty_pwd: false, length_password: false });
        }

        if (this.state.username !== '' && this.state.password !== '') {
            this.setState({ spinner: true, submit_login: true });

            this.setState({ empty_username: false });
            this.setState({ empty_pwd: false });

            if (this.state.remember_me) {
                localStorage.setItem('remember_me', this.state.remember_me);
                localStorage.setItem('username', this.state.username);
                localStorage.setItem('password', this.state.password);
            } else {
                localStorage.setItem('remember_me', 0);
                localStorage.setItem('username', '');
                localStorage.setItem('password', '');
            }
            var status = '';
            var token = '';
            this.setState({ spinner: true });
            this.setState({ waiting: true });
            login(
                this.state.username,
                this.state.password,
                localStorage.getItem('sub_domain_name')
            )
                .then((response) => {
                    token = response.headers.get('WV-Token');
                    localStorage.setItem('WV-Token', token);
                    status = response.status;
                    return response.json();
                })
                .then((data) => {
                    this.setState({ spinner: false });

                    if (data !== '') {
                        if (parseInt(status) === 200) {
                            localStorage.setItem(
                                'user_details',
                                JSON.stringify(data)
                            );

                            localStorage.setItem(
                                'hasPasswordExpired',
                                data.hasPasswordExpired
                            );

                            if (
                                localStorage.getItem('hasPasswordExpired') ===
                                'true'
                            ) {
                                //kalpana
                                this.props.history.push(
                                    '/auth/change-password'
                                );
                            } else {
                                this.setState({ spinner: true });
                                this.setState({ waiting: true });
                                this.get_configuration();
                            }
                        } else if (parseInt(status) === 401) {
                            this.setState({ submit_login: false });
                            this.setState({ waiting: false });
                            toast.error(data.message, {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            this.setState({ submit_login: false });
                            this.setState({ waiting: false });
                            toast.error('Invalid login credentials', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    } else {
                        this.setState({ submit_login: false });
                        this.setState({ waiting: false });
                        toast.error('Invalid login credentials', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ spinner: false });
                    this.setState({ waiting: false });
                });

            // }
        }
    };

    submit_sessionid_login = (sessionID) => {
        this.setState({ spinner: true, submit_login: true });

        var status = '';
        var token = '';
        this.setState({ waiting: true });
        loginWithSession(localStorage.getItem('sub_domain_name'), sessionID)
            .then((response) => {
                token = response.headers.get('WV-Token');
                localStorage.setItem('WV-Token', token);
                status = response.status;
                return response.json();
            })
            .then((data) => {
                this.setState({ spinner: false });

                if (data !== '') {
                    if (parseInt(status) === 200) {
                        localStorage.setItem(
                            'user_details',
                            JSON.stringify(data)
                        );

                        localStorage.setItem(
                            'hasPasswordExpired',
                            data.hasPasswordExpired
                        );

                        if (
                            localStorage.getItem('hasPasswordExpired') ===
                            'true'
                        ) {
                            //kalpana
                            this.props.history.push('/auth/change-password');
                        } else {
                            this.setState({ spinner: true });
                            this.setState({ waiting: true });
                            this.get_configuration();
                        }
                    } else if (parseInt(status) === 401) {
                        this.setState({ submit_login: false });
                        this.setState({ waiting: false });
                        toast.error(data.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        this.setState({ submit_login: false });
                        this.setState({ waiting: false });
                        toast.error('Invalid login credentials', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    this.setState({ submit_login: false });
                    this.setState({ waiting: false });
                    toast.error('Invalid login credentials', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                this.setState({ spinner: false });
                this.setState({ waiting: false });
            });
    };

    handleusername(event) {
        this.setState({ username: event.target.value });
    }

    handlePassword(event) {
        this.setState({ password: event.target.value });
    }

    show_pwd = () => {
        this.setState({ show_pwd: !this.state.show_pwd });
    };

    handleRemember(event) {
        this.setState({ remember_me: event.target.checked ? 1 : 0 });
    }

    handleKeyPress = (event) => {
        if (String(event.key) === 'Enter') {
            this.submit_login();
        }
    };
    close_message = () => {
        this.setState({ show_toast: false });
    };

    render() {
        return (
            <>
                <div
                    className={this.state.toast_type}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '50%',
                    }}
                >
                    {/* delay={5000} autohide */}
                    <Toast
                        show={this.state.show_toast}
                        onClose={this.close_message}
                    >
                        <Toast.Header>Sign In</Toast.Header>
                        <Toast.Body>{this.state.toast_message}</Toast.Body>
                    </Toast>
                </div>
                <div className='whole-form'>
                    {/*  <div className='Logo text-center  mb-5 mb-lg-15'>
                        <a href='#'>
                            {/*<img src={window.location.origin+ '/images/WV-Logo.png'} />*/}
                    {/*  </a>
                    </div> */}
                    <div className='Logo text-center  mb-5 mb-lg-15'>
                        <a href='#'></a>
                    </div>
                    <div
                        className='login-form login-signin'
                        id='kt_login_signin_form'
                    >
                        {/* begin::Head */}
                        <div className='text-center mb-5 mb-lg-12'>
                            <h3 className='font-size-h1 title-color'>
                                <FormattedMessage id='AUTH.LOGIN.TITLE' />
                            </h3>
                            <p className='mt-4'>
                                Enter your details to login to your account:
                            </p>
                        </div>
                        {/* end::Head */}

                        {/*begin::Form*/}
                        <div
                            // onSubmit={submit_login}
                            className='form fv-plugins-bootstrap fv-plugins-framework'
                        >
                            <div className='form-group fv-plugins-icon-container'>
                                <input
                                    placeholder='Username'
                                    type='text'
                                    className={
                                        'form-control form-control-solid h-auto py-4 px-6 '
                                    }
                                    name='username'
                                    maxLength='50'
                                    value={this.state.username}
                                    onChange={this.handleusername}
                                />
                                {this.state.empty_username ? (
                                    <p style={{ color: 'red' }}>
                                        {this.state.username_error}
                                    </p>
                                ) : null}
                            </div>
                            <div className='form-group fv-plugins-icon-container input-icon input-icon-right mb-3'>
                                <input
                                    placeholder='Password'
                                    type={
                                        this.state.show_pwd
                                            ? 'text'
                                            : 'password'
                                    }
                                    className={
                                        'form-control form-control-solid h-auto py-4 px-6 pr-15'
                                    }
                                    name='password'
                                    maxLength='20'
                                    value={this.state.password}
                                    onChange={this.handlePassword}
                                    //  onKeyPress={this.handleKeyPress}
                                />

                                <span className='pr-5' onClick={this.show_pwd}>
                                    {this.state.show_pwd ? (
                                        <i className='icon-md fas fa-eye-slash'></i>
                                    ) : (
                                        <i className='icon-md fas fa-eye'></i>
                                    )}
                                </span>
                                {this.state.empty_pwd ? (
                                    <p style={{ color: 'red' }}>
                                        Password is required
                                    </p>
                                ) : null}
                                {this.state.length_password ? (
                                    <p style={{ color: 'red' }}>
                                        Password contains atleast minimum 8
                                        characters.
                                    </p>
                                ) : null}
                            </div>
                            <div className='form-group d-flex flex-wrap justify-content-between mb-6 align-items-center'>
                                {/*<Link
                                        to="/auth/forgot-password"
                                        className="text-dark-50 text-hover-primary my-3 mr-2"
                                        id="kt_login_forgot"
                                    >
                                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                                    </Link>*/}
                                <div className='checkbox-inline pl-0 '>
                                    <label className='checkbox checkbox-lg text-dark-50 text-hover-primary my-3 mr-2 p-0'>
                                        <input
                                            type='checkbox'
                                            className='checked'
                                            name='Checkboxes3_1'
                                            onChange={this.handleRemember}
                                            checked={this.state.remember_me}
                                        />
                                        <span></span>Remember me
                                    </label>
                                </div>

                                <Link
                                    to='/auth/forgot-password'
                                    className='text-dark-50 text-hover-primary my-3 mr-2'
                                    id=''
                                    title='Forgot Password'
                                >
                                    Forgot Password?
                                </Link>
                            </div>

                            {!this.state.waiting ? (
                                <>
                                    <div className='login-process'>
                                        <button
                                            title='Sign In'
                                            id='kt_login_signin_submit'
                                            onClick={this.submit_login}
                                            className={`btn btn-primary  font-weight-bold px-9 py-4 my-3 login-btn btn-block`}
                                        >
                                            <span>Sign In</span>
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='login-overlay'>
                                        <div className='login-process'>
                                            <button
                                                title='Signing In..'
                                                id='kt_login_signin_submit'
                                                className={`btn btn-primary  font-weight-bold px-9 py-4 my-3 login-btn btn-block`}
                                            >
                                                <span>Signing In..</span>
                                            </button>
                                            <Spinner show={true} />
                                            <span className={`waiting-msg`}>
                                                Please wait while workspace
                                                loads...
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {/*end::Form*/}
                    </div>
                </div>
                <div className='d-flex footer_text justify-content-center justify-content-lg-start justify-content-center align-items-end py-lg-0'>
                    <p>
                        <ApplicationVersion />
                    </p>
                </div>
            </>
        );
    }
}

//export default withRouter(Login);

const mapDispatchToProps = {
    getConfigData: getConfigData,
    getActionArray: getActionArray,
    getCountData: getCountData,
};
const mapStateToProps = (state, ownProps) => {
    return {
        inbox: state.inbox,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

/* var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);*/
