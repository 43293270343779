import React, { useEffect, useState } from 'react';

const SearchFilter = (props) => {
    const [text, setText] = useState('');
    const [ourTimeout, setOurTimeout] = useState(null);
    const [focused, setFocused] = useState(false);
    const [showFilterMessage, setShowFilterMessage] = useState(false);

    useEffect(() => {
        setText(props.value);
    }, [props.value]);

    useEffect(() => {
        if (text !== '' && text.length > 0) {
            if (ourTimeout != null) {
                clearInterval(ourTimeout);
            }
            setOurTimeout(
                setTimeout(
                    () => {
                        props.callBack(text);
                        setShowFilterMessage(true);
                    },
                    text.length === 0 ? 400 : 800
                )
            );
        } else {
            clearInterval(ourTimeout);
            props.callBack('');
            setShowFilterMessage(false);
        }
    }, [text]);

    const clearInput = () => {
        setText('');
        props.callBack('');
        setShowFilterMessage(false);
    };

    return (
        <div className='d-flex'>
            {showFilterMessage && !!text && (
                <div
                    className='font-weight-bold text-nowrap'
                    style={{
                        color: '#007dbc',
                        lineHeight: '32px',
                        paddingRight: '10px',
                    }}
                >
                    Clear the filter to see all the documents
                </div>
            )}
            <div className='filter d-flex' style={{ position: 'relative' }}>
                <input
                    {...props.inputProps}
                    style={{
                        ...props.style,
                        border: focused
                            ? '1px solid black'
                            : text
                            ? '1px solid #007DBC'
                            : '',
                    }}
                    disabled={props.disabled}
                    type='text'
                    placeholder={
                        props.placeholder?.length > 0
                            ? props.placeholder
                            : 'Filter'
                    }
                    className='form-control active patient-autoComplete form-control'
                    value={text}
                    onChange={(event) => {
                        setText(event.target.value);
                        setShowFilterMessage(false);
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                />
                {text && (
                    <i className='fa-regular fa-xmark' onClick={clearInput} />
                )}
                {!text && <i className='fa-regular fa-magnifying-glass' />}
            </div>
        </div>
    );
};

/**
 * @property _value_ : string | callBack: function | placeholder : string | style : input style props
 */
export default React.memo(SearchFilter);
