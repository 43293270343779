/* eslint-disable prettier/prettier */
import {
    GetFieldData,
    showErrorToast,
    createTagElement,
} from '../commonfunction';
import { outboxDocumentAction } from '../../pages/api/api';

export async function AddTags(
    tag,
    stateValues,
    setValues,
    props,
    via,
    action,
    finalTags = []
) {
    const totalJoin = stateValues.menu_selected_tagonly.join('|');

    // console.log('totalJoin:', totalJoin.length);
    // console.log('tag:', tag.name.length);

    if (action === 'add') {
        if (
            totalJoin.length >= 250 ||
            totalJoin.length + tag.name.length >= 250
        ) {
            showErrorToast('Document has no room for more tags.');
            return;
        }
    }

    let successDocId = [];
    let docid = [];
    if (!(action === 'delete')) {
        if (stateValues.menu_selected_tagonly.includes(tag.name)) {
            if (action === 'remove') {
                const tags = stateValues.menu_selected_tag.filter(
                    (item) => item.name.toLowerCase() !== tag.name.toLowerCase()
                );

                const tagsNameOnly = stateValues.menu_selected_tagonly.filter(
                    (item) => item.toLowerCase() !== tag.name.toLowerCase()
                );

                setValues({
                    menu_selected_tag: tags,
                    menu_selected_tagonly: tagsNameOnly,
                });
            } else {
                return true;
            }
        } else if (stateValues.menu_selected_tag.indexOf(tag) === -1) {
            setValues({
                menu_selected_tag: [...stateValues.menu_selected_tag, tag],
            });
            stateValues.menu_selected_tagonly.push(tag.name);
        }
        setValues({ spinner: true, tag_action: true, tagLoader: true });

        const selected_rows = Array.from(stateValues.selectedRows);
        await stateValues.rowData.map(async (data, index) => {
            if (selected_rows.includes(data.id)) {
                docid.push({
                    id: data.doc_id,
                    tags: data.tags_data,
                    index: index,
                });
            }
            return data;
        });
    }

    if (action === 'delete') {
        docid = finalTags;
    }

    const fieldDataObj = GetFieldData({
        configData: props.configData,
    });

    let failedDocId = [];

    await Promise.all(
        docid.map(async (data, index) => {
            const fieldData = {};
            const join = stateValues.menu_selected_tagonly.join('|');

            if (action === 'add') {
                fieldData.fieldId = fieldDataObj.tagFieldId;
                if (data.tags !== '' && tag.name.includes(data.tags) !== true) {
                    fieldData.value = `${join}|${data.tags}`;
                } else {
                    fieldData.value = join;
                }
                fieldData.value = [...new Set(fieldData.value.split('|'))].join(
                    '|'
                );
            } else if (action === 'replace') {
                fieldData.fieldId = fieldDataObj.tagFieldId;
                fieldData.value = join;
            } else if (action === 'delete') {
                fieldData.fieldId = fieldDataObj.tagFieldId;
                fieldData.value = data.tags;
            } else {
                var tagsToFilter = data.tags.split('|');
                var finalTags = tagsToFilter.filter(function (item) {
                    return item !== tag.name;
                });
                fieldData.fieldId = fieldDataObj.tagFieldId;
                fieldData.value = `${finalTags.join('|')}`;
            }

            if (fieldData.value.charAt(0) === '|') {
                fieldData.value = fieldData.value.substring(1);
            }
            if (fieldData.value.charAt(fieldData.value.length - 1) === '|') {
                fieldData.value = fieldData.value.substring(
                    0,
                    fieldData.value.length - 1
                );
            }
            const tagIndex = data.index;
            const finalId = action === 'delete' ? data.doc_id : data.id;

            const params = {};
            const fields = {};
            fields.docIds = [finalId];
            params.processId = props.processId;
            params.statusId = props.statusId;
            params.actionId = props.allActions.update_tag.actionId;

            fields.fields = [
                {
                    fieldId: fieldDataObj.tags,
                    value: fieldData.value,
                },
            ];
            params.fields = fields;

            await outboxDocumentAction(params)
                .then(async (response) => {
                    let result = await response.json();
                    return result;
                })
                .then(async (data) => {
                    if (data.hadFailures === false) {
                        await data.results.map(async (item, idx) => {
                            if (item.actionSucceeded === true) {
                                successDocId.push(item.docId);
                            }
                        });
                    }
                    if (data.hadFailures === true) {
                        await data.results.map(async (item, idx) => {
                            if (item.actionSucceeded === false) {
                                failedDocId.push(item.docId);
                            } else {
                                successDocId.push(item.docId);
                            }
                        });
                    }
                });

            let message;

            let totalCount = failedDocId.length + successDocId.length;
            if (
                failedDocId.length !== 0 &&
                (Number(docid.length) === Number(failedDocId.length) ||
                    Number(docid.length) === Number(totalCount))
            ) {
                let sortArray = failedDocId.sort();
                if (sortArray.length === 1) {
                    message = `Doc Id  ${sortArray[0]} is  Locked`;
                } else {
                    message = `Doc Ids `;
                    sortArray.map(async (item, idx) => {
                        if (
                            idx !== sortArray.length - 1 &&
                            idx !== sortArray.length - 2
                        ) {
                            message += `${item}, `;
                        } else if (idx === sortArray.length - 2) {
                            message += `${item}`;
                        } else {
                            message += ` and ${item} `;
                        }
                    });
                    message += `are Locked`;
                }

                showErrorToast(message);
            }

            if (action === 'delete') {
                var filtered = stateValues.delete_tags.filter((value) => {
                    return value.name !== tag.name;
                });

                setValues({
                    delete_tags: filtered,
                });
            }

            if (successDocId.length !== 0) {
                if (successDocId.includes(finalId)) {
                    const { tagElement } =
                        via === 'outbox'
                            ? createTagElement(
                                  fieldData.value,
                                  props.configData,
                                  action === 'delete' ? true : false,
                                  '',
                                  props.ddl.tags
                              )
                            : createTagElement(
                                  fieldData.value,
                                  props.configData,
                                  true,
                                  '',
                                  props.ddl.tags
                              );
                    let tagIndexPosition = '';
                    const tableData =
                        via === 'outbox'
                            ? props.outbox.outbox.dataTable
                            : props.inbox.inbox.dataTable;
                    tableData.headerColumns.map((data, index) => {
                        if (data.name.toLowerCase() === 'tags') {
                            tagIndexPosition = index;
                        }
                    });
                    stateValues.rowData[tagIndex].tags = tagElement;
                    stateValues.rowData[tagIndex].tags_data = fieldData.value;

                    tableData.dataRows[tagIndex].values[tagIndexPosition] =
                        fieldData.value;

                    if (via === 'outbox') {
                        props.outbox.outbox.dataTable = tableData;
                        props.updateOutboxState({
                            rowData: stateValues.rowData,
                            spinner: false,
                            tagLoader: false,
                        });
                        props.updateList(props.outbox.outbox);
                    } else {
                        props.inbox.inbox.dataTable = tableData;
                        props.updateInboxState({
                            rowData: stateValues.rowData,
                            spinner: false,
                            tagLoader: false,
                        });
                        props.updateList(props.inbox.inbox);
                    }
                    setValues({
                        spinner: false,
                        tagLoader: false,
                    });
                }
            } else {
                if (via === 'outbox') {
                    props.updateOutboxState({
                        tagLoader: false,
                        selected_tag: [],
                    });
                } else {
                    props.updateInboxState({
                        tagLoader: false,
                        selected_tag: [],
                    });
                }
                setValues({
                    tagLoader: false,
                    selected_tag: [],
                });
            }
        })
    );
}
