import React from 'react';
import Loader from 'react-loader-spinner';

interface SpinnerProps {
    show: boolean;
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
    return props.show ? (
        <div className='overlay-loader'>
            <Loader type='Oval' color='#007dbc' height={40} width={40} />
        </div>
    ) : null;
};

export const SpinnerTwo: React.FC<SpinnerProps> = (props) => {
    return props.show ? (
        <div className='spinner-loader'>
            <Loader type='Oval' color='#007dbc' height={40} width={40} />
        </div>
    ) : null;
};

export const SpinnerBlur: React.FC<SpinnerProps> = (props) => {
    return props.show ? (
        <div className='spinner-blur'>
            <Loader type='Oval' color='#007dbc' height={40} width={40} />
        </div>
    ) : null;
};

export const SpinnerThree: React.FC<SpinnerProps> = (props) => {
    return props.show ? (
        <div style={{ textAlign: 'center' }} className='spinner_3'>
            <Loader type='Oval' color='#007dbc' height={40} width={40} />
        </div>
    ) : null;
};

export const Spinner3: React.FC<SpinnerProps> = (props) => {
    return props.show ? (
        <div style={{ textAlign: 'center' }} className='spinner_3'>
            <Loader type='Oval' color='#007dbc' height={40} width={40} />
        </div>
    ) : null;
};

export default Spinner;

