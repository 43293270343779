/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useSelector, useDispatch } from 'react-redux';
import { outboxPage } from '../../_redux/actions/outboxActions';
import { colorCodearray } from '../../../components/commonfunction';

function Statistics(props) {
    const [daysoutstanding, setDaysoutstanding] = React.useState([]);
    const [outboundstatus, setOutboundstatus] = React.useState([]);
    const [colorCode] = React.useState(colorCodearray);
    const [displayAnimation, setDisplayAnimation] = React.useState(false);
    const [agingcount, setAgingcount] = React.useState('');
    const [statuscount, setStatuscount] = React.useState('');
    const [currentStatistics, setCurrentStatistics] =
        React.useState('by_aging');
    const redux_store = useSelector((state) => state);
    const outboxData = redux_store.outbox.outbox;
    const outbox_page = redux_store.outbox.outboxpage;
    const filteredData = useSelector(
        (state) => state.outbox.outboxpage.filteredData
    );
    const [indexActive, setIndexActive] = React.useState('');
    const dispatch = useDispatch();
    let allRowData = useSelector((state) => state.outbox.outboxpage.allRowData);

    useEffect(() => {
        if (Array.isArray(filteredData) && filteredData.length > 0) {
            allRowData = filteredData;
            // dispatch(outboxPage({ allRowData: filteredData }));
        }

        if (!outbox_page.hasOwnProperty('dataRows')) {
            const temp = { ...outboxData.dataTable };
            const initial = outbox_page.allRowData;
            dispatch(outboxPage({ ...temp, initialData: initial }));
        }
        const arr1 = [];
        const arr2 = [];
        const arr3 = [];
        const arr4 = [];
        const arr5 = [];
        const arr6 = [];
        const ageing = [arr1, arr2, arr3, arr4, arr5, arr6];
        let agingcounting = 0;
        if (allRowData !== undefined) {
            allRowData.map((data) => {
                if (data.daysoutstanding >= 0 && data.daysoutstanding <= 14)
                    arr1.push(data);
                else if (
                    data.daysoutstanding >= 15 &&
                    data.daysoutstanding <= 30
                )
                    arr2.push(data);
                else if (
                    data.daysoutstanding >= 31 &&
                    data.daysoutstanding <= 50
                )
                    arr3.push(data);
                else if (
                    data.daysoutstanding >= 51 &&
                    data.daysoutstanding <= 75
                )
                    arr4.push(data);
                else if (
                    data.daysoutstanding >= 76 &&
                    data.daysoutstanding <= 119
                )
                    // arr5.push(data.daysoutstanding);
                    arr5.push(data);
                else if (data.daysoutstanding >= 120) arr6.push(data);
                agingcounting++;
            });

            setAgingcount(agingcounting);
            setDaysoutstanding(ageing);

            let statuscounting = 0;
            const result = allRowData.reduce(function (r, a, c) {
                statuscounting++;
                r[a.outboundstatus] = r[a.outboundstatus] || [];
                // r['testing'] = r['testing'] || [];
                r[a.outboundstatus].push(a);
                // if (c < 20) {
                //     r['testing'].push(a);
                // }
                return r;
            }, Object.create(null));

            setStatuscount(statuscounting);
            setOutboundstatus(result);
            return () => {};
        }
    }, [filteredData]);

    const handleStatisticsOnChange = async (tab) => {
        dispatch(outboxPage({ refreshTable: Math.random() }));
        outboxData.dataTable.dataRows = outbox_page.dataRows;
        if (tab === 'refresh') {
            setDisplayAnimation(true);
            tab = currentStatistics;
        }
        setIndexActive('');
        setCurrentStatistics(tab);
        setTimeout(() => {
            setDisplayAnimation(false);

            // document.getElementById('right-aside').height = result;
        }, 2000);
    };

    const openByAging = (tableData, id_index) => {
        setIndexActive(id_index);
        dispatch(
            outboxPage({
                byAging: true,
                byStatus: false,
                byAgingData: tableData,
                token: Math.random(),
            })
        );
    };

    const byAgeingBottomBar = () => {
        const html = [];
        let id_index = 0;
        let percen = 0;

        // eslint-disable-next-line guard-for-in
        for (const property in daysoutstanding) {
            id_index = parseInt(property, 10);
            percen = (daysoutstanding[property].length / agingcount) * 100;
            if (daysoutstanding[property].length > 0) {
                html.push(
                    <div
                        key={property}
                        className='status'
                        style={{
                            width: `${percen}%`,
                            background: colorCode[id_index],
                        }}
                    />
                );
            }
        }
        return html;
    };

    const byStatusBottomBar = () => {
        const html = [];
        let id_index = 0;
        let percen = 0;

        for (const property in outboundstatus) {
            percen = (outboundstatus[property].length / statuscount) * 100;
            if (outboundstatus[property].length > 0) {
                html.push(
                    <div
                        key={property}
                        className='status'
                        style={{
                            width: `${percen}%`,
                            background: colorCode[id_index],
                        }}
                    />
                );
            }
            id_index++;
        }
        return html;
    };

    const byAgeing = () => {
        let id_index = 0;
        const html = [];
        const ageRange = ['0-14', '15-30', '31-50', '51-75', '76-119', '120+'];
        for (const property in daysoutstanding) {
            id_index = parseInt(property, 10);
            const hex = colorCode[id_index];
            const red = parseInt(hex[1] + hex[2], 16);
            const green = parseInt(hex[3] + hex[4], 16);
            const blue = parseInt(hex[5] + hex[6], 16);
            const backgroundColor = `rgb(${red}, ${green}, ${blue},.1)`;

            html.push(
                <React.Fragment key={property}>
                    <div
                        className={
                            indexActive === property
                                ? 'common_block outstanding active'
                                : 'common_block outstanding'
                        }
                        style={
                            indexActive === property
                                ? { background: backgroundColor }
                                : null
                        }
                        onClick={() => {
                            openByAging(daysoutstanding[property], property);
                        }}
                    >
                        <span
                            className='bd-color'
                            style={{
                                background: colorCode[id_index],
                            }}
                        />
                        <span>{daysoutstanding[property].length}</span>
                        <h6 className=''>{ageRange[id_index]} days</h6>
                    </div>
                </React.Fragment>
            );
        }
        return html;
    };

    const openByStatus = (tableData, property) => {
        setIndexActive({ property });

        dispatch(
            outboxPage({
                byAging: true,
                byStatus: false,
                byAgingData: tableData,
                token: Math.random(),
            })
        );
    };

    const byStatus = () => {
        let idx = 0;
        const html = [];
        for (const property in outboundstatus) {
            html.push(
                <React.Fragment key={property}>
                    <div
                        className={
                            indexActive.property === property
                                ? 'common_block outstanding active'
                                : 'common_block outstanding'
                        }
                        onClick={() => {
                            openByStatus(outboundstatus[property], property);
                        }}
                    >
                        <span
                            className='bd-color'
                            style={{
                                background: colorCode[idx],
                            }}
                        />
                        <span>{outboundstatus[property].length}</span>
                        <h6 className=''>{property}</h6>
                    </div>
                </React.Fragment>
            );
            idx++;
        }

        return html;
    };

    return (
        <>
            <div
                id='Statistic-part'
                className='wrap_details wrap_note p-4 Statistic-part ml-7 mr-7'
            >
                <div className='d-flex justify-content-between mb-5 statistic-top-sec'>
                    <div className='d-flex align-items-center statistics_header'>
                        <h3 className='mb-0'>
                            <i
                                title='Chart-line'
                                className='fas fa-chart-line'
                            />{' '}
                            Statistics
                        </h3>
                    </div>
                    <div className='d-flex'>
                        <span
                            className='align-self-center reload'
                            onClick={() => {
                                handleStatisticsOnChange('refresh');
                            }}
                        >
                            {' '}
                            <i
                                title='Refresh'
                                className={
                                    displayAnimation
                                        ? 'fas fa-sync-alt refreshing'
                                        : 'fas fa-sync-alt'
                                }
                            />{' '}
                        </span>

                        <div className='tab_part'>
                            <div className='tabs'>
                                <Nav
                                    style={{
                                        float: 'left',
                                    }}
                                    id='noanim-tab'
                                    defaultActiveKey='link-2'
                                    variant='pills'
                                    className=''
                                >
                                    <Nav.Item>
                                        <Nav.Link
                                            title='By Status'
                                            key={0}
                                            eventKey='link-1'
                                            onClick={() => {
                                                handleStatisticsOnChange(
                                                    'by_status'
                                                );
                                            }}
                                            className={({ isActive }) =>
                                                isActive ? 'active' : 'inactive'
                                            }
                                        >
                                            By Status
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            title='By Aging'
                                            key={1}
                                            className={({ isActive }) =>
                                                isActive ? 'active' : 'inactive'
                                            }
                                            eventKey='link-2'
                                            onClick={() => {
                                                handleStatisticsOnChange(
                                                    'by_aging'
                                                );
                                            }}
                                        >
                                            By Aging
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>
                </div>

                {currentStatistics === 'by_status' ? (
                    <>
                        <div className='statastic_outbox st-outbox d-flex mb-5'>
                            {byStatus()}
                        </div>
                        <div className='status_outbox mb-5'>
                            {byStatusBottomBar()}
                        </div>
                    </>
                ) : null}

                {currentStatistics === 'by_aging' ? (
                    <div>
                        <div className='statastic_outbox st-outbox d-flex mb-5'>
                            {byAgeing()}
                        </div>
                        <div className='status_outbox mb-5'>
                            {byAgeingBottomBar()}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
}

export { Statistics };
