import React, { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import {
    NoRecordFound,
    createTagElement,
} from '../../../components/commonfunction';
import { Button } from 'react-bootstrap';
import AddTag from './AddTag';
import DeleteTag from './DeleteTag';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getTags } from '../../api/api';
import Spinner from '../../../modules/Auth/pages/Spinner';
import { getDDLlist } from '../../_redux/actions/configActions';

const TagsManage = () => {
    const [model, setModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [rows, setRows] = useState([]);
    const [deletedRow, setDeletedRow] = useState([]);
    const [colorCode, setColorCode] = useState('#7F96A5');
    const [tags, setTags] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tagArray, setTagArray] = useState([]);
    const dispatch = useDispatch();

    const configReducerPersist = useSelector(
        (state) => state.configReducerPersist
    );
    const config = configReducerPersist.configData;
    let defaultHeight = window.innerHeight - 280;

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        setLoading(true);
        try {
            const response = await getTags();
            if (response.ok) {
                const data = await response.json();
                const tag = data.map((item) => item.name);
                setTags(data.map((item) => item.name));
                setTagArray(data);
                dispatch(
                    getDDLlist({
                        tags: tag,
                    })
                );
                const stringifiedObj = JSON.stringify(data);
                localStorage.setItem('tagList', stringifiedObj);
            } else {
                console.error('An error has occurred');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setCount(tagArray.length || 0);
        const newRows = tagArray?.map((data) => {
            const { tagElement } = createTagElement(
                data.name,
                config,
                false,
                '',
                tags,
                data.color
            );
            return { tag: tagElement };
        });
        setRows(newRows || []);
    }, [tagArray, tags]);

    const handleCloseAddTag = () => {
        setModel(false);
    };

    const handleCloseDeleteTag = () => {
        setDeleteModel(false);
    };

    const handleAddTag = () => {
        setModel(true);
    };

    const handleButtonClick = (row) => {
        setDeleteModel(true);
        setDeletedRow(row);
    };

    const columns = [
        { key: 'tag', name: '', width: 510 },
        {
            key: 'action',
            name: '',
            formatter: ({ row }) => (
                <Button
                    variant='outline-danger'
                    onClick={() => handleButtonClick(row)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <>
            <div
                className='right-aside admin_tag-page'
                style={
                    isMobile
                        ? { height: window.innerHeight - 112 }
                        : { height: window.innerHeight - 160 }
                }
            >
                <div
                    className='header-top pl-7 pr-3 mb-5'
                    style={{ maxWidth: '620px' }}
                >
                    <h3 className='m-0 title-color'>All Tags ({count})</h3>
                    <Button
                        variant='success'
                        onClick={() => handleAddTag()}
                        title='Add Tag'
                    >
                        <i className='fa-regular fa-plus'></i>Add New Tag
                    </Button>
                </div>
                <div className='manageTag-dataGrid'>
                    {loading ? (
                        <div
                            className='d-flex align-items-center justify-content-center'
                            style={{ height: '300px' }}
                        >
                            <Spinner show />
                            <span
                                className='text-muted'
                                style={{ fontSize: '16px', marginLeft: '24px' }}
                            >
                                Loading...
                            </span>
                        </div>
                    ) : (
                        <DataGrid
                            columns={columns}
                            rows={rows}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                                width: '1fr',
                                frozen: false,
                            }}
                            rowHeight={65}
                            emptyRowsRenderer={NoRecordFound}
                            className='fill-grid'
                            headerRowHeight={0}
                            style={{
                                height: defaultHeight,
                                maxWidth: '620px',
                                width: '100%',
                            }}
                        />
                    )}
                </div>
            </div>
            <div>
                {model && (
                    <AddTag
                        model={model}
                        setModel={setModel}
                        onhide={handleCloseAddTag}
                        colorCode={colorCode}
                        setColorCode={setColorCode}
                        tags={tags}
                        setTag={setTags}
                        tagArray={tagArray}
                        setTagArray={setTagArray}
                        fetchTags={fetchTags}
                    />
                )}
                {deleteModel && (
                    <DeleteTag
                        deleteModel={deleteModel}
                        setDeleteModel={setDeleteModel}
                        onhide={handleCloseDeleteTag}
                        deletedRow={deletedRow}
                        rows={rows}
                        setRows={setRows}
                        tagArray={tagArray}
                        setTagArray={setTagArray}
                        setTag={setTags}
                    />
                )}
            </div>
        </>
    );
};

export default TagsManage;
