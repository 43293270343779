/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// eslint-disable-next-line no-unused-vars
import { withStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { inboxPage } from '../_redux/actions/inboxActions';
import { reportColorCodeArray } from '../../components/commonfunction';

// eslint-disable-next-line no-unused-vars
const filter = createFilterOptions();
const GroupAutocomplete = (props) => {
    const [groupArray, setGroupArray] = useState(
        props.newUser === true ? [] : props.groupArray
    );
    const [refreshLoop, setRefreshLoop] = useState(true);
    const [resetAutocomplete, setResetAutocomplete] = useState(false);
    const [optionGroup, setOptionGroup] = useState(props.bigArrayGroup);
    const [colorCode] = useState(reportColorCodeArray);
    const [, setValue] = useState('');
    const [heightScroll, setHeightScroll] = useState(
        props.heightScroll !== undefined ? props.heightScroll : 0
    );
    const dispatch = useDispatch();

    // eslint-disable-next-line no-unused-vars
    const useStyles = (theme) => ({
        root: {
            /// width: 200,
            margin: 0,
            '& .MuiOutlinedInput-input': {
                color: '#00233A',
                padding: '0 !important',
            },
            '& .MuiInputLabel-root': {
                color: '#00233A',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E4E6EF',
            },
            '&:hover .MuiOutlinedInput-input': {
                color: '#00233A',
            },
            '&:hover .MuiInputLabel-root': {
                color: '#00233A',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E4E6EF',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
                color: '#00233A',
            },
            '& .MuiInputLabel-root.Mui-focused': {
                color: '#00233A',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                    borderColor: '#E4E6EF',
                    borderWidth: '1px',
                },
        },
        zIndex: {
            zIndex: theme.zIndex.modal + 200,
        },
    });

    useEffect(() => {
        if (heightScroll > 250) {
            setHeightScroll(250);
        }
        let arrayData = props.bigArrayGroup;
        if (groupArray.length !== 0) {
            arrayData = arrayData.filter(
                (val) => !groupArray.includes(val.name)
            );
            setOptionGroup(arrayData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heightScroll, groupArray, props.bigArrayGroup]);

    // const getRandomColorCodeArray = () => {
    //     const coun = groupArray.length;
    //     const originalColorCount = colorCode.length;
    //     const totalColorCount = Math.floor(originalColorCount / coun);
    //     const randomcolors = getRandomColors(colorCode, totalColorCount);
    //     return randomcolors;
    // };
    const selectedGroup = async (data) => {
        // setValue(data);
        props.updateState({
            editGroup: true,
        });
        setResetAutocomplete(true);

        const selectedGrp = data;
        const grpArray = [];
        if (groupArray.length > 0) {
            await groupArray.map((item) => {
                if (item !== '') {
                    grpArray.push(item);
                }
            });
        }

        grpArray.push(selectedGrp);

        setGroupArray(grpArray);
        if (grpArray.length >= 1) {
            const height = 50 * grpArray.length;
            setHeightScroll(height);
        }
        dispatch(
            inboxPage({
                updateGroupArray: grpArray,
            })
        );
        setRefreshLoop(false);
        setTimeout(() => {
            setResetAutocomplete(false);
        }, 500);
        setTimeout(() => {
            setRefreshLoop(true);
            // setValue('');
        }, 500);
    };
    function removeGroup(data) {
        props.updateState({
            editGroup: true,
        });
        const grpArray = [];
        groupArray.map((item) => {
            if (JSON.stringify(item) !== JSON.stringify(data)) {
                grpArray.push(item);
            }
        });

        setGroupArray(grpArray);
        props.updateState({
            updateGroupArray: grpArray,
        });
        dispatch(
            inboxPage({
                updateGroupArray: grpArray,
            })
        );
        setRefreshLoop(false);
        if (grpArray.length > 1) {
            const height = 50 * grpArray.length;
            setHeightScroll(height);
        }
        setTimeout(() => {
            setRefreshLoop(true);
        }, 500);
    }
    const loopingstructure = () => {
        const loopData = [];

        groupArray.map((data, idx) => {
            // var value = data;
            if (data !== '') {
                loopData.push(
                    <div
                        className='statastic_outbox st-outbox group-edit px-5'
                        key={idx}
                    >
                        <div className='common_block'>
                            <span
                                className='bd-color'
                                style={{
                                    background: colorCode[idx],
                                }}
                            />
                            <h6>{data}</h6>
                            <i
                                title='Close'
                                className='fa-light fa-xmark pr-4'
                                aria-hidden='true'
                                onClick={() => {
                                    removeGroup(data);
                                }}
                            />
                        </div>
                    </div>
                );
            }
        });
        const reverseArray = loopData.reverse();
        return reverseArray;
    };
    const handleRenderOption = (option, { inputValue }) => {
        const matches = match(option, inputValue);
        const parts = parse(option, matches);

        const highlightStyle = {
            fontWeight: 600,
            color: '#007DBC',
            /* padding: "5px 2px" */
        };

        return (
            <div>
                {parts.map((part, index) => (
                    <span
                        key={index}
                        style={part.highlight ? highlightStyle : {}}
                    >
                        {part.text}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <>
            <div className='form-group row '>
                <div
                    className={
                        props.newUser === true
                            ? 'col-lg-12 transition-select'
                            : 'col-lg-12 mt-5 transition-select'
                    }
                >
                    {resetAutocomplete === false /* &&
                        Object.keys(optionGroup).length !== 0 */ && (
                        <>
                            <label className='pl-5'>SEARCH GROUP</label>
                            <Autocomplete
                                freeSolo
                                id='free-solo-2-demo'
                                // className='form-control p-0'
                                className={
                                    props.validationError === true &&
                                    groupArray.length === 0
                                        ? 'form-control error-field'
                                        : 'form-control'
                                }
                                disableClearable
                                // selectOnFocus
                                // clearOnBlur
                                options={
                                    Object.keys(optionGroup).length !== 0
                                        ? optionGroup.map(
                                              (option) => option.name
                                          )
                                        : null
                                }
                                // getOptionLabel={option => option.name}
                                onChange={
                                    Object.keys(optionGroup).length !== 0
                                        ? (event, newValue) => {
                                              selectedGroup(newValue);
                                              setValue(newValue);
                                          }
                                        : null
                                }
                                renderInput={(params) =>
                                    Object.keys(optionGroup).length !== 0 ? (
                                        <TextField
                                            {...params}
                                            className='form-control'
                                            placeholder='search group'
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    ) : (
                                        <div className='pl-3 pt-2'>
                                            {' '}
                                            There is no group to add!
                                        </div>
                                    )
                                }
                                renderOption={handleRenderOption} // highlighter
                            />
                        </>
                    )}
                </div>
            </div>

            {refreshLoop === false && (
                <div className='d-flex justify-content-center'>
                    <div className='spinner-border text-primary' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            )}
            {refreshLoop === true && (
                <div
                    className='contain-group-auto'
                    // style={{ height: `${heightScroll}px` }}
                >
                    <Scrollbars
                        autoHeight
                        // autoHide
                        autoHeightMin={0}
                        autoHeightMax={150}
                    >
                        {loopingstructure()}
                    </Scrollbars>
                </div>
            )}
        </>
    );
};
export default GroupAutocomplete;
