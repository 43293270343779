import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteTagApi } from '../../api/api';
import { showSuccessToast } from '../../../components/commonfunction';
import { useDispatch } from 'react-redux';
import { getDDLlist } from '../../_redux/actions/configActions';

const DeleteTag = ({
    deleteModel,
    setDeleteModel,
    deletedRow,
    rows,
    setRows,
    tagArray,
    setTagArray,
    setTag,
}) => {
    const [deleteTagName, setDeleteTagName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            deletedRow &&
            deletedRow.tag &&
            deletedRow.tag.props.children.props.title
        ) {
            setDeleteTagName(deletedRow.tag.props.children.props.title);
        }
    }, [deletedRow]);

    const handleClose = () => setDeleteModel(false);

    const setDataGridRows = () => {
        if (!deleteTagName) return;

        const updatedRows = rows.filter(
            (row) => row.tag.props.children.props.title !== deleteTagName
        );
        const updatedArrayOfTags = tagArray.filter(
            (data) => data.name !== deleteTagName
        );
        const updatedTags = updatedArrayOfTags.map((item) => item.name);

        setTagArray(updatedArrayOfTags);
        setRows(updatedRows);
        setTag(updatedTags);

        dispatch(getDDLlist({ tags: updatedTags }));
        localStorage.setItem('tagList', JSON.stringify(updatedArrayOfTags));
    };

    const deleteTagApiCall = async (deleteTagId) => {
        try {
            setIsLoading(true);
            const response = await deleteTagApi(deleteTagId);
            if (response.ok) {
                setDataGridRows();
                showSuccessToast('Tag Deleted Successfully');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const deleteTag = () => {
        let deleteTag = tagArray.find((row) => row.name === deleteTagName);
        const { tagId } = deleteTag;
        deleteTagApiCall(tagId);
    };

    return (
        <div>
            <Modal
                className='delete-popup'
                show={deleteModel}
                onHide={handleClose}
                animation={false}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>Delete a Tag</div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={() => handleClose()}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-dark mb-0'>
                        You are deleting the tag {deleteTagName}. Are you sure
                        you want to proceed?
                    </p>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                title='Cancel'
                                variant='secondary'
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                title='Delete'
                                className='danger'
                                onClick={() => deleteTag()}
                                disabled={isLoading ? true : false}
                            >
                                {isLoading ? (
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                ) : (
                                    <span>Delete</span>
                                )}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DeleteTag;
