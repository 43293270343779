import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    GET_INBOX_LIST,
    INBOX_LIST_SUCCESS,
    INBOX_LIST_FAILURE,
    SET_SELECTED_ROW,
    GET_SHARED_PROPS,
    DEFAULT_INBOX_STATE,
    GET_RULES_JSON,
    SET_ROW_DATA,
    GET_COUNT_DATA,
    SHOW_LOADER,
    RE_RENDER_INBOX,
    HIDE_BIG_VIEW,
    SET_MENU_NAME,
    SET_PAGE_DATA,
    GET_OWNERS,
    GET_OWNERS_SUCCESS,
    GET_OWNERS_FAILURE,
    SET_PROCESS_ID,
    SET_STATUS_ID,
    SET_CALLING_PAGE,
    SET_OWNER,
    SET_DOC_OWNER,
    UPDATE_INBOX_LIST,
    UPDATE_INBOX_STATE,
    SELECTED_ROW_DETAILS,
    MULTIPLE_EDIT_BASE64,
    EDIT_DETAIL_PAGE,
    INBOX_PAGE,
    CLEAR_INBOX_STATE,
    INBOX_GROUPING,
    INITIAL_INBOX_GROUPING,
    // CREATE_PATIENT,
} from '../actionTypes/inboxTypes';

const initialState = {
    data: undefined,
    loading: true,
    rowListData: [],
    rowData: [],
    count: undefined,
    owners: [],
    processId: '',
    statusId: '',
    owner: '',
    docOwner: [],
    callingPage: '',
    dataToken: Math.random(),
    stateToken: Math.random(),
    stateData: {},
    selectedRowDetails: { rulesJson: {} },
    editDetailPage: { patient_filter: {} },
    inboxPage: {
        single_edit: false,
        related_doc_detail: false,
        related_doc_from: '',
        currentBranch: '',
    },
    grouping: {},
};

export const inboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEFAULT_INBOX_STATE:
            return {
                ...state,
            };
        // INBOX LIST
        case GET_INBOX_LIST:
            return { ...state, loading: true };
        case INBOX_LIST_SUCCESS:
            return {
                ...state,
                inbox: action.data,
                loading: false,
                dataToken: Math.random(),
            };
        case INBOX_LIST_FAILURE:
            return { ...state, loading: false };

        // SELECTED ROW
        case SET_SELECTED_ROW:
            return { ...state, selectedRow: action.rowIdx };
        case GET_SHARED_PROPS:
            return { ...state };

        // To get count
        // case GET_COUNT_DATA:
        // 	return { ...state ,count:action.count, loading: false }
        case SET_ROW_DATA:
            return { ...state, rowData: action.rowData };

        // OWNERS LIST FOR AUTOCOMPLETE
        case GET_OWNERS:
            return { ...state, owners: [] };
        case GET_OWNERS_SUCCESS:
            return { ...state, owners: action.data };
        case GET_OWNERS_FAILURE:
            return { ...state, error: action.error };

        // TO SET PROCESS AND STATUS ID
        case SET_PROCESS_ID:
            return { ...state, processId: action.processId };
        case SET_STATUS_ID:
            return { ...state, statusId: action.statusId };
        case SET_CALLING_PAGE:
            return { ...state, callingPage: action.callingPage };
        // TO SET OWNER VALUE
        case SET_OWNER:
            return { ...state, owner: action.owner };
        case SET_DOC_OWNER:
            return { ...state, docOwner: action.docOwner };

        // TO UPDATE INBOX DATA
        case UPDATE_INBOX_LIST:
            return { ...state, inbox: action.data };

        // TO CLEAR OUTBOX DATA
        case CLEAR_INBOX_STATE:
            var update_inbox_state = {
                ...state.stateData,
                ...action.data,
                currentMenu: '',
            };

            var new_inbox_state = { stateData: update_inbox_state };

            return {
                ...state,
                ...new_inbox_state,
                stateToken: Math.random(),
                stateData: action.data, //small view will display incorrectly
            };

        case UPDATE_INBOX_STATE:
            var update_inbox_state = {
                ...state.stateData,
                ...action.data,
                currentMenu: '',
            };

            return {
                ...state,
                stateData: update_inbox_state,
                stateToken: Math.random(),
            };

        case SHOW_LOADER:
            return { ...state, loading: action.loader };

        case SELECTED_ROW_DETAILS:
            return { ...state, selectedRowDetails: action.data };

        case MULTIPLE_EDIT_BASE64:
            return { ...state, multipleEditBase64: action.data };

        case EDIT_DETAIL_PAGE:
            var old_state = {
                ...state.editDetailPage,
                ...action.data,
            };
            var new_state = { editDetailPage: old_state };
            return { ...state, ...new_state };

        case INBOX_PAGE:
            // eslint-disable-next-line no-redeclare
            var old_state = {
                ...state.inboxPage,
                ...action.data,
            };
            // eslint-disable-next-line no-redeclare
            var new_state = { inboxPage: old_state };
            return { ...state, ...new_state };

        case INITIAL_INBOX_GROUPING:
            return {
                ...state,
                grouping: JSON.parse(
                    localStorage.getItem('grouping_inbox') || '{}'
                ),
            };
        case INBOX_GROUPING:
            if (!action.data.data) {
                let newGroup = { ...state.grouping };
                delete newGroup[action.data.pathname];

                localStorage.setItem(
                    'grouping_inbox',
                    JSON.stringify(newGroup)
                );
                return { ...state, grouping: newGroup };
            }
            let data = { [action.data.pathname]: { ...action.data } };
            delete data[action.data.pathname].pathname;
            let newGroup = {
                ...state.grouping,
                ...data,
            };
            localStorage.setItem('grouping_inbox', JSON.stringify(newGroup));
            return { ...state, grouping: newGroup };

        default:
            return state;
    }
};

const initialAuthState = {
    user: undefined,
    count: [],
    rulesJson: {},
    loader: false,
    rerenderInbox: false,
    currentMenuName: null,
    pageData: {},
};

export const pReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        // RULES JSON
        case GET_COUNT_DATA: {
            const { count } = action;
            return { ...state, count };
        }
        case GET_RULES_JSON:
            return { ...state, rulesJson: action.rulesJson, loading: true };
        case RE_RENDER_INBOX:
            return {
                ...state,
                rerenderInbox: action.rerenderInbox,
                loading: true,
            };
        case HIDE_BIG_VIEW:
            return {
                ...state,
                hidebigview: action.hidebigview,
                loading: true,
            };
        case SET_MENU_NAME:
            return {
                ...state,
                currentMenuName: action.currentMenuName,
                loading: false,
            };
        case SET_PAGE_DATA:
            let { pageData } = state;
            pageData = { ...pageData, ...action.data };
            return {
                ...state,
                pageData,
            };
        default:
            return state;
    }
};

// const initialCountState = {
//     count: undefined,
// };

// export const countPersistor = persistReducer(
// 	{ storage, key: "RowDetail", whitelist: ["count"] },
// 	(state = initialCountState, action) => {
// 		switch (action.type) {
// 			//MENU COUNT
// 			case GET_COU: {
// 				const { count } = action;
// 				return { count, user: "Abu" };
// 			}

// 			default:
// 				return state;
// 		}
// 	}
// );
