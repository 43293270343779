/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { withRouter, useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
//import SVG from "react-inlinesvg";
import {
    getConfig,
    GetCounter,
    checkIsActive,
    constructMenu,
    toAbsoluteUrl,
    getdefaultLinkFromMenu,
} from '../../../../_helpers';
import { formatNameWithSpaceTohipen } from '../../../../../app/components/commonfunction';
import { useSelector, useDispatch } from 'react-redux';
import { deletedPage } from '../../../../../app/pages/_redux/actions/searchActions';
import { getConfigData } from '../../../../../app/pages/_redux/actions/configActions';

export function CommonMenu() {
    const location = useLocation();
    const CounterData = GetCounter();
    const configData = getConfig();    
    const menuItems = configData.length > 0 ? configData[0].menuItems : [];
    let dispatch = useDispatch();

    React.useEffect(() => {
             
    }, []);
    const current_path = location.pathname;
    const path_arr = current_path.split('/');
    var current_menu = path_arr[1].toLowerCase(); 
    const getMenuItemActive = (url, hasSubmenu = false) => {
       
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
            : '';
    };
    const getMenuItemDisplay = (itemcat) => {
    
        var display = 'block';
        var exists = 0;
       
        {
            configData[0].menuItems.map((data,idx)=>{
                

                if(data.menuName.toLowerCase() === current_menu) {  
                    data.processes.map((item, index)=> { 
                        //E-Sign Distribution menu item
                            if(formatNameWithSpaceTohipen(item.processName).toLowerCase() === itemcat){
                                exists++; 
                            }
                    })
                }
            })
          
            if (exists === 0) {
                display = 'none';
            } else {
                display = 'block';
            }
            return display;
        } 
    };

    return (
        <div
            id='kt_aside'
            className='aside aside-left rounded-lg   aside-fixed d-flex flex-column flex-row-auto'
            key={'aside-main-report'}
            /* style={{ height: window.innerHeight - 160 }}*/
        >
            <div
                id='kt_aside_menu_wrapper'
                className='aside-menu-wrapper flex-column-fluid'
            >
                <Scrollbars
                    id='kt_aside_menu'
                    data-menu-vertical='1'
                    className='aside-menu '
                >
                  
                    {menuItems && menuItems.length > 0
                        ? menuItems.map((data, key) => {
                                if (
                                    data.menuName.toLowerCase() === current_menu.toLowerCase()
                                ) {
                                    let catee = 0;
                                    let processId ;
                                    return (
                                        <React.Fragment key={key}>
                                          

                                            {data.processes.length > 0 ? (
                                                <ul
                                                    className='menu-nav p-0'
                                                    key={'aside-main-' + key}
                                                >
                                                  {data.processes.map((itemcat, keyidx) => {

                                                        const path_arr = current_path.split('/');
                                                      
                                                        /* if(path_arr.length>=3){
                                                            String(path_arr[3]) === String(itemcat.processId)
                                                        } */
                                                        processId = itemcat.processId;
                                                        let statusId = itemcat.statusIds[0]
                                                        let statusName = ''
                                                        itemcat.statuses.map((lit, ix)=>{
                                                            if(lit .statusId === statusId){
                                                                statusName = lit.name
                                                            }
                                                        })
                                                        var process_steps = 'p_'+processId;//+'_s_'+val.statusId;
									                    let  count = '';
									                    if(CounterData[process_steps]){
										                 count = CounterData[process_steps];
									                    }
                                                          catee++;
                                                          return (
                                                                <React.Fragment
                                                                    key={'aside-frag-' + formatNameWithSpaceTohipen(itemcat.processName)}
                                                                >
                                                                    <li
                                                                      
                                                                        style={{
                                                                            display: `${getMenuItemDisplay(
                                                                                formatNameWithSpaceTohipen(itemcat.processName)
                                                                            )}`,
                                                                        }}
                                                                       
                                                                        className = 'menu-item menu-item-submenu menu-item-active menu-item-open'
                                                                        
                                                                        data-menu-toggle='hover'
                                                                        key={
                                                                            keyidx
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={'brand'}
                                                                            title={itemcat.processName}
                                                                            id={
                                                                                'menu_search_' +
                                                                                
                                                                                formatNameWithSpaceTohipen(statusName)
                                                                               
                                                                            }
                                                                           /*  to={`/${current_menu}/${
                                                                                formatNameWithSpaceTohipen(statusName)
                                                                                }/${processId}/${statusId}`} */

                                                                         
                                                                        >
                                                                            <h5 className="text-dark" style={{display:"block"}} > {itemcat.processName}</h5>
                                                                            {count != '' ?
                                                                                (<b className="count mt-0 ml-2">{count}</b>)
                                                                                    :''
                                                                            }	
                                                                          
                                                                           
                                                                        </div>
                                                                        <div
                                                                            className=''
                                                                            key={
                                                                                'item_' +
                                                                                formatNameWithSpaceTohipen(itemcat.processName)
                                                                            }
                                                                        >
                                                                            {/* <i className='menu-arrow ' /> */}
                                                                            <ul className='menu-subnav'>
                                                                                {/* <li
                                                                                    className='menu-item  menu-item-parent'
                                                                                    key={itemcat.processName}
                                                                                  // aria-haspopup='true'
                                                                                >
                                                                                    <span className='menu-link'>
                                                                                        <span className='menu-text'>
                                                                                            {
                                                                                              itemcat.processName
                                                                                            }
                                                                                        </span>
                                                                                    </span>
                                                                                </li> */}
                                                                                         
                                                                                  {itemcat.statuses.length === 0 &&
                                                                                    
                                                                                    <div className='no-record-menu'>
                                                                                        No Results Found
                                                                                    </div>
                                                                                  }
                                                                                {itemcat.statuses.sort((a, b) => a.statusId - b.statusId)
                                                                                    .map((item,index) => {
                                                                                       
                                                                                    let subMenuProcessId = itemcat.processId
                                                                                    let subMenuStautsId= item.statusId
                                                                                    let statusName = formatNameWithSpaceTohipen(item.name)
																					
																					 let  status_steps =process_steps + '_s_' +subMenuStautsId; 
                                                                                     let count_submenu = ''
                                                                                     if(CounterData[status_steps]){
                                                                                        count_submenu = CounterData[status_steps]
                                                                                     }
                                                                                     let countstyle = {}
                                                                                     let hex 
                                                                                     /* let red
                                                                                     let green 
                                                                                     let blue */
                                                                                     if(item.color !== '' && item.color){
                                                                                        hex = item.color
                                                                                        var red = parseInt(hex[1]+hex[2], 16)
                                                                                        var green = parseInt(hex[3]+hex[4], 16)
                                                                                        var blue = parseInt(hex[5]+hex[6], 16)
                                                                                        countstyle = {
                                                                                            color: item.color,
                                                                                            backgroundColor:
                                                                                                'rgb(' +
                                                                                                red +
                                                                                                ', ' +
                                                                                                green +
                                                                                                ', ' +
                                                                                                blue +
                                                                                                ',.25)',
                                                                                            /*opacity: 0.2,*/
                                                                                        }
                                                                                     }
                                                                                     let status_icon_font = item.icon
                                                                                     
                                                                                     if(status_icon_font === null){
                                                                                        status_icon_font = 'fas fa-envelope-open-text'
                                                                                     }
                                                                                     let status_icon_color = item.color
																					{return (
                                                                                        <React.Fragment
                                                                                            key={
                                                                                                'aside-frag-' +
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            <li
                                                                                               //className='menu-item '
                                                                                               className = {path_arr.length >= 3 &&  String(path_arr[4]) === String(subMenuStautsId) ?
                                                                                                'menu-item active' :
                                                                                                'menu-item'
                                                                                            } 
                                                                                                //aria-haspopup='true'
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            > 
                                                                                                <NavLink
                                                                                                    title = {item.name}
                                                                                                    className={`menu-link ${getMenuItemActive(
                                                                                                              '/'+ current_menu +'/'+ statusName + '/'+subMenuProcessId +'/'+subMenuStautsId, true
                                                                                                                  
                                                                                                          )}`}
                                                                                                          to={
                                                                                                              '/' +
                                                                                                              current_menu +
                                                                                                              '/' +
                                                                                                             
                                                                                                              statusName
                                                                                                               +
                                                                                                              '/' + 
                                                                                                              subMenuProcessId +
                                                                                                              '/' +
                                                                                                              subMenuStautsId
                                                                                                          }
                                                                                                          
                                                                                                      >

                                                                                                            <i
                                                                                                                style={{
                                                                                                                    color: item.color? item.color:'',
                                                                                                                }}
                                                                                                                className={`fas ${status_icon_font} d-flex align-items-center `}
                                                                                                            />
                                                                                                            <span data-nav={true} data-menu={item.name.toLowerCase().trim().replaceAll(" ", '-')} className='menu-text'>
																											  {
																												  item.name
																											  }
																									        </span>
                                                                                                            {count_submenu !== '' ? 
                                                                                                                <b 
                                                                                                                    style = {countstyle}
                                                                                                                    className = 'count'
                                                                                                                >
                                                                                                                    {count_submenu}
                                                                                                                </b>
                                                                                                            : null}
                                                                                                          
                                                                                                      </NavLink>
                                                                                                  </li>
                                                                                              </React.Fragment>
                                                                                          );
                                                                                      }
                                                                                  }
                                                                              )}
                                                                              
                                                                          </ul>
                                                                      </div>
                                                                  </li>
                                                              </React.Fragment>
                                                          );
                                                      }
                                                  )}
                                              </ul>
                                          ) : (
                                              <div className='no-record-menu'>
                                                  No Results Found
                                              </div>
                                          )}
                                      </React.Fragment>
                                  );
                              }
                          })
                        : null}
                </Scrollbars>
            </div>
        </div>
    );
}


