import React from 'react';
import {
    // Container,
    // Row,
    // Col,
    Button,
    // Dropdown,
    Modal,
    // Toast,
    // OverlayTrigger,
    // Tooltip,
    // Overlay,
} from 'react-bootstrap';
// import Autocomplete from 'react-autocomplete';

function DeleteModal(props) {
    const handlePatientDeleteOperation = () => {
        props.handlePatientDeleteOperation();
    };

    const handleCloseOperation = () => {
        props.handleCloseOperation();
    };

    return (
        <>
            <Modal
                show={props.openDelete}
                onHide={handleCloseOperation}
                animation={false}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>
                            <i
                                title='Delete'
                                className='fas fa-trash-alt mr-3'
                            />
                            Delete Patient
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleCloseOperation}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='deletes'>
                    <h6>
                        {props.deleteText
                            ? props.deleteText
                            : 'You are about to delete this patient. Are you sure you want to do this?'}
                    </h6>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                variant='secondary'
                                onClick={handleCloseOperation}
                                title='Cancel'
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                className='danger'
                                onClick={handlePatientDeleteOperation}
                                title='Yes, Delete'
                            >
                                Yes, Delete
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DeleteModal;
