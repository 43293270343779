import React from 'react';
import SortableHeaderCell from '../datagrid/SortableHeaderCell';

export function DocumentHistroyHeader({
    column,
    currentTab,
    documentHistory,
    workflowQueues,
    workflowTransactions,
    sortRows,
}) {
    let sortDirection = 'ASC';
    let sortColumn = '';
    currentTab = currentTab.trim();
    if (currentTab === 'document-histroy') {
        sortDirection = documentHistory.sortDirection;
        sortColumn = documentHistory.sortColumn;
    } else if (currentTab === 'workflow-queues') {
        sortDirection = workflowQueues.sortDirection;
        sortColumn = workflowQueues.sortColumn;
    } else {
        sortDirection = workflowTransactions.sortDirection;
        sortColumn = workflowTransactions.sortColumn;
    }
    return (
        <>
            <SortableHeaderCell
                currentHeaderName={column.key}
                sortDirection={sortDirection}
                sortColumn={sortColumn}
                onSort={sortRows}
            >
                <span style={{ cursor: 'pointer' }}>{column.name}</span>
            </SortableHeaderCell>
        </>
    );
}
