import React, { useEffect, useState, memo } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
// import { printPlugin } from '@react-pdf-viewer/print';
import { isMobile } from 'react-device-detect';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { toast } from 'react-toastify';
// import { searchPlugin } from '@react-pdf-viewer/search';
import { SpinnerBlur } from '../Spinner';
import { formatPathName } from '../commonfunction';
import { unlockDocument, downloadDoc } from '../../pages/api/api';

toast.configure();
//let customZoom = 1;
let customZoomValue = SpecialZoomLevel.PageFit;
if (isMobile) {
    customZoomValue = SpecialZoomLevel.PageFit;
}
let refreshPDF = false;
// const toolbarPluginInstance = toolbarPlugin();
// const { Toolbar } = toolbarPluginInstance;

const PdfPreview = (props) => {
    refreshPDF = false;

    // Create new plugin instance

    // const searchPluginInstance = searchPlugin();

    // const printPluginInstance = printPlugin();
    const [base64, setBaseData] = useState(null);
    const [apiError, setApiError] = useState(false);
    const [blurSpinner, setBlurSpinner] = useState(false);
    const [currentPage, setCurrentPage] = useState('');
    const [customZoom, setCustomZoom] = useState('');
    // const [refreshPDF, setRefreshPDF] = useState(false);
    const location = useLocation();
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    useEffect(() => {
        setBaseData(null);
        let documentPromise;
        let pathname = location.pathname;
        const currentPage = pathname.split('/');
        if (currentPage.length > 0) {
            if (currentPage[2] === 'detail') {
                setCurrentPage(currentPage[2]);
            }
        }
        setCustomZoom(customZoomValue);
        if (props.docId !== undefined) {
            pathname = formatPathName(pathname);
            localStorage.setItem(`locked-document-id-${pathname}`, props.docId);
            const docId = props.docId;
            documentPromise = downloadDoc(docId);
        }
        if (props.docId === undefined) {
            const pathname = location.pathname.split('/');

            if (pathname[1] === 'detail') {
                documentPromise = downloadDoc(pathname[2]);
            }
        }

        if (documentPromise !== undefined) {
            documentPromise
                .then((response) => {
                    if (response.status === 400) {
                        setApiError(true);
                        toast.error('No pdf to load', {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            showTransitionSuccess: false,
                        });
                        return new Error('error');
                    }
                    if (response.status === 204) {
                        setApiError(false);
                    }
                    return response.blob();
                })
                .then((data) => {
                    const url = URL.createObjectURL(data);
                    setBaseData(url);
                    // setRefreshPDF(true)
                    setBlurSpinner(false);
                })
                .catch((error) => {});
        }

        return () => {
            if (props.docId !== undefined) {
                const docId = props.docId;
                unlockDocument(docId).then((data) => {});
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // }, [props.selectedDoc,location,props]);//props.selectedDoc

    // const selectCheckBox = () => {
    //     setTimeout(() => {
    //         if (
    //             document.getElementsByClassName(
    //                 'rpv-search__popover-label-checkbox'
    //             )
    //         ) {
    //             const clist = document.getElementsByClassName(
    //                 'rpv-search__popover-label-checkbox'
    //             );
    //             for (let i = 0; i < clist.length; ++i) {
    //                 clist[i].checked = true;
    //             }
    //         }
    //     }, 1000);
    // };

    // const pageNavigationPluginInstance = pageNavigationPlugin();
    // const { CurrentPageLabel } = pageNavigationPluginInstance;
    const currentBase64 = base64;
    //

    refreshPDF = true;

    return (
        <>
            {base64 !== null && refreshPDF === true ? (
                <div
                    className='rpv-core__viewer'
                    style={{
                        display: 'flex',
                    }}
                >
                    <div className='toolbar-pdfpreview'>
                        <div className='pdfpreview-tools'>
                            {/* <Toolbar /> */}
                            <Toolbar>
                                {base64 !== null
                                    ? (props) => {
                                          const {
                                              CurrentPageInput,
                                              EnterFullScreen,
                                              GoToNextPage,
                                              GoToPreviousPage,
                                              NumberOfPages,
                                              ShowSearchPopover,
                                              Zoom,
                                              ZoomIn,
                                              ZoomOut,
                                              //   Print,
                                          } = props;

                                          //   const TotalPages =
                                          //       props.NumberOfPages;
                                          return (
                                              <>
                                                  <div>
                                                      <ShowSearchPopover />
                                                  </div>

                                                  <div>
                                                      <GoToPreviousPage />
                                                  </div>
                                                  <div>
                                                      <CurrentPageInput />/
                                                      <NumberOfPages />
                                                  </div>

                                                  <div>
                                                      <GoToNextPage />
                                                  </div>

                                                  <div
                                                      style={{
                                                          marginLeft: 'auto',
                                                      }}
                                                  >
                                                      <ZoomOut />
                                                  </div>
                                                  <div>
                                                      <Zoom
                                                          levels={[
                                                              0.4, 0.8, 1.2,
                                                          ]}
                                                      />
                                                  </div>
                                                  <div>
                                                      <ZoomIn />
                                                  </div>
                                                  {/*<CurrentPageLabel>
                                                      {(
                                                          props: RenderCurrentPageLabelProps
                                                      ) => (
                                                          <span>{`${
                                                              props.currentPage +
                                                              1
                                                          } of ${
                                                              props.numberOfPages
                                                          }`}</span>
                                                      )}
                                                        </CurrentPageLabel>*/}
                                                  <div
                                                      style={{
                                                          marginLeft: 'auto',
                                                      }}
                                                  >
                                                      <EnterFullScreen />
                                                  </div>
                                              </>
                                          );
                                      }
                                    : null}
                            </Toolbar>
                        </div>

                        <div
                            //     style={props.isLocked?{
                            //         flex: 1,
                            //         height: (window.innerHeight-window.innerHeight/2.4),
                            //     }:{
                            //         flex: 1,
                            //         height: (window.innerHeight-window.innerHeight/2.5),
                            //     }
                            //   }
                            className='pdf-scroll'
                            style={{ flex: 1, height: window.innerHeight }}
                        >
                            <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js'>
                                <Viewer
                                    fileUrl={currentBase64}
                                    plugins={[
                                        toolbarPluginInstance,
                                        // printPluginInstance,
                                        // searchPluginInstance,
                                        // pageNavigationPluginInstance
                                    ]}
                                    // defaultScale={customZoom} ( removed for image resize black issue )
                                />
                            </Worker>
                        </div>
                    </div>
                </div>
            ) : apiError === false ? (
                <div
                    id={currentPage === 'detail' ? 'right-aside' : 'left-aside'}
                    className={
                        currentPage === 'detail'
                            ? 'left-size-loader'
                            : 'right-size-loader aaa'
                    }
                    // if we use below style error came
                    style={
                        currentPage === 'detail' ? { textAlign: 'center' } : {}
                    }

                    // style={{ textAlign: 'center'}}
                >
                    <Loader
                        type='Oval'
                        color='#007dbc'
                        height={40}
                        width={40}
                        // timeout={5000}
                    />
                </div>
            ) : null}
            <SpinnerBlur show={blurSpinner} />
        </>
    );
};

export default memo(PdfPreview);
// export default PdfPreview;
