import React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

import { getDDLlist } from '../_redux/actions/configActions';
import GroupAutocomplete from './GroupAutocomplete';
import { inboxPage } from '../_redux/actions/inboxActions';
import {
    normalSuccessToast,
    showErrorToast,
} from '../../components/commonfunction';
import { addUserDetails } from '../api/api';

const postLoading = `post_loading_${Math.random()}`;

class AddNewUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuName: 'users',
            show_result: true,
            userDisplayName: '',
            userEmail: '',
            userName: '',
            password: '',
            validationError: false,
            emailError: false,
            btnLoader: false,
            show_pwd2: false,
        };
    }

    handleCloseOperation = () => {
        this.updateState({
            addUserModal: false,
        });
        this.setState({
            userDisplayName: '',
            userEmail: '',
            userName: '',
            password: '',
            validationError: false,
            emailError: false,
            passwordError: false,
            usernameError: false,
            btnLoader: false,
        });
    };

    updateState = (state) => {
        this.props.updateState(state);
    };

    handleChange = (name, state) => {
        this.setState({
            [state]: name,
        });
        if (this.state.emailError === true) {
            this.setState({
                emailError: false,
            });
        }
        if (this.state.passwordError === true) {
            this.setState({
                passwordError: false,
                usernameError: false,
            });
        }
        if (this.state.usernameError === true) {
            this.setState({
                usernameError: false,
            });
        }
    };

    cancelUpdatedDetails = () => {
        const user_details = JSON.parse(localStorage.getItem('user_details'));

        this.setState({
            ...user_details,
            validationError: false,
            emailError: false,
            passwordError: false,
            usernameError: false,
        });
    };

    show_pwd2 = () => {
        this.setState({ show_pwd2: !this.state.show_pwd2 });
    };

    addNewUser = () => {
        this.setState({
            btnLoader: true,
        });

        if (
            this.state.userDisplayName === '' ||
            this.state.userEmail === '' ||
            this.state.userName === '' ||
            this.state.password === '' ||
            this.props.inbox_Page.updateGroupArray === undefined ||
            this.props.inbox_Page.updateGroupArray.length === 0
        ) {
            this.setState({
                validationError: true,
            });
            showErrorToast('Please fill out the required fields');
            this.setState({
                btnLoader: false,
            });
            return false;
        }

        // eslint-disable-next-line no-useless-escape
        const regex_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (this.state.userEmail) {
            if (!regex_email.test(this.state.userEmail)) {
                this.setState({
                    emailError: true,
                });
                showErrorToast('Please enter a valid Email address');
                this.setState({
                    btnLoader: false,
                });
                return false;
            }
        }
        const regex_username = /^[a-zA-Z0-9-]*$/;

        if (this.state.userDisplayName) {
            if (!regex_username.test(this.state.userName)) {
                this.setState({
                    usernameError: true,
                });
                showErrorToast(
                    "The field Username must match the regular expression '^[a-zA-Z0-9 -]*$'."
                );
                this.setState({
                    btnLoader: false,
                });
                return false;
            }
        }
        if (this.state.password.length < 8) {
            this.setState({
                passwordError: true,
            });
            showErrorToast('Password must be at least 8 characters long.');
            this.setState({
                btnLoader: false,
            });
            return false;
        }

        const groupArray =
            this.props.inbox_Page.updateGroupArray !== undefined
                ? this.props.inbox_Page.updateGroupArray
                : [];

        let usergroups = {};
        const dummyArray = [];
        if (groupArray.length > 0) {
            // eslint-disable-next-line array-callback-return
            groupArray.map((item) => {
                // eslint-disable-next-line array-callback-return
                this.props.bigArrayGroup.map((data) => {
                    if (JSON.stringify(data.name) === JSON.stringify(item)) {
                        dummyArray.push({
                            id: data.id,
                            name: item,
                        });
                    }
                });
            });
        }
        if (dummyArray.length > 0) {
            usergroups = dummyArray;
        } else {
            // eslint-disable-next-line no-unused-vars
            usergroups = [];
        }

        // eslint-disable-next-line no-unused-vars
        const password = this.state.password;
        const fields = {};
        fields.username = this.state.userName;
        fields.email = this.state.userEmail;
        fields.name = this.state.userDisplayName;
        fields.password = this.state.password;
        fields.usergroups = dummyArray;
        let status;

        addUserDetails(fields)
            .then((response) => {
                status = response.status;
                if (status === 204 || status === 200) {
                    this.setState({
                        userDisplayName: '',
                        userEmail: '',
                        userName: '',
                        password: '',
                        validationError: false,
                        emailError: false,
                        passwordError: false,
                        usernameError: false,
                        btnLoader: false,
                    });

                    normalSuccessToast('New User addedd successfully');
                    this.setState({
                        btnLoader: false,
                    });
                    this.updateState({
                        addUserModal: false,
                    });
                    setTimeout(() => this.props.refreshTable(), 10);
                    this.props.inboxPage({
                        updateGroupArray: undefined,
                    });
                    this.setState({
                        userDisplayName: '',
                        userEmail: '',
                        userName: '',
                        password: '',
                    });
                } else if (response !== '') {
                    return response.json();
                }
            })
            .then((data) => {
                if (status === 400 || status === 404) {
                    showErrorToast(JSON.stringify(data));
                    this.setState({
                        btnLoader: false,
                    });
                }
            });
    };

    render() {
        return (
            <>
                <Modal
                    show={this.props.addUserModal}
                    onHide={this.handleCloseOperation.bind(this, false)}
                    className='add-new-user'
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className='title'>
                                <i className='fas fa-plus' /> Add New User
                            </div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.handleCloseOperation.bind(
                                    this,
                                    false
                                )}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes add-new-user'>
                        <h6 className='info_msg p-2'>
                            Enter the new user's name, email, and the username
                            they'll user to login
                            <br />
                            We will send them an email to finish the setting up
                            their account.
                        </h6>
                        <div className=''>
                            <div className='row grid-row-group'>
                                <div className='col-md-6 col-xl-6 transition-select'>
                                    <label className='pl-5'>REAL NAME</label>
                                    <div className='position-relative'>
                                        <input
                                            placeholder=''
                                            type='text'
                                            className={
                                                this.state.validationError ===
                                                    true &&
                                                this.state.userDisplayName ===
                                                    ''
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.userDisplayName}
                                            onChange={(event) => {
                                                this.handleChange(
                                                    event.target.value,
                                                    'userDisplayName'
                                                );
                                            }}
                                        />
                                        {this.state.validationError === true &&
                                        this.state.userDisplayName === '' ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Name is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-6 col-xl-6 transition-select'>
                                    <label className='pl-5'>
                                        EMAIL ADDRESS
                                    </label>
                                    <div className='position-relative'>
                                        <input
                                            placeholder=''
                                            type='text'
                                            className={
                                                (this.state.validationError ===
                                                    true &&
                                                    this.state.userEmail ===
                                                        '') ||
                                                this.state.emailError === true
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.userEmail}
                                            onChange={(event) => {
                                                this.handleChange(
                                                    event.target.value,
                                                    'userEmail'
                                                );
                                            }}
                                        />
                                        {this.state.validationError === true &&
                                        this.state.userEmail === '' ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Email is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='row grid-row-group'>
                                <div className='col-md-6 col-xl-6 transition-select'>
                                    <label className='pl-5'>USERNAME</label>
                                    <div className='position-relative'>
                                        <input
                                            placeholder=''
                                            type='text'
                                            className={
                                                (this.state.validationError ===
                                                    true &&
                                                    this.state.userName ===
                                                        '') ||
                                                this.state.usernameError ===
                                                    true
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.userName}
                                            onChange={(event) => {
                                                this.handleChange(
                                                    event.target.value,
                                                    'userName'
                                                );
                                            }}
                                        />
                                        {this.state.validationError === true &&
                                        this.state.userName === '' ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                UserName is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-6 col-xl-6 transition-select'>
                                    <label className='pl-5'>PASSWORD</label>
                                    <div className='position-relative input-icon input-icon-right'>
                                        <input
                                            placeholder=''
                                            type={
                                                this.state.show_pwd2
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            className={
                                                (this.state.validationError ===
                                                    true &&
                                                    this.state.userName ===
                                                        '') ||
                                                this.state.passwordError ===
                                                    true
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.password}
                                            onChange={(event) => {
                                                this.handleChange(
                                                    event.target.value,
                                                    'password'
                                                );
                                            }}
                                        />
                                        <span
                                            className='pr-5'
                                            onClick={this.show_pwd2}
                                        >
                                            {this.state.show_pwd2 ? (
                                                <i className='icon-md fas fa-eye-slash' />
                                            ) : (
                                                <i className='icon-md fas fa-eye' />
                                            )}
                                        </span>
                                        {this.state.validationError === true &&
                                        this.state.password === '' ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Password is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <GroupAutocomplete
                                bigArrayGroup={this.props.bigArrayGroup}
                                groupArray={this.props.groupArray}
                                updateGroupArray={this.props.updateGroupArray}
                                updateState={() => this.props.updateState()}
                                newUser
                                validationError={this.state.validationError}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    variant='secondary'
                                    onClick={this.handleCloseOperation.bind(
                                        this,
                                        false
                                    )}
                                    title='Cancel'
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                {this.state.btnLoader === true ? (
                                    <Button
                                        type='button'
                                        variant='success'
                                        className='post_loading'
                                        id={postLoading}
                                    >
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        variant='success'
                                        onClick={() => {
                                            this.addNewUser();
                                        }}
                                        title='Add New User'
                                        disabled={
                                            this.props.bigArrayGroup ===
                                                undefined ||
                                            this.props.bigArrayGroup === null ||
                                            this.props.bigArrayGroup === '' ||
                                            Object.keys(
                                                this.props.bigArrayGroup
                                            ).length === 0
                                        }
                                    >
                                        Add New User
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
    inboxPage,
};
const mapStateToProps = (state) => {
    return {
        configData: state.configReducerPersist.configData,
        inbox_Page: state.inbox.inboxPage,
    };
};

export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(AddNewUserModal))
);
