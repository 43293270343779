import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    GET_OUTBOX_LIST,
    OUTBOX_LIST_SUCCESS,
    OUTBOX_LIST_FAILURE,
    SET_SELECTED_ROW,
    GET_SHARED_PROPS,
    DEFAULT_OUTBOX_STATE,
    GET_RULES_JSON,
    SET_ROW_DATA,
    GET_COUNT_DATA,
    SHOW_LOADER,
    RE_RENDER_OUTBOX,
    HIDE_BIG_VIEW,
    SET_MENU_NAME,
    SET_PAGE_DATA,
    GET_OWNERS,
    GET_OWNERS_SUCCESS,
    GET_OWNERS_FAILURE,
    SET_PROCESS_ID,
    SET_STATUS_ID,
    SET_CALLING_PAGE,
    SET_OWNER,
    SET_DOC_OWNER,
    UPDATE_OUTBOX_LIST,
    CLEAR_OUTBOX_STATE,
    UPDATE_OUTBOX_STATE,
    SELECTED_ROW_DETAILS,
    SELECTED_ROW_GRID_DETAILS,
    MULTIPLE_EDIT_BASE64,
    OUTBOX_PAGE,
    OUTBOX_GROUPING,
    INITIAL_OUTBOX_GROUPING,
} from '../actionTypes/outboxTypes';

const initialState = {
    data: undefined,
    loading: true,
    rowListData: [],
    rowData: [],
    count: undefined,
    owners: [],
    processId: '',
    statusId: '',
    callingPage: '',
    owner: '',
    docOwner: [],
    dataToken: Math.random(),
    stateToken: Math.random(),
    stateData: {
        allActions: {},
        selectedRows: new Set(),
        rowData: [],
    },
    selectedRowDetails: { rulesJson: {} },
    rowsToDelete: [],
    tableDesign: 'complex', // 'complex','simple',
    selectedIndexes: [],
    rowTransition: false,
    groupByData: {},
    filterTxt: '',
    row_transition: false,
    refresh_table: true,
    refresh_delete: true,
    menu_selected_tagonly: [],
    //menu_branch: '',
    //menu_group: '',
    //menu_inbox: '',
    toast_type: 'success',
    detailBigView: false,
    // eslint-disable-next-line no-dupe-keys
    owners: [],
    main_spinner: false,
    rowClickClass: [],
    selected_tag: [],
    showData: false,
    selectedTagDocId: '',
    selectedColumn: '',
    ownerTitle: '',
    pageCount: 0,
    displayAnimation: false,
    editId: [],
    menu_name: '',
    tagIndex: 0,
    openedRowId: {},
    outboxpage: {
        refreshTable: '',
        related_doc_detail: false,
        related_doc_from: '',
        filteredData: [],
    },
    grouping: {},
};

export const outboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEFAULT_OUTBOX_STATE:
            return {
                ...state,
            };
        // OUTBOX LIST
        case GET_OUTBOX_LIST:
            return { ...state, loading: true };
        case OUTBOX_LIST_SUCCESS:
            return {
                ...state,
                outbox: action.data,
                loading: false,
                dataToken: Math.random(),
            };
        case OUTBOX_LIST_FAILURE:
            return { ...state, loading: false };

        // SELECTED ROW
        case SET_SELECTED_ROW:
            return { ...state, selectedRow: action.rowIdx };
        case GET_SHARED_PROPS:
            return { ...state };

        // To get count
        // case GET_COUNT_DATA:
        // 	return { ...state ,count:action.count, loading: false }
        case SET_ROW_DATA:
            return { ...state, rowData: action.rowData };

        // OWNERS LIST FOR AUTOCOMPLETE
        case GET_OWNERS:
            return { ...state, owners: [] };
        case GET_OWNERS_SUCCESS:
            return { ...state, owners: action.data };
        case GET_OWNERS_FAILURE:
            return { ...state, error: action.error };

        // TO SET PROCESS AND STATUS ID
        case SET_PROCESS_ID:
            return { ...state, processId: action.processId };
        case SET_STATUS_ID:
            return { ...state, statusId: action.statusId };
        case SET_CALLING_PAGE:
            return { ...state, callingPage: action.callingPage };
        // TO SET OWNER VALUE
        case SET_OWNER:
            return { ...state, owner: action.owner };
        case SET_DOC_OWNER:
            return { ...state, docOwner: action.docOwner };

        // TO UPDATE OUTBOX DATA
        case UPDATE_OUTBOX_LIST:
            return { ...state, outbox: action.data };

        // TO CLEAR OUTBOX DATA
        case CLEAR_OUTBOX_STATE:
            var update_outbox_state = {
                ...state.stateData,
                ...action.data,
                currentMenu: '',
            };

            var new_outbox_state = { stateData: update_outbox_state };

            return {
                ...state,
                ...new_outbox_state,
                stateToken: Math.random(),
                stateData: action.data, //small view will display incorrectly
            };

        case UPDATE_OUTBOX_STATE:
            var update_outbox_state = {
                ...state.stateData,
                ...action.data,
                currentMenu: '',
            };
            //var new_outbox_state = { stateData: update_outbox_state };

            return {
                ...state,
                //  ...new_outbox_state,
                stateData: update_outbox_state,
                stateToken: Math.random(),
                //stateData: action.data, //small view will display incorrectly
            };

        case SHOW_LOADER:
            return { ...state, loading: action.loader };

        case SELECTED_ROW_DETAILS:
            return { ...state, selectedRowDetails: action.data };

        case SELECTED_ROW_GRID_DETAILS:
            return { ...state, selectedRowGRIDDetails: action.data };

        case MULTIPLE_EDIT_BASE64:
            return { ...state, multipleEditBase64: action.data };

        case OUTBOX_PAGE:
            var old_state = {
                ...state.outboxpage,
                ...action.data,
            };
            var new_state = { outboxpage: old_state };
            return {
                ...state,
                ...new_state,
            };
        case INITIAL_OUTBOX_GROUPING:
            return {
                ...state,
                grouping: JSON.parse(
                    localStorage.getItem('grouping_outbox') || '{}'
                ),
            };
        case OUTBOX_GROUPING:
            if (!action.data.data) {
                let newGroup = { ...state.grouping };
                delete newGroup[action.data.pathname];

                localStorage.setItem(
                    'grouping_outbox',
                    JSON.stringify(newGroup)
                );
                return { ...state, grouping: newGroup };
            }
            let data = { [action.data.pathname]: { ...action.data } };
            delete data[action.data.pathname].pathname;
            let newGroup = {
                ...state.grouping,
                ...data,
            };

            localStorage.setItem('grouping_outbox', JSON.stringify(newGroup));
            return { ...state, grouping: newGroup };
        default:
            return state;
    }
};

const initialAuthState = {
    user: undefined,
    count: [],
    rulesJson: {},
    loader: false,
    rerenderOutbox: false,
    currentMenuName: null,
    pageData: {},
};

export const pReducer = persistReducer(
    { storage, key: 'outbox', whitelist: ['count', 'rulesJson'] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            // RULES JSON
            case GET_COUNT_DATA: {
                const { count } = action;
                return { ...state, count };
            }
            case GET_RULES_JSON:
                return { ...state, rulesJson: action.rulesJson, loading: true };
            case RE_RENDER_OUTBOX:
                return {
                    ...state,
                    rerenderOutbox: action.rerenderOutbox,
                    loading: true,
                };
            case HIDE_BIG_VIEW:
                return {
                    ...state,
                    hidebigview: action.hidebigview,
                    loading: true,
                };
            case SET_MENU_NAME:
                return {
                    ...state,
                    currentMenuName: action.currentMenuName,
                    loading: false,
                };
            case SET_PAGE_DATA:
                let { pageData } = state;
                pageData = { ...pageData, ...action.data };
                return {
                    ...state,
                    pageData,
                };
            default:
                return state;
        }
    }
);
