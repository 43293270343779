/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile } from 'react-device-detect';
import { NavLink } from 'react-router-dom';
//import SVG from "react-inlinesvg";
import { getConfig, GetCounter } from '../../../../_helpers';
import { formatNameWithSpaceTohipen } from '../../../../../app/components/commonfunction';
import { useSelector, useDispatch } from 'react-redux';
import { updateSearchState } from '../../../../../app/pages/_redux/actions/searchActions';
// import { getListaction, updateOutboxState } from '../../../../../app/pages/_redux/actions/outboxActions';
export function SearchMenu({ layoutProps }) {
    const location = useLocation();
    const CounterData = GetCounter();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        const currentClass = document.getElementsByClassName('menu-link');

        for (let i = 0; i < currentClass.length; i++) {
            currentClass[i].classList.remove('root-active');
        }
        return location.pathname === url ? 'active current' : '';
    };

    const current_path = location.pathname;
    const path_arr = current_path.split('/');
    var current_menu = path_arr[1];

    // START: Worked by vijay for adding sample data for menu section on 01-07-2021
    const configData = getConfig();

    //const menuItems	=	(configData.length > 0) ? configData[0].menuItems : [];
    const menuItems = configData.length > 0 ? configData[0].menuItems : [];
    
    const configuredRecordsets =
        configData.length > 0 ? configData[0].configuredRecordsets : [];
    const reduxStore = useSelector((state) => state);
    let getItemLocal = localStorage.getItem(
        'initialCustomSearchNamedIdsNeedingConfiguration'
    );
    let initialconfigData =[]
    initialconfigData.push(JSON.parse(getItemLocal));
   
    const customSearchQueries =
       initialconfigData.length > 0 ? initialconfigData[0]: [];
    var availableRecordsetArray = [];
    var recordsets = [];
    var patientRecordset = '';
    var physicianRecordset = '';
    if(configuredRecordsets !== undefined){
        configuredRecordsets.map((item, index) => {
            if (
                String(formatNameWithSpaceTohipen(item.name)) ===
                String(formatNameWithSpaceTohipen('Patient Data'))
            ) {
                patientRecordset = item.recordsetId;
            }
            if (
                String(formatNameWithSpaceTohipen(item.name)) ===
                String(formatNameWithSpaceTohipen('Physician Directory'))
            ) {
                physicianRecordset = item.recordsetId;
            }
        });
    }
    

    let dispatch = useDispatch();
    // END: Worked by vijay for adding sample data for menu section on 01-07-2021
    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const { pathname } = location;
        return pathname === '/';
    };
    const doCollapse = async () => {
        if (document.body.classList.contains('doc-detail-view')) {
            //document.body.classList.remove('doc-detail-view');
            //document.body.classList.remove('aside-minimize');
        } else {
            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
            await dispatch(
                updateSearchState({
                    //selectedRows: new Set(),
                    deletedPage: {
                        ...reduxStore.search.deletedPage,
                        row_transition: false,
                    },
                })
            );
        }
        const elements = document.querySelectorAll('.rdg-row');
        if (isMobile) {
            document.body.classList.remove('small-view-display');
        }
        setTimeout(() => {
            const checkBox =
                document.getElementsByClassName('rdg-checkbox-input');
            if (checkBox !== null) {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].disabled = false;
                    checkBox[i].removeAttribute('disabled');
                }
            }
        }, 20);
    };
    return (
        <div
            id='kt_aside'
            className='aside aside-left rounded-lg   aside-fixed d-flex flex-column flex-row-auto'
            /* style={{ height: window.innerHeight - 160 }}*/
        >
            <div
                id='kt_aside_menu_wrapper'
                className='aside-menu-wrapper flex-column-fluid'
            >
                <Scrollbars
                    id='kt_aside_menu'
                    data-menu-vertical='1'
                    className='aside-menu '
                >
                    {menuItems && menuItems.length > 0
                        ? menuItems.map((data, key) => {
                              if (
                                  data.menuName.toLowerCase() === current_menu
                              ) {
                                  return (
                                      <React.Fragment key={key}>
                                          <ul className={`menu-nav p-0`}>
                                              {patientRecordset !== '' &&
                                              data.recordSets.includes(
                                                  patientRecordset
                                              ) ? (
                                                  <li
                                                      //className='menu-item '
                                                      aria-haspopup='true'
                                                      className={`menu-item ${getMenuItemActive(
                                                        '/search/patients'
                                                    )} ${getMenuItemActive(
                                                        '/search'
                                                    )}`}
                                                  >
                                                      <NavLink
                                                          /* className={`menu-link ${getMenuItemActive(
                                                              '/search/patients'
                                                          )} ${getMenuItemActive(
                                                              '/search'
                                                          )}`} */
                                                          className='menu-link'
                                                          title='Patient Search'
                                                          id='menu_search_patient'
                                                          to='/search/patients'
                                                      >
                                                          <i className='fa-solid fa-user'></i>
                                                          <span className='menu-text'>
                                                              Patient Search
                                                          </span>
                                                      </NavLink>
                                                  </li>
                                              ) : null}
                                              {physicianRecordset !== '' &&
                                              data.recordSets.includes(
                                                  physicianRecordset
                                              ) ? (
                                                  <li
                                                      //className='menu-item '
                                                      aria-haspopup='true'
                                                      className={`menu-item ${getMenuItemActive(
                                                        '/search/physicians'
                                                    )}`}
                                                  >
                                                      <NavLink
                                                        /* className={`menu-link ${getMenuItemActive(
                                                            '/search/physicians'
                                                        )}`} */
                                                            className='menu-link'
                                                          title='Physician Search'
                                                          id='menu_search_physician'
                                                          to='/search/physicians'
                                                      >
                                                          <i className='fa-solid fa-user-doctor'></i>
                                                          <span className='menu-text'>
                                                              Physician Search
                                                          </span>
                                                      </NavLink>
                                                  </li>
                                              ) : null}
                                          </ul>

                                          <span
                                              className='sub-title-menu custome-search-queries'
                                              onClick={() => {
                                                  doCollapse();
                                              }}
                                          >
                                              <i className='fa-solid fa-file-magnifying-glass'></i>
                                              <p className='menu-text'>
                                                  CUSTOM SEARCH QUERIES 
                                              </p>{' '}
                                          </span>

                                          <ul
                                              className='menu-nav p-0 custom-search'
                                              key={'aside-main-' + key}
                                          >
                                              {data.searchQueries.map(
                                                  (value, index) => {
                                                      return (
                                                          <React.Fragment
                                                              key={
                                                                  'aside-frag-' +
                                                                  index
                                                              }
                                                          >
                                                            
                                                              {customSearchQueries.map(
                                                                  (
                                                                      item,
                                                                      index
                                                                  ) => {
                                                                      if (
                                                                          item.id ==
                                                                          value
                                                                      ) {
                                                                      
                                                                          return (
                                                                              <li
                                                                                className = {path_arr.length >= 3 &&  String(path_arr[4]) === String(item.id) ?
                                                                                    'menu-item active' :
                                                                                    'menu-item'
                                                                                } 
                                                                                 // className='menu-item '
                                                                                  aria-haspopup='true'
                                                                                  key={
                                                                                      index
                                                                                  }
                                                                              >
                                                                                  <NavLink
                                                                                      className='menu-link'
                                                                                      to={
                                                                                          '/' +
                                                                                          current_menu +
                                                                                          '/' +
                                                                                          'custom-search' +
                                                                                          '/' +
                                                                                          formatNameWithSpaceTohipen(
                                                                                              item.name
                                                                                          ) +
                                                                                          '/' +
                                                                                          item.id
                                                                                      }
                                                                                  >
                                                                                      {/*'/'+current_menu+'/'+String(val.name).replace(/\s/g,'').toLowerCase()*/}
                                                                                      {/* <i
                                                                                           style={{
                                                                                              color: val.color,
                                                                                          }}
                                                                                          className={`fas d-flex align-items-center `}
																	  ></i>*/}

                                                                                      <span className='menu-text'>
                                                                                          {
                                                                                              item.name
                                                                                          }
                                                                                      </span>
                                                                                  </NavLink>
                                                                              </li>
                                                                          );
                                                                      }
                                                                  }
                                                              )}
                                                          </React.Fragment>
                                                      );
                                                  }
                                              )}
                                          </ul>
                                      </React.Fragment>
                                  );
                              }
                          })
                        : null}
                    {/*<ul className='menu-nav p-0 '>
                            <li className='menu-item ' aria-haspopup='true'>
                                <NavLink
                                    className={`menu-link ${getMenuItemActive(
                                        '/search/deleted-documents'
                                    )}`}
                                    title='Deleted documents'
                                    id='menu_search_deleted_documents'
                                    to='/search/deleted-documents'
                                >
                                    <i className='icon-md fab fa-sistrix'></i>
                                    <span className='menu-text'>
                                        Deleted documents
                                    </span>
                                </NavLink>
                            </li>
                            <li className='menu-item ' aria-haspopup='true'>
                                <NavLink
                                    className={`menu-link ${getMenuItemActive(
                                        '/search/removed-from-tracking'
                                    )}`}
                                    title='Removed Trackings'
                                    id='menu_search_removed_trackings'
                                    to='/search/removed-from-tracking'
                                >
                                    <i className='icon-md fab fa-sistrix'></i>
                                    <span className='menu-text'>
                                        Removed from Tracking
                                    </span>
                                </NavLink>
                            </li>
                        </ul>*/}
                </Scrollbars>
            </div>
        </div>
    );
}

/*const mapDispatchToProps = {
    //showLoader: (canShow) => showLoader(canShow),
    getListaction: getListaction,
};
const mapStateToProps = (state, ownProps) => {
    return {
        outbox: state.outbox,            
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
        
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchMenu))
);*/
