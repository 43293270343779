import React from 'react';
import NumberFormat from 'react-number-format';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCountData } from '../../_redux/actions/inboxActions';
export class FaxNowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alternateFaxNumber: '',
            validateCreate: false,
            disabledClass: 'disabled',
            faxNowBtn: false,
            postLoading: `post_loading_${Math.random()}`,
            submitted_faxNow: this.props.submitted_faxNow,
            posted: `posted_${Math.random()}`,
        };
    }

    closeFaxNowPopUp = (openFaxNow) => {
        this.updateState({
            openFaxNow: false,
            faxSaveBtn: false,
            submitted: 0,
            submitted_faxNow: 0,
        });
    };

    updateState = (state) => {
        this.props.updateState(state);
    };
    componentDidUpdate() {
        if (this.props.childFaxnow_update === true) {
            this.setState(
                {
                    submitted_faxNow: 0,
                },
                this.props.updateState({
                    childFaxnow_update: false,
                    submitted_faxNow: 0,
                })
            );
        }
    }

    submitFaxNow = async () => {
        await this.setState(
            {
                submitted_faxNow: 1,
            },
            () => {
                this.props.updateState({
                    submitted_faxNow: 1,
                });
            }
        );
        const alternateFaxNumberValue = this.state.alternateFaxNumber;
        if (alternateFaxNumberValue === '') {
            this.setState({ validateCreate: true, submitted_faxNow: 0 });
        } else {
            //const pattern = /^[1]-[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
            const pattern = /^\d{10}$/;
            //const pattern = /^[0-9\b\.\,\+\-\*\s\(\)\[\]\#]+$/;
            const format = alternateFaxNumberValue.match(pattern);
            this.setState({
                faxFormat: format,
            });

            if (format === null) {
                this.setState({
                    faxNowBtn: false,
                    submitted_faxNow: 0,
                });
                return false;
            } else {
                this.setState({
                    faxNowBtn: true,
                    // submitted_faxNow: 0,
                });
                await this.props.submitFaxNow('submitFaxNow');

                this.setState({
                    faxNowBtn: false,
                });
            }
        }
    };

    handleInputOnChange = (event) => {
        const alternateFaxNumber = event.target.value.replace(/-/g, '');
        if (alternateFaxNumber !== '') this.setState({ disabledClass: '' });
        this.setState({ alternateFaxNumber: alternateFaxNumber }, () => {
            this.setState({ validateCreate: false });
            this.updateState({ alternateFaxNumber: alternateFaxNumber });
        });
    };

    render() {
        return (
            <>
                <Modal
                    className='delete-popup'
                    show={this.props.openFaxNow}
                    onHide={this.closeFaxNowPopUp.bind(this, false)}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i className='fa fa-fax' />
                                Fax Now to Alternate Number
                            </div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.closeFaxNowPopUp.bind(
                                    this,
                                    false
                                )}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes'>
                        <span style={{ color: 'black' }}>
                            This will send it to this one-off number. This
                            number won’t be saved. If you want to change the
                            delivery method or fax number for this physician,
                            please edit the delivery details instead.
                        </span>
                        <div className='form-group row mb-0'>
                            <div className='col-lg-4 mt-5 transition-select'>
                                <label className='pl-5'>FAX NUMBER</label>

                                <NumberFormat
                                    className={
                                        this.state.validateCreate
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    //type='number'
                                    value={this.state.alternateFaxNumber}
                                    format='###-###-####'
                                    allowEmptyFormatting
                                    mask='_'
                                    //maxLength='14'
                                    onChange={this.handleInputOnChange}
                                />
                                {this.state.validateCreate ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Fax Number is required.
                                    </p>
                                ) : null}
                                {this.state.faxFormat === null &&
                                this.state.alternateFaxNumber ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Please check the Fax Number format.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    title='Cancel'
                                    variant='secondary'
                                    onClick={this.closeFaxNowPopUp.bind(
                                        this,
                                        false
                                    )}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                {this.state.submitted_faxNow === 1 ? (
                                    <Button
                                        className='post_loading btn-success'
                                        id={this.state.postLoading}
                                    >
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        title='Yes, Delete'
                                        className='btn-success'
                                        onClick={this.submitFaxNow}
                                        disabled={this.state.faxNowBtn}
                                    >
                                        Fax Now
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    getCountData,
};
const mapStateToProps = (state) => {
    return {
        rowData: state.inbox.rowData,
        count: state.persist.count,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FaxNowModal);
