/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import { debounce } from 'throttle-debounce';
import {
    orderRecordSetSearch,
    updateRecordSet,
    createNewPatient,
    deleteRecordSet,
} from '../../api/api';
import {
    OrderWithUnderScore,
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    showErrorToast,
    normalSuccessToast,
    GetFieldData,
    encodeParameter,
} from '../../../components/commonfunction';
import CreateEditLocation from './CreateEditLocation';

export class AddLocationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.state,
            locationAutoCompSpinner: true,
            selected_address: '',
            selected_location: '',
            location_list: '',
            open_create_edit: false,
            location_autocomplete: true,
            location_address_lookup: '',
            location_name: '',
            address: '',
            city: '',
            states: '',
            zipcode: '',
            note: '',
            location_details: [],
            initial_view: true,
            physicianId: this.props.physicianId,
            locationByPhysicianRecordsetId: '',
            postLoading: `post_loading_${Math.random()}`,
            submitted: 0,
            posted: `posted_${Math.random()}`,
            unlinkSubmitted: 0,
            uniqueAddressID: this.props.uniqueAddressID,
        };
    }

    componentDidMount() {
        let configuredRecordsets = [];
        let location_FieldIdArray;
        let location_RecordsetId;
        let location_ModifiableRecordsetId;
        let location_by_physician_FieldIdArray;
        let location_by_physician_RecordsetId;
        let location_by_physician_ModifiableRecordsetId;
        let physician_directory_FieldIdArray;
        let physician_directory_RecordsetId;
        let physician_directory_ModifiableRecordsetId;
        let createPhysician_FieldIdArray;
        let createPhysician_RecodsetId;
        let createPhysician_ModifiableRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physicianlocations'
                ) {
                    location_FieldIdArray = data.fields;
                    location_RecordsetId = data.recordsetId;
                    location_ModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'locationsbyphysician'
                ) {
                    location_by_physician_FieldIdArray = data.fields;
                    location_by_physician_RecordsetId = data.recordsetId;
                    location_by_physician_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectorylocations'
                ) {
                    physician_directory_FieldIdArray = data.fields;
                    physician_directory_RecordsetId = data.recordsetId;
                    physician_directory_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectory'
                ) {
                    createPhysician_FieldIdArray = data.fields;
                    createPhysician_RecodsetId = data.recordsetId;
                    createPhysician_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                return data;
            });
        }

        this.setState({
            locationFieldIdArray: location_FieldIdArray,
            locationRecordsetId: location_RecordsetId,
            modifyLocationRecordsetId: location_ModifiableRecordsetId,
            locationByPhysicianFieldIdArray: location_by_physician_FieldIdArray,
            locationByPhysicianRecordsetId: location_by_physician_RecordsetId,
            modifyLocationByPhysicianRecordsetId:
                location_by_physician_ModifiableRecordsetId,
            physicianDirectoryFieldIdArray: physician_directory_FieldIdArray,
            physicianDirectoryRecordsetId: physician_directory_RecordsetId,
            modifyPhysicianDirectoryRecordsetId:
                physician_directory_ModifiableRecordsetId,
            createPhysician_ModifiableRecordsetId:
                createPhysician_ModifiableRecordsetId,
            createPhysician_RecodsetId: createPhysician_RecodsetId,
            createPhysician_FieldIdArray: createPhysician_FieldIdArray,
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.locationId !== '' &&
            this.props.locationId !== prevProps.locationId
        ) {
            this.props.updateState({
                locationId: this.props.locationId,
            });
            const temp = {
                address: this.props.address,
                address_id: this.props.locationId,
                city: this.props.city,
                id: this.props.locationId,
                location: this.props.location_name,
                state: this.props.states,
                zip: this.props.zipcode,
                note: this.props.note,
            };

            this.open_location_search(temp);
        }
    }
    // getSingleLocation this function not defined
    /* getSingleLocation = (locationId) => {
        this.props.updateState({ initial_view: false });
        let locationSearchText;
        this.state.locationFieldIdArray.map((fieldData, index) => {
            if (fieldData.name === 'Location') {
                // to check
                // locationSearchText = `fieldSearch=${fieldData.fieldId}%3D${locationId}%2A`;
                locationSearchText = `fieldSearch=${
                    fieldData.fieldId
                }%3D${'t'}%2A`;
            }
        });

        const response = [];
        response.push(
            orderRecordSetSearch(
                this.state.locationRecordsetId,
                locationSearchText
            )
        );

        const tempLocationList = [];
        Promise.all(response).then((data) => {
            data.map((result) => {
                tempLocationList.push(...OrderWithUnderScore(result));
            });

            this.open_location_search(tempLocationList[0]);
        });
    }; */

    closeLocationDropDown = (event) => {
        const popElement = document.getElementsByClassName('patient_dropdown');
        let popEl;
        for (let i = 0; i < popElement.length; i++) {
            popEl = popElement[i];
            const isClickInside = popEl.contains(event.target);
            if (!isClickInside) {
                this.setState({
                    open_location_dropdown: false,
                    selected_location: '',
                    location_filter: '',
                });
                return;
            }
        }
    };
    debounceFunc = debounce(500, (text) => {
        this.locationApiCall(text);
    });
    async byLocationOnChangeHandler(e) {
        this.setState({
            selected_location: e.target.value,
        });
        this.debounceFunc(e.target.value);
    }
    async locationApiCall(text) {
        this.setState({
            //selected_location: e.target.value,
            create_location: false,
            view_location: false,
            open_location_dropdown: false,
            location_filter: text,
            locationAutoCompSpinner: true,
        });
        let locationSearchText = '';
        if (text !== '') {
            if (this.state.locationFieldIdArray !== '') {
                this.state.locationFieldIdArray.map((fieldData, index) => {
                    if (fieldData.name === 'Location') {
                        // to check
                        locationSearchText += `fieldSearch=${
                            fieldData.fieldId
                        }%3D${encodeParameter(text)}%2A`;
                    }
                    return fieldData;
                });
            }
            this.setState({ location_list: [] }, async () => {});
            this.setState({ open_location_dropdown: true });
            const response = [];
            response.push(
                orderRecordSetSearch(
                    this.state.locationRecordsetId,
                    locationSearchText
                )
            );

            const tempLocationList = [];
            await Promise.all(response).then((data) => {
                data.map((result) => {
                    tempLocationList.push(...OrderWithUnderScore(result));
                });

                //uniqueAddressID

                /*if (!this.state.uniqueAddressID.includes(result.id)) {
                        
                    }*/

                var locationFilteredResult = [];

                tempLocationList.map((result, index) => {
                    //Aiswarya commented the condition due to autocomplete result comimg wrong
                    //if (!this.state.uniqueAddressID.includes(String(result.id))) {
                    locationFilteredResult.push(result);

                    //}
                });

                this.setState({ location_list: locationFilteredResult }, () => {
                    this.setState({ locationAutoCompSpinner: false });
                });
            });
        }
    }

    /* searchByLocation = (searchText) => {
        let status;
        orderRecordSetSearch(this.state.locationRecordsetId, searchText)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then(async (data) => {
                if (status === 200) {
                    const searchResult = OrderWithUnderScore(data);
                    if (this.state.location_list.length > 0) {
                        this.setState(
                            {
                                location_list: [
                                    ...this.state.location_list,
                                    ...searchResult,
                                ],
                            },
                            () => {
                                this.setState({locationAutoCompSpinner: false });
                            }
                        );
                    }
                }
            });
    }; */

    location_list = () => {
        if (this.state.location_list.length > 0) {
            return this.state.location_list.map((search, index) => {
                const req = `${this.state.selected_location} `; // autocomplete value

                let res = `${search.location}`;

                if (req) {
                    const normReq = req
                        .toLowerCase()
                        .replace(/\s+/g, ' ')
                        .trim()
                        .split(' ')
                        .sort((a, b) => b.length - a.length);
                    res = res.replace(
                        new RegExp(`(${normReq.join('|')})`, 'gi'),
                        (match) => `<b className='highlight'>${match}</b>`
                    );
                }
                const custom_location = res;

                return (
                    <li
                        key={index}
                        onClick={() => {
                            this.open_location_search(search);

                            this.props.updateState({
                                autoCompeteLocation: true,
                            });
                        }}
                    >
                        <div className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <div className='patient_no_name'>
                                    <span
                                        className='first_name'
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: custom_location,
                                        }}
                                    />
                                </div>
                                <div>
                                    <span>{search.address}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            });
        } else {
            return <div style={{ textAlign: 'center' }}>No Records Found</div>;
        }
    };

    open_location_search = async (search) => {
        if (search.id > 0 || search.id === 0) {
            this.setState({
                locationIsNegative: false,
            });
        } else {
            this.setState({
                locationIsNegative: true,
            });
        }
        this.props.updateState({
            location_autocomplete: true,
        });

        await this.setState({
            location_details: {
                location_name: search.location,
                location: search.location,
                address: search.address,
                city: search.city,
                states: search.state,
                state: search.state,
                zipcode: search.zip,
                zip: search.zip,
                note: search.note,
                location_id: search.id,
                address_id: search.address_id,
            },
            // selected_location: search.location,
        });

        this.setState({
            location_autocomplete: false,
            autoCompeteLocation: false,
        });
        this.props.updateState({
            location_autocomplete: false,
            openAddLocation: true,
            //locationId: search.id,
            //autoCompeteLocation: false,
        });
        if (this.props.add_or_edit === 'add') {
            await this.props.updateState({
                locationId: '',
            });
            await this.setState({
                open_create_edit: 'add',
            });
        } else if (this.props.add_or_edit === 'edit') {
            await this.setState({
                open_create_edit: 'edit',
            });
        }
        //below condition is only for auto complete
        if (this.props.autoCompeteLocation === true) {
            await this.setState({
                open_create_edit: 'edit',
            });
            await this.props.updateState({
                open_create_edit: 'edit',
                isModifiable: true,
                location_id: search.address_id,
            });
        }
    };

    /* edit_location = () => {
        this.setState({ edit_location: true }, () => {
            this.props.updateState({ edit_location: true });
        });
    }; */

    clearInputBox = async () => {
        await this.setState({
            selected_location: '',
            location_name: '',
            address: '',
            city: '',
            note: '',
            states: '',
            zipcode: '',
        });
        await this.props.updateState({
            locationId: '',
            location_name: '',
            address: '',
            city: '',
            note: '',
            states: '',
            zipcode: '',
        });
    };

    openCreateNewLocation = async () => {
        await this.setState({
            edit_Location: false,
            open_create_edit: 'create',
            location_autocomplete: false,
            location_details: [],
            selected_location: '',
            location_list: '',
            locationId: '',
            location_name: '',
            location: '',
            address: '',
            city: '',
            note: '',
            states: '',
            zipcode: '',
            location_id: '',
        });
        await this.props.updateState({
            locationId: '',
            location_autocomplete: false,
            edit_Location: false,
            autoCompeteLocation: true,
            location_name: '',
            address: '',
            city: '',
            note: '',
            states: '',
            zipcode: '',
            location_id: '',
        });
    };

    unlinkAddress = async () => {
        if (this.props.locationId !== 'undefined') {
            this.setState({
                unlinkSubmitted: 1,
            });
            let status;
            // let recordSetId;
            const multipleIds = [];
            //const recordSetId = this.state.modifyLocationRecordsetId;
            const recordSetId = this.state.modifyPhysicianDirectoryRecordsetId;
            // deleteRecordSet(multipleIds, recordSetId, this.props.locationId)
            let uniqueId = Math.abs(this.props.physician_location_id);
            deleteRecordSet(multipleIds, recordSetId, uniqueId)
                .then((response) => {
                    // this.setState({ blur_spinner: false });
                    status = response.status;
                    if (status === 204) {
                        //normalSuccessToast('Location Deleted Successfully');
                        const successMessage = () => {
                            return (
                                <>
                                    <div>Location Unlinked</div>
                                    <br />
                                    <div>
                                        The location has been unlinked from this
                                        physician. It will still be accessible
                                        under the lookup on “Add Location”.
                                    </div>
                                </>
                            );
                        };
                        toast.success(successMessage, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            showTransitionSuccess: false,
                        });

                        /*deleteRecordSet(
                            multipleIds,
                            this.state.modifyLocationByPhysicianRecordsetId,
                            this.props.physician_location_id
                        ).then((response) => {
                            return response.status;
                        });*/
                        this.props.updateState({
                            openAddLocation: false,
                            autoCompeteLocation: false,
                            open_location_dropdown: false,
                        });
                        this.setState({
                            validateEdit: false,
                            open_location_dropdown: false,
                            submitted: 0,
                        });
                    } else if (response !== '') {
                        return response.json();
                    }
                })
                .then((data) => {
                    if (status === 400 || status === 404) {
                        showErrorToast('Error in deleting.');
                        this.setState({
                            unlinkSubmitted: 0,
                        });
                    }
                });
            await this.props.refreshTable();
        }
    };

    handleLocationSave = async () => {
        await this.props.updateState({
            relDocLoading: true,
            submitted: 1,
        });
        //If is modifaibale false physicain=> this condition will create physician and change to modifabl true
        let create_status;
        if (this.props.selectedPhysician.isModifiable === false) {
            const paramsPhysicain = [];
            const formObj = this.props.selectedPhysician;
            const formData = [];
            formData.push(formObj);

            if (this.state.createPhysician_FieldIdArray !== '') {
                this.state.createPhysician_FieldIdArray.map((data) => {
                    const value =
                        formData[0][formatNameWithSpaceToUnderscore(data.name)];
                    if (
                        formData[0][
                            formatNameWithSpaceToUnderscore(data.name)
                        ] !== undefined &&
                        formData[0][
                            formatNameWithSpaceToUnderscore(data.name)
                        ] !== ''
                    ) {
                        paramsPhysicain.push({
                            fieldId: data.fieldId,
                            value: value,
                        });
                    }
                    return '';
                });
            }
            await createNewPatient(
                paramsPhysicain,
                this.state.createPhysician_ModifiableRecordsetId
            )
                .then((response) => {
                    create_status = response.status;
                    return response.json();
                })
                .then(async (data) => {
                    if (create_status === 200) {
                        formData.id = data.uniqueId;
                        await this.props.updateState({
                            selectedPhysician: {
                                ...this.props.selectedPhysician,
                                id: data.uniqueId,
                                isModifiable: true,
                            },
                        });
                    }
                    if (create_status === 400) {
                        showErrorToast('Error while processing');
                        return false;
                    }
                });
        }

        if (
            // this.state.location_address_lookup === '' ||
            this.state.location_name === '' ||
            this.state.address === '' ||
            this.state.city === '' ||
            this.state.states === '' ||
            this.state.zipcode === ''
        ) {
            await this.setState({
                validateEdit: true,
            });
        } else {
            // locationFieldIdArray
            // locationRecordsetId
            // modifyLocationRecordsetId
            const recordSetId = this.state.modifyLocationRecordsetId;
            const params = [];
            const formObj = this.state.location_details;
            const formData = [];

            formData.push(formObj);

            if (this.state.locationFieldIdArray !== '') {
                this.state.locationFieldIdArray.map((data) => {
                    const value =
                        formData[0][formatNameWithSpaceToUnderscore(data.name)];
                    if (
                        formData[0][
                            formatNameWithSpaceToUnderscore(data.name)
                        ] !== undefined &&
                        formData[0][
                            formatNameWithSpaceToUnderscore(data.name)
                        ] !== ''
                    ) {
                        params.push({
                            fieldId: data.fieldId,
                            value: value,
                        });
                    }
                    return '';
                });
            }

            let uniqueId = Math.abs(this.state.location_id);

            if (
                this.state.open_create_edit === 'edit' &&
                this.props.isModifiable
            ) {
                let status;
                await updateRecordSet(params, recordSetId, uniqueId)
                    .then((response) => {
                        status = response.status;
                        if (status === 204) {
                            if (this.props.autoCompeteLocation !== true) {
                                normalSuccessToast(
                                    'Location Updated Successfully'
                                );
                            }

                            // added for relationship
                            if (this.props.autoCompeteLocation === true) {
                                this.props.updateState({
                                    autoCompeteLocation: false,
                                });
                                const locparams = [];
                                let physicainId_FieldId;
                                let addressId_FieldId;
                                this.state.physicianDirectoryFieldIdArray.map(
                                    (data) => {
                                        if (
                                            formatHeaderNameWithOutSpace(
                                                data.name
                                            ) === 'physicianid'
                                        ) {
                                            physicainId_FieldId = data.fieldId;
                                        }
                                        if (
                                            formatHeaderNameWithOutSpace(
                                                data.name
                                            ) === 'addressid'
                                        ) {
                                            addressId_FieldId = data.fieldId;
                                        }
                                    }
                                );

                                locparams.push({
                                    fieldId: physicainId_FieldId,
                                    value: Math.abs(
                                        this.props.selectedPhysician.id
                                    ),
                                });
                                locparams.push({
                                    fieldId: addressId_FieldId,
                                    value: uniqueId,
                                });
                                //this.state.locationByPhysicianRecordsetId

                                createNewPatient(
                                    locparams,
                                    this.state
                                        .modifyPhysicianDirectoryRecordsetId
                                ).then((response) => {
                                    create_status = response.status;
                                    return response.json();
                                });

                                normalSuccessToast(
                                    'Location Added Successfully'
                                );
                                this.props.updateState({
                                    openAddLocation: false,
                                    relDocLoading: false,
                                });
                                this.setState({
                                    validateEdit: false,
                                    open_location_dropdown: false,
                                    selected_location: '',
                                    submitted: 0,
                                });
                            }

                            this.props.updateState({
                                openAddLocation: false,
                                autoCompeteLocation: false,
                                open_location_dropdown: false,
                                locationId: '',
                                relDocLoading: false,
                            });
                            this.setState({
                                validateEdit: false,
                                open_location_dropdown: false,
                                selected_location: '',
                                submitted: 0,
                            });
                        } else if (response !== '') {
                            return response.json();
                        }
                    })
                    .then((data) => {
                        if (status === 400 || status === 404) {
                            showErrorToast(data.message);
                            this.props.updateState({
                                openAddLocation: false,
                                autoCompeteLocation: false,
                                open_location_dropdown: false,
                            });
                            this.setState({
                                open_location_dropdown: false,
                                selected_location: '',
                                submitted: 0,
                            });
                        }
                    });
            } else {
                let fieldId = GetFieldData({
                    configData: this.props.configData,
                });
                const locparams = [];
                locparams.push({
                    fieldId: fieldId.physician_id,
                    value: Math.abs(this.props.selectedPhysician.id),
                });
                locparams.push({
                    fieldId: fieldId.address_id,
                    //value: data.uniqueId,
                    value: this.state.location_id,
                });

                //return true;

                if (
                    this.state.location_id !== undefined &&
                    this.state.location_id !== '' &&
                    this.state.location_id !== null
                ) {
                    // only call recordset 5 since address chosen from autocomplete
                    await createNewPatient(params, recordSetId)
                        .then((response) => {
                            create_status = response.status;
                            return response.json();
                        })
                        .then((data) => {
                            if (create_status === 200) {
                                formData.id = data.uniqueId;
                                this.setState({
                                    location_details: {
                                        ...this.state.location_details,
                                        id: data.uniqueId,
                                    },
                                });
                                this.setState({
                                    selected_address:
                                        this.state.location_address_lookup,
                                });

                                //commented
                                const locparams = [];
                                locparams.push({
                                    fieldId: fieldId.physician_id,
                                    value: Math.abs(
                                        this.props.selectedPhysician.id
                                    ),
                                });
                                locparams.push({
                                    fieldId: fieldId.address_id,
                                    value: data.uniqueId,
                                });
                                //this.state.locationByPhysicianRecordsetId

                                createNewPatient(
                                    locparams,
                                    this.state
                                        .modifyPhysicianDirectoryRecordsetId
                                ).then((response) => {
                                    create_status = response.status;
                                    return response.json();
                                });

                                normalSuccessToast(
                                    'Location Created Successfully'
                                );
                                this.props.updateState({
                                    openAddLocation: false,
                                    relDocLoading: false,
                                });
                                this.setState({
                                    validateEdit: false,
                                    open_location_dropdown: false,
                                    selected_location: '',
                                    submitted: 0,
                                });
                            }
                            if (create_status === 400) {
                                showErrorToast(data.message);
                                this.setState({
                                    submitted: 0,
                                });
                            }
                        });
                } else {
                    await createNewPatient(params, recordSetId)
                        .then((response) => {
                            create_status = response.status;
                            return response.json();
                        })
                        .then((data) => {
                            if (create_status === 200) {
                                formData.id = data.uniqueId;
                                this.setState({
                                    location_details: {
                                        ...this.state.location_details,
                                        id: data.uniqueId,
                                    },
                                });
                                this.setState({
                                    selected_address:
                                        this.state.location_address_lookup,
                                });

                                //commented
                                const locparams = [];
                                locparams.push({
                                    fieldId: fieldId.physician_id,
                                    value: Math.abs(
                                        this.props.selectedPhysician.id
                                    ),
                                });
                                locparams.push({
                                    fieldId: fieldId.address_id,
                                    value: data.uniqueId,
                                });
                                //this.state.locationByPhysicianRecordsetId

                                createNewPatient(
                                    locparams,
                                    this.state
                                        .modifyPhysicianDirectoryRecordsetId
                                ).then((response) => {
                                    create_status = response.status;
                                    return response.json();
                                });

                                normalSuccessToast(
                                    'Location Created Successfully'
                                );
                                this.props.updateState({
                                    openAddLocation: false,
                                    relDocLoading: false,
                                });
                                this.setState({
                                    validateEdit: false,
                                    open_location_dropdown: false,
                                    selected_location: '',
                                    submitted: 0,
                                });
                            }
                            if (create_status === 400) {
                                showErrorToast(data.message);
                                this.setState({
                                    submitted: 0,
                                });
                            }
                        });
                }
            }

            await this.props.refreshTable();
        }
    };

    handlePatientDeleteOperation = async () => {
        this.props.handlePatientDeleteOperation();
    };

    handleCloseOperation = async () => {
        this.props.updateState({
            openAddLocation: false,
            autoCompeteLocation: false,
            locationId: '',
        });

        await this.setState({
            location_autocomplete: true,
            location_details: '',
            selected_location: '',
            location_list: '',
            locationId: '',
            open_location_dropdown: false,
            validateEdit: false,
            unlinkSubmitted: 0,
            submited: 0,
        });
    };

    render() {
        return (
            <>
                <Modal
                    show={this.props.openAddLocation}
                    onHide={this.handleCloseOperation}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i
                                    title='Add Location'
                                    className='fa-regular fa-location-dot mr-3'
                                    style={{ fontSize: '15px' }}
                                />
                                {this.props.add_or_edit === 'edit'
                                    ? 'Edit Location'
                                    : 'Add Location'}
                                {/*this.props.locationId
                                    ? 'Edit Location'
                                    : 'Add Location'*/}
                            </div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.handleCloseOperation}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes'>
                        <>
                            {this.props.location_autocomplete ? (
                                <div className='row grid-row-group'>
                                    <div className='col-lg-12 detail_search'>
                                        <label className='pl-5'>
                                            ADDRESS LOOKUP
                                        </label>
                                        <div className='d-flex'>
                                            <div className='filter'>
                                                <input
                                                    className='form-control'
                                                    value={
                                                        this.state
                                                            .selected_location
                                                    }
                                                    placeholder='Search Address'
                                                    type='text'
                                                    onChange={this.byLocationOnChangeHandler.bind(
                                                        this
                                                    )}
                                                    // style={{fontWeight:"bold"}}
                                                />
                                                {this.state.selected_address !==
                                                    '' &&
                                                this.state.selected_address
                                                    .length > 0 ? (
                                                    <i
                                                        className='fa-regular fa-xmark'
                                                        onClick={
                                                            this.clearInputBox
                                                        }
                                                    />
                                                ) : (
                                                    <i className='fa-regular fa-magnifying-glass' />
                                                )}
                                                {this.state
                                                    .open_location_dropdown ? (
                                                    <div
                                                        className='patient_dropdown'
                                                        style={{
                                                            height:
                                                                this.state
                                                                    .location_list
                                                                    .length > 0
                                                                    ? '248px'
                                                                    : '80px',
                                                        }}
                                                    >
                                                        <ul
                                                            style={{
                                                                listStyleType:
                                                                    'none',
                                                                height:
                                                                    this.state
                                                                        .location_list
                                                                        .length >
                                                                    0
                                                                        ? '200px'
                                                                        : '',
                                                                overflowY:
                                                                    'scroll',
                                                            }}
                                                        >
                                                            {this.state
                                                                .locationAutoCompSpinner ? (
                                                                <div
                                                                    style={{
                                                                        textAlign:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <Spinner
                                                                        animation='grow'
                                                                        variant='primary'
                                                                    />
                                                                </div>
                                                            ) : (
                                                                this.location_list()
                                                            )}
                                                        </ul>

                                                        <div
                                                            className='adding_patient'
                                                            onClick={() => {
                                                                this.openCreateNewLocation();
                                                            }}
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <div className='plus'>
                                                                <span>
                                                                    <i
                                                                        className='fa-regular fa-plus'
                                                                        aria-hidden='true'
                                                                    />
                                                                </span>
                                                            </div>
                                                            Create New Location
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div
                                                className='plus'
                                                onClick={() => {
                                                    this.openCreateNewLocation();
                                                }}
                                            >
                                                <span>
                                                    <i
                                                        className='fa-regular fa-plus'
                                                        aria-hidden='true'
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <CreateEditLocation
                                        validateEdit={this.state.validateEdit}
                                        location_details={
                                            this.state.location_details
                                        }
                                        updateState={(state) => {
                                            this.props.updateState({
                                                ...state,
                                            });
                                            this.setState({ ...state });
                                        }}
                                        locationId={this.props.locationId}
                                        openCreateNewLocation={() => {
                                            this.openCreateNewLocation();
                                        }}
                                        edit_Location={this.props.edit_Location}
                                        autoCompeteLocation={
                                            this.props.autoCompeteLocation
                                        }
                                        selected_location={
                                            this.state.selected_location
                                        }
                                        open_create_edit={
                                            this.state.open_create_edit
                                        }
                                    />
                                </div>
                            )}
                        </>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    variant='secondary'
                                    onClick={this.handleCloseOperation}
                                    title='Cancel'
                                >
                                    Cancel
                                </Button>
                            </div>

                            <div className='d-flex'>
                                {this.props.isModifiable &&
                                this.props.edit_Location &&
                                this.state.unlinkSubmitted === 0 ? (
                                    <Button
                                        variant='primary'
                                        className=''
                                        onClick={this.unlinkAddress}
                                        title='Unlink Address'
                                    >
                                        Unlink Address
                                    </Button>
                                ) : null}

                                {this.props.isModifiable &&
                                this.props.edit_Location &&
                                this.state.unlinkSubmitted === 1 ? (
                                    <button
                                        className='post_loading btn btn-danger'
                                        id={this.state.postLoading}
                                        type='button'
                                    >
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    </button>
                                ) : null}

                                {this.state.submitted === 1 ? (
                                    <button
                                        className='ml-3 post_loading save_btn btn btn-success'
                                        id={this.state.postLoading}
                                        type='button'
                                    >
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    </button>
                                ) : (
                                    <Button
                                        variant='success'
                                        onClick={this.handleLocationSave}
                                        title='Save changes'
                                        disabled={
                                            this.props.location_autocomplete
                                                ? 'disabled'
                                                : ''
                                        }
                                        className={
                                            this.props.location_autocomplete
                                                ? 'save-disabled'
                                                : ''
                                        }
                                    >
                                        Save changes
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddLocationModal));
