import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import {
    getConfigurationData,
    getPageTitle,
    getCount,
    getDocumentMetaWithDocId,
    lockDocument,
    GetStore,
    getLockDocuments,
    //getOwnersFromApi,
} from '../api/api';
import {
    Spinner,
    SpinnerTwo,
    SpinnerBlur,
    SpinnerThree,
} from './components/Spinner';
import OutboxSmallView from './components/OutboxSmallView';
import DetailBigView from './components/DetailBigView';
import {
    setSelectedRow,
    getCountData,
    showLoader,
    // reRenderOutbox,
    // hideBigView,
    getOwners,
    setProcessId,
    setStatusId,
    setDocOwner,
    setMenuName,
    setPageData,
    getListaction,
    updateList,
    updateOutboxState,
    setRowData,
    getSelectedRowDetails,
    outboxPage,
    clearOutboxState,
} from '../_redux/actions/outboxActions';

import {
    getDDLlist,
    getConfigData,
    getCustomSearchConfig,
    getRecordSetConfig,
} from '../_redux/actions/configActions';
import {
    getDocumentIdFromArray,
    removeCheckboxClass,
    handleClickOutside,
    userPermission,
    stringEmptyOrUndefined,
    GetFieldData,
    RefreshCounts,
    formatHeaderNameWithOutSpace,
    getDropDownDataFromApi,
    getOwnersToOutbox,
    showErrorToast,
    updateCustomSearchConfigDataInLocalStorage,
} from '../../components/commonfunction';
import {
    configurationCustomeSearchId,
    configurationRecordSetId,
} from '../api/api';
import DataGridComponent from '../../components/datagrid/OutboxDataGrid';
import { getProcessCount } from '../_redux/authCrud';
import DeleteModal from './components/DeleteModal';
import { Statistics } from './components/Statistics';
import { getDefaultState, GridState } from '../../components/variable';
import EditWorkPage from '../EditWorkPage';
import DocumentDetailViewComponent from './components/DocumentDetailViewComponent';
import PdfPreview from './components/PdfPreview';
import MultipleEditPage from '../MultipleEditPage';
import SearchFilter from '../../components/searchFilter/SearchFilter';

let userdetails = localStorage.getItem('user_details');
userdetails = JSON.parse(userdetails);
let dispname = '';
let uname = [];
let username = '';
if (userdetails) {
    username = userdetails.userDisplayName;
    uname = username.split(' ');
    if (uname.length > 1) {
        dispname = `${uname[0].charAt(0).toUpperCase()}${uname[1]
            .charAt(0)
            .toUpperCase()}`;
    } else {
        dispname = `${uname[0].charAt(0).toUpperCase()}${uname[0]
            .charAt(1)
            .toUpperCase()}`;
    }
}
const statusArray = [];
class OutboxPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        const defaultQueryParams = {
            pageNumber: 1,
            pageSize: 25,
        };
        this.textInput = React.createRef();
        this.pdfPrintRef = React.createRef();
        // const { value } = this.props;
        this.state = {
            //...this.props.outbox,

            nextDocument: false,
            currentStatistics: 'by_status',
            flag_bigView: false,
            pdf_preview: true,
            dataGridReferesh: true,
            perPage: 25,
            currentPage: 0,
            owenerRow: false,
            detailBigViewExpand: false,
            loading: true,
            callingPage: this.props.outbox.callingPage,
            dataToken: this.props.outbox.dataToken,
            processId: this.props.outbox.processId,
            statusId: this.props.outbox.statusId,
            stateToken: this.props.outbox.stateToken,
            rowData: this.props.outbox.stateData.rowData,
            detailBigView: false,
            filterTxt: '',
            pathName: '',
            queryData: { ...defaultQueryParams, ...props.query },
            lockedDocIds: [],
            lockBarVisible: false,
        };

        this.refreshTable = this.refreshTable.bind(this);

        this.getTransitonDropDownData =
            this.getTransitonDropDownData.bind(this);
    }

    configurationCustomeSearchId = async (id) => {
        configurationCustomeSearchId(id)
            .then(async (data) => {
                const customSearches = data.customSearchQueries;
                const customSearchNamedIdsNeedingConfiguration =
                    data.customSearchNamedIdsNeedingConfiguration;

                updateCustomSearchConfigDataInLocalStorage(
                    customSearches,
                    customSearchNamedIdsNeedingConfiguration
                );

                if (this.props.getCustomSearchConfig) {
                    await this.props.getCustomSearchConfig(
                        customSearches,
                        customSearchNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    };
    configurationRecordSet(id) {
        configurationRecordSetId(id)
            .then(async (data) => {
                const configuredRecordsets = data.configuredRecordsets;
                const recordsetNamedIdsNeedingConfiguration =
                    data.recordsetNamedIdsNeedingConfiguration;

                const existingConfigData =
                    JSON.parse(localStorage.getItem('configData')) || {};
                existingConfigData.configuredRecordsets = configuredRecordsets;
                existingConfigData.recordsetNamedIdsNeedingConfiguration =
                    data.recordsetNamedIdsNeedingConfiguration;

                localStorage.setItem(
                    'configData',
                    JSON.stringify(existingConfigData)
                );

                if (this.props.getRecordSetConfig) {
                    await this.props.getRecordSetConfig(
                        configuredRecordsets,
                        recordsetNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    }
    async componentDidMount() {
        await this.getTransitonDropDownData();
        this._isMounted = true;

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');

        const currentURL = window.location.pathname.split('/');
        this.setState({
            currentURL: currentURL[1],
        });

        let processId = '';
        let statusId = '';
        let menu_name = '';
        const fieldData = GetFieldData(
            { configData: this.props.configData },
            'Outbox'
        );
        GetStore(fieldData);

        if (this.props.match.params.menuName !== undefined) {
            menu_name = this.props.match.params.menuName;
        }
        if (this.props.match.params.processId !== undefined) {
            processId = this.props.match.params.processId;
        }
        if (this.props.match.params.statusId !== undefined) {
            statusId = this.props.match.params.statusId;
        }

        if (String(processId).trim() === '' && String(statusId) === '') {
            if (this.props.processId !== undefined)
                processId = this.props.processId;
            else processId = fieldData.firstProcessId;

            if (this.props.statusId !== undefined)
                statusId = this.props.statusId;
            else statusId = fieldData.firstStatusId;

            const curr_menu = `menu_p_${processId}_s_${statusId}`;
            if (window.location.pathname === '/outbox') {
                this.props.history.push({
                    pathname: `/outbox/${fieldData.firstStatusName}/${processId}/${statusId}`,
                });
            }
            const element = document.getElementById(curr_menu);
            if (element !== null) {
                element.classList.add('root-active');
            }
        }
        this.setState({
            processId: processId,
            statusId: statusId,
            menu_name: menu_name,
        });
        // Set Row data in redux
        this.props.setMenuName(menu_name);
        this.props.clearOutboxState(getDefaultState()); // changed by geetha

        let calingFrom = 'inbox-outbox';
        if (
            this.props.calingFrom !== undefined &&
            this.props.calingFrom === 'search'
        ) {
            this.props.updateList('');
            calingFrom = 'search';
        }
        this.setState({
            calingFrom: calingFrom,
        });

        //this.props.getOwners(processId, statusId);
        //let totalOwner = getOwnersFromApi(processId, statusId);

        this.props.setProcessId(processId);
        this.props.setStatusId(statusId);
        // End

        if (String(processId).trim() !== '' && String(statusId) !== '') {
            let permission = userPermission({
                processId: processId,
                statusId: statusId,
                menu_name: menu_name,
                configData: this.props.configData,
            });

            permission = { ...permission, show_pdf: true, show_notes: true }; // default true

            const config = this.props.configData;
            let result = [];
            if (stringEmptyOrUndefined(config)) {
                result = config.tags !== undefined ? config.tags : [];
            }
            const newTagArray = [];
            result.forEach((tagdata, tagindex) => {
                newTagArray[tagdata.name.toLowerCase()] = tagdata.color;
            });
            this.setState({
                //  ...this.state,
                ...permission,
                newTagColorArray: newTagArray,
            });

            /* this.props.outboxPage({
                 allActions: permission.allActions,
             });*/
            this.props.updateOutboxState({
                allActions: permission.allActions,
            });

            let itemsPerPage = 25;
            if (localStorage.getItem('retainShowAllChecked') === 'true') {
                const countResponse = await getCount(processId, statusId).then(
                    (response) => {
                        return response.json();
                    }
                );

                itemsPerPage =
                    countResponse.count > 0 ? countResponse.count : 25;
            }

            if (window.innerHeight < 825) {
                await this.setState({ perPage: 10 });
            } else {
                await this.setState({
                    perPage: itemsPerPage,
                    queryData: {
                        ...this.state.queryData,
                        pageSize: itemsPerPage,
                    },
                });
            }

            const { queryData, currentPage, filterTxt } = this.state;
            queryData.pageNumber = currentPage + 1;
            queryData.pageSize = itemsPerPage;
            queryData.fullTextSearchString = filterTxt;

            const customFilters = localStorage.getItem(
                `customFilters_${processId}_${statusId}`
            );
            if (customFilters) {
                queryData.filters = customFilters;
            }

            const sortingsObject = localStorage.getItem(
                `sorting_${processId}_${statusId}`
            );
            if (sortingsObject) {
                const storedSortings = JSON.parse(sortingsObject);

                queryData.sortColumn = storedSortings?.sortColumn ?? '';
                queryData.sortOrder = storedSortings?.sortDirection ?? '';
            }

            await this.props.getListaction(
                processId,
                statusId,
                calingFrom,
                queryData
            );
        }
        const titlePage = getPageTitle({ processId, statusId });
        this.setState({ pageTitle: titlePage });

        document.addEventListener('mousedown', handleClickOutside);
        // Fixes for Mobile Responsive issue
        const asideOn = document.getElementById('kt_aside_mobile_toggle');
        if (asideOn !== null) {
            asideOn.addEventListener('click', () => {
                const ktAside = document.getElementById('kt_aside');
                if (ktAside !== null) {
                    ktAside.classList.add('aside-on');
                }
            });
        }
        // End
        this.props.outboxPage({
            related_doc_detail: false,
            multiple_edit_page: false,
            single_edit: false,
        });

        const currentPathName = window.location.pathname.split('/');

        if (currentPathName.length > 2) {
            if (currentPathName[2] === 'edit') {
                this.setState({ single_edit: true });
            }
            if (currentPathName[2] === 'detail') {
                this.props.outboxPage({ related_doc_detail: true });
            }
            if (currentPathName[2] === 'editmultiple') {
                this.props.outboxPage({ multiple_edit_page: true });
            }
        }
        await this.getLockedDocIds();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            this.props.location.pathname === '/outbox' &&
            this.props.location.pathname !== this.state.pathName
        ) {
            this.setState({
                single_edit: false,
                pathName: this.props.location.pathname,
                row_transition: false,
                detailBigView: false,
            });
            await this.props.outboxPage({
                related_doc_detail: false,
                multiple_edit_page: false,
                single_edit: false,
            });
            //this.refreshTable()
            // this.componentDidMount()
            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
        }
        if (this.props.location.pathname !== this.state.pathName) {
            this.setState({
                pathName: this.props.location.pathname,
            });
        }
        let element;
        if (this.props.match.params.menuName !== 'hold-for-signature') {
            if (document.getElementById('right-aside')) {
                element = document.getElementById('right-aside');
                element.classList.remove('statistics');
            }
        }
        let processId = '';
        let statusId = '';
        let menu_name = '';
        const fieldData = GetFieldData(
            { configData: this.props.configData },
            'Outbox'
        );
        if (this.props.match.params.menuName !== undefined) {
            menu_name = this.props.match.params.menuName;
        }
        if (this.props.match.params.processId !== undefined) {
            processId = this.props.match.params.processId;
        }
        if (this.props.match.params.statusId !== undefined) {
            statusId = this.props.match.params.statusId;
        }
        if (String(processId).trim() === '' && String(statusId).trim() === '') {
            if (this.props.processId !== undefined)
                processId = this.props.processId;
            else processId = fieldData.firstProcessId;

            if (this.props.statusId !== undefined)
                statusId = this.props.statusId;
            else statusId = fieldData.firstStatusId;

            // processId = fieldData.firstProcessId;
            // statusId = fieldData.firstStatusId;
            const curr_menu = `menu_p_${processId}_s_${statusId}`;
            if (window.location.pathname === '/outbox') {
                await this.props.history.push({
                    pathname: `/outbox/${fieldData.firstStatusName}/${processId}/${statusId}`,
                    // pathname: `/inbox/intake/1/7`
                });
            }
            // const curr_menu = 'menu_p_' + processId + '_s_' + statusId;
            const element = document.getElementById(curr_menu);
            if (element !== null) {
                element.classList.add('root-active');
            }
        }

        const filterKey = `searchFilter_${processId}_${statusId}`;
        const savedFilter = localStorage.getItem(filterKey) ?? '';

        if (savedFilter !== this.state.filterTxt) {
            this.setState({ filterTxt: savedFilter });
        }

        // UpDate State
        if (String(prevProps.stateToken) !== String(this.props.stateToken)) {
            const requiredProperty = {};
            for (const property in GridState) {
                if (this.props.stateData[property] !== undefined) {
                    requiredProperty[property] = this.props.stateData[property];
                }
            }
            this.setState({
                //...this.state,
                ...requiredProperty,
            });
        }

        if (
            prevProps.match.params.menuName !== this.props.match.params.menuName
        ) {
            this.setState({
                statistics_display: false,
            });
        }

        // Refresh Cond
        if (
            this.props.loading === false &&
            prevState.displayAnimation === true
        ) {
            this.setState({ displayAnimation: false });
        }

        if (
            this.props.match.params.statusId &&
            this.state.statusId &&
            String(this.props.match.params.statusId) !==
                String(this.state.statusId) &&
            this.props.related_doc_detail !== true &&
            this.props.multiple_edit_page !== true &&
            String(this.props.location.pathname).includes('editmultiple') !==
                true
        ) {
            //need to check if any drop down data empty - changed by Aiswarya 12/04/2023
            //await this.getTransitonDropDownData();

            this.setState({
                processId: processId,
                statusId: statusId,
                show_owner: false,
                show_tag: false,
            });
            if (
                String(processId).trim() !== '' &&
                String(statusId).trim() !== ''
            ) {
                let permission = userPermission({
                    processId: processId,
                    statusId: statusId,
                    menu_name: menu_name,
                    configData: this.props.configData,
                });
                permission = {
                    ...permission,
                    show_pdf: true,
                    show_notes: true,
                }; // default true

                let itemsPerPage = 25;
                if (
                    localStorage.getItem('retainShowAllChecked') === 'true' &&
                    processId &&
                    statusId
                ) {
                    const countResponse = await getCount(
                        processId,
                        statusId
                    ).then((response) => {
                        return response.json();
                    });

                    itemsPerPage =
                        countResponse.count > 0 ? countResponse.count : 25;
                }

                this.setState(
                    {
                        filterTxt: '',
                        dataGridReferesh: true,
                        // ...this.props.outbox, //hidden by geetha
                        ...permission,
                        row_transition: false,
                        selectedRows: new Set(),
                        detailBigView: false,
                        dataGridReferesh: true,
                        filterTxt: localStorage.getItem(filterKey) ?? '',
                        perPage: itemsPerPage,
                        queryData: {
                            ...this.state.queryData,
                            pageSize: itemsPerPage,
                        },
                    },
                    () => {
                        // Set Row data in redux
                        this.props.updateList(''); //hidden by geetha
                        this.props.setMenuName(menu_name);
                        this.props.clearOutboxState({
                            groupBy: [],
                            groupByData: {},
                            filterTxt: localStorage.getItem(filterKey) ?? '',
                            perPage: itemsPerPage,
                            currentPage: 0,
                            totalCount: 0,
                            sortDirection: 'NONE',
                            sortColumn: '',
                            selectedRows: new Set(),
                            rowData: [],
                            filteredRowData: [],
                            header: [],
                            expandedRow: new Set(),
                            expandedGroupIds: new Set(),
                            tempExpandedGroupIds: new Set(),
                            uniqueData: {},
                            row_transition: false,
                            rowClickClass: [],
                        });

                        const customFilters = localStorage.getItem(
                            `customFilters_${processId}_${statusId}`
                        );
                        const queryData = { ...this.state.queryData };
                        if (customFilters) {
                            queryData.filters = customFilters;
                        }

                        const sortingsObject = localStorage.getItem(
                            `sorting_${processId}_${statusId}`
                        );
                        if (sortingsObject) {
                            const storedSortings = JSON.parse(sortingsObject);

                            queryData.sortColumn =
                                storedSortings?.sortColumn ?? '';
                            queryData.sortOrder =
                                storedSortings?.sortDirection ?? '';
                        }

                        this.props.getListaction(
                            processId,
                            statusId,
                            this.state.calingFrom,
                            queryData
                        );
                        RefreshCounts(processId, statusId, this.props);
                        //this.props.getOwners(processId, statusId);
                        //let ownerData = getOwnersFromApi(processId, statusId);
                        this.props.outboxPage({
                            rowID: '',
                        });
                        this.props.setProcessId(processId);
                        this.props.setStatusId(statusId);

                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                        // End
                    }
                );
            } else {
                this.setState({
                    show_owner: true,
                    show_tag: true,
                    show_delete: true,
                    processId: processId,
                    statusId: statusId,
                });
            }

            const titlePage = getPageTitle({ processId, statusId });
            this.setState({ pageTitle: titlePage, menu_name: menu_name });
        }
    }

    getLockedDocIds = async () => {
        try {
            let docIds = [];
            const response = await getLockDocuments();
            if (response.length) {
                response.forEach((item) => {
                    docIds.push(item.itemNum);
                });
                this.setState({ lockedDocIds: docIds });
            }
        } catch (e) {
            console.error(e);
        }
    };

    setLockBarVisibility = (isVisible) => {
        this.setState({ lockBarVisible: isVisible });
    };

    getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });

        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('deliverymethod')) {
                getDropDownDataFromApi(fieldData.delivery_method).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
            // API call condition
            if (!this.props.ddl.hasOwnProperty('emrordertype')) {
                getDropDownDataFromApi(fieldData.emr_order_type).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
            // EMR order type
            if (!this.props.ddl.hasOwnProperty('coverpage')) {
                getDropDownDataFromApi(fieldData.cover_page).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            }

            // EMR order type ends
            // Tags type
            getDropDownDataFromApi(fieldData.tags).then((data) => {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
            if (!this.props.ddl.hasOwnProperty('owner')) {
                getDropDownDataFromApi(fieldData.owner).then(async (data) => {
                    if (data.values) {
                        let owners = await getOwnersToOutbox(data.values);
                        await localStorage.setItem(
                            'owner_bg_color_outbox',
                            JSON.stringify(owners)
                        );

                        await this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]: owners,
                        });
                    }
                });
            }
        }
    }
    async componentWillUnmount() {
        document.removeEventListener('mousedown', handleClickOutside);
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');

        const element = document.getElementById('right-aside');
        if (element !== null) {
            element.classList.remove('statistics');
        }
        // below line added for related document unproper close
        await this.props.outboxPage({
            related_doc_detail: false,
            multiple_edit_page: false,
            single_edit: false,
        });
        this._isMounted = false;
    }

    /*
    updateState = (state) => {
        this.setState({ ...state });
    }; */

    async configurationApiCall() {
        var customSearchQueries = [];
        customSearchQueries =
            this.props.configData.customSearchNamedIdsNeedingConfiguration;

        if (customSearchQueries !== undefined) {
            await customSearchQueries.map((data) => {
                if (data.name === 'By Batch') {
                    this.configurationCustomeSearchId(data.id);
                }
                if (data.name === 'By Order') {
                    this.configurationCustomeSearchId(data.id);
                }
            });
        }
        if (
            this.props.configData.recordsetNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                (data) => {
                    if (data.name === 'Order Data') {
                        this.configurationRecordSet(data.id);
                    }
                    if (data.name === 'Patient Data Modify') {
                        this.configurationRecordSet(data.id);
                    }
                    if (data.name === 'Order Data Modify') {
                        this.configurationRecordSet(data.id);
                    }

                    if (data.name === 'Branch Data') {
                        this.configurationRecordSet(data.id);
                    }
                    if (data.name === 'Episode Data') {
                        this.configurationRecordSet(data.id);
                    }
                    if (data.name === 'Episode Visit Data') {
                        this.configurationRecordSet(data.id);
                    }
                    if (data.name === 'Physicians By Location') {
                        this.configurationRecordSet(data.id);
                    }
                    if (data.name === 'Locations By Physician Standard') {
                        this.configurationRecordSet(data.id);
                    }
                }
            );
        }
    }

    onRowClick = async (rowDetails, rowHeader, stateData) => {
        // let stateDataParam = stateData;
        /* this.props.outboxPage({
             stateData,
          //   stateData: stateData,
           //  docOpen: false,
         });   */

        const docid = rowDetails.doc_id;

        document.body.classList.add('doc-small-view');
        this.setLockBarVisibility(false);
        if (String(rowHeader.key) === 'tags') {
            stateData.filteredRowData.map((rows, index) => {
                if (String(rows.doc_id) === String(docid)) {
                    this.state.tagIndex = index;
                }
                return rows;
            });
            this.setState(
                {
                    rowData: stateData.rowData,
                    selectedTagDocId: docid,
                    selected_tag: rowDetails.tags_data,
                    tagIndex: this.state.tagIndex,
                    selectedColumn: rowHeader.key,
                    showData: true,
                    reInitiateGrid: false,
                },
                () => {
                    this.props.updateOutboxState({
                        tempExpandedGroupIds: stateData.tempExpandedGroupIds,
                        customGroupedData: stateData.customGroupedData,
                        expandedGroupIds: stateData.expandedGroupIds,
                        groupByData: stateData.groupByData,
                        groupByIndex: stateData.groupByIndex,
                        groupBy: stateData.groupBy,
                        sortDirection: stateData.sortDirection,
                        sortColumn: stateData.sortColumn,
                        statictics: stateData.statictics,

                        row_transition: stateData.row_transition,
                        // refreshDataGrid: stateData.refreshDataGrid,
                        //  reInitiateGrid: stateData.reInitiateGrid,
                        modifiedColumnsNames: stateData.modifiedColumnsNames,
                        header: stateData.header,
                        currentStateMenuName: stateData.currentStateMenuName,
                        dateFormatFields: stateData.dateFormatFields,
                        totalColumn: stateData.totalColumn,
                        totalCount: stateData.totalCount,
                        tableDesign: stateData.tableDesign,
                        uniqueData: stateData.uniqueData,
                        perPage: stateData.perPage,
                        currentPage: stateData.currentPage,
                        filterTxt: stateData.filterTxt,

                        // ...stateData,
                        filteredRowData: stateData.filteredRowData,
                        rowData: stateData.rowData,
                        selectedColumn: rowHeader.key,
                        selectedTagDocId: docid,
                        selected_tag: rowDetails.tags_data,
                        tagIndex: this.state.tagIndex,
                        showData: true,
                        reInitiateGrid: false,
                    });
                }
            );
            this.setState({
                tagRefresh: true,
            });
            return true;
        }
        if (String(rowHeader.key) === 'owner') {
            const docid = rowDetails.doc_id;
            await this.setState(
                {
                    //onRowClickId: rowDetails.doc_id,
                    //rowClickClass: [rowDetails.id],
                    menu_branch: '',
                    menu_group: '',
                    menu_inbox: '',

                    // rowDetails: rowDetails,
                    reInitiateGrid: false,
                    showData: true,
                    selectedColumn: rowHeader.key,
                },
                async () => {
                    await this.handleMultiSelectClose();

                    this.setState(
                        {
                            // row_transition: true,
                            //selectedDocId: docid,
                            selectedRows: new Set(),
                        },
                        () => {
                            this.props.updateOutboxState({
                                // ...stateData,
                                //  stateDataParam, // removed by geetha since duplicate param

                                tempExpandedGroupIds:
                                    stateData.tempExpandedGroupIds,
                                customGroupedData: stateData.customGroupedData,
                                expandedGroupIds: stateData.expandedGroupIds,
                                groupByData: stateData.groupByData,
                                groupByIndex: stateData.groupByIndex,
                                groupBy: stateData.groupBy,
                                sortDirection: stateData.sortDirection,
                                sortColumn: stateData.sortColumn,
                                statictics: stateData.statictics,

                                row_transition: stateData.row_transition,
                                // refreshDataGrid: stateData.refreshDataGrid,
                                // reInitiateGrid: stateData.reInitiateGrid,
                                modifiedColumnsNames:
                                    stateData.modifiedColumnsNames,
                                header: stateData.header,
                                currentStateMenuName:
                                    stateData.currentStateMenuName,
                                dateFormatFields: stateData.dateFormatFields,
                                totalColumn: stateData.totalColumn,
                                totalCount: stateData.totalCount,
                                tableDesign: stateData.tableDesign,
                                uniqueData: stateData.uniqueData,
                                filteredRowData: stateData.filteredRowData,
                                rowData: stateData.rowData,
                                perPage: stateData.perPage,
                                currentPage: stateData.currentPage,
                                filterTxt: stateData.filterTxt,

                                //   selectedDocId: docid,
                                menu_branch: this.state.menu_branch,
                                menu_group: this.state.menu_group,
                                is_locked_by: this.state.is_locked_by,
                                noteCount: this.state.noteCount,
                                pageCount: this.state.pageCount,

                                //onRowClickId: this.state.onRowClickId,
                                //rowClickClass: this.state.rowClickClass,
                                // rowDetails: this.state.rowDetails,
                                //reInitiateGrid: this.state.reInitiateGrid,
                                showData: this.state.showData,
                                selectedColumn: this.state.selectedColumn,
                                selectedRows: new Set(),
                            });
                        }
                    );
                }
            );

            if (
                this.props.outbox.selectedRow === undefined ||
                String(this.props.outbox.selectedRow) !== String(docid)
            ) {
                this.props.setSelectedRow(docid);
            }

            return;
        }

        if (String(rowHeader.key) === 'select-row') {
            await this.configurationApiCall();

            this.setState({
                showData: false,
                selectedColumn: rowHeader.key,
                reInitiateGrid: false,
            });
            return true;
        } else {
            await this.handleMultiSelectClose();
            await this.configurationApiCall();

            const docid = rowDetails.doc_id;
            let singleStatus = true;
            this.setState(
                {
                    onRowClickId: rowDetails.doc_id,
                    rowClickClass: [rowDetails.id],
                    menu_branch: '',
                    menu_group: '',
                    menu_inbox: '',
                    //row_transition: '',
                    rowDetails: rowDetails,
                    reInitiateGrid: false,
                    showData: true,
                    selectedColumn: rowHeader.key,
                },
                async () => {
                    document.body.classList.add('aside-minimize');
                    const response = getDocumentMetaWithDocId({ docid });
                    const configData = getConfigurationData();
                    await response.then(async (data) => {
                        if (
                            data !== undefined &&
                            (data.statuses.length === 0 ||
                                !data.statuses.some(
                                    (status) =>
                                        String(status.statusId) ===
                                        String(this.state.statusId)
                                ))
                        ) {
                            showErrorToast(
                                'Document has already been processed. Please refresh.'
                            );
                            this.props.updateOutboxState({
                                row_transition: false,
                            });
                            this.setState({
                                row_transition: false,
                            });
                            document.body.classList.remove('aside-minimize');
                            singleStatus = false;
                            return false;
                        } else {
                            this.props.getSelectedRowDetails(data);
                            await this.checkLock(docid);
                            if (this.state.is_locked !== undefined) {
                                setTimeout(() => {
                                    if (this.state.is_locked === true) {
                                        if (
                                            document.getElementById(
                                                'left-aside'
                                            ) !== null
                                        ) {
                                            document
                                                .getElementById('left-aside')
                                                .classList.remove(
                                                    'no-lock-section'
                                                );
                                        }
                                    } else if (
                                        document.getElementById(
                                            'left-aside'
                                        ) !== null
                                    ) {
                                        document
                                            .getElementById('left-aside')
                                            .classList.add('no-lock-section');
                                    }
                                }, 10);
                            }
                            if (data.miscPproperties.lockedBy !== undefined) {
                                this.setState({
                                    is_locked_by: data.miscPproperties.lockedBy,
                                });
                            }
                            if (data.miscPproperties.noteCount !== undefined) {
                                this.setState({
                                    noteCount: data.miscPproperties.noteCount,
                                });
                            }
                            if (data.miscPproperties.pageCount !== undefined) {
                                this.setState({
                                    pageCount: data.miscPproperties.pageCount,
                                });
                            }
                            const foundFields = [];
                            if (data.fields !== undefined) {
                                data.fields.forEach((dataValue) => {
                                    const fields = dataValue.fieldId;

                                    for (const property in configData) {
                                        if (
                                            String(
                                                configData[property].fieldId
                                            ) === String(fields)
                                        ) {
                                            foundFields.push(
                                                configData[property]
                                            );
                                        }
                                    }
                                });
                            }
                        }
                    });
                    /*  this.props.updateOutboxState({
                          // ...stateData,
                          rowClickClass: this.state.rowClickClass,
                      }); */
                    if (singleStatus === true) {
                        this.setState(
                            {
                                row_transition: true,
                                selectedDocId: docid,
                                selectedRows: new Set(),
                            },
                            () => {
                                this.props.updateOutboxState({
                                    //...stateData,
                                    rowClickClass: this.state.rowClickClass,

                                    tempExpandedGroupIds:
                                        stateData.tempExpandedGroupIds,
                                    customGroupedData:
                                        stateData.customGroupedData,
                                    expandedGroupIds:
                                        stateData.expandedGroupIds,
                                    groupByData: stateData.groupByData,
                                    groupByIndex: stateData.groupByIndex,
                                    groupBy: stateData.groupBy,
                                    sortDirection: stateData.sortDirection,
                                    sortColumn: stateData.sortColumn,
                                    statictics: stateData.statictics,

                                    modifiedColumnsNames:
                                        stateData.modifiedColumnsNames,
                                    header: stateData.header,
                                    currentStateMenuName:
                                        stateData.currentStateMenuName,
                                    dateFormatFields:
                                        stateData.dateFormatFields,
                                    totalColumn: stateData.totalColumn,
                                    totalCount: stateData.totalCount,
                                    tableDesign: stateData.tableDesign,
                                    uniqueData: stateData.uniqueData,
                                    filteredRowData: stateData.filteredRowData,
                                    rowData: stateData.rowData,
                                    perPage: stateData.perPage,
                                    currentPage: stateData.currentPage,
                                    allActions: stateData.allActions,
                                    filterTxt: stateData.filterTxt,

                                    row_transition: true,
                                    selectedDocId: docid,
                                    // menu_branch: this.state.menu_branch,
                                    // menu_group: this.state.menu_group,
                                    is_locked_by: this.state.is_locked_by,
                                    noteCount: this.state.noteCount,
                                    pageCount: this.state.pageCount,
                                    onRowClickId: this.state.onRowClickId,
                                    rowClickClass: this.state.rowClickClass,
                                    rowDetails: rowDetails,
                                    // reInitiateGrid: this.state.reInitiateGrid,
                                    showData: this.state.showData,
                                    selectedColumn: this.state.selectedColumn,
                                    selectedRows: new Set(),
                                });
                            }
                        );
                    }

                    if (this.state.is_locked !== true) {
                        await lockDocument(docid);
                    }
                }
            );
        }

        const elem = document.getElementById('last-pos');
        if (elem !== null) {
            elem.parentElement.removeChild(elem);
        }
        //  this.props.setSelectedRow(docid);

        //this.rowAction(docid, rowDetails, rowHeader, stateData);
        // stateData - its a set of State varaible from DataGrid Component
        // const response = await getDocumentMetaWithDocId({ docid })

        // .then((data) => {
        //
        //    if (
        //        data.miscPproperties?.isLocked === true
        //    ) {
        //        showErrorToast('Unable to obtain document lock');
        //     //   return true;
        //    } else {
        //     this.rowAction(docid, rowDetails, rowHeader, stateData)
        //
        //    }
        // })

        // if (response.miscPproperties.isLocked) {
        //     showErrorToast('Unable to obtain document lock');
        // } else {

        // showSuccessToast('sample 111111111111111111');
        // }

        // if (
        //     response.miscPproperties?.isLocked === true
        // ) {
        //     showErrorToast('Unable to obtain document lock');
        //  //   return true;
        // } else {
        //  this.rowAction(docid, rowDetails, rowHeader, stateData)
    };

    async refreshTable() {
        const processId = this.state.processId;
        const statusId = this.state.statusId;
        await this.props.updateList('');
        const filterKey = `searchFilter_${processId}_${statusId}`;
        const savedFilter = localStorage.getItem(filterKey) ?? '';

        this.setState(
            {
                rowData: [],
                filteredRowData: [],
                currentPage: 0,
                perPage: 25,
                sortDirection: 'NONE',
                sortColumn: '',
                filterTxt: savedFilter,
                groupBy: [],
                rowClickClass: [],
                groupByData: {},
                tempExpandedGroupIds: new Set(),
                expandedGroupIds: new Set(),
                selectedRows: new Set(),
                displayAnimation: true,
                showLoader: true,
                row_transition: false,
                openedRowId: {},
                statistics_display: false,
            },
            () => {
                document.body.classList.remove('aside-minimize');
                // document.body.classList.add('small-view-display');
                // document.body.classList.remove('doc-detail-view');
                // modififed by geetha ..state
                this.props.clearOutboxState({
                    rowData: [],
                    filteredRowData: [],
                    currentPage: 0,
                    perPage: 25,
                    sortDirection: 'NONE',
                    sortColumn: '',
                    filterTxt: savedFilter,
                    groupBy: [],
                    rowClickClass: [],
                    groupByData: {},
                    tempExpandedGroupIds: new Set(),
                    expandedGroupIds: new Set(),
                    selectedRows: new Set(),
                    displayAnimation: true,
                    showLoader: true,
                    row_transition: false,
                    openedRowId: {},
                    statistics_display: false,
                });

                this.props.getListaction(
                    processId,
                    statusId,
                    this.state.calingFrom,
                    this.state.queryData
                );
            }
        );
        const count = [];
        const oldCount = this.props.count;

        getProcessCount(processId)
            .then((response) => {
                // status = response.status;
                return response.json();
            })
            .then(async (data) => {
                count.push(data);
                await oldCount.map((old_count) => {
                    if (
                        data.process !== undefined &&
                        old_count.process !== undefined &&
                        old_count.process.processId !== data.process.processId
                    ) {
                        count.push(old_count);
                    }
                    return old_count;
                });
                this.props.getCountData(count);
            });
        await getCount(processId, statusId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('refresh_count', data.count);
                let countData = localStorage.getItem(
                    `CountData${data.processId}`
                );
                if (countData !== null) {
                    let totalCount = 0;
                    countData = JSON.parse(countData);
                    if (countData.statuses !== undefined) {
                        countData.statuses.map((arrayval) => {
                            if (
                                arrayval.processId === data.processId &&
                                arrayval.statusId === data.statusId
                            ) {
                                arrayval.count = data.count;
                            }
                            totalCount += arrayval.count;
                            return arrayval;
                        });
                    }
                    if (countData.process !== undefined) {
                        countData.process.count = totalCount;
                    }
                    localStorage.setItem(
                        `CountData${data.processId}`,
                        JSON.stringify(countData)
                    );
                }
            });
        await this.props.outboxPage({
            rowID: '',
        });

        await this.getLockedDocIds();
        await this.props.history.push(window.location.pathname);
    }

    openDeletePopUp() {
        const selectedRows = Array.from(this.state.selectedRows);
        let rowsToDelete = this.state.rowData.filter((data) => {
            return selectedRows.indexOf(data.id) !== -1;
        });
        if (rowsToDelete.length === 0 && this.state.rowClickClass.length > 0) {
            rowsToDelete = this.state.rowData.filter((data) => {
                return this.state.onRowClickId === data.doc_id;
            });
        }
        this.setState({
            openDelete: true,
            rowsToDelete: rowsToDelete,
            deleteComments: [],
        });
    }

    closeDeletePopUp(param) {
        const { removeRow, rowsToDelete } = param;
        if (removeRow !== undefined && removeRow === true) {
            if (removeRow && rowsToDelete.length > 0) {
                const docIds = [];
                rowsToDelete.forEach((data) => {
                    docIds.push(data.doc_id);
                });
                const rowData = this.state.rowData.filter((data) => {
                    return !(docIds.indexOf(data.doc_id) !== -1);
                });
                const filteredRowData = this.state.filteredRowData.filter(
                    (data) => {
                        return !(docIds.indexOf(data.doc_id) !== -1);
                    }
                );
                this.setState(
                    {
                        openDelete: false,
                        selectedRows: new Set(),
                        selectedIndexes: [],
                        rowsToDelete: [],
                        rowData: rowData,
                        filteredRowData: filteredRowData,
                        row_transition: false,
                    },
                    () => {
                        //modified by geetha ...state
                        this.props.updateOutboxState({
                            openDelete: false,
                            selectedRows: new Set(),
                            selectedIndexes: [],
                            rowsToDelete: [],
                            rowData: rowData,
                            filteredRowData: filteredRowData,
                            row_transition: false,
                        });

                        this.props.getListaction(
                            this.state.processId,
                            this.state.statusId,
                            this.state.calingFrom,
                            this.state.queryData
                        );
                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                    }
                );
            }
            // this.props.showLoader(true);
            return;
        }
        this.setState({
            openDelete: false,
        });
    }

    entering = (e) => {
        e.children[0].style.borderTopColor = 'rgb(0, 35, 58)';
        e.children[1].style.backgroundColor = 'rgb(0, 35, 58)';
    };

    statisticsOpen = async () => {
        await this.setState(
            {
                statistics_display: !this.state.statistics_display,
            },
            () => {
                if (this.state.statistics_display === true) {
                    const element = document.getElementById('right-aside');
                    element.classList.add('statistics');

                    // var result = parseInt(window.innerHeight) + parseInt(document.getElementById('search-top').offsetHeight) + parseInt(document.getElementById('Statistic-part').offsetHeight);
                    // document.getElementById('right-aside').height = result;
                }
                if (this.state.statistics_display === false) {
                    const element = document.getElementById('right-aside');
                    element.classList.remove('statistics');
                    // document.getElementById('right-aside').height = window.innerHeight - 158;
                }
            }
        );
        if (this.state.statistics_display === false) {
            this.setState({
                statisticsTable: true,
            });
        }
    };

    enableLoader(value) {
        this.setState({ blur_spinner: value });
    }

    async restrictFilterDataGrid(searchText = '') {
        const isValid = (str) => {
            // eslint-disable-next-line no-useless-escape
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText.trim()) === false) {
            return [];
        } else if (this?.state?.processId && this?.state?.statusId) {
            const { processId, statusId } = this.state;
            const filterKey = `searchFilter_${processId}_${statusId}`;
            const { queryData, currentPage, perPage } = this.state;
            queryData.pageNumber = currentPage + 1;
            queryData.pageSize = perPage;
            queryData.fullTextSearchString = searchText;

            this.props.getListaction(
                processId,
                statusId,
                this.state.calingFrom,
                queryData
            );

            localStorage.setItem(filterKey, searchText);
            this.setState({ filterTxt: searchText });
        }
    }
    rowTransition = async () => {
        this.setState(
            {
                row_transition: false,
                detailBigView: false,
            },
            () => {
                this.props.updateOutboxState({
                    // ...this.state,  // removed by geetha..
                    row_transition: false,
                    detailBigView: false,
                });
            }
        );
    };
    lockedStatus = async () => {
        this.setState(
            {
                is_locked: true,
            },
            () => {
                this.props.updateOutboxState({
                    is_locked: true,
                });
            }
        );
    };

    handleMultiSelectClose = () => {
        removeCheckboxClass();
        this.setState({
            selectedRows: new Set(),
        });
        this.setState({
            close_tagmenu: false,
            open_addtag: false,
            open_replacetag: false,
            open_deletetag: false,
            menu_selected_tagonly: [],
            menu_selected_tag: [],
        });
    };

    openBigView = () => {
        this.setState({
            detailBigView: true,
        });
    };

    openDetailView = () => {
        this.setState({
            open_edit_details: true,
            detailBigView: true,
        });
    };

    onclose_tag = (event) => {
        if (event === false && this.state.tag_action === true) {
            this.setState({ selectedRows: new Set() });
        }
    };

    closeBigView = () => {
        this.setState({ detailBigView: false });
        //           this.props.updateOutboxState({
        //         ...this.state,
        //         detailBigView: false,
        //     });
    };

    editDetails = () => {
        this.setState({ edit_details: true });
    };

    refreshTag = async (smallView) => {
        this.setState({ tagLoader: true });

        this.setState({ refresh_table: false });
        this.setState({ refresh_table: true });
    };

    onSelectedRowsChange(rows, gridStateData) {
        const selectedRowData = getDocumentIdFromArray(
            gridStateData.filteredRowData,
            Array.from(rows)
        );
        const pushEditId = [];
        selectedRowData.forEach((v, i) => {
            pushEditId.push(v.doc_id);
        });
        const selectedRows = rows;

        this.setState(
            {
                selectedRows: selectedRows,
                printSelectedDocId: [],
                close_tagmenu: false,
                open_addtag: true,
                open_replacetag: false,
                open_deletetag: false,
                menu_selected_tagonly: [],
                menu_selected_tag: [],
                detailBigView: false,
                editId: pushEditId,
            },

            () => {
                this.props.updateOutboxState({
                    // comment by geetha for memory optimization
                    //...this.state,
                    //  ...gridStateData,
                    tempExpandedGroupIds: gridStateData.tempExpandedGroupIds,
                    customGroupedData: gridStateData.customGroupedData,
                    expandedGroupIds: gridStateData.expandedGroupIds,
                    groupByData: gridStateData.groupByData,
                    groupByIndex: gridStateData.groupByIndex,
                    groupBy: gridStateData.groupBy,
                    sortDirection: gridStateData.sortDirection,
                    sortColumn: gridStateData.sortColumn,
                    statictics: gridStateData.statictics,

                    refreshDataGrid: gridStateData.refreshDataGrid,
                    reInitiateGrid: gridStateData.reInitiateGrid,
                    modifiedColumnsNames: gridStateData.modifiedColumnsNames,
                    header: gridStateData.header,
                    currentStateMenuName: gridStateData.currentStateMenuName,
                    dateFormatFields: gridStateData.dateFormatFields,
                    totalColumn: gridStateData.totalColumn,
                    totalCount: gridStateData.totalCount,
                    tableDesign: gridStateData.tableDesign,
                    uniqueData: gridStateData.uniqueData,
                    filteredRowData: gridStateData.filteredRowData,
                    perPage: gridStateData.perPage,
                    currentPage: gridStateData.currentPage,
                    filterTxt: gridStateData.filterTxt,

                    filteredData: gridStateData.filteredRowData,
                    rowData: gridStateData.rowData,

                    allActions: gridStateData.allActions,
                    selectedRows: selectedRows,
                    printSelectedDocId: [],
                    close_tagmenu: false,
                    open_addtag: false,
                    open_replacetag: false,
                    open_deletetag: false,
                    menu_selected_tagonly: [],
                    menu_selected_tag: [],
                    detailBigView: false,
                    editId: pushEditId,
                });
            }
        );

        setTimeout(() => {
            // if (
            //     (this.state.row_transition === true ||
            //         this.state.row_transition === '') &&
            //     document.getElementById('inbox_bottom_menu')
            // ) {
            //     var element = document.getElementById('inbox_bottom_menu');
            //     element.classList.add('sticky');
            // }
            // if (
            //     this.state.row_transition === false &&
            //     this.state.filteredRowData.length <= 10 &&
            //     document.getElementById('inbox_bottom_menu')
            // ) {
            //     var element = document.getElementById('inbox_bottom_menu');
            //     element.classList.remove('sticky');
            // }
        }, 250);
    }

    resetBigView = () => {
        this.rowTransition();
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        this.refreshTable();
    };

    refreshForNextDocument = (docid) => {
        this.setState({ row_transition: false, detailBigView: false }, () => {
            this.setState({
                nextDocument: true,
                selectedDocId: docid,
                row_transition: true,
                detailBigView: true,
            });
        });
    };

    handleStatisticsOnChange = (currentStatistics) => {
        this.setState({ currentStatistics: currentStatistics });
    };

    checkLock = async (docid) => {
        await this.getLockedDocIds();
        const isLocked = this.state.lockedDocIds.includes(docid);
        this.setIsLocked(isLocked);
    };

    setIsLocked = (value) => {
        this.setState({ is_locked: value });
    };

    unlockDocument = async () => {
        await this.getLockedDocIds();
        var updatedList = this.state.lockedDocIds.filter(
            (docId) => docId !== this.state.selectedDocId
        );
        this.setState({
            is_locked: false,
            lockedDocIds: updatedList,
        });
    };

    render() {
        return (
            <>
                <div
                    id='inbox3-overall'
                    className={`toast-container ${this.state.toast_type}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '25%',
                    }}
                />

                <div
                    style={
                        this.state.single_edit === true ||
                        this.props.related_doc_detail === true ||
                        this.props.multiple_edit_page === true
                            ? { display: 'none' }
                            : {}
                    }
                >
                    {this.state.detailBigView === false ? (
                        <div
                            id='right-aside'
                            className='right-aside right-aside-outbox py-5'
                            // style={{ height: (95 * window.innerHeight) / 100 }}
                            // style={
                            //     this.state.currentURL === 'search'
                            //         ? { height: '100%' }
                            //         : window.innerHeight < 700 ?
                            //             { height: window.innerHeight + 170 } :
                            //             { height: window.innerHeight - 158 }
                            // }
                            style={
                                isMobile
                                    ? { height: window.innerHeight - 112 }
                                    : this.state.currentURL === 'search'
                                    ? { height: '100%' }
                                    : { height: window.innerHeight - 158 }
                            }
                        >
                            <div>
                                <div className='d-flex justify-content-between pl-7 pr-7'>
                                    <div
                                        className='d-flex align-items-center'
                                        style={{ float: 'left' }}
                                    >
                                        <h3 className='m-0 title-color'>
                                            {this.props.calingFrom !==
                                                undefined &&
                                            this.props.calingFrom === 'search'
                                                ? this.props.pageTitle
                                                : this.state.pageTitle}
                                        </h3>
                                    </div>
                                    <div
                                        className='d-flex filter_part'
                                        style={{ maxWidth: 500 }}
                                    >
                                        <div
                                            style={{ float: 'left' }}
                                            className='filter normal_view_filter'
                                        >
                                            <SearchFilter
                                                inputProps={{
                                                    maxLength: 50,
                                                    disabled:
                                                        this.state
                                                            .row_transition,

                                                    style: this.state
                                                        .row_transition
                                                        ? {
                                                              cursor: 'not-allowed',
                                                          }
                                                        : {
                                                              paddingRight:
                                                                  '34px',
                                                          },
                                                }}
                                                disabled={
                                                    this.state.row_transition
                                                }
                                                value={this.state.filterTxt}
                                                callBack={(text) =>
                                                    this.restrictFilterDataGrid(
                                                        text
                                                    )
                                                }
                                            />
                                        </div>

                                        <span
                                            className='align-self-strich reload'
                                            onClick={() => this.refreshTable()}
                                        >
                                            {' '}
                                            <i
                                                title='Refresh'
                                                className={
                                                    this.state.displayAnimation
                                                        ? 'fas fa-sync-alt refreshing'
                                                        : 'fas fa-sync-alt'
                                                }
                                            />{' '}
                                        </span>

                                        {this.props.match.params.menuName ===
                                            'hold-for-signature' ||
                                        this.props.showStatistics ? (
                                            <div
                                                className={
                                                    this.state
                                                        .statistics_display ===
                                                    true
                                                        ? 'align-self-strich reload-outer active'
                                                        : 'align-self-strich reload-outer'
                                                }
                                                onClick={() => {
                                                    this.statisticsOpen();
                                                }}
                                            >
                                                <span
                                                    className={
                                                        this.state
                                                            .statistics_display
                                                            ? 'reload active'
                                                            : 'reload'
                                                    }
                                                >
                                                    <i className='fas fa-chart-line' />
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='mobile_filter pl-7 pr-7 my-4'>
                                    <div className='filter'>
                                        <input
                                            type='text'
                                            placeholder='Filter'
                                            value={this.state.filterTxt}
                                            onChange={(event) =>
                                                this.setState({
                                                    filterTxt:
                                                        event.target.value,
                                                })
                                            }
                                            maxLength={20}
                                            disabled={this.state.row_transition}
                                            style={
                                                this.state.row_transition
                                                    ? { cursor: 'not-allowed' }
                                                    : { paddingRight: '34px' }
                                            }
                                        />
                                        <i className='fa-regular fa-magnifying-glass' />
                                    </div>
                                    {/* {this.props.match.params.menuName ===
                                'hold-for-signature' ? (
                                    <i className='fas fa-chart-line'></i>
                                ) : null} */}
                                </div>
                                {/* <hr /> */}
                                {/* statistics starts */}
                                {(this.props.match.params.menuName ===
                                    'hold-for-signature' ||
                                    this.props.showStatistics) &&
                                this.state.statistics_display === true ? (
                                    <>
                                        <SpinnerTwo
                                            show={this.state.main_spinner}
                                            filteredData={
                                                this.props.filteredData
                                            }
                                        />
                                        <Statistics display />
                                    </>
                                ) : null}
                                {/* statistics ends */}
                                {this.state.printLoader ? (
                                    <div className='grouping-loader'>
                                        {' '}
                                        <Spinner
                                            show={this.state.printLoader}
                                        />{' '}
                                    </div>
                                ) : null}
                                <div className='mt-5'>
                                    <SpinnerTwo
                                        show={this.state.main_spinner}
                                    />
                                    {this.state.dataGridReferesh === true && (
                                        <DataGridComponent
                                            detailBigViewExpandObj={{
                                                data: this.state
                                                    .detailBigViewExpand,
                                                setData: (value) => {
                                                    this.setState({
                                                        detailBigViewExpand:
                                                            value,
                                                    });
                                                },
                                            }}
                                            filterTxt={this.state.filterTxt}
                                            tableDesign={this.state.tableDesign}
                                            currentPage={this.state.currentPage}
                                            perPage={this.state.perPage}
                                            statisticsTable={
                                                this.state.statisticsTable
                                            }
                                            //  {...this.state} //comment by geetha, added direct params
                                            onRowClick={this.onRowClick.bind(
                                                this
                                            )}
                                            onSelectedRowsChange={this.onSelectedRowsChange.bind(
                                                this
                                            )}
                                            isOutboxPage
                                            statistics_display={
                                                this.state.statistics_display
                                            }
                                            fromSearch={this.props.calingFrom}
                                            query={this.props.query}
                                            row_transition={
                                                this.state.row_transition
                                            }
                                            dataGridReferesh={
                                                this.state.dataGridReferesh
                                            }
                                            detailBigView={
                                                this.state.detailBigView
                                            }
                                            tagPopup={this.state.tagPopup}
                                            updateState={(state) => {
                                                this.setState({ ...state });
                                            }}
                                            allActions={this.state.allActions}
                                            owenerRow={this.state.owenerRow}
                                            tagRefresh={this.state.tagRefresh}
                                            selected_tag={
                                                this.state.selected_tag
                                            }
                                            selectedTagDocId={
                                                this.state.selectedTagDocId
                                            }
                                            pdfPrintRef={this.pdfPrintRef}
                                            rowTransition={() => {
                                                this.rowTransition();
                                            }}
                                            lockedStatus={() => {
                                                this.lockedStatus();
                                            }}
                                            lockedDocIds={
                                                this.state.lockedDocIds
                                            }
                                            lockBarVisible={
                                                this.state.lockBarVisible
                                            }
                                            setLockBarVisibility={
                                                this.setLockBarVisibility
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            {/* statistics end */}
                        </div>
                    ) : null}
                    {this.state.openDelete ? (
                        <DeleteModal
                            openDelete={this.state.openDelete}
                            rowsToDelete={this.state.rowsToDelete}
                            closeDeletePopUp={this.closeDeletePopUp.bind(this)}
                            processId={this.state.processId}
                            statusId={this.state.statusId}
                            deleteActionId={this.state.deleteActionId}
                            branchData={this.state.branches}
                        />
                    ) : null}
                    {this.state.row_transition === true ? (
                        <div
                            id='left-aside'
                            className={
                                this.state.detailBigView &&
                                this.state.is_locked === false
                                    ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                    : this.state.is_locked &&
                                      this.state.detailBigView
                                    ? 'left-aside py-9 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                    : this.state.is_locked
                                    ? 'left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                                    : 'left-aside py-5 rounded-lg aside-fixed flex-row-auto'
                            }
                        >
                            <OutboxSmallView
                                pdfPrintRef={this.pdfPrintRef}
                                calingFrom='smallView'
                                nextDocument={this.state.nextDocument}
                                resetNextDoc={() => {
                                    this.setState({ nextDocument: false });
                                }}
                                selectedDocId={this.state.selectedDocId}
                                username={username}
                                dispname={dispname}
                                notesVal={this.state.notesVal}
                                openDeletePopUp={() => {
                                    this.openDeletePopUp.call(this);
                                }}
                                statusArray={statusArray}
                                rowTransition={() => {
                                    this.rowTransition();
                                }}
                                selectedGroup={this.set_group}
                                openBigView={() => {
                                    this.openBigView();
                                }}
                                openDetailView={() => {
                                    this.openDetailView();
                                }}
                                notesDDL={JSON.parse(
                                    localStorage.getItem('noteTypes')
                                )}
                                isLocked={this.state.is_locked}
                                lockedBy={this.state.is_locked_by}
                                closeBigView={() => {
                                    this.closeBigView();
                                }}
                                rowData={this.state.rowData}
                                rowDetails={this.state.rowDetails} //hidden by geetha. don't need this props. using it from redux
                                ownerlist={this.state.owners}
                                //   menuTags={this.state.menu_tags}
                                menuSelectedTags={this.state.menu_selected_tag}
                                //   tagColorArray={this.state.newTagColorArray}
                                editDetails={this.editDetails}
                                showDelete={this.state.show_delete}
                                refreshTag={() => {
                                    this.refreshTag();
                                }}
                                enableLoader={this.enableLoader.bind(this)}
                                menu_name={this.state.menu_name}
                                processId={this.state.processId}
                                statusId={this.state.statusId}
                                allActions={this.state.allActions}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                    this.props.outboxPage({ ...state });
                                }}
                                refreshTable={() => {
                                    this.refreshTable();
                                }}
                                pdf_preview={this.state.pdf_preview}
                                open_edit_details={this.state.open_edit_details}
                                dataGridReferesh={this.state.dataGridReferesh}
                                fromSearch={this.props.calingFrom}
                                lockedStatus={() => {
                                    this.lockedStatus();
                                }}
                                onUnlock={this.unlockDocument}
                                setLockBarVisibility={this.setLockBarVisibility}
                            />
                            {this.state.pdf_preview ? (
                                <PdfPreview
                                    ref={this.pdfPrintRef}
                                    loader={(loader) => {
                                        this.setState({
                                            blur_spinner: loader,
                                        });
                                    }}
                                    docId={this.state.selectedDocId}
                                    // need to check below line is comment bcoz not showing no of pages
                                    // detailBigView={this.state.detailBigView}
                                    isLocked={this.state.is_locked}
                                    updateParent={this.getLockedDocIds}
                                />
                            ) : null}
                        </div>
                    ) : this.state.row_transition === '' ? (
                        <div
                            id='left-aside'
                            className={
                                this.state.detailBigView
                                    ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                    : 'left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                            }
                        >
                            <SpinnerThree show />
                        </div>
                    ) : null}
                    {this.state.detailBigView === true ? (
                        <div
                            id='left-last-aside'
                            className={
                                this.state.detailBigView
                                    ? ' expand-mode left-aside py-5 rounded-lg aside-fixed flex-row-auto'
                                    : null
                            }
                        >
                            <DetailBigView
                                pdfPrintRef={this.pdfPrintRef}
                                refreshForNextDocument={
                                    this.refreshForNextDocument
                                }
                                refreshTable={() => {
                                    this.resetBigView();
                                }}
                                selectedDocId={this.state.selectedDocId}
                                username={username}
                                dispname={dispname}
                                notesVal={this.state.notesVal}
                                openDeletePopUp={() => {
                                    this.openDeletePopUp.call(this);
                                }}
                                statusArray={this.state.statusArray}
                                rowTransition={() => {
                                    this.rowTransition();
                                }}
                                lockedStatus={() => {
                                    this.lockedStatus();
                                }}
                                selectedGroup={this.set_group}
                                openBigView={() => {
                                    this.openBigView();
                                }}
                                notesDDL={JSON.parse(
                                    localStorage.getItem('noteTypes')
                                )}
                                processId={this.state.processId}
                                statusId={this.state.statusId}
                                open_edit_details={this.state.open_edit_details}
                                outboxrefreshTable={() => {
                                    this.refreshTable();
                                }}
                                flag_bigView={this.state.flag_bigView}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                }}
                                detailBigView={this.state.detailBigView}
                                openDetailView={() => {
                                    this.openDetailView();
                                }}
                                calingFrom={this.state.calingFrom}
                            />
                        </div>
                    ) : null}
                </div>
                <SpinnerTwo show={this.state.main_spinner} />
                <SpinnerBlur show={this.state.blur_spinner} />
                {this.props.loading ? (
                    <SpinnerBlur show={this.props.loading} />
                ) : null}

                {this.state.single_edit === true ? (
                    <div
                        style={
                            {
                                // height: '800px',
                                // paddingTop: '30px',
                                // position: 'relative',
                            }
                        }
                    >
                        <EditWorkPage
                            style={{ position: 'sticky' }}
                            updateState={(state) => {
                                this.setState({ ...state });
                                // this.props.inboxPage({ ...state });
                            }}
                        />
                    </div>
                ) : null}

                {this.props.related_doc_detail ? (
                    <DocumentDetailViewComponent
                        //pdfPrintRef={this.pdfPrintRef}
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        callingFrom='outboxPage'
                    />
                ) : null}
                {this.props.multiple_edit_page ? (
                    <MultipleEditPage
                        refreshTable={() => {
                            this.refreshTable();
                        }}
                        callingFrom='outboxPage'
                    />
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getListaction: getListaction,
    setSelectedRow: setSelectedRow,
    getRulesJson: '',
    setRowData: (param) => setRowData(param),
    getCountData: getCountData,
    showLoader: (canShow) => showLoader(canShow),
    // reRenderOutbox: (canrender) => reRenderOutbox(canrender),
    //hideBigView: (hide) => hideBigView(hide),
    setMenuName: (name) => setMenuName(name),
    setPageData: (data) => setPageData(data),
    setDocOwner,
    setProcessId,
    setStatusId,
    getOwners,
    updateList,
    updateOutboxState,
    clearOutboxState,
    getDDLlist: getDDLlist,
    getSelectedRowDetails,
    outboxPage: outboxPage,
    getConfigData: getConfigData,
    getCustomSearchConfig: getCustomSearchConfig,
    getRecordSetConfig: getRecordSetConfig,
};
const mapStateToProps = (state) => {
    return {
        loading: state.outbox.loading,
        //  rowData: state.outbox.rowData, //hidden by geetha. not used
        mainSpinner: state.persist.loader,
        // rerenderOutbox: state.persist.rerenderOutbox,
        // hidebigview: state.persist.hidebigview,
        count: state.persist.count,
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        //currentMenuName: state.persist.currentMenuName,
        stateToken: state.outbox.stateToken,
        stateData: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.stateData
            : {},
        outbox: state.outbox,
        ddl: state.persistDDL.DDL,
        related_doc_detail: state.outbox.outboxpage.related_doc_detail,
        filteredData: state.outbox.outboxpage.filteredData,
        multiple_edit_page: state.outbox.outboxpage.multiple_edit_page,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboxPage))
);
