import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { setOwner } from '../../pages/_redux/actions/inboxActions';
import {
    stringEmptyOrUndefined,
    IS_MOBILE_DEVICE,
    getDocumentsOwners,
} from '../commonfunction';

const DropDownEditor = (props) => {
    const [ownerName, setOwnerName] = useState('');
    const [ownerList, setOwnerList] = useState('');
    const [showRemoveOwner, setShowRemoveOwner] = useState(false);
    const dispatch = useDispatch();
    const reduxStore = useSelector((state) => state);

    React.useEffect(() => {
        if (props.parentComp === 'inbox') {
            setOwnerList(reduxStore.inbox.docOwner);
            let selectedRow = reduxStore.inbox.selectedRow;
            reduxStore.inbox.stateData.rowData.map((data) => {
                if (selectedRow === data.doc_id) {
                    if (data.owner_rawdata !== '') {
                        setShowRemoveOwner(true);
                    } else {
                        setShowRemoveOwner(false);
                    }
                }
            });

            setOwnerName('');
        }
        if (props.parentComp === 'outbox') {
            let docOwner = reduxStore.persistDDL.DDL.owner;
            let displayOwner = getDocumentsOwners(docOwner);

            setOwnerList(displayOwner);
            let selectedRow = reduxStore.outbox.selectedRow;
            //comment by geetha
            //reduxStore.outbox.outboxpage.stateData.rowData
            reduxStore.outbox.stateData.rowData.map((data) => {
                if (selectedRow === data.doc_id) {
                    if (data.owner_rawdata !== '') {
                        setShowRemoveOwner(true);
                    } else {
                        setShowRemoveOwner(false);
                    }
                }
            });
            setOwnerName('');
        }
    }, []);

    if (document.getElementById('owner_auto_search_options') !== null) {
        const childElements = document.getElementById(
            'owner_auto_search_options'
        ).children;
        if (stringEmptyOrUndefined(childElements)) {
            for (let i = 0; i < childElements.length; i++) {
                childElements[i].classList.add('owner-div-container');
            }
        }
    }

    return (
        <>
            <div id='auto-div' className='auto-div-default'>
                <Autocomplete
                    onMenuVisibilityChange={(isOpen) => {
                        if (isOpen && IS_MOBILE_DEVICE) {
                            setTimeout(() => {
                                if (
                                    document.getElementById(
                                        'owner_auto_search_options'
                                    ) !== null
                                ) {
                                }
                            }, 10);
                        }
                    }}
                    inputProps={{
                        placeholder: 'Search teammate',
                        autoFocus: true,
                    }}
                    items={ownerList ? ownerList : []}
                    getItemValue={(item) =>
                        `${item.label}+${item.id}+${item.colorCode}+${item.ownerId}`
                    }
                    shouldItemRender={(item, value) =>
                        item.label.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                    }
                    renderItem={(item, isHighlighted) => (
                        <div
                            key={item.id}
                            style={{
                                background: isHighlighted
                                    ? 'lightgray'
                                    : 'white',
                            }}
                        >
                            {item.title}
                        </div>
                    )}
                    value={ownerName}
                    onChange={(e) => {
                        setOwnerName(e.target.value);
                    }}
                    onSelect={(value) => {
                        setOwnerName(value);

                        dispatch(setOwner(value));

                        document.getElementById('auto-div').style.display =
                            'none';
                    }}
                    renderMenu={(items, value) => (
                        <div
                            id='owner_auto_search_options'
                            className='owner-list'
                            style={{ maxHeight: '250px', overflowY: 'auto' }}
                            children={items}
                        />
                    )}
                    renderInput={(params) => (
                        <>
                            <div
                                className='owner-auto-search d-flex '
                                id='owner_auto_search'
                            >
                                <span className='empty-icon'>
                                    <i className='fas fa-user-plus' />
                                </span>
                                <input {...params} id='owner_auto_input' />
                                <br />
                            </div>
                            {showRemoveOwner && (
                                <div
                                    className='owner-auto-search d-flex align-items-center '
                                    onClick={() => {
                                        dispatch(setOwner(null));
                                        document.getElementById(
                                            'auto-div'
                                        ).style.display = 'none';
                                        dispatch(setOwner(null));

                                        document.getElementById(
                                            'auto-div'
                                        ).style.display = 'none';
                                        dispatch(setOwner(null));
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span>
                                        <i className='fas fa-user-minus' />
                                    </span>
                                    Remove Assignment
                                </div>
                            )}
                        </>
                    )}
                />
            </div>
        </>
    );
};
export default DropDownEditor;
