import printJS from 'print-js';
import { showErrorToast } from '../../app/components/commonfunction';
import { downloadDoc, downloadDocMultiple } from '../../app/pages/api/api';

/**
 *
 *
 * @param {string[]} printable
 * @param {(state)=>void} callBack
 */
const printPdf = (printable, callBack) => {
    try {
        printJS({
            printable: printable,
            onError: (e) => {
                console.log(e);
                callBack(1);
            },
            type: 'pdf',
            base64: true,
            onLoadingEnd: () => {
                callBack(0);
            },
            onPrintDialogClose: () => {},
        });
    } catch (error) {
        console.log(error);
    }
};

/**
 *
 *
 * @param {*} [docIDs=null]
 * @param {*} callBack
 * @return {Promise}
 */
export const DocumentIdToPrint = (docIDs = null) => {
    return new Promise(function (resolve, reject) {
        if (docIDs != null) {
            let documentPromise;

            // Make a API promise based on single of multiple document
            if (docIDs.length === 1) {
                const docId = docIDs[0];
                documentPromise = downloadDoc(docId);
            } else if (docIDs.length > 1) {
                const docId = [];
                docIDs.forEach((val) => docId.push(`docId=${val}`));
                documentPromise = downloadDocMultiple(docId.join('&'));
            }

            let status = '';
            if (documentPromise !== undefined) {
                documentPromise
                    .then((response) => {
                        status = response.status;
                        return response.blob();
                    })
                    .then((data) => {
                        if (status === 200) {
                            const reader = new FileReader();
                            reader.readAsDataURL(data);
                            reader.onloadend = function () {
                                const url = reader.result;
                                const encodeBase64 = url.split(',')[1];
                                printPdf(encodeBase64, (state) => {
                                    if ((state == 0) | (state == 1)) {
                                        resolve();
                                    } else {
                                        reject();
                                    }
                                });
                            };
                        } else {
                            reject();
                            showErrorToast('No PDF to print');
                        }
                    })
                    .catch((error) => {
                        reject();
                        showErrorToast('No PDF to print');
                    });
            }
        } else {
            reject('doc empty   ');
        }
    });
};
