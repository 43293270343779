import React from 'react';
import Row from './Row';

export const RowRenderer = function RowRenderer({
    rowIdx,
    isRowSelected,
    className,
    onRowReorder,
    fromPage,
    ...props
}) {
    return (
        <Row
            fromPage={fromPage}
            rowIdx={rowIdx}
            isRowSelected={isRowSelected}
            className={className}
            {...props}
        />
    );
};
