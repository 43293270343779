/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import TablePagination from '@material-ui/core/TablePagination';
import { isMobile } from 'react-device-detect';
import DataGrid from 'react-data-grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';

import { getCustomSearchList } from '../../pages/api/api';

import {
    formatHeaderNameWithOutSpace,
    GetFieldData,
    NoRecordFound,
    formatPathName,
    handleClickOutside,
    handleColumnsReorder,
    sortFields,
    sortMethod,
    customizedWidth,
    ObjectPropertyToLowerCase,
    ObjectPropertyToUpperCase,
    headerDataFormating,
    formatDate,
    dateCellFormatter,
    dateTimeDisplayFormatter,
    typeCellFormatter,
} from '../commonfunction';
import { inboxPage } from '../../pages/_redux/actions/inboxActions';
import ToggleGroupFormatter from './ToggleGroupFormatter';
import DropDownEditor from '../owner/DropDownEditor';
// import { ThemeContext } from '../SearchPage';
import { RowRenderer } from './RowRenderer';
import { SpinnerBlur } from '../Spinner';

const _ = require('lodash');

class PhysicianListingDataGrid extends React.Component {
    // static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = {
            toggleRecentDoc: false,
            selectedDoc: [],
            docIds: [],
            header: [],
            rowData: [],
            selectedRows: new Set(),
            selectedDocIds: [],
            currentPage: 0,
            perPage: 10,
            filterTxt: '',
            refreshDataGrid: '',
            filteredData: [],
            physicianFieldIdArray: [],
            physicianRecordsetId: '',
            modifyPhysicianRecordsetId: '',
            patientId: this.props.patientId,
            groupBy: [],
            groupByData: {},
            row_transition: false,
            defaultPopOpenState: {},
            expandedGroupIds: new Set(),
            dateFormatFields: [],
            modifiedColumnsNames: new Set(),
            rowClickClass: [],
            customGroupedData: [],
            defaultOpen: true,
            groupLoader: true,
            openedRowId: [],
            listingFor: 'physician',
        };
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.nextPage = this.nextPage.bind(this);

        this.createGroupedData = this.createGroupedData.bind(this);
        this.removeAllGrouping = this.removeAllGrouping.bind(this);
        this.resetPopOverData = this.resetPopOverData.bind(this);
        this.createGroupByRows = this.createGroupByRows.bind(this);
        this.sortRows = this.sortRows.bind(this);
    }

    async componentDidMount() {
        // var rowDetails = this.props.patientId;
        GetFieldData({
            configData: this.props.configData,
        });

        let configuredRecordsets = [];
        let physician_FieldIdArray;
        let physician_RecordsetId;
        let physician_ModifiableRecordsetId;

        /* configuredRecordsets =
             redux_store.configReducerPersist.configData.configuredRecordsets; */
        configuredRecordsets = this.props.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectory'
                ) {
                    physician_FieldIdArray = data.fields;
                    physician_RecordsetId = data.recordsetId;
                    physician_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                return data;
            });
        }

        await this.setState(
            {
                physicianFieldIdArray: physician_FieldIdArray,
                physicianRecordsetId: physician_RecordsetId,
                modifyPhysicianRecordsetId: physician_ModifiableRecordsetId,
            },
            () => {
                this.getPhysicianListData();
            }
        );

        this.setState({ row_transition: false });
    }

    initialConfigExecAfterState(
        params = {
            searchMode: false,
        }
    ) {
        let paginatedRowData = this.state.rowData;
        if (this.state.filterTxt.trim() !== '') {
            paginatedRowData = this.filterDataGrid(
                this.state.filterTxt,
                params.searchMode
            );

            this.setState({
                allFilteredRowData: paginatedRowData,
                totalCount: paginatedRowData.length,
                selectedRows: new Set(),
            });
            this.props.inboxPage({
                allFilteredRowData: paginatedRowData,
            });
        } else {
            if (this.state.groupBy.length === 0) {
                const startIndex = 0;
                const endIndex = startIndex + parseInt(this.state.perPage, 10);
                const fields = sortFields(
                    this.state.sortColumn,
                    this.state.sortDirection,
                    this.state.dateFormatFields
                );
                paginatedRowData = _.orderBy(
                    paginatedRowData,
                    fields.sortfn,
                    fields.orderBy
                ); // Sorting
                if (this.state.sortColumn) {
                    let columnDataType;
                    this.state.header.map((item, index) => {
                        if (item.key === this.state.sortColumn) {
                            columnDataType = item.datatype;
                        }
                    });
                    paginatedRowData = sortMethod(
                        paginatedRowData,
                        this.state.sortColumn,
                        this.state.sortDirection,
                        this.state.dateFormatFields,
                        columnDataType
                    );
                }
                paginatedRowData = _.slice(
                    paginatedRowData,
                    startIndex,
                    endIndex
                ); // Pagination
            }
            this.setState(
                {
                    filteredRowData: paginatedRowData,
                    currentPage: 0,
                    perPage: this.state.perPage,
                    totalCount: this.state.rowData.length,
                },
                () => {
                    this.resetPopOverData();
                }
            );
        }
    }

    getPhysicianListData = () => {
        this.setState({ loader: true });
        this.resetDataGrid();
        GetFieldData({
            configData: this.props.configData,
        });
        let searchText = '';
        // byOrderSearchText ='fieldSearch=' + fieldId.order_number + '%3D340129';
        /* searchText =
             'fieldSearch=' +
             fieldId.order_number +
             '%3D' +
             this.state.patientId; */

        // searchText = 'fieldSearch=' + fieldId.npi + '%3D' + 1 + '%2A';

        // recordSetSearch(this.state.physicianRecordsetId, searchText)

        let customSearchQueries = [];
        customSearchQueries = this.props.customSearchQueries;
        let pathname = '';
        const i = 0;
        let concat;
        customSearchQueries.map((data) => {
            if (
                String('ordertracking') ===
                formatHeaderNameWithOutSpace(data.name)
            ) {
                pathname = data.searchId;
                if (i === 0) concat = '';
                else concat = '&';
                if (
                    data.inputSearchFieldIdsAndValues[0] !== undefined &&
                    data.inputSearchFieldIdsAndValues[0] !== ''
                ) {
                    searchText += `${concat}fieldSearch=${data.inputSearchFieldIdsAndValues[0].fieldId}%3D${this.state.patientId}`;
                }
            }
        });
        // searchText = '';
        getCustomSearchList(pathname, searchText)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty('dataTable') && data.dataTable !== '') {
                    // let length;
                    // if (data.dataTable.hasOwnProperty('dataRows')) {
                    //     length = data.dataTable.dataRows.length;
                    // }

                    this.setState({
                        // totalCount: length,
                        refreshTab: false,
                    });

                    setTimeout(() => {
                        this.setState({ refreshTab: true });
                    }, 1000);
                    this.getPhysicianDocData(data.dataTable);
                }
            });
    };

    resetDataGrid = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    handleClick = async () => {
        await this.setState({ openPopover: true });
    };

    actionMenu = (npi, data) => {
        // const id = this.state.openPopover ? 'simple-popover-' : undefined;

        if (npi !== '' && npi !== null) {
            return (
                <div className='d-flex action_column align-items-center rel_action_menu view_btn'>
                    <Button
                        variant='primary'
                        className='success'
                        onClick={() => {
                            this.props.history.push({
                                pathname: `/search/physicians/?NPI=${npi}&alldata=${data}`,
                            });
                        }}
                        title={`View Physician - ${npi}`}
                    >
                        View Physician
                    </Button>
                </div>
            );
        }
    };

    getPhysicianDocData = async (data) => {
        this.setState({ relDocLoading: true });
        let rows = [];
        const lowerCase = [];
        const uniqueData = {};
        let header = [];
        let dateFormatFields = [];
        // header.push(SelectColumn)
        if (data !== undefined) {
            let { dataRows, headerColumns } = data;

            const actionArray = {
                name: 'Action',
                fieldId: '',
                datatype: 'AlphaNumeric',
            };

            let currentWidth = 0;
            if (document.getElementById('physician_listing')) {
                currentWidth = document
                    .getElementById('physician_listing')
                    .getBoundingClientRect().width;
            }

            const otherColumnWidth = 3 * 150; // 3 hardcoded ince we have only 3 columns

            let actionMenuWidth = 200;
            if (currentWidth !== 0) {
                const widthDiff = currentWidth - otherColumnWidth;
                if (widthDiff > 200) {
                    actionMenuWidth = widthDiff;
                }
            }

            await headerColumns.push(actionArray);

            // For duplicate header below line handled
            const headerColumnsData = [];
            const headerColumnsData2 = [];
            let nameunique = '';
            let nameunique2 = '';

            let headerColumnsName = [];
            headerColumns.forEach((data, index) => {
                headerColumnsName.push(data.name);
            });

            const counts = {};
            headerColumnsName.forEach(function (x) {
                counts[x] = (counts[x] || 0) + 1;
            });

            for (const property in counts) {
                if (counts[property] > 1) {
                    let totalCount = counts[property];
                    let i = 1;
                    let initiateValue = ' I';
                    if (i <= totalCount) {
                        // eslint-disable-next-line no-loop-func
                        headerColumns.forEach((data, index) => {
                            if (data.name === property) {
                                if (i === 1) {
                                    nameunique2 = data.name;
                                } else {
                                    nameunique2 = data.name + initiateValue;
                                }
                                const headerArray = {
                                    name: nameunique2,
                                    fieldId: data.fieldId,
                                    datatype: data.datatype,
                                };
                                i++;
                                initiateValue = initiateValue + 'I';
                                headerColumnsData2.push(headerArray);
                            }
                        });
                    }
                }
            }

            headerColumns.forEach((data, index) => {
                if (counts[data.name] === 1) {
                    nameunique = data.name;
                } else if (counts[data.name] > 1) {
                    headerColumnsData2.forEach((property, index) => {
                        if (property.fieldId === data.fieldId) {
                            nameunique = property.name;
                        }
                    });
                }
                const headerArray = {
                    name: nameunique,
                    fieldId: data.fieldId,
                    datatype: data.datatype,
                };

                headerColumnsData.push(headerArray);
            });
            headerColumns = headerColumnsData;

            // duplicate header end

            await headerColumns.forEach((data, index) => {
                const headerName = formatHeaderNameWithOutSpace(data.name);

                // if(String(headerName) === 'npi' || String(headerName) === 'orderingphysician' ||
                // String(headerName) === 'faxnumber' || String(headerName) === 'action'){

                uniqueData[headerName] = new Set();
                if (data.datatype === 'Date' || data.datatype === 'DateTime') {
                    dateFormatFields = [...dateFormatFields, headerName];
                }
                let headerObj = {
                    key: formatHeaderNameWithOutSpace(data.name),
                    name: data.name,
                    datatype: data.datatype,
                    colname: data.datatype,
                    fieldId: data.fieldId,
                    sortable: true,
                    draggable: true,
                    resizable: true,
                    // width: 123,
                    // colSpan(args) {

                    //     return args.type === 'ROW' && args.row.branch === "BBB" ? 3 : undefined;
                    // }
                };
                switch (data.name.trim().toUpperCase()) {
                    case 'TYPE':
                        headerObj = {
                            ...headerObj,
                            ...{
                                sortable: false,
                            },
                        };
                        break;
                    case 'ACTION':
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: actionMenuWidth - 20,
                            },
                        };
                        break;
                    default:
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: 150,
                                editable: false,
                            },
                        };
                        break;
                }
                header.push(headerObj);
                // }
            });

            //worked for new display name by kalpana - start
            let displayNameObj = {
                key: 'displayname',
                name: 'Display Name',
                colname: 'AlphaNumeric',
                sortable: true,
                draggable: true,
                resizable: true,
                width: 150,
                //  frozen: false,
                className: 'Display Name',
            };
            header.push(displayNameObj);
            uniqueData['displayname'] = new Set();
            //worked for new display name by kalpana - end

            this.setState({ totalColumn: header });
            // header = [SelectColumn].concat(header); //commented to remove header checkbox
            // eslint-disable-next-line no-unused-vars
            let g = 0;
            const uniqueRow = [];

            await dataRows.forEach((data, index) => {
                g += 1;
                const lowercaseObj = {};

                const obj = {};
                obj['id'] = data.id;
                lowercaseObj['id'] = data.id;
                const array = data.values;

                let npi = '';
                let allinputs = '';
                // array.splice(0, 0, '');// commented to remove row data for checkbox
                array.push(''); // addinf extra column for any actions
                array.forEach((row, i) => {
                    // if(String(header[i].key) === 'npi' || String(header[i].key) === 'orderingphysician' || String(header[i].key) === 'faxnumber'){
                    if (header[i] !== undefined) {
                        if (
                            String(header[i].key) === 'npi' ||
                            String(header[i].key) === 'orderingphysician' ||
                            String(header[i].key) === 'faxnumber' ||
                            String(header[i].key) === 'deliverymethod' ||
                            String(header[i].key) === 'phonenumber' ||
                            String(header[i].key) === 'emailaddress' ||
                            String(header[i].key) === 'action'
                        ) {
                            obj[header[i].key] = row.trim();
                            lowercaseObj[header[i].key] =
                                row.trim().toLowerCase() + header[i].key;
                            // npi = '';

                            if (String(header[i].key) === 'deliverymethod') {
                                allinputs += `&deliverymethod=${row}`;
                            }
                            if (String(header[i].key) === 'emailaddress') {
                                allinputs += `&emailaddress=${row}`;
                            }
                            if (String(header[i].key) === 'phonenumber') {
                                allinputs += `&phonenumber=${row}`;
                            }

                            if (String(header[i].key) === 'npi') {
                                npi = row;
                                allinputs += `&npi=${row}`;
                            }
                            if (String(header[i].key) === 'orderingphysician') {
                                allinputs += `&orderingphysician=${row}`;
                            }
                            if (String(header[i].key) === 'faxnumber') {
                                allinputs += `&faxnumber=${row}`;
                            }

                            switch (header[i].key) {
                                case 'action':
                                    obj[header[i].key] = this.actionMenu(
                                        npi,
                                        allinputs
                                    );
                                    break;
                                case 'npi':
                                    obj[header[i].key] = Number(row.trim());
                                    break;
                                /* case 'documenttype':
                                        obj[header[i].key] = this.imagePreview(
                                            data.id,
                                            row.trim()
                                        );
                                        //obj[header[i].key] = "s"
                                        lowercaseObj[header[i].key] = row
                                            .trim()
                                            .toLowerCase();
                                        break; */
                                case 'documenttype':
                                    obj[header[i].key] = typeCellFormatter(row);
                                    obj.documenttype_rawdata = row;
                                    this.state.modifiedColumnsNames.add(
                                        header[i].key
                                    );
                                    break;
                                default:
                                    break;
                            }
                            switch (header[i].colname) {
                                case 'Date':
                                    obj[`${header[i].key}_rawdata`] =
                                        formatDate(
                                            row,
                                            'MM/DD/YYYY hh:mm:ss A'
                                        );
                                    obj[`${header[i].key}_onlyDate`] =
                                        formatDate(row, 'MM/DD/YYYY');

                                    // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                    obj[header[i].key] = formatDate(
                                        row,
                                        'MM/DD/YYYY'
                                    );
                                    if (header[i].key === 'date&batch') {
                                        obj[header[i].key] =
                                            dateCellFormatter(row);
                                    }
                                    if (row !== undefined) {
                                        uniqueData[header[i].key].add(
                                            formatDate(row, 'MM/DD/YYYY')
                                        );
                                    }
                                    break;
                                case 'DateTime':
                                    obj[`${header[i].key}_rawdata`] =
                                        formatDate(
                                            row,
                                            'MM/DD/YYYY hh:mm:ss A'
                                        );
                                    // pls note we r reciving UTC date time('2023-06-29T09:58:42Z') so convert to local date time
                                    let dateVal = '';
                                    const date = new Date(row);
                                    const offset = date.getTimezoneOffset();
                                    const localDate = new Date(
                                        date.getTime() - offset * 60 * 1000
                                    );
                                    dateVal = formatDate(
                                        localDate,
                                        'MM/DD/YYYY HH:mm:ss'
                                    );

                                    obj[`${header[i].key}_dateTimedata`] =
                                        dateVal;
                                    obj[`${header[i].key}_onlyDate`] =
                                        formatDate(row, 'MM/DD/YYYY');

                                    // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                    obj[header[i].key] =
                                        dateTimeDisplayFormatter(row);

                                    if (header[i].key === 'date&batch') {
                                        obj[header[i].key] =
                                            dateCellFormatter(row);
                                    }
                                    if (row !== undefined) {
                                        uniqueData[header[i].key].add(
                                            dateTimeDisplayFormatter(row)
                                        );
                                    }
                                    break;
                                default:
                                    if (row !== undefined) {
                                        uniqueData[header[i].key].add(row);
                                    }
                                    break;
                            }
                        }
                    }
                    // }
                });

                lowerCase.push(lowercaseObj);
                if (!uniqueRow.includes(npi)) {
                    uniqueRow.push(npi);
                    rows.push(obj);
                }
            });
            this.setState({
                totalCount: rows.length,
            });
            if (rows.length !== 0) {
                document.getElementById('physician_count').innerHTML =
                    rows.length;
                document.getElementById('physician_count').style.display =
                    'inherit';
            }
        }

        rows = rows.filter((value) => {
            return value.id !== this.props.selectedDocId;
        });

        header = header.filter(
            (data) =>
                data.key === 'orderingphysician' ||
                data.key === 'npi' ||
                data.key === 'faxnumber' ||
                data.key === 'action'
        );
        if (
            this.state.physicianRecordsetId === undefined ||
            this.state.physicianRecordsetId === ''
        ) {
            header = header.filter((data) => data.key !== 'action');
        }

        if (isMobile && window.screen.width <= 960) {
            header = header.filter(
                (data) => data.key !== 'select-row' && data.name.trim() !== ''
            );

            const documentTitle = header.filter(
                (data) =>
                    data.key === 'orderingphysician' ||
                    data.key === 'name' ||
                    data.key === 'displayname'
            );
            const docIndex = header.findIndex(
                (data) =>
                    data.key === 'orderingphysician' ||
                    data.key === 'name' ||
                    data.key === 'displayname'
            );
            header.splice(docIndex, 1);
            const ArrowFormatter = {
                key: 'toggle',
                name: '',
                colname: '',
                width: 10,
                className: 'arrow-mobile',
                formatter: ({ row }) => {
                    const docid = row.id;
                    const openedRowId = this.state.openedRowId;
                    const toggle = () => {
                        if (openedRowId[docid] === undefined) {
                            openedRowId[docid] = true;
                            this.setState({ openedRowId: openedRowId });
                        } else {
                            openedRowId[docid] = !openedRowId[docid];
                            this.setState({ openedRowId: openedRowId });
                        }
                    };
                    const icon =
                        openedRowId[docid] === true
                            ? 'fa fa-chevron-up'
                            : 'fa fa-chevron-right';
                    return (
                        <div
                            onClick={() => toggle()}
                            className='toggle-it d-flex align-items-center'
                        >
                            {' '}
                            <i className={`${icon}`} aria-hidden='true' />{' '}
                        </div>
                    );
                },
            };
            documentTitle.push(ArrowFormatter);
            header = documentTitle.concat(header); // Adding Document Name at the beginning
        } else {
            header = header.filter(
                (data) =>
                    data.key !== 'select-row' &&
                    data.name.trim() !== '' &&
                    data.name.trim() !== 'Document Name' &&
                    data.name.trim() !== 'Display Name'
            );
        }

        this.setState(
            {
                uniqueData: uniqueData,
                relDocLoading: false,
                header: header,
                rowData: rows,
                filteredRowData: rows,
                allRows: rows,
                allRowsLoweCase: lowerCase,
                dateFormatFields,
                // refreshDataGrid:true
            },
            () => {
                let paginatedRowData = this.state.rowData;
                // let paginatedRowData = rows;
                paginatedRowData = _.slice(paginatedRowData, 0, 10);

                this.setState({
                    refreshDataGrid: true,
                    rowData: paginatedRowData,
                    filteredRowData: paginatedRowData,
                    loader: false,
                });
            }
        );
    };

    openPhysician = (docId) => {
        this.setState({
            openPhysician: true,
            docIds: [docId],
        });
    };

    componentDidUpdate(prevState, nextState) {
        if (String(prevState.filterTxt) !== String(this.props.filterTxt)) {
            this.setState(
                {
                    filterTxt: this.props.filterTxt,
                },
                () => {
                    this.initialConfigExecAfterState();
                }
            );
        }

        if (prevState.selectedDocId !== this.props.selectedDocId) {
            this.setState({
                toggleRecentDoc: false,
                selectedDoc: [],
                openPhysician: false,
                docIds: [],
            });
            this.getPhysicianListData();
        }
    }

    /* onRowClick = (rowDetails, rowHeader) => {
        if (String(rowHeader.key) === 'action') {
            this.setState(
                {
                    selectedDocIds: rowDetails.id,
                },
                async () => {
                    let actionButton = document.querySelectorAll(
                        `.settingsmenu${this.state.selectedDocIds}`
                    );
                    let elementPosition =
                        actionButton[0].getBoundingClientRect(); //Get PopOver Position
                    var position = {
                        top: parseInt(elementPosition.top),
                        left: parseInt(elementPosition.x),
                    };
                    await this.setState({ popOverPosition: position });
                }
            );
        }
        
        if (this.props.callingFrom === 'related_document') {
            return true;
        }


    }; */

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    filterDataGrid(searchText = '') {
        // Fixes for ReEx issue
        const isValid = (str) => {
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText.trim()) === false) {
            return [];
        }
        // End
        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        const filterOperation = (rowData) => {
            const filteredData = _.filter(rowData, function (data) {
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        continue;
                    }
                    passed =
                        String(data[property]).toLowerCase().match(inputData) !=
                            null &&
                        String(data[property]).toLowerCase().match(inputData)
                            .length > 0;
                    if (passed) break;
                }
                return passed;
            });
            return filteredData;
        };
        let filteredRowData = [];
        let totalCount = 0;
        const fullRowData = filterOperation(this.state.rowData);
        filteredRowData = this.getRowData(0, this.state.perPage, fullRowData);

        totalCount = fullRowData.length;
        this.setState(
            {
                /* filterTxt: searchText,
                filteredRowData,
                totalCount,
                currentPage: 0, */

                filterTxt: searchText,
                rowData: filteredRowData,
                filteredRowData,
                filteredData: fullRowData,
                totalCount,
                currentPage: 0,
            },
            () => {
                this.resetPopOverData(fullRowData);
            }
        );

        if (inputData === '') {
            this.setState({ rowData: this.state.allRows }, () => {
                this.resetPopOverData(this.state.allRows);
            });
        }

        return fullRowData;
    }

    refreshTable() {
        const resetIt = () => {
            this.componentDidMount();
            this.setState({
                displayAnimation: true,
                refresh: false,
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                filteredRowData: [],
            });
            setTimeout(() => {
                this.setState({ displayAnimation: false });
            }, 1000);
        };
        this.setState(
            {
                refresh: true,
            },
            () => {
                setTimeout(() => {
                    resetIt();
                }, 100);
            }
        );
    }

    nextPage(event, newPage) {
        const startIndex =
            parseInt(newPage, 10) * parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        let paginatedRowData;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }
        // paginatedRowData = this.state.allRows;
        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            currentPage: newPage,
            filteredRowData: paginatedRowData,
        });
    }

    handleChangeRowsPerPage(event) {
        const startIndex = 0;
        const endIndex = parseInt(event.target.value, 10);
        let paginatedRowData = this.state.allRows;
        // let paginatedRowData = this.state.filteredRowData;
        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            filteredRowData: paginatedRowData,
            perPage: endIndex,
            currentPage: 0,
        });
    }

    handleSearch = (searchText) => {
        this.setState({
            refreshDataGrid: false,
            relDocLoading: true,
        });
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
        // const isValid = (str) => {
        //     return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
        // };

        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        const filteredData = _.filter(this.state.allRows, function (data) {
            // filter Operation
            let passed = false;
            for (const property in data) {
                if (property === 'id') {
                    continue;
                } // Excluding few Property from filter
                passed =
                    String(data[property]).toLowerCase().match(inputData) !=
                        null &&
                    String(data[property]).toLowerCase().match(inputData)
                        .length > 0;
                if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
            }
            return passed;
        });
        const length = filteredData.length;

        const paginatedRowData = _.slice(filteredData, 0, 10);
        this.setState({
            filteredData: filteredData,
            relDocLoading: false,
            rowData: paginatedRowData,
            // allRows: filteredData,
            totalCount: length,
            currentPage: 0,
            perPage: 10,
            refreshDataGrid: true,
        });
    };

    draggableColumns() {
        const headerRenderer = (props) => {
            return (
                <DraggableHeaderRenderer
                    {...props}
                    {...this.state}
                    setGrouping={this.createGroupedData}
                    onColumnsReorder={handleColumnsReorder.bind(this)}
                    handleClickOutside={handleClickOutside}
                    sortFields={this.sortRows}
                />
            );
        };
        let header = this.state.header;
        let pathname = this.props.location.pathname;
        pathname = formatPathName(pathname);
        if (header.length > 0 && !isMobile) {
            // HEADER REORDER AND WIDTH SETTING  FROM LOCALSTORAGE
            const pathNameTempReorder = `header-reorder-${this.state.listingFor}-${pathname}`;
            const pathNameTempWidth = `header-width-${this.state.listingFor}-${pathname}`;

            let headerReorder = localStorage.getItem(`${pathNameTempReorder}`);
            let headerWidth = localStorage.getItem(`${pathNameTempWidth}`);

            if (headerReorder !== null && headerReorder !== '') {
                headerReorder = JSON.parse(headerReorder);
                header = headerDataFormating({ headerReorder, header });
            }
            if (headerWidth !== null && headerWidth !== '') {
                headerWidth = JSON.parse(headerWidth);
                header = headerDataFormating({ headerWidth, header });
            }
        } // END
        if (header.length === 0) {
            header = this.state.header;
        }
        header = header.filter((data) => {
            data.rowGroup = false;
            if (data.groupFormatter !== undefined) {
                delete data.groupFormatter; // issue fixes for grouping
            }
            return data;
        });
        return header.map((c) => {
            if (this.state.groupBy.length > 0) {
                c.rowGroup = this.state.groupBy.indexOf(c.key) !== -1;
                if (c.rowGroup) {
                    c.width = c.width > 200 ? c.width : 200;
                    c.groupFormatter = ToggleGroupFormatter.bind(this);
                }
            }
            if (c.key === 'owner') {
                c.editor = DropDownEditor.bind(this);
                c.editorOptions = { createPortal: true, editOnClick: true };
                c.editable = true;
            }
            if (c.key === 'select-row') {
                c.selectedCount = Array.from(this.state.selectedRows).length;
            }
            c.row_transition = this.state.row_transition;
            if (
                c.key === 'id' ||
                c.key === 'select-row' ||
                this.state.tableDesign === 'simple'
            )
                return c;
            return { ...c, headerRenderer: headerRenderer };
        });
    }

    createGroupedData(data, type = '', mode = false) {
        this.setState({ customGroupedData: [] });

        const groupByData = this.state.groupByData;
        const { expandedGroupIds } = this.state; // Grouping Expansion Id
        for (const property in groupByData) {
            groupByData[property] = groupByData[property].map((data) =>
                data.trim().toLowerCase()
            );
        }
        const str_pad = (num) => {
            const str1 = '';
            return str1.padStart(num, '_');
        };
        if (String(type) === 'ALL') {
            // SELECT ALL option in POPOVER
            groupByData[data.title] = [];
            let collection = data.value;
            collection = collection.map((v) => v.trim().toLowerCase());
            groupByData[data.title] = mode ? collection : [];
            const groupBy = [];
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                openState[names] = false;
            }
            for (const props in groupByData) {
                if (groupByData[props].length > 0) {
                    groupBy.push(props);
                }
            }
            let groupingValueData = Object.values(groupByData);
            groupingValueData = groupingValueData.filter((v) => v.length > 0);
            const expandedArray = Array.from(expandedGroupIds);
            const lastIndexData =
                groupingValueData[groupingValueData.length - 1];
            const str = str_pad(2);
            if (lastIndexData !== undefined) {
                if (expandedArray.length > 0) {
                    if (
                        lastIndexData !== undefined &&
                        lastIndexData.length > 0
                    ) {
                        expandedArray.forEach((v1) => {
                            lastIndexData.forEach((v2) => {
                                v2 =
                                    v2.charAt(0).toUpperCase() +
                                    v2.slice(1, v2.length);
                                expandedGroupIds.add(v2);
                                const parentIndexCount = v1.split('__').length;
                                if (parentIndexCount <= groupBy.length) {
                                    expandedGroupIds.add(`${v1}${str}${v2}`);
                                }
                            });
                        });
                    }
                } else {
                    lastIndexData.forEach((v2) => {
                        v2 =
                            v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                        if (!expandedGroupIds.has(v2)) {
                            expandedGroupIds.add(v2);
                        }
                    });
                }
            }
            // let rowData = this.state.rowData;
            let rowData = this.state.allRows;
            if (!mode && !(groupBy.length > 0)) {
                rowData = _.slice(rowData, 0, this.state.perPage);
            }
            if (this.state.filterTxt !== '') {
                rowData = this.state.filteredRowData;
            }
            this.setState({
                groupBy: groupBy,
                groupByData: groupByData,
                defaultOpen: groupBy.length > 0,
                groupLoader: groupBy.length > 0,
                filteredRowData: rowData,
                // filteredRowData: [],
                selectedRows: new Set(),
                tempExpandedGroupIds:
                    groupBy.length > 0 ? expandedGroupIds : new Set(),
                defaultPopOpenState: openState,
                sortColumn: '',
                sortDirection: 'NONE',
                reInitiateGrid: true,
            });
            if (mode === false) {
                this.resetPopOverData(this.state.rowData);
            }
            return;
        } // End SELECT ALL
        if (
            data.title !== undefined &&
            this.state.groupByData[data.title] === undefined
        ) {
            // Check , value already exist, if not,  assign it to Array
            groupByData[data.title] = [];
        }
        const val = data.value.trim().toLowerCase();
        if (
            String(groupByData[data.title].indexOf(val)) === String(-1) &&
            mode
        ) {
            // mode = true, add value to array
            groupByData[data.title].push(val);
        } else if (!mode) {
            // mode = false , Remove value
            const index = groupByData[data.title].indexOf(val);
            groupByData[data.title].splice(index, 1);
        }
        const groupBy = [];
        const openState = this.state.defaultPopOpenState; // FOR POPOVER OPEN or CLOSE
        for (const names in openState) {
            openState[names] = false; // CLOSING ALL POPOVER
        }
        openState[data.title] = true; // OPENING CURRENT POPOVER
        const groupedValueData = [];
        for (const props in groupByData) {
            if (groupByData[props].length > 0) {
                groupBy.push(props);
                groupByData[props].forEach((d) => {
                    groupedValueData.push(d);
                });
            }
        }
        let groupingValueData = Object.values(groupByData);
        groupingValueData = groupingValueData.filter((v) => v.length > 0);
        const expandedArray = Array.from(expandedGroupIds);
        const lastIndexData = groupingValueData[groupingValueData.length - 1];
        const str = str_pad(2);
        if (lastIndexData !== undefined) {
            // Generate Grouping Expansion Id
            if (expandedArray.length > 0) {
                if (lastIndexData !== undefined && lastIndexData.length > 0) {
                    expandedArray.forEach((v1) => {
                        lastIndexData.forEach((v2) => {
                            v2 =
                                v2.charAt(0).toUpperCase() +
                                v2.slice(1, v2.length);
                            expandedGroupIds.add(v2);
                            const parentIndexCount = v1.split('__').length;
                            if (parentIndexCount <= groupBy.length) {
                                expandedGroupIds.add(`${v1}${str}${v2}`);
                            }
                        });
                    });
                }
            } else {
                lastIndexData.forEach((v2) => {
                    v2 = v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                    if (!expandedGroupIds.has(v2)) {
                        expandedGroupIds.add(v2);
                    }
                });
            }
        } // End
        // let rowData = this.state.rowData;
        let rowData = this.state.allRows;
        if (String(groupBy.length) === String(0)) {
            rowData = _.slice(rowData, 0, this.state.perPage); // Paginating the data
        }

        if (String(this.state.filterTxt) !== '') {
            // rowData = this.state.allFilteredRowData;
            rowData = this.state.filteredRowData;
        }
        // if (!mode && !(groupBy.length > 0)) {
        //     rowData = _.slice(rowData, 0, this.state.perPage);
        // }

        // rowData = this.performGroupingFilter(groupedValueData)

        this.setState({
            groupBy: groupBy, // REQUIRED STATE FOR GROUPING
            groupByData: groupByData,
            defaultOpen: groupBy.length > 0,
            groupLoader: groupBy.length > 0,
            filteredRowData: rowData,
            // filteredRowData: [],
            selectedRows: new Set(),
            // expandedGroupIds:  (groupBy.length > 0) ? expandedGroupIds : new Set(),
            tempExpandedGroupIds:
                groupBy.length > 0 ? expandedGroupIds : new Set(),
            defaultPopOpenState: openState,
            sortColumn: '',
            sortDirection: 'NONE',
            reInitiateGrid: true,
            // uniqueData:""
            // refreshDataGrid :true
        });

        // Abu added @19-01-2022 {
        if (mode === false) {
            this.resetPopOverData(this.state.rowData);
        }
        // }
    }

    sortRows(sortColumn, sortDirection) {
        let columnDataType;
        this.state.header.map((item, index) => {
            if (item.key === sortColumn) {
                columnDataType = item.datatype;
            }
        });

        if (sortDirection !== 'NONE') {
            // let paginatedRowData = this.state.rowData;
            let paginatedRowData = this.state.allRows;
            if (this.state.filterTxt.trim() !== '') {
                paginatedRowData = this.filterDataGrid(this.state.filterTxt);
            }
            paginatedRowData = sortMethod(
                paginatedRowData,
                sortColumn,
                sortDirection,
                this.state.dateFormatFields,
                columnDataType
            );

            const sort_by = (field, reverse, primer) => {
                const key = primer
                    ? function (x) {
                          return primer(x[field]);
                      }
                    : function (x) {
                          return x[field];
                      };
                reverse = !reverse ? 1 : -1;
                return function (a, b) {
                    return (
                        (a = key(a)),
                        (b = key(b)),
                        reverse * ((a > b) - (b > a))
                    );
                };
            };

            if (sortColumn === 'npi') {
                let sorted;
                if (sortDirection === 'ASC') {
                    sorted = paginatedRowData.sort(
                        sort_by('npi', false, parseInt)
                    );
                }
                if (sortDirection === 'DESC') {
                    sorted = paginatedRowData.sort(
                        sort_by('npi', true, parseInt)
                    );
                }
                paginatedRowData = sorted;
            }

            this.setState({
                allRows: paginatedRowData,
            });

            paginatedRowData = this.getRowData(
                0,
                this.state.perPage,
                paginatedRowData
            );
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                openState[names] = false;
            }
            this.setState({
                filteredRowData:
                    this.state.groupBy.length > 0
                        ? this.state.allRows
                        : paginatedRowData,
                // filteredRowData:
                currentPage: 0,
                sortDirection: sortDirection,
                sortColumn: sortColumn,
                defaultPopOpenState: openState,
            });
        }
    }

    // Common Function used to regenerate Popover Data while filtering
    resetPopOverData(filteredData = []) {
        const uniqueData = {};
        const uniqueTagNameValues = new Set();
        let makeData = this.state.rowData;
        if (this.state.filterTxt.trim() !== '') {
            makeData = filteredData;
        }

        //  if (this.state.filterTxt.trim() !== '') {
        //     makeData = this.state.allFilteredRowData;
        // }
        // makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        //  makeData=Object.values(this.state.customGroupedData)
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (uniqueData[property] === undefined) {
                    uniqueData[property] = new Set();
                }
                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        uniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        this.state.header?.map((item, idx) => {
                            if (item.datatype === 'DateTime') {
                                if (
                                    formatHeaderNameWithOutSpace(item.name) ===
                                    property
                                ) {
                                    uniqueData[property].add(
                                        formatDate(
                                            data[`${property}_rawdata`],
                                            'MM/DD/YYYY HH:mm:ss'
                                        )
                                    );
                                }
                            } else {
                                uniqueData[property].add(
                                    formatDate(
                                        data[`${property}_rawdata`],
                                        'MM/DD/YYYY'
                                    )
                                );
                            }
                        });
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    uniqueTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    uniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    uniqueData[property].add(data[property]);
                }
            }
        });

        this.setState(
            {
                uniqueData,
                uniqueTagNameValues,
            },
            () => {}
        );
    }

    createGroupByRows(groupedData, indexProp) {
        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            if (String(property) === String(this.state.sortColumn)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.groupByData[property] =
                    this.state.sortDirection === 'ASC'
                        ? rowValue.sort()
                        : rowValue.sort().reverse(); // Performing Sorting
            }
        }
        // End
        let customGroupedData = [];
        const groupResult = {};
        let dateFieldColumn = '';
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        if (String(indexProp) === 'tags') {
            const emptyTagRowNames = [];
            for (let [property, rowValue] of Object.entries(
                this.state.groupByData
            )) {
                const index = this.state.groupBy.indexOf(property);
                if (index !== -1 && String(rowValue.length) === 0) {
                    this.state.groupBy.splice(index, 1);
                }
                if (String(property) === String(indexProp)) {
                    groupedData.forEach((data) => {
                        let tagData = String(data.tags_data).split('|');
                        tagData = tagData.map((data) =>
                            data.trim().toLowerCase()
                        );
                        rowValue = rowValue.map((data) =>
                            data.trim().toLowerCase()
                        );
                        rowValue.forEach((tag) => {
                            if (tagData.indexOf(tag) !== -1) {
                                if (groupResult[tag] === undefined) {
                                    groupResult[tag] = [];
                                }
                                groupResult[tag].push(data);
                            } else {
                                emptyTagRowNames.push(tag);
                            }
                        });
                    });
                    const fields = sortFields(
                        this.state.sortColumn,
                        this.state.sortDirection,
                        this.state.dateFormatFields
                    );
                    for (const property in groupResult) {
                        groupResult[property] = _.orderBy(
                            groupResult[property],
                            fields.sortfn,
                            fields.orderBy
                        );
                    }
                    if (this.state.sortColumn) {
                        let columnDataType;
                        this.state.header.map((item, index) => {
                            if (item.key === this.state.sortColumn) {
                                columnDataType = item.datatype;
                            }
                        });
                        groupResult[property] = sortMethod(
                            groupResult[property],
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields,
                            columnDataType
                        );
                    }
                }
            }
            // this.state.defaultOpen = false;
            // this.state.isFilterRowEmpty = Object.keys(groupResult).length === 0;
            this.render();
            const emptyData = {};
            if (emptyTagRowNames.length > 0) {
                emptyTagRowNames.forEach((name) => {
                    emptyData[name] = [];
                });
            }
            // eslint-disable-next-line vars-on-top
            var result = { ...emptyData, ...groupResult };
            result = ObjectPropertyToUpperCase(
                result,
                this.state.sortDirection,
                this.state.groupByData
            );
            return result;
        }

        // Check If it is Date Field And Group It
        const emptyDateBatch = [];
        if (this.state.dateFormatFields.indexOf(indexProp) !== -1) {
            const index = this.state.dateFormatFields.indexOf(indexProp);
            dateFieldColumn = this.state.dateFormatFields[index];
            if (dateFieldColumn === 'date&batch') {
                customGroupedData = _.groupBy(groupedData, 'date_batch');
            } else {
                // customGroupedData = _.groupBy(
                //     groupedData,
                //     `${dateFieldColumn}_rawdata`
                // );
                customGroupedData = _.groupBy(
                    groupedData,
                    `${dateFieldColumn}_onlyDate`
                );
            }
        } else if (modifiedColumnsNames.indexOf(indexProp) !== -1) {
            customGroupedData = _.groupBy(groupedData, `${indexProp}_rawdata`);
        } else {
            customGroupedData = _.groupBy(groupedData, `${indexProp}`);
        }

        customGroupedData = ObjectPropertyToLowerCase(customGroupedData);
        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            const index = this.state.groupBy.indexOf(property);
            if (index !== -1 && rowValue.length === 0) {
                this.state.groupBy.splice(index, 1);
            }
            if (String(property) === String(indexProp)) {
                rowValue.forEach((data, i) => {
                    const dateData = data.trim();
                    if (customGroupedData[dateData] !== undefined) {
                        const fields = sortFields(
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields
                        );
                        customGroupedData[dateData] = _.orderBy(
                            customGroupedData[dateData],
                            fields.sortfn,
                            fields.orderBy
                        );
                        groupResult[data] = customGroupedData[dateData];
                    } else {
                        emptyDateBatch.push(dateData);
                    }
                });
            }
        }
        //  this.state.defaultOpen = false;
        // this.state.isFilterRowEmpty = Object.keys(groupResult).length === 0;
        const empty = {};
        if (emptyDateBatch.length > 0) {
            emptyDateBatch.forEach((name) => {
                empty[name] = [];
            });
        }
        this.render();
        // eslint-disable-next-line vars-on-top, no-redeclare
        var result = { ...empty, ...groupResult };

        result = ObjectPropertyToUpperCase(
            result,
            this.state.sortDirection,
            this.state.groupByData
        );
        // this.setState({filteredRowData:result})
        // added by abu {

        if (this.state.customGroupedData.length === 0) {
            this.setState({ customGroupedData: result }, () => {
                const temp = Object.values(this.state.customGroupedData);
                const tempArray = [];
                temp.map((data) => {
                    data.map((array) => {
                        tempArray.push(array);
                    });
                });
                // this.setState({childUniqueData:tempArray})
                this.makeChildUniqueData(tempArray);
                // this.resetPopOverData(tempArray);
            });
        }
        // }
        return result;
    }

    makeChildUniqueData = (filteredData = []) => {
        const childUniqueData = {};
        const childTagNameValues = new Set();
        let makeData = this.state.rowData;
        makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (childUniqueData[property] === undefined) {
                    childUniqueData[property] = new Set();
                }

                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        childUniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        childUniqueData[property].add(
                            formatDate(
                                data[`${property}_rawdata`],
                                'MM/DD/YYYY'
                            )
                        );
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    childTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    childUniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    childUniqueData[property].add(data[property]);
                }
            }
        });

        this.setState({
            childUniqueData,
            childTagNameValues,
        });
    };

    removeAllGrouping() {
        this.setState({ refreshDataGrid: false });
        let paginatedRowData = this.state.allRows;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.filterDataGrid(this.state.filterTxt);
        }
        const startIndex =
            parseInt(this.state.currentPage, 10) *
            parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        paginatedRowData = this.getRowData(
            startIndex,
            endIndex,
            paginatedRowData
        );
        this.setState({
            groupByData: {},
            groupBy: [],
            filteredRowData: paginatedRowData,
            groupingData: {},
            tempExpandedGroupIds: new Set(),
            expandedGroupIds: new Set(),
            rowClickClass: this.state.row_transition
                ? this.state.rowClickClass
                : [],
            reInitiateGrid: true,
            refreshDataGrid: true,
        });
    }

    render() {
        // const selectDoc = (event, docId) => {
        //     if (event.target.checked) {
        //         this.setState({
        //             selectedDoc: [...this.state.selectedDoc, docId],
        //         });
        //     } else {
        //         const index = this.state.selectedDoc.indexOf(docId);
        //         if (index !== -1) {
        //             const array = this.state.selectedDoc;
        //             array.splice(index, 1);
        //             this.setState({
        //                 selectedDoc: array,
        //             });
        //         }
        //     }
        // };

        // const openPhysician = (docId) => {
        //     this.setState({
        //         openPhysician: true,
        //         docIds: [docId],
        //     });
        // };
        // const handleReset = () => {
        //     this.setState({
        //         openPhysician: false,
        //     });
        // };

        const temp = Array.from(this.state.selectedRows);
        temp.push(this.state.onRowClickId);

        /* var rows = this.state.rowData;allRows
        if(this.state.filteredRowData !=''){
            rows = this.state.filteredRowData;
        } */

        const stateRowData = this.state.filteredRowData;
        // var defaultHeight = 560;

        let reBuildGrid = {};
        if (this.state.reInitiateGrid) {
            // Need to Set this value to TRUE , if we face ROW COLLAPSE or DATAGRID Design issue
            reBuildGrid = { key: Math.random() };
        }
        let responsiveGrid = {};
        const toggleRowElement = (params) => {
            const { row } = params;
            const docid = row.id;
            const openedRowId = this.state.openedRowId;
            if (openedRowId[docid] === undefined) {
                openedRowId[docid] = true;
                this.setState({ openedRowId: openedRowId });
            } else {
                openedRowId[docid] = !openedRowId[docid];
                this.setState({ openedRowId: openedRowId });
            }
        };
        // isMobile
        if (isMobile && window.screen.width <= 960) {
            responsiveGrid = {
                rowRenderer: (props) => {
                    return (
                        <RowRenderer
                            {...props}
                            fullHeaderData={this.state.header}
                            openedRowId={this.state.openedRowId}
                            modifiedColumnsNames={Array.from(
                                this.state.modifiedColumnsNames
                            )}
                            dateFormatFields={Array.from(
                                this.state.dateFormatFields
                            )}
                            toggleRowElement={toggleRowElement}
                            fromPage='physician_listing'
                        />
                    );
                },
                rowHeight: (args) => {
                    const { type, row } = args;

                    if (type === 'GROUP') {
                        return 70;
                    }

                    if (
                        this.state.openedRowId[row.id] !== undefined &&
                        this.state.openedRowId[row.id] === true
                    ) {
                        return this.state.header.length > 4
                            ? this.state.header.length * 45
                            : this.state.header.length * 42;
                    }
                    return 60;
                },
                headerRowHeight: 0,
            };
        }

        var defaultHeight = window.innerHeight - 280;

        return (
            <>
                {this.state.loader ? (
                    <SpinnerBlur show={this.state.loader} />
                ) : null}
                <div className='right-aside py-5' id='right-aside'>
                    {this.state.groupBy.length > 0 ? (
                        <div
                            className='remove-grouping-all'
                            style={{ cursor: 'pointer' }}
                        >
                            <div className='count_no d-none'>
                                <span>12</span>
                            </div>
                            <i className='fa fa-list-alt' aria-hidden='true' />
                            <span
                                title='Remove Grouping'
                                onClick={() => this.removeAllGrouping()}
                            >
                                Remove Grouping{' '}
                            </span>
                        </div>
                    ) : null}
                    <div className='listing-grid grid-physicians '>
                        <div className='d-flex justify-content-between mb-5 pl-7 pr-7 report_header'>
                            <div className='d-flex align-items-center'>
                                <h3 className='m-0 title-color'>
                                    {this.props.pageTitle}
                                </h3>
                            </div>
                            <div className='d-flex filter_part rpt-filter'>
                                <div className='filter'>
                                    <input
                                        type='text'
                                        placeholder='Filter'
                                        value={this.state.filterTxt}
                                        onChange={(event) =>
                                            this.filterDataGrid(
                                                event.target.value
                                            )
                                        }
                                        style={{ paddingRight: '34px' }}
                                    />
                                    <i className='fa-regular fa-magnifying-glass' />
                                </div>
                                <div className='reload-outer'>
                                    <span
                                        className='align-self-center reload'
                                        onClick={() => this.refreshTable()}
                                    >
                                        {' '}
                                        <i
                                            title='Refresh'
                                            className={
                                                this.state.displayAnimation
                                                    ? 'fas fa-sync-alt refreshing'
                                                    : 'fas fa-sync-alt'
                                            }
                                        />{' '}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                this.state.groupBy.length > 0
                                    ? 'group-added no-check-box'
                                    : 'no-first-col'
                            }
                            id='physician_listing'
                        >
                            {this.state.refreshDataGrid === '' ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '30px',
                                    }}
                                >
                                    No Physicians Found
                                </div>
                            ) : null}

                            {this.state.refreshDataGrid ? (
                                <div
                                    className={
                                        this.state.groupBy.length > 0
                                            ? 'group-added'
                                            : 'no-first-col'
                                    }
                                >
                                    <DndProvider backend={HTML5Backend}>
                                        <DataGrid
                                            {...reBuildGrid}
                                            columns={this.draggableColumns()}
                                            // columns={this.state.header}
                                            rows={stateRowData}
                                            rowsCount={stateRowData.length}
                                            selectedRows={
                                                this.state.selectedRows
                                            }
                                            onSelectedRowsChange={(rows) => {
                                                const selectedRows = rows;
                                                this.setState({
                                                    selectedRows: selectedRows,
                                                    // selectedDocIds: selectedRows
                                                });
                                            }}
                                            expandedGroupIds={
                                                this.state.tempExpandedGroupIds
                                            }
                                            onExpandedGroupIdsChange={(
                                                groupId
                                            ) => {
                                                this.setState({
                                                    expandedGroupIds: groupId,
                                                    tempExpandedGroupIds:
                                                        groupId,
                                                });
                                            }}
                                            // headerRowHeight={0}
                                            rowHeight={52}
                                            // summaryRowHeight={20}
                                            rowKeyGetter={(row) => {
                                                return row.id;
                                            }}
                                            // onRowClick={(rowDetails, rowHeader) => {
                                            //     this.onRowClick(
                                            //         rowDetails,
                                            //         rowHeader
                                            //     );
                                            // }}
                                            emptyRowsRenderer={NoRecordFound.bind(
                                                this,
                                                this.state.relDocLoading,
                                                'Physician'
                                            )}
                                            style={{
                                                height: defaultHeight,
                                                borderRight: '50px',
                                            }}
                                            className='fill-grid '
                                            rowClass={(row) => {
                                                if (
                                                    this.state.rowClickClass.indexOf(
                                                        row.id
                                                    ) !== -1
                                                ) {
                                                    return 'row-selected-once';
                                                }
                                            }}
                                            defaultColumnOptions={{
                                                sortable: true,
                                                resizable: true,
                                                frozen: false,
                                            }}
                                            rowGrouper={(
                                                groupedData,
                                                indexProp
                                            ) =>
                                                this.createGroupByRows(
                                                    groupedData,
                                                    indexProp
                                                )
                                            }
                                            // not defined function(onRowClick) compile error 04-08-2022
                                            // onRowClick={(
                                            //     rowDetails,
                                            //     rowHeader
                                            // ) => {
                                            //     onRowClick(
                                            //         rowDetails,
                                            //         rowHeader,
                                            //         this.state
                                            //     );
                                            // }}
                                            onColumnResize={(idx, width) => {
                                                customizedWidth(
                                                    idx,
                                                    width,
                                                    this.state.header,
                                                    this.props.location
                                                        .pathname,
                                                    this.state.listingFor
                                                );
                                            }}
                                            groupBy={this.state.groupBy}
                                            headerRowHeight={40}
                                            {...responsiveGrid}
                                        />
                                    </DndProvider>
                                </div>
                            ) : null}
                        </div>
                        {this.state.refreshDataGrid &&
                        this.state.groupBy.length === 0 &&
                        this.state.rowData.length > 0 ? (
                            <div>
                                <TablePagination
                                    component='div'
                                    count={this.state.totalCount}
                                    page={parseInt(this.state.currentPage, 10)}
                                    rowsPerPage={parseInt(
                                        this.state.perPage,
                                        10
                                    )}
                                    onRowsPerPageChange={(event) =>
                                        this.handleChangeRowsPerPage(event)
                                    }
                                    onPageChange={this.nextPage.bind(this)}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = { inboxPage };
const mapStateToProps = (state, ownProps) => {
    return {
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PhysicianListingDataGrid));
