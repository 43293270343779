import { Entity } from "../models/interfaces";

export const mapDataRowToEntity = (rows: Entity[], selectedRowId: any): any => {
    const selectedRow = rows.find((row) => row.id === selectedRowId);
    return {
        communicationEmail: selectedRow?.entitycommunicationemail,
        firstName: selectedRow?.entityfirstname,
        lastName: selectedRow?.entitylastname,
        fullNameWithTitle: selectedRow?.fullnamewithtitle,
        countryCode: selectedRow?.countrycode,
        smsNumber: selectedRow?.smsnumber,
        lookupKeyType: selectedRow?.lookupkeytype,
        lookupKeyValue: selectedRow?.lookupkeyvalue,
        resendDays: selectedRow?.resenddays,
        keepDocumentsFromGroups:
            selectedRow?.keepdocumentsfromgroup === 'True' ? true : false,
        disableAutoResend:
            selectedRow?.disableautoresend === 'True' ? true : false,
        welcomeSender: selectedRow?.welcomesender,
        changeCounter: selectedRow?.changecounter,
    };
};

