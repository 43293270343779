import React from 'react';
// import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useFocusRef } from './useFocusRef';

/* const checkboxLabel = `
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: 1px; // align checkbox in row group cell
`; */

// const checkboxLabelClassname = `rdg-checkbox-label ${checkboxLabel}`;

/* const checkboxInput = `
  all: unset;
  width: 0;
  margin: 0;
`; */

// const checkboxInputClassname = `rdg-checkbox-input ${checkboxInput}`;

/* const checkbox = `
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid var(--border-color);
  background-color: var(--background-color);

  .${checkboxInput}:checked + & {
    background-color: var(--checkbox-color);
    box-shadow: inset 0px 0px 0px 4px var(--background-color);
  }

  .${checkboxInput}:focus + & {
    border-color: var(--checkbox-focus-color);
  }
`; */

// const checkboxClassname = `rdg-checkbox ${checkbox}`;

/* const checkboxLabelDisabled = `
  cursor: default;

  .${checkbox} {
    border-color: var(--checkbox-disabled-border-color);
    background-color: var(--checkbox-disabled-background-color);
  }
`; */

// const checkboxLabelDisabledClassname = `rdg-checkbox-label-disabled ${checkboxLabelDisabled}`;

export function SelectCellFormatter({
    value,
    tabIndex,
    isCellSelected,
    disabled,
    onClick,
    onChange,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    columnType,
    selectedCount,
    row_transition,
}) {
    const inputRef = useFocusRef(isCellSelected);

    function handleChange(e) {
        onChange(e.target.checked, e.nativeEvent.shiftKey);
    }
    let headerCls = '';
    if (columnType === 'select-row' && selectedCount > 0) {
        headerCls = 'header-checkbox';
    }
    if (row_transition === undefined || row_transition === '') {
        row_transition = false;
    }
    let classNameVal = 'lock-placement';
    if (isMobile && window.screen.width <= 960) {
        classNameVal = 'lock-placement d-flex align-items-center';
    }
    // const pathname = window.location.pathname;
    // var path = pathname.split('/');
    const disable_checkbox = false;

    // let userName = '';
    let userDetail = localStorage.getItem('user_details');
    if (userDetail !== null) {
        userDetail = JSON.parse(localStorage.getItem('user_details'));
        // userName = userDetail.userName;
    }
    /* if (path[1] === 'outbox' && userName !== 'WV-BPRASANNA') {
    disable_checkbox = true;
} */
    return (
        <div className={classNameVal} key={Math.random()}>
            <label className='rdg-checkbox-label'>
                <input
                    key={Math.random()}
                    style={{
                        marginTop: '10px',
                        marginLeft: '10px',
                    }}
                    aria-label={ariaLabel}
                    aria-labelledby={ariaLabelledBy}
                    tabIndex={tabIndex}
                    ref={inputRef}
                    type='checkbox'
                    className={`rdg-checkbox-input ${headerCls}`}
                    //  disabled={disabled}
                    checked={value}
                    onChange={handleChange}
                    onClick={onClick}
                    // disabled={localStorage.getItem("disable_checkbox")==='true'?true:false}
                    // disabled={!!row_transition}
                    disabled={disable_checkbox}
                />
                <div className='rdg-checkbox' />{' '}
            </label>
        </div>
    );
}
