/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    GetFieldData,
    stringEmptyOrUndefined,
    formatNameWithSpaceToUnderscore,
    formatPathName,
} from '../../../components/commonfunction';
import Spinner from '../../../components/Spinner';

export class FilterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledClass: '',
            customSearch: this.props.customSearch,
            checkList: {},
            keyGen: ['Dates'],
            loading: true,
        };
    }

    componentDidMount() {
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldIds: fieldDataObj });
        this.setState({
            pathName: this.props.location.pathname,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const current_path = this.props.location.pathname;

        let pathname = formatPathName(current_path);

        let selectedFilterOptions = localStorage.getItem(
            `filter-options-${pathname}`
        );

        if (prevProps.customSearch !== this.props.customSearch) {
            this.setState({
                customSearch: this.props.customSearch,
            });
        }

        let arrayData = [];
        if (
            selectedFilterOptions !== '' &&
            selectedFilterOptions !== null &&
            selectedFilterOptions !== undefined &&
            String(selectedFilterOptions) !== 'undefined'
        ) {
            const dataFilter = JSON.parse(selectedFilterOptions);

            if (
                JSON.stringify(this.state.selectedFilterOptions) !==
                JSON.stringify(dataFilter)
            ) {
                this.setState({
                    selectedFilterOptions: dataFilter,
                    checkList: dataFilter,
                });
                for (const property in dataFilter) {
                    arrayData.push(dataFilter[property]);
                }
                this.setState({
                    keyGen: this.state.keyGen.concat(arrayData),
                });
            }
        }
        const currentPageFilterOptions = this.state.customSearch.find(
            (item) => item.name === this.props.pageTitle
        );
        if (
            this.state.loading &&
            this.state.customSearch &&
            currentPageFilterOptions
        ) {
            this.setState({ loading: false });
        }
    }

    handleCheckBox = async (event) => {
        const value = event.target.value;
        let arrayList;
        if (this.state.keyGen.includes(event.target.value)) {
            for (const property in this.state.checkList) {
                if (
                    this.state.checkList[property] !== '' &&
                    this.state.checkList[property] === value
                ) {
                    this.setState({
                        keyGen: this.state.keyGen.filter(function (val) {
                            return val !== value;
                        }),
                    });
                } else {
                    arrayList = {
                        ...arrayList,
                        [property]: this.state.checkList[property],
                    };
                }
            }
            this.setState({
                checkList: arrayList,
            });
        } else {
            this.props.configData.fields.map((item) => {
                if (item.name === value) {
                    this.setState({
                        checkList: {
                            ...this.state.checkList,
                            [item.fieldId]: item.name,
                        },
                        keyGen: this.state.keyGen.concat([value]),
                    });
                }
            });
        }
    };

    saveFilter = () => {
        let pathname = this.props.location.pathname;

        pathname = formatPathName(pathname);
        localStorage.setItem(
            `filter-options-${pathname}`,
            JSON.stringify(this.state.checkList)
        );
        this.setState({
            selectedFilterOptions: this.state.checkList,
        });
        this.updateState({
            open_filter: false,
            filterState: 'not_initial',
            mainFilter: false,
        });
    };

    loopingCheckBox() {
        let customSearchQueries = this.state.customSearch;

        return customSearchQueries?.map((customSearch, index) => {
            if (
                formatNameWithSpaceToUnderscore(customSearch.name) ===
                formatNameWithSpaceToUnderscore(this.props.custom_search_name)
            ) {
                return (
                    <React.Fragment key={index}>
                        {customSearch.fields.map((data, index) => {
                            if (
                                customSearch.inputSearchFieldIdsAndValues
                                    .length > 0 &&
                                customSearch.inputSearchFieldIdsAndValues[0]
                                    .fieldId === data.fieldId
                            ) {
                            } else {
                                return (
                                    <div className='col-sm-4 col-6' key={index}>
                                        <label className='checkbox'>
                                            <input
                                                type='checkbox'
                                                name='Checkboxes3'
                                                value={data.name}
                                                onClick={(e) => {
                                                    this.handleCheckBox(e);
                                                }}
                                                defaultChecked={
                                                    this.state.keyGen.includes(
                                                        data.name
                                                    )
                                                        ? 'checked'
                                                        : ''
                                                }
                                            />
                                            <span></span>
                                            {data.name}
                                        </label>
                                    </div>
                                );
                            }
                        })}
                    </React.Fragment>
                );
            }
        });
    }

    closeFilter = () => {
        this.updateState({
            open_filter: false,
        });
    };

    updateState = (state) => {
        this.props.updateState(state);
    };
    render() {
        return (
            <>
                <Modal
                    className='delete-popup'
                    show={this.props.open_filter}
                    onHide={this.closeFilter}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i className='fa-regular fa-sliders mr-2' />{' '}
                                Edit Filters
                            </div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.closeFilter}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='edit_filters'>
                        <span>
                            Select filters to narrow down your search by.
                        </span>
                        <div className='form-group row '>
                            <div className='checkbox-inline'>
                                <div className='col-sm-4 col-6'>
                                    <label className='checkbox checkbox-disabled'>
                                        <input
                                            type='checkbox'
                                            className='disabled'
                                            checked='checked'
                                            disabled='disabled'
                                            name='Checkboxes3'
                                        />
                                        <span></span>
                                        Dates(from and to){' '}
                                    </label>
                                </div>
                                {this.state.loading ? (
                                    <div className='col-12 text-center'>
                                        <Spinner show={this.state.loading} />
                                    </div>
                                ) : (
                                    <div>{this.loopingCheckBox()}</div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    title='Cancel'
                                    variant='secondary'
                                    onClick={this.closeFilter}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                <div
                                    className='bulk'
                                    style={{ display: 'flex' }}
                                >
                                    <Button
                                        variant='success'
                                        title='Save'
                                        onClick={this.saveFilter}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
    return {
        ddl: state.persistDDL.DDL,

        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        customSearch:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(
                state.configReducerPersist.configData.customSearchQueries
            )
                ? state.configReducerPersist.configData.customSearchQueries
                : [],
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(FilterModal))
);
