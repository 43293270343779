/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { outboxDocumentAction, addDocumentNotes } from '../../api/api';
import {
    getListaction,
    updateOutboxState,
    updateList,
    outboxPage,
} from '../../_redux/actions/outboxActions';
import {
    showSuccessToast,
    showErrorToast,
    GetFieldData,
    stringEmptyOrUndefined,
    getNotes,
    RefreshCounts,
} from '../../../components/commonfunction';
import { getCountData } from '../../_redux/actions/inboxActions';

export class RemoveTrackingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledClass: '',
            commentTextCount: 250,
            allActions: this.props.outbox.stateData.allActions,
            removeNotes: '',
            removeBtn: false,
            postLoading: `post_loading_${Math.random()}`,
            submitted: 0,
            posted: `posted_${Math.random()}`,
        };
    }

    componentDidMount() {
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldIds: fieldDataObj });

        const noteTypes = getNotes(
            this.props.configData,
            this.props.processId,
            this.props.statusId
        );

        if (noteTypes.length > 0) {
            noteTypes.map((data, i) => {
                if (data.name === 'Note') {
                    this.setState({
                        noteFieldId: data.fieldId,
                        noteNoteId: data.noteId,
                        noteName: data.name,
                    });
                }
            });
        }
    }

    closeRemoveTracking = () => {
        this.updateState({
            open_remove_tracking: false,
        });
        this.setState({
            submitted: 0,
        });
    };

    updateState = (state) => {
        this.props.updateState(state);
    };

    handleInputOnChange = (event) => {
        const removeNotes = event.target.value;
        const length = event.target.value.length;
        const count = 250 - length;

        this.setState({ removeNotes: removeNotes }, () => {
            this.updateState({ removeNotes: removeNotes });
        });
        if (length > 250) {
            this.setState({
                commentTextCount: 0,
            });
        } else {
            this.setState({
                commentTextCount: count,
            });
        }
    };

    removeTracking = (action_name) => {
        console.log(this.props);
        // validate
        this.setState({
            submitted: 1,
        });
        // const removeNotes = this.state.removeNotes;
        let actionId = '';
        if (this.props.outbox.stateData.allActions[action_name]) {
            actionId =
                this.props.outbox.stateData.allActions[action_name].actionId;
        } else if (this.props.allActions[action_name]) {
            actionId = this.props.allActions[action_name].actionId;
        } else if (this.props.deleteDocument === 'deleted_document') {
            actionId = this.props.allActions[action_name].actionId;
        } else if (this.props.allActions !== undefined) {
            actionId = this.props.allActions.actionId;
        }
        const docIds = [];
        if (this.props.deleteDocument !== 'deleted_document') {
            if (
                this.props.calingFrom === 'smallView' ||
                this.props.calingFrom === 'bigView'
            ) {
                if (this.props.outbox.stateData.selectedDocId) {
                    docIds.push(this.props.outbox.stateData.selectedDocId);
                } else {
                    docIds.push(this.props.selectedDocId);
                }
            } else {
                const selectedIndex = Array.from(
                    this.props.outbox.stateData.selectedRows
                );

                this.props.outbox.stateData.rowData.map((data, index) => {
                    if (selectedIndex.includes(data.id)) {
                        docIds.push(data.doc_id);
                    }
                });
            }
        }

        let processId;
        let statusId;

        if (this.props.deleteDocument === 'deleted_document') {
            processId = this.props.delprocessId;
            statusId = this.props.delstatusId;
            docIds.push(this.props.selectedDocId);
        } else {
            processId = this.props.processId || this.props.reportProcessId;
            statusId = this.props.statusId || this.props.reportStatusId;
        }

        const params = {};
        const fields = {};
        fields.docIds = docIds;
        /* if (removeNotes !== '') {
            fields.fields = [
                {
                    fieldId: this.state.allFieldIds.note,
                    value: this.state.removeNotes,
                },
            ];
        } else {
            fields.fields = [];
        } */
        fields.fields = [];
        this.setState({
            removeBtn: true,
        });
        params.processId = processId;
        params.statusId = statusId;
        params.actionId = actionId;
        params.fields = fields;

        const notesField = {};
        notesField.noteFieldId = this.state.noteFieldId;
        notesField.noteTypeId = this.state.noteNoteId;
        notesField.noteValue = this.state.removeNotes;
        let status = '';
        let response;
        outboxDocumentAction(params)
            .then((response) => {
                response = response;
                status = response.status;
                if (response.status === 400) {
                    if (response.message !== undefined) {
                        const { message } = response;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');
                        showErrorToast(error);
                    } else {
                        this.setState({
                            removeBtn: false,
                            submitted: 0,
                        });
                        showErrorToast('Error in Processing.');
                    }
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                if (data.hadFailures === false && status === 200) {
                    addDocumentNotes(
                        docIds,
                        notesField,
                        processId,
                        statusId
                    ).then((data) => {
                        if (data.status === 400) {
                            if (data.message) {
                                const { message } = response;
                                let error = message.replace(/[0-9]/g, '');
                                error = error.replace(/:/g, '');
                                showErrorToast(error);
                            } else {
                                this.setState({
                                    removeBtn: false,
                                    submitted: 0,
                                });
                                showErrorToast('Cannot save the Notes.');
                            }
                        } else {
                            showSuccessToast(
                                'You have stopped tracking the selected documents.'
                            );
                            this.setState({
                                submitted: 0,
                            });
                            this.updateState({
                                open_remove_tracking: false,
                            });
                            if (
                                this.props.deleteDocument !==
                                    'deleted_document' &&
                                this.props.fromSearch !== 'search' &&
                                this.props.reportView !== 'report'
                            ) {
                                /*  this.props.getListaction(
                                    this.props.outbox.outbox.processId,
                                    this.props.outbox.outbox.statusId
                                ); */
                                if (this.props.calingFrom === 'actionBar') {
                                    /* this.props.refresh_whole();
                                    document.body.classList.remove(
                                        'aside-minimize'
                                    );
                                    document.body.classList.remove(
                                        'doc-small-view'
                                    );
                                    this.props.updateOutboxState({
                                        selectedRows: new Set(),
                                        row_transition: false,
                                    }); */
                                } else {
                                    // this.props.outboxrefreshTable();

                                    const selectedRowDetailsID =
                                        this.props.outbox.selectedRowDetails
                                            .miscPproperties.docId;

                                    let filteredData =
                                        this.props.outbox.stateData
                                            .filterTxt !== ''
                                            ? this.props.outbox.outboxpage
                                                  .allFilteredRowData
                                            : this.props.outbox.stateData
                                                  .rowData;

                                    let status = '';
                                    const rowsToDelete = [];
                                    let nextIndex = '';
                                    let currentIndex;
                                    let nextDocId = '';
                                    if (
                                        this.props.calingFrom !==
                                        'deleted_document'
                                    ) {
                                        const rowLen = filteredData.length;

                                        filteredData.map((tableData, i) => {
                                            if (
                                                tableData.doc_id ===
                                                selectedRowDetailsID
                                            ) {
                                                if (rowLen === i + 1) {
                                                    nextIndex = '';
                                                } else {
                                                    nextIndex = i + 1;
                                                    currentIndex = tableData.id;
                                                }
                                            }
                                        });

                                        if (nextIndex !== '') {
                                            rowsToDelete.push(
                                                filteredData[nextIndex]
                                            );
                                            nextDocId =
                                                filteredData[nextIndex].doc_id;
                                        }
                                    }
                                    var rowStateData =
                                        this.props.outbox.stateData
                                            .filterTxt !== ''
                                            ? this.props.outbox.outboxpage.allFilteredRowData.slice()
                                            : this.props.outbox.stateData.rowData.slice();

                                    rowStateData = rowStateData.filter(
                                        (obj) => obj.id !== currentIndex
                                    );

                                    let dataRows =
                                        this.props.outbox.outbox.dataTable.dataRows.filter(
                                            (obj) =>
                                                obj.id !== selectedRowDetailsID
                                        );

                                    this.props.outbox.outbox.dataTable.dataRows =
                                        dataRows;

                                    this.props.updateList(
                                        this.props.outbox.outbox
                                    );
                                    if (
                                        this.props.outbox.stateData
                                            .filterTxt !== ''
                                    ) {
                                        this.props.outboxPage({
                                            allFilteredRowData: rowStateData,
                                            deleteRow: true,
                                        });
                                    } else {
                                        this.props.outboxPage({
                                            //allRowData: rowStateData,
                                            deleteRow: true,
                                            stateData: {
                                                ...this.props.stateData,
                                                rowData: rowStateData,
                                            },
                                            //stat
                                        });
                                    }
                                    RefreshCounts(
                                        this.props.outbox.processId,
                                        this.props.outbox.statusId,
                                        this.props //Here count from parent and getCountData is passing
                                    );
                                    this.props.rowTransition();
                                    document.body.classList.remove(
                                        'aside-minimize'
                                    );
                                    document.body.classList.remove(
                                        'doc-small-view'
                                    );
                                    document.body.classList.remove(
                                        'doc-detail-view'
                                    );
                                }
                            } else {
                                if (
                                    typeof this.props.closeBigView ===
                                        'function' &&
                                    this.props.reportView !== 'report'
                                ) {
                                    this.props.closeBigView();
                                }
                            }
                        }
                    });
                } else {
                    let failedDocId = [];
                    let failedMessage = [];
                    let message = '';
                    if (data.hadFailures === true) {
                        data.results.map((item, idx) => {
                            if (item.actionSucceeded === false) {
                                failedDocId.push(item.docId);
                                failedMessage.push(item.message);
                            }
                        });
                        failedDocId.map((property, index) => {
                            if (index === 0) {
                                message += '[';
                            }

                            message += property + ' ';
                            if (failedDocId.length - 1 === index) {
                                message += ']';
                            }
                        });
                        let errorMsg = failedMessage[0].includes('lock')
                            ? 'Unable to obtain document lock'
                            : failedMessage[0];
                        showErrorToast(errorMsg + ' ' + message);
                    }

                    this.setState({
                        removeBtn: false,
                        submitted: 0,
                    });
                }
            });
    };

    render() {
        return (
            <>
                <Modal
                    className='delete-popup'
                    show={this.props.open_remove_tracking}
                    onHide={this.closeRemoveTracking.bind(this, false)}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i
                                    className={
                                        this.state.allActions.hasOwnProperty(
                                            'remove_from_tracking'
                                        ) &&
                                        this.state.allActions
                                            .remove_from_tracking.icon !== null
                                            ? this.state.allActions
                                                  .remove_from_tracking.icon
                                            : 'fas fa-minus-circle mr-3'
                                    }
                                    style={{
                                        color:
                                            this.state.allActions.hasOwnProperty(
                                                'remove_from_tracking'
                                            ) &&
                                            this.state.allActions
                                                .remove_from_tracking.color !==
                                                null
                                                ? this.state.allActions
                                                      .remove_from_tracking
                                                      .color
                                                : '#D84C3A',
                                    }}
                                />{' '}
                                Remove Tracking
                            </div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.closeRemoveTracking.bind(
                                    this,
                                    false
                                )}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes'>
                        <h6>
                            You are about to remove tracking for{' '}
                            {this.props.rowsToRemove === 1
                                ? 'this file.'
                                : `${this.props.rowsToRemove} files.`}{' '}
                            Are you sure you want to do this?
                        </h6>
                        <span>
                            When you remove tracking, the documents will only be
                            accessible from search and you will have to 'Resume
                            tracking' to be able to see them here again.
                        </span>
                        <div className='form-group row '>
                            <div className='col-lg-12 mt-5 transition-select'>
                                <label className='pl-5'>NOTE </label>
                                <textarea
                                    className={
                                        this.state.validateCreate
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    maxLength='250'
                                    onChange={this.handleInputOnChange}
                                    defaultValue={this.state.removeNotes}
                                />
                                <div className='maximum-length'>
                                    <span className='float-left'>
                                        Maximum Length:250
                                    </span>
                                    <span className='float-right'>
                                        Remaining :{this.state.commentTextCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    title='Cancel'
                                    variant='secondary'
                                    onClick={this.closeRemoveTracking.bind(
                                        this,
                                        false
                                    )}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                <div
                                    className='bulk'
                                    style={{ display: 'flex' }}
                                >
                                    {this.state.submitted === 1 ? (
                                        <div
                                            className='posted_btn load_img'
                                            role='group'
                                        >
                                            <button
                                                className='post_loading btn btn-danger'
                                                id={this.state.postLoading}
                                                type='button'
                                            >
                                                <span
                                                    className='spinner-border spinner-border-sm'
                                                    role='status'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                this.state.disabledClass === ''
                                                    ? 'btn btn-danger'
                                                    : 'btn btn-danger disabled'
                                            }
                                            title='Yes, Remove Tracking'
                                            onClick={() => {
                                                if (
                                                    this.state.disabledClass ===
                                                        '' &&
                                                    this.state.removeBtn ===
                                                        false
                                                ) {
                                                    this.removeTracking(
                                                        'remove_from_tracking'
                                                    );
                                                }
                                            }}
                                            disabled={this.state.removeBtn}
                                        >
                                            Yes, Remove Tracking
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    getCountData,
    getListaction,
    updateOutboxState,
    updateList,
    outboxPage,
};
const mapStateToProps = (state) => {
    return {
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        count: state.persist.count,
        ddl: state.persistDDL.DDL,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveTrackingModal);
