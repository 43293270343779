//import React from 'react';
/*import {
    allStorage,
    unlockAllDocumentInStorage,
} from '../components/commonfunction';*/

export function Logout() {
    //const API_URL = process.env.REACT_APP_EXTERNAL_API_URL;
    /*const logoutClick = async () => {
       
        const reponse = unlockAllDocumentInStorage();
        reponse.then((data) => {
            // Need to handle
        });
        fetch(`${API_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        }).then((response) => {
        });
        const remember_me = localStorage.getItem('remember_me');
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        const localStorageData = allStorage();
        const headerStorage = {};
        for (const name in localStorageData) {
            if (
                String(name).includes('header-reorder') ||
                String(name).includes('header-width')
            ) {
                headerStorage[name] = localStorageData[name];
            }
        }
        await localStorage.clear();
        localStorage.setItem('remember_me', remember_me);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        for (const name in headerStorage) {
            localStorage.setItem(name, headerStorage[name]);
        }
        window.location.reload();
    };*/
    // 	let remember_me = 	false;
    // 	let username	=	'';
    // 	let password	=	'';
    // 	let header_width =localStorage.getItem('header-width');
    // 	if(localStorage.getItem('remember_me') && localStorage.getItem('remember_me') === true) {
    // 		remember_me	=	localStorage.getItem('remember_me');
    // 		username	=	localStorage.getItem('username');
    // 		password	=	localStorage.getItem('password');
    // 	}
    // 	localStorage.clear();
    // 	localStorage.setItem('remember_me',remember_me);
    // 	localStorage.setItem('username',username);
    // 	localStorage.setItem('password',password);
    // 	localStorage.setItem('header-width',header_width);
    // 	var  url = window.location.href ;
    // 	var domain = url.split("/");
    // 	//var userid = localStorage.getItem("userid");
    // 	localStorage.clear();
    // 	window.location.href = domain[0]+"/auth/login";
    // 	window.location.reload();
    // return null;
}

export default Logout;
