/* eslint-disable array-callback-return */
import React from 'react';
import Autocomplete from 'react-autocomplete';
import {
    OverlayTrigger,
    Tooltip,
    Dropdown,
    // Modal,
} from 'react-bootstrap';
import { withRouter, NavLink } from 'react-router-dom';

import update from 'immutability-helper';
import { connect } from 'react-redux';
import AllowMultipleEditModal from './AllowMultipleEditModal';
import { Notes } from '../../pages/inbox/components/Notes';
import TransitionComponent from '../../pages/inbox/components/TransitionComponent';
import DeleteModal from '../../pages/inbox/components/DeleteModal';
import PrintPdfDocument from '../../pages/inbox/components/PrintPdfDocument';
import UpdateFaxDetails from '../../pages/inbox/components/UpdateFaxDetails';
import {
    // getOwnersList,
    showErrorToast,
    stringEmptyOrUndefined,
    userPermission,
    ownerCellFormatter,
    createTagElement,
    closeDeletePopUp,
    download_multiple,
    getDocumentsOwners,
    sortFields,
    GetFieldData,
    showSuccessToast,
    getNotes,
    getTagFromStorage,
} from '../commonfunction';
import {
    // downloadDoc,
    addDocumentOwners,
    addDocumentTags,
    outboxDocumentAction,
} from '../../pages/api/api';
import {
    setSelectedRow,
    setOwner,
    updateList,
    getListaction,
    updateInboxState,
    showLoader,
    inboxPage,
} from '../../pages/_redux/actions/inboxActions';
import OwnerRowAction from '../commonAction/OwnerRowAction';
import Office365AuthenticationPage from '../composeDraftMail/Office365AuthenticationPage';
import GmailAuthenticationPage from '../composeDraftMail/GmailAuthenticationPage';
import { DocumentIdToPrint } from '../../../utils/printPdf/printPdf';
import { AddTags } from '../commonAction/RowSelTags';
import ExportMenu from '../../pages/inbox/components/ExportMenu';

const _ = require('lodash');
var deletetag_id_local = [];
class RowSelectionComponent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            urlChange: true,
            ownerList: [],
            selectedRows: new Set(),
            ownerValue: null,
            // show_tag: false,
            // show_owner: false,
            open_replacetag: false,
            refresh_delete: true,
            menu_tags: [],
            close_tagmenu: false,
            open_addtag: false,
            open_deletetag: false,
            menu_selected_tagonly: [],
            menu_selected_tag: [],
            tagLoader: false,
            open_transition: false,
            menu_branch: null,
            menu_group: null,
            menu_inbox: null,
            rowsTansition: [],
            printSelectedDocId: [],
            printLoader: false,
            openDelete: false,
            restrictAllowEdit: false,
            rowsToDelete: [],
            show_owner: false,
            show_tag: false,
            show_branch: false,
            show_group: false,
            show_inbox: false,
            show_notes: true, // default true
            show_pdf: true, // default true
            show_delete: false,
            show_print: false,
            show_download: false,
            processId: null,
            statusId: null,
            statusArray: [],
            branchActionId: null,
            groupActionId: null,
            docIdForNotes: [],
            notesVal: '',
            editURL: '/documents/',
            allActions: this.props.allActions,
            noteTypes: [],
            serviceType: false,
            arrayOfHeader: [],
        };
    }

    componentDidMount() {
        const getDLL = async () => {
            const { menu_tags, newTagColorArray } = await getTagFromStorage(
                this.props.configData,
                this.props.ddl.tags
            );

            this.setState({
                menu_tags: menu_tags,
            });
        };

        getDLL();

        const serviceType =
            this.props.configData?.emailIntegration?.serviceType ===
                'Office 365' ||
            this.props.configData?.emailIntegration?.serviceType === 'Google'
                ? true
                : false;
        this.setState({ serviceType }); // if service type is "Exchange" will implement later
        this._isMounted = true;
        const owners = getDocumentsOwners(this.props.owners);
        this.setState({
            ownerList: owners,
        });
        this.editUrl();
        const noteTypes = getNotes(
            this.props.configData,
            this.props.processId,
            this.props.statusId
        );
        this.setState({
            noteTypes: noteTypes,
        });
        let arrayOfHeader = [];
        this.props.inbox.inbox.dataTable.headerColumns.map((data) => {
            arrayOfHeader.push(data.name);
        });
        this.setState({
            arrayOfHeader: arrayOfHeader,
        });
    }

    editUrl = async () => {
        this.setState({ urlChange: false });
        const selected_rows = Array.from(this.props.selectedRows);

        // ("selected_rows 1",selected_rows)
        // ("selected_rows 3",this.props.rowData)
        let editId = '';
        let multipleID = '';

        //above is commented since client asked to give on click format index
        await selected_rows.map(async (rowIndex) => {
            await this.props.rowData.map(async (data, index) => {
                if (rowIndex === data.id) {
                    if (multipleID === '') {
                        multipleID += `${window.btoa(String(data.doc_id))}`;
                    } else {
                        multipleID += `/${window.btoa(String(data.doc_id))}`;
                    }
                }
            });
        });

        let urlConst;
        // let ids;
        let urlPathName;

        if (this.props.calingFrom === 'search') {
            urlConst = `${this.props.location.pathname}/editmultiple?editmultiple=${editId}`;
            urlPathName = `${this.props.location.pathname}/editmultiple`;
            // ids = `${this.props.location.pathname}/editmultiple?editID=${editId}`;
        } else {
            urlConst = `/inbox/editmultiple?editmultiple=${multipleID}`;
            urlPathName = '/inbox/editmultiple';
            // ids = `/inbox/editmultiple?editID=${editId}`;
        }

        this.setState({
            editURL: urlConst,
            urlChange: true,
            multipleID: multipleID,
            urlPathName: urlPathName,
        });

        // ("selected_rows 2",this.state.editURL)
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedRows !== this.props.selectedRows) {
            this.editUrl();
        }
        const prevSelectedRows = Array.from(prevState.selectedRows);
        const propsSelectedRows = Array.from(this.props.selectedRows);
        let isNotContain = 0;
        propsSelectedRows.forEach((data) => {
            if (prevSelectedRows.indexOf(data) === -1) {
                isNotContain++;
            }
        });
        // const owners = getDocumentsOwners(this.props.owners);
        if (isNotContain > 0 || prevSelectedRows.length === 0) {
            let permission = userPermission({
                processId: this.props.processId,
                statusId: this.props.statusId,
                menu_name: this.props.currentMenuName,
                configData: this.props.configData,
            });

            permission = { ...permission, show_pdf: true, show_notes: true }; // default true
            // ();
            const propStateObj = this.props;
            const newState = { ...prevState, ...propStateObj };
            this.setState({
                ...newState,
                ...permission,
                permission: permission,
                // owners: owners,
            });
        }
        if (
            prevState.ownerValue !== this.props.ownerValue &&
            this.props.ownerValue !== ''
        ) {
            // this.handleOwnerChange(this.props.ownerValue);
        }
        // UPDATE STATE EVENT
        if (prevProps.stateToken !== this.props.stateToken) {
            // stateToken Varaible  denotes that the COMPONENT STATE varaible was changed
            this.setState({
                // ...this.props.stateData,
                selectedRows: this.props.selectedRows,
                menu_inbox: this.state.menu_inbox,
                ownerActionId: this.state.ownerActionId,
                ownerFieldId: this.state.ownerFieldId,
                //owners: owners,
            });
        }

        // print
        if (prevState.printSelectedDocId !== this.state.printSelectedDocId) {
            if (this.state.printSelectedDocId.length > 0) {
                DocumentIdToPrint(this.state.printSelectedDocId)
                    .then(() => {
                        this.props.showLoader(false);
                    })
                    .catch((err) => {
                        this.props.showLoader(false);
                        console.log('error', err);
                    });
            }
        }
    }

    getData = (values) => {
        this.setState(values);
    };

    add_tag = async (tag, colour) => {
        await AddTags(
            tag,
            this.state,
            this.getData,
            this.props,
            'inbox',
            'add'
        );
    };

    /*
    replace_remove_tag_REMOVED = async (tag_index, value) => {

       
        let errorCount = 0;
        this.setState({ spinner: true, tag_action: true, tagLoader: true });
        if (tag_index !== '') {
            this.setState({ menu_selected_tagonly: [] });
            this.state.menu_selected_tag.splice(tag_index, 1);
            await this.setState(
                { menu_selected_tag: this.state.menu_selected_tag },
                () => {
                    this.state.menu_selected_tag.forEach((data) => {
                        this.state.menu_selected_tagonly.push(data.name);
                    });
                }
            );
            this.setState({ spinner: false });
            this.setState({ refresh_delete: true, delete_tag: true });
        }
        if (value !== '') {
            const index_tagonly =
                this.state.menu_selected_tagonly.indexOf(value);
            this.state.menu_selected_tagonly.splice(index_tagonly, 1);
            this.state.menu_selected_tag.forEach((data, index_main) => {
                if (data.name === value) {
                    this.state.menu_selected_tag.splice(index_main, 1);
                    this.setState(
                        {
                            menu_selected_tagonly:
                                this.state.menu_selected_tagonly,
                        },
                        () => {
                            this.setState({ spinner: false });
                            this.setState({
                                refresh_delete: true,
                                delete_tag: true,
                            });
                        }
                    );
                }
            });
        }

        const docid = [];
        const tagIndex = [];
        const selected_rows = Array.from(this.state.selectedRows);
        await this.state.rowData.forEach((data, index) => {
            if (selected_rows.includes(index)) {
                docid.push(data.doc_id);
                tagIndex.push(index);
            }
        });

        const join = this.state.menu_selected_tagonly.join('|');
        const fieldData = {};
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        fieldData.fieldId = fieldDataObj.tagFieldId;
        fieldData.value = join;
        if (fieldData.value.charAt(0) === '|') {
            fieldData.value = fieldData.value.substring(1);
        }
        if (fieldData.value.charAt(fieldData.value.length - 1) === '|') {
            fieldData.value = fieldData.value.substring(
                0,
                fieldData.value.length - 1
            );
        }
        let status = '';
        // fieldData.value += '|'; // kalpana added for testing
        const params = {};
        const fields = {};
        const successDocId = [];
        fields.docIds = docid;
        params.processId = this.props.processId;
        params.statusId = this.props.statusId;
        params.actionId = this.props.allActions.update_tag.actionId;
        params.fields = fields;
        fields.fields = [
            {
                fieldId: fieldDataObj.tags,
                value: fieldData.value,
            },
        ];
        await outboxDocumentAction(params)
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    let failedDocId = [];
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                    let message;
                    if (failedDocId.length !== 0) {
                        if (failedDocId.length === 1) {
                            message = `Doc Id  ${failedDocId[0]} is  Locked`;
                        } else {
                            message = `Doc Ids `;
                            await failedDocId.map(async (item, idx) => {
                                if (
                                    idx !== failedDocId.length - 1 &&
                                    idx !== failedDocId.length - 2
                                ) {
                                    message += `${item}, `;
                                } else if (idx === failedDocId.length - 2) {
                                    message += `${item}`;
                                } else {
                                    message += ` and ${item} `;
                                }
                            });
                            message += `are Locked`;
                        }

                        showErrorToast(message);
                    }
                }
            });
        let tagNewIndex = [];
        if (successDocId.length !== 0) {
            await this.state.rowData.forEach((data, index) => {
                if (successDocId.includes(data.doc_id)) {
                    tagNewIndex.push(index);
                }
            });
        } else {
            this.setState(
                {
                    tagLoader: false,
                    selected_tag: [],
                },
                () => {
                    this.props.updateInboxState({
                        tagLoader: false,
                        selected_tag: [],
                    });
                }
            );
        }

        
        tagNewIndex.forEach((index) => {
            const { tagElement } = createTagElement(
                fieldData.value,
                this.props.configData,
                true,
                '',
                this.props.ddl.tags
            );
            var tagIndexPosition = ''
            this.props.inbox.inbox.dataTable.headerColumns.map((data, index) => {
                if (data.name.toLowerCase() === 'tags') {
                    tagIndexPosition = index
                }
            })
            this.state.rowData[index].tags = tagElement;
            this.state.rowData[index].tags_data = fieldData.value;
            this.props.inbox.inbox.dataTable.dataRows[index].values[tagIndexPosition] =
                fieldData.value;
            this.setState(
                {
                    rowData: this.state.rowData,
                    tagLoader: false,
                },
                () => {
                    this.props.updateInboxState({
                        rowData: this.state.rowData,
                        tagLoader: false,
                    });
                }
            );
        });
       
    }; */

    // delete tags start
    delete_tag = async (tag) => {
        let removeTag = tag;
        this.setState({ tagLoader: true });
        const fieldData = {};
        let errorCount = 0;

        this.setState({ spinner: true });
        const final_tags = [];
        this.setState({ refresh_delete: true, delete_tag: true });

        const nextCounters = await Promise.all(
            deletetag_id_local.map((deletetags) => {
                const tag_data = deletetags.tags_data;
                if (tag_data.includes(tag)) {
                    let deleted = tag_data.replace(tag, '');
                    if (deleted.charAt(0) === '|') {
                        deleted = deleted.slice(1);
                    }
                    final_tags.push({
                        doc_id: deletetags.doc_id,
                        tags: deleted,
                        index: deletetags.index,
                    });
                    deletetags.tag_data = deleted;

                    return deletetags;
                } else {
                    final_tags.push({
                        doc_id: deletetags.doc_id,
                        tags: tag_data,
                        index: deletetags.index,
                    });

                    return deletetags;
                }
            })
        );

        deletetag_id_local = nextCounters;

        AddTags(
            tag,
            this.state,
            this.getData,
            this.props,
            'inbox',
            'delete',
            final_tags
        );
    };

    handleCloseOperation = async () => {
        this.setState({
            restrictAllowEdit: false,
        });
    };

    handleMultiplePage = async () => {
        this.props.inboxPage({
            multiple_edit_page: true,
        });
    };

    closeTransition = async (params) => {
        if (params.reload === true) {
            this.props.getListaction(this.state.processId, this.state.statusId);
            this.setState(
                {
                    open_transition: false,
                    selectedRows: new Set(),
                },
                () => {
                    this.props.updateInboxState({
                        open_transition: false,
                        selectedRows: new Set(),
                    });
                }
            );
            return;
        }
        this.setState(
            {
                open_transition: false,
                selectedRows: new Set(),
            },
            () => {
                this.props.updateInboxState({
                    open_transition: false,
                    selectedRows: new Set(),
                });
            }
        );
    };

    remove_tag = async (tag_index, tag) => {
        AddTags(tag, this.state, this.getData, this.props, 'inbox', 'remove');

        return true;
    };

    ShowFaxModal() {
        const stateSelectedRow = Array.from(this.state.selectedRows);
        const selectedDocId = [];
        if (stateSelectedRow.length > 0) {
            this.state.rowData.forEach((data) => {
                if (stateSelectedRow.indexOf(data.id) !== -1) {
                    selectedDocId.push(data.doc_id);
                }
            });
        }
        this.setState({
            showfaxNow: true,
            selectedDocId: selectedDocId,
        });
    }
    Update_fax_details() {
        const stateSelectedRow = Array.from(this.state.selectedRows);
        const selectedDocId = [];
        if (stateSelectedRow.length > 0) {
            this.state.rowData.forEach((data) => {
                if (stateSelectedRow.indexOf(data.id) !== -1) {
                    selectedDocId.push(data.doc_id);
                }
            });
        }

        let fax_details;
        if (selectedDocId.length === 1) {
            this.state.rowData.map((dataValue, idx) => {
                if (selectedDocId.includes(dataValue.doc_id)) {
                    fax_details = dataValue.faxdetail; // last selected fax_details will be stored
                }
            });
        }

        this.setState({
            openUpdateNotes: true,
            selectedDocId: selectedDocId,
            updateValue: fax_details,
        });
    }

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        endIndex = endIndex < 10 ? 10 : endIndex; // if perPage is less than 10
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    back_tag = () => {
        this.setState({
            close_tagmenu: false,
            open_addtag: false,
            open_replacetag: false,
            open_deletetag: false,
            menu_selected_tagonly: [],
            menu_selected_tag: [],
        });
    };

    open_addtag = () => {
        this.setState({
            close_tagmenu: true,
            open_addtag: true,
            open_replacetag: false,
            open_deletetag: false,
        });
    };

    open_replacetag = () => {
        this.setState({
            close_tagmenu: true,
            open_addtag: false,
            open_replacetag: true,
            open_deletetag: false,
        });
    };

    open_deletetag = async () => {
        let delete_tags = '';
        const deletetag_id = [];
        const selected_rows = Array.from(this.state.selectedRows);
        this.state.rowData.forEach((data, index) => {
            if (selected_rows.includes(data.id)) {
                data.index = index;
                deletetag_id.push(data);
                if (delete_tags !== '') {
                    delete_tags += `|${data.tags_data}`;
                } else {
                    delete_tags += data.tags_data;
                }
            }
        });

        deletetag_id_local = deletetag_id;

        let delete_array = [];
        delete_array = delete_tags.split('|');
        const uniqueDelete = [...new Set(delete_array)];
        const deleteFinal = [];
        const tagList = this.state.menu_tags.map((item) => item.name);
        await uniqueDelete.map((tag, index) => {
            if (tagList.includes(tag)) {
                this.state.menu_tags.map((data, index) => {
                    if (tag === data.name) {
                        deleteFinal.push(data);
                    }
                });
            } else {
                if (tag) {
                    let ddl = {
                        tagId: 0,
                        name: tag,
                        color: '#7F96A5',
                    };
                    deleteFinal.push(ddl);
                }
            }
        });
        this.setState({ delete_tags: deleteFinal });
        this.setState({
            close_tagmenu: true,
            open_addtag: false,
            open_replacetag: false,
            refresh_delete: true,
        });
        this.setState({ open_deletetag: true });
    };

    open_transition = () => {
        const rowsTansition = [];
        Array.from(this.state.selectedRows).forEach((rowIndex, i) => {
            this.state.rowData.map((data, idx) => {
                if (rowIndex === data.id) {
                    rowsTansition.push(this.state.rowData[idx]);
                }
            });
        });
        this.setState({
            open_transition: true,
            menu_branch: null,
            menu_group: null,
            menu_inbox: null,
            rowsTansition,
        });
    };

    download_multiple = async () => {
        this.setState({ blur_spinner: true }, () => {
            download_multiple({
                selectedRows: this.state.selectedRows,
                rowData: this.state.rowData,
            });
            this.setState({ blur_spinner: false });
        });
    };

    restrictAllowEditPopUp() {
        if (Array.from(this.props.selectedRows).length > 4) {
            this.setState({ restrictAllowEdit: true });
        } else {
            this.setState({ restrictAllowEdit: false });
        }
        // this.props.history.push({
        //     pathname: this.state.urlPathName,
        //     selectedDocIds: this.state.editId,
        //     editIds: this.state.editURL,
        //     search: `?editmultiple=${this.state.multipleID}`,
        // });
    }

    openDeletePopUp() {
        const selectedRows = Array.from(this.state.selectedRows);
        let rowsToDelete = this.state.rowData.filter((data) => {
            return selectedRows.indexOf(data.id) !== -1;
        });
        if (rowsToDelete.length === 0 && this.state.rowClickClass.length > 0) {
            rowsToDelete = this.state.rowData.filter((data) => {
                return this.state.onRowClickId === data.doc_id;
            });
        }
        this.setState({
            openDelete: true,
            rowsToDelete: rowsToDelete,
            deleteComments: [],
        });
    }

    closeDeletePopUp(param) {
        param = {
            ...param,
            rowData: this.state.rowData,
            filteredRowData: this.state.filteredRowData,
        };
        const data = closeDeletePopUp(param);
        //const { currentPage, perPage } = this.props;

        let perPage = window.innerHeight < 825 ? 10 : 25;
        this.setState(
            {
                ...this.state,
                ...data,
                currentPage: this.props.stateData.currentPage,
                perPage: this.props.stateData.perPage,
            },
            () => {
                this.props.updateInboxState({
                    ...data,
                    currentPage:
                        param.removeRow === true
                            ? 1
                            : this.props.stateData.currentPage,
                    perPage:
                        param.removeRow === true
                            ? perPage
                            : this.props.stateData.perPage,
                });
                if (param.removeRow === true) {
                    this.props.getListaction(
                        this.state.processId,
                        this.state.statusId
                    );
                }
            }
        );
    }

    openNotesModal() {
        const selectedRows = Array.from(this.state.selectedRows);
        const rowsToNotes = this.state.rowData.filter((data) => {
            return selectedRows.indexOf(data.id) !== -1;
        });
        const docIdForNotes = [];
        rowsToNotes.forEach((data) => {
            docIdForNotes.push(data.doc_id);
        });
        this.setState(
            {
                open_notes: true,
                notesVal: '',
                docIdForNotes,
            },
            () => {}
        );
    }

    async componentWillUnmount() {
        await this.setState({ open_notes: false });
        this._isMounted = false;
    }

    // replace starts
    replace_tag = async (tag, colour) => {
        AddTags(tag, this.state, this.getData, this.props, 'inbox', 'replace');
    };

    sendDraft = async () => {
        const serviceType =
            this.props.configData?.emailIntegration?.serviceType;
        const stateSelectedRow = Array.from(this.state.selectedRows);
        const selectedDocId = [];
        if (stateSelectedRow.length > 0) {
            this.state.rowData.forEach((data) => {
                if (stateSelectedRow.indexOf(data.id) !== -1) {
                    selectedDocId.push(data.doc_id);
                }
            });
        }

        // eslint-disable-next-line eqeqeq
        if (serviceType == 'Office 365') {
            // check to authenticate draft mail
            Office365AuthenticationPage({
                selectedDocID: selectedDocId[0],
                configData: this.props.configData,
            });
        } else {
            GmailAuthenticationPage({
                selectedDocID: selectedDocId[0],
                configData: this.props.configData,
            });
        }
    };

    render() {
        const printIt = () => {
            this.props.showLoader(true);
            const stateSelectedRow = Array.from(this.state.selectedRows);
            const selectedDocId = [];
            if (stateSelectedRow.length > 0) {
                this.state.rowData.forEach((data) => {
                    if (stateSelectedRow.indexOf(data.id) !== -1) {
                        selectedDocId.push(data.doc_id);
                    }
                });
            }
            this.setState(() => {
                return {
                    printSelectedDocId: selectedDocId,
                    printLoader: true,
                };
            });
        };
        /* const handleCloseOperation = () => {
            this.setState({
                restrictAllowEdit: false,
            });
        }; */

        const handleMultiSelectClose = () => {
            this.setState(
                {
                    close_tagmenu: false,
                    open_addtag: false,
                    open_replacetag: false,
                    open_deletetag: false,
                    menu_selected_tagonly: [],
                    menu_selected_tag: [],
                    selectedRows: new Set(),
                    printSelectedDocId: [],
                    open_notes: false,
                },
                () => {
                    this.props.updateInboxState({
                        ...this.props.stateData,
                        close_tagmenu: false,
                        open_addtag: false,
                        open_replacetag: false,
                        open_deletetag: false,
                        menu_selected_tagonly: [],
                        menu_selected_tag: [],
                        selectedRows: new Set(),
                        printSelectedDocId: [],
                        open_notes: false,
                    });
                }
            );
        };
        return (
            <>
                {Array.from(this.state.selectedRows).length > 0 ? (
                    <div
                        className='bottom-function d-flex align-items-center sticky'
                        id='inbox_bottom_menu'
                    >
                        <span className='bottombar-txt'>
                            {Array.from(this.state.selectedRows).length}{' '}
                            {Array.from(this.state.selectedRows).length > 1
                                ? 'files'
                                : 'file'}{' '}
                            selected
                        </span>
                        <div className='d-flex'>
                            {this.state.show_owner &&
                            this.state.arrayOfHeader.length > 0 &&
                            this.state.arrayOfHeader.includes('Owner') ? (
                                <OwnerRowAction
                                    rowSelectFrom='inbox'
                                    allActions={this.state.allActions}
                                    ownerList={this.state.ownerList}
                                    updateState={(state) => {
                                        this.props.updateState({ ...state });
                                    }}
                                    selectedRows={this.state.selectedRows}
                                />
                            ) : null}

                            {this.state.show_tag &&
                            this.state.arrayOfHeader.length > 0 &&
                            this.state.arrayOfHeader.includes('Tags') ? (
                                <div className='tag-symbol'>
                                    <Dropdown onToggle={this.onclose_tag}>
                                        <Dropdown.Toggle id='dropdown-basic'>
                                            <OverlayTrigger
                                                placement='bottom'
                                                onEntering={this.entering}
                                                overlay={
                                                    <Tooltip
                                                        id='tooltip'
                                                        style={{
                                                            backgroundColor:
                                                                'rgb(0, 35, 58)',
                                                            color: 'white',
                                                            marginBottom:
                                                                '25px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    'rgb(0, 35, 58)',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Tags
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'update_tag'
                                                        ) &&
                                                        this.state.allActions
                                                            .update_tag.icon !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .update_tag
                                                                  .icon
                                                            : 'fas fa-tag'
                                                    }
                                                    style={{
                                                        color:
                                                            this.state.allActions.hasOwnProperty(
                                                                'update_tag'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .update_tag
                                                                .color !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .update_tag
                                                                      .color
                                                                : '',
                                                    }}
                                                    onClick={this.back_tag}
                                                />
                                            </OverlayTrigger>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                            className='main-tag'
                                            style={{
                                                border: '1px solid #00233A',
                                                overflow: 'hidden',
                                                borderTopLeftRadius: '0',
                                                borderTopRightRadius: '0',
                                            }}
                                        >
                                            {this.state.close_tagmenu ? null : (
                                                <div
                                                    className='category-tags'
                                                    style={{ padding: '9px' }}
                                                >
                                                    <p
                                                        key={0}
                                                        onClick={
                                                            this.open_addtag
                                                        }
                                                        title='Add Tags'
                                                    >
                                                        <i className='fas fa-plus' />{' '}
                                                        Add Tags
                                                    </p>
                                                    <p
                                                        key={1}
                                                        onClick={
                                                            this.open_replacetag
                                                        }
                                                        title='Replace Tags'
                                                    >
                                                        <i className='fas fa-sync-alt' />{' '}
                                                        Replace Tags
                                                    </p>
                                                    <p
                                                        key={2}
                                                        onClick={
                                                            this.open_deletetag
                                                        }
                                                        title='Delete Tags'
                                                    >
                                                        <i className='fas fa-trash-alt' />{' '}
                                                        Delete Tags
                                                    </p>
                                                </div>
                                            )}
                                            {/* open_addtag  starts */}
                                            {this.state.open_addtag ? (
                                                <div
                                                    className='main_tag tag_options'
                                                    id='addtag'
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {/* <i onClick={this.back_tag} className="fas fa-arrow-left"></i> */}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div className='menu-tag tag_options_bottom add'>
                                                            {this.state.menu_tags.map(
                                                                (data, i) => {}
                                                            )}
                                                            {this.state.menu_tags.map(
                                                                (data, i) => (
                                                                    <>
                                                                        <button
                                                                            type='button'
                                                                            key={
                                                                                i
                                                                            }
                                                                            className={
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? 'delete_true'
                                                                                    : ''
                                                                            }
                                                                            onClick={() => {
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? this.remove_tag(
                                                                                          '',
                                                                                          data
                                                                                      )
                                                                                    : this.add_tag(
                                                                                          data
                                                                                      );
                                                                            }}
                                                                            style={{
                                                                                borderRadius:
                                                                                    '25px',
                                                                                backgroundColor:
                                                                                    data.color,
                                                                                color: 'white',
                                                                            }}
                                                                            title={
                                                                                data.name
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                            {this.state.menu_selected_tagonly.includes(
                                                                                data.name
                                                                            ) ? (
                                                                                <i
                                                                                    title='Close'
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            ) : null}
                                                                        </button>

                                                                        <br />
                                                                    </>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div
                                                            className=' tag_options_up'
                                                            style={{
                                                                borderTop:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            {this.state
                                                                .tagLoader ? (
                                                                <div
                                                                    className='spinner-border text-primary'
                                                                    role='status'
                                                                >
                                                                    <span className='sr-only'>
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {this.state.menu_selected_tag.map(
                                                                (
                                                                    data,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                            color: 'white',
                                                                            borderRadius:
                                                                                '25px',
                                                                        }}
                                                                    >
                                                                        {data.name !==
                                                                        '' ? (
                                                                            <>
                                                                                <button
                                                                                    type='button'
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            data.color,
                                                                                        borderRadius:
                                                                                            '25px',
                                                                                    }}
                                                                                    title={
                                                                                        data.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        data.name
                                                                                    }{' '}
                                                                                </button>
                                                                                <i
                                                                                    title='Close'
                                                                                    onClick={() => {
                                                                                        this.remove_tag(
                                                                                            '',
                                                                                            data
                                                                                        );
                                                                                    }}
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {/* open_addtag  ends */}
                                            {/* open_replacetag  starts */}
                                            {this.state.open_replacetag ? (
                                                <div
                                                    className='main_tag tag_options'
                                                    style={{
                                                        border: this.state
                                                            .refresh_delete
                                                            ? ''
                                                            : '',
                                                        width: '267px',
                                                        backgroundColor:
                                                            'white',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {/* <i onClick={this.back_tag} className="fas fa-arrow-left"></i> */}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div className='menu-tag tag_options_bottom replace'>
                                                            {this.state.menu_tags.map(
                                                                (data, i) => (
                                                                    <>
                                                                        <button
                                                                            type='button'
                                                                            key={
                                                                                i
                                                                            }
                                                                            className={
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? 'delete_true'
                                                                                    : ''
                                                                            }
                                                                            onClick={() =>
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? this.remove_tag(
                                                                                          '',
                                                                                          data
                                                                                      )
                                                                                    : this.replace_tag(
                                                                                          data
                                                                                      )
                                                                            }
                                                                            style={{
                                                                                borderRadius:
                                                                                    '25px',
                                                                                backgroundColor:
                                                                                    data.color,
                                                                                color: 'white',
                                                                            }}
                                                                            title={
                                                                                data.name
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                            {this.state.menu_selected_tagonly.includes(
                                                                                data.name
                                                                            ) ? (
                                                                                <i
                                                                                    title='Close'
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            ) : null}
                                                                        </button>
                                                                        <br />
                                                                    </>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}

                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div
                                                            className=' tag_options_up'
                                                            style={{
                                                                borderTop:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            {this.state
                                                                .tagLoader ? (
                                                                <div
                                                                    className='spinner-border text-primary'
                                                                    role='status'
                                                                >
                                                                    <span className='sr-only'>
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {this.state.menu_selected_tag.map(
                                                                (
                                                                    data,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                            color: 'white',
                                                                            borderRadius:
                                                                                '25px',
                                                                        }}
                                                                    >
                                                                        {data.name !==
                                                                        '' ? (
                                                                            <>
                                                                                <button
                                                                                    type='button'
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            data.color,
                                                                                        borderRadius:
                                                                                            '25px',
                                                                                    }}
                                                                                    title={
                                                                                        data.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        data.name
                                                                                    }
                                                                                </button>
                                                                                <i
                                                                                    title='Close'
                                                                                    onClick={() => {
                                                                                        this.remove_tag(
                                                                                            '',
                                                                                            data
                                                                                        );
                                                                                    }}
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {/* open_replacetag  ends */}
                                            {/* open_deletetag  starts */}
                                            {this.state.open_deletetag ? (
                                                <div
                                                    className='main_tag tag_options'
                                                    style={{
                                                        border: this.state
                                                            .refresh_delete
                                                            ? ''
                                                            : '',
                                                        width: '267px',
                                                        backgroundColor:
                                                            'white',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {/* <i onClick={this.back_tag} className="fas fa-arrow-left"></i> */}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div className='menu-tag tag_options_bottom delete'>
                                                            {this.state
                                                                .tagLoader ? (
                                                                <div
                                                                    className='spinner-border text-primary'
                                                                    role='status'
                                                                >
                                                                    <span className='sr-only'>
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {this.state
                                                                .delete_tags
                                                                .length ===
                                                            0 ? (
                                                                <div
                                                                    style={{
                                                                        textAlign:
                                                                            'center',
                                                                    }}
                                                                    className='noTags'
                                                                >
                                                                    No Tags
                                                                </div>
                                                            ) : null}
                                                            {this.state.delete_tags.map(
                                                                (data, i) => (
                                                                    <React.Fragment
                                                                        key={i}
                                                                    >
                                                                        <button
                                                                            type='button'
                                                                            key={
                                                                                i
                                                                            }
                                                                            className='delete_true'
                                                                            onClick={() =>
                                                                                this.delete_tag(
                                                                                    data.name
                                                                                )
                                                                            }
                                                                            style={{
                                                                                borderRadius:
                                                                                    '25px',
                                                                                backgroundColor:
                                                                                    data.color,
                                                                                color: 'white',
                                                                            }}
                                                                            title={
                                                                                data.name
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                            <i
                                                                                title='Close'
                                                                                className='fa-light fa-xmark'
                                                                            />
                                                                        </button>

                                                                        <br />
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {/* open_deletetag  ends */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            ) : null}
                            <div className='bottom-icons d-flex'>
                                {this.state.show_branch ||
                                this.state.show_group ||
                                this.state.permission?.statusArray?.length >
                                    0 ? (
                                    <OverlayTrigger
                                        placement='bottom'
                                        onEntering={this.entering}
                                        overlay={
                                            <Tooltip
                                                id='tooltip'
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                    marginBottom: '15px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 35, 58)',
                                                        color: 'white',
                                                    }}
                                                >
                                                    Transition
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            className='icon_fun outer-col'
                                            onClick={this.open_transition}
                                        >
                                            <i
                                                className={
                                                    this.state.allActions.hasOwnProperty(
                                                        'transition'
                                                    ) &&
                                                    this.state.allActions
                                                        .transition.icon !==
                                                        null
                                                        ? this.state.allActions
                                                              .transition.icon
                                                        : 'fa-regular fa-arrow-right-arrow-left'
                                                }
                                                style={
                                                    this.state.allActions.hasOwnProperty(
                                                        'transition'
                                                    ) &&
                                                    this.state.allActions
                                                        .transition.color !==
                                                        null
                                                        ? {
                                                              color: this.state
                                                                  .allActions
                                                                  .transition
                                                                  .color,
                                                          }
                                                        : {}
                                                }
                                            />
                                        </div>
                                    </OverlayTrigger>
                                ) : null}

                                {this.state.show_notes &&
                                this.state.noteTypes.length > 0 ? (
                                    <OverlayTrigger
                                        placement='bottom'
                                        onEntering={this.entering}
                                        overlay={
                                            <Tooltip
                                                id='tooltip'
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                    marginBottom: '15px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 35, 58)',
                                                        color: 'white',
                                                    }}
                                                >
                                                    Notes
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            className='icon_fun outer-col'
                                            onClick={this.openNotesModal.bind(
                                                this
                                            )}
                                        >
                                            <i
                                                className={
                                                    this.state.allActions.hasOwnProperty(
                                                        'note'
                                                    ) &&
                                                    this.state.allActions.note
                                                        .icon !== null
                                                        ? this.state.allActions
                                                              .note.icon
                                                        : 'fa-solid fa-sticky-note'
                                                }
                                                style={
                                                    this.state.allActions.hasOwnProperty(
                                                        'note'
                                                    ) &&
                                                    this.state.allActions.note
                                                        .color !== null
                                                        ? {
                                                              color: this.state
                                                                  .allActions
                                                                  .note.color,
                                                          }
                                                        : {}
                                                }
                                            />
                                        </div>
                                    </OverlayTrigger>
                                ) : null}
                                {/* <OverlayTrigger
                                    placement='top'
                                    onEntering={this.entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Email
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        className='icon_fun outer-col'
                                        onClick={() => window.open(this.state.gmailAccessTokenURL, '_self')}
                                    >
                                        <i className='fa-solid fa-envelope' />
                                    </div>
                                </OverlayTrigger> */}
                                {this.state.show_pdf &&
                                this.props.calingFrom !== 'search' &&
                                this.state.permission?.processName !==
                                    'Export' ? (
                                    this.state.urlChange ? (
                                        <OverlayTrigger
                                            placement='bottom'
                                            onEntering={this.entering}
                                            overlay={
                                                <Tooltip
                                                    id='tooltip'
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 35, 58)',
                                                        color: 'white',
                                                        marginBottom: '15px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor:
                                                                'rgb(0, 35, 58)',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Merge / Split Documents{' '}
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            {Array.from(this.props.selectedRows)
                                                .length > 4 ? (
                                                <div
                                                    className='icon_fun outer-col'
                                                    onClick={() => {
                                                        this.restrictAllowEditPopUp.call(
                                                            this
                                                        );
                                                    }}
                                                >
                                                    <i
                                                        className={
                                                            this.state.allActions.hasOwnProperty(
                                                                'show_pdf'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .show_pdf
                                                                .icon !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .show_pdf
                                                                      .icon
                                                                : 'fas fa-page-break'
                                                        }
                                                        /* style={{
                            color:
                                this.state.allActions.hasOwnProperty(
                                    'show_pdf'
                                ) &&
                                this.state
                                    .allActions
                                    .show_pdf
                                    .color !== null
                                    ? this.state
                                          .allActions
                                          .show_pdf
                                          .color
                                    : '#007DBC',
                        }} */
                                                    />{' '}
                                                    {/* - <span>{Array.from(this.props.selectedRows).length}</span> */}
                                                </div>
                                            ) : (
                                                <NavLink
                                                    to={{
                                                        pathname:
                                                            this.state
                                                                .urlPathName,
                                                        selectedDocIds:
                                                            this.state.editId,
                                                        editIds:
                                                            this.state.editURL,
                                                        search: `?editmultiple=${this.state.multipleID}`,
                                                    }}
                                                    onClick={() => {
                                                        this.props.inboxPage({
                                                            multiple_edit_page: true,
                                                        });
                                                    }}
                                                >
                                                    <div className='icon_fun outer-col'>
                                                        <i
                                                            className={
                                                                this.state.allActions.hasOwnProperty(
                                                                    'show_pdf'
                                                                ) &&
                                                                this.state
                                                                    .allActions
                                                                    .show_pdf
                                                                    .icon !==
                                                                    null
                                                                    ? this.state
                                                                          .allActions
                                                                          .show_pdf
                                                                          .icon
                                                                    : 'fas fa-page-break'
                                                            }
                                                            style={
                                                                this.state.allActions.hasOwnProperty(
                                                                    'show_pdf'
                                                                ) &&
                                                                this.state
                                                                    .allActions
                                                                    .show_pdf
                                                                    .color !==
                                                                    null
                                                                    ? {
                                                                          color: this
                                                                              .state
                                                                              .allActions
                                                                              .show_pdf
                                                                              .color,
                                                                      }
                                                                    : {}
                                                            }
                                                        />{' '}
                                                        {/* - <span>{Array.from(this.props.selectedRows).length}</span> */}
                                                    </div>
                                                </NavLink>
                                            )}
                                        </OverlayTrigger>
                                    ) : null
                                ) : null}

                                <Dropdown className='bulk-print-opt icon_fun outer-col dropDown-svg'>
                                    <OverlayTrigger
                                        placement='bottom'
                                        onEntering={this.entering}
                                        overlay={
                                            <Tooltip
                                                id='tooltip'
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 35, 58)',
                                                        color: 'white',
                                                    }}
                                                >
                                                    Settings
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <Dropdown.Toggle
                                            variant='success'
                                            id='dropdown-basic'
                                        >
                                            <i
                                                className='fa fa-ellipsis-v'
                                                aria-hidden='true'
                                            />
                                        </Dropdown.Toggle>
                                    </OverlayTrigger>
                                    <Dropdown.Menu>
                                        <ExportMenu
                                            permission={this.state.permission}
                                            callingFrom='actionBar'
                                            refreshTable={() => {
                                                this.props.refreshTableInbox();
                                            }}
                                            count={this.props.count}
                                        />

                                        {this.state.allActions
                                            .update_fax_details ? (
                                            <Dropdown.Item
                                                title='Update Fax Details'
                                                onClick={() =>
                                                    this.Update_fax_details()
                                                }
                                            >
                                                <span className='delivery-details'>
                                                    <svg
                                                        className='icon-fill'
                                                        width='39'
                                                        height='34'
                                                        viewBox='0 0 39 34'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                    >
                                                        <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                        <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                    </svg>
                                                    Update Fax Details
                                                </span>
                                            </Dropdown.Item>
                                        ) : null}
                                        <Dropdown.Item
                                            title='Print'
                                            className='print-dropdown'
                                        >
                                            <span
                                                onClick={() => {
                                                    printIt();
                                                }}
                                            >
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'print'
                                                        ) &&
                                                        this.state.allActions
                                                            .print.icon !== null
                                                            ? this.state
                                                                  .allActions
                                                                  .print.icon
                                                            : 'fas fa-print'
                                                    }
                                                    style={
                                                        this.state.allActions.hasOwnProperty(
                                                            'print'
                                                        ) &&
                                                        this.state.allActions
                                                            .print.color !==
                                                            null
                                                            ? {
                                                                  color: this
                                                                      .state
                                                                      .allActions
                                                                      .print
                                                                      .color,
                                                              }
                                                            : {}
                                                    }
                                                />
                                                Print
                                            </span>
                                        </Dropdown.Item>

                                        <Dropdown.Item title='Download'>
                                            <span
                                                onClick={() => {
                                                    this.download_multiple();
                                                }}
                                            >
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'download'
                                                        ) &&
                                                        this.state.allActions
                                                            .download.icon !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .download.icon
                                                            : 'fas fa-download'
                                                    }
                                                    style={
                                                        this.state.allActions.hasOwnProperty(
                                                            'download'
                                                        ) &&
                                                        this.state.allActions
                                                            .download.color !==
                                                            null
                                                            ? {
                                                                  color: this
                                                                      .state
                                                                      .allActions
                                                                      .download
                                                                      .color,
                                                              }
                                                            : {}
                                                    }
                                                />
                                                Download
                                            </span>
                                        </Dropdown.Item>

                                        {this.state.serviceType
                                            ? this.state.selectedRows !==
                                                  undefined &&
                                              this.state.permission
                                                  ?.processName !== 'Export' &&
                                              Array.from(
                                                  this.state.selectedRows
                                              ).length === 1 && (
                                                  <Dropdown.Item
                                                      onClick={() => {
                                                          this.sendDraft();
                                                      }}
                                                      title='Send via email'
                                                  >
                                                      <span>
                                                          <i className='fa-solid fa-envelope' />
                                                          Send via email
                                                      </span>
                                                  </Dropdown.Item>
                                              )
                                            : null}

                                        {this.state.permission.show_delete ? (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    this.openDeletePopUp.call(
                                                        this
                                                    );
                                                }}
                                                title='Delete'
                                            >
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'delete'
                                                        ) &&
                                                        this.state.allActions
                                                            .delete.icon !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .delete.icon
                                                            : 'fas fa-trash-alt'
                                                    }
                                                    style={
                                                        this.state.allActions.hasOwnProperty(
                                                            'delete'
                                                        ) &&
                                                        this.state.allActions
                                                            .delete.color !==
                                                            null
                                                            ? {
                                                                  color: this
                                                                      .state
                                                                      .allActions
                                                                      .delete
                                                                      .color,
                                                              }
                                                            : {}
                                                    }
                                                />
                                                Delete
                                            </Dropdown.Item>
                                        ) : null}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <OverlayTrigger
                                    placement='bottom'
                                    onEntering={this.entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Close
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <div className='icon_fun outer-col no-bg'>
                                        <i
                                            className='fa-light fa-xmark'
                                            onClick={() =>
                                                handleMultiSelectClose()
                                            }
                                        />
                                    </div>
                                </OverlayTrigger>
                            </div>
                            {/**solved by another way */}
                            {/* {this.state.printSelectedDocId.length > 0 ? (
                                <PrintPdfDocument
                                    docId={this.state.printSelectedDocId}
                                    loader={setPrinterloader}
                                />
                            ) : null} */}
                            {this.state.open_transition ? (
                                <TransitionComponent
                                    menu_name={this.state.menu_name}
                                    processId={this.state.processId}
                                    statusId={this.state.statusId}
                                    statusArray={this.state.statusArray}
                                    branches={this.state.branches}
                                    groups={this.state.groups}
                                    editTransition
                                    selectedRows={this.state.rowsTansition}
                                    branchActionId={
                                        this.state.permission?.branchActionId
                                    }
                                    groupActionId={
                                        this.state.permission?.groupActionId
                                    }
                                    showBranch={this.state.show_branch}
                                    showGroup={this.state.show_group}
                                    showInbox={this.state.show_inbox}
                                    openTransition={this.state.open_transition}
                                    closeTransition={this.closeTransition}
                                    menuFrom='actionBar'
                                />
                            ) : null}
                            {this.state.openDelete ? (
                                <DeleteModal
                                    openDelete={this.state.openDelete}
                                    rowsToDelete={this.state.rowsToDelete}
                                    closeDeletePopUp={this.closeDeletePopUp.bind(
                                        this
                                    )}
                                    processId={this.state.processId}
                                    statusId={this.state.statusId}
                                    deleteActionId={this.state.deleteActionId}
                                    calingFrom='actionBar'
                                />
                            ) : null}

                            {this.state.restrictAllowEdit ? (
                                <AllowMultipleEditModal
                                    restrictAllowEdit={
                                        this.state.restrictAllowEdit
                                    }
                                    handleCloseOperation={
                                        this.handleCloseOperation
                                    }
                                    handleMultiplePage={this.handleMultiplePage}
                                    editURL={this.state.editURL}
                                    selectedDocIds={this.state.editId}
                                    editIds={this.state.editURL}
                                    multipleID={this.state.multipleID}
                                />
                            ) : null}
                            {this.state.open_notes ? (
                                <Notes
                                    openNote={this.state.open_notes}
                                    docId={this.state.docIdForNotes}
                                    handleReset={() => {
                                        this.setState({ open_notes: false });
                                    }}
                                    updateState={() => {}}
                                    viewAllOpen={false}
                                    refreshTable={() => {
                                        this.props.refreshTable();
                                    }}
                                />
                            ) : null}
                            <UpdateFaxDetails
                                openUpdateNotes={this.state.openUpdateNotes}
                                selectedDocId={this.state.selectedDocId}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                }}
                                updateValue={this.state.updateValue}
                                calingFrom='row-selection'
                                selectedRows={this.state.selectedRows}
                            />
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    setOwner,
    updateList,
    setSelectedRow: setSelectedRow,
    getListaction: getListaction,
    updateInboxState,
    showLoader: (data) => showLoader(data),
    inboxPage,
};
const mapStateToProps = (state, ownProps) => {
    return {
        inbox: state.inbox,
        processId: state.inbox.processId,
        statusId: state.inbox.statusId,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        owners: state.inbox.owners,
        stateData: stringEmptyOrUndefined(state.inbox)
            ? state.inbox.stateData
            : {},
        stateToken: state.inbox.stateToken,
        ddl: state.persistDDL.DDL,
    };
};
export default React.memo(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(RowSelectionComponent))
);
