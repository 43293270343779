import React, { useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
    showErrorToast,
    showSuccessToast,
} from '../../../components/commonfunction';
import { addTags } from '../../api/api';
import { useDispatch } from 'react-redux';
import { getDDLlist } from '../../_redux/actions/configActions';

const AddTag = ({
    model,
    setModel,
    tagArray,
    setTagArray,
    colorCode,
    setColorCode,
    tags,
    setTag,
}) => {
    const [tagName, setTagName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const colorRef = useRef();
    const dispatch = useDispatch();
    const standardColors = [
        '#4E8958',
        '#8B36BF',
        '#ECB445',
        '#00B1BC',
        '#F28A4C',
        '#334F61',
        '#D83AD3',
        '#3A89D8',
        '#3AD88E',
    ];

    const handleClose = () => {
        setModel(false);
        setColorCode('#7F96A5');
    };
    const AddNewTag = async (newTag) => {
        try {
            setIsLoading(true);
            const response = await addTags(newTag);
            if (response.ok) {
                const data = await response.json();
                const updatedTagArray = [...tagArray, data];
                const updatedTags = [...tags, data.name];
                setTagArray(updatedTagArray);
                setTag(updatedTags);
                dispatch(
                    getDDLlist({
                        tags: updatedTags,
                    })
                );
                localStorage.setItem(
                    'tagList',
                    JSON.stringify(updatedTagArray)
                );
                showSuccessToast('Tag Created Successfully');
            }
        } catch (error) {
            console.error('Error Adding data:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };
    const addTag = () => {
        let nameRegex = /^[a-zA-Z0-9 -/]*$/;
        let tag = tagName.trim();
        let duplicateTag = tagArray.find((item) => item.name === tag);

        if (duplicateTag) {
            showErrorToast('tag name already exists');
            return;
        }
        if (tag === '') {
            showErrorToast('Please enter tag name');
            return;
        }
        if (tag.length > 80) {
            showErrorToast('Maximun 80 characters only allowed');
            return;
        }
        if (!nameRegex.test(tag)) {
            showErrorToast('Enter a valid tag name');
            return;
        }

        const newTag = {
            name: tag,
            color: colorCode,
        };

        AddNewTag(newTag);
    };
    const handleChangeColor = (e) => {
        const colorValue = e.target.value;
        setColorCode(colorValue);
    };
    const handleChangeStaticColor = (color) => {
        setColorCode(color);
    };

    return (
        <Modal
            className='addTag-modal'
            show={model}
            onHide={handleClose}
            animation={false}
            size='lg'
            centered
        >
            <Modal.Header closeButton className='d-block justify-content-start'>
                <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                    <div className=''>
                        <i title='add' className='fa-regular fa-plus' />
                        Add New Tag
                    </div>
                    <i
                        title='Close'
                        className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                        aria-hidden='true'
                        onClick={() => handleClose()}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-dark mb-0'>
                    Enter a tag Name and Select a colour by clicking palette
                    button
                </p>
                <div className='divider' />
                <div className='tag-color'>
                    <div className='form-group row'>
                        <div className='col-lg-8 transition-select'>
                            <label className='pl-5'>TAG NAME</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Unique tag name'
                                onChange={(e) => setTagName(e.target.value)}
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label className='pl-5'>STANDARD COLORS</label>
                        <div className='tag-color-sec'>
                            {standardColors.map((item, index) => (
                                <div
                                    key={index}
                                    className='tag-color-item'
                                    style={{ backgroundColor: item }}
                                    onClick={() =>
                                        handleChangeStaticColor(item)
                                    }
                                ></div>
                            ))}
                        </div>
                    </div>
                    <div className='form-group'>
                        <label className='pl-5'>CUSTOM COLORS</label>
                        <div className='tag-color-sec color-picker'>
                            <div
                                className='tag-color-item'
                                style={{ backgroundColor: colorCode }}
                            ></div>
                            <div className='form-control'>
                                <input
                                    id={'sample'}
                                    ref={colorRef}
                                    type='color'
                                    placeholder='color code'
                                    value={colorCode}
                                    onChange={handleChangeColor}
                                />
                                <label
                                    htmlFor='sample'
                                    children={colorCode || 'select color'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-block '>
                <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                    <div className='d-flex'>
                        <Button
                            title='Cancel'
                            variant='secondary'
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className='d-flex'>
                        <Button
                            title='Add'
                            onClick={() => {
                                addTag();
                            }}
                            variant='success'
                            disabled={isLoading ? true : false}
                        >
                            {isLoading ? (
                                <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                            ) : (
                                <span>Add New Tag</span>
                            )}
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTag;
