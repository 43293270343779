import { formatHeaderNameWithOutSpace } from '../../../components/commonfunction';
import { renderHeader } from '../components/dataGrid/HeaderFormatOptions';
import { columnNameAliases } from '../models/entityListData';

interface Column {
    key: string;
    name: string;
    datatype: string;
    fieldId: number;
    resizable?: boolean;
    sortable?: boolean;
    headerRenderer?: (props: any) => JSX.Element;
}

const generateColumnsArray = <
    T extends {
        name: string;
        datatype: string;
        fieldId: number;
        resizable?: boolean;
        sortable?: boolean;
        headerRenderer?: (props: any) => JSX.Element;
    }
>(
    apiResponse: T[]
): Column[] => {
    return apiResponse.map((column) => ({
        key: formatHeaderNameWithOutSpace(column.name)?.toString() || '',
        name: columnNameAliases({ name: column.name }),
        datatype: column.datatype,
        fieldId: column.fieldId,
        resizable: !!column.resizable || false,
        sortable: !!column.sortable || false,
        headerRenderer: (props: any) => renderHeader(props),
    }));
};

export default generateColumnsArray;