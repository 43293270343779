// import React from 'react';

export function getEditFontStyle() {
    let fontStyleData = [
        'Arial, sans-serif',
        'Helvetica, sans-serif',
        'Verdana, sans-serif',
        'Trebuchet MS, sans-serif',
        'Gill Sans, sans-serif',
        'Noto Sans, sans-serif',
        'Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif',
        'Optima, sans-serif',
        'Arial Narrow, sans-serif',
        'sans-serif',
        'Times, Times New Roman, serif',
        'Didot, serif',
        'Georgia, serif',
        'Palatino, URW Palladio L, serif',
        'Bookman, URW Bookman L, serif',
        'New Century Schoolbook, TeX Gyre Schola, serif',
        'American Typewriter, serif',
        'serif',
        'Andale Mono, monospace',
        'Courier New, monospace',
        'Courier, monospace',
        'FreeMono, monospace',
        'OCR A Std, monospace',
        'DejaVu Sans Mono, monospace',
        'monospace',
        'Comic Sans MS, Comic Sans, cursive',
        'Apple Chancery, cursive',
        'Bradley Hand, cursive',
        'Brush Script MT, Brush Script Std, cursive',
        'Snell Roundhand, cursive',
        'URW Chancery L, cursive',
        'cursive',
        'Impact, fantasy',
        'Luminari, fantasy',
        'Chalkduster, fantasy',
        'Jazz LET, fantasy',
        'Blippo, fantasy',
        'Stencil Std, fantasy',
        'Marker Felt, fantasy',
        'Trattatello, fantasy',
        'fantasy',
    ];

    fontStyleData.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    return fontStyleData;
}

export function fontSizeData() {
    let sizeArray = [];
    for (let x = 10; x < 60; x += 2) {
        sizeArray.push(x + 2);
    }
    return sizeArray;
}
