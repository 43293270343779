import React from 'react';

export class ChatPage extends React.Component {
    render() {
        return (
            <div>
                <script src='/main.dart.js' defer></script>
            </div>
        );
    }
}
