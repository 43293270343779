/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo, useRef, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { isMobile } from 'react-device-detect';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { toast } from 'react-toastify';
// import { searchPlugin } from '@react-pdf-viewer/search';
// import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { SpinnerBlur } from '../../../components/inbox/Spinner';
import { formatPathName } from '../../../components/commonfunction';
import { unlockDocument, downloadDoc } from '../../api/api';
import print from 'print-js';
import { useImperativeHandle } from 'react';

toast.configure();
//let customZoom = 1;
let customZoomValue = SpecialZoomLevel.PageFit;
if (isMobile) {
    customZoomValue = SpecialZoomLevel.PageFit;
}
let refreshPDF = false;
// const toolbarPluginInstance = toolbarPlugin();
// const { Toolbar } = toolbarPluginInstance;

const PdfPreview = forwardRef(function PdfPreview(props, ref) {
    refreshPDF = false;

    // Create new plugin instance

    // const searchPluginInstance = searchPlugin();
    let isMountedRef = useRef(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [apiError, setApiError] = useState(false);
    const [blurSpinner, setBlurSpinner] = useState(false);
    const [currentPage, setCurrentPage] = useState('');
    const [customZoom, setCustomZoom] = useState('');
    // const [refreshPDF, setRefreshPDF] = useState(false);
    const location = useLocation();
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    useImperativeHandle(
        ref,
        () => {
            return {
                printPdf: () => {
                    props.loader(true);
                    if (pdfUrl == null) {
                        let documentPromise;
                        if (props.docId !== undefined) {
                            const docId = props.docId;
                            documentPromise = downloadDoc(docId);
                            if (documentPromise !== undefined) {
                                documentPromise
                                    .then((response) => {
                                        if (response.status === 400) {
                                            props.loader(false);
                                            return new Error('error');
                                        }
                                        if (response.status === 204) {
                                        }
                                        return response.blob();
                                    })
                                    .then((data) => {
                                        const url = URL.createObjectURL(data);
                                        print({
                                            onError: (e) => {
                                                console.log(e);
                                            },
                                            type: 'pdf',
                                            printable: url,
                                            onLoadingEnd: () => {
                                                props.loader(false);
                                            },
                                            onPrintDialogClose: () => {
                                                props.loader(false);
                                            },
                                        });
                                    })
                                    .catch((error) => {});
                            } else {
                                props.loader(false);
                            }
                        } else {
                            props.loader(false);
                        }
                    } else {
                        print({
                            onError: (e) => {
                                console.log(e);
                            },
                            type: 'pdf',
                            printable: pdfUrl,
                            onLoadingEnd: () => {
                                props.loader(false);
                            },
                            onPrintDialogClose: () => {
                                props.loader(false);
                            },
                        });
                    }
                },
            };
        },
        [pdfUrl, props.loader]
    );

    useEffect(() => {
        isMountedRef.current = true;
        setPdfUrl(null);
        let documentPromise;
        let pathname = location.pathname;
        const currentPage = pathname.split('/');
        if (currentPage.length > 0 && isMountedRef.current) {
            if (currentPage[2] === 'detail') {
                setCurrentPage(currentPage[2]);
            }
        }
        setCustomZoom(customZoomValue);
        if (props.docId !== undefined && isMountedRef.current) {
            pathname = formatPathName(pathname);
            localStorage.setItem(`locked-document-id-${pathname}`, props.docId);
            const docId = props.docId;
            documentPromise = downloadDoc(docId);
        }
        if (props.docId === undefined && isMountedRef.current) {
            const pathname = location.pathname.split('/');

            if (pathname[1] === 'detail') {
                documentPromise = downloadDoc(pathname[2]);
            }
        }

        if (documentPromise !== undefined) {
            documentPromise
                .then((response) => {
                    if (response.status === 400) {
                        if (isMountedRef.current) {
                            setApiError(true);
                            toast.error('No pdf to load', {
                                position: 'top-right',
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                showTransitionSuccess: false,
                            });
                            return new Error('error');
                        }
                    }
                    if (response.status === 204) {
                        if (isMountedRef.current) {
                            setApiError(false);
                        }
                    }
                    return response.blob();
                })
                .then((data) => {
                    if (isMountedRef.current) {
                        const url = URL.createObjectURL(data);
                        setPdfUrl(url);

                        // setRefreshPDF(true)
                        setBlurSpinner(false);
                    }
                })
                .catch((error) => {});
        }

        return () => {
            if (props.docId !== undefined) {
                const docId = props.docId;
                unlockDocument(docId).then((data) => {
                    if (props.updateParent) {
                        props.updateParent();
                    }
                });
            }
            isMountedRef.current = false;
        };
    }, [props.docId]);
    // }, [props.selectedDoc,location,props]);//props.selectedDoc

    // const pageNavigationPluginInstance = pageNavigationPlugin();
    // const { CurrentPageLabel } = pageNavigationPluginInstance;
    const currentBase64 = pdfUrl;
    //

    refreshPDF = true;
    // 328
    // 370
    var pdfHeight = window.innerHeight - 328;

    if (props.isLocked === true) {
        pdfHeight = window.innerHeight - 370;
    }
    // if (props.detailBigView === true || window.innerHeight < 700) {
    //     pdfHeight = window.innerHeight;
    // }

    pdfHeight = window.innerHeight;

    return (
        <>
            {pdfUrl !== null && refreshPDF === true ? (
                <div
                    className='rpv-core__viewer'
                    style={{
                        display: 'flex',
                    }}
                >
                    <div className='toolbar-pdfpreview'>
                        <div className='pdfpreview-tools'>
                            {/* <Toolbar /> */}
                            <Toolbar>
                                {pdfUrl !== null
                                    ? (props) => {
                                          const {
                                              CurrentPageInput,
                                              EnterFullScreen,
                                              GoToNextPage,
                                              GoToPreviousPage,
                                              NumberOfPages,
                                              ShowSearchPopover,
                                              Zoom,
                                              ZoomIn,
                                              ZoomOut,
                                              // Print,
                                          } = props;

                                          return (
                                              <>
                                                  <div>
                                                      <ShowSearchPopover />
                                                  </div>

                                                  <div>
                                                      <GoToPreviousPage />
                                                  </div>
                                                  <div>
                                                      <CurrentPageInput />/
                                                      <NumberOfPages />
                                                  </div>

                                                  <div>
                                                      <GoToNextPage />
                                                  </div>

                                                  <div
                                                      style={{
                                                          marginLeft: 'auto',
                                                      }}
                                                  >
                                                      <ZoomOut />
                                                  </div>
                                                  <div>
                                                      <Zoom
                                                          levels={[
                                                              0.4, 0.8, 1.2,
                                                          ]}
                                                      />
                                                  </div>
                                                  <div>
                                                      <ZoomIn />
                                                  </div>
                                                  {/* <CurrentPageLabel>
                                                      {(
                                                          props: RenderCurrentPageLabelProps
                                                      ) => (
                                                          <span>{`${
                                                              props.currentPage +
                                                              1
                                                          } of ${
                                                              props.numberOfPages
                                                          }`}</span>
                                                      )}
                                                        </CurrentPageLabel> */}
                                                  <div
                                                      style={{
                                                          marginLeft: 'auto',
                                                      }}
                                                  >
                                                      <EnterFullScreen />
                                                  </div>
                                              </>
                                          );
                                      }
                                    : null}
                            </Toolbar>
                        </div>

                        <div
                            //     style={props.isLocked?{
                            //         flex: 1,
                            //         height: (window.innerHeight-window.innerHeight/2.4),
                            //     }:{
                            //         flex: 1,
                            //         height: (window.innerHeight-window.innerHeight/2.5),
                            //     }
                            //   }
                            className='pdf-scroll'
                            id='pdf-scroll'
                            // style={{ flex: 1, height: window.innerHeight }}
                            style={{ flex: 1, height: pdfHeight }}
                        >
                            <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js'>
                                <Viewer
                                    fileUrl={currentBase64}
                                    plugins={[
                                        toolbarPluginInstance,
                                        // printPluginInstance,
                                        // searchPluginInstance,
                                        // pageNavigationPluginInstance
                                    ]}
                                    // defaultScale={customZoom} ( removed for image resize black issue )
                                />
                            </Worker>
                        </div>
                    </div>
                </div>
            ) : apiError === false ? (
                <div
                    id={currentPage === 'detail' ? 'right-aside' : 'left-aside'}
                    className={
                        currentPage === 'detail'
                            ? 'left-size-loader'
                            : 'right-size-loader bbb'
                    }
                    style={
                        currentPage === 'detail' ? { textAlign: 'center' } : {}
                    }

                    // style={{ position: 'fixed' }}
                >
                    Loading .....
                    <Loader
                        type='Oval'
                        color='#007dbc'
                        height={40}
                        width={40}
                        // timeout={5000}
                    />
                </div>
            ) : null}
            <SpinnerBlur show={blurSpinner} />
        </>
    );
});

export default PdfPreview;
// export default PdfPreview;
