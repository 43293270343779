import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    GET_OWNERS_AUTH,
    GET_OWNERS_SUCCESS_AUTH,
    GET_OWNERS_FAILURE_AUTH,
    DEFAULT_AUTH_STATE,
} from '../actionTypes/checkAuthTypes';

const initialAuthState = {
    validToken: false,
};

export const checkAuthReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case DEFAULT_AUTH_STATE:
            return {
                ...state,
            };
        case GET_OWNERS_AUTH:
            return { ...state, owners: action.data };
        case GET_OWNERS_SUCCESS_AUTH:
            return { ...state, owners: action.data };
        case GET_OWNERS_FAILURE_AUTH:
            return { ...state, error: action.error };
        default:
            return state;
    }
};
