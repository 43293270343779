/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';
import GroupAutocomplete from './GroupAutocomplete';
import VerificationModal from './VerificationModal';
import { inboxPage } from '../_redux/actions/inboxActions';
import { updateUserDetails } from '../api/api';
import {
    normalSuccessToast,
    showErrorToast,
} from '../../components/commonfunction';

const postLoading = `post_loading_${Math.random()}`;
const GroupModal = (props) => {
    const [btnLoader, setBtnLoader] = useState(false);
    const redux_store = useSelector((state) => state);
    const dispatch = useDispatch();
    const handleUpdateUserGroup = () => {
        setBtnLoader(true);
        const groupArray =
            redux_store.inbox.inboxPage.updateGroupArray !== undefined
                ? redux_store.inbox.inboxPage.updateGroupArray
                : props.groupArray;
        let usergroups = {};
        const dummyArray = [];
        if (groupArray.length > 0) {
            groupArray.map((item) => {
                props.bigArrayGroup.map((data) => {
                    if (JSON.stringify(data.name) === JSON.stringify(item)) {
                        dummyArray.push({
                            id: data.id,
                            name: item,
                        });
                    }
                });
            });
        }
        if (dummyArray.length > 0) {
            usergroups = dummyArray;
        } else {
            usergroups = [];
        }
        const fields = {};
        const userId = props.selectedUserDetails.userid;
        fields.userId = props.selectedUserDetails.userid;
        fields.usergroups = usergroups;
        let status;
        updateUserDetails(userId, fields)
            .then((response) => {
                status = response.status;
                if (status === 204) {
                    normalSuccessToast('Updated Successfully');
                    setBtnLoader(false);
                    props.updateState({
                        groupModal: false,
                        loader: true,
                        openVerfication: false,
                    });
                    props.refreshTable();
                    dispatch(
                        inboxPage({
                            updateGroupArray: undefined,
                        })
                    );
                }
            })
            .then((data) => {
                if (status === 400 || status === 404) {
                    showErrorToast('Failed to update');
                    setBtnLoader(false);
                }
            });
    };

    const handleCloseOperation = () => {
        props.updateState({
            groupModal: false,
            openVerfication: false,
        });
        dispatch(
            inboxPage({
                updateGroupArray: undefined,
            })
        );
    };
    const VerificationModalFunction = () => {
        props.updateState({
            openVerfication: true,
        });
    };

    return (
        <>
            <Modal
                show={props.groupModal}
                animation={false}
                onHide={handleCloseOperation}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>Edit Groups</div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleCloseOperation}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='deletes'>
                    <h6 className='info_msg'>
                        You are editing the groups for{' '}
                        <b>{props.selectedUserDetails.username} </b>
                        {props.selectedUserDetails.email !== ''
                            ? '(' + props.selectedUserDetails.email + ')'
                            : ''}
                        <br />
                    </h6>

                    <GroupAutocomplete
                        editGroup={props.editGroup}
                        selectedUserDetails={props.selectedUserDetails}
                        heightScroll={props.heightScroll}
                        bigArrayGroup={props.bigArrayGroup}
                        groupArray={props.groupArray}
                        updateGroupArray={props.updateGroupArray}
                        updateState={(state) => {
                            props.updateState(state);
                        }}
                    />
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                variant='secondary'
                                onClick={() => {
                                    props.editGroup === false
                                        ? handleCloseOperation()
                                        : VerificationModalFunction();
                                }}
                                title='Cancel'
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            {btnLoader === true ? (
                                <Button
                                    type='button'
                                    variant='success'
                                    className='post_loading'
                                    id={postLoading}
                                >
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                </Button>
                            ) : (
                                <Button
                                    variant='success'
                                    onClick={() => {
                                        handleUpdateUserGroup();
                                    }}
                                    title='Save Changes'
                                >
                                    Save Changes
                                </Button>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <>
                <VerificationModal
                    openVerfication={props.openVerfication}
                    handleUpdateUserGroup={() => {
                        handleUpdateUserGroup();
                    }}
                    handleCloseOperation={() => {
                        handleCloseOperation();
                    }}
                />
            </>
        </>
    );
};
export default GroupModal;
