import {
    CONFIG_DATA,
    UPDATE_CONFIGURED_RECORDSETS,
    UPDATE_CUSTOM_SEARCHES,
    ACTION_ARRAY,
    GET_DDL_LIST,
    LOG_OUT,
} from '../actionTypes/configTypes';

export const getConfigData = (configData) => ({
    type: CONFIG_DATA,
    configData,
});

export const getRecordSetConfig = (
    configuredRecordsets,
    recordsetNamedIdsNeedingConfiguration
) => ({
    type: UPDATE_CONFIGURED_RECORDSETS,
    configuredRecordsets,
    recordsetNamedIdsNeedingConfiguration,
});

export const getCustomSearchConfig = (
    customSearchQueries,
    customSearchNamedIdsNeedingConfiguration
) => ({
    type: UPDATE_CUSTOM_SEARCHES,
    customSearchQueries,
    customSearchNamedIdsNeedingConfiguration,
});

export const getActionArray = (actionArray) => ({
    type: ACTION_ARRAY,
    actionArray,
});

export const getDDLlist = (DDL) => ({
    type: GET_DDL_LIST,
    DDL,
});

export const logOut = (IslogouTrue) => ({
    type: LOG_OUT,
    IslogouTrue,
});
