import React from 'react';
// import { useFormik } from "formik";
// import { connect } from "react-redux";
import { Link } from 'react-router-dom';
// import * as Yup from "yup";
// import { injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { requestPassword } from "../_redux/authCrud";
// import { isThisISOWeek } from "date-fns";
// import { Toast } from "react-bootstrap";

// const initialValues = {  email: ""};

export default class SuccessPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <div
                    className='login-form login-forgot m-auto'
                    style={{ display: 'block' }}
                >
                    <div className='Logo confirmation text-center  mb-2 mb-lg-10'>
                        <a href='#ngo' title='Logo'>
                            {/* <img src={window.location.origin+ '/images/WV-Logo.png'} /> */}
                        </a>
                    </div>
                    <div className='text-center mb-5 mb-lg-4'>
                        <h3 className='font-size-h1'>Check your email!</h3>
                        <div className='mt-4 '>
                            If the user name exists, you just got an email from
                            us. Please note it can take up to 10 minutes. Follow
                            the instructions there to reset your password. You
                            can close this screen anytime.
                        </div>
                    </div>
                    <div className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'>
                        <div className='form-group d-flex flex-wrap flex-center frget_pass'>
                            <Link
                                to='/auth'
                                title='Back to Sign in'
                                className='confirm_btn'
                            >
                                <button
                                    type='button'
                                    id='kt_login_forgot_cancel'
                                    className='btn text-white btn-primary font-weight-bold px-9 py-4 my-3 mr-6 ml-0 login-btn btn-block'
                                >
                                    Back to Sign in
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div class="d-flex footer_text justify-content-center justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
			<p className="">You’re signing in to Graham Healthcare Group instance of WorldView</p>			
		</div> */}
            </>
        );
    }
}
