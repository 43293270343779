/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    download,
    download_multiple,
    formatNameWithSpaceToUnderscore,
    GetFieldData,
    getNotes,
    showErrorToast,
    showSuccessToast,
    stringEmptyOrUndefined,
    updateAllactions,
    RefreshCounts,
    getActionsByProcessAndStatus,
} from '../../../components/commonfunction';
import { outboxDocumentAction } from '../../api/api';
import { getCountData, showLoader } from '../../_redux/actions/inboxActions';
import {
    getListaction,
    getSelectedRowDetails,
    outboxPage,
    updateOutboxState,
    updateList,
} from '../../_redux/actions/outboxActions';
import {
    updateSearchState,
    deletedPage,
} from '../../_redux/actions/searchActions';
import CoverPageModal from './CoverPageModal';
import DeliveryDetailModal from './DeliveryDetailModal';
import { DocumentHistroy } from './DocumentHistroy';
import FaxNowModal from './FaxNowModal';
import { Notes } from './Notes';
import RemoveTrackingModal from './RemoveTrackingModal';
import Office365AuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
import GmailAuthenticationPage from '../../../components/composeDraftMail/GmailAuthenticationPage';
import { DocumentIdToPrint } from '../../../../utils/printPdf/printPdf';

// import for msal microsoft configuration

export class OutboxActionsComponent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            selectedBulkAction: 'Fax Now',
            // allActions:
            //     this.props.hasOwnProperty('allActions') &&
            //     this.props.allActions !== undefined
            //         ? this.props.allActions
            //         : {},
            allActions:
                this.props.deleteDocument === 'deleted_document' &&
                this.props.deleted_Page_redux.allActions !== undefined
                    ? this.props.deleted_Page_redux.allActions
                    : this.props.deleteDocument === 'deleted_document' &&
                      this.props.deleted_Page_redux.allActions === undefined
                    ? {}
                    : this.props.allActions,
            calingFrom: this.props.calingFrom,
            noteTypes: undefined,
            openFaxNow: false,
            faxNumberRequired: false,
            resumeResending: false,
            pauseResending: true,
            open_delivery_detail: false,
            open_notes_section: false,
            rowsToRemove: 0,
            printSelectedDocId: [],
            openHistroy: false,
            selectedDocId: this.props.selectedDocId,
            multi_sendFlag: false,
            faxSaveBtn: false,
            postLoading: `post_loading_${Math.random()}`,
            posted: `posted_${Math.random()}`,
            submitted: 0,
            submitted_faxNow: 0,
            show: true,
            allowResume: true,
            allowDelete: true,
            configData: this.props.configData?.emailIntegration,
            serviceType: false,
            noUsedAction: [],
        };
    }

    async componentDidMount() {
        const serviceType =
            this.props.configData?.emailIntegration?.serviceType ===
                'Office 365' ||
            this.props.configData?.emailIntegration?.serviceType === 'Google'
                ? true
                : false;
        this.setState({ serviceType }); // if service type is "Exchange" will implement later
        this._isMounted = true;
        // if (typeof window !== 'undefined' || window !== null) {
        //     const url = window.location.hash;
        // }

        // let config = localStorage.getItem('configData')
        // config = JSON.parse(config)

        if (
            this.props.deleteDocument === 'deleted_document' &&
            this.props.deleted_Page_redux.allActions === undefined
        ) {
            this.setState({
                allActions: {},
            });
        }

        // if (this.props.deleteDocument === 'deleted_document') {
        //     if (
        //         this.props.allActions !== undefined &&
        //         this.props.allActions !== null &&
        //         Object.keys(this.props.allActions).length !== 0
        //     ) {
        //         await this.setState({
        //             allActions: this.props.allActions,
        //         });
        //     } else if (
        //         this.props.actionsDD !== undefined &&
        //         this.props.actionsDD !== null &&
        //         Object.keys(this.props.actionsDD).length !== 0
        //     ) {
        //         await this.setState({
        //             allActions: this.props.actionsDD,
        //         });
        //     }
        // }

        if (
            (this.props.deleteDocument !== 'deleted_document' &&
                this.props.allActions !== undefined &&
                this.props.allActions !== null &&
                Object.keys(this.props.allActions).length !== 0 &&
                this.props.allActions.hasOwnProperty('fax_now')) ||
            this.state.allActions.fax_now !== undefined
        ) {
            this.setState({ selectedBulkAction: 'Fax Now' });
        } else {
            this.setState({ selectedBulkAction: ' ' });
        }
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldIds: fieldDataObj });
        const noteTypes = getNotes(
            this.props.configData,
            this.props.processId,
            this.props.statusId
        );

        this.setState({ noteTypes: noteTypes });
        const pushEditId = [];
        pushEditId.push(window.btoa(String(this.props.selectedDocId)));
        const editURL = `/documents/edit/${pushEditId}`;
        if (this.props.pageCount > 0) {
            this.setState({ editId: editURL });
        } else {
            this.setState({ editId: '' });
        }
        if (
            this.state.calingFrom === 'smallView' ||
            this.state.calingFrom === 'bigView'
        ) {
            let foundField;
            for (const field of this.props.selectRowDetails.fields) {
                if (field.fieldId === fieldDataObj.send_flag) {
                    foundField = true;
                    break; // Exit the loop once the field is found
                }
            }

            if (foundField) {
                this.setState({ sendFlag: true });
            } else {
                this.setState({ sendFlag: false });
            }
            /* if (this.props.rowDetails !== undefined) {
                if (
                    this.props.rowDetails.sendflag !== undefined &&
                    (this.props.rowDetails.sendflag === 'DO NOT SEND' ||
                        this.props.rowDetails.sendflag === 'DONOTSEND')
                ) {
                    this.setState({ sendFlag: true });
                } else {
                    this.setState({ sendFlag: false });
                }
            } */
        } else {
            // var docIds = [];
            const selectedIndex = Array.from(
                this.props.outbox.stateData.selectedRows
            );

            this.props.outbox.stateData.rowData.map((data, index) =>
                selectedIndex.includes(data.id)
                    ? data.sendflag === 'DO NOT SEND' ||
                      data.sendflag === 'DONOTSEND'
                        ? this.setState({ multi_sendFlag: true })
                        : null
                    : null
            );
        }
        const pathName = window.location.pathname.split('/');
        this.setState({
            currentURL: pathName[2],
        });
        if (this.props.reportView === 'report') {
            const { configData, ReportprocessId, ReportstatusId } = this.props;
            let reportActions = getActionsByProcessAndStatus(
                configData,
                ReportprocessId,
                ReportstatusId
            );

            await this.setState({ allActions: reportActions });
            await this.setState({ selectedBulkAction: 'Fax Now' });
        }

        if (
            this.props.globalActions !== undefined &&
            this.props.globalActions.length > 0
        ) {
            // this.props.globalActions.map((actions) => {
            //     if (
            //         formatNameWithSpaceToUnderscore(actions.name) === 'undelete'
            //     ) {
            //         this.setState({ allowDelete: false });
            //     } else if (
            //         formatNameWithSpaceToUnderscore(actions.name) ===
            //         'resume_tracking'
            //     ) {
            //         this.setState({ allowResume: false });
            //     }
            // });

            this.props.globalActions.map((actions) =>
                formatNameWithSpaceToUnderscore(actions.name) === 'undelete'
                    ? this.setState({ allowDelete: false })
                    : formatNameWithSpaceToUnderscore(actions.name) ===
                      'resume_tracking'
                    ? this.setState({ allowResume: false })
                    : null
            );
        }
        let allActions = this.state.allActions;
        for (const property in this.state.allActions) {
            let newObject;
            newObject = allActions[property];
            newObject = {
                ...newObject,
                used: 'No',
            };
            allActions[property] = newObject;
        }

        let dataArray = [
            'assign_to_user',
            'update_tag',
            'fax_now',
            'update_fax_number',
            'send_in_batch_fax',
            'mark_order_as_sent',
            'send_to_e_sign',
            'send_via_email',
            'follow_up_notes',
            'update_cover_page',
            'update_cover_page_comments',
            'remove_from_tracking',
            'update_delivery_method',
            'update_fax_number',
            'update_information_manually',
            'sync_information_from_emr',
            'send_do_not_send',
        ];

        let finalAllActions = updateAllactions(allActions, dataArray);
        await this.setState({
            allActions: finalAllActions,
        });
        let noUsedAction = [];
        for (const property in this.state.allActions) {
            let newObject = this.state.allActions[property];

            if (newObject.used === 'No') {
                noUsedAction.push(property);
            }
        }

        this.setState({
            noUsedAction: noUsedAction,
        });
    }

    async componentWillUnmount() {
        await this.setState({ open_notes: false });
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.props.deleteDocument === 'deleted_document') {
        //     if(this.state.allActions !== undefined && this.props.deleted_page.allActions !== undefined){
        //         if(JSON.stringify(this.state.allActions) !== JSON.stringify(this.props.deleted_page.allActions))
        //         {
        //             this.setState({
        //                 allActions: this.props.deleted_page.allActions,
        //             })
        //         }
        //     } else{
        //         this.setState({
        //             allActions: {},
        //           })
        //     }
        // }

        if (
            prevProps.outbox.stateData.hasOwnProperty('selectedRows') &&
            this.props.outbox.stateData.hasOwnProperty('selectedRows') &&
            prevProps.outbox.stateData.selectedRows !==
                this.props.outbox.stateData.selectedRows
        ) {
            const multiSendFlagsArray = [];
            const selectedIndex = Array.from(
                this.props.outbox.stateData.selectedRows
            );
            if (this.props.outbox.stateData.rowData !== undefined) {
                this.props.outbox.stateData.rowData.map((data, index) =>
                    selectedIndex.includes(data.id)
                        ? multiSendFlagsArray.push(data.sendflag)
                        : null
                );
            }

            /* if (multiSendFlagsArray.some((item) => item === 'DO NOT SEND')) {
                this.setState({
                    multi_sendFlag: true,
                });
            } */
            if (
                multiSendFlagsArray.includes('DO NOT SEND') &&
                multiSendFlagsArray.includes('')
            ) {
                this.setState({
                    bothFlag: true,
                    multi_sendFlag: false,
                });
            }
            if (
                multiSendFlagsArray.includes('DO NOT SEND') &&
                !multiSendFlagsArray.includes('')
            ) {
                this.setState({
                    bothFlag: false,
                    multi_sendFlag: true,
                });
            }
            if (
                !multiSendFlagsArray.includes('DO NOT SEND') &&
                multiSendFlagsArray.includes('')
            ) {
                this.setState({
                    bothFlag: false,
                    multi_sendFlag: false,
                });
            }
            if (this.props.outbox.stateData.rowDetails !== undefined) {
                if (
                    this.props.rowDetails.sendflag !== undefined &&
                    (this.props.rowDetails.sendflag === 'DO NOT SEND' ||
                        this.props.rowDetails.sendflag === 'DONOTSEND')
                ) {
                    this.setState({ sendFlag: true });
                } else {
                    this.setState({ sendFlag: false });
                }
            }
        }

        // Print using printSelectedDocId props

        if (prevState.printSelectedDocId !== this.state.printSelectedDocId) {
            DocumentIdToPrint(this.state.printSelectedDocId)
                .then(() => {
                    if (this.props.showLoader) {
                        this.props.showLoader(false);
                        this.props.deletedPage({ blurSpinner: false });
                    }
                    this.setState({
                        printLoader: false,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        printLoader: false,
                    });
                    this.props.deletedPage({ blurSpinner: false });
                });
        }
    }

    handleHistroy = () => {
        this.setState(
            {
                openHistroy: true,
            },
            () => {
                this.props.updateState({
                    openHistroy: true,
                });
            }
        );
    };

    handleHistroyClose = () => {
        this.setState({
            openHistroy: false,
        });
    };

    open_delivery = () => {
        if (this.state.calingFrom === 'smallView') {
            if (this.props.open_delivery_detail_section === false) {
                this.setState(
                    {
                        open_delivery_detail_section: true,
                        open_notes_section: false,
                    },
                    () => {
                        this.props.updateState({
                            open_delivery_detail_section: true,
                            open_notes_section: false,
                        });
                    }
                );
            } else {
                this.setState(
                    {
                        open_delivery_detail_section: false,
                    },
                    () => {
                        this.props.updateState({
                            open_delivery_detail_section: false,
                            open_notes_section: false,
                        });
                    }
                );
            }
        } else {
            this.setState(
                {
                    open_delivery_detail: true,
                },
                () => {
                    this.props.updateState({ open_delivery_detail: true });
                }
            );
        }
    };

    updateState = (state) => {
        this.props.updateState(state);
    };

    open_cover = () => {
        this.setState({
            open_cover_page: true,
        });
    };

    open_remove_tracking = () => {
        const docIds = [];
        if (
            this.state.calingFrom === 'smallView' ||
            this.state.calingFrom === 'bigView'
        ) {
            docIds.push(this.props.outbox.stateData.selectedDocId);
        } else {
            const selectedIndex = Array.from(
                this.props.outbox.stateData.selectedRows
            );
            this.props.outbox.stateData.rowData.map((data, index) =>
                selectedIndex.includes(data.id)
                    ? docIds.push(data.doc_id)
                    : null
            );
        }

        this.setState({
            open_remove_tracking: true,
            removeNotes: [],
        });

        this.setState({ rowsToRemove: docIds.length }, () => {
            this.updateState({ rowsToRemove: docIds.length });
        });
    };

    selectBulkAction = (selectedBulkAction) => {
        this.setState({
            selectedBulkAction: selectedBulkAction,
            faxSaveBtn: false,
            // submitted:0,
        });
    };

    pauseResumeResendingAction = async (selectedAction) => {
        // this.setState({ selectedAction: selectedAction });
        // this.setState({ selectedAction: 'Send/Do Not Send' });

        //if (this.state.calingFrom === 'smallView') {
        this.setState({
            sendFlag: !this.state.sendFlag,
        });
        //}
        if (selectedAction === 'Pause Resending') {
            await this.handleDocumentAction(
                'Send/Do Not Send',
                'Pause Resending'
            );
        }
        if (selectedAction === 'Resume Resending') {
            await this.handleDocumentAction(
                'Send/Do Not Send',
                'Resume Resending'
            ); // to be checked
        }
    };

    sendESignAction = async (selectedAction) => {
        console.log('selectedAction', selectedAction);
        if (
            selectedAction === 'E-Sign Push Notification' ||
            selectedAction == 'Send Back to E-Sign Initial'
        ) {
            await this.handleDocumentAction(selectedAction); //
        }
    };

    removeFromESignAction = async (selectedAction) => {
        console.log('selectedAction', selectedAction);

        if (selectedAction === 'Remove from E-Sign') {
            await this.handleDocumentAction('Remove from E-Sign'); //
        }
    };
    refresh_whole() {
        // this.props.refresh_whole();
        this.props.refreshTable();
    }
    deleteRowManually = async () => {
        await RefreshCounts(
            this.props.outbox.processId,
            this.props.outbox.statusId,
            this.props //Here count from parent and getCountData is passing
        );
        const selectedRowDetailsID =
            this.props.selectRowDetails.miscPproperties.docId;

        let filteredData =
            this.props.outbox.stateData.filterTxt !== ''
                ? this.props.outbox.outboxpage.allFilteredRowData
                : this.props.outbox.stateData.rowData;

        const rowsToDelete = [];
        let nextIndex = '';
        let currentIndex;
        let nextDocId = '';
        if (
            this.props.calingFrom !== 'deleted_document' &&
            this.props.reportView !== 'report'
        ) {
            const rowLen = filteredData.length;

            filteredData.map((tableData, i) => {
                if (tableData.doc_id === selectedRowDetailsID) {
                    if (rowLen === i + 1) {
                        nextIndex = '';
                    } else {
                        nextIndex = i + 1;
                        currentIndex = tableData.id;
                    }
                }
            });

            if (nextIndex !== '') {
                rowsToDelete.push(filteredData[nextIndex]);
                nextDocId = filteredData[nextIndex].doc_id;
            }
        }
        var rowStateData =
            this.props.outbox.stateData.filterTxt !== ''
                ? this.props.outbox.outboxpage.allFilteredRowData.slice()
                : this.props.outbox.stateData.rowData.slice();

        rowStateData = rowStateData.filter((obj) => obj.id !== currentIndex);

        let dataRows = this.props.outbox.outbox.dataTable.dataRows.filter(
            (obj) => obj.id !== selectedRowDetailsID
        );
        this.props.outbox.outbox.dataTable.dataRows = dataRows;
        await RefreshCounts(
            this.props.outbox.processId,
            this.props.outbox.statusId,
            this.props //Here count from parent and getCountData is passing
        );
        if (this.props.outbox.stateData.filterTxt !== '') {
            await this.props.updateList(this.props.outbox.outbox);
            await this.props.outboxPage({
                deleteRow: true,
                allFilteredRowData: rowStateData,
            });
        } else {
            await this.props.updateList(this.props.outbox.outbox);
            await this.props.outboxPage({
                //allRowData: rowStateData,
                deleteRow: true,
                stateData: {
                    ...this.props.stateData,
                    rowData: rowStateData,
                },
                //stat
            });
        }
    };
    handleDocumentAction = (
        actionName = this.state.selectedBulkAction,

        action = ''
    ) => {
        if (actionName !== 'fax_now_to_alternate_number') {
            this.setState({ faxSaveBtn: true });
        }
        console.log('actionName', actionName);
        let action_name = '';
        let actionId = '';

        if (actionName === 'Follow-Up Notes') {
            action_name = 'follow_up_notes';
        } else {
            action_name = formatNameWithSpaceToUnderscore(actionName);
        }
        console.log('action_name', action_name);
        if (
            action_name === 'fax_now_to_alternate_number' &&
            action !== 'submitFaxNow'
        ) {
            this.setState({
                openFaxNow: true,
            });
            this.setState(
                {
                    submitted_faxNow: 0,
                },
                () => {
                    this.props.updateState({
                        submitted_faxNow: 0,
                    });
                }
            );

            return true;
        }
        if (action_name === 'fax_now_to_alternate_number') {
            action_name = 'update_fax_number';
        }
        if (action_name === 'refresh_with_emr') {
            action_name = 'sync_information_from_emr';
        }
        console.log('this.props', this.props);
        console.log('action_name', action_name);
        console.log(
            'this.props.outbox.stateData.allActions',
            this.props.outbox.stateData.allActions
        );
        if (
            this.props.deleteDocument !== 'deleted_document' &&
            this.props.reportView !== 'report' &&
            this.props.outbox.stateData.allActions[action_name] !== undefined
        ) {
            actionId =
                this.props.outbox.stateData.allActions[action_name].actionId;
            console.log(
                'actionid1,',
                this.props.outbox.stateData.allActions[action_name].actionId
            );
        }
        if (
            this.props.deleteDocument === 'deleted_document' &&
            this.state.allActions !== undefined
        ) {
            actionId = this.state.allActions[action_name].actionId;
            console.log('deleteDocument,', actionId);
        }
        if (
            action_name !== 'sync_information_from_emr' &&
            action !== 'Resume Resending' &&
            action !== 'Pause Resending'
        ) {
            this.setState({
                submitted: 1,
            });
        }
        let docIds = [];

        if (
            this.state.calingFrom === 'smallView' ||
            this.state.calingFrom === 'bigView'
        ) {
            docIds.push(this.props.selectedDocId);
        } else {
            const selectedIndex = Array.from(
                this.props.outbox.stateData.selectedRows
            );
            this.props.outbox.stateData.rowData.map((data, index) =>
                selectedIndex.includes(data.id)
                    ? docIds.push(data.doc_id)
                    : null
            );
        }

        const maxAllowedRows = 25;

        const isRowLimitedAction = (action_name) => {
            const limitedRowsActions = [
                'fax_now',
                'fax_now_to_alternate_number',
                'update_fax_number',
                'send_in_batch_fax',
                'mark_order_as_sent',
                'send_to_e_sign',
            ];
            return limitedRowsActions.includes(action_name);
        };

        if (docIds.length > maxAllowedRows && isRowLimitedAction(action_name)) {
            const errorMsg = `Selection limit exceeded: Maximum number of documents to submit with this action is ${maxAllowedRows}. You have selected ${docIds.length}.`;

            showErrorToast(errorMsg);
            this.setState(
                {
                    submitted: 0,
                    submitted_faxNow: 0,
                    childFaxnow_update: true,
                    faxSaveBtn: false,
                },
                () => {
                    this.props.updateState({
                        submitted_faxNow: 0,
                    });
                }
            );
            return;
        }

        let processId = 1;
        let statusId = 1;
        if (String(this.props.processId) !== '') {
            processId = this.props.processId;
        }
        if (String(this.props.statusId) !== '') {
            statusId = this.props.statusId;
        }
        if (this.props.deleteDocument === 'deleted_document') {
            statusId = this.props.delstatusId;
            processId = this.props.delprocessId;
        }
        if (this.props.reportView === 'report') {
            statusId = this.props.ReportstatusId;
            processId = this.props.ReportprocessId;
            actionId = this.state.allActions[action_name].actionId;
        }

        // var fieldsTemp = [];
        const params = {};
        const fields = {};
        fields.docIds = docIds;

        if (action === 'submitFaxNow') {
            if (this.state.alternateFaxNumber !== '') {
                fields.fields = [
                    {
                        fieldId: this.state.allFieldIds.fax_number,
                        value: this.state.alternateFaxNumber,
                    },
                ];
            } else {
                this.setState({ faxNumberRequired: true });
            }
        } else if (action_name === 'send_do_not_send') {
            if (action === 'Pause Resending') {
                fields.fields = [
                    {
                        fieldId: this.state.allFieldIds.send_flag,
                        value: 'DO NOT SEND',
                    },
                ];
            }
            if (action === 'Resume Resending') {
                fields.fields = [
                    {
                        fieldId: this.state.allFieldIds.send_flag,
                        value: '',
                    },
                ];
            }
        } else {
            fields.fields = []; // need to send empty array
        }
        params.processId = processId;
        params.statusId = statusId;
        params.actionId = actionId;
        params.fields = fields;
        console.log('deleteDocument,', params.actionId);
        outboxDocumentAction(params)
            .then((response) => {
                if (response.status === 400) {
                    if (response.message !== undefined) {
                        const { message } = response;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');
                        showErrorToast(error);
                        this.setState(
                            {
                                submitted: 0,
                                submitted_faxNow: 0,
                            },
                            () => {
                                this.props.updateState({
                                    submitted_faxNow: 0,
                                });
                            }
                        );
                    } else {
                        showErrorToast('Error in Processing.');
                        if (action_name !== 'sync_information_from_emr') {
                            this.setState(
                                {
                                    submitted: 0,
                                    submitted_faxNow: 0,
                                },
                                () => {
                                    this.props.updateState({
                                        submitted_faxNow: 0,
                                    });
                                }
                            );
                        }
                    }
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                if (data.hadFailures === true) {
                    let failedDocId = [];
                    let failedMessage = [];
                    let message = '';

                    data.results.map((item, idx) => {
                        if (item.actionSucceeded === false) {
                            failedDocId.push(item.docId);
                            failedMessage.push(item.message);
                        }
                    });
                    failedDocId.map((property, index) => {
                        if (index === 0) {
                            message += '[';
                        }

                        message += property + ' ';
                        if (failedDocId.length - 1 === index) {
                            message += ']';
                        }
                    });
                    let errorMsg = failedMessage[0].includes('lock')
                        ? 'Unable to obtain document lock'
                        : failedMessage[0];
                    showErrorToast(errorMsg + ' ' + message);

                    this.setState(
                        {
                            submitted: 0,
                            submitted_faxNow: 0,
                            childFaxnow_update: true,
                            faxSaveBtn: false,
                        },
                        () => {
                            this.props.updateState({
                                submitted_faxNow: 0,
                            });
                        }
                    );
                } else {
                    if (
                        action_name === 'fax_now' &&
                        this.state.calingFrom === 'actionBar'
                    ) {
                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                        if (isMobile) {
                            document.body.classList.remove('doc-small-view');
                            document.body.classList.remove('doc-detail-view');
                        }
                        if (this.props.fromSearch !== 'search') {
                            /* this.props.getListaction(
                                this.props.outbox.outbox.processId,
                                this.props.outbox.outbox.statusId
                            ); */
                        }

                        if (this.props.deleteDocument === 'deleted_document') {
                            if (typeof this.props.closeBigView === 'function') {
                                this.props.closeBigView();
                            }
                            document.body.classList.remove('doc-small-view');
                            document.body.classList.remove('doc-detail-view');
                            document.body.classList.remove('aside-minimize');
                        }
                        /* this.props.updateOutboxState({
                            selectedRows: new Set(),
                            row_transition: false,
                        }); */
                    }
                    if (action_name === 'update_fax_number') {
                        this.callFaxNow(params, fields); // it is used for alternate fax number
                    } else if (action_name === 'send_do_not_send') {
                        this.setState({ submitted: 0 });
                        this.changePauseResumeResnding(action);
                    } else {
                        showSuccessToast('Process Successfully Completed.');
                        if (action_name !== 'sync_information_from_emr') {
                            this.setState({
                                submitted: 0,
                            });

                            this.setState(
                                {
                                    submitted_faxNow: 1,
                                },
                                () => {
                                    this.props.updateState({
                                        submitted_faxNow: 1,
                                    });
                                }
                            );
                        }

                        if (
                            action_name === 'fax_now' ||
                            action_name === 'mark_order_as_sent' ||
                            action_name === 'send_in_batch_fax' ||
                            action_name === 'send_to_e_sign' ||
                            action_name === 'send_via_email' ||
                            action_name === 'update_fax_number'
                        ) {
                            if (this.props.fromSearch !== 'search') {
                                /*  this.props.getListaction(
                                     this.props.outbox.outbox.processId,
                                     this.props.outbox.outbox.statusId
                                 ); */
                            }

                            if (isMobile) {
                                document.body.classList.remove(
                                    'doc-small-view'
                                );
                                document.body.classList.remove(
                                    'doc-detail-view'
                                );
                            }
                            if (this.state.calingFrom === 'actionBar') {
                                if (this.props.fromSearch !== 'search') {
                                    // this.props.refresh_whole();
                                }

                                /*  document.body.classList.remove(
                                    'aside-minimize'
                                );
                                document.body.classList.remove(
                                    'doc-small-view'
                                );
                                this.props.updateOutboxState({
                                    selectedRows: new Set(),
                                    row_transition: false,
                                }); */
                            } else {
                                if (
                                    this.props.deleteDocument !==
                                        'deleted_document' &&
                                    this.props.reportView !== 'report'
                                ) {
                                    this.deleteRowManually();
                                    document.body.classList.remove(
                                        'aside-minimize'
                                    );
                                    document.body.classList.remove(
                                        'doc-small-view'
                                    );
                                    document.body.classList.remove(
                                        'doc-detail-view'
                                    );
                                    this.props.rowTransition();
                                }
                            }
                        }
                        if (
                            /*  action_name === 'fax_now' ||
                            action_name === 'mark_order_as_sent' ||
                            action_name === 'send_in_batch_fax' ||
                            action_name === 'send_to_e_sign' ||
                            action_name === 'send_via_email' ||
                            action_name === 'update_fax_number' || */
                            action_name === 'sync_information_from_emr'
                        ) {
                            // showSuccessToast('I am here------------');
                            if (
                                this.props.fromSearch !== 'search' &&
                                this.props.reportView !== 'report'
                            ) {
                                this.props.getListaction(
                                    this.props.outbox.outbox.processId,
                                    this.props.outbox.outbox.statusId
                                );
                            }
                            // this.props.updateOutboxState({
                            //     selectedRows: new Set(),
                            //     row_transition: false,
                            // });
                            if (isMobile) {
                                document.body.classList.remove(
                                    'doc-small-view'
                                );
                                document.body.classList.remove(
                                    'doc-detail-view'
                                );
                            }
                            if (this.state.calingFrom === 'actionBar') {
                                // showSuccessToast('I am here------------');
                                if (this.props.fromSearch !== 'search') {
                                    this.props.refresh_whole();
                                }

                                document.body.classList.remove(
                                    'aside-minimize'
                                );
                                document.body.classList.remove(
                                    'doc-small-view'
                                );
                                this.props.updateOutboxState({
                                    selectedRows: new Set(),
                                    row_transition: false,
                                });
                            } else {
                                if (
                                    this.props.deleteDocument !==
                                        'deleted_document' &&
                                    this.props.reportView !== 'report'
                                ) {
                                    this.props.outboxrefreshTable();
                                }
                            }
                        }
                    }
                }
            });
        /* if (response.status === 400) {
                    if (response.message !== undefined) {
                        const { message } = response;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');
                        showErrorToast(error);
                    } else {
                        showErrorToast('Error in Processing.');
                        if (action_name !== 'sync_information_from_emr') {
                            this.setState({
                                submitted: 0,
                            });
                        }
                    }
                } else if (response.status === 204 || response.status === 200) { */

        /*  if (
                        action_name === 'fax_now' &&
                        this.state.calingFrom === 'actionBar'
                    ) {
                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                        if (isMobile) {
                            document.body.classList.remove('doc-small-view');
                            document.body.classList.remove('doc-detail-view');
                        }
                        if (this.props.fromSearch !== 'search') {
                            this.props.getListaction(
                                this.props.outbox.outbox.processId,
                                this.props.outbox.outbox.statusId
                            );
                        }
                        this.props.updateOutboxState({
                            selectedRows: new Set(),
                            row_transition: false,
                        });
                    }
                    if (action_name === 'update_fax_number') {
                        this.callFaxNow(params, fields);
                    } else if (action_name === 'send_do_not_send') {
                        this.setState({ submitted: 0 });
                        this.changePauseResumeResnding(action);
                    } else {
                        showSuccessToast('Process Successfully Completed.');
                        if (action_name !== 'sync_information_from_emr') {
                            this.setState({
                                submitted: 0,
                            });

                            this.setState(
                                {
                                    submitted_faxNow: 1,
                                },
                                () => {
                                    this.props.updateState({
                                        submitted_faxNow: 1,
                                    });
                                }
                            );
                        }
                        if (
                            action_name === 'fax_now' ||
                            action_name === 'mark_order_as_sent' ||
                            action_name === 'send_in_batch_fax' ||
                            action_name === 'send_to_e_sign' ||
                            action_name === 'send_via_email' ||
                            action_name === 'update_fax_number' ||
                            action_name === 'sync_information_from_emr'
                        ) {
                            // showSuccessToast('I am here------------');
                            if (this.props.fromSearch !== 'search') {
                                this.props.getListaction(
                                    this.props.outbox.outbox.processId,
                                    this.props.outbox.outbox.statusId
                                );
                            }
                            // this.props.updateOutboxState({
                            //     selectedRows: new Set(),
                            //     row_transition: false,
                            // });
                            if (isMobile) {
                                document.body.classList.remove(
                                    'doc-small-view'
                                );
                                document.body.classList.remove(
                                    'doc-detail-view'
                                );
                            }
                            if (this.state.calingFrom === 'actionBar') {
                                // showSuccessToast('I am here------------');
                                if (this.props.fromSearch !== 'search') {
                                    this.props.refresh_whole();
                                }

                                document.body.classList.remove(
                                    'aside-minimize'
                                );
                                document.body.classList.remove(
                                    'doc-small-view'
                                );
                                this.props.updateOutboxState({
                                    selectedRows: new Set(),
                                    row_transition: false,
                                });
                            } else {
                                if (
                                    this.props.deleteDocument !==
                                    'deleted_document'
                                ) {
                                    this.props.outboxrefreshTable();
                                }
                            }
                        }
                    } */
        //}
        /* })
            then.((data)=>{
                
            }) */
        //.catch((err) => {});
    };

    callFaxNow = (params, fields) => {
        if (
            (this.props.deleteDocument !== 'deleted_document' &&
                this.props.outbox?.stateData?.allActions?.fax_now !==
                    undefined) ||
            (this.props.deleteDocument === 'deleted_document' &&
                this.props.allActions?.fax_now !== undefined) ||
            (this.props.reportView === 'report' &&
                this.state.allActions?.fax_now !== undefined)
        ) {
            let actionIDTemp;

            if (this.props.deleteDocument !== 'deleted_document') {
                if (this.props.reportView === 'report') {
                    actionIDTemp = this.state.allActions?.fax_now?.actionId;
                } else {
                    actionIDTemp =
                        this.props.outbox?.stateData?.allActions?.fax_now
                            ?.actionId;
                }
            }

            if (this.props.deleteDocument === 'deleted_document') {
                actionIDTemp = this.props.allActions?.fax_now?.actionId;
            }
            params.actionId = actionIDTemp;
            fields.fields = [];
            params.fields = fields;
            let status = '';
            outboxDocumentAction(params)
                .then((response) => {
                    status = response.status;
                    return response.json();
                })
                .then((data) => {
                    if (data.hadFailures === false && status === 200) {
                        this.setState({
                            openFaxNow: false,
                            submitted: 0,
                        });
                        this.setState(
                            {
                                submitted_faxNow: 1,
                            },
                            () => {
                                this.props.updateState({
                                    submitted_faxNow: 1,
                                });
                            }
                        );

                        showSuccessToast('Process Successfully Completed.');
                        if (this.props.fromSearch !== 'search') {
                            /*  this.props.getListaction(
                                this.props.outbox.outbox.processId,
                                this.props.outbox.outbox.statusId
                            ); */
                        }
                        if (isMobile) {
                            /*  document.body.classList.remove('doc-small-view');
                            document.body.classList.remove('doc-detail-view') */
                        }

                        if (this.props.fromSearch !== 'search') {
                            if (this.state.calingFrom === 'actionBar') {
                                /*  this.props.refresh_whole();
                                this.props.updateOutboxState({
                                    selectedRows: new Set(),
                                }); */
                            } else {
                                this.deleteRowManually();
                                if (
                                    this.props.deleteDocument !==
                                    'deleted_document'
                                ) {
                                    //this.props.outboxrefreshTable();
                                    this.props.rowTransition();
                                    document.body.classList.remove(
                                        'doc-small-view'
                                    );
                                    document.body.classList.remove(
                                        'doc-detail-view'
                                    );
                                    document.body.classList.remove(
                                        'aside-minimize'
                                    );
                                }
                            }
                        }
                        if (this.props.deleteDocument === 'deleted_document') {
                            if (typeof this.props.closeBigView === 'function') {
                                this.props.closeBigView();
                            }
                            document.body.classList.remove('doc-small-view');
                            document.body.classList.remove('doc-detail-view');
                            document.body.classList.remove('aside-minimize');
                        }
                    } else {
                        let failedDocId = [];
                        let failedMessage = [];
                        let message = '';
                        if (data.hadFailures === true) {
                            data.results.map((item, idx) => {
                                if (item.actionSucceeded === false) {
                                    failedDocId.push(item.docId);
                                    failedMessage.push(item.message);
                                }
                            });
                            failedDocId.map((property, index) => {
                                if (index === 0) {
                                    message += '[';
                                }

                                message += property + ' ';
                                if (failedDocId.length - 1 === index) {
                                    message += ']';
                                }
                            });
                            let errorMsg = failedMessage[0].includes('lock')
                                ? 'Unable to obtain document lock'
                                : failedMessage[0];
                            showErrorToast(errorMsg + ' ' + message);
                        }
                        showErrorToast(message);
                        this.setState({
                            submitted: 0,
                        });
                    }
                });
        }
    };

    changePauseResumeResnding = (action) => {
        if (action === 'Pause Resending') {
            this.setState({ resumeResending: true });
            this.setState({ pauseResending: false });
            showSuccessToast(
                'Selected documents paused resending successfully.'
            );
        } else {
            this.setState({ resumeResending: false });
            this.setState({ pauseResending: true });
            showSuccessToast(
                'Selected documents resumed resending successfully.'
            );
        }
        //if (this.props.deleteDocument !== 'deleted_document') {
        if (this.props.fromSearch !== 'search') {
            /*   this.props.getListaction(
                this.props.outbox.outbox.processId,
                this.props.outbox.outbox.statusId
            ); */
        }
        if (isMobile) {
            /* document.body.classList.remove('doc-small-view');
            document.body.classList.remove('doc-detail-view'); */
        }
        if (this.state.calingFrom === 'actionBar') {
            /* this.props.refresh_whole();
            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
            this.props.updateOutboxState({
                selectedRows: new Set(),
                row_transition: false,
            }); */
        } else {
            /* if (this.props.deleteDocument !== 'deleted_document') {
                this.props.outboxrefreshTable();
            } */
            // this.deleteRowManually();
        }
        /* this.props.outboxPage({
            rowID: '',
        }); */
    };

    setPrinterloader = () => {
        this.setState({
            printLoader: false,
        });
    };

    printIt = () => {
        // BY S1008, Print usring pdfprint ref ( initial setup OurboxPage )
        // this.props.showLoader(true);
        // const docIds = [];
        // if (
        //     this.state.calingFrom === 'smallView' ||
        //     this.state.calingFrom === 'bigView'
        // ) {
        //     docIds.push(this.props.selectedDocId);
        // } else {
        //     const selectedIndex = Array.from(
        //         this.props.outbox.stateData.selectedRows
        //     );
        //     this.props.outbox.stateData.rowData.map((data, index) =>
        //         selectedIndex.includes(index) ? docIds.push(data.doc_id) : null
        //     );
        // }

        // this.setState(() => {
        //     return {
        //         printSelectedDocId: docIds,
        //         printLoader: true,
        //     };
        // });

        if (
            (this.state.calingFrom === 'smallView' ||
                this.state.calingFrom === 'bigView') &&
            this.props.pdfPrintRef
        ) {
            this.props.pdfPrintRef.current.printPdf();
        } else if (
            String(this.props.location.pathname).includes('detail') ||
            this.state.selectedDocId
        ) {
            this.props.showLoader(true);
            this.props.deletedPage({ blurSpinner: true });
            this.setState({
                printSelectedDocId: [this.state.selectedDocId],
                printLoader: true,
            });
        } else {
            const docIds = [];
            const selectedIndex = Array.from(
                this.props.outbox.stateData.selectedRows
            );
            this.props.outbox.stateData.rowData.map((data, index) =>
                selectedIndex.includes(data.id)
                    ? docIds.push(data.doc_id)
                    : null
            );
            this.setState({
                printSelectedDocId: docIds,
                printLoader: true,
            });
        }
    };

    download_multiple = async () => {
        if (
            this.state.calingFrom === 'smallView' ||
            this.state.calingFrom === 'bigView'
        ) {
            download(this.props.selectedDocId);
        } else {
            this.setState({ blur_spinner: true }, () => {
                this.updateState({ blur_spinner: true });
                download_multiple({
                    selectedRows: this.props.outbox.stateData.selectedRows,
                    rowData: this.props.outbox.stateData.rowData,
                });
                this.setState({ blur_spinner: false });
                this.updateState({ blur_spinner: false });
            });
        }
    };

    openNotesModal = () => {
        const selectedRows = Array.from(
            this.props.outbox.stateData.selectedRows
        );
        const rowsToNotes = this.props.outbox.stateData.rowData.filter(
            (data) => {
                return selectedRows.indexOf(data.id) !== -1;
            }
        );
        const docIdForNotes = [];
        if (rowsToNotes !== undefined) {
            rowsToNotes.map((data) => docIdForNotes.push(data.doc_id));
        }

        if (this.state.calingFrom === 'smallView') {
            if (this.state.open_notes_section === false) {
                this.setState(
                    {
                        open_notes_section: true,
                        open_delivery_detail_section: false,
                    },
                    () => {
                        this.props.updateState({
                            open_delivery_detail_section: false,
                            open_notes_section: true,
                            is_add_notes: 1,
                        });
                    }
                );
            } else {
                this.setState(
                    {
                        open_notes_section: false,
                    },
                    () => {
                        this.props.updateState({
                            open_delivery_detail_section: false,
                            open_notes_section: false,
                            is_add_notes: 1,
                        });
                    }
                );
            }
        } else {
            this.setState({
                open_notes: true,
                notesVal: '',
                docIdForNotes,
            });
        }
    };

    detailsExpand = () => {
        if (this.props.deleteDocument === 'deleted_document') {
            this.props.openBigView();
            this.props.updateState({
                editDetailsOutbox: true,
            });
        } else {
            this.props.openDetailView();
        }
        this.props.doExpand();
    };

    sendDraft = async () => {
        let selectedDocID;
        if (
            this.state.calingFrom === 'smallView' ||
            this.state.calingFrom === 'bigView'
        ) {
            selectedDocID = this.props.selectedDocId;
        } else {
            let docIds = [];
            const selectedIndex = Array.from(
                this.props.outbox.stateData.selectedRows
            );
            this.props.outbox.stateData.rowData.map((data, index) =>
                selectedIndex.includes(data.id)
                    ? docIds.push(data.doc_id)
                    : null
            );
            selectedDocID = docIds[0];
        }

        // localStorage.setItem('gmail_sendDraftID', selectedDocID);

        let serviceType = this.state.configData.serviceType;

        if (serviceType === 'Office 365') {
            // check to authenticate draft mail

            Office365AuthenticationPage({
                selectedDocID: selectedDocID,
                configData: this.props.configData,
            });
        } else {
            GmailAuthenticationPage({
                selectedDocID: selectedDocID,
                configData: this.props.configData,
            });
        }
    };

    dynamicSetting = () => {
        let settingMenu = [];

        for (const property in this.state.allActions) {
            let newObject = this.state.allActions[property];

            if (
                newObject.used === 'No' &&
                String(this.props.location.pathname).includes('detail') !== true
            ) {
                settingMenu.push(
                    <Dropdown.Item
                        title={newObject.name}
                        onClick={() => {
                            this.handleUnUsedAction(property);
                        }}
                        key={Math.random()}
                    >
                        <span title={newObject.name}>
                            <i
                                className={
                                    newObject.icon !== null
                                        ? newObject.icon
                                        : 'fas fa-sync-alt'
                                }
                            />
                            {newObject.name}
                        </span>
                    </Dropdown.Item>
                );
            }
        }

        return <> {settingMenu}</>;
    };
    handleUnUsedAction(data) {
        let processId;
        let statusId;
        let actionId;
        if (String(this.props.processId) !== '') {
            processId = this.props.processId;
        }
        if (String(this.props.statusId) !== '') {
            statusId = this.props.statusId;
        }
        if (this.props.deleteDocument === 'deleted_document') {
            statusId = this.props.delstatusId;
            processId = this.props.delprocessId;
        }
        for (const property in this.state.allActions) {
            if (property === data) {
                actionId = this.state.allActions[property].actionId;
            }
        }
        const params = {};
        const fields = {};
        fields.docIds = [this.props.selectedDocId];
        fields.fields = [];

        params.processId = processId;
        params.statusId = statusId;
        params.actionId = actionId;
        params.fields = fields;
        let status = '';
        outboxDocumentAction(params)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((data) => {
                if (data.hadFailures === false && status === 200) {
                    showSuccessToast('Process Successfully Completed.');
                } else {
                    let failedDocId = [];
                    let failedMessage = [];
                    let message = '';
                    if (data.hadFailures === true) {
                        data.results.map((item, idx) => {
                            if (item.actionSucceeded === false) {
                                failedDocId.push(item.docId);
                                failedMessage.push(item.message);
                            }
                        });
                        failedDocId.map((property, index) => {
                            if (index === 0) {
                                message += '[';
                            }

                            message += property + ' ';
                            if (failedDocId.length - 1 === index) {
                                message += ']';
                            }
                        });
                        let errorMsg = failedMessage[0].includes('lock')
                            ? 'Unable to obtain document lock'
                            : failedMessage[0];
                        showErrorToast(errorMsg + ' ' + message);
                    }
                }
            });
    }

    render() {
        return (
            <>
                {/* bulk Action STARTS */}
                {this.props.isLocked === true &&
                this.state.allActions.hasOwnProperty('fax_now') ? (
                    <>
                        <div className='fax-outer'>
                            <Dropdown className='icon_fun'>
                                <Dropdown.Toggle
                                    variant='primary'
                                    id='dropdown-basic'
                                    style={{
                                        borderTopRightRadius: '0',
                                        borderBottomRightRadius: '0',
                                        cursor: 'not-allowed',
                                    }}
                                    disabled
                                >
                                    {this.state.selectedBulkAction}{' '}
                                    <i className='fa-light fa-angle-down' />
                                </Dropdown.Toggle>
                            </Dropdown>
                        </div>
                        <div
                            className={
                                this.props.isLocked === true &&
                                (this.state.calingFrom === 'smallView' ||
                                    this.state.calingFrom === 'bigView')
                                    ? 'icon_fun fix-now-btn'
                                    : 'icon_fun fix-now-btn checkbox-note btn-success'
                            }
                            title='Submit'
                            onClick={
                                this.props.isLocked === true &&
                                (this.state.calingFrom === 'smallView' ||
                                    this.state.calingFrom === 'bigView')
                                    ? null
                                    : () => {
                                          this.handleDocumentAction(
                                              this.state.selectedBulkAction
                                          );
                                      }
                            }
                        >
                            <i className='fa-light fa-check' />
                        </div>
                    </>
                ) : null}

                {this.props.isLocked !== true &&
                (this.state.allActions.hasOwnProperty('fax_now') ||
                    this.state.allActions.hasOwnProperty('update_fax_number') ||
                    this.state.allActions.hasOwnProperty('send_in_batch_fax') ||
                    this.state.allActions.hasOwnProperty(
                        'mark_order_as_sent'
                    ) ||
                    this.state.allActions.hasOwnProperty('send_to_e_sign') ||
                    this.state.allActions.hasOwnProperty('send_via_email')) ? (
                    <div className='fax-outer-div'>
                        <div className='fax-outer'>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant='primary'
                                    id='dropdown-basic'
                                    title={this.state.selectedBulkAction}
                                >
                                    {this.props.calingFrom === 'smallView' ||
                                    (this.props.calingFrom === 'bigView' &&
                                        (this.props.allActions.hasOwnProperty(
                                            'send_do_not_send'
                                        ) ||
                                            this.state.allActions
                                                .send_do_not_send !==
                                                undefined))
                                        ? this.state.selectedBulkAction.slice(
                                              0,
                                              17
                                          ) +
                                          (this.state.selectedBulkAction
                                              .length > 17
                                              ? '..'
                                              : '')
                                        : this.state.selectedBulkAction}{' '}
                                    <i className='fa-light fa-angle-down' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropdown-outerbox'>
                                    <Dropdown.Item disabled>Fax</Dropdown.Item>
                                    {this.state.allActions.hasOwnProperty(
                                        'send_in_batch_fax'
                                    ) ? (
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.selectBulkAction(
                                                    'Send in Batch Fax'
                                                );
                                            }}
                                        >
                                            Send in Batch Fax
                                        </Dropdown.Item>
                                    ) : null}

                                    {this.state.allActions.hasOwnProperty(
                                        'fax_now'
                                    ) ? (
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.selectBulkAction(
                                                    'Fax Now'
                                                );
                                            }}
                                        >
                                            Fax Now
                                        </Dropdown.Item>
                                    ) : null}
                                    {/* fax_now_to_alternate_number */}
                                    {this.state.allActions.hasOwnProperty(
                                        'update_fax_number'
                                    ) ? (
                                        <Dropdown.Item
                                            onClick={() => {
                                                const faxNotToAlternativeNumber =
                                                    'Fax Now to Alternate Number';
                                                this.selectBulkAction(
                                                    faxNotToAlternativeNumber
                                                );
                                                this.handleDocumentAction(
                                                    faxNotToAlternativeNumber
                                                );
                                            }}
                                        >
                                            Fax Now to Alternate Number
                                        </Dropdown.Item>
                                    ) : null}

                                    {this.state.allActions.hasOwnProperty(
                                        'send_to_e_sign'
                                    ) ||
                                    this.state.allActions.hasOwnProperty(
                                        'send_via_email'
                                    ) ||
                                    this.state.allActions.hasOwnProperty(
                                        'mark_order_as_sent'
                                    ) ? (
                                        <Dropdown.Item disabled>
                                            Other
                                        </Dropdown.Item>
                                    ) : null}

                                    {this.state.allActions.hasOwnProperty(
                                        'send_to_e_sign'
                                    ) ? (
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.selectBulkAction(
                                                    'Send to e-Sign'
                                                );
                                            }}
                                        >
                                            Send to e-Sign
                                        </Dropdown.Item>
                                    ) : null}

                                    {this.state.allActions.hasOwnProperty(
                                        'mark_order_as_sent'
                                    ) ? (
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.selectBulkAction(
                                                    'Mark Order as Sent'
                                                );
                                            }}
                                        >
                                            Mark Order as Sent
                                        </Dropdown.Item>
                                    ) : null}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {this.state.submitted === 0 ? (
                            this.state.selectedBulkAction !==
                            'Fax Now to Alternate Number' ? (
                                <button
                                    type='button'
                                    className={
                                        this.props.isLocked === true &&
                                        (this.state.calingFrom ===
                                            'smallView' ||
                                            this.state.calingFrom === 'bigView')
                                            ? 'icon_fun fix-now-btn'
                                            : 'icon_fun fix-now-btn checkbox-note btn-success'
                                    }
                                    title='Submit'
                                    onClick={
                                        this.props.isLocked === true &&
                                        this.state.faxSaveBtn === false &&
                                        (this.state.calingFrom ===
                                            'smallView' ||
                                            this.state.calingFrom === 'bigView')
                                            ? null
                                            : () => {
                                                  this.handleDocumentAction(
                                                      this.state
                                                          .selectedBulkAction
                                                  );
                                              }
                                    }
                                    disabled={this.state.faxSaveBtn}
                                >
                                    <i className='fa-light fa-check' />
                                </button>
                            ) : null
                        ) : this.state.submitted === 1 ? (
                            <button
                                type='button'
                                className='post_loading icon_fun fix-now-btn'
                                id={this.state.postLoading}
                            >
                                <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                            </button>
                        ) : null}
                    </div>
                ) : null}
                {this.state.calingFrom === 'bigView' && (
                    <>
                        <div className='oubx obx-fax'>
                            {this.props.editid_bigview !== '' &&
                            this.props.isLocked === false &&
                            this.state.currentURL !== 'deleted-documents' &&
                            this.props.deleteDocument !== 'deleted_document' &&
                            this.props.deleteDocument !== 'search' ? (
                                <a
                                    className='left-pdf-button'
                                    // to={{
                                    //     pathname: this.props.editid_bigview,
                                    //     selectedDocIds:
                                    //         this.props.editid_bigview,
                                    // }}

                                    onClick={() => {
                                        if (
                                            this.state.currentURL === 'detail'
                                        ) {
                                            return true;
                                        }
                                        this.props.updateState({
                                            single_edit: true,
                                        });
                                        this.props.history.push({
                                            pathname: '/outbox/edit',
                                            search: `?edit=${window.btoa(
                                                String(this.props.selectedDocId)
                                            )}`,
                                        });
                                    }}
                                >
                                    <div style={{ display: 'inline-block' }}>
                                        <span title='Edit Document'>
                                            <i className='fas fa-file-edit rest' />
                                            Edit Document
                                        </span>
                                    </div>
                                </a>
                            ) : this.props.deleteDocument === 'search' ? (
                                <a
                                    className='left-pdf-button'
                                    // to={{
                                    //     pathname: this.props.editid_bigview,
                                    //     selectedDocIds:
                                    //         this.props.editid_bigview,
                                    // }}

                                    onClick={() => {
                                        let str = this.props.location.pathname;
                                        const lastChar = str.at(-1);
                                        if (lastChar === '/') {
                                            str = str.substring(
                                                1,
                                                str.length - 1
                                            );
                                        }
                                        this.props.updateState({
                                            single_edit: true,
                                        });
                                        this.props.history.push({
                                            pathname: str + '/edit',
                                            search:
                                                '?edit=' +
                                                window.btoa(
                                                    String(
                                                        this.props.selectedDocId
                                                    )
                                                ),
                                        });
                                    }}
                                >
                                    <div style={{ display: 'inline-block' }}>
                                        <span title='Edit Document'>
                                            <i className='fas fa-file-edit rest' />
                                            Edit Document
                                        </span>
                                    </div>
                                </a>
                            ) : this.props.deleteDocument ===
                              'deleted_document' ? (
                                <a
                                    className='left-pdf-button'
                                    // to={{
                                    //     pathname: this.props.editid_bigview,
                                    //     selectedDocIds:
                                    //         this.props.editid_bigview,
                                    // }}

                                    onClick={() => {
                                        let str1;
                                        let str = this.props.location.pathname;

                                        if (str.includes('?') === true) {
                                            str = str.split('?');
                                            str1 = String(str[0]);

                                            const lastChar = str1.at(-1);
                                            if (lastChar === '/') {
                                                str1 = str1.substring(
                                                    1,
                                                    str1.length - 1
                                                );
                                            }
                                        } else {
                                            str1 = this.props.location.pathname;
                                        }

                                        this.props.deletedPage({
                                            single_edit: true,
                                        });
                                        this.props.history.replace({
                                            pathname: str1 + '/edit',
                                            search:
                                                '?edit=' +
                                                window.btoa(
                                                    String(
                                                        this.props.deleted_page
                                                            .selectedDocId
                                                    )
                                                ),
                                        });
                                    }}
                                >
                                    <div style={{ display: 'inline-block' }}>
                                        <span title='Edit Document'>
                                            <i className='fas fa-file-edit rest' />
                                            Edit Document
                                        </span>
                                    </div>
                                </a>
                            ) : (
                                <div
                                    className='left-pdf-button'
                                    style={{
                                        cursor: 'not-allowed',
                                    }}
                                >
                                    <span
                                        style={{
                                            cursor: 'not-allowed',
                                        }}
                                        title='Edit Documents'
                                    >
                                        <i className='fas fa-file-edit rest' />
                                        Edit Document
                                    </span>
                                </div>
                            )}
                            {(this.props.allActions.hasOwnProperty(
                                'send_do_not_send'
                            ) ||
                                this.state.allActions.send_do_not_send !==
                                    undefined) &&
                            String(this.props.location.pathname).includes(
                                'detail'
                            ) !== true &&
                            this.state.sendFlag === false ? (
                                <div
                                    className='left-pdf-button'
                                    onClick={
                                        this.props.isLocked === true
                                            ? null
                                            : () => {
                                                  this.pauseResumeResendingAction(
                                                      'Pause Resending'
                                                  );
                                              }
                                    }
                                    style={
                                        this.state.isLocked
                                            ? {
                                                  backgroundColor:
                                                      'rgb(0, 35, 58)',
                                                  cursor: 'not-allowed',
                                              }
                                            : { cursor: '' }
                                    }
                                >
                                    <span
                                        className='cancel-bg-btn'
                                        title='Pause Resending'
                                    >
                                        <i
                                            className={
                                                this.props.allActions.hasOwnProperty(
                                                    'send_do_not_send'
                                                ) &&
                                                this.props.allActions
                                                    .send_do_not_send.icon !==
                                                    null
                                                    ? this.state.allActions
                                                          .send_do_not_send.icon
                                                    : 'fas fa-pause'
                                            }
                                            style={{
                                                color:
                                                    this.props.allActions.hasOwnProperty(
                                                        'send_do_not_send'
                                                    ) &&
                                                    this.props.allActions
                                                        .send_do_not_send
                                                        .color !== null
                                                        ? this.props.allActions
                                                              .send_do_not_send
                                                              .color
                                                        : 'rgb(216, 76, 58)',
                                            }}
                                        />
                                        Pause Resending
                                    </span>
                                </div>
                            ) : null}
                            {(this.props.allActions.hasOwnProperty(
                                'send_do_not_send'
                            ) ||
                                this.state.allActions.send_do_not_send !==
                                    undefined) &&
                            this.state.sendFlag === true &&
                            String(this.props.location.pathname).includes(
                                'detail'
                            ) !== true ? (
                                <div
                                    className='left-pdf-button'
                                    onClick={() => {
                                        this.pauseResumeResendingAction(
                                            'Resume Resending'
                                        );
                                    }}
                                    style={
                                        this.state.isLocked
                                            ? {
                                                  backgroundColor:
                                                      'rgb(0, 35, 58)',
                                                  cursor: 'not-allowed',
                                              }
                                            : { cursor: 'inline-block' }
                                    }
                                >
                                    <span
                                        style={
                                            this.state.isLocked
                                                ? { cursor: 'not-allowed' }
                                                : { cursor: 'pointer' }
                                        }
                                        title=' Resume Resending'
                                    >
                                        <i
                                            className={
                                                this.props.allActions.hasOwnProperty(
                                                    'send_do_not_send'
                                                ) &&
                                                this.props.allActions
                                                    .send_do_not_send.icon !==
                                                    null
                                                    ? `${this.props.allActions.send_do_not_send.icon} go-green`
                                                    : 'fas fa-redo-alt go-green'
                                            }
                                            style={{
                                                color:
                                                    this.props.allActions.hasOwnProperty(
                                                        'send_do_not_send'
                                                    ) &&
                                                    this.props.allActions
                                                        .send_do_not_send
                                                        .color !== null
                                                        ? this.props.allActions
                                                              .send_do_not_send
                                                              .color
                                                        : 'rgb(141, 198, 63)',
                                            }}
                                        />
                                        Resume Resending
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}

                {this.state.calingFrom !== 'bigView' &&
                (this.state.allActions.hasOwnProperty(
                    'update_delivery_method'
                ) ||
                    this.state.allActions.hasOwnProperty(
                        'update_fax_number'
                    )) ? (
                    <OverlayTrigger
                        placement={
                            this.state.calingFrom === 'actionBar'
                                ? 'bottom'
                                : 'top'
                        }
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(0, 35, 58)',
                                    color: 'white',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                    }}
                                >
                                    Delivery Details
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            className={
                                this.props.open_delivery_detail_section === true
                                    ? 'icon_fun outer-col active'
                                    : 'icon_fun outer-col'
                            }
                            onClick={
                                this.props.isLocked === true
                                    ? null
                                    : this.open_delivery
                            }
                        >
                            <span
                                className={
                                    this.props.open_delivery_detail_section ===
                                    true
                                        ? 'delivery-details active'
                                        : 'delivery-details'
                                }
                                style={{
                                    cursor: this.props.isLocked
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                            >
                                <svg
                                    className='icon-fill'
                                    width='39'
                                    height='34'
                                    viewBox='0 0 39 34'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                </svg>
                            </span>
                        </div>
                    </OverlayTrigger>
                ) : null}

                {/* follow_up_notes Aiswarya removing this since no need to handle this condition */}

                {/* {(this.state.calingFrom !== 'bigView' &&
                    (this.state.allActions.hasOwnProperty(
                        'update_delivery_method'
                    ) ||
                        this.state.allActions.hasOwnProperty(
                            'update_fax_number'
                        ))) ||
                this.state.callingFrom === 'smallView' ? ( */}
                {this.state.calingFrom !== 'bigView' &&
                (this.state.calingFrom === 'smallView' ||
                    (this.state.calingFrom === 'actionBar' &&
                        this.state.noteTypes !== undefined &&
                        this.state.noteTypes.length !== 0)) ? (
                    <OverlayTrigger
                        placement={
                            this.state.calingFrom === 'actionBar'
                                ? 'bottom'
                                : 'top'
                        }
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(0, 35, 58)',
                                    color: 'white',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                    }}
                                >
                                    Notes
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            className={
                                this.state.open_notes_section === true
                                    ? 'icon_fun outer-col active'
                                    : 'icon_fun outer-col'
                            }
                            onClick={
                                this.props.isLocked === true
                                    ? null
                                    : this.openNotesModal
                            }
                        >
                            <span
                                className={
                                    this.state.open_notes_section === true
                                        ? 'active'
                                        : ''
                                }
                                style={{
                                    cursor: this.props.isLocked
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                            >
                                <i
                                    className={
                                        this.state.allActions.hasOwnProperty(
                                            'note'
                                        ) &&
                                        this.state.allActions.note.icon !== null
                                            ? this.state.allActions.note.icon
                                            : 'fa-solid fa-sticky-note'
                                    }
                                />
                            </span>
                        </div>
                    </OverlayTrigger>
                ) : null}

                {this.state.allActions.hasOwnProperty(
                    'update_cover_page_comments'
                ) && this.state.calingFrom === 'actionBar' ? (
                    <OverlayTrigger
                        placement={
                            this.state.calingFrom === 'actionBar'
                                ? 'bottom'
                                : 'top'
                        }
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(0, 35, 58)',
                                    color: 'white',
                                    marginBottom: '15px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                    }}
                                >
                                    Cover Page
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            className='icon_fun update_cover_page_comments'
                            onClick={this.open_cover}
                        >
                            <i className='fa-solid fa-message-medical' />
                        </div>
                    </OverlayTrigger>
                ) : null}
                {this.state.allActions.hasOwnProperty('remove_from_tracking') &&
                this.state.calingFrom === 'actionBar' ? (
                    <OverlayTrigger
                        placement={
                            this.state.calingFrom === 'actionBar'
                                ? 'bottom'
                                : 'top'
                        }
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(216 76 58)',
                                    color: 'white',
                                    marginBottom: '15px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(216 76 58)',
                                        color: 'white',
                                    }}
                                >
                                    Remove Tracking
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            className='remove_tracking icon_fun'
                            onClick={this.open_remove_tracking}
                        >
                            <i
                                className={
                                    this.state.allActions.hasOwnProperty(
                                        'remove_from_tracking'
                                    ) &&
                                    this.state.allActions.remove_from_tracking
                                        .icon !== null
                                        ? this.state.allActions
                                              .remove_from_tracking.icon
                                        : 'fas fa-minus-circle'
                                }
                                style={{
                                    color:
                                        this.state.allActions.hasOwnProperty(
                                            'remove_from_tracking'
                                        ) &&
                                        this.state.allActions
                                            .remove_from_tracking.color !== null
                                            ? this.state.allActions
                                                  .remove_from_tracking.color
                                            : '#D84C3A !important',
                                }}
                            />{' '}
                        </div>
                    </OverlayTrigger>
                ) : null}

                {/* ... settings started */}
                {this.props.isLocked === true ? (
                    <span
                        className='lock_drop'
                        style={{ cursor: 'not-allowed' }}
                    >
                        <i className='fa fa-ellipsis-v' aria-hidden='true' />
                    </span>
                ) : (
                    <div
                        className='icon_fun'
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        <span>
                            <Dropdown
                                className='bulk-print-opt outer-col settings-dropdown'
                                style={{
                                    position: 'static',
                                }}
                            >
                                <OverlayTrigger
                                    placement={
                                        this.state.calingFrom === 'actionBar'
                                            ? 'bottom'
                                            : 'top'
                                    }
                                    onEntering={this.entering}
                                    // trigger='click'
                                    target={() => {
                                        this.setState({
                                            show: !this.state.show,
                                        });
                                    }}
                                    show={this.state.show}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Settings
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Dropdown.Toggle
                                        style={{
                                            background: 'transparent',
                                            border: 'none',
                                        }}
                                        variant='success'
                                        id='dropdown-basic'
                                    >
                                        <i
                                            className='fa fa-ellipsis-v'
                                            aria-hidden='true'
                                        />
                                    </Dropdown.Toggle>
                                </OverlayTrigger>
                                <Dropdown.Menu>
                                    {this.state.calingFrom === 'smallView' &&
                                        (this.state.allActions.hasOwnProperty(
                                            'update_cover_page_comments'
                                        ) ||
                                            this.state.allActions.hasOwnProperty(
                                                'update_cover_page'
                                            )) && (
                                            <Dropdown.Item
                                                onClick={this.open_cover}
                                                className='cover_page'
                                            >
                                                <span title='Cover Page'>
                                                    <i className='fa-solid fa-message-medical' />{' '}
                                                    Cover Page
                                                </span>
                                            </Dropdown.Item>
                                        )}
                                    {this.state.calingFrom === 'smallView' && (
                                        <Dropdown.Item
                                            title='Edit Document'
                                            // target='_blank'
                                            // as={Link}
                                            // to={{
                                            //     pathname: this.state.editId,
                                            //     selectedDocIds:
                                            //         this.state.editId,
                                            // }}
                                            onClick={() => {
                                                if (
                                                    this.props
                                                        .deleteDocument ===
                                                    'deleted_document'
                                                ) {
                                                    let str =
                                                        this.props.location
                                                            .pathname;
                                                    const lastChar = str.at(-1);
                                                    if (lastChar === '/') {
                                                        str = str.substring(
                                                            1,
                                                            str.length - 1
                                                        );
                                                    }
                                                    this.props.deletedPage({
                                                        single_edit: true,
                                                    });
                                                    this.props.history.push({
                                                        pathname: str + '/edit',
                                                        search:
                                                            '?edit=' +
                                                            window.btoa(
                                                                String(
                                                                    this.props
                                                                        .selectedDocId
                                                                )
                                                            ),
                                                    });
                                                } else {
                                                    this.props.updateState({
                                                        single_edit: true,
                                                    });
                                                    const currentPath =
                                                        window.location.pathname.split(
                                                            '/'
                                                        );
                                                    let pathName = `/${currentPath[1]}/edit`;
                                                    if (
                                                        currentPath[1] ===
                                                        'search'
                                                    ) {
                                                        if (
                                                            currentPath[2] ===
                                                            undefined
                                                        )
                                                            currentPath[2] =
                                                                'patients';
                                                        pathName = `/${currentPath[1]}/${currentPath[2]}/edit`;
                                                    }
                                                    this.props.history.push({
                                                        pathname: pathName,
                                                        search: `?edit=${window.btoa(
                                                            String(
                                                                this.props
                                                                    .selectedDocId
                                                            )
                                                        )}`,
                                                    });
                                                }
                                            }}
                                        >
                                            <span title='Edit Document'>
                                                <i className='fas fa-file-edit rest' />
                                                Edit Document
                                            </span>
                                        </Dropdown.Item>
                                    )}
                                    {this.state.allActions.hasOwnProperty(
                                        'sync_information_from_emr'
                                    ) && (
                                        <Dropdown.Item
                                            title='Refresh with EMR'
                                            onClick={() => {
                                                this.handleDocumentAction(
                                                    'Refresh with EMR'
                                                );
                                            }}
                                        >
                                            <span title='Refresh with EMR'>
                                                <i className='fas fa-sync-alt' />
                                                Refresh with EMR
                                            </span>
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                        title='Print'
                                        onClick={this.printIt}
                                        className='print-dropdown'
                                    >
                                        <span>
                                            <i
                                                className={
                                                    this.state.allActions.hasOwnProperty(
                                                        'print'
                                                    ) &&
                                                    this.state.allActions.print
                                                        .icon !== null
                                                        ? this.state.allActions
                                                              .print.icon
                                                        : 'fas fa-print'
                                                }
                                                style={{
                                                    color:
                                                        this.state.allActions.hasOwnProperty(
                                                            'print'
                                                        ) &&
                                                        this.state.allActions
                                                            .print.color !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .print.color
                                                            : {},
                                                }}
                                            />
                                            Print
                                        </span>
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        title='Download'
                                        onClick={this.download_multiple}
                                    >
                                        <span>
                                            <i
                                                className={
                                                    this.state.allActions.hasOwnProperty(
                                                        'download'
                                                    ) &&
                                                    this.state.allActions
                                                        .download.icon !== null
                                                        ? this.state.allActions
                                                              .download.icon
                                                        : 'fas fa-download'
                                                }
                                                style={{
                                                    color:
                                                        this.state.allActions.hasOwnProperty(
                                                            'download'
                                                        ) &&
                                                        this.state.allActions
                                                            .download.color !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .download
                                                                  .color
                                                            : {},
                                                }}
                                            />
                                            Download
                                        </span>
                                    </Dropdown.Item>

                                    {this.state.serviceType ? (
                                        this.state.calingFrom === 'smallView' ||
                                        this.state.calingFrom === 'bigView' ? (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    this.sendDraft();
                                                }}
                                            >
                                                <span>
                                                    <i className='fa-solid fa-envelope' />
                                                    Send via email
                                                </span>
                                            </Dropdown.Item>
                                        ) : null
                                    ) : null}
                                    {this.state.serviceType ? (
                                        this.state.calingFrom === 'actionBar' &&
                                        this.props.outbox.stateData !==
                                            undefined &&
                                        this.props.outbox.stateData
                                            .selectedRows !== undefined &&
                                        this.props.outbox.stateData.selectedRows
                                            .size === 1 ? (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    this.sendDraft();
                                                }}
                                            >
                                                <span>
                                                    <i className='fa-solid fa-envelope' />
                                                    Send via email
                                                </span>
                                            </Dropdown.Item>
                                        ) : null
                                    ) : null}

                                    {this.state.allActions.hasOwnProperty(
                                        'update_information_manually'
                                    ) &&
                                        this.state.calingFrom ===
                                            'smallView' && (
                                            <Dropdown.Item
                                                title='Edit Details'
                                                onClick={() => {
                                                    this.detailsExpand();
                                                }}
                                            >
                                                <span title='Edit Details'>
                                                    <i className='fa-solid fa-notes-medical' />
                                                    Edit Details
                                                </span>
                                            </Dropdown.Item>
                                        )}
                                    {((this.state.calingFrom === 'smallView' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'send_do_not_send'
                                        ) &&
                                        this.state.sendFlag === false) ||
                                    ((this.state.calingFrom === 'actionBar' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'send_do_not_send'
                                        ) &&
                                        (this.state.multi_sendFlag === false ||
                                            this.state.bothFlag === true)) ? (
                                        <Dropdown.Item
                                            title='Pause Resending'
                                            className='paue-resending'
                                            onClick={() => {
                                                this.pauseResumeResendingAction(
                                                    'Pause Resending'
                                                );
                                            }}
                                        >
                                            <span>
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'send_do_not_send'
                                                        ) &&
                                                        this.state.allActions
                                                            .send_do_not_send
                                                            .icon !== null
                                                            ? this.state
                                                                  .allActions
                                                                  .send_do_not_send
                                                                  .icon
                                                            : 'fas fa-pause'
                                                    }
                                                    style={{
                                                        color:
                                                            this.state.allActions.hasOwnProperty(
                                                                'send_do_not_send'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .send_do_not_send
                                                                .color !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .send_do_not_send
                                                                      .color
                                                                : '#007DBC',
                                                    }}
                                                />
                                                Pause Resending
                                            </span>
                                        </Dropdown.Item>
                                    ) : null}

                                    {((this.state.calingFrom === 'smallView' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'send_do_not_send'
                                        ) &&
                                        this.state.sendFlag === false) ||
                                    ((this.state.calingFrom === 'actionBar' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'send_do_not_send'
                                        ) &&
                                        (this.state.multi_sendFlag === false ||
                                            this.state.bothFlag === true)) ? (
                                        <Dropdown.Item
                                            title='Resume Resending'
                                            onClick={() => {
                                                this.pauseResumeResendingAction(
                                                    'Resume Resending'
                                                );
                                            }}
                                        >
                                            <span>
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'send_do_not_send'
                                                        ) &&
                                                        this.state.allActions
                                                            .send_do_not_send
                                                            .icon !== null
                                                            ? `${this.state.allActions.send_do_not_send.icon} go-green`
                                                            : 'fas fa-redo-alt go-green'
                                                    }
                                                    style={{
                                                        color:
                                                            this.state.allActions.hasOwnProperty(
                                                                'send_do_not_send'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .send_do_not_send
                                                                .color !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .send_do_not_send
                                                                      .color
                                                                : 'rgb(141, 198, 63)',
                                                    }}
                                                />
                                                Resume Resending
                                            </span>
                                        </Dropdown.Item>
                                    ) : null}

                                    {((this.state.calingFrom === 'smallView' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'e_sign_push_notification'
                                        )) ||
                                    ((this.state.calingFrom === 'actionBar' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'e_sign_push_notification'
                                        )) ? (
                                        <Dropdown.Item
                                            title='E-Sign Push Notification'
                                            onClick={() => {
                                                this.sendESignAction(
                                                    'E-Sign Push Notification'
                                                );
                                            }}
                                        >
                                            <span>
                                                <i className='fa-solid fa-bell' />
                                                E-Sign Push Notification
                                            </span>
                                        </Dropdown.Item>
                                    ) : null}

                                    {((this.state.calingFrom === 'smallView' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'send_back_to_e_sign_initial'
                                        )) ||
                                    ((this.state.calingFrom === 'actionBar' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'send_back_to_e_sign_initial'
                                        )) ? (
                                        <Dropdown.Item
                                            title='Send Back to E-Sign Initial'
                                            onClick={() => {
                                                this.sendESignAction(
                                                    'Send Back to E-Sign Initial'
                                                );
                                            }}
                                        >
                                            <span>
                                                <i className='fa-solid fa-bell' />
                                                Send Back to E-Sign Initial
                                            </span>
                                        </Dropdown.Item>
                                    ) : null}

                                    {((this.state.calingFrom === 'smallView' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'remove_from_e_sign'
                                        )) ||
                                    ((this.state.calingFrom === 'actionBar' ||
                                        this.state.calingFrom === 'bigView') &&
                                        this.state.allActions.hasOwnProperty(
                                            'remove_from_e_sign'
                                        )) ? (
                                        <Dropdown.Item
                                            title='Remove from E-Sign'
                                            onClick={() => {
                                                this.removeFromESignAction(
                                                    'Remove from E-Sign'
                                                );
                                            }}
                                        >
                                            <span>
                                                <i className='fa-solid fa-file-minus' />
                                                Remove from E-Sign
                                            </span>
                                        </Dropdown.Item>
                                    ) : null}

                                    {this.state.allowResume &&
                                    (this.state.calingFrom === 'smallView' ||
                                        this.state.calingFrom === 'bigView') &&
                                    this.state.allActions.hasOwnProperty(
                                        'remove_from_tracking'
                                    ) ? (
                                        <Dropdown.Item
                                            title='Remove Tracking'
                                            onClick={this.open_remove_tracking}
                                            className='remove_tracking'
                                        >
                                            <span>
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'remove_from_tracking'
                                                        ) &&
                                                        this.state.allActions
                                                            .remove_from_tracking
                                                            .icon !== null
                                                            ? this.state
                                                                  .allActions
                                                                  .remove_from_tracking
                                                                  .icon
                                                            : 'fas fa-minus-circle'
                                                    }
                                                    style={{
                                                        color:
                                                            this.state.allActions.hasOwnProperty(
                                                                'remove_from_tracking'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .remove_from_tracking
                                                                .color !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .remove_from_tracking
                                                                      .color
                                                                : '#D84C3A',
                                                    }}
                                                />
                                                Remove Tracking
                                            </span>
                                        </Dropdown.Item>
                                    ) : null}
                                    {this.state.calingFrom === 'smallView' ||
                                    this.state.calingFrom === 'bigView' ? (
                                        <Dropdown.Item
                                            title='View History'
                                            onClick={() => this.handleHistroy()}
                                        >
                                            <span>
                                                <i className='fa fa-history' />
                                                View History
                                            </span>
                                        </Dropdown.Item>
                                    ) : null}
                                    {this.state.calingFrom === 'bigView' &&
                                    this.state.noUsedAction.length !== 0
                                        ? this.dynamicSetting()
                                        : null}
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                    </div>
                )}
                {/** Print by another way */}
                {/* {this.state.printSelectedDocId.length > 0 ? (
                    <PrintPdfDocument
                        docId={this.state.printSelectedDocId}
                        loader={this.setPrinterloader}
                    />
                ) : null} */}

                {this.state.openFaxNow ? (
                    <FaxNowModal
                        openFaxNow={this.state.openFaxNow}
                        faxNumberRequired={this.state.faxNumberRequired}
                        faxSaveBtn={this.state.faxSaveBtn}
                        submitted={this.state.submitted}
                        submitted_faxNow={this.state.submitted_faxNow}
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        submitFaxNow={(submit) => {
                            this.handleDocumentAction(
                                this.state.selectedBulkAction,
                                submit
                            );
                        }}
                        selectedBulkAction={this.state.selectedBulkAction}
                        childFaxnow_update={this.state.childFaxnow_update}
                    />
                ) : null}
                {this.state.open_delivery_detail ? (
                    <DeliveryDetailModal
                        calingFrom={this.state.calingFrom}
                        fromSearch={this.props.fromSearch}
                        open_delivery_detail={this.state.open_delivery_detail}
                        open_delivery_detail_section={
                            this.state.open_delivery_detail_section
                        }
                        refresh_whole={() => {
                            // Unwanted Render
                            // this.refresh_whole();
                        }}
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        refreshTable={() => {
                            this.props.refreshTable();
                        }}
                        selectedFrom='row-selection'
                        rowTransition={() => {
                            this.props.rowTransition();
                        }}
                        lockedStatus={() => {
                            this.props.lockedStatus();
                        }}
                    />
                ) : null}
                {this.state.open_cover_page ? (
                    <CoverPageModal
                        open_cover_page={this.state.open_cover_page}
                        calingFrom={this.props.calingFrom}
                        fromSearch={this.props.fromSearch}
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        allActions={
                            this.props.deleteDocument === 'deleted_document'
                                ? this.props.allActions
                                : {}
                        }
                        deleteDocument={this.props.deleteDocument}
                        delstatusId={this.props.delstatusId}
                        delprocessId={this.props.delprocessId}
                        selectedDocId={this.props.selectedDocId}
                        selectedRows={this.state.selectedRows}
                        isFrom='actionBar'
                    />
                ) : null}
                {this.state.open_remove_tracking ? (
                    <RemoveTrackingModal
                        calingFrom={this.props.calingFrom}
                        fromSearch={this.props.fromSearch}
                        open_remove_tracking={this.state.open_remove_tracking}
                        allActions={this.state.allActions}
                        rowsToRemove={this.state.rowsToRemove}
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        refresh_whole={() => {
                            this.refresh_whole();
                        }}
                        refreshTable={() => {
                            this.props.refreshTable();
                        }}
                        outboxrefreshTable={() => {
                            this.props.outboxrefreshTable();
                        }}
                        deleteDocument={this.props.deleteDocument}
                        delstatusId={this.props.delstatusId}
                        delprocessId={this.props.delprocessId}
                        selectedDocId={this.props.selectedDocId}
                        processId={this.props.processId}
                        statusId={this.props.statusId}
                        reportProcessId={this.props.ReportprocessId}
                        reportStatusId={this.props.ReportstatusId}
                        reportView={this.props.reportView}
                        rowTransition={() => {
                            this.props.rowTransition();
                        }}
                        closeBigView={() => {
                            this.props.closeBigView();
                        }}
                    />
                ) : null}

                {this.state.open_notes ? (
                    <Notes
                        calingFrom={this.props.calingFrom}
                        fromSearch={this.props.fromSearch}
                        openNote={this.state.open_notes}
                        docId={
                            this.state.docIdForNotes !== undefined
                                ? this.state.docIdForNotes
                                : this.props.selectedDocId
                        }
                        handleReset={() => {
                            this.setState({ open_notes: false });
                        }}
                        updateState={() => {}}
                        viewAllOpen={false}
                        refreshTable={() => {
                            this.props.refreshTable();
                        }}
                        deleteDocument={this.props.deleteDocument}
                        delstatusId={this.props.delstatusId}
                        delprocessId={this.props.delprocessId}
                        allActions={this.state.allActions}
                    />
                ) : null}

                <Modal
                    show={this.state.openHistroy}
                    onHide={() => {
                        this.handleHistroyClose();
                    }}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start history_header'
                    >
                        <Modal.Title className='d-flex d-flex wrap_history align-items-stretch justify-content-between'>
                            <div className='history_popup'>
                                <i className='fa fa-history' />
                                View History
                            </div>
                            <div className='d-flex align-items-center'>
                                <i
                                    title='Close'
                                    className='fa-light fa-xmark d-flex justify-content-end '
                                    aria-hidden='true'
                                    onClick={() => {
                                        this.handleHistroyClose();
                                    }}
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0 history-pop'>
                        <DocumentHistroy
                            openHistroy={this.state.openHistroy}
                            docId={this.props.selectedDocId}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    showLoader: (canShow) => showLoader(canShow),
    getCountData: getCountData,
    getListaction,
    updateOutboxState,
    updateSearchState,
    getSelectedRowDetails: getSelectedRowDetails,
    outboxPage,
    deletedPage,
    updateList,
};
const mapStateToProps = (state) => {
    return {
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        count: state.persist.count,
        ddl: state.persistDDL.DDL,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,

        stateData: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.stateData
            : {},
        stateToken: state.outbox.stateToken,
        selectRowDetails: state.outbox.selectedRowDetails,
        rowDetails: state.outbox.stateData.rowDetails,
        deleted_Page_redux: state.search.deletedPage,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OutboxActionsComponent));
