import React from 'react';
import Loader from 'react-loader-spinner';

export const Spinner = (props) => {
    return props.show ? (
        <div className='overlay-loader'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const SpinnerTwo = (props) => {
    return props.show ? (
        <div className='spinner-loader'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const SpinnerBlur = (props) => {
    return props.show ? (
        <div className='spinner-blur'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const SpinnerThree = (props) => {
    return props.show ? (
        <div style={{ textAlign: 'center' }} className='spinner_3'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const Spinner3 = (props) => {
    return props.show ? (
        <div style={{ textAlign: 'center' }} className='spinner_3'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const SpinnerLower = (props) => {
    return props.show ? (
        <div style={{ textAlign: 'center' }} className='spinner_lower'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};
export default Spinner;
