/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { encode as base64_encode } from 'base-64';
import { connect } from 'react-redux';
import { downloadDoc } from '../../pages/api/api';
import { SpinnerBlur } from '../../pages/outbox/components/Spinner';
import { stringEmptyOrUndefined } from '../commonfunction';

const SuccessGmailRedirectPage = (props) => {
    const gmailURL = 'https://mail.google.com/mail/mu/mp/me/#cv/Drafts/';
    const office365 = 'https://outlook.office365.com/mail/drafts';
    const secretKey = props.configData?.emailIntegration?.clientSecret;
    const clientId = props.configData?.emailIntegration?.clientId;
    const redirectUri = 'http://localhost:8081/mailSuccess';
    const redirectUriServer = props.configData.emailIntegration.serverURL;

    const [loading, setLoading] = useState(true);

    const windowURL = (link, pageSetUp) => {
        window.open(link, pageSetUp);
    };

    const sendDraft = async (docId, access_token, mailId) => {
        let documentPromise;
        if (docId !== undefined) {
            documentPromise = downloadDoc(docId);
        }
        if (documentPromise !== undefined) {
            await documentPromise
                .then((response) => {
                    if (response.status === 400) {
                        props.outboxPage({
                            pdfURL: 'No pdf Found',
                        });
                    }
                    if (response.status === 204) {
                        // setApiError(false);
                    }
                    return response.blob();
                })
                .then((data) => {
                    localStorage.setItem('gmail_sendDraftID', null);

                    const reader = new FileReader();
                    reader.readAsDataURL(data);
                    reader.onloadend = function () {
                        const base64data = reader.result;

                        const pngData = base64data.split('base64,')[1];

                        const mail = [
                            'Content-Type: multipart/mixed; boundary="foo_bar_baz"\r\n',
                            'MIME-Version: 1.0\r\n',
                            'To: \r\n',
                            'Subject: \r\n\r\n',

                            '--foo_bar_baz\r\n',
                            'Content-Type: multipart/alternative; boundary=foo_bar_baz\r\n\r\n',
                            '--foo_bar_baz\r\n',
                            'Content-Type: text/plain; charset="UTF-8"\r\n',
                            'Content-Transfer-Encoding: quoted-printable\r\n\r\n',

                            '\r\n\r\n',

                            '--foo_bar_baz\r\n',
                            'Content-Type: application/pdf; name=document.pdf\r\n',
                            'MIME-Version: 1.0\r\n',
                            'Content-Transfer-Encoding: base64\r\n',
                            'Content-Disposition: attachment; filename=document.pdf\r\n',
                            'Content-Disposition: inline; filename=document.pdf\r\n',

                            pngData,
                            '\r\n\r\n',

                            '--foo_bar_baz--',
                        ].join('');

                        const encoded = base64_encode(mail);

                        const params = JSON.stringify({
                            message: {
                                raw: `${encoded}`,
                            },
                        });

                        try {
                            fetch(
                                `https://www.googleapis.com/gmail/v1/users/${mailId}/drafts`,
                                {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${access_token}`,
                                    },
                                    body: params,
                                }
                            )
                                .then((response) => response.json())
                                .then((json) => {
                                    setLoading(false);
                                    windowURL(
                                        `https://mail.google.com/mail/mu/mp/me/#cv/Drafts/${json.message.id}`,
                                        '_self'
                                    );
                                    // window.open(
                                    //     `https://mail.google.com/mail/mu/mp/me/#cv/Drafts/${json.message.id}`,
                                    //     '_self'
                                    // );
                                    // window.open(gmailURL, '_self');
                                })
                                .catch((error) => {
                                    setLoading(false);
                                });
                        } catch (error) {
                            setLoading(false);
                        }
                    };
                })
                .catch((error) => {
                    setLoading(false);
                    return 'error';
                });
        }
    };

    const sendDraftOffice365 = async (docId, access_token, mailId) => {
        let documentPromise;
        let webLink;
        if (docId !== undefined) {
            documentPromise = downloadDoc(docId);
        }
        if (documentPromise !== undefined) {
            await documentPromise
                .then((response) => {
                    if (response.status === 400) {
                        props.outboxPage({
                            pdfURL: 'No pdf Found',
                        });
                    }
                    if (response.status === 204) {
                        // setApiError(false);
                    }
                    return response.blob();
                })
                .then((data) => {
                    localStorage.setItem('gmail_sendDraftID', null);

                    const params = JSON.stringify({
                        body: {
                            contentType: 'HTML',
                            content: '',
                        },
                        Attachments: [],
                    });
                    // If here office 365 processing to send create the empty draft first using message api calls and after processing the again the api calls createUploadSession api
                    try {
                        fetch(
                            `https://graph.microsoft.com/v1.0/users/${mailId}/messages`,
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${access_token}`,
                                },
                                body: params,
                            }
                        )
                            .then((response) => response.json())
                            .then((json) => {
                                webLink = json.id;
                                new Response(data)
                                    .arrayBuffer()
                                    .then((response) => {
                                        fetch(
                                            `https://graph.microsoft.com/v1.0/me/messages/${json.id}/attachments/createUploadSession`,
                                            {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type':
                                                        'application/json',
                                                    Authorization: `Bearer ${access_token}`,
                                                },
                                                body: JSON.stringify({
                                                    AttachmentItem: {
                                                        attachmentType: 'file',
                                                        contentType:
                                                            'application/pdf',
                                                        name: 'document.pdf',
                                                        size: data.size,
                                                    },
                                                }),
                                            }
                                        )
                                            .then((response) => response.json())
                                            .then((json) => {
                                                fetch(`${json.uploadUrl}`, {
                                                    method: 'PUT',
                                                    headers: {
                                                        'Content-Type':
                                                            'application/octet-stream',
                                                        'Content-Length': `${data.size}`,
                                                        'Content-Range': `bytes 0-${
                                                            data.size - 1
                                                        }/${data.size}`,
                                                    },
                                                    body: response,
                                                })
                                                    // .then((response) => response.json())
                                                    .then((response) => {
                                                        setLoading(false);
                                                        const slicedWebLink =
                                                            webLink.slice(
                                                                0,
                                                                -1
                                                            );
                                                        windowURL(
                                                            `https://outlook.office365.com/mail/deeplink/compose/${slicedWebLink}%3D?ItemID=${slicedWebLink}%3D&exvsurl=1`,
                                                            '_self'
                                                        );
                                                    })
                                                    .catch((error) => {
                                                        setLoading(false);
                                                    });
                                            })
                                            .catch((error) => {
                                                setLoading(false);
                                            });
                                    });
                            })
                            .catch((error) => {
                                setLoading(false);
                            });
                    } catch (error) {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    return 'error';
                });
        }
    };

    const getAccessURLToken = (code, sendDraftID) => {
        const params = JSON.stringify({
            client_id: clientId,
            client_secret: secretKey,
            grant_type: 'authorization_code',
            code: code,
            redirect_uri:
                process.env.REACT_APP_ENV === 'dev'
                    ? redirectUri
                    : redirectUriServer,
            scope: 'https://mail.google.com/',
        });

        try {
            fetch('https://www.googleapis.com/oauth2/v4/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: params,
            })
                .then((response) => response.json())
                .then((json) => {
                    localStorage.setItem(
                        'gmail_access_token',
                        json.access_token
                    );
                    localStorage.setItem(
                        'gmail_refresh_token',
                        json.refresh_token
                    );
                    localStorage.setItem('gmail_client_secret', secretKey);
                    localStorage.setItem('gmail_client_id', clientId);
                    // getUserID(sendDraftID, json.access_token);

                    let userDetials = localStorage.getItem('user_details');
                    userDetials = JSON.parse(userDetials);
                    sendDraft(
                        sendDraftID,
                        json.access_token,
                        userDetials.userEmail
                    );
                })
                .catch((error) => {
                    setLoading(false);
                });
        } catch (err) {
            setLoading(false);
        }
    };

    const getUserEmail = (sendDraftID, token) => {
        fetch('https://graph.microsoft.com/oidc/userinfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                sendDraftOffice365(sendDraftID, token, json.email);
            })
            .catch((error) => {});
    };

    const getAccessURLTokenOffice365 = (code, sendDraftID, mailId) => {
        const params = {
            client_id: clientId,
            // scope: 'https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/Mail.Send',
            redirect_uri:
                process.env.REACT_APP_ENV === 'dev'
                    ? redirectUri
                    : redirectUriServer,
            grant_type: 'authorization_code',
            // client_secret: 'zBy8Q~Gon9NoAa7XPTI9~BoEWPyuIeiTauL8abOZ',//clientSecret,
            code: code,
            code_verifier: '1qaz2wsx3edc4rfv5tgb6yhn1234567890qwertyuiop',
        };

        const formBody = Object.keys(params)
            .map(
                (key) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        params[key]
                    )}`
            )
            .join('&');

        try {
            fetch(
                'https://login.microsoftonline.com/organizations/oauth2/v2.0/token',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded;charset=UTF-8',
                    },
                    body: formBody,
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    localStorage.setItem(
                        'gmail_office365_access_token',
                        json.access_token
                    );
                    localStorage.setItem(
                        'gmail_office365_refresh_token',
                        json.refresh_token
                    );
                    localStorage.setItem(
                        'gmail_office365_client_secret',
                        secretKey
                    );
                    // localStorage.setItem('gmail_office365_client_id', clientId);
                    let userDetials = localStorage.getItem('user_details');
                    // eslint-disable-next-line no-unused-vars
                    userDetials = JSON.parse(userDetials);
                    getUserEmail(sendDraftID, json.access_token);
                })
                .catch((error) => {
                    setLoading(false);
                });
        } catch (err) {
            setLoading(false);
        }
    };

    const checkAuthorizationCode = () => {
        const sendDraftID = localStorage.getItem('gmail_sendDraftID');
        const url = window.location.search;
        const code = new URLSearchParams(url).get('code');

        if (code === 'access') {
            const docId = new URLSearchParams(url).get('docId');
            const token = new URLSearchParams(url).get('token');
            const mailId = new URLSearchParams(url).get('mailId');
            sendDraft(docId, token, mailId);
        } else if (code != null) {
            getAccessURLToken(code, sendDraftID);
        } else {
            windowURL(gmailURL, '_self');
        }
    };

    const checkAuthorizationCodeOffice365 = async () => {
        const sendDraftID = localStorage.getItem('gmail_sendDraftID');

        const url = window.location.search;
        const code = new URLSearchParams(url).get('code');

        if (code === 'access') {
            const docId = new URLSearchParams(url).get('docId');
            const token = new URLSearchParams(url).get('token');
            const mailId = new URLSearchParams(url).get('mailId');
            sendDraftOffice365(docId, token, mailId);
        } else if (code != null) {
            getAccessURLTokenOffice365(code, sendDraftID);
        } else {
            windowURL(office365, '_self');
        }
    };

    const checkAuthorizeVia = () => {
        if (props.configData?.emailIntegration?.serviceType === 'Office 365') {
            checkAuthorizationCodeOffice365();
        } else checkAuthorizationCode();
    };

    useEffect(() => {
        checkAuthorizeVia();
    }, []);

    return (
        <div>
            <SpinnerBlur show={loading} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    configData:
        stringEmptyOrUndefined(state.configReducerPersist) &&
        stringEmptyOrUndefined(state.configReducerPersist.configData)
            ? state.configReducerPersist.configData
            : {},
});

export default connect(mapStateToProps, null)(SuccessGmailRedirectPage);
