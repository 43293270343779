import React, { useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
// import { useSelector, useDispatch } from 'react-redux';
import {
    reportColorCodeArray,
    getRandomColors,
} from '../../../components/commonfunction';

const _ = require('lodash');

function Tile(props) {
    const [daysoutstanding, setDaysoutstanding] = React.useState([]);
    const [outboundstatus, setOutboundstatus] = React.useState([]);
    const [colorCode] = React.useState(reportColorCodeArray);
    const [displayAnimation] = React.useState(false);
    const [agingcount, setAgingcount] = React.useState('');
    const [statuscount, setStatuscount] = React.useState('');
    const [graphicalField, setGraphicalField] =
        React.useState('outboundstatus');
    const [countData, setCountData] = React.useState(0);
    // const redux_store = useSelector((state) => state);
    // const outboxData = redux_store.outbox.outbox;
    // const outbox_page = redux_store.outbox.outboxpage;
    const filteredData = props.orginAllRows;
    // const dispatch = useDispatch();
    let allRowData = props.rows;
    // let graphicalField = 'daysoutstanding';
    // let graphicalField = 'outboundstatus';

    useEffect(() => {
        for (const property in outboundstatus) {
            if (
                props.propertyTiles === property &&
                outboundstatus[property].length !== props.totalCount
            ) {
                // eslint-disable-next-line no-use-before-define
                openTile(outboundstatus[property], property);
            }
        }
        for (const property in daysoutstanding) {
            if (
                props.propertyTiles === property &&
                daysoutstanding[property].length !== props.totalCount
            ) {
                // if(props.filterTxt)
                // eslint-disable-next-line no-use-before-define
                openTile(daysoutstanding[property], property);
            }
        }

        if (Array.isArray(filteredData) && filteredData.length > 0) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            allRowData = filteredData;
        }

        /* if (!outbox_page.hasOwnProperty('dataRows')) {
            const temp = { ...outboxData.dataTable };
            const initial = outbox_page.allRowData;
            dispatch(outboxPage({ ...temp, initialData: initial }));
        } */
        const arr1 = [];
        const arr2 = [];
        const arr3 = [];
        const arr4 = [];
        const arr5 = [];
        const arr6 = [];
        const ageing = [arr1, arr2, arr3, arr4, arr5, arr6];
        let agingcounting = 0;

        if (graphicalField === 'daysoutstanding') {
            // eslint-disable-next-line array-callback-return
            allRowData.map((data) => {
                if (data[graphicalField] >= 0 && data[graphicalField] <= 14)
                    arr1.push(data);
                else if (
                    data[graphicalField] >= 15 &&
                    data[graphicalField] <= 30
                )
                    arr2.push(data);
                else if (
                    data[graphicalField] >= 31 &&
                    data[graphicalField] <= 50
                )
                    arr3.push(data);
                else if (
                    data[graphicalField] >= 51 &&
                    data[graphicalField] <= 75
                )
                    arr4.push(data);
                else if (
                    data[graphicalField] >= 76 &&
                    data[graphicalField] <= 119
                )
                    // arr5.push(data.daysoutstanding);
                    arr5.push(data);
                else if (data[graphicalField] >= 120) arr6.push(data);
                agingcounting++;
            });
            setAgingcount(agingcounting);
            setDaysoutstanding(ageing);
        }

        if (graphicalField === 'outboundstatus') {
            let statuscounting = 0;
            let del;
            const result = allRowData.reduce(function (r, a, c) {
                statuscounting++;

                del = a[graphicalField];

                if (del === '') del = '(Empty)';
                r[del] = r[del] || [];
                // r['testing'] = r['testing'] || [];
                r[del].push(a);
                // if (c < 20) {
                //     r['testing'].push(a);
                // }
                return r;
            }, Object.create(null));

            setStatuscount(statuscounting);
            setOutboundstatus(result);
        }
        if (graphicalField === 'daysoutstanding') {
            setCountData(2);
        }
        return () => {};
    }, [
        filteredData,
        countData === 1,
        props.orginAllRows,
        props.filterTxt,
        props.allRows,
        props.filteredRowData,
    ]);
    const subTitleFunction = (colour, bgColour) => {
        props.updateState({
            colourSub: colour,
            bgColourSub: bgColour,
        });
    };
    const openTile = async (data, state) => {
        // let dataTable = [];
        // dataTable.dataRows = data;

        // dataTable.headerColumns = props.headerColumns;

        let paginatedRowData;
        const startIndex = 0;
        const endIndex = startIndex + parseInt(props.perPage, 10);
        // eslint-disable-next-line prefer-const
        paginatedRowData = _.slice(data, startIndex, endIndex); // Pagination

        await props.updateState({
            //     onlyGridloader: true,
            allRows: data,
            // rowData: data,
            duplicateAllrows: props.filteredData,
            grpTilesData: data,
            //     grpTiles: true,
            totalCount: data.length,
            currentPage: 0,
            perPage: 10,
            filteredRowData: paginatedRowData,
            rowData: paginatedRowData,
            //     formName: 'Tiles',
            //     refreshDataGrid: false,
            propertyTiles: state,
            tileFilter: true,
        });
        props.resetPopOverData();
    };

    const byAgeingBottomBar = () => {
        const html = [];
        let id_index = 0;
        let percen = 0;

        // eslint-disable-next-line guard-for-in, no-use-before-define
        const randomcolorsArray = getRandomColorCodeArray(6);
        let title = '';
        const ageRange = ['0-14', '15-30', '31-50', '51-75', '76-119', '120+'];
        for (const property in daysoutstanding) {
            id_index = parseInt(property, 10);
            percen = (daysoutstanding[property].length / agingcount) * 100;
            title = `${ageRange[id_index]} days`;
            if (daysoutstanding[property].length > 0) {
                html.push(
                    <div
                        key={property}
                        title={title}
                        className='status'
                        style={{
                            width: `${percen}%`,
                            background: randomcolorsArray[id_index],
                        }}
                    />
                );
            }
        }
        return html;
    };

    const byStatusBottomBar = () => {
        const html = [];
        let id_index = 0;
        let percen = 0;

        // eslint-disable-next-line no-use-before-define
        const randomcolorsArray = getRandomColorCodeArray(0);

        for (const property in outboundstatus) {
            percen = (outboundstatus[property].length / statuscount) * 100;
            if (outboundstatus[property].length > 0) {
                html.push(
                    <div
                        key={property}
                        title={property}
                        className='status'
                        style={{
                            width: `${percen}%`,
                            background: randomcolorsArray[id_index],
                        }}
                    />
                );
            }
            id_index++;
        }
        return html;
    };
    const getRandomColorCodeArray = (resultCount) => {
        let coun = 0;
        // eslint-disable-next-line eqeqeq
        if (resultCount != 0) {
            coun = resultCount;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const property in outboundstatus) {
                coun++;
            }
        }
        const originalColorCount = colorCode.length;
        const totalColorCount = Math.floor(originalColorCount / coun);
        const randomcolors = getRandomColors(colorCode, totalColorCount);

        return randomcolors;
    };
    const handleStatisticsOnChange = (name) => {
        if (name !== 'refresh') {
            setGraphicalField(name);
            if (name === 'daysoutstanding') {
                const count = countData + 1;
                setCountData(count);
            }
        } else {
            props.updateState({
                propertyTiles: 'All Data',
                filterTxt: '',
            });
            openTile(filteredData, 'All Data');
        }
    };
    const byStatus = () => {
        let idx = 0;
        const html = [];

        // html.push(
        //     <React.Fragment key={'all_status'}>
        //         <div
        //             className={
        //                 props.propertyTiles === 'All Data'
        //                     ? 'common_block outstanding active'
        //                     : 'common_block outstanding'
        //             }
        //             onClick={() => {
        //                 openTile(filteredData, 'All Data');
        //                 subTitleFunction('#0f0f12', '#F2F4F5');
        //             }}
        //             style={
        //                 props.propertyTiles === 'All Data'
        //                     ? {
        //                           background: '#F2F4F5',
        //                       }
        //                     : null
        //             }
        //         >
        //             <span
        //                 className='bd-color-all'
        //                 // style={{
        //                 //     background: '#F2F4F5',
        //                 // }}
        //             />
        //             <span>{statuscount}</span>
        //             <h6 className=''>{'All Data'}</h6>
        //         </div>
        //     </React.Fragment>
        // );

        for (const property in outboundstatus) {
            const randomcolorsArray = getRandomColorCodeArray(0);
            const hex = randomcolorsArray[idx];
            const red = parseInt(hex[1] + hex[2], 16);
            const green = parseInt(hex[3] + hex[4], 16);
            const blue = parseInt(hex[5] + hex[6], 16);
            const backgroundColor = `rgb(${red}, ${green}, ${blue},.1)`;

            html.push(
                <React.Fragment key={property}>
                    <div
                        className={
                            props.propertyTiles === property
                                ? 'common_block outstanding active'
                                : 'common_block outstanding'
                        }
                        onClick={() => {
                            openTile(outboundstatus[property], property);
                            subTitleFunction(hex, backgroundColor);
                        }}
                        style={
                            props.propertyTiles === property
                                ? { background: backgroundColor }
                                : null
                        }
                    >
                        <span
                            className='bd-color'
                            style={{
                                background: randomcolorsArray[idx],
                            }}
                        />
                        <span>{outboundstatus[property].length}</span>
                        <h6 className=''>{property}</h6>
                    </div>
                </React.Fragment>
            );
            idx++;
        }

        return html;
    };
    const byAgeing = () => {
        let id_index = 0;
        const html = [];
        const ageRange = ['0-14', '15-30', '31-50', '51-75', '76-119', '120+'];
        // html.push(
        //     <React.Fragment key={'all_status'}>
        //         <div
        //             className={
        //                 props.propertyTiles === 'All Data'
        //                     ? 'common_block outstanding active'
        //                     : 'common_block outstanding'
        //             }
        //             style={
        //                 props.propertyTiles === 'All Data'
        //                     ? {
        //                           background: '#F2F4F5',
        //                       }
        //                     : null
        //             }
        //             onClick={() => {
        //                 openTile(filteredData, 'All Data');
        //                 subTitleFunction('#0f0f12', '#F2F4F5');
        //             }}
        //         >
        //             <span className='bd-color-all' />
        //             <span>{agingcount}</span>
        //             <h6 className=''>{'All Data'}</h6>
        //         </div>
        //     </React.Fragment>
        // );

        for (const property in daysoutstanding) {
            const randomcolorsArray = getRandomColorCodeArray(6);
            id_index = parseInt(property, 10);
            const hex = randomcolorsArray[id_index];
            const red = parseInt(hex[1] + hex[2], 16);
            const green = parseInt(hex[3] + hex[4], 16);
            const blue = parseInt(hex[5] + hex[6], 16);
            const backgroundColor = `rgb(${red}, ${green}, ${blue},.1)`;

            html.push(
                <React.Fragment key={property}>
                    <div
                        className={
                            props.propertyTiles === `${ageRange[property]} days`
                                ? 'common_block outstanding active'
                                : 'common_block outstanding'
                        }
                        style={
                            props.propertyTiles === `${ageRange[property]} days`
                                ? { background: backgroundColor }
                                : null
                        }
                        onClick={() => {
                            openTile(
                                daysoutstanding[property],
                                `${ageRange[property]} days`
                            );
                            subTitleFunction(hex, backgroundColor);
                        }}
                    >
                        <span
                            className='bd-color'
                            style={{
                                background: randomcolorsArray[id_index],
                            }}
                        />
                        <span>{daysoutstanding[property].length}</span>
                        <h6 className=''>{ageRange[id_index]} days</h6>
                    </div>
                </React.Fragment>
            );
        }
        return html;
    };
    return (
        <>
            <div
                id='Statistic-part'
                className='wrap_details wrap_note p-4 Statistic-part ml-7 mr-7'
            >
                <div className='d-flex justify-content-between mb-5 statistic-top-sec'>
                    <div className='d-flex align-items-center statistics_header'>
                        <h3 className='mb-0'>
                            <i
                                title='Chart-line'
                                className='fas fa-chart-line'
                            />{' '}
                            Statistics
                        </h3>
                    </div>
                    <div className='d-flex'>
                        <span
                            className='align-self-center reload'
                            onClick={() => {
                                handleStatisticsOnChange('refresh');
                            }}
                        >
                            {' '}
                            <i
                                title='Refresh'
                                className={
                                    displayAnimation
                                        ? 'fas fa-sync-alt refreshing'
                                        : 'fas fa-sync-alt'
                                }
                            />{' '}
                        </span>

                        <div className='tab_part'>
                            <div className='tabs'>
                                <Nav
                                    style={{
                                        float: 'left',
                                    }}
                                    id='noanim-tab'
                                    defaultActiveKey='link-1'
                                    variant='pills'
                                    className=''
                                >
                                    <Nav.Item>
                                        <Nav.Link
                                            title='By Status'
                                            key={0}
                                            eventKey='link-1'
                                            onClick={() => {
                                                handleStatisticsOnChange(
                                                    'outboundstatus'
                                                );
                                            }}
                                            className={({ isActive }) =>
                                                isActive ? 'active' : 'inactive'
                                            }
                                        >
                                            By Status
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            title='By Aging'
                                            key={1}
                                            className={({ isActive }) =>
                                                isActive ? 'active' : 'inactive'
                                            }
                                            eventKey='link-2'
                                            onClick={() => {
                                                handleStatisticsOnChange(
                                                    'daysoutstanding'
                                                );
                                            }}
                                        >
                                            By Aging
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' justify-content-between mb-5 statistic-top-sec'>
                    <>
                        {graphicalField === 'daysoutstanding' ? (
                            <div>
                                <div className='statastic_outbox st-outbox d-flex mb-5'>
                                    {byAgeing()}
                                </div>
                                <div className='status_outbox mb-5'>
                                    {byAgeingBottomBar()}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className='statastic_outbox st-outbox d-flex mb-5'>
                                    {byStatus()}
                                </div>
                                <div className='status_outbox mb-5'>
                                    {byStatusBottomBar()}
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </div>
        </>
    );
}

export { Tile };
