import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { processPassword } from '../_redux/authCrud';
import Spinner from './Spinner';

export const ProcessPassword = () => {
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');
    const [sucmsg, setSucmsg] = useState('');
    const [errmsg, setErrmsg] = useState('');
    useEffect(() => {
        if (String(token) !== '') {
            processPassword(token, localStorage.getItem('sub_domain_name'))
                .then((response) =>
                    parseInt(response.status) === 204
                        ? setSucmsg(
                              'Your password has been changed successfully!'
                          )
                        : setErrmsg('Your request token has been expired')
                )
                .catch(() => {
                    setErrmsg('Your request token has been expired');
                });
        } else {
            setErrmsg('Your request token has been expired');
        }
    }, [token]);

    if (String(sucmsg) !== '') {
        return (
            <Redirect
                to={{
                    pathname: '/auth/login',
                    state: { msg: sucmsg, msgtype: 'success' },
                }}
            />
        );
    }
    if (String(errmsg) !== '') {
        return (
            <Redirect
                to={{
                    pathname: '/auth/login',
                    state: { msg: errmsg, msgtype: 'error' },
                }}
            />
        );
    }
    return (
        <>
            <Spinner show />
            <div
                className='login-form login-forgot m-auto'
                style={{ display: 'block' }}
            >
                {/* <div className='Logo confirmation text-center  mb-2 mb-lg-10'>
                    <a href='#' title='Logo'>
                        {/* <img src={window.location.origin+ '/images/WV-Logo.png'} /> */}
                {/* </a>
                </div>*/}
                <div className='text-center mb-5 mb-lg-4'>
                    <div className='mt-4 '>
                        New password being sent to your email address.
                    </div>
                </div>
            </div>
        </>
    );
};
