/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment-timezone';
import {
    formatNameWithSpaceToUnderscore,
    formatDate,
    GetFieldData,
    EpisodeSearch,
    loadHTMLData,
    formatHeaderNameWithOutSpace,
    OrderWithUnderScore,
    encodeParameter,
} from '../../../components/commonfunction';
import { PostMessageLogout, recordSetSearch } from '../../api/api';
import { editDetailPage } from '../../../pages/_redux/actions/inboxActions';

const _ = require('lodash');
export class ViewDetails extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            rowDetails: {},
            patientRecordAvailable: false,
            currentLookup: '',
            rowValues: {},
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.initializeViewDetails();
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedRowDetails !== this.props.selectedRowDetails ||
            prevProps.selectedDocId !== this.props.selectedDocId
        ) {
            this.initializeViewDetails();
        }
    }

    initializeViewDetails = async () => {
        let fieldData = GetFieldData({ configData: this.props.configData });
        this.setState({ allFieldId: fieldData });
        var allRecordSetID = {};
        var configuredRecordsets = [];
        let episodeRecordsetId;
        let orderRecordsetId;
        configuredRecordsets = this.props.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (formatHeaderNameWithOutSpace(data.name) === 'episodedata') {
                    episodeRecordsetId = data.recordsetId;
                } else if (
                    formatHeaderNameWithOutSpace(data.name) === 'orderdata'
                ) {
                    orderRecordsetId = data.recordsetId;
                }

                var fieldIDArray = {};
                if (data.hasOwnProperty('fields') && data.fields.length > 0) {
                    data.fields.map((field, index) => {
                        fieldIDArray[
                            formatNameWithSpaceToUnderscore(field.name)
                        ] = field;
                    });
                    data.fields_obj = fieldIDArray;
                }
                var name = formatNameWithSpaceToUnderscore(data.name);
                allRecordSetID[name] = data;
            });
        }
        this.setState({ allRecordSetID: allRecordSetID });

        var rowDetails = [];
        var allFields = this.props.configData.fields;

        if (this.props.selectedRowDetails.hasOwnProperty('fields')) {
            rowDetails = this.props.selectedRowDetails.fields;
        }

        var finalRowDetail = {};
        var formDataTemp = {};
        if (rowDetails.length > 0) {
            rowDetails.map((rowdetail) => {
                allFields.map((allfields) => {
                    var temp = { ...rowdetail };
                    if (rowdetail.fieldId === allfields.fieldId) {
                        temp.fieldName = allfields.name;
                        var removeSpace = formatNameWithSpaceToUnderscore(
                            allfields.name
                        );
                        finalRowDetail[removeSpace] = temp;
                    }
                });
            });
        }

        const transformedData = {};

        for (const key in finalRowDetail) {
            transformedData[key] = finalRowDetail[key].value;
        }
        await this.setState(
            {
                rowDetails: finalRowDetail,
                rowValues: transformedData,
            },
            () => {
                if (this._isMounted) {
                    if (
                        this.state.rowDetails.hasOwnProperty('patient_name') &&
                        this.state.rowDetails.patient_name.value !== ''
                    ) {
                        var split =
                            this.state.rowDetails.patient_name.value.split(',');
                        this.state.rowDetails.patient_name.value =
                            split[0] + ', ' + split[1];
                    }
                }
            }
        );

        var patient_filter = {};
        for (const value in this.state.rowDetails) {
            if (
                value === 'branch' ||
                value === 'date_of_birth' ||
                value === 'service_line' ||
                value === 'patient_name' ||
                value === 'gender' ||
                value === 'patient_id' ||
                value === 'order_number' ||
                value === 'order_date'
            ) {
                var key = formatNameWithSpaceToUnderscore(
                    this.state.rowDetails[value].fieldName
                );
                patient_filter[key] = this.state.rowDetails[value].value;
            }
            if (
                value === 'attachment_type' ||
                value === 'attachment_location' ||
                value === 'team' ||
                value === 'comments' ||
                value === 'new_referral' ||
                value === 'date_of_birth_validation' ||
                value === 'signed_date' ||
                value === 'payor_source' ||
                value === 'care_type' ||
                value === 'episodeid' ||
                value === 'referral_date'
            ) {
                var formKey = formatNameWithSpaceToUnderscore(
                    this.state.rowDetails[value].fieldName
                );
                formDataTemp[formKey] = this.state.rowDetails[value].value;
            }
        }
        let selectRowDetail = this.props.selectedRowDetails.fields;
        let configData = this.props.configData.fields;
        let visitShow = false;
        await selectRowDetail.map((data, idx) => {
            if (this.state.allFieldId) {
                if (data.fieldId === this.state.allFieldId.visit_id) {
                    visitShow = true;
                }
            }
        });
        let fieldObj = {};
        configData.map((item) => {
            if (item.name === 'AgencyID') {
                fieldObj.agencyid = item.fieldId;
            }
            if (item.name === 'EpisodeID') {
                fieldObj.episodeId = item.fieldId;
            }
        });
        const fieldObj2 = selectRowDetail.reduce((pre, curr) => {
            if (curr.fieldId === fieldObj.agencyid) {
                pre.agency_value = curr.value;
            }
            if (curr.fieldId === fieldObj.episodeId) {
                pre.episode_value = curr.value;
            }
            return pre;
        }, {});
        if (
            visitShow === true &&
            fieldObj2.agency_value &&
            fieldObj2.episode_value
        ) {
            let episodeSearch = `fieldSearch=${fieldObj.agencyid}=${fieldObj2.agency_value}&fieldSearch=${fieldObj.episodeId}=${fieldObj2.episode_value}`;
            this.episodeVisitFunction(episodeSearch);
        }
        if (typeof this.props.updateState === 'function') {
            this.props.updateState();
        }

        if (
            patient_filter.hasOwnProperty('patient_name') &&
            patient_filter.patient_name !== ''
        ) {
            var split = patient_filter.patient_name.trim().split(',');

            patient_filter.last_name = split[0];
            patient_filter.first_name = split[1];
        }

        if (this.state.rowDetails.hasOwnProperty('order_number')) {
            if (typeof this.props.updateState === 'function') {
                patient_filter.fromViewDetail = true;
                patient_filter.id = '-123';
                this.props.updateState({
                    currentLookup: 'byOrder',
                    patient_filter: patient_filter,
                });
            }
            this.setState({ currentLookup: 'byOrder' });
        } else {
            if (typeof this.props.updateState === 'function') {
                if (_.isEmpty(patient_filter) === false) {
                    patient_filter.id = '-123';
                    patient_filter.patientRecord = true;
                }
                this.props.updateState({
                    currentLookup: 'byPatient',
                    patient_filter: patient_filter,
                });
            }
        }
        if (this._isMounted) {
            await this.setState({ patient_filter: patient_filter });
        }

        if (typeof this.props.updateState === 'function') {
            this.props.updateState({
                fromViewDetails: true,
                formData: formDataTemp,
            });
        }
        if (this.props.subCalingFrom !== 'global_actions') {
            this.props.editDetailPage({ viewDetailFormdata: formDataTemp });
        }

        if (
            this.state.rowDetails?.attachment_location?.value === 'EPISODE' &&
            this.state.rowValues.order_number
        ) {
            this.orderApiCall(episodeRecordsetId, orderRecordsetId);
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };
    orderApiCall = async (episodeRecordsetId, orderRecordsetId) => {
        let orderSearchText;
        let episodeid;
        this.props.configData.fields.map((fieldData, index) => {
            if (fieldData.name === 'Order Number') {
                orderSearchText = `fieldSearch=${fieldData.fieldId}%3D${this.state.rowValues.order_number}%2A`;
            }
        });

        const resultOrder = await recordSetSearch(
            orderRecordsetId,
            orderSearchText
        );
        let orderData;
        if (resultOrder.status === 200) {
            orderData = await resultOrder.json();
        } else {
            throw new Error('Error in recordSetSearch');
        }
        if (orderData && orderData?.code === 300) {
            PostMessageLogout();
        }
        if (orderData) {
            const searchResult = await OrderWithUnderScore(orderData);
            if (searchResult.length === 1) {
                episodeid = searchResult[0].episodeid;
            }

            await this.episodeApiCall(episodeRecordsetId, episodeid);
        }
    };
    episodeApiCall = async (episodeRecordsetId, episodeid) => {
        let searchText = '';
        if (
            this.state.allRecordSetID.episode_data.inputSearchFieldIds.length >
                0 &&
            this.state.allRecordSetID &&
            this.state.allRecordSetID.episode_data &&
            this.state.allRecordSetID.episode_data.inputSearchFieldIds
        ) {
            const inputSearchfields =
                this.state.allRecordSetID.episode_data.inputSearchFieldIds;
            inputSearchfields.map((inputs, index) => {
                let amb;
                if (index === 0) {
                    amb = '';
                } else {
                    amb = '&';
                }
                if (
                    inputs === this.state.allFieldId.agencyid &&
                    this.state.rowValues.agencyid
                ) {
                    searchText += `${amb}fieldSearch=${inputs}=${this.state.rowValues.agencyid}`;
                }
                if (
                    inputs === this.state.allFieldId.branch &&
                    this.state.rowValues.branch
                ) {
                    searchText += `${amb}fieldSearch=${inputs}=${encodeParameter(
                        this.state.rowValues.branch
                    )}`;
                }
                if (
                    inputs === this.state.allFieldId.patient_id &&
                    this.state.rowValues.patient_id
                ) {
                    searchText += `${amb}fieldSearch=${inputs}=${this.state.rowValues.patient_id}`;
                }
            });
        }

        if (episodeid) {
            searchText += `&fieldSearch=${this.state.allFieldId.episodeid}=${episodeid}`;
        }

        const result = await recordSetSearch(episodeRecordsetId, searchText);

        var data;

        if (result.status === 200) {
            data = await result.json();
        } else {
            throw new Error('Error in recordSetSearch');
        }
        if (data && data.code === 300) {
            PostMessageLogout();
        }
        if (data) {
            const searchResult = EpisodeSearch(data);
            if (searchResult.length === 1) {
                let rowDetails = {
                    ...this.state.rowDetails,
                    select_an_episode: {
                        value: searchResult[0].select_an_episode
                            ? searchResult[0].select_an_episode
                            : '',
                    },
                    episode_end: {
                        value: searchResult[0].episode_end
                            ? searchResult[0].episode_end
                            : '',
                    },
                    episode_start: {
                        value: searchResult[0].episode_start
                            ? searchResult[0].episode_start
                            : '',
                    },
                    episode_status: {
                        value: searchResult[0].episode_status
                            ? searchResult[0].episode_status
                            : '',
                    },
                    medical_record_: {
                        value: searchResult[0].medical_record_
                            ? searchResult[0].medical_record_
                            : '',
                    },
                };
                let rowValues = {
                    ...this.state.rowValues,
                    select_an_episode: searchResult[0].select_an_episode
                        ? searchResult[0].select_an_episode
                        : '',

                    episode_end: searchResult[0].episode_end
                        ? searchResult[0].episode_end
                        : '',

                    episode_start: searchResult[0].episode_start
                        ? searchResult[0].episode_start
                        : '',

                    episode_status: searchResult[0].episode_status
                        ? searchResult[0].episode_status
                        : '',

                    medical_record_: searchResult[0].medical_record_
                        ? searchResult[0].medical_record_
                        : '',
                };
                this.setState({
                    rowDetails: rowDetails,
                    rowValues: rowValues,
                });
            }
        }
    };
    episodeVisitFunction = async (searchText) => {
        const response = await recordSetSearch(
            this.state.allRecordSetID.episode_visit_data.recordsetId,
            searchText
        );
        let data;

        if (response.status === 200) {
            data = await response.json();
        } else {
            throw new Error('Error in recordSetSearch');
        }
        if (data) {
            if (data && data?.code === 300) {
                PostMessageLogout();
            }
            const searchResult = await EpisodeSearch(data);
            if (searchResult.lenght === 1) {
                const formData = {
                    ...this.state.formData,
                    episode_visit: searchResult[0].visit_date,
                };

                this.setState({ formData: formData }, () => {
                    this.updateState({ formData: formData });
                });
            }
            this.setState({ episodeVisitData: searchResult }, () => {
                this.setState({
                    refreshEpisodeVisit: true,
                });
            });
        }
    };

    render() {
        return (
            <>
                {!this.state.rowDetails.hasOwnProperty('order_number') &&
                !this.state.rowDetails.hasOwnProperty('order_date') &&
                !this.state.rowDetails.hasOwnProperty('mr') &&
                !this.state.rowDetails.hasOwnProperty('patient_name') &&
                !this.state.rowDetails.hasOwnProperty('date_of_birth') &&
                !this.state.rowDetails.hasOwnProperty('gender') &&
                !this.state.rowDetails.hasOwnProperty('new_referral') &&
                !this.state.rowDetails.hasOwnProperty('attachment_type') &&
                !this.state.rowDetails.hasOwnProperty('attachment_location') &&
                !this.state.rowDetails.hasOwnProperty('signed_date') &&
                !this.state.rowDetails.hasOwnProperty(
                    'date_of_birth_validation'
                ) &&
                !this.state.rowDetails.hasOwnProperty('episodeid') &&
                !this.state.rowDetails.hasOwnProperty('episode_status') &&
                !this.state.rowDetails.hasOwnProperty('episode_start') &&
                !this.state.rowDetails.hasOwnProperty('episode_end') &&
                !this.state.rowDetails.hasOwnProperty('select_an_episode') &&
                !this.state.rowDetails.hasOwnProperty('branch') &&
                !this.state.rowDetails.hasOwnProperty('visit_date') &&
                !this.state.rowDetails.hasOwnProperty('comments') ? (
                    <div className='no_detail'>
                        No details added yet. Click on the “Edit Details” button
                        above to get started.{' '}
                    </div>
                ) : null}

                {this.state.rowDetails.hasOwnProperty('branch') &&
                Object.keys(this.state.rowDetails).length === 2 ? (
                    <div className='no_detail'>No details Found.</div>
                ) : null}

                {!this.state.rowDetails.hasOwnProperty(
                    'new_referral'
                ) ? null : (
                    <div style={{ display: 'flex' }} className='form-group row'>
                        <div className='col-lg-4 transition-select'>
                            <span className='patient_label'>NEW REFERRAL?</span>
                            <br />
                            {this.state.rowDetails.new_referral !==
                            undefined ? (
                                <span className='patient_info'>
                                    {this.state.rowDetails.new_referral.value}
                                </span>
                            ) : (
                                <span className='patient_info'> - </span>
                            )}
                        </div>
                    </div>
                )}

                {this.state.currentLookup === 'byOrder' ? (
                    <>
                        {!this.state.rowDetails.hasOwnProperty(
                            'order_number'
                        ) &&
                        !this.state.rowDetails.hasOwnProperty('order_date') &&
                        !this.state.rowDetails.hasOwnProperty('mr') &&
                        !this.state.rowDetails.hasOwnProperty('patient_name') &&
                        !this.state.rowDetails.hasOwnProperty(
                            'date_of_birth'
                        ) &&
                        !this.state.rowDetails.hasOwnProperty(
                            'gender'
                        ) ? null : (
                            <div
                                className='patient_detail edit-detail-bg'
                                style={{ backgroundColor: '#F0F7FB' }}
                            >
                                <div
                                    style={{ display: 'flex' }}
                                    className='form-group row'
                                >
                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            ORDER NUMBER
                                        </span>
                                        <br />
                                        {this.state.rowDetails.order_number !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                #{' '}
                                                {
                                                    this.state.rowDetails
                                                        .order_number.value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-lg-4'>
                                        <span className='patient_label'>
                                            ORDER DATE
                                        </span>
                                        <br />
                                        {this.state.rowDetails.order_date !==
                                            undefined &&
                                        this.state.rowDetails.order_date !==
                                            ' ' ? (
                                            <span className='patient_info'>
                                                {formatDate(
                                                    this.state.rowDetails
                                                        .order_date.value,
                                                    'MM/DD/YYYY'
                                                )}
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            MR #
                                        </span>
                                        <br />
                                        {this.state.rowDetails.mr !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {this.state.rowDetails.mr.value}
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-lg-4 transition-select'>
                                        {
                                            <span className='patient_label'>
                                                PATIENT NAME
                                            </span>
                                        }
                                        <br />
                                        {this.state.rowDetails.patient_name !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails
                                                        .patient_name.value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>

                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            DATE OF BIRTH
                                        </span>
                                        <br />
                                        {this.state.rowDetails.date_of_birth !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {formatDate(
                                                    this.state.rowDetails
                                                        .date_of_birth.value,
                                                    'MM/DD/YYYY'
                                                )}
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            GENDER
                                        </span>
                                        <br />
                                        {this.state.rowDetails.gender !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails.gender
                                                        .value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            BRANCH
                                        </span>
                                        <br />
                                        {this.state.rowDetails.branch !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails.branch
                                                        .value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {!this.state.rowDetails.hasOwnProperty(
                            'patient_name'
                        ) &&
                        !this.state.rowDetails.hasOwnProperty(
                            'date_of_birth'
                        ) &&
                        !this.state.rowDetails.hasOwnProperty(
                            'gender'
                        ) ? null : (
                            <div
                                className='patient_detail edit-detail-bg'
                                style={{ backgroundColor: '#F0F7FB' }}
                            >
                                <div
                                    style={{ display: 'flex' }}
                                    className='form-group row'
                                >
                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            PATIENT NAME
                                        </span>
                                        <br />
                                        {this.state.rowDetails.patient_name !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails
                                                        .patient_name.value
                                                }
                                            </span>
                                        ) : this.state.rowDetails
                                              ?.patient_first_name?.value !==
                                              '' &&
                                          this.state.rowDetails
                                              ?.patient_last_name?.value !==
                                              '' ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails
                                                        ?.patient_last_name
                                                        ?.value
                                                }
                                                ,{' '}
                                                {
                                                    this.state.rowDetails
                                                        ?.patient_first_name
                                                        ?.value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>

                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            DATE OF BIRTH
                                        </span>
                                        <br />
                                        {this.state.rowDetails.date_of_birth !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {formatDate(
                                                    this.state.rowDetails
                                                        .date_of_birth.value,
                                                    'MM/DD/YYYY'
                                                )}
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            GENDER
                                        </span>
                                        <br />
                                        {this.state.rowDetails.gender !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails.gender
                                                        .value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>

                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            SERVICE LINE
                                        </span>
                                        <br />
                                        {this.state.rowDetails.service_line !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails
                                                        .service_line.value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-lg-4 transition-select'>
                                        <span className='patient_label'>
                                            BRANCH
                                        </span>
                                        <br />
                                        {this.state.rowDetails.branch !==
                                        undefined ? (
                                            <span className='patient_info'>
                                                {
                                                    this.state.rowDetails.branch
                                                        .value
                                                }
                                            </span>
                                        ) : (
                                            <span className='patient_info'>
                                                {' '}
                                                -{' '}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {!this.state.rowDetails.hasOwnProperty('payor_source') &&
                !this.state.rowDetails.hasOwnProperty('care_type') &&
                !this.state.rowDetails.hasOwnProperty('team') &&
                !this.state.rowDetails.hasOwnProperty(
                    'referral_date'
                ) ? null : (
                    <div
                        className='patient_detail edit-detail-bg'
                        style={{
                            backgroundColor: '#F0F7FB',
                            marginTop: '20px',
                        }}
                    >
                        <div className='form-group row'>
                            {this.props.configData.indexingSettings
                                .payorSourceUsed === true ? (
                                <div className='col-lg-4 transition-select'>
                                    <span className='patient_label'>
                                        PAYOR SOURCE
                                    </span>
                                    <br />
                                    {this.state.rowDetails.payor_source !==
                                    undefined ? (
                                        <span className='patient_info'>
                                            {
                                                this.state.rowDetails
                                                    .payor_source.value
                                            }
                                        </span>
                                    ) : (
                                        <span className='patient_info'>
                                            {' '}
                                            -{' '}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                            {this.props.configData.indexingSettings
                                .careTypeUsed === true ? (
                                <div className='col-lg-4 transition-select'>
                                    <span className='patient_label'>
                                        CARE TYPE
                                    </span>
                                    <br />
                                    {this.state.rowDetails.care_type !==
                                    undefined ? (
                                        <span className='patient_info'>
                                            {
                                                this.state.rowDetails.care_type
                                                    .value
                                            }
                                        </span>
                                    ) : (
                                        <span className='patient_info'>
                                            {' '}
                                            -{' '}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                            {this.props.configData.indexingSettings.teamUsed ===
                            true ? (
                                <div className='col-lg-4 transition-select'>
                                    <span className='patient_label'>TEAM</span>
                                    <br />
                                    {this.state.rowDetails.team !==
                                    undefined ? (
                                        <span className='patient_info'>
                                            {this.state.rowDetails.team.value}
                                        </span>
                                    ) : (
                                        <span className='patient_info'>
                                            {' '}
                                            -{' '}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-lg-4 transition-select'>
                                <span className='patient_label'>
                                    REFERRAL DATE
                                </span>
                                <br />
                                {this.state.rowDetails.referral_date !==
                                undefined ? (
                                    <span className='patient_info'>
                                        {' '}
                                        {moment(
                                            new Date(
                                                this.state.rowDetails.referral_date.value
                                            )
                                        ).format('MM/DD/y')}
                                    </span>
                                ) : (
                                    <span className='patient_info'> - </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {!this.state.rowDetails.hasOwnProperty('signed_date') &&
                !this.state.rowDetails.hasOwnProperty(
                    'date_of_birth_validation'
                ) ? null : (
                    <div className='form-group patient_detail row'>
                        <div className='col-lg-6'>
                            <span className='patient_label'>
                                DATE OF BIRTH VALIDATION
                            </span>
                            <br />
                            {this.state.rowDetails.date_of_birth_validation !==
                                undefined &&
                            this.state.rowDetails.date_of_birth_validation !==
                                '' ? (
                                <span className='patient_info'>
                                    {formatDate(
                                        this.state.rowDetails
                                            .date_of_birth_validation.value,
                                        'MM/DD/YYYY'
                                    )}
                                </span>
                            ) : (
                                <span className='patient_info'> - </span>
                            )}
                        </div>
                        <div className='col-lg-6 transition-select'>
                            <span className='patient_label'>SIGNED DATE</span>
                            <br />
                            {this.state.rowDetails.signed_date !== undefined ? (
                                <span className='patient_info'>
                                    {formatDate(
                                        this.state.rowDetails.signed_date.value,
                                        'MM/DD/YYYY'
                                    )}
                                </span>
                            ) : (
                                <span className='patient_info'> - </span>
                            )}
                        </div>
                    </div>
                )}

                <div className='form-group patient_detail row'>
                    {this.state.rowDetails.hasOwnProperty('attachment_type') ? (
                        <div className='col-lg-6 transition-select'>
                            <span className='patient_label'>
                                {this.props.configData.indexingSettings
                                    .attachmentTypeLabel
                                    ? this.props.configData.indexingSettings.attachmentTypeLabel.toUpperCase()
                                    : 'ATTACHMENT TYPE'}
                            </span>
                            <br />
                            {this.state.rowDetails.attachment_type !==
                            undefined ? (
                                <span className='patient_info'>
                                    {
                                        this.state.rowDetails.attachment_type
                                            .value
                                    }
                                </span>
                            ) : (
                                <span className='patient_info'> - </span>
                            )}
                        </div>
                    ) : null}
                    {this.props.configData.indexingSettings.locationUsed ===
                        true &&
                    this.state.rowDetails.hasOwnProperty(
                        'attachment_location'
                    ) ? (
                        <div className='col-lg-6 transition-select'>
                            <span className='patient_label'>
                                {this.props.configData.indexingSettings
                                    .locationLabel
                                    ? this.props.configData.indexingSettings.locationLabel.toUpperCase()
                                    : 'ATTACHMENT LOCATION'}
                            </span>
                            <br />
                            {this.state.rowDetails.attachment_location !==
                            undefined ? (
                                <span className='patient_info'>
                                    {
                                        this.state.rowDetails
                                            .attachment_location.value
                                    }
                                </span>
                            ) : (
                                <span className='patient_info'> - </span>
                            )}
                        </div>
                    ) : null}
                    {this.state.rowValues.select_an_episode !== '' &&
                    this.state.rowValues.select_an_episode !== undefined &&
                    this.state.rowValues.select_an_episode !== null &&
                    (this.state.rowDetails?.attachment_location?.value ===
                        'EPISODE' ||
                        (this.state.rowDetails?.attachment_location?.value
                            ?.toLowerCase()
                            .includes('episode') &&
                            this.state.rowDetails?.attachment_location?.value
                                ?.toLowerCase()
                                .includes('visit'))) ? (
                        <div className='col-lg-6 transition-select'>
                            <span className='patient_label'>EPISODE</span>
                            <br />
                            {this.state.rowDetails.hasOwnProperty(
                                'select_an_episode'
                            ) &&
                            this.state.rowDetails.select_an_episode !==
                                undefined &&
                            this.state.rowDetails.select_an_episode !== '' ? (
                                <span className='patient_info'>
                                    {
                                        this.state.rowDetails.select_an_episode
                                            .value
                                    }
                                </span>
                            ) : (
                                <span className='patient_info'> - </span>
                            )}
                        </div>
                    ) : null}

                    {!this.state.rowDetails.hasOwnProperty(
                        'visit_id'
                    ) ? null : (
                        <div className='col-lg-6 transition-select'>
                            <span className='patient_label'>EPISODE VISIT</span>
                            <br />
                            {this.state.rowDetails.visit_id !== undefined &&
                            this.state.episodeVisitData !== undefined ? (
                                <span className='patient_info'>
                                    {(() => {
                                        return this.state.episodeVisitData.find(
                                            (item) =>
                                                item.visit_id ===
                                                this.state.rowDetails.visit_id
                                                    .value
                                        )?.visit_label;
                                    })()}
                                </span>
                            ) : (
                                <span className='patient_info'> - </span>
                            )}
                        </div>
                    )}
                </div>

                {this.state.rowValues.select_an_episode !== '' &&
                this.state.rowValues.select_an_episode !== undefined &&
                this.state.rowValues.select_an_episode !== null &&
                (this.state.rowDetails?.attachment_location?.value ===
                    'EPISODE' ||
                    (this.state.rowDetails?.attachment_location?.value
                        ?.toLowerCase()
                        .includes('episode') &&
                        this.state.rowDetails?.attachment_location?.value
                            ?.toLowerCase()
                            .includes('visit'))) ? (
                    <div
                        className='patient_detail edit-detail-bg'
                        style={{
                            backgroundColor: '#F0F7FB',
                            marginTop: '20px',
                        }}
                    >
                        <div className='form-group row'>
                            <div className='col-lg-4 transition-select'>
                                <span className='patient_label'>
                                    EPISODE STATUS
                                </span>
                                <br />
                                {this.state.rowDetails.hasOwnProperty(
                                    'episode_status'
                                ) &&
                                this.state.rowDetails.episode_status !==
                                    undefined &&
                                this.state.rowDetails.episode_status !== '' ? (
                                    <span className='patient_info'>
                                        {
                                            this.state.rowDetails.episode_status
                                                .value
                                        }
                                    </span>
                                ) : (
                                    <span className='patient_info'> - </span>
                                )}
                            </div>
                            <div className='col-lg-4 transition-select'>
                                <span className='patient_label'>
                                    START DATE
                                </span>
                                <br />
                                {this.state.rowDetails.hasOwnProperty(
                                    'episode_start'
                                ) &&
                                this.state.rowDetails.episode_start !==
                                    undefined &&
                                this.state.rowDetails.episode_start !== '' ? (
                                    <span className='patient_info'>
                                        {formatDate(
                                            this.state.rowDetails.episode_start
                                                .value,
                                            'MM/DD/YYYY'
                                        )}
                                    </span>
                                ) : (
                                    <span className='patient_info'> - </span>
                                )}
                            </div>
                            <div className='col-lg-4 transition-select'>
                                <span className='patient_label'>END DATE</span>
                                <br />
                                {this.state.rowDetails.hasOwnProperty(
                                    'episode_end'
                                ) &&
                                this.state.rowDetails.episode_end !==
                                    undefined &&
                                this.state.rowDetails.episode_end !== '' ? (
                                    <span className='patient_info'>
                                        {formatDate(
                                            this.state.rowDetails.episode_end
                                                .value,
                                            'MM/DD/YYYY'
                                        )}
                                    </span>
                                ) : (
                                    <span className='patient_info'> - </span>
                                )}
                            </div>
                        </div>

                        <div
                            style={{ display: 'flex' }}
                            className='form-group row mb-0'
                        >
                            <div className='col-lg-4 transition-select'>
                                <span className='patient_label'>BRANCH</span>
                                <br />
                                {this.state.rowDetails.hasOwnProperty(
                                    'branch'
                                ) &&
                                this.state.rowDetails.branch !== undefined &&
                                this.state.rowDetails.branch !== '' ? (
                                    <span className='patient_info'>
                                        {this.state.rowDetails.branch.value}
                                    </span>
                                ) : (
                                    <span className='patient_info'> - </span>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}

                {!this.state.rowDetails.hasOwnProperty('comments') ? null : (
                    <div className='form-group patient_detail row'>
                        <div className='col-lg-12'>
                            <span className='patient_label'>COMMENTS</span>
                            <br />
                            {this.state.rowDetails.comments !== undefined ? (
                                <span
                                    className='patient_info'
                                    id='view_comments2'
                                    dangerouslySetInnerHTML={loadHTMLData(
                                        this.state.rowDetails.comments.value
                                    )}
                                ></span>
                            ) : (
                                <span
                                    className='patient_info'
                                    id='view_comments2'
                                >
                                    {' '}
                                    -{' '}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    editDetailPage,
};
const mapStateToProps = (state, ownProps) => {
    return {
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewDetails));
