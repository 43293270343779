/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { withRouter, useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
//import SVG from "react-inlinesvg";
import {
    getConfig,
    GetCounter,
    checkIsActive,
    constructMenu,
    toAbsoluteUrl,
    getdefaultLinkFromMenu,
} from '../../../../_helpers';
import { formatNameWithSpaceTohipen } from '../../../../../app/components/commonfunction';
import { useSelector, useDispatch } from 'react-redux';
import { deletedPage } from '../../../../../app/pages/_redux/actions/searchActions';
import { getConfigData } from '../../../../../app/pages/_redux/actions/configActions';



// import { getListaction, updateOutboxState } from '../../../../../app/pages/_redux/actions/outboxActions';
export function ReportMenu({ layoutProps }) {
    const location = useLocation();
    const CounterData = GetCounter();
    const history = useHistory();
    // START: Worked by vijay for adding sample data for menu section on 01-07-2021
    const configData = getConfig();

    //const menuItems	=	(configData.length > 0) ? configData[0].menuItems : [];
    const menuItems = configData.length > 0 ? configData[0].menuItems : [];
    var customSearchQueriesArray =
        configData.length > 0 ? configData[0].customSearchQueries : [];
    var reportsArray = configData.length > 0 ? configData[0].reports : [];
    const reportsCategory = constructMenu(reportsArray, menuItems);
    const getdefaultLink = getdefaultLinkFromMenu(reportsArray, menuItems);
    const [filterText, setFilterText] = useState('');
    const [filter, setFilter] = useState('');
    const [customSearchQueries, setCustomSearchQueries] = useState(
        customSearchQueriesArray
    );
    const [reports, setReports] = useState(reportsArray);
    const [menuRefresh, setMenuRefresh] = useState(true);
    const customMenuArray = localStorage.getItem('customMenuArray')?.split(',');
    const reduxStore = useSelector((state) => state);
    const configurationData = useSelector((state) => state.configReducerPersist.configData)
    let dispatch = useDispatch();

    React.useEffect(() => {
        // if (document.body.classList.contains('doc-small-view')) {
        //     setMenuRefresh(false);
        // }
    }, []);

    const current_path = location.pathname;
    const path_arr = current_path.split('/');
    var current_menu = path_arr[1];
    var current_menu_cat = '';
    if (path_arr.length >= 2) {
        current_menu_cat = path_arr[2];
    }

    var filter_tex = '';
    const handleInputOnChangeReport = async (filter, event) => {
        var reports = configData.length > 0 ? configData[0].reports : [];
        filter_tex = event.target.value;
        if (filter_tex !== '') {
            setFilter(filter_tex);
            filter_tex = filter_tex.toLowerCase();
            let filterResult = [];
            for (let i = 0; i < reports.length; i++) {
                if (reports[i].name.toLowerCase().includes(filter_tex)) {
					reports[i].originalname = reports[i].name;
                    reports[i].name = reports[i].name.replace(
                        new RegExp(filter_tex, 'gi'),
                        '<span class="highlight">$&</span>'
                    );

                    filterResult.push(reports[i]);
                }
            }
            var reports = filterResult;
            setReports(filterResult);
            //menu-item-submenu
            const boxes = document.querySelectorAll('.menu-item-submenu');

            for (const box of boxes) {
                box.classList.add(
                    'menu-item-submenu',
                    'menu-item',
                    'menu-item-open'
                );
            }
            // document.querySelectorAll('.menu-submenu').style.display = "block";
        } else {
            setFilter(filter_tex);
            setReports(reports);
        }
    };

    var filter_text = '';

    
    

    

    // END: Worked by vijay for adding sample data for menu section on 01-07-2021
    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const { pathname } = location;
        return pathname === '/';
    };

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
            : '';
    };

    const getMenuItemActiveCustom = (cat) => {
        if (formatNameWithSpaceTohipen(cat) === current_menu_cat) {
            return 'menu-item-active menu-item-open';
        }
    };

    const getMenuItemActiveDefault = (cateeCount) => {
        if (current_menu === 'reports' && cateeCount === 1) {
            return 'menu-item-active menu-item-open';
        }
    };

    const getMenuItemDisplay = (itemcat) => {
        var display = 'block';
        var exists = 0;
        {
            reports.map((item, index) => {
                var relatedToMenus = item.relatedToMenus;
                if (item.relatedToMenus.length === 0) {
                    relatedToMenus = customMenuArray;
                }
                let isFounded = relatedToMenus.some((ai) =>
                    customMenuArray.includes(String(ai))
                );

                if (
                    isFounded &&
                    String(formatNameWithSpaceTohipen(item.category)) ===
                        String(formatNameWithSpaceTohipen(itemcat))
                ) {
                    exists++;
                }
            });
            if (exists === 0) {
                display = 'none';
            } else {
                display = 'block';
            }
            return display;
        }
    };

    const doCollapse = async () => {
        await document.body.classList.remove('aside-minimize');
        // await document.body.classList.remove('doc-small-view');

        // element.style = '';
        setMenuRefresh(false);
        setTimeout(() => {
            setMenuRefresh(true);
        }, 500);
    };
    const removeStyle = async (path) => {
        var element = document.getElementsByClassName('hide-small-view')[0];
        await dispatch(
            deletedPage({
                filterTxt_report: '',
                hideSmallView: false,
                loadInitially: true,
            })
        );
    };
    
    const getDefaultUrl = (itemcat) => {
        var linking = '';
        {
            var t = 0;
            reports.map((item, index) => {
                if (
                    t === 0 &&
                    String(formatNameWithSpaceTohipen(item.category)) ===
                        String(formatNameWithSpaceTohipen(itemcat))
                ) {
                    linking =
                        '/reports/' +
                        formatNameWithSpaceTohipen(item.category) +
                        '/' +
                        formatNameWithSpaceTohipen(item.name) +
                        '/' +
                        item.reportId;
                    t++;
                    return linking;
                }
            });
        }

        return linking;
    };

    return (
        <div
            id='kt_aside'
            className='aside aside-left rounded-lg   aside-fixed d-flex flex-column flex-row-auto'
            key={'aside-main-report'}
            /* style={{ height: window.innerHeight - 160 }}*/
        >
            <div
                id='kt_aside_menu_wrapper'
                className='aside-menu-wrapper flex-column-fluid'
            >
                <Scrollbars
                    id='kt_aside_menu'
                    data-menu-vertical='1'
                    className='aside-menu '
                >
                    <div className='report-left-top'>
                        {!document.body.classList.contains('aside-minimize') &&
                        menuRefresh === true ? (
                            <div className='filter'>
                                <input
                                    type='text'
                                    name='filter_tex'
                                    placeholder='Filter'
                                    className='form-control'
                                    onChange={(event) => {
                                        handleInputOnChangeReport(
                                            filter,
                                            event
                                        );
                                    }}
                                    value={filter}
                                />
                                <i className='icon-md fab fa-sistrix' />
                            </div>
                        ) : (
                            <div className='filter'>
                                <input className='form-control' />
                                <i
                                    className='fa-regular fa-chevrons-right'
                                    onClick={() => {
                                        doCollapse();
                                    }}
                                ></i>
                            </div>
                        )}
                    </div>
                    {menuItems && menuItems.length > 0
                        ? menuItems.map((data, key) => {
                              if (
                                  data.menuName.toLowerCase() === current_menu
                              ) {
                                  let catee = 0;
                                  return (
                                      <React.Fragment key={key}>
                                          {/* {!document.body.classList.contains(
                                              'doc-small-view'
                                          ) && menuRefresh === true ? ( */}
                                          {reports.length > 0 ? (
                                              <ul
                                                  className='menu-nav p-0'
                                                  key={'aside-main-' + key}
                                              >
                                                  {reportsCategory.map(
                                                      (itemcat, keyidx) => {
                                                          catee++;
                                                          return (
                                                              <React.Fragment
                                                                  key={
                                                                      'aside-frag-' +
                                                                      itemcat
                                                                  }
                                                              >
                                                                  <li
                                                                      /* className={`menu-item menu-item-submenu	
																		${getMenuItemActiveCustom(itemcat)} 
																		${getMenuItemActiveDefault(catee)}`}*/
                                                                      // aria-haspopup='true'
                                                                      style={{
                                                                          display: `${getMenuItemDisplay(
                                                                              itemcat
                                                                          )}`,
                                                                      }}
                                                                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                                          '/' +
                                                                              current_menu +
                                                                              '/' +
                                                                              formatNameWithSpaceTohipen(
                                                                                  itemcat
                                                                              ),
                                                                          true
                                                                      )}`}
                                                                      data-menu-toggle='hover'
                                                                      key={
                                                                          keyidx
                                                                      }
                                                                  >
                                                                      <NavLink
                                                                          className={
                                                                              'menu-link menu-toggle'
                                                                          }
                                                                          title={
                                                                              itemcat
                                                                          }
                                                                          id={
                                                                              'menu_search_' +
                                                                              formatNameWithSpaceTohipen(
                                                                                  itemcat
                                                                              )
                                                                          }
                                                                          to={`/reports/${formatNameWithSpaceTohipen(
                                                                                  itemcat
                                                                              )}`}

                                                                          /*to={
																			  '/' +
																			  current_menu +
																			  '/' +
																			  formatNameWithSpaceTohipen(
																				  itemcat
																			  ) 
																		  }*/
                                                                      >
                                                                          <span className='menu-text'>
                                                                              {
                                                                                  itemcat
                                                                              } 
                                                                          </span>
                                                                          <i className='menu-arrow' />
                                                                      </NavLink>
                                                                      <div
                                                                          className='menu-submenu '
                                                                          key={
                                                                              'item_' +
                                                                              itemcat
                                                                          }
                                                                      >
                                                                          <i className='menu-arrow' />
                                                                          <ul className='menu-subnav'>
                                                                              <li
                                                                                  className='menu-item  menu-item-parent'
                                                                                  key={
                                                                                      itemcat
                                                                                  }
                                                                                  // aria-haspopup='true'
                                                                              >
                                                                                  <span className='menu-link'>
                                                                                      <span className='menu-text'>
                                                                                          {
                                                                                              itemcat
                                                                                          }
                                                                                      </span>
                                                                                  </span>
                                                                              </li>

                                                                              {reports.map(
                                                                                  (
                                                                                      item,
                                                                                      index
                                                                                  ) => {
                                                                                      var relatedToMenus =
                                                                                          item.relatedToMenus;
																					var linkBuild = formatNameWithSpaceTohipen(
                                                                                                                  item.name
                                                                                                              );
																					if(item.originalname !== undefined && item.name !== '' ){
																						linkBuild = formatNameWithSpaceTohipen(
                                                                                                                  item.originalname
                                                                                                              );
																					}																						
                                                                                      if (
                                                                                          item
                                                                                              .relatedToMenus
                                                                                              .length ===
                                                                                          0
                                                                                      ) {
                                                                                          relatedToMenus =
                                                                                              customMenuArray;
                                                                                      }

                                                                                      let isFounded =
                                                                                          relatedToMenus.some(
                                                                                              (
                                                                                                  ai
                                                                                              ) =>
                                                                                                  customMenuArray.includes(
                                                                                                      String(
                                                                                                          ai
                                                                                                      )
                                                                                                  )
                                                                                          );
                                                                                      if (
                                                                                          isFounded &&
                                                                                          data.reports.includes(
                                                                                              item.reportId
                                                                                          ) &&
                                                                                          String(
                                                                                              item.category
                                                                                          ) ===
                                                                                              String(
                                                                                                  itemcat
                                                                                              )
                                                                                      )
																						
																					  
																					  {
                                                                                          return (
                                                                                              <React.Fragment
                                                                                                  key={
                                                                                                      'aside-frag-' +
                                                                                                      index
                                                                                                  }
                                                                                              >
                                                                                                  <li
                                                                                                     className = {path_arr.length >= 3 &&  String(path_arr[4]) === String(item.reportId) ?
                                                                                                        'menu-item active' :
                                                                                                        'menu-item'
                                                                                                    } 
                                                                                                      //aria-haspopup='true'
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                  > 
                                                                                                      <NavLink
                                                                                                          /* className={`menu-link ${getMenuItemActive(
                                                                                                              '/reports/' +
                                                                                                                  item.reportId
                                                                                                          )}`} */
                                                                                                          className = {'menu-link'}
                                                                                                          to={
                                                                                                              '/' +
                                                                                                              current_menu +
                                                                                                              '/' +
                                                                                                              formatNameWithSpaceTohipen(
                                                                                                                  item.category
                                                                                                              ) +
                                                                                                              '/' + 
                                                                                                              linkBuild +
                                                                                                              '/' +
                                                                                                              item.reportId
                                                                                                          }
                                                                                                          onClick={() => {
                                                                                                           
                                                                                                            removeStyle();
                                                                                                          }}
                                                                                                      >
                                                                                                          {/*<span className='menu-text'>
																											  {
																												  item.name
																											  }
																									  </span>*/}
                                                                                                          <div
                                                                                                              className='menu-text'
                                                                                                              dangerouslySetInnerHTML={{
                                                                                                                  __html: item.name,
                                                                                                              }}
                                                                                                          />
                                                                                                      </NavLink>
                                                                                                  </li>
                                                                                              </React.Fragment>
                                                                                          );
                                                                                      }
                                                                                  }
                                                                              )}
                                                                          </ul>
                                                                      </div>
                                                                  </li>
                                                              </React.Fragment>
                                                          );
                                                      }
                                                  )}
                                              </ul>
                                          ) : (
                                              <div className='no-record-menu'>
                                                  No Results Found
                                              </div>
                                          )}
                                      </React.Fragment>
                                  );
                              }
                          })
                        : null}
                </Scrollbars>
            </div>
        </div>
    );
}


