import { Redirect, Route } from 'react-router-dom';
import Login from '../../modules/Auth/pages/Login';
import { allStorage, showErrorToast } from '../../components/commonfunction';

export const API_URL = process.env.REACT_APP_EXTERNAL_API_URL;

var allFields = '';

export const GetStore = (allField, condition = true) => {
    if (condition) {
        allFields = allField;
    }
};

export const InboxData = (params) => {
    return fetch('/inbox.json').then((response) => response.json());
};

const header = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
    },
};

// Function to get document owners

/*export function getDocumentOwners(processId, statusId) {
    fetch(`${API_URL}/documentOwnersasasa/${processId}/${statusId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    }).then((response) => {
        // status = response.status;

        return response.json();
    });
    //return fetch;
}*/
//commented by bargavi which is not in use

// Function to add document owners { ownerId: string, docIds: array }
export function addDocumentOwners(params) {
    const data = {
        docIds: params.docIds,
    };
    if (String(params.fields).trim() !== '') {
        data.fields = [{ fieldId: params.fieldId, value: params.owner_id }];
    } else {
        data.fields = [];
    }
    return fetch(
        API_URL +
            '/documentAction/' +
            params.processId +
            '/' +
            params.statusId +
            '/' +
            params.actionId,
        {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + localStorage.getItem('WV-Token'),
            },
        }
    )
        .then((response) => {
            if (response.status === 401 || response.status === 400) {
                if (params.docIds.length === 1) {
                    showErrorToast('Document is locked');
                } else {
                    showErrorToast('Some Documents are Locked');
                }
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

// Function to add document tags { docIds: array, fields: array }
export function addDocumentTags(docIds, fields) {
    const data = {
        docIds,
        fieldUpdates: fields,
    };

    return fetch(`${API_URL}/documentMetadata`, {
        body: JSON.stringify(data),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

//Function for update user details
export function updateUserDetails(userIds, fields) {
    return fetch(`${API_URL}/user/${userIds}`, {
        body: JSON.stringify(fields),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

//Function for add user details
export function addUserDetails(fields) {
    return fetch(`${API_URL}/user/`, {
        body: JSON.stringify(fields),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

//Function for group array data
export function allGroupData() {
    return fetch(`${API_URL}/userGroups`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}
export function submitDetailApi1(params) {
    const data = {
        ...params,
    };

    return fetch(`${API_URL}/documentMetadata`, {
        //return fetch(`${API_URL}/documentAction`, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

export function submitDetailApi(params, processId, statusId, actionId) {
    const data = {
        ...params,
    };
    // if (String(params.fields).trim() !== '') {
    //     data.fields = [{ fieldId: params.fieldId, value: params.owner_id }];
    // } else {
    //     data.fields = [];
    // }
    return fetch(
        API_URL +
            '/documentAction/' +
            processId +
            '/' +
            statusId +
            '/' +
            actionId,
        {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + localStorage.getItem('WV-Token'),
            },
        }
    );
}

// Function to get status count to update count in left nav
export function getProcessStatusCount(processId, statusId) {
    const result = [
        {
            processId,
            statusId,
            count: 2,
        },
    ];
    if (processId !== undefined && statusId !== undefined) {
        return fetch(`${API_URL}/counts/${processId}/${statusId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        }).then((response) => result);
    }
}

// Function to get docs listing based in menu selection
export function getDocumentsListing(processId, statusId) {
    return fetch(`${API_URL}/documents/${processId}/${statusId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

// Function to get drop down data based on docs
export function GetDropdownData(id) {
    if (
        allFields.hasOwnProperty('allFieldID') &&
        allFields.allFieldID[id].hasDropdown === false
    ) {
        return new Promise(() => {
            return 'No ddl';
        });
    }

    if (id === null || id === undefined) {
        throw Error('Id should not be null');
    }
    // const result = [
    //     {
    //         fieldId: id,
    //         name: 'tag',
    //         values: ['tags'],
    //     },
    // ];

    return fetch(`${API_URL}/dropdownData/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                // <Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getDropdownDatawithFiled(searchTxt) {
    return fetch(`${API_URL}/dropdownData/${searchTxt}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                // <Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}
// Function to get color pallete
export function getColorCodes() {
    const data = [
        '#ECB445',
        '#8B36BF',
        '#00B1BC',
        '#ffbe0b',
        '#fb5607',
        '#ff006e',
        '#8338ec',
        '#3a86ff',
        '#003049',
        '#d62828',
        '#f77f00',
        '#283618',
        '#bc6c25',
        '#1b4332',
        '#1a759f',
    ];
    return data;
}

export function deleteDocument(params) {
    return fetch(
        `${API_URL}/documentAction/${params.processId}/${params.statusId}/${params.actionId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            body: JSON.stringify(params),
        }
    );
}
export function deleteDocumentApi(params) {
    return fetch(
        `${API_URL}/documentAction/${params.processId}/${params.statusId}/${params.actionId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            body: JSON.stringify(params),
        }
    );
}

export function deleteDocumentInUpload(docId) {
    return fetch(`${API_URL}/documentAction/${docId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}
export function getPageTitle(params) {
    let config = localStorage.getItem('configData');
    const pageTitleArray = [];
    if (config !== '' && config !== null) {
        config = JSON.parse(config);

        const { menuItems } = config;

        if (menuItems && menuItems.length > 0) {
            menuItems.forEach((data, key) => {
                data.processes.forEach((value, index) => {
                    const process_key = value.processId;
                    if (value.statuses && value.statuses.length > 0) {
                        value.statuses.forEach((val, ind) => {
                            const status_key = val.statusId;
                            const key_combo = `${process_key}_${status_key}`;
                            pageTitleArray[key_combo] = val.name;
                        });
                    }
                });
            });
        }
    }
    const menu_id = `${params.processId}_${params.statusId}`;
    if (pageTitleArray[menu_id]) return pageTitleArray[menu_id];
    return 'Documents';
}
export function addDocumentNotes(docIds, note, processId, statusId) {
    const data = {
        docIds,
        note,
    };

    return fetch(`${API_URL}/documentNote/${processId}/${statusId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(data),
    });
}

export function addGlobalDocumentNotes(docIds, note) {
    const data = {
        docIds,
        note,
    };

    return fetch(`${API_URL}/globalDocumentNote`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(data),
    });
}

export function viewDocumentAllNotes(docId) {
    // const data = {
    //     docId,
    // };
    return fetch(`${API_URL}/documentNotes/${docId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                // <Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getConfigurationData() {
    let config = localStorage.getItem('configData');
    const configData = {};
    if (config !== '' && config !== null) {
        config = JSON.parse(config);
        for (const props in config) {
            if (Array.isArray(config[props])) {
                config[props].forEach((data) => {
                    configData[data.name] = data;
                });
            }
        }
    }
    return configData;
}

export function addTransition(
    docIds,
    actionId,
    processId,
    statusId,
    fields,
    statusArr = {}
) {
    const data = {
        docIds,
        actionId,
        /* "newStatus" : {
            "processId" : processId,
            "statusId"	: statusId
        } */
        newStatus: statusArr,
    };

    if (fields !== '' && Array.isArray(fields)) {
        data.fields = fields;
    } else if (fields !== '') {
        data.fields = [fields];
    } else {
        data.fields = Array.isArray(fields) ? fields : [fields];
    }

    return fetch(
        `${API_URL}/documentAction/${processId}/${statusId}/${actionId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            body: JSON.stringify(data),
        }
    )
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                // <Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                const responseData = response.json();
                return responseData;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function refreshDataTable(params) {
    const { processId, statusId } = params;
    let statusCount;
    const records = fetch(`${API_URL}/documents/${processId}/${statusId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
    if (processId !== undefined && statusId !== undefined) {
        statusCount = fetch(`${API_URL}/counts/${processId}/${statusId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        });
    }
    return Promise.all([records, statusCount]);
}

export function getCount(processId, statusId) {
    // const {processId,statusId} =params;
    if (processId !== undefined && statusId !== undefined) {
        return fetch(`${API_URL}/counts/${processId}/${statusId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            // body:JSON.stringify(data)
        })
            .then((response) => {
                if (response.status === 401 || response.status === 502) {
                    // <Redirect to='/auth/login' />;
                    // clearLocalStorage();
                    // window.location.reload();
                    PostMessageLogout(); // logout
                } else {
                    return response;
                }
            })
            .catch((e) => {
                console.log(`An error has occured while calling the API. ${e}`);
            });
    }
}

//check
/* export function getDocumentMetaWithDocId(params) {
   
    return fetch(`${API_URL}/document/${params.docid}`, header).then((data) =>
        data.json()
    );
} */

export function getDocumentMetaWithDocId(params) {
    return fetch(`${API_URL}/document/${params.docid}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    }).then((data) => data.json());
}
export function getDocumentMetaWithDocIdCustom(params) {
    /*return fetch(`${API_URL}/document/${params.docid}`, header).then((data) =>
        data.json()
    );*/
    return fetch(`${API_URL}/document/${params.docid}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getDocumentImage(docId, pageCount) {
    return fetch(`${API_URL}/documentPage/${docId}/${pageCount}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getStamp() {
    return fetch(`${API_URL}/getStamps`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function downloadDoc(docId) {
    if (docId === undefined) {
        throw new Error('docId is Undefined');
    }
    return fetch(`${API_URL}/documentPDF?docId=${docId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                // this.props.history.push('/logout');
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}
export function downloadDocMultiple(docId) {
    if (docId === undefined) {
        throw new Error('docId is Undefined');
    }
    return fetch(`${API_URL}/documentPDF?${docId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function samplePdf() {
    return fetch('/sample.pdf');
}

export function relatedDoc(recordsetId) {
    return fetch(`${API_URL}/recordset/${recordsetId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getDocumentHistroy(params) {
    return fetch(`${API_URL}/documentHistory/${params.docId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export async function lockDocument(docid) {
    if (docid === undefined) {
        throw new Error('DocId is undefined');
    }
    try {
        const response = await fetch(`${API_URL}/lockDocument/${docid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        });
        if (response.status === 401 || response.status === 502) {
            // <Redirect to='/auth/login' />;
            // clearLocalStorage();
            // window.location.reload();
            PostMessageLogout(); // logout
        } else {
            let lockListIds = JSON.parse(
                localStorage.getItem('documentLocks') || '[]'
            );
            lockListIds.push(docid);
            localStorage.setItem('documentLocks', JSON.stringify(lockListIds));
            return response;
        }
    } catch (e) {
        console.error(`An error has occured while calling the API. ${e}`);
    }
}

export async function unlockDocument(docid) {
    if (docid === undefined) {
        throw new Error('DocId is undefined');
    }
    try {
        const response = await fetch(`${API_URL}/lockDocument/${docid}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        });
        if (response.status === 401 || response.status === 502) {
            //<Redirect to='/auth/login' />;
            // clearLocalStorage();
            // window.location.reload();
            PostMessageLogout(); // logout
        } else {
            let lockListIds = JSON.parse(
                localStorage.getItem('documentLocks') || '[]'
            );
            lockListIds = lockListIds.filter((item) => item !== docid);
            localStorage.setItem('documentLocks', JSON.stringify(lockListIds));
            return response;
        }
    } catch (e) {
        console.error(`An error has occured while calling the API. ${e}`);
    }
}

export function deleteMultipleDocument(docId) {
    if (docId === undefined) {
        throw new Error('DocId is undefined');
    }
    return fetch(`${API_URL}/document/${docId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                // <Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function notesSubmitAction(params) {
    const { notesValue } = params;
    const { docIds } = params;
    if (notesValue === '' || notesValue === undefined) {
        return false;
    }
    const note = {};
    note.noteFieldId = localStorage.getItem('note_field_id');
    note.noteTypeId = localStorage.getItem('note_type_id');
    note.noteValue = notesValue;
    window.scroll({ top: 0, behavior: 'smooth' });
    return addDocumentNotes(docIds, note);
}

export function getUserIp() {
    return fetch('https://geolocation-db.com/json/', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    });
}

export function logout() {
    return fetch(`${API_URL}/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

export async function uploadDocument(params) {
    return fetch(`${API_URL}/document`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(params),
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function updateDocument(params, docId) {
    return fetch(`${API_URL}/document/${docId}`, {
        body: JSON.stringify(params),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function recordSetSearch(searchId, searchText) {
    if (searchText !== undefined) {
        return fetch(`${API_URL}/recordset/${searchId}?${searchText}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                if (response.status === 401 || response.status === 502) {
                    // clearLocalStorage();
                    // window.location.reload();
                    PostMessageLogout(); // logout
                    //return <Redirect to='/auth/login' />;
                } else {
                    return response;
                }
            })
            .catch((e) => {
                console.log(`An error has occured while calling the API. ${e}`);
            });
    } else {
        return fetch(`${API_URL}/recordset/${searchId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                if (response.status === 401 || response.status === 502) {
                    // <Redirect to='/auth/login' />;
                    // clearLocalStorage();
                    // window.location.reload();
                    PostMessageLogout(); // logout
                } else {
                    return response;
                }
            })
            .catch((e) => {
                console.log(`An error has occured while calling the API. ${e}`);
            });
    }
}
//check
export function orderRecordSetSearch(searchId, searchText, options = {}) {
    return fetch(`${API_URL}/recordset/${searchId}?${searchText}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        signal: options?.signal,
    }).then((data) => data.json());
}

export function createNewPatient(params, patientModifiableRecordsetId) {
    return fetch(`${API_URL}/recordset/${patientModifiableRecordsetId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(params),
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function createNewCoordinator(params, RecordsetId) {
    return fetch(`${API_URL}/recordset/${RecordsetId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(params),
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function updateRecordSet(
    params,
    patientModifiableRecordsetId,
    uniqueId
) {
    return fetch(
        `${API_URL}/recordset/${patientModifiableRecordsetId}/${uniqueId}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            body: JSON.stringify(params),
        }
    )
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function deleteRecordSet(
    multipeEditIds,
    modifiableRecordsetId,
    uniqueId
) {
    return fetch(`${API_URL}/recordset/${modifiableRecordsetId}/${uniqueId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(multipeEditIds),
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getSinglePatientRecord(recordsetId) {
    return fetch(`${API_URL}/recordset/3/1675`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401) {
                // <Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function attachToChart(params, fields) {
    return fetch(
        `${API_URL}/documentAction/${params.processId}/${params.statusId}/${params.actionId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            body: JSON.stringify(fields),
        }
    )
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getRelatedDocumentList(searchId, searchText) {
    return fetch(`${API_URL}/customSearch/${searchId}?${searchText}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getCustomSearchList(searchId, searchText) {
    if (searchId === '') {
        return fetch(`${API_URL}/customSearch/${searchText}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                if (response.status === 401 || response.status === 502) {
                    //<Redirect to='/auth/login' />;
                    // clearLocalStorage();
                    // window.location.reload();
                    PostMessageLogout(); // logout
                } else {
                    return response;
                }
            })
            .catch((e) => {
                console.log(`An error has occured while calling the API. ${e}`);
            });
    } else {
        return fetch(`${API_URL}/customSearch/${searchId}?${searchText}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                if (response.status === 401 || response.status === 502) {
                    //<Redirect to='/auth/login' />;
                    // clearLocalStorage();
                    // window.location.reload();
                    PostMessageLogout(); // logout
                } else {
                    return response;
                }
            })
            .catch((e) => {
                console.log(`An error has occured while calling the API. ${e}`);
            });
    }
}
export function getReport(query) {
    return fetch(`${API_URL}/${query}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
                //return <Redirect to='/auth/logout' />;
                //window.location('/auth/logout')
                //<Route path="/auth/login" component={Login} />
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getUsersList() {
    return fetch(`${API_URL}/users`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function getAttachmentLocData(id, search) {
    if (
        allFields.hasOwnProperty('allFieldID') &&
        allFields.allFieldID[id].hasDropdown === false
    ) {
        return new Promise(() => {
            return 'No ddl';
        });
    }

    if (id === null || id === undefined) {
        throw Error('Id should not be null');
    }

    return fetch(`${API_URL}/dropdownData/${id}${search}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function outboxDocumentAction(params) {
    return fetch(
        `${API_URL}/documentAction/${params.processId}/${params.statusId}/${params.actionId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            body: JSON.stringify(params.fields),
        }
    );
}

export function globalDocumentAction(params) {
    return fetch(`${API_URL}/documentGlobalAction/${params.actionId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(params.fields),
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                //<Redirect to='/auth/login' />;
                // clearLocalStorage();
                // window.location.reload();
                PostMessageLogout(); // logout
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function loadThemeJson(domainName) {
    //return fetch(`https://wv-webclientapi-staging.azurewebsites.net/getWhitelabel/medattach3template/${params.actionId}`, {
    return fetch(`${API_URL}/getWhitelabel/${domainName}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            // Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

export function clearLocalStorage() {
    let remember_me = false;
    let username = '';
    let password = '';
    var siteName = localStorage.getItem('SiteName');
    if (
        localStorage.getItem('remember_me') &&
        localStorage.getItem('remember_me') === true
    ) {
        remember_me = localStorage.getItem('remember_me');
        username = localStorage.getItem('username');
        password = localStorage.getItem('password');
    }

    const localStorageData = allStorage();
    const headerStorage = {};
    for (const name in localStorageData) {
        if (
            String(name).includes('header-reorder') ||
            String(name).includes('header-width') ||
            String(name).includes('filter-options') ||
            String(name).includes('gmail_') ||
            String(name).includes('sub_domain_name') ||
            String(name).includes('tagsCurrentWidth') ||
            String(name).includes('searchFilter_') ||
            String(name).includes('grouping_') ||
            String(name).includes('retainShowAllChecked') ||
            String(name).includes('sorting_') ||
            String(name).includes('customFilters_') ||
            String(name).includes('selectedBranch')
        ) {
            headerStorage[name] = localStorageData[name];
        }
    }

    localStorage.clear();
    localStorage.setItem('remember_me', remember_me);
    localStorage.setItem('username', username);
    localStorage.setItem('password', password);
    localStorage.setItem('SiteName', siteName);

    for (const name in headerStorage) {
        localStorage.setItem(name, headerStorage[name]);
    }
}

export function configurationCustomeSearchId(customSearchId) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/configurationWithCustomSearch/${customSearchId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                mode: 'no-cors',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                // Check the response status
                if (response.ok) {
                    // If the response is successful, parse the JSON data
                    return response.json();
                } else {
                    // If the response is not successful, reject the promise with an error message
                    throw new Error('API request failed');
                }
            })
            .then((data) => {
                // Resolve the promise with the retrieved data
                resolve(data);
            })
            .catch((error) => {
                // Reject the promise with the encountered error
                reject(error);
            });
    });
}
export function configurationRecordSetId(recordsetId) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/configurationWithRecordset/${recordsetId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                mode: 'no-cors',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                // Check the response status
                if (response.ok) {
                    // If the response is successful, parse the JSON data
                    return response.json();
                } else {
                    // If the response is not successful, reject the promise with an error message
                    throw new Error('API request failed');
                }
            })
            .then((data) => {
                // Resolve the promise with the retrieved data
                resolve(data);
            })
            .catch((error) => {
                // Reject the promise with the encountered error
                reject(error);
            });
    });
}
export function configurationWithStatus(statusId) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/configurationWithStatus/${statusId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                // Check the response status
                if (response.ok) {
                    // If the response is successful, parse the JSON data
                    return response.json();
                } else {
                    // If the response is not successful, reject the promise with an error message
                    throw new Error('API request failed');
                }
            })
            .then((data) => {
                // Resolve the promise with the retrieved data
                resolve(data);
            })
            .catch((error) => {
                // Reject the promise with the encountered error
                reject(error);
            });
    });
}

export function configurationSqlId(sqlqueryid) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/customsql/${sqlqueryid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                mode: 'no-cors',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                // Check the response status
                if (response.ok) {
                    // If the response is successful, parse the JSON data
                    return response.json();
                } else {
                    // If the response is not successful, reject the promise with an error message
                    throw new Error('API request failed');
                }
            })
            .then((data) => {
                // Resolve the promise with the retrieved data
                resolve(data);
            })
            .catch((error) => {
                // Reject the promise with the encountered error
                reject(error);
            });
    });
}

export function getProcessCount(id) {
    return fetch(`${API_URL}/counts/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

export const PostMessageLogout = () => {
    window.postMessage({
        out: 'Logout',
    });
};

export const PostMessageManualLogout = () => {
    window.postMessage({
        out: 'Logout_Manual',
    });
};
export function getLockDocuments() {
    return fetch(`${API_URL}/documentlocks`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((data) => data.json())
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}
export function deleteDocumentLocks(params) {
    if (params === undefined) {
        throw new Error('DocId is undefined');
    }
    return fetch(`${API_URL}/documentlocks`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(params),
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                PostMessageLogout();
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export async function getTags() {
    try {
        const response = await fetch(`${API_URL}/tag`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        });

        if (response.status === 401 || response.status === 502) {
            PostMessageLogout();
        } else {
            return response;
        }
    } catch (error) {
        console.log(`An error has occurred while calling the API: ${error}`);
    }
}
export async function deleteTagApi(tagId) {
    try {
        const response = await fetch(`${API_URL}/tag/${tagId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        });

        if (response.status === 401 || response.status === 502) {
            PostMessageLogout(); // logout
        } else {
            return response;
        }
    } catch (error) {
        console.log(`An error has occurred while calling the API: ${error}`);
    }
}

export async function addTags(param) {
    try {
        const response = await fetch(`${API_URL}/tag`, {
            body: JSON.stringify(param),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        });

        if (response.status === 401 || response.status === 502) {
            PostMessageLogout();
        } else {
            return response;
        }
    } catch (error) {
        console.log(`An error has occurred while calling the API: ${error}`);
    }
}

export async function addNewPhysician(param, recordsetId) {
    try {
        const response = await fetch(`${API_URL}/recordset/${recordsetId}`, {
            body: JSON.stringify(param),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        });

        if (response.status === 401 || response.status === 502) {
            PostMessageLogout();
        } else {
            return response;
        }
    } catch (error) {
        console.log(`An error has occurred while calling the API: ${error}`);
    }
}

export async function updatePhysician(param, recordsetId, entityId) {
    try {
        const response = await fetch(
            `${API_URL}/recordset/${recordsetId}/${entityId}`,
            {
                body: JSON.stringify(param),
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
                },
            }
        );

        if (response.status === 401 || response.status === 502) {
            PostMessageLogout();
        } else {
            return response;
        }
    } catch (error) {
        console.log(`An error has occurred while calling the API: ${error}`);
    }
}

export async function deletePhysician(recordsetId, entityId) {
    try {
        const response = await fetch(
            `${API_URL}/recordset/${recordsetId}/${entityId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
                },
            }
        );

        if (response.status === 401 || response.status === 502) {
            PostMessageLogout();
        } else {
            return response;
        }
    } catch (error) {
        console.log(`An error has occurred while calling the API: ${error}`);
    }
}

export async function assignSignature(params, RecordsetId) {
    return await fetch(`${API_URL}/recordset/${RecordsetId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
        body: JSON.stringify(params),
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                PostMessageLogout();
            } else {
                return response.json();
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export function unassignSignature(RecordsetId, objectId) {
    return fetch(`${API_URL}/recordset/${RecordsetId}/${objectId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                PostMessageLogout();
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}

export async function uploadPhysiciansCsv(file) {
    try {
        const formData = new FormData();
        formData.append('File', file);

        return fetch(`${API_URL}/api/upload/physicians`, {
            method: 'POST',
            body: formData,
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        })
            .then((response) => {
                if (response.status === 401 || response.status === 502) {
                    PostMessageLogout();
                } else {
                    return response.json();
                }
            })
            .catch((e) => {
                console.log(`An error has occured while calling the API. ${e}`);
            });
    } catch (error) {
        console.log(`An error has occurred while calling the API: ${error}`);
    }
}

export async function validateSession() {
    return fetch(`${API_URL}/validate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    })
        .then((response) => {
            if (response.status === 401 || response.status === 502) {
                PostMessageLogout();
            } else {
                return response;
            }
        })
        .catch((e) => {
            console.log(`An error has occured while calling the API. ${e}`);
        });
}
