import React from 'react';
import { useFocusRef } from './useFocusRef';

export default function ToggleGroupFormatter({
    row,
    childRows,
    groupKey,
    isCellSelected,
    isExpanded,
    toggleGroup,
}) {
    const cellRef = useFocusRef(isCellSelected);
    function handleKeyDown({ key }) {
        if (key === 'Enter') {
            toggleGroup();
        }
    }
    const rowIdName = String(row.id).replace(/\s/g, '').toLowerCase();
    const Count = childRows.length;
    if (Count !== 0 && isExpanded) {
        this.state.tempExpandedGroupIds.add(row.id);
        this.state.expandedGroupIds.add(row.id);
    }
    let arrow = (
        <>
            <i className='fas fa-sort-up' aria-hidden='true' />
        </>
    );
    if (isExpanded) {
        arrow = (
            <>
                <i className='fas fa-sort-down' aria-hidden='true' />
            </>
        );
    }
    return (
        <>
            <span
                id={rowIdName}
                ref={cellRef}
                className='d-flex'
                tabIndex={-1}
                onKeyDown={handleKeyDown}
                title={groupKey}
            >
                {Count > 0 ? arrow : null}
                <span style={{ marginRight: '1em' }}>
                    <b>{groupKey}</b>
                </span>
                {Count !== '' ? (
                    <div className='count_no'>
                        <span>{Count}</span>
                    </div>
                ) : null}
            </span>
            {/*
		Count === 0 ? <span onClick={()=>this.removeGrouping(groupKey,row)}>Remove</span> : null
	*/}
        </>
    );
}
