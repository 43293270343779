export const DEFAULT_OUTBOX_STATE = 'DEFAULT_OUTBOX_STATE';

export const GET_OUTBOX_LIST = 'GET_OUTBOX_LIST';
export const OUTBOX_LIST_SUCCESS = 'OUTBOX_LIST_SUCCESS';
export const OUTBOX_LIST_FAILURE = 'OUTBOX_LIST_FAILURE';

export const SET_SELECTED_ROW = 'SET_SELECTED_ROW';
export const GET_SHARED_PROPS = 'GET_SHARED_PROPS';

export const GET_RULES_JSON = 'GET_RULES_JSON';
export const RULES_JSON_SUCCESS = 'RULES_JSON_SUCCESS';
export const RULES_JSON_FAILURE = 'RULES_JSON_FAILURE';

export const SET_ROW_DATA = 'SET_ROW_DATA';

export const GET_COUNT_DATA = 'GET_COUNT_DATA';

export const SHOW_LOADER = 'SHOW_LOADER';
export const RE_RENDER_OUTBOX = 'RE_RENDER_OUTBOX';
export const HIDE_BIG_VIEW = 'HIDE_BIG_VIEW';
export const SET_MENU_NAME = 'SET_MENU_NAME';
export const SET_PAGE_DATA = 'SET_PAGE_DATA';

export const GET_OWNERS = 'GET_OWNERS';
export const GET_OWNERS_SUCCESS = 'GET_OWNERS_SUCCESS';
export const GET_OWNERS_FAILURE = 'GET_OWNERS_FAILURE';

export const SET_PROCESS_ID = 'SET_PROCESS_ID';
export const SET_STATUS_ID = 'SET_STATUS_ID';
export const SET_CALLING_PAGE = 'SET_CALLING_PAGE';

export const SET_OWNER = 'SET_OWNER';
export const SET_DOC_OWNER = 'SET_DOC_OWNER';

export const UPDATE_OUTBOX_LIST = 'UPDATE_OUTBOX_LIST';

export const UPDATE_OUTBOX_STATE = 'UPDATE_OUTBOX_STATE';
export const CLEAR_OUTBOX_STATE = 'CLEAR_OUTBOX_STATE';

export const SELECTED_ROW_DETAILS = 'SELECTED_ROW_DETAILS';

export const SELECTED_ROW_GRID_DETAILS = 'SELECTED_ROW_GRID_DETAILS';

export const MULTIPLE_EDIT_BASE64 = 'MULTIPLE_EDIT_BASE64';

export const OUTBOX_PAGE = 'OUTBOX_PAGE';

export const OUTBOX_GROUPING = 'OUBOX_GROUPING';

export const INITIAL_OUTBOX_GROUPING = 'INITIAL_OUTBOX_GROUPING';
