import React from 'react';
// import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import TextField from '@material-ui/core/TextField';
// import * as moment from 'moment-timezone';
import DatePicker from 'react-date-picker';
import { SpinnerBlur } from './Spinner';
import {
    formatDate,
    EpisodeSearch,
    BranchIdSearch,
    GetFieldData,
    formatHeaderNameWithOutSpace,
    stringEmptyOrUndefined,
    encodeParameter,
} from '../../../components/commonfunction';
import { getDDLlist } from '../../_redux/actions/configActions';

import states from '../../../../states.json';
import { recordSetSearch, getDropdownDatawithFiled } from '../../api/api';

// https://preview.keenthemes.com/metronic/react/demo1/google-material/inputs/pickers
const initialState = {
    // dob: new Date(),
    dob: '',
    firstName: '',
    lastName: '',
    gender: '',
};

export class DetailCreatePatient extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            createPatientData: {
                patient_first_name: '',
                patient_last_name: '',
                first_name: '',
                last_name: '',
                date_of_birth: '',
                gender: '',
                service_line: '',
                branch: '',
                dob: '',
                patient_address: '',
                patient_city: '',
                patient_state: '',
                patient_zip_code: '',
                patient_phone_: '',
                patient_mobile_phone_: '',
            },
            validateCreate: this.props.validateCreate,
            todaysDate: new Date(),
            patientCreated: false,
        };

        this.sortedStates = Object.entries(states).sort((a, b) => {
            if (a[1] < b[1]) return -1;
            if (a[1] > b[1]) return 1;
            return 0;
        });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    async componentDidMount() {
        this._isMounted = true;

        if (this.props.ddl.branch.length === 1) {
            let event = {
                target: {
                    value: this.props.ddl.branch[0],
                },
            };
            this.handleBranchOnChange('branch', event);
        }
        if (
            this.props.createPatientData !== undefined &&
            this.props.createPatientData !== '' &&
            Object.keys(this.props.createPatientData).length > 0 &&
            (this.props.isUploadPage !== true ||
                this.props.isUploadPage === undefined)
        ) {
            let tempPatientData = { ...this.props.createPatientData };

            if (this.props.createPatientData.hasOwnProperty('patient_state')) {
                const abbreviation = this.props.createPatientData.patient_state;
                const stateName = states[abbreviation];
                const formattedState = `${stateName} (${abbreviation})`;

                await this.setState({
                    selectedState: formattedState,
                });
            }

            if (
                this.props.createPatientData.hasOwnProperty(
                    'patient_mobile_phone_'
                )
            ) {
                tempPatientData.patient_mobile_phone_ = this.formatPhoneNumber(
                    this.props.createPatientData.patient_mobile_phone_
                );
            }

            if (this.props.createPatientData.hasOwnProperty('patient_phone_')) {
                tempPatientData.patient_phone_ = this.formatPhoneNumber(
                    this.props.createPatientData.patient_phone_
                );
            }

            if (this.props.createPatientData.hasOwnProperty('date_of_birth')) {
                tempPatientData.date_of_birth = formatDate(
                    this.props.createPatientData.date_of_birth,
                    'MM/DD/YYYY'
                );

                tempPatientData.dob = new Date(
                    this.props.createPatientData.date_of_birth
                );
            }

            if (
                this.props.createPatientData.hasOwnProperty('patient_name') &&
                !this.props.createPatientData.hasOwnProperty('first_name') &&
                this.props.isUploadPage !== true
            ) {
                const split = this.props.createPatientData.patient_name
                    .trim()
                    .split(',');
                tempPatientData = {
                    ...tempPatientData,
                    first_name: split[1],
                    last_name: split[0],
                    patient_first_name: split[1],
                    patient_last_name: split[0],
                };
            } else if (
                !this.props.createPatientData.hasOwnProperty('patient_name') &&
                this.props.createPatientData.hasOwnProperty(
                    'patient_first_name'
                )
            ) {
                tempPatientData = {
                    ...tempPatientData,
                    first_name: this.props.createPatientData.patient_first_name,
                    last_name: this.props.createPatientData.patient_last_name,
                };
            }

            if (this.props.isUploadPage !== true) {
                await this.setState({ createPatientData: tempPatientData });
                this.props.updateState({ patient_filter: tempPatientData });
            }
        }

        this.setState({ patientCreated: false }, () => {
            this.props.updateState({ patientCreated: false });
        });

        const todaysDate = formatDate(this.state.todaysDate, 'YYYY-MM-DD');
        this.setState({ todaysDate: todaysDate });

        await this.getTeamDropDownData(this.state.createPatientData.branch);
    }

    componentDidUpdate(prevProps) {
        if (this.props.submitCreatePatient === true) {
            this.submitCreateNewPatient();
        }
        if (this.props.validateForUpload === true) {
            this.validateForm();
        }
        // if(this.props.validateCreate ===true ){
        //     this.setState({validateCreate:this.props.validateCreate})
        // }
        // if (this.props.validateForUpload === false &&
        //     this.props.submitCreatePatient === false) {
        //     this.removeValidate();
        // }
    }

    removeValidate = () => {
        if (this.state.validateCreate === true) {
            this.setState({ validateCreate: false });
        }
    };

    async componentWillUnmount() {
        await this.setState({ ...initialState });
        this._isMounted = false;
    }

    validateForm = () => {
        let error = false;
        if (this.props.location.pathname.includes('upload')) {
        } else {
            for (const property in this.state.createPatientData) {
                if (
                    this.state.createPatientData[property] === '' &&
                    (property === 'first_name' ||
                        property === 'last_name' ||
                        property === 'dob' ||
                        property === 'gender' ||
                        property === 'service_line' ||
                        property === 'branch')
                ) {
                    if (error === false) {
                        error = true;
                    }
                    break;
                }
            }
        }
        if (error === true) {
            if (this.state.validateCreate === false) {
                this.setState({ validateCreate: true });
            }
        }
    };

    submitCreateNewPatient = async () => {
        let error = false;

        for (const property in this.state.createPatientData) {
            if (
                this.state.createPatientData[property] === '' &&
                (property === 'first_name' ||
                    property === 'last_name' ||
                    property === 'date_of_birth' ||
                    property === 'gender' ||
                    property === 'service_line' ||
                    property === 'branch')
            ) {
                if (error === false) {
                    error = true;
                }
                break;
            }
        }
        if (
            this.state.createPatientData.hasOwnProperty('first_name') ===
                false ||
            this.state.createPatientData.hasOwnProperty('last_name') ===
                false ||
            this.state.createPatientData.hasOwnProperty('date_of_birth') ===
                false ||
            this.state.createPatientData.hasOwnProperty('gender') === false ||
            this.state.createPatientData.hasOwnProperty('service_line') ===
                false ||
            this.state.createPatientData.hasOwnProperty('branch') === false
        ) {
            if (error === false) {
                error = true;
            }
        }

        if (
            // Validate patient_phone_ if it exists and is not empty
            (this.state.createPatientData.hasOwnProperty('patient_phone_') &&
                !this.validatePhoneNumber(
                    this.state.createPatientData.patient_phone_
                )) ||
            // Validate patient_mobile_phone_ if it exists and is not empty
            (this.state.createPatientData.hasOwnProperty(
                'patient_mobile_phone_'
            ) &&
                !this.validatePhoneNumber(
                    this.state.createPatientData.patient_mobile_phone_
                ))
        ) {
            if (error === false) {
                error = true;
            }
        }

        if (error === true) {
            if (this.state.validateCreate === false) {
                this.setState({ validateCreate: true });
            }
            this.props.resetSubmit();
            return true;
        }
        if (error === false) {
            this.props.resetSubmit();

            this.props.updateState({ patientCreated: true });
            setTimeout(() => {
                this.props.submitDetailForm();
            }, 500);
        }

        // var patientID = generatePatientId();
        // var addPatientID = {
        //     ...this.state.createPatientData,
        //     patientid: patientID,
        //     patient_id: patientID,
        // };

        // await this.setState({ loader: true, createPatientData: addPatientID });
        // var dob;
        // var patientname = '';
        // if (
        //     this.state.createPatientData.hasOwnProperty('first_name') &&
        //     this.state.createPatientData.hasOwnProperty('last_name')
        // ) {
        //     patientname =
        //         this.state.createPatientData.first_name +
        //         ',' +
        //         this.state.createPatientData.last_name;
        // }

        // if (
        //     this.state.createPatientData.hasOwnProperty('first_name') ===
        //         false &&
        //     this.state.createPatientData.hasOwnProperty('last_name')
        // ) {
        //     patientname = this.state.createPatientData.last_name;
        // }

        // if (
        //     this.state.createPatientData.hasOwnProperty('first_name') &&
        //     this.state.createPatientData.hasOwnProperty('last_name') === false
        // ) {
        //     patientname = this.state.createPatientData.first_name;
        // }

        // if (patientname !== '') {
        //     var createPatientData = {
        //         ...this.state.createPatientData,
        //         patient_name: patientname,
        //         patient_first_name: this.state.createPatientData.first_name,
        //         patient_last_name: this.state.createPatientData.last_name,
        //     };
        //     await this.setState({ createPatientData: createPatientData });
        // }

        // var patientFieldIdArray = [];
        // patientFieldIdArray = this.props.patientFieldIdArray;
        // var params = [];
        // var formData = this.state.createPatientData;

        // patientFieldIdArray.map((data) => {
        //     var value = formData[formatNameWithSpaceToUnderscore(data.name)];
        //     if (
        //         (formData[formatNameWithSpaceToUnderscore(data.name)] !==
        //             undefined &&
        //             formData[formatNameWithSpaceToUnderscore(data.name)] !==
        //                 '') ||
        //         formatNameWithSpaceToUnderscore(data.name) === 'agencyid'
        //     ) {
        //         params.push({
        //             fieldId: data.fieldId,
        //             value: value,
        //         });
        //     }
        // });

        // var status;
        // createNewPatient(params, this.props.patientModifiableRecordsetId)
        //     .then((response) => {
        //         status = response.status;
        //         return response.json();
        //     })
        //     .then((data) => {
        //         if (status === 200) {
        //             formData.id = data.uniqueId;
        //             this.setState({ loader: false });
        //             this.setState({ patientCreated: true }, () => {
        //                 this.props.updateState({ patientCreated: true });
        //             });
        //             normalSuccessToast('Patient Created Successfully');
        //             this.props.closeCreatePatient(formData);
        //             if (
        //                 this.props.submittingViaBigView === true ||
        //                 this.props.submittingViaUpload === true
        //             ) {
        //                 this.props.submitDetailForm();
        //             }
        //         }
        //         if (status === 400) {
        //             this.setState({ loader: false });
        //             showErrorToast(data.message);
        //         }
        //     });
    };

    handleSelectOnChange = (state, event) => {
        this.setState({ [state]: event.target.value });

        const createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        this.setState({ createPatientData: createPatientData });
        this.props.updateState({
            patient_filter: createPatientData,
            formData: {
                ...this.props.formData,
                ...createPatientData,
            },
        });
    };

    formatPhoneNumber = (value) => {
        // the mask for phone numbers is "1-XXX-XXX-XXXX"
        const originalValue = value;
        value = value.replace(/\D/g, '');

        if (value === '' || value === undefined) {
            return '';
        }

        if (originalValue.startsWith('1-')) {
            value = value.slice(1);
        }

        if (value.length > 10) {
            value = value.slice(0, 10);
        }

        if (value.length > 6) {
            value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(
                6
            )}`;
        } else if (value.length > 3) {
            value = `${value.slice(0, 3)}-${value.slice(3)}`;
        } else if (value.length > 0) {
            value = `${value}`;
        }

        return value ? `1-${value}` : '';
    };

    handlePhoneChange = (state, event) => {
        const formattedValue = this.formatPhoneNumber(event.target.value);
        this.handleInputOnChange(state, { target: { value: formattedValue } });
    };

    handleInputOnChange = async (state, event) => {
        this.setState({ [state]: event.target.value });
        const createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        if (
            createPatientData !== undefined &&
            createPatientData.first_name !== undefined &&
            createPatientData.last_name !== undefined
        ) {
            createPatientData.patient_first_name =
                createPatientData.first_name.trim();
            createPatientData.patient_last_name =
                createPatientData.last_name.trim();
            createPatientData.patient_name = `${createPatientData.last_name},${createPatientData.first_name}`;
        }

        await this.setState({ createPatientData: createPatientData });
        await this.props.updateState({
            patient_filter: createPatientData,
            formData: {
                ...this.props.formData,
                ...createPatientData,
            },
        });
    };

    handleStateChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue) {
            const [stateName, stateAbbreviation] = selectedValue.split(' (');

            const createPatientData = {
                ...this.state.createPatientData,
                patient_state: stateAbbreviation
                    ? stateAbbreviation.slice(0, -1)
                    : '', // Remove the closing parenthesis
            };

            this.setState({
                createPatientData,
                selectedState: selectedValue,
            });

            this.props.updateState({
                patient_filter: createPatientData,
                formData: {
                    ...this.props.formData,
                    ...createPatientData,
                },
            });
        } else {
            // Reset state if default option is selected
            this.setState({
                createPatientData: {
                    ...this.state.createPatientData,
                    patient_state: '',
                },
                selectedState: '',
            });

            this.props.updateState({
                patient_filter: {
                    ...this.state.createPatientData,
                    patient_state: '',
                },
                formData: {
                    ...this.props.formData,
                    patient_state: '',
                },
            });
        }
    };

    setDob = (date) => {
        this.setState({
            dob: date,
        });
        const dob = formatDate(date, 'MM/DD/YYYY');
        const createPatientData = {
            ...this.state.createPatientData,
            date_of_birth: dob,
            dob: date,
        };
        this.setState({ createPatientData: createPatientData });
        this.props.updateState({
            patient_filter: createPatientData,
            formData: {
                ...this.props.formData,
                ...createPatientData,
            },
        });
    };
    getTeamDropDownData = async (branch, fromName) => {
        const fieldData = GetFieldData({ configData: this.props.configData });
        let searchText = `${fieldData.team}?fieldSearch=${
            fieldData.branch
        }=${encodeParameter(branch)}`;
        await getDropdownDatawithFiled(searchText)
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                if (data.values) {
                    await this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                    if (fromName === 'OnChange') {
                        let event = { target: { value: '' } };
                        this.props.handleFormOnChange('team', event);
                    }
                }
            });
    };

    handleBranchOnChange = async (state, event, fromName = '') => {
        this.getTeamDropDownData(event.target.value, fromName);
        this.setState({ [state]: event.target.value });
        const createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };

        this.setState({ createPatientData: createPatientData });

        const episodeId =
            this.props.allRecordSetID.branch_data.fields_obj.branch.fieldId;

        const searchText = `fieldSearch=${episodeId}=${event.target.value}`;
        let status;
        const result = await recordSetSearch(
            this.props.allRecordSetID.branch_data.recordsetId,
            searchText
        );
        var data = null;

        if (result.status === 200) {
            data = await result.json();
        } else {
            throw new Error('Error in recordSetSearch');
            return;
        }

        if (data) {
            const searchResult = await EpisodeSearch(data);
            let branch_id = await BranchIdSearch(data);
            let firstNonEmptyAgency;
            if (searchResult) {
                firstNonEmptyAgency = searchResult.find(
                    (item) => item.agencyid !== ''
                );
            }
            const agencyid = {
                ...this.state.createPatientData,
                agencyid: firstNonEmptyAgency?.agencyid,
                branch_id: branch_id,
            };
            await this.setState({ createPatientData: agencyid });
            await this.props.updateState({
                patient_filter: agencyid,
                agency_id: searchResult,
                branch_id: branch_id,
                formData: {
                    ...this.props.formData,
                    ...createPatientData,
                },
            });
        }
    };

    validatePhoneNumber = (phoneNumber) => {
        // Check if phoneNumber is undefined or empty
        if (!phoneNumber || phoneNumber === '') {
            return true; // Valid if phone number is empty or undefined
        }

        // Validate the format: 1-XXX-XXX-XXXX
        return /^\d{1}-\d{3}-\d{3}-\d{4}$/.test(phoneNumber);
    };

    render() {
        return (
            <div className='create_patient'>
                <SpinnerBlur show={this.state.loader} />
                <div className='row grid-row-group d-flex'>
                    {this.props.isDetailBigView ||
                    (this.props.isUploadPage &&
                        this.props.allowedFields.includes('patient_name')) ? (
                        <>
                            <div className='col-lg-6 transition-select'>
                                <label className='pl-5'>
                                    PATIENT FIRST NAME
                                </label>
                                <div className='position-relative'>
                                    <input
                                        type='text'
                                        className={
                                            this.state.validateCreate &&
                                            stringEmptyOrUndefined(
                                                this.state.createPatientData
                                                    .first_name
                                            ) === false
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={
                                            this.state.createPatientData
                                                .first_name
                                        }
                                        onChange={(event) => {
                                            this.handleInputOnChange(
                                                'first_name',
                                                event
                                            );
                                        }}
                                    />
                                </div>
                                {this.state.validateCreate &&
                                stringEmptyOrUndefined(
                                    this.state.createPatientData.first_name
                                ) === false ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Patient First Name is required.
                                    </p>
                                ) : null}
                            </div>
                            <div className='col-lg-6 transition-select'>
                                <label className='pl-5'>
                                    PATIENT LAST NAME
                                </label>
                                <input
                                    type='text'
                                    className={
                                        this.state.validateCreate &&
                                        stringEmptyOrUndefined(
                                            this.state.createPatientData
                                                .last_name
                                        ) === false
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        this.state.createPatientData.last_name
                                    }
                                    onChange={(event) => {
                                        this.handleInputOnChange(
                                            'last_name',
                                            event
                                        );
                                    }}
                                />
                                {this.state.validateCreate &&
                                stringEmptyOrUndefined(
                                    this.state.createPatientData.last_name
                                ) === false ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Patient Last Name is required.
                                    </p>
                                ) : null}
                            </div>

                            {!!this.props?.configData?.indexingSettings
                                ?.referralsUseHCHBAddlFields && (
                                <>
                                    {/* Patient Address */}
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            PATIENT ADDRESS
                                        </label>
                                        <div className='position-relative'>
                                            <input
                                                type='text'
                                                maxLength={100}
                                                className='form-control'
                                                value={
                                                    this.state.createPatientData
                                                        .patient_address
                                                }
                                                onChange={(event) => {
                                                    this.handleInputOnChange(
                                                        'patient_address',
                                                        event
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* Patient City */}
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            PATIENT CITY
                                        </label>
                                        <div className='position-relative'>
                                            <input
                                                type='text'
                                                maxLength={50}
                                                className='form-control'
                                                value={
                                                    this.state.createPatientData
                                                        .patient_city
                                                }
                                                onChange={(event) => {
                                                    this.handleInputOnChange(
                                                        'patient_city',
                                                        event
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* Patient State */}
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            PATIENT STATE
                                        </label>
                                        <select
                                            id='select_state'
                                            className='form-control'
                                            onChange={this.handleStateChange}
                                            value={this.state.selectedState}
                                        >
                                            <option value=''>Select</option>
                                            {this.sortedStates.map(
                                                ([abbreviation, name]) => (
                                                    <option
                                                        key={abbreviation}
                                                        value={`${name} (${abbreviation})`}
                                                    >
                                                        {name} ({abbreviation})
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                    </div>

                                    {/* Patient Zip Code */}
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            PATIENT ZIP CODE
                                        </label>
                                        <div className='position-relative'>
                                            <input
                                                type='text'
                                                maxLength={10}
                                                className='form-control'
                                                value={
                                                    this.state.createPatientData
                                                        .patient_zip_code
                                                }
                                                onChange={(event) => {
                                                    this.handleInputOnChange(
                                                        'patient_zip_code',
                                                        event
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* Patient Phone # */}
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            PATIENT PHONE #
                                        </label>
                                        <div className='position-relative'>
                                            <input
                                                type='text'
                                                className={
                                                    this.validatePhoneNumber(
                                                        this.state
                                                            .createPatientData
                                                            .patient_phone_
                                                    ) === false
                                                        ? 'position-relative form-control error-field'
                                                        : 'position-relative form-control'
                                                }
                                                value={
                                                    this.state.createPatientData
                                                        .patient_phone_
                                                }
                                                onChange={(event) => {
                                                    this.handlePhoneChange(
                                                        'patient_phone_',
                                                        event
                                                    );
                                                }}
                                            />
                                            {!this.validatePhoneNumber(
                                                this.state.createPatientData
                                                    .patient_phone_
                                            ) ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Phone Number should be empty
                                                    or contain 10 digits.
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* Patient Mobile Phone # */}
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            PATIENT MOBILE PHONE #
                                        </label>
                                        <div className='position-relative'>
                                            <input
                                                type='text'
                                                className={
                                                    this.validatePhoneNumber(
                                                        this.state
                                                            .createPatientData
                                                            .patient_mobile_phone_
                                                    ) === false
                                                        ? 'position-relative form-control error-field'
                                                        : 'position-relative form-control'
                                                }
                                                value={
                                                    this.state.createPatientData
                                                        .patient_mobile_phone_
                                                }
                                                onChange={(event) => {
                                                    this.handlePhoneChange(
                                                        'patient_mobile_phone_',
                                                        event
                                                    );
                                                }}
                                            />
                                            {!this.validatePhoneNumber(
                                                this.state.createPatientData
                                                    .patient_mobile_phone_
                                            ) ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Phone Number should be empty
                                                    or contain 10 digits.
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ) : null}

                    <>
                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes(
                                'date_of_birth'
                            )) ? (
                            <div className='col-lg-6 transition-select'>
                                <label className='pl-5'>DATE OF BIRTH</label>
                                <div
                                    className={
                                        this.state.validateCreate &&
                                        stringEmptyOrUndefined(
                                            this.state.createPatientData.dob
                                        ) === false
                                            ? 'position-relative form-control error-field'
                                            : 'position-relative form-control'
                                    }
                                >
                                    <DatePicker
                                        format='MM/dd/y'
                                        onChange={(date) => {
                                            this.setDob(date);
                                        }}
                                        value={this.state.createPatientData.dob}
                                        className='date-picker'
                                    />
                                </div>
                                {this.state.validateCreate &&
                                stringEmptyOrUndefined(
                                    this.state.createPatientData.dob
                                ) === false ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Date of Birth is required.
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes('gender')) ? (
                            <div className='col-lg-6 transition-select'>
                                <label className='pl-5'>GENDER</label>
                                <select
                                    id='delete_selectbrand'
                                    className={
                                        this.state.validateCreate &&
                                        stringEmptyOrUndefined(
                                            this.state.createPatientData.gender
                                        ) === false
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={this.state.createPatientData.gender}
                                    onChange={(event) => {
                                        this.handleSelectOnChange(
                                            'gender',
                                            event
                                        );
                                    }}
                                >
                                    <option value=''>Select</option>
                                    <option value='M'>Male</option>
                                    <option value='F'>Female</option>
                                </select>
                                <i
                                    className='fa-light fa-chevron-down'
                                    style={{ pointerEvents: 'none' }}
                                />
                                {this.state.validateCreate &&
                                stringEmptyOrUndefined(
                                    this.state.createPatientData.gender
                                ) === false ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Gender is required.
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                    </>

                    <>
                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes(
                                'service_line'
                            )) ? (
                            <div className='col-lg-6 transition-select'>
                                <label className='pl-5'>SERVICE LINE</label>
                                <div className=''>
                                    <select
                                        id='delete_selectbrand'
                                        className={
                                            this.state.validateCreate &&
                                            stringEmptyOrUndefined(
                                                this.state.createPatientData
                                                    .service_line
                                            ) === false
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={
                                            this.state.createPatientData
                                                .service_line
                                        }
                                        onChange={(event) => {
                                            this.handleSelectOnChange(
                                                'service_line',
                                                event
                                            );
                                        }}
                                    >
                                        <option value=''>Select</option>
                                        {this.props.ddl &&
                                        Array.isArray(
                                            this.props.ddl.serviceline
                                        )
                                            ? this.props.ddl.serviceline.map(
                                                  (data, index) => (
                                                      <option
                                                          key={index}
                                                          value={data.toUpperCase()}
                                                      >
                                                          {data.toUpperCase()}
                                                      </option>
                                                  )
                                              )
                                            : null}
                                    </select>
                                    <i
                                        className='fa-light fa-chevron-down'
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    {this.state.validateCreate &&
                                    stringEmptyOrUndefined(
                                        this.state.createPatientData
                                            .service_line
                                    ) === false ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Service Line is required.
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}

                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes('branch')) ? (
                            <div className='col-lg-6 transition-select'>
                                <label className='pl-5'>BRANCH</label>
                                <select
                                    id='delete_selectbrand'
                                    className={
                                        this.state.validateCreate &&
                                        stringEmptyOrUndefined(
                                            this.state.createPatientData.branch
                                        ) === false
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    onChange={(event) => {
                                        this.handleBranchOnChange(
                                            'branch',
                                            event,
                                            'OnChange'
                                        );
                                    }}
                                    value={this.state.createPatientData.branch}
                                >
                                    <option value=''>Select</option>
                                    {this.props.ddl &&
                                    Array.isArray(this.props.ddl.branch)
                                        ? this.props.ddl.branch.map(
                                              (data, index) => (
                                                  <option
                                                      key={index}
                                                      value={data}
                                                      style={{
                                                          display:
                                                              data !== ''
                                                                  ? 'block'
                                                                  : 'none',
                                                      }}
                                                  >
                                                      {data}
                                                  </option>
                                              )
                                          )
                                        : null}
                                </select>
                                <i
                                    className='fa-light fa-chevron-down'
                                    style={{ pointerEvents: 'none' }}
                                />
                                {this.state.validateCreate &&
                                stringEmptyOrUndefined(
                                    this.state.createPatientData.branch
                                ) === false ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Branch is required.
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                    </>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
};

const mapStateToProps = (state, ownProps) => {
    return {
        configData: state.configReducerPersist.configData,
        ddl: state.persistDDL.DDL,
        rulesJson: state.inbox.selectedRowDetails.rulesJson,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DetailCreatePatient));
