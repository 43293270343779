import React, { useEffect, useState } from 'react';

import { deleteDocumentLocks, getLockDocuments, lockDocument } from '../pages/api/api';
import DeleteModal from './DeleteModal';
import { showErrorToast } from './commonfunction';

interface LockDocumentProps {
    docId: number;
    lockOwner: string;
    isCalledFromGrid: boolean;
    onUnlock: () => void;
}

const LockBar: React.FC<LockDocumentProps> = ({ docId, isCalledFromGrid, onUnlock }) => {
    const [currentItem, setCurrentItem] = useState<LockDocumentProps>();
    const [showModal, setShowModal] = useState(false);
    const [adminLocks, setAdminLocks] = useState(false);

    useEffect(() => {
        fetchLocks();
        checkAdminLocks();
    }, [docId]);

    const checkAdminLocks = () => {
        const userDetail = localStorage.getItem('user_details');
        const parsedUserDetail = userDetail ? JSON.parse(userDetail) : null;
        const claims = parsedUserDetail?.claims || [];
        if (claims.includes('adminlocks')) {
            setAdminLocks(true);
        }
    };

    const fetchLocks = async () => {
        try {
            const response = await getLockDocuments();
            if (response.length) {
                const matchingItem = response.find(
                    (item: any) => item.itemNum === docId
                );
                if (matchingItem) {
                    setCurrentItem(matchingItem);
                }
            }
        } catch (error) {
            showErrorToast('Error fetching locks data');
            console.error('Error fetching locks data:', error);
        }
    };

    const deleteLock = async () => {
        try {
            const response = await deleteDocumentLocks([currentItem]);
            if (response) {
                onUnlock();
                await lockDocument(docId);
            }
        } catch (error) {
            showErrorToast('Failed to delete a lock');
            console.error('Failed to delete a lock:', error);
        }
    };
    return (
        <>
            <div className={isCalledFromGrid ? 'talkbubble' : 'lock-message'}>
                <i className='fa fa-lock' aria-hidden='true' />
                Document Locked.{' '}
                <span style={{ color: 'black', fontWeight: 600 }}>
                    {currentItem?.lockOwner ?? 'Someone '}
                </span>
                is working on this document.
                {adminLocks && !isCalledFromGrid && (
                    <span
                        onClick={() => setShowModal(true)}
                        title='Unlock'
                        style={{
                            marginLeft: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        <i
                            className='fas fa-unlock-keyhole'
                            style={{
                                color: 'black',
                                fontSize: '14px',
                            }}
                        ></i>
                    </span>
                )}
            </div>
            <DeleteModal
                deleteModel={showModal}
                setDeleteModel={setShowModal}
                onDelete={deleteLock}
                title='Unlock Document'
                warningText='Are you sure you want to remove the lock on this document?'
                actionButtonText='Remove'
            />
        </>
    );
};

export default LockBar;

