import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getDDLlist } from '../_redux/actions/configActions';
import UserListingDataGrid from '../../components/datagrid/UserListingDataGrid';

class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuName: 'users',
            show_result: true,
        };
    }

    componentDidMount() {
        // this.getTransitonDropDownData();

        if (
            this.props.match.params.menuName !== undefined &&
            this.props.match.params.menuName !== ''
        ) {
            this.setState({ menuName: this.props.match.params.menuName });
        }

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.menuName !==
                this.props.match.params.menuName &&
            this.props.match.params.menuName !== undefined &&
            this.props.match.params.menuName !== ''
        ) {
            // this.setState({ menuName: this.props.match.params.menuName });
        }
    }

    render() {
        return (
            <div className='search-right-aside'>
                {this.state.menuName === 'users' ? (
                    <UserListingDataGrid
                        calingFrom='user'
                        pageTitle='Users'
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        show_result
                    />
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
};
const mapStateToProps = (state) => {
    return {
        configData: state.configReducerPersist.configData,
    };
};

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(withRouter(SearchPage));
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(UserPage))
);
