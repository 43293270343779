import { getConfig } from "../../../../_metronic/_helpers";
import { showErrorToast } from "../../../components/commonfunction";

export const getSyncFieldId = (recordSetName: string) => {
    try {
        const configData = getConfig();
        const configuredRecordsets =
            configData.length > 0 ? configData[0].configuredRecordsets : [];
        if (configuredRecordsets !== undefined) {
            const recordsetData = configuredRecordsets.find(
                (recordset: any) => recordset.name === recordSetName
            );

            if (recordsetData && recordsetData.recordsetId && recordsetData.name) {
                const syncFieldId = recordsetData.fields.find((r: any) => r.name === 'sync');

                if (!syncFieldId) {
                    throw new Error(
                        `No syncFieldId received for: ${recordsetData.name}`
                    );
                }

                return syncFieldId;
            } else {
                showErrorToast(`No recordset found for ${recordSetName}`);
            }
        }
    } catch (error) {
        console.error('Error in fetching or processing data:', error);
        showErrorToast('Error while processing');
        throw new Error('Error while processing');
    }
};