/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useCombinedRefs } from './useCombinedRefs';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';
import { formatDate } from '../commonfunction';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

let initialUniqueArray = [];

export function DraggablePaginatedHeaderRenderer(
    {
        onColumnsReorder,
        column,
        sortDirection,
        uniqueData,
        setGrouping,
        groupByData,
        sortFields,
        sortColumn,
        defaultPopOpenState,
        uniqueTagNameValues,
        handleClickOutside,
        dateFormatFields,
        groupBy,
        childUniqueData,
        onColumnFilterClear,
        onAddCustomFilter,
        customFilters,
        removeColumnGrouping,
    },
    props
) {
    if (uniqueData.hasOwnProperty('action')) {
        uniqueData.action = '';
        //  uniqueData.dateofbirth = '';
    }

    if (
        typeof childUniqueData === 'object' &&
        childUniqueData.hasOwnProperty('action')
    ) {
        childUniqueData.action = '';
        // childUniqueData.dateofbirth = '';
    }

    //const IndexName = String(column.name).replace(/\s/g, '').toLowerCase();
    //going to remove - from the header name since we use only lower case , if any changes in header name need to restrict Index name
    // I am changing for lastfollow-up to lastfollowup
    const IndexName = String(column.name).replace(/\s|-/g, '').toLowerCase();

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState(null);
    const [filterInputs, setFilterInputs] = React.useState(['']);

    const [position, setPosition] = React.useState({ left: 0, top: 0 });
    const inputRefs = React.useRef([]);
    const tagName = new Set();
    let uniqueTagName =
        uniqueTagNameValues !== undefined
            ? Array.from(uniqueTagNameValues)
            : [];
    uniqueTagName.forEach((data) => {
        if (data.trim() !== '') {
            data.split('|').forEach((val) => {
                if (data !== undefined) {
                    val =
                        val.charAt(0).toUpperCase() + val.slice(1, data.length); // First Letter UpperCase
                    if (val !== undefined) {
                        tagName.add(val);
                    }
                }
            });
        }
    });
    uniqueTagName = Array.from(tagName);
    // For Dragging and Droping
    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN_DRAG',
        item: { key: column.key },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'COLUMN_DRAG',
        drop({ key }) {
            onColumnsReorder(key, column.key);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    // End
    // Open PopOver Functionality
    const handleClick = (event, name) => {
        handleClickOutside(event, true);
        const elementPosition = event.currentTarget.getBoundingClientRect();
        const positionTemp = {
            top: parseInt(elementPosition.top, 10),
            left: parseInt(elementPosition.x, 10),
        };
        const key = `${name}_position`;
        setAnchorEl(event.currentTarget);
        setPosition(positionTemp);

        setTimeout(() => {
            if (inputRefs.current.length > 0) {
                inputRefs.current[0]?.focus();
            }
        }, 100);
        localStorage.setItem(key, JSON.stringify(positionTemp));
    };

    /* const handleClose = () => {
        setAnchorEl(null);
    }; */
    // For Single data selection
    const handleGrouping = (data, event) => {
        setGrouping(data, '', event.target.checked, '', true);
    };
    /* const isValid = (str) => {
        return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
    } */
    const handleSearchAction = (txt, index, event) => {
        txt = String(txt).replaceAll(/\\/g, ''); // Remove backward slash from string
        setSearchValue({ index, txt });
    };
    // Select / unSelect Function
    const selectAllHandler = (title, event, selectData) => {
        let uniqueDataArray =
            IndexName !== 'tags' && uniqueData !== undefined
                ? Array.from(uniqueData[IndexName])
                : uniqueTagName;
        uniqueDataArray = uniqueDataArray.filter((data) => {
            return data.trim() !== '';
        });
        const mode = !(
            groupByData[title] !== undefined &&
            groupByData[title].length === uniqueDataArray.length
        ); // Checking All Data Selected Or Not

        setGrouping(
            {
                title: title,
                value: selectData,
            },
            'ALL',
            mode,
            '',
            true
        );
    };
    // Clear all Function
    const handleClearAll = (event, title) => {
        removeColumnGrouping(title);
        setSearchValue({ index: title, txt: '' });
    };
    let open = Boolean(anchorEl);

    React.useEffect(() => {
        if (!!customFilters && customFilters?.length !== 0) {
            const searchFiter = customFilters?.find(
                (filter) => filter.filterColumn === column.name
            );
            setFilterInputs(searchFiter?.filterString ?? ['']);
        }

        let IndexClass = IndexName;
        if (IndexName.indexOf('&') !== -1) {
            // Remove (&) symbol, className won`t accept it
            IndexClass = IndexClass.replace('&', '-');
        }
    }, [anchorEl, inputRefs, customFilters, props]);

    const hasAppliedFilters = () => {
        const nonEmptyFilters = customFilters.filter((filter) =>
            filter.filterString.some((input) => input.trim() !== '')
        );
        return nonEmptyFilters;
    };

    let uniqueDataArray = [];
    if (
        IndexName.trim() !== '' &&
        uniqueData !== undefined &&
        uniqueData[IndexName] !== undefined
    ) {
        if (groupBy.length > 0 && childUniqueData !== undefined) {
            const child = {};
            const parent = {};
            for (const property in childUniqueData) {
                if (!groupBy.includes(property)) {
                    child[property] = childUniqueData[property];
                }
            }
            for (const property in uniqueData) {
                if (groupBy.includes(property)) {
                    parent[property] = uniqueData[property];
                }
            }
            if (Object.keys(child).includes(IndexName)) {
                uniqueDataArray =
                    IndexName !== 'tags'
                        ? Array.from(child[IndexName])
                        : uniqueTagName;
            }
            if (Object.keys(parent).includes(IndexName)) {
                uniqueDataArray =
                    IndexName !== 'tags'
                        ? Array.from(parent[IndexName])
                        : uniqueTagName;
            }
        } else {
            uniqueDataArray =
                IndexName !== 'tags'
                    ? Array.from(uniqueData[IndexName])
                    : uniqueTagName;
        }

        if (searchValue !== null) {
            // perform Search Action
            if (IndexName === 'userid') {
                uniqueDataArray =
                    searchValue.index !== 'tags'
                        ? Array.from(uniqueData[searchValue.index])
                        : uniqueTagName;
                uniqueDataArray = uniqueDataArray.filter((data) => {
                    if (typeof data === 'string')
                        data = data.toLowerCase().trim();
                    return data.toString().match(String(searchValue.txt));
                });
            } else {
                uniqueDataArray =
                    searchValue.index !== 'tags'
                        ? Array.from(uniqueData[searchValue.index])
                        : uniqueTagName;
                uniqueDataArray = uniqueDataArray.filter((data) => {
                    data = data.toLowerCase().trim();
                    return data.match(searchValue.txt);
                });
            }
        }
    }

    const id = open ? `simple-popover-${column.name}` : undefined;
    if (uniqueDataArray.length > 0 && uniqueDataArray !== undefined) {
        uniqueDataArray = uniqueDataArray.map((data) => {
            if (
                typeof data !== 'object' &&
                data !== undefined &&
                data !== '' &&
                data !== null
            ) {
                data = String(data);
                data = data.trim();
                return (
                    data.charAt(0).toUpperCase() + data.slice(1, data.length)
                ); // First Letter UpperCase
            }
            if (typeof data === 'object') {
                data = '';
            }
        });
    }
    uniqueDataArray = uniqueDataArray.filter((data) => {
        if (data !== undefined && data !== '') {
            return data.trim() !== '';
        }
    }); // Filter empty data
    uniqueDataArray = uniqueDataArray.sort();
    const DisplayData = uniqueDataArray;
    const displayDataInHtml = (index) => {
        const data = DisplayData[index];
        return data.length > 25 ? `${data.slice(0, 25)}...` : data;
    };
    let groupByValue =
        groupByData[IndexName] !== undefined ? groupByData[IndexName] : [];
    groupByValue = groupByValue.map((data) => {
        data = data.trim();
        return data.charAt(0).toUpperCase() + data.slice(1, data.length); // First Letter UpperCase
    });
    uniqueDataArray = uniqueDataArray.map((data) => {
        data = data.trim();
        return (
            data.charAt(0).toUpperCase() +
            data.slice(1, data.length).toLowerCase()
        ); // First Letter UpperCase
    });

    function handleClosePopUp() {
        for (const name in defaultPopOpenState) {
            if (defaultPopOpenState) {
                defaultPopOpenState[name] = false;
            }
        }
        open = false;
        setAnchorEl(null);
    }
    let IndexClass = IndexName;
    if (IndexName.indexOf('&') !== -1) {
        IndexClass = IndexClass.replace('&', '-');
    } // Set class to highlight asc or desc
    let activeSortClassASC = '';
    let activeSortClassDESC = '';
    if (
        (IndexName === sortColumn || column.name === sortColumn) &&
        sortDirection === 'ASC'
    ) {
        activeSortClassASC = 'active-asc';
    }
    if (
        (IndexName === sortColumn || column.name === sortColumn) &&
        sortDirection === 'DESC'
    ) {
        activeSortClassDESC = 'active-desc';
    }
    // End
    const isSelectAll =
        groupByValue.length === uniqueDataArray.length &&
        groupByValue.length !== 0; // Check SelectALL should be checked or not
    if (initialUniqueArray.length === 0) {
        initialUniqueArray = uniqueDataArray;
    }

    let dataFromTable = [];
    if (
        IndexName !== 'tags' &&
        uniqueData !== undefined &&
        uniqueData !== null &&
        uniqueData[IndexName] !== undefined
    ) {
        const temparr = Array.from(uniqueData[IndexName]);
        dataFromTable = temparr.filter((n) => n);
    }

    const applyCustomerFilter = () => {
        onAddCustomFilter({
            columnType: column?.colname ?? '',
            filterColumn: column.name,
            filterString: filterInputs,
        });
    };

    const hasFilters = () => {
        const nonEmptyFilters = filterInputs.filter(
            (input) => input.trim() !== ''
        );
        return nonEmptyFilters;
    };

    const addedFilters = hasFilters();

    const addFilterInput = () => {
        const newInputs = [...filterInputs, ''];
        setFilterInputs(newInputs);
        const newInputIndex = newInputs.length - 1;

        setTimeout(() => {
            inputRefs.current[newInputIndex]?.focus();
        }, 100);
    };

    const removeFilterInput = (index) => {
        const filterToRemove = filterInputs[index];

        if (!filterToRemove.trim()) {
            const updatedInputs = filterInputs.filter((_, i) => i !== index);
            setFilterInputs(updatedInputs);
        } else if (filterInputs.length > 1) {
            const updatedInputs = filterInputs.filter((_, i) => i !== index);
            setFilterInputs(updatedInputs);
        } else if (addedFilters.length === 1) {
            const updatedInputs = [...filterInputs];
            updatedInputs[index] = '';
            setFilterInputs(updatedInputs);
        }
    };

    const handleFilterChange = (index, value) => {
        const updatedInputs = [...filterInputs];

        if (column?.colname === 'Date' || column?.colname === 'DateTime') {
            // Regular expression to match the YYYY-MM-DD format
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

            if (dateRegex.test(value)) {
                updatedInputs[index] = value;
                setFilterInputs(updatedInputs);
            } else {
                console.log(
                    'Invalid date format. Please enter in YYYY-MM-DD format.'
                );
                updatedInputs[index] = formatDate(value, 'YYYY-MM-DD');
                setFilterInputs(updatedInputs);
            }
        } else if (column?.name === 'Fax Number') {
            const numericValue = value.replace(/\D/g, '');
            updatedInputs[index] = numericValue;
            setFilterInputs(updatedInputs);
        } else {
            updatedInputs[index] = value;
            setFilterInputs(updatedInputs);
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            applyCustomerFilter();
        } else if (event.key === 'Tab') {
            event.preventDefault();

            addFilterInput();
        }
    };

    return (
        <div
            ref={useCombinedRefs(drag, drop)}
            style={{
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: isOver ? '#ececec' : 'inherit',
                cursor: 'move',
                display: 'flex',
                flexDirection: 'column',
                height: '60px',
                justifyContent: 'center',
                lineHeight: 'normal',
            }}
        >
            {IndexName.trim() !== '' ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <div className='d-flex'>
                            <span className='header-name' title={column.name}>
                                {column.name.charAt(0).toUpperCase() +
                                    column.name.slice(1)}
                            </span>

                            <Button
                                aria-describedby={id}
                                key={String(Math.random()) + column.name}
                                variant='contained'
                                className='filter-popup'
                                style={
                                    IndexName === 'tags' ||
                                    column.name == 'usergroups'
                                        ? { cursor: 'not-allowed' }
                                        : {}
                                }
                                onClick={(event) => {
                                    if (
                                        column.name != 'usergroups' &&
                                        IndexName !== 'tags'
                                    ) {
                                        handleClick(event, column.name);
                                    }
                                }}
                            >
                                <i
                                    title='Filter'
                                    className={`fa fa-chevron-down filter-position get-position-${IndexClass}`}
                                    aria-hidden='true'
                                />
                            </Button>
                        </div>

                        <div
                            className='applied-filter-text'
                            style={{
                                display: 'flex',
                            }}
                        >
                            {(sortColumn === column.name ||
                                IndexName === sortColumn) && (
                                <span>
                                    <i
                                        className={
                                            sortDirection === 'ASC'
                                                ? 'fa-regular fa-sort-alpha-down'
                                                : sortDirection === 'DESC'
                                                ? 'fa-regular fa-sort-alpha-up'
                                                : ''
                                        }
                                        style={{
                                            color: '#007DBC',
                                        }}
                                        aria-hidden='true'
                                    />
                                </span>
                            )}
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        {addedFilters.length > 0 ? (
                            <div
                                className='applied-filter-text'
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={(event) => {
                                    if (column.name != 'usergroups') {
                                        handleClick(event, column.name);
                                    }
                                }}
                            >
                                {addedFilters.length === 1 ? (
                                    <span>{addedFilters[0]}</span>
                                ) : (
                                    <span>Multiple filters</span>
                                )}
                            </div>
                        ) : (
                            <div>
                                <span className='header-name'></span>
                            </div>
                        )}

                        {addedFilters?.length >= 1 && (
                            <span className='icon_fun outer-col no-bg pl-2'>
                                <span
                                    className='fa-regular cursor-pointer'
                                    onClick={() => {
                                        onColumnFilterClear(column.name);
                                    }}
                                    title='Clear filters'
                                >
                                    <SVG
                                        width='13px'
                                        height='13px'
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/General/filter-circle-xmark-regular.svg'
                                        )}
                                    />
                                </span>
                            </span>
                        )}
                    </div>

                    <Popover
                        id={id}
                        open={open}
                        anchorReference='anchorPosition'
                        anchorPosition={position}
                        //  onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleClosePopUp}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        style={{ maxHeight: '400px' }}
                        className='popover-menu-items'
                    >
                        {IndexName !== 'tags' ? (
                            <div className='filter-sorting'>
                                <ul>
                                    <li
                                        key={0}
                                        onClick={() => {
                                            sortFields(
                                                column.name,
                                                'ASC',
                                                dateFormatFields
                                            );
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        className={`custom-sort ${activeSortClassASC}`}
                                    >
                                        {' '}
                                        <i className='fa-regular fa-sort-alpha-down' />
                                        Sort A to Z
                                    </li>
                                    <li
                                        key={1}
                                        onClick={() =>
                                            sortFields(
                                                column.name,
                                                'DESC',
                                                dateFormatFields
                                            )
                                        }
                                        style={{ cursor: 'pointer' }}
                                        className={`custom-sort ${activeSortClassDESC}`}
                                    >
                                        {' '}
                                        <i className='fa-regular fa-sort-alpha-up' />
                                        Sort Z to A{' '}
                                    </li>
                                    <li
                                        key={2}
                                        onClick={() =>
                                            sortFields(
                                                column.name,
                                                'NONE',
                                                dateFormatFields
                                            )
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {' '}
                                        <span
                                            className='fa-regular menu-icon svg-icon'
                                            style={{ marginRight: '5px' }}
                                        >
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    '/media/svg/icons/General/trash-can-list-regular.svg'
                                                )}
                                            />
                                        </span>
                                        Remove Sorting{' '}
                                    </li>
                                </ul>
                            </div>
                        ) : null}

                        {
                            <>
                                <span className='title'>
                                    <i
                                        className='fa-regular fa-plus cursor-pointer'
                                        style={{
                                            color: '#2e7cb8',
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={addFilterInput}
                                        title='Add filter'
                                    />
                                    Filter by
                                    <i
                                        className='icon-xd fas fa-check float-right cursor-pointer m-0'
                                        style={{
                                            color:
                                                addedFilters.length > 0 ||
                                                hasAppliedFilters()?.length > 0
                                                    ? '#78AD30'
                                                    : '',
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}
                                        title='Apply filter'
                                        onClick={applyCustomerFilter}
                                    />
                                </span>

                                {filterInputs.map((input, index) => (
                                    <div
                                        key={index}
                                        className={
                                            IndexName === 'tags'
                                                ? 'filter-search tag-filter d-flex'
                                                : 'filter-search d-flex'
                                        }
                                        onClick={() =>
                                            inputRefs.current[index]?.focus()
                                        }
                                    >
                                        <div className='filter_wrap'>
                                            <i className='fa-regular fa-magnifying-glass' />
                                            <div className='filter-input-container'>
                                                {column?.colname === 'Date' ||
                                                column?.colname ===
                                                    'DateTime' ? (
                                                    <input
                                                        value={input}
                                                        type='date'
                                                        ref={(el) =>
                                                            (inputRefs.current[
                                                                index
                                                            ] = el)
                                                        }
                                                        maxLength={10}
                                                        placeholder='YYYY-MM-DD'
                                                        className='search-textbox'
                                                        onChange={(event) =>
                                                            handleFilterChange(
                                                                index,
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        onKeyDown={(event) =>
                                                            handleKeyDown(
                                                                event,
                                                                index
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <input
                                                        value={input}
                                                        ref={(el) =>
                                                            (inputRefs.current[
                                                                index
                                                            ] = el)
                                                        }
                                                        maxLength={20}
                                                        placeholder='Search value'
                                                        className='search-textbox'
                                                        onChange={(event) =>
                                                            handleFilterChange(
                                                                index,
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        onKeyDown={(event) =>
                                                            handleKeyDown(
                                                                event,
                                                                index
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {((addedFilters.length > 0 &&
                                            hasAppliedFilters()?.length > 0) ||
                                            filterInputs?.length > 1) && (
                                            <div className='d-flex align-items-center justify-content-center ml-2 mb-2'>
                                                <i
                                                    className='fa-regular fa-trash-can cursor-pointer'
                                                    onClick={() => {
                                                        removeFilterInput(
                                                            index
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        }

                        {uniqueDataArray.length > 0 && (
                            <Typography
                                className={classes.typography}
                                key='group-fields-checkbox'
                            >
                                <label>
                                    <span className='rdg-checkbox-label'>
                                        <input
                                            type='checkbox'
                                            checked={
                                                groupByValue.length ===
                                                uniqueDataArray.length
                                            }
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    // Group by all fields
                                                    selectAllHandler(
                                                        IndexName,
                                                        event,
                                                        uniqueDataArray
                                                    );
                                                } else {
                                                    // Clear grouping for all fields
                                                    handleClearAll(
                                                        event,
                                                        IndexName
                                                    );
                                                }
                                            }}
                                        />
                                        <span className='rdg-checkbox'></span>
                                    </span>
                                    <span title='Group fields'>
                                        Group by values
                                    </span>
                                </label>
                            </Typography>
                        )}
                    </Popover>
                </>
            ) : null}
        </div>
    );
}
