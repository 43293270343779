import React from 'react';
// import { useLatestFunc } from './useLatestFunc';
// import { createContext, useContext } from 'react';
import {
    RowSelectionProvider,
    groupRowSelectedClassname,
    rowClassname,
} from 'react-data-grid';
import clsx from 'clsx';
import { getColSpan } from './Util';

function Row({
    fromPage,
    className,
    rowIdx,
    isRowSelected,
    copiedCellIdx,
    draggedOverCellIdx,
    lastFrozenColumnIndex,
    row,
    viewportColumns,
    selectedCellProps,
    onRowClick,
    onRowDoubleClick,
    rowClass,
    setDraggedOverRowIdx,
    onMouseEnter,
    top,
    height,
    onRowChange,
    selectCell,
    fullHeaderData,
    openedRowId,
    modifiedColumnsNames,
    dateFormatFields,
    toggleRowElement,
    ...props
}) {
    className = clsx(
        rowClassname,
        `rdg-row-${rowIdx % 2 === 0 ? 'even' : 'odd'}`,
        {
            [groupRowSelectedClassname]: selectedCellProps?.idx === -1,
        },
        rowClass(row),
        className
    );
    const defaultStyle = {
        height: String.raw`25px`,
        maxHeight: String.raw`25px`,
    };
    function handleDragEnter(event) {
        if (typeof setDraggedOverRowIdx === 'function') {
            setDraggedOverRowIdx(rowIdx);
        }
        if (typeof onMouseEnter === 'function') {
            onMouseEnter(event);
        }
    }
    const toggleCells = [];
    const titleCells = [];
    const arrowElement = [];
    const titleFormatter = (params) => {
        const { row, column } = params;
        if (column.key === 'tags') {
            return row[`${column.key}_data`];
        } else if (
            dateFormatFields.indexOf(column.key) !== -1 ||
            modifiedColumnsNames.indexOf(column.key) ||
            column.key === 'owner'
        ) {
            return row[`${column.key}_rawdata`];
        } else {
            return row[column.key];
        }
    };

    // let duplicteRow = [];
    const formatName = (name) => {
        return name.charAt(0).toUpperCase() + name.slice(1, name.length);
    };
    const formatLength = (params) => {
        const { row, column } = params;
        const inlineFormatter = (data) => {
            // return data.length > 30 ? data.slice(0,30)+" ..." : data ;
            // data = "Lorem Ipsum has been the industry's standard dummy text ever since the 1500";
            /* let displayTxtArray = data.match(/.{1,35}/g);

            if (displayTxtArray.length > 3) {
                // return displayTxtArray.slice(0,3).join(`<br/>`)+String.raw`...`;
            } */
            // return displayTxtArray.join(`<br/>`);

            return data;
        };
        if (column.key === 'tags') {
            return inlineFormatter(row[`${column.key}_data`]);
        } else if (
            dateFormatFields.indexOf(column.key) !== -1 ||
            modifiedColumnsNames.indexOf(column.key) !== -1
        ) {
            return inlineFormatter(row[`${column.key}_rawdata`]);
        } else if (
            row[column.key] === '' &&
            row['username'] !== undefined &&
            row['username'] !== null
        ) {
            return inlineFormatter(row['username']);
        } else if (
            row[column.key] === '' &&
            row['displayname'] !== undefined &&
            row['displayname'] !== null
        ) {
            return inlineFormatter(row['displayname']);
        } else {
            return inlineFormatter(row[column.key]);
        }
    };
    let openState = false;

    if (openedRowId[row.doc_id] !== undefined) {
        openState = openedRowId[row.doc_id];
    }
    if (openedRowId[row.id] !== undefined) {
        openState = openedRowId[row.id];
    }
    function getRows(viewportColumns) {
        var uniqueColumn = [];
        //  let cells = [];
        var exists = 0;
        for (let index = 0; index < viewportColumns.length; index++) {
            const column = viewportColumns[index];
            const columnKey = column.key;

            if (!uniqueColumn.includes(columnKey)) {
                uniqueColumn.push(columnKey);
                const { idx } = column;
                const colSpan = getColSpan(column, lastFrozenColumnIndex, {
                    type: 'ROW',
                    row,
                });
                if (colSpan !== undefined) {
                    index += colSpan - 1;
                }
                let isCellSelected = false;
                //  let dragHandleProps;
                // let onFocus;
                // let onKeyDown;
                if (selectedCellProps !== undefined) {
                    isCellSelected = selectedCellProps.idx === idx;
                    /*  if (isCellSelected && selectedCellProps.mode === 'SELECT') {
						({ dragHandleProps, onFocus, onKeyDown } =
							selectedCellProps);
					} */
                }

                if (column.key === 'select-row') {
                    titleCells.push(
                        <column.formatter
                            key={Math.random()}
                            column={column}
                            row={row}
                            isCellSelected={isCellSelected}
                            onRowChange={onRowChange}
                        />
                    );
                }
                if (exists === 0 && column.key === 'displayname') {
                    exists = 1;

                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual 111 d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (exists === 0 && column.key === 'documentname') {
                    exists = 1;
                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual 111 d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (
                    exists === 0 &&
                    column.key === 'name' &&
                    fromPage === 'user_listing'
                ) {
                    exists = 1;

                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual 111 d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (exists === 0 && column.key === 'name') {
                    exists = 1;

                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual 111 d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (
                    exists === 0 &&
                    fromPage === 'physician_listing' &&
                    column.key === 'orderingphysician'
                ) {
                    exists = 1;
                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual aaa d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (
                    exists === 0 &&
                    fromPage === 'patient_listing' &&
                    column.key === 'patientname'
                ) {
                    exists = 1;
                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual bbb d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (
                    exists === 0 &&
                    fromPage === 'location_listing' &&
                    column.key === 'location'
                ) {
                    exists = 1;
                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual ccc d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (
                    exists === 0 &&
                    (fromPage === 'custom_listing' ||
                        fromPage === 'reportset_listing') &&
                    //|| column.key === 'ordernumber'
                    column.key === 'name'
                ) {
                    exists = 1;
                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual ddd d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                } else if (
                    exists === 0 &&
                    (fromPage === 'custom_listing' ||
                        fromPage === 'reportset_listing') &&
                    //|| column.key === 'ordernumber'

                    column.key === 'documenthandle'
                ) {
                    exists = 1;
                    titleCells.push(
                        <div
                            key={Math.random()}
                            className='title-manual ddd d-flex align-items-center'
                            onClick={() => toggleRowElement({ row })}
                        >
                            <span title={titleFormatter({ row, column })}>
                                {formatLength({ row, column })}
                            </span>
                        </div>
                    );
                }

                if (column.key === 'toggle') {
                    arrowElement.push(
                        <column.formatter
                            key={Math.random()}
                            column={column}
                            row={row}
                            isCellSelected={isCellSelected}
                            onRowChange={onRowChange}
                        />
                    );
                }
            }
        }
    }
    getRows(viewportColumns);
    const arrawObj = viewportColumns.filter(
        (data) => data.key === String.raw`toggle`
    );
    if (arrowElement.length === 0 && arrawObj.length > 0) {
        const column = arrawObj;
        const isCellSelected = false;
        arrowElement.push(
            <arrawObj.formatter
                key={Math.random()}
                column={column}
                row={row}
                isCellSelected={isCellSelected}
                onRowChange={onRowChange}
            />
        );
    }
    fullHeaderData.forEach((column, index) => {
        if (
            row[column.key] !== undefined &&
            column.key !== 'displayname' &&
            column.key !== 'documentname' &&
            // column.key !== 'orderingphysician' &&
            column.key !== 'name'
        ) {
            toggleCells.push(
                <div key={Math.random()} style={defaultStyle}>
                    <span title={formatName(column.name)}>
                        {formatName(column.name)}
                    </span>
                    <span title={titleFormatter({ row, column })}>
                        {row[column.key]}
                    </span>
                </div>
            );
        }
    });
    const responsiveRowClick = () => {
        if (typeof onRowClick === 'function') {
            const column = {};
            column.key = 'view-document';
            onRowClick(row, column, {});
        }
    };

    if (
        fromPage !== 'physician_listing' &&
        fromPage !== 'patient_listing' &&
        fromPage !== 'location_listing' &&
        fromPage !== 'user_listing' &&
        fromPage !== 'reportset_listing'
    ) {
        toggleCells.push(
            <div key={Math.random()} className='view-document-responsive'>
                <button type='button' onClick={() => responsiveRowClick()}>
                    {' '}
                    View Document{' '}
                </button>
            </div>
        );
    }
    const card = openState ? String.raw`card-view` : null;
    return (
        <RowSelectionProvider value={isRowSelected}>
            <div
                role='row'
                tabIndex={0}
                className={`${className} custom-mobile-row-main custom-mobile-responsive-row `}
                onMouseEnter={handleDragEnter}
                style={{
                    top: top,
                    '--row-height': `${height}px`,
                    '--row-width': '100%',
                    display: 'inline-block',
                }}
                {...props}
            >
                <div className={`expandable-div mobile-data ${card}`}>
                    <div className='title-data d-flex align-items-stretch justify-content-between'>
                        <div className='d-flex'>{titleCells}</div>
                        {arrowElement}
                    </div>
                    <div
                        style={
                            openState
                                ? { display: 'block' }
                                : { display: 'none' }
                        }
                        className='other-datas'
                    >
                        {toggleCells}
                    </div>
                </div>
            </div>
        </RowSelectionProvider>
    );
}
export default Row;
