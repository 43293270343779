import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";





import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";

export function HeaderMobile() {
  const uiService = useHtmlClassService();
   const location = useLocation();
  const getMenuItemActive = (url) => {
		return checkIsActive(location, url) ? "menu-item-active" : "";
    }

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);
  let uploadTypes = [];
  let config = localStorage.getItem('configData');
	config = JSON.parse(config);

	if (config !== undefined && config !== null) {
		uploadTypes = config.uploadTypes;
	}
	var show_mobile_menu = false;
  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
          {/*begin::Logo*/}
          <Link to="/">
            <img alt="logo" src={layoutProps.headerLogo}/>
          </Link>
          {/*end::Logo*/}

          {/*begin::Toolbar*/}




			  <div className="d-flex align-items-center header">
			 {/*<div className="upload-part d-flex align-items-center header-menu mr-5 ">
				<ul className={`menu-nav  ${layoutProps.ulClasses}`}>
					<li key={'first-level-upload'} className={`menu-item menu-item-submenu ${getMenuItemActive('/upload','/sendtofax')}`} data-menu-toggle="hover" aria-haspopup="true">
						
							<span className="svg-icon svg-icon-xl svg-icon-primary">
							<SVG alt="upload-user"
							src={toAbsoluteUrl("/media/svg/icons/Media/upload-user.svg")}
							/>
							</span>
						


						<div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
							<ul className="menu-subnav">
							{uploadTypes.length > 0 ?
									uploadTypes.map((data) =>
									{
										var text = '';
										
										var link_url = '';
											if(data.type_flag == 'Upload') { text = 'Upload Document'; link_url = "/upload/" + data.docId;}
											else if(data.type_flag == 'Fax') { text = 'Send To Fax'; link_url = "/send-to-fax/" + data.docId; }


										return(<li key={'first-level-upload-browse-' + data.docId} className={`menu-item ${getMenuItemActive('/upload/'+data.docId)}`}>
												<NavLink className="menu-link" to={link_url} title="Upload Document">
													<i className="fas fa-file-upload  align-items-center pr-3"></i>
													<span className="menu-text">
														{text}
														</span>
												</NavLink>
										</li>)
									}
									)
									: null}
								
							</ul>
						</div>

					</li>
				</ul>
			 </div>*/}



            {show_mobile_menu && layoutProps.asideDisplay && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <button className="btn p-0 burger-icon burger-icon-left kalpana2" id="kt_aside_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}

            {layoutProps.headerMenuSelfDisplay && (
                <>
                  {/*begin::Header Menu Mobile Toggle*/}
                  <button className="btn p-0 burger-icon ml-4 kalpana1" id="kt_header_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Header Menu Mobile Toggle*/}
                </>
            )}

            {/*begin::Topbar Mobile Toggle*/}
			{show_mobile_menu && (
				<button
					className="btn btn-hover-text-primary p-0 ml-2"
					id="kt_header_mobile_topbar_toggle"
				>
				 <span className="svg-icon svg-icon-xl">
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
				</span>
				</button>
			)}
            {/*end::Topbar Mobile Toggle*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
