import { Entity } from "./interfaces";

export const columnNameAliases = (headerColumn: any) => {
    let newName;
    switch (headerColumn.name) {
        case 'Entity Last Name':
            newName = 'Last Name';
            break;
        case 'Entity First Name':
            newName = 'First Name';
            break;
        case 'Lookup Key Value':
            newName = 'Lookup Value';
            break;
        case 'Entity Communication Email':
            newName = 'Communication Email';
            break;
        case 'Resend Days':
            newName = 'Default Resend Days';
            break;
        default:
            newName = headerColumn.name;
    }
    return newName;
};

export const columnKeysToSearch: (keyof Entity)[] = [
    'entitylastname',
    'entityfirstname',
    'lookupkeyvalue',
    'entitycommunicationemail',
    'countrycode',
    'smsnumber',
    'resenddays',
];

export const columnsToShow = (
    allColumns: any[]
): any[] => {
    return allColumns.filter(
        (column) =>
            column.name !== 'Entity Display Communication Email' &&
            column.name !== 'Disable Auto Resend' &&
            column.name !== 'Lookup Key Type' &&
            column.name !== 'Full Name With Title' &&
            column.name !== 'Welcome Sender' &&
            column.name !== 'Change Counter' &&
            column.name !== 'Keep Documents From Group'
    );
};

