export const mapEntityToApiData = (values: any, headerColumns: any[]) => {
    const apiData = [];

    for (const column of headerColumns) {
        let value;

        switch (column.name) {
            case 'Last Name':
                value = values.lastName;
                break;
            case 'First Name':
                value = values.firstName;
                break;
            case 'Lookup Value':
                value = values.lookupKeyValue;
                break;
            case 'Communication Email':
            case 'Entity Display Communication Email':
                value = values.communicationEmail;
                break;
            case 'Country Code':
                value = values.countryCode;
                break;
            case 'SMS Number':
                value = values.smsNumber;
                break;
            case 'Default Resend Days':
                value = values.resendDays;
                break;
            case 'Disable Auto Resend':
                value = values.disableAutoResend;
                break;
            case 'Keep Documents From Group':
                value = values.keepDocumentsFromGroups;
                break;
            case 'Lookup Key Type':
                value = values.lookupKeyType;
                break;
            case 'Full Name With Title':
                value = values.fullNameWithTitle;
                break;
            case 'Welcome Sender':
                value = values.welcomeSender;
                break;
            case 'Change Counter':
                value = values.changeCounter;
                break;
            default:
                continue;
        }
        apiData.push({ fieldId: column.fieldId, value });
    }

    return apiData;
};