import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { getDocumentImage } from '../../api/api';
import { Spinner } from './Spinner';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        maxHeight: 260,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
function ImagePreviewTooltip({ docId, displayText }, props) {
    const [previewUrl, setPreviewUrl] = React.useState(null);
    const [loader, setLoader] = React.useState(false);
    React.useEffect(() => {
        return () => {};
    }, [props]);
    const getImage = () => {
        if (docId !== undefined) {
            setLoader(true);
            setPreviewUrl(null);
            getDocumentImage(docId, 1)
                .then((response) => {
                    if (response.status === 200) {
                        setLoader(false);
                        return response.blob();
                    }
                    if (response.status === 400) {
                        setLoader(false);
                    }
                    throw new Error('');
                })
                .then((data) => {
                    const url = URL.createObjectURL(data);
                    setPreviewUrl(url);
                })
                .catch((error) => {});
        }
    };
    return (
        <>
            <HtmlTooltip
                title={
                    <>
                        {loader === true ? (
                            <div width={100} height={200}>
                                {' '}
                                <Spinner show />{' '}
                            </div>
                        ) : loader === false && previewUrl !== null ? (
                            <img
                                width={120}
                                height={200}
                                src={previewUrl}
                                alt='No Preview'
                            />
                        ) : (
                            'No Preview found'
                        )}
                    </>
                }
            >
                <span onMouseEnter={() => getImage()}>
                    {' '}
                    <i className='fas fa-file-medical-alt' /> {displayText}
                </span>
            </HtmlTooltip>
        </>
    );
}

export { ImagePreviewTooltip };
