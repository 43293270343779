import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataGrid, { Row, SelectColumn } from 'react-data-grid';
import { deleteDocumentLocks, getLockDocuments } from '../pages/api/api';
import Spinner from './Spinner';
import { NoRecordFound, formatDate, showErrorToast } from './commonfunction';

const ManageLockPage = ({ model, setModel }) => {
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [sortColumns, setSortColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const sortedRows = [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
        setRows(sortedRows.map((item, key) => ({ ...item, id: key })));
    }, [model, sortColumns]);

    const handleClose = () => {
        setModel(false);
    };
    const refreshTable = async () => {
        setIsRefresh(true);
        setSelectedRows(() => new Set());
        await fetchData();
        setIsRefresh(false);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getLockDocuments();
            if (response.length) {
                let sampleRows = response.map((item, key) => {
                    item.lockOwner = item.lockOwner.trim();
                    item.documentName = item.documentName.trim();
                    let convertDateTime = formatDate(
                        item.checkoutTime,
                        'MM/DD/YYYY HH:mm:ss'
                    );
                    item.checkoutTime = convertDateTime;
                    return { ...item, id: key };
                });
                sampleRows.sort((a, b) => {
                    let checkoutTimeA = new Date(a.checkoutTime);
                    let checkoutTimeB = new Date(b.checkoutTime);
                    return checkoutTimeB - checkoutTimeA;
                });
                setRows(sampleRows);
                setSelectedRows(() => new Set());
            } else {
                setRows([]);
                setSelectedRows(() => new Set());
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteApiCall = async (deleteItems) => {
        setLoading(true);
        let response;
        try {
            response = await deleteDocumentLocks(deleteItems);
        } catch (error) {
            console.error('Error deleting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (ids) => {
        let indexesToDelete = Array.from(ids);
        if (indexesToDelete.length === 0) {
            showErrorToast('Choose any document');
            return;
        }
        let filteredRows = rows.filter(
            (item) => !indexesToDelete.includes(item.id)
        );
        setRows(filteredRows);
        setSelectedRows(() => new Set());

        let rowsToDelete = rows.filter((item) =>
            indexesToDelete.includes(item.id)
        );
        const modifiedRows = rowsToDelete.map((item) => {
            delete item.id;
            delete item.checkoutTime;
            return item;
        });
        await deleteApiCall(modifiedRows);
    };

    const handleSort = (columnKey) => {
        let newSortColumns = [...sortColumns];
        const columnIndex = newSortColumns.findIndex(
            (sort) => sort.columnKey === columnKey
        );

        if (columnIndex !== -1) {
            const currentSortDirection = newSortColumns[columnIndex].direction;
            newSortColumns[columnIndex] = {
                columnKey,
                direction: currentSortDirection === 'ASC' ? 'DESC' : 'ASC',
            };
        } else {
            newSortColumns = [{ columnKey, direction: 'ASC' }];
        }
        setSortColumns(newSortColumns);
    };

    const renderHeader = (props, columnKey) => {
        const { column } = props;
        return (
            <span
                className='rdg-header-sort-cell'
                onClick={() => handleSort(columnKey)}
            >
                <span className='rdg-header-sort-name'>{column.name}</span>
                {props.sortDirection !== undefined &&
                props.sortDirection === 'DESC' ? (
                    <span
                        className='MuiButton-label ml-2'
                        style={{ cursor: 'pointer' }}
                    >
                        <i
                            className='fa fa-chevron-down filter-position'
                            aria-hidden='true'
                        />
                    </span>
                ) : (
                    <span
                        className='MuiButton-label ml-2'
                        style={{ cursor: 'pointer' }}
                    >
                        <i
                            className='fa fa-chevron-up filter-position'
                            aria-hidden='true'
                        />
                    </span>
                )}
            </span>
        );
    };
    const columns = [
        {
            ...SelectColumn,
            headerCellClass: 'selectCellClassname',
            cellClass: 'selectCellClassname',
            width: 45,
            maxWidth: 45,
        },
        {
            key: 'lockOwner',
            name: 'LOCK OWNER',
            sortable: true,
            headerRenderer: (props) => renderHeader(props, 'lockOwner'),
        },
        {
            key: 'checkoutTime',
            name: 'DATE LOCKED',
            sortable: true,
            headerRenderer: (props) => renderHeader(props, 'checkoutTime'),
            width: 120,
        },
        {
            key: 'itemNum',
            name: 'DOC ID',
            sortable: true,
            headerRenderer: (props) => renderHeader(props, 'itemNum'),
            width: 90,
        },
        {
            key: 'documentName',
            name: 'DETAILS',
            sortable: true,
            width: 320,
            headerRenderer: (props) => renderHeader(props, 'documentName'),
        },
    ];

    const getComparator = (columnKey) => {
        switch (columnKey) {
            case 'itemNum':
            case 'checkoutTime':
                return (a, b) => {
                    if (
                        typeof a[columnKey] === 'number' &&
                        typeof b[columnKey] === 'number'
                    ) {
                        return a[columnKey] - b[columnKey];
                    }
                    return String(a[columnKey]).localeCompare(
                        String(b[columnKey])
                    );
                };
            case 'lockOwner':
                return (a, b) => {
                    const trimmedA = a[columnKey].trim();
                    const trimmedB = b[columnKey].trim();
                    return trimmedA.localeCompare(trimmedB);
                };
            case 'documentName':
                return (a, b) => {
                    if (
                        typeof a[columnKey] === 'number' &&
                        typeof b[columnKey] === 'number'
                    ) {
                        return a[columnKey] - b[columnKey];
                    }
                    return String(a[columnKey]).localeCompare(
                        String(b[columnKey])
                    );
                };
            default:
                return (a, b) => {
                    if (
                        typeof a[columnKey] === 'number' &&
                        typeof b[columnKey] === 'number'
                    ) {
                        return a[columnKey] - b[columnKey];
                    }
                    return String(a[columnKey]).localeCompare(
                        String(b[columnKey])
                    );
                };
        }
    };

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    return (
        <>
            <Modal
                show={model}
                onHide={() => setModel(false)}
                animation={false}
                size='lg'
                centered
                className='docLock-modal'
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start history_header'
                >
                    <Modal.Title className='d-flex align-items-center justify-content-between'>
                        <div className='history_popup'>
                            <span>
                                <i className='fa-solid fa-lock' />
                            </span>
                            Manage Locks
                            <span
                                className='align-self-center reload'
                                onClick={() => refreshTable()}
                            >
                                <i
                                    title='Refresh'
                                    className={
                                        isRefresh
                                            ? 'fas fa-sync-alt refreshing'
                                            : 'fas fa-sync-alt'
                                    }
                                    style={{ fontSize: '16px' }}
                                />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end '
                                aria-hidden='true'
                                onClick={handleClose}
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div style={{ width: '100%' }} className='docLock-dataGrid'>
                        {model && loading ? (
                            <div
                                className='d-flex align-items-center justify-content-center'
                                style={{ height: '300px' }}
                            >
                                <Spinner show />
                                <span
                                    className='text-muted'
                                    style={{
                                        fontSize: '16px',
                                        marginLeft: '24px',
                                    }}
                                >
                                    Loading...
                                </span>
                            </div>
                        ) : (
                            <>
                                <DataGrid
                                    columns={columns}
                                    className='fill-grid'
                                    rows={rows}
                                    rowKeyGetter={(row) => row.id}
                                    style={{ height: '300px' }}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    sortColumns={sortColumns}
                                    onSortColumnsChange={setSortColumns}
                                    defaultColumnOptions={{
                                        sortable: true,
                                        resizable: true,
                                        width: '1fr',
                                        frozen: false,
                                    }}
                                    rowHeight={50}
                                    emptyRowsRenderer={NoRecordFound}
                                />
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button
                            variant='danger'
                            onClick={() => handleDelete(selectedRows)}
                            className='text-white'
                            disabled={selectedRows.size > 0 ? false : true}
                        >
                            <i className='fa-light fa-xmark' /> Remove Selected
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ManageLockPage;
