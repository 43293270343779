import React from 'react';
import Nav from 'react-bootstrap/Nav';
import DataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import { saveAs } from 'save-as';
import TablePagination from '@material-ui/core/TablePagination';
import { ImagePreviewTooltip } from './ImagePreviewTooltip';
import { MultipleSelection } from './MultipleSelection';
import { getCustomSearchConfig } from '../../_redux/actions/configActions';

// import DetailBigView from './DetailBigView';
// import { DocumentOptions } from './DocumentOptions';
import { Notes } from './Notes';
import {
    getRelatedDocumentList,
    downloadDoc,
    configurationCustomeSearchId,
} from '../../api/api';
import { SelectColumn } from './RelatedDocColumn';
import {
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    GetFieldData,
    NoRecordFound,
    getNotes,
    updateCustomSearchConfigDataInLocalStorage,
} from '../../../components/commonfunction';
//import PrintPdfDocument from './PrintPdfDocument';
// import AllowMultipleEditModal from '../../../components/datagrid/AllowMultipleEditModal';
import DocumentDetailViewComponent from './DocumentDetailViewComponent';
import { inboxPage, showLoader } from '../../_redux/actions/inboxActions';
import { deletedPage } from '../../_redux/actions/searchActions';

// import { ThemeContext } from '../InboxPage';

import Office365AuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
import GmailAuthenticationPage from '../../../components/composeDraftMail/GmailAuthenticationPage';
import { DocumentIdToPrint } from '../../../../utils/printPdf/printPdf';
//import { Spinner } from 'react-bootstrap';

const _ = require('lodash');
// import { handleNotesSubmit } from '../../../components/commonfunction';

class RelatedDocument extends React.Component {
    // static contextType = ThemeContext;
    constructor(props) {
        super(props);
        this.state = {
            toggleRecentDoc: false,
            selectedDoc: [],
            openNote: false,
            docIds: [],
            relatedDocColumns: [],
            relatedDocRows: [],
            selectedRows: new Set(),
            selectedDocIds: [],
            // popOverPosition: { left: ''},
            printDoc: [],
            currentPage: 0,
            perPage: 10,
            // selectedRows:[],
            // notesSubmitted: 0,
            byOrderRowId: '',
            byBatchRowId: '',
            activeTab: '',
            filterTxt: '',
            refreshDataGrid: false,
            filteredData: [],
            openPopover: false,
            noteTypes: [],
            refreshTab: true,
            onHide: true,
            serviceType: false,
            byBatchCount: '',
            byOrderCount: '',
        };
        this.getByBatchData = this.getByBatchData.bind(this);
        this.getByOrderData = this.getByOrderData.bind(this);
    }

    async componentDidMount() {
        const customSearchNamedIdsNeedingConfiguration = this.props
            .configReducerPersist.customSearchNamedIdsNeedingConfiguration
            ? this.props.configReducerPersist
                  .customSearchNamedIdsNeedingConfiguration
            : [];
        if (customSearchNamedIdsNeedingConfiguration.length !== undefined) {
            await customSearchNamedIdsNeedingConfiguration.map(async (item) => {
                if (item.name === 'By Order' || item.name === 'By Batch') {
                    await this.configurationCustomeSearchId(item.id);
                }
            });
        }

        const serviceType =
            this.props.configData?.emailIntegration?.serviceType ===
                'Office 365' ||
            this.props.configData?.emailIntegration?.serviceType === 'Google'
                ? true
                : false;
        this.setState({ serviceType }); // if service type is "Exchange" will implement later
        var rowDetails = this.props.selectedRowDetails.fields;
        let fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        var onRowClickId;
        if (this.props.selectedRowDetails.hasOwnProperty('miscPproperties')) {
            onRowClickId = this.props.selectedRowDetails.miscPproperties.docId;
        }
        this.setState({ onRowClickId: onRowClickId });
        if (rowDetails !== undefined) {
            rowDetails.map((data) => {
                if (data.fieldId === fieldDataObj['batch']) {
                    this.setState({ byBatchRowId: data.value });
                }
                if (data.fieldId === fieldDataObj['order_number']) {
                    this.setState({ byOrderRowId: data.value });
                }
            });
        }
        var customSearchQueries = [];
        customSearchQueries = this.props.customSearchQueries;

        await customSearchQueries.map((data) => {
            if (data.name === 'By Batch') {
                this.setState({ byBatchSearchId: data.searchId });
            }
            if (data.name === 'By Order') {
                this.setState({ byOrderSearchId: data.searchId });
            }
        });

        // if (this.state.byOrderRowId !== '') {
        //     this.getByOrderData(true);
        // } else if (this.state.byBatchRowId !== '') {
        //     this.getByBatchData(true);
        // }
        // if (this.state.byOrderRowId !== '' && this.state.byBatchRowId !== '') {
        //     this.getByBatchData(true);
        // }
        var currentPath = window.location.pathname.split('/');
        let processId;
        let statusId;
        if (currentPath[1] === 'search') {
            processId = this.props.deleted_Page.processId;
            statusId = this.props.deleted_Page.statusId;
        } else {
            processId = this.props.inbox.processId;
            statusId = this.props.inbox.statusId;
        }
        let noteTypes = getNotes(this.props.configData, processId, statusId);
        this.setState({
            noteTypes: noteTypes,
        });
        if (this.state.byBatchRowId !== '' && this.state.byOrderRowId !== '') {
            await this.getByOrderData(false);
            await this.getByBatchData(true);
            await this.setState({
                defaultByorder: 1,
                activeTab: 'batch',
                refreshDataGrid: false,
            });
            setTimeout(() => {
                this.setState({
                    onHide: false,
                    activeTab: 'batch',
                    refreshDataGrid: true,
                });
            }, 3000);
        } else if (
            this.state.byBatchRowId !== '' &&
            this.state.byOrderRowId === ''
        ) {
            await this.getByBatchData(true);
            this.setState({
                defaultByorder: 1,
                activeTab: 'batch',
                onHide: false,
            });
        } else if (
            this.state.byBatchRowId === '' &&
            this.state.byOrderRowId !== ''
        ) {
            await this.getByOrderData(true);
            this.setState({
                defaultByorder: 0,
                activeTab: 'order',
                onHide: false,
            });
        } else {
            this.setState({
                refreshDataGrid: '',
                onHide: false,
            });
        }
    }

    configurationCustomeSearchId = (id) => {
        configurationCustomeSearchId(id)
            .then(async (data) => {
                const customSearches = data.customSearchQueries;
                const customSearchNamedIdsNeedingConfiguration =
                    data.customSearchNamedIdsNeedingConfiguration;

                updateCustomSearchConfigDataInLocalStorage(
                    customSearches,
                    customSearchNamedIdsNeedingConfiguration
                );

                if (this.props.getCustomSearchConfig) {
                    await this.props.getCustomSearchConfig(
                        customSearches,
                        customSearchNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    };

    getByOrderData = (change_data) => {
        this.setState({
            refreshDataGrid: false,
            defaultByorder: 2,
        });
        this.resetDataGrid();
        let fieldId = GetFieldData({
            configData: this.props.configData,
        });
        var byOrderSearchText;
        // byOrderSearchText ='fieldSearch=' + fieldId.order_number + '%3D340129';
        byOrderSearchText =
            'bbb=1&fieldSearch=' +
            fieldId.order_number +
            '%3D' +
            this.state.byOrderRowId;

        if (this.state.byOrderSearchId !== undefined) {
            getRelatedDocumentList(
                this.state.byOrderSearchId,
                byOrderSearchText
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.dataTable !== undefined) {
                        var length = 0;
                        let newRows = [];
                        if ('dataRows' in data.dataTable) {
                            data.dataTable.dataRows.map((item) => {
                                if (
                                    String(item.id) !==
                                    String(this.props.selectedDocId)
                                ) {
                                    newRows.push(item.id);
                                }
                            });
                            length = newRows.length;
                        }
                        this.setState({ byOrderCount: length });
                        if (change_data === true) {
                            this.setState({ activeTab: 'order' });
                            this.setState({
                                totalCount: length,
                                //refreshTab: false,
                            });
                            setTimeout(() => {
                                //this.setState({ refreshTab: true });
                            }, 1000);
                            this.getRelatedDocData(data.dataTable, 'byorder');
                        }
                    }

                    /*if (data.hasOwnProperty('dataTable') && data.dataTable !== '') {
                    var length;
                    if (data.dataTable.hasOwnProperty('dataRows')) {
                        length = data.dataTable.dataRows.length;
                    }
                
                	
                	
                   this.setState({
                        byOrderCount: length,
                        totalCount: length,
                        refreshTab: false,
                    });
                    setTimeout(() => {
                        this.setState({ refreshTab: true });
                    }, 1000);
                    this.getRelatedDocData(data.dataTable, 'byorder');
                	
                }*/
                });
        } else {
            this.setState({
                refreshDataGrid: true,
                byOrderCount: 0,
            });
        }
    };

    resetDataGrid = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    getByBatchData = (change_data) => {
        if (!this.props.isLocked) {
            this.setState({
                refreshDataGrid: false,
                defaultByorder: 2,
            });
            this.resetDataGrid();
            let fieldId = GetFieldData({
                configData: this.props.configData,
            });
            var byBatchSearchText;
            //  byBatchSearchText = 'fieldSearch=' + fieldId.batch + '%3D150';
            byBatchSearchText =
                'aaa=1&fieldSearch=' +
                fieldId.batch +
                '%3D' +
                this.state.byBatchRowId;

            if (this.state.byBatchSearchId !== undefined) {
                getRelatedDocumentList(
                    this.state.byBatchSearchId,
                    byBatchSearchText
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.dataTable !== undefined) {
                            var length = 0;
                            let newRows = [];
                            if ('dataRows' in data.dataTable) {
                                data.dataTable.dataRows.map((item) => {
                                    if (
                                        String(item.id) !==
                                        String(this.props.selectedDocId)
                                    ) {
                                        newRows.push(item.id);
                                    }
                                });
                                length = newRows.length;
                            }
                            this.setState({ byBatchCount: length });
                            if (change_data === true) {
                                this.setState({ activeTab: 'batch' });
                                this.setState({
                                    totalCount: length,
                                    //refreshTab: false,
                                });
                                setTimeout(() => {
                                    //this.setState({ refreshTab: true });
                                }, 10);
                                this.getRelatedDocData(
                                    data.dataTable,
                                    'bybatch'
                                );
                            }
                        }
                    });
            } else {
                this.setState({
                    refreshDataGrid: true,
                    byBatchCount: 0,
                });
            }
        }
    };

    handleClick = async () => {
        await this.setState({ openPopover: true });
    };

    actionMenu = (id) => {
        // const id = this.state.openPopover ? 'simple-popover-' : undefined;
        return (
            <div className='d-flex related_detail_icon align-items-center rel_action_menu'>
                {this.state.noteTypes.length !== 0 && (
                    <span
                        title='Note'
                        onClick={() => {
                            this.openNotes(id);
                        }}
                        // style={
                        //     this.props.callingFrom === 'related_document'
                        //         ? { cursor: 'not-allowed' }
                        //         : {}
                        // }
                    >
                        <i className='fa-solid fa-sticky-note' />
                    </span>
                )}
                {/* <DocumentOptions
                    docId={863}
                    toggleLoader={this.props.toggleSpinner}
                /> */}
                {/* <Button style={{ backgroundColor: "white" }} aria-describedby={id} variant="contained" onClick={this.handleClick}> */}
                <span
                    onClick={this.handleClick}
                    // style={
                    //     this.props.callingFrom === 'related_document'
                    //         ? { cursor: 'not-allowed' }
                    //         : {}
                    // }
                    className={'settingsmenu' + id}
                    variant='success'
                    disabled={true}
                    //  id={'settingsmenu' + id}
                    title='Settings'
                >
                    <i className='fa fa-ellipsis-v' aria-hidden='true' />
                </span>
                {/* </Button> */}
            </div>
        );
    };

    imagePreview = (id, displayText) => {
        return <ImagePreviewTooltip docId={id} displayText={displayText} />;
    };

    getRelatedDocData = async (data, tab) => {
        this.setState({ relDocLoading: true });
        var rows = [];
        const lowerCase = [];
        // let uniqueData = {};
        let header = [];
        // header.push(SelectColumn)
        if (data !== undefined) {
            const { dataRows, headerColumns } = data;

            var actionArray = {
                name: 'Action',
                fieldId: '',
                datatype: 'AlphaNumeric',
            };
            await headerColumns.push(actionArray);
            await headerColumns.forEach((data, index) => {
                let headerObj = {
                    key: formatHeaderNameWithOutSpace(data.name),
                    name: data.name,
                    datatype: data.datatype,
                    fieldId: data.fieldId,
                    resizable: false,
                    sortable: false,
                    // width: 123,
                    // colSpan(args) {

                    //     return args.type === 'ROW' && args.row.branch === "BBB" ? 3 : undefined;
                    // }
                };
                header.push(headerObj);
            });
            header = [SelectColumn].concat(header);
            var g = 0;
            await dataRows.forEach((data, index) => {
                g = g + 1;
                const lowercaseObj = {};
                const obj = {};
                obj['id'] = data.id;
                // obj['idkal'] = data.id +''+ g;

                // obj['id'] = index;
                lowercaseObj['id'] = data.id;
                var array = data.values;
                array.splice(0, 0, '');
                array.push('');
                array.forEach((row, i) => {
                    if (header[i] !== undefined) {
                        obj[header[i].key] = row.trim();
                        lowercaseObj[header[i].key] =
                            row.trim().toLowerCase() + header[i].key;
                        switch (header[i].key) {
                            case 'action':
                                obj[header[i].key] = this.actionMenu(data.id);
                                break;
                            case 'emrordertype':
                                process.env.REACT_APP_ENV === 'dev'
                                    ? (obj[header[i].key] = '#' + data.id)
                                    : row.trim(); // kalpana
                                break;
                            case 'documenttype':
                                obj[header[i].key] = this.imagePreview(
                                    data.id,
                                    row.trim()
                                );
                                // obj[header[i].key] = "s"
                                lowercaseObj[header[i].key] = row
                                    .trim()
                                    .toLowerCase();
                                break;
                            default:
                                break;
                        }
                    }
                });

                lowerCase.push(lowercaseObj);

                if (String(data.id) !== String(this.props.selectedDocId)) {
                    // kalpana worked to suppress parent doc id from RD listing
                    rows.push(obj);
                }

                if (tab === 'byorder') {
                    this.setState({ byOrderCount: rows.length });
                } else {
                    this.setState({ byBatchCount: rows.length });
                }
                this.setState({ totalCount: rows.length });
            });
        }
        rows = rows.filter((value) => {
            return String(value.id) !== String(this.props.selectedDocId);
        });
        this.setState(
            {
                relDocLoading: false,
                relatedDocColumns: header,
                relatedDocRows: rows,
                allRows: rows,
                allRowsLoweCase: lowerCase,
                // refreshDataGrid:true
            },
            () => {
                let paginatedRowData = this.state.relatedDocRows;
                // let paginatedRowData = rows;
                paginatedRowData = _.slice(paginatedRowData, 0, 10);

                this.setState({
                    refreshDataGrid: true,
                    relatedDocRows: paginatedRowData,
                });
            }
        );
    };

    openNotes = (docId) => {
        this.setState({
            openNote: true,
            docIds: [docId],
        });
    };

    componentDidUpdate(prevState, nextState) {
        if (prevState.selectedDocId !== this.props.selectedDocId) {
            this.setState({
                printSelectedDocId: [],
                toggleRecentDoc: false,
                selectedDoc: [],
                openNote: false,
                docIds: [],
                notesSubmitted: 0,
            });
            if (
                this.state.byBatchRowId !== '' &&
                this.state.byOrderRowId !== ''
            ) {
                this.setState({
                    defaultByorder: 1,
                    activeTab: 'batch',
                });
            } else if (
                this.state.byBatchRowId !== '' &&
                this.state.byOrderRowId === ''
            ) {
                this.setState({
                    defaultByorder: 1,
                    activeTab: 'batch',
                });
            } else {
                this.setState({
                    defaultByorder: 0,
                    activeTab: 'order',
                });
            }
        }

        // print

        if (nextState.printDoc !== this.state.printDoc) {
            if (this.state.printDoc.length > 0) {
                DocumentIdToPrint(this.state.printDoc)
                    .then(() => {
                        this.props.showLoader(false);
                    })
                    .catch((err) => {
                        console.log('err', this.props.showLoader(true));
                        this.props.showLoader(false);
                    });
            }
        }
    }

    closeMultiSelect = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    onRowClick = (rowDetails, rowHeader) => {
        if (String(rowHeader.key) === 'action') {
            this.setState(
                {
                    selectedDocIds: rowDetails.id,
                },
                async () => {
                    let actionButton = document.querySelectorAll(
                        `.settingsmenu${this.state.selectedDocIds}`
                    );
                    let elementPosition =
                        actionButton[0].getBoundingClientRect(); // Get PopOver Position
                    var position = {
                        top: parseInt(elementPosition.top),
                        left: parseInt(elementPosition.x),
                    };
                    await this.setState({ popOverPosition: position });
                }
            );
        }

        if (this.props.callingFrom === 'related_document') {
            return true;
        }
        // lockDocument(this.props.selectedDocId)
        // .then((data) => {})
        // .catch((err) => {});

        if (
            String(rowHeader.key) === 'documenttype' ||
            String(rowHeader.key) === 'branch' ||
            String(rowHeader.key) === 'documentname'
        ) {
            this.setState(
                {
                    selectedDocIds: rowDetails.id,
                },
                () => {
                    if (this.props.myParent === 'bigview') {
                        this.props.inboxPage({ related_doc_from: 'bigview' });
                    }
                    var currentPath = window.location.pathname.split('/');

                    if (currentPath[1] !== 'search') {
                        this.props.inboxPage({ related_doc_detail: true });
                        this.props.history.push({
                            pathname: '/' + currentPath[1] + '/detail',
                            // '/detail/' +
                            // window.btoa(String(this.state.selectedDocIds)),
                            search:
                                '?detail=' +
                                window.btoa(String(this.state.selectedDocIds)),
                            state: {
                                selectedDocID: window.btoa(
                                    String(this.state.selectedDocIds)
                                ),
                                processId: this.props.inbox.processId,
                                statusId: this.props.inbox.statusId,
                            },
                        });
                    } else {
                        this.props.history.push({
                            pathname: '/' + currentPath[1] + '/detail',
                            // '/detail/' +
                            // window.btoa(String(this.state.selectedDocIds)),
                            search:
                                '?detail=' +
                                window.btoa(String(this.state.selectedDocIds)),
                            state: {
                                selectedDocID: window.btoa(
                                    String(this.state.selectedDocIds)
                                ),
                                processId: this.props.deleted_Page.processId,
                                statusId: this.props.deleted_Page.statusId,
                            },
                        });
                        this.props.deletedPage({
                            onlySearch: true,
                        });
                    }

                    // window.history.pushState("", "", "/detail");
                    // this.props.history.push({
                    //     pathname:
                    //         '/detail/' +
                    //         window.btoa(String(this.state.selectedDocIds)),
                    //     state: {
                    //         processId: this.props.inbox.processId,
                    //         statusId: this.props.inbox.processId,
                    //     },
                    // });
                }
            );
        }
    };

    popoverHandleClose = () => {
        this.setState({
            openPopover: false,
        });
    };

    print = () => {
        var array = [];
        array.push(this.state.selectedDocIds);
        this.props.showLoader(true);
        this.setState({
            printDoc: array,
        });
    };

    download = () => {
        if (this.state.selectedDocIds === '') {
            return false;
        }
        downloadDoc(this.state.selectedDocIds)
            .then((response) => {
                return response.blob();
            })
            .then((data) => {
                saveAs(data, 'Document.pdf');
            });
    };
    nextPage(event, newPage) {
        let startIndex = parseInt(newPage) * parseInt(this.state.perPage);
        let endIndex = startIndex + parseInt(this.state.perPage);
        var paginatedRowData;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }
        // paginatedRowData = this.state.allRows;
        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            relatedDocRows: paginatedRowData,
            currentPage: newPage,
        });
    }
    handleChangeRowsPerPage(event) {
        let startIndex = 0;
        let endIndex = parseInt(event.target.value);
        let paginatedRowData = this.state.allRows;

        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            relatedDocRows: paginatedRowData,
            perPage: endIndex,
            currentPage: 0,
        });
    }

    handleSearch = (searchText) => {
        this.setState({
            refreshDataGrid: false,
            relDocLoading: true,
        });
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
        // const isValid = (str) => {
        //     return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
        // };

        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        let filteredData = _.filter(this.state.allRows, function (data) {
            // filter Operation
            let passed = false;
            for (let property in data) {
                if (property === 'id') {
                    continue;
                } // Excluding few Property from filter
                passed =
                    String(data[property]).toLowerCase().match(inputData) !=
                        null &&
                    String(data[property]).toLowerCase().match(inputData)
                        .length > 0;
                if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
            }
            return passed;
        });
        var length = filteredData.length;

        var paginatedRowData = _.slice(filteredData, 0, 10);
        this.setState({
            filteredData: filteredData,
            relDocLoading: false,
            relatedDocRows: paginatedRowData,
            // allRows: filteredData,
            totalCount: length,
            currentPage: 0,
            perPage: 10,
            refreshDataGrid: true,
        });
    };

    sendDraft = async () => {
        let serviceType = this.props.configData?.emailIntegration?.serviceType;
        if (serviceType === 'Office 365') {
            // check to authenticate draft mail
            Office365AuthenticationPage({
                selectedDocID: this.state.selectedDocIds,
                configData: this.props.configData,
            });
        } else {
            GmailAuthenticationPage({
                selectedDocID: this.state.selectedDocIds,
                configData: this.props.configData,
            });
        }
    };

    render() {
        // if (this.props.callingFrom === 'related_document') {
        //     this.state.relatedDocColumns.shift();
        // }
        const id = this.state.openPopover ? 'simple-popover-' : undefined;
        const toggleRecentDoc = () => {
            this.setState({
                toggleRecentDoc: !this.state.toggleRecentDoc,
            });
        };

        const handleReset = () => {
            this.setState({
                openNote: false,
            });
        };

        var temp = [];
        //temp.push(this.state.onRowClickId);
        const temps = [
            this.state.onRowClickId,
            ...Array.from(this.state.selectedRows),
        ];
        var multiSelectId = [...new Set(temps)];

        return (
            <>
                <div
                    className={
                        this.props.isLocked
                            ? 'realted_document locked-screen'
                            : 'realted_document'
                    }
                >
                    <div
                        className={
                            this.state.toggleRecentDoc
                                ? 'with-bg-recent header_row d-flex align-items-stretch justify-content-between'
                                : 'header_row d-flex align-items-stretch justify-content-between no-bg-recent'
                        }
                        onClick={() =>
                            this.props.isLocked === true
                                ? null
                                : toggleRecentDoc()
                        }
                    >
                        <div className='header_title'>
                            <i
                                className='fa-regular fa-link'
                                aria-hidden='true'
                            />
                            Related Documents
                        </div>
                        <div
                            className='dropdown_icon'
                            onClick={() =>
                                this.props.isLocked === true
                                    ? null
                                    : toggleRecentDoc()
                            }
                        >
                            <i
                                title={
                                    this.state.toggleRecentDoc
                                        ? 'Close'
                                        : 'Open'
                                }
                                className={
                                    this.state.toggleRecentDoc
                                        ? 'fa-regular fa-chevron-up'
                                        : 'fa-regular fa-chevron-down'
                                }
                                aria-hidden='true'
                            />
                        </div>
                    </div>
                    {this.state.onHide === false ? (
                        <>
                            <div
                                className={
                                    this.state.toggleRecentDoc
                                        ? 'open-recent-doc'
                                        : 'close-recent-doc'
                                }
                            >
                                <div className='grid_table'>
                                    {this.state.refreshDataGrid === '' ? (
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                marginTop: '30px',
                                            }}
                                        >
                                            No Documents Found
                                        </div>
                                    ) : null}
                                    <div className='realted_content aaa d-flex align-items-stretch justify-content-between'>
                                        <div className='tab'>
                                            {this.state.refreshTab ? (
                                                <Nav
                                                    variant='pills'
                                                    defaultActiveKey={
                                                        this.state.activeTab
                                                    }
                                                >
                                                    {this.state.byBatchRowId !==
                                                    '' ? (
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                title='By Batch'
                                                                eventKey='batch'
                                                                key='2'
                                                                onClick={
                                                                    this.state
                                                                        .byOrderRowId !==
                                                                    ''
                                                                        ? () => {
                                                                              this.getByBatchData(
                                                                                  true
                                                                              );
                                                                          }
                                                                        : null
                                                                }
                                                                className={
                                                                    this.state
                                                                        .defaultByorder ===
                                                                    1
                                                                        ? 'active'
                                                                        : ''
                                                                }
                                                            >
                                                                By Batch{' '}
                                                                <span className='count'>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .byBatchCount
                                                                    }
                                                                </span>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ) : null}
                                                    {this.state.activeTab !==
                                                        '' &&
                                                    this.state.byOrderRowId !==
                                                        '' ? (
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                title=' By Order'
                                                                eventKey='order'
                                                                key='1'
                                                                onClick={() =>
                                                                    this.state
                                                                        .byBatchRowId !==
                                                                    ''
                                                                        ? this.getByOrderData(
                                                                              true
                                                                          )
                                                                        : null
                                                                }
                                                                className={
                                                                    this.state
                                                                        .defaultByorder ===
                                                                    0
                                                                        ? 'active'
                                                                        : ''
                                                                }
                                                            >
                                                                By Order{' '}
                                                                <span className='count'>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .byOrderCount
                                                                    }
                                                                </span>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ) : null}
                                                </Nav>
                                            ) : null}
                                        </div>
                                        {this.state.byOrderRowId !== '' &&
                                        this.state.activeTab === 'order' ? (
                                            <div className='d-flex align-items-center'>
                                                <span>
                                                    Order #
                                                    {this.state.byOrderRowId}
                                                </span>
                                            </div>
                                        ) : null}
                                        {this.state.byBatchRowId !== '' &&
                                        this.state.activeTab === 'batch' ? (
                                            <div className='d-flex align-items-center'>
                                                <span>
                                                    Batch #
                                                    {this.state.byBatchRowId}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                    {this.state.refreshDataGrid === false && (
                                        <div className='d-flex justify-content-center'>
                                            <div
                                                className='spinner-border'
                                                role='status'
                                            >
                                                <span className='sr-only'>
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.refreshDataGrid ? (
                                        <>
                                            <DataGrid
                                                columns={
                                                    this.state.relatedDocColumns
                                                }
                                                rows={this.state.relatedDocRows}
                                                selectedRows={
                                                    this.state.selectedRows
                                                }
                                                onSelectedRowsChange={(
                                                    rows
                                                ) => {
                                                    let selectedRows = rows;
                                                    this.setState({
                                                        selectedRows:
                                                            selectedRows,
                                                        // selectedDocIds: selectedRows
                                                    });
                                                }}
                                                headerRowHeight={0}
                                                rowHeight={52}
                                                // summaryRowHeight={20}
                                                rowKeyGetter={(row) => {
                                                    return row.id;
                                                }}
                                                onRowClick={(
                                                    rowDetails,
                                                    rowHeader,
                                                    stateData
                                                ) => {
                                                    this.onRowClick(
                                                        rowDetails,
                                                        rowHeader,
                                                        stateData
                                                    );
                                                }}
                                                emptyRowsRenderer={NoRecordFound.bind(
                                                    this,
                                                    this.state.relDocLoading
                                                )}
                                                style={{
                                                    borderRight: '50px',
                                                }}
                                                className='related-doc-grid'
                                            />
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            {this.state.refreshDataGrid ? (
                                <div
                                    style={{
                                        display: this.state.toggleRecentDoc
                                            ? 'block'
                                            : 'none',
                                    }}
                                >
                                    <TablePagination
                                        component='div'
                                        count={this.state.totalCount}
                                        page={parseInt(this.state.currentPage)}
                                        rowsPerPage={parseInt(
                                            this.state.perPage
                                        )}
                                        onRowsPerPageChange={(event) =>
                                            this.handleChangeRowsPerPage(event)
                                        }
                                        onPageChange={this.nextPage.bind(this)}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <div
                                className={
                                    this.state.toggleRecentDoc
                                        ? 'open-recent-doc'
                                        : 'close-recent-doc'
                                }
                            >
                                <div className='d-flex justify-content-center'>
                                    <div
                                        className='spinner-border'
                                        role='status'
                                    >
                                        <span className='sr-only'>
                                            Loading...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <Notes
                    openNote={this.state.openNote}
                    docId={this.state.selectedDocIds}
                    handleReset={handleReset}
                    // handleNotesSubmit={(notes,notesValue,notesDDL,docId)=>{handleNotesSubmit.call(this,notes,notesValue,notesDDL,docId)}}
                    // notesSubmitted ={this.state.notesSubmitted}
                />

                {Array.from(this.state.selectedRows).length > 0 ? (
                    <MultipleSelection
                        showLoader={(value) => {
                            this.props.showLoader(value);
                        }}
                        onRowClickId={this.state.onRowClickId}
                        selectedDoc={Array.from(this.state.selectedRows)}
                        documentCount={`Current file + ${
                            Array.from(this.state.selectedRows).length
                        } related file selected`}
                        docIds={multiSelectId}
                        closefn={this.closeMultiSelect}
                        noteTypes={this.state.noteTypes}
                        calingFrom='RelatedDocuments'
                    />
                ) : null}
                <Popover
                    id={id}
                    open={this.state.openPopover}
                    anchorReference='anchorPosition'
                    anchorPosition={this.state.popOverPosition}
                    // anchorEl={anchorEl}
                    onClose={this.popoverHandleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>
                        <div className='rel-dropdown-menu'>
                            <div
                                title='Print'
                                onClick={() => {
                                    this.print();
                                    this.popoverHandleClose();
                                }}
                                className='rel-dropdown-item print-dropdown'
                            >
                                <i className='fas fa-print' />
                                Print
                            </div>
                            <div
                                title='Download'
                                onClick={() => {
                                    this.download();
                                    //this.popoverHandleClose();
                                }}
                                className='rel-dropdown-item'
                            >
                                <i className='fas fa-download' />
                                Download
                            </div>
                            {this.state.serviceType ? (
                                <div
                                    title='send via email'
                                    onClick={() => {
                                        this.sendDraft();
                                        //this.popoverHandleClose();
                                    }}
                                    className='rel-dropdown-item'
                                >
                                    <i className='fa-solid fa-envelope' />
                                    Send via email
                                </div>
                            ) : null}
                        </div>
                    </Typography>
                </Popover>
                {/**Solved by another way */}
                {/* {this.state.printDoc.length > 0 ? (
                    <PrintPdfDocument docId={this.state.printDoc} />
                ) : null} */}

                {this.state.relDocView ? (
                    <DocumentDetailViewComponent
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        processId={this.props.inbox.processId}
                        statusId={this.props.inbox.statusId}
                    />
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    inboxPage,
    deletedPage,
    showLoader,
    getCustomSearchConfig: getCustomSearchConfig,
};
const mapStateToProps = (state, ownProps) => {
    return {
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        configData: state.configReducerPersist.configData,
        configReducerPersist: state.configReducerPersist,
        inbox: state.inbox,
        deleted_Page: state.search.deletedPage,
        selectedRowDetails: state.inbox.selectedRowDetails,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RelatedDocument));
