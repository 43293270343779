import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import {
    outboxDocumentAction,
    getDocumentMetaWithDocId,
    recordSetSearch,
} from '../../api/api';
import {
    multiSelectErrorMessage,
    showSuccessToast,
    RefreshCounts,
    showErrorToast,
    BranchIdSearch,
    encodeParameter,
} from '../../../components/commonfunction';
import {
    getCountData,
    getListaction,
    getSelectedRowDetails,
    updateInboxState,
} from '../../_redux/actions/inboxActions';
//This is used for RefreshCounts while passing props}

class ExportMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markAsAttachmentSuccessAndRemove: {},
            restartRetryExport: {},
        };
    }

    async componentDidMount() {
        await this.setState({
            markAsAttachmentSuccessAndRemove:
                this.props.permission?.allActions
                    ?.mark_as_attachment_success_and_remove,
            restartRetryExport:
                this.props.permission?.allActions?.restart_retry_export,
        });
    }
    componentDidUpdate(prevProps, prevState) {}
    getBranchId(value) {
        let branchFiledId = '';
        this.props.config_data.fields.forEach((data) => {
            if (data.name === 'Branch') {
                branchFiledId = data.fieldId;
            }
        });

        const searchText = `fieldSearch=${branchFiledId}=${encodeParameter(
            value
        )}`;
        let recordsetId = '';
        this.props.configuredRecordsets.forEach((data) => {
            if (data.name === 'Branch Data') {
                recordsetId = data.recordsetId;
            }
        });

        return recordSetSearch(recordsetId, searchText)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error('Error in recordSetSearch');
            })
            .then((data) => {
                if (data.dataTable !== undefined) {
                    const { dataRows, headerColumns } = data.dataTable;
                    let agency_idIndex = '';

                    headerColumns.forEach((data, index) => {
                        if (data.name === 'AgencyID') {
                            agency_idIndex = index;
                        }
                    });
                    for (const row of dataRows) {
                        const agencyValue = row.values[agency_idIndex];

                        if (agencyValue !== '') {
                            this.setState({
                                agencyValue: agencyValue,
                            });
                        }
                    }
                }

                return BranchIdSearch(data);
            });
    }
    getSingleOrder = async (ordernumber, orderFiledId) => {
        if (orderFiledId) {
            let orderSearchText =
                (orderSearchText = `fieldSearch=${orderFiledId}%3D${ordernumber}%2A`);

            let recordsetId = '';
            this.props.configuredRecordsets.forEach((data) => {
                if (data.name === 'Order Data') {
                    recordsetId = data.recordsetId;
                }
            });
            const response = await recordSetSearch(
                recordsetId,
                orderSearchText
            );
            if (response.status === 200) {
                const data = await response.json();
                if (data.dataTable !== undefined) {
                    const { dataRows, headerColumns } = data.dataTable;

                    let order_idIndex = '';
                    let orderId = '';
                    headerColumns.forEach((data, index) => {
                        if (data.name === 'Order ID') {
                            order_idIndex = index;
                        }
                    });
                    for (const row of dataRows) {
                        orderId = row.values[order_idIndex];
                    }

                    return orderId;
                }
            }
            return null;
        }
    };
    removeDuplicates(data) {
        const seenFieldIds = new Set();
        const filteredData = data.filter((item) => {
            if (seenFieldIds.has(item.fieldId)) {
                return false;
            }
            seenFieldIds.add(item.fieldId);
            return true;
        });

        return filteredData;
    }
    async submitAllAction(actionName) {
        let FinalData;
        let filteredData;
        let actionSubmit = this.props.permission.allActions[actionName];
        let finalId = [];
        if (this.props.callingFrom === 'actionBar') {
            for (let property of this.props.stateData.selectedRows) {
                this.props.stateData.rowData.map((idx) => {
                    if (idx.id == property) {
                        finalId.push(idx.doc_id);
                    }
                });
            }
        } else {
            finalId.push(this.props.selectedRowDetails.miscPproperties.docId);
        }
        let filtered = [];

        let branchFiledId;
        let branchIdFieldId;
        let OrderIdFiledId;
        let OrderNumberFiledID;
        let AgencyIdFieldId;
        let branchValue;
        let orderValue;
        if (this.props.callingFrom !== 'actionBar') {
            await this.props.config_data.fields.forEach((data) => {
                if (data.name === 'Branch') {
                    branchFiledId = data.fieldId;
                } else if (data.name === 'AgencyID') {
                    AgencyIdFieldId = data.fieldId;
                } else if (data.name === 'Branch ID') {
                    branchIdFieldId = data.fieldId;
                } else if (data.name === 'Order ID') {
                    OrderIdFiledId = data.fieldId;
                } else if (data.name === 'Order Number') {
                    OrderNumberFiledID = data.fieldId;
                }
            });
            await this.props.selectedRowDetails.fields.map((data) => {
                if (data.fieldId === branchFiledId) {
                    branchValue = data.value;
                } else if (data.fieldId === OrderNumberFiledID) {
                    orderValue = data.value;
                }
            });
            if (orderValue && OrderNumberFiledID) {
                let orderId = await this.getSingleOrder(
                    orderValue,
                    OrderNumberFiledID
                );

                filtered.push({
                    fieldId: OrderIdFiledId,
                    value: orderId ? orderId : '',
                });
            }
            let branchID;

            if (branchValue) {
                await this.getBranchId(branchValue)
                    .then((branch_id) => {
                        branchID = branch_id;
                    })
                    .catch((error) => {
                        console.log('Error:', error);
                    });

                filtered.push({
                    fieldId: branchIdFieldId,
                    value: branchID,
                });
            }
            filtered.push({
                fieldId: AgencyIdFieldId,
                value: this.state.agencyValue,
            });
            let orderId = '';
            if (orderValue) {
                orderId = await this.getSingleOrder(orderValue);
                filtered.push({
                    fieldId: OrderIdFiledId,
                    value: orderId ? orderId : '',
                });
            }
            FinalData = this.props.selectedRowDetails.fields.concat(filtered);
            filteredData = this.removeDuplicates(FinalData);
        }

        const params = {};
        const fields = {};
        fields.fields =
            this.props.callingFrom !== 'actionBar' ? filteredData : [];
        fields.docIds = finalId;
        fields.newStatus = {
            processId: String(this.props.inbox.inbox.processId),
            statusId: String(this.props.inbox.inbox.statusId),
        };
        fields.actionId = actionSubmit.actionId;
        params.processId =
            this.props.calingFrom === 'deleted_document'
                ? this.props.permission.processId
                : this.props.inbox.inbox.processId;
        params.statusId =
            this.props.calingFrom === 'deleted_document'
                ? this.props.permission.statusId
                : this.props.inbox.inbox.statusId;
        params.actionId = actionSubmit.actionId;
        params.fields = fields;
        let successDocId = [];
        let failedDocId = [];
        await outboxDocumentAction(params)
            .then(async (response) => {
                let result = await response.json();
                return result;
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                            if (
                                this.props.callingFrom == 'detailView' &&
                                this.props.calingFrom !== 'deleted_document'
                            ) {
                                this.props.nextIdSelected();
                            }
                            if (
                                this.props.callingFrom == 'smallView' &&
                                this.props.calingFrom !== 'deleted_document'
                            ) {
                                await this.props.getListaction(
                                    this.props.inbox.inbox.processId,
                                    this.props.inbox.inbox.statusId
                                );
                                RefreshCounts(
                                    this.props.inbox.processId,
                                    this.props.inbox.statusId,
                                    this.props //Here count from parent and getCountData is passing
                                );
                                let nextDocId = '';
                                let rowsToDelete = [];
                                let getInitialkey = 0;
                                let currentIndex;
                                let isLocked;
                                this.props.stateData.rowData.map((idx, key) => {
                                    if (finalId == idx.doc_id) {
                                        getInitialkey = key + 1;
                                        currentIndex = idx.id;
                                    }

                                    if (
                                        getInitialkey == key &&
                                        getInitialkey != 0
                                    ) {
                                        rowsToDelete.push(
                                            this.props.stateData.rowData[key]
                                        );

                                        nextDocId = idx.doc_id;
                                    }
                                });

                                const response = getDocumentMetaWithDocId({
                                    docid: nextDocId,
                                });
                                response.then(async (data) => {
                                    await this.props.getSelectedRowDetails(
                                        data
                                    );
                                    isLocked = data.miscPproperties.isLocked;

                                    if (data.statuses.length > 1) {
                                        showErrorToast(
                                            `Error in processing Document ${data.miscPproperties.docId} . Please contact WorldView Customer Support for further assistance.`
                                        );
                                        this.props.doCollapse();
                                        return false;
                                    } else {
                                        this.props.updateState({
                                            selectedDocId: nextDocId,
                                            pdf_preview: false,
                                            selectedRowDetails: data,
                                            is_locked: isLocked,
                                            rowsToDelete: rowsToDelete,
                                        });
                                        setTimeout(() => {
                                            this.props.updateState({
                                                pdf_preview: true,
                                            });
                                        }, 1000);

                                        var rowStateData =
                                            this.props.stateData.rowData.slice();

                                        rowStateData.splice(currentIndex, 1);

                                        this.props.updateInboxState({
                                            rowData: rowStateData,
                                            selectedDocId: nextDocId,
                                            selectedRowDetails: data,
                                            is_locked: isLocked,
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                }
            });

        if (failedDocId.lenght > 0) {
            multiSelectErrorMessage(failedDocId);
        }
        if (successDocId.length > 0) {
            if (failedDocId.lenght > 0) {
                //empty
            } else {
                showSuccessToast('Process Successfully Completed.');
            }
        }
        if (this.props.callingFrom == 'actionBar') {
            await this.props.refreshTable();
            await RefreshCounts(
                this.props.inbox.processId,
                this.props.inbox.statusId,
                this.props //Here count from parent and getCountData is passing
            );
        }
    }

    render() {
        return (
            <>
                {this.props.permission?.allActions
                    ?.mark_as_attachment_success_and_remove ||
                this.props.permission?.allActions?.restart_retry_export ? (
                    <>
                        <Dropdown.Item
                            onClick={() => {
                                this.submitAllAction('restart_retry_export');
                            }}
                            title='Restart/Retry Export'
                        >
                            <span>
                                <i
                                    className={
                                        this.state.restartRetryExport.icon
                                            ? `fa ${this.state.restartRetryExport.icon}`
                                            : 'fa-solid fa-envelope'
                                    }
                                    style={{
                                        color: this.state.restartRetryExport
                                            .color
                                            ? this.state.restartRetryExport
                                                  .color
                                            : null,
                                    }}
                                />
                                Restart/Retry Export
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                this.submitAllAction(
                                    'mark_as_attachment_success_and_remove'
                                );
                            }}
                            className='big-menu-name'
                            title='Mark as Attachment Success and Remove'
                        >
                            <i
                                className={
                                    this.state.markAsAttachmentSuccessAndRemove
                                        .icon
                                        ? `fa ${this.state.markAsAttachmentSuccessAndRemove.icon}`
                                        : 'fa-solid fa-envelope'
                                }
                                style={{
                                    color: this.state
                                        .markAsAttachmentSuccessAndRemove.color
                                        ? this.state
                                              .markAsAttachmentSuccessAndRemove
                                              .color
                                        : null,
                                }}
                            />
                            <span>Mark as Attachment Success and Remove</span>
                        </Dropdown.Item>
                    </>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getCountData: getCountData,
    getListaction: getListaction,
    getSelectedRowDetails,
    updateInboxState,
};
const mapStateToProps = (state) => {
    return {
        inbox: state.inbox,
        stateData: state.inbox.stateData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        config_data: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ExportMenu))
);
