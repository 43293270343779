import parseCSVLine from "./parseCSVLine";

const validateCSVContent = (file: File): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const text = event.target?.result as string;
            const lines = text.split('\n').filter(line => line.trim() !== '');

            if (lines.length < 2) {
                resolve(false);
                return;
            }

            const header = parseCSVLine(lines[0]);
            if (header.length < 2) {
                resolve(false);
                return;
            }

            for (let i = 1; i < lines.length; i++) {
                const row = parseCSVLine(lines[i]);

                if (row.length !== header.length) {
                    resolve(false);
                    return;
                }
            }

            resolve(true);
        };

        reader.onerror = () => {
            reject(false);
        };

        reader.readAsText(file);
    });
};

export default validateCSVContent;