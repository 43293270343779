import { version, date } from '../../../../../package.json';

const ApplicationVersion = () => {
    return (
        <>
            <span>Version {version}</span>
            <br />
            <small>{date}</small>
        </>
    );
};

export default ApplicationVersion;
