/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { outboxDocumentAction } from '../../api/api';
import {
    showSuccessToast,
    showErrorToast,
    GetFieldData,
    stringEmptyOrUndefined,
    getDropDownDataFromApi,
    formatHeaderNameWithOutSpace,
} from '../../../components/commonfunction';
import {
    getCountData,
    getSelectedRowDetails,
    outboxPage,
} from '../../_redux/actions/outboxActions';
import { getDDLlist } from '../../_redux/actions/configActions';

export class CoverPageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledClass: 'disabled',
            commentTextCount: 250,
            add_Cover_Page: false,
            covercomments: '',
            coverBtn: false,
            postLoading: `post_loading_${Math.random()}`,
            submitted: 0,
            selectRowDetails: this.props.selectRowDetails,
            posted: `posted_${Math.random()}`,
            covercomments_original: '',
            cover_page_original: '',
            cover_page: '',
            errorMessage: false,
        };
    }

    addCoverPage = () => {
        this.setState({
            add_Cover_Page: true,
        });
    };

    closeAddCoverPage = () => {
        this.setState({
            add_Cover_Page: false,
            submitted: 0,
            cover_page: this.state.cover_page_original,
            covercomments: this.state.covercomments_original,
        });
    };

    componentDidMount() {
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldIds: fieldDataObj });
        this.getTransitonDropDownData();

        if (
            (this.props.calingFrom === 'smallView' ||
                this.props.calingFrom === 'bigView') &&
            this.props.selectRowDetails.hasOwnProperty('fields')
        ) {
            // eslint-disable-next-line no-unused-vars
            const datacover = this.props.selectRowDetails.fields.map(
                (rowData, index) => {
                    const fields = rowData.fieldId;

                    if (String(fields) === String(fieldDataObj.fax_comments)) {
                        this.setState({
                            covercomments: rowData.value,
                            covercomments_original: rowData.value,
                        });
                        if (rowData.value !== '')
                            this.setState({ disabledClass: '' });
                    }
                    if (String(fields) === String(fieldDataObj.cover_page)) {
                        this.setState({
                            cover_page: rowData.value,
                            cover_page_original: rowData.value,
                        });
                        if (rowData.value !== '')
                            this.setState({ disabledClass: '' });
                    }
                }
            );
        } else if (this.props.calingFrom === 'actionBar') {
            // const docIds = [];
            const selectedIndex = Array.from(
                this.props.outbox.stateData.selectedRows
            );

            this.props.outbox.stateData.rowData.forEach((data, index) => {
                if (selectedIndex.includes(data.id)) {
                    if (
                        data.fax_comments !== undefined &&
                        data.cover_page !== undefined
                    ) {
                        this.setState({
                            cover_page: data.cover_page,
                            covercomments: data.fax_comments,
                        });
                    }
                }
            });
        }
        const pathName = window.location.pathname.split('/');
        this.setState({
            currentURL: pathName[2],
        });
        // if(this.props.reportView === 'report'){
        //     allActions
        // }
    }

    getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('coverpage')) {
                getDropDownDataFromApi(fieldData.cover_page).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            }
            // coverpage DDL ends
        }
    }

    closeCover = () => {
        this.updateState({ open_cover_page: false });
    };

    updateState = (state) => {
        this.props.updateState(state);
    };

    handleFormOnChange = (state, event) => {
        if (this.props.calingFrom === 'upload') {
            this.props.coverpage_typeFunction(event.target.value);
        }
        this.setState({ disabledClass: 'disabled' });
        const value = event.target.value;
        this.setState({ [state]: value }, () => {
            this.updateState({ [state]: value });
        });

        if (event.target.value !== '') this.setState({ disabledClass: '' });
    };

    handleInputOnChange = (event) => {
        if (this.props.calingFrom === 'upload') {
            this.props.coverpage_commentFunction(event.target.value);
        }
        const covercomments = event.target.value;
        if (covercomments !== '') this.setState({ disabledClass: '' });
        const length = event.target.value.length;
        const count = 250 - length;
        this.setState({ covercomments: covercomments }, () => {
            /* if (covercomments == '') {
                //this.setState({ validateCreate: true });
                this.setState({ disabledClass: 'disabled' });
            } else {
                // this.setState({ validateCreate: false });
                this.setState({ disabledClass: '' });
            } */
            this.updateState({ covercomments: covercomments });
        });
        if (length > 250) {
            this.setState({
                commentTextCount: 0,
            });
        } else {
            this.setState({
                commentTextCount: count,
            });
        }
    };

    submitCoverPage = async (action_name) => {
        // validate
        this.setState({
            coverBtn: true,
            submitted: 1,
        });
        const covercomments = this.state.covercomments;

        let cover_page = this.state.cover_page;
        if (cover_page === undefined) {
            cover_page = '';
        }

        if (covercomments !== '' || cover_page !== '') {
            let actionId = '';
            if (
                this.props.deleteDocument !== 'deleted_document' &&
                this.props.outbox.stateData.hasOwnProperty('allActions') &&
                this.props.outbox.stateData.allActions[action_name] !==
                    undefined
            ) {
                actionId =
                    this.props.outbox.stateData.allActions[action_name]
                        .actionId;
            }
            if (
                this.props.deleteDocument === 'deleted_document' &&
                this.props.allActions[action_name] !== undefined
            ) {
                actionId = this.props.allActions[action_name].actionId;
            }

            const docIds = [];
            if (this.props.deleteDocument !== 'deleted_document') {
                const selectedIndex = Array.from(
                    this.props.outbox.stateData.selectedRows
                );

                this.props.outbox.stateData.rowData.map((data, index) => {
                    if (selectedIndex.includes(data.id)) {
                        docIds.push(data.doc_id);
                    }
                });
            }

            if (
                docIds.length === 0 &&
                this.props.deleteDocument !== 'deleted_document'
            ) {
                docIds.push(this.props.outbox.stateData.selectedDocId);
            }
            let processId = 1;
            let statusId = 1;
            if (String(this.props.processId) !== '') {
                processId = this.props.processId;
            }
            if (String(this.props.statusId) !== '') {
                statusId = this.props.statusId;
            }
            if (this.props.deleteDocument === 'deleted_document') {
                statusId = this.props.delstatusId;
                processId = this.props.delprocessId;
                docIds.push(this.props.selectedDocId);
            }

            const params = {};
            const fields = {};
            const coverfields = {};
            fields.docIds = docIds;
            coverfields.docIds = docIds;

            if (covercomments !== '') {
                fields.fields = [
                    {
                        fieldId: this.state.allFieldIds.fax_comments,
                        value: covercomments,
                    },
                ];
            } else {
                fields.fields = [];
            }

            if (cover_page !== '') {
                coverfields.fields = [
                    {
                        fieldId: this.state.allFieldIds.cover_page,
                        value: cover_page,
                    },
                ];
            } else {
                coverfields.fields = [];
            }
            let allowcomments = 0;
            let allowcoverpage = 0;

            if (
                this.props.deleteDocument !== 'deleted_document' &&
                this.props.outbox.stateData.hasOwnProperty('allActions') &&
                this.props.outbox.stateData.allActions.hasOwnProperty(
                    'update_cover_page_comments'
                )
            ) {
                allowcomments = 1;
            }
            if (
                this.props.deleteDocument !== 'deleted_document' &&
                this.props.outbox.stateData.hasOwnProperty('allActions') &&
                this.props.outbox.stateData.allActions.hasOwnProperty(
                    'update_cover_page'
                )
            ) {
                allowcoverpage = 1;
            }
            if (
                this.props.deleteDocument === 'deleted_document' &&
                this.props.allActions.update_cover_page_comments !== undefined
            ) {
                allowcomments = 1;
            }
            if (
                this.props.deleteDocument === 'deleted_document' &&
                this.props.allActions.update_cover_page !== undefined
            ) {
                allowcoverpage = 1;
            }

            const Id_comment = this.state.allFieldIds.fax_comments;
            const Id_coverpage = this.state.allFieldIds.cover_page;
            let r = 0;
            let commentsUpdated = 0;
            // eslint-disable-next-line no-unused-vars
            let coverUpdated = 0;

            let updateSelectedRow = false;
            if (
                this.props.isFrom &&
                this.props.isFrom === 'actionBar' &&
                this.props.selectRowDetails &&
                this.props.selectRowDetails.miscPproperties
            ) {
                if (
                    docIds.includes(
                        this.props.selectRowDetails.miscPproperties.docId
                    )
                ) {
                    updateSelectedRow = true;
                }
            } else {
                if (this.props.isFrom && this.props.isFrom === 'actionBar') {
                    //No action need to perform
                } else {
                    updateSelectedRow = true;
                }
            }
            if (updateSelectedRow === true) {
                if (
                    this.state.selectRowDetails &&
                    this.state.selectRowDetails.fields
                ) {
                    const selectRowDetailsArray =
                        this.state.selectRowDetails.fields;
                    const newstate = [...selectRowDetailsArray];
                    await selectRowDetailsArray.map((data) => {
                        if (data) {
                            if (data.fieldId === Id_comment) {
                                newstate[r] = {
                                    ...newstate[r],
                                    fieldId: Id_comment,
                                    value: covercomments,
                                };
                                commentsUpdated = 1;
                            }
                            if (data.fieldId === Id_coverpage) {
                                newstate[r] = {
                                    ...newstate[r],
                                    fieldId: Id_coverpage,
                                    value: cover_page,
                                };
                                coverUpdated = 1;
                            }

                            r++;
                        }
                    });
                    const len = this.props.selectRowDetails.fields.length;
                    let j = len;
                    if (commentsUpdated === 0) {
                        newstate[j + 1] = {
                            ...newstate[r + len],
                            fieldId: Id_comment,
                            value: covercomments,
                        };
                        j += 1;
                    }
                    if (coverUpdated === 0) {
                        newstate[j + 1] = {
                            ...newstate[r + len],
                            fieldId: Id_coverpage,
                            value: cover_page,
                        };
                        j += 1;
                    }

                    this.props.selectRowDetails.fields = newstate;
                    setTimeout(() => {
                        this.props.getSelectedRowDetails(
                            this.props.selectRowDetails
                        );
                    }, 500);
                }
            }

            params.processId = processId;
            params.statusId = statusId;
            params.actionId = actionId;
            params.fields = fields;
            let status = '';
            if (allowcomments === 0 && allowcoverpage !== 0) {
                this.callSubmitCoverPage(params, coverfields);
            } else {
                outboxDocumentAction(params)
                    .then((response) => {
                        status = response.status;
                        return response.json();
                    })
                    .then((data) => {
                        if (data.hadFailures === false && status === 200) {
                            this.updateState({ open_cover_page: false });

                            if (
                                (cover_page !== '' &&
                                    this.props.deleteDocument !==
                                        'deleted_document' &&
                                    this.props.outbox.stateData.allActions.hasOwnProperty(
                                        'update_cover_page'
                                    )) ||
                                (cover_page !== '' &&
                                    this.props.deleteDocument ===
                                        'deleted_document' &&
                                    this.props.allActions.update_cover_page !==
                                        undefined)
                            ) {
                                this.callSubmitCoverPage(params, coverfields);
                                this.setState({
                                    covercomments_original: covercomments,
                                });
                            } else {
                                showSuccessToast(
                                    'Cover Page comments updated successfully.'
                                );
                                this.setState({
                                    add_Cover_Page: false,
                                    covercomments_original: covercomments,
                                    submitted: 0,
                                });
                            }
                        } else {
                            let message = data?.results[0]?.message
                                ? data?.results[0]?.message
                                : 'Error in Processing cover page comments.';
                            showErrorToast(message);
                            this.setState({
                                coverBtn: false,
                            });
                            this.setState({
                                submitted: 0,
                            });

                            if (cover_page !== '') {
                                // this.callSubmitCoverPage(params, coverfields);
                            } else {
                                this.setState({
                                    submitted: 0,
                                });
                            }
                        }
                    });
            }
        }
    };

    callSubmitCoverPage = (params, fields) => {
        // if (
        //     this.props.outbox.stateData.hasOwnProperty('allActions') &&
        //     this.props.outbox.stateData.allActions.update_cover_page !==
        //         undefined
        // ) {
        let actionIDTemp;
        if (
            this.props.deleteDocument !== 'deleted_document' &&
            this.props.outbox.stateData.hasOwnProperty('allActions') &&
            this.props.outbox.stateData.allActions.update_cover_page !==
                undefined
        ) {
            actionIDTemp =
                this.props.outbox.stateData.allActions.update_cover_page
                    .actionId;
        }
        if (
            this.props.deleteDocument === 'deleted_document' &&
            this.props.allActions.update_cover_page !== undefined
        ) {
            actionIDTemp = this.props.allActions.update_cover_page.actionId;
        }

        params.actionId = actionIDTemp;
        // fields.fields = [];

        params.fields = fields;
        let status = '';
        outboxDocumentAction(params)
            .then((response) => {
                status = response.status;
                if (response.status === 400) {
                    if (response.message !== undefined) {
                        const { message } = response;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');
                        showErrorToast(error);
                    } else {
                        showErrorToast('Error in Processing.');
                        this.setState({
                            submitted: 0,
                        });
                    }
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                if (status === 200 && data.hadFailures === false) {
                    this.setState({
                        add_Cover_Page: false,
                        submitted: 0,
                        cover_page_original: this.state.cover_page,
                    });
                    showSuccessToast(
                        'Cover Page details updated successfully.'
                    );
                } else {
                    let message = data?.results[0]?.message
                        ? data?.results[0]?.message
                        : 'Error while Processing cover page.';
                    showErrorToast(message);
                    this.setState({
                        coverBtn: false,
                    });
                    this.setState({
                        submitted: 0,
                    });
                }
            })
            .catch((err) => {});
        // }
    };

    render() {
        return (
            <>
                {this.props.calingFrom === 'bigView' ||
                this.props.calingFrom === 'upload' ||
                (this.props.deleteDocument === 'deleted_document' &&
                    this.props.calingFrom !== 'smallView') ? (
                    <>
                        {this.props.calingFrom !== 'upload' &&
                        this.state.add_Cover_Page === false ? (
                            <div className='wrap_details wrap_note p-4'>
                                <div className='details_part'>
                                    <div className='details_fun detail_title_mobile'>
                                        <span>
                                            <i
                                                title='Cover-Page'
                                                className='fa-solid fa-message-medical'
                                            />
                                            Cover Page
                                        </span>
                                    </div>
                                </div>
                                <div className='d-flex mb-5 step2 details_part align-items-stretch justify-content-between'>
                                    <div className='details_fun'>
                                        <span>
                                            <i
                                                title='Cover-Page'
                                                className='fa-solid fa-message-medical'
                                            />
                                            Cover Page
                                        </span>
                                    </div>
                                    {(this.props.reportView !== 'report' &&
                                        this.props.deleteDocument !==
                                            'deleted_document' &&
                                        this.props.calingFrom === 'bigView' &&
                                        this.props.outbox.stateData !==
                                            undefined &&
                                        this.props.outbox.stateData.hasOwnProperty(
                                            'allActions'
                                        ) &&
                                        Object.keys(
                                            this.props.outbox.stateData
                                                .allActions
                                        ).length !== 0 &&
                                        (this.props.outbox.stateData.allActions.hasOwnProperty(
                                            'update_cover_page_comments'
                                        ) ||
                                            this.props.outbox.stateData.allActions.hasOwnProperty(
                                                'update_cover_page'
                                            ))) ||
                                    this.props.calingFrom === 'upload' ||
                                    (this.props.deleteDocument ===
                                        'deleted_document' &&
                                        this.props.calingFrom === 'bigView' &&
                                        this.props.reportView !== 'report' &&
                                        (this.props.allActions
                                            .update_cover_page !== undefined ||
                                            this.props.allActions
                                                .update_cover_page_comments !==
                                                undefined)) ? (
                                        <div className='edit_details'>
                                            <Button
                                                title='Select Cover Page'
                                                // disabled={
                                                //  this.props.isLocked === true ||
                                                // this.state.currentURL ===
                                                //  'detail'
                                                // ? true
                                                // : false
                                                // }
                                                disabled={
                                                    !!(
                                                        this.props.isLocked ||
                                                        this.state
                                                            .currentURL ===
                                                            'detail'
                                                    )
                                                }
                                                style={
                                                    this.state.currentURL ===
                                                    'detail'
                                                        ? {
                                                              cursor: 'not-allowed',
                                                          }
                                                        : {}
                                                }
                                                variant='primary'
                                                onClick={() => {
                                                    this.addCoverPage();
                                                }}
                                            >
                                                Select Cover Page
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>
                                <div className='form-group row '>
                                    <div className='col-lg-4 transition-select'>
                                        <label className='pl-5'>
                                            COVER PAGE
                                        </label>
                                        <div className='span-output'>
                                            {this.state.cover_page_original !==
                                            ''
                                                ? this.state.cover_page_original
                                                : 'Default'}
                                        </div>
                                    </div>
                                    <div className='col-lg-8 transition-select'>
                                        <label className='pl-5'>COMMENTS</label>
                                        <div className='comments span-output'>
                                            {this.state
                                                .covercomments_original !== ''
                                                ? this.state
                                                      .covercomments_original
                                                : 'No comments yet'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.props.calingFrom === 'upload' ||
                        this.state.add_Cover_Page === true ? (
                            <div className='wrap_details p-4'>
                                <div className='details_part'>
                                    <div className='details_fun detail_title_mobile'>
                                        <span>
                                            <i
                                                title='Cover-Page'
                                                className='fa-solid fa-message-medical'
                                            />
                                            Cover Page
                                        </span>
                                    </div>
                                </div>
                                <div className='d-flex details_part step2 mb-3 align-items-stretch justify-content-between'>
                                    <div className='details_fun'>
                                        <span>
                                            <i
                                                title='Cover-Page'
                                                className='fa-solid fa-message-medical'
                                            />
                                            Cover Page
                                        </span>
                                    </div>
                                    {/* cancel button and save start here */}
                                    {this.props.calingFrom !== 'upload' ? (
                                        <div className='edit_details notes d-flex justify-content-between'>
                                            {/* cancel_save */}
                                            <Button
                                                className='cancel_btn'
                                                title='Cancel'
                                                variant='secondary'
                                                onClick={() => {
                                                    this.closeAddCoverPage();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            {this.state.submitted === 1 ? (
                                                <button
                                                    className='ml-3 post_loading save_btn btn btn-success'
                                                    id={this.state.postLoading}
                                                    type='button'
                                                >
                                                    <span
                                                        className='spinner-border spinner-border-sm'
                                                        role='status'
                                                        aria-hidden='true'
                                                    />
                                                </button>
                                            ) : (
                                                <Button
                                                    title='Save'
                                                    variant='success'
                                                    className={
                                                        this.state
                                                            .disabledClass ===
                                                        ''
                                                            ? ''
                                                            : 'disabled'
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            this.state
                                                                .disabledClass ===
                                                            ''
                                                        ) {
                                                            this.submitCoverPage(
                                                                'update_cover_page_comments'
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </div>
                                    ) : null}
                                    {/* end here cancel and save btn */}
                                </div>
                                {/* this.props.calingFrom !== 'upload' && <hr /> */}
                                <div className='form-group row '>
                                    {this.props.calingFrom === 'upload' ||
                                    (this.props.deleteDocument ===
                                        'deleted_document' &&
                                        this.props.allActions
                                            .update_cover_page !== undefined) ||
                                    (this.props.outbox.stateData.hasOwnProperty(
                                        'allActions'
                                    ) &&
                                        this.props.outbox.stateData !==
                                            undefined &&
                                        Object.keys(
                                            this.props.outbox.stateData
                                                .allActions
                                        ).length !== 0 &&
                                        this.props.outbox.stateData.allActions.hasOwnProperty(
                                            'update_cover_page'
                                        )) ? (
                                        <div className='col-lg-4 transition-select'>
                                            <label className='pl-5'>
                                                COVER PAGE
                                            </label>
                                            <select
                                                id='delete_selectbrand'
                                                className='form-control'
                                                onChange={(event) => {
                                                    this.handleFormOnChange(
                                                        'cover_page',
                                                        event
                                                    );
                                                }}
                                                value={
                                                    this.state.cover_page
                                                        ? this.state.cover_page
                                                        : ''
                                                }
                                            >
                                                <option value=''>Select</option>
                                                {this.props.ddl &&
                                                Array.isArray(
                                                    this.props.ddl.coverpage
                                                )
                                                    ? this.props.ddl.coverpage.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data}
                                                              >
                                                                  {data}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                            {this.props.coverpage_validation &&
                                            (this.state.cover_page === '' ||
                                                this.state.cover_page ===
                                                    undefined) ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Cover Page is required.
                                                </p>
                                            ) : null}
                                        </div>
                                    ) : null}
                                    {this.props.calingFrom === 'upload' ||
                                    (this.props.deleteDocument ===
                                        'deleted_document' &&
                                        this.props.allActions
                                            .update_cover_page_comments !==
                                            undefined) ||
                                    (this.props.outbox.stateData !==
                                        undefined &&
                                        this.props.outbox.stateData.hasOwnProperty(
                                            'allActions'
                                        ) &&
                                        Object.keys(
                                            this.props.outbox.stateData
                                                .allActions
                                        ).length !== 0 &&
                                        this.props.outbox.stateData.allActions.hasOwnProperty(
                                            'update_cover_page_comments'
                                        )) ? (
                                        <div className='col-lg-8 '>
                                            <label className='pl-5'>
                                                COMMENTS
                                            </label>
                                            <textarea
                                                className={
                                                    this.state.validateCreate
                                                        ? 'form-control error-field'
                                                        : 'form-control'
                                                }
                                                maxLength='250'
                                                onChange={
                                                    this.handleInputOnChange
                                                }
                                                defaultValue={
                                                    this.state.covercomments
                                                }
                                            />
                                            <div className='maximum-length'>
                                                <span className='float-left'>
                                                    Maximum Length: 250
                                                </span>
                                                <span className='float-right'>
                                                    Remaining :{' '}
                                                    {
                                                        this.state
                                                            .commentTextCount
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : (
                    ((this.props.deleteDocument !== 'deleted_document' &&
                        this.props.outbox.stateData !== undefined &&
                        Object.keys(this.props.outbox.stateData.allActions)
                            .length !== 0 &&
                        this.props.outbox.stateData.allActions.hasOwnProperty(
                            'update_cover_page_comments'
                        )) ||
                        this.props.allActions.update_cover_page_comments ||
                        this.props.allActions.update_cover_page ||
                        (Object.keys(this.props.outbox.stateData.allActions)
                            .length !== 0 &&
                            this.props.outbox.stateData.allActions.hasOwnProperty(
                                'update_cover_page'
                            ))) && (
                        <Modal
                            className='delete-popup'
                            show={this.props.open_cover_page}
                            onHide={this.closeCover.bind(this, false)}
                            animation={false}
                            size='lg'
                            aria-labelledby='contained-modal-title-vcenter'
                            centered
                        >
                            <Modal.Header
                                closeButton
                                className='d-block justify-content-start'
                            >
                                <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                                    <div className=''>
                                        <i className='fa-solid fa-message-medical' />{' '}
                                        Cover Page
                                    </div>
                                    <i
                                        title='Close'
                                        className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                        aria-hidden='true'
                                        onClick={this.closeCover.bind(
                                            this,
                                            false
                                        )}
                                    />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='deletes'>
                                <div className='form-group row '>
                                    {((this.props.deleteDocument !==
                                        'deleted_document' &&
                                        Object.keys(
                                            this.props.outbox.stateData
                                                .allActions
                                        ).length !== 0 &&
                                        this.props.outbox.stateData.allActions.hasOwnProperty(
                                            'update_cover_page'
                                        )) ||
                                        this.props.allActions
                                            .update_cover_page) && (
                                        <div className='col-lg-4 mt-5 transition-select'>
                                            <label className='pl-5'>
                                                Cover Page
                                            </label>
                                            <select
                                                id='delete_selectbrand'
                                                className='form-control'
                                                onChange={(event) => {
                                                    this.handleFormOnChange(
                                                        'cover_page',
                                                        event
                                                    );
                                                }}
                                                value={
                                                    this.state.cover_page
                                                        ? this.state.cover_page
                                                        : ''
                                                }
                                            >
                                                <option value=''>Select</option>
                                                {this.props.ddl &&
                                                Array.isArray(
                                                    this.props.ddl.coverpage
                                                )
                                                    ? this.props.ddl.coverpage.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data}
                                                              >
                                                                  {data}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </div>
                                    )}
                                    {((this.props.deleteDocument !==
                                        'deleted_document' &&
                                        Object.keys(
                                            this.props.outbox.stateData
                                                .allActions
                                        ).length !== 0 &&
                                        this.props.outbox.stateData.allActions.hasOwnProperty(
                                            'update_cover_page_comments'
                                        )) ||
                                        this.props.allActions
                                            .update_cover_page_comments) && (
                                        <div className='col-lg-8 mt-5 transition-select'>
                                            <label className='pl-5'>
                                                Comments
                                            </label>
                                            <textarea
                                                className={
                                                    this.state.validateCreate
                                                        ? 'form-control error-field'
                                                        : 'form-control'
                                                }
                                                maxLength='250'
                                                onChange={
                                                    this.handleInputOnChange
                                                }
                                                defaultValue={
                                                    this.state.covercomments
                                                }
                                            />
                                            <div className='maximum-length'>
                                                <span className='float-left'>
                                                    Maximum Length: 250
                                                </span>
                                                <span className='float-right'>
                                                    Remaining :{' '}
                                                    {
                                                        this.state
                                                            .commentTextCount
                                                    }
                                                </span>
                                            </div>
                                            {/* this.state.validateCreate ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Comments is required.
                                                </p>
                                            ) : null */}
                                        </div>
                                    )}
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='d-block delete_btn'>
                                <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                                    <div className='d-flex'>
                                        <Button
                                            title='Cancel'
                                            variant='secondary'
                                            onClick={this.closeCover.bind(
                                                this,
                                                false
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className='d-flex'>
                                        <div
                                            className='bulk'
                                            style={{ display: 'flex' }}
                                        >
                                            {this.state.submitted === 1 ? (
                                                <div className='notes-btn d-flex'>
                                                    <div
                                                        className=' btn-group posted_btn load_img'
                                                        role='group'
                                                    >
                                                        <button
                                                            className='post_loading'
                                                            id={
                                                                this.state
                                                                    .postLoading
                                                            }
                                                            type='button'
                                                        >
                                                            <span
                                                                className='spinner-border spinner-border-sm'
                                                                role='status'
                                                                aria-hidden='true'
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        this.state
                                                            .disabledClass ===
                                                        ''
                                                            ? 'btn btn-success'
                                                            : 'btn btn-success disabled'
                                                    }
                                                    title='Save'
                                                    onClick={() => {
                                                        if (
                                                            this.state
                                                                .disabledClass ===
                                                                '' &&
                                                            this.state
                                                                .coverBtn ===
                                                                false
                                                        ) {
                                                            this.submitCoverPage(
                                                                'update_cover_page_comments'
                                                            );
                                                        }
                                                    }}
                                                    disabled={
                                                        this.state.coverBtn
                                                    }
                                                >
                                                    Save
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    )
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    getCountData,
    getDDLlist: getDDLlist,
    getSelectedRowDetails: getSelectedRowDetails,
    outboxPage,
};
const mapStateToProps = (state) => {
    return {
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        count: state.persist.count,
        ddl: state.persistDDL.DDL,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
        selectRowDetails: state.outbox.selectedRowDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CoverPageModal);
