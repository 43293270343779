import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { SpinnerBlur } from './Spinner';
import { formatDate, EpisodeSearch, BranchIdSearch } from '../commonfunction';
import { recordSetSearch } from '../../pages/api/api';
import { inboxPage } from '../../pages/_redux/actions/inboxActions';

export class CreateNewPatientForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.state,
            first_name: '',
            last_name: '',
            dob: '',
            gender: '',
            service_line: '',
            branch: '',
            order_date: '',
            order_number: '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.state.validateCreate !== this.props.state.validateCreate
        ) {
            this.setState({ validateCreate: this.props.state.validateCreate });
        }
    }

    componentDidMount() {
        // Check the item availability in the branch and set aside the item if there is only one.
        if (this.props.ddl.branch.length == 1) {
            this.handleBranchOnChange('branch', {
                target: {
                    value: this.props.ddl.branch[0],
                },
            });
        }
    }

    handleSelectOnChange = async (state, event) => {
        this.setState({
            [state]: event.target.value,
        });

        const createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        await this.setState(
            {
                createPatientData: createPatientData,
            },
            () => {
                this.props.updateState({
                    createPatientData: createPatientData,
                });
            }
        );
        if (this.props.calingFrom === 'bigView') {
            this.props.functionPatientData({
                ...this.state.createPatientData,
            });
        }
    };

    handleInputOnChange = async (state, event) => {
        this.setState({
            [state]: event.target.value,
        });
        const createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        await this.setState(
            {
                createPatientData: createPatientData,
            },
            () => {
                this.props.updateState({
                    createPatientData: createPatientData,
                });
            }
        );
        if (this.props.calingFrom === 'bigView') {
            this.props.functionPatientData({
                ...this.state.createPatientData,
            });
        }
    };

    setDob = async (date) => {
        this.setState({
            dob: date,
        });
        const dob = formatDate(date, 'MM/DD/YYYY');
        const createPatientData = {
            ...this.state.createPatientData,
            date_of_birth: dob,
        };
        await this.setState(
            {
                createPatientData: createPatientData,
            },
            () => {
                this.props.updateState({
                    createPatientData: createPatientData,
                });
            }
        );
        if (this.props.calingFrom === 'bigView') {
            this.props.functionPatientData({
                ...this.state.createPatientData,
            });
        }
    };

    setOrderDate = async (date) => {
        this.setState({
            order_date: date,
        });
        const order_date = formatDate(date, 'MM/DD/YYYY');
        const createPatientData = {
            ...this.state.createPatientData,
            order_date: order_date,
        };
        await this.setState(
            {
                createPatientData: createPatientData,
            },
            () => {
                this.props.updateState({
                    createPatientData: createPatientData,
                });
            }
        );
        if (this.props.calingFrom === 'bigView') {
            this.props.functionPatientData({
                ...this.state.createPatientData,
            });
        }
    };

    handleBranchOnChange = async (state, event) => {
        const value = event.target.value;
        this.setState({
            [state]: event.target.value,
        });
        // this.props.inboxPage({
        //     currentBranch: event.target.value
        // })
        const createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        await this.setState(
            {
                createPatientData: createPatientData,
            },
            () => {
                this.props.updateState({
                    createPatientData: createPatientData,
                });
            }
        );
        if (this.props.calingFrom === 'bigView') {
            this.props.functionPatientData({
                ...this.state.createPatientData,
            });
        }

        const episodeId =
            this.props.allRecordSetID.branch_data.fields_obj.branch.fieldId;
        const searchText = `fieldSearch=${episodeId}=${value}`;
        let status;
        await recordSetSearch(
            this.props.allRecordSetID.branch_data.recordsetId,
            searchText
        )
            .then(async (response) => {
                status = response.status;
                return response.json();
            })
            .then(async (data) => {
                if (status !== undefined && status === 200) {
                    const searchResult = await EpisodeSearch(data);
                    const branch_id = await BranchIdSearch(data);

                    const agencyid = {
                        ...this.state.createPatientData,
                        agencyid: searchResult[0].agencyid,
                        branch_id: branch_id,
                    };
                    this.setState(
                        {
                            createPatientData: agencyid,
                        },
                        () => {
                            this.props.updateState({
                                createPatientData: agencyid,
                            });
                        }
                    );
                }
            });
    };

    render() {
        let classrow = 'col-lg-6';
        if (this.props.calingFrom === 'search') classrow = 'col-lg-3';
        return (
            <div
                className={
                    this.props.calingFrom === 'search'
                        ? 'search_page create_patient'
                        : 'create_patient dd'
                }
            >
                <div className='row grid-row-group'>
                    <SpinnerBlur show={this.props.loader} />
                    {/* <div
                    className={
                        this.props.calingFrom === 'search'
                            ? 'row grid-row-group'
                            : ''
                    }
                    > */}
                    {this.props.isDetailBigView ||
                    (this.props.isUploadPage &&
                        this.props.allowedFields.includes('patient_name')) ? (
                        <>
                            <div className={`transition-select ${classrow}`}>
                                <label className='pl-5'>
                                    PATIENT FIRST NAME
                                </label>
                                <div className='position-relative'>
                                    <input
                                        type='text'
                                        className={
                                            (this.state.validateCreate ||
                                                this.props.patientValidation ===
                                                    true) &&
                                            this.state.createPatientData
                                                .first_name === ''
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={this.state.first_name}
                                        onChange={(event) => {
                                            this.handleInputOnChange(
                                                'first_name',
                                                event
                                            );
                                        }}
                                        maxLength='50'
                                    />
                                </div>
                                {(this.state.validateCreate ||
                                    this.props.patientValidation === true) &&
                                this.state.createPatientData.first_name ===
                                    '' ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Patient First Name is required.
                                    </p>
                                ) : null}
                            </div>
                            <div className={`transition-select ${classrow}`}>
                                <label className='pl-5'>
                                    PATIENT LAST NAME
                                </label>
                                <input
                                    type='text'
                                    className={
                                        (this.state.validateCreate ||
                                            this.props.patientValidation ===
                                                true) &&
                                        this.state.createPatientData
                                            .last_name === ''
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={this.state.last_name}
                                    onChange={(event) => {
                                        this.handleInputOnChange(
                                            'last_name',
                                            event
                                        );
                                    }}
                                    maxLength='50'
                                />
                                {(this.state.validateCreate ||
                                    this.props.patientValidation === true) &&
                                this.state.createPatientData.last_name ===
                                    '' ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Patient Last Name is required.
                                    </p>
                                ) : null}
                            </div>
                        </>
                    ) : null}

                    <>
                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes(
                                'date_of_birth'
                            )) ? (
                            <div className={`transition-select ${classrow}`}>
                                <label className='pl-5'>DATE OF BIRTH</label>
                                <div
                                    className={
                                        (this.state.validateCreate ||
                                            this.props.patientValidation ===
                                                true) &&
                                        this.state.createPatientData
                                            .date_of_birth === ''
                                            ? 'position-relative form-control error-field'
                                            : 'position-relative form-control'
                                    }
                                >
                                    <DatePicker
                                        onChange={(date) => {
                                            this.setDob(date);
                                        }}
                                        value={this.state.dob}
                                        className='date-picker'
                                        maxDate={new Date()}
                                        format='MM/dd/y'
                                    />
                                </div>
                                {(this.state.validateCreate ||
                                    this.props.patientValidation === true) &&
                                this.state.createPatientData.date_of_birth ===
                                    '' ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Date of Birth is required.
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes('gender')) ? (
                            <div className={`transition-select ${classrow}`}>
                                <label className='pl-5'>GENDER</label>
                                <select
                                    id='delete_selectbrand'
                                    className={
                                        (this.state.validateCreate ||
                                            this.props.patientValidation ===
                                                true) &&
                                        this.state.createPatientData.gender ===
                                            ''
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={this.state.gender}
                                    onChange={(event) => {
                                        this.handleSelectOnChange(
                                            'gender',
                                            event
                                        );
                                    }}
                                >
                                    <option value=''>Select</option>
                                    <option value='M'>Male</option>
                                    <option value='F'>Female</option>
                                </select>
                                <i
                                    className='fa-light fa-chevron-down'
                                    style={{ pointerEvents: 'none' }}
                                ></i>
                                {(this.state.validateCreate ||
                                    this.props.patientValidation === true) &&
                                this.state.createPatientData.gender === '' ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Gender is required.
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                    </>

                    <>
                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes(
                                'service_line'
                            )) ? (
                            <div className={`transition-select ${classrow}`}>
                                <label className='pl-5'>SERVICE LINE</label>
                                <div className=''>
                                    <select
                                        id='delete_selectbrand'
                                        className={
                                            (this.state.validateCreate ||
                                                this.props.patientValidation ===
                                                    true) &&
                                            this.state.createPatientData
                                                .service_line === ''
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={this.state.service_line}
                                        onChange={(event) => {
                                            this.handleSelectOnChange(
                                                'service_line',
                                                event
                                            );
                                        }}
                                    >
                                        <option value=''>Select</option>
                                        {this.props.ddl &&
                                        Array.isArray(
                                            this.props.ddl.serviceline
                                        )
                                            ? this.props.ddl.serviceline.map(
                                                  (data, index) => (
                                                      <option
                                                          key={index}
                                                          value={data}
                                                      >
                                                          {data}
                                                      </option>
                                                  )
                                              )
                                            : null}
                                    </select>
                                    <i
                                        className='fa-light fa-chevron-down'
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    {(this.state.validateCreate ||
                                        this.props.patientValidation ===
                                            true) &&
                                    this.state.createPatientData
                                        .service_line === '' ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Service Line is required.
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                        {this.props.isDetailBigView ||
                        (this.props.isUploadPage &&
                            this.props.allowedFields.includes('branch')) ? (
                            <div className={`transition-select ${classrow}`}>
                                <label className='pl-5'>BRANCH</label>
                                <select
                                    value={this.state.createPatientData.branch}
                                    id='delete_selectbrand'
                                    className={
                                        (this.state.validateCreate ||
                                            this.props.patientValidation ===
                                                true) &&
                                        this.state.createPatientData.branch ===
                                            ''
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    onChange={(event) => {
                                        this.handleBranchOnChange(
                                            'branch',
                                            event
                                        );
                                    }}
                                    //disabled={this.props.ddl.branch.length == 1}
                                >
                                    <option value=''>Select</option>
                                    {this.props.ddl &&
                                    Array.isArray(this.props.ddl.branch)
                                        ? this.props.ddl.branch.map(
                                              (data, index) => (
                                                  <option
                                                      key={index}
                                                      value={data}
                                                      style={{
                                                          display:
                                                              data !== ''
                                                                  ? 'block'
                                                                  : 'none',
                                                      }}
                                                  >
                                                      {data}
                                                  </option>
                                              )
                                          )
                                        : null}
                                </select>
                                <i
                                    className='fa-light fa-chevron-down'
                                    style={{ pointerEvents: 'none' }}
                                />
                                {(this.state.validateCreate ||
                                    this.props.patientValidation === true) &&
                                this.state.createPatientData.branch === '' ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Branch is required.
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                    </>
                </div>
                {this.props.formFor !== undefined &&
                    this.props.formFor === 'order' && (
                        <>
                            <hr />
                            <div className='row grid-row-group'>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>ORDER NUMBER</label>
                                    <input
                                        type='text'
                                        className={
                                            (this.state.validateCreate ||
                                                this.props.patientValidation ===
                                                    true) &&
                                            this.state.createPatientData
                                                .order_number === ''
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={this.state.order_number}
                                        onChange={(event) => {
                                            this.handleInputOnChange(
                                                'order_number',
                                                event
                                            );
                                        }}
                                        maxLength='10'
                                    />

                                    {(this.state.validateCreate ||
                                        this.props.patientValidation ===
                                            true) &&
                                    this.state.createPatientData
                                        .order_number === '' ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Order Number is required.
                                        </p>
                                    ) : null}
                                </div>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>ORDER DATE</label>
                                    <div
                                        className={
                                            (this.state.validateCreate ||
                                                this.props.patientValidation ===
                                                    true) &&
                                            this.state.createPatientData
                                                .order_date === ''
                                                ? 'position-relative form-control error-field'
                                                : 'position-relative form-control'
                                        }
                                    >
                                        <DatePicker
                                            onChange={(order_date) => {
                                                this.setOrderDate(order_date);
                                            }}
                                            value={this.state.order_date}
                                            className='date-picker'
                                            maxDate={new Date()}
                                            format='MM/dd/y'
                                        />
                                    </div>
                                    {(this.state.validateCreate ||
                                        this.props.patientValidation ===
                                            true) &&
                                    this.state.createPatientData.order_date ===
                                        '' ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Order Date is required.
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    )}
            </div>
        );
    }
}
const mapDispatchToProps = { inboxPage };
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.inbox.selectedRowDetails.rulesJson,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateNewPatientForm));
