import {
    GET_OUTBOX_LIST,
    SET_SELECTED_ROW,
    GET_SHARED_PROPS,
    DEFAULT_OUTBOX_STATE,
    GET_RULES_JSON,
    SET_ROW_DATA,
    GET_COUNT_DATA,
    SHOW_LOADER,
    RE_RENDER_OUTBOX,
    HIDE_BIG_VIEW,
    SET_MENU_NAME,
    SET_PAGE_DATA,
    GET_OWNERS,
    SET_PROCESS_ID,
    SET_STATUS_ID,
    SET_CALLING_PAGE,
    SET_OWNER,
    SET_DOC_OWNER,
    UPDATE_OUTBOX_LIST,
    UPDATE_OUTBOX_STATE,
    SELECTED_ROW_DETAILS,
    SELECTED_ROW_GRID_DETAILS,
    MULTIPLE_EDIT_BASE64,
    OUTBOX_PAGE,
    CLEAR_OUTBOX_STATE,
    OUTBOX_GROUPING,
    INITIAL_OUTBOX_GROUPING,
} from '../actionTypes/outboxTypes';

export const getListaction = (
    processId,
    statusId,
    callingPage = 'inbox-outbox',
    queryData = ''
) => ({
    type: GET_OUTBOX_LIST,
    processId,
    statusId,
    callingPage,
    queryData,
});

export const setSelectedRow = (rowIdx) => ({
    type: SET_SELECTED_ROW,
    rowIdx,
});

export const getSharedProps = () => ({
    type: GET_SHARED_PROPS,
});

export const defaultOutbox = () => ({
    type: DEFAULT_OUTBOX_STATE,
});

export const getRulesJson = (rulesJson) => ({
    type: GET_RULES_JSON,
    rulesJson,
});

export const setRowData = (data = []) => ({
    type: SET_ROW_DATA,
    rowData: data,
});

export const getCountData = (count = []) => ({
    type: GET_COUNT_DATA,
    count,
});

export const showLoader = (loader) => ({
    type: SHOW_LOADER,
    loader,
});

export const reRenderOutbox = (canrender) => ({
    type: RE_RENDER_OUTBOX,
    rerenderOutbox: canrender,
});

export const hideBigView = (hide) => ({
    type: HIDE_BIG_VIEW,
    hidebigview: hide,
});

export const setMenuName = (name) => ({
    type: SET_MENU_NAME,
    currentMenuName: name,
});
export const setPageData = (data) => ({
    type: SET_PAGE_DATA,
    data,
});

export const getOwners = (processId, statusId) => ({
    type: GET_OWNERS,
    processId,
    statusId,
});

export const setProcessId = (processId) => ({
    type: SET_PROCESS_ID,
    processId,
});

export const setStatusId = (statusId) => ({
    type: SET_STATUS_ID,
    statusId,
});
export const setCallingPage = (callingPage) => ({
    type: SET_CALLING_PAGE,
    callingPage,
});

export const setOwner = (owner) => ({
    type: SET_OWNER,
    owner,
});

export const setDocOwner = (docOwner) => ({
    type: SET_DOC_OWNER,
    docOwner,
});

export const updateList = (data) => ({
    type: UPDATE_OUTBOX_LIST,
    data,
});

export const updateOutboxState = (data) => ({
    type: UPDATE_OUTBOX_STATE,
    data,
});

export const clearOutboxState = (data) => ({
    type: CLEAR_OUTBOX_STATE,
    data,
});

export const getSelectedRowDetails = (data) => ({
    type: SELECTED_ROW_DETAILS,
    data,
});

export const getSelectedRowGRIDDetails = (data) => ({
    type: SELECTED_ROW_GRID_DETAILS,
    data,
});

export const getMultipleEditBase64 = (data) => ({
    type: MULTIPLE_EDIT_BASE64,
    data,
});

export const outboxPage = (data) => ({
    type: OUTBOX_PAGE,
    data,
});
export const outboxGrouping = (data) => ({
    type: OUTBOX_GROUPING,
    data,
});

export const initialOutboxGrouping = () => ({
    type: INITIAL_OUTBOX_GROUPING,
});
