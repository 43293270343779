import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReportDocumentsDataGrid from '../../components/datagrid/ReportDocumentsDataGrid';

import { SpinnerThree, SpinnerBlur } from '../../components/Spinner';

import { getDDLlist } from '../_redux/actions/configActions';
import {
    deletedPage,
    updateSearchState,
    selectedRowDetail,
} from '../_redux/actions/searchActions';
import EditWorkPage from '../EditWorkPage';

import {
    GetFieldData,
    // userPermission,
    stringEmptyOrUndefined,
    formatHeaderNameWithOutSpace,
    getDropDownDataFromApi,
    updateCustomSearchConfigDataInLocalStorage,
} from '../../components/commonfunction';
import { configurationCustomeSearchId } from '../api/api';
// import { getConfig } from '../../../_metronic/_helpers';

class ReportDocumentPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            filter_options: {},
            deleteBigView: false,
            paginationRefresh: true,
            edit_details: false, // Inbox edit details
            editDetailsOutbox: false,
        };
        this.closeBigView = this.closeBigView.bind(this);
    }

    closeBigView = () => {
        document.body.classList.remove('doc-detail-view');
        this.setState({
            deleteBigView: false,
        });
    };

    refreshForNextDocument = async (docid) => {
        document.body.classList.remove('aside-minimize');
        // await this.props.updateState({
        //     show_result: false,
        // });
        this.props.updateSearchState({
            deletedPage: {
                ...this.props.search.deletedPage,
                row_transition: false,
            },
        });
        this.closeBigView();
        // setTimeout(() => {
        //     this.props.updateState({
        //         show_result: true,
        //     });
        // }, 1000);
    };

    getBranchDropDownData = () => {
        const fieldData = GetFieldData({ configData: this.props.configData });

        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('attachmenttype')) {
                getDropDownDataFromApi(fieldData.attachmenttype).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
        }
    };
    configurationCustomeSearchId = async (id) => {
        configurationCustomeSearchId(id)
            .then(async (data) => {
                const customSearches = data.customSearchQueries;
                const customSearchNamedIdsNeedingConfiguration =
                    data.customSearchNamedIdsNeedingConfiguration;

                updateCustomSearchConfigDataInLocalStorage(
                    customSearches,
                    customSearchNamedIdsNeedingConfiguration
                );

                if (this.props.getCustomSearchConfig) {
                    await this.props.getCustomSearchConfig(
                        customSearches,
                        customSearchNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    };
    async componentDidMount() {
        // eslint-disable-next-line no-underscore-dangle
        this._isMounted = true;
        this.getBranchDropDownData();
        var customSearchQueries = [];
        customSearchQueries =
            this.props.configData.customSearchNamedIdsNeedingConfiguration;

        if (customSearchQueries !== undefined) {
            await customSearchQueries.map((data) => {
                if (data.name === 'By Batch') {
                    this.configurationCustomeSearchId(data.id);
                }
                if (data.name === 'By Order') {
                    this.configurationCustomeSearchId(data.id);
                }
            });
        }
        // const config = getConfig();
        // const statusArray = [];

        /* if (config.length > 0 && config[0].fields !== undefined) {
            if (config[0].menuItems.length > 0) {
                config[0].menuItems.forEach((data) => {
                    if (
                        data.menuName === 'Inbox' &&
                        data.processes.length > 0
                    ) {
                        data.processes.forEach((value) => {
                            if (value.statuses.length > 0) {
                                value.statuses.forEach((val) => {
                                    statusArray.push({
                                        id: val.statusId,
                                        name: val.name,
                                    });
                                });
                            }
                        });
                    }
                });
            }
        }

        await this.setState({
            statusArray: statusArray,
        });
        
        
        this.props.updateState({
            operate_search: false,
            operate_filter: {},
        });
        this.setState({
            filter_options: this.props.operate_filter,
        }); */

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
    }

    async componentWillUnmount() {
        await this.props.updateSearchState({
            deletedPage: {
                ...this.props.search.deletedPage,
                row_transition: false,
            },
        });
        // eslint-disable-next-line no-underscore-dangle
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        /* if (prevProps.operate_search !== this.props.operate_search) {
            if (this.props.operate_search) {
                this.setState({
                    filter_options: this.props.operate_filter,
                });
            }
        } */
        // added for custome-search-queries on click hide the detail big view
        /* if (document.body.classList.contains('doc-detail-view')) {
            const customClick = document.getElementsByClassName(
                'custome-search-queries'
            );
            if (customClick[0] !== undefined) {
                customClick[0].onclick = function () {
                    this.closeBigView();
                }.bind(this);
            }
        } */

        // added for search-button on click hide the detail big view
        if (document.body.classList.contains('doc-detail-view')) {
            const customClick =
                document.getElementsByClassName('search-button');
            if (customClick[0] !== undefined) {
                customClick[0].onclick = function () {
                    this.closeBigView();
                    this.props.updateSearchState({
                        deletedPage: {
                            ...this.props.search.deletedPage,
                            row_transition: false,
                        },
                    });
                    document.body.classList.remove('aside-minimize');
                }.bind(this);
            }
        }
    }

    closeHistory = () => {
        this.props.deletedPage({ openHistory: false });
    };

    /* openBigView = () => {
        if (this.props.selectedRowDetails.statuses.length > 0) {
            const permission = userPermission({
                processId: this.props.selectedRowDetails.statuses[0].processId,
                statusId: this.props.selectedRowDetails.statuses[0].statusId,
                menu_name: 'Search',
                configData: this.props.configData,
            });
           
            this.setState({
                userPermission: permission,
                processId: this.props.selectedRowDetails.statuses[0].processId,
                statusId: this.props.selectedRowDetails.statuses[0].statusId,
            });
        }

        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        let menuBranch = null;
        let menuGroup = null;
        let menu_inbox = null;
        var data = this.props.selectedRowDetails;
        if (data.fields !== undefined) {
            data.fields.forEach((dataValue) => {
                const fields = dataValue.fieldId;
                if (fields === fieldDataObj.branchFieldId) {
                    this.setState({
                        menuBranch: dataValue.value,
                    });
                }
                if (fields === fieldDataObj.groupFieldId) {
                    this.setState({
                        menuGroup: dataValue.value,
                    });
                }
                if (fields === fieldDataObj.workflow_status) {
                    this.setState({
                        menu_inbox: dataValue.value,
                    });
                }
                // for (const property in configData) {
                //     if (configData[property].fieldId === fields) {
                //         foundFields.push(configData[property]);
                //     }
                // }
            });
        }

        this.setState({
            isLockedBy: data.miscPproperties.isLockedBy,
            isLocked: data.miscPproperties.isLocked,
            noteCount: data.miscPproperties.noteCount,
            pageCount: data.miscPproperties.pageCount,
        });
        this.setState({
            deleteBigView: true,
        });
    }; */
    updateState = (state) => {
        this.setState({ ...state });
    };

    enableLoader(value) {
        this.setState({ blur_spinner: value });
    }

    render() {
        return (
            <>
                {this.props.location.pathname === '/reports' ? (
                    <div className='right-aside p-5 form_empty'>
                        <div className='no_form'>
                            <i className='fa-light fa-file-chart-column' />
                            <span>
                                Select a report on the left to get started
                            </span>
                        </div>
                    </div>
                ) : (
                    <>
                        <SpinnerBlur
                            show={this.props.deleted_page.blurSpinner}
                        />

                        <div
                            style={
                                this.props.deleted_page.single_edit === true
                                    ? { display: 'none' }
                                    : {}
                            }
                        >
                            {this.props.show_result ? (
                                <ReportDocumentsDataGrid
                                    calingFrom='report'
                                    pageTitle={this.props.pageTitle}
                                    paginationRefresh={
                                        this.state.paginationRefresh
                                    }
                                />
                            ) : null}

                            {/*
                                <div className='no_result norecordfound search_error'>
                                    <span> Operate search to view search results </span>
                                </div> */}

                            {this.props.deleted_page.row_transition === true ? (
                                <div
                                    id='left-aside'
                                    className={
                                        this.props.miscPproperties !==
                                            undefined &&
                                        this.props.miscPproperties.isLocked ===
                                            false
                                            ? 'left-aside py-5 rounded-lg aside-fixed flex-row-auto no-lock-section'
                                            : 'left-aside py-9 rounded-lg aside-fixed flex-row-auto '
                                    }
                                    style={
                                        this.state.deleteBigView === true
                                            ? { margin: 0 }
                                            : {}
                                    }
                                />
                            ) : this.props.deleted_page.row_transition ===
                              '' ? (
                                <div
                                    id='left-aside'
                                    className={
                                        this.state.deleteBigView
                                            ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                            : 'left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                                    }
                                    style={{
                                        height:
                                            window.innerHeight -
                                            window.innerHeight / 7,
                                    }}
                                >
                                    <SpinnerThree show />
                                </div>
                            ) : null}
                        </div>

                        {this.props.deleted_page.single_edit === true ? (
                            <div
                                style={
                                    {
                                        // height: '800px',
                                        // paddingTop: '30px',
                                        // position: 'relative',
                                    }
                                }
                            >
                                <EditWorkPage
                                    // style={{ position: 'sticky' }}
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                        this.props.deletedPage({
                                            ...state,
                                        });
                                    }}
                                    deleteBigView={this.state.deleteBigView}
                                    calingFrom='search'
                                    show_result={this.props.show_result}
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    deletedPage,
    updateSearchState,
    selectedRowDetail,
    getDDLlist: getDDLlist,
};
const mapStateToProps = (state) => {
    return {
        search: state.search,
        deleted_page: state.search.deletedPage,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.search.selectedRowDetails,
        miscPproperties: state.search.selectedRowDetails.miscPproperties,
        statuses: state.search.selectedRowDetails.statuses,
        ddl: state.persistDDL.DDL,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportDocumentPage))
);
