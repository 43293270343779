import React from 'react';
import { Button } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import { EntityFormProps, OptionType } from '../models/interfaces';

const EntityForm: React.FC<EntityFormProps> = ({
    formik,
    lookupOptions,
    welcomeSenderOptions,
    title,
}) => {
    const handleSmsNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
        if (value.length > 10) {
            value = value.slice(0, 10); // Limit to 10 digits
        }
        if (value.length > 6) {
            value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(
                6
            )}`;
        } else if (value.length > 3) {
            value = `${value.slice(0, 3)}-${value.slice(3)}`;
        }

        formik.setFieldValue('smsNumber', value);
    };

        const handleCountryCodeChange = (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            let value = e.target.value.replace(/\D/g, '');
            if (value.length > 1) {
                value = value.slice(0, 1);
            }
            value = '+' + value;

            formik.setFieldValue('countryCode', value);
        };

    return (
        <div>
            <div className='d-flex justify-content-between mb-2 pl-7 pr-7'>
                <div className='d-flex mb-3 align-items-stretch justify-content-between'>
                    <h3 className='m-0 title-color'>
                        <i
                            title='add'
                            className='fa-regular fa-plus'
                            style={{ color: '#8DC63F', marginRight: '15px' }}
                        />
                        {title}
                    </h3>
                </div>
            </div>
            <hr />
            <div className='mb-5 pl-7 pr-7'>
                <p className='text-dark mb-10'>
                    On this page you can add a new entity.
                </p>

                <div>
                    <div className='row grid-row-group mb-5'>
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>
                                COMMUNICATION EMAIL
                                <span style={{ color: 'red' }}> *</span>
                            </label>
                            <Field
                                type='email'
                                name='communicationEmail'
                                className={`form-control ${
                                    formik.touched.communicationEmail &&
                                    formik.errors.communicationEmail
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            />
                            <ErrorMessage
                                name='communicationEmail'
                                component='div'
                                className='error m-0'
                            />
                        </div>

                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>
                                FIRST NAME
                                <span style={{ color: 'red' }}> *</span>
                            </label>
                            <Field
                                type='text'
                                name='firstName'
                                className={`form-control ${
                                    formik.touched.firstName &&
                                    formik.errors.firstName
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            />
                            <ErrorMessage
                                name='firstName'
                                component='div'
                                className='error m-0'
                            />
                        </div>

                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>
                                LAST NAME
                                <span style={{ color: 'red' }}> *</span>
                            </label>
                            <Field
                                type='text'
                                name='lastName'
                                className={`form-control ${
                                    formik.touched.lastName &&
                                    formik.errors.lastName
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            />
                            <ErrorMessage
                                name='lastName'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                    </div>

                    <div className='row grid-row-group mb-5'>
                        <div className='col-md-6 col-xl-6 transition-select form-control-iconless'>
                            <label className='pl-5'>FULL NAME WITH TITLE</label>
                            <Field
                                type='text'
                                name='fullNameWithTitle'
                                className={`form-control ${
                                    formik.touched.fullNameWithTitle &&
                                    formik.errors.fullNameWithTitle
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            />
                            <ErrorMessage
                                name='fullNameWithTitle'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                        <div className='col-md-2 col-xl-2 transition-select form-control-iconless'>
                            <label className='pl-5'>COUNTRY CODE</label>
                            <Field
                                type='text'
                                name='countryCode'
                                className={`form-control ${
                                    formik.touched.countryCode &&
                                    formik.errors.countryCode
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                onChange={handleCountryCodeChange}
                            />
                            <ErrorMessage
                                name='countryCode'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>SMS NUMBER</label>
                            <Field
                                type='text'
                                name='smsNumber'
                                className={`form-control ${
                                    formik.touched.smsNumber &&
                                    formik.errors.smsNumber
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                onChange={handleSmsNumberChange}
                            />
                            <ErrorMessage
                                name='smsNumber'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                    </div>

                    <div className='row grid-row-group mb-5'>
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>
                                LOOKUP KEY TYPE
                                <i
                                    title={'Open'}
                                    className={'fa-regular fa-chevron-down'}
                                    aria-hidden='true'
                                />
                            </label>
                            <Field
                                as='select'
                                name='lookupKeyType'
                                className={`form-control ${
                                    formik.touched.lookupKeyType &&
                                    formik.errors.lookupKeyType
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            >
                                <option value=''>Select...</option>
                                {lookupOptions?.map((option: OptionType, index: number) => (
                                    <option
                                        key={index}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage
                                name='lookupKeyType'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>LOOKUP VALUE</label>
                            <Field
                                type='text'
                                name='lookupKeyValue'
                                className={`form-control ${
                                    formik.touched.lookupKeyValue &&
                                    formik.errors.lookupKeyValue
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            />
                            <ErrorMessage
                                name='lookupKeyValue'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>OVERRIDE RESEND DAYS</label>
                            <Field
                                type='text'
                                name='resendDays'
                                className={`form-control ${
                                    formik.touched.resendDays &&
                                    formik.errors.resendDays
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        formik.setFieldValue(
                                            'resendDays',
                                            value
                                        );
                                    }
                                }}
                            />
                            <ErrorMessage
                                name='resendDays'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                    </div>

                    <div className='row grid-row-group mb-5'>
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='checkbox checkbox-lg text-dark-50 text-hover-primary my-3 mr-2 p-0'>
                                <Field
                                    type='checkbox'
                                    name='keepDocumentsFromGroups'
                                />
                                <span className='mr-5'></span>KEEP DOCUMENTS
                                FROM GROUPS
                            </label>
                        </div>
                        <div className='col-md-4' />
                        <div className='col-md-4'>
                            <Button
                                title='Resend Welcome Email'
                                onClick={() => {
                                    formik.setFieldValue(
                                        'changeCounter',
                                        Number(formik.values.changeCounter) + 1
                                    );
                                }}
                                variant='primary'
                                style={{ width: '100%', textAlign: 'left' }}
                            >
                                <i className='fa-solid fa-envelope-open-text pr-5'></i>
                                Resend Welcome Email
                            </Button>
                        </div>
                    </div>

                    <div className='row grid-row-group mb-5'>
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='checkbox checkbox-lg text-dark-50 text-hover-primary my-3 mr-2 p-0'>
                                <Field
                                    type='checkbox'
                                    name='disableAutoResend'
                                />
                                <span className='mr-5'></span>DISABLE AUTO
                                RESEND
                            </label>
                        </div>
                        <div className='col-md-4' />
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            <label className='pl-5'>
                                WELCOME SENDER
                                <i
                                    title='Open'
                                    className='fa-regular fa-chevron-down'
                                    aria-hidden='true'
                                />
                            </label>
                            <Field
                                as='select'
                                name='welcomeSender'
                                className={`form-control ${
                                    formik.touched.welcomeSender &&
                                    formik.errors.welcomeSender
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            >
                                <option value=''>Select...</option>
                                {welcomeSenderOptions?.map(
                                    (option: OptionType) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    )
                                )}
                            </Field>
                            <ErrorMessage
                                name='welcomeSender'
                                component='div'
                                className='error m-0'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntityForm;
