import React from 'react';
import { useRowSelection } from 'react-data-grid';
import { SelectCellFormatter } from './SelectCellFormatter';
// import { useRowSelection } from './useRowSelection';

export const SELECT_COLUMN_KEY = 'select-row';

function stopPropagation(event) {
    event.stopPropagation();
}

function SelectFormatter(props) {
    const [isRowSelected, onRowSelectionChange] = useRowSelection();
    // let {selectedCount, key, row_transition } = props.column;
    const { rowTransition } = props.column;
    return (
        <SelectCellFormatter
            aria-label='Select'
            tabIndex={-1}
            isCellSelected={props.isCellSelected}
            value={isRowSelected}
            onClick={stopPropagation}
            onChange={(checked, isShiftClick) => {
                onRowSelectionChange({ row: props.row, checked, isShiftClick });
            }}
            row_transition={rowTransition}
        />
    );
}

function SelectGroupFormatter(props) {
    const [isRowSelected, onRowSelectionChange] = useRowSelection();
    const { selectedCount, key } = props.column;
    return (
        <SelectCellFormatter
            aria-label='Select Group'
            tabIndex={-1}
            isCellSelected={props.isCellSelected}
            value={isRowSelected}
            onChange={(checked) => {
                onRowSelectionChange({
                    row: props.row,
                    checked,
                    isShiftClick: false,
                });
            }}
            // Stop propagation to prevent row selection
            onClick={stopPropagation}
            selectedCount={selectedCount}
            columnType={key}
        />
    );
}

export const SelectColumn = {
    key: SELECT_COLUMN_KEY,
    name: '',
    width: 45,
    height: 45,
    maxWidth: 45,
    resizable: false,
    sortable: false,
    frozen: true,
    headerRenderer(props) {
        const { selectedCount, key, rowTransition } = props.column;
        return (
            <SelectCellFormatter
                aria-label='Select All'
                isCellSelected={false}
                value={props.allRowsSelected}
                onChange={props.onAllRowsSelectionChange}
                selectedCount={selectedCount}
                columnType={key}
                row_transition={rowTransition}
            />
        );
    },
    formatter: SelectFormatter,
    groupFormatter: SelectGroupFormatter,
};
