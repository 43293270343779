import {
    GET_INBOX_LIST,
    SET_SELECTED_ROW,
    GET_SHARED_PROPS,
    DEFAULT_INBOX_STATE,
    GET_RULES_JSON,
    SET_ROW_DATA,
    GET_COUNT_DATA,
    SHOW_LOADER,
    RE_RENDER_INBOX,
    HIDE_BIG_VIEW,
    SET_MENU_NAME,
    SET_PAGE_DATA,
    GET_OWNERS,
    SET_PROCESS_ID,
    SET_STATUS_ID,
    SET_CALLING_PAGE,
    SET_OWNER,
    SET_DOC_OWNER,
    UPDATE_INBOX_LIST,
    UPDATE_INBOX_STATE,
    SELECTED_ROW_DETAILS,
    MULTIPLE_EDIT_BASE64,
    EDIT_DETAIL_PAGE,
    INBOX_PAGE,
    CLEAR_INBOX_STATE,
    INBOX_GROUPING,
    INITIAL_INBOX_GROUPING,
} from '../actionTypes/inboxTypes';

export const getListaction = (
    processId,
    statusId,
    callingPage = 'inbox-outbox',
    queryData = ''
) => ({
    type: GET_INBOX_LIST,
    processId,
    statusId,
    callingPage,
    queryData,
});

export const setSelectedRow = (rowIdx) => ({
    type: SET_SELECTED_ROW,
    rowIdx,
});

export const getSharedProps = () => ({
    type: GET_SHARED_PROPS,
});

export const defaultInbox = () => ({
    type: DEFAULT_INBOX_STATE,
});

export const getRulesJson = (rulesJson) => ({
    type: GET_RULES_JSON,
    rulesJson,
});

export const setRowData = (data = []) => ({
    type: SET_ROW_DATA,
    rowData: data,
});

export const getCountData = (count = []) => ({
    type: GET_COUNT_DATA,
    count,
});

export const showLoader = (loader) => ({
    type: SHOW_LOADER,
    loader,
});

export const reRenderInbox = (canrender) => ({
    type: RE_RENDER_INBOX,
    rerenderInbox: canrender,
});

export const hideBigView = (hide) => ({
    type: HIDE_BIG_VIEW,
    hidebigview: hide,
});

export const setMenuName = (name) => ({
    type: SET_MENU_NAME,
    currentMenuName: name,
});
export const setPageData = (data) => ({
    type: SET_PAGE_DATA,
    data,
});

export const getOwners = (processId, statusId) => ({
    type: GET_OWNERS,
    processId,
    statusId,
});

export const setProcessId = (processId) => ({
    type: SET_PROCESS_ID,
    processId,
});

export const setStatusId = (statusId) => ({
    type: SET_STATUS_ID,
    statusId,
});
export const setCallingPage = (callingPage) => ({
    type: SET_CALLING_PAGE,
    callingPage,
});

export const setOwner = (owner) => ({
    type: SET_OWNER,
    owner,
});

export const setDocOwner = (docOwner) => ({
    type: SET_DOC_OWNER,
    docOwner,
});

export const updateList = (data) => ({
    type: UPDATE_INBOX_LIST,
    data,
});

export const updateInboxState = (data) => ({
    type: UPDATE_INBOX_STATE,
    data,
});

export const clearInboxState = (data) => ({
    type: CLEAR_INBOX_STATE,
    data,
});

export const getSelectedRowDetails = (data) => ({
    type: SELECTED_ROW_DETAILS,
    data,
});

export const getMultipleEditBase64 = (data) => ({
    type: MULTIPLE_EDIT_BASE64,
    data,
});

export const editDetailPage = (data) => ({
    type: EDIT_DETAIL_PAGE,
    data,
});

export const inboxPage = (data) => ({
    type: INBOX_PAGE,
    data,
});

export const inboxGrouping = (data) => ({
    type: INBOX_GROUPING,
    data,
});

export const initialInboxGrouping = (data) => ({
    type: INITIAL_INBOX_GROUPING,
    data,
});
