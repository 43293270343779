import React from 'react';
import * as moment from 'moment-timezone';
import { viewDocumentAllNotes } from '../../api/api';
import { formatDate, showErrorToast } from '../../../components/commonfunction';
import { Spinner3 } from './Spinner';

export class ViewAllNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes_list: [],
            spinner: false,
        };
    }

    componentDidMount() {
        this.setState({ spinner: true });
        let notesStatusCode = '';
        if (this.props.selectedDocId !== undefined) {
            viewDocumentAllNotes(this.props.selectedDocId)
                .then((response) => {
                    notesStatusCode = response.status;
                    return response.json();
                })
                .then((data) => {
                    this.setState({ spinner: false });

                    if (notesStatusCode === 200) {
                        if (data.notes !== undefined) {
                            const orderedData = data.notes.reverse();
                            this.setState({ notes_list: orderedData });
                        }
                    } else {
                        if (data.message !== undefined) {
                            showErrorToast(data.message);
                        }
                        this.setState({
                            notes_list: [],
                        });
                    }
                });
        }
    }

    render() {
        if (
            this.state.notes_list.length === 0 &&
            this.state.spinner === false
        ) {
            return null;
        }
        let height = 2 * 80;
        let styleData = {
            height,
            overflow: 'scroll',
            overflowX: 'hidden',
        };
        if (
            this.state.notes_list.length >= 1 &&
            this.state.notes_list.length <= 2
        ) {
            height = this.state.notes_list.length * 80;
            styleData = {
                height,
                overflow: 'auto',
            };
        }
        if (this.state.notes_list.length === 0) {
            styleData = {};
        }
        return (
            <div id='viewAllNotes' style={styleData}>
                <Spinner3 show={this.state.spinner} />
                {this.state.notes_list.map((list, index) => {
                    let userName = '';
                    let userDisplayName = '';
                    if (list.username) {
                        userName = list.realname;
                        const uname = userName.split(' ');
                        if (uname.length > 1) {
                            userDisplayName = `${uname[0]
                                .charAt(0)
                                .toUpperCase()}${uname[1]
                                .charAt(0)
                                .toUpperCase()}`;
                        } else {
                            userDisplayName = `${uname[0]
                                .charAt(0)
                                .toUpperCase()}${uname[0]
                                .charAt(1)
                                .toUpperCase()}`;
                        }
                    }
                    const { date } = list;
                    const startDate = moment(date);
                    const endDate = moment();
                    const duration = moment.duration(endDate.diff(startDate));
                    const daysDiff = duration.asDays();
                    const yearsDiff = duration.asYears();
                    let displayTime = '';
                    if (yearsDiff > 1) {
                        displayTime = formatDate(date, 'MMM D YYYY');
                    } else if (daysDiff > 1) {
                        displayTime = formatDate(date, 'MMM D');
                    } else {
                        displayTime = moment(date).fromNow();
                    }
                    return (
                        <div
                            className={
                                this.state.notes_list.length > 1
                                    ? 'post_data expect_more_items mb-5 pt-8 pb-1'
                                    : 'post_data mb-5 pt-8 pb-1'
                            }
                            key={index}
                        >
                            <div className='d-flex'>
                                <button
                                    type='button'
                                    className='username mr-3'
                                    title={this.props.username}
                                >
                                    {userDisplayName}
                                </button>
                                <div className=''>
                                    <h5 className='mr-5'>{list.username}</h5>
                                    <span>{displayTime}</span>
                                    {list.isFollowup ? (
                                        <span className='note-type-tag'>
                                            Follow-up Note
                                        </span>
                                    ) : null}
                                    <p>{list.value}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
