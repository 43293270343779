import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatHeaderNameWithOutSpace } from '../../../components/commonfunction';
import { getDDLlist } from '../../_redux/actions/configActions';

export class CreateEditLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.state,
            location_address_lookup: '',
            location_name: '',
            address: '',
            city: '',
            states: '',
            zipcode: '',
            note: '',
            location_details: this.props.location_details,
            show: true,
            statesOption: this.props.ddl.state,
            commentTextFlag: 0,
            commentTextCount: 250,
        };
    }

    componentDidMount() {
        let configuredRecordsets = [];
        let location_FieldIdArray;
        let location_RecordsetId;
        let location_ModifiableRecordsetId;
        configuredRecordsets = this.props.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physicianlocations'
                ) {
                    location_FieldIdArray = data.fields;
                    location_RecordsetId = data.recordsetId;
                    location_ModifiableRecordsetId = data.modifiableRecordsetId;
                }
                return data;
            });
        }
        this.setState({
            locationFieldIdArray: location_FieldIdArray,
            locationRecordsetId: location_RecordsetId,
            modifyLocationRecordsetId: location_ModifiableRecordsetId,
        });

        if (this.state.location_details !== '') {
            this.setState({ show: false });
            let propertyTemp;
            for (const property in this.state.location_details) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.state.location_details,
                        property
                    )
                ) {
                    propertyTemp = property;
                    if (property === 'location_name') propertyTemp = 'location';
                    if (property === 'states') propertyTemp = 'state';
                    if (property === 'zipcode') propertyTemp = 'zip';

                    this.setState({
                        [property]: this.state.location_details[property],
                    });
                    this.setState({
                        [propertyTemp]: this.state.location_details[property],
                    });
                    this.props.updateState({
                        [property]: this.state.location_details[property],
                    });
                    this.props.updateState({
                        [propertyTemp]: this.state.location_details[property],
                    });
                }
            }
            this.setState({
                show: true,
                //location_name: '',
            });
            // this.setState({'location_address_lookup' : this.state.location_details.location_name});
            // this.setState({'location_name' : this.state.location_details.location_name});
        }
    }

    componentDidUpdate(prevProps) {
        // if (this.props.state !== prevProps.state) {
        //     this.setState({ ...this.props.state });
        // }
    }

    handleInputOnChange = (state, event) => {
        const value = event.target.value;
        // let stateValue;
        this.setState({ [state]: value });
        // this.props.updateState({ [state]: value });
        // stateValue = state;
        //if (state === 'location_name') stateValue = 'location';
        // if (state === 'states') stateValue = 'state';
        // if (state === 'zipcode') stateValue = 'zip';

        this.props.updateState({
            location_details: {
                ...this.props.location_details,
                [state]: value,
            },
            [state]: value,
        });

        //});
    };

    handleLocationOnChange = (state, event) => {
        const value = event.target.value;
        this.setState({
            location: value,
            location_name: value,
        });
        this.props.updateState({
            location_details: {
                ...this.props.location_details,
                location: value,
                //location_name: value,
            },
            location: value,
            location_name: value,
        });
    };

    handleStateOnChange = (event) => {
        this.setState({
            states: event.target.value,
        });
        this.props.updateState({
            location_details: {
                ...this.props.location_details,
                state: event.target.value,
            },
            states: event.target.value,
        });
    };
    handleZipCodeChange = (event) => {
        const regExp = /^[0-9-]+$/;
        if (event.target.value === '' || regExp.test(event.target.value)) {
            // let zipValue =event.target.value
            this.setState({
                zipcode: event.target.value,
            });
            this.props.updateState({
                location_details: {
                    ...this.props.location_details,
                    zip: event.target.value,
                    zipcode: event.target.value,
                },
                zip: event.target.value,
                zipcode: event.target.value,
            });
        }
    };
    handleNoteOnChange = (event) => {
        this.setState({
            note: event.target.value,
        });
        this.props.updateState({
            location_details: {
                ...this.props.location_details,
                note: event.target.value,
            },
            note: event.target.value,
        });

        // Iswarya to work
        const length = event.target.value.length;
        const countValue = 250 - length;
        if (length > 250) {
            this.setState({ commentTextCount: 0 });
            this.setState({ commentTextFlag: 1 });
        } else {
            this.setState({ commentTextCount: countValue });
            this.setState({ commentTextFlag: 1 });
        }
    };
    openCreateNewLocation = () => {
        this.props.openCreateNewLocation();
        this.setState({
            location_name: '',
            location: '',
            address: '',
            city: '',
            states: '',
            zipcode: '',
            note: '',
        });
    };

    render() {
        var opts = {};
        if (this.props.selected_location) {
            opts['readOnly'] = 'readOnly';
            opts['disabled'] = 'disabled';
        }
        return (
            <>
                {this.state.show ? (
                    <div className='location-block'>
                        {this.props.edit_Location === false ||
                        this.props.autoCompeteLocation === true ? (
                            <div className='row grid-row-group'>
                                <div className='transition-select col-lg-12'>
                                    <label className='pl-5'>
                                        ADDRESS LOOKUP
                                    </label>
                                    <div className='d-flex create-location'>
                                        <div className='position-relative w-100'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={
                                                    this.props
                                                        .selected_location !==
                                                    ''
                                                        ? this.props
                                                              .selected_location
                                                        : this.props
                                                              .locationId === ''
                                                        ? 'Create New Address'
                                                        : ''
                                                }
                                                readOnly
                                            />
                                        </div>
                                        {this.props.selected_location !== '' ? (
                                            <div
                                                className='plus'
                                                onClick={() => {
                                                    this.openCreateNewLocation();
                                                }}
                                            >
                                                <span>
                                                    <i
                                                        className='fa-regular fa-plus'
                                                        aria-hidden='true'
                                                    />
                                                </span>
                                            </div>
                                        ) : this.props.locationId ===
                                          '' ? null : (
                                            <div
                                                className='plus'
                                                onClick={() => {
                                                    this.openCreateNewLocation();
                                                }}
                                            >
                                                <span>
                                                    <i
                                                        className='fa-regular fa-plus'
                                                        aria-hidden='true'
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className='row grid-row-group'>
                            <div className='transition-select col-lg-12'>
                                <label className='pl-5'>LOCATION NAME</label>
                                <input
                                    type='text'
                                    className={
                                        (this.props.validateEdit &&
                                            this.state.location_name === '') ||
                                        (this.props.location_validation &&
                                            this.props.location === '')
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        this.state.location_name !== ''
                                            ? this.state.location_name
                                            : ''
                                    }
                                    onChange={(event) => {
                                        this.handleLocationOnChange(
                                            'location',
                                            event
                                        );
                                    }}
                                    {...opts}
                                />
                                {(this.props.validateEdit &&
                                    this.state.location_name === '') ||
                                (this.props.location_validation &&
                                    this.props.location_name === '') ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Location Name is required.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className='row grid-row-group'>
                            <div className='transition-select col-lg-12'>
                                <label className='pl-5'>ADDRESS</label>
                                <input
                                    type='text'
                                    className={
                                        (this.props.validateEdit &&
                                            this.state.address === '') ||
                                        (this.props.location_validation &&
                                            this.props.address === '')
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        this.state.address !== ''
                                            ? this.state.address
                                            : ''
                                    }
                                    onChange={(event) => {
                                        this.handleInputOnChange(
                                            'address',
                                            event
                                        );
                                    }}
                                    {...opts}
                                />
                                {(this.props.validateEdit &&
                                    this.state.address === '') ||
                                (this.props.location_validation &&
                                    this.props.address === '') ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Address is required.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className='row grid-row-group'>
                            <div className='transition-select col-lg-4'>
                                <label className='pl-5'>CITY</label>
                                <input
                                    type='text'
                                    className={
                                        (this.props.validateEdit &&
                                            this.state.city === '') ||
                                        (this.props.location_validation &&
                                            this.props.city === '')
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        this.state.city !== ''
                                            ? this.state.city
                                            : ''
                                    }
                                    onChange={(event) => {
                                        this.handleInputOnChange('city', event);
                                    }}
                                    {...opts}
                                />
                                {(this.props.validateEdit &&
                                    this.state.city === '') ||
                                (this.props.location_validation &&
                                    this.props.city === '') ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        City is required.
                                    </p>
                                ) : null}
                            </div>

                            <div className='transition-select col-lg-4'>
                                <label className='pl-5'>STATE</label>
                                <select
                                    className={
                                        (this.props.validateEdit &&
                                            this.state.states === '') ||
                                        (this.props.location_validation &&
                                            this.props.states === '')
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        this.state.states !== ''
                                            ? this.state.states
                                            : ''
                                    }
                                    onChange={(event) => {
                                        this.handleStateOnChange(event);
                                    }}
                                    {...opts}
                                >
                                    <option value=''>select</option>
                                    {this.state.statesOption !== undefined
                                        ? this.state.statesOption.map(
                                              (options, idx) => {
                                                  return (
                                                      <option
                                                          value={options}
                                                          key={idx}
                                                      >
                                                          {options}
                                                      </option>
                                                  );
                                              }
                                          )
                                        : null}
                                </select>
                                <i
                                    className='fa-light fa-chevron-down'
                                    style={{ pointerEvents: 'none' }}
                                />
                                {(this.props.validateEdit &&
                                    this.state.states === '') ||
                                (this.props.location_validation &&
                                    this.props.states === '') ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        State is required.
                                    </p>
                                ) : null}
                            </div>

                            <div className='transition-select col-lg-4'>
                                <label className='pl-5'>ZIP CODE</label>
                                <input
                                    type='text'
                                    maxLength='11'
                                    className={
                                        (this.props.validateEdit &&
                                            this.state.zipcode === '') ||
                                        (this.props.location_validation &&
                                            this.props.zipcode === '')
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={
                                        this.state.zipcode !== ''
                                            ? this.state.zipcode
                                            : ''
                                    }
                                    // onChange={(event) => {
                                    //     this.handleInputOnChange(
                                    //         'zipcode',
                                    //         event
                                    //     );
                                    // }}
                                    onChange={(event) => {
                                        this.handleZipCodeChange(event);
                                    }}
                                    {...opts}
                                />
                                {(this.props.validateEdit &&
                                    this.state.zipcode === '') ||
                                (this.props.location_validation &&
                                    this.props.zipcode === '') ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Zipcode is required.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className='row grid-row-group mt-4'>
                            <div className='transition-select col-lg-12'>
                                <label className='pl-5'>Note</label>
                                <textarea
                                    className='form-control'
                                    value={
                                        this.state.note !== ''
                                            ? this.state.note
                                            : ''
                                    }
                                    onChange={(event) => {
                                        this.handleNoteOnChange(event);
                                    }}
                                    maxLength='250'
                                ></textarea>
                                <div className='maximum-length'>
                                    <span className='float-left'>
                                        Maximum Length: 250
                                    </span>
                                    {this.state.commentTextFlag === 1 ? (
                                        <span className='float-right'>
                                            Remaining:{' '}
                                            {this.state.commentTextCount}
                                        </span>
                                    ) : (
                                        ' '
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateEditLocation));
