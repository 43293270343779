import React from 'react';
import Chart from 'react-apexcharts';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ApexCharts from 'apexcharts';
import jsPDF from 'jspdf';
import {
    reportColorCodeArray,
    getRandomColors,
    sortObjectByKeys,
    formatHeaderNameWithOutSpace,
    reportColorCodeBigArray,
} from '../../components/commonfunction';
import { deletedPage } from '../_redux/actions/searchActions';
const _ = require('lodash');

class TreeMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imgUrl: '',
            filteredData: this.props.filteredData,
            //filterTxt: this.props.filterTxt,
            rowData: this.props.rowData,
            allRowData: this.props.rowData,
            //totalCount: this.props.totalCount,
            reportParams: this.props.reportParams,
            graphicalField: this.props.graphicalField,
            statusCount: 0,
            outboundStatus: [],
            showStatus: false,

            series: [
                {
                    data: [
                        {
                            x: 'New Delhi',
                            y: 218,
                        },
                        {
                            x: 'Kolkata',
                            y: 149,
                        },
                        {
                            x: 'Mumbai',
                            y: 184,
                        },
                    ],
                },
            ],
            legend: {
                default: true,
                show: true,
            },

            options: {
                legend: {
                    showForSingleSeries: true,
                    show: true,
                    position: 'top',
                    horizontalAlign: 'left',
                    width: '100%',
                    height: '100%',
                    fontSize: '13px',
                    onItemClick: {
                        toggleDataSeries: false,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                },
                chart: {
                    id: formatHeaderNameWithOutSpace(this.props.reportParams),
                    height: 500,
                    type: 'treemap',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset:
                                true |
                                '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [
                                {
                                    icon: `<i class="fa-solid fa-file-arrow-down"></i>`,
                                    class: 'custom-icon',
                                    index: 0,
                                    title: 'Download PDF',
                                    click: () => {
                                        this.downloadPDF();
                                    },
                                },
                            ],
                        },
                    },
                    events: {
                        legendClick: function (
                            chartContext,
                            seriesIndex,
                            config
                        ) {
                            props.radialChartOnClick(
                                config.config.series[seriesIndex].name,
                                'Treemap'
                            );
                        },
                    },
                },
                colors: [
                    '#0029BC',
                    '#007DBC',
                    '#00B1BC',
                    '#55D36A',
                    '#8DC63F',
                    '#F3E13B',
                    '#ECB445',
                    '#D87C3A',
                    '#D84C3A',
                    '#D83A71',
                    '#9F3481',
                    '#8B36BF',
                ],
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false,
                    },
                },
                responsive: [
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                width: '100%',
                                height: 350,
                                position: 'center',
                                horizontalAlign: 'left',
                            },
                            legend: {
                                show: true,
                                position: 'bottom',
                                width: '100%',
                                horizontalAlign: 'center',
                                fontSize: '10px',
                            },
                        },
                    },
                ],
            },
        };
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
    }

    async componentDidMount() {
        await this.drawDonut();
    }
    async componentDidUpdate(prevState, prevProps) {
        if (
            _.isEqual(this.state.filteredData, this.props.filteredData) ===
                false &&
            this.props.chartName === 'Treemap'
        ) {
            this.setState(
                {
                    filteredData: this.props.filteredData,
                },
                () => {
                    this.drawDonut();
                }
            );
        }
        if (this.props.noDataOnClick === true) {
            this.props.updateState({
                noDataOnClick: false,
                noDataFound: false,
            });
            await this.refreshRadialChart();

            if (this.props.deleted_Page.onClickchartName === 'Treemap') {
                await this.props.radialChartOnClick(
                    this.props.deleted_Page.onClickchartFilterTxt,
                    'Treemap'
                );
            }
        }
    }
    refreshRadialChart = async () => {
        const paginatedRowData = _.slice(this.props.allRows, 0, 10);
        await this.props.updateState({
            chartFilterTxt: '',
            // filteredRowData: [],
            filteredData: this.props.allRows,
            rowData: paginatedRowData,
            allRows: this.props.allRows,
            currentPage: 0,
            totalCount: this.props.allRows.length,
            propertyTiles: 'All Data',
            selectedRadialChart: 'All Data',
            chartName: '',
            perPage: 10,
            noDataFound: false,
        });

        if (this.props.filterTxt !== '') {
            this.props.filterDataGrid(this.props.filterTxt);
        } else {
            this.props.resetPopOverData(this.props.allRows);
            this.props.filterDataGrid(this.props.filterTxt);
        }
    };
    getKey = () => {
        return this.keyCount++;
    };
    drawDonut = async () => {
        if (
            Array.isArray(this.state.filteredData) &&
            this.state.filteredData.length > 0
        ) {
            this.state.allRowData = this.state.filteredData;
        }

        let statuscounting = 0;
        let graphicalField = this.state.graphicalField;

        var del;
        let series = [];
        if (
            String(graphicalField) !== 'daysoutstanding' &&
            this.state.allRowData !== undefined
        ) {
            // error came if condition added
            // if (this.state.allRowData !== undefined) {
            const result = await this.state.allRowData.reduce(function (
                r,
                a,
                c
            ) {
                del = a[graphicalField];

                //if (del !== '' && del !== ',') {
                statuscounting++;
                if (del === '' || del === ',') del = '(Blank)';
                r[del] = r[del] || [];
                //r[del].push(a);//kalpana changed this
                //r[del].push(a.id);
                r[del]++;
                // }
                return r;
            },
            Object.create(null));
            var finalresult = sortObjectByKeys(result);

            await this.setState({
                statusCount: statuscounting,
                outboundStatus: finalresult,
            });

            var propertyArray = [];
            var percentArray = [];
            var chartColorArray = [];
            let id_index = 0;
            let percen = 0;

            const randomcolorsArray = this.getRandomColorCodeArray(0);

            var dataArray = [];

            for (const property in finalresult) {
                //percen = (this.state.outboundStatus[property].length / this.state.statusCount) * 100;
                percen = finalresult[property];

                if (percen > 0) {
                    propertyArray.push(property);
                    percentArray.push(percen);
                    var headerArray = {
                        x: property,
                        y: percen,
                        fillColor: randomcolorsArray[id_index],
                    };
                    series.push({
                        data: [headerArray],
                        name: property,
                    });
                    dataArray.push(headerArray);

                    chartColorArray.push(randomcolorsArray[id_index]);
                }
                id_index++;
            }
            await this.setState({
                showStatus: true,
            });
        }

        await this.setState({
            series: series,
            options: {
                ...this.state.options,
                labels: propertyArray,
                colors: chartColorArray,
            },
        });
    };

    getRandomColorCodeArray = (resultCount) => {
        let coun = 0;
        // eslint-disable-next-line eqeqeq
        if (resultCount != 0) {
            coun = resultCount;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const property in this.state.outboundStatus) {
                coun++;
            }
        }
        let colorCode = reportColorCodeArray();
        let originalColorCount = colorCode.length;
        if (coun > originalColorCount) {
            colorCode = reportColorCodeBigArray(coun);
            originalColorCount = colorCode.length;
        }
        const totalColorCount = Math.floor(originalColorCount / coun);
        const randomcolors = getRandomColors(colorCode, totalColorCount);

        return randomcolors;
    };

    getDataUri = async (chartId) => {
        return await ApexCharts.exec(chartId, 'dataURI').then(({ imgURI }) => {
            this.setState({ imgUrl: imgURI });
            return imgURI;
        });
    };

    downloadPDF = () => {
        this.getDataUri(this.state.options.chart.id);

        setTimeout(() => {
            const pdf = new jsPDF();

            var pdfname =
                formatHeaderNameWithOutSpace(this.props.reportParams) + '.pdf';
            pdf.setFontSize(20);
            pdf.text(20, 25, this.props.reportParams);
            pdf.addImage(this.state.imgUrl, 'PNG', 10, 50);
            pdf.save(pdfname);
        }, 1000);
    };

    render() {
        return (
            <div className='report-doc'>
                <div className='report-doc-top'>
                    <h4>Per {this.props.grpahicalFieldNameFormat}</h4>
                    <div className='filter-sec'>
                        <span
                            className='reload'
                            onClick={() => {
                                this.refreshRadialChart();
                                this.props.deletedPage({
                                    chartName: '',
                                    chartFilterTxt: '',
                                    currentPage_Report: 0,
                                    perPage_Report: this.state.perPage,
                                });
                            }}
                        >
                            <i
                                title='Refresh'
                                className={
                                    this.state.displayAnimation
                                        ? 'fas fa-sync-alt refreshing'
                                        : 'fas fa-sync-alt'
                                }
                            />
                        </span>
                    </div>
                </div>
                <div
                    className={
                        this.props.graphicalTypeArray !== undefined &&
                        this.props.graphicalTypeArray.length === 1
                            ? 'd-flex justify-content-center'
                            : ''
                    }
                >
                    <div
                        style={
                            {
                                /*height: '445px',*/
                            }
                        }
                        className={
                            this.props.graphicalTypeArray !== undefined &&
                            this.props.graphicalTypeArray.length === 1
                                ? 'col-lg-6'
                                : ''
                        }
                    >
                        <div id='chart '>
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                type='treemap'
                                width='100%'
                                height={
                                    this.state.series.length > 15 ? 600 : 420
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    deletedPage,
};
const mapStateToProps = (state) => {
    return {
        outbox: state.outbox,
        deleted_Page: state.search.deletedPage,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TreeMap));
