const GmailAuthenticationPage = (props) => {
    let userDetials = localStorage.getItem('user_details');
    userDetials = JSON.parse(userDetials);
    // localStorage.setItem('props ==== > gmail_sendDraftID', props);
    const redirectUri = props.configData.emailIntegration.serverURL;
    const gmailAccessTokenURL =
        process.env.REACT_APP_ENV === 'dev'
            ? `https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com&include_granted_scopes=true&access_type=offline&prompt=consent&response_type=code&state=security_token=138r5719ru3e1%26url=http://localhost:8081/mailSuccess&redirect_uri=http://localhost:8081/mailSuccess&client_id=${props.configData?.emailIntegration?.clientId}&login_hint=${userDetials.userEmail}&hd=${userDetials.userEmail}`
            : `https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com&include_granted_scopes=true&access_type=offline&prompt=consent&response_type=code&state=security_token=138r5719ru3e1%26url=${redirectUri}&redirect_uri=${redirectUri}&client_id=${props.configData?.emailIntegration?.clientId}&login_hint=${userDetials.userEmail}&hd=${userDetials.userEmail}`;

    const token = localStorage.getItem('gmail_access_token');
    const pageTitle = process.env.REACT_APP_TITLE;

    const windowURL = (link, pageSetUp) => {
        const winData = window.open(
            link,
            pageSetUp,
            'height=600,width=500,scrollbars=yes'
        );
        winData.document.title = pageTitle;
    };

    const redirectToAuth = () => {
        windowURL(gmailAccessTokenURL, '_blank');
    };

    const refreshToken = (email) => {
        const clientId = localStorage.getItem('gmail_client_id');
        const clientSecret = localStorage.getItem('gmail_client_secret');
        const refreshToken = localStorage.getItem('gmail_refresh_token');
        const sendDraftID = localStorage.getItem('gmail_sendDraftID');

        if (refreshToken == null) {
            redirectToAuth();
            return;
        }

        const params = JSON.stringify({
            client_id: clientId,
            client_secret: clientSecret,
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        });

        try {
            fetch('https://www.googleapis.com/oauth2/v4/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: params,
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json?.access_token) {
                        localStorage.setItem(
                            'gmail_access_token',
                            json.access_token
                        );
                        windowURL(
                            `${redirectUri}?code=access&docId=${sendDraftID}&token=${json.access_token}&mailId=${email}`,
                            '_blank'
                        );
                    } else {
                        this.redirectToAuth();
                    }
                })
                .catch((error) => {});
        } catch (err) {}
    };

    try {
        fetch(
            `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${token}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => response.json())
            .then((json) => {
                if (json?.expires_in) {
                    if (Number(json.expires_in) < 10) {
                        refreshToken(userDetials.userEmail);
                    } else {
                        windowURL(
                            `${redirectUri}?code=access&docId=${props.selectedDocID}&token=${token}&mailId=${userDetials.userEmail}`,
                            '_blank'
                        );
                    }
                } else {
                    refreshToken(userDetials.userEmail);
                }
            })
            .catch((error) => {});
    } catch (err) {}
};

export default GmailAuthenticationPage;
