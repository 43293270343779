/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentDetail from './DocumentDetail';
import DetailBigView from './DetailBigView';
import { getDocumentMetaWithDocId, getConfigurationData } from '../../api/api';
import {
    download,
    stringEmptyOrUndefined,
    userPermission,
    GetFieldData,
    formatNameWithSpaceTohipen,
    getDropDownDataFromApi,
    formatHeaderNameWithOutSpace,
} from '../../../components/commonfunction';
import { getDDLlist } from '../../_redux/actions/configActions';
import {
    inboxPage,
    getSelectedRowDetails,
} from '../../_redux/actions/inboxActions';
import PdfPreview from './PdfPreview';
import { deletedPage } from '../../_redux/actions/searchActions';

class DocumentDetailViewComponent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLockedBy: null,
            isLocked: false,
            noteCount: 0,
            pageCount: 0,
            menuBranch: null,
            menuGroup: null,
            ownerlist: [],
            processId: null,
            statusId: null,
            allStatusArray: [],
            menu_name: 'Inbox',
            branches: [],
            groups: [],
            row_transition: false,
            show_branch: false,
            branchActionId: null,
            show_group: false,
            groupActionId: null,
            show_inbox: false,
            show_delete: false,
            deleteActionId: null,
            show_tag: false,
            show_owner: false,
            show_notes: false,
            show_print: true,
            show_download: true,
            show_pdf: true,
            statusArray: [],
            notesVal: null,
            notes_ddl: null,
            notes_submitted: 0,
            open_notes: false,
            noteError: null,
            selectedRows: [],
            selectedRowData: {},
        };
    }

    // static contextType = ThemeContext;
    componentWillUnmount() {
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        document.body.classList.remove('show-detail-view');
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        if (this.props.loader === true && this.props.calingFrom === 'report') {
            this.props.updateState({
                loader: false,
            });
        }
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        document.body.classList.add('show-detail-view');
        if (this.props.match.params !== undefined) {
            let docid = this.props.location.state.selectedDocID;

            docid = window.atob(String(docid));

            const { processId, statusId } = this.props.location.state;
            let rowDetails = { doc_id: docid };
            let detailsData = localStorage.getItem('detail_data');
            if (Object.keys(this.props.stateData).length > 0) {
                localStorage.setItem(
                    'detail_data',
                    JSON.stringify(this.props.stateData)
                );
                localStorage.setItem(
                    'inbox_data',
                    JSON.stringify(this.props.inbox)
                );
                rowDetails = this.props.stateData.rowDetails;
            } else if (detailsData !== null) {
                detailsData = JSON.parse(detailsData);
                rowDetails = detailsData.rowDetails;
            }

            this.setState({
                rowDetails,
                selectedDocId: docid,
            });
            const response = getDocumentMetaWithDocId({ docid });
            const configData = getConfigurationData();
            let menuBranch = null;
            let menuGroup = null;
            const fieldDataObj = GetFieldData({
                configData: this.props.configData,
            });
            response.then((data) => {
                this.props.getSelectedRowDetails(data);
                const foundFields = [];

                if (data.fields !== undefined) {
                    data.fields.forEach((dataValue) => {
                        const fields = dataValue.fieldId;
                        if (fields === fieldDataObj.branchFieldId) {
                            menuBranch = dataValue.value;
                        }
                        if (fields === fieldDataObj.groupFieldId) {
                            menuGroup = dataValue.value;
                        }
                        for (const property in configData) {
                            if (configData[property].fieldId === fields) {
                                foundFields.push(configData[property]);
                            }
                        }
                    });
                }
                if (
                    data.isLocked &&
                    document.querySelector('.rdg-row.row-selected-once') !==
                        null
                ) {
                    document
                        .querySelector('.rdg-row.row-selected-once')
                        .classList.add('locked');
                }
                let permission = userPermission({
                    processId: this.props.processId,
                    statusId: this.props.statusId,
                    menu_name: this.props.currentMenuName,
                    configData: this.props.configData,
                });
                permission = {
                    ...permission,
                    show_pdf: true,
                    show_notes: true,
                };
                this.setState({
                    selectedDocId: docid,
                    isLockedBy: data.isLockedBy,
                    isLocked: data.isLocked,
                    noteCount: data.noteCount,
                    pageCount: data.pageCount,
                    menuBranch,
                    menuGroup,
                    rowDetails,
                    processId,
                    statusId,
                    selectedRows: [docid],
                    ...permission,
                });
            });
        }

        if (!this.props.ddl.hasOwnProperty('branch')) {
            await getDropDownDataFromApi(fieldDataObj.branch).then(
                async (data) => {
                    if (data.values) {
                        await this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                }
            );
        }
        if (!this.props.ddl.hasOwnProperty('group')) {
            await getDropDownDataFromApi(fieldDataObj.group).then(
                async (data) => {
                    if (data.values) {
                        await this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                }
            );
        }
        this.setState({
            branches: this.props.ddl?.branch,
            groups: this.props.ddl?.group,
            refreshDetailView: true,
        });

        if (this.props.calingFrom === 'report') {
            await this.setState({
                ...this.props.deleted_Page.permission,
                processId: this.props.deleted_Page.processId,
                statusId: this.props.deleted_Page.statusId,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.state !== undefined) {
            const { scrollView } = this.props.location.state;
            if (scrollView !== undefined && scrollView === true) {
                window.scrollTo(0, document.body.scrollHeight);
            }
        }
        if (this.props.loader === true && this.props.calingFrom === 'report') {
            this.props.updateState({
                loader: false,
            });
        }
    }

    rowTransition = async () => {
        await this.setState({ row_transition: false });
        localStorage.setItem('disable_checkbox', false);
    };

    set_group = (event) => {
        this.setState({ menu_group: event.target.value });
    };

    goToPage = () => {
        // this.props.history.goBack();
        const currentPath = window.location.pathname.split('/');
        const lastIndex = window.location.pathname.lastIndexOf('/');
        const before = window.location.pathname.slice(0, lastIndex);
        const reportIndex = before.lastIndexOf('/');
        const ReportName = before.slice(reportIndex + 1);
        if (this.props.calingFrom === 'report') {
            this.props.updateState({
                hideSmallView: false,
                // visibleDoc: false,
            });

            // this.props.history.goBack();

            let pushPath;
            this.props.configData.reports.map((data) => {
                if (ReportName === formatNameWithSpaceTohipen(data.name)) {
                    pushPath = before + '/' + data.reportId;
                }
            });
            this.props.history.push(pushPath);

            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
            this.props.deletedPage({
                hideSmallView: false,
            });
            // this.props.removeAllGrouping();
        } else {
            this.props.inboxPage({
                related_doc_detail: false,
                multiple_edit_page: false,
                row_transition: true,
            });

            this.props.history.goBack();
            setTimeout(() => {
                if (this.props.related_doc_from !== 'bigview') {
                    document.body.classList.add('aside-minimize');
                    document.body.classList.add('doc-small-view');
                }

                if (this.props.related_doc_from === 'bigview') {
                    document.body.classList.add('aside-minimize');
                    this.props.inboxPage({
                        related_doc_from: '',
                        row_transition: true,
                    });
                }
            }, 1000);
        }
    };

    render() {
        return (
            <>
                <div
                    id='right-aside'
                    className='right-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                    // id='left-aside'
                    // className={
                    //         'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'}
                >
                    {this.state.refreshDetailView &&
                    this.props.calingFrom !== 'report' ? (
                        <DocumentDetail
                            selectedDocId={
                                this.props.location.state !== undefined &&
                                this.props.location.state !== null &&
                                this.props.location.state.selectedDocID //need to check undefined null and empty case
                                    ? window.atob(
                                          this.props.location.state
                                              .selectedDocID
                                      )
                                    : null
                            }
                            isLocked={this.state.isLocked}
                            lockedBy={this.state.isLockedBy}
                            noteCount={this.state.noteCount}
                            pageCount={this.state.pageCount}
                            detailView
                            ownerlist={this.state.ownerlist}
                            rowDetails={this.state.rowDetails}
                            notesDDL={JSON.parse(
                                localStorage.getItem('noteTypes')
                            )}
                            menuGroup={this.state.menuGroup}
                            goToPage={this.goToPage}
                            pdf_preview
                            callingFrom='related_document'
                            /* processId={this.props.location.state.processId}
                        statusId={this.props.location.state.statusId}*/
                            processId={
                                this.props.location.state !== undefined &&
                                this.props.location.state.processId !==
                                    undefined
                                    ? this.props.location.state.processId
                                    : null
                            }
                            statusId={
                                this.props.location.state !== undefined &&
                                this.props.location.state.statusId !== undefined
                                    ? this.props.location.state.statusId
                                    : null
                            }
                        />
                    ) : null}
                    <PdfPreview
                        docId={
                            this.props.location.state !== undefined &&
                            this.props.location.state.selectedDocID !==
                                undefined
                                ? window.atob(
                                      this.props.location.state.selectedDocID
                                  )
                                : null
                        }
                    />
                </div>
                <div
                    id='left-aside'
                    className='expand-mode left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                >
                    {this.state.refreshDetailView && (
                        <DetailBigView
                            selectedDocId={
                                this.props.location.state !== undefined &&
                                this.props.location.state.selectedDocID !==
                                    undefined
                                    ? window.atob(
                                          this.props.location.state
                                              .selectedDocID
                                      )
                                    : ''
                            }
                            download={() => {
                                this.props.location.state !== undefined &&
                                    this.props.location.state.selectedDocID !==
                                        undefined &&
                                    download(
                                        window.atob(
                                            this.props.location.state
                                                .selectedDocID
                                        )
                                    );
                            }}
                            openDeletePopUp={() => {}}
                            statusArray={this.state.statusArray}
                            branches={this.state.branches}
                            groups={this.state.groups}
                            rowTransition={() => {
                                this.rowTransition();
                            }}
                            menuGroup={this.state.menuGroup}
                            showBranch={this.state.show_branch}
                            showDelete={this.state.show_delete}
                            showDownload={this.state.show_download}
                            showGroup={this.state.show_group}
                            showInbox={this.state.show_inbox}
                            showNotes={this.state.show_notes}
                            showOwner={this.state.show_owner}
                            showPrint={this.state.show_print}
                            showPdf={this.state.show_pdf}
                            detailView
                            menuBranch={this.state.menuBranch}
                            menuInbox={this.state.menu_inbox}
                            goToPage={this.goToPage}
                            callingFrom='related_document'
                            processId={
                                this.props.location.state !== undefined &&
                                this.props.location.state.processId !==
                                    undefined
                                    ? this.props.location.state.processId
                                    : ''
                            }
                            statusId={
                                this.props.location.state !== undefined &&
                                this.props.location.state.statusId
                                    ? this.props.location.state.statusId
                                    : ''
                            }
                            reportPagecalingFrom={this.props.calingFrom}
                        />
                    )}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    inboxPage,
    getSelectedRowDetails,
    deletedPage,
    getDDLlist: getDDLlist,
};
const mapStateToProps = (state) => {
    return {
        rowData: state.inbox,
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        currentMenuName: state.persist.currentMenuName,
        processId: state.inbox.processId,
        statusId: state.inbox.statusId,
        stateData: stringEmptyOrUndefined(state.inbox)
            ? state.inbox.stateData
            : {},
        inbox: stringEmptyOrUndefined(state.inbox) ? state.inbox : {},
        related_doc_from: state.inbox.inboxPage.related_doc_from,
        deleted_Page: state.search.deletedPage,
        ddl: state.persistDDL.DDL,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DocumentDetailViewComponent));
