import React from 'react';
import Autocomplete from 'react-autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import { addDocumentOwners } from '../../api/api';
import { updateList } from '../../_redux/actions/inboxActions';

import {
    getDocumentsOwners,
    showErrorToast,
} from '../../../components/commonfunction';

export function OwnerAutocomplete(props) {
    const dispatch = useDispatch();
    const [ownerName, setOwnerValue] = React.useState('');
    const [showRemoveOwner, setShowRemoveOwner] = React.useState(false);
    const redux_store = useSelector((state) => state.outbox);
    const newState = useSelector((state) => state);

    const docOwner = newState.persistDDL.DDL.owner;
    const displayOwner = getDocumentsOwners(docOwner);
    const handleOwnerChange = (val) => {
        let ownData;
        let value;
        let owner_id;
        if (val === '') {
            value = '';
            owner_id = '';
        } else {
            ownData = val.split('+');
            value = ownData[0];
            owner_id = ownData[1];
        }
        // let rowsToUpdate	=	[];
        // let rowDocs			=	[];
        const docIds = [props.docId];
        const processId = redux_store.processId;
        const statusId = redux_store.statusId;
        // addDocumentOwners(processId, statusId, owner_id, docIds).then(response => {
        // 	//dispatch(getListaction(processId,statusId)); by kalpana
        // }).catch(err=>{});
        const params = {};
        params.processId = processId;
        params.statusId = statusId;
        params.owner_id = owner_id;
        params.docIds = docIds;
        params.actionId = props.actionId;

        params.fieldId = props.ownerFieldId;

        //	params.statusArr=newStatus
        let status = '';
        addDocumentOwners(params)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((data) => {
                if (
                    parseInt(status, 10) === 200 &&
                    data.hadFailures === false
                ) {
                    if (typeof props.handleHide === 'function') {
                        props.handleHide();
                    }
                    if (typeof props.handleOwnerNameReset === 'function') {
                        props.handleOwnerNameReset(value);
                    }
                    if (redux_store.outbox.dataTable !== undefined) {
                        let ownerIndex = '';
                        redux_store.outbox.dataTable.headerColumns.map(
                            (data, index) => {
                                if (data.name.toLowerCase() === 'owner') {
                                    ownerIndex = index;
                                }
                                return data;
                            }
                        );
                        const tableData = redux_store.outbox;
                        const rawData =
                            redux_store.outbox.dataTable.dataRows.slice();
                        redux_store.outbox.dataTable.dataRows.map(
                            (data, index) => {
                                if (docIds.includes(data.id)) {
                                    const updated = {};
                                    updated[ownerIndex] = value;
                                    data.values.map((row, idx) => {
                                        if (idx === ownerIndex) {
                                            const rowToUpdate =
                                                rawData[index]['values'];
                                            const updatedRaw = update(
                                                rowToUpdate,
                                                { $merge: updated }
                                            );
                                            rawData[index]['values'] =
                                                updatedRaw;
                                        }
                                        return row;
                                    });
                                }
                                return data;
                            }
                        );
                        dispatch(updateList(''));
                        tableData.dataTable.dataRows = rawData;
                        dispatch(updateList(tableData));
                    }
                } else {
                    let message = data?.results[0]?.message
                        ? data?.results[0]?.message
                        : 'Error in processing';
                    showErrorToast(message);
                }
            });
        // dispatch(setSelectedRow(props.docId));
        // dispatch(setOwner(value));
    };
    React.useEffect(() => {
        if (redux_store.stateData.rowDetails.owner_rawdata) {
            setShowRemoveOwner(true);
        } else {
            setShowRemoveOwner(false);
        }
        const handleListener = (e) => {
            const displayEle = document.getElementById('owner-display-name');
            const hasDisplayEle =
                displayEle !== null && displayEle.contains(e.target);
            const containerEle = document.getElementById(
                'ownerAutocompleteArea'
            );
            const hasContainerEle =
                containerEle !== null && containerEle.contains(e.target);

            if (!hasContainerEle && !hasDisplayEle) {
                if (typeof props.handleHide === 'function') {
                    props.handleHide();
                }
            }
        };
        window.addEventListener('click', handleListener);
        return () => {
            window.removeEventListener('click', handleListener);
        };
    }, [props]);

    return (
        <>
            <div id='ownerAutocompleteArea'>
                <Autocomplete
                    open
                    inputProps={{
                        placeholder: 'Search teammate',
                        autoFocus: true,
                    }}
                    items={displayOwner ? displayOwner : []}
                    shouldItemRender={(item, value) =>
                        item.label.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                    }
                    getItemValue={(item) =>
                        `${item.label}+${item.id}+${item.colorCode}+${item.ownerId}`
                    }
                    renderItem={(item, isHighlighted) => (
                        <div
                            key={item.id}
                            style={{
                                background: isHighlighted
                                    ? 'lightgray'
                                    : 'white',
                                color: 'black',
                            }}
                        >
                            {item.title}
                        </div>
                    )}
                    renderMenu={(items, value) => (
                        <div
                            className='owner-list'
                            style={{ maxHeight: '250px', overflowY: 'auto' }}
                            // eslint-disable-next-line react/no-children-prop
                            children={items}
                        />
                    )}
                    value={ownerName}
                    onChange={(e) => {
                        setOwnerValue(e.target.value);
                    }}
                    onSelect={(value) => handleOwnerChange(value)}
                    renderInput={(params, inputProps) => (
                        <div className='owner-auto-search d-flex'>
                            <span>
                                <i className='fas fa-user-plus' />
                            </span>
                            <input {...params} id='owner_bottom_input' />{' '}
                            {/* onBlur={()=>handleHide()} */}
                        </div>
                    )}
                />
                {showRemoveOwner && (
                    <div
                        className='owner-auto-search d-flex align-items-center'
                        onClick={() => {
                            setOwnerValue('');
                            handleOwnerChange('');
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <span>
                            <i className='fas fa-user-minus' />
                        </span>
                        Remove Assignment
                    </div>
                )}
            </div>
        </>
    );
}
