/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    addDocumentTags,
    unlockDocument,
    lockDocument,
    outboxDocumentAction,
} from '../../pages/api/api';
import {
    GetFieldData,
    stringEmptyOrUndefined,
    getTagFromStorage,
    showErrorToast,
    createTagElement,
} from '../commonfunction';
import {
    updateList as updateListOutbox,
    outboxPage,
    updateOutboxState,
} from '../../../app/pages/_redux/actions/outboxActions';
import {
    updateList as updateListInbox,
    inboxPage,
    updateInboxState,
} from '../../../app/pages/_redux/actions/inboxActions';

export function AddEditTag(props) {
    let tagElement = [];
    const menuSelectedTagElement = [];
    const [menuSelectedTag, setMenuSelectedTag] = useState([]);
    const tagsName = props.selectedTagNameData.map((data) =>
        data.toLowerCase()
    );
    const [menuSelectedTagNameonly, setMenuSelectedTagNameonly] =
        useState(tagsName);
    const [tagLoader, settagLoader] = useState(false);
    const [filterTxt, setFilterTxt] = useState('');
    const [filteredTag, setFilteredTag] = useState('');

    const dispatch = useDispatch();
    const redux_outboxStore = useSelector((state) => state.outbox);
    const redux_inboxStore = useSelector((state) => state.inbox);

    const configReducerPersist = useSelector(
        (state) => state.configReducerPersist
    );
    const allDDls = useSelector((state) => state.persistDDL.DDL);
    const reduxStore = useSelector((state) => state);
    const config = configReducerPersist.configData;

    var ddlTag = allDDls.tags;
    var [tagList, setTagList] = useState([]);
    var [tagColorArray, setTagColorArray] = useState([]);

    React.useEffect(() => {
        const getDLL = async () => {
            const { menu_tags, newTagColorArray } = await getTagFromStorage(
                configReducerPersist.configData,
                ddlTag
            );
            setTagList(menu_tags);
            setTagColorArray(newTagColorArray);
            //  createPromiseCapability;
            let selectedTagTemp = [];
            const finalTags = [];
            const tagonly = [];
            selectedTagTemp = props.selectedTagNameData;
            const tagList = menu_tags.map((item) => item.name);
            selectedTagTemp.map((tag) => {
                if (tagList.includes(tag)) {
                    menu_tags.map((data, index) => {
                        if (tag === data.name) {
                            finalTags.push(data);
                            tagonly.push(data.name);
                        }
                    });
                } else {
                    if (tag) {
                        let ddl = {
                            tagId: 0,
                            name: tag,
                            color: '#7F96A5',
                        };
                        finalTags.push(ddl);
                        tagonly.push(tag);
                    }
                }

                return tag;
            });
            setMenuSelectedTagNameonly(tagonly);
            setMenuSelectedTag(finalTags);
        };

        getDLL();
    }, []);

    const addTag = async (name, action) => {
        var tags = [];
        if (action == 'ADD') {
            tags = [...menuSelectedTagNameonly, name];
        } else {
            settagLoader(true);

            tags = menuSelectedTagNameonly.filter(
                (val) => val.toLowerCase() !== name.toLowerCase()
            );
        }

        const finalTags = [];
        tags.map((data) => {
            const tag = {};
            tag.name = data;
            finalTags.push(tag);
            return data;
        });

        setMenuSelectedTagNameonly(tags);

        const docid = props.docId;
        const tagData = tags;
        if (typeof props.resetCount === 'function') {
            props.updateTag(tagData);
            props.resetCount(tagData.length);
        }
        const fieldDataObj = GetFieldData({ configData: config });
        //await makeApiCall({ docid, tagData });
        const fieldData = {};
        fieldData.fieldId = fieldDataObj.tagFieldId;

        fieldData.value = tagData.join('|');
        fieldData.value += '|';
        let successDocId = [];
        let failedDocId = [];
        const params = {};
        const fields = {};
        fields.docIds = [docid];
        params.processId = reduxStore.outbox.processId;
        params.statusId = reduxStore.outbox.statusId;

        params.actionId =
            props.page == 'inbox'
                ? reduxStore.inbox.stateData.allActions.update_tag.actionId
                : reduxStore.outbox.stateData.allActions.update_tag.actionId;

        params.fields = fields;
        fields.fields = [
            {
                fieldId: fieldDataObj.tags,
                value: fieldData.value,
            },
        ];

        await unlockDocument([docid]).then((data) => {});
        await outboxDocumentAction(params)
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                settagLoader(false);
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                }
            });

        let message;
        if (failedDocId.length !== 0) {
            message = `Doc Id  ${failedDocId[0]} is  Locked`;
            await showErrorToast(message);
        }
        if (successDocId.length !== 0) {
            let tagIndexPosition = '';
            if (
                (redux_outboxStore.outbox !== undefined &&
                    redux_outboxStore.outbox.dataTable !== undefined) ||
                redux_inboxStore
            ) {
                const { tagElement } = createTagElement(
                    fieldData.value,
                    config,
                    false,
                    '',
                    ddlTag
                );

                const tableData =
                    props.page == 'inbox'
                        ? redux_inboxStore.inbox.dataTable
                        : redux_outboxStore.outbox.dataTable;

                tableData.headerColumns.map((data, index) => {
                    if (data.name.toLowerCase() === 'tags') {
                        tagIndexPosition = index;
                    }
                    return data;
                });

                tableData.dataRows.map((data, index) => {
                    if (data.id === docid) {
                        tableData.dataRows[index].values[tagIndexPosition] =
                            fieldData.value;

                        if (props.page == 'inbox') {
                            redux_inboxStore.stateData.rowData[index].tags =
                                tagElement;
                            redux_inboxStore.stateData.rowData[
                                index
                            ].tags_data = fieldData.value;
                        } else {
                            redux_outboxStore.stateData.rowData[index].tags =
                                tagElement;
                            redux_outboxStore.stateData.rowData[
                                index
                            ].tags_data = fieldData.value;
                        }
                    }
                    return data;
                });
            } else if (reduxStore.search.deletedPage.rowData !== undefined) {
                //need to check is this required
                reduxStore.search.deletedPage.totalColumn.map((data, index) => {
                    if (data.name.toLowerCase() === 'tags') {
                        tagIndexPosition = index;
                    }
                    return data;
                });
            }

            if (props.page == 'inbox') {
                dispatch(updateInboxState(redux_inboxStore.inbox));
            } else {
                dispatch(updateListOutbox(redux_outboxStore.outbox));
            }

            setMenuSelectedTag(finalTags);

            /*  await props.updateState({
                dataGridReferesh: false,
            });

            setTimeout(() => {
                props.updateState({
                    dataGridReferesh: true,
                });
            }, 1000); */
            /* dispatch(
                outboxPage({
                    finalTags: finalTags,
                })
            ); */
        }
        await lockDocument([docid]).then((data) => {});
    };

    /*const removeTag = async (name) => {
        settagLoader(true);

        const data = menuSelectedTagNameonly.filter(
            (val) => val.toLowerCase() !== name.toLowerCase()
        );
        setMenuSelectedTagNameonly(data);

        const finalTags = [];
        data.map((tagName) => {
            const tag = {};
            tag.name = tagName;
            finalTags.push(tag);
            return tagName;
        });
        const docid = props.docId;
        const tagData = data;
        if (typeof props.resetCount === 'function') {
            props.updateTag(tagData);
            props.resetCount(tagData.length);
        }

        const fieldDataObj = GetFieldData({ configData: config });
        const fieldData = {};
        fieldData.fieldId = fieldDataObj.tagFieldId;

        fieldData.value = tagData.join('|');
        fieldData.value += '|';
        let failedDocId = [];
        let successDocId = [];

        const params = {};
        const fields = {};
        fields.docIds = [docid];
        params.processId = reduxStore.outbox.processId;
        params.statusId = reduxStore.outbox.statusId;
        params.actionId =
            reduxStore.outbox.stateData.allActions.update_tag.actionId;
        params.fields = fields;
        fields.fields = [
            {
                fieldId: fieldDataObj.tags,
                value: fieldData.value,
            },
        ];
        await unlockDocument([docid]).then((data) => { });
        await outboxDocumentAction(params)
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                settagLoader(false);
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                }
            });
        let message;
        if (failedDocId.length !== 0) {
            message = `Doc Id  ${failedDocId[0]} is  Locked`;
            await showErrorToast(message);
        }
        if (successDocId.length !== 0) {
            let tagIndexPosition = '';
            if (
                redux_outboxStore.outbox !== undefined &&
                redux_outboxStore.outbox.dataTable !== undefined
            ) {
                redux_outboxStore.outbox.dataTable.headerColumns.map(
                    (data, index) => {
                        if (data.name.toLowerCase() === 'tags') {
                            tagIndexPosition = index;
                        }
                        return data;
                    }
                );

                const { tagElement } = createTagElement(
                    fieldData.value,
                    fieldDataObj,
                    false,
                    '',
                    ddlTag
                );
                redux_outboxStore.outbox.dataTable.dataRows.map((data, index) => {
                    if (data.id === docid) {
                        redux_outboxStore.outbox.dataTable.dataRows[index].values[
                            tagIndexPosition
                        ] = fieldData.value;

                        redux_outboxStore.stateData.rowData[index].tags = tagElement;
                        redux_outboxStore.stateData.rowData[index].tags_data = fieldData.value;
                    }
                    return data;
                });
            } else if (reduxStore.search.deletedPage.rowData !== undefined) {
                reduxStore.search.deletedPage.totalColumn.map(
                    (data, index) => {
                        if (data.name.toLowerCase() === 'tags') {
                            tagIndexPosition = index;
                        }
                        return data;
                    }
                );
            }
            dispatch(updateListOutbox(redux_outboxStore.outbox));
            setMenuSelectedTag(finalTags);
            dispatch(
                outboxPage({
                    finalTags: finalTags,
                })
            );
        }
        await lockDocument([docid]).then((data) => { });
    }; */

    if (menuSelectedTag.length > 0) {
        menuSelectedTag.map((data, index) => {
            let color;
            if (data.hasOwnProperty('name')) {
                color = data.name;
            }
            menuSelectedTagElement.push(
                <React.Fragment key={index}>
                    <div
                        style={{
                            textAlign: 'center',
                            color: 'white',
                            borderRadius: '25px',
                            display: 'inline-block',
                        }}
                    >
                        <button
                            key={Math.random() + index}
                            type='button'
                            style={{
                                backgroundColor: tagColorArray[color]
                                    ? tagColorArray[color]
                                    : '#7f96a5',
                                borderRadius: '25px',
                            }}
                            title={data.name}
                        >
                            {data.name}
                        </button>
                        <i
                            key={Math.random() + index}
                            title='Close'
                            onClick={() => {
                                addTag(data.name, 'REMOVE');
                            }}
                            className='fa-light fa-xmark'
                            style={{ cursor: 'pointer' }}
                        />
                        <br />
                    </div>
                </React.Fragment>
            );
            return data;
        });
    }

    const hanldeClick = (name) => {
        settagLoader(true);
        if (menuSelectedTagNameonly.includes(name)) {
            addTag(name, 'REMOVE');
        } else {
            addTag(name, 'ADD');
        }
    };

    if (tagList !== undefined && tagList.length > 0) {
        let tags = [];
        if (filteredTag !== '') {
            tags = filteredTag;
        } else {
            tags = tagList;
        }

        tags.map((data, index) => {
            tagElement.push(
                <React.Fragment key={index}>
                    <button
                        className={
                            menuSelectedTagNameonly.includes(data.name)
                                ? 'delete_true'
                                : ''
                        }
                        type='button'
                        key={Math.random() + index}
                        onClick={() => {
                            hanldeClick(data.name);
                        }}
                        style={{
                            borderRadius: '25px',
                            backgroundColor: data.color,
                            color: 'white',
                            display: 'inline-block',
                            cursor: 'pointer',
                        }}
                        title={data.name}
                        disabled={tagLoader}
                    >
                        {data.name}
                        {menuSelectedTagNameonly.includes(data.name) ? (
                            <i
                                title='Close'
                                className='fa-light fa-xmark'
                                onClick={() => {
                                    addTag(data.name, 'REMOVE');
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        ) : null}
                    </button>
                    <br />
                </React.Fragment>
            );
            return data;
        });
    }
    const filterTag = (event) => {
        tagElement = [];
        // setTagElement([]);
        setFilterTxt(event.target.value);

        const tempFilter = tagList.filter((tags) =>
            tags.name.toUpperCase().startsWith(event.target.value.toUpperCase())
        );
        const finalTags = [];
        tempFilter.map((data) => {
            const tag = {};
            tag.name = data;
            finalTags.push(tag);
            return data;
        });
        // setMenuSelectedTagNameonly(finalTags);
        setFilteredTag(tempFilter);
        // alltagList(tempFilter);
    };

    return (
        <>
            <div
                className='main_tag tag_options'
                id='addtag'
                style={{
                    backgroundColor: 'white',
                    display: 'block',
                    zIndex: 2,
                }}
            >
                <div className='tag_options_up'>
                    <span>
                        <input
                            type='text'
                            value={filterTxt}
                            id='addTagInput'
                            onChange={(event) => {
                                filterTag(event);
                            }}
                        />
                    </span>
                    {tagLoader ? (
                        <div
                            className='spinner-border text-primary'
                            role='status'
                        >
                            <span className='sr-only'>Loading...</span>
                        </div>
                    ) : null}
                    {menuSelectedTagElement}
                </div>
                <div
                    className='menu-tag tag_options_bottom'
                    style={{ borderTop: '1px solid black' }}
                >
                    {tagElement}
                </div>
            </div>
        </>
    );
}
