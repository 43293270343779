/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import { outboxPage } from '../../pages/_redux/actions/outboxActions';

const Recipient = (props) => {
    const [recipientName, setRecipientName] = useState(
        props.selectedPhysician.ordering_physician !== undefined &&
            props.selectedPhysician.ordering_physician !== null &&
            props.selectedPhysician.ordering_physician !== ''
            ? props.selectedPhysician.ordering_physician
            : ''
    );
    const [recipientFax, setRecipientfax] = useState(
        props.selectedPhysician.fax_number !== undefined &&
            props.selectedPhysician.fax_number !== null &&
            props.selectedPhysician.fax_number !== ''
            ? props.selectedPhysician.fax_number
            : ''
    );
    const dispatch = useDispatch();
    const redux_store = useSelector((state) => state);
    const handleInputOnChange = (state, value) => {
        props.updateState({
            recipientData: {
                ...props.recipientData,
                [state]: value,
            },
        });
        dispatch(
            outboxPage({
                recipientDataError: false,
                recipientFaxError: false,
            })
        );
        if (props.calingFrom === 'upload') {
            props.Recipient_Data({
                recipientData: {
                    ...props.recipientData,
                    [state]: value,
                },
            });
        }
    };

    return (
        <>
            <div className='row grid-row-group'>
                <div className={`transition-select col-lg-6`}>
                    <label className='pl-5'>RECIPIENT NAME</label>
                    <div className='position-relative'>
                        <input
                            type='text'
                            className={
                                redux_store.outbox.outboxpage
                                    .recipientDataError !== undefined &&
                                redux_store.outbox.outboxpage
                                    .recipientDataError === true &&
                                recipientName == ''
                                    ? 'form-control error-field'
                                    : 'form-control'
                            }
                            value={recipientName !== '' ? recipientName : ''}
                            onChange={(event) => {
                                setRecipientName(event.target.value);
                                handleInputOnChange(
                                    'ordering_physician',
                                    event.target.value
                                );
                            }}
                            maxLength='50'
                        />
                        {redux_store.outbox.outboxpage.recipientDataError !==
                            undefined &&
                        redux_store.outbox.outboxpage.recipientDataError ===
                            true &&
                        recipientName == '' ? (
                            <p className='error m-0' style={{ color: 'red' }}>
                                Recipient Name is required.
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className='row grid-row-group'>
                <div className={`transition-select col-lg-6`}>
                    <label className='pl-5'>FAX NUMBER</label>
                    <NumberFormat
                        className={
                            (redux_store.outbox.outboxpage
                                .recipientDataError !== undefined &&
                                redux_store.outbox.outboxpage
                                    .recipientDataError === true &&
                                recipientFax == '') ||
                            (redux_store.outbox.outboxpage.recipientFaxError !==
                                undefined &&
                                redux_store.outbox.outboxpage
                                    .recipientFaxError === true)
                                ? 'form-control error-field'
                                : 'form-control'
                        }
                        value={recipientFax !== '' ? recipientFax : ''}
                        format='###-###-####'
                        //maxLength='14'
                        allowEmptyFormatting
                        mask='_'
                        onChange={(event) => {
                            setRecipientfax(event.target.value);
                            handleInputOnChange(
                                'fax_number',
                                event.target.value
                            );
                        }}
                    />
                    {redux_store.outbox.outboxpage.recipientDataError !==
                        undefined &&
                    redux_store.outbox.outboxpage.recipientDataError === true &&
                    redux_store.outbox.outboxpage.recipientFaxError !==
                        undefined &&
                    redux_store.outbox.outboxpage.recipientFaxError === false &&
                    recipientFax == '' ? (
                        <p className='error m-0' style={{ color: 'red' }}>
                            Fax Number is required.
                        </p>
                    ) : null}
                    {redux_store.outbox.outboxpage.recipientFaxError !==
                        undefined &&
                    redux_store.outbox.outboxpage.recipientFaxError === true ? (
                        <p className='error m-0' style={{ color: 'red' }}>
                            Incorrect Format
                        </p>
                    ) : null}
                </div>
                {/*<div className={`transition-select col-lg-6`}>
                    <label className='pl-5'>FAX NUMBER</label>
                    <i
                        nput
                        type='number'
                        className={
                            redux_store.outbox.outboxpage.recipientDataError !==
                                undefined &&
                            redux_store.outbox.outboxpage.recipientDataError ===
                                true &&
                            recipientFax == ''
                                ? 'form-control error-field'
                                : 'form-control'
                        }
                        value={recipientFax !== '' ? recipientFax : ''}
                        onChange={(event) => {
                            if (event.target.value.length <= 10) {
                                setRecipientfax(event.target.value);
                                handleInputOnChange(
                                    'fax_number',
                                    event.target.value
                                );
                            }
                        }}
                        maxLength='10'
                    />
                    {redux_store.outbox.outboxpage.recipientDataError !==
                        undefined &&
                    redux_store.outbox.outboxpage.recipientDataError === true &&
                    recipientFax == '' ? (
                        <p className='error m-0' style={{ color: 'red' }}>
                            Fax Number is required.
                        </p>
                    ) : null}
                    </div>*/}
            </div>
        </>
    );
};
export default Recipient;
