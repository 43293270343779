import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export class ViewEpisode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div
                className='patient_detail edit-detail-bg'
                style={{ backgroundColor: '#F0F7FB' }}
            >
                <div style={{ display: 'flex' }} className='form-group row'>
                    <span className='col-lg-4'>
                        <span className='patient_label'>EPISODE STATUS</span>
                        <br />
                        <span className='patient_info'>
                            {this.props.selectedEpisode &&
                            this.props.selectedEpisode.hasOwnProperty(
                                'episode_status'
                            ) &&
                            this.props.selectedEpisode.episode_status !== ''
                                ? this.props.selectedEpisode.episode_status
                                : '-'}
                        </span>
                    </span>

                    <span className='col-lg-4'>
                        <span className='patient_label'>START DATE</span>
                        <br />
                        <span className='patient_info'>
                            {this.props.selectedEpisode !== '' &&
                            this.props.selectedEpisode.hasOwnProperty(
                                'episode_start'
                            )
                                ? this.props.selectedEpisode.episode_start
                                : '-'}
                        </span>
                    </span>

                    <span className='col-lg-4'>
                        <span className='patient_label'>END DATE</span>
                        <br />
                        <span className='patient_info'>
                            {this.props.selectedEpisode &&
                            this.props.selectedEpisode.hasOwnProperty(
                                'episode_end'
                            )
                                ? this.props.selectedEpisode.episode_end
                                : '-'}
                        </span>
                    </span>
                </div>

                <div
                    style={{ display: 'flex' }}
                    className='form-group row mb-0'
                >
                    <span className='col-lg-4'>
                        <span className='patient_label'>BRANCH</span>
                        <br />
                        <span className='patient_info'>
                            {this.props.selectedEpisode &&
                            this.props.selectedEpisode.hasOwnProperty('branch')
                                ? this.props.selectedEpisode.branch
                                : '-'}
                        </span>
                    </span>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        selectedRowDetails: state.inbox.selectedRowDetails,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewEpisode));
