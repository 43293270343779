/* eslint-disable array-callback-return */
import React from 'react';
import NumberFormat from 'react-number-format';
import { Dropdown, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { outboxDocumentAction } from '../../api/api';
import {
    showSuccessToast,
    showErrorToast,
    GetFieldData,
    stringEmptyOrUndefined,
    faxCellFormatter,
    RefreshCounts,
} from '../../../components/commonfunction';
import {
    getListaction,
    updateOutboxState,
    getCountData,
    outboxPage,
    getSelectedRowDetails,
    updateList,
} from '../../_redux/actions/outboxActions';
import { deletedPage } from '../../_redux/actions/searchActions';
import { isMobile } from 'react-device-detect';

export class DeliveryDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            calingFrom: this.props.calingFrom,
            currentDelivery: 'Save',
            show_fax_number: false,
            selectRowDetails: this.props.selectRowDetails,
            faxNumber: '',
            delivery_method: '',
            disabledClass: 'disabled',
            editDetailsFlag: true,
            faxNowBtn: false,
            postLoading: `post_loading_${Math.random()}`,
            submitted: 0,
            posted: `posted_${Math.random()}`,
        };
    }

    componentDidMount() {
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });

        this.setState({ allFieldIds: fieldDataObj });
        if (
            (this.state.calingFrom === 'smallView' ||
                this.state.calingFrom === 'bigView') &&
            this.props.selectRowDetails.hasOwnProperty('fields')
        ) {
            // eslint-disable-next-line no-unused-vars
            this.props.selectRowDetails.fields.map((rowData, index) => {
                const fields = rowData.fieldId;
                // if (String(fields) === String(fieldDataObj.fax_number))
                // if (String(fields) === String(fieldDataObj.delivery_method))

                if (String(fields) === String(fieldDataObj.fax_number)) {
                    // fax number
                    const formatFax = faxCellFormatter(rowData.value);
                    this.setState({
                        faxNumber: formatFax,
                        duplicateFax: formatFax,
                    });
                }
                if (String(fields) === String(fieldDataObj.delivery_method)) {
                    // delivery method
                    this.setState({
                        delivery_method: rowData.value,
                        duplicateMethod: rowData.value,
                        disabledClass: '',
                    });

                    if (rowData.value.includes('FAX')) {
                        this.setState({ show_fax_number: true });
                        this.setState({
                            currentDelivery: 'Save and Fax Now',
                        });
                    } else {
                        this.setState({ show_fax_number: false });
                        this.setState({ currentDelivery: 'Save' });
                    }
                }
            });
        } else {
            // var docIds = [];
            let selectedIndex;
            if (
                this.props.outbox.stateData !== undefined &&
                this.props.outbox.stateData.selectedRows !== undefined
            ) {
                selectedIndex = Array.from(
                    this.props.outbox.stateData.selectedRows
                );
            }

            // var selected_rows = Array.from(this.state.selectedRows);
            let row_fax_number;
            let fax_method;
            this.props.outbox.stateData.rowData.forEach((data, index) => {
                if (selectedIndex.includes(data.id)) {
                    if (
                        data.faxnumber !== undefined &&
                        data.deliverymethod !== undefined
                    ) {
                        row_fax_number = data.faxnumber;
                        fax_method = data.deliverymethod;
                        this.setState({
                            faxNumber: row_fax_number,
                            delivery_method: fax_method,
                            disabledClass: '',
                        });
                    } else if (data.faxnumber !== undefined) {
                        row_fax_number = data.faxnumber;
                        this.setState({
                            faxNumber: row_fax_number,
                        });
                    } else if (data.deliverymethod !== undefined) {
                        fax_method = data.deliverymethod;
                        this.setState({
                            delivery_method: fax_method,
                            disabledClass: '',
                        });
                    }

                    if (data.deliverymethod.includes('FAX')) {
                        this.setState({ show_fax_number: true });
                        this.setState({ currentDelivery: 'Save and Fax Now' });
                    } else {
                        this.setState({ show_fax_number: false });
                        this.setState({ currentDelivery: 'Save' });
                    }

                    if (row_fax_number === '' && fax_method === '') {
                        this.setState({
                            disabledClass: 'disabled',
                        });
                    }
                }
            });
        }
        const pathName = window.location.pathname.split('/');
        this.setState({
            currentURL: pathName[2],
        });
    }

    closeDelivery = () => {
        this.props.updateState({
            open_delivery_detail: false,
        });
        this.setState({
            editDetailsFlag: true,
            faxNumber: this.state.duplicateFax,
            delivery_method: this.state.duplicateMethod,
            submitted: 0,
        });
        if (
            this.state.duplicateFax === undefined ||
            this.state.duplicateMethod === undefined
        ) {
            this.setState({
                disabledClass: 'disabled',
            });
        }
    };
    async selectedRowDetailUpdate(fields) {
        let deliveryMethodID;
        let faxNumberID;
        for (const property in this.state.allFieldIds) {
            if (property === 'delivery_method') {
                deliveryMethodID = this.state.allFieldIds[property];
            } else if (property === 'fax_number') {
                faxNumberID = this.state.allFieldIds[property];
            }
        }

        let r = 0;
        let deliveryMethodUpdated = 0;
        let faxNumberUpdated = 0;
        const selectRowDetailsArray = this.props.selectRowDetails.fields;
        if (
            this.props.selectRowDetails !== undefined &&
            this.props.selectRowDetails.fields !== undefined
        ) {
            const newstate = [...selectRowDetailsArray];
            selectRowDetailsArray.map((data) => {
                if (data.fieldId === deliveryMethodID) {
                    newstate[r] = {
                        ...newstate[r],
                        fieldId: deliveryMethodID,
                        value: this.state.delivery_method,
                    };
                    deliveryMethodUpdated = 1;
                }
                if (data.fieldId === faxNumberID) {
                    newstate[r] = {
                        ...newstate[r],
                        fieldId: faxNumberID,
                        value: faxCellFormatter(this.state.faxNumber),
                    };
                    faxNumberUpdated = 1;
                }

                r++;
            });
            const len = this.props.selectRowDetails.fields.length;
            let j = len;
            if (deliveryMethodUpdated === 0) {
                newstate[j + 1] = {
                    ...newstate[r + len],
                    fieldId: deliveryMethodID,
                    value: this.state.delivery_method,
                };
                j += 1;
            }
            if (faxNumberUpdated === 0) {
                newstate[j + 1] = {
                    ...newstate[r + len],
                    fieldId: faxNumberID,
                    value: faxCellFormatter(this.state.faxNumber),
                };
            }

            this.props.selectRowDetails.fields = newstate;

            await this.props.getSelectedRowDetails(this.props.selectRowDetails);

            this.props.outbox.stateData.rowData.map((item, index) => {
                if (
                    item.doc_id ===
                    this.props.selectRowDetails.miscPproperties.docId
                ) {
                    this.props.outbox.stateData.rowData[index].deliverymethod =
                        this.state.delivery_method;
                    this.props.outbox.stateData.rowData[index].faxnumber =
                        faxCellFormatter(this.state.faxNumber);
                }
            });
            this.props.updateOutboxState({
                rowData: this.props.outbox.stateData.rowData,
            });
            /* this.props.updateState({
                rowData: this.props.outbox.stateData.rowData,
                dataGridReferesh:false,
            }) 
            setTimeout(() => {
                this.props.updateState({
                    
                    dataGridReferesh:true,
                }) 
            }, 500); */

            let deliveryIndexPosition;
            let faxIndexPosition;
            if (
                this.props.outbox.outbox !== undefined &&
                this.props.outbox.outbox.dataTable !== undefined
            ) {
                this.props.outbox.outbox.dataTable.headerColumns.map(
                    (data, index) => {
                        if (data.name.toLowerCase() === 'delivery method') {
                            deliveryIndexPosition = index;
                        }
                        if (data.name.toLowerCase() === 'fax number') {
                            faxIndexPosition = index;
                        }
                        return data;
                    }
                );
                this.props.outbox.outbox.dataTable.dataRows.map(
                    (data, index) => {
                        if (
                            data.id ===
                            this.props.outbox.selectedRowDetails.miscPproperties
                                .docId
                        ) {
                            this.props.outbox.outbox.dataTable.dataRows[
                                index
                            ].values[deliveryIndexPosition] =
                                this.state.delivery_method;
                            this.props.outbox.outbox.dataTable.dataRows[
                                index
                            ].values[faxIndexPosition] = faxCellFormatter(
                                this.state.faxNumber
                            );
                        }
                        return data;
                    }
                );
                this.props.updateList(this.props.outbox.outbox);
            }
        }
        this.props.outboxPage({
            //dataTable:  this.props.outbox.outbox.dataTable,
            deliveryUpdate: true,
        });
    }
    editDisplay = () => {
        this.setState({
            editDetailsFlag: false,
        });
    };

    updateState = (state) => {
        this.props.updateState(state);
    };

    handleFormOnChange = (state, event) => {
        this.setState({
            disabledClass: 'disabled',
            faxNowBtn: false,
        });
        const value = event.target.value;
        this.setState({ [state]: value }, () => {
            this.updateState({ [state]: value });
        });
        if (value.includes('FAX')) {
            this.setState({ show_fax_number: true });
            this.setState({ currentDelivery: 'Save and Fax Now' });
        } else {
            this.setState({ show_fax_number: false });
            this.setState({ currentDelivery: 'Save' });
        }
        if (value !== '') this.setState({ disabledClass: '' });
    };

    handleInputOnChange = (event) => {
        const fax = event.target.value.replace(/-/g, '');
        //let regExp = /^\d{10}$/;
        //const format = event.target.value.match(regExp);
        //if (format !== null || event.target.value === '') {
        this.setState({ faxNumber: fax }, () => {
            this.setState({
                validateCreate: false,
                faxNowBtn: false,
            });
        });
        // }
    };

    submitDelivery = (action_name) => {
        this.setState({
            //faxNowBtn: true,
            submitted: 1,
        });
        let faxNumber = this.state.faxNumber;
        if (faxNumber === '') {
            this.setState({
                validateCreate: true,
                faxNowBtn: false,
                submitted: 0,
            });
            return false;
        } else {
            //const pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
            if (String(faxNumber).includes('-')) {
                faxNumber = faxNumber.replace(/-/g, '');
            }
            const pattern = /^\d{10}$/;
            const format = faxNumber.match(pattern);
            this.setState({
                faxFormat: format,
            });

            if (format === null) {
                this.setState({
                    faxNowBtn: false,
                    submitted: 0,
                });
                return false;
            }
        }

        const delivery_method = this.state.delivery_method;
        if (delivery_method === '') {
            this.setState({
                validateCreateDelivery: true,
                submitted: 0,
            });
            return false;
        }
        let actionId = '';

        if (
            this.props.deleteDocument !== 'deleted_document' &&
            this.props.outbox.stateData.allActions[action_name] !== undefined
        ) {
            actionId =
                this.props.outbox.stateData.allActions[action_name].actionId;
        }
        if (
            this.props.deleteDocument === 'deleted_document' &&
            Object.keys(this.props.allActions).length !== 0 &&
            this.props.allActions[action_name] !== undefined
        ) {
            actionId = this.props.allActions[action_name].actionId;
        }
        // } else {
        //     actionId =
        //         this.props.outbox.stateData.allActions.update_fax_number
        //             .actionId;
        // }

        const docIds = [];
        if (this.props.deleteDocument !== 'deleted_document') {
            if (
                this.state.calingFrom === 'smallView' ||
                this.state.calingFrom === 'bigView'
            ) {
                docIds.push(this.props.outbox.stateData.selectedDocId);
            } else {
                const selectedIndex = Array.from(
                    this.props.outbox.stateData.selectedRows
                );

                this.props.outbox.stateData.rowData.map((data, index) => {
                    if (selectedIndex.includes(data.id)) {
                        docIds.push(data.doc_id);
                    }
                });
            }
        }
        let processId = 1;
        let statusId = 1;
        if (String(this.props.processId) !== '') {
            processId = this.props.processId;
        }
        if (String(this.props.statusId) !== '') {
            statusId = this.props.statusId;
        }
        if (this.props.deleteDocument === 'deleted_document') {
            statusId = this.props.delstatusId;
            processId = this.props.delprocessId;
            docIds.push(this.props.selectedDocId);
        }
        const params = {};
        const fields = {};
        fields.docIds = docIds;
        fields.fields = [
            {
                fieldId: this.state.allFieldIds.delivery_method,
                value: this.state.delivery_method,
            },
        ];
        params.processId = processId;
        params.statusId = statusId;
        params.actionId = actionId;
        params.fields = fields;
        outboxDocumentAction(params)
            .then((response) => {
                if (response.status === 400) {
                    if (response.message !== undefined) {
                        const { message } = response;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');

                        showErrorToast(error);
                    } else {
                        showErrorToast('Error in Processing.');
                        this.setState({
                            submitted: 3,
                        });
                        setTimeout(() => {
                            this.updateState({ open_delivery_detail: false });
                        }, 3.0 * 1000);
                    }
                } else {
                    if (response.json) return response.json();
                }
            })
            .then((data) => {
                let failedDocId = [];
                let failedMessage = [];
                let message = '';
                if (data.hadFailures === true) {
                    data.results.map((item, idx) => {
                        if (item.actionSucceeded === false) {
                            failedDocId.push(item.docId);
                            failedMessage.push(item.message);
                        }
                    });
                    failedDocId.map((property, index) => {
                        if (index === 0) {
                            message += '[';
                        }

                        message += property + ' ';
                        if (failedDocId.length - 1 === index) {
                            message += ']';
                        }
                    });
                    let errorMsg = failedMessage[0].includes('lock')
                        ? 'Unable to obtain document lock'
                        : failedMessage[0];

                    showErrorToast(errorMsg + ' ' + message);
                    this.setState({
                        submitted: 3,
                    });
                } else {
                    if (
                        String(this.props.selectedFrom) ===
                        String('row-selection')
                    ) {
                        let selectedRowIndex = [];
                        let selectedDocIds = [];
                        let selectedRows = Array.from(
                            this.props.outbox.stateData.selectedRows
                        );

                        this.props.outbox.stateData.rowData.map(
                            (data, index) => {
                                if (selectedRows.includes(data.id)) {
                                    selectedRowIndex.push(index);
                                    selectedDocIds.push(data.doc_id);
                                }
                            }
                        );

                        selectedRowIndex.map((data) => {
                            let id =
                                this.props.outbox.stateData.rowData[data]
                                    .doc_id;
                            this.props.outbox.stateData.rowData[
                                data
                            ].deliverymethod = this.state.delivery_method;
                        });
                        this.props.updateOutboxState({
                            rowData: this.props.outbox.stateData.rowData,
                        });

                        let deliveryMethodPosition = '';
                        this.props.outbox.outbox.dataTable.headerColumns.map(
                            (data, index) => {
                                if (
                                    data.name.toLowerCase() ===
                                    'delivery method'
                                ) {
                                    deliveryMethodPosition = index; //5
                                }
                                return data;
                            }
                        );
                        selectedDocIds.map((docId) => {
                            this.props.outbox.outbox.dataTable.dataRows.map(
                                (data, index) => {
                                    if (String(data.id) === String(docId)) {
                                        this.props.outbox.outbox.dataTable.dataRows[
                                            index
                                        ].values[deliveryMethodPosition] =
                                            this.state.delivery_method;
                                    }
                                    //return data;
                                }
                            );
                        });

                        this.props.outboxPage({
                            dataTable: this.props.outbox.outbox.dataTable,
                            deliveryUpdate: true,
                        });
                    }

                    if (faxNumber !== 'aaa') {
                        this.updateFaxNumberFunction(params, fields);
                    } else {
                        showSuccessToast('Operation done successfully.');
                        this.setState({
                            submitted: 2,
                        });
                        if (this.state.currentDelivery === 'Save') {
                            this.selectedRowDetailUpdate(fields);
                        }

                        setTimeout(() => {
                            this.updateState({
                                open_delivery_detail: false,
                            });
                            if (
                                this.state.calingFrom === 'smallView' ||
                                this.state.calingFrom === 'bigView'
                            ) {
                                this.setState({
                                    editDetailsFlag: true,
                                    submitted: 0,
                                });
                                if (
                                    this.props.deleteDocument ===
                                    'deleted_document'
                                ) {
                                    // this.props.refreshForNextDocument();
                                    this.props.updateState({
                                        editDetailsOutbox: false,
                                    });
                                    this.props.deletedPage({
                                        row_transition: false,
                                        deleteBigView: false,
                                        listingView: true,
                                    });
                                    document.body.classList.remove(
                                        'doc-small-view'
                                    );
                                    document.body.classList.remove(
                                        'aside-minimize'
                                    );
                                    document.body.classList.remove(
                                        'doc-detail-view'
                                    );
                                } else {
                                    if (
                                        this.state.currentDelivery ===
                                        'Save and Fax Now'
                                    ) {
                                        //this.props.outboxrefreshTable();
                                        // this.props.rowTransition();
                                        document.body.classList.remove(
                                            'doc-small-view'
                                        );
                                        document.body.classList.remove(
                                            'doc-detail-view'
                                        );
                                        document.body.classList.remove(
                                            'aside-minimize'
                                        );
                                    }
                                }
                            } else {
                                if (
                                    this.state.currentDelivery ===
                                    'Save and Fax Now'
                                ) {
                                    //this.props.refresh_whole();
                                    //this.props.refreshTable();
                                    /* this.props.outboxPage({
                                        rowID: '',
                                    }); */
                                }
                            }
                        }, 3.0 * 1000);
                        if (isMobile) {
                            if (
                                this.state.currentDelivery ===
                                'Save and Fax Now'
                            ) {
                                /*  document.body.classList.remove(
                                    'doc-small-view'
                                );
                                document.body.classList.remove(
                                    'doc-detail-view'
                                ); */
                            }
                        }
                        // this.setState({ disabledClass: '' })
                    }
                }
            });
    };
    deleteRowManually = async () => {
        let processId = 1;
        let statusId = 1;
        if (String(this.props.processId) !== '') {
            processId = this.props.processId;
        }
        if (String(this.props.statusId) !== '') {
            statusId = this.props.statusId;
        }
        if (this.props.deleteDocument === 'deleted_document') {
            statusId = this.props.delstatusId;
            processId = this.props.delprocessId;
        }
        await RefreshCounts(
            processId,
            statusId,
            this.props //Here count from parent and getCountData is passing
        );
        const selectedRowDetailsID =
            this.props.selectRowDetails.miscPproperties.docId;

        let filteredData =
            this.props.outbox.stateData.filterTxt !== ''
                ? this.props.outbox.outboxpage.allFilteredRowData
                : this.props.outbox.stateData.rowData;

        let status = '';
        const rowsToDelete = [];
        let nextIndex = '';
        let currentIndex;
        let nextDocId = '';
        if (this.props.calingFrom !== 'deleted_document') {
            const rowLen = filteredData.length;

            filteredData.map((tableData, i) => {
                if (tableData.doc_id === selectedRowDetailsID) {
                    if (rowLen === i + 1) {
                        nextIndex = '';
                    } else {
                        nextIndex = i + 1;
                        currentIndex = tableData.id;
                    }
                }
            });

            if (nextIndex !== '') {
                rowsToDelete.push(filteredData[nextIndex]);
                nextDocId = filteredData[nextIndex].doc_id;
            }
        }
        var rowStateData =
            this.props.outbox.stateData.filterTxt !== ''
                ? this.props.outbox.outboxpage.allFilteredRowData.slice()
                : this.props.outbox.stateData.rowData.slice();

        rowStateData = rowStateData.filter((obj) => obj.id !== currentIndex);

        let dataRows = this.props.outbox.outbox.dataTable.dataRows.filter(
            (obj) => obj.id !== selectedRowDetailsID
        );
        this.props.outbox.outbox.dataTable.dataRows = dataRows;

        if (this.props.outbox.stateData.filterTxt !== '') {
            this.props.updateList(this.props.outbox.outbox);
            this.props.outboxPage({
                deleteRow: true,
                allFilteredRowData: rowStateData,
            });
        } else {
            this.props.updateList(this.props.outbox.outbox);
            this.props.outboxPage({
                //allRowData: rowStateData,
                deleteRow: true,
                stateData: {
                    ...this.props.stateData,
                    rowData: rowStateData,
                },
                //stat
            });
        }
    };
    updateFaxNumberFunction = (params, fields) => {
        // if (
        //     this.props.outbox.stateData.allActions.update_fax_number !==
        //         undefined ||
        //         this.props.allActions.update_fax_number !== undefined
        // ) {

        let actionIDTemp;
        if (this.props.deleteDocument === 'deleted_document') {
            actionIDTemp = this.props.allActions.update_fax_number.actionId;
        } else {
            actionIDTemp =
                this.props.outbox.stateData.allActions.update_fax_number
                    .actionId;
        }

        params.actionId = actionIDTemp;

        fields.fields = [];
        let faxNumber = this.state.faxNumber.replace(/-/g, '');
        fields.fields = [
            {
                fieldId: this.state.allFieldIds.fax_number,
                value: faxNumber,
            },
        ];
        params.fields = fields;

        outboxDocumentAction(params)
            .then((response) => {
                if (response.status === 400) {
                    if (response.message !== undefined) {
                        const { message } = response;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');

                        showErrorToast(error);
                    } else {
                        showErrorToast('Error in Processing.');
                        this.setState({
                            submitted: 3,
                        });
                        setTimeout(() => {
                            this.updateState({
                                open_delivery_detail: false,
                            });
                        }, 3.0 * 1000);
                    }
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                let failedDocId = [];
                let failedMessage = [];
                let message = '';
                if (data.hadFailures === true) {
                    data.results.map((item, idx) => {
                        if (item.actionSucceeded === false) {
                            failedDocId.push(item.docId);
                            failedMessage.push(item.message);
                        }
                    });
                    failedDocId.map((property, index) => {
                        if (index === 0) {
                            message += '[';
                        }

                        message += property + ' ';
                        if (failedDocId.length - 1 === index) {
                            message += ']';
                        }
                    });
                    let errorMsg = failedMessage[0].includes('lock')
                        ? 'Unable to obtain document lock'
                        : failedMessage[0];

                    showErrorToast(errorMsg + ' ' + message);
                    this.setState({
                        submitted: 3,
                    });
                } else {
                    if (
                        String(this.props.selectedFrom) ===
                        String('row-selection')
                    ) {
                        let selectedRowIndex = [];
                        let selectedDocIds = [];
                        let selectedRows = Array.from(
                            this.props.outbox.stateData.selectedRows
                        );
                        this.props.outbox.stateData.rowData.map(
                            (data, index) => {
                                if (selectedRows.includes(data.id)) {
                                    selectedRowIndex.push(index);
                                    selectedDocIds.push(data.doc_id);
                                }
                            }
                        );
                        selectedRowIndex.map((data) => {
                            let id =
                                this.props.outbox.stateData.rowData[data]
                                    .doc_id;
                            this.props.outbox.stateData.rowData[
                                data
                            ].faxnumber = this.state.faxNumber;
                        });
                        this.props.updateOutboxState({
                            rowData: this.props.outbox.stateData.rowData,
                        });

                        // above code will show the change, but after collapse code will not retain
                        // for that purpose we updating data table

                        let faxNumberPosition = '';
                        this.props.outbox.outbox.dataTable.headerColumns.map(
                            (data, index) => {
                                if (data.name.toLowerCase() === 'fax number') {
                                    faxNumberPosition = index; //5
                                }
                                return data;
                            }
                        );

                        selectedDocIds.map((doc_id) => {
                            this.props.outbox.outbox.dataTable.dataRows.map(
                                (data, index) => {
                                    if (String(data.id) === String(doc_id)) {
                                        this.props.outbox.outbox.dataTable.dataRows[
                                            index
                                        ].values[faxNumberPosition] =
                                            this.state.faxNumber;
                                    }
                                    //return data;
                                }
                            );
                        });

                        this.props.outboxPage({
                            dataTable: this.props.outbox.outbox.dataTable,
                            deliveryUpdate: true,
                        });
                    }
                    if (this.state.currentDelivery === 'Save and Fax Now') {
                        this.deleteRowManually();
                        this.callFaxNow(params, fields);
                    } else {
                        showSuccessToast('Operation done successfully.');

                        this.setState({
                            submitted: 2,
                        });
                        if (this.state.currentDelivery === 'Save') {
                            this.selectedRowDetailUpdate(fields);
                        }
                        setTimeout(() => {
                            this.updateState({
                                open_delivery_detail: false,
                            });

                            if (
                                this.state.calingFrom === 'smallView' ||
                                this.state.calingFrom === 'bigView'
                            ) {
                                this.setState({
                                    editDetailsFlag: true,
                                    submitted: 0,
                                });
                                if (
                                    this.props.deleteDocument ===
                                    'deleted_document'
                                ) {
                                    // this.props.refreshForNextDocument();
                                    this.props.updateState({
                                        editDetailsOutbox: false,
                                    });
                                    this.props.deletedPage({
                                        row_transition: false,
                                        deleteBigView: false,
                                        listingView: true,
                                    });
                                    document.body.classList.remove(
                                        'doc-small-view'
                                    );
                                    document.body.classList.remove(
                                        'aside-minimize'
                                    );
                                    document.body.classList.remove(
                                        'doc-detail-view'
                                    );
                                } else {
                                    if (
                                        this.state.currentDelivery ===
                                        'Save and Fax Now'
                                    ) {
                                        //this.props.outboxrefreshTable();
                                        this.props.rowTransition();
                                        document.body.classList.remove(
                                            'doc-small-view'
                                        );
                                        document.body.classList.remove(
                                            'aside-minimize'
                                        );
                                        document.body.classList.remove(
                                            'doc-detail-view'
                                        );
                                    }
                                }
                            } else {
                                if (
                                    this.state.currentDelivery ===
                                    'Save and Fax Now'
                                ) {
                                    /* this.props.outboxPage({
                                        rowID: '',
                                    });
                                    this.props.refresh_whole();
                                    this.props.refreshTable(); */
                                }
                            }
                        }, 3.0 * 1000);
                        if (isMobile) {
                            if (
                                this.state.currentDelivery ===
                                'Save and Fax Now'
                            ) {
                                /*   document.body.classList.remove(
                                    'doc-small-view'
                                );
                                document.body.classList.remove(
                                    'doc-detail-view'
                                ); */
                            }
                        }
                        // this.setState({ disabledClass: '' })
                    }
                }
            });
        //.catch((err) => {});
        // }
    };

    callFaxNow = async (params, fields) => {
        // if (
        //     this.props.outbox.stateData.allActions.fax_now !== undefined ||
        //     this.props.allActions.update_fax_number !== undefined
        // ) {

        let actionIDTemp;
        if (this.props.deleteDocument === 'deleted_document') {
            actionIDTemp = this.props.allActions.fax_now.actionId;
        } else {
            actionIDTemp =
                this.props.outbox.stateData.allActions.fax_now.actionId;
        }
        params.actionId = actionIDTemp;
        fields.fields = [];
        params.fields = fields;
        outboxDocumentAction(params)
            .then((response) => {
                if (response.status === 400) {
                    if (response.message !== undefined) {
                        const { message } = response;
                        let error = message.replace(/[0-9]/g, '');
                        error = error.replace(/:/g, '');

                        showErrorToast(error);
                    } else {
                        showErrorToast('Error in Processing.');
                        this.setState({
                            submitted: 3,
                        });
                        setTimeout(() => {
                            this.updateState({
                                open_delivery_detail: false,
                            });
                        }, 3.0 * 1000);
                    }
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                let failedDocId = [];
                let failedMessage = [];
                let message = '';
                if (data.hadFailures === true) {
                    data.results.map((item, idx) => {
                        if (item.actionSucceeded === false) {
                            failedDocId.push(item.docId);
                            failedMessage.push(item.message);
                        }
                    });
                    failedDocId.map((property, index) => {
                        if (index === 0) {
                            message += '[';
                        }

                        message += property + ' ';
                        if (failedDocId.length - 1 === index) {
                            message += ']';
                        }
                    });
                    let errorMsg = failedMessage[0].includes('lock')
                        ? 'Unable to obtain document lock'
                        : failedMessage[0];

                    showErrorToast(errorMsg + ' ' + message);
                    this.setState({
                        submitted: 3,
                    });
                } else {
                    showSuccessToast('Operation done successfully.');
                    this.setState({
                        submitted: 2,
                    });
                    if (this.state.currentDelivery === 'Save') {
                        this.selectedRowDetailUpdate(fields);
                    }
                    setTimeout(() => {
                        this.updateState({ open_delivery_detail: false });
                        if (
                            this.state.calingFrom === 'smallView' ||
                            this.state.calingFrom === 'bigView'
                        ) {
                            this.setState({
                                editDetailsFlag: true,
                                submitted: 0,
                            });
                            if (
                                this.props.deleteDocument === 'deleted_document'
                            ) {
                                // this.props.refreshForNextDocument();
                                /*  this.props.updateState({
                                    editDetailsOutbox: false,
                                });
                                this.props.deletedPage({
                                    row_transition: false,
                                    deleteBigView: false,
                                    listingView: true,
                                }); */
                                if (
                                    typeof this.props.closeBigView ===
                                    'function'
                                ) {
                                    this.props.closeBigView();
                                }
                                document.body.classList.remove(
                                    'doc-small-view'
                                );
                                document.body.classList.remove(
                                    'doc-detail-view'
                                );
                                document.body.classList.remove(
                                    'aside-minimize'
                                );
                            } else {
                                if (
                                    this.state.currentDelivery ===
                                    'Save and Fax Now'
                                ) {
                                    /* this.props.outboxrefreshTable();
                                    this.props.outboxPage({
                                        rowID: '',
                                    }); */
                                    const selectedRowDetailsID =
                                        this.props.selectRowDetails
                                            .miscPproperties.docId;

                                    let filteredData =
                                        this.props.outbox.stateData
                                            .filterTxt !== ''
                                            ? this.props.outbox.outboxpage
                                                  .allFilteredRowData
                                            : this.props.outbox.stateData
                                                  .rowData;

                                    let status = '';
                                    const rowsToDelete = [];
                                    let nextIndex = '';
                                    let currentIndex;
                                    let nextDocId = '';
                                    if (
                                        this.props.calingFrom !==
                                        'deleted_document'
                                    ) {
                                        const rowLen = filteredData.length;

                                        filteredData.map((tableData, i) => {
                                            if (
                                                tableData.doc_id ===
                                                selectedRowDetailsID
                                            ) {
                                                if (rowLen === i + 1) {
                                                    nextIndex = '';
                                                } else {
                                                    nextIndex = i + 1;
                                                    currentIndex = tableData.id;
                                                }
                                            }
                                        });

                                        if (nextIndex !== '') {
                                            rowsToDelete.push(
                                                filteredData[nextIndex]
                                            );
                                            nextDocId =
                                                filteredData[nextIndex].doc_id;
                                        }
                                    }
                                    var rowStateData =
                                        this.props.outbox.stateData
                                            .filterTxt !== ''
                                            ? this.props.outbox.outboxpage.allFilteredRowData.slice()
                                            : this.props.outbox.stateData.rowData.slice();

                                    rowStateData = rowStateData.filter(
                                        (obj) => obj.id !== currentIndex
                                    );

                                    let dataRows =
                                        this.props.outbox.outbox.dataTable.dataRows.filter(
                                            (obj) =>
                                                obj.id !== selectedRowDetailsID
                                        );

                                    this.props.outbox.outbox.dataTable.dataRows =
                                        dataRows;

                                    this.props.updateList(
                                        this.props.outbox.outbox
                                    );
                                    if (
                                        this.props.outbox.stateData
                                            .filterTxt !== ''
                                    ) {
                                        this.props.outboxPage({
                                            deleteRow: true,
                                            allFilteredRowData: rowStateData,
                                        });
                                    } else {
                                        this.props.outboxPage({
                                            //allRowData: rowStateData,
                                            deleteRow: true,
                                            stateData: {
                                                ...this.props.stateData,
                                                rowData: rowStateData,
                                            },
                                            //stat
                                        });
                                    }
                                    RefreshCounts(
                                        this.props.outbox.processId,
                                        this.props.outbox.statusId,
                                        this.props //Here count from parent and getCountData is passing
                                    );

                                    this.props.rowTransition();
                                    document.body.classList.remove(
                                        'doc-small-view'
                                    );
                                    document.body.classList.remove(
                                        'doc-detail-view'
                                    );
                                    document.body.classList.remove(
                                        'aside-minimize'
                                    );
                                }
                            }
                        } else if (this.state.calingFrom === 'actionBar') {
                            //showSuccessToast('Operation done successfully-----------------.');

                            if (
                                this.state.currentDelivery ===
                                'Save and Fax Now'
                            ) {
                                /* document.body.classList.remove(
                                    'aside-minimize'
                                );
                                document.body.classList.remove(
                                    'doc-small-view'
                                ); */
                                if (this.props.fromSearch !== 'search') {
                                    /* this.props.getListaction(
                                        this.props.outbox.outbox.processId,
                                        this.props.outbox.outbox.statusId
                                    ); */
                                    //this.props.refresh_whole();
                                }
                                /*  this.props.updateOutboxState({
                                    selectedRows: new Set(),
                                    row_transition: false,
                                });
                                this.props.refreshTable(); */
                            }
                        }
                    }, 3.0 * 1000);
                    if (isMobile) {
                        if (this.state.currentDelivery === 'Save and Fax Now') {
                            /* document.body.classList.remove('doc-small-view');
                            document.body.classList.remove('doc-detail-view'); */
                        }
                    }
                }
            });

        //}
    };

    render() {
        let allActions = this.props.allActions;
        return (
            <>
                {this.state.calingFrom === 'smallView' ||
                this.state.calingFrom === 'bigView' ||
                this.props.deleteDocument === 'deleted_document' ? (
                    <div>
                        {this.state.editDetailsFlag === true ? (
                            <div className='outbx_detail wrap_details p-4'>
                                <div className='details_part'>
                                    <div className='details_fun detail_title_mobile'>
                                        <span>
                                            <i
                                                title='Delivery_Details'
                                                className='icon-fill'
                                            >
                                                <svg
                                                    className='icon-fill'
                                                    width='30'
                                                    viewBox='0 0 39 34'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                </svg>
                                            </i>
                                            Delivery Details
                                        </span>
                                    </div>
                                </div>
                                <div className='d-flex mb-5 step2 details_part align-items-stretch justify-content-between'>
                                    <div className='details_fun'>
                                        <span>
                                            <i
                                                title='Delivery_Details'
                                                className='icon-fill'
                                            >
                                                <svg
                                                    className='icon-fill'
                                                    width='30'
                                                    viewBox='0 0 39 34'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                </svg>
                                            </i>
                                            Delivery Details
                                        </span>
                                    </div>
                                    <div className='edit_details'>
                                        {(this.props.reportView !== 'report' &&
                                            this.props.deleteDocument !==
                                                'deleted_document' &&
                                            (this.state.calingFrom ===
                                                'smallView' ||
                                                this.state.calingFrom ===
                                                    'bigView') &&
                                            this.props.stateData.hasOwnProperty(
                                                'allActions'
                                            ) &&
                                            this.props.stateData.allActions.hasOwnProperty(
                                                'update_fax_number'
                                            )) ||
                                        (this.props.deleteDocument ===
                                            'deleted_document' &&
                                            this.props.reportView !==
                                                'report' &&
                                            this.props.allActions
                                                .update_fax_number !==
                                                undefined) ? (
                                            <div className='edit_details d-flex'>
                                                <Button
                                                    disabled={
                                                        !!(
                                                            this.props
                                                                .isLocked ===
                                                                true ||
                                                            this.state
                                                                .currentURL ===
                                                                'detail'
                                                        )
                                                    }
                                                    style={
                                                        this.state
                                                            .currentURL ===
                                                        'detail'
                                                            ? {
                                                                  cursor: 'not-allowed',
                                                              }
                                                            : {}
                                                    }
                                                    title={
                                                        this.state
                                                            .calingFrom ===
                                                        'smallView'
                                                            ? 'Edit'
                                                            : 'Edit Delivery Details'
                                                    }
                                                    variant='primary'
                                                    onClick={() => {
                                                        this.editDisplay();
                                                    }}
                                                >
                                                    {this.state.calingFrom ===
                                                    'smallView'
                                                        ? 'Edit'
                                                        : 'Edit Delivery Details'}
                                                </Button>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='form-group row '>
                                    <div className='col-lg-4 mt-5 transition-select'>
                                        <label className='pl-5'>METHOD</label>
                                        <div className='method-value'>
                                            <span className='span-output'>
                                                {this.state.delivery_method
                                                    ? this.state.delivery_method
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-5 transition-select'>
                                        <label className='pl-5'>
                                            FAX NUMBER
                                        </label>
                                        <div className='fax-number'>
                                            <span className='span-output'>
                                                {this.state.faxNumber
                                                    ? faxCellFormatter(
                                                          this.state.faxNumber
                                                      )
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='outbx_detail wrap_details wrap_note p-4'>
                                <div className='details_part'>
                                    <div className='details_fun detail_title_mobile'>
                                        <span>
                                            <i
                                                title='Delivery_Details'
                                                className='icon-fill'
                                            >
                                                <svg
                                                    className='icon-fill'
                                                    width='30'
                                                    viewBox='0 0 39 34'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                </svg>
                                            </i>
                                            Delivery Details
                                        </span>
                                    </div>
                                </div>
                                <div className='d-flex mb-5 step2 details_part align-items-stretch justify-content-between'>
                                    <div className='details_fun'>
                                        <span>
                                            <i
                                                title='Delivery_Details'
                                                className='icon-fill'
                                            >
                                                <svg
                                                    className='icon-fill'
                                                    width='30'
                                                    viewBox='0 0 39 34'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                </svg>
                                            </i>
                                            Delivery Details
                                        </span>
                                    </div>
                                    {/* cancel button and save start here */}
                                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                                        {/* <div className='edit_details notes d-flex'> */}
                                        <Button
                                            className='cancel_btn'
                                            title='Cancel'
                                            variant='secondary'
                                            onClick={() => {
                                                this.closeDelivery();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        {/* </div> */}
                                        {this.state.submitted === 1 ? (
                                            <div className='notes-btn d-flex'>
                                                <div
                                                    className=' btn-group posted_btn load_img'
                                                    role='group'
                                                >
                                                    <button
                                                        className='post_loading'
                                                        id={
                                                            this.state
                                                                .postLoading
                                                        }
                                                        type='button'
                                                    >
                                                        <span
                                                            className='spinner-border spinner-border-sm'
                                                            role='status'
                                                            aria-hidden='true'
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        ) : this.state.submitted === 2 ? (
                                            <div className='notes-btn d-flex'>
                                                <div
                                                    className='btn-group posted_btn '
                                                    role='group'
                                                >
                                                    <button
                                                        className='posted d-flex'
                                                        id={this.state.posted}
                                                        type='button'
                                                    >
                                                        <i className='fa fa-check' />
                                                        Saved!
                                                    </button>
                                                </div>
                                            </div>
                                        ) : this.state.submitted === 3 ? (
                                            <div className='notes-btn d-flex'>
                                                <div
                                                    className='btn-group posted_btn '
                                                    role='group'
                                                >
                                                    <button
                                                        className='posted d-flex'
                                                        id={this.state.posted}
                                                        style={{
                                                            backgroundColor:
                                                                ' rgb(216, 76, 58)',
                                                            borderColor:
                                                                'rgb(216, 76, 58)',
                                                        }}
                                                        type='button'
                                                    >
                                                        Error!
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='d-flex'>
                                                <div
                                                    className='bulk save-popup-action'
                                                    style={{ display: 'flex' }}
                                                >
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant='primary'
                                                            title={
                                                                this.state
                                                                    .currentDelivery
                                                            }
                                                            className={`${this.state.disabledClass} success-poup`}
                                                            id='dropdown-basic'
                                                        >
                                                            {
                                                                this.state
                                                                    .currentDelivery
                                                            }{' '}
                                                            {this.state
                                                                .show_fax_number ? (
                                                                <i className='fa-light fa-angle-down' />
                                                            ) : null}
                                                        </Dropdown.Toggle>

                                                        {this.state
                                                            .disabledClass ===
                                                            '' &&
                                                        !this.state
                                                            .show_fax_number ? (
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    title='Save'
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                currentDelivery:
                                                                                    'Save',
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Save
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        ) : null}
                                                        {this.state
                                                            .disabledClass ===
                                                            '' &&
                                                        this.state
                                                            .show_fax_number ? (
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    title='Save and Fax Now'
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                currentDelivery:
                                                                                    'Save and Fax Now',
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Save and Fax
                                                                    Now
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    title='Save'
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                currentDelivery:
                                                                                    'Save',
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Save
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        ) : null}
                                                    </Dropdown>
                                                    <div
                                                        className={
                                                            this.state
                                                                .disabledClass ===
                                                            ''
                                                                ? 'checkbox-note btn btn-success'
                                                                : 'checkbox-note btn btn-success disabled'
                                                        }
                                                        title={
                                                            this.state
                                                                .currentDelivery
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                this.state
                                                                    .disabledClass ===
                                                                    '' &&
                                                                this.state
                                                                    .faxNowBtn ===
                                                                    false
                                                            ) {
                                                                this.submitDelivery(
                                                                    'update_delivery_method'
                                                                );
                                                            }
                                                        }}
                                                        disabled={
                                                            this.state.faxNowBtn
                                                        }
                                                    >
                                                        <i className='fa-light fa-check' />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* end here cancel and save btn */}
                                    </div>
                                </div>
                                <div className='form-group row '>
                                    <div className='col-lg-4 mt-5 transition-select'>
                                        <label className='pl-5'>METHOD</label>
                                        <select
                                            id='delete_selectbrand'
                                            className={
                                                this.state
                                                    .validateCreateDelivery
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={
                                                this.state.delivery_method
                                                    ? this.state.delivery_method
                                                    : ''
                                            }
                                            onChange={(event) => {
                                                this.handleFormOnChange(
                                                    'delivery_method',
                                                    event
                                                );
                                            }}
                                        >
                                            <option value=''>Select</option>
                                            {this.props.ddl &&
                                            Array.isArray(
                                                this.props.ddl.deliverymethod
                                            )
                                                ? this.props.ddl.deliverymethod.map(
                                                      (data, index) => (
                                                          <option
                                                              key={index}
                                                              value={data}
                                                          >
                                                              {data}
                                                          </option>
                                                      )
                                                  )
                                                : null}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                        {this.state.validateCreateDelivery ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Delivery method is required.
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className='col-lg-4 mt-5 transition-select'>
                                        <label className='pl-5'>
                                            FAX NUMBER
                                        </label>
                                        <NumberFormat
                                            className={
                                                this.state.validateCreate
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.faxNumber}
                                            format='###-###-####'
                                            allowEmptyFormatting
                                            mask='_'
                                            //maxLength={14}
                                            onChange={this.handleInputOnChange}
                                        />
                                        {this.state.validateCreate === true &&
                                        (this.state.faxNumber === '' ||
                                            this.state.faxNumber ===
                                                undefined) ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Fax Number is required.
                                            </p>
                                        ) : null}
                                        {this.state.faxFormat === null &&
                                        this.state.faxNumber !== '' ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Please check the Fax Number
                                                format.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <Modal
                        className='delete-popup'
                        show={this.props.open_delivery_detail}
                        onHide={this.closeDelivery.bind(this, false)}
                        animation={false}
                        size='lg'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                    >
                        <Modal.Header
                            closeButton
                            className='d-block justify-content-start'
                        >
                            <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                                <div className='delivery-details'>
                                    <svg
                                        className='icon-fill mr-3'
                                        width='30'
                                        // height=''
                                        viewBox='0 0 39 34'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                        <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                    </svg>
                                    Delivery Details
                                </div>
                                <i
                                    title='Close'
                                    className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                    aria-hidden='true'
                                    onClick={this.closeDelivery.bind(
                                        this,
                                        false
                                    )}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='deletes'>
                            <div className='form-group row '>
                                <div className='col-lg-4 mt-5 transition-select'>
                                    <label className='pl-5'>METHOD</label>
                                    <select
                                        id='delete_selectbrand'
                                        className={
                                            this.state.validateCreateDelivery
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={
                                            this.state.delivery_method
                                                ? this.state.delivery_method
                                                : ''
                                        }
                                        onChange={(event) => {
                                            this.handleFormOnChange(
                                                'delivery_method',
                                                event
                                            );
                                        }}
                                    >
                                        <option value=''>Select</option>
                                        {this.props.ddl &&
                                        Array.isArray(
                                            this.props.ddl.deliverymethod
                                        )
                                            ? this.props.ddl.deliverymethod.map(
                                                  (data, index) => (
                                                      <option
                                                          key={index}
                                                          value={data}
                                                      >
                                                          {data}
                                                      </option>
                                                  )
                                              )
                                            : null}
                                    </select>
                                    <i
                                        className='fa-light fa-chevron-down'
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    {this.state.validateCreateDelivery ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Delivery method is required.
                                        </p>
                                    ) : null}
                                </div>

                                <div className='col-lg-4 mt-5 transition-select'>
                                    <label className='pl-5'>FAX NUMBER</label>
                                    <NumberFormat
                                        className={
                                            this.state.validateCreate
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={this.state.faxNumber}
                                        format='###-###-####'
                                        allowEmptyFormatting
                                        mask='_'
                                        //maxLength={14}
                                        onChange={this.handleInputOnChange}
                                    />
                                    {this.state.validateCreate ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Fax Number is required.
                                        </p>
                                    ) : null}
                                    {this.state.faxFormat === null &&
                                    this.state.faxNumber !== '' ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Please check the Fax Number format.
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='d-block transition-footer'>
                            <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                                <div className='d-flex'>
                                    <Button
                                        className='cancel_btn'
                                        title='Cancel'
                                        variant='secondary'
                                        onClick={this.closeDelivery.bind(
                                            this,
                                            false
                                        )}
                                    >
                                        Cancel
                                    </Button>
                                </div>

                                {this.state.submitted === 1 ? (
                                    <div className='notes-btn d-flex'>
                                        <div
                                            className=' btn-group posted_btn load_img'
                                            role='group'
                                        >
                                            <button
                                                className='post_loading'
                                                id={this.state.postLoading}
                                                type='button'
                                            >
                                                <span
                                                    className='spinner-border spinner-border-sm'
                                                    role='status'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                ) : this.state.submitted === 2 ? (
                                    <div className='notes-btn d-flex'>
                                        <div
                                            className='btn-group posted_btn '
                                            role='group'
                                        >
                                            <button
                                                className='posted d-flex'
                                                id={this.state.posted}
                                                type='button'
                                            >
                                                <i className='fa fa-check' />
                                                Saved!
                                            </button>
                                        </div>
                                    </div>
                                ) : this.state.submitted === 3 ? (
                                    <div className='notes-btn d-flex'>
                                        <div
                                            className='btn-group posted_btn '
                                            role='group'
                                        >
                                            <button
                                                className='posted d-flex'
                                                id={this.state.posted}
                                                style={{
                                                    backgroundColor:
                                                        ' rgb(216, 76, 58)',
                                                    borderColor:
                                                        'rgb(216, 76, 58)',
                                                }}
                                                type='button'
                                            >
                                                Error!
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex'>
                                        <div
                                            className='bulk save-popup-action'
                                            style={{ display: 'flex' }}
                                        >
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant='primary'
                                                    title='Save'
                                                    className={`${this.state.disabledClass} success-poup`}
                                                    id='dropdown-basic'
                                                    style={{
                                                        borderTopRightRadius:
                                                            '0',
                                                        borderBottomRightRadius:
                                                            '0',
                                                    }}
                                                >
                                                    {this.state.currentDelivery}{' '}
                                                    {this.state
                                                        .show_fax_number ? (
                                                        <i className='fa-light fa-angle-down' />
                                                    ) : null}
                                                </Dropdown.Toggle>

                                                {this.state.disabledClass ===
                                                    '' &&
                                                !this.state.show_fax_number ? (
                                                    <Dropdown.Menu
                                                        style={{
                                                            backgroundColor:
                                                                'white',
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.setState({
                                                                    currentDelivery:
                                                                        'Save',
                                                                });
                                                            }}
                                                        >
                                                            Save
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                ) : null}
                                                {this.state.disabledClass ===
                                                    '' &&
                                                this.state.show_fax_number ? (
                                                    <Dropdown.Menu
                                                        style={{
                                                            backgroundColor:
                                                                'white',
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.setState({
                                                                    currentDelivery:
                                                                        'Save and Fax Now',
                                                                });
                                                            }}
                                                        >
                                                            Save and Fax Now
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.setState({
                                                                    currentDelivery:
                                                                        'Save',
                                                                });
                                                            }}
                                                        >
                                                            Save
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                ) : null}
                                            </Dropdown>

                                            <div
                                                className={
                                                    this.state.disabledClass ===
                                                    ''
                                                        ? 'checkbox-note btn btn-success'
                                                        : 'checkbox-note btn btn-success disabled'
                                                }
                                                title='Save'
                                                onClick={() => {
                                                    if (
                                                        this.state
                                                            .disabledClass ===
                                                            '' &&
                                                        this.state.faxNowBtn ===
                                                            false
                                                    ) {
                                                        this.submitDelivery(
                                                            'update_delivery_method'
                                                        );
                                                    }
                                                }}
                                                disabled={this.state.faxNowBtn}
                                            >
                                                <i className='fa-light fa-check' />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    getCountData,
    getListaction,
    updateOutboxState,
    outboxPage,
    deletedPage,
    getSelectedRowDetails: getSelectedRowDetails,
    updateList,
};
const mapStateToProps = (state) => {
    return {
        outbox: state.outbox,
        search: state.search,
        rowData: state.outbox.rowData,
        count: state.persist.count,
        ddl: state.persistDDL.DDL,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
        selectRowDetails: state.outbox.selectedRowDetails,
        stateData: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.stateData
            : {},
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryDetailModal);
