/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { isMobile } from 'react-device-detect';
// eslint-disable-next-line import/no-duplicates
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
// eslint-disable-next-line import/no-duplicates
import { Button } from 'react-bootstrap';

import {
    download,
    download_multiple,
    formatNameWithSpaceToUnderscore,
    showSuccessToast,
    getPageFromPath,
} from '../../../components/commonfunction';
//import PrintPdfDocument from '../../../components/pdfpreview/PrintPdfDocument';
import { showLoader } from '../../_redux/actions/inboxActions';
import {
    deletedPage,
    updateSearchState,
} from '../../_redux/actions/searchActions';
import { globalDocumentAction } from '../../api/api';
import UpdateFaxDetails from '../../inbox/components/UpdateFaxDetails';
import Office365AuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
import GmailAuthenticationPage from '../../../components/composeDraftMail/GmailAuthenticationPage';
import { DocumentIdToPrint } from '../../../../utils/printPdf/printPdf';

class GlobalActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            printSelectedDocId: [],
            selectedRows: this.props.selectedRows,
            rowData: this.props.allRows,
            actionName: '',
            open_transition_section: false,
            serviceType: false,
        };
    }

    componentDidMount() {
        const serviceType =
            this.props.configData?.emailIntegration?.serviceType ===
                'Office 365' ||
            this.props.configData?.emailIntegration?.serviceType === 'Google'
                ? true
                : false;
        this.setState({ serviceType: serviceType }); // if service type is "Exchange" will implement later

        const pathnames = this.props.location.pathname;
        const pathname = getPageFromPath(pathnames);

        this.props.config_data.customSearchQueries.map((data) => {
            if (String(data.searchId) === String(pathname)) {
                if (
                    data.globalActions !== undefined &&
                    data.globalActions.length > 0
                ) {
                    data.globalActions.map((actions) => {
                        if (
                            formatNameWithSpaceToUnderscore(actions.name) ===
                            'undelete'
                        ) {
                            this.setState({ actionName: 'Recover' });
                        } else if (
                            formatNameWithSpaceToUnderscore(actions.name) ===
                            'resume_tracking'
                        ) {
                            this.setState({ actionName: actions.name });
                        } else {
                            this.setState({ actionName: actions.name });
                        }
                    });
                }
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.printSelectedDocId !== this.state.printSelectedDocId) {
            DocumentIdToPrint(this.state.printSelectedDocId)
                .then(() => {
                    this.props.deletedPage({ blurSpinner: false });
                })
                .catch((err) => {
                    console.log(err);
                    this.props.deletedPage({ blurSpinner: false });
                });
        }
    }
    open_transition_section = () => {
        this.props.open_transition_section_function();
    };

    single_download = () => {
        download(this.props.deleted_page.selectedDocId);
    };

    printIt = (data) => {
        this.props.showLoader(true);
        this.setState({
            //return {
            printSelectedDocId: [data],
            //};
        });
    };

    printMultiple = async () => {
        this.props.showLoader(true);
        const stateSelectedRow = Array.from(
            this.props.deleted_page.selectedRows
        );
        const selectedDocId = [];
        if (stateSelectedRow.length > 0) {
            await this.state.rowData.forEach((data) => {
                if (stateSelectedRow.indexOf(data.id) !== -1) {
                    selectedDocId.push(data.doc_id);
                }
            });
        }
        this.setState({
            printSelectedDocId: selectedDocId,
            printLoader: true,
        });
    };

    multiple_download = async () => {
        this.setState({ blur_spinner: true }, () => {
            download_multiple(
                {
                    selectedRows: this.props.selectedRows,
                    rowData: this.props.allRows,
                },
                'fromDocid'
            );
            this.setState({ blur_spinner: false });
        });
    };

    recover_form = () => {
        return <>recover</>;
    };

    openHistory = () => {
        this.props.deletedPage({ openHistory: true });
    };

    Update_fax_details() {
        const selectedRowDetails = this.props.selectedRowDetails.fields;
        const Fields = this.props.config_data.fields;
        const allFields = [];

        Fields.map((data) => {
            allFields.push({
                fieldId: data.fieldId,
                name: formatNameWithSpaceToUnderscore(data.name),
            });
        });

        let fieldId;
        allFields.map((dataValue) => {
            if (dataValue.name === 'fax_detail') {
                fieldId = dataValue.fieldId;
            }
        });
        selectedRowDetails.map((data) => {
            if (data.fieldId === fieldId) {
                this.setState({
                    updateValue: data.value,
                });
            }
        });

        this.setState({
            openUpdateNotes: true,
            selectedDocId: this.props.selectedDocId,
        });
    }

    recoverDoc = async (type) => {
        this.props.deletedPage({ blurSpinner: true });
        let selectedDocId = [];
        let actionId = {};
        let messageText = '';
        if (type === 'single') {
            selectedDocId.push(this.props.deleted_page.selectedDocId);
        }

        if (type === 'multiple') {
            selectedDocId = Array.from(this.props.deleted_page.selectedRows);
        }
        const pathnames = this.props.location.pathname;
        const pathname = getPageFromPath(pathnames);
        this.props.config_data.customSearchQueries.map((data) => {
            if (String(data.searchId) === String(pathname)) {
                if (
                    data.globalActions !== undefined &&
                    data.globalActions.length > 0
                ) {
                    data.globalActions.map((actions) => {
                        if (
                            formatNameWithSpaceToUnderscore(actions.name) ===
                            'undelete'
                        ) {
                            actionId = actions.id;
                            messageText = 'Recovered';
                        } else if (
                            formatNameWithSpaceToUnderscore(actions.name) ===
                            'resume_tracking'
                        ) {
                            actionId = actions.id;
                            messageText = 'Resumed';
                        } else {
                            actionId = actions.id;
                            messageText = actions.name;
                        }
                    });
                }
            }
        });

        const params = {};
        params.actionId = actionId;
        params.fields = {
            docIds: selectedDocId,
            fields: [],
        };
        await globalDocumentAction(params)
            .then((response) => {
                this.props.deletedPage({
                    blurSpinner: false,
                    refreshTable: true,
                    row_transition: false,
                    deleteBigView: false,
                });
                this.props.updateSearchState({
                    deletedPage: {
                        ...this.props.deleted_page,
                        deleteBigView: false,
                        row_transition: false,
                        blurSpinner: false,
                    },
                });
                this.props.updateState({
                    deleteBigView: false,
                });
                if (response.status === 204) {
                    showSuccessToast(`${messageText} Successfully`);
                    return true;
                }
                return response.json();
            })
            .then((data) => {});
    };

    sendDraft = async () => {
        const serviceType =
            this.props.config_data?.emailIntegration?.serviceType;
        // eslint-disable-next-line eqeqeq
        if (serviceType == 'Office 365') {
            // check to authenticate draft mail
            Office365AuthenticationPage({
                selectedDocID: this.props.deleted_page.selectedDocId,
                configData: this.props.config_data,
            });
        } else {
            GmailAuthenticationPage({
                selectedDocID: this.props.deleted_page.selectedDocId,
                configData: this.props.config_data,
            });
        }
    };

    render() {
        return (
            <>
                {this.state.actionName !== '' &&
                this.props.callingFrom === 'rowSelection' ? (
                    <div className='fax-outer-div'>
                        <div className='fax-outer'>
                            <div className='dropdown'>
                                <button
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                    id='dropdown-basic'
                                    type='button'
                                    className='dropdown-toggle btn btn-primary'
                                    disabled={this.props.isLocked}
                                >
                                    {this.state.actionName}{' '}
                                </button>
                            </div>
                        </div>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                            className='icon_fun fix-now-btn checkbox-note btn-success'
                            title='Save'
                            onClick={() => {
                                this.recoverDoc('multiple');
                            }}
                        >
                            <i className='fa-light fa-check' />
                        </button>
                    </div>
                ) : this.state.actionName !== '' ? (
                    <>
                        <Button
                            title={this.state.actionName}
                            className='track_resume'
                            // style={{ borderRadius: '6px' }}
                            onClick={() => {
                                this.recoverDoc('single');
                            }}
                            disabled={this.props.isLocked}
                        >
                            {this.state.actionName}
                        </Button>
                    </>
                ) : null}

                {this.props.deleteBigView !== true &&
                this.props.calingFrom !== 'action_bar_delete' &&
                this.props.deleted_page.permission !== undefined &&
                (this.props.deleted_page.permission?.show_branch === true ||
                    this.props.deleted_page.permission?.show_group === true ||
                    this.props.deleted_page.permission?.statusArray.length >
                        0) ? (
                    <OverlayTrigger
                        placement='top'
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(0, 35, 58)',
                                    color: 'white',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                    }}
                                >
                                    Transition
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            className={
                                this.props.open_transition_section
                                    ? 'icon_fun outer-col active'
                                    : 'icon_fun outer-col'
                            }
                            style={{
                                display: 'inline-block',
                            }}
                            onClick={
                                this.props.isLocked === true
                                    ? null
                                    : this.open_transition_section
                            }
                        >
                            <span
                                className={
                                    this.props.open_transition_section
                                        ? 'active'
                                        : null
                                }
                            >
                                <i className='fa-regular fa-arrow-right-arrow-left' />
                            </span>
                        </div>
                    </OverlayTrigger>
                ) : null}
                {this.props.deleteBigView !== true &&
                this.props.calingFrom !== 'action_bar_delete' &&
                this.props.deleted_page.allActions !== undefined ? (
                    <OverlayTrigger
                        placement='top'
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(0, 35, 58)',
                                    color: 'white',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                    }}
                                >
                                    Details
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            className={
                                this.props.open_details_section
                                    ? 'icon_fun outer-col active'
                                    : 'icon_fun outer-col'
                            }
                            style={{
                                display: 'inline-block',
                            }}
                            onClick={() =>
                                this.props.isLocked === true
                                    ? null
                                    : this.props.open_details_section_function()
                            }
                        >
                            <span
                                className={
                                    this.props.open_details_section
                                        ? 'active'
                                        : null
                                }
                            >
                                {' '}
                                {/* <i className='fa-solid fa-notes-medical'></i> */}
                                <i
                                    className='fa-solid fa-notes-medical'

                                    // style={{
                                    //     color:
                                    //         this.state.allActions.hasOwnProperty(
                                    //             'details'
                                    //         ) &&
                                    //         this.state.allActions.details.color !==
                                    //             null
                                    //             ? this.state.allActions.details
                                    //                   .color
                                    //             : '',
                                    // }}
                                />
                            </span>
                        </div>
                    </OverlayTrigger>
                ) : null}
                {this.props.deleteBigView !== true && (
                    <OverlayTrigger
                        placement='top'
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(0, 35, 58)',
                                    color: 'white',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                    }}
                                >
                                    Notes
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            className={
                                this.props.open_notes_section
                                    ? 'icon_fun outer-col active'
                                    : 'icon_fun outer-col'
                            }
                            onClick={() => {
                                if (this.props.isLocked === false) {
                                    if (
                                        this.props.callingFrom ===
                                        'rowSelection'
                                    ) {
                                        this.props.openNotesModal();
                                    } else {
                                        this.props.openNotes();
                                    }
                                }
                            }}
                        >
                            <span
                                className={
                                    this.props.open_notes_section
                                        ? 'active'
                                        : null
                                }
                                style={
                                    this.props.isLocked === true
                                        ? {
                                              cursor: 'not-allowed',
                                              pointerEvents: 'none !important',
                                          }
                                        : {}
                                }
                            >
                                <i className='fa-solid fa-sticky-note' />
                            </span>
                        </div>
                    </OverlayTrigger>
                )}
                {this.props.deleteBigView !== true &&
                this.props.calingFrom !== 'action_bar_delete' &&
                this.props.deleted_page?.permission?.processName !==
                    'Export' ? (
                    <OverlayTrigger
                        placement='top'
                        onEntering={this.entering}
                        overlay={
                            <Tooltip
                                id='tooltip'
                                style={{
                                    backgroundColor: 'rgb(0, 35, 58)',
                                    color: 'white',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                    }}
                                >
                                    Edit Document
                                </div>
                            </Tooltip>
                        }
                    >
                        {this.props.deleteBigView !== true &&
                        this.props.isLocked !== true ? (
                            <a
                                onClick={() => {
                                    let str = this.props.location.pathname;
                                    const lastChar = str.at(-1);
                                    if (lastChar === '/') {
                                        str = str.substring(1, str.length - 1);
                                    }
                                    this.props.deletedPage({
                                        single_edit: true,
                                    });
                                    this.props.history.push({
                                        pathname: str + '/edit',
                                        search: `?edit=${window.btoa(
                                            String(
                                                this.props.deleted_page
                                                    .selectedDocId
                                            )
                                        )}`,
                                    });
                                }}
                            >
                                <div
                                    className='icon_fun outer-col'
                                    style={{
                                        display: 'inline-block',
                                    }}
                                >
                                    <span>
                                        <i className='fas fa-file-edit' />
                                    </span>
                                </div>
                            </a>
                        ) : (
                            <div
                                className='icon_fun outer-col'
                                style={{
                                    display: 'inline-block',
                                    cursor: 'not-allowed',
                                }}
                            >
                                <span>
                                    <i className='fas fa-file-edit' />
                                </span>
                            </div>
                        )}
                    </OverlayTrigger>
                ) : null}

                {this.props.deleteBigView !== true && (
                    <div
                        className='icon_fun'
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        <span>
                            <Dropdown
                                className='bulk-print-opt outer-col dropDown-svg'
                                style={{
                                    position: 'static',
                                }}
                            >
                                <OverlayTrigger
                                    // placement='bottom'
                                    onEntering={this.entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Settings
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Dropdown.Toggle
                                        style={
                                            this.props.isLocked === true
                                                ? {
                                                      cursor: 'not-allowed',
                                                      border: '0',
                                                      background: 'transparent',
                                                      pointerEvents:
                                                          'none !important',
                                                  }
                                                : {
                                                      border: '0',
                                                      background: 'transparent',
                                                  }
                                        }
                                        variant='success'
                                        id='dropdown-basic'
                                    >
                                        <i
                                            className='fa fa-ellipsis-v'
                                            aria-hidden='true'
                                        />
                                    </Dropdown.Toggle>
                                </OverlayTrigger>

                                {this.props.isLocked === false && (
                                    <Dropdown.Menu>
                                        {this.props.callingFrom ===
                                        'smallView' ? (
                                            <>
                                                <Dropdown.Item
                                                    title='View History'
                                                    onClick={() =>
                                                        this.openHistory()
                                                    }
                                                >
                                                    <span>
                                                        <i className='fa fa-history' />
                                                        View History
                                                    </span>
                                                </Dropdown.Item>
                                                {this.props.deleted_page
                                                    .allActions !== undefined &&
                                                this.props.deleted_page
                                                    .allActions
                                                    .update_fax_details ? (
                                                    <Dropdown.Item
                                                        title='Update Fax Details'
                                                        onClick={() =>
                                                            this.Update_fax_details()
                                                        }
                                                    >
                                                        <span className='delivery-details'>
                                                            <svg
                                                                className='icon-fill'
                                                                width='39'
                                                                height='34'
                                                                viewBox='0 0 39 34'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                style={{
                                                                    marginRight:
                                                                        '5px',
                                                                }}
                                                            >
                                                                <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                                <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                            </svg>
                                                            Update Fax Details
                                                        </span>
                                                    </Dropdown.Item>
                                                ) : null}

                                                {/* <Dropdown.Item
                                                    title='Edit Document'
                                                    //target='_blank'
                                                    //as={Link}
                                                    // to={{
                                                    //     pathname: this.state.editId,
                                                    //     selectedDocIds:
                                                    //         this.state.editId,
                                                    // }}
                                                    onClick={() => {
                                                        this.props.deletedPage({
                                                            single_edit: true,
                                                        });
                                                        this.props.history.push(
                                                            {
                                                                pathname:
                                                                    this.props
                                                                        .location
                                                                        .pathname +
                                                                    '/edit',
                                                                search:
                                                                    '?edit=' +
                                                                    window.btoa(
                                                                        String(
                                                                            this
                                                                                .props
                                                                                .deleted_page
                                                                                .selectedDocId
                                                                        )
                                                                    ),
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <span title='Edit Document'>
                                                        <i className='fas fa-file-edit rest'></i>
                                                        Edit Document
                                                    </span>
                                                </Dropdown.Item> */}
                                            </>
                                        ) : null}

                                        <Dropdown.Item
                                            className='print-dropdown'
                                            title='Print'
                                            onClick={() => {
                                                if (
                                                    this.props.callingFrom ===
                                                    'rowSelection'
                                                ) {
                                                    this.props.deletedPage({
                                                        blurSpinner: true,
                                                    });
                                                    this.printMultiple();
                                                } else {
                                                    this.props.deletedPage({
                                                        blurSpinner: true,
                                                    });
                                                    this.printIt([
                                                        this.props.deleted_page
                                                            .selectedDocId,
                                                    ]);
                                                }
                                            }}
                                        >
                                            <span>
                                                {' '}
                                                <i className='fas fa-print' />
                                                Print{' '}
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                if (
                                                    this.props.callingFrom ===
                                                    'rowSelection'
                                                ) {
                                                    this.multiple_download();
                                                } else {
                                                    this.single_download();
                                                }
                                            }}
                                            title='Download'
                                        >
                                            <span>
                                                <i className='fas fa-download' />
                                                Download
                                            </span>
                                        </Dropdown.Item>
                                        {this.props.deleted_page?.permission
                                            ?.processName !== 'Export' &&
                                        this.props.config_data?.emailIntegration
                                            ?.serviceType ? (
                                            <Dropdown.Item
                                                onClick={() => this.sendDraft()}
                                                title='send via email'
                                            >
                                                <span>
                                                    <i className='fa-solid fa-envelope' />
                                                    Send via email
                                                </span>
                                            </Dropdown.Item>
                                        ) : null}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        </span>
                    </div>
                )}

                {/* this.state.printSelectedDocId.length > 0 ? (
                    <PrintPdfDocument docId={this.state.printSelectedDocId} />
                ) : null */}
                <UpdateFaxDetails
                    openUpdateNotes={this.state.openUpdateNotes}
                    selectedDocId={[this.state.selectedDocId]}
                    updateState={(state) => {
                        this.setState({ ...state });
                    }}
                    updateValue={this.state.updateValue}
                    processId={this.props.deleted_page.processId}
                    statusId={this.props.deleted_page.statusId}
                    calingFrom='deleted_document'
                    refreshForNextDocument={() => {
                        this.props.refreshForNextDocument();
                    }}
                />
            </>
        );
    }
}

const mapDispatchToProps = {
    showLoader: (canShow) => showLoader(canShow),
    deletedPage,
    updateSearchState,
};
const mapStateToProps = (state) => {
    return {
        deleted_page: state.search.deletedPage,
        selectedRowDetails: state.search.selectedRowDetails,
        config_data: state.configReducerPersist.configData,
    };
};

export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(GlobalActions))
);
