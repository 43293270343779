// import { css } from '@linaria/core';
import React from 'react';

const headerSortCell = '';
const headerSortCellClassname = `rdg-header-sort-cell ${headerSortCell}`;
const headerSortName = '';
const headerSortNameClassname = `rdg-header-sort-name ${headerSortName}`;
// const arrow = '';
// const arrowClassname = `rdg-sort-arrow ${arrow}`;
function SortableHeaderCell({
    onSort,
    sortDirection,
    priority,
    children,
    sortColumn,
    currentHeaderName,
}) {
    return (
        <span
            className={headerSortCellClassname}
            onClick={(e) => onSort(currentHeaderName, sortDirection)}
        >
            <span className={headerSortNameClassname}>{children}</span>
            <span style={{ cursor: 'pointer' }}>
                {/* sortDirection !== undefined && (
          <svg viewBox="0 0 12 8" width="12" height="8" className={arrowClassname} aria-hidden>
            <path d={sortDirection === 'ASC' && (sortColumn !== "" && sortColumn===currentHeaderName ) ? 'M0 8 6 0 12 8' : 'M0 0 6 8 12 0'} />
          </svg>
        ) */}
                {sortDirection !== undefined &&
                sortDirection === 'ASC' &&
                sortColumn !== '' &&
                sortColumn === currentHeaderName ? (
                    <span className='MuiButton-label'>
                        <i
                            className='fa fa-chevron-up filter-position '
                            aria-hidden='true'
                        />
                    </span>
                ) : (
                    <span className='MuiButton-label'>
                        <i
                            className='fa fa-chevron-down filter-position '
                            aria-hidden='true'
                        />
                    </span>
                )}
                {priority}
            </span>
        </span>
    );
}

export default SortableHeaderCell;
