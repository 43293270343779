/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import {
    outboxDocumentAction,
    GetStore,
    createNewPatient,
    recordSetSearch,
} from '../../api/api';
import { toast } from 'react-toastify';
import {
    formatNameWithSpaceToUnderscore,
    formatHeaderNameWithOutSpace,
    stringEmptyOrUndefined,
    getDropDownDataFromApi,
    showSuccessToast,
    showErrorToast,
    GetFieldData,
    normalErrorToast,
    BranchIdSearch,
} from '../../../components/commonfunction';
import {
    inboxPage,
    updateInboxState,
    getSelectedRowDetails,
} from '../../_redux/actions/inboxActions';
import { getDDLlist, getConfigData } from '../../_redux/actions/configActions';
import { outboxPage } from '../../_redux/actions/outboxActions';
import DetailSection from '../../outbox/components/DetailSection';
import { getConfig } from '../../../../_metronic/_helpers';

toast.configure();

const config = getConfig();
const statusArray = [];
if (config.length > 0 && config[0].fields !== undefined) {
    if (config[0].menuItems.length > 0) {
        config[0].menuItems.forEach((data) => {
            if (data.menuName === 'Inbox' && data.processes.length > 0) {
                data.processes.forEach((value) => {
                    if (value.statuses.length > 0) {
                        value.statuses.forEach((val) => {
                            statusArray.push({
                                id: val.statusId,
                                name: val.name,
                            });
                        });
                    }
                });
            }
        });
    }
}

const SendToFaxUploadModal = (props) => {
    const redux_store = useSelector((state) => state);
    const branchOptionArray = redux_store.persistDDL.DDL.branch;
    const [branch, setBranch] = React.useState(
        branchOptionArray.length === 1 ? branchOptionArray[0] : ''
    );
    const [count, setCount] = React.useState(0);
    const [loader, setLoader] = React.useState(false);
    const [saveActionLoader, setSaveAction] = React.useState(false);
    const [currentDoc, setCurrentDoc] = React.useState(1);
    const [formData, setFormData] = React.useState('');
    const [, setPhysicianFilter] = React.useState({});
    const [viewEditDetails, setviewEditDetails] = React.useState(true);
    const [, setValidateDetail] = React.useState(false);
    const [selectedDocData, setSelectedDocData] = React.useState({});
    const [, setValidateForUpload] = React.useState(false);
    const [, setSubmitCreatePhysician] = React.useState(false);
    const [, setphysicianCreated] = React.useState(false);
    const [comment, setComment] = React.useState('');
    const [coverpage_type, setCoverpage_type] = React.useState('');
    const [physicianData_fax, setPysicianData_fax] = React.useState({});
    const [coverpage_validation, setCoverpage_validation] =
        React.useState(false);
    const [physician_validation, setPhysician_validation] =
        React.useState(false);
    const [view_check, setView_check] = React.useState(0);
    const [faxNumberVerify, setFaxNumberVerify] = React.useState(false);
    const [phoneNumberVerify, setPhoneNumberVerify] = React.useState(false);
    const [npiVerify, setNpiVerify] = React.useState(false);
    const [emailVerify, setEmailVerify] = React.useState(false);
    const [branchValidation, setBranchValidation] = React.useState(false);
    const [signatureRequired] = React.useState('N');
    const [currentSaveOption, setCurrentSaveOption] =
        React.useState('WEB FAX NOW');
    const [currentSaveTitle, setCurrentSaveTitle] = React.useState('Fax Now');
    const [physicianLink, setPhysicianLink] = React.useState(false);
    const [editPhysician, setEditPhysician] = React.useState(false);
    const [currentLookup_Rec, setCurrentLookup_Rec] =
        React.useState('Physician');
    const [recipientData, setRecipientData] = React.useState({});

    const configData = redux_store.configReducerPersist.configData;

    const menuCounts = redux_store.persist.count;
    var configuredRecordsets = [];
    configuredRecordsets = configData.configuredRecordsets;

    var allRecordSetID = {};

    var physicianModifiableRecordsetId;
    if (configuredRecordsets !== undefined) {
        configuredRecordsets.map((data) => {
            if (
                formatHeaderNameWithOutSpace(data.name) === 'physiciandirectory'
            ) {
                physicianModifiableRecordsetId = data.modifiableRecordsetId;
            }

            var fieldIDArray = {};
            if (data.hasOwnProperty('fields') && data.fields.length > 0) {
                data.fields.map((field, index) => {
                    fieldIDArray[formatNameWithSpaceToUnderscore(field.name)] =
                        field;
                });
                data.fields_obj = fieldIDArray;
            }
            var name = formatNameWithSpaceToUnderscore(data.name);
            allRecordSetID[name] = data;
            return data;
        });
    }

    const dispatch = useDispatch();

    const clearAll = () => {
        setPhysician_validation(false);
        setCoverpage_validation(false);
        setBranchValidation(false);
        setComment('');
        setCoverpage_type('');
        setPysicianData_fax({});
        setviewEditDetails(false);

        setTimeout(function () {
            setviewEditDetails(true);
        }, 1000);
        setBranch('');
        setFaxNumberVerify(false);
        setPhoneNumberVerify(false);
        setNpiVerify(false);
        setEmailVerify(false);
        setPhysicianLink(false);
        setCurrentLookup_Rec('Physician');
        setRecipientData({});
    };

    useEffect(() => {
        //configuration api call
        if (branchOptionArray.length === 1) {
            setBranch(branchOptionArray[0]);
        }

        dispatch(getSelectedRowDetails(redux_store.inbox.selectedRowDetails));
        getBranchDropDownData();
        let fieldDataObj = GetFieldData({
            configData: configData,
        });
        GetStore(fieldDataObj);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBranchDropDownData = () => {
        let fieldData = GetFieldData({ configData: configData });

        if (stringEmptyOrUndefined(fieldData)) {
            if (redux_store.persistDDL.DDL.hasOwnProperty('branch')) {
                getDropDownDataFromApi(fieldData.branch).then((data) => {
                    if (data.values) {
                        dispatch(
                            getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            })
                        );
                    }
                });
            }
        }
    };
    const getBranchId = (value) => {
        let branchFiledId = '';
        configData.fields.forEach((data) => {
            if (data.name === 'Branch') {
                branchFiledId = data.fieldId;
            }
        });

        const searchText = `fieldSearch=${branchFiledId}=${value}`;
        let recordsetId = '';
        configuredRecordsets.forEach((data) => {
            if (data.name === 'Branch Data') {
                recordsetId = data.recordsetId;
            }
        });

        return recordSetSearch(recordsetId, searchText)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error('Error in recordSetSearch');
            })
            .then((data) => {
                return BranchIdSearch(data);
            });
    };
    const saveAction = async () => {
        var error = false;
        let noPhysician = 0;

        if (coverpage_type === '') {
            await setCoverpage_validation(true);
            error = true;
        }
        if (branch === '') {
            await setBranchValidation(true);
            error = true;
        }

        if (
            physicianData_fax.length === 0 ||
            (Object.keys(physicianData_fax).length === 0 &&
                currentLookup_Rec === 'Physician')
        ) {
            noPhysician = 1;
            error = true;
        }
        if (editPhysician === true && currentLookup_Rec === 'Physician') {
            for (const property in physicianData_fax) {
                if (
                    physicianData_fax[property] === '' &&
                    (property === 'npi' ||
                        property === 'fax_number' ||
                        //property === 'phone_number' ||
                        //property === 'email_address' ||
                        property === 'physician_first_name' ||
                        property === 'physician_last_name')
                ) {
                    if (error === false) {
                        error = true;
                    }
                    break;
                }
            }
        }
        if (
            (view_check === 0 || noPhysician === 1) &&
            currentLookup_Rec === 'Physician'
        ) {
            normalErrorToast('Please select/create the Physician');
            setPhysicianLink(true);

            return false;
        }

        // eslint-disable-next-line no-useless-escape

        var errorValidate = false;
        /*  var regex_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (physicianData_fax.email_address) {
            if (!regex_email.test(physicianData_fax.email_address)) {
                errorValidate = true;
                setEmailVerify(true);
            }
        } */

        var faxPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        //var faxPattern = /^[0-9\b\.\,\+\-\*\s\(\)\[\]\#]+$/;
        if (physicianData_fax.fax_number && currentLookup_Rec === 'Physician') {
            if (!physicianData_fax.fax_number.match(faxPattern)) {
                errorValidate = true;
                setFaxNumberVerify(true);
            }
        }

        if (
            physicianData_fax.phone_number &&
            currentLookup_Rec === 'Physician'
        ) {
            if (
                !physicianData_fax.phone_number.match(faxPattern) &&
                physicianData_fax.phone_number !== '___-___-____' &&
                physicianData_fax.phone_number !== '__________'
            ) {
                errorValidate = true;
                setPhoneNumberVerify(true);
            }
        }
        var pattern = /^\d{10}$/;
        if (physicianData_fax.npi && currentLookup_Rec === 'Physician') {
            if (!physicianData_fax.npi.match(pattern)) {
                errorValidate = true;
                setNpiVerify(true);
            }
        }

        if (
            error === true ||
            (errorValidate === true && currentLookup_Rec === 'Physician')
        ) {
            if (physician_validation === false) {
                setPhysician_validation(true);
            }
            normalErrorToast('Please fill mandatory fields');
            return false;
        }
        var name_id = [];
        redux_store.configReducerPersist.configData.fields.map((data) => {
            name_id.push({
                name: formatNameWithSpaceToUnderscore(data.name),
                id: data.fieldId,
            });
        });

        var fieldValue = [];
        let branchID;
        await getBranchId(branch)
            .then((branch_id) => {
                branchID = branch_id;
            })
            .catch((error) => {
                console.log('Error:', error);
            });

        name_id.map((property) => {
            if (property.name === 'delivery_method') {
                fieldValue.push({
                    fieldId: property.id,
                    value: currentSaveOption,
                });
            }
            if (property.name === 'branch') {
                fieldValue.push({
                    fieldId: property.id,
                    value: branch,
                });
            }
            if (property.name === 'branch_id') {
                fieldValue.push({
                    fieldId: property.id,
                    value: branchID,
                });
            }
        });
        var physician_field = [];

        name_id.map((property) => {
            if (
                (property.name === 'npi' ||
                    //property.name === 'delivery_method' ||
                    property.name === 'ordering_physician') &&
                currentLookup_Rec === 'Physician'
            ) {
                fieldValue.push({
                    fieldId: property.id,
                    value: physicianData_fax[property.name],
                });
                physician_field.push({
                    fieldId: property.id,
                    value: physicianData_fax[property.name],
                });
            } else if (
                property.name === 'email_address' &&
                physicianData_fax.email_address &&
                currentLookup_Rec === 'Physician'
            ) {
                fieldValue.push({
                    fieldId: property.id,
                    value: physicianData_fax[property.name],
                });
                physician_field.push({
                    fieldId: property.id,
                    value: physicianData_fax[property.name],
                });
            } else if (
                property.name === 'physician_first_name' ||
                (property.name === 'physician_last_name' &&
                    currentLookup_Rec === 'Physician')
            ) {
                physician_field.push({
                    fieldId: property.id,
                    value: physicianData_fax[property.name],
                });
            } else if (
                property.name === 'phone_number' &&
                currentLookup_Rec === 'Physician'
            ) {
                if (
                    physicianData_fax !== undefined &&
                    physicianData_fax !== '' &&
                    physicianData_fax[property.name].includes('-') === true &&
                    physicianData_fax[property.name].includes('_') !== true
                ) {
                    var formatfax = physicianData_fax[property.name].split('-');
                    var number1 = formatfax[0] + formatfax[1] + formatfax[2];
                    fieldValue.push({
                        fieldId: property.id,
                        value: number1,
                    });
                    physician_field.push({
                        fieldId: property.id,
                        value: number1,
                    });
                } else {
                    fieldValue.push({
                        fieldId: property.id,
                        value:
                            physicianData_fax[property.name].includes('_') ||
                            physicianData_fax[property.name].includes('-')
                                ? physicianData_fax[property.name].replace(
                                      /[_-]/g,
                                      ''
                                  )
                                : physicianData_fax[property.name],
                    });
                    physician_field.push({
                        fieldId: property.id,
                        value:
                            physicianData_fax[property.name].includes('_') ||
                            physicianData_fax[property.name].includes('-')
                                ? physicianData_fax[property.name].replace(
                                      /[_-]/g,
                                      ''
                                  )
                                : physicianData_fax[property.name],
                    });
                }
            } else if (
                property.name === 'fax_number' &&
                currentLookup_Rec === 'Physician'
            ) {
                if (
                    physicianData_fax !== undefined &&
                    physicianData_fax !== '' &&
                    physicianData_fax[property.name].includes('-') === true
                ) {
                    var formatfax1 =
                        physicianData_fax[property.name].split('-');
                    var number2 = formatfax1[0] + formatfax1[1] + formatfax1[2];
                    fieldValue.push({
                        fieldId: property.id,
                        value: number2,
                    });
                    physician_field.push({
                        fieldId: property.id,
                        value: number2,
                    });
                } else {
                    fieldValue.push({
                        fieldId: property.id,
                        value: physicianData_fax[property.name],
                    });
                    physician_field.push({
                        fieldId: property.id,
                        value: physicianData_fax[property.name],
                    });
                }
            } else if (property.name === 'cover_page') {
                fieldValue.push({
                    fieldId: property.id,
                    value: coverpage_type,
                });
            } else if (property.name === 'fax_comments' && comment !== '') {
                fieldValue.push({
                    fieldId: property.id,
                    value: comment,
                });
            }
        });

        setValidateForUpload(true);
        let fieldDataObj = GetFieldData({
            configData: configData,
        });

        var create_status;
        if (physicianData_fax.id === undefined || physicianData_fax.id === '') {
            // createNewPatient(physician_field, physicianModifiableRecordsetId)
            //     .then((response) => {
            //         create_status = response.status;
            //         return response.json();
            //     })
            //     .then((data) => {
            //         if (create_status === 200) {
            //             physician_field.id = data.uniqueId;
            //             setPysicianData_fax({
            //                 ...physicianData_fax,
            //                 id: data.uniqueId,
            //             });
            //             // normalSuccessToast('Physician Created Successfully');
            //         }
            //         if (create_status === 400) {
            //             showErrorToast(data.message);
            //         }
            //     });

            const response = await createNewPatient(
                physician_field,
                physicianModifiableRecordsetId
            );
            create_status = response.status;
            const data = await response.json();
            if (create_status === 200) {
                physician_field.id = data.uniqueId;
                setPysicianData_fax({
                    ...physicianData_fax,
                    id: data.uniqueId,
                });
                // normalSuccessToast('Physician Created Successfully');
            }
            if (create_status === 400) {
                showErrorToast(data.message);
                setSaveAction(false);
                setLoader(false);
                return;
            }
        }

        var processId;
        var processArray;
        configData.menuItems.map((menu) => {
            if (formatHeaderNameWithOutSpace(menu.menuName) === 'outbox') {
                processArray = menu.processes;
                processArray.forEach((value) => {
                    processId = value.processId;
                });
            }
        });

        setSaveAction(true);
        setLoader(true);

        var selectedDoc = {};
        let uploadDocStatus = '';

        var uploadData;
        // added in order to send
        fieldValue.push({
            fieldId: fieldDataObj.signature_required,
            value: signatureRequired,
        });

        let errorConfirm = false;

        let singleField = false;

        if (
            recipientData.hasOwnProperty('ordering_physician') &&
            recipientData.hasOwnProperty('fax_number') &&
            recipientData.ordering_physician !== '' &&
            recipientData.fax_number !== ''
        ) {
            errorConfirm = false;
        } else if (
            recipientData.hasOwnProperty('ordering_physician') === true &&
            recipientData.hasOwnProperty('fax_number') === false &&
            recipientData.ordering_physician !== ''
        ) {
            singleField = true;
        } else if (
            recipientData.hasOwnProperty('ordering_physician') === false &&
            recipientData.hasOwnProperty('fax_number') === true &&
            recipientData.fax_number !== ''
        ) {
            singleField = true;
        } else if (
            recipientData.hasOwnProperty('ordering_physician') === true &&
            recipientData.hasOwnProperty('fax_number') === true &&
            (recipientData.fax_number == '___-___-____' ||
                recipientData.ordering_physician == '')
        ) {
            if (
                recipientData.fax_number == '___-___-____' &&
                recipientData.ordering_physician == ''
            ) {
                errorConfirm = true;
            } else {
                singleField = true;
            }
        } else {
            errorConfirm = true;
        }
        const faxPatternRec = /^[0-9\b]+$/;
        if (recipientData.fax_number) {
            let fax_number = recipientData.fax_number.replace(/-/g, '');

            if (fax_number) {
                if (!fax_number.match(faxPatternRec)) {
                    singleField = true;
                    dispatch(
                        outboxPage({
                            recipientFaxError: true,
                        })
                    );
                }
            }
        }
        if (
            currentLookup_Rec === 'Other Recipient' &&
            // Object.keys(recipientData).length != 0 &&
            errorConfirm === false &&
            singleField === false
        ) {
            fieldValue.push({
                fieldId: fieldDataObj.ordering_physician,
                value: recipientData.ordering_physician,
            });

            fieldValue.push({
                fieldId: fieldDataObj.fax_number,
                value: recipientData.fax_number.replace(/-/g, ''),
            });
        } else if (
            currentLookup_Rec === 'Other Recipient' &&
            (errorConfirm === true || singleField === true)
        ) {
            dispatch(
                outboxPage({
                    recipientDataError: true,
                })
            );
            setSaveAction(false);
            setLoader(false);
            let message =
                errorConfirm === true
                    ? 'Please create Recipient.'
                    : 'Please fill the mandatory fields.';
            normalErrorToast(message);
            return false;
        }
        let actionId = redux_store.inbox.stateData.allActions.fax_now.actionId;
        let statusId = props.statusId;
        let status = '';
        var fieldsKeyValue = {};
        var params = {};
        fieldsKeyValue.docIds = props.selectedDocId;
        fieldsKeyValue.fields = fieldValue;
        params.processId = props.processId;
        params.statusId = statusId;
        params.actionId = actionId;
        params.fields = fieldsKeyValue;
        let successDocId = [];
        let failedDocId = [];
        await outboxDocumentAction(params)
            .then(async (response) => {
                let result = await response.json();

                return result;
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                }
            });
        let message;
        let messageSucc;
        if (failedDocId.length !== 0) {
            props.updateState({
                penUpdateNotes: false,
            });

            if (failedDocId.length === 1) {
                message = `Error Sending Fax for Doc Id  ${failedDocId[0]} `;
            }
            props.updateState({
                openUpdateNotes: false,
                more_information: false,
            });
            setTimeout(() => {
                props.updateState({
                    more_information: true,
                });
            }, 10);

            showErrorToast(message);
        }
        if (successDocId.length !== 0) {
            props.updateState({
                showfaxNow: false,
            });

            if (successDocId.length === 1) {
                messageSucc = `Send Fax Details ( ${successDocId[0]} )  successfully.`;
            } else {
                messageSucc = `Send Fax Details (`;
                successDocId.map(async (item, idx) => {
                    if (
                        idx !== successDocId.length - 1 &&
                        idx !== successDocId.length - 2
                    ) {
                        messageSucc += `${item}, `;
                    } else if (idx === successDocId.length - 2) {
                        messageSucc += `${item}`;
                    } else {
                        messageSucc += ` and ${item} `;
                    }
                });
                messageSucc += `) updated successfully.`;
            }
            showSuccessToast('Fax Sent  successfully.');
        }
    };

    const updateState = (state) => {
        if (state.hasOwnProperty('physician_filter')) {
            setPhysicianFilter({ ...state.physician_filter });
        }
        if (state.hasOwnProperty('formData')) {
            setFormData({ ...formData, ...state.formData });
        }

        if (state.hasOwnProperty('validateDetail')) {
            setValidateForUpload(state.validateDetail);
            setValidateDetail(state.validateDetail);
        }
        if (state.hasOwnProperty('submitCreatephysician')) {
            setSubmitCreatePhysician(state.submitCreatephysician);
        }
        if (state.hasOwnProperty('physicianCreated')) {
            setphysicianCreated(state.physicianCreated);
        }
    };

    const handleCloseAction = (event) => {
        clearAll();
        props.updateState({
            showfaxNow: false,
        });
    };

    const coverpage_commentFunction = (value) => {
        setComment(value);
    };
    const coverpage_typeFunction = (value) => {
        setCoverpage_type(value);
    };
    const PhsicianData_Function = (value) => {
        setPysicianData_fax({ ...value });
    };
    const Recipient_Data = (value) => {
        setRecipientData({ ...value.recipientData });
    };
    const PhsicianLink_Function = (value) => {
        setPhysicianLink(value);
    };
    const checkingEdit_Physician = (value) => {
        setEditPhysician(value);
    };
    return (
        <>
            <Modal
                show={props.showfaxNow}
                onHide={() => handleCloseAction()}
                animation={false}
                size='lg'
                className='notes-popup upload_popup'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>
                            <span className='delivery-details'>
                                <i className='fas fa-fax' />
                                Send to Fax
                            </span>
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={() => handleCloseAction()}
                        />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='modal-section' id='upload-form'>
                        <div className='wrap_details wrap_note p-4'>
                            <div className='d-flex mb-5 details_part test align-items-stretch justify-content-between'>
                                <div className='details_fun'>
                                    <span>
                                        <i
                                            title='Cover-Page'
                                            className='fa-solid fa-message-medical'
                                        ></i>
                                        Sender
                                    </span>
                                </div>
                            </div>
                            <div className='form-group row '>
                                <div className='col-lg-4 mt-5 transition-select'>
                                    <label className='pl-5'> BRANCH </label>
                                    <select
                                        id='delete_selectbrand'
                                        className='form-control'
                                        onChange={(event) => {
                                            setBranch(event.target.value);
                                        }}
                                        value={branch ? branch : ''}
                                    >
                                        <option value=''>Select</option>
                                        {branchOptionArray !== undefined
                                            ? branchOptionArray.map(
                                                  (data, index) =>
                                                      data !== '' ? (
                                                          <option
                                                              key={index}
                                                              value={data}
                                                              style={{
                                                                  display:
                                                                      data !==
                                                                      ''
                                                                          ? 'block'
                                                                          : 'none',
                                                              }}
                                                          >
                                                              {data}
                                                          </option>
                                                      ) : null
                                              )
                                            : null}
                                    </select>
                                    <i
                                        className='fa-light fa-chevron-down'
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    {branch === '' &&
                                    branchValidation === true ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Branch is required.
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <DetailSection
                            open_edit_details={true}
                            isLocked={false}
                            isDetailBigView={false}
                            selectedDocId={''}
                            updateState={(state) => {
                                updateState(state);
                            }}
                            isUploadPage={true}
                            calingFrom='upload'
                            callingsource='FaxModal' //This is so the physican_filter resets to '' when sendtofax is clicked again. It has a reference to the code in DetailSection.js
                            coverpage_commentFunction={(e) => {
                                coverpage_commentFunction(e);
                            }}
                            coverpage_typeFunction={(e) =>
                                coverpage_typeFunction(e)
                            }
                            PhsicianData_Function={(e) =>
                                PhsicianData_Function(e)
                            }
                            setCurrentLookup_Rec={(value) =>
                                setCurrentLookup_Rec(value)
                            }
                            Recipient_Data={(e) => Recipient_Data(e)}
                            physicianData_fax={physicianData_fax}
                            coverpage_validation={coverpage_validation}
                            physician_validation={physician_validation}
                            setView_check={(num) => {
                                setView_check(num);
                            }}
                            faxNumberVerify={faxNumberVerify}
                            phoneNumberVerify={phoneNumberVerify}
                            npiVerify={npiVerify}
                            emailVerify={emailVerify}
                            physicianLink={physicianLink}
                            PhsicianLink_Function={(e) => {
                                PhsicianLink_Function(e);
                            }}
                            checkingEdit_Physician={(value) => {
                                checkingEdit_Physician(value);
                            }}
                            editPhysician={editPhysician}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                variant='secondary'
                                onClick={() => handleCloseAction()}
                                title='Cancel'
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                className='fax-outer'
                                onClick={saveAction}
                                title='Submit'
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default SendToFaxUploadModal;
