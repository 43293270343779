import {
    DELETED_PAGE,
    ROW_DETAIL,
    UPDATE_SEARCH_STATE,
} from '../actionTypes/searchTypes';

export const deletedPage = (data) => ({
    type: DELETED_PAGE,
    data,
});

export const selectedRowDetail = (data) => ({
    type: ROW_DETAIL,
    data,
});

export const updateSearchState = (data) => ({
    type: UPDATE_SEARCH_STATE,
    data,
});
