/* eslint-disable array-callback-return */
//import React, {useMemo} from "react";
import * as utils from './LocalStorageHelpers';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
//import formatNameWithSpaceTohipen from '../../app/components/commonfunctions';
const localStorageLastLocationKey = 'metronic-lastLocation';
//const dispatch = useDispatch();
//const redux_store = useSelector(state => state);
function acceptLocation(lastLocation) {
    if (
        lastLocation &&
        lastLocation.pathname &&
        lastLocation.pathname !== '/' &&
        lastLocation.pathname.indexOf('auth') === -1 &&
        lastLocation.pathname !== '/logout'
    ) {
        return true;
    }

    return false;
}

export function saveLastLocation(lastLocation) {
    if (acceptLocation(lastLocation)) {
        utils.setStorage(
            localStorageLastLocationKey,
            JSON.stringify(lastLocation),
            120
        );
    }
}

// export function formatNameWithSpaceToUnderscore(name) {
// 	return String(name).replace(/\s/g, '_').toLowerCase();
// }
export function formatNameWithSpaceTohipen(name) {
    // const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
    // let temp = String(name).replace(regex, '').trim().toLowerCase();
    // temp = String(temp).replace(/\s/g, '_');
    //return String(temp).replace(/\//g, '_');
    if (name !== '' && name !== undefined) {
        let temp1 = name.trim().toLowerCase();
        let outString = temp1.replace(
            // eslint-disable-next-line no-useless-escape
            /[`~!@#$%^&*()|+\_=?;:'",.<>\{\}\[\]\\\/]/gi,
            '-'
        );

        let temp = String(outString).replace(/\s/g, '-');
        temp = String(temp).replace('---', '-');
        temp = String(temp).replace('--', '-');
        return temp;
    } else {
        return '';
    }

    // return String(name).replace(/\s/g, '_').trim().toLowerCase();
}

export function formatNameWithSpaceToUnderscore(name) {
    /*const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
	let temp = String(name).replace(regex, '').trim().toLowerCase();
	temp = String(temp).replace(/\s/g, '_').trim().toLowerCase();
	return String(temp).replace(/\//g, "_");*/

    if (name !== '' && name !== undefined) {
        let temp1 = name.trim().toLowerCase();
        let outString = temp1.replace(
            /[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            '_'
        );

        let temp = String(outString).replace(/\s/g, '_');
        temp = String(temp).replace('___', '_');
        temp = String(temp).replace('__', '_');
        return temp;
    } else {
        return '';
    }
}

export function forgotLastLocation() {
    utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
    const defaultLocation = { pathname: '/', title: 'Dashboard' };
    const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
    if (!localStorateLocations) {
        return { pathname: '/', title: 'Dashboard' };
    }

    try {
        const result = JSON.parse(localStorateLocations);
        return result;
    } catch (error) {
        return defaultLocation;
    }
}

export function getCurrentUrl(location) {
    return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url) {
    const current = getCurrentUrl(location);
    if (!current || !url) {
        return false;
    }

    if (current === url) {
        return true;
    }

    if (current.indexOf(url) > -1) {
        return true;
    }

    return false;
}

export function getConfig() {
    let configData = [];
    if (
        localStorage.getItem('configData') &&
        localStorage.getItem('configData') != ''
    ) {
        const config = localStorage.getItem('configData');
        configData.push(JSON.parse(config));
    }
    return configData;
}

export function GetCounterWithData(config, count) {
    //const dispatch = useDispatch();
    let configData = [];
    configData.push(config);
    let values = [];
    var process_id = '';

    if (
        configData.length > 0 &&
        configData[0] !== undefined &&
        configData[0] !== null
    ) {
        const menuItems =
            configData[0].menuItems !== undefined
                ? configData[0].menuItems
                : [];
        menuItems.map((data, key) => {
            if (data.displayCounts === true) {
                data.processes.map((value, index) => {
                    let CountData = [];
                    //if(localStorage.getItem('CountData'+value.processId)) {
                    if (count !== undefined && Object.keys(count).length > 0) {
                        count.map((countData) => {
                            if (
                                countData !== undefined && countData !== null && 
                                countData.process !== undefined &&  countData.process !== null &&
                                countData.process.processId !== undefined && countData.process.processId !== null &&
                                countData.process.processId === value.processId
                            ) {
                                CountData.push(countData);
                            }
                        });
                        // let count		=	localStorage.getItem('CountData'+value.processId);
                        // CountData.push(JSON.parse(count));
                        if (CountData.length > 0) {
                            CountData.map((val, ind) => {
                                if (
                                    val.process != null &&
                                    val.statuses != null
                                ) {
                                    process_id = 'p_' + val.process.processId;
                                    values[process_id] = val.process.count;
                                    if (val.process) {
                                        //CountArray.push(data.process.processId : );
                                        val.statuses.map((pp, pkey) => {
                                            process_id =
                                                'p_' +
                                                pp.processId +
                                                '_s_' +
                                                pp.statusId;
                                            values[process_id] = pp.count;

                                            //CountArray.push({'processId':pp.processId,'statusId':pp.statusId,'count':pp.count});
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    }
    //	return <h1>Hello,</h1>;
    return values;
}

export function GetCounter() {
    //const dispatch = useDispatch();
    let configData = [];
    const redux_store = useSelector((state) => state);
    const config = redux_store.configReducerPersist.configData;
    configData.push(config);
    const count = redux_store.persist.count;
    let values = [];
    var process_id = '';

    if (
        configData.length > 0 &&
        configData[0] !== undefined &&
        configData[0] !== null
    ) {
        const menuItems =
            configData[0].menuItems !== undefined
                ? configData[0].menuItems
                : [];
        menuItems.map((data, key) => {
            if (data.displayCounts === true) {
                data.processes.map((value, index) => {
                    let CountData = [];
                    //if(localStorage.getItem('CountData'+value.processId)) {
                    if (count !== undefined && Object.keys(count).length > 0 && count !== null && count !== '') {
                        count.map((countData) => {
                            
                            if (
                                countData !== undefined && countData !== null &&
                                countData.process !== null &&
                                countData.process !== undefined &&
                                countData.process.processId !== null &&                               
                                countData.process.processId !== undefined &&
                                value !== undefined && value !== null &&
                                value.processId !== undefined && 
                                value.processId !== null &&
                                countData.process.processId === value.processId
                            ) {
                                CountData.push(countData);
                            }
                        });
                        // let count		=	localStorage.getItem('CountData'+value.processId);
                        // CountData.push(JSON.parse(count));
                        if (CountData.length > 0) {
                            CountData.map((val, ind) => {
                                if (
                                    val.process != null &&
                                    val.statuses != null
                                ) {
                                    process_id = 'p_' + val.process.processId;
                                    values[process_id] = val.process.count;
                                    if (val.process) {
                                        //CountArray.push(data.process.processId : );
                                        val.statuses.map((pp, pkey) => {
                                            process_id =
                                                'p_' +
                                                pp.processId +
                                                '_s_' +
                                                pp.statusId;
                                            values[process_id] = pp.count;

                                            //CountArray.push({'processId':pp.processId,'statusId':pp.statusId,'count':pp.count});
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    }
    //	return <h1>Hello,</h1>;
    return values;
}

export function getTags() {
    let configData = [];

    const config = localStorage.getItem('configData');
    configData.push(JSON.parse(config));

    if (configData[0].tags && configData[0].tags.length > 0) {
        return configData[0].tags;
    } else {
        return [];
    }
}

export function getActions(menuName, processId, statusId) {
   
    let configData = [];
    let actions = [];
    const config = localStorage.getItem('configData');
    if (config !== null) {
        configData.push(JSON.parse(config));
    }
    if (configData.length > 0) {
        configData[0].menuItems.map((data) => {
            if (data.processes && data.processes.length > 0) {
                data.processes.map((value) => {
                    if (value.processId == processId) {
                        if (value.statuses && value.statuses.length > 0) {
                           
                            value.statuses.map((val) => {
                                if (val.statusId == statusId) {
                                    if (val.actions && val.actions.length > 0) {
                                        val.actions.map((result) => {
                                            actions[result.actionId] =
                                                result.name;
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    }

    return actions;
} 
/* export function getActions(menuName, processId, statusId) {
    let configData = [];
    let actions = [];
    const config = localStorage.getItem('configData');
    
    if (config !== null) {
        configData.push(JSON.parse(config));
    }
   
    if (configData.length > 0) {
        configData[0].menuItems.map((data) => {
            if (data.processes && data.processes.length > 0) {
                data.processes.map((value) => {
                    if (value.processId == processId) {
                        if (value.statuses && value.statuses.length > 0) {
                            value.statuses.map((val) => {
                                if (val.statusId == statusId) {
                                    if(configData[0].configuredStatuses !== undefined){
                                       
                                        configData[0].configuredStatuses.map((item)=>{
                                           
                                            if(item.statusId === statusId){
                                                if (item.actions && item.actions.length > 0) {
                                                    item.actions.map((result) => {
                                                        actions[result.actionId] =
                                                            result.name;
                                                    });
                                                }  
                                            }
                                        })
                                    }
                                    

                                }
                            });
                        }
                    }
                });
            }
        });
    }
   
    return actions;
} */
export function getdefaultLinkFromMenu(reportsArray, menuItems) {
    var report_link = '';
    var allowedReports = [];
    if (menuItems && menuItems.length > 0) {
        menuItems.map((data, key) => {
            if (data.menuName.toLowerCase() === 'reports') {
                allowedReports = data.reports;
            }
        });
    }
    var indexx = 0;
    let customMenuArrayName = localStorage.getItem('customMenuArray')
    let customMenuArray 
    if(customMenuArrayName !== null && customMenuArrayName !== undefined){
        customMenuArray = localStorage.getItem('customMenuArray').split(',');  
        if (reportsArray && reportsArray.length > 0) {
            reportsArray.map((item, index) => {

                var relatedToMenus = item.relatedToMenus;
                if (item.relatedToMenus.length === 0) {
                    relatedToMenus = customMenuArray;
                }

                let isFounded = relatedToMenus.some((ai) =>
                    customMenuArray.includes(String(ai))
                );

                if (
                    isFounded &&
                    allowedReports.includes(item.reportId) &&
                    indexx === 0
                ) {
                    report_link =
                        '/reports/' +
                        formatNameWithSpaceTohipen(item.category) +
                        '/' +
                        formatNameWithSpaceTohipen(item.name) +
                        '/' +
                        item.reportId;
                    indexx++;
                    return report_link;
                }
                //break;
            });

            return report_link;
        }
    }
   // return newArray;
}
export function getdefaultLinkFromFormMenu(reportsArray, menuItems) {
    var report_link = '';
    var allowedReports = [];
    if (menuItems && menuItems.length > 0) {
        menuItems.map((data, key) => {
            if (data.menuName.toLowerCase() === 'forms') {
                allowedReports = data.forms;
            }
        });
    }
    if (reportsArray && reportsArray.length > 0) {
        reportsArray.map((item, index) => {
            if (allowedReports.includes(item.formId) && index === 0) {
                report_link =
                    '/forms/' +
                    formatNameWithSpaceTohipen(item.category) +
                    '/' +
                    formatNameWithSpaceTohipen(item.name) +
                    '/' +
                    item.formId;
                return report_link;
            }
            //break;
        });

        return report_link;
    }
    //return newArray;
}
export function constructMenu(reportsArray, menuItems) {
    let newArray = [];

    var allowedReports = [];
    if (menuItems && menuItems.length > 0) {
        menuItems.map((data, key) => {
            if (data.menuName.toLowerCase() === 'reports') {
                allowedReports = data.reports;
            }
        });
    }

    if (reportsArray && reportsArray.length > 0) {
        reportsArray.map((item, index) => {
            if (allowedReports.includes(item.reportId)) {
                if (!newArray.includes(item.category)) {
                    newArray.push(item.category);
                }
            }
        });
    }
    return newArray;
}
export function constructFormMenu(reportsArray, menuItems) {
    let newArray = [];

    var allowedReports = [];
    if (menuItems && menuItems.length > 0) {
        menuItems.map((data, key) => {
            if (data.menuName.toLowerCase() === 'forms') {
                allowedReports = data.forms;
            }
        });
    }

    if (reportsArray && reportsArray.length > 0) {
        reportsArray.map((item, index) => {
            if (allowedReports.includes(item.formId)) {
                if (!newArray.includes(item.category)) {
                    newArray.push(item.category);
                }
            }
        });
    }
    return newArray;
}
export function getAllActions(menuName, processId, statusId) {
    let configData = [];
    let actions = [];
    const config = localStorage.getItem('configData');
    if (config !== null) {
        configData.push(JSON.parse(config));
    }
    if (configData.length > 0) {
        configData[0].menuItems.map((data) => {
            if (data.processes && data.processes.length > 0) {
                data.processes.map((value) => {
                    if (value.processId == processId) {
                        if (value.statuses && value.statuses.length > 0) {
                            value.statuses.map((val) => {
                                if (val.statusId == statusId) {
                                    if (val.actions && val.actions.length > 0) {
                                        var temp = {};
                                        var name;
                                        val.actions.map((result) => {
                                            name =
                                                formatNameWithSpaceToUnderscore(
                                                    result.name
                                                );
                                            temp[name] = result;
                                        });
                                        actions.push(temp);
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    }
    if (actions[0] !== undefined) {
        return actions[0];
    } else {
        var empty = {};
        return empty;
    }
}

export function getAllFields() {
    let configData = [];
    let fields = [];
    const config = localStorage.getItem('configData');
    configData.push(JSON.parse(config));

    if (
        configData.length > 0 &&
        configData[0] !== undefined &&
        configData[0] !== null
    ) {
        configData[0].fields.map((data) => {
            fields[data.fieldId] = data;
        });
    }

    return fields;
}
