import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import './BreakPointModal.css';

function BreakPointModal(props) {
    const [breakValue, setBreakValue] = useState('');
    const [isCustom, setIsCustom] = useState(false);
    const [customValue, setCustomValue] = useState('');

    const handleBreakCloseOperation = () => {
        props.handleBreakCloseOperation();
    };

    const handleBreakOperation = () => {
        if (breakValue !== '') {
            props.handleBreakOperation(breakValue);
        } else {
            document.getElementById('nav-pills').classList.add('error');
        }
    };

    const handleBreakPoint = (breakPt) => {
        if (breakPt) {
            setIsCustom(false);
            setBreakValue(breakPt);
        } else {
            setIsCustom(true);
        }
        document.getElementById('nav-pills').classList.remove('error');
    };

    const handleCustomValueChange = (e) => {
        setCustomValue(e.target.value);
    };

    const handleCustomBreakPoint = () => {
        const customBreakValue = parseInt(customValue, 10);
        if (!isNaN(customBreakValue)) {
            setBreakValue(customBreakValue);
            props.handleBreakOperation(customBreakValue);
        }
    };

    return (
        <>
            <Modal
                show={props.showBreakPointPopup}
                onHide={handleBreakCloseOperation}
                animation={false}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div>
                            <svg
                                className='icon-fill'
                                width='20'
                                height='17'
                                viewBox='0 0 100 100'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path d='M100,77c-4.7-3.5-9.4-7-13.9-10.5c-1.6-1.2-3.2-2.4-4.7-3.6c0,0.5,0,1,0,1.5l0,1.3c0,0.4,0,0.8,0,1.1  c0,0.1,0,0.2,0,0.3L81,72.8l-5.7,0.5c0,0-0.1,0-0.1,0c-0.2,0-0.5,0.1-1,0.1c-2,0-4,0-6,0c-4.4,0-8.9,0-13.3,0  c-3.6,0-6.7-1.7-8.8-4.9c-3.6-5.4-6.6-9.9-9.4-14.1c-0.9-1.4-1.4-2.9-1.4-4.4c0-1.5,0.5-3,1.4-4.4c3.2-4.8,6.4-9.5,9.4-14  c2.1-3.2,5.4-4.9,9.2-4.9c4.3,0,8.6,0,12.8,0c1.9,0,3.8,0,5.7,0c0.5,0,0.9,0,1.2,0.1c0.1,0,0.2,0,0.3,0l6.1,0.4v6.1  c0,0.9,0,1.9,0,2.8c0,0.3,0,0.7,0,1c0.5-0.4,1-0.7,1.5-1.1c5.6-4.2,11.4-8.6,17.2-12.9c-5-3.8-10.1-7.6-15.1-11.3  c-1.2-0.9-2.4-1.8-3.6-2.7c0,0.1,0,0.2,0,0.3c0,0.8,0,1.7,0,2.5v1.1v6.5h-6.5c-2.3,0-4.7,0-7,0c-5.2,0-10.6,0-15.8,0  c-4.3,0-7.4,1.5-9.9,5c-2.4,3.2-4.8,6.5-7.2,9.6c-1.9,2.6-3.9,5.2-5.9,7.8c-2.2,3-5.4,4.6-9.1,4.6c-3.2,0-6.4,0-9.6,0  c-3.4,0-6.9,0-10.3,0v7.3c3.2,0,6.3,0,9.4,0c3.4,0,6.9,0,10.3,0c3.9,0,7.2,1.6,9.5,4.8c2.4,3.2,4.8,6.5,7.2,9.6  c1.9,2.5,3.9,5.2,5.8,7.8c2.5,3.3,5.4,4.8,9.6,4.8c5.1,0,10.2,0,15.2,0c2.2,0,4.4,0,6.6,0h1.3h6.5v6.5c0,1,0,1.9,0,2.9  c0,0.4,0,0.7,0,1c1.3-1,2.7-2,4-3C90.1,84.4,95.1,80.6,100,77z' />
                            </svg>
                            Break Documents
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleBreakCloseOperation}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='breaks'>
                    <div className='tab_part'>
                        <div className='tabs'>
                            <Nav
                                style={{
                                    float: 'left',
                                }}
                                variant='pills'
                                id='nav-pills'
                                className='mb-3 nav-item'
                                defaultActiveKey='link-0'
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        title='Every Page'
                                        key='option1'
                                        value='1'
                                        eventKey='link-1'
                                        onClick={() => {
                                            handleBreakPoint(1);
                                        }}
                                    >
                                        Every Page
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        title='Every Other Page'
                                        key='option2'
                                        value='2'
                                        eventKey='link-2'
                                        onClick={() => {
                                            handleBreakPoint(2);
                                        }}
                                    >
                                        Every Other Page
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        title='Every 3 Pages'
                                        key='option3'
                                        value='3'
                                        eventKey='link-3'
                                        onClick={() => {
                                            handleBreakPoint(3);
                                        }}
                                    >
                                        Every 3 Pages
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        title='Custom'
                                        key='option4'
                                        value='4'
                                        eventKey='link-4'
                                        onClick={() => {
                                            handleBreakPoint();
                                        }}
                                    >
                                        Custom
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className='custom-breakpoints'>
                            {isCustom && (
                                <input
                                    className='custom-input'
                                    type='number'
                                    value={customValue}
                                    onChange={handleCustomValueChange}
                                    placeholder='Enter number of pages'
                                />
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                variant='secondary'
                                onClick={handleBreakCloseOperation}
                                title='Cancel'
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                variant='success'
                                className=''
                                onClick={
                                    isCustom
                                        ? handleCustomBreakPoint
                                        : handleBreakOperation
                                }
                                title='Break Documents'
                            >
                                Break Documents
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BreakPointModal;
