import React from 'react';
import DatePicker from 'react-date-picker';
import { useSelector } from 'react-redux';

const FormFileds = ({
    errorMessage,
    fieldType,
    fieldName,
    onChange,
    value,
}) => {
    const redux_store = useSelector((state) => state);
    const ddl = redux_store.persistDDL.DDL;
    const configData = redux_store.configReducerPersist.configData;

    const displayDataObject = [...configData.fields].find(
        (value) => value.name === fieldName
    );

    const displayType =
        displayDataObject.datatype === 'Date'
            ? 'date'
            : displayDataObject.hasDropdown
            ? 'dropDown'
            : '';
    switch (displayType) {
        case 'date':
            return (
                <div className='col-lg-6 transition-select'>
                    <label className='pl-5'>{displayDataObject.name}</label>
                    <div
                        className={
                            errorMessage
                                ? 'position-relative form-control error-field'
                                : 'position-relative form-control'
                        }
                    >
                        <DatePicker
                            onChange={(date) => {
                                onChange(date);
                            }}
                            value={value}
                            className='date-picker'
                            format='MM/dd/y'
                        />
                    </div>
                    {errorMessage ? (
                        <p
                            className='error m-0'
                            style={{
                                color: 'red',
                            }}
                        >
                            {errorMessage}
                        </p>
                    ) : null}
                </div>
            );
        case 'dropDown':
            return (
                <div className='col-lg-6 transition-select'>
                    <label className='pl-5'>{displayDataObject.name}</label>
                    <select
                        id='delete_selectbra'
                        className={
                            errorMessage
                                ? 'form-control error-field'
                                : 'form-control'
                        }
                        value={value}
                        onChange={(event) => {}}
                        disabled={false}
                    >
                        <option value=''>Select</option>
                        {ddl['attachmentlocation'].map((options, key) => {
                            return (
                                <option value={options} key={key}>
                                    {options}
                                </option>
                            );
                        })}
                    </select>
                    <i
                        className='fa-light fa-chevron-down'
                        style={{ pointerEvents: 'none' }}
                    />
                    {errorMessage ? (
                        <p
                            className='error m-0'
                            style={{
                                color: 'red',
                            }}
                        >
                            {errorMessage}
                        </p>
                    ) : null}
                </div>
            );
        default:
            return (
                <div className='col-lg-6 transition-select'>
                    <label className='pl-5'>{displayDataObject.name}</label>
                    <div className='position-relative'>
                        <input
                            type='text'
                            className={
                                errorMessage
                                    ? 'form-control error-field'
                                    : 'form-control'
                            }
                            value={value || ''}
                            onChange={(event) => {
                                onChange(event.target.value);
                            }}
                            maxLength='50'
                        />
                    </div>
                    {errorMessage ? (
                        <p className='error m-0' style={{ color: 'red' }}>
                            {errorMessage}
                        </p>
                    ) : null}
                </div>
            );
    }
};

export default FormFileds;
