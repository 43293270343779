/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { saveAs } from 'save-as';
import JSZip from 'jszip';
import { NavLink } from 'react-router-dom';
import { downloadDoc } from '../../api/api';
import { showErrorToast } from '../../../components/commonfunction';
import PrintPdfDocument from './PrintPdfDocument';
import { Notes } from './Notes';
// import Spinner from './Spinner';
import AllowMultipleEditModal from '../../../components/datagrid/AllowMultipleEditModal';
import { inboxPage } from '../../_redux/actions/inboxActions';
import { useDispatch } from 'react-redux';
// import Office365AuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
// import GmailAuthenticationPage from '../../../components/composeDraftMail/GmailAuthenticationPage';
import { useLocation, useHistory } from 'react-router-dom';
import { DocumentIdToPrint } from '../../../../utils/printPdf/printPdf';

export function MultipleSelection(props) {
    const [printDoc, setPrintDoc] = useState([]);
    const [openNote, setOpenNote] = useState(false);
    const [, setLoader] = useState(false);
    const [editURL, setEditURL] = useState(false);
    const [multipleID, setMultipleID] = useState('');
    // const [docIds, setDocIds] = useState([]);
    const [restrictAllowEdit, setRestrictAllowEdit] = useState(false);

    const entering = (e) => {
        e.children[0].style.borderTopColor = 'rgb(0, 35, 58)';
        e.children[1].style.backgroundColor = 'rgb(0, 35, 58)';
    };
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (printDoc.length > 0) {
            DocumentIdToPrint(printDoc)
                .then(() => {
                    props.showLoader(false);
                })
                .catch((err) => {
                    props.showLoader(false);
                });
        }
    }, [printDoc]);

    useEffect(() => {
        let urlConst;
        const docId = props.docIds;

        let editId = '';
        let multiple_id = '';
        docId.map((id) => {
            editId += `/${window.btoa(String(id))}`;
            if (multiple_id === '') {
                multiple_id += `${window.btoa(String(id))}`;
            } else {
                multiple_id += `/${window.btoa(String(id))}`;
            }
        });

        if (props.docIds.length === 1) {
            urlConst = `/documents/edit${editId}`;
        } else {
            //urlConst = `/documents/editmultiple${editId}`;
            urlConst = `/inbox/editmultiple?editmultiple=${multiple_id}`;
        }

        if (
            String(location.pathname).includes('detail') === true &&
            String(location.pathname).includes('editmultiple') !== true
        ) {
            multiple_id += `/${location.state.selectedDocID}`;
            urlConst = `/inbox/detail/editmultiple?editmultiple=${multiple_id}`;
        }

        setMultipleID(multiple_id);

        setEditURL(urlConst);
    }, [props, location]);

    const downloadMultipleDoc = async () => {
        const docid = props.docIds;

        let status = '';
        // const files = false;
        let status_200 = 0;
        const zip = new JSZip();
        const img = zip.folder('PDF');
        // images is the folder which will be zip
        let count = 0;
        let blob = '';
        await docid.forEach((doc_id, index) => {
            downloadDoc(doc_id)
                .then((response) => {
                    status = response.status;
                    return response.blob();
                })
                .then((data) => {
                    count += 1;
                    if (status === 200) {
                        blob = data;
                        status_200 += 1;
                        // const url = URL.createObjectURL(data);
                        img.file(`${doc_id}.pdf`, data, { base64: true });
                    }
                    if (docid.length === count && status_200 > 1) {
                        zip.generateAsync({ type: 'blob' }).then(function (
                            content
                        ) {
                            saveAs(content, 'Document.zip');
                        });
                    }
                    if (docid.length === count && status_200 === 1) {
                        saveAs(blob, 'Document.pdf');
                    }
                    if (docid.length === count && status_200 === 0) {
                        showErrorToast('No PDF to download');
                    }
                });
        });
    };
    const print = (docId) => {
        // docId = [863];
        if (docId.length === 0) {
            return false;
        }
        props.showLoader(true);
        setPrintDoc(docId);
    };
    const setPrinterloader = () => {
        setLoader(false);
    };

    const openNoteModal = () => {
        setOpenNote(true);
    };
    const handleReset = () => {
        setOpenNote(false);
    };
    const handleCloseOperation = () => {
        setRestrictAllowEdit(false);
    };
    const openEditError = () => {
        // history.push({
        //     state: {
        //         selectedDocID: location.state !== undefined && location.state !== null && location.state.selectedDocID  !== undefined && location.state.selectedDocID  !== null ? location.state.selectedDocID:undefined,
        //         processId: location.state !== undefined  && location.state !== null && location.state.processId !== undefined && location.state.processId !== null? location.state.processId: undefined,
        //         statusId: location.state !== undefined  && location.state !== null && location.state.statusId !== undefined && location.state.statusId !== null ? location.state.statusId: undefined,
        //     },
        // })
        setRestrictAllowEdit(true);
    };

    const handleMultiplePage = () => {
        dispatch(
            inboxPage({
                multiple_edit_page: true,
            })
        );
    };

    /*handleCloseOperation = async () => {
        this.setState({
            restrictAllowEdit: false,
        });
    };
    handleMultiplePage = async () => {
        this.props.inboxPage({
            multiple_edit_page: true,
        });
    };*/

    // const sendDraft = async (docIds) => {
    //     let serviceType = this.props.configData?.emailIntegration?.serviceType;

    //     if (serviceType == 'Office 365') {
    //         // check to authenticate draft mail
    //         Office365AuthenticationPage({
    //             selectedDocID: docIds[0],
    //             configData: this.props.configData,
    //         });
    //     } else {
    //         GmailAuthenticationPage({
    //             selectedDocID: docIds[0],
    //             configData: this.props.configData,
    //         });
    //     }
    // };

    const callEditPage = () => {
        dispatch(
            inboxPage({
                multiple_edit_page: true,
            })
        );
        history.push(editURL);
    };

    return (
        <>
            {' '}
            <div className='right-aside pdf_section_multiselect mt-3'>
                <div className='bottom-function d-flex align-items-stretch justify-content-between'>
                    <div className='d-flex align-items-center ml-6 selected_count'>
                        {props.documentCount}{' '}
                    </div>

                    <div className='bottom-icons d-flex align-items-center'>
                        {props.noteTypes !== undefined &&
                            props.noteTypes.length !== 0 && (
                                <OverlayTrigger
                                    placement='top'
                                    onEntering={entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            className='icon_fun outer-col'
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Notes
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        className='icon_fun outer-col fst-icon'
                                        onClick={() => {
                                            openNoteModal();
                                        }}
                                    >
                                        <i className='fa-solid fa-sticky-note' />
                                    </div>
                                </OverlayTrigger>
                            )}
                        {window.location.pathname.includes('search') !== true &&
                            window.location.pathname.includes('report') !==
                                true && (
                                <OverlayTrigger
                                    placement='top'
                                    onEntering={entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Merge / Split Documents
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    {props.docIds.length > 4 ? (
                                        <div
                                            onClick={openEditError}
                                            className='icon_fun outer-col'
                                        >
                                            <i className='fas fa-file-medical-alt' />
                                        </div>
                                    ) : (
                                        // <div
                                        //     onClick={callEditPage}
                                        //     className='icon_fun outer-col'
                                        // >
                                        //     <i className='fas fa-file-medical-alt' />
                                        // </div>
                                        <NavLink
                                            to={{
                                                pathname:
                                                    props.propsCalingFrom ===
                                                    'report'
                                                        ? '/reports/editmultiple'
                                                        : String(
                                                              location.pathname
                                                          ).includes(
                                                              'detail'
                                                          ) === true
                                                        ? '/inbox/detail/editmultiple'
                                                        : '/inbox/editmultiple',
                                                selectedDocIds: props.docIds,
                                                editIds: editURL,
                                                search:
                                                    '?editmultiple=' +
                                                    multipleID,
                                                state: {
                                                    selectedDocID:
                                                        location.state !==
                                                            undefined &&
                                                        location.state !==
                                                            null &&
                                                        location.state
                                                            .selectedDocID !==
                                                            undefined &&
                                                        location.state
                                                            .selectedDocID !==
                                                            null
                                                            ? location.state
                                                                  .selectedDocID
                                                            : undefined,
                                                    processId:
                                                        location.state !==
                                                            undefined &&
                                                        location.state !==
                                                            null &&
                                                        location.state
                                                            .processId !==
                                                            undefined &&
                                                        location.state
                                                            .processId !== null
                                                            ? location.state
                                                                  .processId
                                                            : undefined,
                                                    statusId:
                                                        location.state !==
                                                            undefined &&
                                                        location.state !==
                                                            null &&
                                                        location.state
                                                            .statusId !==
                                                            undefined &&
                                                        location.state
                                                            .statusId !== null
                                                            ? location.state
                                                                  .statusId
                                                            : undefined,
                                                },
                                            }}
                                            onClick={() => {
                                                dispatch(
                                                    inboxPage({
                                                        multiple_edit_page: true,
                                                    })
                                                );
                                                if (
                                                    String(
                                                        location.pathname
                                                    ).includes('detail') ===
                                                    true
                                                ) {
                                                    dispatch(
                                                        inboxPage({
                                                            related_doc_detail: false,
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            <div className='icon_fun outer-col'>
                                                <i className='fas fa-file-medical-alt' />
                                            </div>
                                        </NavLink>
                                    )}
                                </OverlayTrigger>
                            )}
                        <Dropdown className='bulk-print-opt icon_fun'>
                            <OverlayTrigger
                                placement='top'
                                onEntering={entering}
                                overlay={
                                    <Tooltip
                                        id='tooltip'
                                        style={{
                                            backgroundColor: 'rgb(0, 35, 58)',
                                            color: 'white',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                            }}
                                        >
                                            Settings
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <Dropdown.Toggle
                                    variant='success'
                                    id='dropdown-basic'
                                >
                                    <i
                                        className='fa fa-ellipsis-v'
                                        aria-hidden='true'
                                    />
                                </Dropdown.Toggle>
                            </OverlayTrigger>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    title='Print'
                                    className='print-dropdown'
                                >
                                    <span
                                        onClick={() => {
                                            print(props.docIds);
                                        }}
                                    >
                                        <i className='fas fa-print' />
                                        Print
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item title='Download'>
                                    <span
                                        onClick={() => {
                                            downloadMultipleDoc(props.docIds);
                                        }}
                                    >
                                        <i className='fas fa-download' />
                                        Download
                                    </span>
                                </Dropdown.Item>
                                {/*<Dropdown.Item  //*** if unblock this plz let me know service type not handled here by krish
                                    onClick={() => {
                                        sendDraft(props.docIds);
                                    }}
                                >
                                    <span>
                                        <i className='fa-solid fa-envelope' />
                                        Send via email
                                    </span>
                                </Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>

                        <OverlayTrigger
                            placement='top'
                            onEntering={entering}
                            overlay={
                                <Tooltip
                                    id='tooltip'
                                    style={{
                                        backgroundColor: 'rgb(0, 35, 58)',
                                        color: 'white',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: 'rgb(0, 35, 58)',
                                            color: 'white',
                                        }}
                                    >
                                        Close
                                    </div>
                                </Tooltip>
                            }
                        >
                            <div className='icon_fun outer-col no-bg'>
                                <i
                                    className='fa-light fa-xmark'
                                    onClick={() => {
                                        props.closefn();
                                    }}
                                />
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            {/* {printDoc.length > 0 ? (
                <PrintPdfDocument docId={printDoc} loader={setPrinterloader} />
            ) : null} */}
            <Notes
                openNote={openNote}
                docId={props.docIds}
                handleReset={handleReset}
            />
            {restrictAllowEdit ? (
                <AllowMultipleEditModal
                    restrictAllowEdit={restrictAllowEdit}
                    handleCloseOperation={handleCloseOperation}
                    editURL={editURL}
                    handleMultiplePage={handleMultiplePage}
                    selectedDocID={
                        location.state !== undefined &&
                        location.state !== null &&
                        location.state.selectedDocID !== undefined &&
                        location.state.selectedDocID !== null
                            ? location.state.selectedDocID
                            : undefined
                    }
                    processId={
                        location.state !== undefined &&
                        location.state !== null &&
                        location.state.processId !== undefined &&
                        location.state.processId !== null
                            ? location.state.processId
                            : undefined
                    }
                    statusId={
                        location.state !== undefined &&
                        location.state !== null &&
                        location.state.statusId !== undefined &&
                        location.state.statusId !== null
                            ? location.state.statusId
                            : undefined
                    }
                />
            ) : null}
            {/* {showLoader ? (
                <div className='grouping-loader'>
                    {' '}
                    <Spinner show={showLoader} />{' '}
                </div>
            ) : null} */}
        </>
    );
}
