import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { formatHeaderNameWithOutSpace } from '../../../components/commonfunction';
import { formatDate } from '../../../components/commonfunction';

const DetailMoreInformation = (props) => {
    return (
        <>
            <div className='form-group patient_detail row'>
                <span className='col-lg-4'>
                    <span className='patient_label'>DOCUMENT DATE</span>
                    <br />
                    {props.miscPproperties.documentDate ? (
                        <span className='patient_info'>
                            {formatDate(
                                props.miscPproperties.documentDate,
                                'MM/DD/YYYY'
                            )}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>
                <span className='col-lg-4'>
                    <span className='patient_label'>DOCUMENT TYPE</span>
                    <br />
                    {props.miscPproperties.documentType ? (
                        <span className='patient_info'>
                            {props.miscPproperties.documentType}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>
                <span className='col-lg-4'>
                    <span className='patient_label'>DOCUMENT ID</span>
                    <br />
                    {props.miscPproperties.docId ? (
                        <span className='patient_info'>
                            {props.miscPproperties.docId}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>
            </div>

            <div className='form-group patient_detail row'>
                <span className='col-lg-4'>
                    <span className='patient_label'>CREATED BY</span>
                    <br />
                    {props.miscPproperties.createdBy ? (
                        <span className='patient_info'>
                            {props.miscPproperties.createdBy}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>
                <span className='col-lg-4'>
                    <span className='patient_label'>DATE STORED</span>
                    <br />
                    {props.miscPproperties.createdBy ? (
                        <span className='patient_info'>
                            {formatDate(
                                props.miscPproperties.dateStored,
                                'MM/DD/YYYY'
                            )}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>

                <span className='col-lg-4'>
                    <span className='patient_label'>STATUS</span>
                    <br />
                    {props.miscPproperties.documentStatus ? (
                        <span className='patient_info'>
                            {props.miscPproperties.documentStatus}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>
            </div>
            <div className='form-group patient_detail row'>
                <span className='col-lg-4'>
                    <span className='patient_label'>SIGNATURE REQUIRED</span>
                    <br />
                    {props.signature_required ? (
                        <span className='patient_info'>
                            {props.signature_required}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>

                <span className='col-lg-8'>
                    <span className='patient_label'>Order ID</span>
                    <br />
                    {props.signature_required ? (
                        <span className='patient_info'>{props.order_id}</span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                </span>
            </div>
            {/* <div className='form-group patient_detail row'>                    
                    <span className='col-lg-4'>
                    <span className='patient_label'>
                        BATCH
                    </span>
                    <br />
                    {props.rowDetails.batch !== undefined ? (
                        <span className='patient_info'>
                             {props.rowDetails.batch.value}
                        </span>
                    ) : (
                        <span className='patient_info'> - </span>
                    )}
                    </span> */}
            {/* <span className='col-lg-4'>
                    <span className='patient_label'>
                        SERVICE
                    </span>
                        <br />
                        {props.rowDetails.serviceline !== undefined ? (
                            <span className='patient_info'>
                                {props.rowDetails.serviceline.value}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                        </span> */}
            {/* <span className='col-lg-4'>
                        <span className='patient_label'>INFO</span>
                        <br />
                        {props.rowDetails.faxdetail !== undefined ? (
                            <span className='patient_info'>
                                {props.rowDetails.faxdetail.value}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                        </span>
                </div> */}

            {/* <div className='form-group patient_detail row'>
                    <span className='col-lg-4'>
                        <span className='patient_label'>WORKFLOW STATUS</span>
                        <br />
                        {props.rowDetails.workflowstatus !== undefined ? (
                            <span className='patient_info'>
                                {props.rowDetails.workflowstatus.value}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                        </span>
                </div> */}
        </>
    );
};

export default DetailMoreInformation;
