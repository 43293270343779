/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    formatHeaderNameWithOutSpace,
    formatDate,
} from '../../../components/commonfunction';

export class DetailMoreInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowDetails: '',
            miscPproperties: {},
        };
    }

    componentDidMount() {
        const allFields = this.props.configData.fields;
        const rowDetails = this.props.selectedRowDetails.fields;
        const miscPproperties = this.props.selectedRowDetails.miscPproperties;
        const finalRowDetail = {};
        rowDetails.map((rowdetail) => {
            allFields.map((allfields) => {
                const temp = { ...rowdetail };
                if (rowdetail.fieldId === allfields.fieldId) {
                    temp.fieldName = allfields.name;
                    const removeSpace = formatHeaderNameWithOutSpace(
                        allfields.name
                    );
                    finalRowDetail[removeSpace] = temp;
                }
            });
        });
        this.setState({ rowDetails: finalRowDetail });
        this.setState({ miscPproperties: miscPproperties });
    }

    render() {
        return (
            <>
                <div className='form-group patient_detail row'>
                    <span className='col-lg-4'>
                        <span className='patient_label'>DOCUMENT DATE</span>
                        <br />
                        {this.state.miscPproperties.hasOwnProperty(
                            'documentDate'
                        ) ? (
                            <span className='patient_info'>
                                {formatDate(
                                    this.state.miscPproperties.documentDate,
                                    'MM/DD/YYYY'
                                )}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                    <span className='col-lg-4'>
                        <span className='patient_label'>DOCUMENT TYPE</span>
                        <br />
                        {this.state.miscPproperties.hasOwnProperty(
                            'documentType'
                        ) ? (
                            <span className='patient_info'>
                                {this.state.miscPproperties.documentType}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                    <span className='col-lg-4'>
                        <span className='patient_label'>DOCUMENT ID</span>
                        <br />
                        {this.state.miscPproperties.hasOwnProperty('docId') ? (
                            <span className='patient_info'>
                                {this.state.miscPproperties.docId}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                </div>

                <div className='form-group patient_detail row'>
                    <span className='col-lg-4'>
                        <span className='patient_label'>CREATED BY</span>
                        <br />
                        {this.state.miscPproperties.hasOwnProperty(
                            'createdBy'
                        ) ? (
                            <span className='patient_info'>
                                {this.state.miscPproperties.createdBy}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>

                    <span className='col-lg-4'>
                        <span className='patient_label'>BATCH</span>
                        <br />
                        {this.state.rowDetails.batch !== undefined ? (
                            <span className='patient_info'>
                                {this.state.rowDetails.batch.value}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                    <span className='col-lg-4'>
                        <span className='patient_label'>SERVICE</span>
                        <br />
                        {this.state.rowDetails.serviceline !== undefined ? (
                            <span className='patient_info'>
                                {this.state.rowDetails.serviceline.value}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                </div>
                <div className='form-group patient_detail row'>
                    <span className='col-lg-4'>
                        <span className='patient_label'>DATE STORED</span>
                        <br />
                        {this.state.miscPproperties.hasOwnProperty(
                            'createdBy'
                        ) ? (
                            <span className='patient_info'>
                                {formatDate(
                                    this.state.miscPproperties.dateStored,
                                    'MM/DD/YYYY'
                                )}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>

                    <span className='col-lg-4'>
                        <span className='patient_label'>STATUS</span>
                        <br />
                        {this.state.miscPproperties.hasOwnProperty(
                            'documentStatus'
                        ) ? (
                            <span className='patient_info'>
                                {this.state.miscPproperties.documentStatus}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                    <span className='col-lg-4'>
                        <span className='patient_label'>INFO</span>
                        <br />
                        {this.state.rowDetails.faxdetail !== undefined ? (
                            <span className='patient_info'>
                                {this.state.rowDetails.faxdetail.value}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                </div>

                <div className='form-group patient_detail row'>
                    <span className='col-lg-4'>
                        <span className='patient_label'>WORKFLOW STATUS</span>
                        <br />
                        {this.state.rowDetails.workflowstatus !== undefined ? (
                            <span className='patient_info'>
                                {this.state.rowDetails.workflowstatus.value}
                            </span>
                        ) : (
                            <span className='patient_info'> - </span>
                        )}
                    </span>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DetailMoreInformation));
