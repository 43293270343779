import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { toast } from 'react-toastify';
import { Routes } from './Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default function App({ store, persistor, basename }) {
    let isPreventingLogouts = false;

    if (
        process.env.REACT_APP_IS_DEVELOPER === 'true' ||
        process.env.REACT_APP_IS_DEVELOPER === true
    ) {
        isPreventingLogouts = true;
    }

    if (isPreventingLogouts) {
        console.info(
            'DEV MODE: If you see this, then logouts on reload are disabled!'
        );
    }

    console.log('isPreventingLogouts', isPreventingLogouts);

    return (
        /* Provide Redux store */
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen />}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename}>
                        {/* This library only returns the location that has been active before the recent location change in the current window lifetime. */}
                        <MaterialThemeProvider>
                            {/* Provide `react-intl` context synchronized with Redux state.  */}
                            <I18nProvider>
                                {/* Render routes with provided `Layout`. */}
                                <Routes
                                    isPreventingLogouts={isPreventingLogouts}
                                />
                            </I18nProvider>
                        </MaterialThemeProvider>
                    </BrowserRouter>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}
