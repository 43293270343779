import React, { useEffect } from 'react';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import { Layout } from '../_metronic/layout';
import SuccessGmailRedirectPage from './components/composeDraftMail/SuccessGmailRedirectPage';
import { useIdleTimer } from 'react-idle-timer/dist/modern';
import * as moment from 'moment-timezone';
import { logOut } from '../app/pages/_redux/actions/configActions';
import { useDispatch, useSelector } from 'react-redux';
import { unlockAllDocumentInStorage } from './components/commonfunction';
import { clearLocalStorage } from './pages/api/api';

export const Routes = ({ isPreventingLogouts }) => {
    const selector = useSelector((state) => state);

    const current_time = moment().format('DD/MM/YYYY HH:mm:ss');

    const last_active = localStorage.getItem('idle_start');

    const startTime = moment(last_active, 'DD/MM/YYYY HH:mm:ss');
    const endTime = moment(current_time, 'DD/MM/YYYY HH:mm:ss');
    const minutesDiff = endTime.diff(startTime, 'minutes');

    const history = useHistory();
    const logoutDispatch = useDispatch();

    const handleOnIdle = async () => logout();

    const handleOnAction = () => {
        const mTimestamp1 = moment().format('DD/MM/YYYY HH:mm:ss');
        localStorage.setItem('idle_start', mTimestamp1);
    };

    useIdleTimer({
        timeout: 1000 * 60 * process.env.REACT_APP_SESSION_TIMEOUT,
        onIdle: handleOnIdle,
        onActive: () => {},
        onAction: handleOnAction,
        debounce: 250,
    });

    const API_URL = process.env.REACT_APP_EXTERNAL_API_URL;

    const logout = async () => {
        const reponse = unlockAllDocumentInStorage();
        reponse.then((data) => {});
        await fetch(`${API_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
        }).then((response) => {
            clearLocalStorage();
            logoutDispatch(logOut(1));
            history.push('/auth/login');
        });
    };

    const logoutTabClose = () => {
        const reponse = unlockAllDocumentInStorage();
        reponse.then((data) => {});
        fetch(`${API_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
            },
            keepalive: true,
        }).then((response) => {});
        clearLocalStorage();
        logoutDispatch(logOut(1));
        history.push('/auth/login');
    };

    if (minutesDiff > 10) {
        logout();
    }

    useEffect(() => {
        const messageListener = window.addEventListener('message', (event) => {
            if (event.data.out && event.data.out === 'Logout') {
                clearLocalStorage();
                logoutDispatch(logOut(1));
                history.push('/auth/login');
            } else if (event.data.out && event.data.out === 'Logout_Manual') {
                logout();
            }
        });
        return () => {
            window.removeEventListener('message', messageListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selector.auth.user && selector.auth.user === undefined) {
            clearLocalStorage();
            history.push('/auth/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selector.auth]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (localStorage.getItem('valid_user') === 'true') {
                e.preventDefault();
                e.returnValue = 'Are you sure you want to log out?';
            }
        };
        const handleUnload = (e) => {
            if (
                localStorage.getItem('valid_user') === 'true' &&
                e.type !== 'reload'
            ) {
                logoutTabClose();
            }
        };
        const handleKeyDown = (e) => {
            const key = e.keyCode ? e.keyCode : e.which;
            if (
                key === 116 ||
                (key === 17 && e.key !== 'r') ||
                (key === 82 && e.ctrlKey === true) ||
                (key === 82 && e.metaKey)
            ) {
                e.preventDefault();
            }
        };

        if (!isPreventingLogouts) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('unload', handleUnload);
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            if (!isPreventingLogouts) {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('unload', handleUnload);
            }

            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderAuthRoute = () => {
        if (localStorage.getItem('valid_user')) {
            return <Redirect from='/auth' to='/' />;
        } else {
            return (
                <Route>
                    <AuthPage />
                </Route>
            );
        }
    };

    return (
        <Switch>
            {renderAuthRoute()}
            <Route path='/mailSuccess' component={SuccessGmailRedirectPage} />
            <Route path='/error' component={ErrorsPage} />
            <Route path='/logout' component={Logout} />
            {localStorage.getItem('valid_user') ? (
                <Layout>
                    <BasePage />
                </Layout>
            ) : localStorage.getItem('hasPasswordExpired') ? (
                <Redirect to='/auth/change-password' />
            ) : (
                <Redirect to='/auth/login' />
            )}
        </Switch>
    );
};
