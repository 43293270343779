import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { showErrorToast, showSuccessToast } from '../../../../components/commonfunction';
import { uploadPhysiciansCsv } from '../../../api/api';
import validateCSVContent from '../utils/validateCSVContent';

interface ImportPhysicianModalProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleUploadResults: (results: any) => void;
}

const ImportPhysicianModal: React.FC<ImportPhysicianModalProps> = ({ showModal, setShowModal, handleUploadResults }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [uploadedFileInfo, setUploadedFileInfo] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClose = () => {
        setShowModal(false);
        setError(null);
        setFileUploaded(false);
    };

    const handleFileUpload = async (files: FileList | null) => {
        if (!files || files.length === 0) return;
        const file = files[0];
        if (!file || file.type !== 'text/csv') {
            setError('Only CSV files are allowed.');
            setFileUploaded(false);
        } else {
            const isValidCSV = await validateCSVContent(file);
            if (isValidCSV) {
                setError(null);
                setFileUploaded(true);
                setUploadedFileInfo(file);
                if (fileInputRef.current) {
                    fileInputRef.current.files = files;
                }
            } else {
                setError('The file content is not a valid CSV.');
                setFileUploaded(false);
            }
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.currentTarget.style.border = '2px dashed #cccccc';

        const files = event.dataTransfer.files;
        handleFileUpload(files);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.currentTarget.style.border = '2px dashed blue';
    };

    const handleDragOverLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.currentTarget.style.border = '2px dashed #cccccc';
    }

    const handleAddFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        handleFileUpload(files);
    };

    const handleImport = async () => {
        if (!fileInputRef.current || !fileInputRef.current.files || !fileUploaded) {
            showErrorToast('Please upload a CSV file to import.');
            return;
        }

        setIsLoading(true);
        try {
            console.log("fileInputRef.current.files[0]", fileInputRef.current)
            const response = await uploadPhysiciansCsv(fileInputRef.current.files[0]);
            if (response && response.loadResults && response.loadResults.resultsJson) {
                const resultJson = JSON.parse(response.loadResults.resultsJson);
                showSuccessToast('File uploaded successfully.');
                handleUploadResults(resultJson);
                handleClose();
            } else {
                showErrorToast('Error uploading file.');
            }

            setIsLoading(false);
        } catch (error: any) {
            showErrorToast(error?.message || 'Error uploading file.');
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Modal
                className='import-popup'
                show={showModal}
                onHide={handleClose}
                animation={false}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton className='d-block justify-content-start'>
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>Physician Bulk Import</div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleClose}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {error && <div className="text-danger">{error}</div>}
                        {fileUploaded &&
                            <>
                                <div className="text-success">File Uploaded Successfully</div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: "15px", marginTop: "5px" }}>
                                    <i className="fa-duotone fa-file-csv" style={{ fontSize: '32px', marginRight: "24px" }}></i>
                                    <div>
                                        <div style={{ color: 'black', fontWeight: 500 }}>{uploadedFileInfo?.name}</div>
                                        <div style={{ fontSize: "10px" }}>{(uploadedFileInfo?.size / (1024 * 1024)).toFixed(3)} MB</div>
                                    </div>
                                </div>
                            </>
                        }
                        <div
                            style={{
                                height: '250px',
                                border: '2px dashed #cccccc',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '20px',
                                position: 'relative',
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragOverLeave}
                        >
                            <input
                                type="file"
                                id="fileInput"
                                accept=".csv"
                                style={{ display: 'none' }}
                                onChange={handleInputChange}
                                ref={fileInputRef}
                            />
                            <i className="fa-solid fa-file-csv" style={{ fontSize: '24px', marginBottom: '8px' }}></i>
                            <label htmlFor="fileInput">
                                Drag & Drop or Click to Upload CSV File
                            </label>
                            <button
                                onClick={handleAddFileClick}
                                style={{
                                    background: '#007bff',
                                    color: '#fff',
                                    border: 'none',
                                    padding: '8px 16px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                Add File
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-block'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                title='Cancel'
                                variant='secondary'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                title='Import'
                                variant='success'
                                onClick={handleImport}
                                disabled={isLoading || !!error || !fileUploaded}
                            >
                                {isLoading ? (
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                ) : (
                                    <span>Import</span>
                                )}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ImportPhysicianModal;