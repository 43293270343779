import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { deletedPage } from '../_redux/actions/searchActions';
import {
    reportColorCodeArray,
    getRandomColors,
    sortObjectByKeys,
    LightenDarkenColor,
} from '../../components/commonfunction';

const _ = require('lodash');

function Tile(props) {
    const [daysoutstanding, setDaysoutstanding] = React.useState([]);
    const [outboundstatus, setOutboundstatus] = React.useState([]);
    const [colorCode] = React.useState(reportColorCodeArray);
    const [agingcount, setAgingcount] = React.useState('');
    const [statuscount, setStatuscount] = React.useState('');
    const [windowSize, setWindowSize] = React.useState(getWindowSize());
    // const redux_store = useSelector((state) => state);
    // const outboxData = redux_store.outbox.outbox;
    // const outbox_page = redux_store.outbox.outboxpage;

    const dispatch = useDispatch();
    const filteredData = props.filteredData;
    let allRowData = props.rows;
    const graphicalField = props.graphicalField;

    useEffect(() => {
        for (const property in outboundstatus) {
            if (
                props.propertyTiles === property &&
                outboundstatus[property].length !== props.totalCount
            ) {
                // eslint-disable-next-line no-use-before-define
                openTile(outboundstatus[property], property);
            }
        }
        for (const property in daysoutstanding) {
            if (
                props.propertyTiles === property &&
                daysoutstanding[property].length !== props.totalCount
            ) {
                // if(props.filterTxt)
                for (const property in outboundstatus) {
                    if (props.propertyTiles === property) {
                        // eslint-disable-next-line no-use-before-define
                        openTile(outboundstatus[property], property);
                    }
                }
            }
        }

        // if (props.forCountZero === true) {

        //     for (const property in outboundstatus) {
        //         if (props.propertyTiles === property) {
        //             openTile(outboundstatus[property], property);
        //         }
        //     }
        //     props.updateState({
        //         forCountZero: false,
        //     });
        // }

        if (Array.isArray(filteredData) && filteredData.length > 0) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            allRowData = filteredData;
        }

        /* if (!outbox_page.hasOwnProperty('dataRows')) {
            const temp = { ...outboxData.dataTable };
            const initial = outbox_page.allRowData;
            dispatch(outboxPage({ ...temp, initialData: initial }));
        } */
        const arr1 = [];
        const arr2 = [];
        const arr3 = [];
        const arr4 = [];
        const arr5 = [];
        const arr6 = [];
        const ageing = [arr1, arr2, arr3, arr4, arr5, arr6];
        let agingcounting = 0;

        if (graphicalField === 'daysoutstanding') {
            // eslint-disable-next-line array-callback-return
            allRowData.map((data) => {
                if (data[graphicalField] >= 0 && data[graphicalField] <= 14)
                    arr1.push(data);
                else if (
                    data[graphicalField] >= 15 &&
                    data[graphicalField] <= 30
                )
                    arr2.push(data);
                else if (
                    data[graphicalField] >= 31 &&
                    data[graphicalField] <= 50
                )
                    arr3.push(data);
                else if (
                    data[graphicalField] >= 51 &&
                    data[graphicalField] <= 75
                )
                    arr4.push(data);
                else if (
                    data[graphicalField] >= 76 &&
                    data[graphicalField] <= 119
                )
                    // arr5.push(data.daysoutstanding);
                    arr5.push(data);
                else if (data[graphicalField] >= 120) arr6.push(data);
                agingcounting++;
            });
            setAgingcount(agingcounting);
            setDaysoutstanding(ageing);
        }
        let statuscounting = 0;
        let del;

        const result = allRowData.reduce(function (r, a, c) {
            statuscounting++;

            del = a[graphicalField];

            if (del === '') del = '(Empty)';
            r[del] = r[del] || [];
            // r['testing'] = r['testing'] || [];
            r[del].push(a);
            // if (c < 20) {
            //     r['testing'].push(a);
            // }
            return r;
        }, Object.create(null));

        const finalresult = sortObjectByKeys(result);
        setStatuscount(statuscounting);
        setOutboundstatus(finalresult);
        // setOutboundstatus(result);
        setWindowSize(getWindowSize());
        setTimeout(() => {
            if (document.getElementById('Statistic-part')) {
                document.getElementById('Statistic-part').style.width =
                    windowSize.innerWidth;
            }
        }, 6000);

        return () => {};
    }, [filteredData, props.forCountZero === true, props.totalCount]);
    const subTitleFunction = (colour, bgColour) => {
        props.updateState({
            colourSub: colour,
            bgColourSub: bgColour,
        });
    };
    const openTile = async (data, state) => {
        await props.removeAllGrouping();
        const dataTable = [];
        dataTable.dataRows = data;

        dataTable.headerColumns = props.headerColumns;

        let paginatedRowData;
        const startIndex = 0;
        const endIndex = startIndex + parseInt(props.perPage, 10);
        // eslint-disable-next-line prefer-const
        paginatedRowData = _.slice(data, startIndex, endIndex); // Pagination

        await props.updateState({
            onlyGridloader: true,
            allRows: data,
            // duplicateAllrows: props.filteredData,
            grpTilesData: data,
            grpTiles: true,
            totalCount: data.length,
            currentPage: 0,
            perPage: 10,
            filteredRowData: paginatedRowData,
            rowData: paginatedRowData,
            formName: 'Tiles',
            refreshDataGrid: false,
            propertyTiles: state,
            tileFilter: true,
        });
        props.resetPopOverData();
        dispatch(deletedPage({ propertyTiles: state }));
    };

    const byAgeingBottomBar = () => {
        const html = [];
        let id_index = 0;
        let percen = 0;

        // eslint-disable-next-line guard-for-in, no-use-before-define
        const randomcolorsArray = getRandomColorCodeArray(6);
        let title = '';
        const ageRange = ['0-14', '15-30', '31-50', '51-75', '76-119', '120+'];
        for (const property in daysoutstanding) {
            id_index = parseInt(property, 10);
            percen = (daysoutstanding[property].length / agingcount) * 100;
            title = `${ageRange[id_index]} days`;

            let bgColor = randomcolorsArray[id_index];
            var NewColor = '';

            if (randomcolorsArray[id_index] === undefined) {
                const newindex = id_index % 12;
                const index_count = Math.floor(id_index / 12);

                NewColor = randomcolorsArray[newindex];
                bgColor = LightenDarkenColor(NewColor, 50 * index_count);
            }

            if (daysoutstanding[property].length > 0) {
                html.push(
                    <div
                        key={property}
                        title={title}
                        className='status'
                        style={{
                            width: `${percen}%`,
                            background: bgColor,
                        }}
                    />
                );
            }
        }
        return html;
    };

    const byStatusBottomBar = () => {
        const html = [];
        let id_index = 0;
        let percen = 0;

        // eslint-disable-next-line no-use-before-define
        const randomcolorsArray = getRandomColorCodeArray(0);

        for (const property in outboundstatus) {
            percen = (outboundstatus[property].length / statuscount) * 100;
            if (outboundstatus[property].length > 0) {
                let bgColor = randomcolorsArray[id_index];
                var NewColor = '';

                if (randomcolorsArray[id_index] === undefined) {
                    const newindex = id_index % 12;
                    const index_count = Math.floor(id_index / 12);
                    NewColor = randomcolorsArray[newindex];
                    bgColor = LightenDarkenColor(NewColor, 50 * index_count);
                }

                html.push(
                    <div
                        key={property}
                        title={property}
                        className='status'
                        style={{
                            width: `${percen}%`,
                            background: bgColor,
                        }}
                    />
                );
            }
            id_index++;
        }
        return html;
    };
    const getRandomColorCodeArray = (resultCount) => {
        let coun = 0;
        // eslint-disable-next-line eqeqeq
        if (resultCount != 0) {
            coun = resultCount;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const property in outboundstatus) {
                coun++;
            }
        }

        if (coun >= 12) {
            coun = 12;
        }
        const originalColorCount = colorCode.length;
        const totalColorCount = Math.floor(originalColorCount / coun);
        const randomcolors = getRandomColors(colorCode, totalColorCount);

        return randomcolors;
    };
    const byStatus = () => {
        let idx = 0;
        const html = [];

        html.push(
            // eslint-disable-next-line react/jsx-curly-brace-presence
            <React.Fragment key={'all_status'}>
                <div
                    className={
                        props.propertyTiles === 'All Data'
                            ? 'common_block outstanding active'
                            : 'common_block outstanding'
                    }
                    onClick={() => {
                        openTile(props.filteredData, 'All Data');
                        subTitleFunction('#0f0f12', '#F2F4F5');
                    }}
                    style={
                        props.propertyTiles === 'All Data'
                            ? {
                                  background: '#F2F4F5',
                              }
                            : null
                    }
                >
                    <span
                        className='bd-color-all'
                        // style={{
                        //     background: '#F2F4F5',
                        // }}
                    />
                    <span>{statuscount}</span>
                    <h6 className=''>All Data</h6>
                </div>
            </React.Fragment>
        );

        for (const property in outboundstatus) {
            const randomcolorsArray = getRandomColorCodeArray(0);
            //const hex = randomcolorsArray[idx];

            let hex = randomcolorsArray[idx];
            var NewColor = '';

            if (randomcolorsArray[idx] === undefined) {
                const newindex = idx % 12;
                const index_count = Math.floor(idx / 12);
                NewColor = randomcolorsArray[newindex];
                hex = LightenDarkenColor(NewColor, 50 * index_count);
            }

            const red = parseInt(hex[1] + hex[2], 16);
            const green = parseInt(hex[3] + hex[4], 16);
            const blue = parseInt(hex[5] + hex[6], 16);
            const backgroundColor = `rgb(${red}, ${green}, ${blue},.1)`;

            html.push(
                <React.Fragment key={property}>
                    <div
                        className={
                            props.propertyTiles === property
                                ? 'common_block outstanding active'
                                : 'common_block outstanding'
                        }
                        onClick={() => {
                            openTile(outboundstatus[property], property);
                            subTitleFunction(hex, backgroundColor);
                        }}
                        style={
                            props.propertyTiles === property
                                ? { background: '#F2F4F5' }
                                : null
                        }
                    >
                        <span
                            className='bd-color'
                            style={{
                                background: hex,
                            }}
                        />
                        <span>{outboundstatus[property].length}</span>
                        <h6 className=''>{property}</h6>
                    </div>
                </React.Fragment>
            );
            idx++;
        }

        return html;
    };
    const byAgeing = () => {
        let id_index = 0;
        const html = [];
        const ageRange = ['0-14', '15-30', '31-50', '51-75', '76-119', '120+'];
        html.push(
            <React.Fragment key='all_status'>
                <div
                    className={
                        props.propertyTiles === 'All Data'
                            ? 'common_block outstanding active'
                            : 'common_block outstanding'
                    }
                    style={
                        props.propertyTiles === 'All Data'
                            ? {
                                  background: '#F2F4F5',
                              }
                            : null
                    }
                    onClick={() => {
                        openTile(props.filteredData, 'All Data');
                        subTitleFunction('#0f0f12', '#F2F4F5');
                    }}
                >
                    <span className='bd-color-all' />
                    <span>{agingcount}</span>
                    <h6 className=''>All Data</h6>
                </div>
            </React.Fragment>
        );

        for (const property in daysoutstanding) {
            const randomcolorsArray = getRandomColorCodeArray(6);
            id_index = parseInt(property, 10);
            const hex = randomcolorsArray[id_index];
            const red = parseInt(hex[1] + hex[2], 16);
            const green = parseInt(hex[3] + hex[4], 16);
            const blue = parseInt(hex[5] + hex[6], 16);
            const backgroundColor = `rgb(${red}, ${green}, ${blue},.1)`;

            html.push(
                <React.Fragment key={property}>
                    <div
                        className={
                            props.propertyTiles === `${ageRange[property]} days`
                                ? 'common_block outstanding active'
                                : 'common_block outstanding'
                        }
                        style={
                            props.propertyTiles === `${ageRange[property]} days`
                                ? { background: '#F2F4F5' }
                                : null
                        }
                        onClick={() => {
                            openTile(
                                daysoutstanding[property],
                                `${ageRange[property]} days`
                            );
                            subTitleFunction(hex, backgroundColor);
                        }}
                    >
                        <span
                            className='bd-color'
                            style={{
                                background: randomcolorsArray[id_index],
                            }}
                        />
                        <span>{daysoutstanding[property].length}</span>
                        <h6 className=''>{ageRange[id_index]} days</h6>
                    </div>
                </React.Fragment>
            );
        }
        return html;
    };
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    return (
        <>
            <div
                id='Statistic-part'
                className='wrap_details wrap_note p-4 Statistic-part ml-7 mr-7'
                style={{
                    maxWidth:
                        windowSize.innerWidth > 1000
                            ? windowSize.innerWidth - 400
                            : windowSize.innerWidth - 50,
                }}
            >
                <div className=' justify-content-between mb-5 statistic-top-sec'>
                    <>
                        {graphicalField === 'daysoutstanding' ? (
                            <div>
                                <div className='statastic_outbox st-outbox d-flex mb-5'>
                                    {byAgeing()}
                                </div>
                                <div className='status_outbox mb-5'>
                                    {byAgeingBottomBar()}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div
                                    style={{ overflow: 'auto' }}
                                    className='statastic_outbox st-outbox d-flex mb-5'
                                >
                                    {byStatus()}
                                </div>
                                <div
                                    style={{ overflow: 'auto' }}
                                    className='status_outbox mb-5'
                                >
                                    {byStatusBottomBar()}
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </div>
        </>
    );
}

export { Tile };
