/* eslint-disable array-callback-return */
import React from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import Nav from 'react-bootstrap/Nav';
import {
    outboxDocumentAction,
    createNewPatient,
    lockDocument,
    unlockDocument,
    recordSetSearch,
} from '../../api/api';
import {
    GetFieldData,
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    showSuccessToast,
    showErrorToast,
    formatDate,
    faxCellFormatter,
    normalErrorToast,
    stringEmptyOrUndefined,
    getDropDownDataFromApi,
    generatePatientId,
    BranchIdSearch,
    encodeParameter,
} from '../../../components/commonfunction';

import {
    getListaction,
    setSelectedRow,
    getRulesJson,
    showLoader,
    hideBigView,
    getSelectedRowDetails,
    outboxPage,
} from '../../_redux/actions/outboxActions';
import { getDDLlist } from '../../_redux/actions/configActions';
import ByPatient from '../../../components/patient/ByPatient';
import ByPhysician from '../../../components/physician/ByPhysician';
import CreateNewPatient from '../../../components/patient/CreateNewPatient';
import ViewEditPatient from '../../../components/patient/ViewEditPatient';
import ViewEditPhysician from '../../../components/physician/ViewEditPhysician';
import CoverPageModal from './CoverPageModal';
import DetailMoreInformation from './DetailMoreInformation';
import Recipient from '../../../components/physician/Recipient';
export class DetailSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open_edit_details: this.props.open_edit_details,
            isLocked: this.props.isLocked,
            isDetailBigView: this.props.isDetailBigView,
            calingFrom: this.props.calingFrom,
            validateEdit: false,
            submit_normal_create_patient: false,
            allActions:
                this.props.deleteDocument === 'deleted_document'
                    ? this.props.allActions
                    : this.props.outbox.stateData.allActions,
            edit_or_create: 'edit',
            // ...this.props.state
            clearData: true,
            patient_filter: { patientname: '' },
            physician_first_name: '',
            physician_last_name: '',
            branch: '',
            view: 'initial_view',
            checked: '',
            more_information: false,
            unsavePhysician: '',
            unsavePatient: '',
            selectedPhysician: {},
            patientValidation: false,
            editPatientDataDetail: '',
            create_physician: false,
            disableBtn: false,
            signature_required: '',
            order_id: '',
            patientLink: false,
            physicianLink: false,
            byPatientRender: true,
            currentLookup_Rec: 'Physician',
            recipientData: '',
            initial_Rec: '',
            physician_filter: '',
            physicianData_fax: {},
            callingsource: this.props.callingsource,
        };
        this.initialStateSetting = this.initialStateSetting.bind(this);
    }

    async initialStateSetting() {
        const fieldDataObj = await GetFieldData({
            configData: this.props.configData,
        });

        await this.setState({ allFieldIds: fieldDataObj });
        let patient_filter_temp = [];
        let physician_temp = [];
        let physicainCheck = this.props.outbox.selectedRowDetails.fields.some(
            (obj) => obj.fieldId === fieldDataObj.npi
        );
        if (this.props.calingFrom !== 'upload') {
            if (physicainCheck === true) {
                await this.setState({
                    currentLookup_Rec: 'Physician',
                    initial_Rec: 'Physician',
                });
            } else {
                await this.setState({
                    currentLookup_Rec: 'Other Recipient',
                    initial_Rec: 'Other Recipient',
                });
                let recipientData = {};
                await this.props.outbox.selectedRowDetails.fields.map(
                    async (rowData) => {
                        for (const property in fieldDataObj) {
                            if (
                                property === 'fax_number' ||
                                property === 'ordering_physician' ||
                                property === 'branch'
                            ) {
                                const fields = rowData.fieldId;
                                if (
                                    String(fields) ===
                                    String(fieldDataObj[property])
                                ) {
                                    recipientData = {
                                        ...recipientData,
                                        [property]: rowData.value,
                                    };
                                }
                            }
                        }
                    }
                );

                await this.setState({
                    recipientData: { ...recipientData },
                });
            }
        }
        if (this.props.callingFrom === 'upload') {
            this.props.setCurrentLookup_Rec('Physician');
        }

        await this.props.outbox.selectedRowDetails.fields.map(
            async (rowData) => {
                const fields = rowData.fieldId;

                if (
                    String(fields) === String(fieldDataObj.signature_required)
                ) {
                    await this.setState({
                        signature_required: rowData.value,
                    });
                }
                if (String(fields) === String(fieldDataObj.order_id)) {
                    await this.setState({
                        order_id: rowData.value,
                    });
                }
                if (String(fields) === String(fieldDataObj.order_number)) {
                    await this.setState({
                        order_number: rowData.value,
                        clear_order_number: rowData.value,
                    });
                } else if (String(fields) === String(fieldDataObj.order_date)) {
                    await this.setState({
                        order_date: new Date(rowData.value),
                        clear_order_date: new Date(rowData.value),

                        // datestring:rowData.value.getDate()  + "-" + (rowData.value.getMonth()+1) + "-" + rowData.value.getFullYear() + " " +rowData.value.getHours() + ":" + rowData.value.getMinutes()
                    });
                } else if (
                    String(fields) === String(fieldDataObj.emr_order_type)
                ) {
                    await this.setState({
                        order_type: rowData.value,
                        clear_order_type: rowData.value,
                    });
                } else if (String(fields) === String(fieldDataObj.branch)) {
                    await this.setState({
                        branch: rowData.value,
                        clear_branch: rowData.value,
                    });
                } else if (
                    String(fields) === String(fieldDataObj.patient_name)
                ) {
                    await this.setState({
                        patient_filter_display: rowData.value,
                    });
                }
                // "var patient_filter = {};

                for (const property in fieldDataObj) {
                    if (
                        property === 'patient_name' ||
                        property === 'gender' ||
                        property === 'date_of_birth' ||
                        property === 'branch' ||
                        property === 'service_line' ||
                        property === 'patient_id'
                        // value === 'episodeid'
                    ) {
                        if (String(fields) === String(fieldDataObj[property])) {
                            patient_filter_temp = {
                                ...patient_filter_temp,
                                [property]: rowData.value,
                            };

                            if (
                                patient_filter_temp !== undefined &&
                                Object.keys(patient_filter_temp).length > 1
                            ) {
                                await this.setState({
                                    patient_filter: { ...patient_filter_temp },
                                    clear_patient_filter: {
                                        ...patient_filter_temp,
                                    },
                                    view_patient: true,
                                    edit_patient: false,
                                    patientIsNegative: false,
                                });
                                if (property === 'branch') {
                                    await this.setState({
                                        recipientData: {
                                            ...this.state.recipientData,
                                            branch: rowData.value,
                                        },
                                    });
                                }
                            }
                        }
                    }
                }
                if (Object.keys(patient_filter_temp).length < 1) {
                    await this.setState({
                        patient_filter: {
                            patient_name: '',
                        },
                    });
                }

                for (const property in fieldDataObj) {
                    if (
                        property === 'npi' ||
                        property === 'fax_number' ||
                        property === 'phone_number' ||
                        property === 'email_address' ||
                        property === 'ordering_physician' ||
                        property === 'physician_first_name' ||
                        property === 'physician_last_name' ||
                        property === 'delivery_method' ||
                        property === 'id'
                    ) {
                        if (String(fields) === String(fieldDataObj[property])) {
                            physician_temp = {
                                ...physician_temp,
                                [property]: rowData.value,
                            };

                            await this.setState({
                                selectedPhysician: { ...physician_temp },
                                clear_selectedPhysician: { ...physician_temp },
                            });
                        }
                    }
                }

                if (
                    String(fields) === String(fieldDataObj.ordering_physician)
                ) {
                    const nameArray = rowData.value.split(' ');

                    if (
                        nameArray[0] === 'Dr ' ||
                        nameArray[0] === 'Dr' ||
                        nameArray[0] === 'DR ' ||
                        nameArray[0] === 'DR'
                    ) {
                        await this.setState({
                            physician_filter: `${nameArray[2]}, ${nameArray[1]}`,
                            physician_first_name: nameArray[1],
                            physician_last_name: nameArray[2],
                            edit_physician: false,
                            view_physician: true,
                            clear_physician_filter: `${nameArray[2]}, ${nameArray[1]}`,
                        });
                    } else {
                        await this.setState({
                            physician_filter: rowData.value,
                            physician_first_name: nameArray[1],
                            physician_last_name: nameArray[0].replace(',', ''),
                            edit_physician: false,
                            view_physician: true,
                            clear_physician_filter: rowData.value,
                        });
                    }

                    if (this.props.callingsource === 'FaxModal') {
                        console.log('callingsource is FaxModal');
                        await this.setState({
                            selectedPhysician: '',
                            physician_filter: '',
                            physician_first_name: '',
                            physician_last_name: '',
                        });
                    }
                }

                return '';
            }
        );
        if (this.props.calingFrom === 'upload') {
            if (this.props.editPhysician !== this.state.edit_physician) {
                await this.props.checkingEdit_Physician(
                    this.state.edit_physician
                );
            }
        }
        await this.setState({
            initialRun: true,
        });
    }
    async componentDidMount() {
        await this.getTransitonDropDownData();
        if (this.props.outbox.selectedRowDetails.fields !== undefined) {
            await this.initialStateSetting();
        } else {
        }
        // if (
        //     this.props.outbox.stateData !== undefined &&
        //     this.props.outbox.stateData.allActions !== undefined &&
        //     Object.keys(this.props.outbox.stateData.allActions).length === 0
        // ) {
        //     this.setState({
        //         allActions: this.props.allActions,
        //     });
        // }
        // if (this.props.open_edit_details !== undefined) {

        // this.setState({ open_edit_details: this.props.open_edit_details });
        // }
        if (this.props.open_edit_details === undefined) {
            await this.setState({ open_edit_details: false });
            await this.props.updateState({
                open_edit_details: false,
            });
        }
        // if(this.props.open_edit_details === true){
        //     this.setState({
        //        view: 'initial_view'
        //     })
        // }

        const fieldDataObj = await GetFieldData({
            configData: this.props.configData,
        });

        await this.setState({ allFieldIds: fieldDataObj });

        let configuredRecordsets = [];
        let patientFieldIdArray;
        let modifyPatientFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;
        let physicianFieldIdArray;
        let physicianRecordsetId;
        let physicianModifiableRecordsetId;
        // var episodeRecordsetId, episodevisitRecordsetId;
        // var modifyPhysicianFieldIdArray, modifyPhysicianRecordsetId, modifyPhysicianModifiableRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;
        const allRecordSetID = {};
        if (configuredRecordsets !== undefined) {
            await configuredRecordsets.map(async (data) => {
                if (formatHeaderNameWithOutSpace(data.name) === 'patientdata') {
                    patientFieldIdArray = data.fields;
                    patientRecordsetId = data.recordsetId;
                    patientModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    modifyPatientFieldIdArray = data.fields;
                    modifyPatientRecordsetId = data.recordsetId;
                    modifyPatientModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectory'
                ) {
                    physicianFieldIdArray = data.fields;
                    physicianRecordsetId = data.recordsetId;
                    physicianModifiableRecordsetId = data.modifiableRecordsetId;
                }

                const fieldIDArray = {};
                if (data.hasOwnProperty('fields') && data.fields.length > 0) {
                    data.fields.map((field, index) => {
                        fieldIDArray[
                            formatNameWithSpaceToUnderscore(field.name)
                        ] = field;
                        return field;
                    });
                    data.fields_obj = fieldIDArray;
                }
                const name = formatNameWithSpaceToUnderscore(data.name);
                allRecordSetID[name] = data;
                return data;
            });
        }
        await this.setState({ allRecordSetID: allRecordSetID });
        await this.setState({
            patientModifiableRecordsetId: patientModifiableRecordsetId,
            patientFieldIdArray: patientFieldIdArray,
            patientRecordsetId: patientRecordsetId,
            modifyPatientFieldIdArray: modifyPatientFieldIdArray,
            modifyPatientRecordsetId: modifyPatientRecordsetId,
            modifyPatientModifiableRecordsetId:
                modifyPatientModifiableRecordsetId,
            physicianFieldIdArray: physicianFieldIdArray,
            physicianRecordsetId: physicianRecordsetId,
            physicianModifiableRecordsetId: physicianModifiableRecordsetId,
            edit_details: false,
        });
        if (this.props.ddl.hasOwnProperty('emrordertype')) {
            await this.setState({
                emr_order_typeDDL: this.props.ddl.emrordertype,
            });
        }

        await this.setState({
            branchOption: this.props.ddl.branch,
        });

        // patient
        // let fieldData = GetFieldData({ configData: this.props.configData });
        // this.setState({ attachmentTypeDDL: this.props.ddl.attachmenttype });
        // this.setState({ newReferralDDL: this.props.ddl.newreferral });

        if (this.props.editDetails === true) {
            await this.setState({
                edit_details: true,
            });
        }

        // this.setState({editId:'/documents/edit/865'})

        const rulesJson = this.props.rulesJson;
        // eslint-disable-next-line guard-for-in
        for (let rules in rulesJson) {
            let rulesTemp = rules;
            rulesTemp = rulesTemp.replace('(', '');
            rulesTemp = rulesTemp.replace(')', '');
            const upperCaseWords = rulesTemp.match(
                /(\b[A-Z][A-Z]+|\b[A-Z]\b)/g
            );
            const firstWord = rulesTemp.split(' ')[0];
            const array = rulesJson[rules];
            rules = `${firstWord}-${upperCaseWords[0]}`;
            // var finalRules = {};
            // finalRules[rules]=array;
            this.state.rulesJson[rules] = array;
        }

        const pathName = window.location.pathname.split('/');
        await this.setState({
            currentURL: pathName[2],
        });
        if (
            this.props.deleteDocument === 'deleted_document' &&
            this.props.editDetailsOutbox === true
        ) {
            await this.setState({
                open_edit_details: this.props.editDetailsOutbox,
            });
        }
        if (this.props.reportView === 'report') {
            await this.setState({
                allActions: {},
            });
        }
    }
    componentDidUpdate() {
        if (this.props.calingFrom === 'upload') {
            if (this.props.editPhysician !== this.state.edit_physician) {
                this.props.checkingEdit_Physician(this.state.edit_physician);
            }
        }
    }
    getTransitonDropDownData = () => {
        const fieldData = GetFieldData({ configData: this.props.configData });

        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('emrordertype')) {
                getDropDownDataFromApi(fieldData.emr_order_type).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
        }
    };

    addEditDetails = () => {
        this.setState({
            open_edit_details: true,
            view: 'initial_view',
            edit_or_create: 'edit',
            // edit_physician: false,
            // view_physician: true,
        });
        this.props.updateState({
            open_edit_details: true,

            // edit_physician: false,
            // view_physician: true,
        });
    };

    functionPhsicianData = (value) => {
        this.setState({
            unsavePhysician: value,
        });
    };

    functionPatientData = (value) => {
        this.setState({
            unsavePatient: value,
        });
    };

    clearAll = () => {
        this.setState({
            clearData: false,
            order_number: '',
            order_date: '',
            order_type: '',
            patient_filter: {
                patient_name: '',
            },
            selectedPhysician: '',
            physician_filter: '',
            patient_filter_display: '',
            checked: '',
            patientValidation: false,
            validateOrderDate: false,
            validateOrderNumber: false,
            validateOrderType: false,
            faxValidation: false,
            phoneValidation: false,
            npiValidation: false,
            view_physician: false,
            view_patient: false,
            open_create_patient: false,
            edit_patient: false,
            patientLink: false,
            physicianLink: false,
            currentLookup_Rec: 'Physician',
            recipientData: {},
            callingsource: '',
        });
        setTimeout(() => {
            this.setState({ clearData: true });
        }, 2000);

        /* this.setState(
            {
                formData: {},
                patient_filter: {},
                edit_details: false,
                open_edit_details: false,
            },
            () => {
                this.setState({ edit_details: true });
            }
        );
        this.props.updateState({
            open_edit_details: true,
            edit_details: true,
        }); */
    };

    openMore = () => {
        this.setState({ more_information: !this.state.more_information });
    };

    byPhysician = async () => {
        await this.setState({
            currentLookup_Rec: 'Physician',
            selectedPhysician: '',
            physician_filter: '',
            physician_first_name: '',
            physician_last_name: '',
        });

        if (this.props.calingFrom === 'upload') {
            this.props.setCurrentLookup_Rec('Physician');
        }
        this.props.outboxPage({
            recipientDataError: false,
            recipientFaxError: false,
        });
    };
    byRecipient = () => {
        this.setState({
            currentLookup_Rec: 'Other Recipient',
            selectedPhysician: '',
            physician_filter: '',
            physician_first_name: '',
            physician_last_name: '',
        });
        if (this.props.calingFrom === 'upload') {
            this.props.setCurrentLookup_Rec('Other Recipient');
        }
        this.props.outboxPage({
            recipientDataError: false,
            recipientFaxError: false,
        });
    };

    closeEditDetails = () => {
        let physician_first_name = '';
        let physician_last_name = '';
        if (
            this.state.clear_selectedPhysician.ordering_physician !==
                undefined &&
            this.state.clear_selectedPhysician.ordering_physician !== null &&
            this.state.clear_selectedPhysician.ordering_physician !== ''
        ) {
            const nameArray =
                this.state.clear_selectedPhysician.ordering_physician.split(
                    ' '
                );

            physician_first_name = nameArray[1];
            physician_last_name = nameArray[0].replace(',', '');
        }
        if (
            this.state.clear_patient_filter !== undefined &&
            this.state.clear_patient_filter.patient_name !== undefined
        ) {
            this.setState({
                view_patient: true,
            });
            this.props.updateState({
                view_patient: true,
            });
        } else {
            this.setState({
                view_patient: false,
            });
            this.props.updateState({
                view_patient: false,
            });
        }
        if (this.state.clear_physician_filter === undefined) {
            this.setState({
                view_physician: false,
            });
        } else {
            this.setState({
                view_physician: true,
                edit_physician: false,
            });
            this.props.updateState({
                view_physician: true,
                edit_physician: false,
            });
        }
        this.setState({
            open_edit_details: false,
            // view_patient: false,
            //edit_physician: false,
            //view_physician: true,
            // view_patient: true,
            checked: '',
            order_number: this.state.clear_order_number,
            order_date: this.state.clear_order_date,
            order_type: this.state.clear_order_type,
            patient_filter: { ...this.state.clear_patient_filter },
            selectedPhysician: { ...this.state.clear_selectedPhysician },
            physician_filter: this.state.clear_physician_filter,
            patient_filter_display:
                this.state.clear_patient_filter !== undefined
                    ? this.state.clear_patient_filter.patient_name
                    : '',
            patientValidation: false,
            validateOrderDate: false,
            validateOrderNumber: false,
            validateOrderType: false,
            emailValidation: false,
            faxValidation: false,
            phoneValidation: false,
            npiValidation: false,
            patientLink: false,
            physicianLink: false,
            currentLookup_Rec: this.state.initial_Rec,
            physician_first_name: physician_first_name,
            physician_last_name: physician_last_name,
        });
        this.props.updateState({
            open_edit_details: false,
            // view_patient: false,
            // edit_physician: false,
            // view_physician: true,
            // view_patient: true,
            patientValidation: false,
        });
    };

    setOrderDate = (date) => {
        // var formattedDate = formatDate(
        //     date,
        //     'MM/DD/YYYY'
        // )
        this.setState({
            order_date: date,
            validateOrderDate: false,
        });
    };

    handleEMR_OrderType = (event) => {
        this.setState({
            order_type: event.target.value,
            validateOrderType: false,
        });
    };

    /* handleBranchType = (event) => {
        this.setState({
            branch: event.target.value,
        });
    }; */
    setOrderNumber = (event) => {
        this.setState({
            order_number: event.target.value,
            validateOrderNumber: false,
        });
    };

    closeCreatePatient = async (formData) => {
        const state = {
            patient_filter: formData,
            open_create_patient: false,
            view_patient: true,
            patientIsNegative: false,
        };
        await this.setState(state, async () => {
            this.props.updateState(state);
        });
    };

    submitCreatePatient = () => {
        this.setState({
            submitCreatePatient: true,
        });
    };

    openNewPatient = () => {
        this.setState({
            // create_patient: true,
            open_create_patient: true,
        });
    };

    updateState = (state) => {
        this.setState({ ...state });
    };
    getSingleOrder = async (ordernumber) => {
        let orderFiledId = '';
        this.props.configData.fields.map((data, item) => {
            if (data.name === 'Order Number') {
                orderFiledId = data.fieldId;
            }
        });

        let orderSearchText = `fieldSearch=${orderFiledId}%3D${ordernumber}%2A`;

        let recordsetId = '';

        this.props.configuredRecordsets.forEach((data) => {
            if (data.name === 'Order Data') {
                recordsetId = data.recordsetId;
            }
        });
        const response = await recordSetSearch(recordsetId, orderSearchText);
        if (response.status === 200) {
            const data = await response.json();
            if (data.dataTable !== undefined) {
                const { dataRows, headerColumns } = data.dataTable;

                let order_idIndex = '';
                let orderId = '';
                headerColumns.forEach((data, index) => {
                    if (data.name === 'Order ID') {
                        order_idIndex = index;
                    }
                });
                for (const row of dataRows) {
                    orderId = row.values[order_idIndex];
                }

                return orderId;
            }
        }
        return null;
    };

    getBranchId(value) {
        let branchFiledId = '';
        this.props.configData.fields.forEach((data) => {
            if (data.name === 'Branch') {
                branchFiledId = data.fieldId;
            }
        });

        const searchText = `fieldSearch=${branchFiledId}=${encodeParameter(
            value
        )}`;
        let recordsetId = '';
        this.props.configuredRecordsets.forEach((data) => {
            if (data.name === 'Branch Data') {
                recordsetId = data.recordsetId;
            }
        });

        return recordSetSearch(recordsetId, searchText)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error('Error in recordSetSearch');
            })
            .then((data) => {
                return BranchIdSearch(data);
            });
    }
    removeDuplicates = async (data) => {
        const seenFieldIds = new Set();
        const filteredData = data.filter((item) => {
            if (seenFieldIds.has(item.fieldId)) {
                return false;
            }
            seenFieldIds.add(item.fieldId);
            return true;
        });

        return filteredData;
    };

    handleSubmitDetails = async (action_name) => {
        if (
            this.state.selectedPhysician === undefined ||
            this.state.selectedPhysician === ''
        ) {
            await this.setState({
                selectedPhysician: { ...this.state.unsavePhysician },
            });
        }

        if (
            (this.state.patient_filter === undefined ||
                this.state.patient_filter === '') &&
            this.state.unsavePatient !== ''
        ) {
            await this.setState({
                patient_filter: {
                    ...this.state.unsavePatient,
                    patient_name: `${this.state.unsavePatient.last_name}, ${this.state.unsavePatient.first_name}`,
                    patient_first_name: this.state.unsavePatient.first_name,
                    patient_last_name: this.state.unsavePatient.last_name,
                },
            });
        }
        if (this.state.editPatientDataDetail !== '') {
            await this.setState({
                patient_filter: {
                    ...this.state.editPatientDataDetail,
                    patient_name: `${this.state.editPatientDataDetail.last_name}, ${this.state.editPatientDataDetail.first_name}`,
                    patient_first_name:
                        this.state.editPatientDataDetail.first_name,
                    patient_last_name:
                        this.state.editPatientDataDetail.last_name,
                },
            });
        }

        this.setState({
            checked: 'checked',
        });

        let actionId = '';
        if (
            this.props.deleteDocument !== 'deleted_document' &&
            this.props.outbox.stateData !== undefined &&
            this.props.outbox.stateData.allActions !== undefined &&
            this.props.outbox.stateData.allActions[action_name] !== undefined
        ) {
            actionId =
                this.props.outbox.stateData.allActions[action_name].actionId;
        }
        if (this.props.deleteDocument === 'deleted_document') {
            actionId = this.state.allActions[action_name].actionId;
        }

        const docIds = [];

        docIds.push(this.props.selectedDocId);
        let processId = 1;
        let statusId = 1;
        if (String(this.props.processId) !== '') {
            processId = this.props.processId;
        }
        if (String(this.props.statusId) !== '') {
            statusId = this.props.statusId;
        }
        if (this.props.deleteDocument === 'deleted_document') {
            statusId = this.props.delstatusId;
            processId = this.props.delprocessId;
        }
        const physician_field = [];
        const patient_field = [];
        const fields = {};
        let params = [];
        const fieldsTemp = {};
        let form_data = {};
        let form_data_phy = {};
        let callSaveAction = 1;
        const fieldData = this.state.allFieldIds;

        if (
            this.state.order_date !== '' &&
            this.state.order_date !== undefined
        ) {
            const formattedDate = formatDate(
                this.state.order_date,
                'MM/DD/YYYY'
            );
            params.push({
                fieldId: this.state.allFieldIds.order_date,
                value: formattedDate,
            });
        } else {
            if (this.state.currentLookup_Rec === 'Physician') {
                this.setState({ validateOrderDate: true });
                callSaveAction = 0;
            }
        }
        if (
            this.state.order_number !== '' &&
            this.state.order_number !== undefined
        ) {
            params.push({
                fieldId: this.state.allFieldIds.order_number,
                value: this.state.order_number,
            });
        } else {
            if (this.state.currentLookup_Rec === 'Physician') {
                this.setState({ validateOrderNumber: true });
                callSaveAction = 0;
            }
        }
        if (
            this.state.order_type !== '' &&
            this.state.order_type !== undefined
        ) {
            params.push({
                fieldId: this.state.allFieldIds.emr_order_type,
                value: this.state.order_type,
            });
        } else {
            if (this.state.currentLookup_Rec === 'Physician') {
                this.setState({ validateOrderType: true });
                callSaveAction = 0;
            }
        }

        if (
            this.state.patient_filter !== undefined &&
            Object.keys(this.state.patient_filter).length > 1
        ) {
            await this.setState({
                patient_filter: {
                    ...this.state.patient_filter,
                    patient_first_name: this.state.patient_filter.first_name,
                    patient_last_name: this.state.patient_filter.last_name,
                },
            });

            form_data = {
                ...form_data,
                ...this.state.patient_filter,
            };

            let value_temp = '';
            // eslint-disable-next-line guard-for-in
            for (const value in form_data) {
                value_temp = value;
                // if(value === 'first_name') value_temp = 'patient_first_name';
                // if(value === 'last_name') value_temp = 'patient_last_name';

                if (
                    fieldData[value_temp] !== undefined &&
                    form_data[value] !== undefined &&
                    form_data[value] !== '' &&
                    form_data[value] !== null &&
                    value !== 'patientid' &&
                    value !== 'patient' &&
                    value !== 'dob' &&
                    // value !== 'gender' && //need to comments once don handled for keyword type error solved
                    // value !== 'service_line' && //need to comments once don handled for keyword type error solved
                    value !== 'attachmentlocation' &&
                    value !== 'episodeVisit'
                    // value !== 'select_an_episode'
                ) {
                    params.push({
                        fieldId: fieldData[value_temp],
                        value: form_data[value],
                    });
                }
                if (
                    fieldData[value_temp] !== undefined &&
                    form_data[value] !== undefined &&
                    form_data[value] !== '' &&
                    form_data[value] !== null
                ) {
                    patient_field.push({
                        fieldId: fieldData[value_temp],
                        value: form_data[value],
                    });
                }
            }

            // callSaveAction = 1;
        } else {
            // callSaveAction = 0;
            if (this.state.currentLookup_Rec === 'Physician') {
                const message = 'Please link/create the Patient';
                let error = message.replace(/[0-9]/g, '');
                error = error.replace(/:/g, '');
                showErrorToast(error);
                this.setState({
                    checked: '',
                    patientLink: true,
                });

                return false;
            }
        }
        let errorConfirm = false;
        /* let checkErrorRec = 0;
      
        let recipientDataLength = Object.keys(this.state.recipientData).length;
        let checkLength;
        for (const property in this.state.recipientData) {
            checkLength = checkLength + 1;
            if (this.state.recipientData[property] == '') {
                checkErrorRec = checkErrorRec + 1;
            }
        }
        if (checkErrorRec === recipientDataLength) {
            errorConfirm = true;
        } */
        //either one should be mandatory case condition

        let singleField = false;
        if (
            this.state.recipientData.hasOwnProperty('ordering_physician') &&
            this.state.recipientData.hasOwnProperty('fax_number') &&
            this.state.recipientData.ordering_physician !== '' &&
            this.state.recipientData.fax_number !== '___-___-____'
        ) {
            errorConfirm = false;
        } else if (
            this.state.recipientData.hasOwnProperty('ordering_physician') ===
                true &&
            this.state.recipientData.hasOwnProperty('fax_number') === false &&
            this.state.recipientData.ordering_physician !== ''
        ) {
            singleField = true;
        } else if (
            this.state.recipientData.hasOwnProperty('ordering_physician') ===
                false &&
            this.state.recipientData.hasOwnProperty('fax_number') === true &&
            this.state.recipientData.fax_number !== '___-___-____'
        ) {
            singleField = true;
        } else if (
            this.state.recipientData.hasOwnProperty('ordering_physician') ===
                true &&
            this.state.recipientData.hasOwnProperty('fax_number') === true &&
            (this.state.recipientData.fax_number == '___-___-____' ||
                this.state.recipientData.ordering_physician == '')
        ) {
            if (
                this.state.recipientData.fax_number == '___-___-____' &&
                this.state.recipientData.ordering_physician == ''
            ) {
                errorConfirm = true;
            } else {
                singleField = true;
            }
        } else {
            errorConfirm = true;
        }

        const faxPatternRec = /^[0-9\b]+$/;
        if (this.state.recipientData.fax_number) {
            let fax_number = this.state.recipientData.fax_number.replace(
                /-/g,
                ''
            );

            if (fax_number) {
                if (!fax_number.match(faxPatternRec)) {
                    singleField = true;

                    this.props.outboxPage({
                        recipientFaxError: true,
                    });
                }
            }
        }

        /* const faxPatternRecpi = /^[0-9\b]+$/;
         if (this.state.recipientData.fax_number) {
             if (!this.state.recipientData.fax_number.match(faxPatternRecpi)) {
                
                  this.props.outboxPage({
                      recipientDataFax: true,
                  });
             }
         } */
        if (
            this.state.currentLookup_Rec === 'Physician' &&
            this.state.selectedPhysician !== undefined &&
            Object.keys(this.state.selectedPhysician).length > 0
        ) {
            form_data_phy = {
                ...form_data_phy,
                ...this.state.selectedPhysician,
            };

            if (form_data_phy.fax_number) {
                // var format1 = form_data_phy.fax_number.slice(2);
                /* if (form_data_phy.fax_number.includes('-') === true) {
                    const formatfax = form_data_phy.fax_number.split('-');
                    const number1 = formatfax[0] + formatfax[1] + formatfax[2];

                    form_data_phy = {
                        ...form_data_phy,
                        fax_number: number1,
                    };
                } else { */
                form_data_phy = {
                    ...form_data_phy,
                    fax_number: form_data_phy.fax_number.replace(/-/g, ''),
                };
                /*  } */
            }
            if (form_data_phy.phone_number) {
                // var format2 = form_data_phy.phone_number.slice(2);
                /*  if (form_data_phy.phone_number.includes('-') === true) {
                    const format = form_data_phy.phone_number.split('-');
                    const number = format[0] + format[1] + format[2];

                    form_data_phy = {
                        ...form_data_phy,
                        phone_number: number,
                    };
                } else { */
                form_data_phy = {
                    ...form_data_phy,
                    phone_number: form_data_phy.phone_number.replace(/-/g, ''),
                };
                /* } */
            }

            let value_tempp = '';
            // eslint-disable-next-line guard-for-in
            for (const value in form_data_phy) {
                value_tempp = value;
                // if(value === 'first_name') value_temp = 'patient_first_name';
                // if(value === 'last_name') value_temp = 'patient_last_name';

                if (
                    fieldData[value_tempp] !== undefined &&
                    form_data_phy[value] !== undefined &&
                    form_data_phy[value] !== '' &&
                    form_data_phy[value] !== null &&
                    form_data_phy[value] !== '___-___-____' &&
                    form_data_phy[value] !== '__________' &&
                    value !== 'physician_id' // added for configuration error physician_id not need
                    // value !== 'physician_first_name' && //need to comments once don handled for keyword type error solved
                    // value !== 'physician_last_name' //need to comments once don handled for keyword type error solved
                ) {
                    params.push({
                        fieldId: fieldData[value_tempp],
                        value: form_data_phy[value],
                    });
                }
                if (
                    fieldData[value_tempp] !== undefined &&
                    form_data_phy[value] !== undefined &&
                    form_data_phy[value] !== '' &&
                    form_data_phy[value] !== null &&
                    form_data_phy[value] !== '___-___-____' &&
                    form_data_phy[value] !== '__________'
                ) {
                    physician_field.push({
                        fieldId: fieldData[value_tempp],
                        value: form_data_phy[value],
                    });
                }
                // if (value === "id") {
                //     params.push({
                //         fieldId: fieldData["physician_id"],
                //         value: String(form_data_phy[value]),
                //     });
                // }
            }
        } else if (
            this.state.currentLookup_Rec === 'Other Recipient' &&
            Object.keys(this.state.recipientData).length !== 0 &&
            errorConfirm === false &&
            singleField === false
        ) {
            params.push({
                fieldId: this.state.allFieldIds.ordering_physician,
                value: this.state.recipientData.ordering_physician,
            });
            params.push({
                fieldId: this.state.allFieldIds.fax_number,
                value: this.state.recipientData.fax_number.replace(/-/g, ''),
            });
        } else {
            let message = '';
            if (this.state.currentLookup_Rec === 'Physician') {
                message = 'Please select/create the Physician';
            } else if (singleField === true) {
                message = 'Please fill the madatory fields';
                this.props.outboxPage({
                    recipientDataError: true,
                });
            } else {
                message = 'Please create Recipient';
                this.props.outboxPage({
                    recipientDataError: true,
                });
            }

            let error = message.replace(/[0-9]/g, '');
            error = error.replace(/:/g, '');
            showErrorToast(error);
            this.setState({
                checked: '',
                physicianLink: true,
            });

            return false;
        }

        let branchID;

        if (
            form_data.branch_id === '' ||
            form_data.branch_id === null ||
            form_data.branch_id === undefined
        ) {
            let branchData =
                form_data !== undefined &&
                form_data.branch !== undefined &&
                form_data.branch !== null &&
                form_data.branch !== ''
                    ? form_data.branch
                    : this.state.recipientData?.branch;
            await this.getBranchId(branchData)
                .then((branch_id) => {
                    branchID = branch_id;
                })
                .catch((error) => {
                    console.log('Error:', error);
                });

            await params.push({
                fieldId: fieldData.branch_id,
                value: branchID,
            });
            if (
                this.state.currentLookup_Rec === 'Other Recipient' &&
                form_data !== undefined &&
                form_data.branch !== undefined &&
                form_data.branch !== null &&
                form_data.branch !== '' &&
                this.state.recipientData.branch !== undefined &&
                this.state.recipientData.branch !== null &&
                this.state.recipientData.branch !== ''
            ) {
                await params.push({
                    fieldId: fieldData.branch,
                    value: this.state.recipientData.branch,
                });
            }
        }

        //return false;
        let orderId = '';
        if (this.state.order_number) {
            orderId = await this.getSingleOrder(this.state.order_number);
            await params.push({
                fieldId: fieldData.order_id,
                value: orderId ? orderId : '',
            });
        }
        // physician validation
        //below if condition alone commented for madatory field issue

        // if (this.state.edit_physician === false) {
        //     if (
        //         this.state.selectedPhysician.npi === undefined ||
        //         this.state.selectedPhysician.fax_number === undefined ||
        //         this.state.selectedPhysician.phone_number === undefined ||
        //         this.state.selectedPhysician.ordering_physician === undefined ||
        //         this.state.selectedPhysician.email_address === undefined
        //     ) {
        //         if (callSaveAction !== 0) {
        //             callSaveAction = 0;
        //         }
        //     }
        // }

        /*if (
            this.state.edit_physician === undefined &&
            (this.state.selectedPhysician === undefined ||
            Object.keys(this.state.selectedPhysician).length <= 0)
        ) {
            callSaveAction = 0;
            const message = 'Please link/create the Physician';
            let error = message.replace(/[0-9]/g, '');
            error = error.replace(/:/g, '');
            showErrorToast(error);
            this.setState({
                checked: '',
            });
            return false;
        }*/

        if (
            this.state.edit_physician === true &&
            this.state.currentLookup_Rec === 'Physician'
        ) {
            let noError = false;
            if (
                this.state.selectedPhysician.npi === undefined ||
                this.state.selectedPhysician.fax_number === undefined ||
                //this.state.selectedPhysician.phone_number === undefined ||
                this.state.selectedPhysician.ordering_physician === undefined //||
                //this.state.selectedPhysician.email_address === undefined
            ) {
                if (callSaveAction !== 0) {
                    callSaveAction = 0;
                }
            }
            for (const property in this.state.selectedPhysician) {
                if (
                    this.state.selectedPhysician[property] === '' &&
                    (property === 'npi' ||
                        property === 'fax_number' ||
                        //property === 'phone_number' ||
                        // property === 'email_address' ||
                        property === 'delivery_method' ||
                        property === 'physician_first_name' ||
                        property === 'physician_last_name')
                ) {
                    callSaveAction = 0;
                    noError = true;
                    this.setState({
                        validateEdit: true,
                    });
                    break;
                }
            }
            if (
                this.state.selectedPhysician.npi === undefined ||
                this.state.selectedPhysician.fax_number === undefined ||
                //this.state.selectedPhysician.phone_number === undefined ||
                this.state.selectedPhysician.ordering_physician === undefined ||
                //this.state.selectedPhysician.email_address === undefined ||
                this.state.selectedPhysician.delivery_method === undefined
            ) {
                callSaveAction = 0;
                noError = true;
                this.setState({
                    validateEdit: true,
                });
            }
            // const faxPattern =
            const faxPattern = /^[0-9\b]+$/;
            if (this.state.selectedPhysician.fax_number) {
                if (
                    !this.state.selectedPhysician.fax_number.match(faxPattern)
                ) {
                    callSaveAction = 0;
                    noError = true;
                    this.setState({
                        faxValidation: true,
                    });
                }
            }

            if (this.state.selectedPhysician.phone_number) {
                if (
                    !this.state.selectedPhysician.phone_number.match(
                        faxPattern
                    ) &&
                    this.state.selectedPhysician.phone_number !==
                        '___-___-____' &&
                    this.state.selectedPhysician.phone_number !== '__________'
                ) {
                    callSaveAction = 0;
                    noError = true;
                    this.setState({
                        phoneValidation: true,
                    });
                }
            }
            const pattern = /^\d{10}$/;
            if (this.state.selectedPhysician.npi) {
                if (!this.state.selectedPhysician.npi.match(pattern)) {
                    callSaveAction = 0;
                    noError = true;
                    this.setState({
                        npiValidation: true,
                    });
                }
            }
            if (this.state.selectedPhysician.physician_last_name) {
                await this.setState({
                    selectedPhysician: {
                        ...this.state.selectedPhysician,
                        ordering_physician: `${this.state.selectedPhysician.physician_last_name}, ${this.state.selectedPhysician.physician_first_name}`,
                    },
                });
            }

            // eslint-disable-next-line no-useless-escape
            /* const regex_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (this.state.selectedPhysician.email_address) {
                if (
                    !regex_email.test(
                        this.state.selectedPhysician.email_address
                    )
                ) {
                    noError = true;
                    callSaveAction = 0;
                    this.setState({
                        emailValidation: true,
                    });
                }
            } */

            if (noError === false && this.state.create_physician === true) {
                // physicain create api call
                let create_status;
                await createNewPatient(
                    physician_field,
                    this.state.physicianModifiableRecordsetId
                )
                    .then((response) => {
                        create_status = response.status;
                        return response.json();
                    })
                    .then((data) => {
                        if (create_status === 200) {
                            physician_field.id = data.uniqueId;
                            this.setState({
                                selectedPhysician: {
                                    ...this.state.selectedPhysician,
                                    id: data.uniqueId,
                                },
                                physician_filter:
                                    this.state.selectedPhysician
                                        .ordering_physician,
                            });

                            // normalSuccessToast('Physician Created Successfully');
                        }
                        if (create_status === 400) {
                            // error = true;
                            callSaveAction = 2;
                            showErrorToast(data.message);
                            this.setState({
                                checked: '',
                            });

                            return false;
                        }
                    });

                /* const message = 'Please Save Physician Details';
                let error = message.replace(/[0-9]/g, '');
                error = error.replace(/:/g, '');
                showErrorToast(error);
                this.setState({
                    checked: '',
                });
                return false; */
            }
            this.setState({
                checked: '',
            });
        }

        if (
            this.state.edit_patient === true ||
            this.state.open_create_patient === true
        ) {
            let checkError = false;
            if (
                this.state.patient_filter.patient_first_name === '' ||
                this.state.patient_filter.patient_last_name === '' ||
                this.state.patient_filter.date_of_birth === '' ||
                this.state.patient_filter.gender === '' ||
                this.state.patient_filter.service_line === '' ||
                this.state.patient_filter.branch === ''
            ) {
                if (callSaveAction !== 0) {
                    callSaveAction = 0;
                    checkError = true;
                    this.setState({
                        patientValidation: true,
                    });
                }
            }
            // patient validation ends here
            if (
                checkError === false &&
                this.state.open_create_patient === true
            ) {
                let status;
                await createNewPatient(
                    patient_field,
                    this.state.patientModifiableRecordsetId
                )
                    .then((response) => {
                        status = response.status;
                        return response.json();
                    })
                    .then((data) => {
                        // this.setState({loader:false});

                        if (status === 200) {
                            patient_field.id = data.uniqueId;
                            this.setState({
                                patient_filter: {
                                    ...this.state.patient_filter,
                                    patient_id: data.uniqueId,
                                },
                            });
                            this.setState({
                                selectedPatient: {
                                    ...this.state.patient_filter,
                                    patient_id: data.uniqueId,
                                },
                            });
                            this.setState({
                                open_create_patient: false,
                                view_patient: true,
                            });
                            // formData.patientid = data.uniqueId;
                            // this.setState({ loader: false });
                            // normalSuccessToast('Patient Created Successfully');
                            // this.props.closeCreatePatient(formData);
                        }
                        if (status === 400) {
                            // this.setState({ loader: false });
                            // error = true;
                            callSaveAction = 2;
                            showErrorToast(data.message);
                            this.setState({
                                checked: '',
                            });

                            return false;
                        }
                    });

                /* const message = 'Please Save Patient Details';
                let error = message.replace(/[0-9]/g, '');
                error = error.replace(/:/g, '');
                showErrorToast(error);
                this.setState({
                    checked: '',
                });
                return false; */
            }
            // this.setState({
            // checked: '',
            // });
        }

        if (callSaveAction === 0) {
            this.setState({
                checked: '',
            });
            normalErrorToast('Please fill mandatory fields');

            return false;
        } else {
            this.setState({ validateOrderDate: false });
            this.setState({ validateOrderNumber: false });
            this.setState({ validateOrderType: false });
            // this.setState({validateBranch:false})
        }

        if (
            this.props.outbox.selectedRowDetails !== undefined &&
            this.props.outbox.selectedRowDetails !== null &&
            this.props.outbox.selectedRowDetails !== '' &&
            this.props.outbox.selectedRowDetails.fields !== undefined &&
            (this.state.selectedPhysician.delivery_method === undefined ||
                this.state.selectedPhysician.delivery_method === '' ||
                this.state.selectedPhysician.delivery_method === null)
        ) {
            this.props.outbox.selectedRowDetails.fields.map((delivery) => {
                if (
                    delivery.fieldId ===
                        this.state.allFieldIds.delivery_method &&
                    delivery.value !== ''
                ) {
                    params.push({
                        fieldId: this.state.allFieldIds.delivery_method,
                        value: delivery.value,
                    });
                }
            });
        }

        if (callSaveAction === 1) {
            // var error = false;
            // unsave physicain api call

            /* if (
                 this.state.selectedPhysician.id === undefined ||
                 this.state.selectedPhysician.id === ''
             ) {
                 var create_status;
                 await createNewPatient(
                     physician_field,
                     this.state.physicianModifiableRecordsetId
                 )
                     .then((response) => {
                         create_status = response.status;
                         return response.json();
                     })
                     .then((data) => {
                         if (create_status === 200) {
                             physician_field.id = data.uniqueId;
                             this.setState({
                                 selectedPhysician: {
                                     ...this.state.selectedPhysician,
                                     id: data.uniqueId,
                                 },
                             });

                             // normalSuccessToast('Physician Created Successfully');
                         }
                         if (create_status === 400) {
                             error = true;
                             showErrorToast(data.message);
                             this.setState({
                                 checked: '',
                             });
                         }
                     });
             } */
            // unsave physicain api call ends

            // unsave patient api call starts
            /* if (
                this.state.patient_filter !== undefined ||
                this.state.patient_filter.patient_id === '' ||
                this.state.patient_filter.patient_id === undefined
            ) {
                
                var status;
                await createNewPatient(
                    patient_field,
                    this.state.patientModifiableRecordsetId
                )
                    .then((response) => {
                        status = response.status;
                        return response.json();
                    })
                    .then((data) => {
                        // this.setState({loader:false});
                        
                        if (status === 200) {
                            patient_field.id = data.uniqueId;
                            this.setState({
                                patient_filter: {
                                    ...this.state.patient_filter,
                                    patient_id: data.uniqueId,
                                },
                            });
                            this.setState({
                                selectedPatient: {
                                    ...this.state.patient_filter,
                                    patient_id: data.uniqueId,
                                },
                            });
                            this.setState({
                                open_create_patient: false,
                                view_patient: true,
                            });
                            //formData.patientid = data.uniqueId;
                            //this.setState({ loader: false });
                            //normalSuccessToast('Patient Created Successfully');
                            //this.props.closeCreatePatient(formData);
                        }
                        if (status === 400) {
                            //this.setState({ loader: false });
                            error = true;
                            showErrorToast(data.message);
                            this.setState({
                                checked: '',
                            });
                        }
                    });
            } */
            // unsave patient api call end here

            /*params.push({
                fieldId: this.state.allFieldIds.signature_required,
                value: 'N',
            });*/

            if (this.props.match.params.menuName === 'manual-processing') {
                const orderID = generatePatientId();
                params.push({
                    fieldId: this.state.allFieldIds.order_id,
                    value: orderID ? orderID : '',
                });
            }

            params = params.map((item) => {
                if (item.fieldId === this.state.allFieldIds.phone_number) {
                    return {
                        ...item,
                        value:
                            item.value.includes('_') || item.value.includes('-')
                                ? item.value.replace(/[_-]/g, '')
                                : item.value,
                    };
                }
                return item;
            });
            params = await this.removeDuplicates(params);
            fieldsTemp.fields = params;
            fieldsTemp.docIds = docIds;
            fields.processId = processId;
            fields.statusId = statusId;
            fields.actionId = actionId;

            fields.fields = fieldsTemp;
            await unlockDocument(this.props.selectedDocId);
            await outboxDocumentAction(fields)
                .then((response) => {
                    if (response.status === 400) {
                        if (response.message !== undefined) {
                            const { message } = response;
                            let error = message.replace(/[0-9]/g, '');
                            error = error.replace(/:/g, '');
                            showErrorToast(error);
                            this.setState({
                                submitted: 0,
                                checked: '',
                            });
                        } else {
                            showErrorToast('Error in Processing.');
                            this.setState({
                                submitted: 0,
                                checked: '',
                            });
                        }
                    }
                    return response.json();
                })
                .then(async (data) => {
                    if (data.hadFailures === false) {
                        showSuccessToast('Details updated successfully.');
                        await this.setState({
                            initial_Rec: this.state.currentLookup_Rec,
                            open_edit_details: false,
                            edit_physician: false,
                            checked: '',
                            clear_order_number: this.state.order_number,
                            clear_order_date: this.state.order_date,
                            clear_order_type: this.state.order_type,
                            clear_patient_filter: {
                                ...this.state.patient_filter,
                            },
                            clear_selectedPhysician: {
                                ...this.state.selectedPhysician,
                            },
                            clear_physician_filter: `${this.state.selectedPhysician.physician_last_name}, ${this.state.selectedPhysician.physician_first_name}`,
                        });
                        if (
                            this.state.currentLookup_Rec === 'Other Recipient'
                        ) {
                            this.setState({
                                selectedPhysician: {
                                    ordering_physician:
                                        this.state.recipientData
                                            .ordering_physician,
                                    fax_number:
                                        this.state.recipientData.fax_number,
                                },
                            });
                        }

                        const rowDetails = this.props.outbox.selectedRowDetails;
                        rowDetails.fields = [...params];

                        await this.props.getSelectedRowDetails(rowDetails);
                        await this.props.updateState({
                            open_delivery_detail_section: false,
                        });
                        setTimeout(() => {
                            this.props.updateState({
                                open_delivery_detail_section: true,
                            });
                        }, 1000);
                    }
                    if (data.hadFailures === true) {
                        let message = data.results[0].message;
                        showErrorToast(message);
                        this.setState({
                            submitted: 0,
                            checked: '',
                        });
                    }
                })
                .catch((err) => {});
            await lockDocument(this.props.selectedDocId);
        }
    };

    render() {
        return (
            <>
                {this.state.calingFrom === 'upload' ? (
                    <>
                        <hr />
                        <CoverPageModal
                            calingFrom='upload'
                            isLocked={false}
                            updateState={(state) => {
                                this.setState({ ...state });
                            }}
                            coverpage_commentFunction={(e) => {
                                this.props.coverpage_commentFunction(e);
                            }}
                            coverpage_typeFunction={(e) =>
                                this.props.coverpage_typeFunction(e)
                            }
                            coverpage_validation={
                                this.props.coverpage_validation
                            }
                        />
                        <hr />
                    </>
                ) : null}

                {this.state.open_edit_details === false ? (
                    <div className='wrap_details p-4 pb-lg-0 mb-0'>
                        <div className='details_part'>
                            <div className='details_fun detail_title_mobile'>
                                <span>
                                    <i
                                        title='Details'
                                        className='fa-solid fa-notes-medical'
                                    >
                                        {' '}
                                    </i>
                                    Details
                                </span>
                            </div>
                        </div>
                        <div className='d-flex mb-5 details_part step2 mb-3 align-items-stretch justify-content-between'>
                            <div className='details_fun'>
                                <span title='Edit Details'>
                                    <i className='fa-solid fa-notes-medical' />
                                    Details
                                </span>
                            </div>

                            {this.state.allActions !== undefined &&
                            this.state.allActions !== null &&
                            Object.keys(this.state.allActions).length !== 0 &&
                            (this.state.allActions.hasOwnProperty(
                                'update_information_manually'
                            ) ||
                                this.state.allActions
                                    .update_information_manually) ? (
                                <div className='edit_details'>
                                    <Button
                                        disabled={
                                            !!(
                                                this.state.isLocked === true ||
                                                this.state.currentURL ===
                                                    'detail'
                                            )
                                        }
                                        title='Edit Details'
                                        variant='primary'
                                        style={
                                            this.state.currentURL === 'detail'
                                                ? { cursor: 'not-allowed' }
                                                : {}
                                        }
                                        onClick={() => {
                                            this.addEditDetails();
                                        }}
                                    >
                                        Edit Details
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            {this.state.order_type ||
                            this.state.order_number ||
                            this.state.order_date ||
                            (this.state.selectedPhysician !== undefined &&
                                this.state.selectedPhysician.npi !==
                                    undefined &&
                                this.state.selectedPhysician.npi !== '') ||
                            this.state.selectedPhysician.fax_number !==
                                undefined ||
                            this.state.selectedPhysician.ordering_physician !==
                                undefined ? (
                                <>
                                    <div className='form-group row'>
                                        {this.state.currentLookup_Rec !==
                                            'Other Recipient' && (
                                            <div className='col-lg-6 transition-select'>
                                                <label className='pl-5'>
                                                    ORDER TYPE
                                                </label>
                                                <div className='span-output'>
                                                    {this.state.order_type
                                                        ? this.state.order_type
                                                        : '-'}
                                                </div>
                                            </div>
                                        )}
                                        <div className='col-lg-6 transition-select'>
                                            <label className='pl-5'>
                                                Branch
                                            </label>
                                            <div className='span-output'>
                                                {this.state.branch !== ' '
                                                    ? this.state.branch
                                                    : '-'}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.currentLookup_Rec !==
                                        'Other Recipient' && (
                                        <div className='form-group row'>
                                            <div className='col-lg-6 transition-select'>
                                                <label className='pl-5'>
                                                    ORDER NUMBER
                                                </label>
                                                <div className='span-output'>
                                                    {this.state.order_number
                                                        ? this.state
                                                              .order_number
                                                        : '-'}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 transition-select mb-2'>
                                                <label className='pl-5'>
                                                    ORDER DATE
                                                </label>
                                                <div className='span-output'>
                                                    {this.state.order_date
                                                        ? formatDate(
                                                              this.state
                                                                  .order_date,
                                                              'MMM D, y'
                                                          )
                                                        : '-'}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.selectedPhysician.npi !==
                                        undefined &&
                                    this.state.selectedPhysician.npi !== '' ? (
                                        <>
                                            <div className='form-group row'>
                                                <div className='col-lg-6 transition-select mb-2'>
                                                    <label className='pl-5'>
                                                        PHYSICIAN
                                                    </label>
                                                    <div className='span-output'>
                                                        {this.state
                                                            .physician_filter
                                                            ? this.state
                                                                  .physician_filter
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className='patient_detail edit-detail-bg'
                                                style={{
                                                    backgroundColor: '#F0F7FB',
                                                }}
                                            >
                                                <div
                                                    style={{ display: 'flex' }}
                                                    className='form-group row'
                                                >
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            NPI
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .npi !== ''
                                                                ? `#${this.state.selectedPhysician.npi}`
                                                                : '-'}
                                                        </span>
                                                    </span>

                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            PHYSICIAN FIRST NAME
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .physician_first_name !==
                                                            undefined
                                                                ? this.state
                                                                      .selectedPhysician
                                                                      .physician_first_name
                                                                : this.state
                                                                      .physician_first_name !==
                                                                  ''
                                                                ? this.state
                                                                      .physician_first_name
                                                                : '-'}
                                                        </span>
                                                    </span>

                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            PHYSICIAN LAST NAME
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .physician_last_name !==
                                                            undefined
                                                                ? this.state
                                                                      .selectedPhysician
                                                                      .physician_last_name
                                                                : this.state
                                                                      .physician_last_name !==
                                                                  ''
                                                                ? this.state
                                                                      .physician_last_name
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginTop: '20px',
                                                    }}
                                                    className='form-group row mb-0'
                                                >
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            FAX NUMBER
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .fax_number !==
                                                            undefined
                                                                ? faxCellFormatter(
                                                                      this.state
                                                                          .selectedPhysician
                                                                          .fax_number
                                                                  )
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            PHONE NUMBER
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .phone_number !==
                                                            undefined
                                                                ? faxCellFormatter(
                                                                      this.state
                                                                          .selectedPhysician
                                                                          .phone_number
                                                                  )
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            EMAIL ADDRESS
                                                        </span>
                                                        <br />
                                                        <span className='patient_info p-email'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .email_address !==
                                                            undefined
                                                                ? this.state
                                                                      .selectedPhysician
                                                                      .email_address
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginTop: '20px',
                                                    }}
                                                    className='form-group row mb-0'
                                                >
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            DELIVERY METHOD
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .delivery_method !==
                                                            undefined
                                                                ? this.state
                                                                      .selectedPhysician
                                                                      .delivery_method
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* <div className='form-group row'>
                                                <div className='col-lg-6 transition-select mb-2'>
                                                    <label className='pl-5'>
                                                        RECIPIENT
                                                    </label>
                                                    <div className='span-output'>
                                                        {this.state
                                                            .selectedPhysician !==
                                                            undefined &&
                                                        this.state
                                                            .selectedPhysician
                                                            .ordering_physician !==
                                                            undefined
                                                            ? this.state
                                                                  .selectedPhysician
                                                                  .ordering_physician
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div
                                                className='patient_detail edit-detail-bg'
                                                style={{
                                                    backgroundColor: '#F0F7FB',
                                                }}
                                            >
                                                <div
                                                    style={{ display: 'flex' }}
                                                    className='form-group row'
                                                >
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            RECIPIENT NAME
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .ordering_physician !==
                                                            undefined
                                                                ? this.state
                                                                      .selectedPhysician
                                                                      .ordering_physician
                                                                : this.state
                                                                      .ordering_physician !==
                                                                  ''
                                                                ? this.state
                                                                      .ordering_physician
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginTop: '20px',
                                                    }}
                                                    className='form-group row mb-0'
                                                >
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            FAX NUMBER
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .selectedPhysician
                                                                .fax_number !==
                                                            undefined
                                                                ? faxCellFormatter(
                                                                      this.state
                                                                          .selectedPhysician
                                                                          .fax_number
                                                                  )
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {(this.state.patient_filter.patient_id !==
                                        undefined &&
                                        this.state.patient_filter.patient_id !==
                                            '') ||
                                    (this.state.patient_filter.patient_name !==
                                        undefined &&
                                        this.state.patient_filter
                                            .patient_name !== '') ? (
                                        <>
                                            <div className='form-group row'>
                                                <div className='col-lg-6 transition-select mb-2'>
                                                    <label className='pl-5'>
                                                        PATIENT NAME
                                                    </label>
                                                    <div className='span-output'>
                                                        {this.state
                                                            .patient_filter
                                                            .patient_name !==
                                                        undefined
                                                            ? this.state
                                                                  .patient_filter
                                                                  .patient_name
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className='patient_detail edit-detail-bg'
                                                style={{
                                                    backgroundColor: '#F0F7FB',
                                                }}
                                            >
                                                <div
                                                    style={{ display: 'flex' }}
                                                    className='form-group row'
                                                >
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            PATIENT NAME
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .patient_filter
                                                                .patient_name !==
                                                            undefined
                                                                ? this.state
                                                                      .patient_filter
                                                                      .patient_name
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            DATE OF BIRTH
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .patient_filter
                                                                .date_of_birth !==
                                                            undefined
                                                                ? formatDate(
                                                                      this.state
                                                                          .patient_filter
                                                                          .date_of_birth,
                                                                      'MM/DD/YYYY'
                                                                  )
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            GENDER
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .patient_filter
                                                                .gender !==
                                                            undefined
                                                                ? this.state
                                                                      .patient_filter
                                                                      .gender
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginTop: '20px',
                                                    }}
                                                    className='form-group row mb-0'
                                                >
                                                    {/* <span className='col-lg-4'>
                                                    <span className='patient_label'>
                                                        EPISODE STATUS
                                                    </span>
                                                    <br />
                                                    <span className='patient_info'>
                                                        {this.state.episodestatus}
                                                    </span>
                                                </span> */}

                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            BRANCH
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .patient_filter
                                                                .branch !==
                                                            undefined
                                                                ? this.state
                                                                      .patient_filter
                                                                      .branch
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                    <span className='col-lg-4'>
                                                        <span className='patient_label'>
                                                            SERVICE LINE
                                                        </span>
                                                        <br />
                                                        <span className='patient_info'>
                                                            {this.state
                                                                .patient_filter
                                                                .service_line !==
                                                            undefined
                                                                ? this.state
                                                                      .patient_filter
                                                                      .service_line
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    {this.state.calingFrom !== 'upload' && (
                                        <>
                                            <div
                                                className='more_info pl-2'
                                                onClick={this.openMore}
                                            >
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <a className='my-4'>
                                                    {this.state
                                                        .more_information ? (
                                                        <div title='Less Information'>
                                                            <i className='fa-regular fa-chevron-up' />
                                                            <button type='button'>
                                                                {' '}
                                                                Less Information
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div title='More Information'>
                                                            <i className='fa-regular fa-chevron-down' />
                                                            <button type='button'>
                                                                {' '}
                                                                More Information
                                                            </button>
                                                        </div>
                                                    )}
                                                </a>
                                            </div>
                                            {this.state.more_information ===
                                                true && (
                                                <DetailMoreInformation
                                                    miscPproperties={
                                                        this.props
                                                            .selectedRowDetails
                                                            .miscPproperties
                                                    }
                                                    signature_required={
                                                        this.state
                                                            .signature_required
                                                    }
                                                    order_id={
                                                        this.state.order_id
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className='no_detail'>
                                    No details added yet. Click on the 'Edit
                                    Details' button above to get started
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}

                {this.state.open_edit_details === true ? (
                    <div className='wrap_details p-4 pb-lg-0 mb-0'>
                        <div className='details_part'>
                            <div className='details_fun detail_title_mobile'>
                                <span>
                                    <i
                                        title='Details'
                                        className='fa-solid fa-notes-medical'
                                    >
                                        {' '}
                                    </i>
                                    Details
                                </span>
                            </div>
                        </div>
                        <div className='d-flex details_part step2 mb-3 align-items-stretch justify-content-between'>
                            <div className='details_fun'>
                                <span>
                                    <i
                                        title='Details'
                                        className='fa-solid fa-notes-medical'
                                    >
                                        {' '}
                                    </i>
                                    Details
                                </span>
                            </div>
                            {this.state.calingFrom !== 'upload' ? (
                                <div className='edit_details notes d-flex justify-content-between'>
                                    <button
                                        type='button'
                                        title='Clear'
                                        className='clear cancel-bg-btn'
                                        disabled={this.props.isLocked === true}
                                        onClick={() => {
                                            this.clearAll();
                                        }}
                                    >
                                        <i className='fas fa-broom' />
                                        Clear
                                    </button>

                                    <button
                                        type='button'
                                        title='Cancel'
                                        variant='secondary'
                                        className='cancel_btn ml-3'
                                        disabled={this.props.isLocked === true}
                                        onClick={this.closeEditDetails}
                                    >
                                        Cancel
                                    </button>
                                    <div className='cancel_save'>
                                        <Button
                                            title='Save Details'
                                            variant='primary'
                                            className='save_btn btn btn-success'
                                            onClick={() => {
                                                this.handleSubmitDetails(
                                                    'update_information_manually'
                                                );
                                            }}
                                            disabled={this.state.checked}
                                        >
                                            Save Details
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {this.state.clearData === false ? (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <Loader
                                    type='Oval'
                                    color='#007dbc'
                                    height={40}
                                    width={40}
                                />
                            </div>
                        ) : null}

                        {this.state.clearData === true ? (
                            <div>
                                {this.state.calingFrom !== 'upload' ? (
                                    <div className='row grid-row-group'>
                                        <div className='d-flex w-100'>
                                            <div className='col-lg-6 transition-select mb-2'>
                                                <label className='pl-5'>
                                                    ORDER TYPE
                                                </label>
                                                <select
                                                    id='delete_selectbrand'
                                                    className={
                                                        this.state
                                                            .validateOrderType
                                                            ? 'form-control error-field'
                                                            : 'form-control'
                                                    }
                                                    value={
                                                        this.state
                                                            .order_type === ''
                                                            ? ''
                                                            : this.state
                                                                  .order_type
                                                    }
                                                    onChange={(event) => {
                                                        this.handleEMR_OrderType(
                                                            event
                                                        );
                                                    }}
                                                >
                                                    <option value=''>
                                                        Select
                                                    </option>
                                                    {this.state
                                                        .emr_order_typeDDL !==
                                                    undefined
                                                        ? this.state.emr_order_typeDDL.map(
                                                              (
                                                                  options,
                                                                  idx
                                                              ) => {
                                                                  return (
                                                                      <option
                                                                          value={
                                                                              options
                                                                          }
                                                                          key={
                                                                              idx
                                                                          }
                                                                      >
                                                                          {
                                                                              options
                                                                          }
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <i
                                                    className='fa-light fa-chevron-down'
                                                    style={{
                                                        pointerEvents: 'none',
                                                    }}
                                                />
                                                {this.state
                                                    .validateOrderType ? (
                                                    <p
                                                        className='error m-0'
                                                        style={{ color: 'red' }}
                                                    >
                                                        Order Type is required.
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className='col-lg-6 transition-select'>
                                            <label className='pl-5'>
                                                ORDER NUMBER
                                            </label>
                                            <div className='position-relative'>
                                                <input
                                                    type='text'
                                                    className={
                                                        this.state
                                                            .validateOrderNumber
                                                            ? 'form-control error-field'
                                                            : 'form-control'
                                                    }
                                                    defaultValue={
                                                        this.state.order_number
                                                    }
                                                    onChange={(e) => {
                                                        this.setOrderNumber(e);
                                                    }}
                                                    maxLength='10'
                                                />
                                                {this.state
                                                    .validateOrderNumber ? (
                                                    <p
                                                        className='error m-0'
                                                        style={{ color: 'red' }}
                                                    >
                                                        Order Number is
                                                        required.
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className='col-lg-6 transition-select'>
                                            <label className='pl-5'>
                                                ORDER DATE
                                            </label>
                                            <div
                                                className={
                                                    this.state.validateOrderDate
                                                        ? 'date-picker  error-field position-relative form-control'
                                                        : 'date-picker position-relative form-control '
                                                }
                                            >
                                                <DatePicker
                                                    onChange={(date) => {
                                                        this.setOrderDate(date);
                                                    }}
                                                    value={
                                                        this.state.order_date
                                                    }
                                                    className={
                                                        this.state
                                                            .validateOrderDate
                                                            ? 'date-picker '
                                                            : 'date-picker '
                                                    }
                                                    format='MM/dd/y'
                                                />
                                            </div>
                                            {this.state.validateOrderDate ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Order Date is required.
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}

                                <div className='form-group row'>
                                    <div className='col-lg-12'>
                                        <label className='pl-5'>
                                            RECIPIENT
                                        </label>
                                        <div className='tab_part'>
                                            <div className='tabs'>
                                                <Nav
                                                    style={{
                                                        float: 'left',
                                                    }}
                                                    id='noanim-tab'
                                                    defaultActiveKey={
                                                        this.state
                                                            .currentLookup_Rec
                                                    }
                                                    variant='pills'
                                                    className=''
                                                >
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            title='Physician'
                                                            key={0}
                                                            eventKey='Physician'
                                                            onClick={
                                                                this.byPhysician
                                                            }
                                                        >
                                                            Physician
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            title='Other Recipient'
                                                            key={1}
                                                            eventKey='Other Recipient'
                                                            onClick={
                                                                this.byRecipient
                                                            }
                                                        >
                                                            Other Recipient
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <>
                                    {this.state.currentLookup_Rec ===
                                        'Physician' && (
                                        <ByPhysician
                                            state={{ ...this.state }}
                                            updateState={(state) => {
                                                this.setState({ ...state });
                                            }}
                                            calingFrom={this.props.calingFrom}
                                            validateEdit={
                                                this.state.validateEdit
                                            }
                                            // emailValidation={this.state.emailValidation}
                                            // phoneValidation={this.state.phoneValidation}
                                            // faxValidation={this.state.faxValidation}
                                            resetSubmit={() => {
                                                this.setState({
                                                    submit_edit_physician: false,
                                                });
                                            }}
                                            // updateSelectedPhysician={
                                            // this.props.updateSelectedPhysician
                                            // }
                                            edit_or_create={
                                                this.state.edit_or_create
                                            }
                                            selectedPhysician={
                                                this.state.selectedPhysician
                                            }
                                            PhsicianData_Function={(e) => {
                                                this.props.PhsicianData_Function(
                                                    e
                                                );
                                            }}
                                            physician_filter={
                                                this.state.physician_filter
                                            }
                                            setView_check={(num) => {
                                                this.props.setView_check(num);
                                            }}
                                            currentLookup='byPhysician'
                                            view={this.state.view}
                                            create_physician={
                                                this.state.create_physician
                                            }
                                            physicianLink={
                                                this.state.physicianLink
                                            }
                                            updatephysicianLink={
                                                this.props.physicianLink
                                            }
                                            PhsicianLink_Function={(e) => {
                                                this.props.calingFrom ===
                                                    'upload' &&
                                                    this.props.PhsicianLink_Function(
                                                        e
                                                    );
                                            }}
                                        />
                                    )}
                                </>
                                {this.state.view_physician &&
                                this.state.currentLookup_Rec === 'Physician' ? (
                                    <ViewEditPhysician
                                        state={{ ...this.state }}
                                        validateEdit={this.state.validateEdit}
                                        emailValidation={
                                            this.state.emailValidation
                                        }
                                        faxValidation={this.state.faxValidation}
                                        phoneValidation={
                                            this.state.phoneValidation
                                        }
                                        npiValidation={this.state.npiValidation}
                                        resetSubmit={() => {
                                            this.setState({
                                                submit_edit_physician: false,
                                            });
                                        }}
                                        selectedPhysician={
                                            this.state.selectedPhysician
                                        }
                                        edit_physician={
                                            this.state.edit_physician
                                        }
                                        submitEditPhysician={
                                            this.state.submit_edit_physician
                                        }
                                        physicianFieldIdArray={
                                            this.state.physicianFieldIdArray
                                        }
                                        physicianModifiableRecordsetId={
                                            this.state
                                                .physicianModifiableRecordsetId
                                        }
                                        closeEditPhysician={(formData) =>
                                            this.closeEditPhysician(formData)
                                        }
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                        }}
                                        allRecordSetID={
                                            this.state.allRecordSetID
                                        }
                                        updateFromCreate={
                                            this.submitEditPhysician
                                        }
                                        create_physician={
                                            this.state.create_physician
                                        }
                                        edit_or_create={
                                            this.state.edit_or_create
                                        }
                                        PhsicianData_Function={(e) =>
                                            this.props.PhsicianData_Function(e)
                                        }
                                        physicianData_fax={
                                            this.props.physicianData_fax
                                        }
                                        calingFrom={this.props.calingFrom}
                                        physician_validation={
                                            this.props.physician_validation
                                        }
                                        physician_first_name={
                                            this.state.physician_first_name
                                        }
                                        physician_last_name={
                                            this.state.physician_last_name
                                        }
                                        functionPhsicianData={(e) =>
                                            this.functionPhsicianData(e)
                                        }
                                        faxNumberVerify={
                                            this.props.faxNumberVerify
                                        }
                                        phoneNumberVerify={
                                            this.props.phoneNumberVerify
                                        }
                                        npiVerify={this.props.npiVerify}
                                        emailVerify={this.props.emailVerify}
                                    />
                                ) : null}
                                <>
                                    {this.state.currentLookup_Rec ===
                                    'Other Recipient' ? (
                                        <Recipient
                                            updateState={(state) => {
                                                this.setState({ ...state });
                                            }}
                                            recipientData={
                                                this.state.recipientData
                                            }
                                            Recipient_Data={(e) =>
                                                this.props.Recipient_Data(e)
                                            }
                                            calingFrom={this.state.calingFrom}
                                            selectedPhysician={
                                                this.state.selectedPhysician
                                            }
                                        />
                                    ) : null}
                                </>
                                {this.state.calingFrom !== 'upload' ? (
                                    <div className='row grid-row-group'>
                                        {this.state.byPatientRender ===
                                            true && (
                                            <ByPatient
                                                state={{ ...this.state }}
                                                updateState={(state) => {
                                                    this.setState(
                                                        { ...state },
                                                        () => {
                                                            this.updateState(
                                                                state
                                                            );
                                                        }
                                                    );
                                                }}
                                                editPatient={
                                                    this.state.edit_patient
                                                }
                                                submitEditPatient={() => {
                                                    this.setState({
                                                        submit_edit_patient: true,
                                                    });
                                                }}
                                                submitNormalCreatePatient={() => {
                                                    this.setState({
                                                        submit_normal_create_patient: true,
                                                    });
                                                }}
                                                openNewPatient={
                                                    this.openNewPatient
                                                }
                                                byOrder={false}
                                                calingFrom='outbox'
                                                disableBtn={
                                                    this.state.disableBtn
                                                }
                                                patientLink={
                                                    this.state.patientLink
                                                }
                                            />
                                        )}
                                    </div>
                                ) : null}

                                {this.state.view_patient &&
                                this.state.patient_filter.patient_name !==
                                    undefined ? (
                                    <ViewEditPatient
                                        // newReferral={
                                        // this.state.formData.new_referral
                                        // }
                                        resetSubmit={() => {
                                            this.setState({
                                                submit_edit_patient: false,
                                            });
                                        }}
                                        patient_filter={
                                            this.state.patient_filter
                                        }
                                        selectedPatient={
                                            this.state.patient_filter
                                        }
                                        editPatient={this.state.edit_patient}
                                        submitEditPatient={
                                            this.state.submit_edit_patient
                                        }
                                        patientFieldIdArray={
                                            this.state.modifyPatientFieldIdArray
                                        }
                                        patientModifiableRecordsetId={
                                            this.state
                                                .modifyPatientModifiableRecordsetId
                                        }
                                        closeEditPatient={(formData) =>
                                            this.closeEditPatient(formData)
                                        }
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                        }}
                                        byPatient={this.byPatient}
                                        // byOrder={this.state.by_order}
                                        allRecordSetID={
                                            this.state.allRecordSetID
                                        }
                                        updateFromCreate={
                                            this.submitEditPatient
                                        }
                                        open_edit_patient
                                        patient_form_apperance='normal'
                                        calingFrom='outbox'
                                        patientValidation={
                                            this.state.patientValidation
                                        }
                                        editPatientDataDetail={
                                            this.state.editPatientDataDetail
                                        }
                                    />
                                ) : null}
                                {this.state.open_create_patient === true ? (
                                    <CreateNewPatient
                                        closeCreatePatient={(formData) =>
                                            this.closeCreatePatient(formData)
                                        }
                                        branches={this.props.branches}
                                        patientFieldIdArray={
                                            this.state.patientFieldIdArray
                                        }
                                        patientModifiableRecordsetId={
                                            this.state
                                                .patientModifiableRecordsetId
                                        }
                                        submitCreatePatient={
                                            this.state.submitCreatePatient
                                        }
                                        submitNormalCreatePatient={
                                            this.state
                                                .submit_normal_create_patient
                                        }
                                        resetSubmit={() => {
                                            this.setState({
                                                submitCreatePatient: false,
                                                submit_normal_create_patient: false,
                                            });
                                            this.updateState({
                                                submitCreatePatient: false,
                                                submit_normal_create_patient: false,
                                            });
                                        }}
                                        submittingViaBigView={
                                            this.state.submittingViaBigView
                                        }
                                        submittingViaUpload={
                                            this.state.submittingViaUpload
                                        }
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                            this.updateState({ ...state });
                                        }}
                                        submitDetailForm={() => {
                                            this.props.submitDetailForm();
                                        }}
                                        allRecordSetID={
                                            this.state.allRecordSetID
                                        }
                                        allowedFields={this.state.allowedFields}
                                        validateForUpload={
                                            this.props.validateForUpload
                                        }
                                        isDetailBigView={
                                            this.state.isDetailBigView
                                        }
                                        open_create_patient={
                                            this.state.open_create_patient
                                        }
                                        patient_form_apperance='normal'
                                        calingFrom='bigView'
                                        functionPatientData={(e) =>
                                            this.functionPatientData(e)
                                        }
                                        patientValidation={
                                            this.state.patientValidation
                                        }
                                        editPatientDataDetail={
                                            this.state.editPatientDataDetail
                                        }
                                        disableBtn={this.state.disableBtn}
                                        modifyPatientFieldIdArray={
                                            this.state.modifyPatientFieldIdArray
                                        }
                                        byPatientRender={
                                            this.state.byPatientRender
                                        }
                                    />
                                ) : null}
                                {this.state.calingFrom !== 'upload' && (
                                    <>
                                        <div
                                            className='more_info pl-4'
                                            onClick={this.openMore}
                                        >
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a className='my-4'>
                                                {this.state.more_information ? (
                                                    <div title='Less Information'>
                                                        <i className='fa-regular fa-chevron-up' />
                                                        <button type='button'>
                                                            {' '}
                                                            Less Information
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div title='More Information'>
                                                        <i className='fa-regular fa-chevron-down' />
                                                        <button type='button'>
                                                            {' '}
                                                            More Information
                                                        </button>
                                                    </div>
                                                )}
                                            </a>
                                        </div>
                                        {this.state.more_information ===
                                            true && (
                                            <DetailMoreInformation
                                                miscPproperties={
                                                    this.props
                                                        .selectedRowDetails
                                                        .miscPproperties
                                                }
                                                signature_required={
                                                    this.state
                                                        .signature_required
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getListaction: getListaction,
    setSelectedRow: setSelectedRow,
    getRulesJson: getRulesJson,
    showLoader: (data) => showLoader(data),
    hideBigView: (data) => hideBigView(data),
    getDDLlist: getDDLlist,
    getSelectedRowDetails: getSelectedRowDetails,
    outboxPage,
};
const mapStateToProps = (state, ownProps) => {
    return {
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        rulesJson: state.persist.rulesJson,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.outbox.selectedRowDetails,
        ddl: state.persistDDL.DDL,
        currentMenuName: state.persist.currentMenuName,
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DetailSection));
