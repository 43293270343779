import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { updateUserDetails } from '../api/api';
import {
    normalSuccessToast,
    showErrorToast,
} from '../../components/commonfunction';

const ResetPasswordModal = (props) => {
    const [newPassword, setNewPassword] = useState('');
    const [validateError, setValidateError] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const postLoading = `post_loading_${Math.random()}`;
    const handleResetPasswordUser = () => {
        setBtnLoader(true);
        if (newPassword === '') {
            setValidateError(true);
            setBtnLoader(false);
            return false;
        }
        const userId = props.selectedUserDetails.userid;
        const fields = {};
        fields.userId = props.selectedUserDetails.userid;
        // fields.username = this.state.userName;
        // fields.email = this.state.userEmail;
        // fields.name = this.state.userDisplayName;
        // fields.disabled = true;
        fields.password = newPassword;
        let status;
        updateUserDetails(userId, fields)
            .then((response) => {
                status = response.status;
                if (status === 204) {
                    setBtnLoader(false);
                    normalSuccessToast(
                        `The User ${props.selectedUserDetails.username}  Password has been changed successfully.`
                    );
                    props.updateState({
                        passwordModal: false,
                    });
                    setNewPassword('');
                }
            })
            .then((data) => {
                if (status === 400 || status === 404) {
                    setBtnLoader(false);
                    showErrorToast('Unable to reset the password');
                }
            });
    };

    const handleCloseOperation = () => {
        setNewPassword('');
        setValidateError(false);
        setBtnLoader(false);
        props.updateState({
            passwordModal: false,
        });
    };
    const handleUpdateText = (event) => {
        setNewPassword(event.target.value);
    };

    return (
        <>
            <Modal
                show={props.passwordModal}
                animation={false}
                onHide={handleCloseOperation}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <form>
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>Reset Password</div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={handleCloseOperation}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes'>
                        <h6 className='info_msg'>
                            You are resetting the password for{' '}
                            <b>{props.selectedUserDetails.username} </b>
                            {props.selectedUserDetails.email !== ''
                                ? '(' + props.selectedUserDetails.email + ')'
                                : ''}
                            .<br />
                            Make sure you share it with them once you change it
                            here. When they login with this new password,
                            they'll be prompted to reset it to something they
                            choose.
                        </h6>
                        <div className='form-group row '>
                            <div className='col-lg-6 mt-5 transition-select'>
                                <label className='pl-5'>NEW PASSWORD</label>
                                <input
                                    type='text'
                                    placeholder='Type it here'
                                    className={
                                        validateError === true &&
                                        newPassword === ''
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={newPassword}
                                    onChange={(event) =>
                                        handleUpdateText(event)
                                    }
                                />
                                {validateError === true &&
                                newPassword === '' ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Password cannot be empty.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    variant='secondary'
                                    onClick={handleCloseOperation}
                                    title='Cancel'
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                {btnLoader === true ? (
                                    <Button
                                        type='button'
                                        variant='success'
                                        className='post_loading'
                                        id={postLoading}
                                    >
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        variant='success'
                                        onClick={() => {
                                            handleResetPasswordUser();
                                        }}
                                        title='Save Changes'
                                        type='submit'
                                    >
                                        Save Changes
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};
export default ResetPasswordModal;
