/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
//import SVG from "react-inlinesvg";
import { getConfig, checkIsActive } from '../../../../_helpers';
import { formatNameWithSpaceTohipen } from '../../../../../app/components/commonfunction';
import { useSelector, useDispatch } from 'react-redux';
import { updateSearchState } from '../../../../../app/pages/_redux/actions/searchActions';
// import { getListaction, updateOutboxState } from '../../../../../app/pages/_redux/actions/outboxActions';
export function UserMenu({ layoutProps }) {
    const location = useLocation();
    const history = useHistory();

    const configData = getConfig();

    const menuItems = configData.length > 0 ? configData[0].menuItems : [];

    var show_user_management = 0;
    if (menuItems && menuItems.length > 0) {
        menuItems.map((data, key) => {
            if (data.menuName.toLowerCase() == 'user administration') {
                show_user_management = 1;
            }
        });
    }
    React.useEffect(() => {}, [location]);

    const current_path = location.pathname;
    const path_arr = current_path.split('/');
    var current_menu = path_arr[1];
    var current_menu_cat = '';
    if (path_arr.length >= 2) {
        current_menu_cat = path_arr[2];
    }

    const reduxStore = useSelector((state) => state);

    let dispatch = useDispatch();

    // END: Worked by vijay for adding sample data for menu section on 01-07-2021
    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const { pathname } = location;

        return pathname === '/';
    };

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` menu-item-open ` : '';
    };

    const addNewUserAction = () => {
        history.push('/users/users?create=new');
    };
    const checkOnClick = () => {
        // var element = document.getElementById('User-Management');
        // if (element.className.includes('menu-item-open') === true) {
        //     element.classList.remove('menu-item-open');
        // } else {
        //     element.classList.add('menu-item-open');
        // }
    };

    return (
        <div
            id='kt_aside'
            className='aside aside-left rounded-lg   aside-fixed d-flex flex-column flex-row-auto'
        >
            <div
                id='kt_aside_menu_wrapper'
                className='aside-menu-wrapper flex-column-fluid'
                style={{ height: '50%' }}
            >
                <div
                    id='kt_aside_menu'
                    data-menu-vertical='1'
                    className='aside-menu '
                >
                    <ul className='menu-nav p-0' key={'aside-main-user'}>
                        {/*begin::1 Level*/}
                        <li
                            className={`menu-item menu-item-submenu
										  ${getMenuItemActive('/users/my-account', true)}
										  ${getMenuItemActive('/users/change-password', true)}`}
                            // aria-haspopup='true'
                            data-menu-toggle='hover'
                        >
                            <NavLink
                                className={`menu-link menu-toggle`}
                                to='/users/my-account'
                            >
                                <span className='menu-text'>
                                    PERSONAL SETTINGS
                                </span>
                                <i className='menu-arrow' />
                            </NavLink>
                            <div className='menu-submenu '>
                                <ul className='menu-subnav'>
                                    <ul className='menu-subnav'>
                                        <li
                                            className='menu-item  menu-item-parent'
                                            // aria-haspopup='true'
                                        >
                                            <span className='menu-link'>
                                                <span className='menu-text'>
                                                    PERSONAL SETTINGS
                                                </span>
                                            </span>
                                        </li>

                                        {/*begin::2 Level*/}
                                        <li
                                            className={`menu-item ${getMenuItemActive(
                                                '/users/my-account'
                                            )}`}
                                            // aria-haspopup='true'
                                        >
                                            <NavLink
                                                className='menu-link'
                                                to='/users/my-account'
                                            >
                                                <span className='menu-text'>
                                                    My Account
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li
                                            className={`menu-item ${getMenuItemActive(
                                                '/users/change-password'
                                            )}`}
                                            // aria-haspopup='true'
                                        >
                                            <NavLink
                                                className='menu-link'
                                                to='/users/change-password'
                                            >
                                                <span className='menu-text'>
                                                    Change Password
                                                </span>
                                            </NavLink>
                                        </li>
                                        {/*end::2 Level*/}
                                    </ul>
                                </ul>
                            </div>
                        </li>
                        {/*end::1 Level*/}

                        {/*begin::1 Level*/}
                        <li
                            className={`menu-item menu-item-submenu
										  ${getMenuItemActive('/users/users', true)}
										  ${getMenuItemActive('/users/groups', true)}`}
                            style={{
                                display: show_user_management
                                    ? 'block'
                                    : 'none',
                            }}
                            //aria-haspopup='true'
                            id='User-Management'
                            data-menu-toggle='hover'
                        >
                            <NavLink
                                className='menu-link menu-toggle'
                                to='/users/users'
                                onClick={() => checkOnClick()}
                            >
                                <span className='menu-text'>
                                    USERS MANAGEMENT
                                </span>
                                <i className='menu-arrow' />
                            </NavLink>
                            <div className='menu-submenu '>
                                <ul className='menu-subnav'>
                                    <ul className='menu-subnav'>
                                        <li
                                            className='menu-item  menu-item-parent'
                                            //aria-haspopup='true'
                                        >
                                            <span className='menu-link'>
                                                <span className='menu-text'>
                                                    USERS MANAGEMENT
                                                </span>
                                            </span>
                                        </li>

                                        {/*begin::2 Level*/}
                                        <li
                                            className={`menu-item  ${getMenuItemActive(
                                                '/users/users'
                                            )}`}
                                            // aria-haspopup='true'
                                        >
                                            <NavLink
                                                className='menu-link'
                                                to='users'
                                            >
                                                <span className='menu-text'>
                                                    Users Management
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li
                                            className={`menu-item ${getMenuItemActive(
                                                '/users/groups'
                                            )}`}
                                            // aria-haspopup='true'
                                        >
                                            <NavLink
                                                className='menu-link'
                                                to='/users/groups'
                                            >
                                                <span className='menu-text'>
                                                    Groups Management
                                                </span>
                                            </NavLink>
                                        </li>
                                        {/*end::2 Level*/}
                                    </ul>
                                </ul>
                            </div>
                        </li>
                        {/*end::1 Level*/}
                    </ul>
                </div>

                <div
                    className='left-nav-add-user'
                    style={{ marginLeft: '10px', display: show_user_management ? 'block' : 'none' }}
                >
                    {' '}
                    <Button
                        className='empty'
                        variant='success'
                        onClick={() => addNewUserAction()}
                        title='Add New User'
                    >
                        <i className='fas fa-plus'></i> Add New User
                    </Button>
                </div>
            </div>
        </div>
    );
}
