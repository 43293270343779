import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function FilterDeleteModal(props) {
    const handleOpenNewPhysicain = (state, property) => {
        props.handleOpenNewPhysicain(state, property);
    };

    const handleCloseOperation = () => {
        props.updateState({
            deleteQuery: false,
        });
    };
    return (
        <>
            <Modal
                show={props.deleteQuery}
                onHide={handleCloseOperation}
                animation={false}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>
                            <i
                                title='Delete'
                                className='fa-solid fa-user-doctor'
                            />
                            Add New Physician
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleCloseOperation}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='deletes'>
                    <h6>
                        The physician record does not exist in the physician
                        directory. <br />
                        Do you want to create a new record?
                    </h6>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                variant='secondary'
                                onClick={handleCloseOperation}
                                title='Cancel'
                            >
                                No
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                variant='success'
                                onClick={() =>
                                    handleOpenNewPhysicain(
                                        props.stateRemoveFilter,
                                        props.propertyRemoveFilter
                                    )
                                }
                                title='Yes'
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default FilterDeleteModal;
