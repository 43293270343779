/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import TablePagination from '@material-ui/core/TablePagination';
import { isMobile } from 'react-device-detect';
import DataGrid from 'react-data-grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';

import {
    recordSetSearch,
    deleteRecordSet,
    createNewPatient,
} from '../../pages/api/api';
import AddLocationModal from '../../pages/search/components/AddLocationModal';
import UnlinkAddressModal from '../../pages/search/components/UnlinkAddressModal';
import {
    formatHeaderNameWithOutSpace,
    GetFieldData,
    NoRecordFound,
    formatPathName,
    handleClickOutside,
    handleColumnsReorder,
    sortFields,
    sortMethod,
    customizedWidth,
    ObjectPropertyToLowerCase,
    ObjectPropertyToUpperCase,
    headerDataFormating,
    formatDate,
    // dateDisplayFormatter,
    dateCellFormatter,
    dateTimeDisplayFormatter,
    manipulatDataFromHeader,
    showErrorToast,
    ownerCellFormatter,
    EpisodeSearch,
    getDocumentsCoordinator,
    typeCellFormatter,
    noteCellFormatter,
    formatNameWithSpaceToUnderscore,
} from '../commonfunction';
import { inboxPage } from '../../pages/_redux/actions/inboxActions';
import {
    updateSearchState,
    deletedPage,
} from '../../pages/_redux/actions/searchActions';
import ToggleGroupFormatter from './ToggleGroupFormatter';
import DropDownCoordinator from '../coordinator/DropDownCoordinator';
import { RowRenderer } from './RowRenderer';
import { SpinnerBlur } from '../Spinner';

const _ = require('lodash');

class LocationListingDataGrid extends React.Component {
    // static contextType = ThemeContext;
    constructor(props) {
        super(props);
        this.state = {
            toggleRecentDoc: false,
            selectedDoc: [],
            docIds: [],
            header: [],
            rowData: [],
            selectedRows: new Set(),
            selectedDocIds: [],
            currentPage: 0,
            perPage: 10,
            filterTxt: '',
            refreshDataGrid: '',
            filteredData: [],
            physicianFieldIdArray: [],
            physicianRecordsetId: '',
            modifyPhysicianRecordsetId: '',
            physicianId: this.props.physicianId,
            npi: this.props.npi,
            groupBy: [],
            groupByData: {},
            row_transition: false,
            defaultPopOpenState: {},
            expandedGroupIds: new Set(),
            dateFormatFields: [],
            modifiedColumnsNames: new Set(),
            rowClickClass: [],
            customGroupedData: [],
            defaultOpen: true,
            groupLoader: true,
            openedRowId: [],
            openAddLocation: false,
            open_create_edit: false,
            location_autocomplete: true,
            locationId: '',
            physician_location_id: '',
            autoCompeteLocation: false, // aiswarya reference
            add_or_edit: 'add',
            isModifiable: '',
            locationByPhysicianFieldIdArray: [],
            locationByPhysicianRecordsetId: '',
            modifyLocationByPhysicianRecordsetId: '',
            uniqueAddressID: [],
            showAddLocation: false,
            unlinkState: false,
            listingFor: 'location',
            coOrdinatorArray: [],
            show_owner: true,
        };
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.nextPage = this.nextPage.bind(this);

        this.createGroupedData = this.createGroupedData.bind(this);
        this.removeAllGrouping = this.removeAllGrouping.bind(this);
        this.resetPopOverData = this.resetPopOverData.bind(this);
        this.createGroupByRows = this.createGroupByRows.bind(this);
        this.sortRows = this.sortRows.bind(this);
    }

    async componentDidMount() {
        let configuredRecordsets = [];
        // let physician_FieldIdArray;
        // let physician_RecordsetId;
        // let physician_ModifiableRecordsetId;
        let physician_directory_FieldIdArray;
        let physician_directory_RecordsetId;
        let physician_directory_ModifiableRecordsetId;
        let location_by_physician_FieldIdArray;
        let location_by_physician_RecordsetId;
        let location_by_physician_ModifiableRecordsetId;
        let coordinator_FieldIdArray;
        let coordinator_RecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physicianlocations'
                ) {
                    // physician_FieldIdArray = data.fields;
                    // physician_RecordsetId = data.recordsetId;
                    // physician_ModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectorylocations'
                ) {
                    physician_directory_FieldIdArray = data.fields;
                    physician_directory_RecordsetId = data.recordsetId;
                    physician_directory_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'locationsbyphysician'
                ) {
                    location_by_physician_FieldIdArray = data.fields;
                    location_by_physician_RecordsetId = data.recordsetId;
                    location_by_physician_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'admissioncoordinator'
                ) {
                    // compile error need to check
                    coordinator_FieldIdArray = data.fields;
                    coordinator_RecordsetId = data.recordsetId;
                }

                return data;
            });
        }

        await this.setState(
            {
                physicianDirectoryFieldIdArray:
                    physician_directory_FieldIdArray,
                physicianDirectoryRecordsetId: physician_directory_RecordsetId,
                modifyPhysicianDirectoryRecordsetId:
                    physician_directory_ModifiableRecordsetId,
                locationByPhysicianFieldIdArray:
                    location_by_physician_FieldIdArray,
                locationByPhysicianRecordsetId:
                    location_by_physician_RecordsetId,
                modifyLocationByPhysicianRecordsetId:
                    location_by_physician_ModifiableRecordsetId,

                coordinatorFieldIdArray: coordinator_FieldIdArray,
                coordinatorRecordsetId: coordinator_RecordsetId,
            },
            () => {
                this.getPhysicianListData();
            }
        );

        this.setState({ row_transition: false });
    }

    async componentDidUpdate(prevState, nextState) {
        if (String(prevState.filterTxt) !== String(this.props.filterTxt)) {
            this.setState(
                {
                    filterTxt: this.props.filterTxt,
                },
                () => {
                    this.initialConfigExecAfterState();
                }
            );
        }

        if (prevState.selectedDocId !== this.props.selectedDocId) {
            this.setState({
                toggleRecentDoc: false,
                selectedDoc: [],
                openPhysician: false,
                docIds: [],
            });
        }

        if (nextState.address_id !== this.state.address_id) {
            await this.props.deletedPage({
                address_id: this.state.address_id,
            });
        }
        if (this.props.search.deletedPage.coordinatorChanged === true) {
            this.props.deletedPage({
                coordinatorChanged: false,
            });
            this.getPhysicianListData();
        }
    }

    initialConfigExecAfterState(
        params = {
            searchMode: false,
        }
    ) {
        let paginatedRowData = this.state.rowData;
        if (this.state.filterTxt.trim() !== '') {
            paginatedRowData = this.filterDataGrid(
                this.state.filterTxt,
                params.searchMode
            );

            this.setState({
                allFilteredRowData: paginatedRowData,
                totalCount: paginatedRowData.length,
                selectedRows: new Set(),
            });
            this.props.inboxPage({
                allFilteredRowData: paginatedRowData,
            });
        } else {
            if (this.state.groupBy.length === 0) {
                const startIndex = 0;
                const endIndex = startIndex + parseInt(this.state.perPage, 10);
                // const fields = sortFields(
                //     this.state.sortColumn,
                //     this.state.sortDirection,
                //     this.state.dateFormatFields
                // );
                // paginatedRowData = _.orderBy(
                //     paginatedRowData,
                //     fields.sortfn,
                //     fields.orderBy
                // ); // Sorting

                if (this.state.sortColumn) {
                    let columnDataType;
                    this.state.header.map((item, index) => {
                        if (item.key === this.state.sortColumn) {
                            columnDataType = item.datatype;
                        }
                    });
                    paginatedRowData = sortMethod(
                        paginatedRowData,
                        this.state.sortColumn,
                        this.state.sortDirection,
                        this.state.dateFormatFields,
                        columnDataType
                    );
                }
                paginatedRowData = _.slice(
                    paginatedRowData,
                    startIndex,
                    endIndex
                ); // Pagination
            }
            this.setState(
                {
                    filteredRowData: paginatedRowData,
                    currentPage: 0,
                    perPage: this.state.perPage,
                    totalCount: this.state.rowData.length,
                },
                () => {
                    this.resetPopOverData();
                }
            );
        }
    }

    getPhysicianListData = async () => {
        if (this.props.search.deletedPage.loaderHide !== true) {
            this.setState({ loader: true });
        }

        this.resetDataGrid();
        const fieldId = GetFieldData({
            configData: this.props.configData,
        });
        // eslint-disable-next-line prefer-const
        let searchText;

        // coordinatorFieldIdArray:coordinator_FieldIdArray,
        // coordinatorRecordsetId:coordinator_RecordsetId,
        await recordSetSearch(this.state.coordinatorRecordsetId, searchText)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const newArray = EpisodeSearch(data);
                this.setState({
                    coOrdinatorArray: EpisodeSearch(data),
                });
                const color_code = [
                    '#ECB445',
                    '#8B36BF',
                    '#00B1BC',
                    '#ffbe0b',
                    '#fb5607',
                    '#ff006e',
                    '#8338ec',
                    '#3a86ff',
                    '#003049',
                    '#d62828',
                    '#f77f00',
                    '#283618',
                    '#bc6c25',
                    '#1b4332',
                    '#1a759f',
                ];
                let i = 0;
                newArray.map((arrayData, index) => {
                    arrayData['colorCode'] = color_code[i];
                    i++;
                    if (i === 14) {
                        i = 0;
                    }
                });

                const coordinatorList = getDocumentsCoordinator(newArray);

                let RecordsetId;
                if (this.props.configuredRecordsets !== undefined) {
                    this.props.configuredRecordsets.map((data) => {
                        if (data.name === 'Physician Locations') {
                            RecordsetId = data.modifiableRecordsetId;
                        }
                    });
                }

                this.props.updateSearchState({
                    deletedPage: {
                        ...this.props.deleted_page,
                        coordinatorArray: coordinatorList,
                        RecordsetId: RecordsetId,
                        fields: this.props.configData.fields,
                    },
                });
            });
        // byOrderSearchText ='fieldSearch=' + fieldId.order_number + '%3D340129';
        /* searchText =
             'fieldSearch=' +
             fieldId.order_number +
             '%3D' +
             this.state.patientId; */

        //searchText = `fieldSearch=${fieldId.physician_id}%3D${this.state.physicianId}`;
        searchText = `fieldSearch=${fieldId.npi}%3D${this.state.npi}`;

        recordSetSearch(this.state.locationByPhysicianRecordsetId, searchText)
            .then((response) => {
                if (
                    Number(response.status) === Number(400) ||
                    Number(response.status) === Number(404) ||
                    Number(response.status) === Number(403)
                ) {
                    this.setState({ loader: false });
                    showErrorToast('Error while processing ');
                }
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty('dataTable') && data.dataTable !== '') {
                    let length;
                    if (data.dataTable.hasOwnProperty('dataRows')) {
                        length = data.dataTable.dataRows.length;
                    }

                    this.setState({
                        totalCount: length,
                        refreshTab: false,
                    });
                    if (length !== 0) {
                        document.getElementById('location_count').innerHTML =
                            length;
                        document.getElementById(
                            'location_count'
                        ).style.display = 'inherit';
                    } else {
                        document.getElementById('location_count').innerHTML =
                            length;
                        document.getElementById(
                            'location_count'
                        ).style.display = 'none';
                    }
                    setTimeout(() => {
                        this.setState({ refreshTab: true });
                    }, 1000);
                    this.getPhysicianDocData(data.dataTable);
                }
            });
    };

    resetDataGrid = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    handleClick = async () => {
        await this.setState({ openPopover: true });
    };

    generateLocationId = async (id) => {
        await this.setState({ unlinkId: id });
    };

    actionMenu = (header, data) => {
        let entireRow = manipulatDataFromHeader(header, data);
        //entireRow.address_id='';
        return (
            <div className='d-flex action_column align-items-center unlink-btn'>
                {data.isModifiable && (
                    <>
                        <Button
                            variant='outline-primary'
                            className='success'
                            onClick={() => {
                                this.generateLocationId(data.id);
                                this.setState({
                                    unlinkState: true,
                                });
                            }}
                            title='Unlink Location'
                        >
                            <i className='fa-solid fa-link-slash' />
                        </Button>
                        <span style={{ width: '20px' }} />
                    </>
                )}
                {entireRow.address_id !== undefined &&
                entireRow.address_id !== null &&
                entireRow.address_id !== '' ? (
                    <Button
                        variant='primary'
                        className='success'
                        onClick={() => {
                            this.editLocation(header, data);
                        }}
                        title={`Edit Location - ${data.id}`}
                    >
                        Edit Location
                    </Button>
                ) : null}
            </div>
        );
    };

    getPhysicianDocData = async (data) => {
        await this.setState({ relDocLoading: true });
        await this.setState({ showAddLocation: false });
        let rows = [];
        const lowerCase = [];
        const uniqueData = {};
        let header = [];
        let dateFormatFields = [];
        // header.push(SelectColumn)
        if (data !== undefined) {
            let { dataRows, headerColumns } = data;

            const actionArray = {
                name: 'Action',
                fieldId: '',
                datatype: 'AlphaNumeric',
            };
            let currentWidth = 0;
            if (document.getElementById('location_listing')) {
                currentWidth = document
                    .getElementById('location_listing')
                    .getBoundingClientRect().width;
            }

            await headerColumns.push(actionArray);

            const otherColumnWidth = (headerColumns.length - 1) * 150;
            let actionMenuWidth = 220;
            if (currentWidth !== 0) {
                const widthDiff = currentWidth - otherColumnWidth;

                if (widthDiff > 220) {
                    actionMenuWidth = widthDiff;
                }
            }

            // For duplicate header below line handled
            const headerColumnsData = [];
            const headerColumnsData2 = [];
            let nameunique = '';
            let nameunique2 = '';

            let headerColumnsName = [];
            headerColumns.forEach((data, index) => {
                headerColumnsName.push(data.name);
            });

            const counts = {};
            headerColumnsName.forEach(function (x) {
                counts[x] = (counts[x] || 0) + 1;
            });

            for (const property in counts) {
                if (counts[property] > 1) {
                    let totalCount = counts[property];
                    let i = 1;
                    let initiateValue = ' I';
                    if (i <= totalCount) {
                        // eslint-disable-next-line no-loop-func
                        headerColumns.forEach((data, index) => {
                            if (data.name === property) {
                                if (i === 1) {
                                    nameunique2 = data.name;
                                } else {
                                    nameunique2 = data.name + initiateValue;
                                }
                                const headerArray = {
                                    name: nameunique2,
                                    fieldId: data.fieldId,
                                    datatype: data.datatype,
                                };
                                i++;
                                initiateValue = initiateValue + 'I';
                                headerColumnsData2.push(headerArray);
                            }
                        });
                    }
                }
            }

            headerColumns.forEach((data, index) => {
                if (counts[data.name] === 1) {
                    nameunique = data.name;
                } else if (counts[data.name] > 1) {
                    headerColumnsData2.forEach((property, index) => {
                        if (property.fieldId === data.fieldId) {
                            nameunique = property.name;
                        }
                    });
                }
                const headerArray = {
                    name: nameunique,
                    fieldId: data.fieldId,
                    datatype: data.datatype,
                };

                headerColumnsData.push(headerArray);
            });
            headerColumns = headerColumnsData;

            // duplicate header end

            await headerColumns.forEach((data, index) => {
                const headerName = formatHeaderNameWithOutSpace(data.name);
                uniqueData[headerName] = new Set();
                if (data.datatype === 'Date' || data.datatype === 'DateTime') {
                    dateFormatFields = [...dateFormatFields, headerName];
                }
                let headerObj = {
                    key: formatHeaderNameWithOutSpace(data.name),
                    name: data.name,
                    datatype: data.datatype,
                    colname: data.datatype,
                    fieldId: data.fieldId,
                    sortable: false,
                    draggable: true,
                    resizable: true,
                    // width: 123,
                    // colSpan(args) {

                    //     return args.type === 'ROW' && args.row.branch === "BBB" ? 3 : undefined;
                    // }
                };

                switch (data.name.trim().toUpperCase()) {
                    case 'TYPE':
                        headerObj = {
                            ...headerObj,
                            ...{
                                sortable: false,
                            },
                        };
                        break;
                    case 'COORDINATOR':
                        headerObj = {
                            ...headerObj,
                            ...{
                                editor: DropDownCoordinator.bind(this),
                                className: 'ownercolumn',
                                width: 150,
                                editable: true,
                                editorOptions: {
                                    createPortal: true,
                                    editOnClick: true, // For Single Click Open
                                },
                            },
                        };
                        break;
                    case 'ACTION':
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: actionMenuWidth,
                            },
                        };
                        break;
                    default:
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: 150,
                                editable: false,
                            },
                        };
                        break;
                }
                header.push(headerObj);
            });
            //worked for new display name by kalpana - start
            let displayNameObj = {
                key: 'displayname',
                name: 'Display Name',
                colname: 'AlphaNumeric',
                sortable: true,
                draggable: true,
                resizable: true,
                width: 150,
                //  frozen: false,
                className: 'Display Name',
            };
            header.push(displayNameObj);
            uniqueData['displayname'] = new Set();
            //worked for new display name by kalpana - end

            this.setState({ totalColumn: header });
            // header = [SelectColumn].concat(header); //commented to remove header checkbox
            // eslint-disable-next-line no-unused-vars
            let g = 0;
            const uniqueAddressID = [];
            await dataRows.forEach((data, index) => {
                g += 1;
                const lowercaseObj = {};

                const obj = {};
                obj['id'] = data.id;
                obj.isModifiable = data.isModifiable;
                obj.doc_id = data.id;
                lowercaseObj['id'] = data.id;
                const array = data.values;
                // eslint-disable-next-line no-unused-vars
                const address_id = '';
                // array.splice(0, 0, '');// commented to remove row data for checkbox
                array.push(''); // addinf extra column for any actions
                array.forEach((row, i) => {
                    if (header[i] !== undefined) {
                        obj[header[i].key] = row.trim();
                        lowercaseObj[header[i].key] =
                            row.trim().toLowerCase() + header[i].key;

                        switch (header[i].key) {
                            case 'action':
                                obj[header[i].key] = this.actionMenu(
                                    header,
                                    data
                                );

                                break;
                            /* case 'documenttype':
                                obj[header[i].key] = this.imagePreview(
                                    data.id,
                                    row.trim()
                                );
                                //obj[header[i].key] = "s"
                                lowercaseObj[header[i].key] = row
                                    .trim()
                                    .toLowerCase();
                                break; */

                            case 'documenttype':
                                obj[header[i].key] = typeCellFormatter(row);
                                obj.documenttype_rawdata = row;
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;

                            case 'note':
                                obj[header[i].key] = row;
                                obj[header[i].key] = noteCellFormatter(row);
                                obj.lastnote_rawdata = row.trim();
                                obj.note_rawdata = row.trim();
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            case 'coordinator':
                                // eslint-disable-next-line vars-on-top, no-var
                                var ownerValue = '';
                                // eslint-disable-next-line vars-on-top, no-var
                                var ownerId = [];

                                if (this.state.coOrdinatorArray.length > 0) {
                                    this.state.coOrdinatorArray.map(
                                        (ownerData) => {
                                            ownerId.push(
                                                ownerData.coordinator_id
                                            );
                                            if (
                                                ownerData.coordinator_id === row
                                            ) {
                                                ownerValue =
                                                    ownerData.coordinator;
                                            }
                                            return ownerData;
                                            // return 'AAAAA';
                                        }
                                    );
                                }

                                if (ownerId.includes(row)) {
                                    this.state.coOrdinatorArray.map(
                                        (ownerData) => {
                                            if (
                                                ownerData.coordinator_id === row
                                            ) {
                                                ownerValue =
                                                    ownerData.coordinator;
                                            }
                                            return ownerData;
                                            // return 'BBBBB';
                                        }
                                    );
                                } else {
                                    ownerValue = row;
                                    // ownerValue = 'kalpama';
                                }

                                // eslint-disable-next-line no-case-declarations
                                const nameData =
                                    ownerValue.split('#')[0] !== undefined
                                        ? ownerValue.split('#')[0]
                                        : ownerValue; // issue fixes
                                obj.owner_rawdata = nameData;
                                obj.coordinators_rawdata = nameData;
                                obj.coordinator_rawdata = nameData;
                                obj[header[i].key] = ownerCellFormatter(
                                    ownerValue,
                                    obj.doc_id,
                                    this.state.show_owner,
                                    'coordinator'
                                );
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                obj.editable = true;
                                row = ownerValue;
                                break;

                            case 'coordinators':
                                obj.editable = true;
                                obj.owner_rawdata = row;
                                obj.coordinators_rawdata = row;
                                obj.coordinator_rawdata = row;
                                obj[header[i].key] = ownerCellFormatter(
                                    row,
                                    obj.doc_id,
                                    this.state.show_owner,
                                    'coordinator'
                                );
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                obj.editable = true;
                                break;

                            case 'addressid':
                                if (!uniqueAddressID.includes(row)) {
                                    uniqueAddressID.push(row);
                                }

                                break;
                            default:
                                break;
                        }
                        switch (header[i].colname) {
                            case 'Date':
                                obj[`${header[i].key}_rawdata`] = formatDate(
                                    row,
                                    'MM/DD/YYYY hh:mm:ss A'
                                );
                                obj[`${header[i].key}_onlyDate`] = formatDate(
                                    row,
                                    'MM/DD/YYYY'
                                );

                                // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                obj[header[i].key] = formatDate(
                                    row,
                                    'MM/DD/YYYY'
                                );
                                if (header[i].key === 'date&batch') {
                                    obj[header[i].key] = dateCellFormatter(row);
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(
                                        formatDate(row, 'MM/DD/YYYY')
                                    );
                                }
                                break;
                            case 'DateTime':
                                // pls note we r reciving UTC date time('2023-06-29T09:58:42Z') so convert to local date time
                                let dateVal = '';
                                const date = new Date(row);
                                const offset = date.getTimezoneOffset();
                                const localDate = new Date(
                                    date.getTime() - offset * 60 * 1000
                                );
                                dateVal = formatDate(
                                    localDate,
                                    'MM/DD/YYYY HH:mm:ss'
                                );

                                obj[`${header[i].key}_rawdata`] = dateVal;
                                obj[`${header[i].key}_onlyDate`] = formatDate(
                                    row,
                                    'MM/DD/YYYY'
                                );

                                // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                obj[header[i].key] =
                                    dateTimeDisplayFormatter(row);

                                if (header[i].key === 'date&batch') {
                                    obj[header[i].key] = dateCellFormatter(row);
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(
                                        dateTimeDisplayFormatter(row)
                                    );
                                }
                                break;
                            default:
                                if (header[i].key === 'coordinators') {
                                    row =
                                        row.split('#')[0] !== undefined
                                            ? row.split('#')[0]
                                            : row; // issue fixes
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(row);
                                }
                                break;
                        }
                    }
                });

                lowerCase.push(lowercaseObj);
                rows.push(obj);
            });

            this.setState({ uniqueAddressID: uniqueAddressID });
            this.setState({ showAddLocation: true });

            // this.props.updateState({uniqueAddressID: uniqueAddressID });
        }
        rows = rows.filter((value) => {
            return value.id !== this.props.selectedDocId;
        });

        if (isMobile && window.screen.width <= 960) {
            header = header.filter(
                (data) => data.key !== 'select-row' && data.name.trim() !== ''
            );

            const documentTitle = header.filter(
                (data) =>
                    data.key === 'location' ||
                    data.key === 'name' ||
                    data.key === 'displayname'
            );
            const docIndex = header.findIndex(
                (data) =>
                    data.key === 'location' ||
                    data.key === 'name' ||
                    data.key === 'displayname'
            );
            header.splice(docIndex, 1);
            const ArrowFormatter = {
                key: 'toggle',
                name: '',
                colname: '',
                width: 10,
                className: 'arrow-mobile',
                formatter: ({ row }) => {
                    const docid = row.id;
                    const openedRowId = this.state.openedRowId;
                    const toggle = () => {
                        if (openedRowId[docid] === undefined) {
                            openedRowId[docid] = true;
                            this.setState({ openedRowId: openedRowId });
                        } else {
                            openedRowId[docid] = !openedRowId[docid];
                            this.setState({ openedRowId: openedRowId });
                        }
                    };
                    const icon =
                        openedRowId[docid] === true
                            ? 'fa fa-chevron-up'
                            : 'fa fa-chevron-right';
                    return (
                        <div
                            onClick={() => toggle()}
                            className='toggle-it d-flex align-items-center'
                        >
                            {' '}
                            <i className={`${icon}`} aria-hidden='true' />{' '}
                        </div>
                    );
                },
            };
            documentTitle.push(ArrowFormatter);
            header = documentTitle.concat(header); // Adding Document Name at the beginning
        } else {
            header = header.filter(
                (data) =>
                    data.key !== 'select-row' &&
                    data.name.trim() !== '' &&
                    data.name.trim() !== 'Document Name' &&
                    data.name.trim() !== 'Display Name'
            );
        }

        this.setState(
            {
                uniqueData: uniqueData,

                header: header,
                rowData: rows,
                filteredRowData: rows,
                allRows: rows,
                allRowsLoweCase: lowerCase,
                dateFormatFields,
                // refreshDataGrid:true
            },
            () => {
                let paginatedRowData = this.state.rowData;
                // let paginatedRowData = rows;
                paginatedRowData = _.slice(paginatedRowData, 0, 10);

                this.setState({
                    refreshDataGrid: true,
                    rowData: paginatedRowData,
                    filteredRowData: paginatedRowData,

                    relDocLoading: false,
                });
                if (this.props.search.deletedPage.loaderHide !== true) {
                    this.setState({ loader: false });
                }
                if (this.props.search.deletedPage.loaderHide === true) {
                    this.props.deletedPage({
                        loaderHide: false,
                    });
                }
            }
        );
    };

    editLocation = async (header, data) => {
        const locparams = [];
        locparams.push({
            headerColumns: header,
            dataRows: data,
        });

        const singleLocationDetails = manipulatDataFromHeader(header, data);

        await this.setState({
            // locationId: data.id,
            location_autocomplete: false,
            openAddLocation: true,
            initial_view: true,
            autoCompeteLocation: false,
            edit_Location: true,
            add_or_edit: 'edit',
            isModifiable: data.isModifiable,
        });

        if (data.values !== undefined && data.values.length !== 0) {
            this.setState({
                address_id: singleLocationDetails.address_id,
                locationId: singleLocationDetails.address_id,
                location_name: singleLocationDetails.location,
                address: singleLocationDetails.address,
                city: singleLocationDetails.city,
                states: singleLocationDetails.state,
                zipcode: singleLocationDetails.zip,
                physician_location_id: singleLocationDetails.id,
                note: singleLocationDetails.note,
            });
        }
    };

    unlinkLocation = async (physician_location_id) => {
        let status;
        // let recordSetId;
        const multipleIds = [];
        // const recordSetId = this.state.modifyLocationRecordsetId;
        const recordSetId = this.state.modifyPhysicianDirectoryRecordsetId;
        let physician_location_id_abs = Math.abs(physician_location_id);
        // deleteRecordSet(multipleIds, recordSetId, this.props.locationId)
        deleteRecordSet(multipleIds, recordSetId, physician_location_id_abs)
            .then((response) => {
                status = response.status;
                if (status === 204) {
                    const successMessage = () => {
                        this.setState({
                            unlinkState: false,
                        });
                        return (
                            <>
                                <div>Location Unlinked</div>
                                <br />
                                <div>
                                    The location has been unlinked from this
                                    physician. It will still be accessible under
                                    the lookup on “Add Location”.
                                </div>
                            </>
                        );
                    };
                    toast.success(successMessage, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        showTransitionSuccess: false,
                    });
                } else if (response !== '') {
                    return response.json();
                }
            })
            .then((data) => {
                if (status === 400 || status === 404) {
                    showErrorToast('Error in deleting.');
                    this.setState({
                        unlinkSubmitted: 0,
                        unlinkState: false,
                    });
                }
            });
        await this.refreshTable();
    };

    addLocation = () => {
        this.setState({
            openAddLocation: true,
            location_autocomplete: true,
            locationId: '',
            physician_location_id: '',
            initial_view: false,
            edit_Location: false,
        });
    };

    /* onRowClick = (rowDetails, rowHeader) => {
        if (String(rowHeader.key) === 'action') {
            this.setState(
                {
                    selectedDocIds: rowDetails.id,
                },
                async () => {
                    let actionButton = document.querySelectorAll(
                        `.settingsmenu${this.state.selectedDocIds}`
                    );
                    let elementPosition =
                        actionButton[0].getBoundingClientRect(); //Get PopOver Position
                    var position = {
                        top: parseInt(elementPosition.top),
                        left: parseInt(elementPosition.x),
                    };
                    await this.setState({ popOverPosition: position });
                }
            );
        }
       
        if (this.props.callingFrom === 'related_document') {
            return true;
        }


    }; */

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    filterDataGrid(searchText = '') {
        // Fixes for ReEx issue
        const isValid = (str) => {
            // eslint-disable-next-line no-useless-escape
            return !/[~`!#$()%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText.trim()) === false) {
            return [];
        }
        // End
        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        const filterOperation = (rowData) => {
            const filteredData = _.filter(rowData, function (data) {
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                    passed =
                        String(data[property]).toLowerCase().match(inputData) !=
                            null &&
                        String(data[property]).toLowerCase().match(inputData)
                            .length > 0;
                    if (passed) break;
                }
                return passed;
            });
            return filteredData;
        };
        let filteredRowData = [];
        let totalCount = 0;
        const fullRowData = filterOperation(this.state.rowData);
        filteredRowData = this.getRowData(0, this.state.perPage, fullRowData);
        totalCount = fullRowData.length;
        this.setState(
            {
                filterTxt: searchText,
                filteredRowData,
                totalCount,
                currentPage: 0,
            },
            () => {
                this.resetPopOverData(fullRowData);
            }
        );

        if (inputData === '') {
            this.setState({ rowData: this.state.allRows }, () => {
                this.resetPopOverData(this.state.allRows);
            });
        }

        return fullRowData;
    }

    refreshTable = () => {
        const resetIt = () => {
            this.componentDidMount();
            this.setState({
                displayAnimation: true,
                refresh: false,
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                filteredRowData: [],
            });
            setTimeout(() => {
                this.setState({ displayAnimation: false });
            }, 1000);
        };
        this.setState(
            {
                refresh: true,
            },
            () => {
                setTimeout(() => {
                    resetIt();
                }, 100);
            }
        );
    };

    nextPage(event, newPage) {
        const startIndex =
            parseInt(newPage, 10) * parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        let paginatedRowData;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }
        // paginatedRowData = this.state.allRows;
        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            currentPage: newPage,
            filteredRowData: paginatedRowData,
        });
    }

    handleChangeRowsPerPage(event) {
        const startIndex = 0;
        const endIndex = parseInt(event.target.value, 10);
        let paginatedRowData = this.state.allRows;

        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            filteredRowData: paginatedRowData,
            perPage: endIndex,
            currentPage: 0,
        });
    }

    handleSearch = async (searchText) => {
        await this.setState({
            refreshDataGrid: false,
            relDocLoading: true,
        });
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string

        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        const filteredData = _.filter(this.state.allRows, function (data) {
            // filter Operation
            let passed = false;
            for (const property in data) {
                if (property === 'id') {
                    continue;
                } // Excluding few Property from filter
                passed =
                    String(data[property]).toLowerCase().match(inputData) !=
                        null &&
                    String(data[property]).toLowerCase().match(inputData)
                        .length > 0;
                if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
            }
            return passed;
        });
        const length = filteredData.length;

        const paginatedRowData = _.slice(filteredData, 0, 10);
        this.setState({
            filteredData: filteredData,
            relDocLoading: false,
            rowData: paginatedRowData,
            // allRows: filteredData,
            totalCount: length,
            currentPage: 0,
            perPage: 10,
            refreshDataGrid: true,
        });
    };

    draggableColumns() {
        const headerRenderer = (props) => {
            return (
                <DraggableHeaderRenderer
                    {...props}
                    {...this.state}
                    setGrouping={this.createGroupedData}
                    onColumnsReorder={handleColumnsReorder.bind(this)}
                    handleClickOutside={handleClickOutside}
                    sortFields={this.sortRows}
                />
            );
        };
        let header = this.state.header;
        let pathname = this.props.location.pathname;
        pathname = formatPathName(pathname);
        if (header.length > 0 && !isMobile) {
            // HEADER REORDER AND WIDTH SETTING  FROM LOCALSTORAGE
            /* let headerReorder = localStorage.getItem(
                `header-reorder-${pathname}`
            );
            let headerWidth = localStorage.getItem(`header-width-${pathname}`); */

            const pathNameTempReorder = `header-reorder-${this.state.listingFor}-${pathname}`;
            const pathNameTempWidth = `header-width-${this.state.listingFor}-${pathname}`;

            let headerReorder = localStorage.getItem(`${pathNameTempReorder}`);
            let headerWidth = localStorage.getItem(`${pathNameTempWidth}`);

            if (headerReorder !== null && headerReorder !== '') {
                headerReorder = JSON.parse(headerReorder);
                header = headerDataFormating({ headerReorder, header });
            }
            if (headerWidth !== null && headerWidth !== '') {
                headerWidth = JSON.parse(headerWidth);
                header = headerDataFormating({ headerWidth, header });
            }
        } // END
        if (header.length === 0) {
            header = this.state.header;
        }
        header = header.filter((data) => {
            data.rowGroup = false;
            if (data.groupFormatter !== undefined) {
                delete data.groupFormatter; // issue fixes for grouping
            }
            return data;
        });
        return header.map((c) => {
            if (this.state.groupBy.length > 0) {
                c.rowGroup = this.state.groupBy.indexOf(c.key) !== -1;
                if (c.rowGroup) {
                    c.width = c.width > 200 ? c.width : 200;
                    c.groupFormatter = ToggleGroupFormatter.bind(this);
                }
            }
            if (c.key === 'coordinator') {
                c.editor = DropDownCoordinator.bind(this);
                c.editorOptions = { createPortal: true, editOnClick: true };
                c.editable = true;
            }
            if (c.key === 'select-row') {
                c.selectedCount = Array.from(this.state.selectedRows).length;
            }
            c.row_transition = this.state.row_transition;
            if (
                c.key === 'id' ||
                c.key === 'select-row' ||
                this.state.tableDesign === 'simple'
            )
                return c;

            return { ...c, headerRenderer: headerRenderer };
        });
    }

    createGroupedData(data, type = '', mode = false) {
        this.setState({ customGroupedData: [] });

        const groupByData = this.state.groupByData;
        const { expandedGroupIds } = this.state; // Grouping Expansion Id
        for (const property in groupByData) {
            groupByData[property] = groupByData[property].map((data) =>
                data.trim().toLowerCase()
            );
        }
        const str_pad = (num) => {
            const str1 = '';
            return str1.padStart(num, '_');
        };
        if (String(type) === 'ALL') {
            // SELECT ALL option in POPOVER
            groupByData[data.title] = [];
            let collection = data.value;
            collection = collection.map((v) => v.trim().toLowerCase());
            groupByData[data.title] = mode ? collection : [];
            const groupBy = [];
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                openState[names] = false;
            }
            for (const props in groupByData) {
                if (groupByData[props].length > 0) {
                    groupBy.push(props);
                }
            }
            let groupingValueData = Object.values(groupByData);
            groupingValueData = groupingValueData.filter((v) => v.length > 0);
            const expandedArray = Array.from(expandedGroupIds);
            const lastIndexData =
                groupingValueData[groupingValueData.length - 1];
            const str = str_pad(2);
            if (lastIndexData !== undefined) {
                if (expandedArray.length > 0) {
                    if (
                        lastIndexData !== undefined &&
                        lastIndexData.length > 0
                    ) {
                        expandedArray.forEach((v1) => {
                            lastIndexData.forEach((v2) => {
                                v2 =
                                    v2.charAt(0).toUpperCase() +
                                    v2.slice(1, v2.length);
                                expandedGroupIds.add(v2);
                                const parentIndexCount = v1.split('__').length;
                                if (parentIndexCount <= groupBy.length) {
                                    expandedGroupIds.add(`${v1}${str}${v2}`);
                                }
                            });
                        });
                    }
                } else {
                    lastIndexData.forEach((v2) => {
                        v2 =
                            v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                        if (!expandedGroupIds.has(v2)) {
                            expandedGroupIds.add(v2);
                        }
                    });
                }
            }
            // let rowData = this.state.rowData;
            let rowData = this.state.allRows;
            if (!mode && !(groupBy.length > 0)) {
                rowData = _.slice(rowData, 0, this.state.perPage);
            }
            if (this.state.filterTxt !== '') {
                rowData = this.state.filteredRowData;
            }
            this.setState({
                groupBy: groupBy,
                groupByData: groupByData,
                defaultOpen: groupBy.length > 0,
                groupLoader: groupBy.length > 0,
                filteredRowData: rowData,
                // filteredRowData: [],
                selectedRows: new Set(),
                tempExpandedGroupIds:
                    groupBy.length > 0 ? expandedGroupIds : new Set(),
                defaultPopOpenState: openState,
                sortColumn: '',
                sortDirection: 'NONE',
                reInitiateGrid: true,
            });
            if (mode === false) {
                this.resetPopOverData(this.state.rowData);
            }
            return;
        } // End SELECT ALL
        if (
            data.title !== undefined &&
            this.state.groupByData[data.title] === undefined
        ) {
            // Check , value already exist, if not,  assign it to Array
            groupByData[data.title] = [];
        }
        const val = data.value.trim().toLowerCase();
        if (
            String(groupByData[data.title].indexOf(val)) === String(-1) &&
            mode
        ) {
            // mode = true, add value to array
            groupByData[data.title].push(val);
        } else if (!mode) {
            // mode = false , Remove value
            const index = groupByData[data.title].indexOf(val);
            groupByData[data.title].splice(index, 1);
        }
        const groupBy = [];
        const openState = this.state.defaultPopOpenState; // FOR POPOVER OPEN or CLOSE
        for (const names in openState) {
            openState[names] = false; // CLOSING ALL POPOVER
        }
        openState[data.title] = true; // OPENING CURRENT POPOVER
        const groupedValueData = [];
        for (const props in groupByData) {
            if (groupByData[props].length > 0) {
                groupBy.push(props);
                groupByData[props].forEach((d) => {
                    groupedValueData.push(d);
                });
            }
        }
        let groupingValueData = Object.values(groupByData);
        groupingValueData = groupingValueData.filter((v) => v.length > 0);
        const expandedArray = Array.from(expandedGroupIds);
        const lastIndexData = groupingValueData[groupingValueData.length - 1];
        const str = str_pad(2);
        if (lastIndexData !== undefined) {
            // Generate Grouping Expansion Id
            if (expandedArray.length > 0) {
                if (lastIndexData !== undefined && lastIndexData.length > 0) {
                    expandedArray.forEach((v1) => {
                        lastIndexData.forEach((v2) => {
                            v2 =
                                v2.charAt(0).toUpperCase() +
                                v2.slice(1, v2.length);
                            expandedGroupIds.add(v2);
                            const parentIndexCount = v1.split('__').length;
                            if (parentIndexCount <= groupBy.length) {
                                expandedGroupIds.add(`${v1}${str}${v2}`);
                            }
                        });
                    });
                }
            } else {
                lastIndexData.forEach((v2) => {
                    v2 = v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                    if (!expandedGroupIds.has(v2)) {
                        expandedGroupIds.add(v2);
                    }
                });
            }
        } // End
        // let rowData = this.state.rowData;
        let rowData = this.state.allRows;
        if (String(groupBy.length) === String(0)) {
            rowData = _.slice(rowData, 0, this.state.perPage); // Paginating the data
        }

        if (String(this.state.filterTxt) !== '') {
            // rowData = this.state.allFilteredRowData;
            rowData = this.state.filteredRowData;
        }
        // if (!mode && !(groupBy.length > 0)) {
        //     rowData = _.slice(rowData, 0, this.state.perPage);
        // }

        // rowData = this.performGroupingFilter(groupedValueData)

        this.setState({
            groupBy: groupBy, // REQUIRED STATE FOR GROUPING
            groupByData: groupByData,
            defaultOpen: groupBy.length > 0,
            groupLoader: groupBy.length > 0,
            filteredRowData: rowData,
            // filteredRowData: [],
            selectedRows: new Set(),
            // expandedGroupIds:  (groupBy.length > 0) ? expandedGroupIds : new Set(),
            tempExpandedGroupIds:
                groupBy.length > 0 ? expandedGroupIds : new Set(),
            defaultPopOpenState: openState,
            sortColumn: '',
            sortDirection: 'NONE',
            reInitiateGrid: true,
            // uniqueData:""
            // refreshDataGrid :true
        });

        // Abu added @19-01-2022 {
        if (mode === false) {
            this.resetPopOverData(this.state.rowData);
        }
        // }
    }

    sortRows(sortColumn, sortDirection) {
        let columnDataType;
        this.state.header.map((item, index) => {
            if (item.key === sortColumn) {
                columnDataType = item.datatype;
            }
        });

        if (sortDirection !== 'NONE') {
            // let paginatedRowData = this.state.rowData;
            let paginatedRowData = this.state.allRows;
            if (this.state.filterTxt.trim() !== '') {
                paginatedRowData = this.filterDataGrid(this.state.filterTxt);
            }
            paginatedRowData = sortMethod(
                paginatedRowData,
                sortColumn,
                sortDirection,
                this.state.dateFormatFields,
                columnDataType
            );

            paginatedRowData = this.getRowData(
                0,
                this.state.perPage,
                paginatedRowData
            );
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                openState[names] = false;
            }
            this.setState({
                filteredRowData:
                    this.state.groupBy.length > 0
                        ? this.state.rowData
                        : paginatedRowData,
                // filteredRowData:
                currentPage: 0,
                sortDirection: sortDirection,
                sortColumn: sortColumn,
                defaultPopOpenState: openState,
            });
        }
    }

    // Common Function used to regenerate Popover Data while filtering
    resetPopOverData(filteredData = []) {
        const uniqueData = {};
        const uniqueTagNameValues = new Set();
        let makeData = this.state.rowData;
        if (this.state.filterTxt.trim() !== '') {
            makeData = filteredData;
        }

        //  if (this.state.filterTxt.trim() !== '') {
        //     makeData = this.state.allFilteredRowData;
        // }
        // makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        //  makeData=Object.values(this.state.customGroupedData)
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (uniqueData[property] === undefined) {
                    uniqueData[property] = new Set();
                }
                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        uniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        this.state.header?.map((item, idx) => {
                            if (item.datatype === 'DateTime') {
                                if (
                                    formatHeaderNameWithOutSpace(item.name) ===
                                    property
                                ) {
                                    uniqueData[property].add(
                                        formatDate(
                                            data[`${property}_rawdata`],
                                            'MM/DD/YYYY HH:mm:ss'
                                        )
                                    );
                                }
                            } else {
                                uniqueData[property].add(
                                    formatDate(
                                        data[`${property}_rawdata`],
                                        'MM/DD/YYYY'
                                    )
                                );
                            }
                        });
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    uniqueTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    uniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    uniqueData[property].add(data[property]);
                }
            }
        });

        this.setState(
            {
                uniqueData,
                uniqueTagNameValues,
            },
            () => {}
        );
    }

    createGroupByRows(groupedData, indexProp) {
        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            if (String(property) === String(this.state.sortColumn)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.groupByData[property] =
                    this.state.sortDirection === 'ASC'
                        ? rowValue.sort()
                        : rowValue.sort().reverse(); // Performing Sorting
            }
        }
        // End
        let customGroupedData = [];
        const groupResult = {};
        let dateFieldColumn = '';
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        // if (String(indexProp) === 'tags') {
        //     let emptyTagRowNames = [];
        //     for (let [property, rowValue] of Object.entries(
        //         this.state.groupByData
        //     )) {
        //         let index = this.state.groupBy.indexOf(property);
        //         if (index !== -1 && String(rowValue.length) === 0) {
        //             this.state.groupBy.splice(index, 1);
        //         }
        //         if (String(property) === String(indexProp)) {
        //             groupedData.forEach((data) => {
        //                 let tagData = String(data.tags_data).split('|');
        //                 tagData = tagData.map((data) =>
        //                     data.trim().toLowerCase()
        //                 );
        //                 rowValue = rowValue.map((data) =>
        //                     data.trim().toLowerCase()
        //                 );
        //                 rowValue.forEach((tag) => {
        //                     if (tagData.indexOf(tag) !== -1) {
        //                         if (groupResult[tag] === undefined) {
        //                             groupResult[tag] = [];
        //                         }
        //                         groupResult[tag].push(data);
        //                     } else {
        //                         emptyTagRowNames.push(tag);
        //                     }
        //                 });
        //             });
        //             let fields = sortFields(
        //                 this.state.sortColumn,
        //                 this.state.sortDirection,
        //                 this.state.dateFormatFields
        //             );
        //             for (const property in groupResult) {
        //                 groupResult[property] = _.orderBy(
        //                     groupResult[property],
        //                     fields.sortfn,
        //                     fields.orderBy
        //                 );
        //             }
        //         }
        //     }
        //     //this.state.defaultOpen = false;
        //     //this.state.isFilterRowEmpty = Object.keys(groupResult).length === 0;
        //     this.render();
        //     let emptyData = {};
        //     if (emptyTagRowNames.length > 0) {
        //         emptyTagRowNames.forEach((name) => {
        //             emptyData[name] = [];
        //         });
        //     }
        //     var result = { ...emptyData, ...groupResult };
        //     result = ObjectPropertyToUpperCase(
        //         result,
        //         this.state.sortDirection,
        //         this.state.groupByData
        //     );
        //     return result;
        // }

        // Check If it is Date Field And Group It
        const emptyDateBatch = [];

        if (this.state.dateFormatFields.indexOf(indexProp) !== -1) {
            const index = this.state.dateFormatFields.indexOf(indexProp);
            dateFieldColumn = this.state.dateFormatFields[index];
            if (dateFieldColumn === 'date&batch') {
                customGroupedData = _.groupBy(groupedData, 'date_batch');
            } else {
                // customGroupedData = _.groupBy(
                //     groupedData,
                //     `${dateFieldColumn}_rawdata`
                // );
                customGroupedData = _.groupBy(
                    groupedData,
                    `${dateFieldColumn}_onlyDate`
                );
            }
        } else if (modifiedColumnsNames.indexOf(indexProp) !== -1) {
            customGroupedData = _.groupBy(groupedData, `${indexProp}_rawdata`);
        } else {
            customGroupedData = _.groupBy(groupedData, `${indexProp}`);
        }

        customGroupedData = ObjectPropertyToLowerCase(customGroupedData);
        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            const index = this.state.groupBy.indexOf(property);
            if (index !== -1 && rowValue.length === 0) {
                this.state.groupBy.splice(index, 1);
            }
            if (String(property) === String(indexProp)) {
                rowValue.forEach((data, i) => {
                    const dateData = data.trim();
                    if (customGroupedData[dateData] !== undefined) {
                        const fields = sortFields(
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields
                        );
                        customGroupedData[dateData] = _.orderBy(
                            customGroupedData[dateData],
                            fields.sortfn,
                            fields.orderBy
                        );
                        if (this.state.sortColumn) {
                            let columnDataType;
                            this.state.header.map((item, index) => {
                                if (item.key === this.state.sortColumn) {
                                    columnDataType = item.datatype;
                                }
                            });
                            customGroupedData[dateData] = sortMethod(
                                customGroupedData[dateData],
                                this.state.sortColumn,
                                this.state.sortDirection,
                                this.state.dateFormatFields,
                                columnDataType
                            );
                        }

                        groupResult[data] = customGroupedData[dateData];
                    } else {
                        emptyDateBatch.push(dateData);
                    }
                });
            }
        }
        //  this.state.defaultOpen = false;
        // this.state.isFilterRowEmpty = Object.keys(groupResult).length === 0;
        const empty = {};
        if (emptyDateBatch.length > 0) {
            emptyDateBatch.forEach((name) => {
                empty[name] = [];
            });
        }
        this.render();
        let result = { ...empty, ...groupResult };

        result = ObjectPropertyToUpperCase(
            result,
            this.state.sortDirection,
            this.state.groupByData
        );
        // this.setState({filteredRowData:result})
        // added by abu {

        if (this.state.customGroupedData.length === 0) {
            this.setState({ customGroupedData: result }, () => {
                const temp = Object.values(this.state.customGroupedData);
                const tempArray = [];
                temp.map((data) => {
                    data.map((array) => {
                        tempArray.push(array);
                    });
                });
                // this.setState({childUniqueData:tempArray})
                this.makeChildUniqueData(tempArray);
                // this.resetPopOverData(tempArray);
            });
        }
        // }
        return result;
    }

    makeChildUniqueData = (filteredData = []) => {
        const childUniqueData = {};
        const childTagNameValues = new Set();
        let makeData = this.state.rowData;
        makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (childUniqueData[property] === undefined) {
                    childUniqueData[property] = new Set();
                }

                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        childUniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        childUniqueData[property].add(
                            formatDate(
                                data[`${property}_rawdata`],
                                'MM/DD/YYYY'
                            )
                        );
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    childTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    childUniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    childUniqueData[property].add(data[property]);
                }
            }
        });

        this.setState({
            childUniqueData,
            childTagNameValues,
        });
    };

    removeAllGrouping() {
        this.setState({ refreshDataGrid: false });
        let paginatedRowData = this.state.allRows;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.filterDataGrid(this.state.filterTxt);
        }
        const startIndex =
            parseInt(this.state.currentPage, 10) *
            parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        paginatedRowData = this.getRowData(
            startIndex,
            endIndex,
            paginatedRowData
        );
        this.setState({
            groupByData: {},
            groupBy: [],
            filteredRowData: paginatedRowData,
            groupingData: {},
            tempExpandedGroupIds: new Set(),
            expandedGroupIds: new Set(),
            rowClickClass: this.state.row_transition
                ? this.state.rowClickClass
                : [],
            reInitiateGrid: true,
            refreshDataGrid: true,
        });
    }

    render() {
        const onRowClick = async (rowDetails, rowHeader, stateData) => {
            // this.props.updateState({
            //     toggleRecentDoc: true,
            // });
            // if (this.props.toggleRecentDoc === false) {
            //     await this.props.toggleRecentDocFun();
            // }

            await this.setState({
                address_id: rowDetails.addressid,
                id: rowDetails.id,
            });
            await this.props.deletedPage({
                address_id: rowDetails.addressid,
                id: rowDetails.id,
                rowDetails: rowDetails,
            });
            if (this.props.selectedPhysician.isModifiable === true) {
                await this.props.deletedPage({
                    selectedPhysician: this.props.selectedPhysician,
                });
            }
            if (rowHeader.key === 'coordinator') {
                if (this.props.selectedPhysician.isModifiable === false) {
                    const paramsPhysicain = [];
                    const formObj = this.props.selectedPhysician;
                    const formData = [];
                    formData.push(formObj);
                    let createPhysician_FieldIdArray;
                    let createPhysician_RecodsetId;
                    let createPhysician_ModifiableRecordsetId;

                    let configuredRecordsets = this.props.configuredRecordsets;
                    if (configuredRecordsets !== undefined) {
                        configuredRecordsets.map((data) => {
                            if (
                                formatHeaderNameWithOutSpace(data.name) ===
                                'physiciandirectory'
                            ) {
                                createPhysician_FieldIdArray = data.fields;
                                createPhysician_RecodsetId = data.recordsetId;
                                createPhysician_ModifiableRecordsetId =
                                    data.modifiableRecordsetId;
                            }
                            return data;
                        });
                    }
                    if (createPhysician_FieldIdArray !== '') {
                        createPhysician_FieldIdArray.map((data) => {
                            const value =
                                formData[0][
                                    formatNameWithSpaceToUnderscore(data.name)
                                ];
                            if (
                                formData[0][
                                    formatNameWithSpaceToUnderscore(data.name)
                                ] !== undefined &&
                                formData[0][
                                    formatNameWithSpaceToUnderscore(data.name)
                                ] !== ''
                            ) {
                                paramsPhysicain.push({
                                    fieldId: data.fieldId,
                                    value: value,
                                });
                            }
                            return '';
                        });
                    }
                    let create_status;
                    await createNewPatient(
                        paramsPhysicain,
                        createPhysician_ModifiableRecordsetId
                    )
                        .then((response) => {
                            create_status = response.status;
                            return response.json();
                        })
                        .then(async (data) => {
                            if (create_status === 200) {
                                formData.id = data.uniqueId;
                                await this.props.updateState({
                                    selectedPhysician: {
                                        ...this.props.selectedPhysician,
                                        id: data.uniqueId,
                                        isModifiable: true,
                                    },
                                });
                                await this.props.deletedPage({
                                    selectedPhysician: {
                                        ...this.props.selectedPhysician,
                                        id: data.uniqueId,
                                        isModifiable: true,
                                    },
                                });
                            }
                            if (create_status === 400) {
                                showErrorToast('Error while processing');
                                return false;
                            }
                        });
                }
            }
            // setTimeout(() => {
            //
            // await this.props.updateSearchState({
            //     deletedPage: {
            //         //...this.props.deleted_page,
            //     //address_id: rowDetails.addressid,
            //     },
            // });
            // }, 1000);
        };
        // eslint-disable-next-line no-unused-vars
        const selectDoc = (event, docId) => {
            if (event.target.checked) {
                this.setState({
                    selectedDoc: [...this.state.selectedDoc, docId],
                });
            } else {
                const index = this.state.selectedDoc.indexOf(docId);
                if (index !== -1) {
                    const array = this.state.selectedDoc;
                    array.splice(index, 1);
                    this.setState({
                        selectedDoc: array,
                    });
                }
            }
        };

        // eslint-disable-next-line no-unused-vars
        const openPhysician = (docId) => {
            this.setState({
                openPhysician: true,
                docIds: [docId],
            });
        };
        // eslint-disable-next-line no-unused-vars
        const handleReset = () => {
            this.setState({
                openPhysician: false,
            });
        };

        const temp = Array.from(this.state.selectedRows);
        temp.push(this.state.onRowClickId);

        /* var rows = this.state.rowData;allRows
        if(this.state.filteredRowData !=''){
            rows = this.state.filteredRowData;
        } */

        const stateRowData = this.state.filteredRowData;
        // eslint-disable-next-line vars-on-top, no-var
        var defaultHeight = 560;

        let reBuildGrid = {};
        if (this.state.reInitiateGrid) {
            // Need to Set this value to TRUE , if we face ROW COLLAPSE or DATAGRID Design issue
            reBuildGrid = { key: Math.random() };
        }
        let responsiveGrid = {};
        const toggleRowElement = (params) => {
            const { row } = params;
            const docid = row.id;
            const openedRowId = this.state.openedRowId;
            if (openedRowId[docid] === undefined) {
                openedRowId[docid] = true;
                this.setState({ openedRowId: openedRowId });
            } else {
                openedRowId[docid] = !openedRowId[docid];
                this.setState({ openedRowId: openedRowId });
            }
        };
        // isMobile
        if (isMobile && window.screen.width <= 960) {
            responsiveGrid = {
                rowRenderer: (props) => {
                    return (
                        <RowRenderer
                            {...props}
                            fullHeaderData={this.state.header}
                            openedRowId={this.state.openedRowId}
                            modifiedColumnsNames={Array.from(
                                this.state.modifiedColumnsNames
                            )}
                            dateFormatFields={Array.from(
                                this.state.dateFormatFields
                            )}
                            toggleRowElement={toggleRowElement}
                            fromPage='location_listing'
                        />
                    );
                },
                rowHeight: (args) => {
                    const { type, row } = args;
                    let heightVal = this.state.header.length * 30;
                    if (
                        this.state.header.length >= 15 &&
                        this.state.header.length <= 19
                    ) {
                        heightVal = this.state.header.length * 35;
                    } else if (
                        this.state.header.length < 15 &&
                        this.state.header.length >= 12
                    )
                        heightVal = this.state.header.length * 35;
                    else if (
                        this.state.header.length < 12 &&
                        this.state.header.length >= 10
                    )
                        heightVal = this.state.header.length * 40;
                    else if (
                        this.state.header.length < 10 &&
                        this.state.header.length > 4
                    )
                        heightVal = (this.state.header.length - 1) * 45;
                    //else this.state.header.length * 42;
                    if (type === 'GROUP') {
                        return 70;
                    }

                    if (type === 'GROUP') {
                        return 70;
                    }

                    if (
                        this.state.openedRowId[row.id] !== undefined &&
                        this.state.openedRowId[row.id] === true
                    ) {
                        return heightVal;
                    }
                    return 60;
                },
                headerRowHeight: 0,
            };
        }

        // eslint-disable-next-line vars-on-top, no-var, no-redeclare
        var defaultHeight = window.innerHeight - 280;

        return (
            <>
                {this.state.loader ? (
                    <SpinnerBlur show={this.state.loader} />
                ) : null}
                <div className='right-aside py-5' id='right-aside'>
                    {this.state.groupBy.length > 0 ? (
                        <div
                            className='remove-grouping-all'
                            style={{ cursor: 'pointer' }}
                        >
                            <div className='count_no d-none'>
                                <span>12</span>
                            </div>
                            <i className='fa fa-list-alt' aria-hidden='true' />
                            <span
                                title='Remove Grouping'
                                onClick={() => this.removeAllGrouping()}
                            >
                                Remove Grouping{' '}
                            </span>
                        </div>
                    ) : null}
                    <div className='listing-grid grid-physicians '>
                        <div className='d-flex justify-content-between mb-5 pl-7 pr-7 report_header'>
                            <div className='d-flex align-items-center'>
                                <h3 className='m-0 title-color'>Locations</h3>
                            </div>
                            <div
                                className='d-flex filter_part rpt-filter'
                                style={{ maxWidth: '450px' }}
                            >
                                <div className='filter normal_view_filter'>
                                    <input
                                        type='text'
                                        placeholder='Filter'
                                        value={this.state.filterTxt}
                                        onChange={(event) =>
                                            this.filterDataGrid(
                                                event.target.value
                                            )
                                        }
                                        style={{ paddingRight: '34px' }}
                                    />
                                    <i className='fa-regular fa-magnifying-glass' />
                                </div>
                                <div className='reload-outer'>
                                    <span
                                        className='align-self-center reload'
                                        onClick={() => this.refreshTable()}
                                    >
                                        {' '}
                                        <i
                                            title='Refresh'
                                            className={
                                                this.state.displayAnimation
                                                    ? 'fas fa-sync-alt refreshing'
                                                    : 'fas fa-sync-alt'
                                            }
                                        />{' '}
                                    </span>{' '}
                                </div>
                                <span
                                    className='align-self-center add_location'
                                    style={{ marginLeft: '10px' }}
                                >
                                    {' '}
                                    <Button
                                        className='empty'
                                        variant='light'
                                        onClick={() => {
                                            this.addLocation();
                                            this.setState({
                                                add_or_edit: 'add',
                                            });
                                        }}
                                        title='Add Location'
                                    >
                                        <i className='fas fa-plus' /> Add
                                        Location
                                    </Button>
                                </span>
                            </div>
                        </div>
                        <div className='mobile_filter pl-7 pr-7 mb-3'>
                            <div className='filter'>
                                <input
                                    type='text'
                                    placeholder='Filter'
                                    value={this.state.filterTxt}
                                    onChange={(event) =>
                                        this.filterDataGrid(event.target.value)
                                    }
                                    style={{ paddingRight: '34px' }}
                                />
                                <i className='fa-regular fa-magnifying-glass' />
                            </div>
                        </div>

                        <div
                            className={
                                this.state.groupBy.length > 0
                                    ? 'group-added'
                                    : 'no-first-col'
                            }
                            id='location_listing'
                        >
                            {this.state.refreshDataGrid === '' &&
                            this.state.loader === false ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '30px',
                                    }}
                                >
                                    No Locations Found
                                </div>
                            ) : null}

                            {this.state.refreshDataGrid &&
                            this.state.loader !== true ? (
                                <div
                                    className={
                                        this.state.groupBy.length > 0
                                            ? 'group-added no-check-box'
                                            : 'no-first-col'
                                    }
                                >
                                    <DndProvider backend={HTML5Backend}>
                                        <DataGrid
                                            // {...reBuildGrid}
                                            columns={this.draggableColumns()}
                                            rows={stateRowData}
                                            rowKeyGetter={(row) => {
                                                return row.id;
                                            }}
                                            rowsCount={stateRowData.length}
                                            emptyRowsRenderer={NoRecordFound.bind(
                                                this,
                                                this.state.relDocLoading,
                                                'location'
                                            )}
                                            groupBy={this.state.groupBy}
                                            expandedGroupIds={
                                                this.state.tempExpandedGroupIds
                                            }
                                            onExpandedGroupIdsChange={(
                                                groupId
                                            ) => {
                                                this.setState({
                                                    expandedGroupIds: groupId,
                                                    tempExpandedGroupIds:
                                                        groupId,
                                                });
                                            }}
                                            selectedRows={
                                                this.state.selectedRows
                                            }
                                            sortColumns={this.state.sortColumns}
                                            onSortColumnsChange={(
                                                sortColumns
                                            ) => {
                                                this.setState({
                                                    sortColumns: sortColumns,
                                                });
                                            }}
                                            style={{
                                                height: defaultHeight,
                                                // ['--row-width']:'200px !important'
                                            }}
                                            className='fill-grid no-check-box'
                                            rowClass={(row) => {
                                                if (
                                                    this.state.rowClickClass.indexOf(
                                                        row.id
                                                    ) !== -1
                                                ) {
                                                    return 'row-selected-once';
                                                }
                                            }}
                                            defaultColumnOptions={{
                                                sortable: false,
                                                resizable: true,
                                                frozen: false,
                                            }}
                                            rowHeight={52}
                                            rowGrouper={(
                                                groupedData,
                                                indexProp
                                            ) =>
                                                this.createGroupByRows(
                                                    groupedData,
                                                    indexProp
                                                )
                                            }
                                            onRowClick={(
                                                rowDetails,
                                                rowHeader
                                            ) => {
                                                onRowClick(
                                                    rowDetails,
                                                    rowHeader,
                                                    this.state
                                                );
                                            }}
                                            onColumnResize={(idx, width) => {
                                                customizedWidth(
                                                    idx,
                                                    width,
                                                    this.state.header,
                                                    this.props.location
                                                        .pathname,
                                                    this.state.listingFor
                                                );
                                            }}
                                            onSelectedRowsChange={(rows) => {
                                                const selectedRows = rows;
                                                this.setState({
                                                    selectedRows: selectedRows,
                                                    // selectedDocIds: selectedRows
                                                });
                                            }}
                                            headerRowHeight={40}
                                            sortable={false}
                                            {...responsiveGrid}
                                        />
                                    </DndProvider>
                                </div>
                            ) : null}
                        </div>
                        {this.state.refreshDataGrid &&
                        this.state.groupBy.length === 0 &&
                        this.state.rowData.length > 0 ? (
                            <div>
                                <TablePagination
                                    component='div'
                                    count={this.state.totalCount}
                                    page={parseInt(this.state.currentPage, 10)}
                                    rowsPerPage={parseInt(
                                        this.state.perPage,
                                        10
                                    )}
                                    onRowsPerPageChange={(event) =>
                                        this.handleChangeRowsPerPage(event)
                                    }
                                    onPageChange={this.nextPage.bind(this)}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.state.showAddLocation ? (
                    <AddLocationModal
                        openAddLocation={this.state.openAddLocation}
                        location_autocomplete={this.state.location_autocomplete}
                        locationId={
                            this.state.locationId !== undefined
                                ? this.state.locationId
                                : this.state.id
                        }
                        physician_location_id={this.state.physician_location_id}
                        initial_view={this.state.initial_view}
                        physicianId={this.state.physicianId}
                        // open_create_edit={this.state.open_create_edit}
                        updateState={(state) => {
                            this.setState({ ...state });
                            this.props.updateState(state);
                        }}
                        refreshTable={this.refreshTable}
                        edit_Location={this.state.edit_Location}
                        autoCompeteLocation={this.state.autoCompeteLocation}
                        location_name={this.state.location_name}
                        address={this.state.address}
                        city={this.state.city}
                        states={this.state.states}
                        zipcode={this.state.zipcode}
                        note={this.state.note}
                        add_or_edit={this.state.add_or_edit}
                        relDocLoading={this.state.relDocLoading}
                        uniqueAddressID={this.state.uniqueAddressID}
                        isModifiable={this.state.isModifiable}
                        selectedPhysician={this.props.selectedPhysician}
                    />
                ) : null}
                <UnlinkAddressModal
                    unlinkState={this.state.unlinkState}
                    unlinkId={this.state.unlinkId}
                    updateState={(state) => {
                        this.setState({ ...state });
                    }}
                    unlinkLocation={(id) => this.unlinkLocation(id)}
                />
            </>
        );
    }
}

const mapDispatchToProps = {
    inboxPage,
    updateSearchState,
    deletedPage,
};
const mapStateToProps = (state, ownProps) => {
    return {
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        deletedPage: state.search.deletedPage,
        search: state.search,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LocationListingDataGrid));
