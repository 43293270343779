import React from 'react';
import Loader from 'react-loader-spinner';

const Spinner = (props) => {
    return props.show ? (
        <div className='overlay-loader'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};
export default Spinner;
