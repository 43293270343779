/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';
// import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateRecordSet,
    createNewCoordinator,
    createNewPatient,
} from '../../pages/api/api';
import { deletedPage } from '../../pages/_redux/actions/searchActions';
import store from '../../../redux/store';
import CreateCoordinatorModule from './CreateCoordinatorModule';
import {
    stringEmptyOrUndefined,
    IS_MOBILE_DEVICE,
    showErrorToast,
    normalSuccessToast,
    // ownerCellFormatter,
    formatHeaderNameWithOutSpace,
} from '../commonfunction';

export default function DropDownEditor({ row, onRowChange }) {
    const [ownerName, setOwnerName] = useState('');
    const [popUp, setpopUp] = useState(false);
    const [emptyOwner, setemptyOwner] = useState(false);
    const [isModifiableLoction, setIsModifiableLoction] = useState(false);
    const [newAddressId, setNewAddressId] = useState('');
    const [showRemoveOwner, setShowRemoveOwner] = useState(false);
    const dispatch = useDispatch();
    const reduxStore = useSelector((state) => state);

    const newState = store.getState();
    let showCreate = false;
    // const docOwner = [];
    // const owners = [];
    // const color_code = getColorCodes();

    React.useEffect(() => {
        if (
            reduxStore.search.deletedPage !== undefined &&
            reduxStore.search.deletedPage.rowDetails !== undefined &&
            reduxStore.search.deletedPage.rowDetails.owner_rawdata !== ''
        ) {
            setShowRemoveOwner(true);
        } else {
            setShowRemoveOwner(false);
        }
        setOwnerName('');
    }, []);

    const handleOwnerChange = async (val) => {
        let location_FieldIdArray;
        let location_RecordsetId;
        let location_ModifiableRecordsetId;
        let location_by_physician_FieldIdArray;
        let location_by_physician_RecordsetId;
        let location_by_physician_ModifiableRecordsetId;
        let physician_directory_FieldIdArray;
        let physician_directory_RecordsetId;
        let physician_directory_ModifiableRecordsetId;
        let createPhysician_FieldIdArray;
        let createPhysician_RecodsetId;
        let createPhysician_ModifiableRecordsetId;
        let Loction_uniqueId;

        if (newState.search.deletedPage.rowDetails.isModifiable === false) {
            setIsModifiableLoction(true);
            let configuredRecordsets =
                newState.configReducerPersist.configData.configuredRecordsets;

            configuredRecordsets.map((data) => {
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physicianlocations'
                ) {
                    location_FieldIdArray = data.fields;

                    location_RecordsetId = data.recordsetId;
                    location_ModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'locationsbyphysician'
                ) {
                    location_by_physician_FieldIdArray = data.fields;

                    location_by_physician_RecordsetId = data.recordsetId;
                    location_by_physician_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectorylocations'
                ) {
                    physician_directory_FieldIdArray = data.fields;

                    physician_directory_RecordsetId = data.recordsetId;
                    physician_directory_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectory'
                ) {
                    createPhysician_FieldIdArray = data.fields;
                    createPhysician_RecodsetId = data.recordsetId;
                    createPhysician_ModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                return data;
            });
            let rowDetails = newState.search.deletedPage.rowDetails;
            let selectedPhysician =
                newState.search.deletedPage.selectedPhysician;
            let locationParam = [];
            let recordSetId_loction = location_ModifiableRecordsetId;
            let addressId_FieldId;
            let physicainId_FieldId;
            const ownData = val.split('+');
            location_by_physician_FieldIdArray.map((items) => {
                for (const property in rowDetails) {
                    if (
                        formatHeaderNameWithOutSpace(items.name) === property &&
                        formatHeaderNameWithOutSpace(items.name) !==
                            'coordinatorid' &&
                        formatHeaderNameWithOutSpace(items.name) !==
                            'addressid' &&
                        formatHeaderNameWithOutSpace(items.name) !==
                            'coordinator' &&
                        formatHeaderNameWithOutSpace(items.name) !== 'note' &&
                        formatHeaderNameWithOutSpace(items.name) !== 'npi'
                    ) {
                        locationParam.push({
                            fieldId: items.fieldId,
                            value: rowDetails[property],
                        });
                    }
                    if (
                        formatHeaderNameWithOutSpace(items.name) === 'addressid'
                    ) {
                        addressId_FieldId = items.fieldId;
                    }
                    if (
                        formatHeaderNameWithOutSpace(items.name) !==
                        'coordinator'
                    ) {
                        locationParam.push({
                            fieldId: items.fieldId,
                            value: ownData[3],
                        });
                    }
                }
            });
            physician_directory_FieldIdArray.map((items) => {
                if (
                    formatHeaderNameWithOutSpace(items.name) === 'physicianid'
                ) {
                    physicainId_FieldId = items.fieldId;
                }
            });

            let create_status;

            await createNewPatient(locationParam, recordSetId_loction)
                .then((response) => {
                    create_status = response.status;
                    return response.json();
                })
                .then(async (data) => {
                    if (create_status === 200) {
                        Loction_uniqueId = data.uniqueId;
                        const RelationshipParams = [];
                        RelationshipParams.push({
                            fieldId: physicainId_FieldId,
                            value: Math.abs(selectedPhysician.id),
                        });
                        RelationshipParams.push({
                            fieldId: addressId_FieldId,
                            value: Loction_uniqueId,
                        });

                        await setNewAddressId(addressId_FieldId);

                        createNewPatient(
                            RelationshipParams,
                            physician_directory_ModifiableRecordsetId
                        ).then((response) => {
                            create_status = response.status;
                            return response.json();
                        });

                        normalSuccessToast('Coordinator changed sucessfully ');
                        this.props.updateState({
                            openAddLocation: false,
                            relDocLoading: false,
                        });
                        this.setState({
                            validateEdit: false,
                            open_location_dropdown: false,
                            selected_location: '',
                            submitted: 0,
                        });
                    }
                    if (create_status === 400) {
                        showErrorToast(data.message);
                        return false;
                    }
                });
        } else {
            document.getElementById('auto-div').style.display = 'none';
            const ownData = val.split('+');

            let address_id;

            if (newState.search.deletedPage.address_id !== undefined) {
                address_id = Math.abs(newState.search.deletedPage.address_id);
            }
            if (newState.search.deletedPage.rowDetails.isModifiable === false) {
                address_id = Loction_uniqueId;
            }

            // if (newState.search.deletedPage.id !== undefined) {
            //     address_id = Math.abs(newState.search.deletedPage.id);
            // }
            const Coordinator_id = ownData[3];

            const recordSetId = newState.search.deletedPage.RecordsetId;
            const params = [];

            newState.search.deletedPage.fields.map((data) => {
                if (data.name === 'Coordinator ID') {
                    params.push({
                        fieldId: data.fieldId,
                        value: Coordinator_id,
                    });
                }
            });
            let status;
            if (address_id !== 0) {
                updateRecordSet(params, recordSetId, address_id)
                    .then((response) => {
                        status = response.status;
                        if (status === 204) {
                            normalSuccessToast(
                                'Coordinator changed sucessfully '
                            );

                            dispatch(
                                deletedPage({
                                    coordinatorChanged: true,
                                    loaderHide: true,
                                })
                            );
                            // const newCo = ownerCellFormatter(
                            //     'kalpana Raja',
                            //     row.id,
                            //     true
                            // );
                            let displayName = '';
                            const bg_color = '#7F96A5';
                            /* var aa = "<div
                            className='owner-name-list owner-logo'
                                id={`owner-logo-${row.id}`}
                                title={value}
                                style={{ background: bg_color }}
                            >
                                <span style={{ display: 'inline' }}>
                                    <span className='d-flex justify-content-center'>
                                        {displayName}
                                    </span>
                                </span>
                            </div>"; */

                            // document.getElementById('ownerCell-'+row.id).innerHTML = newCo;
                            // document.getElementById('auto-div').style.display = 'none';

                            const div = document.querySelector(
                                `#ownerCell-${row.id}`
                            );

                            const span2 = document.createElement('span');
                            span2.className = 'd-flex justify-content-center';
                            span2.innerHTML = displayName;

                            const span1 = document.createElement('span');
                            span1.style.display = 'inline';
                            span1.innerHTML = span2;

                            const p = document.createElement('div');
                            p.className = 'owner-name-list owner-logo';
                            p.id = `owner-logo-${row.id}`;
                            p.title = displayName;
                            p.style.background = bg_color;
                            p.innerHTML = `<span>---${displayName}</span>`;
                            div.innerHTML = '';

                            if (ownData[0] !== '' && ownData[0] !== undefined) {
                                const value = ownData[0];
                                const name_array = value.split(' ');
                                if (name_array.length > 1) {
                                    displayName = `${name_array[0]
                                        .charAt(0)
                                        .toUpperCase()}${name_array[1]
                                        .charAt(0)
                                        .toUpperCase()}`;
                                } else {
                                    const username = value.substring(0, 3);

                                    if (username === 'WV-') {
                                        // displayName = `${name_array[0].substring(3,2).toUpperCase()}`;
                                        displayName = `${value[3].toUpperCase()}${value[4].toUpperCase()}`;
                                        /* displayName = `${name_array[0]
                                        .charAt(0)
                                        .toUpperCase()}${name_array[0].charAt(1).toUpperCase()}`; */
                                    } else {
                                        displayName = `${name_array[0]
                                            .charAt(0)
                                            .toUpperCase()}${name_array[0]
                                            .charAt(1)
                                            .toUpperCase()}`;
                                    }
                                }
                            }
                            let pp;
                            if (ownData[0] !== '') {
                                pp = `<div class="owner-name-list owner-logo" id="owner-logo-3805" title="${ownData[0]}" style="background: ${ownData[2]}">`;
                                pp += `<span style="display: inline;"><span class="d-flex justify-content-center">${displayName}</span></span></div>`;
                            } else {
                                pp = `<div class="no-user-icon owner-logo" id="owner-logo--${address_id}" title=""><span><span><i class="fas fa-user-plus"></i></span></span></div>`;
                            }
                            div.appendChild = pp;
                            div.innerHTML = pp;

                            /* this.props.updateState({
                            openAddLocation: false,
                            autoCompeteLocation: false,
                            open_location_dropdown: false,
                            locationId: '',
                            relDocLoading: false,
                        });
                        this.setState({
                            validateEdit: false,
                            open_location_dropdown: false,
                            selected_location: '',
                            submitted: 0,
                        }); */
                        } else if (response !== '') {
                            return response.json();
                        }
                    })
                    .then((data) => {
                        if (status === 400 || status === 404) {
                            showErrorToast(data.message);
                        }
                    });
            }
        }
    };
    if (document.getElementById('owner_auto_search_options') !== null) {
        const childElements = document.getElementById(
            'owner_auto_search_options'
        ).children;
        if (stringEmptyOrUndefined(childElements)) {
            for (let i = 0; i < childElements.length; i++) {
                childElements[i].classList.add('owner-div-container');
            }
        }
    }

    var result = newState.search.deletedPage.coordinatorArray.some((i) =>
        i.name.toLowerCase().includes(ownerName)
    );
    const createCoordinatorModule = () => {
        setpopUp(true);
    };
    const handleCloseOperation = () => {
        setpopUp(false);
    };
    const createCoordinator = async (value) => {
        if (ownerName !== '' && ownerName !== null && ownerName !== undefined) {
            const param = [];
            let recordSetId;
            newState.search.deletedPage.fields.map((data) => {
                if (data.name === 'Coordinator') {
                    param.push({
                        fieldId: data.fieldId,
                        value: ownerName,
                    });
                }
            });
            newState.configReducerPersist.configData.configuredRecordsets.map(
                (data) => {
                    if (data.name === 'Admission Coordinator') {
                        recordSetId = data.modifiableRecordsetId;
                    }
                }
            );
            let create_status;
            createNewCoordinator(param, recordSetId)
                .then((response) => {
                    create_status = response.status;
                    return response.json();
                })
                .then(async (data) => {
                    if (create_status === 200) {
                        let Coordinator_id = data.uniqueId;

                        let address_id;
                        if (
                            newState.search.deletedPage.address_id !== undefined
                        ) {
                            address_id = Math.abs(
                                newState.search.deletedPage.address_id
                            );
                        }

                        //const Coordinator_id = ownData[3];

                        const recordSetId =
                            newState.search.deletedPage.RecordsetId;
                        const params = [];

                        newState.search.deletedPage.fields.map((data) => {
                            if (data.name === 'Coordinator ID') {
                                params.push({
                                    fieldId: data.fieldId,
                                    value: Coordinator_id,
                                });
                            }
                        });
                        let location_FieldIdArray;
                        let location_RecordsetId;
                        let location_ModifiableRecordsetId;
                        let location_by_physician_FieldIdArray;
                        let location_by_physician_RecordsetId;
                        let location_by_physician_ModifiableRecordsetId;
                        let physician_directory_FieldIdArray;
                        let physician_directory_RecordsetId;
                        let physician_directory_ModifiableRecordsetId;
                        let createPhysician_FieldIdArray;
                        let createPhysician_RecodsetId;
                        let createPhysician_ModifiableRecordsetId;
                        let Loction_uniqueId;

                        if (
                            newState.search.deletedPage.rowDetails
                                .isModifiable === false
                        ) {
                            setIsModifiableLoction(true);
                            let configuredRecordsets =
                                newState.configReducerPersist.configData
                                    .configuredRecordsets;

                            configuredRecordsets.map((data) => {
                                if (
                                    formatHeaderNameWithOutSpace(data.name) ===
                                    'physicianlocations'
                                ) {
                                    location_FieldIdArray = data.fields;

                                    location_RecordsetId = data.recordsetId;
                                    location_ModifiableRecordsetId =
                                        data.modifiableRecordsetId;
                                }
                                if (
                                    formatHeaderNameWithOutSpace(data.name) ===
                                    'locationsbyphysician'
                                ) {
                                    location_by_physician_FieldIdArray =
                                        data.fields;

                                    location_by_physician_RecordsetId =
                                        data.recordsetId;
                                    location_by_physician_ModifiableRecordsetId =
                                        data.modifiableRecordsetId;
                                }
                                if (
                                    formatHeaderNameWithOutSpace(data.name) ===
                                    'physiciandirectorylocations'
                                ) {
                                    physician_directory_FieldIdArray =
                                        data.fields;

                                    physician_directory_RecordsetId =
                                        data.recordsetId;
                                    physician_directory_ModifiableRecordsetId =
                                        data.modifiableRecordsetId;
                                }
                                if (
                                    formatHeaderNameWithOutSpace(data.name) ===
                                    'physiciandirectory'
                                ) {
                                    createPhysician_FieldIdArray = data.fields;
                                    createPhysician_RecodsetId =
                                        data.recordsetId;
                                    createPhysician_ModifiableRecordsetId =
                                        data.modifiableRecordsetId;
                                }
                                return data;
                            });
                            let rowDetails =
                                newState.search.deletedPage.rowDetails;
                            let selectedPhysician =
                                newState.search.deletedPage.selectedPhysician;
                            let locationParam = [];
                            let recordSetId_loction =
                                location_ModifiableRecordsetId;
                            let addressId_FieldId;
                            let physicainId_FieldId;
                            let coordinatorid_FieldId;
                            location_by_physician_FieldIdArray.map((items) => {
                                for (const property in rowDetails) {
                                    if (
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) === property &&
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) !== 'coordinatorid' &&
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) !== 'addressid' &&
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) !== 'coordinator' &&
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) !== 'note' &&
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) !== 'npi'
                                    ) {
                                        locationParam.push({
                                            fieldId: items.fieldId,
                                            value: rowDetails[property],
                                        });
                                    }
                                    if (
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) === 'addressid'
                                    ) {
                                        addressId_FieldId = items.fieldId;
                                    }
                                    if (
                                        formatHeaderNameWithOutSpace(
                                            items.name
                                        ) === 'coordinatorid'
                                    ) {
                                        coordinatorid_FieldId = items.fieldId;
                                    }
                                }
                            });
                            locationParam.push({
                                fieldId: coordinatorid_FieldId,
                                value: Coordinator_id,
                            });
                            physician_directory_FieldIdArray.map((items) => {
                                if (
                                    formatHeaderNameWithOutSpace(items.name) ===
                                    'physicianid'
                                ) {
                                    physicainId_FieldId = items.fieldId;
                                }
                            });

                            let create_status;

                            await createNewPatient(
                                locationParam,
                                recordSetId_loction
                            )
                                .then((response) => {
                                    create_status = response.status;
                                    return response.json();
                                })
                                .then(async (data) => {
                                    if (create_status === 200) {
                                        Loction_uniqueId = data.uniqueId;
                                        const RelationshipParams = [];
                                        RelationshipParams.push({
                                            fieldId: physicainId_FieldId,
                                            value: Math.abs(
                                                selectedPhysician.id
                                            ),
                                        });
                                        RelationshipParams.push({
                                            fieldId: addressId_FieldId,
                                            value: Loction_uniqueId,
                                        });

                                        await setNewAddressId(
                                            addressId_FieldId
                                        );

                                        createNewPatient(
                                            RelationshipParams,
                                            physician_directory_ModifiableRecordsetId
                                        ).then((response) => {
                                            create_status = response.status;
                                            return response.json();
                                        });

                                        normalSuccessToast(
                                            'Coordinator changed sucessfully '
                                        );
                                        this.props.updateState({
                                            openAddLocation: false,
                                            relDocLoading: false,
                                        });
                                        this.setState({
                                            validateEdit: false,
                                            open_location_dropdown: false,
                                            selected_location: '',
                                            submitted: 0,
                                        });
                                        dispatch(
                                            deletedPage({
                                                coordinatorChanged: true,
                                                loaderHide: true,
                                            })
                                        );

                                        let displayName = '';
                                        const bg_color = '#7F96A5';

                                        const div = document.querySelector(
                                            `#ownerCell-${row.id}`
                                        );

                                        const span2 =
                                            document.createElement('span');
                                        span2.className =
                                            'd-flex justify-content-center';
                                        span2.innerHTML = displayName;

                                        const span1 =
                                            document.createElement('span');
                                        span1.style.display = 'inline';
                                        span1.innerHTML = span2;

                                        const p = document.createElement('div');
                                        p.className =
                                            'owner-name-list owner-logo';
                                        p.id = `owner-logo-${row.id}`;
                                        p.title = displayName;
                                        p.style.background = bg_color;
                                        p.innerHTML = `<span>---${displayName}</span>`;
                                        div.innerHTML = '';

                                        if (
                                            ownerName !== '' &&
                                            ownerName !== undefined
                                        ) {
                                            const value = ownerName;
                                            const name_array = value.split(' ');
                                            if (name_array.length > 1) {
                                                displayName = `${name_array[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${name_array[1]
                                                    .charAt(0)
                                                    .toUpperCase()}`;
                                            }
                                        }

                                        let pp = `<div class="owner-name-list owner-logo" id="owner-logo-3805" title="${ownerName}" style="background: ${ownerName}">`;
                                        pp += `<span style="display: inline;"><span class="d-flex justify-content-center">${displayName}</span></span></div>`;
                                        div.appendChild = pp;
                                        div.innerHTML = pp;

                                        /* this.props.updateState({
                                            openAddLocation: false,
                                            autoCompeteLocation: false,
                                            open_location_dropdown: false,
                                            locationId: '',
                                            relDocLoading: false,
                                        });
                                        this.setState({
                                            validateEdit: false,
                                            open_location_dropdown: false,
                                            selected_location: '',
                                            submitted: 0,
                                        }); */
                                        setpopUp(false);
                                    }
                                    if (create_status === 400) {
                                        showErrorToast(data.message);
                                        return false;
                                    }
                                });
                        }
                        if (
                            newState.search.deletedPage.rowDetails
                                .isModifiable !== false
                        ) {
                            let status;
                            updateRecordSet(params, recordSetId, address_id)
                                .then((response) => {
                                    status = response.status;
                                    if (status === 204 || status === 200) {
                                        normalSuccessToast(
                                            'Coordinator changed sucessfully '
                                        );

                                        dispatch(
                                            deletedPage({
                                                coordinatorChanged: true,
                                                loaderHide: true,
                                            })
                                        );

                                        let displayName = '';
                                        const bg_color = '#7F96A5';

                                        const div = document.querySelector(
                                            `#ownerCell-${row.id}`
                                        );

                                        const span2 =
                                            document.createElement('span');
                                        span2.className =
                                            'd-flex justify-content-center';
                                        span2.innerHTML = displayName;

                                        const span1 =
                                            document.createElement('span');
                                        span1.style.display = 'inline';
                                        span1.innerHTML = span2;

                                        const p = document.createElement('div');
                                        p.className =
                                            'owner-name-list owner-logo';
                                        p.id = `owner-logo-${row.id}`;
                                        p.title = displayName;
                                        p.style.background = bg_color;
                                        p.innerHTML = `<span>---${displayName}</span>`;
                                        div.innerHTML = '';

                                        if (
                                            ownerName !== '' &&
                                            ownerName !== undefined
                                        ) {
                                            const value = ownerName;
                                            const name_array = value.split(' ');
                                            if (name_array.length > 1) {
                                                displayName = `${name_array[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${name_array[1]
                                                    .charAt(0)
                                                    .toUpperCase()}`;
                                            }
                                        }

                                        let pp = `<div class="owner-name-list owner-logo" id="owner-logo-3805" title="${ownerName}" style="background: ${ownerName}">`;
                                        pp += `<span style="display: inline;"><span class="d-flex justify-content-center">${displayName}</span></span></div>`;
                                        div.appendChild = pp;
                                        div.innerHTML = pp;

                                        /* this.props.updateState({
                                            openAddLocation: false,
                                            autoCompeteLocation: false,
                                            open_location_dropdown: false,
                                            locationId: '',
                                            relDocLoading: false,
                                        });
                                        this.setState({
                                            validateEdit: false,
                                            open_location_dropdown: false,
                                            selected_location: '',
                                            submitted: 0,
                                        }); */
                                        setpopUp(false);
                                    } else if (response !== '') {
                                        setpopUp(false);
                                        return response.json();
                                    }
                                })
                                .then((data) => {
                                    if (status === 400 || status === 404) {
                                        showErrorToast(data.message);
                                        setpopUp(false);
                                    }
                                });
                        }
                    }
                })
                .then((data) => {
                    if (create_status === 400 || create_status === 404) {
                        showErrorToast(data.message);
                    }
                });
            setemptyOwner(false);
        } else {
            setemptyOwner(true);
        }
    };

    const ownerNameConfirm = (value) => {
        setOwnerName(value);
    };
    return (
        <>
            <>
                {popUp === true ? (
                    <CreateCoordinatorModule
                        handleCloseOperation={() => handleCloseOperation()}
                        createCoordinator={() => createCoordinator()}
                        ownerNameConfirm={(value) => ownerNameConfirm(value)}
                        ownerName={ownerName}
                        popUp={popUp}
                        emptyOwner={emptyOwner}
                    />
                ) : (
                    <div id='auto-div' style={{ display: 'none' }}>
                        <Autocomplete
                            onMenuVisibilityChange={(isOpen) => {
                                if (isOpen && IS_MOBILE_DEVICE) {
                                    setTimeout(() => {
                                        if (
                                            document.getElementById(
                                                'owner_auto_search_options'
                                            ) !== null
                                        ) {
                                            // document.getElementById('owner_auto_search_options').style.maxHeight = "70px";
                                        }
                                    }, 10);
                                }
                            }}
                            inputProps={{
                                placeholder: 'Search teammate',
                                autoFocus: true,
                            }}
                            items={
                                newState.search.deletedPage.coordinatorArray
                                    ? newState.search.deletedPage
                                          .coordinatorArray
                                    : []
                            }
                            getItemValue={
                                (item) =>
                                    `${item.label}+${item.id}+${item.colorCode}+${item.coordinator_id}`
                                // `${item.coordinator}+${item.id}+${item.colorCode}+${item.coordinator_id}`
                            }
                            shouldItemRender={(item, value) =>
                                item.label
                                    .toLowerCase()
                                    .indexOf(value.toLowerCase()) > -1
                            }
                            renderItem={(item, isHighlighted) => (
                                <div
                                    key={item.id}
                                    style={{
                                        background: isHighlighted
                                            ? 'lightgray'
                                            : 'white',
                                    }}
                                >
                                    {item.title}
                                </div>
                            )}
                            value={ownerName}
                            onChange={(e) => {
                                var regex_email = /^[a-zA-Z0-9\s.\-_',]+$/;
                                var errorValidate = false;

                                if (
                                    !regex_email.test(e.target.value) &&
                                    e.target.value !== ''
                                ) {
                                    errorValidate = true;
                                }
                                if (errorValidate === false) {
                                    setOwnerName(e.target.value);
                                }

                                // row.owner = ownerCellFormatter(e.target.value);
                            }}
                            // onChange={(value) => handleOwnerChange(value)}
                            // onSelect={(value) => {
                            //     setOwnerName(value);
                            //     // row.owner = ownerCellFormatter(value);
                            //     dispatch(setOwner(value));
                            //     document.getElementById('auto-div').style.display = 'none';
                            //     // document.getElementById("kt_content").click();
                            //     // document.getElementById("kt_content").focus();
                            // }} // row.owner = value
                            onSelect={(value) => handleOwnerChange(value)}
                            renderMenu={(items, value) => (
                                <div
                                    id='owner_auto_search_options'
                                    className='owner-list'
                                    style={{
                                        maxHeight: '250px',
                                        overflowY: 'auto',
                                    }}
                                    // eslint-disable-next-line react/no-children-prop
                                    children={items}
                                />
                            )}
                            renderInput={(params) => (
                                <>
                                    <div
                                        className='owner-auto-search d-flex'
                                        id='owner_auto_search'
                                    >
                                        <span>
                                            <i className='fas fa-user-plus' />
                                        </span>
                                        <input
                                            {...params}
                                            id='owner_auto_input'
                                        />
                                        <br />
                                    </div>
                                    {showRemoveOwner && (
                                        <div
                                            className='owner-auto-search d-flex align-items-center'
                                            onClick={() => {
                                                handleOwnerChange('');
                                            }}
                                            //id='owner_auto_search'
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <span>
                                                <i className='fas fa-user-minus' />
                                            </span>
                                            Remove Co-ordinator
                                        </div>
                                    )}
                                </>
                            )}
                        />
                        {result === false && (
                            <div
                                className='owner-auto-search d-flex align-items-center'
                                onClick={() => {
                                    createCoordinatorModule();
                                }}
                                //id='owner_auto_search'
                                style={{ cursor: 'pointer' }}
                            >
                                <span>
                                    <i className='fas fa-user-plus' />
                                </span>
                                Add new coordinator
                            </div>
                        )}
                    </div>
                )}
            </>
        </>
    );
}
