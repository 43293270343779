import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import Autocomplete from 'react-autocomplete';
import update from 'immutability-helper';
import {
    setOwner as setOwnerInbox,
    setSelectedRow as setSelectedRowInbox,
    updateList as updateListInbox,
    updateInboxState,
    inboxPage,
} from '../../pages/_redux/actions/inboxActions';
import {
    setOwner as setOwnerOutbox,
    setSelectedRow as setSelectedRowOutbox,
    updateList as updateListOutbox,
    updateOutboxState,
    outboxPage,
} from '../../pages/_redux/actions/outboxActions';
import {
    multiSelectErrorMessage,
    sortFields,
    ownerCellFormatter,
} from '../commonfunction';
import { addDocumentOwners } from '../../pages/api/api';
//import OwnerAutocomplete from './OwnerAutocomplete';

const _ = require('lodash');
class OwnerRowAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ownerValue: null,
            rowData: this.props.rowData,
            show: false,
        };
    }

    entering = (e) => {
        e.children[0].style.borderTopColor = 'rgb(0, 35, 58)';
        e.children[1].style.backgroundColor = 'rgb(0, 35, 58)';
    };

    handleOwnerChange = async (val) => {
        this.setState({ show: !this.state.show });
        // document.getElementById('dropdown-basic').hidden = true
        let ownData;
        let value;
        let owner_id;
        if (val === null) {
            ownData = '';
            value = '';
            owner_id = '';
        } else {
            ownData = val.split('#');
            value = ownData[0];
            owner_id = ownData[1];
        }

        const rowsToUpdate = [];
        const newRowsToUpdate = [];
        const rowDocs = [];
        let docIds = [];

        let processId;
        let statusId;
        if (String(this.props.processId) !== '') {
            processId = this.props.processId;
        }
        if (String(this.props.statusId) !== '') {
            statusId = this.props.statusId;
        }
        this.props.selectedRows.forEach((rowIndex, i) => {
            rowsToUpdate.push(rowIndex);
            //rowDocs.push(this.props.rowData[rowIndex]);
            this.props.rowData.map((data, index) => {
                if (rowIndex === data.id) {
                    rowDocs.push(this.props.rowData[index]);
                }
            });
        });
        if (rowsToUpdate.length > 0) {
            this.props.setOwner('');
            this.props.setSelectedRow('');
        }
        await rowDocs.map((data) => {
            docIds.push(data.doc_id);
            return data;
        });
        if (this.props.selectedRow && this.props.selectedRow !== '') {
            docIds = [];
            docIds.push(this.props.selectedRow);
            await this.props.rowData.map(async (data, index) => {
                if (data.doc_id === this.props.selectedRow) {
                    await rowsToUpdate.push(index);
                }
                return data;
            });
            this.props.setOwner('');
            this.props.setSelectedRow('');
        }

        let ownerFiledId;
        const ownerActionId = this.props.allActions.assign_to_user.actionId;
        await this.props.configData.fields.map((item) => {
            if (item.name === 'Owner') {
                ownerFiledId = item.fieldId;
            }
        });

        window.scroll({ top: 0, behavior: 'smooth' });
        const newStatus = {};
        newStatus.processId = processId;
        newStatus.statusId = statusId;
        const params = {};
        params.processId = processId;
        params.statusId = statusId;
        params.owner_id = owner_id;
        params.docIds = docIds;
        params.actionId = ownerActionId;
        params.fieldId = ownerFiledId;
        params.statusArr = newStatus;
        let apiDocIds = [];
        let failedDocIds = [];
        //Api call start
        await addDocumentOwners(params)
            .then(async (response) => {
                let result = await response.json();
                return result;
            })
            .then(async (data) => {
                let results = data.results;

                if (data.hadFailures === true) {
                    await results.map(async (item) => {
                        if (item.actionSucceeded === true) {
                            await apiDocIds.push(item.docId);
                        } else if (item.actionSucceeded === false) {
                            await failedDocIds.push(item.docId);
                        }
                    });
                } else {
                    await results.map(async (item) => {
                        await apiDocIds.push(item.docId);
                    });
                }
            })
            .catch((err) => {});
        //api call end here

        if (apiDocIds.length !== 0) {
            await this.props.rowData.map(async (data, index) => {
                if (apiDocIds.includes(data.doc_id)) {
                    await newRowsToUpdate.push(index);
                }
                return data;
            });

            if (this.props.dataTable !== undefined) {
                let ownerIndex = '';
                await this.props.dataTable.headerColumns.map((data, index) => {
                    if (data.name.toLowerCase() === 'owner') {
                        ownerIndex = index;
                    }
                    return data;
                });
                const tableData = this.props.dataTable; //need to check this temp solution
                const rawData = this.props.dataTable.dataRows.slice();
                await this.props.dataTable.dataRows.map(async (data, index) => {
                    if (apiDocIds.includes(data.id)) {
                        const updated = {};
                        updated[ownerIndex] = value;
                        await data.values.map((row, idx) => {
                            if (idx === ownerIndex) {
                                // eslint-disable-next-line dot-notation
                                const rowToUpdate = rawData[index]['values'];
                                const updatedRaw = update(rowToUpdate, {
                                    $merge: updated,
                                });
                                // eslint-disable-next-line dot-notation
                                rawData[index]['values'] = updatedRaw;
                            }
                            return row;
                        });
                    }
                    return data;
                });

                // await this.props.updateList('');
                tableData.dataRows = rawData;
                //  this.setState({ ownerValue: '', //selectedRows: new Set()});
                await this.props.updateList(tableData);
            }

            const updated = {};
            updated.owner = ownerCellFormatter(
                value,
                '',
                true,
                this.props.rowSelectFrom
            );
            const nameData =
                value.split('#')[0] !== undefined ? value.split('#')[0] : value;
            updated.owner_rawdata = nameData;
            const rowData = this.props.rowData.slice();

            await newRowsToUpdate.map(async (index) => {
                this.state.rowData[index].owner_rawdata = updated.owner_rawdata;
                this.state.rowData[index].owner = updated.owner;

                /*
                //I) this one used for filter row data
                    const fields = sortFields(
                        this.props.sortColumn,
                        this.props.sortDirection,
                        this.props.dateFormatFields
                    ); 
                    const startIndex =
                        parseInt(this.props.currentPage, 10) *
                        parseInt(this.props.perPage, 10);
                    const endIndex = startIndex + parseInt(this.props.perPage, 10);
                    let paginatedRowData = _.orderBy(
                        rowData,
                        fields.sortfn,
                        fields.orderBy
                    ); 
                      paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);                     
                //I) end of filterrowData
                */
                await this.props.updateState({
                    rowData: this.state.rowData,
                    // filteredRowData: paginatedRowData,
                    // selectedRows: new Set(),
                });
                this.props.updateParentState({
                    rowData: this.state.rowData,
                });
            });
        }

        if (failedDocIds.length !== 0) {
            multiSelectErrorMessage(failedDocIds);
        }
    };

    setDropdown = () => this.setState({ show: !this.state.show });

    /*setDropdown() {
                      //  this.setState({ ownerValue: '', //selectedRows: new Set()});
        this.setState({ show: true});
    }*/

    handleOwnerFocus(event) {
        //

        setTimeout(function () {
            if (document.getElementById('owner_bottom_input') !== null) {
                //
                // document.getElementById('ownerPopup').style.display = 'block';

                // document.getElementById('ownerPopup').style.visibility ='visible';

                document.getElementById('owner_bottom_input').focus();
            }
        }, 100);
    }

    render() {
        return (
            <>
                <div className='add-user-bottom' style={{ marginLeft: '10px' }}>
                    <Dropdown
                        show={this.state.show}
                        onToggle={this.setDropdown}
                    >
                        <Dropdown.Toggle
                            id='dropdown-basic'
                            className='owner_drop_down'
                            onClick={this.setDropdown}
                        >
                            <OverlayTrigger
                                placement='top'
                                onEntering={this.entering}
                                overlay={
                                    <Tooltip
                                        id='tooltip'
                                        style={{
                                            backgroundColor: 'rgb(0, 35, 58)',
                                            color: 'white',
                                            marginBottom: '25px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                            }}
                                        >
                                            Owner
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <i
                                    className={
                                        this.props.allActions.hasOwnProperty(
                                            'assign_to_user'
                                        ) &&
                                        this.props.allActions.assign_to_user
                                            .icon !== null
                                            ? this.props.allActions
                                                  .assign_to_user.icon
                                            : 'fas fa-user-plus'
                                    }
                                    style={{
                                        color:
                                            this.props.allActions.hasOwnProperty(
                                                'assign_to_user'
                                            ) &&
                                            this.props.allActions.assign_to_user
                                                .color !== null
                                                ? this.props.allActions
                                                      .assign_to_user.color
                                                : '',
                                    }}
                                    onClick={this.handleOwnerFocus}
                                />
                            </OverlayTrigger>
                        </Dropdown.Toggle>
                        <Dropdown.Menu id='owner-drop'>
                            <div id='ownerPopup' className='dropdown-outerbox'>
                                <Autocomplete
                                    inputProps={{
                                        placeholder: 'Search teammate',
                                        autoFocus: true,
                                    }}
                                    //items={this.state.owners}
                                    items={this.props.ownerList}
                                    shouldItemRender={(item, value) =>
                                        item.label
                                            .toLowerCase()
                                            .indexOf(value.toLowerCase()) > -1
                                    }
                                    getItemValue={(item) =>
                                        `${item.label}#${item.id}`
                                    }
                                    renderItem={(item, isHighlighted) => (
                                        <div
                                            key={item.id}
                                            style={{
                                                background: isHighlighted
                                                    ? 'lightgray'
                                                    : 'white',
                                                color: 'black',
                                            }}
                                        >
                                            {item.title}
                                        </div>
                                    )}
                                    renderMenu={(items, value) => (
                                        <div
                                            id='owner_auto_search_options'
                                            className='owner-list'
                                            key={items.id}
                                            style={{
                                                maxHeight: '250px',
                                                overflowY: 'auto',
                                            }}
                                            // eslint-disable-next-line react/no-children-prop
                                            children={items}
                                        />
                                    )}
                                    value={
                                        this.state.ownerValue !== null
                                            ? this.state.ownerValue
                                            : ''
                                    }
                                    onChange={(e) => {
                                        this.setState({
                                            ownerValue: e.target.value,
                                        });
                                    }}
                                    onSelect={(value) =>
                                        this.handleOwnerChange(value)
                                    }
                                    renderInput={(params, inputProps) => (
                                        <div className='owner-auto-search d-flex'>
                                            <span>
                                                <i className='fas fa-user-plus' />
                                            </span>
                                            <input
                                                {...params}
                                                id='owner_bottom_input'
                                            />
                                        </div>
                                    )}
                                />
                                <div
                                    className='owner-auto-search d-flex align-items-center'
                                    onClick={() => {
                                        this.handleOwnerChange(null);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <span>
                                        <i className='fas fa-user-minus' />
                                    </span>
                                    Remove Assignment
                                </div>
                            </div>

                            {/* <OwnerAutocomplete
                            ownerList={this.props.ownerList}  
                            handleOwnerChange = { (value) =>
                                {
                                    this.handleOwnerChange(value)
                                }
                            }
                            setOwnerValue={(e)=>{
                                this.setState({
                                    ownerValue: e.target.value
                                })
                            }}
                            ownerValue={this.state.ownerValue}
                            calingFrom='rowSelection'
                            parentComp = {this.props.rowSelectFrom}
                        />  */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    if (ownProps.state === 'inbox') {
        return {
            setOwner: setOwnerInbox,
            setSelectedRow: setSelectedRowInbox,
            updateList: updateListInbox,
            updateParentState: updateInboxState,
            updatePage: inboxPage,
        };
    } else {
        return {
            setOwner: setOwnerOutbox,
            setSelectedRow: setSelectedRowOutbox,
            updateList: updateListOutbox,
            updateParentState: updateOutboxState,
            updatePage: outboxPage,
        };
    }
};
const mapStateToProps = (state, ownProps) => {
    if (ownProps.rowSelectFrom === 'inbox') {
        return {
            inbox: state.inbox,
            processId: state.inbox.inbox.processId,
            statusId: state.inbox.inbox.statusId,
            selectedRows: state.inbox?.stateData?.selectedRows,
            rowData: state.inbox?.stateData?.rowData,
            configData: state.configReducerPersist.configData,
            dataTable: state.inbox.inbox?.dataTable,
        };
    } else {
        return {
            outbox: state.outbox,
            processId: state.outbox.outbox.processId,
            statusId: state.outbox.outbox.statusId,
            selectedRows: state.outbox?.stateData?.selectedRows,
            rowData: state.outbox?.stateData?.rowData,
            configData: state.configReducerPersist.configData,
            dataTable: state.outbox.outbox?.dataTable,
        };
    }
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(OwnerRowAction))
);
