/* eslint-disable array-callback-return */
import React, { useMemo } from 'react';
import objectPath from 'object-path';

// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout';

import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { CommonMenu } from './aside/aside-menu/CommonMenu'
import { SearchMenu } from './aside/aside-menu/SearchMenu';
import { ReportMenu } from './aside/aside-menu/ReportMenu';
import { FormMenu } from './aside/aside-menu/FormMenu';
import { UserMenu } from './aside/aside-menu/UserMenu';
import { Footer } from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import { ScrollTop } from './extras/ScrollTop';
import { StickyToolbar } from './extras/StickyToolbar';
import { getConfig } from '../../_helpers';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';
import AdminMenu from './aside/aside-menu/AdminMenu';
import ESignMenu from '../../../app/pages/eSign/components/ESignMenu';


function Layout({ children }, props) {
    const uiService = useHtmlClassService();
    const location = useLocation();
    React.useEffect(() => {}, [location]);
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, 'self.layout'),
            asideDisplay: objectPath.get(
                uiService.config,
                'aside.self.display'
            ),
            // subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                'header.self.fixed.desktop'
            ),
            contentCssClasses: uiService.getClasses('content', true),
            contentContainerClasses: uiService.getClasses(
                'content_container',
                true
            ),
            contentExtended: objectPath.get(
                uiService.config,
                'content.extended'
            ),
        };
    }, [uiService]);

    // const redux_store = useSelector((state) => state);
    var single_edit = false;

    // single_edit = redux_store.inbox.inboxPage.single_edit;
    const current_path = location.pathname;
    const path_arr = current_path.split('/');
    var current_menu = path_arr[1];
    if (path_arr.length > 2) {
        if (
            path_arr[2] === 'edit' ||
            path_arr[2] === 'detail' ||
            path_arr[2] === 'editmultiple'
        ) {
            single_edit = true;
        }
        if (path_arr[1] === 'search' && path_arr[3] === 'edit') {
            single_edit = true;
        }
        if (path_arr[1] === 'search' && path_arr[5] === 'edit') {
            single_edit = true;
        }
    }
    if (current_menu === 'inbox-design') {
        current_menu = 'inbox';
    }
    /*if (current_menu === 'search') {
        single_edit = false;
    }*/

    layoutProps.asideDisplay = false;
    if (localStorage.getItem('hasPasswordExpired') == 'false') {
        const configData = getConfig();

        if (configData !== '' && configData !== null) {
            if (configData[0].menuItems && configData[0].menuItems.length > 0) {
                const menuItems = configData[0].menuItems;

                menuItems.map((data, key) => {
                    if (data.menuName.toLowerCase() === current_menu) {
                        if (data.processes && data.processes.length > 0) {
                            layoutProps.asideDisplay = true;
                        } else {
                            layoutProps.asideDisplay = false;
                        }
                    } else {
                        // layoutProps.asideDisplay = false;
                    }
                });
            } else {
                // layoutProps.asideDisplay = false;
            }
        }
    }

    //Worked on 10-09-2021
    if (
        localStorage.getItem('valid_user') === 'false' ||
        localStorage.getItem('valid_user') === null
    ) {
        return <div className='d-flex flex-column flex-root'>{children}</div>;
    }
    return layoutProps.selfLayout !== 'blank' ? (
        <>
            {/*begin::Main*/}
            <HeaderMobile />
            <div className='d-flex flex-column flex-root'>
                {/*begin::Page*/}
                <div className='d-flex flex-row flex-column-fluid page'>
                    {/*layoutProps.asideDisplay && (<Aside/>)*/}
                    {/*begin::Wrapper*/}
                    <div
                        className='d-flex flex-column flex-row-fluid wrapper'
                        id='kt_wrapper'
                    >
                        <Header />
                        {/*begin::Content*/}
                        <div
                            id='kt_content'
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid mobile-content`}
                        >
                            {/*layoutProps.subheaderDisplay && <SubHeader/>*/}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className='d-flex flex-column-fluid px-5 mobile-layout'>
                                    {/*begin::Container*/}
                                    {/*begin::left aside*/}

                                    {/*Kalpana*/}
                                    {layoutProps.asideDisplay &&
                                    single_edit === false ? (
                                        <CommonMenu/>
                                    ) : null}

                                    {single_edit === false &&
                                    current_menu === 'search' ? (
                                        <SearchMenu />
                                    ) : null}
                                    {single_edit === false &&
                                    current_menu === 'reports' ? (
                                        <ReportMenu />
                                    ) : null}
                                    {single_edit === false &&
                                    current_menu === 'forms' ? (
                                        <FormMenu />
                                    ) : null}
                                    {single_edit === false &&
                                    current_menu === 'users' ? (
                                        <UserMenu />
                                    ) : null}
                                    {single_edit === false &&
                                    current_menu === 'admin' ? (
                                        <AdminMenu />
                                    ) : null}
                                       {single_edit === false &&
                                    current_menu === 'e-sign' ? (
                                        <ESignMenu />
                                    ) : null}
                                    {/* <Aside/> */}
                                    {/*end::left aside*/}
                                    <div
                                        className={`container-content-area ${layoutProps.contentContainerClasses}`}
                                    >
                                        {children}
                                    </div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && { children }}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <Footer />
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {/*<QuickUser/>
			<QuickPanel/>*/}
            <ScrollTop />
            <StickyToolbar />
            {/*end::Main*/}
            <LayoutInit />
        </>
    ) : (
        // BLANK LAYOUT
        <div className='d-flex flex-column flex-root'>{children}</div>
    );
}
export default withRouter(Layout);
