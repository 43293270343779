import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
    EditEntityPageProps,
    OptionType,
    Signature,
} from './models/interfaces';
import EntityForm from './components/EntityForm';
import { mapEntityToApiData } from './utils/mapEntityToApiData';
import { updatePhysician } from '../api/api';
import {
    normalSuccessToast,
    showErrorToast,
} from '../../components/commonfunction';
import SignatureSection from './components/SignatureSection';
import generateColumnsArray from './utils/generateColumnsArray';
import { fetchRecordsetData } from './utils/signatureApi';
import { mapApiDataToObjectModel } from './utils/mapApiDataToSignature';
import generateRows from './utils/generateRows';
import { signatureColumns } from './models/signatureColumns';
import { getDropDownDataFromApi } from '../../components/commonfunctionTS';
import { getSyncFieldId } from './utils/getSyncFieldId';

const EditEntityPage: React.FC<EditEntityPageProps> = ({
    entityData,
    setModel,
    headerColumns,
    physicianRecordSetId,
    entityId,
    dataRows,
}) => {
    const [lookupOptions, setLookupOptions] = useState<OptionType[]>([]);
    const [welcomeSenderOptions, setWelcomeSenderOptions] = useState<
        OptionType[]
    >([]);
    const [initialSignature, setInitialSignature] = useState<Signature[]>([]);
    const [postSignature, setPostSignature] = useState<Signature[]>([]);
    const [initialSignatureHeader] = useState<any[]>(signatureColumns);
    const [postSignatureHeader] = useState<any[]>(signatureColumns);
    const [initialSignatureLoading, setInitialSignatureLoading] =
        useState<boolean>(true);
    const [postSignatureLoading, setPostSignatureLoading] =
        useState<boolean>(true);
    const [initialReviewersConfig, setIntialReviewersConfig] = useState<any>({
        recordSetId: '',
        headerColumns: [],
    });
    const [postSignatureReviewersConfig, setPostSignatureReviewersConfig] =
        useState<any>({ recordSetId: '', headerColumns: [] });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const formikRef = useRef<FormikProps<any>>(null);

    useEffect(() => {
        const lookupKeyField = headerColumns.find(
            (col: any) => col.name === 'Lookup Key Type'
        )?.fieldId;
        if (lookupKeyField) {
            fetchDropDownData(lookupKeyField);
        }
    }, []);

    useEffect(() => {
        fetchRecordsetData('Send Entity Email')
            .then((sendEntityEmails) => {
                if (sendEntityEmails) {
                    const { data } = sendEntityEmails;
                    if (data) {
                        const response: OptionType[] =
                            data.dataTable.dataRows.map((item: any) => ({
                                value: item.id,
                                label: item.values,
                            }));
                        setWelcomeSenderOptions(response);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (entityData.welcomeSender) {
            const welcomeSenderOption = welcomeSenderOptions.find(
                (option) => option.label[0] === entityData.welcomeSender
            );
            if (welcomeSenderOption) {
                formikRef.current?.setFieldValue(
                    'welcomeSender',
                    welcomeSenderOption.value
                );
            }
        }
    }, [welcomeSenderOptions, entityData.welcomeSender]);

    useEffect(() => {
        fetchRecordsetData('Initial Reviewers')
            .then((initialReviewrs) => {
                if (initialReviewrs) {
                    const { data, headerColumns } = initialReviewrs;
                    setIntialReviewersConfig({
                        recordSetId: data?.recordsetId,
                        headerColumns,
                    });
                    const signature = mapApiDataToObjectModel(data);

                    const entitySignature = signature
                        .filter(
                            (item) =>
                                item.sourceentityid === entityId.toString() &&
                                item.linkedentityid !== entityId.toString()
                        )
                        .map((item) => {
                            const matchingDataRow = dataRows.find(
                                (row) =>
                                    row?.id?.toString() ===
                                    item?.linkedentityid?.toString()
                            );
                            return {
                                communicationemail:
                                    matchingDataRow?.entitycommunicationemail ||
                                    '',
                                lookupkey:
                                    matchingDataRow?.lookupkeyvalue || '',
                                firstname:
                                    matchingDataRow?.entityfirstname || '',
                                lastname: matchingDataRow?.entitylastname || '',
                                entityId: item?.linkedentityid || '',
                                id: item.id,
                            };
                        });
                    setInitialSignature(entitySignature);
                }
                setInitialSignatureLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setInitialSignatureLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchRecordsetData('Post Signature Reviewers')
            .then((postSignatureReviewers) => {
                if (postSignatureReviewers) {
                    const { data, headerColumns } = postSignatureReviewers;
                    setPostSignatureReviewersConfig({
                        recordSetId: data?.recordsetId,
                        headerColumns,
                    });
                    const signature = mapApiDataToObjectModel(data);

                    const entitySignature = signature
                        .filter(
                            (item) =>
                                item.sourcepostsignatureentityid === entityId.toString() &&
                                item.linkedpostsignatureentityid !== entityId.toString()
                        )
                        .map((item) => {
                            const matchingDataRow = dataRows.find(
                                (row) =>
                                    row?.id?.toString() ===
                                    item?.linkedpostsignatureentityid?.toString()
                            );
                            return {
                                communicationemail:
                                    matchingDataRow?.entitycommunicationemail ||
                                    '',
                                lookupkey:
                                    matchingDataRow?.lookupkeyvalue || '',
                                firstname:
                                    matchingDataRow?.entityfirstname || '',
                                lastname: matchingDataRow?.entitylastname || '',
                                entityId: item?.linkedpostsignatureentityid || '',
                                id: item.id,
                            };
                        });

                    setPostSignature(entitySignature);
                }
                setPostSignatureLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setPostSignatureLoading(false);
            });
    }, []);

    const filterDataRowsWithoutEntitySignature = (
        entitySignature: any,
        dataRows: any
    ) => {
        const filtered = dataRows
            .filter(
                (row: any) =>
                    !entitySignature.some(
                        (item: any) => item?.entityId === row.id.toString() || row.id.toString() === entityId.toString()
                    )

            )
            .map((item: any) => {
                return {
                    communicationemail: item.entitycommunicationemail,
                    lookupkey: item.lookupkeyvalue,
                    firstname: item.entityfirstname,
                    lastname: item.entitylastname,
                    id: item.id,
                };
            });
        return filtered;
    };

    const fetchDropDownData = async (lookupKeyField: any) => {
        const data = await getDropDownDataFromApi(lookupKeyField);
        if (data?.values) {
            const response: OptionType[] = data.values.map((item: string) => ({
                value: item ?? '',
                label: item ?? '',
            }));
            setLookupOptions(response);
        }
    };

    const editPhysician = async (values: any) => {
        try {
            setIsSubmitting(true);
            const apiData = mapEntityToApiData(values, headerColumns);

            const syncFieldId = getSyncFieldId("All Entities");
            if (syncFieldId?.fieldId) {
                apiData.push({ fieldId: syncFieldId.fieldId, value: true });
            }
            const response = await updatePhysician(
                apiData,
                physicianRecordSetId,
                entityId
            );
            if (
                !response ||
                response.status === 400 ||
                response.status === 404
            ) {
                const responseData = await response?.json();
                if (
                    responseData.message.includes(
                        'Communication Email is already in use'
                    )
                ) {
                    showErrorToast(
                        'Entity with this Communication Email already exists'
                    );
                } else {
                    showErrorToast('Failed to update physician');
                }
            } else {
                setModel(false);
                normalSuccessToast('Physician updated successfully');
            }
        } catch (error) {
            console.error(error);
            showErrorToast('Failed to update physician');
        } finally {
            setIsSubmitting(false);
        }
    };

    const initialSignatureColumns = generateColumnsArray(
        initialSignatureHeader
    );
    const postSignatureColumns = generateColumnsArray(postSignatureHeader);

    const addSignature = (
        assignedId: string,
        objectId: string,
        setSignature: React.Dispatch<React.SetStateAction<Signature[]>>
    ) => {
        const assignedRow = dataRows.find((row: any) => row.id === assignedId);

        if (assignedRow) {
            setSignature((prevSignatures) => [
                ...prevSignatures,
                {
                    communicationemail: assignedRow.entitycommunicationemail,
                    lookupkey: assignedRow.lookupkeyvalue,
                    firstname: assignedRow.entityfirstname,
                    lastname: assignedRow.entitylastname,
                    entityId: assignedRow?.id?.toString() || '',
                    id: objectId,
                },
            ]);
        }
    };

    const onDelete = (
        assignedId: string,
        setSignature: React.Dispatch<React.SetStateAction<Signature[]>>
    ) => {
        setSignature((prevSignatures) =>
            prevSignatures.filter((item) => item.id !== assignedId)
        );
    };

    return (
        <div
            id='right-aside'
            className='right-aside py-7 d-flex flex-column justify-content-between'
            style={{
                minHeight: isMobile
                    ? window.innerHeight - 112
                    : window.innerHeight - 158,
            }}
        >
            <div className='listing-grid'>
                <Formik
                    innerRef={formikRef}
                    initialValues={entityData}
                    validationSchema={validationSchema}
                    onSubmit={editPhysician}
                >
                    {(formik) => (
                        <Form>
                            <EntityForm
                                formik={formik}
                                lookupOptions={lookupOptions}
                                welcomeSenderOptions={welcomeSenderOptions}
                                title='Edit Entity'
                            />

                            <div className='mb-5 pl-7 pr-7'>
                                <SignatureSection
                                    title='Initial Reviewers'
                                    reviewersConfig={initialReviewersConfig}
                                    entityId={entityId}
                                    onAdd={(id, objectId) => addSignature(id, objectId, setInitialSignature)}
                                    onUnassign={(id) => onDelete(id, setInitialSignature)}
                                    loading={initialSignatureLoading}
                                    columnsArray={initialSignatureColumns}
                                    rows={generateRows(
                                        initialSignature,
                                        initialSignatureColumns
                                    )}
                                    availableItems={generateRows(
                                        filterDataRowsWithoutEntitySignature(
                                            initialSignature,
                                            dataRows
                                        ),
                                        initialSignatureColumns
                                    )}
                                />
                                <SignatureSection
                                    title='Post Signature Reviewers'
                                    reviewersConfig={postSignatureReviewersConfig}
                                    entityId={entityId}
                                    onAdd={(id, objectId) => addSignature(id, objectId, setPostSignature)}
                                    onUnassign={(id) => onDelete(id, setPostSignature)}
                                    loading={postSignatureLoading}
                                    columnsArray={postSignatureColumns}
                                    rows={generateRows(
                                        postSignature,
                                        postSignatureColumns
                                    )}
                                    availableItems={generateRows(
                                        filterDataRowsWithoutEntitySignature(
                                            postSignature,
                                            dataRows
                                        ),
                                        initialSignatureColumns
                                    )}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <div>
                <hr />
                <div className='d-flex footer-btn align-items-stretch justify-content-between mb-2 pl-7 pr-7'>
                    <div className='d-flex'>
                        <Button
                            title='Cancel'
                            variant='secondary'
                            onClick={() => setModel(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className='d-flex'>
                        <Button
                            title='Update'
                            type='submit'
                            variant='success'
                            disabled={isSubmitting}
                            onClick={() => formikRef.current?.submitForm()}
                        >
                            {isSubmitting ? (
                                <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                            ) : (
                                <span>Update</span>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEntityPage;

const validationSchema = Yup.object().shape({
    communicationEmail: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    smsNumber: Yup.string()
        .matches(/^\d{3}-\d{3}-\d{4}$/, 'SMS number must be 10 digits')
        .nullable(true),
});