/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// import {useSubheader} from "../../_metronic/layout";
import Nav from 'react-bootstrap/Nav';
import ByPatient from '../../../components/patient/ByPatient';
import ViewEditPatient from '../../../components/patient/ViewEditPatient';
import CreateNewPatient from '../../../components/patient/CreateNewPatient';
// import InboxPage from '../../inbox/InboxPage';
// import OutboxPage from '../../outbox/OutboxPage';
import DeletedDocumentPage from '../../deletedDocument/DeletedDocumentPage';
// import EditWorkPage from '../../../pages/EditWorkPage';
import PhysicianListingDataGrid from '../../../components/datagrid/PhysicianListingDataGrid';
// import { useLocation } from "react-router";
import {
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    PatientWithUnderScore,
    showErrorToast,
} from '../../../components/commonfunction';
import { getDDLlist } from '../../_redux/actions/configActions';
import { updateList, inboxPage } from '../../_redux/actions/inboxActions';
import { deletedPage } from '../../_redux/actions/searchActions';
import { recordSetSearch } from '../../api/api';
// import { withRouter } from 'react-router-dom';

export class PatientSearch extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        // const location = useLocation();
        this.state = {
            currentLookupPatient: 'byPatient',
            submit_normal_create_patient: false,
            tab_name: 'tab_all_documents',
            show_tabs: true,
            patient_filter: '',
            // physician_filter : '',
            refresh: false,
            toggleRecentDoc: true,
            togglePatient: true,
            check: false,
            result_refresh: true,
            noPatientError: false,
            cancelSearch: false,
            addOperation: true,
            mobileHide: false,
            closeFunction: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ show_tabs: true });
            const current_path = this.props.location.pathname;
            const path_arr = current_path.split('/');
            // var current_menu = path_arr[1];

            if (path_arr.length === 2) {
                if (this.state.show_tabs === false) {
                    this.setState({ show_tabs: true });
                }
            }
            if (path_arr.length > 2) {
                if (path_arr[1] === 'search' && path_arr[3] !== 'edit') {
                    if (this.state.show_tabs === false) {
                        this.setState({ show_tabs: true });
                    }
                }
                if (path_arr[1] === 'search' && path_arr[3] === 'edit') {
                    this.props.history.push({
                        pathname: '/search/patients',
                    });
                    var patient_filter = JSON.parse(
                        localStorage.getItem('searchPage_patient_filter')
                    );
                    var tab_name = localStorage.getItem('tab_name');
                    if (tab_name === 'tab_tracking') {
                        this.setState({ tab_name: tab_name });
                    }

                    this.setState({ patient_filter: patient_filter });
                    if (this.state.show_tabs === true) {
                        this.setState({ show_tabs: false });
                    }
                }
            }

            // in order to get details of Physician from url
            let patientID = '';
            const query = window.location.search.substring(1);
            const vars = query.split('&');

            for (let i = 0; i < vars.length; i++) {
                const pair = vars[i].split('=');

                if (pair[0] === 'ID') {
                    patientID = pair[1];
                }
            }
            //if (patientID !== '' && patientID !== null && patientID !== undefined) {
            // if (patientID) {
            //     this.performPatientSearch(patientID)
            // } else this.setState({ refresh : true })
            this.performPatientSearch(patientID);
            // }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== this.state.pathName) {
            this.setState({
                pathName: this.props.location.pathname,
            });
        }

        if (String(prevState.pathName).includes('edit')) {
            var myEle = document.getElementById('documents_count');
            var myTrack = document.getElementById('tracking_count');
            if (
                myEle &&
                this.props.inbox.inboxPage.documents_count !== 0 &&
                this.props.inbox.inboxPage.documents_count !== undefined
            ) {
                document.getElementById('documents_count').innerHTML =
                    this.props.inbox.inboxPage.documents_count;
                document.getElementById('documents_count').style.display =
                    'inherit';
            }

            if (
                myTrack &&
                this.props.inbox.inboxPage.tracking_count !== 0 &&
                this.props.inbox.inboxPage.tracking_count !== undefined
            ) {
                document.getElementById('tracking_count').innerHTML =
                    this.props.inbox.inboxPage.tracking_count;
                document.getElementById('tracking_count').style.display =
                    'inherit';
            }
        }
        if (prevState.tab_name !== this.state.tab_name) {
            this.setState({
                result_refresh: false,
            });
            setTimeout(() => {
                this.setState({ result_refresh: true });
            }, 100);
            document.body.classList.remove('doc-detail-view');
            document.body.classList.remove('aside-minimize');
        }
        const current_path = this.props.location.pathname;
        const path_arr = current_path.split('/');
        // var current_menu = path_arr[1];

        if (path_arr.length === 2) {
            if (this.state.show_tabs === false) {
                this.setState({ show_tabs: true });
            }
        } else if (path_arr.length > 2) {
            if (path_arr[1] === 'search' && path_arr[3] !== 'edit') {
                if (this.state.show_tabs === false) {
                    this.setState({ show_tabs: true });
                }
            }
            if (path_arr[1] === 'search' && path_arr[3] === 'edit') {
                if (this.state.show_tabs === true) {
                    this.setState({ show_tabs: false });
                }
            }
        }

        if (
            this.props.location.search.includes('?operation=add') &&
            this.state.addOperation === true
        ) {
            this.setState({
                addOperation: false,
                open_create_patient: true,
                show_tabs: true,
            });
        }

        if (
            (this.state.patient_filter !== undefined &&
                prevState.patient_filter !== undefined &&
                this.state.patient_filter.id !== prevState.patient_filter.id) ||
            (prevState.open_create_patient === true &&
                this.state.open_create_patient === false)
        ) {
            this.setState({
                result_refresh: false,
                noPatientError: false,
            });

            var customSearchQueries = [];
            customSearchQueries = this.props.customSearchQueries;
            var searchText = '';
            var pathname = '';
            var trackingSearchText = '';
            var trackingPathname = '';

            var concat = '?';
            customSearchQueries.map((data) => {
                if (
                    String('patientdocuments') ===
                    formatHeaderNameWithOutSpace(data.name)
                ) {
                    pathname = data.searchId;
                    if (
                        this.state.patient_filter !== undefined &&
                        this.state.patient_filter.patient_id !== undefined &&
                        data.inputSearchFieldIdsAndValues[0] !== undefined &&
                        data.inputSearchFieldIdsAndValues[0] !== ''
                    ) {
                        searchText +=
                            concat +
                            'fieldSearch=' +
                            data.inputSearchFieldIdsAndValues[0].fieldId +
                            '%3D' +
                            this.state.patient_filter.patient_id;
                    }
                }
                if (
                    String('ordertracking') ===
                    formatHeaderNameWithOutSpace(data.name)
                ) {
                    trackingPathname = data.searchId;
                    if (
                        this.state.patient_filter !== undefined &&
                        this.state.patient_filter.patient_id !== undefined &&
                        data.inputSearchFieldIdsAndValues[0] !== undefined &&
                        data.inputSearchFieldIdsAndValues[0] !== ''
                    ) {
                        trackingSearchText +=
                            concat +
                            'fieldSearch=' +
                            data.inputSearchFieldIdsAndValues[0].fieldId +
                            '%3D' +
                            this.state.patient_filter.patient_id;
                    }
                }
            });
            searchText = pathname + searchText;
            trackingSearchText = trackingPathname + trackingSearchText;
            this.setState({ patient_documents_query: searchText });
            this.setState({ patient_tracking_query: trackingSearchText });
            this.openTab('tab_all_documents');
            var eleDiv = document.getElementById('documents_count');
            if (eleDiv) {
                document.getElementById('documents_count').style.display =
                    'none';
                document.getElementById('tracking_count').style.display =
                    'none';
                document.getElementById('physician_count').style.display =
                    'none';
            }
            this.props.inboxPage({
                tracking_count: 0,
                documents_count: 0,
            });
            setTimeout(() => {
                this.setState({ result_refresh: true });
            }, 100);
        }
        // if(prevState.open_create_patient === true && this.state.open_create_patient === false)
        // {
        //     setTimeout(() => {
        //     document.getElementById('documents_count').style.display =
        //         'none !important';
        //     document.getElementById('tracking_count').style.display =
        //             'none !important';
        //     document.getElementById('physician_count').style.display =
        //             'none !important';
        //         this.props.inboxPage({
        //             documents_count: 100,
        //             tracking_count: 11,
        //         });
        //     }, 2000);

        // }
    }

    performPatientSearch = async (patientID) => {
        let configuredRecordsets = [];
        let patientFieldIdArray = [];
        let modifyPatientFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;
        const allRecordSetID = {};
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (this._isMounted) {
                    if (
                        formatHeaderNameWithOutSpace(data.name) ===
                        'patientdata'
                    ) {
                        patientFieldIdArray = data.fields;
                        patientRecordsetId = data.recordsetId;
                        patientModifiableRecordsetId =
                            data.modifiableRecordsetId;
                    }
                    if (
                        formatHeaderNameWithOutSpace(data.name) ===
                        'patientdatamodify'
                    ) {
                        modifyPatientFieldIdArray = data.fields;
                        modifyPatientRecordsetId = data.recordsetId;
                        modifyPatientModifiableRecordsetId =
                            data.modifiableRecordsetId;
                    }

                    const fieldIDArray = {};
                    if (
                        data.hasOwnProperty('fields') &&
                        data.fields.length > 0
                    ) {
                        data.fields.map((field, index) => {
                            fieldIDArray[
                                formatNameWithSpaceToUnderscore(field.name)
                            ] = field;
                            return field;
                        });
                        data.fields_obj = fieldIDArray;
                    }
                    const name = formatNameWithSpaceToUnderscore(data.name);
                    allRecordSetID[name] = data;
                    return data;
                }
            });
        }
        // if (this._isMounted) {
        // this.setState({ allRecordSetID: allRecordSetID });
        this.setState({
            allRecordSetID: allRecordSetID,
            patientModifiableRecordsetId: patientModifiableRecordsetId,
            patientFieldIdArray: patientFieldIdArray,
            patientRecordsetId: patientRecordsetId,
            modifyPatientFieldIdArray: modifyPatientFieldIdArray,
            modifyPatientRecordsetId: modifyPatientRecordsetId,
            modifyPatientModifiableRecordsetId:
                modifyPatientModifiableRecordsetId,
            edit_details: false,
        });
        // }

        if (
            patientID !== '' &&
            patientID !== null &&
            patientRecordsetId === undefined
        ) {
            showErrorToast(
                'Access Restricted : You dont have access to view this patient'
            );
        }

        if (patientID !== '' && patientID !== null) {
            this.setState({ refresh: false });
            let searchText = '';

            if (patientFieldIdArray !== '') {
                patientFieldIdArray.map((fieldData, index) => {
                    if (
                        fieldData.name === 'PatientID' ||
                        fieldData.name === 'Patient ID'
                    ) {
                        searchText += `fieldSearch=${fieldData.fieldId}%3D${patientID}%2A`;
                        // encodeURI(fieldData.fieldId + '=' + e.target.value);
                    }
                    //  return fieldData;
                });
            }

            let status;
            recordSetSearch(patientRecordsetId, searchText)
                .then((response) => {
                    if (this._isMounted) {
                        status = response.status;
                        return response.json();
                    }
                })
                .then((data) => {
                    if (this._isMounted) {
                        if (status === 200) {
                            // var searchResult = PatientWithUnderScore(data);
                            const singlePatient = PatientWithUnderScore(data);

                            let search = {};
                            if (singlePatient.length > 0) {
                                search = singlePatient[0];
                            }

                            if (search.hasOwnProperty('id')) {
                                const temp = {
                                    ...search,
                                    id: search.id,
                                };
                                this.setState({ patient_filter: temp });
                                this.props.updateState({
                                    patient_filter: temp,
                                });
                            }
                            if (search.id > 0 || search.id === 0) {
                                this.setState(
                                    {
                                        patientIsNegative: false,
                                    },
                                    () => {
                                        this.props.updateState({
                                            patientIsNegative: false,
                                        });
                                    }
                                );
                            } else {
                                this.setState(
                                    {
                                        patientIsNegative: true,
                                    },
                                    () => {
                                        this.props.updateState({
                                            patientIsNegative: true,
                                        });
                                    }
                                );
                            }

                            this.setState({
                                refresh: true,
                                view_patient: true,
                            });
                            if (
                                Object.keys(data.dataTable.dataRows).length ===
                                0
                            ) {
                                this.setState({
                                    noPatientError: true,
                                });
                            } else {
                                this.setState({
                                    noPatientError: false,
                                });
                            }
                        }
                    }
                });
        } else {
            this.setState({ refresh: true });
        }
    };
    closeCreatePatient = async (formData) => {
        const state = {
            patient_filter: formData,
            open_create_patient: false,
            view_patient: true,
            patientIsNegative: false,
            //closeFunction: true,
        };

        if (
            formData.closeFunction !== undefined &&
            formData.closeFunction !== null &&
            formData.closeFunction !== ''
        ) {
            state.closeFunction = true;
        }
        await this.setState(state, async () => {
            await this.props.updateState(state);
        });
    };

    submitCreatePatient = () => {
        this.setState({
            submitCreatePatient: true,
        });
    };

    openNewPatient = () => {
        this.setState({
            // create_patient: true,
            open_create_patient: true,
        });
    };

    updateState = (state) => {
        this.setState({ ...state });
    };

    openTab = async (tab_name) => {
        localStorage.setItem('tab_name', tab_name);
        await this.props.updateList('');
        this.setState({
            tab_name: tab_name,
        });
        if (tab_name === 'tab_tracking') {
            this.props.deletedPage({
                showStatistic: true,
            });
        } else {
            this.props.deletedPage({
                showStatistic: false,
            });
        }
    };

    toggleRecentDoc = () => {
        this.setState({
            toggleRecentDoc: !this.state.toggleRecentDoc,
            togglePatient: !this.state.togglePatient,
            check: !this.state.check,
        });
    };

    byPatient = async (view) => {
        this.setState({ currentLookupPatient: 'byPatient' });
        this.setState({ patient_filter_display: '' });
    };

    byMRNumber = async (view) => {
        this.setState({ currentLookupPatient: 'byMRNumber' });
        this.setState({ patient_filter_display: '' });
    };

    render() {
        return (
            <>
                {this.state.refresh ? (
                    <div className='search-inner-right-aside'>
                        {this.state.mobileHide === false ? (
                            <div id='search-top'>
                                <div className='form-group search-block'>
                                    <div
                                        className={
                                            this.state.check === false
                                                ? 'form-group search_bypatient'
                                                : 'form-group search_bypatient close-lookup'
                                        }
                                        style={{
                                            display: this.state.show_tabs
                                                ? 'block'
                                                : 'none',
                                        }}
                                    >
                                        {/* <div className="row">
                                            <div className='col-3'>
                                                <label className='pl-5'>
                                                    LOOKUP BY
                                                </label>
                                            </div>
                                            <div className="col-9 d-flex justify-content-between align-items-center">
                                                <h3 className="title m-0">PATIENT NAME</h3>
                                                <div className="dropdown_icon"><i title="Open" className="fa-regular fa-chevron-down" aria-hidden="true"></i></div>
                                            </div>
                                            </div> */}
                                        <div className='row'>
                                            {this.state.toggleRecentDoc ===
                                                true && (
                                                <div className='ml-4'>
                                                    <label className='pl-5'>
                                                        LOOKUP BY
                                                    </label>
                                                    <div className='tab_part'>
                                                        <div className='tabs'>
                                                            <Nav
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                                id='noanim-tab'
                                                                defaultActiveKey={
                                                                    this.state
                                                                        .currentLookupPatient
                                                                }
                                                                variant='pills'
                                                                className='search_nav'
                                                            >
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        title='By Patient'
                                                                        key={0}
                                                                        eventKey='byPatient'
                                                                        onClick={
                                                                            this
                                                                                .byPatient
                                                                        }
                                                                    >
                                                                        By
                                                                        Patient
                                                                        Name
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        title='By MR Number'
                                                                        key={1}
                                                                        eventKey='byMRNumber'
                                                                        onClick={
                                                                            this
                                                                                .byMRNumber
                                                                        }
                                                                    >
                                                                        By MR
                                                                        Number
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='col'>
                                                <div className='grid-row-group'>
                                                    <ByPatient
                                                        state={{
                                                            ...this.state,
                                                        }}
                                                        updateState={(
                                                            state
                                                        ) => {
                                                            this.setState({
                                                                ...state,
                                                            });
                                                            if (
                                                                state.hasOwnProperty(
                                                                    'patient_filter'
                                                                )
                                                            ) {
                                                                localStorage.setItem(
                                                                    'searchPage_patient_filter',
                                                                    JSON.stringify(
                                                                        state.patient_filter
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                        submitEditPatient={() => {
                                                            this.setState({
                                                                submit_edit_patient: true,
                                                            });
                                                        }}
                                                        submitNormalCreatePatient={() => {
                                                            this.setState({
                                                                submit_normal_create_patient: true,
                                                            });
                                                        }}
                                                        editPatient={
                                                            this.state
                                                                .edit_patient
                                                        }
                                                        openNewPatient={
                                                            this.openNewPatient
                                                        }
                                                        byOrder={false}
                                                        calingFrom='search'
                                                        selectedPatient={
                                                            this.state
                                                                .patient_filter
                                                        }
                                                        toggleRecentDoc={
                                                            this.state
                                                                .toggleRecentDoc
                                                        }
                                                        toggleRecentDocFun={() => {
                                                            this.toggleRecentDoc();
                                                        }}
                                                        cancelSearch={
                                                            this.state
                                                                .cancelSearch
                                                        }
                                                        closeFunction={
                                                            this.state
                                                                .closeFunction
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {this.state.view_patient && (
                                                <>
                                                    <div
                                                        className='dropdown_icon mr-4'
                                                        onClick={() =>
                                                            this.toggleRecentDoc()
                                                        }
                                                    >
                                                        <i
                                                            title={
                                                                this.state
                                                                    .toggleRecentDoc
                                                                    ? 'Close'
                                                                    : 'Open'
                                                            }
                                                            className={
                                                                this.state
                                                                    .toggleRecentDoc
                                                                    ? 'fa fa-chevron-up'
                                                                    : 'fa fa-chevron-down'
                                                            }
                                                            aria-hidden='true'
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.noPatientError && (
                                        <div className='form-group row data-display'>
                                            <div className='col-lg-12'>
                                                <div className='search_page view_patient'>
                                                    <div
                                                        className='patient_detail edit-detail-bg'
                                                        style={{
                                                            backgroundColor:
                                                                '#ffffff',
                                                        }}
                                                    >
                                                        <div className='form-group row'>
                                                            <span
                                                                className='col-lg-12'
                                                                style={{
                                                                    color: 'red',
                                                                    textalign:
                                                                        'center',
                                                                }}
                                                            >
                                                                No Patient found
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className='form-group row data-display'
                                        style={{
                                            display:
                                                this.state.view_patient &&
                                                this.state.togglePatient &&
                                                this.state.show_tabs
                                                    ? 'block'
                                                    : this.state
                                                          .open_create_patient ===
                                                      true
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        <div className='col-lg-12'>
                                            {this.state.view_patient &&
                                            this.state.noPatientError !==
                                                true &&
                                            this.state.togglePatient &&
                                            this.state.show_tabs ? (
                                                <ViewEditPatient
                                                    // newReferral={
                                                    //     this.state.formData.new_referral
                                                    // }
                                                    resetSubmit={() => {
                                                        this.setState({
                                                            submit_edit_patient: false,
                                                        });
                                                    }}
                                                    selectedPatient={
                                                        this.state
                                                            .patient_filter
                                                    }
                                                    editPatient={
                                                        this.state.edit_patient
                                                    }
                                                    submitEditPatient={
                                                        this.state
                                                            .submit_edit_patient
                                                    }
                                                    patientFieldIdArray={
                                                        this.state
                                                            .modifyPatientFieldIdArray
                                                    }
                                                    patientModifiableRecordsetId={
                                                        this.state
                                                            .modifyPatientModifiableRecordsetId
                                                    }
                                                    closeEditPatient={(
                                                        formData
                                                    ) =>
                                                        this.closeEditPatient(
                                                            formData
                                                        )
                                                    }
                                                    updateState={(state) => {
                                                        this.setState({
                                                            ...state,
                                                        });
                                                    }}
                                                    byPatient={this.byPatient}
                                                    byOrder={
                                                        this.state.by_order
                                                    }
                                                    allRecordSetID={
                                                        this.state
                                                            .allRecordSetID
                                                    }
                                                    updateFromCreate={
                                                        this.submitEditPatient
                                                    }
                                                    patient_form_apperance='normal'
                                                    open_edit_patient
                                                    calingFrom='search'
                                                    cancelSearch={
                                                        this.state.cancelSearch
                                                    }
                                                />
                                            ) : null}

                                            {this.state.open_create_patient ===
                                                true && this.state.show_tabs ? (
                                                <CreateNewPatient
                                                    closeCreatePatient={(
                                                        formData
                                                    ) =>
                                                        this.closeCreatePatient(
                                                            formData
                                                        )
                                                    }
                                                    branches={
                                                        this.props.branches
                                                    }
                                                    patientFieldIdArray={
                                                        this.state
                                                            .patientFieldIdArray
                                                    }
                                                    patientModifiableRecordsetId={
                                                        this.state
                                                            .patientModifiableRecordsetId
                                                    }
                                                    submitCreatePatient={
                                                        this.state
                                                            .submitCreatePatient
                                                    }
                                                    submitNormalCreatePatient={
                                                        this.state
                                                            .submit_normal_create_patient
                                                    }
                                                    resetSubmit={() => {
                                                        this.setState({
                                                            submitCreatePatient: false,
                                                            submit_normal_create_patient: false,
                                                        });
                                                        this.updateState({
                                                            submitCreatePatient: false,
                                                            submit_normal_create_patient: false,
                                                        });
                                                    }}
                                                    submittingViaBigView={
                                                        this.state
                                                            .submittingViaBigView
                                                    }
                                                    submittingViaUpload={
                                                        this.state
                                                            .submittingViaUpload
                                                    }
                                                    updateState={(state) => {
                                                        this.updateState({
                                                            ...state,
                                                        });
                                                    }}
                                                    submitDetailForm={() => {
                                                        this.props.submitDetailForm();
                                                    }}
                                                    allRecordSetID={
                                                        this.state
                                                            .allRecordSetID
                                                    }
                                                    allowedFields={
                                                        this.state.allowedFields
                                                    }
                                                    validateForUpload={
                                                        this.props
                                                            .validateForUpload
                                                    }
                                                    isDetailBigView
                                                    open_create_patient={
                                                        this.state
                                                            .open_create_patient
                                                    }
                                                    patient_form_apperance='normal'
                                                    calingFrom='search'
                                                    modifyPatientFieldIdArray={
                                                        this.state
                                                            .modifyPatientFieldIdArray
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="form-group search_bypatient">
                                    <div className='col'>
                                        <div className='grid-row-group'>
                                            <ByPatient
                                                state={{ ...this.state }}
                                                updateState={(state) => {
                                                    this.setState({ ...state });
                                                }}
                                                submitEditPatient={() => {
                                                    this.setState({
                                                        submit_edit_patient: true,
                                                    });
                                                }}
                                                submitNormalCreatePatient={() => {
                                                    this.setState({
                                                        submit_normal_create_patient: true,
                                                    });
                                                }}
                                                editPatient={
                                                    this.state.edit_patient
                                                }
                                                openNewPatient={
                                                    this.openNewPatient
                                                }
                                                byOrder={false}
                                                calingFrom='search'
                                                selectedPatient={
                                                    this.state.patient_filter
                                                }
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {this.state.patient_filter &&
                                this.state.patient_filter.patient_id !== '' &&
                                this.state.patient_filter.patient_id !==
                                    undefined &&
                                this.state.show_tabs ? (
                                    <div className='navigation'>
                                        <div className='tab'>
                                            <ul className='nav nav-pills'>
                                                <li className='nav-item '>
                                                    <NavLink
                                                        title='Inbox'
                                                        id='tab_all_documents'
                                                        onClick={() => {
                                                            this.openTab(
                                                                'tab_all_documents'
                                                            );
                                                        }}
                                                        className={
                                                            this.state
                                                                .tab_name ===
                                                            'tab_all_documents'
                                                                ? 'custom-menu-link menu-active'
                                                                : 'custom-menu-link'
                                                        }
                                                        to='#'
                                                    >
                                                        <span className='tab-text'>
                                                            Inbox
                                                        </span>
                                                        <span
                                                            className='count'
                                                            id='documents_count'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            5
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item '>
                                                    <NavLink
                                                        title='Tracking'
                                                        id='tab_tracking'
                                                        onClick={() => {
                                                            this.openTab(
                                                                'tab_tracking'
                                                            );
                                                        }}
                                                        className={
                                                            this.state
                                                                .tab_name ===
                                                            'tab_tracking'
                                                                ? 'custom-menu-link menu-active'
                                                                : 'custom-menu-link'
                                                        }
                                                        to='#'
                                                    >
                                                        <span className='tab-text'>
                                                            Tracking
                                                        </span>
                                                        <span
                                                            className='count'
                                                            id='tracking_count'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            98
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item '>
                                                    <NavLink
                                                        title='Physicians'
                                                        id='tab_physicians'
                                                        onClick={() => {
                                                            this.openTab(
                                                                'tab_physicians'
                                                            );
                                                        }}
                                                        className={
                                                            this.state
                                                                .tab_name ===
                                                            'tab_physicians'
                                                                ? 'custom-menu-link menu-active'
                                                                : 'custom-menu-link'
                                                        }
                                                        to='#'
                                                    >
                                                        <span className='tab-text'>
                                                            Physicians
                                                        </span>
                                                        <span
                                                            className='count'
                                                            id='physician_count'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            989
                                                        </span>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {this.state.patient_filter &&
                        this.state.patient_filter.patient_id !== '' &&
                        this.state.patient_filter.patient_id !== undefined &&
                        this.state.tab_name === 'tab_all_documents' &&
                        this.state.result_refresh ? (
                            <div>
                                {/*<InboxPage
										calingFrom='search'
										pageTitle='All Documents'
										//processId={1}
										//statusId={3}
										patientId={
											this.state.patient_filter.patient_id
										}
										updateState={(state) => {
											this.setState({ ...state });
										}}
										query={this.state.patient_documents_query}
								/>*/}

                                {/* <EditWorkPage /> */}

                                <DeletedDocumentPage
                                    {...this.state}
                                    calingFrom='search'
                                    pageTitle='Inbox'
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                    }}
                                    patientId={
                                        this.state.patient_filter.patient_id
                                    }
                                    query={this.state.patient_documents_query}
                                    show_result={true}
                                    tab_name={this.state.tab_name}
                                    mobileHide={this.state.mobileHide}
                                />
                            </div>
                        ) : this.state.patient_filter &&
                          this.state.patient_filter.patient_id !== '' &&
                          this.state.patient_filter.patient_id !== undefined &&
                          this.state.tab_name === 'tab_tracking' &&
                          this.state.result_refresh ? (
                            <div>
                                {/*<OutboxPage
                                    calingFrom='search'
                                    pageTitle='Tracking'
                                    processId={3}
                                    statusId={16}
                                    patientId={
                                        this.state.patient_filter.patient_id
                                    }
                                    showStatistics
                                    query={this.state.patient_tracking_query}
                                />
							*/}

                                <DeletedDocumentPage
                                    {...this.state}
                                    calingFrom='search'
                                    pageTitle='Tracking'
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                    }}
                                    patientId={
                                        this.state.patient_filter.patient_id
                                    }
                                    query={this.state.patient_tracking_query}
                                    show_result={true}
                                    tab_name={this.state.tab_name}
                                    mobileHide={this.state.mobileHide}
                                />
                            </div>
                        ) : this.state.patient_filter &&
                          this.state.patient_filter.patient_id !== '' &&
                          this.state.patient_filter.patient_id !== undefined &&
                          this.state.tab_name === 'tab_physicians' ? (
                            <>
                                <div>
                                    <PhysicianListingDataGrid
                                        patientId={
                                            this.state.patient_filter.patient_id
                                        }
                                        calingFrom='search'
                                        pageTitle='Patient Physicians'
                                    />
                                </div>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
    updateList,
    deletedPage,
    inboxPage,
};
const mapStateToProps = (state, ownProps) => {
    return {
        outbox: state.outbox,
        inbox: state.inbox,
        rowData: state.outbox.rowData,
        rulesJson: state.persist.rulesJson,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.outbox.selectedRowDetails,
        ddl: state.persistDDL.DDL,
        currentMenuName: state.persist.currentMenuName,
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PatientSearch));
