/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    getDocumentMetaWithDocId,
    getDocumentImage,
    getStamp,
    updateDocument,
} from './api/api';
import { Dropdown } from 'react-bootstrap';
import { SpinnerBlur } from '../components/inbox/Spinner';
import Loader from 'react-loader-spinner';
import EditDeleteModal from '../components/editpdf/EditDeleteModal';
import {
    getEditFontStyle,
    fontSizeData,
} from '../components/editpdf/PdfFunction';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import ImageEditor from './ImageEditorRef';
import colorPicker from 'tui-color-picker';
import { useDispatch, useSelector } from 'react-redux';
import { deletedPage } from './_redux/actions/searchActions';
toast.configure();

const getNumber = (value) => Number(value.replace('px', ''));
const resizeElement = (element, percentage, props = [], values = []) => {
    props.forEach((key, idx) => {
        element.style[key] = values[idx] * percentage + 'px';
    });
};
var zoomSave = 1;
var uploadTypeId = '';

function EditWorkPage(props) {
    var currentImagePosition = '';
    const defaultfontStyle = 'normal';
    const defaultfontWeight = 'normal';
    const defaultfontFamily = 'Times, Times New Roman, serif';
    const defaulttextDecoration = '';
    const defaultfontSize = '12';
    const defaultfontFill = '#000000';
    const defaultborderColor = '#f0923a';
    const defaultbgColor = '#f0923a';

    const editorRef = React.useRef(null);
    const [imgUrl, setImgUrl] = React.useState([]);
    const [changeImage, setchangeImage] = React.useState(true);
    const [stampItems, setStampItems] = useState([]);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [bgApply, setBgApply] = useState(0);
    const [, setBorderApply] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [showImage, setShowImage] = React.useState(false);
    const [blurSpinner, setBlurSpinner] = React.useState(false);
    const [fontSize, setfontSize] = React.useState(defaultfontSize);
    const [textDecoration, settextDecoration] = React.useState(
        defaulttextDecoration
    );
    const [fontFamily, setfontFamily] = React.useState(defaultfontFamily);
    const [fontStyle, setfontStyle] = React.useState(defaultfontStyle);
    const [fontWeight, setfontWeight] = React.useState(defaultfontWeight);
    const [fontFill, setFontFill] = React.useState(defaultfontFill);
    const [lastTextEditId, setlastTextEditId] = React.useState('');
    const [borderColor, setBorderColor] = useState(defaultborderColor);
    const [bgColor, setBgColor] = useState(defaultbgColor);
    const [stampShow, setStampShow] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [imageIndex, setImageIndex] = useState(0);
    const [prevImageIndex, setPrevImageIndex] = useState(0);
    const [deleteIndex, setDeleteIndex] = useState('');
    const [onLoad, setOnLoad] = useState(true);
    const [centerImgLoader, setCenterImgLoader] = useState(true);

    const [maxZoom, setMaxZoom] = useState(2);
    const [minZoom, setMinZoom] = useState(0.5);
    const [, setObjectActivateId] = useState(false);
    const [, setObjectActivatePropId] = useState(0);
    const [previewImages] = useState([]);
    const [initialPreviewImages, setInitialPreviewImages] = useState(false);
    const [, setCurrentImgPos] = useState(0);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [currentStrokeBorder, setCurrentStrokeBorder] = useState('green');
    const [currentFillColour, setCurrentFillColour] = useState('green');
    const reduxStore = useSelector((state) => state);
    const configData = reduxStore.configReducerPersist.configData;
    let editor: any = editorRef.current;
    let isMountedRef = useRef(true);

    const imageArr = [];
    const stampArr = [];
    const [zoomer, setZoomer] = useState(1);
    const search = useLocation().search;
    const editID = new URLSearchParams(search).get('edit');
    const dispatch = useDispatch();
    let location = useLocation();

    useEffect(() => {
        //  editor = editorRef.current;
        if (isMountedRef.current) {
            configData.uploadTypes.forEach((item, indx) => {
                if (item.type_flag.toLowerCase() === 'upload') {
                    uploadTypeId = item.docId;
                }
            });

            if (onLoad === true) {
                getStampFunction();
                documentImageUpload();

                async function documentImageUpload() {
                    let docidDecode = '';
                    let docid = '';
                    if (
                        props.hasOwnProperty('match') &&
                        props.match.params.docId !== ''
                    ) {
                        docidDecode = props.match.params.docId;
                        docid = window.atob(String(docidDecode));
                    }

                    if (docid === '') {
                        docid = window.atob(String(editID));
                    }

                    let response = getDocumentMetaWithDocId({ docid });
                    await response.then(async (data) => {
                        let pageCountTemp = data.miscPproperties.pageCount;

                        if (pageCountTemp !== undefined) {
                            if (pageCountTemp > 0) {
                                let pagecount = pageCountTemp;
                                setTotalCount(pagecount);
                                let status = 0;
                                let k = 0;
                                setShowSaveButton(true);
                                for (let i = 0; i < pageCountTemp; i++) {
                                    await getDocumentImage(docid, i + 1)
                                        .then((response) => {
                                            if (isMountedRef.current) {
                                                status = response.status;
                                                return response.blob();
                                            }
                                        })
                                        .then((data) => {
                                            k++;
                                            if (
                                                status === 200 &&
                                                isMountedRef.current
                                            ) {
                                                var reader = new FileReader();
                                                reader.readAsDataURL(data);
                                                reader.onloadend =
                                                    async function () {
                                                        var url = reader.result;

                                                        if (
                                                            i === 0 &&
                                                            isMountedRef.current
                                                        ) {
                                                            let editor: any =
                                                                editorRef.current;
                                                            if (
                                                                editor !==
                                                                    null &&
                                                                editor.hasOwnProperty(
                                                                    'imageEditorInst'
                                                                ) &&
                                                                editor.imageEditorInst !==
                                                                    null
                                                            ) {
                                                                editor.imageEditorInst.invoke(
                                                                    'loadImageFromURL',
                                                                    url,
                                                                    'pdf image'
                                                                );
                                                            }

                                                            change_image(
                                                                i,
                                                                url,
                                                                0
                                                            );
                                                            setInitialPreviewImages(
                                                                true
                                                            );
                                                        }
                                                        imageArr[i] = url;
                                                        setImgUrl([
                                                            ...imageArr,
                                                        ]);

                                                        var node =
                                                            document.createElement(
                                                                'img'
                                                            );
                                                        node.src = url;
                                                        node.onclick =
                                                            function () {
                                                                change_image(
                                                                    i,
                                                                    url,
                                                                    0
                                                                );
                                                            };

                                                        await previewImages.push(
                                                            isMountedRef.current ? (
                                                                <div
                                                                    className={`print-preview pdf-image `}
                                                                    id={
                                                                        'preview' +
                                                                        i
                                                                    }
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        toggleActive(
                                                                            event
                                                                        );
                                                                    }}
                                                                    key={i}
                                                                >
                                                                    <img
                                                                        id={
                                                                            'preview_img' +
                                                                            i
                                                                        }
                                                                        alt='text-edit'
                                                                        onClick={() =>
                                                                            change_image(
                                                                                i,
                                                                                url,
                                                                                0
                                                                            )
                                                                        }
                                                                        src={
                                                                            url
                                                                        }
                                                                    />
                                                                    <p>
                                                                        Page{' '}
                                                                        {i + 1}
                                                                    </p>
                                                                </div>
                                                            ) : null
                                                        );
                                                        if (
                                                            String(k) ===
                                                            String(pagecount)
                                                        ) {
                                                            setInitialPreviewImages(
                                                                ''
                                                            );
                                                            setShowImage(true);
                                                            setShowSaveButton(
                                                                false
                                                            );
                                                        }
                                                    };
                                            }

                                            if (status === 400 && i === 0) {
                                                setShowSaveButton(true);
                                                toast.error(
                                                    'No image to load',
                                                    {
                                                        position: 'top-right',
                                                        autoClose: true,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        showTransitionSuccess: false,
                                                    }
                                                );
                                            }
                                        });
                                }
                                setOnLoad(false);
                            } else {
                                setShowSaveButton(true);
                                toast.error('No image to load', {
                                    position: 'top-right',
                                    autoClose: true,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    showTransitionSuccess: false,
                                });
                            }
                        }
                    });
                }

                objectElementActiveFunction();
            }
            // setchangeImage(true)
            document.addEventListener('mousedown', closeColourPicker);
        }
        return () => {
            isMountedRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Sathish - if i remove [] editor getting issue lastTextEditId

    const closeColourPicker = (event) => {
        var popElement = document.getElementsByClassName('text-color');
        var popEl;
        if (document.getElementsByClassName('text-color')) {
            for (var i = 0; i < popElement.length; i++) {
                popEl = popElement[i];
                var isClickInside = popEl.contains(event.target);
                if (!isClickInside) {
                    selectColorPickers(event, 1, 1);
                    document
                        .getElementById('color-picker-bg')
                        .classList.remove('text-color');
                    document
                        .getElementById('color-picker')
                        .classList.remove('text-color');
                    document
                        .getElementById('color-picker-text')
                        .classList.remove('text-color');
                    return;
                }
            }
        }
    };

    const getStampFunction = () => {
        let stampStatus = 0;
        getStamp()
            .then((response) => {
                if (isMountedRef.current) {
                    stampStatus = response.status;
                    return response.json();
                }
            })
            .then((data) => {
                if (stampStatus === 200) {
                    data.forEach((item, i) => {
                        if (isMountedRef.current) {
                            stampArr.push(item);
                            setStampItems(stampArr);
                        }
                    });
                }
            });
    };

    const objectElementActiveFunction = () => {
        setObjectActivateId(false);
        let editor: any = editorRef.current;
        let imageEditor = editor.getInstance();
        imageEditor.on('objectActivated', (props) => {
            if (String(props.type) === 'i-text') {
                setlastTextEditId(props.id);

                if (
                    String(props.fontWeight) === 'normal' ||
                    String(props.fontWeight) === ''
                )
                    document
                        .getElementById('text-bold')
                        .classList.remove('bold-active');
                else
                    document
                        .getElementById('text-bold')
                        .classList.add('bold-active');

                if (
                    String(props.fontStyle) === 'normal' ||
                    String(props.fontStyle) === ''
                )
                    document
                        .getElementById('text-italic')
                        .classList.remove('italic-active');
                else
                    document
                        .getElementById('text-italic')
                        .classList.add('italic-active');

                if (
                    String(props.textDecoration) === 'undefined' ||
                    String(props.textDecoration) === '' ||
                    String(props.textDecoration) === 'none'
                )
                    document
                        .getElementById('text-underline')
                        .classList.remove('underline-active');
                else
                    document
                        .getElementById('text-underline')
                        .classList.add('underline-active');
            }

            setObjectActivateId(true);
            setObjectActivatePropId(props.id);
        });
    };

    const clearUndoStack = async (type) => {
        if (isMountedRef.current) {
            let editor: any = editorRef.current;
            let imageEditor = editor.getInstance();
            imageEditor.clearUndoStack();
        }
    };

    const zoomChange = async (type) => {
        if (type === 'in') {
            zoomSave = zoomer + 0.25;
        } else if (type === 'out') {
            zoomSave = zoomer - 0.25;
        }

        setZoomer(zoomSave);
        let maxHeight = height;
        let maxWidth = width;
        if (!loaded) {
            const canvas = document.querySelector('canvas');

            if (
                canvas.style.maxHeight !== '' &&
                canvas.style.maxHeight !== null &&
                canvas.style.maxHeight !== undefined
            )
                maxHeight = getNumber(canvas.style.maxHeight);
            else maxHeight = canvas.height;

            if (
                canvas.style.maxWidth !== '' &&
                canvas.style.maxWidth !== null &&
                canvas.style.maxWidth !== undefined
            )
                maxWidth = getNumber(canvas.style.maxWidth);
            else maxWidth = canvas.width;

            setHeight(maxHeight);
            setWidth(maxWidth);
            setLoaded(true);
        }

        resizeElement(
            document.querySelector('.tui-image-editor'),
            //zoom,
            zoomSave,
            ['height', 'width'],
            [maxHeight, maxWidth]
        );
        resizeElement(
            document.querySelector('.tui-image-editor-canvas-container'),
            //zoom,
            zoomSave,
            ['maxHeight', 'maxWidth'],
            [maxHeight, maxWidth]
        );

        document.querySelectorAll('canvas').forEach((element) => {
            resizeElement(
                element,
                zoomSave,
                ['maxHeight', 'maxWidth'],
                [maxHeight, maxWidth]
            );
        });
    };

    const backToPage = () => {
        props.updateState({
            single_edit: false,
            pdf_preview: false,
        });
        //setTimeout(() => {

        props.updateState({
            pdf_preview: true,
        });
        //}, 1000);
        if (
            props.calingFrom === 'search' ||
            String(location.pathname.includes('search'))
        ) {
            props.updateState({
                show_result: true,
            });
            dispatch(
                deletedPage({
                    backBtn: true,
                })
            );
        }
    };

    const overAllSaveFunction = (overAllUpdatedArray) => {
        let docidDecode;
        let docId;

        if (props.hasOwnProperty('match') && props.match.params.docId !== '') {
            docidDecode = props.match.params.docId;
            docId = window.atob(String(docidDecode));
        }
        if (docId === '' || docId === undefined) {
            docId = window.atob(String(editID));
        }

        let pages = [];
        let fields = [];

        overAllUpdatedArray.forEach((data, index) => {
            let encodeBase64 = data.split(',')[1];
            pages.push({ pageData: encodeBase64 });
        });

        let extension = 'png';
        let uploadDocStatus = 0;
        let updateDocData = updateDocument(
            {
                fields,
                pages,
                extension,
            },
            docId
        );
        updateDocData
            .then((response) => {
                uploadDocStatus = response.status;
                if (uploadDocStatus === 204 && isMountedRef.current) {
                    setBlurSpinner(false);

                    /*history.push({
                        pathname: '/' + path[1],
                        // search: '?edit=324234'
                    });*/
                    if (typeof props.updateState === 'function') {
                        backToPage();
                    }

                    toast.success('Document edited successfully', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        showTransitionSuccess: false,
                    });
                    history.goBack();
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                if (uploadDocStatus === 400 && isMountedRef.current) {
                    setBlurSpinner(false);
                    toast.error(data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        showTransitionSuccess: false,
                    });
                }
            });
    };

    const overallSaveAction = async () => {
        setBlurSpinner(true);
        if (imgUrl.length) {
            let overAllUpdatedArray = saveEditImage(1);
            await overAllSaveFunction(overAllUpdatedArray);
        }
    };

    const currentPageDelete = (index) => {
        setDeleteIndex(index);
        setOpenDelete(true);
    };

    const handleDeleteOperation = async () => {
        setShowImage(false);
        if (deleteIndex !== '') {
            let removePage = imgUrl.splice(deleteIndex, 1); // dont remove this line

            let nextIndex = deleteIndex;
            if (parseInt(imgUrl.length) > 1) {
                imgUrl.forEach((data, index) => {
                    if (String(index) === String(nextIndex)) {
                        change_image(index, data, 1);
                    }
                });
                setTotalCount(imgUrl.length);
                setTimeout(() => {
                    if (isMountedRef.current) {
                        setShowImage(true);
                    }
                }, 100);
            } else if (parseInt(imgUrl.length) === 1) {
                imgUrl.forEach((data, index) => {
                    change_image(index, data, 1);
                });
                setTotalCount(imgUrl.length);
                setTimeout(() => {
                    if (isMountedRef.current) {
                        setShowImage(true);
                    }
                }, 100);
            } else {
                setShowImage(false);
                setCurrentPage(0);
                setTotalCount(0);
            }
        }
        setOpenDelete(false);
    };

    const handleCloseOperation = () => {
        setOpenDelete(false);
        setDeleteIndex('');
    };

    const prevPage = () => {
        if (imgUrl.length) {
            let prevIndex = imageIndex - 1;
            imgUrl.forEach((data, index) => {
                if (String(index) === String(prevIndex))
                    change_image(index, data, 1, 'previous');
            });
        }
    };

    const nextPage = () => {
        if (imgUrl.length) {
            let nextIndex = imageIndex + 1;
            imgUrl.forEach((data, index) => {
                if (parseInt(index) === parseInt(nextIndex))
                    change_image(index, data, 1, 'next');
            });
        }
    };

    const handleCurrentPage = (event) => {
        setCurrentPage(event.target.value);
        if (imgUrl.length && String(event.target.value) !== '') {
            let currentIndex = event.target.value - 1;
            imgUrl.forEach((data, index) => {
                if (index === parseInt(currentIndex))
                    change_image(index, data, 1);
            });
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const sInd = +source.index;
        const dInd = +destination.index;

        setShowImage(false);
        if (String(sInd) === String(imageIndex)) {
            setImageIndex(dInd);
            setCurrentPage(dInd + 1);
        }
        if (imgUrl.length) {
            const result = Array.from(imgUrl);
            const [removed] = result.splice(sInd, 1);
            result.splice(dInd, 0, removed);
            setImgUrl(result);
            setTimeout(() => {
                if (isMountedRef.current) {
                    setShowImage(true);
                }
            }, 100);

            let tempImageData = result[0];

            setTimeout(() => {
                if (isMountedRef.current) {
                    let editor = editorRef.current;
                    editor.imageEditorInst
                        .loadImageFromURL(tempImageData, 'pdf image')

                        .then((result) => {
                            if (isMountedRef.current) {
                                editor.imageEditorInst.ui.activeMenuEvent();
                                clearUndoStack();
                            }
                        });

                    var removeClass = document.getElementById(
                        'preview' + imageIndex
                    );
                    if (removeClass) {
                        document
                            .getElementById('preview' + imageIndex)
                            .classList.remove('active');
                    }
                    if (imgUrl.length) {
                        imgUrl.forEach((data, index) => {
                            var removeClass = document.getElementById(
                                'preview' + index
                            );
                            if (removeClass) {
                                document
                                    .getElementById('preview' + index)
                                    .classList.remove('active');
                            }
                        });
                    }
                    var addClass = document.getElementById('preview0');
                    if (addClass) {
                        document
                            .getElementById('preview0')
                            .classList.add('active');
                    }
                }
            }, 100);
        }
    };

    const change_image = async (
        currentImageIndex,
        imageItem,
        page,
        prev,
        imgId
    ) => {
        if (isMountedRef.current) {
            setlastTextEditId('');
            setObjectActivateId(false);
            setObjectActivatePropId(0);
            setImageIndex(currentImageIndex);
            setCurrentPage(currentImageIndex + 1);
            setchangeImage(false);
        }

        if (imageItem !== '') {
            setTimeout(() => {
                if (isMountedRef.current) {
                    let editor = editorRef.current;

                    if (
                        editor !== null &&
                        editor.hasOwnProperty('imageEditorInst') &&
                        editor.imageEditorInst !== null
                    ) {
                        editor.imageEditorInst
                            .loadImageFromURL(imageItem, 'pdf image')

                            .then((result) => {
                                if (isMountedRef.current) {
                                    editor.imageEditorInst.ui.activeMenuEvent();
                                    clearUndoStack();
                                }
                            });
                    }
                    if (page !== 0) {
                        var addindex = 'preview' + currentImageIndex;
                        var addClass = document.getElementById(addindex);
                        let activeImgPos;
                        if (document.getElementById(addindex)) {
                            activeImgPos =
                                document.getElementById(addindex).offsetTop;
                        }
                        setCurrentImgPos(activeImgPos);

                        if (addClass) {
                            document
                                .getElementById(addindex)
                                .classList.add('active');
                        }
                        if (String(prev) === 'previous') {
                            var Removeindex =
                                'preview' + (currentImageIndex + 1);
                            var removeClass = document.getElementById(addindex);
                            let activeImgPos =
                                document.getElementById(Removeindex).offsetTop;
                            setCurrentImgPos(activeImgPos);
                            if (removeClass) {
                                document
                                    .getElementById(Removeindex)
                                    .classList.remove('active');
                            }
                        }
                        if (String(prev) === 'next') {
                            var RemoveindexNext =
                                'preview' + (currentImageIndex - 1);
                            var removeClassNext =
                                document.getElementById(addindex);
                            let activeImgPos =
                                document.getElementById(
                                    RemoveindexNext
                                ).offsetTop;
                            setCurrentImgPos(activeImgPos);
                            if (removeClassNext) {
                                document
                                    .getElementById(RemoveindexNext)
                                    .classList.remove('active');
                            }
                        }

                        imgUrl.map((item, index) => {
                            if (index !== currentImageIndex) {
                                var RemoveindexPreview = 'preview' + index;

                                var removeClassPre =
                                    document.getElementById(addindex);
                                if (removeClassPre) {
                                    document
                                        .getElementById(RemoveindexPreview)
                                        .classList.remove('active');
                                }
                            }
                            return item;
                        });
                    }
                }
            }, 100);
        }
        if (isMountedRef.current) {
            await setchangeImage(true);
            setZoomer(1);

            let minZoomClass = '';
            let maxZoomClass = '';

            await setCenterImgLoader(false);
        }

        if (currentImageIndex !== '' && isMountedRef.current) {
            var addindex = 'preview' + currentImageIndex;
            let activeImgPos;
            if (document.getElementById(addindex)) {
                activeImgPos = document.getElementById(addindex).offsetTop;
            }
            currentImagePosition = activeImgPos;
        }
        setTimeout(() => {
            if (parseInt(imgUrl.length) === 1) {
                if (isMountedRef.current) {
                    if (
                        document.getElementById('activeDelete') &&
                        document.getElementById('activeDelete') !== undefined
                    ) {
                        document.getElementById('activeDelete').style.display =
                            'none';
                    }
                }
            }
        }, 100);
    };

    const saveEditImage = (type, editImageIndex) => {
        var imageEditor = editor.getInstance();
        var dataUrl;
        if (imageEditor !== null) {
            dataUrl = imageEditor.toDataURL();
        }

        let getImageIndex = imageIndex;

        if (dataUrl !== '') {
            let newArr = [...imgUrl];
            newArr[getImageIndex] = dataUrl;
            var filtered = newArr.filter(function (el) {
                return el != null;
            });

            setImgUrl([...filtered]);

            setTimeout(() => {
                if (isMountedRef.current) {
                    setShowImage(false);
                    setShowImage(true);
                    if (document.getElementById('scrollPosition')) {
                        document.getElementById('scrollPosition').scrollTop =
                            currentImagePosition;
                    }
                    if (parseInt(imgUrl.length) === 1) {
                        if (
                            document.getElementById('activeDelete') &&
                            document.getElementById('activeDelete') !==
                                undefined
                        ) {
                            document.getElementById(
                                'activeDelete'
                            ).style.display = 'none';
                        }
                    }
                }
            }, 0.05);
        }
        if (parseInt(type) === 1) {
            return filtered;
        }
        setPrevImageIndex(editImageIndex);
    };

    function resetMenuTools() {
        removeallActiveEle('editor-custom-option', '', 'active_item');
        applySelectFunction('fontText', 'fontTextApply', 1);
        document
            .getElementById('borderSelect')
            .classList.remove('borderSelectApply');
        document.getElementById('bgSelect').classList.remove('bgSelectApply');

        document.getElementById('text-bold').classList.remove('bold-active');
        document
            .getElementById('text-italic')
            .classList.remove('italic-active');
        document
            .getElementById('text-underline')
            .classList.remove('underline-active');

        var array_of_li = document.querySelectorAll('.color-picker-multiple');
        var i;
        for (i = 0; i < array_of_li.length; ++i) {
            array_of_li[i].style.display = 'none';
        }
    }

    const selectStamp = async () => {
        discardSelection();
        resetMenuTools();
        setStampShow(true);
    };
    //for drag & drop
    const getListStyle = (isDraggingOver) => ({
        /*display: 'flex',*/
    });

    function getItemClass(isDragging, imageActive) {
        return isDragging
            ? 'image-inner on-drag-custom '
            : 'image-inner ' + imageActive;
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        // change background colour if dragging
        background: isDragging ? '#BFDFEE' : '', //grey
        border: isDragging ? '1px solid #007DBC' : '', //grey
        margin: isDragging ? '2px' : '', //grey
        padding: isDragging ? '0px' : '', //grey

        ...draggableStyle,
    });

    const getItemStyleInner = (isDragging, draggableStyle) => ({
        border: isDragging ? '1px solid #007DBC' : '', //grey
    });

    //for drag & drop

    const selectStampImage = (event) => {
        let imageEditor = editor.getInstance();
        imageEditor.stopDrawingMode();
        imageEditor.addImageObject(event.target.src).then((objectProps) => {
            if (isMountedRef.current) {
                imageEditor.setObjectPosition(objectProps.id, {
                    x: 10,
                    y: 10,
                    originX: 'left',
                    originY: 'top',
                });
            }
        });
    };

    function discardSelection() {
        if (
            editor !== null &&
            editor.hasOwnProperty('getInstance') &&
            editor.hasOwnProperty('discardSelection')
        ) {
            let imageEditor = editor.getInstance();

            imageEditor.deactivateAll();
            imageEditor.discardSelection();
        }
    }

    const selectColorPickers = (event, type, delColor) => {
        discardSelection();
        setStampShow(false);
        let deleteColor = delColor;
        let colorDisplay = 'block';
        if (deleteColor !== undefined && parseInt(deleteColor) === 1) {
            colorDisplay = 'none';
        }

        var array_of_li = document.querySelectorAll('.color-picker-multiple');

        var i;
        for (i = 0; i < array_of_li.length; ++i) {
            array_of_li[i].style.display = 'none';
        }

        let colortype = type;
        let colorId = '';

        if (
            delColor !== 1 &&
            (parseInt(colortype) === 1 || parseInt(colortype) === 2)
        ) {
            let editor: any = editorRef.current;
            let imageEditor = editor.getInstance();
            imageEditor.stopDrawingMode();
            imageEditor.deactivateAll();
            discardSelection();
        }

        if (parseInt(colortype) === 1) {
            var colorpickerborder = colorPicker.create({
                container: document.getElementById('color-picker'),
            });
            colorpickerborder.destroy();

            var result = document.getElementById('result');
            var colorpickerborderAppend = colorPicker.create({
                container: document.getElementById('color-picker'),
                color: borderColor,
            });

            var borderDelete = document.createElement('i');
            borderDelete.className = 'fa fa-window-close delete-color';
            borderDelete.setAttribute('aria-hidden', 'true');
            borderDelete.onclick = function (event) {
                selectColorPickers(event, 1, 1);
            };
            var borderParent = document.getElementById('color-picker');
            document
                .getElementById('color-picker-bg')
                .classList.remove('text-color');
            document.getElementById('color-picker').classList.add('text-color');
            borderParent.appendChild(borderDelete);

            colorpickerborderAppend.on('selectColor', function (ev) {
                discardSelection();
                result.value = JSON.stringify(ev, null, 8);
                document
                    .getElementById('add-rect')
                    .classList.add('active_item');
                document
                    .getElementById('borderSelect')
                    .classList.add('borderSelectApply');
                setBorderColor(ev.color);
                addShape(ev.color);
            });

            colorId = 'color-picker';
            if (
                document
                    .getElementById('add-rect')
                    .classList.contains('active_item')
            ) {
                if (
                    String(deleteColor) === 'undefined' &&
                    parseInt(deleteColor) !== 1
                )
                    commonActiveToggle('borderSelect', 'borderSelectApply');
            }
            if (
                !document
                    .getElementById('bgSelect')
                    .classList.contains('bgSelectApply') &&
                !document
                    .getElementById('borderSelect')
                    .classList.contains('borderSelectApply')
            ) {
                document
                    .getElementById('add-rect')
                    .classList.remove('active_item');
            }
        } else if (parseInt(colortype) === 2) {
            document
                .getElementById('color-picker-bg')
                .classList.add('text-color');
            document
                .getElementById('color-picker')
                .classList.remove('text-color');
            var colorpickerbg = colorPicker.create({
                container: document.getElementById('color-picker-bg'),
            });
            colorpickerbg.destroy();

            var resultBg = document.getElementById('result-bg');
            var colorpickerbgAppend = colorPicker.create({
                container: document.getElementById('color-picker-bg'),
                color: bgColor,
            });

            var bgDelete = document.createElement('i');
            bgDelete.className = 'fa fa-window-close delete-color';
            bgDelete.setAttribute('aria-hidden', 'true');
            bgDelete.onclick = function (event) {
                selectColorPickers(event, 2, 1);
            };
            var bgParent = document.getElementById('color-picker-bg');
            bgParent.appendChild(bgDelete);

            colorpickerbgAppend.on('selectColor', function (ev) {
                discardSelection();
                resultBg.value = JSON.stringify(ev, null, 8);
                document
                    .getElementById('bgSelect')
                    .classList.add('bgSelectApply');
                setBgApply(1);
                setBgColor(ev.color);
                addBackground(ev.color);
            });

            colorId = 'color-picker-bg';
            if (
                String(deleteColor) === 'undefined' &&
                parseInt(deleteColor) !== 1
            )
                commonActiveToggle('bgSelect', 'bgSelectApply');
            if (
                !document
                    .getElementById('bgSelect')
                    .classList.contains('bgSelectApply') &&
                !document
                    .getElementById('borderSelect')
                    .classList.contains('borderSelectApply')
            ) {
                document
                    .getElementById('add-rect')
                    .classList.remove('active_item');
            }
        } else if (parseInt(colortype) === 3) {
            document
                .getElementById('color-picker-text')
                .classList.add('text-color');
            var colorpickertext = colorPicker.create({
                container: document.getElementById('color-picker-text'),
            });
            colorpickertext.destroy();

            var resultText = document.getElementById('result-text');
            var colorpickertextAppend = colorPicker.create({
                container: document.getElementById('color-picker-text'),
                color: fontFill,
            });

            var newTH = document.createElement('i');
            newTH.className = 'fa fa-window-close delete-color';
            newTH.setAttribute('aria-hidden', 'true');
            newTH.onclick = function (event) {
                selectColorPickers(event, 3, 1);
            };
            var textappend = document.getElementById('color-picker-text');
            textappend.appendChild(newTH);

            colorpickertextAppend.on('selectColor', (ev) => {
                discardSelection();
                resultText.value = JSON.stringify(ev, null, 8);
                changeTextColor(ev.color);
                setFontFill(ev.color);
            });
            colorId = 'color-picker-text';
        }

        document.getElementById(colorId).style.display = colorDisplay;
    };

    function handleSelectFontSize(event) {
        let imageEditor = editor.getInstance();
        imageEditor.stopDrawingMode();
        setfontSize(event.target.value);
        if (lastTextEditId !== '') {
            imageEditor['changeTextStyle'](lastTextEditId, {
                fontSize: event.target.value * 4, //kalpana for font
            });
        }
    }

    function handleSelectChange(event) {
        let imageEditor = editor.getInstance();
        imageEditor.stopDrawingMode();
        setfontFamily(event.target.value);
        if (lastTextEditId !== '') {
            imageEditor['changeTextStyle'](lastTextEditId, {
                fontFamily: event.target.value,
            });
        }
    }

    function removeColorPicker() {
        var array_of_li = document.querySelectorAll('.color-picker-multiple');
        for (var i = 0; i < array_of_li.length; ++i) {
            array_of_li[i].style.display = 'none';
        }
    }

    const addTexte = () => {
        objectElementActiveFunction();

        let imageEditor = editor.getInstance();
        imageEditor.stopDrawingMode();
        imageEditor.deactivateAll();
        discardSelection();

        imageEditor['addText']('Start typing here......', {
            styles: {
                fill: fontFill,
                fontSize: fontSize * 4, //kalpana for font
                color: fontFill,
                fontWeight: fontWeight,
                fontStyle: fontStyle,
                textDecoration: textDecoration,
                textAlign: 'center',
                fontFamily: fontFamily,
            },
            position: {
                x: 100,
                y: 100,
            },
            autofocus: false,
        }).then(async (objectProps) => {
            if (isMountedRef.current) {
                var customObjID = objectProps.id;

                await setlastTextEditId(customObjID);
            }
        });
    };

    const changeTextColor = (selectedTextColor) => {
        let selectedtext = fontFill;
        if (
            selectedTextColor !== undefined &&
            selectedTextColor !== '' &&
            typeof selectedTextColor !== 'object'
        )
            selectedtext = selectedTextColor;
        let editor: any = editorRef.current;
        let imageEditor = editor.getInstance();
        if (lastTextEditId !== '') {
            imageEditor['changeTextStyle'](lastTextEditId, {
                fill: selectedtext,
            });
        }
    };

    function addLineFree(color) {
        let editor: any = editorRef.current;
        let imageEditor = editor.getInstance();
        imageEditor.stopDrawingMode();
        imageEditor.deactivateAll();
        discardSelection();
        imageEditor.startDrawingMode('LINE_DRAWING');
        var lineColor = borderColor;
        if (color != undefined) {
            lineColor = color;
        }
        imageEditor.setBrush({
            width: 5,
            color: lineColor,
        });
    }

    function addLine(color) {
        let editor: any = editorRef.current;
        let imageEditor = editor.getInstance();
        imageEditor.stopDrawingMode();
        imageEditor.deactivateAll();
        discardSelection();

        var lineColor = borderColor;
        if (color !== undefined) {
            lineColor = color;
        }
        editor.getInstance()['startDrawingMode']('LINE_DRAWING', {
            width: 5,
            color: lineColor,
            arrowType: {
                tail: 'chevron', // triangle
            },
        });
    }

    function addPen(color) {
        let editor: any = editorRef.current;
        let imageEditor = editor.getInstance();
        imageEditor.stopDrawingMode();
        imageEditor.deactivateAll();
        discardSelection();
        if (color !== undefined) {
            editor.getInstance()['startDrawingMode']('FREE_DRAWING', {
                width: 5,
                color: color,
                arrowType: {
                    tail: 'chevron', // triangle
                },
            });
        } else {
            editor.getInstance()['startDrawingMode']('FREE_DRAWING', {
                width: 5,
                color: borderColor,
                arrowType: {
                    tail: 'chevron', // triangle
                },
            });
        }
    }

    async function addShape(selectedBorderColor, isBg = '') {
        if (
            document
                .getElementById('borderSelect')
                .classList.contains('borderSelectApply') ||
            document
                .getElementById('bgSelect')
                .classList.contains('bgSelectApply')
        ) {
            objectElementActiveFunction();
            var fillColor = 'transparent';
            if (parseInt(bgApply) === 1) {
                fillColor = bgColor;
            }
            if (parseInt(isBg) === 0) {
                fillColor = 'transparent';
            }

            let selectedborder = '';
            if (
                document
                    .getElementById('borderSelect')
                    .classList.contains('borderSelectApply')
            )
                selectedborder = borderColor;
            if (
                selectedBorderColor !== undefined &&
                selectedBorderColor !== '' &&
                typeof selectedBorderColor !== 'object'
            ) {
                selectedborder = selectedBorderColor;
            }

            let strokeBorder = selectedborder;

            let editor: any = editorRef.current;
            let imageEditor = editor.getInstance();
            imageEditor.deactivateAll();
            discardSelection();
            setCurrentStrokeBorder(strokeBorder);
            setCurrentFillColour(fillColor);
            imageEditor['startDrawingMode']('SHAPE', {
                width: 10,
                color: strokeBorder,
                //color: selectedborder,
            });
            imageEditor['setDrawingShape']('rect', {
                fill: fillColor,
                stroke: strokeBorder,
                //stroke: selectedborder,
                strokeWidth: 5,
                //rx: 10,
                //ry: 10,
            });
        }

        if (
            document
                .getElementById('add-arrow')
                .classList.contains('active_item')
        ) {
            document.getElementById('add-rect').classList.remove('active_item');
            addLine(selectedBorderColor);
        }

        if (
            document
                .getElementById('add-line')
                .classList.contains('active_item')
        ) {
            document.getElementById('add-rect').classList.remove('active_item');
            addLineFree(selectedBorderColor);
        }

        //Abu===>22-12-2021
        if (
            document
                .getElementById('add-pencil')
                .classList.contains('active_item')
        ) {
            document.getElementById('add-rect').classList.remove('active_item');
            addPen(selectedBorderColor);
        }
    }

    async function addBackground(bgselectedcolor, isBorder = '') {
        if (
            document
                .getElementById('bgSelect')
                .classList.contains('bgSelectApply')
        ) {
            let selectedbg = bgColor;
            if (
                bgselectedcolor !== undefined &&
                bgselectedcolor !== '' &&
                typeof bgselectedcolor !== 'object'
            )
                selectedbg = bgselectedcolor;
            var strokeBorder = selectedbg;
            if (
                document
                    .getElementById('borderSelect')
                    .classList.contains('borderSelectApply')
            ) {
                strokeBorder = borderColor;
            }

            if (parseInt(isBorder) === 0) {
                strokeBorder = selectedbg;
            }

            let editor: any = editorRef.current;
            editor.getInstance()['startDrawingMode']('SHAPE', {
                width: 10,
                color: selectedbg,
            });
            editor.getInstance()['setDrawingShape']('rect', {
                //stroke: borderColor,
                stroke: strokeBorder,
                fill: selectedbg,
                strokeWidth: 5,
                //rx: 10,
                //ry: 10,
            });
        }
    }

    function formatTextBold(className) {
        if (String(lastTextEditId) !== '') {
            commonActiveToggle(className, 'bold-active');
            let boldTextApply = 'normal';
            if (
                document
                    .getElementById('text-bold')
                    .classList.contains('bold-active')
            ) {
                boldTextApply = 'bold';
            } else {
            }

            //setfontWeight('bold');
            setfontWeight(boldTextApply);

            let imageEditor = editor.getInstance();
            imageEditor.stopDrawingMode();
            if (lastTextEditId !== '') {
                imageEditor['changeTextStyle'](lastTextEditId, {
                    //fontWeight: 'bold',
                    fontWeight: boldTextApply,
                });
            }
        }
        removeColorPicker();
    }

    function formatTextItalic(className) {
        if (String(lastTextEditId) !== '') {
            commonActiveToggle(className, 'italic-active');
            let italicTextApply = 'normal';
            if (
                document
                    .getElementById('text-italic')
                    .classList.contains('italic-active')
            ) {
                italicTextApply = 'italic';
            }

            //setfontStyle('italic');
            else setfontStyle(italicTextApply);

            let imageEditor = editor.getInstance();
            imageEditor.stopDrawingMode();
            if (lastTextEditId !== '') {
                imageEditor['changeTextStyle'](lastTextEditId, {
                    //fontStyle: 'italic',
                    fontStyle: italicTextApply,
                });
            }
        }
        removeColorPicker();
    }

    function formatTextUnderline(className) {
        if (String(lastTextEditId) !== '') {
            commonActiveToggle(className, 'underline-active');
            let underlineTextApply = 'none';
            if (
                document
                    .getElementById('text-underline')
                    .classList.contains('underline-active')
            ) {
                underlineTextApply = 'underline';
            }

            //settextDecoration('underline');
            else settextDecoration(underlineTextApply);

            let imageEditor = editor.getInstance();
            imageEditor.stopDrawingMode();
            if (lastTextEditId !== '') {
                imageEditor['changeTextStyle'](lastTextEditId, {
                    //textDecoration: 'underline',
                    textDecoration: underlineTextApply,
                });
            }
        }
        removeColorPicker();
    }

    function resetClear() {
        //new
        resetMenuTools();
        let editor = editorRef.current;
        var imageEditor = editor.getInstance();
        if (!imageEditor.isEmptyUndoStack()) {
            imageEditor.undo();
        }
        setlastTextEditId('');
        setObjectActivateId(false);
        setObjectActivatePropId(0);
    }

    /*function deleteAllObject() {

        let imageEditor = editor.getInstance();
        imageEditor.changeSelectableAll(true);
    }*/

    function deleteObject() {
        //new
        let imageEditor = editor.getInstance();
        imageEditor.clearObjects();
        setlastTextEditId('');
        setObjectActivateId(false);
        setObjectActivatePropId(0);
        removeColorPicker();
        remove_all_active();
    }

    /*function flipImageByAxis() {
        editor.getInstance().flipX();
    }

    function flipImageByAxis() {
        editor.getInstance().flipY();
    }*/

    function rotateclockwise() {
        let editor: any = editorRef.current;
        let imageEditor = editor.getInstance();
        imageEditor.rotate(90);
        removeColorPicker();
    }

    function rotateAnticlockwise() {
        let editor: any = editorRef.current;
        let imageEditor = editor.getInstance();
        imageEditor.rotate(-90);
        removeColorPicker();
    }

    var list = [];
    for (let i = 0; i < totalCount; i++) {
        list.push(
            <div key={i}>
                <div
                    style={{
                        width: '100px',
                        height: '100px',
                        textAlign: 'center',
                        marginTop: '20px',
                    }}
                >
                    <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                    ></span>
                </div>
                <p>Page {i + 1}</p>
            </div>
        );
    }

    var prevClass = currentPage <= 1 ? 'disabled' : '';
    var nextClass = currentPage >= totalCount ? 'disabled' : '';
    let minZoomClass = minZoom >= zoomer ? 'disabled' : '';
    let maxZoomClass = maxZoom <= zoomer ? 'disabled' : '';

    let toggleActive = (event, id) => {
        var array_of_li = document.querySelectorAll('.pdf-image');
        let className = 'active';
        var i;
        for (i = 0; i < array_of_li.length; ++i) {
            array_of_li[i].classList.remove(className);
        }
        if (!event.target.parentNode.classList.contains(className))
            event.target.parentNode.classList.add(className);
    };

    function applySelectFunction(selctedClassName, classNameAssign, isactive) {
        var array_of_li = document.querySelectorAll('.' + selctedClassName);
        var i;
        for (i = 0; i < array_of_li.length; ++i) {
            if (parseInt(isactive) === 0) {
                //add class
                array_of_li[i].classList.add(classNameAssign);
            } else {
                //remove class
                array_of_li[i].classList.remove(classNameAssign);
            }
        }
    }

    function removeallActiveEle(queryClasName, idval, className) {
        const currentClass = document.getElementsByClassName(queryClasName);
        for (let i = 0; i < currentClass.length; i++) {
            currentClass[i].classList.remove(className);
            if (idval !== '') {
                document.getElementById(idval).classList.add(className);
                if (
                    String(idval) === 'add-line' ||
                    String(idval) === 'add-arrow' ||
                    String(idval) === 'add-pencil' ||
                    String(idval) === 'add-text'
                ) {
                    setBgApply(0);
                    document
                        .getElementById('bgSelect')
                        .classList.remove('bgSelectApply');
                }
            }
        }
    }

    function applySelect(idval, className) {
        if (String(idval) === 'add-text') {
            applySelectFunction('fontText', 'fontTextApply', 0);
            applySelectFunction('borderSelect', 'borderSelectApply', 1);
        }

        //    if(String(idval) === 'add-rect'){
        //     applySelectFunction('addRect', 'borderSelectApply', 0);
        //    }

        if (
            String(idval) === 'add-line' ||
            String(idval) === 'add-arrow' ||
            String(idval) === 'add-rect' ||
            String(idval) === 'add-pencil'
        ) {
            //setBorderApply(1);
            if (String(idval) === 'add-rect' && parseInt(bgApply) === 1) {
                //Do nothing
                //setBorderApply(0);
                // applySelectFunction('borderSelect', 'borderSelectApply', 0);
            } else {
                applySelectFunction('borderSelect', 'borderSelectApply', 0);
            }
            // if(String(idval) === 'add-pencil'){
            //     applySelectFunction(idval, className, 1);
            // }

            applySelectFunction('fontText', 'fontTextApply', 1);
        }

        removeallActiveEle('editor-custom-option', idval, className);
        removeColorPicker();
    }

    function commonActiveToggle(toggleId, className) {
        if (document.getElementById(toggleId).classList.contains(className)) {
            if (String(toggleId) === 'borderSelect') {
                setBorderApply(0);
                addBackground('', 0);
            }
            if (String(toggleId) === 'bgSelect') {
                setBgApply(0);
                addShape('', 0);
            }
            document.getElementById(toggleId).classList.remove(className);
        } else {
            document.getElementById(toggleId).classList.add(className);
            if (String(toggleId) === 'borderSelect') {
                setBorderApply(1);
                addShape();
            }
            if (String(toggleId) === 'bgSelect') {
                setBgApply(1);
                removeallActiveEle(
                    'editor-custom-option',
                    'add-rect',
                    'active_item'
                );
                applySelectFunction('fontText', 'fontTextApply', 1);
                addBackground();
            }
        }
    }
    const history = useHistory();

    function remove_all_active() {
        const iconsArray = [
            'add-line',
            'add-arrow',
            'add-pencil',
            'add-text',
            'add-rect',
            'borderSelect',
            'bgSelect',
            'fontText',
        ];
        iconsArray.map((data) => {
            if (isMountedRef.current) {
                if (
                    document
                        .getElementById(data)
                        .classList.contains('active_item')
                ) {
                    document
                        .getElementById(data)
                        .classList.remove('active_item');
                }
                if (
                    document
                        .getElementById(data)
                        .classList.contains('borderSelectApply')
                ) {
                    document
                        .getElementById(data)
                        .classList.remove('borderSelectApply');
                }
                if (
                    document
                        .getElementById(data)
                        .classList.contains('bgSelectApply')
                ) {
                    document
                        .getElementById(data)
                        .classList.remove('bgSelectApply');
                }
                if (
                    document
                        .getElementById(data)
                        .classList.contains('fontTextApply')
                ) {
                    document
                        .getElementById(data)
                        .classList.remove('fontTextApply');
                }
            }
        });

        if (document.getElementsByClassName('fontText')) {
            var array_of_li = document.querySelectorAll('.fontText');
            var i;
            for (i = 0; i < array_of_li.length; ++i) {
                //remove class
                if (isMountedRef.current) {
                    array_of_li[i].classList.remove('fontTextApply');
                }
            }
        }
    }

    // if (editor !== null && editor.hasOwnProperty('getInstance')) {
    //     let imageEditor = editor.getInstance();
    // }

    // const rectangle_refresh = () => {
    //     let editor: any = editorRef.current;
    //     let imageEditor = editor.getInstance();
    //     imageEditor.deactivateAll();
    //     discardSelection();
    //     imageEditor['startDrawingMode']('SHAPE', {
    //         width: 10,
    //         color: currentStrokeBorder,
    //         //color: selectedborder,
    //     });
    //     imageEditor['setDrawingShape']('rect', {
    //         fill: currentFillColour,
    //         stroke: currentStrokeBorder,
    //         //stroke: selectedborder,
    //         strokeWidth: 5,
    //         //rx: 10,
    //         //ry: 10,
    //     });
    // };

    return (
        <>
            <div className='right-aside edit_page print-page'>
                <div className='top-controls'></div>
                <div
                    className='print-top'
                    // style={props.style !== undefined ? { ...props.style } : {}}
                >
                    <div className='edit-row d-flex align-items-stretch justify-content-between mb-4'>
                        <div className='print-editer'>
                            <div className='text-edit'>
                                <span
                                    className='m-0 editor-custom-option'
                                    title='Add Text'
                                    id='add-text'
                                    onClick={() => {
                                        addTexte();
                                        applySelect('add-text', 'active_item');
                                    }}
                                >
                                    {/* <img
                                        alt='text-edit'
                                        src={
                                            window.location.origin +
                                            '/images/text-edit.svg'
                                        }
								/>*/}
                                    <i className='fa-regular fa-text'></i>
                                </span>
                                <span
                                    className='editor-custom-option'
                                    id='add-line'
                                    title='Add Line'
                                    onClick={() => {
                                        addLineFree();
                                        applySelect('add-line', 'active_item');
                                    }}
                                >
                                    <i className='fas fa-slash'></i>
                                </span>
                                <span
                                    className='editor-custom-option'
                                    id='add-arrow'
                                    title='Add Arrow'
                                    onClick={() => {
                                        addLine();
                                        applySelect('add-arrow', 'active_item');
                                    }}
                                >
                                    <i className='fa-regular fa-arrow-up-right'></i>
                                    {/* <img
                                        alt='text-edit'
                                        src={
                                            window.location.origin +
                                            '/images/arrow.svg'
                                        }
/>*/}
                                </span>
                                <span
                                    className='editor-custom-option'
                                    id='add-rect'
                                    title='Add Shape'
                                    onClick={() => {
                                        applySelect('add-rect', 'active_item');
                                        addShape();
                                    }}
                                >
                                    <i className='fa fa-square'></i>
                                </span>
                                <span
                                    className='editor-custom-option'
                                    id='add-pencil'
                                    title='Pen'
                                    onClick={() => {
                                        addPen();
                                        applySelect(
                                            'add-pencil',
                                            'active_item'
                                        );
                                    }}
                                >
                                    {/* <img
                                        alt='text-edit'
                                        src={
                                            window.location.origin +
                                            '/images/color.svg'
                                        }
                                    />*/}
                                    <svg
                                        className='icon-fill'
                                        viewBox='0 0 17 16'
                                        width='16'
                                        height='17'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path d='M16.002 2.71875L14.1562 0.873047C13.8633 0.550781 13.4531 0.375 13.043 0.375C12.6621 0.375 12.2812 0.521484 11.9883 0.785156L3.58008 8.05078C3.25781 8.34375 3.14062 8.7832 3.25781 9.16406L3.63867 10.4238L2.67188 11.4199C2.4082 11.6836 2.37891 12.123 2.64258 12.3867L0.738281 14.291C0.328125 14.7012 0.621094 15.375 1.17773 15.375H3.31641C3.43359 15.375 3.55078 15.3457 3.63867 15.2578L4.57617 14.3203C4.83984 14.4961 5.2207 14.4961 5.48438 14.2324L6.45117 13.2656L7.71094 13.6465C7.79883 13.6758 7.91602 13.6758 8.0332 13.6758C8.32617 13.6758 8.61914 13.5586 8.82422 13.3242L16.0898 4.94531C16.6465 4.27148 16.6172 3.30469 16.002 2.71875ZM7.91602 12.2109L6.07031 11.625L4.98633 12.7383L4.13672 11.8887L5.25 10.8047L4.66406 8.98828L5.57227 8.16797L8.67773 11.2734L7.91602 12.2109ZM15.0059 4.00781L9.61523 10.2188L6.68555 7.25977L12.8965 1.83984C12.9551 1.81055 12.9844 1.78125 13.043 1.78125C13.0723 1.78125 13.1309 1.81055 13.1895 1.83984L15.0352 3.71484C15.0938 3.77344 15.0938 3.89062 15.0059 4.00781Z' />
                                    </svg>
                                </span>

                                {
                                    <div id='stamp-outer'>
                                        <Dropdown
                                            className='bulk-print-opt'
                                            style={{
                                                backgroundColor: 'white',
                                            }}
                                            onClick={selectStamp}
                                        >
                                            <Dropdown.Toggle
                                                variant='success'
                                                id='dropdown-basic'
                                                title='Add Stamp'
                                            >
                                                <svg
                                                    className='icon-fill'
                                                    width='15'
                                                    height='16'
                                                    viewBox='0 0 15 16'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path d='M13.5938 13.9688H1.40625C1.14258 13.9688 0.9375 14.1738 0.9375 14.4375V14.9062C0.9375 15.1699 1.14258 15.375 1.40625 15.375H13.5938C13.8281 15.375 14.0625 15.1699 14.0625 14.9062V14.4375C14.0625 14.2031 13.8281 13.9688 13.5938 13.9688ZM12.1582 7.875H10.2246C9.75586 7.875 9.375 7.49414 9.375 7.02539V6.76172C9.375 5.94141 9.60938 5.17969 9.99023 4.47656C10.2539 3.94922 10.3711 3.33398 10.2539 2.68945C10.0488 1.54688 9.11133 0.609375 7.96875 0.433594C7.82227 0.404297 7.64648 0.375 7.5 0.375C5.91797 0.375 4.6875 1.63477 4.6875 3.1875C4.6875 3.62695 4.77539 4.00781 4.92188 4.35938C5.33203 5.20898 5.625 6.11719 5.5957 7.05469C5.5957 7.52344 5.21484 7.875 4.74609 7.875H2.8125C1.23047 7.875 0 9.13477 0 10.6875V12.0938C0 12.5918 0.410156 13.0312 0.9375 13.0312H14.0625C14.5605 13.0312 15 12.5918 15 12.0938V10.6875C15 9.13477 13.7402 7.875 12.1582 7.875ZM13.5938 11.625H1.40625V10.6875C1.40625 9.89648 2.02148 9.28125 2.8125 9.28125H4.74609C6.00586 9.28125 7.00195 8.25586 7.03125 7.02539C7.03125 6 6.76758 4.94531 6.21094 3.74414C6.12305 3.59766 6.09375 3.39258 6.09375 3.1875C6.09375 2.83594 6.21094 2.48438 6.50391 2.2207C6.76758 1.92773 7.11914 1.78125 7.5 1.78125C7.55859 1.78125 7.64648 1.81055 7.73438 1.81055C8.29102 1.89844 8.75977 2.39648 8.87695 2.92383C8.93555 3.24609 8.87695 3.56836 8.73047 3.83203C8.20312 4.85742 7.96875 5.79492 7.96875 6.76172V7.02539C7.96875 8.28516 8.96484 9.28125 10.2246 9.28125H12.1875C12.9492 9.28125 13.5938 9.92578 13.5938 10.6875V11.625Z' />
                                                </svg>

                                                <i className='fa-regular fa-angle-down'></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                className={
                                                    stampItems.length > 0
                                                        ? 'stamp-grp'
                                                        : ''
                                                }
                                            >
                                                {stampShow &&
                                                stampItems.length > 0 ? (
                                                    stampItems.map(
                                                        (stamp, index) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        alt='text-edit'
                                                                        onClick={
                                                                            selectStampImage
                                                                        }
                                                                        src={`data:image/png;base64,${stamp}`}
                                                                    />
                                                                </Dropdown.Item>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <Dropdown.Item>
                                                        There are no stamps
                                                        configured
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                }
                            </div>
                            <div className='color-edit'>
                                <div
                                    className='text-edit borderSelect'
                                    id='borderSelect'
                                >
                                    <div
                                        className='selectColorDiv border-color'
                                        title='Add Outline'
                                        onClick={(event) =>
                                            selectColorPickers(event, 1)
                                        }
                                    >
                                        <span
                                            className='selectColorPicker'
                                            style={{
                                                border: `3px solid ${borderColor}`,
                                            }}
                                        ></span>
                                        <i
                                            aria-hidden='true'
                                            className='fa-regular fa-angle-down'
                                        ></i>
                                    </div>
                                    <div
                                        id='color-picker'
                                        className='color-picker-multiple color-text-picker '
                                    ></div>
                                    <div className='explain'>
                                        <p id='result'></p>
                                    </div>
                                </div>
                                <div className='text-edit' id='bgSelect'>
                                    <div
                                        onClick={(event) =>
                                            selectColorPickers(event, 2)
                                        }
                                        className='selectColorDiv'
                                        title='Add Background'
                                    >
                                        <span
                                            className='selectColorPicker'
                                            style={{ backgroundColor: bgColor }}
                                        ></span>
                                        <i
                                            className='fa-regular fa-angle-down'
                                            aria-hidden='true'
                                        ></i>
                                    </div>
                                    <div
                                        id='color-picker-bg'
                                        className='color-picker-multiple color-text-picker bg-color'
                                    ></div>
                                    <div className='explainbg'>
                                        <p id='result-bg'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-edit edit-fonts'>
                                <span
                                    className='select-span fonts fontText'
                                    title='Font'
                                    id='fontText'
                                >
                                    <select
                                        name='font_name'
                                        id=''
                                        value={fontFamily}
                                        onClick={removeColorPicker}
                                        onChange={handleSelectChange}
                                        style={{ maxWidth: '135px' }}
                                    >
                                        <option value='0'> Select Font </option>
                                        {getEditFontStyle().map(
                                            (fontData, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={fontData}
                                                    >
                                                        {' '}
                                                        {fontData}{' '}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </span>
                                <span
                                    className='select-span fontText'
                                    title='Font Size'
                                >
                                    <select
                                        name='font_size'
                                        id='font_size'
                                        value={fontSize}
                                        onClick={removeColorPicker}
                                        onChange={handleSelectFontSize}
                                    >
                                        {fontSizeData().map((sizeData, i) => {
                                            return (
                                                <option
                                                    value={sizeData}
                                                    key={i}
                                                >
                                                    {' '}
                                                    {sizeData}{' '}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </span>
                                <div className='text-edit fontText'>
                                    <div
                                        onClick={(event) =>
                                            selectColorPickers(event, 3)
                                        }
                                        className='selectColorDiv'
                                        title='Text Color'
                                    >
                                        <span
                                            className='selectColorPicker'
                                            style={{
                                                backgroundColor: fontFill,
                                            }}
                                        ></span>
                                        <i
                                            className='fa-regular fa-angle-down'
                                            aria-hidden='true'
                                        ></i>
                                    </div>
                                    <div
                                        id='color-picker-text'
                                        className='color-picker-multiple color-text-picker'
                                        style={{ display: 'none' }}
                                    ></div>
                                    <div className='explaintext'>
                                        <p id='result-text'></p>
                                    </div>
                                </div>
                                <ul>
                                    <li
                                        id='text-bold'
                                        title='Bold'
                                        onClick={() => {
                                            formatTextBold('text-bold');
                                        }}
                                    >
                                        <i className={`fa fa-bold`}></i>
                                    </li>
                                    <li
                                        id='text-italic'
                                        title='Italic'
                                        onClick={() => {
                                            formatTextItalic('text-italic');
                                        }}
                                    >
                                        <i className={`fa fa-italic`}></i>
                                    </li>
                                    <li
                                        id='text-underline'
                                        title='Underline'
                                        onClick={() => {
                                            formatTextUnderline(
                                                'text-underline'
                                            );
                                        }}
                                    >
                                        <i className={`fa fa-underline`}></i>
                                    </li>
                                </ul>
                            </div>

                            <p className='text-edit reset-edit'>
                                <span
                                    className='reset-icon'
                                    title='Undo'
                                    onClick={resetClear}
                                >
                                    <img
                                        alt='text-edit'
                                        src={
                                            window.location.origin +
                                            '/images/red-forward.svg'
                                        }
                                    />
                                </span>
                                <span
                                    className='editer-delete'
                                    title='Delete'
                                    onClick={deleteObject}
                                >
                                    <i className={`fa-light fa-trash-can`}></i>
                                </span>
                            </p>
                            <p className='text-edit rotate-edit'>
                                <span
                                    onClick={rotateAnticlockwise}
                                    className='rotate-icon'
                                    title='Rotate Left'
                                >
                                    <i
                                        className={`fa-regular fa-rotate-left`}
                                    ></i>
                                </span>
                                <span
                                    onClick={rotateclockwise}
                                    className='rotate-icon'
                                    title='Rotate Right'
                                >
                                    <i
                                        className={`fa-regular fa-rotate-right`}
                                    ></i>
                                </span>
                            </p>
                        </div>
                        <div className='d-flex align-items-center justify-content-end edit-btns'>
                            {/* {userDetail.userName === 'WV-VADIVELAN' ? ( */}
                            <button
                                className='btn-can'
                                onClick={() => {
                                    history.goBack();
                                    // history.push({
                                    //    // pathname: '/' + path[1],
                                    //     // search: '?edit=324234'
                                    // });
                                    backToPage();
                                }}
                            >
                                Cancel
                            </button>
                            {/* ) : (
                                <button
                                    title='Cancel'
                                    className='btn-can'
                                    onClick={() => props.history.goBack()}
                                >
                                    Cancel
                                </button>
                            )} */}
                            <button
                                title='Save & close'
                                disabled={showSaveButton}
                                className={
                                    showSaveButton
                                        ? 'save-disabled btn-sav'
                                        : 'btn-sav'
                                }
                                onClick={overallSaveAction}
                            >
                                Save & close
                            </button>
                        </div>
                    </div>
                    <div className='print-zoom'>
                        <div className='row'>
                            <div className='col-md-6 col-7 pr-0 pdf-counter'>
                                <i
                                    onClick={prevPage}
                                    className={`fa fa-chevron-up ${prevClass}`}
                                ></i>
                                <input
                                    type='text'
                                    onChange={handleCurrentPage}
                                    value={currentPage}
                                />
                                <span>/ {totalCount}</span>
                                <i
                                    onClick={nextPage}
                                    className={`fa fa-chevron-down ${nextClass}`}
                                ></i>
                            </div>
                            <div className='col-md-6 col-5 zoom-icon'>
                                <span
                                    className={minZoomClass}
                                    onClick={() => zoomChange('out')}
                                >
                                    <i className='fa-light fa-search-minus'></i>
                                </span>
                                {zoomer * 100}%
                                <span
                                    className={maxZoomClass}
                                    onClick={() => zoomChange('in')}
                                >
                                    <i className='fa-light fa-search-plus'></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {openDelete ? (
                        <EditDeleteModal
                            openDelete={openDelete}
                            handleDeleteOperation={handleDeleteOperation}
                            handleCloseOperation={handleCloseOperation}
                        />
                    ) : null}
                    {showImage ? (
                        <div
                            className='left-content col-lg-1  pr-0'
                            id='scrollPosition'
                        >
                            {/* imgUrl.length > 0 ?
							imgUrl.map((item, index) => {
							  let imageActive = (imageIndex == index) ? 'active' : '';
							  return (<div className={`print-preview ${imageActive}`} key={index}><img onClick={() => change_image(index,item)}  key={index} src={item} /><p>Page {index+1}</p><span onClick={() => currentPageDelete(index)} ><i className="fa fa-trash-alt"></i> </span></div>)
							})
						:
							<div>{list}</div>
						*/}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(
                                                snapshot.isDraggingOver
                                            )}
                                        >
                                            {imgUrl.length > 0
                                                ? imgUrl.map((item, index) => {
                                                      let imageActive =
                                                          parseInt(
                                                              imageIndex
                                                          ) === parseInt(index)
                                                              ? 'active'
                                                              : '';
                                                      return (
                                                          <Draggable
                                                              key={index}
                                                              draggableId={`0${index}`}
                                                              index={index}
                                                          >
                                                              {(
                                                                  dragProvided,
                                                                  snapshot
                                                              ) => (
                                                                  <div
                                                                      // className='image-outer'
                                                                      id={
                                                                          'preview' +
                                                                          index
                                                                      }
                                                                      className={getItemClass(
                                                                          snapshot.isDragging,
                                                                          imageActive
                                                                      )}
                                                                      {...dragProvided.dragHandleProps}
                                                                      {...dragProvided.draggableProps}
                                                                      ref={
                                                                          dragProvided.innerRef
                                                                      }
                                                                      style={getItemStyle(
                                                                          snapshot.isDragging,
                                                                          dragProvided
                                                                              .draggableProps
                                                                              .style
                                                                      )}
                                                                      onClick={(
                                                                          event
                                                                      ) => {
                                                                          toggleActive(
                                                                              event,
                                                                              'preview' +
                                                                                  index
                                                                          );
                                                                          saveEditImage(
                                                                              '',
                                                                              index
                                                                          );
                                                                      }}
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      <div className='single-edit-image-thumb'>
                                                                          <img
                                                                              id={
                                                                                  'preview_img' +
                                                                                  index
                                                                              }
                                                                              alt='text-edit'
                                                                              onClick={(
                                                                                  event
                                                                              ) =>
                                                                                  change_image(
                                                                                      index,
                                                                                      item,
                                                                                      0
                                                                                  )
                                                                              }
                                                                              style={getItemStyleInner(
                                                                                  snapshot.isDragging,
                                                                                  dragProvided
                                                                                      .draggableProps
                                                                                      .style
                                                                              )}
                                                                              data-index={
                                                                                  index
                                                                              }
                                                                              src={
                                                                                  item
                                                                              }
                                                                          />
                                                                      </div>
                                                                      <p>
                                                                          Page{' '}
                                                                          {index +
                                                                              1}
                                                                      </p>
                                                                      {imgUrl.length >
                                                                      1 ? (
                                                                          <span
                                                                              id='activeDelete'
                                                                              onClick={() => {
                                                                                  currentPageDelete(
                                                                                      index
                                                                                  );
                                                                              }}
                                                                          >
                                                                              <i className='fa fa-trash-alt'></i>{' '}
                                                                          </span>
                                                                      ) : null}
                                                                  </div>
                                                              )}
                                                          </Draggable>
                                                      );
                                                  })
                                                : null}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    ) : null}
                    {/* </div> ) :list} */}

                    {initialPreviewImages === true ? (
                        <div
                            id='scrollPosition'
                            className='left-content col-lg-1  pr-0'
                        >
                            {/* <Draggable onPosChange={getChangedPos}> */}
                            <div>{previewImages}</div>
                            <div>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        textAlign: 'center',
                                        marginTop: '20px',
                                    }}
                                >
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    ></span>
                                </div>
                                <p>Page {previewImages.length + 1}</p>
                            </div>
                            {/* </Draggable> */}
                        </div>
                    ) : initialPreviewImages === false ? (
                        <div
                            id='scrollPosition'
                            className='left-content col-lg-1  pr-0'
                        >
                            {list}
                        </div>
                    ) : null}

                    <div className='center-editor col-lg-11'>
                        {changeImage ? (
                            <div className='print-main'>
                                <div className='bot-sav text-right m-3'>
                                    {/*<button
                                        onClick={saveEditImage}
                                        className='btn-sav'
                                    >
                                        Save
								</button>
									<span title="save" className="save_icon" onClick={saveEditImage}><i className="fas fa-save"></i></span> */}
                                </div>
                                <ImageEditor
                                    ref={editorRef}
                                    includeUI={{
                                        //loadImage: {
                                        //path: url,
                                        //path: 'https://i.picsum.photos/id/756/200/300.jpg?hmac=kojqQY60yVD4KaSEFOEw62LRuwfiOR2f-6ZdnEgKhxM',
                                        //name: 'SampleImage',
                                        //},
                                        uiSize: {
                                            //width: '80%',
                                            //height: '80%',
                                        },
                                    }}
                                />
                            </div>
                        ) : null}
                        <div
                            className='imageNotFound'
                            id='imageNotFound'
                            style={{ color: 'red', display: 'none' }}
                        >
                            There is no image to edit.
                        </div>
                        {centerImgLoader ? (
                            <div className='print-main'>
                                <div className='spinner-loader'>
                                    <Loader
                                        type='Oval'
                                        color='#007dbc'
                                        height={40}
                                        width={40}
                                        // radius={10}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {blurSpinner ? <SpinnerBlur show={blurSpinner} /> : null}
        </>
    );
}
export default EditWorkPage;
