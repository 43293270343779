import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { inboxPage } from '../../pages/_redux/actions/inboxActions';
import { outboxPage } from '../../pages/_redux/actions/outboxActions';

function AllowMultipleEditModal(props) {
    //  ('/******AllowMultipleEditModal********/');
    //   ('/******props********/', props);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const redirectToMergeSplitPage = () => {
        // return <Redirect to='/target' />

        props.handleMultiplePage();
        props.handleCloseOperation();

        if (
            String(location.pathname).includes('inbox/detail') === true ||
            String(location.pathname).includes('outbox/detail') === true
        ) {
            dispatch(
                inboxPage({
                    multiple_edit_page: true,
                })
            );
            dispatch(
                outboxPage({
                    multiple_edit_page: true,
                })
            );
            if (
                String(location.pathname).includes('inbox/detail') === true ||
                String(location.pathname).includes('outbox/detail') === true
            ) {
                dispatch(
                    inboxPage({
                        related_doc_detail: false,
                    })
                );
                dispatch(
                    outboxPage({
                        related_doc_detail: false,
                    })
                );
            }
            history.push({
                pathname: props.editURL,
                state: {
                    selectedDocID:
                        props.selectedDocID !== undefined &&
                        props.selectedDocID !== null
                            ? props.selectedDocID
                            : undefined,
                    processId:
                        props.processId !== undefined &&
                        props.processId !== null
                            ? props.processId
                            : undefined,
                    statusId:
                        props.statusId !== undefined && props.statusId !== null
                            ? props.statusId
                            : undefined,
                },
            });
        } else {
            history.push(props.editURL);
        }

        // <Redirect to={props.editURL}/>
        /* props.history.push({
            pathname: props.editURL,

        }); */
    };
    const handleCloseOperation = () => {
        //   ('/*******handleCloseOperation child*********/');
        props.handleCloseOperation();
    };

    return (
        <>
            <Modal
                show={props.restrictAllowEdit}
                animation={false}
                onHide={handleCloseOperation}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div style={{ color: 'red' }}>
                            <i
                                style={{ color: 'red' }}
                                title='Edit'
                                className='fas fa-page-break mr-3'
                            />
                            Edit Documents Restriction
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleCloseOperation}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='deletes'>
                    <p>
                        Edit operation on selected documents will take more time
                        to process. Do you still want to continue?
                    </p>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                variant='secondary'
                                onClick={handleCloseOperation}
                                title='Cancel'
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                variant='success'
                                onClick={redirectToMergeSplitPage}
                                title='Yes'
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AllowMultipleEditModal;
