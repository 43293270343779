/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Redirect } from 'react-router-dom';
//import { useFormik } from "formik";
//import * as Yup from "yup";
//import { connect } from "react-redux";
//import { FormattedMessage, injectIntl } from "react-intl";
import { changePassword } from '../_redux/authCrud';
import { Toast } from 'react-bootstrap';
//import { SpinnerBlur } from './inbox/components/Spinner';
import { allStorage } from '../../../components/commonfunction';
import { toast } from 'react-toastify';
toast.configure();

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

export class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new_pwd1: '',
            new_pwd2: '',
            empty_pwd1: false,
            empty_pwd2: false,
            length_pwd1: false,
            length_pwd2: false,
            old_pwd: '',
            empty_old: false,
            length_old: false,
            pwd_mismatch: false,
            show_pwd1: false,
            show_pwd2: false,
            show_pwd3: false,
            toast_msg: '',
            toast_type: '',
            show_toast: false,
            spinner: false,
            redirectLogin: false,
        };
        this.handlePwd1 = this.handlePwd1.bind(this);
        this.handlePwd2 = this.handlePwd2.bind(this);
        this.handleOldPwd = this.handleOldPwd.bind(this);
    }

    change_pwd = (e) => {
        e.preventDefault();
        let error = 0;
        if (String(this.state.new_pwd1) === '') {
            error = 1;
            this.setState({ empty_pwd1: true, length_pwd1: false });
        } else if (this.state.new_pwd1.length < 8) {
            error = 1;
            this.setState({ length_pwd1: true, empty_pwd1: false });
        } else {
            this.setState({ empty_pwd1: false, length_pwd1: false });
        }

        if (String(this.state.new_pwd2) === '') {
            error = 1;
            this.setState({ empty_pwd2: true, length_pwd2: false });
        } else if (this.state.new_pwd2.length < 8) {
            error = 1;
            this.setState({ length_pwd2: true, empty_pwd2: false });
        } else {
            this.setState({ empty_pwd2: false, length_pwd2: false });
        }

        if (
            String(this.state.new_pwd1) !== '' &&
            String(this.state.new_pwd2) !== '' &&
            String(this.state.new_pwd1) !== String(this.state.new_pwd2)
        ) {
            error = 1;
            this.setState({ pwd_mismatch: true });
        } else {
            this.setState({ pwd_mismatch: false });
        }

        if (String(this.state.old_pwd) === '') {
            error = 1;
            this.setState({ empty_old: true, length_old: false });
        } else if (this.state.old_pwd.length < 8) {
            error = 1;
            this.setState({ length_old: true, empty_old: false });
        } else {
            this.setState({ empty_old: false, length_old: false });
        }

        //  if (error === 0 && localStorage.getItem('WV-Token')) {
        if (error === 0) {
            this.setState({ spinner: true });
            changePassword(this.state.old_pwd, this.state.new_pwd2)
                //  changeExpiredPassword(this.state.new_pwd2)
                .then(async (response) => {
                    this.setState({ spinner: false });
                    if (parseInt(response.status) === 204) {
                        await toast.success('Password changed successfully', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        await this.setState({
                            redirectLogin: true,
                        });
                        await this.setState(
                            {
                                old_pwd: '',
                                new_pwd2: '',
                                new_pwd1: '',
                            },
                            () => {
                                //localStorage.clear();
                                const headerStorage = {};
                                const localStorageData = allStorage();
                                let remember_me =
                                    localStorage.getItem('remember_me');
                                let username = localStorage.getItem('username');
                                let password = localStorage.getItem('password');
                                for (const name in localStorageData) {
                                    if (
                                        String(name).includes(
                                            'header-reorder'
                                        ) ||
                                        String(name).includes('header-width') ||
                                        String(name).includes(
                                            'filter-options'
                                        ) ||
                                        String(name).includes('gmail_') ||
                                        String(name).includes(
                                            'sub_domain_name'
                                        ) ||
                                        String(name).includes(
                                            'tagsCurrentWidth'
                                        )
                                    ) {
                                        headerStorage[name] =
                                            localStorageData[name];
                                    }
                                }

                                localStorage.clear();
                                localStorage.setItem(
                                    'remember_me',
                                    remember_me
                                );
                                localStorage.setItem('username', username);
                                localStorage.setItem('password', password);

                                for (const name in headerStorage) {
                                    localStorage.setItem(
                                        name,
                                        headerStorage[name]
                                    );
                                }

                                localStorage.setItem('forcelogout', 1);
                                window.location.reload();
                            }
                        );
                    } else {
                        toast.error(
                            'There is an error in changing your password, Please try again later',
                            {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                    }
                    //this.setState({toast_msg : 'There is an error in changing your password, Please try again later', toast_type: 'error', show_toast : true})
                })
                .catch((error) => {
                    toast.error(
                        'There is an error in changing your password, Please try again later',
                        {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    //this.setState({toast_msg : 'There is an error in changing your password, Please try again later', toast_type: 'error', show_toast : true})
                });
        }
    };

    handlePwd1(event) {
        this.setState({ new_pwd1: event.target.value });
    }

    handlePwd2(event) {
        this.setState({ new_pwd2: event.target.value });
    }

    handleOldPwd(event) {
        this.setState({ old_pwd: event.target.value });
    }

    show_pwd1 = () => {
        this.setState({ show_pwd1: !this.state.show_pwd1 });
    };

    show_pwd2 = () => {
        this.setState({ show_pwd2: !this.state.show_pwd2 });
    };

    show_pwd3 = () => {
        this.setState({ show_pwd3: !this.state.show_pwd3 });
    };

    close_message = () => {
        this.setState({ show_toast: false });
    };

    nospaces = (event) => {
        if (event.target.value.match(/\s/g)) {
            event.target.value = event.target.value.replace(/\s/g, '');
        }
    };
    componentDidMount() {}
    render() {
        return (
            <>
                {this.state.redirectLogin ? (
                    <Redirect to='/auth/login' />
                ) : null}
                {/* <SpinnerBlur show={this.state.spinner} /> */}
                <div
                    className={this.state.toast_type}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '50%',
                    }}
                >
                    <Toast
                        show={this.state.show_toast}
                        onClose={this.close_message}
                        delay={5000}
                    >
                        <Toast.Header>Reset Password</Toast.Header>
                        <Toast.Body>{this.state.toast_msg}</Toast.Body>
                    </Toast>
                </div>

                <div className='whole-form'>
                    <div className='Logo text-center  mb-5 mb-lg-15'>
                        <a href='#'>
                            {/*<img src={window.location.origin+ '/images/WV-Logo.png'} />*/}
                        </a>
                    </div>
                    {/* <div className='Logo change_pass text-center  mb-5 mb-lg-12'>
                        <a href='#' alt='Logo'></a>
                    </div> */}
                    <div className='login-form login-signin' id=''>
                        <div className='text-center mb-5 mb-lg-12'>
                            <h3 className='font-size-h1'>
                                Reset your Password
                            </h3>
                            <p className='mt-4'>
                                Enter your details to reset your password
                            </p>
                        </div>

                        <div
                            // onSubmit={submit_login}
                            className='form fv-plugins-bootstrap fv-plugins-framework'
                        >
                            <div className='form-group fv-plugins-icon-container input-icon input-icon-right'>
                                <input
                                    placeholder='Your old password'
                                    type={
                                        this.state.show_pwd1
                                            ? 'text'
                                            : 'password'
                                    }
                                    className={
                                        'form-control form-control-solid h-auto py-4 px-6 pr-15'
                                    }
                                    name='password'
                                    maxLength='20'
                                    value={this.state.old_pwd}
                                    onChange={this.handleOldPwd}
                                    onKeyUp={(event) => {
                                        this.nospaces(event);
                                    }}
                                />
                                <span className='pr-5' onClick={this.show_pwd1}>
                                    {this.state.show_pwd1 ? (
                                        <i className='icon-md fas fa-eye-slash'></i>
                                    ) : (
                                        <i className='icon-md fas fa-eye'></i>
                                    )}
                                </span>
                                {this.state.empty_old ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Old Password is required
                                    </p>
                                ) : null}
                                {this.state.length_old ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Password contains atleast minimum 8
                                        characters.
                                    </p>
                                ) : null}
                            </div>

                            <div className='form-group fv-plugins-icon-container input-icon input-icon-right'>
                                <input
                                    placeholder='Your new password'
                                    type={
                                        this.state.show_pwd2
                                            ? 'text'
                                            : 'password'
                                    }
                                    className={
                                        'form-control form-control-solid h-auto py-4 px-6 pr-15'
                                    }
                                    name='password'
                                    maxLength='20'
                                    value={this.state.new_pwd1}
                                    onChange={this.handlePwd1}
                                    onKeyUp={(event) => {
                                        this.nospaces(event);
                                    }}
                                />
                                <span className='pr-5' onClick={this.show_pwd2}>
                                    {this.state.show_pwd2 ? (
                                        <i className='icon-md fas fa-eye-slash'></i>
                                    ) : (
                                        <i className='icon-md fas fa-eye'></i>
                                    )}
                                </span>
                                {this.state.empty_pwd1 ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        New Password is required
                                    </p>
                                ) : null}
                                {this.state.length_pwd1 ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Password contains atleast minimum 8
                                        characters.
                                    </p>
                                ) : null}
                            </div>
                            <div className='form-group fv-plugins-icon-container input-icon input-icon-right'>
                                <input
                                    placeholder='Confirm new password'
                                    type={
                                        this.state.show_pwd3
                                            ? 'text'
                                            : 'password'
                                    }
                                    className={
                                        'form-control form-control-solid h-auto py-4 px-6 pr-15'
                                    }
                                    name='password'
                                    maxLength='20'
                                    value={this.state.new_pwd2}
                                    onChange={this.handlePwd2}
                                    onKeyUp={(event) => {
                                        this.nospaces(event);
                                    }}
                                />
                                <span className='pr-5' onClick={this.show_pwd3}>
                                    {this.state.show_pwd3 ? (
                                        <i className='icon-md fas fa-eye-slash'></i>
                                    ) : (
                                        <i className='icon-md fas fa-eye'></i>
                                    )}
                                </span>
                                {this.state.empty_pwd2 ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Confirm Password is required
                                    </p>
                                ) : null}
                                {this.state.length_pwd2 ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Password contains atleast minimum 8
                                        characters.
                                    </p>
                                ) : null}
                                {this.state.pwd_mismatch ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Mismatch in password and confirm
                                        password
                                    </p>
                                ) : null}
                            </div>
                            <div className=''>
                                <button
                                    onClick={this.change_pwd}
                                    className={`btn btn-primary  font-weight-bold px-9 py-4 my-3 login-btn btn-block`}
                                >
                                    <span>Reset Password</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ChangePassword;
