const generateRows = <T extends { id?: any }>(data: T[], columnsArray: { key?: keyof T }[]): T[] => {
    const rows = data.map((item, index) => {
        const row: Partial<T> = {};
        columnsArray.forEach(column => {
            if (column.key !== undefined && column.key in item) {
                row[column.key] = item[column.key];
            }
        });

        return { ...row, id: item?.id } as T;
    });

    return rows;
};

export default generateRows;