import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    deleteDocument,
    getConfigurationData,
    GetDropdownData,
} from '../../api/api';
import {
    showSuccessToast,
    showErrorToast,
    removeCheckboxClass,
} from '../../../components/commonfunction';
import { getProcessCount } from '../../_redux/authCrud';
import { getCountData } from '../../_redux/actions/inboxActions';

export class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: {
                errorDeleteSelect: '',
                errorDeleteComment: '',
            },
            commentsData: {},
            branchData: {},
            openDelete:
                this.props.openDelete !== undefined
                    ? this.props.openDelete
                    : false,
            deleteComments: [],
            deleteBranch: [],
            rowsToDelete: [],
            deleteSelectVal: localStorage.getItem('selectedBranch') || '',
        };
    }

    componentDidMount() {
        const configData = getConfigurationData();
        if (configData.Branch !== undefined) {
            const branch = GetDropdownData(configData.Branch.fieldId);
            branch
                .then((response) => response.json())
                .then((data) => {
                    this.setState({
                        branchData: data,
                    });
                });
        }
        if (configData.Comments !== undefined) {
            this.setState({
                commentsData: configData.Comments,
            });
        }
    }

    closeDeletePopUp(forceClose = false) {
        this.setState({
            openDelete: false,
            deleteSelectVal: '',
            errorMsg: Object.assign(this.state.errorMsg, {
                errorDeleteSelect: '',
                errorDeleteComment: '',
            }),
        });
        const params = {
            rowsToDelete: [],
            removeRow: false,
        };
        if (forceClose === true) {
            params.rowsToDelete = this.props.rowsToDelete;
            params.removeRow = true;
        }
        this.props.closeDeletePopUp(params);
    }

    render() {
        const deleteSelectChange = (event) => {
            const selectedBranch = event.target.value;
            this.setState({ deleteSelectVal: selectedBranch });
            localStorage.setItem('selectedBranch', selectedBranch);
        };
        const deleteOptionTag = (data) => {
            const optionTag = [];
            optionTag.push(
                <option value='' key={Math.random()}>
                    Select Branch
                </option>
            );
            if (data.values !== undefined) {
                data.values.forEach((val, index) => {
                    optionTag.push(
                        <option
                            value={val}
                            key={index}
                            style={{ display: val !== '' ? 'block' : 'none' }}
                        >
                            {val}
                        </option>
                    );
                });
            }
            return optionTag;
        };
        const handleDeleteText = () => {
            if (
                document.getElementById('delete_selectbrand').value.trim() !==
                    '' &&
                document.getElementById('delete_comments').value.trim() !== ''
            ) {
                const comment = [];
                const selectData = [];
                comment.push(document.getElementById('delete_comments').value);
                selectData.push(
                    document.getElementById('delete_selectbrand').value.trim()
                );
                this.setState({
                    deleteComments: comment,
                    deleteBranch: selectData,
                    errorMsg: Object.assign(this.state.errorMsg, {
                        errorDeleteSelect: '',
                        errorDeleteComment: '',
                    }),
                });
            } else {
                const obj = {};
                if (
                    document.getElementById('delete_comments').value.trim() ===
                    ''
                ) {
                    obj.errorDeleteComment = 'Comment Is Mandatory';
                }
                if (
                    document
                        .getElementById('delete_selectbrand')
                        .value.trim() === ''
                ) {
                    obj.errorDeleteSelect = 'Branch Is Mandatory';
                }
                this.setState({
                    errorMsg: Object.assign(this.state.errorMsg, obj),
                    deleteSelectVal: '',
                });
            }
        };
        const handleDeleteOperation = async () => {
            if (
                document.getElementById('delete_comments').value.trim() === ''
            ) {
                this.setState({
                    errorMsg: Object.assign(this.state.errorMsg, {
                        errorDeleteComment: 'Comment Is Mandatory',
                    }),
                });
                return;
            }

            if (this.props.rowsToDelete.length > 0) {
                const docIds = [];
                const fields = [];
                const { processId } = this.props;
                const { statusId } = this.props;
                const actionId = this.props.deleteActionId;
                const { fieldId } = this.state.branchData;
                const newStatus = {
                    processId,
                    statusId,
                    actionId,
                };
                this.props.rowsToDelete.forEach((data) => {
                    docIds.push(data.doc_id);
                });
                this.state.deleteBranch.forEach((data, i) => {
                    fields.push({
                        fieldId,
                        value: data,
                    });
                });
                this.state.deleteComments.forEach((data, i) => {
                    fields.push({
                        fieldId: this.state.commentsData.fieldId,
                        value: data,
                    });
                });
                this.setState({ main_spinner: true });
                window.scroll({ top: 0, behavior: 'smooth' });

                const deleteData = deleteDocument({
                    processId,
                    statusId,
                    actionId,
                    docIds,
                    fields,
                    newStatus,
                });
                deleteData
                    .then((response) => {
                        if (
                            response.status === 204 ||
                            response.status === 200
                        ) {
                            const count = [];
                            const oldCount = this.props.count;
                            getProcessCount(this.props.processId)
                                .then((response) => {
                                    // status = response.status;
                                    return response.json();
                                })
                                .then((data) => {
                                    count.push(data);
                                    oldCount.forEach((old_count) => {
                                        if (
                                            old_count.process.processId !==
                                            data.process.processId
                                        ) {
                                            count.push(old_count);
                                        }
                                    });
                                    this.props.getCountData(count);
                                });
                            showSuccessToast('Records Deleted Successfully');
                            this.setState(
                                () => {
                                    return {
                                        rowsToDelete: [],
                                        deleteComments: [],
                                        deleteBranch: [],
                                        errorMsg: Object.assign(
                                            this.state.errorMsg,
                                            {
                                                errorDeleteSelect: '',
                                                errorDeleteComment: '',
                                            }
                                        ),
                                    };
                                },
                                () => {
                                    this.closeDeletePopUp(true);
                                }
                            );
                            removeCheckboxClass();
                        }
                        return response.json();
                    })
                    .then((response) => {
                        if (response.message !== undefined) {
                            showErrorToast(response.message);
                            this.setState({
                                openDelete: false,
                                rowsToDelete: [],
                                deleteComments: [],
                                deleteBranch: [],
                            });
                        }
                    })
                    .catch(() => {
                        this.setState({
                            rowsToDelete: [],
                            deleteComments: [],
                            deleteBranch: [],
                        });
                    });
            }
        };

        return (
            <>
                <Modal
                    className='delete-popup'
                    show={this.props.openDelete}
                    onHide={() => {
                        this.closeDeletePopUp.bind(this, false);
                    }}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i
                                    title='Delete'
                                    className='fas fa-trash-alt mr-3'
                                />
                                Delete File
                            </div>
                            <i
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.closeDeletePopUp.bind(
                                    this,
                                    false
                                )}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes'>
                        <h6>
                            You are about to delete{' '}
                            {this.props.rowsToDelete.length === 1
                                ? 'this file.'
                                : `${this.props.rowsToDelete.length} files.`}{' '}
                            Are you sure you want to do this?
                        </h6>
                        <span>
                            Deleted files can be found in the Deleted section
                        </span>
                        <div className='form-group row '>
                            <div className='col-lg-4 mt-5 transition-select'>
                                <label className='pl-5'>BRANCH</label>
                                <select
                                    id='delete_selectbrand'
                                    className='form-control'
                                    ref={this.deleteSelectTagRef}
                                    onChange={deleteSelectChange}
                                    name='billing_card_exp_month'
                                    style={
                                        this.state.errorMsg.errorDeleteSelect.trim() !==
                                        ''
                                            ? { borderColor: 'red' }
                                            : {}
                                    }
                                    value={this.state.deleteSelectVal}
                                >
                                    {deleteOptionTag(this.state.branchData)}
                                </select>
                                <i
                                    className='fa-light fa-chevron-down'
                                    style={{ pointerEvents: 'none' }}
                                />
                            </div>
                        </div>

                        <div className='form-group row mb-0'>
                            <div className='col-lg-12 mt-3 transition-select'>
                                <label className='pl-5'>COMMENTS</label>
                                <textarea
                                    id='delete_comments'
                                    style={
                                        this.state.errorMsg.errorDeleteComment.trim() !==
                                        ''
                                            ? { borderColor: 'red' }
                                            : {}
                                    }
                                    className='form-control'
                                    ref={this.deleteCommentTagRef}
                                    onChange={(event) =>
                                        handleDeleteText(event)
                                    }
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    title='Cancel'
                                    variant='secondary'
                                    onClick={this.closeDeletePopUp.bind(
                                        this,
                                        false
                                    )}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                <Button
                                    title='Yes, Delete'
                                    disabled={
                                        this.state.deleteComments.length ===
                                            0 ||
                                        this.state.deleteBranch.length === 0
                                    }
                                    className='danger'
                                    onClick={() => handleDeleteOperation()}
                                >
                                    Yes, Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

// export default DeleteModal;

const mapDispatchToProps = {
    getCountData,
};
const mapStateToProps = (state) => {
    return {
        rowData: state.inbox.rowData,
        count: state.persist.count,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
