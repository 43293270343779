import React from 'react';
import Loader from 'react-loader-spinner';

export const Spinner = (props) => {
    return props.show ? (
        <div className='overlay-loader'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const Spinner_2 = (props) => {
    return props.show ? (
        <div className='spinner-loader'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const SpinnerBlur = (props) => {
    return props.show ? (
        <div className='spinner-blur'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const Spinner_3 = (props) => {
    return props.show ? (
        <div style={{ textAlign: 'center' }} className='spinner_3'>
            <Loader
                type='Oval'
                color='#007dbc'
                height={40}
                width={40}
                // radius={10}
            />
        </div>
    ) : null;
};

export const ProgressLoader = (props) => {
    return props.show ? (
        <div className='spinner-blur'>
            <svg version='1.0' width='256px' height='32px' viewBox='0 0 512 64'>
                <path
                    fill='#000'
                    fill-rule='evenodd'
                    d='M486.4 19.2A12.8 12.8 0 1 1 473.6 32a12.8 12.8 0 0 1 12.8-12.8zm-51.2 0A12.8 12.8 0 1 1 422.4 32a12.8 12.8 0 0 1 12.8-12.8zm-51.2 0A12.8 12.8 0 1 1 371.2 32 12.8 12.8 0 0 1 384 19.2zm-51.2 0A12.8 12.8 0 1 1 320 32a12.8 12.8 0 0 1 12.8-12.8zm-51.2 0A12.8 12.8 0 1 1 268.8 32a12.8 12.8 0 0 1 12.8-12.8zm-51.2 0A12.8 12.8 0 1 1 217.6 32a12.8 12.8 0 0 1 12.8-12.8zm-51.2 0A12.8 12.8 0 1 1 166.4 32a12.8 12.8 0 0 1 12.8-12.8zm-51.2 0A12.8 12.8 0 1 1 115.2 32 12.8 12.8 0 0 1 128 19.2zm-51.2 0A12.8 12.8 0 1 1 64 32a12.8 12.8 0 0 1 12.8-12.8zm-51.2 0A12.8 12.8 0 1 1 12.8 32a12.8 12.8 0 0 1 12.8-12.8z'
                />
                <g>
                    <path
                        className='vertical-loader-fill'
                        fill-rule='evenodd'
                        d='M-119.6,10.24A21.76,21.76,0,0,1-97.84,32,21.76,21.76,0,0,1-119.6,53.76,21.76,21.76,0,0,1-141.36,32,21.76,21.76,0,0,1-119.6,10.24Zm-51.2,0A21.76,21.76,0,0,1-149.04,32,21.76,21.76,0,0,1-170.8,53.76,21.76,21.76,0,0,1-192.56,32,21.76,21.76,0,0,1-170.8,10.24Zm-51.2,0A21.76,21.76,0,0,1-200.24,32,21.76,21.76,0,0,1-222,53.76,21.76,21.76,0,0,1-243.76,32,21.76,21.76,0,0,1-222,10.24Zm-51.2,0A21.76,21.76,0,0,1-251.44,32,21.76,21.76,0,0,1-273.2,53.76,21.76,21.76,0,0,1-294.96,32,21.76,21.76,0,0,1-273.2,10.24Zm-51.2,0A21.76,21.76,0,0,1-302.64,32,21.76,21.76,0,0,1-324.4,53.76,21.76,21.76,0,0,1-346.16,32,21.76,21.76,0,0,1-324.4,10.24Zm-51.2,0A21.76,21.76,0,0,1-353.84,32,21.76,21.76,0,0,1-375.6,53.76,21.76,21.76,0,0,1-397.36,32,21.76,21.76,0,0,1-375.6,10.24Zm-51.2,0A21.76,21.76,0,0,1-405.04,32,21.76,21.76,0,0,1-426.8,53.76,21.76,21.76,0,0,1-448.56,32,21.76,21.76,0,0,1-426.8,10.24Zm-51.2,0A21.76,21.76,0,0,1-456.24,32,21.76,21.76,0,0,1-478,53.76,21.76,21.76,0,0,1-499.76,32,21.76,21.76,0,0,1-478,10.24Zm-51.2,0A21.76,21.76,0,0,1-507.44,32,21.76,21.76,0,0,1-529.2,53.76,21.76,21.76,0,0,1-550.96,32,21.76,21.76,0,0,1-529.2,10.24Zm-51.2,0A21.76,21.76,0,0,1-558.64,32,21.76,21.76,0,0,1-580.4,53.76,21.76,21.76,0,0,1-602.16,32,21.76,21.76,0,0,1-580.4,10.24Zm-51,2.56A19.2,19.2,0,0,1-612.2,32a19.2,19.2,0,0,1-19.2,19.2A19.2,19.2,0,0,1-650.6,32,19.2,19.2,0,0,1-631.4,12.8Zm-51,2.56A16.64,16.64,0,0,1-665.76,32,16.64,16.64,0,0,1-682.4,48.64,16.64,16.64,0,0,1-699.04,32,16.64,16.64,0,0,1-682.4,15.36ZM-68.8,12.8A19.2,19.2,0,0,1-49.6,32,19.2,19.2,0,0,1-68.8,51.2,19.2,19.2,0,0,1-88,32,19.2,19.2,0,0,1-68.8,12.8Zm51.2,2.56A16.64,16.64,0,0,1-.96,32,16.64,16.64,0,0,1-17.6,48.64,16.64,16.64,0,0,1-34.24,32,16.64,16.64,0,0,1-17.6,15.36Z'
                    />
                    <animateTransform
                        attributeName='transform'
                        type='translate'
                        values='44 0;95.2 0;146.4 0;197.6 0;248.8 0;300 0;351.2 0;402.4 0;453.6 0;504.8 0;556 0;607.2 0;658.4 0;709.6 0;760.8 0;812 0;863.2 0;914.4 0;965.6 0;1016.8 0;1068 0;1119.2 0;1170.4 0;1221.6 0;1272.8 0;1324 0'
                        calcMode='discrete'
                        dur='2160ms'
                        repeatCount='indefinite'
                    />
                </g>
            </svg>
        </div>
    ) : null;
};

export default Spinner;
