import { Popover, Typography } from '@material-ui/core';
import { useState, useRef } from 'react';

interface ThreeDotsMenuProps {
    id: string | number;
    onEdit: (id: string | number) => void;
    onDelete: (id: string | number) => void;
}

export const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = ({
    id,
    onEdit,
    onDelete,
}) => {
    const [openPopover, setOpenPopover] = useState(false);
    const actionButtonRef = useRef<HTMLSpanElement>(null);

    const togglePopover = () => {
        setOpenPopover(!openPopover);
    };

    const popoverHandleClose = () => {
        setOpenPopover(false);
    };

    const calculatePosition = () => {
        if (actionButtonRef.current) {
            const buttonPosition =
                actionButtonRef.current.getBoundingClientRect();

            return {
                top: buttonPosition.bottom,
                left: buttonPosition.left - 50,
            };
        }
        return undefined;
    };

    const popoverPosition = calculatePosition();

    return (
        <>
            <div className='d-flex related_detail_icon align-items-center rel_action_menu'>
                <span
                    ref={actionButtonRef}
                    onClick={togglePopover}
                    className={`settingsmenu${id}`}
                    title='Settings'
                >
                    <i className='fa fa-ellipsis-h' aria-hidden='true' />
                </span>
            </div>

            {popoverPosition && (
                <Popover
                    open={openPopover}
                    anchorReference='anchorPosition'
                    anchorPosition={popoverPosition}
                    onClose={popoverHandleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: { border: '1px solid #000', width: '150px' },
                    }}
                >
                    <Typography>
                        <div className='rel-dropdown-menu' style={{ padding: "0px" }}>
                            <div
                                title='Edit Entity'
                                onClick={() => {
                                    onEdit(id);
                                    popoverHandleClose();
                                }}
                                className='rel-dropdown-item action-item'
                            >
                                <i className='fas fa-user-edit' />
                                <span>Edit Entity</span>
                            </div>
                            <div
                                title='Delete'
                                onClick={() => {
                                    onDelete(id);
                                    popoverHandleClose();
                                }}
                                className='rel-dropdown-item action-item'
                            >
                                <i className='fas fa-trash-can' />
                                Delete
                            </div>
                        </div>
                    </Typography>
                </Popover>
            )}
        </>
    );
};
