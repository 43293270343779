import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import { listWatcher, defaultInbox } from '../app/pages/_redux/inboxRedux';
import { checkAuth } from '../app/pages/_redux/checkAuth';
import {
    inboxReducer,
    pReducer,
} from '../app/pages/_redux/reducers/inboxReducer.js';
import { searchReducer } from '../app/pages/_redux/reducers/searchReducer';
import { checkAuthReducer } from '../app/pages/_redux/reducers/chkauthReducer';
import { outboxReducer } from '../app/pages/_redux/reducers/outboxReducer.js';
import {
    configReducer,
    persistDDL,
} from '../app/pages/_redux/reducers/configReducer.js';

const rootReducer = combineReducers({
    auth: auth.reducer,
    inbox: inboxReducer,
    outbox: outboxReducer,
    persist: pReducer,
    configReducerPersist: configReducer,
    persistDDL: persistDDL,
    checkAuth: checkAuthReducer,
    search: searchReducer,
});

export const appReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
        return rootReducer(undefined, action);
    }

    return rootReducer(state, action);
};

function* helloSaga() {}

export function* rootSaga() {
    yield all([
        listWatcher(),
        helloSaga(),
        auth.saga(),
        defaultInbox(),
        checkAuth(),
    ]);
}
