const Office365AuthenticationPage = (props) => {
    let userDetials = localStorage.getItem('user_details');
    userDetials = JSON.parse(userDetials);
    localStorage.setItem('gmail_sendDraftID', props.selectedDocID);

    const redirectUri =
        process.env.REACT_APP_ENV === 'dev'
            ? 'http://localhost:8081/mailSuccess'
            : props.configData.emailIntegration.serverURL; //window.origin + '/mailSuccess'; //
    const office365AccessTokenURL =
        process.env.REACT_APP_ENV === 'dev'
            ? `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${props.configData?.emailIntegration?.clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&prompt=login&code_challenge=_r67lcj4MoDNBAkhxS7ke_YKhKCBAiM0SgzNCagbCxo&code_challenge_method=S256&state=12345&login_hint=${userDetials.userEmail}`
            : `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${props.configData?.emailIntegration?.clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&prompt=login&code_challenge=_r67lcj4MoDNBAkhxS7ke_YKhKCBAiM0SgzNCagbCxo&code_challenge_method=S256&state=12345&login_hint=${userDetials.userEmail}`;

    const token = localStorage.getItem('gmail_office365_access_token');
    const pageTitle = process.env.REACT_APP_TITLE;

    const windowURL = (link, pageSetUp) => {
        const winData = window.open(
            link,
            pageSetUp,
            'height=600,width=500,scrollbars=yes,resizable=no,fullscreen=no'
        );
        winData.document.title = pageTitle;
    };

    const redirectToAuthOffice365 = () => {
        windowURL(office365AccessTokenURL, '_blank');
    };

    const refreshTokenOffice365 = (email) => {
        const clientId = props.configData?.emailIntegration?.clientId; // localStorage.getItem('gmail_office365_client_id');
        // const clientSecret = localStorage.getItem(
        //     'gmail_office365_client_secret'
        // );
        const refreshToken = localStorage.getItem(
            'gmail_office365_refresh_token'
        );
        const sendDraftID = localStorage.getItem('gmail_sendDraftID');

        const params = {
            client_id: clientId,
            // scope: 'https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/Mail.Send',
            redirect_uri: redirectUri,
            grant_type: 'refresh_token',
            // client_secret: clientSecret,
            refresh_token: refreshToken,
            code_verifier: '1qaz2wsx3edc4rfv5tgb6yhn1234567890qwertyuiop',
        };

        const formBody = Object.keys(params)
            .map(
                (key) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        params[key]
                    )}`
            )
            .join('&');

        try {
            fetch(
                'https://login.microsoftonline.com/organizations/oauth2/v2.0/token',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded;charset=UTF-8',
                    },
                    body: formBody,
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    if (json?.access_token) {
                        localStorage.setItem(
                            'gmail_office365_access_token',
                            json.access_token
                        );
                        windowURL(
                            `${redirectUri}?code=access&docId=${sendDraftID}&token=${json.access_token}&mailId=${email}`,
                            '_blank'
                        );
                    } else {
                        redirectToAuthOffice365();
                    }
                })
                .catch((error) => {});
        } catch (err) {}
    };

    try {
        fetch('https://graph.microsoft.com/oidc/userinfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                if (json?.email) {
                    localStorage.setItem('gmail_email', json.email);
                    windowURL(
                        `${redirectUri}?code=access&docId=${props.selectedDocID}&token=${token}&mailId=${json?.email}`,
                        '_blank'
                    );
                } else {
                    const userEmail = localStorage.getItem('gmail_email');
                    refreshTokenOffice365(userEmail);
                }
            })
            .catch((error) => {});
    } catch (err) {}
};

export default Office365AuthenticationPage;
