import {
    GET_OWNERS_AUTH,
    // GET_OWNERS_SUCCESS_AUTH,
    // GET_OWNERS_FAILURE_AUTH,
    // DEFAULT_AUTH_STATE,
    LOGOUT,
} from '../actionTypes/checkAuthTypes';

export const getOwnerForAuth = (processId, statusId) => ({
    type: GET_OWNERS_AUTH,
    processId,
    statusId,
});

export const logOut = () => ({
    type: LOGOUT,
});
