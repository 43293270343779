import React from 'react';
//import { useFormik } from "formik";
//import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
//import * as Yup from "yup";
//import { injectIntl } from "react-intl";
//import * as auth from "../_redux/authRedux";
import { requestPassword } from '../_redux/authCrud';
//import { isThisISOWeek } from "date-fns";
import { Toast } from 'react-bootstrap';
import Spinner from './Spinner';
//import IdleTimer from 'react-idle-timer'
import { toast } from 'react-toastify';
toast.configure();
//const initialValues = { email: ""};

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            empty_username: false,
            show_success: false,
            success_msg: '',
            success_show: false,
            toast_type: '',
            spinner: false,
        };
        this.handleUsername = this.handleUsername.bind(this);
    }
    componentDidMount() {}

    forgot_pwd = () => {
        if (this.state.username === '') {
            this.setState({ empty_username: true });
        } else {
            this.setState({ empty_username: false, spinner: true });
            requestPassword(
                this.state.username,
                localStorage.getItem('sub_domain_name')
            )
                .then((response) => {
                    this.setState({ spinner: false });
                    response.status === 204
                        ? this.setState({ show_success: true })
                        : toast.error('Invalid username', {
                              position: 'top-right',
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                          });
                    // this.setState({toast_type : 'error',success_show : true,success_msg : 'Invalid username'})
                })
                .catch((error) => {
                    toast.error('Invalid username', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    //  this.setState({
                    // 			toast_type : 'error',
                    // 			success_show : true,
                    // 			success_msg : 'Invalid username',
                    //       spinner:false
                    // 		})
                });
            // toastr.success("<b>EMAIL SENT</b><p>Sent an email with a link.<br/>The click on that link and sends them another email.<br/>That second email contains a temproary password.</p>");
        }
    };

    handleUsername(event) {
        this.setState({ username: event.target.value });
    }

    handleOnAction(event) {}

    handleOnActive(event) {}

    handleOnIdle(event) {}

    render() {
        const show_success = this.state.show_success;
        const success_show = this.state.success_show;
        if (show_success) {
            return <Redirect to='/auth/confirmation' />;
        }
        const close_success = () => this.setState({ success_show: false });
        return (
            <>
                {/* <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 1}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        /> */}
                <Spinner show={this.state.spinner} />

                <div
                    className={this.state.toast_type}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '50%',
                    }}
                >
                    <Toast
                        show={success_show}
                        onClose={close_success}
                        delay={5000}
                        autohide
                    >
                        <Toast.Header>
                            <img
                                src='holder.js/20x20?text=%20'
                                className='rounded mr-2'
                                alt=''
                            />
                            <strong className='mr-auto'>Forgot Password</strong>
                        </Toast.Header>
                        <Toast.Body>{this.state.success_msg}</Toast.Body>
                    </Toast>
                </div>

                <div
                    className='login-form login-forgot m-auto'
                    style={{ display: 'block' }}
                >
                    <div className='Logo text-center  mb-5 mb-lg-15'>
                        <a href='#ngo'>
                            {/*<img src={window.location.origin+ '/images/WV-Logo.png'} />*/}
                        </a>
                    </div>
                    <div className='text-center mb-10 mb-lg-12'>
                        <h3 className='font-size-h1'>Forgotten Password?</h3>
                        <div className='mt-4 '>
                            Enter your username to reset your password
                        </div>
                    </div>
                    <div className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'>
                        <div className='form-group fv-plugins-icon-container'>
                            <input
                                placeholder='Username'
                                type='text'
                                className={`form-control  h-auto py-4 px-6 pr-15 `}
                                name='text'
                                maxLength='50'
                                value={this.state.username}
                                onChange={this.handleUsername}
                            />
                            {this.state.empty_username ? (
                                <p style={{ color: 'red' }}>
                                    Username is required
                                </p>
                            ) : null}
                        </div>
                        <div className='form-group d-flex flex-wrap flex-center frget_pass'>
                            <Link
                                to='/auth'
                                title='Cancel'
                                className='cancel-btn'
                            >
                                <button
                                    type='button'
                                    id='kt_login_forgot_cancel'
                                    className='btn btn-secondary px-9 py-4 my-3 mr-6 ml-0'
                                >
                                    Cancel
                                </button>
                            </Link>
                            <button
                                title='Request'
                                id='kt_login_forgot_submit'
                                onClick={this.forgot_pwd}
                                className='btn btn-primary font-weight-bold px-9 py-4 my-3 ml-6 mr-0 sm-btn'
                            >
                                Request
                            </button>
                        </div>
                    </div>
                </div>
                <div className='d-flex footer_text justify-content-center justify-content-lg-start justify-content-center align-items-end py-lg-0'>
                    <p className=''>
                        You’re signing in to {localStorage.getItem('SiteName')}{' '}
                        instance of WorldView
                    </p>
                </div>
            </>
        );
    }
}
