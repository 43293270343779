/* eslint-disable array-callback-return */
/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import axios from 'axios';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './app/App';
import './index.scss'; // Standard version
import './web.config'; // include web config
import * as _redux from './redux';
import store, { persistor } from './redux/store';

import 'socicon/css/socicon.css';
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';

import './_metronic/_assets/css/all.css';

import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import './_metronic/_assets/sass/default-style.scss';

// Datepicker
import 'react-datepicker/dist/react-datepicker.css';

import { GlobalStyles } from './themes/global';
import { MetronicI18nProvider } from './_metronic/i18n';
import {
    MetronicLayoutProvider,
    MetronicSplashScreenProvider,
    MetronicSubheaderProvider,
} from './_metronic/layout';

import { loadThemeJson } from './app/pages/api/api';

/*
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */ _redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);
// or const ReactDynamicImport = require('react-dynamic-import');

// Define dynamic import loader function

// const esign2 = React.lazy(() => import("./_metronic/_assets/sass/default-style.scss"));
//the below four line show compile error please check it(location)
const { host } = window.location;
let windowWidth = window.innerWidth;
window.onresize = function () {
    if (window.innerWidth != windowWidth) {
        windowWidth = window.innerWidth;
        //window.location.reload();
    }
};

// let protocol = window.location.protocol;
var subdomain = process.env.REACT_APP_API_SUB_DOMAIN;
if (
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
) {
    // console.log = function () {};
    // console.info =  function () {};
}
//the below four line show compile error please check it(host)
if (process.env.REACT_APP_ENV === 'dev') {
    const parts = host.split(':');
    if (parts.length >= 2) {
        subdomain = parts[1];
    }
    //subdomain = process.env.REACT_APP_API_SUB_DOMAIN;
    //subdomain = 'wvwebclientdev-staging';
    subdomain = 'wv-webclient-staging';
    //subdomain = 'medattach3stagingaxxess-web';
    //subdomain = 'hcdemo-webclient';
    //subdomain = 'personalhealthcs-web';
    //subdomain = 'hcdemo-webclientkalpana';
    //subdomain = 'adara-web';
    //subdomain = 'interimgreatlakes-web';
    //subdomain = 'whitelabel-staging';
    //subdomain = 'adara-configtesting';
} else {
    const parts = host.split('.');
    subdomain = parts[0];
}

if (subdomain === 'wv-webclient-staging')
    subdomain = process.env.REACT_APP_API_SUB_DOMAIN;

var domainName = subdomain;

localStorage.setItem('sub_domain_name', subdomain);
//localStorage.setItem('sub_domain_name', 'wvwebclientdev-staging');

var theme = '';

//subdomain = 'aaaaaaaaaaaaaa'; //kalpana testing

var themeData = localStorage.getItem('theme_details_' + domainName);
var loadInterval = '3000';

function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == '#') {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

//default theme color
var imagesArray = {
    bgimageURL: window.location.origin + '/default_bg.jpg',
    logoURL: window.location.origin + '/default-logo.png',
    smallLogoURL: window.location.origin + '/default-logo.png',
    primary: '#00233A',
    primary_hover: '#146893',
    primary_inverse: '#FFFFFF',
    primary_active: '#F0F7FB',
    primary_light: '#F0F7FB',
    icon_color: '#007dbc',
    tooltip_color: '#007DBC',
    check_color: '#007DBC',
    btn: '#007DBC',
    pdf_color: '#334F61',
    btn_light_primary: '#334F61',

    //kalpana_added
    primary_table_hover: '#007dbc',
};
var themeImages = [];

//themeData = '';
if (themeData !== '' && themeData !== null && themeData !== undefined) {
    theme = JSON.parse(themeData);
    loadInterval = '1000';
} else {
    var status;
    loadThemeJson(domainName)
        .then((response) => {
            status = response.status;
            return response.json();
        })
        .then((data) => {
            if (status === 400 || status === 404) {
                if (theme === '') {
                    themeImages.push(imagesArray);
                    theme = themeImages[0];
                    localStorage.setItem(
                        'theme_details_' + domainName,
                        JSON.stringify(theme)
                    );

                    /* Object.keys(defaultThemes).map((index) => {
                        return defaultThemes[index][subdomain];
                    });
                    // var theme = resultArray[0];
                    resultData = resultData.filter((v) => v !== undefined);
                    theme = resultData[0];
                    theme.bgimageURL =
                        window.location.origin + '/default_bg.jpg';
                    theme.logoURL =
                        window.location.origin + '/default-logo.png';
                    theme.smallLogoURL =
                        window.location.origin + '/default-logo.png';
                    //('------themetheme------',theme);
                    localStorage.setItem(
                        'theme_details_' + domainName,
                        JSON.stringify(theme)
                    );

                    //kalpana added - 16-12-2021
                    if (process.env.REACT_APP_ENV === 'dev') {
                        var themeData = Object.keys(defaultThemes).map(
                            function (k) {
                                var themeDomain;
                                if (defaultThemes[k][subdomain] !== undefined) {
                                    themeDomain = defaultThemes[k][subdomain];
                                } else {
                                    themeDomain = defaultThemes[k]['default'];
                                }

                                return themeDomain;
                            }
                        );
                        theme = themeData[0];
                        theme.bgimageURL =
                            window.location.origin + '/default_bg.jpg';
                        theme.logoURL =
                            window.location.origin + '/default-logo.png';
                        theme.smallLogoURL =
                            window.location.origin + '/default-logo.png';
                        localStorage.setItem(
                            'theme_details_' + domainName,
                            JSON.stringify(theme)
                        );
						
                    }*/
                }
            } else {
                if (
                    data.folders === undefined ||
                    data.folders === null ||
                    data.keyValuePairs === undefined ||
                    data.keyValuePairs === null
                ) {
                    //resultData = resultData.filter((v) => v !== undefined);
                    themeImages.push(imagesArray);
                    theme = themeImages[0];
                    //theme = resultData[0];
                } else {
                    var responseDataImages = data.folders;
                    var responseDataColors = data.keyValuePairs;

                    Object.entries(responseDataImages).map(([key, value]) => {
                        if (value.name === 'bgimage' && value.urls[0] !== '') {
                            imagesArray.bgimageURL = value.urls[0];
                        }
                        if (value.name === 'logo' && value.urls[0] !== '') {
                            imagesArray.logoURL = value.urls[0];
                        }
                        if (
                            value.name === 'smallLogo' &&
                            value.urls[0] !== ''
                        ) {
                            imagesArray.smallLogoURL = value.urls[0];
                        }
                    });

                    Object.entries(responseDataColors).map(([key, value]) => {
                        imagesArray[value.key] = value.value;
                        if (value.key === 'pdf_color') {
                            // Lighten
                            var NewColor = LightenDarkenColor(value.value, 100);
                            imagesArray.primary_table_hover = NewColor;
                        }

                        /*if (value.key === 'primary') {
                            imagesArray.primary = value.value;
                        }
                        if (value.key === 'primary_hover')
                            imagesArray.primary_hover = value.value;
                        if (value.key === 'primary_inverse')
                            imagesArray.primary_inverse = value.value;
                        if (value.key === 'primary_light')
                            imagesArray.primary_inverse = value.value;
                        if (value.key === 'primary_active')
                            imagesArray.primary_active = value.value;
                        if (value.key === 'icon_color')
                            imagesArray.icon_color = value.value;
                        if (value.key === 'tooltip_color')
                            imagesArray.tooltip_color = value.value;
                        if (value.key === 'check_color')
                            imagesArray.check_color = value.value;
                        if (value.key === 'btn_hover')
                            imagesArray.btn_hover = value.value;
                        if (value.key === 'pdf_color') {
                            imagesArray.pdf_color = value.value;
                            var hex = value.value;
                            var red = parseInt(hex[1] + hex[2], 16);
                            var green = parseInt(hex[3] + hex[4], 16);
                            var blue = parseInt(hex[5] + hex[6], 16);*/
                        /*imagesArray.primary_table_hover =
                                'rgb(' +
                                red +
                                ', ' +
                                green +
                                ', ' +
                                blue +
                                ',.01)';*/
                        // Lighten
                        /*    var NewColor = LightenDarkenColor(value.value, 100);
                            imagesArray.primary_table_hover = NewColor;
                        }
                        if (value.key === 'btn_light_primary')
                            imagesArray.btn_light_primary = value.value;
						*/
                    });
                    themeImages.push(imagesArray);
                    theme = themeImages[0];
                }
                localStorage.setItem(
                    'theme_details_' + domainName,
                    JSON.stringify(theme)
                );
            }
        });
}
/*setTimeout(() => {
   if (theme === '') {
        const resultArray = Object.keys(defaultThemes).map((index) => {
            return defaultThemes[index][subdomain];
        });
        // var theme = resultArray[0];
        resultData = resultData.filter((v) => v !== undefined);
        theme = resultData[0];

        //kalpana added - 16-12-2021
        if (process.env.REACT_APP_ENV === 'dev') {
            var themeData = Object.keys(defaultThemes).map(function (k) {
                if (defaultThemes[k][subdomain] != undefined) {
                    var themeDomain = defaultThemes[k][subdomain];
                } else {
                    var themeDomain = defaultThemes[k]['default'];
                }

                return themeDomain;
            });
            theme = themeData[0];
        }
    }
}, 2000);*/

if (subdomain === '8081') {
    localStorage.setItem(
        'SiteName',
        process.env.REACT_APP_TITLE + ' - Local  - 8081 PORT'
    );
} else if (subdomain === '8082') {
    localStorage.setItem(
        'SiteName',
        process.env.REACT_APP_TITLE + ' - Local  - 8082 PORT'
    );
} else if (subdomain === '5000') {
    localStorage.setItem(
        'SiteName',
        process.env.REACT_APP_TITLE + ' - Local  - 5000 PORT'
    );
} else {
    localStorage.setItem(
        'SiteName',
        process.env.REACT_APP_TITLE + ' - ' + subdomain
    );
}
/*
 <ThemeProvider theme={theme}>
   <GlobalStyles  />
*/

document.title = process.env.REACT_APP_TITLE + ' - ' + subdomain;
// Msal configuration

setTimeout(() => {
    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <MetronicI18nProvider>
                <MetronicLayoutProvider>
                    <MetronicSubheaderProvider>
                        <MetronicSplashScreenProvider>
                            <App
                                store={store}
                                persistor={persistor}
                                basename={PUBLIC_URL}
                            />
                        </MetronicSplashScreenProvider>
                    </MetronicSubheaderProvider>
                </MetronicLayoutProvider>
            </MetronicI18nProvider>
        </ThemeProvider>,
        document.getElementById('root')
    );
}, loadInterval);
