import React, { Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import loadable from '@loadable/component';
import { MyPage } from './pages/MyPage';

//import { SignedOrdersPage } from './pages/SignedOrdersPage';
//import { MobileReviewPage } from './pages/MobileReviewPage';
//import { ExceptionPage } from './pages/ExceptionPage';
import ReportsPage from './pages/reports/ReportPage';
import UserPage from './pages/user/UserPage';
import GroupPage from './pages/user/GroupPage';
import SearchPage from './pages/search/SearchPage';
//import { TrackingPage } from './pages/TrackingPage';
import OutboxPage from './pages/outbox/OutboxPage';
import { Logout } from './pages/Logout';
import inboxPage from './pages/inbox/InboxPage';
import ESignPage from './pages/eSign/EntityListPage';
import { DocumentHistroy } from './pages/inbox/components/DocumentHistroy';
import DocumentDetail from './pages/inbox/components/DocumentDetail';
import AuthPage from './modules/Auth/pages/AuthPage';
import DocumentDetailViewComponent from './pages/inbox/components/DocumentDetailViewComponent';
import OutboxDetailViewComponent from './pages/outbox/components/DocumentDetailViewComponent';

import { ChatPage } from './pages/ChatPage';
import TagsManage from './pages/admin/tags/TagsManage';
import PhysicianUpload from './pages/admin/documentsUpload/BulkUpload';

// import DashboardPage from './pages/dashboard/DashboardPage';
// import ApexChart from './pages/dashboard/tree';
// import TreeMapSample from './pages/dashboard/TreeMapSample';

// import FormsPage from './pages/FormsPage';
// import ThemePage from './pages/ThemePage';
// import { ChangePassword } from './pages/user/ChangePassword';
// import MyAccount from './pages/user/MyAccount';

//import OutboxPage from './pages/outbox/OutboxPage';
//import { OutboxPage } from './pages/OutboxPage';

//import { OutboxPage as outbox } from './pages/OutboxPage';
// import UploadPage from './pages/UploadPage';
// import SendToFaxUploadPage from './pages/SendToFaxUploadPage';
// import EditWorkPage from './pages/EditWorkPage';
// import MultipleEditPage from './pages/MultipleEditPage';
// import { SendToFaxPage } from './pages/SendToFaxPage';
// import { SettingsPage } from './pages/SettingsPage';

// import InboxSrc from "./pages/inbox/InboxSrc";
// import PdfPreview from './pages/inbox/components/PdfPreview';

// import Login from "../app/modules/Auth/pages/Login";

// import SuccessGmailRedirectPage from './components/composeDraftMail/SuccessGmailRedirectPage';
//import DataGridPage from './pages/DataGridPage';

const DashboardPage = loadable(() => import('./pages/dashboard/DashboardPage'));
//const ApexChart = loadable(() => import('./pages/dashboard/tree'));
//const TreeMapSample = loadable(() => import('./pages/dashboard/TreeMapSample'));
const FormsPage = loadable(() => import('./pages/FormsPage'));
//const ThemePage = loadable(() => import('./pages/ThemePage'));
const ChangePassword = loadable(() => import('./pages/user/ChangePassword'));
const MyAccount = loadable(() => import('./pages/user/MyAccount'));
const UploadPage = loadable(() => import('./pages/UploadPage'));
const SendToFaxUploadPage = loadable(() =>
    import('./pages/SendToFaxUploadPage')
);
const EditWorkPage = loadable(() => import('./pages/EditWorkPage'));
const MultipleEditPage = loadable(() => import('./pages/MultipleEditPage'));
const AdminPage = loadable(() => import('./pages/AdminPage'));
//const SendToFaxPage = loadable(() => import('./pages/SendToFaxPage'));
//const SettingsPage = loadable(() => import('./pages/SettingsPage'));
const PdfPreview = loadable(() =>
    import('./pages/inbox/components/PdfPreview')
);

function BasePage(props) {
    function loadMainDartJs() {
        // var scriptTag = document.createElement('script');
        // scriptTag.src = './main.dart.js';
        // scriptTag.type = 'application/javascript';
        // var result = document.getElementById('flutterPreview');
        // result.append(scriptTag);
    }

    React.useEffect(() => {
        loadMainDartJs();
    }, [props.location]);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/*
                {localStorage.getItem('hasPasswordExpired') === 'true' ? (
                    <Redirect exact from='/' to='/change-password' />
                ) : null}                
                kalpana - http://172.21.4.230:8081/auth/change-password*/}
                {
                    /*localStorage.getItem('hasPasswordExpired') === true ? (
                        <Redirect exact from='/' to='/auth/change-password' />
                    ) : localStorage.getItem('valid_user') === 'true' &&
                      localStorage.getItem('inboxMenu') === 'true' ? (
                        <Redirect
                            exact
                            from='/'
                            to={localStorage.getItem('inboxLink')}
                        />
                    ) : (
                        <Redirect exact from='/' to='/dashboard' />
                    )*/

                    localStorage.getItem('hasPasswordExpired') === true ? (
                        <Redirect exact from='/' to='/auth/change-password' />
                    ) : localStorage.getItem('valid_user') === 'true' ? (
                        <Redirect exact from='/' to='/dashboard' />
                    ) : (
                        <Redirect exact from='/' to='/dashboard' />
                    )
                }

                <ContentRoute path='/dashboard' component={DashboardPage} />
                <ContentRoute path='/dashboards' component={DashboardPage} />
                <ContentRoute path='/chat' component={ChatPage} />
                <ContentRoute
                    path='/inbox/:menuName?/:processId?/:statusId?'
                    component={inboxPage}
                />
                <ContentRoute path='/e-sign' component={ESignPage} />
                <ContentRoute path='/histroy' component={DocumentHistroy} />
                <ContentRoute
                    path='/documentdetail'
                    component={DocumentDetail}
                />
                <ContentRoute
                    path='/detail/:docid?'
                    component={DocumentDetailViewComponent}
                />
                <ContentRoute
                    path='/outbox-detail/:docid?'
                    component={OutboxDetailViewComponent}
                />

                <ContentRoute path='/pdf_preview' component={PdfPreview} />

                <ContentRoute
                    path='/change-password'
                    component={ChangePassword}
                />
                <ContentRoute
                    path='/users/change-password'
                    component={ChangePassword}
                />
                <ContentRoute path='/users/my-account' component={MyAccount} />
                <ContentRoute path='/users/groups' component={GroupPage} />
                <ContentRoute path='/users' component={UserPage} />
                <ContentRoute path='/reports' component={ReportsPage} />
                <ContentRoute
                    path='/search/:menuName?'
                    component={SearchPage}
                />
                <ContentRoute
                    path='/search/edit/:docId?'
                    component={SearchPage}
                />

                <ContentRoute path='/forms' component={FormsPage} />
                <ContentRoute path='/admin' component={AdminPage} />

                <ContentRoute
                    path='/outbox/:menuName?/:processId?/:statusId?'
                    component={OutboxPage}
                />

                <ContentRoute
                    path='/upload/:uploadId?'
                    component={UploadPage}
                />
                <ContentRoute
                    path='/send-to-fax/:uploadId?'
                    component={SendToFaxUploadPage}
                />
                <ContentRoute
                    path='/documents/edit/:docId?'
                    component={EditWorkPage}
                />
                <ContentRoute
                    path='/documents/editmultiple/:docId?'
                    component={MultipleEditPage}
                />

                <ContentRoute
                    path='/process-forgot-password'
                    component={MyPage}
                />
                <ContentRoute path='/logout' component={Logout} />

                {/*
				
				
				 <ContentRoute path='/exceptions' component={ExceptionPage} />
				<ContentRoute path='/grid' component={DataGridPage} />
				<ContentRoute path='/settings' component={SettingsPage} />
				 <ContentRoute path='/sendtofax' component={SendToFaxPage} />
				<ContentRoute
                    path='/mobile-review'
                    component={MobileReviewPage}
                />

				<ContentRoute path="/inbox-kalpana/:menuName?/:processId?/:statusId?" component={Inboxkalpana}/> */}
                {/*<ContentRoute path='/dashboard/tree' component={ApexChart} />*/}
                {/*<ContentRoute
                    path='/dashboard/TreeMapSample'
                    component={TreeMapSample}
					/>*/}
                {/* <ContentRoute path='/chat' component={Messages} /> */}
                {/* <ContentRoute path="/inbox" component={InboxPage}/> */}
                {/*  <ContentRoute path="/inbox-demo" component={InboxPageDemo}/>
                <ContentRoute path="/inbox2" component={Inbox2}/>
                <ContentRoute path="/inbox3" component={Inbox3}/>

					<ContentRoute path="/inbox5" component={Inbox5}/> */}

                {/*
				<ContentRoute path="/inbox-final/:menuName?/:processId?/:statusId?" component={InboxSrc}/>
				<ContentRoute path="/inbox4" component={inbox4}/>
				<ContentRoute path="/inbox5" component={Inbox5}/> */}
                {/* <ContentRoute
                    path='/mailSuccess'
                    component={SuccessGmailRedirectPage}
                /> */}
                {/* <ContentRoute path="/inbox4duplicate" component={Inbox4Duplicate}/>
				<ContentRoute path="/inbox-design-duplicate" component={inbox_design_duplicate}/>
                <ContentRoute path="/inbox-design" component={inbox_design}/>
				 */}
                {/* <ContentRoute path="/responsive" component={ResponsiveGrid}/> */}
                {/* <ContentRoute path="/responsive2" component={ResponsiveGrid2}/> */}
                {/*<ContentRoute path='/themes' component={ThemePage} />
                <ContentRoute path='/tracking' component={TrackingPage} />*/}
                {/* <ContentRoute path='/outbox_' component={outbox} /> 
                <ContentRoute
                    path='/signed-orders'
                    component={SignedOrdersPage}
                />*/}

                {/* <ContentRoute
                    path='/documents/edit/:docId?'
                    component={EditPage}
                /> */}

                {/* <ContentRoute path="/upload_d" component={UploadDummy}/> */}
                {
                    /* Redirect from root URL to /dashboard. */
                    (localStorage.getItem('valid_user') === 'false' ||
                        localStorage.getItem('valid_user') === null) && (
                        <>
                            {' '}
                            <Route>
                                {' '}
                                <AuthPage />{' '}
                            </Route>{' '}
                        </>
                    )
                }
                {/* <ContentRoute path="/detail_view" component={DetailBigView1}/> */}
                {/* <ContentRoute path="/builder" component={BuilderPage}/>

                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
				
				 


				*/}
                <Route path='/iframe'>
                    <Redirect push to={'/iframe.html'} />
                </Route>

                <Redirect to='error/error-v1' />
            </Switch>
        </Suspense>
    );
}

export default withRouter(BasePage);
