import React, { FC, useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import { NoRecordFound } from '../../../components/commonfunction';
import { Button, Modal } from 'react-bootstrap';

interface AddReviewersModalProps {
    columnsArray: any[];
    rows: any[];
    title: string;
    onClose: () => void;
    onAssignReviewers: (selectedRows: any[]) => void;
}

const AddReviewersModal: FC<AddReviewersModalProps> = ({ columnsArray, rows, title, onClose, onAssignReviewers }) => {
    const [selectedRows, setSelectedRows] = useState<Set<any>>(new Set());

    const columns = useMemo(() => {
        return [...columnsArray];
    }, [columnsArray]);


    const handleAssign = () => {
        if (selectedRows.size === 1) {
            const selectedRow = Array.from(selectedRows)[0];
            onAssignReviewers([selectedRow]);
        }
    };

    const handleRowClick = (row: any) => {
        setSelectedRows(new Set([row.id]));
    };

    const rowClass = (row: any) => {
        return selectedRows.has(row.id) ? 'row-selected-once' : '';
    };

    return (
        <Modal
            show={true}
            onHide={() => onClose()}
            animation={false}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header closeButton className='d-block justify-content-start'>
                <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                    <div className=''>
                        <i title={'Add'} className={'fa-regular fa-circle-plus'} aria-hidden='true' />
                        {title}
                    </div>
                    <i
                        title='Close'
                        className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                        aria-hidden='true'
                        onClick={() => onClose()}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='no-first-col'>
                    <div className='grid_table' style={{ minHeight: '450px', height: '150px' }}>
                        <DataGrid
                            columns={columns}
                            rows={rows}
                            emptyRowsRenderer={NoRecordFound}
                            style={{ height: '100%' }}
                            headerRowHeight={40}
                            rowHeight={52}
                            rowKeyGetter={(row: any) => row.id}
                            onRowClick={handleRowClick}
                            rowClass={rowClass}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-block delete_btn'>
                <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                    <div className='d-flex'>
                        <Button variant='secondary' onClick={() => onClose()} title='Cancel'>
                            Cancel
                        </Button>
                    </div>
                    <div className='d-flex'>
                        <Button variant='success' className='success' onClick={handleAssign} title='Select'>
                            Select
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AddReviewersModal;
