import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { processPassword } from '../modules/Auth/_redux/authCrud';

export function MyPage() {
    //const suhbeader = useSubheader();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');
    useEffect(() => {
        if (token !== '') {
            processPassword(token)
                .then(({ data }) => {})
                .catch(() => {});
        }
    });

    return (
        <Redirect
            to={{
                pathname: '/auth/forgot-password',
                state: { msg: 'success' },
            }}
        />
    );
}
