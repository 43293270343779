import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
.Logo a{background:${({ theme }) => theme.dark_blue}}
  a.text-hover-primary:hover, .text-hover-primary:hover,
  a.text-hover-primary:focus, .text-hover-primary:focus{
	  color: ${({ theme }) => theme.primary} !important;
}

.btn.btn-primary,.btn-light-primary,
.transition_part .edit_location button,
.transition_part .edit_details button,
.transition_part .cancel_save button,
.details_part .edit_location button,
.details_part .cancel_save button{
	color: #FFFFFF;
	background-color: ${({ theme }) => theme.icon_color};
	border-color: ${({ theme }) => theme.icon_color};
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn.btn-primary,
.btn.btn-primary:active,.login .btn.btn-primary:focus,
.btn.btn-primary:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(.btn-text),
.btn-light-primary,
.btn-light-primary:active,
.btn-light-primary:focus,
.btn-light-primary:not(.btn-text):not(:disabled):not(.disabled),
.btn-light-primary:focus:not(.btn-text){
	background: ${({ theme }) => theme.icon_color};
	border-color: ${({ theme }) => theme.icon_color};
}
.btn.btn-primary:hover,
.btn.btn-primary:active,.login .btn.btn-primary:focus,
.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(.btn-text),
.btn-light-primary:hover,
.btn-light-primary:active,
.btn-light-primary:focus,
.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn-light-primary:focus:not(.btn-text),.right-aside .bottom-function .icon_fun:hover{
	background: ${({ theme }) => theme.primary_hover};
	border-color: ${({ theme }) => theme.primary_hover};
}
.btn.btn-outline-primary {
	border-color:${({ theme }) => theme.icon_color};
	color:${({ theme }) => theme.icon_color};
}
.btn.btn-outline-primary:hover {
	background:${({ theme }) => theme.icon_color} !important;
	color:#fff;
}
.delivery-details svg {
		fill:${({ theme }) => theme.icon_color};
}
.whole-form {
    background-color: #ffffff;
 }

.aside-enabled .header,
.brand-dark .header-mobile,
.related_detail_icon span:hover,
.nav.nav-pills .show > .nav-link,
.post_data .note-type-tag ,
.scrolltop,.details-tag.contain-tag-value,.header-menu-wrapper,.header-menu-mobile,.dashboard_block,.search_bypatient,.search-block{
    background: ${({ theme }) => theme.primary} !important;
}
.nav.nav-pills .show > .nav-link, .nav.nav-pills .nav-link.active,.detail_search .plus .edit_btn ,.dashboard_block .top_section .user_icon{
	background-color:${({ theme }) => theme.icon_color};
}

.login {
    background-size: cover;
	background-image: url(${({ theme }) => theme.bgimageURL}) !important;
	background-color:  background-color: ${({ theme }) => theme.primary};
	background-repeat: no-repeat;
}
.menu_logo {
	background-image: url(${({ theme }) => theme.smallLogoURL}) !important;
	background-size: cover;
	background-repeat: no-repeat;
	/*background-color:transparent !important;*/
}
.header-menu-mobile .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-arrow, .header-menu-mobile .menu-nav > .menu-item.menu-item-active > .menu-link .menu-arrow {
		color:${({ theme }) => theme.icon_color} !important;
}

.Logo{
	a{
		background-image:url(${({ theme }) => theme.logoURL}) !important;
		 background-size: cover;
		 background-repeat: no-repeat;

	}
	&.change_pass{
		a{
			background-image:url(${({ theme }) => theme.smallLogoURL}) !important;
			 background-size: cover;
			 background-repeat: no-repeat;

		}
	}
	&.confirmation{
		a{
			background-image:url(${({ theme }) => theme.smallLogoURL}) !important;
			 background-size: cover;

		}
	}
}
.rdg-row:hover .rdg-cell{	
	/*background-color: ${({ theme }) => theme.primary_table_hover} !important;
	background:lighten(${({ theme }) => theme.primary},20%)!important;*/
	background-color:#f2f4f5!important;
}
.rdg-row.row-selected-once .rdg-cell,.rdg .row-selected-once > div.rdg-cell-frozen:first-child:before {background: ${({
    theme,
}) => theme.icon_color}10}
/*.rdg-row:hover .rdg-cell:first-child:before,.rdg-row:hover .rdg-cell-frozen:first-child:before,.rdg .row-selected-once:hover > div.rdg-cell-frozen:first-child:before { 
	background: ${({ theme }) => theme.primary}10;
}*/
/*.rdg-row:hover .rdg-cell {background: ${({ theme }) => theme.primary}05;}*/
.splash-spinner .path {
	stroke:${({ theme }) => theme.icon_color};
}
.menu_logo{
	/*background: url(${({ theme }) =>
        theme.image_path}/8081/Logo-white.png) no-repeat; */
	/* height: 35px;
	width: 35px;
	display: inline-block !important;
	background-size: contain; */
	@media(max-width:992px){
		/* background-image:url(#{${({ theme }) =>
            theme.image_path}}/8081/WV-Logo.png); */
		/* min-height:auto; */
	}
}

.spinner-blur:before{background:${({ theme }) =>
    theme.primary_hover} !important}

.reload i,.right-aside .bottom-function .bottom-icons div i ,
/*.pdf-detail .right-function .icon_fun:hover span .dropdown-item i,
.pdf-detail .right-function .icon_fun span i,*/
.pdf-detail .right-function .icon_fun span .bulk-print-opt .fa-ellipsis-v,
.transition_part .transition_fun span i,
.transition_part .details_fun span i,
.details_part .transition_fun span i,
.details_part .details_fun span i,
.related_detail_icon span i,
.related_detail_icon span:nth-child(2) i,
/*.details-tag .fatag.active  i,*/
.pdf-detail .right-function .icon_fun span i.fa-file-medical-alt,
#right-aside .bottom-function .bottom-icons .bulk-print-opt .btn-success:hover i.fa-ellipsis-v,
#left-aside .bottom-function .bottom-icons .bulk-print-opt .btn-success:hover i.fa-ellipsis-v,.image-checkbox i,.bottom-function .fa-sticky-note,.bottom-function .fa-page-break
.MuiPopover-paper p .rel-dropdown-menu .rel-dropdown-item i,
.MuiPopover-paper .inner-filter span,.multi_edit_icons i,.pdf-detail .right-function .icon_fun span i,.print-page .print-top .print-editer .text-edit span i,i.fas.fa-sign-out-alt,.form_empty .no_form i,.action-user i,.Statistic-part .statistics_header h3 i,.btn.btn-outline-primary i,.category-tags p .fa-sync-alt,.coming_txt .upload_icon i,.coming_txt .upload_icon i,.coming_txt .upload_txt button,.filter-sorting ul li.active-asc,.filter-sorting ul li.active-asc i,.filter-sorting ul li.active-desc,.filter-sorting ul li.active-desc i,.realted_document .header_row.no-bg-recent .header_title i{
	color:${({ theme }) => theme.icon_color};
}
.MuiPopover-paper .rel-dropdown-menu .rel-dropdown-item i ,.highlight,.patient_no_name span b,.right-aside .bottom-function .bulk-print-opt.show button i,.right-aside .bottom-function .icon_fun:hover .dropdown-item i,.btn.btn-success:focus:not(.btn-text) i, .btn.btn-success.focus:not(.btn-text) i{
	color:${({ theme }) => theme.icon_color} !important;
}
.pdf-detail .left-expand .left-pdf-button span i,
.expand-mode .pdf-detail .left-expand .bulk-print-opt .btn.btn-success i,
.expand-mode .pdf-detail .left-expand .bulk-print-opt .dropdown-menu a i,
.right-aside .bottom-function .icon_fun i,
.modal-title i{
	color:${({ theme }) => theme.icon_color};
}
.icon-fill ,.vertical-loader-fill,.dropdown-menu.show svg{
	fill:${({ theme }) => theme.icon_color};
}
.react-date-picker__calendar-button svg {
	stroke:${({ theme }) => theme.icon_color};
}
.pdf-detail .right-function .icon_fun span i.fa-trash-alt,
.pdf-detail .right-function .icon_fun:hover span .dropdown-item i.fa-trash-alt,
.expand-mode .pdf-detail .left-expand .bulk-print-opt .dropdown-menu a i.fa-trash-alt,
.right-aside .bottom-function .bottom-icons div i.fa-trash-alt{
	 color: #D84C3A !important;
}

.right-aside.upload_page{
	border-color:${({ theme }) => theme.icon_color};
}
.details-tag.contain-tag-value .fatag.active i,
.related_detail_icon .btn-success span:hover i,.related_detail_icon span:hover i.fa-sticky-note{
	color:#fff !important;
}
.details-tag.contain-tag-value .fatag.active{
	border:0 !important;
}
.grouping-loader,.spinner-loader,.overlay-loader,.spinner-blur,.spinner_3,.pdf_loader{
	svg{
		stroke:${({ theme }) => theme.icon_color} !important;
	}
}

.right-aside .bottom-function .bottom-icons div i.fa-file-medical-alt,
.right-aside .bottom-function .bulk-print-opt.show button i,
.right-aside .bottom-function .bulk-print-opt button i,
.right-aside .bottom-function .bulk-print-opt button:active i,
.right-aside .bottom-function .bulk-print-opt button:hover i /*,
.pdf-detail .right-function .icon_fun:hover span  .dropdown-menu i*/{
	color:${({ theme }) => theme.icon_color};
}
.right-function .icon_fun span:hover i.fa-file-medical-alt,
.related_detail_icon span:hover i.fa-ellipsis-v,
.pdf-detail .right-function .icon_fun span:hover .bulk-print-opt .fa-ellipsis-v,.reload:hover i,
.right-aside .bottom-function .bottom-icons div.no-bg i{
	color:#fff !important;
	background-color:transparent;
}


/*.realted_document .realted_content .tab .nav .nav-item a.active,
.realted_document .realted_content .tab .nav .nav-item a:hover,/.nav.nav-pills .nav-link.active{
	background:${({ theme }) => theme.icon_color} !important;
}
.realted_document .header_row.with-bg-recent{
	background:${({ theme }) => theme.primary};
}
.pdf-detail .right-function .icon_fun:hover button i{color:#fff}
.header .upload-part.header-menu ul.undefined .menu-submenu-right ul li:hover a i {
	/*color:${({ theme }) => theme.primary_hover} !important;
}*/
.form-control:focus{border-color:${({ theme }) => theme.primary_hover}}
.right-aside .bottom-function .tag-symbol button i,.right-aside .bottom-function i.fa-user-plus {
	color:rgba(255,255,255,0.7);
}
.right-aside .bottom-function .tag-symbol,.right-aside .bottom-function .add-user-bottom {
	border-color: rgba(255,255,255,0.7);
}
.right-aside .bottom-function .tag-symbol:hover,.right-aside .bottom-function .add-user-bottom:hover{
	border-color: rgba(255,255,255,10);
}
.tooltip  .tooltip-inner { background:${({ theme }) =>
    theme.primary} !important;}
.tooltip  .tooltip-inner div{ background:transparent !important;}

.login{
	 background-color: ${({ theme }) => theme.primary};
}
.report-left-top{
	.filter:hover{		
		input{
			background:${({ theme }) => theme.primary_active};
		}
		i{
			color:${({ theme }) => theme.icon_color};
		}
	}
}
.aside-menu{
	.menu-nav{
		.menu-item {
			.menu-subnav .menu-item i{
				color:#B5B5C3 !important;
			}
			&:hover,.active{
				a{
					background:${({ theme }) => theme.primary_inverse};
					border-right: 4px solid ${({ theme }) => theme.icon_color} !important;
					span{
						color:${({ theme }) => theme.icon_color} !important
					}
					i{
						color:${({ theme }) => theme.icon_color} !important
					}
					

				}
				
			} 
			.menu-submenu {
				.menu-item:hover{
					a{
						background:${({ theme }) => theme.primary_inverse};
						border-right: 4px solid ${({ theme }) => theme.icon_color} !important;
						span{
							color:${({ theme }) => theme.icon_color} !important
						}
						i{
							color:${({ theme }) => theme.icon_color} !important
						}

					}
				}
			}
			a{
				&.active, &.root-active{
					background-color:${({ theme }) => theme.primary_active} !important;
					border-right: 4px solid ${({ theme }) => theme.icon_color} !important;
					span{
						color:${({ theme }) => theme.icon_color} !important
					}
					i{
						color:${({ theme }) => theme.icon_color} !important
					}
				}
			} 
		}
	}
}
.aside-minimize .sub-title-menu:hover {
	background-color:${({ theme }) => theme.primary_active};
	border-right: 4px solid ${({ theme }) => theme.icon_color};
	i{
		color:${({ theme }) => theme.icon_color} !important;
	}
}
.aside-menu .menu-nav .menu-item.menu-item-submenu .menu-subnav .menu-item{
	position:relative;
		/*&:before{
			border-right: 4px solid transparent !important;
			content:"";
			height: 40px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		&.active, &:hover{
			&:before{
				border-right: 4px solid ${({ theme }) => theme.icon_color} !important;
			}
		}*/
		&.active, &:hover{
			background-color:${({ theme }) => theme.primary_active} !important;
			border-right: 4px solid ${({ theme }) => theme.icon_color} !important;
				i,.menu-text{
					color:${({ theme }) => theme.icon_color} !important;
				}
		}
}
.header .header-menu.header-menu-mobile .menu-nav .menu-item.menu-item-active .menu-subnav .menu-link/*,
 .header-menu-mobile .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link*/{	
	border-right: 4px solid #fff !important;
	background:#fff !important;
	&.active {
		background-color:${({ theme }) => theme.primary_active} !important;
		border-right: 4px solid ${({ theme }) => theme.icon_color} !important;
			span{
				color:${({ theme }) => theme.icon_color} !important
			}
			i{
				color:${({ theme }) => theme.icon_color} !important
			}
	}
}
.span-output{
	padding-left: 1.25rem
}


.wrap_note .edit_details span,.wrap_note .edit_details span i,.related_detail_icon span:hover,.related_detail_icon span .dropdown-menu a i,.fa-exchange-alt, .fa-sticky-note,.realted_document .realted_content .plan_care {
		color:${({ theme }) => theme.primary};
}
.checkbox > input:checked ~ span{
	background:${({ theme }) => theme.icon_color};
}

.right-aside {
	.bottom-function {
		background:${({ theme }) => theme.primary} !important;

			.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text)/*, .btn.btn-primary:not(:disabled):not(.disabled).active, .show > .btn.btn-primary.dropdown-toggle, .show .btn.btn-primary.btn-dropdown*/{
				background-color:${({ theme }) => theme.icon_color} !important;
				border-color:${({ theme }) => theme.icon_color} !important;
			}
		.tag-symbol{
			.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary:not(:disabled):not(.disabled).active, .show > .btn.btn-primary.dropdown-toggle, .show .btn.btn-primary.btn-dropdown{
				background-color:transparent !important;
				border-color:transparent !important;
			}
		}
	}
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
	border-top-color:${({ theme }) => theme.bright_blue} !important;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before{
	border-bottom-color:${({ theme }) => theme.icon_color} !important;
}
.rdg-cell,.edit_filters .form-group .checkbox-inline .checkbox,.MuiTypography-root,.document_title{
	input{
		&:checked ~ .rdg-checkbox,&:checked ~ span{
			background-color:${({ theme }) => theme.icon_color}  !important;
		}
	}
}
.rdg-checkbox-label,.document_title .rdg-checkbox-label {
	input {
		&:checked {
			~ {
				.rdg-checkbox {
					border: 1px solid ${({ theme }) => theme.icon_color};
				}
			}
		}
	}
}
 .rdg-checkbox-label{
	.rdg-checkbox-input{
		&.header-checkbox{
			~{
				.rdg-checkbox {
					background-color: ${({ theme }) => theme.icon_color};
					border-color: ${({ theme }) => theme.icon_color};
				}
			}
		}
	}
 }
 .rdg-checkbox-label .rdg-checkbox-input.header-checkbox ~ .rdg-checkbox{
	background-color: ${({ theme }) => theme.icon_color} !important;
	border-color: ${({ theme }) => theme.icon_color} !important;
 }
 .dropdown-item{
	 color: ${({ theme }) => theme.primary};
	 i{
		/* color: ${({ theme }) => theme.icon_color};*/
	 }
	&.active,&:active{
		/*background-color:${({ theme }) => theme.primary};*/
	}
}
.pdf-detail .right-function .icon_fun {
		&.remove_icon_hover{
			span{
				.dropdown-menu
					i {
						color:${({ theme }) => theme.primary};
					}
			}
		}
		/*&:hover,*/&.active{
			background-color:${({ theme }) => theme.pdf_color};
		}
		span{
			&:hover,&.active{
				/*background-color:${({ theme }) => theme.pdf_color};*/
			}
			.bulk-print-opt:hover{
				background-color:${({ theme }) => theme.pdf_color};
		}
	}
}
.wrap_transition, .wrap_details {border-color:${({ theme }) => theme.pdf_color}}

.expand-mode .pdf-detail .left-expand .bulk-print-opt .btn.btn-success:hover{
	background:${({ theme }) => theme.icon_color};
	border-color:${({ theme }) => theme.icon_color};
}
.tooltip {
	border-radius:15px;
	line-height:11px;
	bottom:3px !important;
	padding:0 !important;

	.tooltip-inner {
		padding-top:0.50rem;
		padding-bottom:0.50rem;
		background:${({ theme }) => theme.icon_color} !important;
		div{
			background:transparent !important;
		}
	}
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
	top:5px !important;
	border-top-color:${({ theme }) => theme.icon_color} !important;
}
.rdg .row-selected-once > div.rdg-cell-frozen:first-child:before {
	border-color:${({ theme }) => theme.icon_color};
}
.last-note {
	color:${({ theme }) => theme.pdf_color};
}
.owner-auto-search{
	span{
		border-color: ${({ theme }) => theme.pdf_color};
		i{
			color:${({ theme }) => theme.pdf_color} !important;
		}
	}
}
.pdf-detail{
	.left-expand{
		.left-pdf-button{
			span{
				color: ${({ theme }) => theme.pdf_color};
			}
		}
	}
	.right-function{
		.icon_fun{
			&.active{
				background:${({ theme }) => theme.pdf_color};
			}
			&:hover{
				span{
					/*background:${({ theme }) => theme.pdf_color};*/
				}
			}
			span{
				.bulk-print-opt{
					&:hover,&.active{
						background:${({ theme }) => theme.pdf_color};
					}
				}	
			}
		}
	}
}
/*.wrap_transition,.wrap_details{
	border-top:3px solid ${({ theme }) => theme.pdf_color};
}*/

.report-doc::before{
	background:${({ theme }) => theme.pdf_color};
}

.reload:hover,.reload-outer.active:after,.reload-outer .reload.active{
	background:${({ theme }) => theme.pdf_color} !important;
}
.right-aside .bottom-function .icon_fun:hover .dropdown-menu .delivery-details svg{fill:${({
    theme,
}) => theme.icon_color} !important;}
.realted_document{
	.header_row{
		&.no-bg-recent{
			color:${({ theme }) => theme.pdf_color};
		}
		&.with-bg-recent{
			background-color:${({ theme }) => theme.pdf_color};
		}
	}
}
.realted_content .tab .nav .nav-item a.active, .realted_content .tab .nav .nav-item a:hover, .navigation .tab .nav .nav-item a.active, .navigation .tab .nav .nav-item a:hover {
	background-color:${({ theme }) => theme.pdf_color};
}
.navigation .tab .nav .nav-item a:hover, .navigation .tab .nav .nav-item a.menu-active{
	background-color:${({ theme }) => theme.pdf_color} !important;
}
.pdf-detail .right-function .icon_fun:hover span,.pdf-detail .right-function .icon_fun span.active,.pdf-detail .right-function .icon_fun.active::after {background-color:${({
    theme,
}) => theme.pdf_color}}
.modal-title i{
	margin-right:15px;
}
 @media (min-width: 992px){
	 .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item > .menu-link .menu-text,
	 .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text{
		 color:${({ theme }) => theme.primary} !important;
	 }
 }
 @media (max-width: 992px){
	 .topbar {background-color:transparent}
	 .brand-dark .header-mobile .burger-icon span,.related_detail_icon span:nth-child(2) i,
	 .brand-dark .header-mobile .burger-icon span::before, .brand-dark .header-mobile .burger-icon span::after,
	 .brand-dark .header-mobile .burger-icon span::before, .brand-dark .header-mobile .burger-icon span::after{
		 background-color: ${({ theme }) => theme.primary_inverse} !important;
	 }
	.menu-toggle {
		svg rect {
			stroke:${({ theme }) => theme.primary_inverse} !important;
		}
	}
	
	.menu-item {
		&.menu-item-open{
				.menu-toggle,.menu-toggle.active {
					svg {
						path{
							fill:${({ theme }) => theme.icon_color};
							
						}
						rect {
							stroke:${({ theme }) => theme.icon_color} !important;
						}
					}
					.menu-arrow {
						color:${({ theme }) => theme.icon_color} !important;
						
					}
				}
			}
		}
	}
	.menu-item:hover,.menu-item:focus {
		.menu-toggle {
			svg {
				path{
					fill:${({ theme }) => theme.icon_color};
					
				}
				rect {
					stroke:${({ theme }) => theme.icon_color} !important;
				}
			}
			.menu-arrow {
				color:${({ theme }) => theme.icon_color} !important;
				
			}
		}
	}
 .bottom-function,.right-function {
	.btn-success {
		i{
			padding:0;
			color:#fff;
		}

	}
}

.aside-minimize {
	.sub-title-menu{
		&:hover {
			border-right:4px solid ${({ theme }) => theme.icon_color};
			i{
				color:${({ theme }) => theme.icon_color};
			}
		}
	}
}

`;
