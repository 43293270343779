/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getDDLlist,
    getConfigData,
} from '../../../pages/_redux/actions/configActions';
import {
    GetFieldData,
    formatNameWithSpaceToUnderscore,
    getPageFromPath,
} from '../../../components/commonfunction';

import DeletedDocumentPage from '../../deletedDocument/DeletedDocumentPage';
import FilterOptions from './FilterOptions';
import {
    updateSearchState,
    deletedPage,
} from '../../_redux/actions/searchActions';

export class RemovedDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh: true,
            open_filter: false,
            branchOption: this.props.ddl.branch,
            operate_search: false,
            operate_filter: {},
            show_result: false,
            pathName: '',
            pathId: '',
            dataGrid: true,
            mobileTab: false,
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.editPath !== this.props.location.pathname) {
            this.setState({
                editPath: this.props.location.pathname,
            });
        }

        if (
            String(prevState.currentPath).includes('edit') &&
            String(this.state.currentPath).includes('edit') !== true
        ) {
            this.setState({
                operate_filter: prevState.operate_filter,
            });
        }
        const current_path = this.props.location.pathname;

        const path_arr = current_path.split('/');

        if (path_arr.length === 2) {
            if (this.state.show_tabs === false) {
                this.setState({ show_tabs: true });
            }
        } else if (path_arr.length > 2) {
            if (path_arr[1] === 'search' && path_arr[5] !== 'edit') {
                if (this.state.show_tabs === false) {
                    this.setState({ show_tabs: true });
                }
            }
            if (path_arr[1] === 'search' && path_arr[5] === 'edit') {
                if (this.state.show_tabs === true) {
                    this.setState({ show_tabs: false });
                }
            }
        }
        if (this.state.pathId !== path_arr[4]) {
            this.props.customSearchQueries.map((data) => {
                //due to comapring array and object use two equal-to only
                if (parseInt(path_arr[4]) === parseInt(data.searchId)) {
                    this.setState({
                        pathName: data.name,
                    });
                }
            });
            this.setState({
                pathId: path_arr[4],
                dataGrid: false,
            });
            setTimeout(() => {
                this.setState({
                    dataGrid: true,
                });
            }, 100);

            this.setState({
                operate_search: false,
                operate_filter: {},
            });
        }

        if (prevProps.customSearchQueries !== this.props.customSearchQueries) {
            this.props.customSearchQueries.map((data) => {
                if (parseInt(path_arr[4]) === parseInt(data.searchId)) {
                    this.setState({
                        pathName: data.name,
                    });
                }
            });
        }
    }

    async componentDidMount() {
        this.setState({
            currentPath: this.props.location.pathname,
        });

        this.setState({ show_tabs: true });
        const current_path = this.props.location.pathname;

        const path_arr = current_path.split('/');

        if (path_arr.length === 2) {
            if (this.state.show_tabs === false) {
                this.setState({ show_tabs: true });
            }
        }
        if (path_arr.length > 2) {
            if (path_arr[1] === 'search' && path_arr[5] !== 'edit') {
                if (this.state.show_tabs === false) {
                    this.setState({ show_tabs: true });
                }
            }
            if (path_arr[1] === 'search' && path_arr[5] === 'edit') {
                if (this.state.show_tabs === true) {
                    this.setState({ show_tabs: false });
                }
            }
        }

        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldIds: fieldDataObj });

        if (this.state.pathId !== path_arr[4]) {
            this.props.customSearchQueries.map((data) => {
                if (parseInt(path_arr[4]) === parseInt(data.searchId)) {
                    this.setState({
                        pathName: data.name,
                    });
                }
            });
            this.setState({ pathId: path_arr[4] });
        }
    }

    getMenuItemActive = () => {
        const currentClass =
            document.getElementsByClassName('custom-menu-link');

        for (let i = 0; i < currentClass.length; i++) {
            currentClass[i].classList.remove('root-active');
        }
    };

    updateState = (state) => {
        this.setState({ ...state });
    };

    render() {
        let pathname = this.state.pathName;
        pathname = getPageFromPath(pathname);
        pathname = String(formatNameWithSpaceToUnderscore(pathname));

        return (
            <>
                {this.state.refresh ? (
                    <div className='search-inner-right-aside'>
                        {this.state.show_tabs ? (
                            <div className='form-group filter-block'>
                                {this.state.mobileTab === false ? (
                                    <FilterOptions
                                        custom_search_name={pathname}
                                        custom_search_id={pathname}
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                        }}
                                        pageTitle={this.state.pathName}
                                        operate_search={
                                            this.state.operate_search
                                        }
                                        operate_filter={
                                            this.state.operate_filter
                                        }
                                        editPath={this.state.editPath}
                                    />
                                ) : null}
                            </div>
                        ) : null}

                        <div>
                            {this.state.dataGrid && (
                                <DeletedDocumentPage
                                    {...this.state}
                                    calingFrom='search'
                                    pageTitle={this.state.pathName}
                                    custom_search_name={pathname}
                                    //custom_search_id={pathname}
                                    operate_search={this.state.operate_search}
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                    }}
                                    operate_filter={this.state.operate_filter}
                                    show_result={this.state.show_result}
                                    mobileTab={this.state.mobileTab}
                                />
                            )}
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
    updateSearchState,
    getConfigData: getConfigData,
    deletedPage,
};
const mapStateToProps = (state, ownProps) => {
    return {
        search: state.search,
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        rulesJson: state.persist.rulesJson,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.outbox.selectedRowDetails,
        ddl: state.persistDDL.DDL,
        currentMenuName: state.persist.currentMenuName,
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        deleted_page: state.search.deletedPage,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RemovedDocuments));
