import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PatientSearch from './components/PatientSearch';
import PhysicianSearch from './components/PhysicianSearch';
import RemovedDocuments from './components/RemovedDocuments';

import { deletedPage } from '../_redux/actions/searchActions';
import { getDDLlist } from '../_redux/actions/configActions';
import { getConfigData } from '../_redux/actions/configActions';
import {
    getRecordSetConfig,
    getCustomSearchConfig,
} from '../_redux/actions/configActions';
import { SpinnerBlur } from '../../components/Spinner';
import {
    GetFieldData,
    formatHeaderNameWithOutSpace,
    stringEmptyOrUndefined,
    getDropDownDataFromApi,
    download,
    updateCustomSearchConfigDataInLocalStorage,
} from '../../components/commonfunction';
import DetailBigView from '../inbox/components/DetailBigView';
import {
    configurationCustomeSearchId,
    configurationRecordSetId,
} from '../api/api';

import PdfPreview from '../inbox/components/PdfPreview';
import DocumentDetailViewComponent from '../outbox/components/DocumentDetailViewComponent';

class SearchPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuName: 'patients',
            customLoader: false,
            entirePageLoader: false,
        };
    }
    async apiFunction(idx) {
        try {
            const data = await configurationCustomeSearchId(idx);
            const customSearches = data.customSearchQueries;
            const customSearchNamedIdsNeedingConfiguration =
                data.customSearchNamedIdsNeedingConfiguration;

            updateCustomSearchConfigDataInLocalStorage(
                customSearches,
                customSearchNamedIdsNeedingConfiguration
            );

            if (this.props.getCustomSearchConfig) {
                await this.props.getCustomSearchConfig(
                    customSearches,
                    customSearchNamedIdsNeedingConfiguration
                );
            }
        } catch (error) {
            console.error(error);
        }
    }
    async recorsetFunction(idx) {
        try {
            const data = await configurationRecordSetId(idx);
            const configuredRecordsets = data.configuredRecordsets;
            const recordsetNamedIdsNeedingConfiguration =
                data.recordsetNamedIdsNeedingConfiguration;

            const existingConfigData =
                JSON.parse(localStorage.getItem('configData')) || {};
            existingConfigData.configuredRecordsets = configuredRecordsets;
            existingConfigData.recordsetNamedIdsNeedingConfiguration =
                data.recordsetNamedIdsNeedingConfiguration;

            localStorage.setItem(
                'configData',
                JSON.stringify(existingConfigData)
            );

            if (this.props.getRecordSetConfig) {
                await this.props.getRecordSetConfig(
                    configuredRecordsets,
                    recordsetNamedIdsNeedingConfiguration
                );
            }
        } catch (error) {
            console.error(error);
        }
    }
    async componentDidMount() {
        this.setState({
            entirePageLoader: true,
        });
        if (
            this.props.configData.customSearchNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.customSearchNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (data.name === 'Physician Directory') {
                        this.apiFunction(data.id);
                    }
                    if (data.name === 'Patient Data') {
                        this.apiFunction(data.id);
                    }
                    if (data.name === 'By Batch') {
                        this.apiFunction(data.id);
                    }
                    if (data.name === 'By Order') {
                        this.apiFunction(data.id);
                    }
                }
            );
        }
        if (
            this.props.configData.recordsetNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (data.name === 'Locations By Physician') {
                        //await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Admission Coordinator') {
                        // await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physician Locations') {
                        //await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Locations By Physician') {
                        //await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physician Directory Standard') {
                        // await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physician Directory Locations') {
                        // await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Payor Sources') {
                        //await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Order Data') {
                        //await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Patient Data Modify') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Order Data Modify') {
                        //await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Episode Data') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Branch Data') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Episode Visit Data') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physicians By Location') {
                        //await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Locations By Physician Standard') {
                        //await this.recorsetFunction(data.id);
                    }
                }
            );
        }
        if (
            this.props.configData.customSearchNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.customSearchNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (data.name === 'Physician Search') {
                        //await this.apiFunction(data.id);
                    }
                    if (data.name === 'Order Tracking') {
                        await this.apiFunction(data.id);
                    }
                    if (data.name === 'Patient Documents') {
                        await this.apiFunction(data.id);
                    }
                    if (data.name === 'Physician Inbox') {
                        // await this.apiFunction(data.id);
                    }
                    if (data.name === 'Physician Outbox') {
                        //await this.apiFunction(data.id);
                    }
                    if (data.name === 'Physician Patients') {
                        //await this.apiFunction(data.id);
                    }
                    /* if(data.name === 'Order Tracking'){
                    await this.apiFunction(data.id)
                }
                if(data.name === 'Order Tracking'){

                }
                if(data.name === 'Order Tracking'){

                } */
                }
            );
        }

        setTimeout(() => {
            this.setState({
                entirePageLoader: false,
            });
        }, 2000);

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        await this.getBranchDropDownData();
        if (
            this.props.match.params.menuName !== undefined &&
            this.props.match.params.menuName !== ''
        ) {
            await this.setState({ menuName: this.props.match.params.menuName });
        }
    }

    async componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.menuName !==
                this.props.match.params.menuName &&
            this.props.match.params.menuName !== undefined &&
            this.props.match.params.menuName !== ''
        ) {
            this.setState({ menuName: this.props.match.params.menuName });
        }

        let submenu = this.props.location.pathname.split('/');
        let lengthArray = submenu.length;
        if (submenu[lengthArray - 1] !== this.state.subMenu) {
            this.setState({
                customLoader: true,
            });

            this.setState({
                subMenu: submenu[lengthArray - 1],
            });
            this.props.configData.customSearchNamedIdsNeedingConfiguration.map(
                (data) => {
                    if (String(data.id) === String(submenu[lengthArray - 1])) {
                        configurationCustomeSearchId(data.id)
                            .then(async (data) => {
                                localStorage.setItem(
                                    'configData',
                                    JSON.stringify(data)
                                );
                                if (this.props.getConfigData !== undefined) {
                                    await this.props.getConfigData(data);
                                    // this.props.getConfigData()
                                }
                            })
                            .catch((error) => {
                                // Handle the encountered error
                                console.error(error);
                            });
                    }
                }
            );
            setTimeout(() => {
                this.setState({
                    customLoader: false,
                });
            }, 2000);
        }
    }
    getBranchDropDownData = () => {
        const fieldData = GetFieldData({ configData: this.props.configData });

        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('attachmenttype')) {
                getDropDownDataFromApi(fieldData.attachmenttype).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
        }
        if (this.props.configData.newreferral === undefined) {
            if (!this.props.ddl.hasOwnProperty('newreferral')) {
                getDropDownDataFromApi(fieldData.new_referral).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            }
        }
        if (this.props.configData.group === undefined) {
            if (!this.props.ddl.hasOwnProperty('group')) {
                getDropDownDataFromApi(fieldData.group).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            }
        }
        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('deliverymethod')) {
                getDropDownDataFromApi(fieldData.delivery_method).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
        }
    };
    goToPage = () => {
        // this.props.history.goBack();
        this.props.deletedPage({ onlySearch: false });
        this.props.history.goBack();
        // setTimeout(() => {
        //     if (this.props.related_doc_from !== 'bigview') {
        //         document.body.classList.add('aside-minimize');
        //         document.body.classList.add('doc-small-view');
        //     }

        //     if (this.props.related_doc_from === 'bigview') {
        //         document.body.classList.add('aside-minimize');
        //         this.props.inboxPage({ related_doc_from: '' });
        //     }
        // }, 1000);
    };
    render() {
        return (
            <>
                <div className='search-right-aside'>
                    {this.state.menuName === 'patients' &&
                    this.state.entirePageLoader === false ? (
                        <PatientSearch
                            updateState={(state) => {
                                this.setState({ ...state });
                            }}
                        />
                    ) : (
                        <SpinnerBlur show={this.state.entirePageLoader} />
                    )}
                    {this.state.menuName === 'physicians' ? (
                        <PhysicianSearch
                            updateState={(state) => {
                                this.setState({ ...state });
                            }}
                        />
                    ) : null}
                    {/*this.state.menuName === 'deleted-documents' ? (
                    <DeletedDocuments
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                    />
                    ) : null*/}
                    {/*this.state.menuName === 'removed-from-tracking' ? (
                    <RemovedDocuments
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                    />
                    ) : null*/}
                    {this.state.menuName === 'custom-search' &&
                    this.state.customLoader === false ? (
                        <RemovedDocuments
                            updateState={(state) => {
                                this.setState({ ...state });
                            }}
                        />
                    ) : (
                        <SpinnerBlur show={this.state.customLoader} />
                    )}
                </div>
                {this.props.deleted_page.onlySearch === true &&
                this.props.location.state &&
                this.props.location.state.selectedDocID ? (
                    <>
                        <div
                            id='right-aside'
                            className='right-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                        >
                            <PdfPreview
                                docId={window.atob(
                                    this.props.location.state.selectedDocID
                                )}
                            />
                        </div>
                        <div
                            id='left-aside'
                            className='expand-mode left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                        >
                            <DetailBigView
                                selectedDocId={window.atob(
                                    this.props.location.state.selectedDocID
                                )}
                                download={() => {
                                    download(
                                        window.atob(
                                            this.props.location.state
                                                .selectedDocID
                                        )
                                    );
                                }}
                                openDeletePopUp={() => {}}
                                statusArray={this.state.statusArray}
                                branches={this.state.branches}
                                groups={this.state.groups}
                                rowTransition={() => {
                                    this.rowTransition();
                                }}
                                menuGroup={this.state.menuGroup}
                                showBranch={this.state.show_branch}
                                showDelete={this.state.show_delete}
                                showDownload={this.state.show_download}
                                showGroup={this.state.show_group}
                                showInbox={this.state.show_inbox}
                                showNotes={this.state.show_notes}
                                showOwner={this.state.show_owner}
                                showPrint={this.state.show_print}
                                showPdf={this.state.show_pdf}
                                detailView
                                menuBranch={this.state.menuBranch}
                                menuInbox={this.state.menu_inbox}
                                goToPage={this.goToPage}
                                callingFrom='related_document'
                                processId={this.props.location.state.processId}
                                statusId={this.props.location.state.statusId}
                            />
                        </div>
                    </>
                ) : null}
                {this.props.deleted_page.onlySearch_outbox === true &&
                this.props.location.state &&
                this.props.location.state.selectedDocID ? (
                    <DocumentDetailViewComponent
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                    />
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
    deletedPage,
    getConfigData: getConfigData,
    getRecordSetConfig: getRecordSetConfig,
    getCustomSearchConfig: getCustomSearchConfig,
};
const mapStateToProps = (state) => {
    return {
        configData: state.configReducerPersist.configData,
        deleted_page: state.search.deletedPage,
        ddl: state.persistDDL.DDL,
    };
};

export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchPage))
);
