import { getConfig } from '../../../../_metronic/_helpers';
import { showErrorToast } from '../../../components/commonfunction';
import { relatedDoc } from '../../api/api';

export const fetchRecordsetData = async (recordSetName: string) => {
    try {
        const configData = getConfig();
        const configuredRecordsets =
            configData.length > 0 ? configData[0].configuredRecordsets : [];
        if (configuredRecordsets !== undefined) {
            const recordsetData = configuredRecordsets.find(
                (recordset: any) => recordset.name === recordSetName
            );

            if (recordsetData && recordsetData.recordsetId && recordsetData.name) {
                const response = await relatedDoc(recordsetData.recordsetId);

                if (!response) {
                    throw new Error(
                        `No response received for: ${recordsetData.name}`
                    );
                }

                const data = await response.json();

                return { data, headerColumns: data.dataTable.headerColumns };
            } else {
                showErrorToast(`No recordset found for ${recordSetName}`);
            }
        }
    } catch (error) {
        console.error('Error in fetching or processing data:', error);
        showErrorToast('Error while processing');
        throw new Error('Error while processing');
    }
};