import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { printPlugin } from '@react-pdf-viewer/print';
import { downloadDoc, downloadDocMultiple } from '../../api/api';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import {
    // showSuccessToast,
    showErrorToast,
    // stringEmptyOrUndefined,
    // GetFieldData,
} from '../../../components/commonfunction';
import { showLoader } from '../../_redux/actions/inboxActions';
// https://react-pdf-viewer.dev/examples/hide-unnecessary-elements-when-printing-a-document/

const PrintPdfDocument = (props) => {
    const printPluginInstance = printPlugin();
    // const { PrintButton } = printPluginInstance;
    const { Print } = printPluginInstance;
    const [base64, setBaseData] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch(showLoader(true));
        let documentPromise;
        if (props.docId.length === 1) {
            const docId = props.docId[0];
            documentPromise = downloadDoc(docId);
        } else if (props.docId.length > 1) {
            const docId = [];
            props.docId.forEach((val) => docId.push(`docId=${val}`));
            documentPromise = downloadDocMultiple(docId.join('&'));
        }
        let status = '';
        if (documentPromise !== undefined) {
            documentPromise
                .then((response) => {
                    status = response.status;
                    return response.blob();
                })
                .then((data) => {
                    if (status === 200) {
                        const reader = new FileReader();
                        reader.readAsDataURL(data);
                        reader.onloadend = function () {
                            const url = reader.result;
                            const encodeBase64 = url.split(',')[1];

                            setBaseData(encodeBase64);
                        };
                        // let  url = URL.createObjectURL(data)
                        // setBaseData(url);
                        dispatch(showLoader(true));
                    } else {
                        // props.loader();
                        // if( typeof props.toggleLoader === 'function' ){
                        // props.toggleLoader();
                        // }
                        dispatch(showLoader(false));
                        showErrorToast('No PDF to print');
                    }
                })
                .catch((error) => {
                    // props.loader(error);
                    dispatch(showLoader(false));
                });
        }
        // }, [props.docId,dispatch]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.docId]);
    if (props.docId.length === 0 || base64 === null) {
        return null;
    }
    const onReady = () => {
        dispatch(showLoader(false));
        printPluginInstance.print();
    };
    return (
        <>
            {base64 !== null ? (
                <div>
                    <Print>
                        {(props) => (
                            <span
                                onClick={props.onClick}
                                id='print-btn'
                                style={{}}
                            />
                        )}
                    </Print>
                    <div
                        style={{
                            flex: 1,
                        }}
                    >
                        <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js'>
                            <Viewer
                                // fileUrl={`${base64}`}
                                fileUrl={`data:application/pdf;base64,${base64}`}
                                plugins={[printPluginInstance]}
                                onDocumentLoad={onReady}
                                renderLoader={() => null}
                            />
                        </Worker>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default PrintPdfDocument;
