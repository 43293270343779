import { ThreeDotsMenu } from '../ThreeDotsMenu';

export const renderHeader = (props: any) => {
    const { column } = props;
    return <span className='rdg-header-sort-name auto-line-height'>{column.name}</span>;
};

export const actionColumn = (
    editRow: (id: string | number) => void,
    deleteRow: (id: string | number) => void
) => {
    return {
        key: 'menu',
        name: 'Menu',
        width: 70,
        fieldId: 0,
        datatype: 'action',
        headerRenderer: (props: any) => <div></div>,
        formatter: ({ row }: { row: any }) => (
            <ThreeDotsMenu
                id={row.id}
                onEdit={() => editRow(row.id)}
                onDelete={() => deleteRow(row.id)}
            />
        ),
    };
};

