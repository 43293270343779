/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import { Button } from 'react-bootstrap';
import * as moment from 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
    formatDate,
    GetFieldData,
    formatPathName,
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    stringEmptyOrUndefined,
    getDropDownDataFromApi,
    showErrorToast,
} from '../../../components/commonfunction';
import { getDDLlist } from '../../_redux/actions/configActions';
import FilterModal from './FilterModal';
import Spinner from '../../../components/Spinner';
import {
    updateSearchState,
    deletedPage,
} from '../../_redux/actions/searchActions';
import FilterDeleteModal from './FilterDeleteModal';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

const useStyles = (theme) => ({
    root: {
        /// width: 200,
        margin: 0,
        '& .MuiOutlinedInput-input': {
            color: '#00233A',
            padding: '0 !important',
        },
        '& .MuiInputLabel-root': {
            color: '#00233A',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E4E6EF',
        },
        '&:hover .MuiOutlinedInput-input': {
            color: '#00233A',
        },
        '&:hover .MuiInputLabel-root': {
            color: '#00233A',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E4E6EF',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#00233A',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#00233A',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                borderColor: '#E4E6EF',
                borderWidth: '1px',
            },
    },
});

export class FilterOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh: true,
            open_filter: false,
            branchOption: this.props.ddl.branch,
            operate_search: true,
            operate_filter: {},
            refreshFilter: false,
            is_validate: true,
            dateSelected: true,
            to_date: '',
            from_date: '',
            deleteQuery: false,
            open: false,
            inputValue: '',
            autocomplete: {},
            filterState: 'initial',
            mainFilter: false,
            autocompleteRefresh: false,
            toggleData: true,
            loading: false,
            previousSelectedFilterOptions: '',
        };
        this.loading = false;

        this.handleSelectOnChange = this.handleSelectOnChange.bind(this);
        this.handleComboOnChange = this.handleComboOnChange.bind(this);
        this.handleDateOnChange = this.handleDateOnChange.bind(this);
        this.handleFaxOnChange = this.handleFaxOnChange.bind(this);
        this.handleInputOnChange = this.handleInputOnChange.bind(this);
    }

    componentDidMount() {
        let pathname = this.props.location.pathname;
        pathname = formatPathName(pathname);
        this.setState({ refreshFilter: false });
        let filterOptions = localStorage.getItem(`filter-options-${pathname}`);
        if (String(filterOptions) !== 'undefined') {
            filterOptions = JSON.parse(filterOptions);
        }

        this.setState({
            editPath: this.props.location.pathname,
            pathName: this.props.location.pathname,
            filterOptions: filterOptions,
            autocomplete: {},
        });

        this.setState({
            filterState: 'initial',
        });
        if (
            this.props.location.pathname.includes('/edit') !== true &&
            this.state.pathName !== this.props.location.pathname
        ) {
            this.loadFilterOptions();
        }
    }
    async componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.operate_filter !== undefined &&
            prevState.operate_filter !== undefined &&
            prevProps.operate_filter !== prevState.operate_filter
        ) {
            await this.setState({
                operate_filter: prevProps.operate_filter,
                operate_search: true,
            });
        }

        if (
            Object.keys(this.state.operate_filter).length !== 0 &&
            this.state.operate_filter.from_date !== undefined &&
            this.state.from_date === ''
        ) {
            await this.setState({
                from_date: formatDate(
                    this.state.operate_filter.from_date,
                    'YYYY-MM-DD'
                ),
                to_date: formatDate(
                    this.state.operate_filter.to_date,
                    'YYYY-MM-DD'
                ),
            });
            for (const property in this.state.operate_filter) {
                await this.setState({
                    [property]: this.state.operate_filter[property],
                });
            }
        }

        if (
            this.props.location.pathname.includes('/edit') !== true &&
            this.state.pathName !== this.props.location.pathname
        ) {
            await this.setState({
                refreshFilter: true,
                pathName: this.props.location.pathname,
                dateSelected: true,
                is_validate: true,
                show_result: false,
                to_date: '',
                from_date: '',
                filterState: 'initial',
                operate_filter: {},
                autocomplete: {},
                autocompleteRefresh: true,
            });
            setTimeout(
                () =>
                    this.setState({
                        refreshFilter: false,
                        autocompleteRefresh: false,
                    }),
                100
            );
            await this.loadFilterOptions();

            if (this.state.to_date !== '' && this.state.from_date !== '') {
                await this.setState({
                    operate_filter: {
                        from_date: formatDate(
                            this.state.from_date,
                            'YYYY-MM-DD'
                        ),
                        to_date: formatDate(this.state.to_date, 'YYYY-MM-DD'),
                    },
                });
            } else {
                await this.setState({
                    operate_filter: {},
                });
            }
        }

        const currentPath = this.props.location.pathname;
        let pathName = formatPathName(currentPath);
        let filterOptions = localStorage.getItem(`filter-options-${pathName}`);
        if (String(filterOptions) !== 'undefined') {
            filterOptions = JSON.parse(filterOptions);
        }

        if (
            filterOptions !== this.state.filterOptions &&
            this.state.mainFilter === false
        ) {
            this.setState({
                filterOptions: filterOptions,
                mainFilter: true,
            });
            let tempFilter = {};
            for (const data in this.state.operate_filter) {
                for (const property in filterOptions) {
                    let nameFilter = formatNameWithSpaceToUnderscore(
                        filterOptions[property]
                    );

                    if (
                        data === nameFilter ||
                        data === 'to_date' ||
                        data === 'from_date'
                    ) {
                        tempFilter = {
                            ...tempFilter,
                            [data]: this.state.operate_filter[data],
                        };
                    }
                }
            }
            let autocompleteTemp = {};
            for (const data in this.state.autocomplete) {
                for (const property in filterOptions) {
                    if (data === property) {
                        autocompleteTemp = {
                            ...autocompleteTemp,
                            [data]: this.state.autocomplete[data],
                        };
                    }
                }
            }

            await this.setState({
                operate_filter: tempFilter,
                operate_search: false,
                autocomplete: autocompleteTemp,
            });
            this.props.updateState({
                operate_search: false,
                operate_filter: tempFilter,
                autocomplete: autocompleteTemp,
            });
        }

        const current_path = this.props.location.pathname;
        let pathname = formatPathName(current_path);
        const selectedFilterOptions = localStorage.getItem(
            `filter-options-${pathname}`
        );
        if (selectedFilterOptions && selectedFilterOptions !== 'undefined') {
            const parsedSelectedFilterOptions = JSON.parse(
                selectedFilterOptions
            );
            const { previousSelectedFilterOptions } = this.state;

            if (
                JSON.stringify(parsedSelectedFilterOptions) !==
                JSON.stringify(previousSelectedFilterOptions)
            ) {
                this.setState({
                    loading: true,
                });
                try {
                    const allFieldsId = this.props.configData.fields;
                    for (const property in parsedSelectedFilterOptions) {
                        allFieldsId.forEach(async (data) => {
                            if (
                                data.name ===
                                    parsedSelectedFilterOptions[property] &&
                                data.hasDropdown === true
                            ) {
                                const option = formatHeaderNameWithOutSpace(
                                    data.name
                                );
                                const passingName =
                                    formatNameWithSpaceToUnderscore(data.name);
                                if (
                                    this.props.ddl[option] === undefined &&
                                    this.state.filterState !== 'initial'
                                ) {
                                    await this.getBranchDropDownData(
                                        passingName
                                    );
                                }
                            }
                        });
                    }
                } finally {
                    this.setState({
                        loading: false,
                    });
                }

                this.setState({
                    previousSelectedFilterOptions: parsedSelectedFilterOptions,
                });
            }
        }
    }

    loadFilterOptions = async () => {
        const current_path = this.props.location.pathname;
        let pathname = formatPathName(current_path);
        let selectedFilterOptions = localStorage.getItem(
            `filter-options-${pathname}`
        );
        if (
            selectedFilterOptions !== '' &&
            selectedFilterOptions !== null &&
            selectedFilterOptions !== undefined &&
            String(selectedFilterOptions) !== 'undefined'
        ) {
            let selectedFilters = JSON.parse(selectedFilterOptions);
            let allFieldsId = this.props.configData.fields;

            for (const property in selectedFilters) {
                for (const data of allFieldsId) {
                    if (
                        data.name === selectedFilters[property] &&
                        data.hasDropdown === true
                    ) {
                        let option = formatHeaderNameWithOutSpace(data.name);
                        let passingName = formatNameWithSpaceToUnderscore(
                            data.name
                        );
                        if (this.props.ddl[option] === undefined) {
                            await this.getBranchDropDownData(passingName);
                        }
                    }
                }
            }
        }
    };

    getBranchDropDownData = async (name) => {
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (
            stringEmptyOrUndefined(fieldData) &&
            !this.props.ddl.hasOwnProperty(name)
        ) {
            try {
                const data = await getDropDownDataFromApi(fieldData[name]);
                if (data.values && data.values.length > 0) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                } else {
                    showErrorToast(
                        'An error occurred while fetching the ' +
                            formatNameWithSpaceToUnderscore(name) +
                            ' dropdown data'
                    );
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    handleInputOnChange = (state, event) => {
        const value = event.target.value;

        this.setState({
            operate_search: false,
            [state]: value,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: value,
            },
        });
        this.props.updateState({
            operate_search: false,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: value,
            },
            [state]: value,
        });
    };
    handleFaxOnChange = async (state, event) => {
        let value = event.target.value;
        value = value.replace(/-/g, '');

        this.setState({ fax_number: value });
        await this.setState({
            operate_search: false,
            [state]: event.target.value,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: value,
            },
        });
        await this.props.updateState({
            operate_search: false,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: value,
            },
            [state]: value,
        });
        await this.setState({
            faxError: false,
        });
    };
    handleSelectOnChange = (state, event) => {
        this.setState({
            operate_search: false,
            [state]: event.target.value,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: event.target.value,
            },
        });
        this.props.updateState({
            operate_search: false,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: event.target.value,
            },
            [state]: event.target.value,
        });
    };

    handleComboOnChange = (state, value) => {
        this.setState({
            operate_search: false,
            [state]: value,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: value,
            },
        });
        this.props.updateState({
            operate_search: false,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: value,
            },
            [state]: value,
        });
    };

    handleDateOnChange = async (state, date) => {
        let dateFill = formatDate(date, 'YYYY-MM-DD');

        await this.setState({
            operate_search: false,
            [state]: date,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: formatDate(date, 'YYYY-MM-DD'),
            },
        });
        await this.props.updateState({
            operate_search: false,
            operate_filter: {
                ...this.state.operate_filter,
                [state]: dateFill,
            },
            [state]: dateFill,
        });
    };
    handleRemoveFilter = (state, property) => {
        this.setState({
            deleteQuery: true,
            stateRemoveFilter: state,
            propertyRemoveFilter: property,
        });
    };
    handleRemoveFilterConfirm = async (state, property) => {
        const filterRemove = formatNameWithSpaceToUnderscore(state);
        // clear date at remove filter
        this.handleDateOnChange(filterRemove, '');
        const autoCompleteRemove =
            formatNameWithSpaceToUnderscore(state) + '_temp';
        let tempFilter = {};
        for (const data in this.state.operate_filter) {
            if (data !== filterRemove) {
                tempFilter = {
                    ...tempFilter,
                    [data]: this.state.operate_filter[data],
                };
            }
        }
        let autocompleteTemp = {};
        for (const property in this.state.autocomplete) {
            if (property !== autoCompleteRemove) {
                autocompleteTemp = {
                    ...autocompleteTemp,
                    [property]: this.state.autocomplete[property],
                };
            }
        }
        await this.setState({
            operate_search: false,
            operate_filter: tempFilter,
            autocomplete: autocompleteTemp,
        });
        await this.props.updateState({
            operate_search: false,
            operate_filter: tempFilter,
            autocomplete: autocompleteTemp,
        });
        let pathname = this.props.location.pathname;
        pathname = formatPathName(pathname);
        let pastItems = localStorage.getItem(`filter-options-${pathname}`);
        pastItems = JSON.parse(pastItems);
        let presentItem;
        for (const index in pastItems) {
            if (JSON.stringify(property) !== JSON.stringify(index)) {
                presentItem = {
                    ...presentItem,
                    [index]: pastItems[index],
                };
            }
        }
        await localStorage.setItem(
            `filter-options-${pathname}`,
            JSON.stringify(presentItem)
        );
        await this.setState({
            refreshFilter: true,
            deleteQuery: false,
            stateRemoveFilter: '',
            propertyRemoveFilter: '',
        });
        setTimeout(() => this.setState({ refreshFilter: false }), 100);
    };

    setFromDate = (date) => {
        var validation = false;
        if (this.state.to_date !== '' && this.state.to_date !== undefined) {
            let A = moment(date);
            let B = moment(this.state.to_date);
            let diff_days = B.diff(A, 'days');

            if (diff_days < 0) {
                validation = true;
                this.setState({
                    is_validate: false,
                    from_date: date,
                    operate_search: true,
                });
                //return false;
            }
        }

        if (!validation) {
            this.setState({
                is_validate: true,
                operate_search: false,
                from_date: date,
                operate_filter: {
                    ...this.state.operate_filter,
                    from_date: formatDate(date, 'YYYY-MM-DD'),
                },
            });

            this.props.updateState({
                operate_search: false,
                operate_filter: {
                    ...this.state.operate_filter,
                    from_date: formatDate(date, 'YYYY-MM-DD'),
                },
            });
        }
    };

    setToDate = (date) => {
        var validation = false;
        if (this.state.from_date !== '' && this.state.from_date !== undefined) {
            let A = moment(this.state.from_date);
            let B = moment(date);
            let diff_days = B.diff(A, 'days');
            if (diff_days < 0) {
                validation = true;
                this.setState({
                    is_validate: false,
                    to_date: date,
                    operate_search: true,
                });
                return false;
            }
        }

        if (!validation) {
            this.setState({
                is_validate: true,
                operate_search: false,
                to_date: date,
                operate_filter: {
                    ...this.state.operate_filter,
                    to_date: formatDate(date, 'YYYY-MM-DD'),
                },
            });

            this.props.updateState({
                operate_search: false,
                operate_filter: {
                    ...this.state.operate_filter,
                    to_date: formatDate(date, 'YYYY-MM-DD'),
                },
            });
        }
    };

    openEditfilter = () => {
        this.setState({ open_filter: true });
    };

    clearfilter = () => {
        // Get state name of the Data filter and clear Start
        const current_path = this.props.location.pathname;
        let pathname = formatPathName(current_path);
        let selectedFilterOptions = localStorage.getItem(
            `filter-options-${pathname}`
        );

        if (
            selectedFilterOptions !== '' &&
            selectedFilterOptions !== null &&
            selectedFilterOptions !== undefined &&
            String(selectedFilterOptions) !== 'undefined'
        ) {
            let selectedFilters = JSON.parse(selectedFilterOptions);
            let allFieldsId = this.props.configData.fields;
            for (const property in selectedFilters) {
                allFieldsId.map((data, _index) => {
                    if (
                        data.name === selectedFilters[property] &&
                        data.datatype === 'Date'
                    ) {
                        let stateVal = formatNameWithSpaceToUnderscore(
                            data.name
                        );

                        this.handleDateOnChange(stateVal, '');
                    }
                });
            }
        }
        // Get state name of the Data filter and clear End

        this.setState({
            operate_search: false,
            to_date: '',
            from_date: '',
            branch: '',
            operate_filter: {},
            autocomplete: {},
            autocompleteRefresh: true,
        });
        this.props.updateState({
            operate_search: false,
            operate_filter: {},
        });
        setTimeout(() => {
            this.setState({
                autocompleteRefresh: false,
            });
        }, 100);
    };

    performSearch = async () => {
        await this.props.deletedPage({
            refreshTab: true,
        });

        setTimeout(() => {
            let newFilter = {};
            for (const property in this.state.operate_filter) {
                if (
                    property === 'fax_number' &&
                    this.state.operate_filter[property] !== undefined &&
                    String(this.state.operate_filter[property]) !==
                        '___-___-____'
                ) {
                    const pattern = /^\d{10}$/;
                    const format =
                        this.state.operate_filter[property].match(pattern);
                    if (format === null) {
                        this.setState({
                            faxError: true,
                        });
                        return false;
                    }
                }
            }
            for (const property in this.state.operate_filter) {
                if (
                    String(this.state.operate_filter[property]) !==
                    '___-___-____'
                ) {
                    newFilter = {
                        ...newFilter,
                        [property]: this.state.operate_filter[property],
                    };
                }
            }
            if (Object.keys(newFilter).length !== 0) {
                this.setState({
                    operate_filter: newFilter,
                });
                this.props.updateState({
                    operate_filter: newFilter,
                });
            }

            if (
                this.state.from_date === '' ||
                this.state.to_date === '' ||
                this.state.to_date === undefined ||
                this.state.from_date === undefined ||
                this.state.from_date === null ||
                this.state.to_date === null
            ) {
                this.setState({
                    dateSelected: false,
                });
            } else {
                this.setState({
                    refreshFilter: true,
                });
                if (
                    document.body.classList.contains('doc-small-view') ||
                    document.body.classList.contains('doc-detail-view')
                ) {
                    document.body.classList.remove('aside-minimize');
                    document.body.classList.remove('doc-small-view');
                    document.body.classList.remove('doc-detail-view');

                    this.props.updateSearchState({
                        deletedPage: {
                            ...this.props.search.deletedPage,
                            row_transition: false,
                        },
                    });
                }
                this.setState({
                    operate_search: true,
                    dateSelected: true,
                    //deleteBigView:false
                    refreshFilter: false,
                });
                this.props.updateState({
                    operate_search: true,
                    show_result: true,

                    operate_filter: this.state.operate_filter,
                });
            }
        }, 1000);
    };

    updateState = (state) => {
        this.setState({ ...state });
    };

    buildFilterOptions() {
        const current_path = this.props.location.pathname;
        let pathname = formatPathName(current_path);
        let selectedFilterOptions = localStorage.getItem(
            `filter-options-${pathname}`
        );

        if (
            selectedFilterOptions !== '' &&
            selectedFilterOptions !== null &&
            selectedFilterOptions !== undefined &&
            String(selectedFilterOptions) !== 'undefined'
        ) {
            let selectedFilters = JSON.parse(selectedFilterOptions);
            let allFieldsId = this.props.configData.fields;
            let loopData = [];
            let dateLoop = [];
            let otherLoop = [];
            let faxLoop = [];
            const { classes } = this.props;

            for (const property in selectedFilters) {
                allFieldsId.map((data, _index) => {
                    if (
                        data.name === selectedFilters[property] &&
                        data.hasDropdown === true
                    ) {
                        let option = formatHeaderNameWithOutSpace(data.name);

                        let stateVal = formatNameWithSpaceToUnderscore(
                            data.name
                        );

                        var tempstate =
                            formatNameWithSpaceToUnderscore(data.name) +
                            '_temp';
                        loopData.push(
                            <div
                                className='col-xl-4 col-sm-6 mt-5 transition-select'
                                key={_index}
                            >
                                <label className='pl-5'>{data.name}</label>
                                <div
                                    className='remove-link'
                                    title='Remove this filter'
                                    onClick={() => {
                                        this.handleRemoveFilter(
                                            data.name,
                                            property
                                        );
                                    }}
                                >
                                    <i className='fa-regular fa-trash-can'></i>
                                    <span>Remove this filter</span>
                                </div>
                                {data.name !== 'Ordering Physician kalpana' ? (
                                    <>
                                        {this.state.autocompleteRefresh ===
                                            false && (
                                            <Autocomplete
                                                freeSolo
                                                key={'ordering_' + tempstate}
                                                //autoSelect
                                                disableClearable
                                                id={
                                                    'free-solo-2-demo-' +
                                                    tempstate
                                                }
                                                inputValue={
                                                    this.state.autocomplete[
                                                        tempstate
                                                    ]
                                                }
                                                onChange={(newValue) => {
                                                    if (
                                                        newValue !== null &&
                                                        newValue !== '' &&
                                                        newValue !== undefined
                                                    ) {
                                                        this.setState({
                                                            autocomplete: {
                                                                ...this.state
                                                                    .autocomplete,
                                                                [tempstate]:
                                                                    newValue,
                                                            },
                                                        });
                                                    }
                                                }}
                                                onInputChange={(
                                                    e,
                                                    value,
                                                    reason
                                                ) => {
                                                    if (
                                                        value !== null &&
                                                        value !== ''
                                                    ) {
                                                        this.setState({
                                                            autocomplete: {
                                                                ...this.state
                                                                    .autocomplete,
                                                                [tempstate]:
                                                                    value,
                                                            },
                                                        });

                                                        this.handleComboOnChange(
                                                            formatNameWithSpaceToUnderscore(
                                                                data.name
                                                            ),
                                                            value
                                                        );
                                                    }
                                                }}
                                                options={
                                                    this.props.ddl[option] !==
                                                        '' &&
                                                    this.props.ddl[option] !==
                                                        undefined
                                                        ? this.props.ddl[
                                                              option
                                                          ].filter(
                                                              (element) => {
                                                                  if (
                                                                      element !==
                                                                      undefined
                                                                  ) {
                                                                      return (
                                                                          element !==
                                                                          ''
                                                                      );
                                                                  }
                                                              }
                                                          )
                                                        : []
                                                }
                                                //style={{ width: 300 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className={classes.root}
                                                        placeholder='Select'
                                                        //label="Select"
                                                        variant='outlined'
                                                        margin='normal'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <select
                                            id='delete_selectbrand'
                                            className='form-control'
                                            onChange={(event) => {
                                                this.handleSelectOnChange(
                                                    formatNameWithSpaceToUnderscore(
                                                        data.name
                                                    ),
                                                    event
                                                );
                                            }}
                                            value={
                                                this.state.operate_filter[
                                                    stateVal
                                                ]
                                                    ? this.state.operate_filter[
                                                          stateVal
                                                      ]
                                                    : ''
                                            }
                                        >
                                            <option value=''>Select </option>
                                            {this.props.ddl[option] !==
                                            undefined
                                                ? this.props.ddl[option].map(
                                                      (data, index) =>
                                                          data !== '' ? (
                                                              <option
                                                                  key={index}
                                                                  value={data}
                                                                  style={{
                                                                      display:
                                                                          data !==
                                                                          ''
                                                                              ? 'block'
                                                                              : 'none',
                                                                  }}
                                                              >
                                                                  {data}
                                                              </option>
                                                          ) : null
                                                  )
                                                : null}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                    </>
                                )}
                            </div>
                        );
                    } else if (
                        data.name === selectedFilters[property] &&
                        data.datatype === 'Date'
                    ) {
                        let stateVal = formatNameWithSpaceToUnderscore(
                            data.name
                        );
                        dateLoop.push(
                            <div
                                className='col-xl-4 col-sm-6 mt-5 transition-select'
                                key={_index}
                            >
                                <label className='pl-5'>{data.name}</label>
                                <div
                                    className='remove-link'
                                    title='Remove this filter'
                                    onClick={(event) => {
                                        this.handleRemoveFilter(
                                            data.name,
                                            property
                                        );
                                    }}
                                >
                                    <i className='fa-regular fa-trash-can'></i>
                                    <span>Remove this filter</span>
                                </div>
                                <div className='date-picker position-relative form-control'>
                                    <DatePicker
                                        onChange={(date) => {
                                            this.handleDateOnChange(
                                                stateVal,
                                                date
                                            );
                                        }}
                                        value={
                                            this.state[stateVal]
                                                ? this.state[stateVal]
                                                : ''
                                        }
                                        className='date-picker'
                                        format='MM/dd/y'
                                    />
                                </div>
                            </div>
                        );
                    } else if (
                        data.name === selectedFilters[property] &&
                        data.name === 'Fax Number' &&
                        data.datatype !== 'Date' &&
                        data.hasDropdown === false
                    ) {
                        let stateVal = formatNameWithSpaceToUnderscore(
                            data.name
                        );
                        faxLoop.push(
                            <div
                                className='col-xl-4 col-sm-6 mt-5 transition-select'
                                key={_index}
                            >
                                <label className='pl-5'>{data.name}</label>
                                <div
                                    className='remove-link'
                                    title='Remove this filter'
                                    onClick={(event) => {
                                        this.handleRemoveFilter(
                                            data.name,
                                            property
                                        );
                                    }}
                                >
                                    <i className='fa-regular fa-trash-can'></i>
                                    <span>Remove this filter</span>
                                </div>
                                <div className='position-relative'>
                                    <NumberFormat
                                        className={
                                            this.state.faxError
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={
                                            this.state.fax_number
                                                ? this.state.fax_number
                                                : ''
                                        }
                                        format='###-###-####'
                                        allowEmptyFormatting
                                        mask='_'
                                        //maxLenth={14}
                                        onChange={(event) => {
                                            this.handleFaxOnChange(
                                                stateVal,
                                                event
                                            );
                                        }}
                                    />
                                </div>

                                <i className='fa-regular fa-magnifying-glass'></i>
                                {this.state.faxError === true && (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Incorrect fax number
                                    </p>
                                )}
                            </div>
                        );
                    } else if (
                        data.name === selectedFilters[property] &&
                        data.name !== 'Fax Number' &&
                        data.datatype !== 'Date' &&
                        data.hasDropdown === false
                    ) {
                        let stateVal = formatNameWithSpaceToUnderscore(
                            data.name
                        );
                        otherLoop.push(
                            <div
                                className='col-xl-4 col-sm-6 mt-5 transition-select'
                                key={_index}
                            >
                                <label className='pl-5'>{data.name}</label>
                                <div
                                    className='remove-link'
                                    title='Remove this filter'
                                    onClick={(event) => {
                                        this.handleRemoveFilter(
                                            data.name,
                                            property
                                        );
                                    }}
                                >
                                    <i className='fa-regular fa-trash-can'></i>
                                    <span>Remove this filter</span>
                                </div>
                                <div className='position-relative'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        value={
                                            this.state.operate_filter[stateVal]
                                                ? this.state.operate_filter[
                                                      stateVal
                                                  ]
                                                : ''
                                        }
                                        onChange={(event) => {
                                            this.handleInputOnChange(
                                                stateVal,
                                                event
                                            );
                                        }}
                                    />
                                </div>
                                <i className='fa-regular fa-magnifying-glass'></i>
                            </div>
                        );
                    }
                });
            }
            const entireLoop = loopData.concat(dateLoop, otherLoop, faxLoop);

            return (
                <>
                    {this.state.loading ? (
                        <div className='col text-center'>
                            <Spinner show={this.state.loading} />
                        </div>
                    ) : (
                        <div className='Entire-Loop'>{entireLoop}</div>
                    )}
                </>
            );
        }
    }

    toggleTitle = () => {
        this.setState({
            toggleData: !this.state.toggleData,
        });
    };
    // let classes = useStyles();
    render() {
        return (
            <>
                <div className='wrap_deleted_document wrap_details p-4 pb-lg-0 my-0'>
                    <div className='details_part'>
                        <div
                            className={
                                this.state.toggleData
                                    ? 'details_fun detail_title_mobile'
                                    : 'details_fun detail_title_mobile mb-0'
                            }
                        >
                            <div className='row'>
                                <div className='col'>
                                    <h3 className='m-0 title-color'>
                                        {this.props.pageTitle}
                                    </h3>
                                </div>
                                {/* <i
                                    title='Open'
                                    className='fa fa-chevron-down mr-4'
                                    aria-hidden='true'
                                ></i> */}
                                <div
                                    className='dropdown_icon mr-4'
                                    onClick={() => this.toggleTitle()}
                                >
                                    <i
                                        title={
                                            this.state.toggleData
                                                ? 'Close'
                                                : 'Open'
                                        }
                                        className={
                                            this.state.toggleData
                                                ? 'fa fa-chevron-up'
                                                : 'fa fa-chevron-down'
                                        }
                                        aria-hidden='true'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex details_part step2 mb-3 align-items-stretch justify-content-between'>
                        <div className='details_fun'>
                            <h3 className='m-0 title-color'>
                                {this.props.pageTitle}
                            </h3>
                        </div>
                        {this.state.toggleData ? (
                            <div className='edit_details notes d-flex justify-content-between'>
                                <button
                                    title='Clear'
                                    className='clear cancel-bg-btn'
                                    onClick={this.clearfilter}
                                    disabled={
                                        Object.keys(this.state.operate_filter)
                                            .length === 0
                                            ? true
                                            : false
                                    }
                                >
                                    <i className='fas fa-broom'></i>
                                    Clear
                                </button>
                                <Button
                                    title='Edit Filters'
                                    variant='primary'
                                    className='mx-3'
                                    onClick={this.openEditfilter}
                                >
                                    <i className='fa-regular fa-sliders mr-2'></i>
                                    Edit Filters
                                </Button>
                                <div>
                                    <Button
                                        className='search-button'
                                        title='Search'
                                        variant='success'
                                        onClick={this.performSearch}
                                        disabled={
                                            this.state.is_validate === false
                                                ? true
                                                : false
                                        }
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {this.state.toggleData ? (
                        <div className='row grid-row-group'>
                            <div className='d-flex d-md-block col-xl-12 mb-4'>
                                <div className='col-xl-4 col-sm-6 transition-select'>
                                    <label className='pl-5'>FROM DATE *</label>
                                    <div
                                        className={
                                            (this.state.dateSelected ===
                                                false &&
                                                (this.state.from_date === '' ||
                                                    this.state.from_date ===
                                                        null)) ||
                                            !this.state.is_validate
                                                ? 'date-picker position-relative form-control error-field'
                                                : 'date-picker position-relative form-control'
                                        }
                                    >
                                        <DatePicker
                                            onChange={(date) => {
                                                this.setFromDate(date);
                                            }}
                                            value={this.state.from_date}
                                            className='date-picker'
                                            format='MM/dd/y'
                                        />
                                    </div>
                                    {!this.state.is_validate ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Please check From and to Date.
                                        </p>
                                    ) : null}
                                    {this.state.dateSelected === false &&
                                    (this.state.from_date === '' ||
                                        this.state.from_date === null) ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            From Date is required.
                                        </p>
                                    ) : null}
                                </div>

                                <div className='col-xl-4 col-sm-6 transition-select'>
                                    <label className='pl-5'>TO DATE *</label>
                                    <div
                                        className={
                                            this.state.dateSelected === false &&
                                            (this.state.to_date === '' ||
                                                this.state.to_date === null)
                                                ? 'date-picker position-relative form-control error-field'
                                                : 'date-picker position-relative form-control'
                                        }
                                    >
                                        <DatePicker
                                            onChange={(date) => {
                                                this.setToDate(date);
                                            }}
                                            value={this.state.to_date}
                                            placeholder='To Date'
                                            className='date-picker'
                                            format='MM/dd/y'
                                        />
                                    </div>
                                    {this.state.dateSelected === false &&
                                    (this.state.to_date === '' ||
                                        this.state.to_date === null) ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            To Date is required.
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-xl-12 mb-4'>
                                {this.buildFilterOptions()}
                            </div>
                        </div>
                    ) : null}
                </div>
                {this.state.refreshFilter === false && (
                    <FilterModal
                        open_filter={this.state.open_filter}
                        custom_search_name={this.props.custom_search_name}
                        pageTitle={this.props.pageTitle}
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        mainFilter={this.state.mainFilter}
                    />
                )}
                {this.state.deleteQuery && (
                    <FilterDeleteModal
                        deleteQuery={this.state.deleteQuery}
                        stateRemoveFilter={this.state.stateRemoveFilter}
                        propertyRemoveFilter={this.state.propertyRemoveFilter}
                        handleRemoveFilterConfirm={(state, property) =>
                            this.handleRemoveFilterConfirm(state, property)
                        }
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        filterState={this.state.filterState}
                    />
                )}
            </>
        );
    }
}
const mapDispatchToProps = {
    getDDLlist: getDDLlist,
    updateSearchState,
    deletedPage,
};
const mapStateToProps = (state, _ownProps) => {
    return {
        search: state.search,
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        rulesJson: state.persist.rulesJson,
        deleted_page: state.search.deletedPage,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.outbox.selectedRowDetails,
        ddl: state.persistDDL.DDL,
        currentMenuName: state.persist.currentMenuName,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(useStyles),
    withRouter
)(FilterOptions);
