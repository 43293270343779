import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ResultsData {
    NumInputRecords?: string;
    NumPhysiciansSupplied?: string;
    NumPhysiciansInserted?: string;
    NumPhysiciansUpdated?: string;
    NumPhysiciansDeactivated?: string;
    NumAddressesSupplied?: string;
    NumAddressesInserted?: string;
    NumAddressesUpdated?: string;
    NumAddressesDeactivated?: string;
    NumCoordinatorsSupplied?: string;
    NumCoordinatorsInserted?: string;
    NumCoordinatorsUpdated?: string;
    NumCoordinatorsDeactivated?: string;
    errors?: string;
}

interface SuccessUploadModalProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    uploadResults: ResultsData;
}

const SuccessUploadModal: React.FC<SuccessUploadModalProps> = ({ showModal, setShowModal, uploadResults }) => {
    const handleClose = () => {
        setShowModal(false);
    };

    const renderProperty = (label: string, value?: number | string) => {
        return (
            <div style={{ display: "flex", color: "#181C32", fontWeight: 600 }}>
                <p style={{ minWidth: "250px" }}>{label}: </p> <p>{value ?? 0}</p>
            </div>
        )
    };

    return (
        <Modal
            className='import-popup'
            show={showModal}
            onHide={handleClose}
            animation={false}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header closeButton className='d-block justify-content-start'>
                <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                    <div>Bulk Import Results</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {renderProperty('Physicians added', uploadResults?.NumPhysiciansInserted)}
                    {renderProperty('Physicians updated', uploadResults?.NumPhysiciansUpdated)}

                    {renderProperty('Locations added', uploadResults?.NumAddressesInserted)}
                    {renderProperty('Locations updated', uploadResults?.NumAddressesUpdated)}

                    {renderProperty('Admission Coordinators added', uploadResults?.NumCoordinatorsInserted)}
                    {renderProperty('Admission Coordinators updated', uploadResults?.NumCoordinatorsUpdated)}
                </div>
            </Modal.Body>
            <Modal.Footer className='d-block'>
                <div className='d-flex footer-btn align-items-stretch justify-content-end'>
                    <Button variant='success' onClick={handleClose}>
                        OK
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessUploadModal;
