import React from 'react';
// import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { updateRecordSet, recordSetSearch } from '../../pages/api/api';
import {
    formatHeaderNameWithOutSpace,
    formatDate,
    normalSuccessToast,
    normalErrorToast,
    EpisodeSearch,
    formatNameWithSpaceToUnderscore,
    BranchIdSearch,
} from '../commonfunction';
// import TextField from '@material-ui/core/TextField';
import { SpinnerBlur } from '../../pages/outbox/components/Spinner';

export class ViewEditPatient extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            first_name: this.props.selectedPatient.first_name,
            last_name: this.props.selectedPatient.last_name,
            dob: new Date(),
            date_of_birth: this.props.selectedPatient.date_of_birth,
            gender: this.props.selectedPatient.gender,
            branch: this.props.selectedPatient.branch,
            patient_id: this.props.selectedPatient.patient_id,
            order_number: this.props.selectedPatient.order_number,
            order_date: new Date(),

            validateEdit: false,
            editPatientData: {
                first_name: '',
                last_name: '',
                dob: '',
                date_of_birth: '',
                gender: '',
                branch: '',
                service_line: '',
            },
            todaysDate: new Date(),
            blur_spinner: false,
            open_edit_patient: this.props.open_edit_patient,
            patient_form_apperance: this.props.patient_form_apperance,
            edit_patient: this.props.editPatient,
        };
    }

    async componentDidMount() {
        this._isMounted = true;

        if (this._isMounted) {
            this.configuredRecordsets();

            if (this.props.selectedPatient.hasOwnProperty('id')) {
                this.setState({ id: this.props.selectedPatient.id });
            }

            if (this.props.selectedPatient.hasOwnProperty('service_line')) {
                this.setState({
                    service_line:
                        this.props.selectedPatient.service_line.toUpperCase(),
                });
            }

            const todaysDate = formatDate(this.state.todaysDate, 'YYYY-MM-DD');
            this.setState({ todaysDate: todaysDate });

            if (
                this.props.selectedPatient.hasOwnProperty('date_of_birth') &&
                this.props.selectedPatient.date_of_birth !== ''
            ) {
                this.setState({
                    dob: new Date(this.props.selectedPatient.date_of_birth),
                    date_of_birth: formatDate(
                        this.props.selectedPatient.date_of_birth,
                        'MM/DD/YYYY'
                    ),
                });
            }
            if (
                this.props.selectedPatient.hasOwnProperty('order_date') &&
                this.props.selectedPatient.order_date !== ''
            ) {
                this.setState({
                    order_date: new Date(this.props.selectedPatient.order_date),
                    // : formatDate(
                    //  this.props.selectedPatient.date_of_birth,
                    // 'MM/DD/YYYY'
                    // ),
                });
            }

            if (this.props.selectedPatient.hasOwnProperty('patient_name')) {
                const split = this.props.selectedPatient.patient_name
                    .trim()
                    .split(',');
                const name = { last_name: split[0], first_name: split[1] };
                // this.setState({ ...name });
                this.setState({
                    ...name,
                    editPatientData: { ...this.props.selectedPatient, ...name },
                });
            }

            const selectedPatient = {
                ...this.state.editPatientData,
                ...this.props.selectedPatient,
            };

            this.setState({ editPatientData: selectedPatient });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    configuredRecordsets = async () => {
        let configuredRecordsets = [];
        let patientFieldIdArray;
        let modifyPatientFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    patientFieldIdArray = data.fields;
                    patientRecordsetId = data.recordsetId;
                    patientModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    modifyPatientFieldIdArray = data.fields;
                    modifyPatientRecordsetId = data.recordsetId;
                    modifyPatientModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }

                return data;
            });
        }

        this.setState({
            patientModifiableRecordsetId: patientModifiableRecordsetId,
            patientFieldIdArray: patientFieldIdArray,
            patientRecordsetId: patientRecordsetId,
            modifyPatientFieldIdArray: modifyPatientFieldIdArray,
            modifyPatientRecordsetId: modifyPatientRecordsetId,
            modifyPatientModifiableRecordsetId:
                modifyPatientModifiableRecordsetId,
            edit_details: false,
        });
    };

    updateState = (state) => {
        if (typeof this.props.updateState === 'function') {
            this.props.updateState(state);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editPatient !== this.props.editPatient) {
            this.setState({ edit_patient: this.props.editPatient });
        }

        if (prevProps.submitEditPatient !== this.props.submitEditPatient) {
            if (this.props.submitEditPatient === true) {
                this.submitUpdatePatient();
            }
        }
        if (this.props.cancelSearch === true) {
            this.props.updateState({
                cancelSearch: false,
            });
            this.setState({
                id: this.props.selectedPatient.id,
                first_name: this.props.selectedPatient.first_name,
                last_name: this.props.selectedPatient.last_name,
                dob: new Date(),
                date_of_birth: this.props.selectedPatient.date_of_birth,
                gender: this.props.selectedPatient.gender,
                branch: this.props.selectedPatient.branch,
                patient_id: this.props.selectedPatient.patient_id,
                editPatientData: this.props.selectedPatient,
            });
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    handleSelectOnChange = (state, event) => {
        this.setState({ [state]: event.target.value });
        const editPatientData = {
            ...this.state.editPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        this.setState({ editPatientData: editPatientData });
        if (this.props.calingFrom === 'outbox') {
            this.updateState({
                editPatientDataDetail: editPatientData,
            });
        }
    };

    handleBranchOnChange = async (state, event) => {
        this.setState({ [state]: event.target.value });
        const editPatientData = {
            ...this.state.editPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        this.setState({ editPatientData: editPatientData });
        if (this.props.calingFrom === 'outbox') {
            this.updateState({
                editPatientDataDetail: editPatientData,
            });
        }
        const episodeId =
            this.props.allRecordSetID.branch_data.fields_obj.branch.fieldId;
        const searchText = `fieldSearch=${episodeId}=${event.target.value}`;
        let status;
        await recordSetSearch(
            this.props.allRecordSetID.branch_data.recordsetId,
            searchText
        )
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then(async (data) => {
                if (status === 200) {
                    let searchResult = [];
                    searchResult = await EpisodeSearch(data);
                    let branch_id = await BranchIdSearch(data);

                    if (searchResult.length > 0) {
                        const firstNonEmptyAgency = searchResult.find(
                            (item) => item.agencyid !== ''
                        );

                        const agencyid = {
                            ...this.state.editPatientData,
                            agencyid: firstNonEmptyAgency.agencyid,
                            branch_id: branch_id,
                        };

                        await this.setState({ editPatientData: agencyid });
                        if (this.props.calingFrom === 'outbox') {
                            await this.updateState({
                                editPatientDataDetail: agencyid,
                            });
                        }
                    }
                }
            });
    };

    handleInputOnChange = (state, event) => {
        this.setState({ [state]: event.target.value });
        const editPatientData = {
            ...this.state.editPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        this.setState({ editPatientData: editPatientData });
        if (this.props.calingFrom === 'outbox') {
            this.updateState({
                editPatientDataDetail: editPatientData,
            });
        }
    };

    submitUpdatePatient = async () => {
        this.setState({ blur_spinner: true });
        await this.props.resetSubmit();
        let error = false;

        for (const property in this.state.editPatientData) {
            if (
                this.state.editPatientData[property] === '' &&
                (property === 'branch' ||
                    property === 'date_of_birth' ||
                    property === 'first_name' ||
                    property === 'last_name' ||
                    property === 'gender' ||
                    property === 'service_line')
            ) {
                if (error === false) {
                    error = true;
                }
                break;
            }
        }

        if (error === true) {
            if (this.state.validateEdit === false) {
                this.setState({ validateEdit: true });
            }
            normalErrorToast('Please fill mandatory fields');
            this.setState({ blur_spinner: false });
            return true;
        }

        let dob;
        if (this.state.dob !== '') {
            dob = formatDate(this.state.dob, 'MM/DD/YYYY');
        }

        let patientFieldIdArray;
        let recordSetId;
        if (this.props.byOrder === true) {
            patientFieldIdArray = this.props.orderFieldIdArray;
            recordSetId = this.props.orderModifiableRecordsetId;
        } else {
            patientFieldIdArray = this.state.patientFieldIdArray;
            recordSetId = this.state.patientModifiableRecordsetId;
        }

        const params = [];
        // var patientName = this.state.first_name + ',' + this.state.last_name;
        const patientName = `${this.state.last_name}, ${this.state.first_name}`;
        const formObj = {
            id: Math.abs(this.props.selectedPatient.id),
            patient_firstname: this.state.first_name,
            patient_lastname: this.state.last_name,
            date_of_birth: dob,
            gender: this.state.gender,
            branch: this.state.branch,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            patient_name: patientName.trim(),
            patient_id: this.state.patient_id,
            service_line: this.state.service_line,
            patient_first_name: this.state.first_name.trim(),
            patient_last_name: this.state.last_name.trim(),
        };

        if (this.props.byOrder === true) {
            formObj.order_number = this.props.selectedPatient.order_number;
        }
        // added agency id
        if (this.state.editPatientData.agencyid) {
            formObj.agencyid = this.state.editPatientData.agencyid;
        }
        if (this.state.editPatientData.branch_id) {
            formObj.branch_id = this.state.editPatientData.branch_id;
        }

        const formData = [];
        formData.push(formObj);

        // eslint-disable-next-line array-callback-return
        patientFieldIdArray.map((data) => {
            const value =
                formData[0][formatNameWithSpaceToUnderscore(data.name)];
            if (
                formData[0][formatNameWithSpaceToUnderscore(data.name)] !==
                    undefined &&
                formData[0][formatNameWithSpaceToUnderscore(data.name)] !== ''
            ) {
                params.push({
                    fieldId: data.fieldId,
                    value: value,
                });
            }
        });
        const patientId = Math.abs(this.props.selectedPatient.id);
        if (this.props.updateState) {
            this.props.updateState({
                byPatientRender: false,
            });
        }
        if (
            this.props.selectedPatient.id !== undefined &&
            this.props.byOrderEdit !== true
        ) {
            let status;
            updateRecordSet(params, recordSetId, patientId)
                .then((response) => {
                    this.setState({ blur_spinner: false });
                    status = response.status;
                    if (status === 204) {
                        this.setState({ edit_patient: false }, () => {
                            this.props.updateState({ edit_patient: false });
                        });

                        normalSuccessToast('Updated Successfully');
                        // Add patient_filter for Outbox Detail
                        if (this.props.calingFrom === 'outbox') {
                            this.props.updateState({
                                patient_filter: {
                                    ...this.state.editPatientData,
                                    patient_name: `${this.state.editPatientData.last_name}, ${this.state.editPatientData.first_name}`,
                                },
                                // patient_filter_display: `${this.state.editPatientData.last_name}, ${this.state.editPatientData.first_name}`,
                            });
                        }
                        this.props.updateState({
                            patient_filter_display: `${this.state.editPatientData.last_name}, ${this.state.editPatientData.first_name}`,
                            patient_filter: {
                                ...this.state.editPatientData,
                                patient_name: `${this.state.editPatientData.last_name}, ${this.state.editPatientData.first_name}`,
                            },
                        });
                        if (this.props.updatePatientFilter) {
                            this.props.updatePatientFilter({
                                patient_filter: {
                                    ...this.state.editPatientData,
                                    patient_name: `${this.state.editPatientData.last_name}, ${this.state.editPatientData.first_name}`,
                                },
                            });
                        }

                        if (
                            this.props.newReferral === 'No' &&
                            this.props.byOrder === false
                        ) {
                            this.props.closeEditPatient(formData[0]);
                        } else if (
                            this.props.newReferral === 'Incomplete' ||
                            this.props.newReferral === 'Yes'
                        ) {
                            this.props.updateFromCreate();
                        }

                        if (
                            this.props.newReferral === 'No' &&
                            this.props.byOrder === true
                        ) {
                            this.props.closeEditOrder(formData[0]);
                        }
                        if (this.props.updateState) {
                            this.props.updateState({
                                byPatientRender: true,
                            });
                        }
                    } else if (response !== '') {
                        return response.json();
                    }
                })
                .then((data) => {
                    if (status === 400 || status === 404) {
                        toast.error(data.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (this.props.updateState) {
                            this.props.updateState({
                                byPatientRender: true,
                            });
                        }
                    }
                });
        } else {
            this.setState({ blur_spinner: false });
            this.props.updateState({
                patient_filter: {
                    ...this.state.editPatientData,
                    patient_name: `${this.state.editPatientData.last_name}, ${this.state.editPatientData.first_name}`,
                },
            });
            if (this.props.calingFrom !== 'outbox') {
                if (this.props.updatePatientFilter) {
                    this.props.updatePatientFilter({
                        patient_filter: {
                            ...this.state.editPatientData,
                            patient_name: `${this.state.editPatientData.last_name}, ${this.state.editPatientData.first_name}`,
                        },
                    });
                }
            }
            this.setState({ edit_patient: false }, () => {
                this.props.updateState({ edit_patient: false });
            });
            if (this.props.updateState) {
                this.props.updateState({
                    byPatientRender: true,
                });
            }
        }
    };

    setDob = (date) => {
        this.setState({
            dob: date,
            date_of_birth: formatDate(date, 'MM/DD/YYYY'),
        });
        const editPatientData = {
            ...this.state.editPatientData,
            date_of_birth: formatDate(date, 'MM/DD/YYYY'),
        };
        this.setState({ editPatientData: editPatientData });
        if (this.props.calingFrom === 'outbox') {
            this.updateState({
                editPatientDataDetail: editPatientData,
            });
        }
    };

    setOrderDate = (date) => {
        this.setState({
            order_date: date,
        });
        const editPatientData = {
            ...this.state.editPatientData,
            order_date: formatDate(date, 'MM/DD/YYYY'),
        };
        this.setState({ editPatientData: editPatientData });
        if (this.props.calingFrom === 'outbox') {
            this.updateState({
                editPatientDataDetail: editPatientData,
            });
        }
    };

    closeCover = () => {
        const state = {
            open_edit_patient: false,
            edit_patient: false,
            view_patient: true,
            // disabledClass: 'disabled',
        };
        this.setState(state, () => {
            this.props.updateState(state);
        });
    };

    render() {
        let classrow = 'col-lg-6';
        if (this.props.calingFrom === 'search') classrow = 'col-lg-3';

        return (
            <div
                className={
                    this.props.calingFrom === 'search' &&
                    this.state.edit_patient
                        ? 'search_page edit_patient'
                        : this.props.calingFrom === 'search' &&
                          this.state.edit_patient === false
                        ? 'search_page view_patient'
                        : 'patient'
                }
            >
                <SpinnerBlur show={this.state.blur_spinner} />
                {this.state.edit_patient &&
                this.state.patient_form_apperance === 'normal' ? (
                    <div>
                        <div className='row grid-row-group'>
                            <>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>
                                        PATIENT FIRST NAME
                                    </label>
                                    <div className='position-relative'>
                                        <input
                                            type='text'
                                            className={
                                                this.state.first_name === ''
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.first_name}
                                            onChange={(event) => {
                                                this.handleInputOnChange(
                                                    'first_name',
                                                    event
                                                );
                                            }}
                                            maxLength='50'
                                        />
                                        {(this.state.validateEdit ||
                                            this.props.patientValidation) &&
                                        this.state.first_name === '' ? (
                                            <p
                                                className='error m-0'
                                                style={{
                                                    color: 'red',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Patient First Name is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>
                                        PATIENT LAST NAME
                                    </label>
                                    <input
                                        type='text'
                                        className={
                                            this.state.last_name === ''
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={this.state.last_name}
                                        onChange={(event) => {
                                            this.handleInputOnChange(
                                                'last_name',
                                                event
                                            );
                                        }}
                                        maxLength='50'
                                    />
                                    {(this.state.validateEdit ||
                                        this.props.patientValidation) &&
                                    this.state.last_name === '' ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Patient Last Name is required.
                                        </p>
                                    ) : null}
                                </div>
                            </>

                            <>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>
                                        DATE OF BIRTH
                                    </label>
                                    <div
                                        className={
                                            this.state.dob === ''
                                                ? 'position-relative form-control error-field'
                                                : 'position-relative form-control'
                                        }
                                    >
                                        <DatePicker
                                            onChange={(date) => {
                                                this.setDob(date);
                                            }}
                                            value={this.state.dob}
                                            className='date-picker'
                                            maxDate={new Date()}
                                            format='MM/dd/y'
                                        />

                                        {(this.state.validateEdit ||
                                            this.props.patientValidation) &&
                                        this.state.dob === '' ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Date of Birth is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>GENDER</label>
                                    <select
                                        id='delete_selectbrand'
                                        className={
                                            this.state.gender === ''
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={this.state.gender}
                                        onChange={(event) => {
                                            this.handleSelectOnChange(
                                                'gender',
                                                event
                                            );
                                        }}
                                    >
                                        <option value=''>Select</option>
                                        <option value='M'>Male</option>
                                        <option value='F'>Female</option>
                                    </select>
                                    <i
                                        className='fa-light fa-chevron-down'
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    {(this.state.validateEdit ||
                                        this.props.patientValidation) &&
                                    this.state.gender === '' ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Gender is required.
                                        </p>
                                    ) : null}
                                </div>
                            </>

                            <>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>SERVICE LINE</label>
                                    <div className=''>
                                        <select
                                            id='delete_selectbrand'
                                            className={
                                                this.state.service_line === ''
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.service_line}
                                            onChange={(event) => {
                                                this.handleSelectOnChange(
                                                    'service_line',
                                                    event
                                                );
                                            }}
                                        >
                                            <option value=''>Select</option>
                                            {this.props.ddl &&
                                            Array.isArray(
                                                this.props.ddl.serviceline
                                            )
                                                ? this.props.ddl.serviceline.map(
                                                      (data, index) => (
                                                          <option
                                                              key={index}
                                                              value={data.toUpperCase()}
                                                          >
                                                              {data.toUpperCase()}
                                                          </option>
                                                      )
                                                  )
                                                : null}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                        {(this.state.validateEdit ||
                                            this.props.patientValidation) &&
                                        this.state.editPatientData
                                            .service_line === '' ? (
                                            <p
                                                className='error m-0'
                                                style={{
                                                    color: 'red',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Service Line is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`transition-select ${classrow}`}
                                >
                                    <label className='pl-5'>BRANCH</label>
                                    <select
                                        id='delete_selectbrand'
                                        className={
                                            this.state.branch === ''
                                                ? 'form-control error-field'
                                                : 'form-control'
                                        }
                                        value={this.state.branch}
                                        onChange={(event) => {
                                            this.handleBranchOnChange(
                                                'branch',
                                                event
                                            );
                                        }}
                                    >
                                        <option value=''>Select</option>
                                        {this.props.ddl &&
                                        Array.isArray(this.props.ddl.branch)
                                            ? this.props.ddl.branch.map(
                                                  (data, index) => (
                                                      <option
                                                          key={index}
                                                          value={data}
                                                          style={{
                                                              display:
                                                                  data !== ''
                                                                      ? 'block'
                                                                      : 'none',
                                                          }}
                                                      >
                                                          {data}
                                                      </option>
                                                  )
                                              )
                                            : null}
                                    </select>
                                    <i
                                        className='fa-light fa-chevron-down'
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    {(this.state.validateEdit ||
                                        this.props.patientValidation) &&
                                    this.state.branch === '' ? (
                                        <p
                                            className='error m-0'
                                            style={{ color: 'red' }}
                                        >
                                            Branch is required.
                                        </p>
                                    ) : null}
                                </div>
                            </>
                        </div>
                    </div>
                ) : this.state.edit_patient &&
                  this.state.patient_form_apperance !== 'normal' ? (
                    <Modal
                        className='add-new-patient'
                        // show={true}
                        // show={this.state.open_edit_patient}
                        show
                        onHide={this.closeCover.bind(this, false)}
                        animation={false}
                        size='lg'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                    >
                        <Modal.Header
                            closeButton
                            className='d-block justify-content-start'
                        >
                            <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                                <div className=''>
                                    <i className='fa-solid fa-pen' /> Edit
                                    Patient
                                </div>
                                <i
                                    title='Close'
                                    className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                    aria-hidden='true'
                                    onClick={this.closeCover.bind(this, false)}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='edit patient'>
                            <div>
                                <div className='row grid-row-group'>
                                    <>
                                        <div className={`${classrow}`}>
                                            <label className='pl-5'>
                                                PATIENT FIRST NAME
                                            </label>
                                            <div className='position-relative'>
                                                <input
                                                    type='text'
                                                    className={
                                                        this.state
                                                            .first_name === ''
                                                            ? 'form-control error-field'
                                                            : 'form-control'
                                                    }
                                                    value={
                                                        this.state.first_name
                                                    }
                                                    onChange={(event) => {
                                                        this.handleInputOnChange(
                                                            'first_name',
                                                            event
                                                        );
                                                    }}
                                                />
                                                {this.state.validateEdit &&
                                                this.state.first_name === '' ? (
                                                    <p
                                                        className='error m-0'
                                                        style={{
                                                            color: 'red',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        Patient First Name is
                                                        required.
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div
                                            className={`transition-select ${classrow}`}
                                        >
                                            <label className='pl-5'>
                                                PATIENT LAST NAME
                                            </label>
                                            <input
                                                type='text'
                                                className={
                                                    this.state.last_name === ''
                                                        ? 'form-control error-field'
                                                        : 'form-control'
                                                }
                                                value={this.state.last_name}
                                                onChange={(event) => {
                                                    this.handleInputOnChange(
                                                        'last_name',
                                                        event
                                                    );
                                                }}
                                            />
                                            {this.state.validateEdit &&
                                            this.state.last_name === '' ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Patient Last Name is
                                                    required.
                                                </p>
                                            ) : null}
                                        </div>
                                    </>

                                    <>
                                        <div className={`${classrow}`}>
                                            <label className='pl-5'>
                                                DATE OF BIRTH
                                            </label>
                                            <div
                                                className={
                                                    this.state.dob === ''
                                                        ? 'position-relative form-control error-field'
                                                        : 'position-relative form-control'
                                                }
                                            >
                                                <DatePicker
                                                    onChange={(date) => {
                                                        this.setDob(date);
                                                    }}
                                                    value={this.state.dob}
                                                    className='date-picker'
                                                    maxDate={new Date()}
                                                    format='MM/dd/y'
                                                />

                                                {this.state.validateEdit &&
                                                this.state.dob === '' ? (
                                                    <p
                                                        className='error m-0'
                                                        style={{ color: 'red' }}
                                                    >
                                                        Date of Birth is
                                                        required.
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div
                                            className={`transition-select ${classrow}`}
                                        >
                                            <label className='pl-5'>
                                                GENDER
                                            </label>
                                            <select
                                                id='delete_selectbrand'
                                                className={
                                                    this.state.gender === ''
                                                        ? 'form-control error-field'
                                                        : 'form-control'
                                                }
                                                value={this.state.gender}
                                                onChange={(event) => {
                                                    this.handleSelectOnChange(
                                                        'gender',
                                                        event
                                                    );
                                                }}
                                            >
                                                <option value=''>Select</option>
                                                <option value='M'>Male</option>
                                                <option value='F'>
                                                    Female
                                                </option>
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                            {this.state.validateEdit &&
                                            this.state.gender === '' ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Gender is required.
                                                </p>
                                            ) : null}
                                        </div>
                                    </>

                                    <>
                                        <div
                                            className={`transition-select ${classrow}`}
                                        >
                                            <label className='pl-5'>
                                                SERVICE LINE
                                            </label>
                                            <div className=''>
                                                <select
                                                    id='delete_selectbrand'
                                                    className={
                                                        this.state
                                                            .service_line === ''
                                                            ? 'form-control error-field'
                                                            : 'form-control'
                                                    }
                                                    value={
                                                        this.state.service_line
                                                    }
                                                    onChange={(event) => {
                                                        this.handleSelectOnChange(
                                                            'service_line',
                                                            event
                                                        );
                                                    }}
                                                >
                                                    <option value=''>
                                                        Select
                                                    </option>
                                                    {this.props.ddl &&
                                                    Array.isArray(
                                                        this.props.ddl
                                                            .serviceline
                                                    )
                                                        ? this.props.ddl.serviceline.map(
                                                              (data, index) => (
                                                                  <option
                                                                      key={
                                                                          index
                                                                      }
                                                                      value={data.toUpperCase()}
                                                                  >
                                                                      {data.toUpperCase()}
                                                                  </option>
                                                              )
                                                          )
                                                        : null}
                                                </select>
                                                <i
                                                    className='fa-light fa-chevron-down'
                                                    style={{
                                                        pointerEvents: 'none',
                                                    }}
                                                />
                                                {this.state.validateEdit &&
                                                this.state.editPatientData
                                                    .service_line === '' ? (
                                                    <p
                                                        className='error m-0'
                                                        style={{
                                                            color: 'red',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        Service Line is
                                                        required.
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div
                                            className={`transition-select ${classrow}`}
                                        >
                                            <label className='pl-5'>
                                                BRANCH
                                            </label>
                                            <select
                                                id='delete_selectbrand'
                                                className={
                                                    this.state.branch === ''
                                                        ? 'form-control error-field'
                                                        : 'form-control'
                                                }
                                                value={this.state.branch}
                                                onChange={(event) => {
                                                    this.handleBranchOnChange(
                                                        'branch',
                                                        event
                                                    );
                                                }}
                                            >
                                                <option value=''>Select</option>
                                                {this.props.ddl &&
                                                Array.isArray(
                                                    this.props.ddl.branch
                                                )
                                                    ? this.props.ddl.branch.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data}
                                                                  style={{
                                                                      display:
                                                                          data !==
                                                                          ''
                                                                              ? 'block'
                                                                              : 'none',
                                                                  }}
                                                              >
                                                                  {data}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                            {this.state.validateEdit &&
                                            this.state.branch === '' ? (
                                                <p
                                                    className='error m-0'
                                                    style={{ color: 'red' }}
                                                >
                                                    Branch is required.
                                                </p>
                                            ) : null}
                                        </div>
                                    </>
                                    {this.props.currentLookup !== undefined &&
                                        this.props.currentLookup ===
                                            'byOrder' && (
                                            <>
                                                <hr />

                                                <div
                                                    className={`transition-select ${classrow}`}
                                                >
                                                    <label className='pl-5'>
                                                        ORDER NUMBER
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className={
                                                            this.state
                                                                .validateCreate &&
                                                            this.state
                                                                .order_number ===
                                                                ''
                                                                ? 'form-control error-field'
                                                                : 'form-control'
                                                        }
                                                        value={
                                                            this.state
                                                                .order_number
                                                        }
                                                        onChange={(event) => {
                                                            this.handleInputOnChange(
                                                                'order_number',
                                                                event
                                                            );
                                                        }}
                                                    />

                                                    {this.state
                                                        .validateCreate &&
                                                    this.state.order_number ===
                                                        '' ? (
                                                        <p
                                                            className='error m-0'
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            Order Number is
                                                            required.
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div
                                                    className={`transition-select ${classrow}`}
                                                >
                                                    <label className='pl-5'>
                                                        ORDER DATE
                                                    </label>
                                                    <div
                                                        className={
                                                            this.state
                                                                .validateCreate &&
                                                            this.state
                                                                .order_date ===
                                                                ''
                                                                ? 'position-relative form-control error-field'
                                                                : 'position-relative form-control'
                                                        }
                                                    >
                                                        <DatePicker
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                this.setOrderDate(
                                                                    date
                                                                );
                                                            }}
                                                            value={
                                                                this.state
                                                                    .order_date
                                                            }
                                                            className='date-picker'
                                                            maxDate={new Date()}
                                                            format='MM/dd/y'
                                                        />
                                                    </div>
                                                    {this.state
                                                        .validateCreate &&
                                                    this.state.createPatientData
                                                        .order_date === '' ? (
                                                        <p
                                                            className='error m-0'
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            Order Date is
                                                            required.
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='d-block delete_btn'>
                            <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                                <div className='d-flex'>
                                    <Button
                                        title='Cancel'
                                        variant='secondary'
                                        onClick={this.closeCover.bind(
                                            this,
                                            false
                                        )}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className='d-flex'>
                                    <div
                                        className='bulk'
                                        style={{ display: 'flex' }}
                                    >
                                        <div
                                            className='btn btn-success'
                                            title='Save'
                                            onClick={() => {
                                                this.submitUpdatePatient();
                                            }}
                                            // disabled={this.state.coverBtn}*/
                                        >
                                            Save
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                ) : this.props.byOrder ? (
                    <div
                        className='patient_detail edit-detail-bg'
                        style={{ backgroundColor: '#F0F7FB' }}
                    >
                        <div
                            style={{ display: 'flex' }}
                            className='form-group row'
                        >
                            <span className='col-lg-4'>
                                <span className='patient_label'>
                                    ORDER NUMBER
                                </span>
                                <br />
                                <span className='patient_info'>
                                    #
                                    {this.props.selectedPatient.order_number !==
                                    ''
                                        ? this.props.selectedPatient
                                              .order_number
                                        : '-'}
                                </span>
                            </span>
                            <span className='col-lg-4'>
                                <span className='patient_label'>
                                    ORDER DATE
                                </span>
                                <br />
                                <span className='patient_info'>
                                    {this.props.selectedPatient.order_date !==
                                    ' '
                                        ? formatDate(
                                              this.props.selectedPatient
                                                  .order_date,
                                              'MM/DD/YYYY'
                                          )
                                        : '-'}
                                </span>
                            </span>
                            <span className='col-lg-4'>
                                <span className='patient_label'>MR #</span>
                                <br />
                                <span className='patient_info'>
                                    {this.props.selectedPatient.mr !== ''
                                        ? this.props.selectedPatient.mr
                                        : '-'}
                                </span>
                            </span>
                        </div>
                        <div
                            style={{ display: 'flex' }}
                            className='form-group row'
                        >
                            <span className='col-lg-4'>
                                <span className='patient_label'>
                                    PATIENT NAME
                                </span>
                                <br />
                                <span className='patient_info'>
                                    {this.state.last_name},{' '}
                                    {this.state.first_name}
                                </span>
                            </span>
                            <span className='col-lg-4'>
                                <span className='patient_label'>
                                    DATE OF BIRTH
                                </span>
                                <br />
                                <span className='patient_info'>
                                    {this.state.date_of_birth}
                                </span>
                            </span>
                            <span className='col-lg-4'>
                                <span className='patient_label'>GENDER</span>
                                <br />
                                <span className='patient_info'>
                                    {this.state.gender}
                                </span>
                            </span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            className='patient_detail edit-detail-bg'
                            style={{ backgroundColor: '#F0F7FB' }}
                        >
                            <div
                                style={{ display: 'flex' }}
                                className='form-group row'
                            >
                                <span className='col-lg-4'>
                                    <span className='patient_label'>
                                        PATIENT NAME
                                    </span>
                                    <br />
                                    <span className='patient_info'>
                                        {this.state.last_name !== '' &&
                                        this.state.last_name !== undefined &&
                                        this.state.first_name !== undefined &&
                                        this.state.first_name !== ''
                                            ? `${this.state.last_name}, ${this.state.first_name}`
                                            : '-'}
                                    </span>
                                </span>
                                <span className='col-lg-4'>
                                    <span className='patient_label'>
                                        DATE OF BIRTH
                                    </span>
                                    <br />
                                    <span className='patient_info'>
                                        {this.state.date_of_birth}
                                    </span>
                                </span>
                                <span className='col-lg-4'>
                                    <span className='patient_label'>
                                        GENDER
                                    </span>
                                    <br />
                                    <span className='patient_info'>
                                        {this.state.gender}
                                    </span>
                                </span>
                            </div>

                            <div
                                style={{ display: 'flex', marginTop: '20px' }}
                                className='form-group row mb-0'
                            >
                                {/* <span className='col-lg-4'>
                                <span className='patient_label'>
                                    EPISODE STATUS
                                </span>
                                <br />
                                <span className='patient_info'>
                                    {this.state.episodestatus}
                                </span>
                                </span> */}

                                <span className='col-lg-4'>
                                    <span className='patient_label'>
                                        SERVICE LINE
                                    </span>
                                    <br />
                                    <span className='patient_info'>
                                        {this.state.service_line !== ''
                                            ? this.state.service_line
                                            : '-'}
                                    </span>
                                </span>
                                <span className='col-lg-4'>
                                    <span className='patient_label'>
                                        BRANCH
                                    </span>
                                    <br />
                                    <span className='patient_info'>
                                        {this.state.branch}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewEditPatient));
