export const DEFAULT_INBOX_STATE = 'DEFAULT_INBOX_STATE';

export const GET_INBOX_LIST = 'GET_INBOX_LIST';
export const INBOX_LIST_SUCCESS = 'INBOX_LIST_SUCCESS';
export const INBOX_LIST_FAILURE = 'INBOX_LIST_FAILURE';

export const SET_SELECTED_ROW = 'SET_SELECTED_ROW';
export const GET_SHARED_PROPS = 'GET_SHARED_PROPS';

export const GET_RULES_JSON = 'GET_RULES_JSON';
export const RULES_JSON_SUCCESS = 'RULES_JSON_SUCCESS';
export const RULES_JSON_FAILURE = 'RULES_JSON_FAILURE';

export const SET_ROW_DATA = 'SET_ROW_DATA';

export const GET_COUNT_DATA = 'GET_COUNT_DATA';

export const SHOW_LOADER = 'SHOW_LOADER';
export const RE_RENDER_INBOX = 'RE_RENDER_INBOX';
export const HIDE_BIG_VIEW = 'HIDE_BIG_VIEW';
export const SET_MENU_NAME = 'SET_MENU_NAME';
export const SET_PAGE_DATA = 'SET_PAGE_DATA';

export const GET_OWNERS = 'GET_OWNERS';
export const GET_OWNERS_SUCCESS = 'GET_OWNERS_SUCCESS';
export const GET_OWNERS_FAILURE = 'GET_OWNERS_FAILURE';

export const SET_PROCESS_ID = 'SET_PROCESS_ID';
export const SET_STATUS_ID = 'SET_STATUS_ID';

export const SET_CALLING_PAGE = 'SET_CALLING_PAGE';

export const SET_OWNER = 'SET_OWNER';
export const SET_DOC_OWNER = 'SET_DOC_OWNER';

export const UPDATE_INBOX_LIST = 'UPDATE_INBOX_LIST';

export const UPDATE_INBOX_STATE = 'UPDATE_INBOX_STATE';

export const SELECTED_ROW_DETAILS = 'SELECTED_ROW_DETAILS';

export const MULTIPLE_EDIT_BASE64 = 'MULTIPLE_EDIT_BASE64';

export const EDIT_DETAIL_PAGE = 'EDIT_DETAIL_PAGE';

export const INBOX_PAGE = 'INBOX_PAGE';

export const CLEAR_INBOX_STATE = 'CLEAR_INBOX_STATE';

export const INBOX_GROUPING = 'INBOX_GROUPING';

export const INITIAL_INBOX_GROUPING = 'INITIAL_INBOX_GROUPING';
