import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import jsPDF from 'jspdf';
import { deletedPage } from '../_redux/actions/searchActions';
import {
    reportColorCodeArray,
    getRandomColors,
    sortObjectByKeys,
    formatHeaderNameWithOutSpace,
    LightenDarkenColor,
    reportColorCodeBigArray,
} from '../../components/commonfunction';

const _ = require('lodash');

class Radial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredData: this.props.filteredData,
            filterTxt: this.props.filterTxt,
            rows: this.props.rows,
            totalCount: this.props.totalCount,
            graphicalField: this.props.graphicalField,
            statusCount: 0,
            outboundStatus: [],
            radialImgUrl: '',
            filterCondition: false,
            Radialseries: [44, 55, 67, 83],
            Radialoptions: {
                chart: {
                    id: `${formatHeaderNameWithOutSpace(
                        this.props.pageTitle
                    )}-${this.props.graphicalField}`,
                    selection: {
                        enabled: true,
                    },
                    height: 700,
                    width: 492,
                    type: 'radialBar',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset:
                                true |
                                '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [
                                {
                                    icon: '<i class="fa-solid fa-file-arrow-down"></i>',
                                    class: 'custom-icon',
                                    index: 0,
                                    title: 'Download PDF',
                                    click: () => {
                                        this.downloadPDF();
                                    },
                                },
                            ],
                        },
                    },
                    events: {
                        legendClick: function (
                            chartContext,
                            seriesIndex,
                            config
                        ) {
                            props.radialChartOnClick(
                                config.config.labels[seriesIndex],
                                'Radial'
                            );
                        },
                    },
                },
                legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'left',
                    width: '50%',
                    fontSize: '13px',
                    onItemClick: {
                        toggleDataSeries: false,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                            },
                            total: {
                                show: true,
                                label: 'All Data',
                                formatter: function (
                                    w = this.props.totalCount
                                ) {
                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function

                                    const arraySum = w.globals.series;
                                    let sum = 0;
                                    arraySum.forEach((item) => {
                                        sum += item;
                                    });

                                    return sum;
                                },
                            },
                        },
                    },
                },
                labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
                responsive: [
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                width: '100%',
                                height: 350,
                                position: 'center',
                                horizontalAlign: 'left',
                            },
                            legend: {
                                show: true,
                                position: 'bottom',
                                width: '100%',
                                horizontalAlign: 'center',
                                fontSize: '10px',
                            },
                        },
                    },
                ],
            },
        };
    }

    async componentDidMount() {
        await this.drawDonut();
    }

    async componentDidUpdate(prevState, prevProps) {
        // due to this condition radial chart after filter not working
        // if (
        // _.isEqual(this.state.filteredData, this.props.filteredData) ===
        // false  && this.props.chartFilterTxt == ''
        // ) {
        if (
            _.isEqual(this.state.filteredData, this.props.filteredData) ===
                false &&
            this.props.chartName === 'Radial'
        ) {
            this.setState(
                {
                    filteredData: this.props.filteredData,
                },
                () => {
                    this.drawDonut();
                }
            );
        }
        if (
            _.isEqual(this.state.filteredData, this.props.filteredData) ===
                false &&
            this.props.filterTxt !== '' &&
            this.props.chartName !== 'Stack'
        ) {
            this.setState(
                {
                    filteredData: this.props.filteredData,
                },
                () => {
                    this.drawDonut();
                }
            );
        }
        // eslint-disable-next-line eqeqeq
        if (prevState.filterTxt !== '' && this.props.filterTxt == '') {
            await this.setState({
                filterCondition: true,
            });
            await this.drawDonut();
        }
        if (this.props.noDataOnClick === true) {
            this.props.updateState({
                noDataOnClick: false,
                noDataFound: false,
            });
            await this.refreshRadialChart();

            if (this.props.deleted_Page.onClickchartName === 'Radial') {
                await this.props.radialChartOnClick(
                    this.props.deleted_Page.onClickchartFilterTxt,
                    'Radial'
                );
            }
        }
    }

    drawDonut = async () => {
        let allData;

        if (
            this.props.filterTxt !== '' &&
            this.props.filterTxt !== undefined &&
            this.props.selectedRadialChart === '' &&
            this.state.filterCondition !== true &&
            this.state.filterCondition !== undefined
        ) {
            allData = this.state.filteredData;
        } else if (
            this.props.filterTxt !== '' &&
            this.props.filterTxt !== undefined &&
            this.props.selectedRadialChart !== '' &&
            this.state.filterCondition !== true &&
            this.state.filterCondition !== undefined
        ) {
            allData = this.props.filterAllRows;
        } else {
            this.setState({
                filterCondition: false,
            });

            allData = this.props.allRows;
        }

        let statuscounting = 0;
        const graphicalField = this.state.graphicalField;
        let del;
        if (allData !== undefined) {
            const result = await allData.reduce(function (r, a, c) {
                statuscounting++;

                del = a[graphicalField];

                if (del === '') del = '(Empty)';
                r[del] = r[del] || [];
                // r['testing'] = r['testing'] || [];
                r[del].push(a);
                // if (c < 20) {
                //     r['testing'].push(a);
                // }
                return r;
            }, Object.create(null));

            // result.sort();

            // Usage
            // var a = [[12, 'AAA'], [58, 'BBB'], [28, 'CCC'],[18, 'DDD']]
            // sortByCol(result, 0)

            const finalresult = sortObjectByKeys(result);

            await this.setState({
                statusCount: statuscounting,
                outboundStatus: finalresult,
            });

            const propertyArray = [];
            const percentArray = [];
            const chartColorArray = [];
            let id_index = 0;
            let percen = 0;

            const randomcolorsArray = this.getRandomColorCodeArray(0);

            // eslint-disable-next-line guard-for-in
            for (const property in this.state.outboundStatus) {
                // percen = (this.state.outboundStatus[property].length / this.state.statusCount) * 100;
                percen = this.state.outboundStatus[property].length;
                if (this.state.outboundStatus[property].length > 0) {
                    propertyArray.push(property);
                    percentArray.push(percen);
                    // percentArray.push(Number(percen).toFixed(2));
                    //chartColorArray.push(randomcolorsArray[id_index]);

                    let bgColor = randomcolorsArray[id_index];
                    var NewColor = '';

                    if (randomcolorsArray[id_index] === undefined) {
                        const newindex = id_index % 12;
                        const index_count = Math.floor(id_index / 12);

                        NewColor = randomcolorsArray[newindex];
                        bgColor = LightenDarkenColor(
                            NewColor,
                            50 * index_count
                        );
                    }
                    chartColorArray.push(bgColor);
                }

                id_index++;
            }

            await this.setState({
                Radialseries: percentArray,
                Radialoptions: {
                    ...this.state.Radialoptions,
                    labels: propertyArray,
                    colors: chartColorArray,
                },
            });
        }
    };

    getRandomColorCodeArray = (resultCount) => {
        // let coun = 0;
        // // eslint-disable-next-line eqeqeq
        // if (resultCount != 0) {
        //     coun = resultCount;
        // } else {
        //     // eslint-disable-next-line no-unused-vars
        //     for (const property in this.state.outboundStatus) {
        //         coun++;
        //     }
        // }

        // if (coun >= 12) {
        //     coun = 12;
        // }

        // const colorCode = reportColorCodeArray();
        // const originalColorCount = colorCode.length;
        // const totalColorCount = Math.floor(originalColorCount / coun);
        // const randomcolors = getRandomColors(colorCode, totalColorCount);

        // return randomcolors;
        let coun = 0;
        // eslint-disable-next-line eqeqeq
        if (resultCount != 0) {
            coun = resultCount;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const property in this.state.outboundStatus) {
                coun++;
            }
        }
        let colorCode = reportColorCodeArray();
        let originalColorCount = colorCode.length;
        if (coun > originalColorCount) {
            colorCode = reportColorCodeBigArray(coun);
            originalColorCount = colorCode.length;
        }
        const totalColorCount = Math.floor(originalColorCount / coun);
        const randomcolors = getRandomColors(colorCode, totalColorCount);

        return randomcolors;
    };

    getDataUri = async (chartId) => {
        // eslint-disable-next-line no-return-await
        return await ApexCharts.exec(chartId, 'dataURI').then(({ imgURI }) => {
            this.setState({ radialImgUrl: imgURI });
            return imgURI;
        });
    };

    downloadPDF = () => {
        this.getDataUri(this.state.Radialoptions.chart.id);

        setTimeout(() => {
            // eslint-disable-next-line new-cap
            const pdf = new jsPDF();
            const pdfname = `${formatHeaderNameWithOutSpace(
                this.props.pageTitle
            )}-${this.props.graphicalField}.pdf`;
            pdf.setFontSize(20);
            pdf.text(20, 25, this.props.pageTitle);
            pdf.addImage(this.state.radialImgUrl, 'PNG', 10, 50);
            pdf.save(pdfname);
        }, 1000);
    };

    refreshRadialChart = async () => {
        const paginatedRowData = _.slice(this.props.allRows, 0, 10);
        await this.props.updateState({
            chartFilterTxt: '',
            // filteredRowData: [],
            filteredData: this.props.allRows,
            rowData: paginatedRowData,
            allRows: this.props.allRows,
            currentPage: 0,
            totalCount: this.props.allRows.length,
            propertyTiles: 'All Data',
            selectedRadialChart: 'All Data',
            chartName: '',
            perPage: 10,
        });

        if (this.props.filterTxt !== '') {
            this.props.filterDataGrid(this.props.filterTxt);
        } else {
            this.props.resetPopOverData(this.props.allRows);
        }
    };

    render() {
        return (
            <div
                className={
                    this.props.graphicalType === 'radial'
                        ? 'col-lg-12 col-md-12'
                        : ''
                }
            >
                <div className='report-doc'>
                    <div className='report-doc-top'>
                        <h4>Per {this.props.grpahicalFieldNameFormat}</h4>
                        <div className='filter-sec'>
                            <span
                                className='reload'
                                onClick={() => {
                                    this.refreshRadialChart();
                                    this.props.deletedPage({
                                        chartName: '',
                                        chartFilterTxt: '',
                                        currentPage_Report: 0,
                                        perPage_Report: this.state.perPage,
                                    });
                                }}
                            >
                                <i
                                    title='Refresh'
                                    className={
                                        this.state.displayAnimation
                                            ? 'fas fa-sync-alt refreshing'
                                            : 'fas fa-sync-alt'
                                    }
                                />
                            </span>
                        </div>
                    </div>
                    <div id='chart'>
                        <Chart
                            options={this.state.Radialoptions}
                            series={this.state.Radialseries}
                            type='radialBar'
                            width='100%'
                            height={
                                this.state.Radialseries.length > 15 ? 600 : 420
                            }
                        />
                    </div>
                </div>
            </div>
        );

        /* <div className='row'>
                    <div className='col-lg-6'>
                        <div className='report-block'></div>
                    </div>
                    <div className='col-lg-6'></div>
            </div> */
    }
}

// const domContainer = document.querySelector('#chart');
// ReactDOM.render(React.createElement(ApexChart), domContainer);
const mapDispatchToProps = {
    deletedPage,
};
const mapStateToProps = (state) => {
    return {
        outbox: state.outbox,
        deleted_Page: state.search.deletedPage,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Radial));

// export default Radial;
