/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import {
    getDocumentMetaWithDocId,
    updateDocument,
    uploadDocument,
    getDocumentImage,
    deleteMultipleDocument,
    deleteDocument,
} from './api/api';
import { useSelector, useDispatch } from 'react-redux';
import { getProcessCount } from './_redux/authCrud';
import { getCountData, inboxPage } from './_redux/actions/inboxActions';
import { outboxPage } from './_redux/actions/outboxActions';
import {
    GetFieldData,
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
} from '../components/commonfunction';
import { SpinnerBlur } from '../components/inbox/Spinner';

import EditDeleteModal from '../components/editpdf/EditDeleteModal';
import BreakPointModal from '../components/editpdf/BreakPointModal';
import { toast } from 'react-toastify';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import Tooltip from '@material-ui/core/Tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { deletedPage } from './_redux/actions/searchActions';

toast.configure();

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: theme.typography.pxToRem(12),
        border: '1.5px solid #334F61 !important',
    },
}))(Tooltip);
// var imgUrlObjInitialTest = [];
var kowsiArr = [];
var transitionData = [];
var statusId = '';
var branchFieldId = '';
var groupFieldId = '';
var mergeAllNewPush = [];
// var isEnableCopyCut = 0;

var docidsCount = 0;
var allFields = [];
var cutCopyDocName = '';
var cutCopyParentId = '';
var deletedPages = [];

//const history = useHistory();
function MultipleEditPage(props) {
    const [imgUrlObj, setImgUrlObj] = useState([]);
    const [, setInitialData] = useState([]);
    const [imgActiveUrlObj, setImgActiveUrlObj] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const [documentToggle, setDocumentToggle] = useState(true);
    const [deleteText, setDeleteText] = useState('');
    const [blurSpinner, setBlurSpinner] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [showBreakPointPopup, setShowBreakPointPopup] = useState(false);
    const [resetDoc, setResetDoc] = useState([]);
    const [showBreakDoc, setShowBreakDoc] = useState(true);
    const [showMergeDoc, setShowMergeDoc] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [disableReset, setDisableReset] = useState(true);
    const [lastReset, setLastReset] = useState();
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [selDocForBreak, setSelDocForBreak] = useState([]);
    const [lockedDocument, setLockedDocument] = useState([]);
    const [initialArrayAppend] = useState([]);
    const [deleteDocIds] = useState([]);
    const lockedDoc = [];
    var imageActiveUrl = imgActiveUrlObj;
    var pushSelectDatas = selectedData;
    const mergePush = [];
    const reduxStore = useSelector((state) => state);

    const checkboxRef = useRef(null);

    const configData = reduxStore.configReducerPersist.configData;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const multipleID = useLocation().search;
    const editIds = new URLSearchParams(multipleID).get('editmultiple');

    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        setBlurSpinner(true);
        let fieldDataObj = GetFieldData({
            configData: configData,
        });
        branchFieldId = fieldDataObj.branchFieldId;
        groupFieldId = fieldDataObj.groupFieldId;

        // return () => {
        //    // initialArrayAppend = []
        // }

        getMultipleDocImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMultipleDocImages = async () => {
        let pathname = '';

        if (props.location !== undefined) {
            pathname = props.location.pathname;
        } else if (
            location !== undefined &&
            (editIds === null || editIds === undefined)
        ) {
            pathname = location.pathname;
        }

        if (pathname === '') {
            pathname = editIds;
        }

        let path_arr = '';
        let splitArray = '';
        if (
            pathname !== undefined &&
            pathname !== null &&
            pathname !== '' &&
            pathname.includes('editmultiple=') === true
        ) {
            splitArray = pathname.split('editmultiple=');
            path_arr = splitArray[1].split('/');
        }
        if (
            pathname !== undefined &&
            pathname !== null &&
            pathname !== '' &&
            pathname.includes('editmultiple=') === false
        ) {
            path_arr = pathname.split('/');
        }

        let docids = path_arr; //array

        let badStatusCountArr = [];
        let response = [];
        let DocIdOrder = [];
        docidsCount = docids.length;
        setShowMergeDoc(true);
        setShowBreakDoc(true);
        //let lockedDoc= [...lockedDocument];
        if (docids.length > 0) {
            docids.forEach((mutipleDocid, index) => {
                let docid = window.atob(mutipleDocid);

                DocIdOrder.push(docid);
                response.push(getDocumentMetaWithDocId({ docid }));

                getDocumentMetaWithDocId({ docid }).then((urlResponse) => {
                    let pageCountTemp = urlResponse.miscPproperties.pageCount;
                    if (urlResponse.miscPproperties.isLocked === true) {
                        lockedDoc.push(urlResponse.miscPproperties.docId);
                    }
                    setLockedDocument(lockedDoc);
                    //setLockedDocument(lockedDoc);

                    if (pageCountTemp !== undefined) {
                        for (let i = 0; i < pageCountTemp; i++) {
                            getDocumentImage(
                                urlResponse.miscPproperties.docId,
                                i + 1
                            ).then((image) => {
                                if (image !== undefined) {
                                    var blobImages = image.blob();
                                    blobImages.then(async (dataResult) => {
                                        var blob = dataResult;

                                        var reader = new FileReader();
                                        reader.readAsDataURL(blob);
                                        reader.onloadend = async () => {
                                            var temp = [];
                                            var url = reader.result;

                                            var convertImage = '';
                                            let encodeBase64 =
                                                url.split(',')[1];
                                            convertImage =
                                                'data:image/png;deleted:0;base64,' +
                                                encodeBase64;

                                            temp.push(convertImage);

                                            //temp.push(url);
                                            initialData(temp);
                                        };
                                    });
                                }
                            });
                        }
                    }
                });
            });
        }
        let imgResponse = [];

        let myObject = new Map();
        let docName = new Map();
        let imgOrderResponse = [];
        let imgBlob = [];
        var imageLoaded = false;

        let initialData = async (val) => {
            if (imageLoaded === true) {
                return true;
            }
            setShowImage(false);

            badStatusCountArr.forEach((badReqData, badReqIndex) => {
                myObject.delete(badReqData);
            });

            if (myObject.size) {
                for (let [i, count] of myObject) {
                    var individualDocData = val.splice(0, count);
                    var convertImage;
                    if (imgUrlObj.length === 0) {
                        //individualDocData.replace('data:image/png;base64,','data:image/jpeg;charset=utf-8;base64,')
                        var dummy = [];
                        convertImage = individualDocData[0];
                        let encodeBase64 = convertImage.split(',')[1];
                        convertImage =
                            'data:image/png;deleted:0;base64,' + encodeBase64;
                        dummy.push(convertImage);
                        var tempObj = {
                            docId: i,
                            imageUrl: dummy,
                            // imageUrl: individualDocData,
                            docName: '',
                            parentId: i,
                            deleted: 0,
                            uniqueId: Math.random(),
                        };
                        // eslint-disable-next-line no-use-before-define
                        for (let [docId, docName] of docName) {
                            if (i === docId) {
                                tempObj.docName = docName;
                            }
                        }
                        initialArrayAppend.push(tempObj);
                        imgUrlObj.push(tempObj);
                    } else {
                        if (individualDocData.length > 0) {
                            var dummy2 = [];
                            convertImage = individualDocData[0];
                            let encodeBase64 = convertImage.split(',')[1];
                            convertImage =
                                'data:image/png;deleted:0;base64,' +
                                encodeBase64;
                            dummy2.push(convertImage);

                            imgUrlObj[0].imageUrl.push(dummy2);
                        }
                    }
                }
            }
            setImgUrlObj(initialArrayAppend);

            setShowImage(true);
        };

        let constructData = async (val) => {
            imageLoaded = true;
            setShowImage(false);

            badStatusCountArr.forEach((badReqData, badReqIndex) => {
                myObject.delete(badReqData);
            });
            let newArrayAppend = [];
            if (myObject.size) {
                for (let [i, count] of myObject) {
                    var individualDocData = val.splice(0, count);

                    var dummy = [];
                    individualDocData.map((imageData, index) => {
                        let convertImage = '';
                        let encodeBase64 = imageData.split(',')[1];
                        convertImage =
                            'data:image/png;deleted:0;base64,' + encodeBase64;
                        dummy.push(convertImage);
                    });

                    var tempObj = {
                        docId: i,
                        imageUrl: dummy,
                        //imageUrl: individualDocData,
                        docName: '',
                        parentId: i,
                        uniqueId: Math.random(),
                    };
                    // eslint-disable-next-line no-use-before-define
                    for (let [docId, docName] of docName) {
                        if (i === docId) {
                            tempObj.docName = docName;
                        }
                    }
                    newArrayAppend.push(tempObj);
                    // imgUrlObj.push(tempObj)
                }
            }
            setInitialData(newArrayAppend);
            kowsiArr.push(newArrayAppend);
            setImgUrlObj(newArrayAppend);

            var newArrayAppTemp = [];

            newArrayAppend.map((img) => {
                var objTem = {};
                objTem.docId = img.docId;
                objTem.docName = img.docName;
                objTem.parentId = img.docId;
                objTem.uniqueId = Math.random();
                var arrTem = [];
                img.imageUrl.map((base64) => {
                    var arr = base64;
                    arrTem.push(arr);
                });
                objTem.imageUrl = arrTem;

                newArrayAppTemp.push(objTem);
            });
            resetDoc.push(newArrayAppTemp);

            setBlurSpinner(false);
            setShowImage(true);

            if (parseInt(myObject.size) === 0) {
                setShowSaveButton(true);
                toast.error('No image to load', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        // var tempInitialData = [];
        await Promise.all(response).then((data) => {
            data.forEach(async (val, index) => {
                allFields[val.miscPproperties.docId] = val.fields;
                let pageCountTemp = val.miscPproperties.pageCount;
                let docIdTemp = val.miscPproperties.docId;
                let docNameTemp = val.miscPproperties.documentName;
                if (pageCountTemp !== 'undefined') {
                    if (pageCountTemp > 0) {
                        if (
                            parseInt(transitionData.length) === 0 &&
                            String(statusId) === ''
                        ) {
                            if (val.fields.length && val.statuses.length) {
                                val.fields.forEach((data, i) => {
                                    if (
                                        parseInt(data.fieldId) ===
                                            branchFieldId ||
                                        parseInt(data.fieldId) === groupFieldId
                                    )
                                        transitionData.push({
                                            fieldId: data.fieldId,
                                            value: data.value,
                                        });
                                });
                                val.statuses.forEach((data, i) => {
                                    if (data.statusId !== '')
                                        statusId = data.statusId;
                                });
                            }
                        }

                        myObject.set(docIdTemp, pageCountTemp);
                        docName.set(docIdTemp, docNameTemp);
                        let badStatusCount = 0;
                        if (pageCountTemp !== undefined) {
                            for (let i = 0; i < pageCountTemp; i++) {
                                let getDocImg = getDocumentImage(
                                    val.miscPproperties.docId,
                                    i + 1
                                );

                                getDocImg.then((resPromises) => {
                                    if (
                                        resPromises !== undefined &&
                                        resPromises.status !== undefined
                                    ) {
                                        if (
                                            parseInt(resPromises.status) === 400
                                        ) {
                                            badStatusCount++;
                                            if (
                                                badStatusCount === pageCountTemp
                                            ) {
                                                badStatusCountArr.push(
                                                    docIdTemp
                                                );
                                            }
                                        }
                                    }
                                });

                                imgResponse.push(getDocImg);
                            }
                        }
                    }
                }
            });
        });

        let img_blobTemp = [];

        await Promise.allSettled(imgResponse).then((dataImage) => {
            dataImage.map(async (res, index) => {
                if (
                    res !== undefined &&
                    res.value !== undefined &&
                    res.value.status !== undefined
                ) {
                    var status = res.value.status;
                    if (parseInt(status) === 400) {
                    }
                    if (parseInt(status) === 200 || parseInt(status) === 204) {
                        var blobImages = res.value.blob();
                        var tempBlob = {};
                        tempBlob[index] = blobImages;
                        await img_blobTemp.push(tempBlob);
                        imgBlob.push(blobImages);
                        return res;
                    }
                }
            });
        });

        var imgOrderResponseTemp = {};
        var finalImgOrd = [];

        await Promise.all(imgBlob).then(async (dataResult) => {
            var blob = dataResult;

            await blob.forEach(async (dataBlob, i) => {
                var reader = new FileReader();
                reader.readAsDataURL(dataBlob);
                reader.onloadend = async () => {
                    var url = reader.result;
                    imgOrderResponseTemp[i] = url;
                    await imgOrderResponse.push(url);
                };
                if (dataResult.length - 1 === i) {
                    setTimeout(() => {
                        for (const property in imgOrderResponseTemp) {
                            finalImgOrd.push(imgOrderResponseTemp[property]);
                        }
                        constructData(finalImgOrd);
                    }, 5000);
                }
            });
        });
    };
    const setSeparator = async (event, imageIndex) => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        event.stopPropagation();
        let queryClassName = 'separator_cut';
        let activeClassName = 'sel';
        classActiveSingle(event, queryClassName, activeClassName);
        splitDocument();
        showMergeBreakSection();
    };

    const activeImage = async (event) => {
        let className = 'active';
        let avoidClassName = 'deleted';

        if (event.target.parentNode.classList.contains(avoidClassName)) {
            event.target.parentNode.classList.remove(avoidClassName);
            // let docsecImage = event.target.getAttribute('data-image');
            // let docsecId = event.target.getAttribute('data-docsecid');

            await setShowImage(false);
            // await imgUrlObj.forEach((data, i) => {
            //     if (parseInt(i) === parseInt(docsecId)) {
            //         let activeIndexVal = data.imageUrl.indexOf(docsecImage);

            //         if (activeIndexVal > -1) {
            //             let imageData = data.imageUrl[activeIndexVal];
            //             let convertImage = '';
            //             let encodeBase64 = imageData.split(',')[1];
            //             convertImage =
            //                 'data:image/png;deleted:0;base64,' + encodeBase64;
            //         }
            //     }
            // });

            await setShowImage(true);
            setOpenDelete(false);

            return true;
        }
        if (event.target.parentNode.classList.contains(className)) {
            event.target.parentNode.classList.remove(className);
            var indexInactive = imageActiveUrl.indexOf(
                event.target.parentNode.getAttribute('data-image')
            );

            if (indexInactive > -1) {
                imageActiveUrl.splice(indexInactive, 1);
            }
            if (pushSelectDatas.length) {
                pushSelectDatas.forEach((SelectDatas, index) => {
                    if (
                        SelectDatas.docsecid !== null &&
                        SelectDatas.index !== null
                    ) {
                        let docsecidRemove = SelectDatas.docsecid.indexOf(
                            event.target.parentNode.getAttribute(
                                'data-docsecid'
                            )
                        );
                        let indexRemove = SelectDatas.index.indexOf(
                            event.target.parentNode.getAttribute('data-index')
                        );
                        if (docsecidRemove > -1 && indexRemove > -1) {
                            pushSelectDatas.splice(index, 1);
                        }
                    }
                });
            }
        } else {
            event.target.parentNode.classList.add(className);
            imageActiveUrl.push(
                event.target.parentNode.getAttribute('data-image')
            );
            cutCopyDocName =
                event.target.parentNode.getAttribute('data-docname');
            cutCopyParentId =
                event.target.parentNode.getAttribute('data-docparentid');

            pushSelectDatas.push({
                docsecid: event.target.parentNode.getAttribute('data-docsecid'),
                index: event.target.parentNode.getAttribute('data-index'),
            });
            setSelectedData(pushSelectDatas);
        }

        let activeCount =
            document.getElementsByClassName('pdf-image active').length;
        document.getElementById('merge-break-section').style.display = 'none';
        document.getElementById('duplicate-doc').style.display = 'none';
        document.getElementById('rotate-section').style.display = 'none';
        document.getElementById('new-doc-section').style.display = 'none';
        document.getElementById('multiple-delete').style.display = 'none';
        document.getElementById('unselect').style.display = 'none';
        if (activeCount >= 1) {
            //if (parseInt(activeCount) === 1 || parseInt(imgUrlObj.length) === 1) {
            if (parseInt(activeCount) === 1) {
                document.getElementById('duplicate-doc').style.display =
                    'inline-block';
            }
            document.getElementById('new-doc-section').style.display =
                'inline-block';

            document.getElementById('rotate-section').style.display =
                'inline-flex';
            document.getElementById('multiple-delete').style.display =
                'inline-block';
            document.getElementById('unselect').style.display = 'inline-block';
        } else {
            document.getElementById('merge-break-section').style.display =
                'inline-block';
        }
    };

    const unselectDocument = async () => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        setShowImage(false);
        let activeElem = document.querySelectorAll('.pdf-image.active');
        for (let i = 0; i < activeElem.length; i++) {
            activeElem[i].classList.remove('active');
        }
        await setImgActiveUrlObj([]);
        await setSelectedData([]);
        showMergeBreakSection();
        setShowBreakDoc(false);
        setSelDocForBreak([]);
        setShowImage(true);
    };

    function showMergeBreakSection() {
        document.getElementById('unselect').style.display = 'none';
        document.getElementById('merge-break-section').style.display =
            'inline-block';
        document.getElementById('new-doc-section').style.display = 'none';
        document.getElementById('multiple-delete').style.display = 'none';
        document.getElementById('rotate-section').style.display = 'none';
    }

    const activeImageAppend = async () => {
        imgUrlObj.push({
            docId: 'upload',
            imageUrl: imageActiveUrl, //selectedImageArr
            docName: cutCopyDocName,
            parentId: cutCopyParentId,
            uniqueId: Math.random(),
        });
        await setResetArray(imgUrlObj);
        await setImgUrlObj(imgUrlObj);
    };

    const makeImageAsDeleted = () => {
        let activeEle = document.querySelectorAll('.pdf-image.active');
        for (let i = 0; i < activeEle.length; i++) {
            activeEle[i].setAttribute('data-deleted', 1);
            activeEle[i].classList.remove('active');
            activeEle[i].classList.add('deleted');
            let docsecImage = activeEle[i].getAttribute('data-image');
            let docsecId = activeEle[i].getAttribute('data-docsecid');
            //deletedPages.push(docsecImage);
            imgUrlObj.forEach((data, i) => {
                if (parseInt(i) === parseInt(docsecId)) {
                    let activeIndexVal = data.imageUrl.indexOf(docsecImage);

                    if (activeIndexVal > -1) {
                        let imageData = data.imageUrl[activeIndexVal];
                        let convertImage = '';
                        let encodeBase64 = imageData.split(',')[1];
                        convertImage =
                            'data:image/png;deleted:1;base64,' + encodeBase64;

                        data.imageUrl[activeIndexVal] = convertImage;
                        //data.imageUrl.splice(activeIndexVal, 1);
                        //data.imageUrl.splice(activeIndexVal + 1, 0, convertImage)
                    }
                    /*if (activeIndexVal > -1) {
                        data.imageUrl.splice(activeIndexVal, 1);
                    }*/
                }
            });

            /*setTimeout(() => {
                selctedImageExtract();
                setShowImage(true);
            }, 1000);*/
        }
    };

    const selctedImageExtract = () => {
        let activeEle = document.querySelectorAll('.pdf-image.active');
        for (let i = 0; i < activeEle.length; i++) {
            activeEle[i].parentNode.style.display = 'none';
            let docsecId = activeEle[i].getAttribute('data-docsecid');
            let docsecImage = activeEle[i].getAttribute('data-image');
            activeEle[i].setAttribute('data-deleted', 1);
            imgUrlObj.forEach((data, i) => {
                if (parseInt(i) === parseInt(docsecId)) {
                    let activeIndexVal = data.imageUrl.indexOf(docsecImage);
                    if (activeIndexVal > -1) {
                        data.imageUrl.splice(activeIndexVal, 1);
                    }
                }
            });
        }
    };

    const copyDocument = async () => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        setShowImage(false);
        await activeImageAppend();
        await setImgActiveUrlObj([]); //
        await setSelectedData([]);
        setShowImage(true);
        //await showSelctedImage();
        showMergeBreakSection(); //
        setShowMergeDoc(false);
        setShowBreakDoc(true);
    };

    const cutDocument = async () => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        //setShowSplitDoc(true);
        setShowImage(false);
        selctedImageExtract();
        await activeImageAppend();
        await setImgActiveUrlObj([]);
        await setSelectedData([]);
        setShowImage(true);
        showMergeBreakSection();
        setShowMergeDoc(false);
        setShowBreakDoc(true);
    };
    const openBreakpointsPopup = async () => {
        setShowBreakPointPopup(true);
    };
    const mergeAllDocument = async () => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        setShowImage(false);
        var mergeDocId = '';
        mergeAllNewPush = [];
        var findIndex = null;

        // imgUrlObj.forEach(async (data, i) => {
        //     mergeAllNewPush.push(...data.imageUrl);
        //     if (i == 0) {
        //         mergeDocId = data.docId;
        //         var mergeAllNewArr = mergePush.push({
        //             docId: mergeDocId,
        //             imageUrl: mergeAllNewPush,
        //         });
        //     }
        // });

        selDocForBreak.forEach(async (data, i) => {
            if (lockedDocument.includes(data.docId) !== true) {
                mergeAllNewPush.push(...data.imageUrl);
                imgUrlObj.map((oldData, oldIndex) => {
                    if (
                        data.parentId === oldData.parentId &&
                        data.uniqueId === oldData.uniqueId
                    ) {
                        if (findIndex === null) {
                            findIndex = oldIndex;
                            imgUrlObj[oldIndex] = {
                                docId: data.docId,
                                imageUrl: mergeAllNewPush,
                                docName: data.docName,
                                parentId: data.parentId, // changed by geetha. from data.docId
                                uniqueId: Math.random(),
                            };
                        } else {
                            imgUrlObj.splice(oldIndex, 1);
                            deleteDocIds.push(data.docId);
                        }
                    }
                });
                if (i === 0) {
                    // mergeDocId = data.docId;
                    // indexValue = data;
                } else {
                    // deleteDocIds.push(data.docId);
                }
            }
        });

        // var newItem = {
        //     docId: mergeDocId,
        //     imageUrl: mergeAllNewPush,
        //     docName: indexValue.docName,
        //     parentId: indexValue.parentId, // changed by geetha. from data.docId
        //     uniqueId: Math.random(),
        // }

        var tempArr = [...imgUrlObj]; // addAfter(imgUrlObj, findIndex, newItem);

        await setResetArray(tempArr);
        await setImgUrlObj(tempArr);
        setShowImage(true);
        if (tempArr.length === 1) {
            setShowMergeDoc(true);
        }
        setShowBreakDoc(false);
        setSelDocForBreak([]);
    };

    const deleteMultiple = () => {
        let deleteCount =
            document.getElementsByClassName('pdf-image active').length;
        if (deleteCount > 0) {
            setOpenDelete(true);
            if (deleteCount > 1)
                setDeleteText(
                    'You are about to delete multiple pages. Are you sure you want to do this?'
                );
            else setDeleteText('');
        }
    };

    const handleCloseOperation = () => {
        setOpenDelete(false);
    };
    const handleBreakCloseOperation = () => {
        setShowBreakPointPopup(false);
    };
    const handleDeleteOperation = async () => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        setShowImage(false);
        await makeImageAsDeleted(); //kalpana delete

        //selctedImageExtract();
        await setImgActiveUrlObj([]);
        await setSelectedData([]);

        setShowImage(true);
        setOpenDelete(false);
        showMergeBreakSection();
    };
    const handleBreakOperation = async (breakValue) => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        setShowImage(false);
        let breakArray = [];
        // if (parseInt(docidsCount) === 1) {
        //     mergeAllNewPush = [];
        //     imgUrlObj.forEach(async (data, i) => {
        //         mergeAllNewPush.push(...data.imageUrl);
        //     });
        // }

        // let loopCount = mergeAllNewPush.length / breakValue;
        // let loopCountLength = Math.ceil(loopCount);
        // for (let i = 0; i < loopCountLength; i++) {
        //     let breakArraySplice = mergeAllNewPush.splice(0, breakValue);
        //     imgUrlObj.forEach(async (data, index) => {
        //         if (i == 0) {
        //             let breakDocId = data.docId;
        //             breakArray.push({
        //                 docId: breakDocId,
        //                 imageUrl: breakArraySplice,
        //             });
        //         } else {
        //             breakArray.push({
        //                 docId: 'upload',
        //                 imageUrl: breakArraySplice,
        //             });
        //         }
        //     });
        // }

        selDocForBreak.map((data, index) => {
            if (lockedDocument.includes(data.docId) !== true) {
                imgUrlObj.map((oldData, oldIndex) => {
                    if (data.parentId === oldData.parentId) {
                        imgUrlObj.splice(oldIndex, 1);
                    }
                });
                mergeAllNewPush = [];
                mergeAllNewPush.push(...data.imageUrl);
                let loopCount = mergeAllNewPush.length / breakValue;
                let loopCountLength = Math.ceil(loopCount);
                for (let i = 0; i < loopCountLength; i++) {
                    let breakArraySplice = mergeAllNewPush.splice(
                        0,
                        breakValue
                    );
                    if (i === 0) {
                        breakArray.push({
                            docId: data.docId,
                            imageUrl: breakArraySplice,
                            parentId: data.parentId,
                            docName: data.docName,
                            uniqueId: Math.random(),
                        });
                    } else {
                        breakArray.push({
                            docId: 'upload',
                            imageUrl: breakArraySplice,
                            docName: data.docName,
                            parentId: data.parentId,
                            uniqueId: Math.random(),
                        });
                    }
                }
            }
        });

        let breakResult = breakArray;
        await setResetArray(breakResult);

        var mergeWithOld = [...imgUrlObj, ...breakResult];
        await setImgUrlObj(mergeWithOld);

        setShowBreakPointPopup(false);
        setShowImage(true);
        setShowBreakDoc(true);
        setShowMergeDoc(false);
        setSelDocForBreak([]);
    };

    const showSelctedImage = () => {
        pushSelectDatas.forEach((selectedDatas, index) => {
            let pdfImage = document.querySelectorAll(
                '.pdf-image[data-docsecid="' +
                    selectedDatas.docsecid +
                    '"][data-index="' +
                    selectedDatas.index +
                    '"]'
            );
            for (let i = 0; i < pdfImage.length; i++) {
                pdfImage[i].classList.add('active');
            }
        });
    };

    const duplicateDocument = async () => {
        checkboxRef.current.checked = false;
        setAllChecked(false);
        setShowImage(false);
        let activeEle = document.querySelectorAll('.pdf-image.active');
        for (let i = 0; i < activeEle.length; i++) {
            var docDupId = activeEle[i].getAttribute('data-docsecid');
            var docDupImage = activeEle[i].getAttribute('data-image');
        }

        imgUrlObj.forEach((item, i) => {
            if (parseInt(i) === parseInt(docDupId)) {
                item.imageUrl.push(docDupImage);
            }
        });

        await setResetArray(imgUrlObj);
        await setImgUrlObj(imgUrlObj);
        setShowImage(true);
        await showSelctedImage();
    };

    const handleDocumentToggle = (event, i) => {
        setDocumentToggle(!documentToggle);
        let classNameOpen = 'doc-open';
        let classNameClose = 'doc-close';
        if (
            document
                .getElementById('document-section-' + i)
                .classList.contains(classNameOpen)
        ) {
            document
                .getElementById('document-section-' + i)
                .classList.remove(classNameOpen);
            document
                .getElementById('document-section-' + i)
                .classList.add(classNameClose);
            document
                .getElementById('doc-outer-sec-' + i)
                .classList.remove(classNameOpen);
            document
                .getElementById('doc-outer-sec-' + i)
                .classList.add(classNameClose);
        } else {
            document
                .getElementById('document-section-' + i)
                .classList.add(classNameOpen);
            document
                .getElementById('document-section-' + i)
                .classList.remove(classNameClose);
            document
                .getElementById('doc-outer-sec-' + i)
                .classList.add(classNameOpen);
            document
                .getElementById('doc-outer-sec-' + i)
                .classList.remove(classNameClose);
        }
    };

    const performRoatateperformRoatate = async (rotateDegree) => {
        let activeEle = document.querySelectorAll('.pdf-image.active');
        for (let i = 0; i < activeEle.length; i++) {
            let imageSrcValue = activeEle[i].getAttribute('data-image');
            let imageDocSection = activeEle[i].getAttribute('data-docsecid');
            let imageDocIndex = activeEle[i].getAttribute('data-index');

            rotate(imageSrcValue, rotateDegree, function (resultBase64) {
                let currentActiveEle = activeEle[i].getElementsByClassName(
                    'multiple-edit-image'
                )[0];
                currentActiveEle.setAttribute('src', resultBase64);
                activeEle[i].setAttribute('data-image', resultBase64);

                imgUrlObj.forEach((data, i) => {
                    if (parseInt(imageDocSection) === parseInt(i)) {
                        data.imageUrl.splice(imageDocIndex, 1, resultBase64);
                    }
                });
            });
        }
        await setImgUrlObj(imgUrlObj);
        await setImgActiveUrlObj([]);
        await setSelectedData([]);
        showMergeBreakSection();
        setTimeout(() => {
            setShowImage(false);
            setShowImage(true);
        }, 0.05);
    };

    const rotate = (srcBase64, degrees, callback) => {
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let image = new Image();
        image.onload = function () {
            canvas.width = degrees % 180 === 0 ? image.width : image.height;
            canvas.height = degrees % 180 === 0 ? image.height : image.width;
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((degrees * Math.PI) / 180);
            ctx.drawImage(image, image.width / -2, image.height / -2);
            callback(canvas.toDataURL());
        };
        image.src = srcBase64;
    };

    const overallSaveAction = async () => {
        var successCount = 0;
        var successUploadCount = 0;
        var errorCount = 0;
        let DeleteData = false;
        setBlurSpinner(true);
        if (imgUrlObj.length) {
            var processId = '';
            let menuCount = [];
            let overallArrLength = imgUrlObj.length;

            await imgUrlObj.forEach(async (imgUrlObjData, mainIndex) => {
                if (
                    deleteDocIds.length > 0 &&
                    deleteDocIds.includes(imgUrlObjData.docId) === false &&
                    lockedDocument.includes(imgUrlObjData.docId) === false
                ) {
                    deleteDocIds.map((id) => {
                        if (id !== 'upload') {
                            //DeleteData = true;
                            let deleteMultiDocument =
                                deleteMultipleDocument(id);
                            deleteMultiDocument.then((response) => {
                                let deleteDocStatus = response.status;
                                if (
                                    parseInt(deleteDocStatus) === 204 ||
                                    parseInt(deleteDocStatus) === 200
                                ) {
                                    DeleteData = false;
                                } else {
                                    // DeleteData = true;
                                }
                            });
                        }
                    });
                }

                if (
                    Object.keys(imgUrlObjData).length &&
                    lockedDocument.includes(imgUrlObjData.docId) === false
                ) {
                    if (String(imgUrlObjData.docId) === 'upload') {
                        if (imgUrlObjData.imageUrl.length) {
                            let imgUrl = imgUrlObjData.imageUrl;
                            let pages = [];
                            let fields = [];
                            let extension = 'png';
                            let parentDocId = imgUrlObjData.parentId;
                            fields = allFields[imgUrlObjData.parentId];
                            let uploadDocParams = {};

                            if (imgUrl.length) {
                                imgUrl.forEach((data, index) => {
                                    //kalpana to edit here
                                    if (
                                        String(data) !== '' &&
                                        !data.includes('deleted:1')
                                    ) {
                                        let encodeBase64 = data.split(',')[1];
                                        pages.push({ pageData: encodeBase64 });
                                    }
                                });

                                // return true;

                                if (statusId !== '') {
                                    uploadDocParams = {
                                        // typeId,
                                        fields,
                                        pages,
                                        extension,
                                        statusId,
                                        parentDocId,
                                    };
                                } else {
                                    uploadDocParams = {
                                        // typeId,
                                        fields,
                                        pages,
                                        extension,
                                        parentDocId,
                                    };
                                }

                                //upload api call
                                let uploadData =
                                    uploadDocument(uploadDocParams);

                                await uploadData.then((response) => {
                                    let uploadDocStatus = response.status;

                                    if (
                                        parseInt(uploadDocStatus) === 200 ||
                                        parseInt(uploadDocStatus) === 204
                                    ) {
                                        successUploadCount += 1;
                                        successCount += 1;
                                    } else {
                                        errorCount += 1;
                                    }
                                });
                            }
                        } else {
                            successCount += 1;
                        }
                    } else {
                        if (imgUrlObjData.imageUrl.length) {
                            let docId = imgUrlObjData.docId;
                            let imgUrl = imgUrlObjData.imageUrl;
                            let pages = [];
                            let extension = 'png';
                            let fields = [];

                            if (imgUrl.length) {
                                imgUrl.forEach((data, index) => {
                                    if (
                                        String(data) !== '' &&
                                        !data.includes('deleted:1')
                                    ) {
                                        let encodeBase64 = data.split(',')[1];
                                        pages.push({ pageData: encodeBase64 });
                                    }
                                    //kalpana to edit here
                                    /*if (String(data) !== '') {
                                        deletedPages.map( (delPage) => {
                                            if (String(delPage) === String(data)) {
                                                //dont push    
                                            }
                                            else{
                                                let encodeBase64 = data.split(',')[1];
                                                pages.push({ pageData: encodeBase64 });
                                            }
                                        });
                                    }  */
                                });
                                // return true;
                                if (pages.length > 0) {
                                    //update api call

                                    let updateDocData = updateDocument(
                                        {
                                            fields,
                                            pages,
                                            extension,
                                        },
                                        docId
                                    );

                                    await updateDocData.then((response) => {
                                        let updateDocStatus = response.status;
                                        if (
                                            parseInt(updateDocStatus) === 204 ||
                                            parseInt(updateDocStatus) === 200
                                        ) {
                                            successCount += 1;
                                        } else {
                                            errorCount += 1;
                                        }
                                    });
                                } else {
                                    // DeleteData= true

                                    let deleteMultiDocument =
                                        deleteMultipleDocument(docId);
                                    await deleteMultiDocument.then(
                                        (response) => {
                                            let deleteDocStatus =
                                                response.status;
                                            if (
                                                parseInt(deleteDocStatus) ===
                                                    204 ||
                                                parseInt(deleteDocStatus) ===
                                                    200
                                            ) {
                                                successCount += 1;
                                            } else {
                                                errorCount += 1;
                                            }
                                        }
                                    );
                                }
                            }
                        } else {
                            let docId = imgUrlObjData.docId;
                            if (docId !== 'upload') {
                                // DeleteData= true

                                let deleteMultiDocument =
                                    deleteMultipleDocument(docId);
                                await deleteMultiDocument.then((response) => {
                                    let deleteDocStatus = response.status;
                                    if (
                                        parseInt(deleteDocStatus) === 204 ||
                                        parseInt(deleteDocStatus) === 200
                                    ) {
                                        successCount += 1;
                                    } else {
                                        errorCount += 1;
                                    }
                                });
                            }
                        }
                    }
                }

                let successErrorCount = errorCount + successCount;

                if (overallArrLength - 1 === mainIndex) {
                    // need to check this msg showing crtly
                    if (errorCount === 0) {
                        toast.error(
                            DeleteData === true
                                ? 'Document is Locked - Unable to Delete '
                                : null,
                            {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                    }
                    if (parseInt(errorCount) === 0) {
                        if (parseInt(true)) {
                            //successUploadCount) !== 0
                            configData.menuItems.map((menu) => {
                                if (
                                    formatHeaderNameWithOutSpace(
                                        menu.menuName
                                    ) === 'inbox' &&
                                    String(location.pathname).includes('inbox')
                                ) {
                                    processId = menu.processes[0].processId;
                                }
                                if (
                                    formatHeaderNameWithOutSpace(
                                        menu.menuName
                                    ) === 'outbox' &&
                                    String(location.pathname).includes('outbox')
                                ) {
                                    processId = menu.processes[0].processId;
                                }
                            });

                            getProcessCount(processId)
                                .then((response) => {
                                    return response.json();
                                })
                                .then((data) => {
                                    menuCount.push(data);
                                    dispatch(getCountData(menuCount));
                                });
                        }

                        toast.success('Update successful', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        setTimeout(() => {
                            dispatch(
                                inboxPage({
                                    multiple_edit_page: false,
                                })
                            );
                            dispatch(
                                outboxPage({
                                    multiple_edit_page: false,
                                })
                            );
                            if (
                                String(location.pathname).includes(
                                    'inbox/detail'
                                ) === true
                            ) {
                                let processId =
                                    reduxStore.inbox.inbox.processId;
                                let statusId = reduxStore.inbox.inbox.statusId;
                                let menuArray =
                                    reduxStore.configReducerPersist.configData
                                        .menuItems;
                                let subMenuName;
                                menuArray.map((data) => {
                                    if (data.menuName === 'Inbox') {
                                        let newArray = data.processes;
                                        newArray.map((item) => {
                                            let subMenu = item.statuses;
                                            subMenu.map((key) => {
                                                if (key.statusId === statusId) {
                                                    subMenuName =
                                                        formatNameWithSpaceToUnderscore(
                                                            key.name
                                                        );
                                                }
                                            });
                                        });
                                    }
                                });

                                history.push({
                                    pathname: `/inbox/${subMenuName}/${processId}/${statusId}`,
                                });
                            } else if (
                                String(location.pathname).includes(
                                    'outbox/detail'
                                ) === true
                            ) {
                                let processId =
                                    reduxStore.outbox.outbox.processId;
                                let statusId =
                                    reduxStore.outbox.outbox.statusId;
                                let menuArray =
                                    reduxStore.configReducerPersist.configData
                                        .menuItems;
                                let subMenuName;
                                menuArray.map((data) => {
                                    if (data.menuName === 'Outbox') {
                                        let newArray = data.processes;
                                        newArray.map((item) => {
                                            let subMenu = item.statuses;
                                            subMenu.map((key) => {
                                                if (key.statusId === statusId) {
                                                    subMenuName =
                                                        formatNameWithSpaceToUnderscore(
                                                            key.name
                                                        );
                                                }
                                            });
                                        });
                                    }
                                });

                                history.push({
                                    pathname: `/outbox/${subMenuName}/${processId}/${statusId}`,
                                });
                            } else if (
                                String(location.pathname).includes('reports') ==
                                true
                            ) {
                                dispatch(
                                    deletedPage({
                                        hideSmallView: false,
                                    })
                                );
                                history.push(
                                    reduxStore.search.deletedPage
                                        .reportRedirect,
                                    {}
                                );
                            } else {
                                history.goBack();
                            }

                            if (props.calingFrom !== 'Report') {
                                props.refreshTable();
                            }
                        }, 1000);
                    } else {
                        if (successCount > 0) {
                            toast.success('Document Updated', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                        if (errorCount > 0) {
                            toast.error(
                                DeleteData === true
                                    ? 'Document is Locked - Unable to Delete '
                                    : 'Error in processing',
                                {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                }
                            );
                        }
                        setBlurSpinner(false);
                    }
                }
            });
        }
    };

    const resetDocument = async () => {
        setSelDocForBreak([]);
        setBlurSpinner(true);
        setShowImage(false);
        if (resetDoc.length > 1) {
            if (parseInt(docidsCount) === 1) {
                setShowMergeDoc(true);
                setShowBreakDoc(false);
            } else {
                setShowMergeDoc(false);
                setShowBreakDoc(true);
            }
            showMergeBreakSection();
            await setSelectedData([]);
            document.getElementById('resetAction').removeAttribute('disabled');
            let lastElement = resetDoc[resetDoc.length - 2];
            await setImgUrlObj(lastElement);
            resetDoc.splice(resetDoc.length - 1, 1);
        }
        if (resetDoc.length === 1) {
            setDisableReset(true);
            document
                .getElementById('resetAction')
                .setAttribute('disabled', 'disabled');
            if (resetDoc[0] !== undefined) {
                var temp = [];
                await resetDoc[0].map((img, index) => {
                    var obj = {};
                    obj.docId = img.docId;
                    obj.docName = img.docName;
                    var arr = [];
                    var imgTemp = img.imageUrl;
                    imgTemp.map((base64) => {
                        var temp_base64 = base64;
                        arr.push(temp_base64);
                    });
                    obj.imageUrl = arr;
                    temp.push(obj);
                });
            }

            setLastReset(temp);
            setResetDoc([]);
        }

        setShowImage(true);
        setTimeout(() => {
            setBlurSpinner(false);
        }, 1000);
    };

    const setResetArray = (currentimgUrl) => {
        setDisableReset(false);
        if (resetDoc.length === 0) {
            resetDoc.push(lastReset);
        }

        var temp = [];
        currentimgUrl.map((img, index) => {
            var obj = {};
            obj.docId = img.docId;
            obj.docName = img.docName;
            var arr = [];
            img.imageUrl.map((base64) => {
                arr.push(base64);
            });
            obj.imageUrl = arr;
            temp.push(obj);
        });
        if (resetDoc.length > 6) {
            resetDoc.splice(0, 1);
            resetDoc.push(temp);
        } else {
            resetDoc.push(temp);
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const move = (
        source,
        destination,
        droppableSource,
        droppableDestination
    ) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);

        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
        return result;
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        const mainArray = imgUrlObj[sInd];
        if (parseInt(sInd) === parseInt(dInd)) {
            setShowImage(false);
            const items = reorder(
                mainArray.imageUrl,
                source.index,
                destination.index
            );

            mainArray.imageUrl = items;

            await setResetArray(imgUrlObj);
            await setImgUrlObj(imgUrlObj);
            setShowImage(true);
            showMergeBreakSection();
        } else {
            setShowImage(false);
            const maindArray = imgUrlObj[dInd];
            const result = move(
                mainArray.imageUrl,
                maindArray.imageUrl,
                source,
                destination
            );
            mainArray.imageUrl = result[sInd];
            maindArray.imageUrl = result[dInd];
            await setResetArray(imgUrlObj);
            await setImgUrlObj(imgUrlObj);
            setShowImage(true);
            showMergeBreakSection();
        }
    };

    const splitDocument = async () => {
        setShowImage(false);
        let splitDocSecid = document
            .getElementsByClassName('separator_cut sel')[0]
            .parentNode.getAttribute('data-docsecid');
        let splitDocIndex = document
            .getElementsByClassName('separator_cut sel')[0]
            .parentNode.getAttribute('data-index');
        let splitDocIndexRes = parseInt(splitDocIndex) + parseInt(1);
        imgUrlObj.forEach((data, i) => {
            if (parseInt(splitDocSecid) === parseInt(i)) {
                var splitVal = data.imageUrl.splice(splitDocIndexRes);

                imgUrlObj.push({
                    docId: 'upload',
                    imageUrl: splitVal,
                    docName: data.docName,
                    parentId: data.parentId,
                    uniqueId: Math.random(),
                });
            }
        });
        await setResetArray(imgUrlObj);
        await setImgUrlObj(imgUrlObj);
        setShowImage(true);
    };

    // const grid = 8;

    const getListStyle = (isDraggingOver, isArrEmpty) => ({
        display: 'flex',
        overflow: isArrEmpty,
    });

    function getItemClass(isDragging) {
        return isDragging ? 'image-outer aaa on-drag-custom' : 'image-outer';
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? '#BFDFEE' : '', //grey
        border: isDragging ? '1px solid #007DBC' : '', //grey
        margin: isDragging ? '2px' : '', //grey
        padding: isDragging ? '0px' : '', //grey

        ...draggableStyle,
    });

    const getItemStyleInner = (isDragging, draggableStyle) => ({
        border: isDragging ? '1px solid #007DBC' : '', //grey
    });

    const classActiveSingle = (event, queryClass, activeClass) => {
        let querySelector = document.querySelectorAll(`.${queryClass}`);
        if (!event.target.classList.contains(activeClass)) {
            for (let i = 0; i < querySelector.length; i++) {
                querySelector[i].classList.remove(activeClass);
            }
            event.target.classList.add(activeClass);
        } else {
            event.target.classList.remove(activeClass);
        }
    };

    const selectAllDocument = () => {
        checkboxRef.current.checked = !checkboxRef.current.checked;
        {
            Object.entries(imgUrlObj).map(([k, v]) => {
                if (checkboxRef.current.checked) {
                    let findIndex = selDocForBreak.findIndex(
                        (item) => item === v
                    );
                    if (findIndex === -1) {
                        selDocForBreak.push(v);
                        setSelDocForBreak([...selDocForBreak]);
                    }
                } else {
                    setSelDocForBreak([]);
                }
            });

            if (checkboxRef.current.checked) {
                setAllChecked(true);
            } else {
                setAllChecked(false);
            }
        }
    };

    const selectDocument = (event, doc) => {
        let findIndex = null;
        var moreThanOnePage = false;
        var removeIndex = '';
        if (event.target.checked === true) {
            findIndex = selDocForBreak.findIndex((item) => item === doc);
            if (findIndex === -1) {
                selDocForBreak.push(doc);
                setSelDocForBreak([...selDocForBreak]);
            }
            selDocForBreak.map((data, index) => {
                if (data.imageUrl.length > 1) {
                    moreThanOnePage = true;
                }
            });
        } else if (event.target.checked === false) {
            selDocForBreak.map((data, index) => {
                if (doc.docId === data.docId) {
                    removeIndex = index;
                }
            });
            if (removeIndex !== '') {
                selDocForBreak.splice(removeIndex, 1);
                setSelDocForBreak([...selDocForBreak]);
            }
        }
    };

    // merge and break option using selected document (selDocForBreak)
    useEffect(() => {
        setShowMergeDoc(!(selDocForBreak.length > 1));
        var moreThanOnePage = false;
        selDocForBreak.map((data, index) => {
            if (data.imageUrl.length > 1) {
                moreThanOnePage = true;
            }
        });
        setShowBreakDoc(!(moreThanOnePage === true));
    }, [selDocForBreak]);

    const checkedAction = (doc) => {
        const getFilter = selDocForBreak.findIndex((item) => item === doc);
        return getFilter !== -1 ? true : false;
    };

    // let docNum = 0;
    let docNumUpload = 0;

    // if (selDocForBreak.length === 0 && showMergeDoc === false) {
    //     setShowMergeDoc(true);
    // }
    // if (selDocForBreak.length === 0 && showBreakDoc === false) {
    //     setShowBreakDoc(true);
    // }

    return (
        <>
            <div>
                <div className='multi_btns pr-4 back-button'>
                    {blurSpinner && (
                        <Button
                            variant='secondary'
                            title='Back'
                            className='btn-can btn'
                            onClick={() => {
                                history.goBack();
                                if (props.callingFrom === 'inboxPage') {
                                    dispatch(
                                        inboxPage({
                                            multiple_edit_page: false,
                                        })
                                    );
                                }
                                if (props.callingFrom !== 'inboxPage') {
                                    dispatch(
                                        outboxPage({
                                            multiple_edit_page: false,
                                        })
                                    );
                                }

                                if (
                                    String(location.pathname).includes(
                                        'outbox/detail'
                                    ) === true
                                ) {
                                    dispatch(
                                        outboxPage({
                                            related_doc_detail: true,
                                        })
                                    );
                                }
                                if (
                                    String(location.pathname).includes(
                                        'inbox/detail'
                                    ) === true
                                ) {
                                    dispatch(
                                        inboxPage({
                                            related_doc_detail: true,
                                        })
                                    );
                                }

                                /*  if (
                                     String(location.pathname).includes(
                                         'detail/editmultiple'
                                     ) === true
                                 ) {
                                     dispatch(
                                         outboxPage({
                                             related_doc_detail: true,
                                         })
                                     );
                                     dispatch(
                                         inboxPage({
                                             related_doc_detail: true,
                                         })
                                     );
                                 } */
                            }}
                        >
                            Back
                        </Button>
                    )}
                </div>
            </div>

            <div className='edit-loader'>
                <div className='right-aside edit_page print-page multi-page'>
                    <div className='row align-items-stretch justify-content-between mb-10 multiple-header'>
                        <div className='col align-items-center multi_edit_icons'>
                            <div
                                className='merge-break-section'
                                id='merge-break-section'
                                style={{ display: 'inline-block' }}
                            >
                                <button
                                    id='merge-all'
                                    onClick={selectAllDocument}
                                    className={' document_title chk_all'}
                                    disabled={
                                        imgUrlObj.length ===
                                        lockedDocument.length
                                            ? true
                                            : false
                                    }
                                    style={{
                                        cursor:
                                            imgUrlObj.length ===
                                            lockedDocument.length
                                                ? 'not-allowed'
                                                : '',
                                    }}
                                >
                                    <label className='rdg-checkbox-label'>
                                        <input
                                            ref={checkboxRef}
                                            // onChange={selectAllDocument}
                                            type='checkbox'
                                            className='merge-checkbox'
                                            disabled
                                        />
                                        <span className='rdg-checkbox'></span>
                                    </label>
                                    {checkboxRef && checkboxRef.current?.checked
                                        ? 'Unselect all'
                                        : 'Select all'}
                                </button>

                                <button
                                    disabled={showMergeDoc}
                                    id='merge-all'
                                    onClick={mergeAllDocument}
                                    style={{
                                        cursor: showMergeDoc
                                            ? 'not-allowed'
                                            : '',
                                    }}
                                    className={
                                        showMergeDoc ? 'disabled-merge' : ''
                                    }
                                >
                                    <i className='fa-light fa-diagram-venn'></i>
                                    Merge all
                                </button>
                                <button
                                    disabled={showBreakDoc}
                                    id='break-doc'
                                    onClick={openBreakpointsPopup}
                                    style={{
                                        cursor: showBreakDoc
                                            ? 'not-allowed'
                                            : '',
                                    }}
                                    className={
                                        showBreakDoc ? 'disabled-break' : ''
                                    }
                                >
                                    <svg
                                        className='icon-fill'
                                        style={{ marginRight: '5px' }}
                                        width='20'
                                        height='17'
                                        viewBox='0 0 100 100'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path d='M100,77c-4.7-3.5-9.4-7-13.9-10.5c-1.6-1.2-3.2-2.4-4.7-3.6c0,0.5,0,1,0,1.5l0,1.3c0,0.4,0,0.8,0,1.1  c0,0.1,0,0.2,0,0.3L81,72.8l-5.7,0.5c0,0-0.1,0-0.1,0c-0.2,0-0.5,0.1-1,0.1c-2,0-4,0-6,0c-4.4,0-8.9,0-13.3,0  c-3.6,0-6.7-1.7-8.8-4.9c-3.6-5.4-6.6-9.9-9.4-14.1c-0.9-1.4-1.4-2.9-1.4-4.4c0-1.5,0.5-3,1.4-4.4c3.2-4.8,6.4-9.5,9.4-14  c2.1-3.2,5.4-4.9,9.2-4.9c4.3,0,8.6,0,12.8,0c1.9,0,3.8,0,5.7,0c0.5,0,0.9,0,1.2,0.1c0.1,0,0.2,0,0.3,0l6.1,0.4v6.1  c0,0.9,0,1.9,0,2.8c0,0.3,0,0.7,0,1c0.5-0.4,1-0.7,1.5-1.1c5.6-4.2,11.4-8.6,17.2-12.9c-5-3.8-10.1-7.6-15.1-11.3  c-1.2-0.9-2.4-1.8-3.6-2.7c0,0.1,0,0.2,0,0.3c0,0.8,0,1.7,0,2.5v1.1v6.5h-6.5c-2.3,0-4.7,0-7,0c-5.2,0-10.6,0-15.8,0  c-4.3,0-7.4,1.5-9.9,5c-2.4,3.2-4.8,6.5-7.2,9.6c-1.9,2.6-3.9,5.2-5.9,7.8c-2.2,3-5.4,4.6-9.1,4.6c-3.2,0-6.4,0-9.6,0  c-3.4,0-6.9,0-10.3,0v7.3c3.2,0,6.3,0,9.4,0c3.4,0,6.9,0,10.3,0c3.9,0,7.2,1.6,9.5,4.8c2.4,3.2,4.8,6.5,7.2,9.6  c1.9,2.5,3.9,5.2,5.8,7.8c2.5,3.3,5.4,4.8,9.6,4.8c5.1,0,10.2,0,15.2,0c2.2,0,4.4,0,6.6,0h1.3h6.5v6.5c0,1,0,1.9,0,2.9  c0,0.4,0,0.7,0,1c1.3-1,2.7-2,4-3C90.1,84.4,95.1,80.6,100,77z' />
                                    </svg>
                                    Break Documents
                                </button>
                            </div>
                            <div
                                className='new-doc-section'
                                id='new-doc-section'
                                style={{ display: 'none' }}
                            >
                                <button
                                    id='duplicate-doc'
                                    onClick={duplicateDocument}
                                    style={{ display: 'none' }}
                                >
                                    <i className='fa-regular fa-clone'></i>
                                    Duplicate
                                </button>
                                <button id='copy-doc' onClick={copyDocument}>
                                    <i className='fa-regular fa-copy'></i>
                                    Copy into New Document
                                </button>
                                <button id='cut-doc' onClick={cutDocument}>
                                    <i className='fa-regular fa-scissors'></i>
                                    Cut into New Document
                                </button>
                            </div>
                            <div className='text-edit' style={{ top: '10px' }}>
                                {/* <button
                                disabled={showSplitDoc}
                                id='split-doc'
                                onClick={splitDocument}
                                style={{
                                    cursor: showSplitDoc ? 'not-allowed' : '',
                                    width: 'auto',
                                }}
                                className={showSplitDoc ? 'disabled-split' : ''}
                            >
                                <i className='fas fa-page-break'></i>
                                Split Document
                            </button> */}
                                <button
                                    id='resetAction'
                                    onClick={resetDocument}
                                    disabled={disableReset}
                                    style={{
                                        cursor: disableReset
                                            ? 'not-allowed'
                                            : '',
                                    }}
                                    className={
                                        disableReset ? 'disabled-reset' : ''
                                    }
                                >
                                    {/*  <i className='fas fa-redo'></i> */}
                                    <img
                                        alt='text-rotate'
                                        title='Rotate'
                                        src={
                                            window.location.origin +
                                            '/images/red-forward.svg'
                                        }
                                    />
                                </button>
                                <span
                                    className='editer-delete'
                                    onClick={deleteMultiple}
                                    id='multiple-delete'
                                    style={{ display: 'none' }}
                                >
                                    {/*  <i className='fas fa-trash-alt'></i>*/}
                                    <i className='fa-light fa-trash-can'></i>
                                </span>
                            </div>
                            <div
                                className='tbd text-edit'
                                id='rotate-section'
                                style={{ display: 'none' }}
                            >
                                <span
                                    onClick={() =>
                                        performRoatateperformRoatate('-90')
                                    }
                                >
                                    <i className='fa-regular fa-rotate-left'></i>
                                </span>
                                <span
                                    onClick={() =>
                                        performRoatateperformRoatate('90')
                                    }
                                >
                                    <i className='fa-regular fa-rotate-right'></i>
                                </span>
                            </div>
                            <button
                                className='ml-4'
                                onClick={unselectDocument}
                                id='unselect'
                                style={{ display: 'none' }}
                            >
                                Unselect all
                            </button>
                        </div>
                        <div className='multi_btns pr-4'>
                            <button
                                title='Cancel'
                                className='btn-can'
                                onClick={() => {
                                    history.goBack();
                                    if (props.callingFrom === 'inboxPage') {
                                        dispatch(
                                            inboxPage({
                                                multiple_edit_page: false,
                                            })
                                        );
                                    }
                                    if (props.callingFrom !== 'inboxPage') {
                                        dispatch(
                                            outboxPage({
                                                multiple_edit_page: false,
                                            })
                                        );
                                    }

                                    if (
                                        String(location.pathname).includes(
                                            'outbox/detail'
                                        ) === true
                                    ) {
                                        dispatch(
                                            outboxPage({
                                                related_doc_detail: true,
                                            })
                                        );
                                    }
                                    if (
                                        String(location.pathname).includes(
                                            'inbox/detail'
                                        ) === true
                                    ) {
                                        dispatch(
                                            inboxPage({
                                                related_doc_detail: true,
                                            })
                                        );
                                    }
                                }}
                            >
                                Cancel
                            </button>
                            <Button
                                variant='sucess'
                                title='Save & close'
                                disabled={
                                    showSaveButton ||
                                    imgUrlObj.length === lockedDocument.length
                                }
                                className={
                                    showSaveButton ||
                                    imgUrlObj.length === lockedDocument.length
                                        ? 'save-disabled btn-sav'
                                        : 'btn-sav'
                                }
                                onClick={overallSaveAction}
                                style={{
                                    cursor:
                                        showSaveButton ||
                                        imgUrlObj.length ===
                                            lockedDocument.length
                                            ? 'not-allowed'
                                            : '',
                                }}
                            >
                                Save & close
                            </Button>
                        </div>
                    </div>

                    {openDelete ? (
                        <EditDeleteModal
                            openDelete={openDelete}
                            deleteText={deleteText}
                            handleDeleteOperation={handleDeleteOperation}
                            handleCloseOperation={handleCloseOperation}
                        />
                    ) : null}
                    {showBreakPointPopup ? (
                        <BreakPointModal
                            showBreakPointPopup={showBreakPointPopup}
                            handleBreakCloseOperation={
                                handleBreakCloseOperation
                            }
                            handleBreakOperation={handleBreakOperation}
                            //handleBreakPoint={handleBreakPoint}
                        />
                    ) : null}

                    {showImage ? (
                        imgUrlObj.length > 0 ? (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <div>
                                    {Object.entries(imgUrlObj).map(([k, v]) => {
                                        let isLocked = lockedDocument.includes(
                                            v.docId
                                        )
                                            ? true
                                            : false;
                                        /* if (
                                            lockedDocument.includes(
                                                String(v.docId)
                                            )
                                        ) {
                                            isLocked = true;
                                        } else {
                                            isLocked = false;
                                        } */

                                        let isArrayEmpty = 'auto';
                                        if (v.imageUrl.length === 0) {
                                            isArrayEmpty = 'inherit';
                                        }
                                        // docNum++;
                                        let newArray = v;
                                        let i = k;
                                        let documentSectionId = k;
                                        let docIdName = v.docName;
                                        // 'Document ' +
                                        // docNum +
                                        // ' - ' +
                                        // newArray.docId;
                                        if (
                                            String(newArray.docId) === 'upload'
                                        ) {
                                            docNumUpload++;
                                            docIdName =
                                                'New Document ' +
                                                docNumUpload +
                                                ' (' +
                                                v.docName +
                                                ')';
                                        }
                                        return (
                                            <div
                                                className={
                                                    isLocked
                                                        ? 'row document-row Locked-Document'
                                                        : 'row document-row'
                                                }
                                                key={i}
                                                data-docid={newArray.docId}
                                                id={`docment-row-${newArray.docId}`}
                                            >
                                                <div className='center-editor col-lg-12 col-sm-12'>
                                                    <div
                                                        id={`doc-outer-sec-${i}`}
                                                        className='document_title doc-open'
                                                    >
                                                        {isLocked && (
                                                            <i
                                                                className='fa fa-lock'
                                                                aria-hidden='true'
                                                            />
                                                        )}
                                                        {/* {allChecked ?
                                                            <label className='rdg-checkbox-label'>
                                                                <input
                                                                    // onChange={(
                                                                    //     event
                                                                    // ) => {
                                                                    //     selectDocument(
                                                                    //         event,
                                                                    //         v
                                                                    //     );
                                                                    // }}
                                                                    type='checkbox'
                                                                    className='merge-checkbox'
                                                                    checked={true}
                                                                />
                                                                <span className='rdg-checkbox'></span>
                                                            </label> : */}
                                                        {isLocked !== true && (
                                                            <label className='rdg-checkbox-label'>
                                                                <input
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        if (
                                                                            checkboxRef &&
                                                                            checkboxRef
                                                                                .current
                                                                                ?.checked
                                                                        ) {
                                                                            setAllChecked(
                                                                                false
                                                                            );
                                                                            checkboxRef.current.checked = false;
                                                                        }
                                                                        selectDocument(
                                                                            event,
                                                                            v
                                                                        );
                                                                    }}
                                                                    type='checkbox'
                                                                    className='merge-checkbox'
                                                                    checked={
                                                                        isLocked !==
                                                                            true &&
                                                                        checkedAction(
                                                                            v
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        isLocked
                                                                    }
                                                                />
                                                                <span className='rdg-checkbox'></span>
                                                            </label>
                                                        )}
                                                        <span>
                                                            <i
                                                                className='fa fa-chevron-up'
                                                                aria-hidden='true'
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleDocumentToggle(
                                                                        event,
                                                                        i
                                                                    )
                                                                }
                                                            ></i>
                                                        </span>
                                                        <span>{docIdName}</span>
                                                    </div>
                                                    <Droppable
                                                        droppableId={k}
                                                        type='CARD'
                                                        direction='horizontal'
                                                        isCombineEnabled={false}
                                                        key={k}
                                                        isDropDisabled={
                                                            isLocked
                                                        }
                                                    >
                                                        {(
                                                            dropProvided,
                                                            snapshot
                                                        ) => (
                                                            <div
                                                                {...dropProvided.droppableProps}
                                                            >
                                                                <div
                                                                    className='document-section doc-open'
                                                                    id={`document-section-${i}`}
                                                                    data-index={
                                                                        i
                                                                    }
                                                                >
                                                                    <div
                                                                        className='image-flex'
                                                                        ref={
                                                                            dropProvided.innerRef
                                                                        }
                                                                        style={getListStyle(
                                                                            snapshot.isDraggingOver,
                                                                            isArrayEmpty
                                                                        )}
                                                                    >
                                                                        {v
                                                                            .imageUrl
                                                                            .length ? (
                                                                            v.imageUrl.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => {
                                                                                    let indexRes =
                                                                                        index +
                                                                                        1;
                                                                                    let showScissors =
                                                                                        'block';
                                                                                    if (
                                                                                        parseInt(
                                                                                            v
                                                                                                .imageUrl
                                                                                                .length
                                                                                        ) ===
                                                                                        parseInt(
                                                                                            indexRes
                                                                                        )
                                                                                    )
                                                                                        showScissors =
                                                                                            'none';

                                                                                    let deletedImage = 0;
                                                                                    let deletedClass =
                                                                                        '';
                                                                                    let disabledClass =
                                                                                        '';
                                                                                    var deleted = 0;
                                                                                    if (
                                                                                        item.includes(
                                                                                            'deleted:0'
                                                                                        )
                                                                                    ) {
                                                                                        deleted = 0;
                                                                                    } else if (
                                                                                        item.includes(
                                                                                            'deleted:1'
                                                                                        )
                                                                                    ) {
                                                                                        deleted = 1;
                                                                                        deletedImage = 1;
                                                                                        deletedClass =
                                                                                            'deleted';
                                                                                        disabledClass =
                                                                                            'disabled';
                                                                                    }
                                                                                    deletedPages.map(
                                                                                        (
                                                                                            delPage
                                                                                        ) => {
                                                                                            if (
                                                                                                String(
                                                                                                    delPage
                                                                                                ) ===
                                                                                                String(
                                                                                                    item
                                                                                                )
                                                                                            ) {
                                                                                                deletedImage = 1;
                                                                                                deletedClass =
                                                                                                    'deleted';
                                                                                                disabledClass =
                                                                                                    'disabled';
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    //deletedPages.includes(item)

                                                                                    return (
                                                                                        <Draggable
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            draggableId={`${k}${index}`}
                                                                                            index={
                                                                                                index
                                                                                            }
                                                                                            isDragDisabled={
                                                                                                isLocked
                                                                                            }
                                                                                        >
                                                                                            {(
                                                                                                dragProvided,
                                                                                                snapshot
                                                                                            ) => (
                                                                                                <div
                                                                                                    // className='image-outer'
                                                                                                    className={`${deletedClass} ${getItemClass(
                                                                                                        snapshot.isDragging
                                                                                                    )}`}
                                                                                                    {...dragProvided.dragHandleProps}
                                                                                                    {...dragProvided.draggableProps}
                                                                                                    ref={
                                                                                                        dragProvided.innerRef
                                                                                                    }
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        dragProvided
                                                                                                            .draggableProps
                                                                                                            .style
                                                                                                    )}
                                                                                                    //disable="disabled"
                                                                                                    disable={
                                                                                                        disabledClass
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        id='pdf-image'
                                                                                                        className={`print-preview pdf-image ${deletedClass}`}
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        onClick={(
                                                                                                            event
                                                                                                        ) =>
                                                                                                            isLocked !==
                                                                                                            true
                                                                                                                ? activeImage(
                                                                                                                      event
                                                                                                                  )
                                                                                                                : null
                                                                                                        }
                                                                                                        data-deleted-param={
                                                                                                            deleted
                                                                                                        }
                                                                                                        data-docsecid={
                                                                                                            documentSectionId
                                                                                                        }
                                                                                                        data-index={
                                                                                                            index
                                                                                                        }
                                                                                                        data-image={
                                                                                                            item
                                                                                                        }
                                                                                                        data-docname={
                                                                                                            v.docName
                                                                                                        }
                                                                                                        data-docparentid={
                                                                                                            v.parentId
                                                                                                        }
                                                                                                        data-deleted={
                                                                                                            deletedImage
                                                                                                        }
                                                                                                    >
                                                                                                        <i className='fa-solid fa-xmark' />
                                                                                                        {isLocked !==
                                                                                                            true && (
                                                                                                            <div
                                                                                                                className='separator_cut aaa'
                                                                                                                onClick={(
                                                                                                                    event
                                                                                                                ) =>
                                                                                                                    isLocked !==
                                                                                                                    true
                                                                                                                        ? setSeparator(
                                                                                                                              event,
                                                                                                                              index
                                                                                                                          )
                                                                                                                        : null
                                                                                                                }
                                                                                                                style={{
                                                                                                                    display: `${showScissors}`,
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className='fas fa-cut'></i>
                                                                                                            </div>
                                                                                                        )}
                                                                                                        <span className='image-checkbox'>
                                                                                                            <i className='fas fa-check-square'></i>
                                                                                                        </span>
                                                                                                        {isLocked !==
                                                                                                        true ? (
                                                                                                            <HtmlTooltip
                                                                                                                placement='right'
                                                                                                                title={
                                                                                                                    <React.Fragment>
                                                                                                                        {' '}
                                                                                                                        {
                                                                                                                            <img
                                                                                                                                width={
                                                                                                                                    374
                                                                                                                                }
                                                                                                                                height={
                                                                                                                                    460
                                                                                                                                }
                                                                                                                                src={
                                                                                                                                    item
                                                                                                                                }
                                                                                                                                alt='pdf-page'
                                                                                                                            />
                                                                                                                        }{' '}
                                                                                                                    </React.Fragment>
                                                                                                                }
                                                                                                            >
                                                                                                                <img
                                                                                                                    style={getItemStyleInner(
                                                                                                                        snapshot.isDragging,
                                                                                                                        dragProvided
                                                                                                                            .draggableProps
                                                                                                                            .style
                                                                                                                    )}
                                                                                                                    className='multiple-edit-image'
                                                                                                                    data-docsecid={
                                                                                                                        documentSectionId
                                                                                                                    }
                                                                                                                    data-index={
                                                                                                                        index
                                                                                                                    }
                                                                                                                    src={
                                                                                                                        item
                                                                                                                    }
                                                                                                                    data-deleted-param={
                                                                                                                        deleted
                                                                                                                    }
                                                                                                                    alt='pdf-page'
                                                                                                                />
                                                                                                            </HtmlTooltip>
                                                                                                        ) : (
                                                                                                            <img
                                                                                                                style={getItemStyleInner(
                                                                                                                    snapshot.isDragging,
                                                                                                                    dragProvided
                                                                                                                        .draggableProps
                                                                                                                        .style
                                                                                                                )}
                                                                                                                className='multiple-edit-image'
                                                                                                                data-docsecid={
                                                                                                                    documentSectionId
                                                                                                                }
                                                                                                                data-index={
                                                                                                                    index
                                                                                                                }
                                                                                                                src={
                                                                                                                    item
                                                                                                                }
                                                                                                                data-deleted-param={
                                                                                                                    deleted
                                                                                                                }
                                                                                                                alt='pdf-page'
                                                                                                            />
                                                                                                        )}
                                                                                                        <p>
                                                                                                            Page{' '}
                                                                                                            {index +
                                                                                                                1}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    );
                                                                                }
                                                                            )
                                                                        ) : (
                                                                            <div className='empty-doc-sec'>
                                                                                The
                                                                                Document
                                                                                is
                                                                                empty
                                                                            </div>
                                                                        )}
                                                                        {
                                                                            dropProvided.placeholder
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </DragDropContext>
                        ) : null
                    ) : (
                        <div
                            className='kalpana'
                            style={{ height: window.innerHeight }}
                        ></div>
                    )}
                </div>
                {blurSpinner ? <SpinnerBlur show={blurSpinner} /> : null}
            </div>

            {/*<ProgressLoader show={true} />*/}
        </>
    );
}

export default MultipleEditPage;
