import { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import EntityForm from './components/EntityForm';
import { AddEntityPageProps, OptionType } from './models/interfaces';
import { isMobile } from 'react-device-detect';
import { addNewPhysician } from '../api/api';
import { mapEntityToApiData } from './utils/mapEntityToApiData';
import {
    normalSuccessToast,
    showErrorToast,
} from '../../components/commonfunction';
import { fetchRecordsetData } from './utils/signatureApi';
import { getDropDownDataFromApi } from '../../components/commonfunctionTS';

const AddEntityPage: FC<AddEntityPageProps> = ({
    setModel,
    headerColumns,
    physicianRecordSetId,
}) => {
    const [lookupOptions, setLookupOptions] = useState<OptionType[]>([]);
    const [welcomeSenderOptions, setWelcomeSenderOptions] = useState<
        OptionType[]
    >([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const formikRef = useRef<FormikProps<any>>(null);

    useEffect(() => {
        const lookupKeyField = headerColumns.find(
            (col: any) => col.name === 'Lookup Key Type'
        )?.fieldId;
        if (lookupKeyField) {
            fetchDropDownData(lookupKeyField);
        }

        fetchRecordsetData('Send Entity Email')
            .then((sendEntityEmails) => {
                if (sendEntityEmails) {
                    const { data } = sendEntityEmails;
                    if (data) {
                        const response: OptionType[] =
                            data.dataTable.dataRows.map((item: any) => ({
                                value: item.id,
                                label: item.values,
                            }));
                        setWelcomeSenderOptions(response);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const fetchDropDownData = async (lookupKeyField: any) => {
        const data = await getDropDownDataFromApi(lookupKeyField);
        if (data.values) {
            const response: OptionType[] = data.values.map((item: string) => ({
                value: item ?? '',
                label: item ?? '',
            }));
            setLookupOptions(response);
        }
    };

    const createPhysician = async (values: object) => {
        setIsSubmitting(true);
        try {
            const apiData = mapEntityToApiData(values, headerColumns);
            const response = await addNewPhysician(
                apiData,
                physicianRecordSetId
            );

            if (
                !response ||
                response.status === 400 ||
                response.status === 404
            ) {
                const responseData = await response?.json();
                if (responseData.message.includes('Communication Email is already in use')) {
                    showErrorToast(
                        'Entity with this Communication Email already exists'
                    );
                } else {
                    showErrorToast('Failed to create physician');
                }
            } else {
                setModel(false);
                normalSuccessToast('Physician created successfully');
            }
        } catch (error) {
            showErrorToast('Failed to create physician');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div
            id='right-aside'
            className='right-aside py-7 d-flex flex-column justify-content-between'
            style={
                isMobile
                    ? { minHeight: window.innerHeight - 112 }
                    : { minHeight: window.innerHeight - 158 }
            }
        >
            <div className='listing-grid'>
                <Formik
                    innerRef={formikRef}
                    onSubmit={createPhysician}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => (
                        <Form>
                            <EntityForm
                                formik={formik}
                                lookupOptions={lookupOptions}
                                welcomeSenderOptions={welcomeSenderOptions}
                                title='Add Entity'
                            />
                        </Form>
                    )}
                </Formik>
            </div>

            <div>
                <hr />
                <div className='d-flex footer-btn align-items-stretch justify-content-between mb-2 pl-7 pr-7'>
                    <div className='d-flex'>
                        <Button
                            title='Cancel'
                            variant='secondary'
                            onClick={() => setModel(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className='d-flex'>
                        <Button
                            title='Add'
                            type='submit'
                            variant='success'
                            disabled={isSubmitting}
                            onClick={() => formikRef?.current?.handleSubmit()}
                        >
                            {isSubmitting ? (
                                <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                            ) : (
                                <span>Add Physician</span>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEntityPage;

const initialValues = {
    communicationEmail: '',
    firstName: '',
    lastName: '',
    fullNameWithTitle: '',
    countryCode: '+1',
    smsNumber: '',
    lookupKeyType: '',
    lookupKeyValue: '',
    resendDays: '',
    keepDocumentsFromGroups: false,
    disableAutoResend: false,
    welcomeSender: '',
    changeCounter: 0,
};

const validationSchema = Yup.object().shape({
    communicationEmail: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    smsNumber: Yup.string()
        .matches(/^\d{3}-\d{3}-\d{4}$/, 'SMS number must be 10 digits')
        .nullable(true),
});
