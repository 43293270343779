/* eslint-disable array-callback-return */
import React from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    formatHeaderNameWithOutSpace,
    formatDate,
    normalSuccessToast,
} from '../../../components/commonfunction';
import { createNewPatient } from '../../api/api';

export class CreateNewOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_date: new Date(),
            orderFormData: { date_of_birth_validation: '', dob: '' },
        };
    }

    componentDidUpdate() {
        if (this.props.submitCreateOrder === true) {
            this.submitCreateNewOrder();
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    handleFormOnChange = (state, event) => {
        const orderFormData = {
            ...this.state.orderFormData,
            [state]: event.target.value,
        };
        this.setState({ orderFormData: orderFormData });
    };

    setFormOnChange = (state, value, type) => {
        const orderFormData = { ...this.state.orderFormData, [state]: value };
        this.setState({ orderFormData: orderFormData });
    };

    submitCreateNewOrder = () => {
        this.props.resetSubmit();
        const orderFieldIdArray = this.props.orderFieldIdArray;
        if (this.state.orderFormData.date_of_birth_validation !== '') {
            const date = formatDate(
                this.state.orderFormData.date_of_birth_validation,
                'MM/DD/YYYY'
            );

            if (date !== 'Invalid date') {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.orderFormData.date_of_birth_validation = date;
            }
        }
        const params = [];
        orderFieldIdArray.map((data) => {
            const value =
                this.state.orderFormData[
                    formatHeaderNameWithOutSpace(data.name)
                ];
            if (
                this.state.orderFormData[
                    formatHeaderNameWithOutSpace(data.name)
                ] !== undefined &&
                this.state.orderFormData[
                    formatHeaderNameWithOutSpace(data.name)
                ] !== ''
            ) {
                params.push({
                    fieldId: data.fieldId,
                    value: value,
                });
            }
        });
        let status;
        createNewPatient(params, this.props.orderModifiableRecordsetId)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((data) => {
                if (status === 200) {
                    // eslint-disable-next-line react/no-direct-mutation-state
                    this.state.orderFormData.id = data.uniqueId;
                    this.props.closeCreateOrder(this.state.orderFormData);
                    normalSuccessToast('Order Created Successfully');
                }
            });
    };

    render() {
        return (
            <>
                <div className='form-group row'>
                    <div className='col-lg-6 transition-select'>
                        <label className='pl-5'>PATIENT FIRST NAME</label>
                        <div className='position-relative'>
                            <input
                                type='text'
                                className='form-control'
                                value={this.state.firstName}
                                onChange={(event) => {
                                    this.handleFormOnChange('firstName', event);
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 transition-select'>
                        <label className='pl-5'>PATIENT LAST NAME</label>
                        <input
                            type='text'
                            className='form-control'
                            value={this.state.lastName}
                            onChange={(event) => {
                                this.handleFormOnChange('lastName', event);
                            }}
                        />
                    </div>
                </div>

                <div className='form-group row'>
                    <div className='col-lg-6 transition-select'>
                        <label className='pl-5'>DATE OF BIRTH</label>
                        <div className='position-relative'>
                            {/* <input type="text" className="form-control" readonly placeholder="Select date"/> */}
                            <DatePicker
                                selected={this.state.orderFormData.dob}
                                onChange={(date) => {
                                    this.setFormOnChange('dob', date);
                                }}
                                onChangeRaw={this.handleDateChangeRaw}
                                className='form-control'
                                customInput={
                                    <input
                                        type='text'
                                        id='validationCustom01'
                                        placeholder='First name'
                                        readOnly
                                    />
                                }
                                showYearDropdown
                                format='MM/dd/y'
                            />
                            <span className='date_icon'>
                                <i className='ki ki-calendar' />
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-6 transition-select'>
                        <label className='pl-5'>GENDER</label>
                        <select
                            id='delete_selectbrand'
                            className='form-control'
                            value={this.state.gender}
                            onChange={(event) => {
                                this.handleFormOnChange('gender', event);
                            }}
                        >
                            <option value=''>Select</option>
                            <option value='M'>Male</option>
                            <option value='F'>Female</option>
                        </select>
                        <i
                            className='fa-light fa-chevron-down'
                            style={{ pointerEvents: 'none' }}
                        />
                    </div>
                </div>

                <div className='form-group row'>
                    <div className='col-lg-6 transition-select'>
                        <label className='pl-5'>EPISODE STATUS</label>
                        <div className='position-relative'>
                            <select
                                id='delete_selectbrand'
                                className='form-control'
                                value={this.state.episode_status}
                                onChange={(event) => {
                                    this.handleFormOnChange(
                                        'episode_status',
                                        event
                                    );
                                }}
                            >
                                <option value=''>Select</option>
                                {this.props.ddl &&
                                Array.isArray(this.props.ddl.episode_status)
                                    ? this.props.ddl.episode_status.map(
                                          (data, index) => (
                                              <option key={index} value={data}>
                                                  {data}
                                              </option>
                                          )
                                      )
                                    : null}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-6 transition-select'>
                        <label className='pl-5'>BRANCH</label>
                        <select
                            id='delete_selectbrand'
                            className='form-control'
                            onChange={(event) => {
                                this.handleFormOnChange('branch', event);
                            }}
                        >
                            <option value=''>Select</option>
                            {this.props.ddl &&
                            Array.isArray(this.props.ddl.branch)
                                ? this.props.ddl.branch.map((data, index) => (
                                      <option
                                          key={index}
                                          value={data}
                                          style={{
                                              display:
                                                  data !== ''
                                                      ? 'block'
                                                      : 'none',
                                          }}
                                      >
                                          {data}
                                      </option>
                                  ))
                                : null}
                        </select>
                        <i
                            className='fa-light fa-chevron-down'
                            style={{ pointerEvents: 'none' }}
                        />
                    </div>
                </div>

                <div className='form-group row'>
                    <div className='col-lg-6 transition-select'>
                        <label className='pl-5'>PATIENT ID</label>
                        <select
                            id='delete_selectbrand'
                            className='form-control'
                            onChange={(event) => {
                                this.handleFormOnChange('patient_id', event);
                            }}
                        >
                            <option value=''>Select</option>
                            {this.props.ddl &&
                            Array.isArray(this.props.ddl.patientid)
                                ? this.props.ddl.patientid.map(
                                      (data, index) => (
                                          <option key={index} value={data}>
                                              {data}
                                          </option>
                                      )
                                  )
                                : null}
                        </select>
                        <i
                            className='fa-light fa-chevron-down'
                            style={{ pointerEvents: 'none' }}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateNewOrder));
